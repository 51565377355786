import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const CodeValidationView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const Title = styled.h1`
	width: 100%;
	color: ${colorThemeProvider.primaryWhiteColorContrasct};
	font-size: 1.5rem;
	font-weight: 600;
	padding-bottom: 2rem;
`

export const Description = styled.p`
	color: ${colorThemeProvider.blackColor};
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const ContactText = styled.p`
	padding-top: 8px;
	color: ${colorThemeProvider.blackColor};
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const Code = styled.div`
	display: flex;
	gap: 16px;
	padding-top: 2.62rem;

	input {
		display: flex;
		text-align: center;
		width: 2.1875rem;
		height: 3.75rem;
		border-radius: 0.3125rem;
		border: 1px solid var(--stroke-10, ${colorThemeProvider.extrasColor});
		background: var(--white-10, ${colorThemeProvider.primaryWhiteColor});

		font-size: 1.5rem;
		color: ${colorThemeProvider.blackColor};
	}
`

export const TimerWrapper = styled.div`
	padding-top: 2.62rem;

	div {
		display: flex;
		flex-direction: column;
		align-items: center;

		p:first-of-type {
			color: ${colorThemeProvider.grayColor};
			text-align: center;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1.5rem; /* 150% */
			letter-spacing: -0.0125rem;
		}
	}
`

export const Timer = styled.p`
	color: ${colorThemeProvider.grayColor};
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const OtherWayCard = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 0.3125rem;
	background: ${colorThemeProvider.primaryWhiteColor};
	padding: 16px;
	margin-top: 2.62rem;

	img {
		padding-right: 16px;
	}

	p {
		display: flex;
		gap: 4px;
		color: ${colorThemeProvider.blackColor};
		font-size: 0.875rem;
		line-height: 1.25rem; /* 142.857% */
		letter-spacing: -0.0125rem;

		p {
			color: ${colorThemeProvider.primaryColor};
			font-size: 0.875rem;
			font-weight: 700;
			line-height: 1.25rem;
			letter-spacing: -0.0125rem;
			cursor: pointer;
		}
	}
`

export const ErrorCard = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	border-radius: 0.3125rem;
	background: #f5edff;
	padding: 16px;
	margin-top: 2.62rem;

	img {
		padding-right: 16px;
	}

	p {
		display: flex;
		gap: 4px;
		color: ${colorThemeProvider.errorColor};
		font-size: 0.875rem;
		line-height: 1.25rem; /* 142.857% */
		letter-spacing: -0.0125rem;
	}
`
