import { eqProps } from 'ramda'
import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import { IResaleItem } from '../../../modules/ResaleList/types'
import {
	IActionsFromCreators,
	ICustomerActionCreators,
	IResalesActionCreators
} from '../../interfaces/action'
import { arrayReplace } from '../../utils/array'

export const {
	Types,
	Creators
}: IActionsFromCreators<IResalesActionCreators> = createActions({
	setResales: ['resales'],
	updateResale: ['code', 'payload'],
	resetResales: []
})

const INITIAL_STATE: IResaleItem[] = []

const set = (state = INITIAL_STATE, action: any) => action.resales

const update = (state = INITIAL_STATE, action: any) => {
	const { code, payload } = action || {}

	const index = state.findIndex(eqProps('code', code))

	if (!index || index === -1) return state

	const updated = arrayReplace(index, payload, state)

	return updated
}
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

export default createReducer<IResaleItem[], Action<ICustomerActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_RESALES]: set,
		[Types.UPDATE_RESALE]: update,
		[Types.RESET_RESALES]: reset
	}
)
