import styled from 'styled-components'

export const ButtonExpandedWrapper = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 24px;
	height: 41px;
	padding: 24px;
	color: #fff;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	background: ${(props) => props.theme.palette.primary.main};
	border-radius: 5px;
	border: none;
    cursor: pointer;

	&:disabled {
		border-radius: 5px;
		border: none;
		background: #b7b7b7;
		cursor: not-allowed;
	}
`

export const ButtonDecressWrapper = styled.button`
	display: flex;
	align-items: center;
	justify-content: space-around;
	padding: 24px;
	height: 41px;
	border-radius: 5px;
	border: 1px solid #ce332e;
	background: #f9f9f9;

	color: #ce332e;
	font-size: 10px;
	font-weight: 700;

    cursor: pointer;

	&:disabled {
		border-radius: 5px;
		border: none;
		background: #b7b7b7;
		cursor: not-allowed;
	}
`
