import { axiosGPInstance as apiAdmin } from '../../../shared/services/axiosInstace'

export const comboServices = {
	getCompanies: async function (): Promise<any> {
		const url = `/companies`
		const { data } = await apiAdmin.get(url)
		return data
	},

	getComboData: async function (salesOrganizationID: string): Promise<any> {
		const url = `/combo?salesOrganizationID=${salesOrganizationID}&useMock=false`
		const { data } = await apiAdmin.get(url)
		return data
	},

	uploadComboImage: async function (file: any) {
		const url = `${'https://jyvxvsusib.execute-api.sa-east-1.amazonaws.com/prd'}/bucketbpgpbanners`

		const bodyFormData = new FormData()
		bodyFormData.append('upload', file)

		const options = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}

		const { data } = await apiAdmin.post(url, bodyFormData, options)
		return data
	},

	updateComboSaveUrlImage: async function (
		comboId: string,
		comboImageUrl: any
	) {
		const url = `/combo`
		const body = {
			comboId,
			comboImageUrl
		}
		const { data } = await apiAdmin.patch(url, body)
		return data
	}
}
