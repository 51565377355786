import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import { colorThemeProvider } from '../../theme/themeProvider'

export const Container = styled.div`
	margin-bottom: 40px;
	.mt20 {
		margin-top: 20px;
	}
`

export const GoBackButton = styled.button`
	display: flex;
	align-items: center;
	background: none;
	border: none;

	p {
		color: ${(props) => props.theme.palette.primary.main};
		margin-left: 8px;
	}
`

export const Title = styled.span<{ color: string }>`
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	color: ${({ color }) => color};
	line-height: ${(props) => props.theme.font.size.TEXT_LG.line_height}rem;
`

export const ButtonWrapper = styled.div`
	margin-top: 40px;
	justify-content: center;
	align-self: center;
	display: flex;
`

export const PreviewImage = styled.div`
	display: flex;
	padding: 8px;
	align-items: center;
	justify-content: space-between;
	background: ${(props) => props.theme.palette.white.main};
	border-radius: 4px;
	margin: 32px 0;
	border: 1px solid ${(props) => props.theme.palette.primary.main};
	height: 83px;

	.img-preview {
		width: 63px;
		height: 63px;
	}
`
export const ImageInfo = styled.div`
	margin-left: 8px;
`

export const ImageName = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	letter-spacing: 0.22px;
	color: ${colorThemeProvider.grayColor};

	max-width: 250px;
	font-size: ${(props) => props.theme.font.size.TEXT_XL.size}rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`

export const ImageSize = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;

	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
	letter-spacing: 0.64px;
	color: ${colors.sixthGray};
`
export const SendDocumentContainer = styled.div`
	align-items: center;
	margin: auto;
	display: flex;
	flex-direction: column;
`
