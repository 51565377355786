/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

import CartSummaryBadge from '../CartSummaryBadge'
import { IViewProps } from './types'

import XIcon from '../../assets/images/x-circle.svg'

function BottomBarToaster(props: IViewProps) {
	const {
		count,
		isActive,
		minValueLabel,
		productSrc,
		reachedMinValue,
		handleGoToCart,
		hide,
		itemType
	} = props

	const baseClass = 'bottom-bar-toaster-wrapper'

	const valueClass = `${!reachedMinValue ? 'below-value' : ''}`

	const activeClass = `${isActive ? 'active' : ''}`

	const wrapperClass = `${baseClass} ${activeClass} ${valueClass}`

	return (
		<div className={wrapperClass}>
			<div className="bottom-bar-toaster">
				<div className="left-side">
					<div className="item-photo">
						<span className="badge">{count}</span>

						<img
							className="image"
							src={productSrc}
							alt="item foto"
						/>
					</div>

					<div className="bar-info">
						<h1 className="message">
							Produto adicionado ao Carrinho!
						</h1>
					</div>
				</div>
				<div className="toaster-center">
					{reachedMinValue ? (
						<button
							onClick={handleGoToCart}
							className="cart-button">
							FINALIZAR COMPRA
						</button>
					) : (
						<div className="min-order-warning">
							<p className="min-order-subtitle">
								{'Valor mínimo não foi atingido'}
							</p>

							<p className="min-order-text">
								{'Sua compra precisa atingir o'}
								<span className="bold">{' valor mínimo '}</span>
								<span>{'de'}</span>{' '}
								<span className="bold">{`${minValueLabel}.`}</span>
							</p>
						</div>
					)}
				</div>
				<div className="right-side">
					<CartSummaryBadge itemType={itemType} reverse />
					<a onClick={hide} className="close">
						<img className="close-icon" src={XIcon} alt="Fechar" />
					</a>
				</div>
			</div>
		</div>
	)
}

export default BottomBarToaster
