export const handleFormatDate = (date: string) => {
	// Criar um objeto Date a partir da string
	const data = new Date(date)

	// Obter componentes da data (dia, mês, ano)
	const dia = data.getDate()
	const mes = data.getMonth() + 1 // Mês é base 0, então somamos 1
	const ano = data.getFullYear()

	// Formatar a data como "dd/mm/yyyy"
	const dataFormatada = `${dia}/${mes}/${ano}`
	return dataFormatada
}
