import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'

export enum ActionType {
	OPEN_PRODUCT = 'OPEN_PRODUCT',
	OPEN_COMBOS = 'OPEN_COMBOS',
	OPEN_CATEGORY = 'OPEN_CATEGORY',
	OPEN_CHALLENGES = 'OPEN_CHALLENGES',
	OPEN_VIDEO = 'OPEN_VIDEO',
	OPEN_PROGRAM_POINTS = 'OPEN_PROGRAM_POINTS'
}

export enum ActionTypeLink {
	OPEN_PRODUCT = CustomerRoutesEnum.PRODUCT_LIST,
	OPEN_COMBOS = CustomerRoutesEnum.COMBO_LIST,
	OPEN_CATEGORY = CustomerRoutesEnum.PRODUCT_LIST,
	OPEN_CHALLENGES = CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGES,
	OPEN_VIDEO = '',
	OPEN_PROGRAM_POINTS = CustomerRoutesEnum.POINTS_PROGRAM__MY_POINTS
}

export enum Destiny {
	HOME = 'HOME',
	LOGIN = 'LOGIN'
}

export const creationStatusMapper = {
	PENDING: 'Pendente',
	SUCCESS: 'Sucesso ao criar',
	FAIL: 'Falha ao criar'
} as Record<string, string>
