import { prop } from 'ramda'
import { deepCopy } from '.'
import { isCombo } from '../../modules/OrderSummary/util'

import {
	ICartComboProduct,
	ICartItem,
	ICartItemTypeEnum,
	OperationTypeDescription
} from '../interfaces/cart'
import { ICombo, TradeType } from '../interfaces/combo'
import { Maybe } from '../interfaces/common'
import { IMaterial, IPriceByQuantity } from '../interfaces/material'
import { ICartChallenge } from '../interfaces/pointsProgramChallenge'
import { IPriceAction } from '../interfaces/priceAction'
import { IStoreCart } from '../interfaces/store'
import { isPropEqual, pickKeys } from './object'
import { pipe } from './operators'
// import { getHighestPrice } from './price'
import { randomStringGenerator } from './random-string-generator.util'

const itemKeys = [
	'MaterialID',
	'OrderQuantity',
	'UnitMeasureSale',
	'PriceByQuantity',
	'ImageUrl'
]

export function findCheaperPriceAction(priceActions: IMaterial): IMaterial {
	priceActions?.priceAction?.sort((paa, pab) => {
		const compare = Number(paa.maxAmount) <= Number(pab.maxAmount)

		if (compare) return -1
		return 1
	})

	return priceActions
}

function extractCartItemFromIMaterial(
	item: IMaterial,
	amount: number
): ICartItem {
	const { Characteristics: char, StockPosition, Description } = item

	const basicKeys = pickKeys(itemKeys)(item)

	const pack = char?.find(isPropEqual('Type')('Package'))

	const packType = char?.find(isPropEqual('Type')('PackageType'))?.Description

	const Size = pack?.Description || 'Sem tamanho'

	const prices = (item as IMaterial).PriceByQuantity

	const Price = getPrice(prices, amount)

	const isActionPrice = findCheaperPriceAction(item)

	const pointsRedeemed = !!Number(item.ScoreRate) ? Number(item.ScoreRate) : 0

	const isARescueProduct = !!item.rescueProduct
		? ICartItemTypeEnum.REDEMPTION
		: ICartItemTypeEnum.NORMAL

	if (
		item?.priceAction &&
		item.priceAction.length &&
		isActionPrice.priceAction?.length
	) {
		const action = isActionPrice.priceAction[0] as IPriceAction
		const price = Number(action.maxAmount)
		const priceByQtd = [
			{
				Price: price,
				Quantity: Number(amount)
			}
		]

		return {
			MaterialID: item.MaterialID,
			UnitMeasureSale: item.UnitMeasureSale,
			Price: getPrice(priceByQtd, amount),
			Size,
			priceByUnit: price,
			ImageUrl: item.ImageUrl,
			StockPosition: parseInt(isActionPrice.StockPosition),
			Description: action.description,
			PackType: packType,
			OrderQuantity: amount,
			PriceByQuantity: priceByQtd,
			promotionID: action.promotionID,
			promotionShortName: isActionPrice.priceAction[0].promotionShortName,
			promotionType: isActionPrice.priceAction[0].promotionType,
			operationTypeDescription: OperationTypeDescription.Ação,
			ScoreValue: item.ScoreValue,
			ScoreRate: item.ScoreRate,
			typeItem: item.typeItem,
			rescueProduct: item.rescueProduct,
		}
	}

	const product: ICartItem = {
		...basicKeys,
		PriceByQuantity: item.PriceByQuantity,
		ImageUrl: item.ImageUrl,
		OrderQuantity: amount,
		Size,
		Price,
		pointsRedeemed,
		StockPosition: Number(StockPosition),
		Description,
		PackType: packType,
		ScoreValue: item.ScoreValue,
		ScoreRate: item.ScoreRate,
		typeItem: isARescueProduct,
		rescueProduct: item.rescueProduct
	}

	return product as ICartItem
}

function extractCartItemFromCombo(item: ICombo, amount: number): ICartItem {
	const priceAndQuantity = item.TradePromotionItens.results
		.filter((x) => x.tradeType === TradeType.Z4VN)
		.map((item) => {
			return {
				Price: Number(item.price),
				Quantity: Number(item.quantity)
			}
		})

	const promotionDescription = String(
		item?.TradePromotionItens?.results?.map((item) => item.description)
	)
	const promotionUnitMeasureSale = String(
		item?.TradePromotionItens?.results?.map((item) => item.unitMeasureSale)
	)
	const promotionOlderPrice = Number(
		item?.TradePromotionItens?.results?.map((item) => item.minAmount)
	)
	const promotionQuantityOrder = item.maxQuantityTradePromotion

	return {
		Description: item.description,
		ImageUrl: item.productImageURL,
		MaterialID: item.promotionID,
		OrderQuantity: amount,
		Price: item.comboPriceTotal * amount,
		PriceByQuantity: priceAndQuantity,
		Size: item.promotionType === 2 ? promotionDescription : 'Combo',
		StockPosition:
			item.promotionType === 2
				? promotionQuantityOrder
				: item.maxQuantityOrder,
		UnitMeasureSale:
			item.promotionType === 2 ? promotionUnitMeasureSale : 'Combo',
		PackType: '',
		MaxQuantityTradePromotion: promotionQuantityOrder,
		operationTypeDescription: OperationTypeDescription.Combo,
		PromotionOlderPrice: promotionOlderPrice,
		products: item.TradePromotionItens.results.map((item) => {
			return {
				price: Number(item.price),
				productID: item.productID,
				unitMeasureSale: item.unitMeasureSale,
				tradeType: item.tradeType,
				quantity: item.quantity,
				numeratorConversion: item.numeratorConversion
			} as unknown as ICartComboProduct
		}),
		promotionID: item.promotionID,
		promotionShortName: item.promotionShortName,
		promotionType: item.promotionType,
		tradePrice: item.tradePrice,
		total: item.total,
		actionPriceFinalSalesValue: item.actionPriceFinalSalesValue,
		actionPriceUndiscountedSalesValue: item.actionPriceUndiscountedSalesValue
	}
}

export const extractCartItem = (
	item: IMaterial | ICombo,
	amount: number
): ICartItem => {
	const isProduct = Object.prototype.hasOwnProperty.call(item, 'MaterialID')

	if (isProduct)
		return extractCartItemFromIMaterial(item as IMaterial, amount)

	return extractCartItemFromCombo(item as ICombo, amount)
}

export const getPrice = (
	prices: Maybe<Array<IPriceByQuantity>>,
	amount: number
): number => {
	if (!prices?.length) return 0

	const isLess = ({ Quantity: q }: IPriceByQuantity) => Number(q) <= amount

	const formatPrice = pipe(prop('Price'), Number)

	const validPrices = prices.filter(isLess).map(formatPrice)

	const priceToUse = Math.min(...validPrices)

	if (priceToUse === Infinity) return 0

	const price = priceToUse * amount || 0

	return price
}

export const getPriceCombo = (
	prices: Maybe<Array<IPriceByQuantity>>,
	amount: number
): number => {
	if (!prices?.length) return 0

	return (
		prices.reduce((acc, currentValue) => {
			const maxAmount = Number(currentValue.Price) || 0
			const quantity = Number(currentValue.Quantity) || 0
			const productTotal = maxAmount * quantity

			return acc + productTotal
		}, 0) * amount
	)
}

export const getTotalPoints = (points: number, amount: number) => {
	return points * amount
}

export function isComboAmountAboveStock(
	selectedCombo: ICombo | undefined,
	productAmount: number
) {
	if (!selectedCombo) return { amountAboveStock: true, stock: 0 }
	const { maxQuantityOrder, maxQuantityPeriod } = selectedCombo
	const stock = Math.round(Number(maxQuantityOrder))

	if (
		maxQuantityOrder > 0 &&
		maxQuantityPeriod > 0 &&
		productAmount > stock
	) {
		return { amountAboveStock: true, stock }
	}

	return { amountAboveStock: false, stock }
}

export function checkConflictProductAndComboFromCartWithNewItem(
	cart: IStoreCart,
	newItem: ICartItem
) {
	let hasConflictProductAndComboProduct = false
	if (isCombo(newItem.promotionType)) {
		cart.items.forEach((itemFromCart) => {
			if (isCombo(itemFromCart.promotionType)) {
				// Compara Combo x Combo Adicionado
				itemFromCart.products?.forEach((comboProductFromCart) => {
					const hasDuplicatedProductAndComboProduct =
						newItem.products?.some(
							(comboProduct) =>
								comboProduct.productID ===
								comboProductFromCart.productID
						)
					if (
						hasDuplicatedProductAndComboProduct &&
						newItem.promotionID !== itemFromCart.promotionID
					) {
						hasConflictProductAndComboProduct = true
					}
				})
			} else {
				// Compara Produto x Combo Adicionado
				const hasDuplicatedProductAndComboProduct =
					newItem.products?.some(
						(comboProduct) =>
							comboProduct.productID === itemFromCart.MaterialID
					)
				if (hasDuplicatedProductAndComboProduct) {
					hasConflictProductAndComboProduct = true
				}
			}
		})
	} else {
		cart.items.forEach((itemFromCart) => {
			if (isCombo(itemFromCart.promotionType)) {
				// Compara Combo x Produto Adicionado
				itemFromCart.products?.forEach((comboProductFromCart) => {
					if (newItem.MaterialID === comboProductFromCart.productID) {
						hasConflictProductAndComboProduct = true
					}
				})
			}
			// Não necessário checar Produto x Produto Adicionado, sem conflito
		})
	}

	return hasConflictProductAndComboProduct
}

export function checkingIfAnyChallengeItemConflictWithCombos(
	setAllCartsState: (value: React.SetStateAction<IStoreCart[]>) => void,
	carts: IStoreCart[],
	cartChallengeItems: ICartChallenge[]
) {
	const currentCarts = deepCopy(carts)

	if (!cartChallengeItems.length) {
		setAllCartsState(currentCarts)
		return
	}

	if (!currentCarts.length) {
		const salesOrderID = randomStringGenerator() as string

		const orderChallengeItems: IStoreCart = {
			items: [],
			salesOrderID: salesOrderID,
			itemsChallenge: []
		}

		cartChallengeItems.forEach((cartChallengeItem) => {
			const challengeAddedProducts = deepCopy(
				cartChallengeItem.addedProducts
			)

			challengeAddedProducts.forEach((item) => {
				orderChallengeItems.itemsChallenge?.push({
					...item,
					challengeID: cartChallengeItem.challengeId
				})
			})
		})

		setAllCartsState([orderChallengeItems])
		return
	}

	const newCarts: IStoreCart[] = []

	cartChallengeItems.forEach((cartChallengeItem) => {
		const cartWithConflicts = cartChallengeItem.addedProducts.some(
			(product) =>
				currentCarts.some((cart) =>
					checkConflictProductAndComboFromCartWithNewItem(
						cart,
						product
					)
				)
		)

		const challengesProducts: ICartItem[] =
			cartChallengeItem.addedProducts.map((item) => ({
				...item,
				challengeID: cartChallengeItem.challengeId
			}))

		if (cartWithConflicts) {
			const salesOrderID = randomStringGenerator() as string

			const newCart: IStoreCart = {
				items: [],
				salesOrderID: salesOrderID,
				itemsChallenge: challengesProducts
			}

			newCarts.push(newCart)
			return
		}

		const currentCart = currentCarts[0]

		if (currentCart.itemsChallenge?.length) {
			currentCart.itemsChallenge?.push(...challengesProducts)
			return
		}
		currentCart.itemsChallenge = [...challengesProducts]
	})
	const allCartsUpdated = [...newCarts, ...currentCarts]
	setAllCartsState(allCartsUpdated)
}
