import React, { useState, useEffect } from 'react'
import { IToastSucess } from './toast-sucess.types'
import * as S from './toast-sucess.styles'

function TopModalSucess({
	isOpen,
	sucessToastMessage,
	handleHideModal
}: IToastSucess) {
	const [shouldRender, setShouldRender] = useState(isOpen)

	useEffect(() => {
		if (isOpen) {
			setShouldRender(true)

			const timer = setTimeout(() => {
				handleHideModal()
			}, 10000)

			return () => clearTimeout(timer)
		} else {
			const timer = setTimeout(() => {
				setShouldRender(false)
			}, 10000)

			return () => clearTimeout(timer)
		}
	}, [isOpen, handleHideModal])

	const handleAnimationEnd = () => {
		if (!isOpen) {
			setShouldRender(false)
		}
	}

	if (!shouldRender) return null

	return (
		<S.WrapperTostSucessModal
			fade={isOpen}
			onAnimationEnd={handleAnimationEnd}>
			<S.DoneIconStyles />
			<p>{sucessToastMessage}</p>
		</S.WrapperTostSucessModal>
	)
}

export default TopModalSucess
