import styled from 'styled-components'
import { MaintenanceTitle } from './status-page.types'

export const MessageContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: auto;
	width: 100%;
	margin: ${(props) => props.theme.spacingOptions.SM}px 0;
	gap: ${(props) => props.theme.spacingOptions.LG}px;
`
export const CircleIcon = styled.div<MaintenanceTitle>`
	width: 82px;
	height: 82px;
	background-color: ${(props) =>
		(props.type === 'system' && props.theme.palette.error.main) ||
		(props.type === 'construction' && props.theme.palette.primary.main) ||
		(props.type === 'error' && props.theme.palette.error.main) ||
		(props.type === 'warning' && props.theme.palette.warning.main) ||
		(props.type === 'alert' && props.theme.palette.extras.alert.main) ||
		(props.type === 'info' && props.theme.palette.primaryWhite.main)};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: ${(props) => props.theme.spacingOptions.SM}px;
	svg {
		color: ${(props) =>
			props.type !== 'info' && props.theme.palette.primaryWhite.main};
		font-size: 42px;
	}
`

export const MessageTitle = styled.h1<MaintenanceTitle>`
	color: ${(props) =>
		(props.type === 'system' && props.theme.palette.error.main) ||
		(props.type === 'construction' && props.theme.palette.primary.main) ||
		(props.type === 'error' && props.theme.palette.error.main) ||
		props.theme.palette.black.main};
	text-align: center;
	font-style: normal;
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	padding: ${(props) => props.theme.spacingOptions.MD}px, 0px;
`

export const MessageContent = styled.div`
	font-weight: ${(props) => props.theme.font.size.DISPLAY_SM};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	text-align: center;
	width:327px;
`
export const HelpInfo = styled.div`
	display: flex;
	width: auto;
	align-items: center;
	align-content: center;
	padding: ${(props) => props.theme.spacingOptions.SM}px;
	margin-top: ${(props) => props.theme.spacingOptions.XLG}px;
	background: ${(props) => props.theme.palette.background.default};
	border-radius: ${(props) => props.theme.spacingOptions.XXSM}px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	svg {
		color: ${(props) => props.theme.palette.gray.main};
		margin-right: ${(props) => props.theme.spacingOptions.SM}px;
	}
	.wrapper-help {
		width: 100vh;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		h2 {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			p {
				color: ${(props) => props.theme.palette.primary.main};
				font-weight: ${(props) => props.theme.font.weight.bold};
				:hover {
					cursor: pointer;
				}
			}
		}
	}
`
