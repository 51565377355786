import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const Container = styled.div`
	background-color: ${colors.fifthGray};
`

export const PageHeader = styled.div`
	margin: 32px 0;

	a.go-back-button {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			height: 16px;
			width: 16px;
		}

		span {
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			color: ${colors.red};
			margin-left: 8px;
		}
	}

	h1.pg-title {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${colors.secondGrey};
		margin-top: 24px;
	}
`

export const PageSession = styled.section`
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid ${colors.lightGrey};

	.pg-subtitle {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		color: ${colors.secondGrey};
	}
`

export const ButtonActionsGroup = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	width: 100%;

	.button-delete {
		border: none;
		background: none;

		margin-right: 32px;

		span {
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			font-weight: ${(props) => props.theme.font.weight.bold};
			color: ${colors.secondGrey};
		}

		&:hover {
			cursor: pointer;
		}
	}
`

export const FiltersRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 16px;

	&.sales-hierarchy-filters {
		& > div:nth-child(1) {
			width: 30%;
		}
		& > div:nth-child(2) {
			width: 30%;
			padding: 0 16px;
		}
		& > div:nth-child(3) {
			width: 20%;
			padding-right: 16px;
		}
		& > div:nth-child(4) {
			width: 20%;
		}
	}

	&.other-filters {
		& > div:nth-child(1) {
			width: 25%;
		}
		& > div:nth-child(2) {
			width: 40%;
			padding: 0 16px;
		}
		& > div:nth-child(3) {
			width: 35%;
		}
	}

	svg {
		color: ${(props) => props.theme.palette.black.main};
	}
`
