import React from 'react'
import { ISidebarSuccess } from './sidebar-success.types'
import * as S from './sidebar-success.styles';
import { TypeButtonEnum } from '../../../../../../components/buttons/button/button-default.types';
import StatusIcon from '../../../../../../components/StatusIcon/StatusIcon.component';
import { TypeIconEnum } from '../../../../../../components/StatusIcon/StatusIcon.types';
import DoneIcon from '@mui/icons-material/Done'
function SidebarSucess({ handleModal }: ISidebarSuccess) {
	return (
		<S.WrapperSuccessModal>
			<div className='main-modal'>

				<StatusIcon ComponentIcon={DoneIcon} text='Avaliação enviada!' type={TypeIconEnum.SUCCESS}/>

				<p>Agradecemos a sua avaliação. Estamos trabalhando para melhorar a sua experiência</p>

				<S.ButtonStyled
					type={TypeButtonEnum.PRIMARY}
					buttonText="Entendi"
					onClick={() => { handleModal(false); }}
					idElement="button-success"
				/>
			</div>
		</S.WrapperSuccessModal>
	)
}

export default SidebarSucess
