import { removeEspecialCharacters } from "../../../shared/utils";
import { getUserDataMasked } from "../services/login.services";

export function determineDocumentType(unformatedTextDoc: string): string | null {
  // Lógica para determinar o tipo de documento
  const cnpjRegex = /^\d{14}$/;
  const cpfRegex = /^\d{11}$/;

  if (cnpjRegex.test(unformatedTextDoc)) {
    return 'cnpj';
  } else if (cpfRegex.test(unformatedTextDoc)) {
    return 'cpf';
  }

  // Adicione mais lógica conforme necessário para outros tipos de documentos

  return null; // Retorna null se o tipo de documento não puder ser determinado
}

export async function handleSetContextUserData(unformatedTextDoc: string) {
  const typeDocument = determineDocumentType(unformatedTextDoc);

  const userData = await getUserDataMasked({
    [typeDocument === 'cnpj' ? 'companyFiscalID' : 'personalFiscalID']: removeEspecialCharacters(unformatedTextDoc),
    platform: 'web',
  });

  return userData;
}
