import React, { Fragment } from 'react'

import ProductPlaceholder from '../../../assets/images/placeholder-product.svg'
import { TradeType } from '../../../shared/interfaces/combo'
import {
	characteristicTypeEnum,
	ICharacteristics,
	IPriceByQuantity
} from '../../../shared/interfaces/material'
import { parseItemPrice, toMoney } from '../../../shared/utils/money'

import { formatEarnPointsLabel } from '../../../shared/utils/points'
import * as S from './styles'
import { IProps } from './types'

export function parseCharType(type: string): string {
	return characteristicTypeEnum[type]
}

function ProductInfo({
	type,
	productItem,
	productAmount,
	comboItem: combo,
	thisIsARescueProduct
}: IProps) {
	const totalAmount = productAmount

	function omitSomeChar(x: ICharacteristics) {
		return true
		// x.Description !== '-'
		// x.Type !== `Package` &&
		// x.Type !== `PackageType` &&
		// x.Type !== 'UnitBarcode' &&
		// x.Type !== 'PackageBarcode'
	}

	function renderPrice() {
		if (thisIsARescueProduct) {
			return (
				<Fragment>
					<S.PriceActionInfoContainer
						thisIsARescueProduct={thisIsARescueProduct}>
						<S.PriceInfoContainer>
							<div className="point-value">
								{productItem.PriceByQuantity.map((item) => (
									<h4
										key={item.Quantity}
										className="unit-measure-sale">
										{`${item.Quantity} ${productItem.UnitMeasureSale}`}
									</h4>
								))}
								<p className="points">
									{productItem.ScoreRate}
								</p>
							</div>
						</S.PriceInfoContainer>
					</S.PriceActionInfoContainer>
				</Fragment>
			)
		}

		const pricesList = productItem.PriceByQuantity

		const highlight = (item: IPriceByQuantity, i: number) =>
			totalAmount >= Number(item.Quantity) &&
			totalAmount < Number(pricesList[i + 1]?.Quantity || 10000)

		return (
			<S.PriceInfoContainer>
				{pricesList.length && productItem.StockPosition
					? pricesList.map((item, i) => (
							<S.PriceInfoItem
								key={i}
								highlight={highlight(item, i)}>
								<div>
									<h4 className="price-title">
										{`${item.Quantity} ${productItem.UnitMeasureSale}.`}
									</h4>
									<p className="price-value">
										{parseItemPrice(item.Price)}
									</p>
									<p className="price-desc">
										{Number(item.Quantity) > 1
											? 'Cada'
											: ''}
									</p>
								</div>
								<S.EarnsPointsLabel>
									{formatEarnPointsLabel(
										item.Quantity,
										item.Price,
										productItem.ScoreValue
									)}
								</S.EarnsPointsLabel>
							</S.PriceInfoItem>
					  ))
					: null}
				<S.UnitPriceInfoWrapper>
					<S.UnitPriceLabel>
						{'PREÇO / ' + productItem.typeOfContainer}
					</S.UnitPriceLabel>
					<S.UnitPriceValue>
						{parseItemPrice(
							Number(productItem.PricePerUnit).toFixed(2)
						)}
					</S.UnitPriceValue>
				</S.UnitPriceInfoWrapper>
			</S.PriceInfoContainer>
		)
	}

	function renderPriceAction() {
		if (!combo) return null
		return (
			<div className="combo">
				{combo.TradePromotionItens?.results.map((comboItem, index) => (
					<div className="combo-box" key={index}>
						<figure>
							<img
								src={
									comboItem.productImageURL ||
									ProductPlaceholder
								}
								alt={comboItem.description}
							/>
						</figure>

						<div className="combo-info">
							{comboItem.tradeType === TradeType.Z4BN && (
								<span className="bonus">
									<p>BONIFICAÇÃO</p>
								</span>
							)}
							<p className="combo-description">
								{comboItem.productID} - {comboItem.description}
							</p>
							<p className="combo-unit">
								{comboItem.unitMeasureSale}
							</p>
							<S.WrapperQuantity>
								<p className="combo-unit">
									QUANTIDADE: {comboItem.quantity}
								</p>
							</S.WrapperQuantity>
						</div>
						<p
							className={
								comboItem.tradeType === TradeType.Z4BN
									? 'old-price'
									: 'price'
							}>
							{toMoney(Number(comboItem.priceTotal))}
						</p>
					</div>
				))}
			</div>
		)
	}

	function renderProductDescription() {
		return (
			<div className="info-list">
				{productItem?.Characteristics?.length
					? productItem.Characteristics.map((characteristicItem, i) =>
							omitSomeChar(characteristicItem) ? (
								<div key={i} className="info-item">
									<h4 className="info-title">
										{parseCharType(characteristicItem.Type)}
									</h4>
									<p className="info-value">
										{characteristicItem.Description}
									</p>
								</div>
							) : null
					  )
					: null}
				<div className="info-item">
					<h4 className="info-title">Estoque</h4>
					<p className="info-value">
						{productItem.StockPosition &&
							parseInt(productItem.StockPosition)}
					</p>
				</div>
			</div>
		)
	}

	return (
		<div className="product-info">
			{type === 'DESCRIPTION' && renderProductDescription()}
			{type === 'PRICE' && renderPrice()}
			{type === 'COMBO' && renderPriceAction()}
		</div>
	)
}

export default ProductInfo
