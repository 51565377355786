import styled from 'styled-components'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { StylesActionsButtons } from './actions-buttons.types'

export const StyledIncrementButton = styled(
	AddCircleIcon
)<StylesActionsButtons>`
	color: ${(props) =>
		props.isDisabled
			? props.theme.palette.lightGray.main
			: props.theme.palette.primary.main};
	&:hover {
		cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
	}
`

export const StyledDecrementButton = styled(
	RemoveCircleIcon
)<StylesActionsButtons>`
	color: ${(props) =>
		props.isDisabled
			? props.theme.palette.lightGray.main
			: props.theme.palette.black.main};
	&:hover {
		cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
	}
`
