import { createElement, useCallback, useEffect, useMemo, useState } from 'react'
import {
	endOfDay,
	endOfToday,
	format,
	startOfDay,
	startOfMonth
} from 'date-fns'

import ClientsReport from './view'
import { INITIAL_SORT, ITableData, ITableNavProps, IViewProps } from './types'
import { ISelectOption } from '../../shared/utils/SelectOptions'
import {
	IReport,
	fetchTotalReport,
	getResalesFilter,
	useCustomersPage
} from '../../shared/services/dashboard.service'
import { defaultSort } from '../../shared/utils/array'
import {
	FORMAT_BIRTH_BIRTH_DATE,
	friendlyFormatDate,
	parseFormatOnlyDate
} from '../../shared/utils/date'
import { pt } from 'date-fns/locale'
import { IAcessorAndOrder } from '../../components/Table/types'

function ClientsReportContainer(): JSX.Element {
	const ITEMS_PER_PAGE = 10
	const ALL_SELECT = useMemo(() => {
		return { label: 'Selecionar todos', value: '*' }
	}, [])
	const [loading, setLoading] = useState(false)
	const [loadingFilter, setLoadingFilter] = useState(false)
	const [resaleList, setResaleList] = useState<ISelectOption[]>([])
	const [selectedResales, setSelectedResales] = useState<ISelectOption[]>([])
	const [selectedDates, setSelectedDates] = useState<any>({
		from: startOfMonth(new Date()),
		to: endOfToday()
	})
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [, setBtnFilterClicked] = useState(true)
	const [sortBy, setSortBy] = useState<IAcessorAndOrder>(INITIAL_SORT)
	const [totalSalesOrder, setTotalSalesOrder] = useState(0)

	const [customersPage, setCustomersPage] = useState({
		page: 1,
		numberOfPages: 0,
		totalDocs: 0
	})

	const { status, data } = useCustomersPage({
		pageIndex: customersPage.page,
		maxItemsPerPage: ITEMS_PER_PAGE,
		finalDate: selectedDates.to ? formatDate(selectedDates.to) : '',
		initialDate: selectedDates.from ? formatDate(selectedDates.from) : '',
		resalesList: renderOptionsSelected(),
		order: sortBy.order,
		sort: sortBy.acessor
	})

	function handlerSortColumn(acessor: string, order: number): any {
		if (order === 0) {
			setSortBy(INITIAL_SORT)
			setBtnFilterClicked(true)
			return
		}

		setSortBy({
			acessor,
			order
		})
		setBtnFilterClicked(true)
	}

	function formatDate(date: Date) {
		return format(date, FORMAT_BIRTH_BIRTH_DATE, {
			locale: pt
		})
	}

	function renderOptionsSelected() {
		if (selectedResales.length === 0) return []
		if (selectedResales.find((options) => options.value === '*')) return []

		return selectedResales
			.filter((options) => options.value !== '*')
			.map((select) => select.value)
	}

	const handleSelectDates = useCallback((value: any): void => {
		if (!value.from || !value.to) return
		setSelectedDates({
			from: startOfDay(value.from as Date),
			to: endOfDay(value.to as Date)
		})
	}, [])

	function tableElementGenerator(row: IReport): ITableData {
		return {
			customerId: row.customerId || '-',
			salesOrgId: row.salesOrgId || '-',
			virtualCustomerDays: friendlyFormatDate(row.registerDate) || '-',
			customerName: row.customerName || '-',
			quantitySalesOrder: row.quantitySalesOrder || 0,
			registerDate: row.registerDate
				? parseFormatOnlyDate(row.registerDate)
				: '-'
		}
	}

	function fetchFilterOptions() {
		;(async () => {
			try {
				setLoadingFilter(true)
				const response = await getResalesFilter()
				const options = response
					.sort(defaultSort('SalesOrganizationID'))
					.map((x) => {
						return {
							label: `${x.SalesOrganizationID} - ${x.Name}`,
							value: x.SalesOrganizationID
						}
					})
				setResaleList([ALL_SELECT, ...options])
				setSelectedResales([ALL_SELECT, ...options])
			} catch (err) {
				console.error({ err })
			} finally {
				setLoadingFilter(false)
			}
		})()
	}

	function fetchTotals() {
		;(async () => {
			try {
				const getTotal = await fetchTotalReport({
					maxItemsPerPage: 10,
					pageIndex: 1,
					finalDate: selectedDates.to
						? formatDate(selectedDates.to)
						: '',
					initialDate: selectedDates.from
						? formatDate(selectedDates.from)
						: '',
					resalesList: renderOptionsSelected()
				})

				setCustomersPage({
					...customersPage,
					numberOfPages: Math.ceil(
						getTotal.totalCustomers / ITEMS_PER_PAGE
					),
					totalDocs: getTotal.totalCustomers
				})

				setTotalSalesOrder(getTotal.totalCustomersWithSalesOrder)
			} catch (err) {
				console.error({ err })
			}
		})()
	}

	const columns = useMemo(
		() => [
			{
				Header: 'Código PDV',
				accessor: 'customerId',
				sortType: 'basic'
			},
			{
				Header: 'Revenda',
				accessor: 'salesOrgId',
				sortType: 'basic'
			},
			{
				Header: 'Cliente',
				accessor: 'customerName',
				sortType: 'basic'
			},
			{
				Header: 'Quantidade de Pedidos',
				accessor: 'quantitySalesOrder',
				disableSortBy: 'basic'
			},
			{
				Header: 'Data de Cadastro',
				accessor: 'registerDate',
				sortType: 'basic'
			},
			{
				Header: 'Cliente Virtual',
				accessor: 'virtualCustomerDays',
				disableSortBy: false
			}
		],

		[]
	)

	function handleClickFilter() {
		setCustomersPage((state) => ({ ...state, page: 1 }))
		setBtnFilterClicked(true)

		setTimeout(() => {
			setBtnFilterClicked(false)
		}, 10000)
		// refetch()
	}

	function fetchData() {
		try {
			setLoading(true)

			if (!data?.docs.length) {
				setTableData([])
				return
			}

			const table: ITableData[] = data.docs.map(tableElementGenerator)
			setTableData(table)

			// setCustomersPage({
			// 	...customersPage,

			// })

			setBtnFilterClicked(false)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function selectAll() {
		setSelectedResales(resaleList)
	}

	function diselectedAll() {
		setSelectedResales([])
	}

	function handleChangeFilter(value: ISelectOption[], event: any) {
		if (event.action === 'select-option' && event.option.value === '*') {
			selectAll()
			return
		}

		if (event.action === 'deselect-option' && event.option.value === '*') {
			diselectedAll()
			return
		}

		setSelectedResales(value.filter((options) => options.value !== '*'))
	}

	function goToPage(pageIndex: number) {
		setCustomersPage((state) => ({ ...state, page: pageIndex + 1 }))
		setBtnFilterClicked(true)
	}

	const navProps: ITableNavProps = {
		nextPage: (pageIndex) => goToPage(pageIndex),
		previousPage: (pageIndex) => goToPage(pageIndex),
		gotoPage: (pageIndex) => goToPage(pageIndex),
		pageCount: customersPage.numberOfPages,
		pageIndex: customersPage.page - 1,
		totalDocs: customersPage.totalDocs
	}

	useEffect(fetchData, [data])
	useEffect(fetchFilterOptions, [ALL_SELECT])
	useEffect(fetchTotals, [data])

	const viewProps: IViewProps = {
		loading,
		columns,
		tableData,
		resaleList,
		selectedResales,
		handleChangeFilter,
		handleSelectDates,
		selectedDates,
		loadingFilter,
		navProps,
		itemsPerPage: ITEMS_PER_PAGE,
		handleClickFilter,
		status,
		handlerSortColumn,
		sortBy,
		totalSalesOrder
	}

	return createElement(ClientsReport, viewProps)
}

export default ClientsReportContainer
