import { ISelect } from '../../components/SelectPrimary/types'
import { ICartItem } from '../../shared/interfaces/cart'
import { Maybe, Nullable } from '../../shared/interfaces/common'
import { IFormattedPaymentCondition } from '../../shared/interfaces/customer'
import { IPaymentMethodNameEnum } from '../../shared/interfaces/Payments'
import { IStoreCart } from '../../shared/interfaces/store'
import { PaymentOptions } from './Payment/types'
import { OrderDetailsModel } from './util'

export default interface IProps {}
export interface IViewProps {
	isLoading: boolean
	handleBack: () => void
	handleChangePaymentCondition: (item: IFormattedPaymentCondition) => void
	handleFinalization: () => void
	paymentInfo: PaymentInfo
	dateToDelivery: ISelect | null
	handleSelectThree(selectedValue: Maybe<Nullable<ISelect>>, _: any): void
	orderDetails: OrderDetailsModel
	condition?: IFormattedPaymentCondition
	cartRedemptionItems: ICartItem[]
	// paymentSteps: IPaymentSteps
	totalPrice: number
	// splittedOrderItems: ISplittedOrders[]
	// isCheckingCombos: boolean
	paymentMethod: IPaymentMethodNameEnum[]
	handleChangeCreditCard: (id: string, value: string) => void
	creditCard: ICreditCard
	fieldsErros: IErrorState
	enableButton: boolean
	handlerSelectPaymentMethod: (
		option: PaymentOptions[],
		buttonConfirm: boolean
	) => void
	handlerChangeStep: (step: number) => void
	paymentSteps: IPaymentSteps
	stepCurrent: number
	payment: number
	allCartsState: IStoreCart[]
	paymentMethodsAtDelivery: IFormattedPaymentCondition[]
}

export interface ICreditCard {
	cardNumber: string
	cpf: string
	cvv: string
	dueDate: string
	ownerName: string
	installmentCount?: string
}
export interface IPaymentSteps {
	step1: IPaymentStep
	step2: IPaymentStep
	step3: IPaymentStep
	buttons: IPaymentStepsButtons
}

export interface IPaymentStepsButtons {
	confirm: boolean
}

export interface IPaymentStep {
	active: boolean
	options?: IPaymentStepOptions[]
}

export interface IPaymentStepOptions {
	index: PaymentOptions
	active: boolean
}

export interface IWorkingHours {
	open: string
	close: string
}

export type WorkingHours = IWorkingHours | undefined

export interface IPaymentInfo {
	paymentMethod: string
	tax: number
	total: number
}

export type PaymentInfo = IPaymentInfo | undefined
export interface IViewItem extends ICartItem {}

export interface ISplittedOrders {
	purchaseItems: ICartItem[]
	redemptionItems: ICartItem[]
}

export interface IErrorState {
	cardNumberValueError: string
	cardSecurityCodeValueError: string
	namePrintedOnCardValueError: string
	validityCreditCardValueError: string
	documentNumberError: string
}

export enum ErrorsActions {
	CARD_NUMBER = 'CARD_NUMBER',
	VALIDITY_CREDIT_CARD = 'VALIDITY_CREDIT_CARD',
	CARD_SECURITY_CODE = 'CARD_SECURITY_CODE',
	NAME_PRINTED_ON_CARD = 'NAME_PRINTED_ON_CARD',
	DOCUMENT_NUMBER = 'DOCUMENT_NUMBER'
}

export interface IErrorsAction {
	type: ErrorsActions
	payload: any
}
