import AlarmOnIcon from '@mui/icons-material/AlarmOn'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import React from 'react'
import {
	NotificationIconWrapper,
	NotificationText,
	NotificationWrapper
} from './styles'

export interface NotificationProps {
	message: string
	type: 'success' | 'error' | 'info'
	isPortalAdmin?: boolean
	isComboFlex?: boolean
}

export function Notification({
	message,
	type,
	isPortalAdmin,
	isComboFlex = false
}: NotificationProps) {
	const changeIconByType = {
		success: <CheckCircleOutlinedIcon />,
		error: <AlarmOnIcon />,
		info: <ErrorOutlineIcon />
	}

	return (
		<NotificationWrapper
			type={type}
			isPortalAdmin={isPortalAdmin}
			isComboFlex={isComboFlex}>
			<NotificationIconWrapper type={type}>
				{changeIconByType[type]}
			</NotificationIconWrapper>
			<NotificationText>{message}</NotificationText>
		</NotificationWrapper>
	)
}
