import styled from 'styled-components'

import Autocomplete from '@mui/material/Autocomplete'

export const AutoCompleteWrapper = styled(Autocomplete)`
	width: 359px;
	height: 54px;
	border-color: #979e97;

	.MuiFormControl-root {
		display: row;
	}

	.MuiInputBase-input {
		font-size: 14;
	}

	.css-1q60rmi-MuiAutocomplete-endAdornment{
		top: 0;
	}

	fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
		border-color: #979e97;
	}

	label,
	label.Mui-focused {
		color: #979e97;
	}

	.css-1q60rmi-MuiAutocomplete-endAdornment {
		position: relative;
	}
`
