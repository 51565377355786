import cogoToast from 'cogo-toast'
import React, { ChangeEvent } from 'react'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../hooks/useTypedSelector'

import { ICartItem, ICartItemTypeEnum } from '../../interfaces/cart'
import { Creators as CartActions } from '../../store/ducks/cart'
import {
	CartRedemptionItemsActionEnum,
	Creators as CartRedemptionsItemsActions
} from '../../store/ducks/cartRedemptionItem'

import { Creators as CartChallengeItemsActions } from '../../store/ducks/cartChallengeItems'

import { CogoPositions } from '../../utils/toaster'
import CartItem from './CartItem'
import { IProps } from './types'

function CartTable(props: IProps) {
	const {
		min = 1,
		tableData,
		max = 9999,
		cartChallengeItem,
		isChallengeItems,
		isEditable = true,
		totalPointsRedeemed,
		isChallengeMix = false
	} = props
	const {
		removeItem: removeNormalItem,
		// updateItem: updateNormalItem,
		addItem
	} = CartActions
	const { removeRedemptionItem, updateRedemptionItem } =
		CartRedemptionsItemsActions
	const { pointsProgramStatus } = useTypedSelector(['pointsProgramStatus'])

	const dispatch = useDispatch()

	const handleRemove = (item: ICartItem) => {
		if (
			item.typeItem !== ICartItemTypeEnum.REDEMPTION &&
			item.typeItem !== ICartItemTypeEnum.CHALLENGE_ITEM
		) {
			dispatch(removeNormalItem(item))
		} else if (
			item.typeItem === ICartItemTypeEnum.CHALLENGE_ITEM &&
			cartChallengeItem &&
			isChallengeItems
		) {
			dispatch(
				CartChallengeItemsActions.removeChallengeItem(
					cartChallengeItem,
					item
				)
			)
		} else if (
			item.typeItem === ICartItemTypeEnum.CHALLENGE_ITEM &&
			cartChallengeItem &&
			isChallengeItems
		) {
			dispatch(
				CartChallengeItemsActions.removeChallengeItem(
					cartChallengeItem,
					item
				)
			)
		} else {
			dispatch(removeRedemptionItem(item))
		}
	}

	const handleIncrementOrderQty = (item: ICartItem) => () => {
		const { OrderQuantity } = item

		if (isNaN(OrderQuantity)) return

		if (OrderQuantity + 1 > max) {
			cogoToast.error(
				`Quantidade acima de ${max}`,
				CogoPositions['top-right']
			)
			return
		}
		if (OrderQuantity + 1 > item.StockPosition) {
			cogoToast.error(
				`Quantidade acima do estoque de ${item.StockPosition}`,
				CogoPositions['top-right']
			)
			return
		}
		item.OrderQuantity = OrderQuantity + 1

		if (
			item.typeItem !== ICartItemTypeEnum.REDEMPTION &&
			item.typeItem !== ICartItemTypeEnum.CHALLENGE_ITEM
		) {
			dispatch(addItem(item, 'UPDATE'))
		} else if (
			item.typeItem === ICartItemTypeEnum.CHALLENGE_ITEM &&
			cartChallengeItem &&
			isChallengeItems
		) {
			dispatch(
				CartChallengeItemsActions.updateChallengeItem(
					cartChallengeItem,
					item
				)
			)
		} else {
			dispatch(
				updateRedemptionItem(
					item,
					CartRedemptionItemsActionEnum.UPDATE_REDEMPTION_ITEM
				)
			)
		}
	}

	const handleDecrementOrderQty = (item: ICartItem) => () => {
		const { OrderQuantity } = item
		if (isNaN(OrderQuantity)) return

		const decrementedQty =
			OrderQuantity > min ? OrderQuantity - 1 : OrderQuantity

		item.OrderQuantity = decrementedQty

		if (
			item.typeItem !== ICartItemTypeEnum.REDEMPTION &&
			item.typeItem !== ICartItemTypeEnum.CHALLENGE_ITEM
		) {
			dispatch(addItem(item, 'UPDATE'))
		} else if (
			item.typeItem === ICartItemTypeEnum.CHALLENGE_ITEM &&
			cartChallengeItem &&
			isChallengeItems
		) {
			dispatch(
				CartChallengeItemsActions.updateChallengeItem(
					cartChallengeItem,
					item
				)
			)
		} else {
			dispatch(
				updateRedemptionItem(
					item,
					CartRedemptionItemsActionEnum.UPDATE_REDEMPTION_ITEM
				)
			)
		}
	}

	const handleUpdateItemLabel =
		(item: ICartItem) => (event: ChangeEvent<HTMLInputElement>) => {
			const OrderQuantity = Number(event.target.value)
			if (isNaN(OrderQuantity)) return

			if (OrderQuantity > max) {
				cogoToast.error(
					`Quantidade acima de ${max}`,
					CogoPositions['top-right']
				)
				return
			}
			if (OrderQuantity > item.StockPosition) {
				cogoToast.error(
					`Quantidade acima do estoque de ${item.StockPosition}`,
					CogoPositions['top-right']
				)
				return
			}

			if (item.typeItem !== ICartItemTypeEnum.REDEMPTION) {
				dispatch(addItem(item, 'UPDATE'))
				return
			}
			dispatch(
				updateRedemptionItem(
					item,
					CartRedemptionItemsActionEnum.UPDATE_REDEMPTION_ITEM
				)
			)
		}
	// console.log("tableData ->>",tableData)
	return (
		<table className="cart-table">
			<tbody>
				<>
					{tableData.map((item, i) => (
						<CartItem
							cartChallengeItem={cartChallengeItem}
							isChallengeMix={isChallengeMix}
							isChallengeItem={isChallengeItems}
							totalPointsRedeemed={totalPointsRedeemed}
							handleIncrementQty={handleIncrementOrderQty}
							handleDecrementQty={handleDecrementOrderQty}
							handleUpdateItemLabel={handleUpdateItemLabel}
							handleRemove={handleRemove}
							isEditable={isEditable}
							item={item}
							key={i}
							salesOrgHasProgramPoints={
								pointsProgramStatus.salesOrgHasProgramPoints
							}
						/>
					))}
				</>
			</tbody>
		</table>
	)
}

export default CartTable
