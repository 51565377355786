import { IAcessorAndOrder } from '../../components/Table/types'
import { ISelectOption } from '../../shared/utils/SelectOptions'

export interface IViewProps {
	loading: boolean
	columns: any[]
	tableData: ITableData[]
	resaleList: ISelectOption[]
	selectedResales: ISelectOption[]
	handleChangeFilter: (value: ISelectOption[], event: any) => void
	handleSelectDates: (value: any) => void
	selectedDates: any
	loadingFilter: boolean
	navProps: ITableNavProps
	itemsPerPage: number
	handleClickFilter: () => void
	status: 'loading' | 'success' | 'idle' | 'error'
	handlerSortColumn(acessor: string, order: number): IAcessorAndOrder
	sortBy: IAcessorAndOrder
	totalSalesOrder: number
}

export interface ITableData {
	customerId: string
	salesOrgId: string
	customerName: string
	quantitySalesOrder: number
	registerDate: string
	virtualCustomerDays: string
}

export const INITIAL_SORT: IAcessorAndOrder = {
	acessor: 'registerDate',
	order: -1
}

export interface ITableNavProps {
	nextPage: (pageIndex: number) => void
	previousPage: (pageIndex: number) => void
	gotoPage: (pageIndex: number) => void
	pageCount: number
	pageIndex: number
	totalDocs: number
}
