import { createElement, useEffect, useState } from 'react'
import FileIcon from '../../assets/images/file-text.svg'
import { fetchUsersPage, deleteUser } from '../../shared/services/user.service'
import {
	ISacUser,
	IDeleteUserPayload,
	UserGroupEnum
} from '../../shared/interfaces/sacUser'
import { TableButton } from '../../shared/components'
import { include } from '../../shared/utils/boolean'

import { ITableProps, ModalType, IViewProps } from './types'
import UsersView from './view'
import cogoDefaultOptions from '../../shared/utils/toaster'
import cogoToast from 'cogo-toast'

const tableColumns = [
	{ Header: 'Matrícula', accessor: 'user' },
	{ Header: 'Perfil', accessor: 'group' },
	{ Header: 'CD', accessor: 'companies' },
	{
		Header: '',
		accessor: 'editButton',
		disableSortBy: true
	}
]

export default function Users() {
	const [users, setUsers] = useState<ISacUser[]>([])
	const [tableProps, setTableProps] = useState<ITableProps>({
		data: [],
		columns: tableColumns,
		isFetching: false
	})
	const [searchString, setSearchString] = useState('')
	const [modalVisible, setModalVisible] = useState<ModalType>('')
	const [selectedUser, setSelectedUser] = useState<string>()
	const [isDeleting, setIsDeleting] = useState(false)

	function handleModalVisibility({
		modal,
		user
	}: {
		modal: ModalType
		user?: string
	}) {
		setModalVisible(modal)
		setSelectedUser(user)
	}

	function handleCloseModal({ refresh = false }: { refresh?: boolean }) {
		setModalVisible('')
		refresh && fetchData()
	}

	function handleDeleteUser() {
		;(async () => {
			try {
				setIsDeleting(true)

				const salesOrganizationActions: IDeleteUserPayload[] = []

				const selectedUserValue = users.find(
					(item) => item.user === selectedUser
				)

				if (!selectedUserValue) return

				const { user, groupId, group, salesOrganizationID, companies } =
					selectedUserValue

				const userPayload: IDeleteUserPayload = {
					user: user,
					groupID: groupId,
					logicalDelete: 'X'
				}
		
				if (group === UserGroupEnum.UNIDADE && companies && companies.length > 0) {
					const formattedCompanies = companies?.map((salesOrgId) => salesOrgId.salesOrganizationID)
				  
					formattedCompanies?.forEach((companyId) => {
					  const salesOrgPayload = {
						salesOrganization: {
						  salesOrgId: companyId,
						  logicalDelete: 'X'
						}
					  };
				  
					  const userPayloadToDelete = { ...userPayload, ...salesOrgPayload };
					  salesOrganizationActions.push(userPayloadToDelete);
					})
				  }

				if(salesOrganizationActions?.length > 0){
					for (let i = 0; i < salesOrganizationActions.length; i++) {
						const fomattedUserToDelete = salesOrganizationActions[i]
						await deleteUser(fomattedUserToDelete)
					}

				}

				if (group === UserGroupEnum.UNIDADE && salesOrganizationID) {
					userPayload.salesOrganization = {
						salesOrgId: salesOrganizationID,
						logicalDelete: 'X'
					}
				}

				 await deleteUser(userPayload)

				handleCloseModal({ refresh: true })

				cogoToast.success(
					'Usuário excluído com sucesso!',
					cogoDefaultOptions
				)
			} finally {
				setIsDeleting(false)
			}
		})()
	}

	function filterDataByString(searchString: string, newUsers?: ISacUser[]) {
		const usersToFilter = newUsers || users
		return usersToFilter.filter((item) => {
			return (
				include(item.user, searchString) ||
				include(item.group, searchString) ||
				(item.salesOrganizationID && include(item.salesOrganizationID, searchString)) ||
				(item.companies && item.companies.map((companie) => companie.salesOrganizationID).includes(searchString))
				
			)
		})
	}

	function handleFilterOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value

		setSearchString(value)

		const filteredUsers = filterDataByString(value)
		setFormatTableData(filteredUsers)
	}

	function setFormatTableData(users: ISacUser[]) {
		const tableData = users.map((user) => ({
			user: user.user,
			group: user.group === UserGroupEnum.UNIDADE ? 'CD' : user.group,
			companies:
				user.companies && user.companies.length > 0
					? user.companies
							?.map((saleOrgId) => saleOrgId.salesOrganizationID)
							.join(', ')
					: user.companies || '-',
			editButton: TableButton(
				() =>
					handleModalVisibility({
						user: user.user,
						modal: 'CREATE/UPDATE'
					}),
				FileIcon
			)
		}))

		setTableProps((state) => ({ ...state, data: tableData }))
	}

	function fetchData() {
		;(async () => {
			try {
				setTableProps((state) => ({ ...state, isFetching: true }))

				const users = await fetchUsersPage()

				setUsers(users)

				const filteredUsers = filterDataByString(searchString, users)

				setFormatTableData(filteredUsers)
			} finally {
				setTableProps((state) => ({ ...state, isFetching: false }))
			}
		})()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchData, [])

	const viewProps: IViewProps = {
		tableProps,
		handleModalVisibility,
		modalUserIsActive: modalVisible === 'CREATE/UPDATE',
		modalDeleteIsActive: modalVisible === 'DELETE',
		selectedUser: users.find((item) => item.user === selectedUser),
		handleCloseModal,
		handleFilterOnChange,
		handleDeleteUser,
		isDeleting
	}

	return createElement(UsersView, viewProps)
}
