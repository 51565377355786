export enum PushActionTypesEnum {
	OPEN_APP = 'OPEN_APP',
	OPEN_SPECIFIC_PRODUCT = 'OPEN_SPECIFIC_PRODUCT',
	OPEN_PRODUCT_CATEGORY = 'OPEN_PRODUCT_CATEGORY',
	OPEN_COMBOS = 'OPEN_COMBOS',
	OPEN_CHALLENGES = 'OPEN_CHALLENGES',
	OPEN_PROGRAM_POINTS = 'OPEN_PROGRAM_POINTS'
}

export enum CategoriesProductEnum {
	BEERS = 'CERVEJAS',
	ENERGY = 'ENERGÉTICOS',
	DISTILLATES = 'DESTILADOS',
	SOFT_DRINKS = 'REFRIGERANTES',
	ISOTONICS = 'ISOTÔNICO',
	WATERS = 'ÁGUA',
	MORE_POINTS = 'MAIS PONTOS'
}

export interface INotificationProps {
	title: string
	body: string
	imageUrl: string
	data: {
		actionType: PushActionTypesEnum | string
		productToOpen?: string
		categoryToOpen?: string
	}
	trigger_date?: string
}
