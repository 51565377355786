import React, { ReactNode, createContext, useCallback, useState } from 'react';
import { IUnavailableProductReq } from '../modules/ProductList/types';

type UnavailableProductContextProps = {
  selectedProducts: IUnavailableProductReq[];
  addSelectedProduct: (product: IUnavailableProductReq) => void;
  showNotification: boolean;
  setShowNotification: (value: boolean) => void;
};

type UnavailableProductProviderProps = {
	children: ReactNode
}

export const UnavailableProductContext = createContext(
	{} as UnavailableProductContextProps
)

export function UnavailableProductProvider({
	children
}: UnavailableProductProviderProps) {
  const [selectedProducts, setSelectedProducts] = useState<IUnavailableProductReq[]>([]);
  const [showNotification, setShowNotification] = useState(false);

  const addSelectedProduct = useCallback((product: IUnavailableProductReq) => {
    setSelectedProducts((previousProducts) => [
      ...previousProducts,
      { ...product },
    ]);
  }, [setSelectedProducts]);

  return (
    <UnavailableProductContext.Provider
      value={{
        selectedProducts,
        addSelectedProduct,
        showNotification,
        setShowNotification,
      }}>
      {children}
    </UnavailableProductContext.Provider>
  );
}
