import { ICheckCustomerResponse, ViewStateEnum } from './types'

export function checkUserState(
	status: ICheckCustomerResponse[]
): ViewStateEnum {
	const GP = status.find((item) => item.System === 'GP')
	const cognito = status.find((item) => item.System === 'COGNITO')

	let viewState: ViewStateEnum = ''

	if (
		cognito?.CustomerStatus === 'REGISTERED' &&
		cognito?.CustomerStatusDetail === 'UNCONFIRMED'
	) {
		viewState = 'ALREADY_REGISTERED_UNCONFIRMED'
	} else if (cognito?.CustomerStatus === 'REGISTERED') {
		viewState = 'ALREADY_REGISTERED'
	} else if (GP?.CustomerStatus === 'REGISTERED') {
		viewState = 'GP_CLIENT'
	} else {
		viewState = 'OTHER'
	}

	return viewState
}

export function returnBase64AsImage(base64: string) {
	const base64InitData = base64.startsWith('data:')
	if (base64InitData) return base64
	return `data:image/jpeg;base64,${base64}`
}

export function renameImageToAws(id: string) {
	switch (id) {
		case 'DocumentPhoto':
			return 'COMP_ENDE'
		case 'MirrorCnpj':
			return 'CARTAO_CNPJ'
		case 'EstablishmentPhoto':
			return 'ESTABELEC_FOTO'
		case 'AddressPhoto':
			return 'COMP_ENDE'
		case 'DocumentPhotoFront':
			return 'FOTO_SELFIE'
		case 'DocumentPersonalFront':
			return 'RG_CPF_CNH_FRENTE'
		case 'DocumentPersonalBack':
			return 'RG_CPF_CNH_FRENTE'
		default:
			return id
	}
}

export function estabilishmentOptions() {
	const establishments = [
		'academia',
		'açougue/ peixaria',
		'adega de cerveja/ depósito',
		'banca de jornal/ livraria',
		'bar/ boteco/ quiosque/ barraca/ trailer',
		'barzinho',
		'boliche/ cinema/ kart/ teatro',
		'buffet',
		'choperia',
		'conveniencia/ posto de gasolina',
		'empório/ delicatessen/ adega de vinho',
		'evento',
		'farmácia',
		'hotel/ motel/ hostel',
		'hotel vip',
		'lanchonete/ cafeteria/ sorveteria',
		'mercearia/ armazém/ quitanda',
		'padaria/ confeitaria',
		'pub/ casa noturna/ boate/ danceteria',
		'quadra esportiva',
		'rede de supermercado atacado',
		'rede supermercado regional',
		'república',
		'restaurante/ pizzaria/ churrascaria',
		'subdistribuidor',
		'supermercado atacado',
		'supermercado com 01 a 04 caixas',
		'supermercado com 05 a 09 caixas',
		'supermercado com mais de 10 caixas',
		'outros'
	];
	const options = establishments.map(item => ({ label: item.toUpperCase(), value: item.toUpperCase() }))
	return options
}