import styled from 'styled-components'

export const WrapperReviewSidebar = styled.div`
	height: 100%;
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.XLG}px;
`

export const WrapperSubtitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
`

export const SubtitleSidebar = styled.p`
	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
	strong {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.bold};
	}
`

export const ReviewText = styled.p`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	text-align: left;
	width: 100%;
`

export const WrapperCommentReview = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;

	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	}

	textarea {
		width: 100%;
		height: 124px;
		padding: ${(props) => props.theme.spacingOptions.MD}px;

		border-radius: 5px;
		border: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
		background: ${(props) => props.theme.palette.background.paper};

		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;

		::placeholder {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
			line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		}
	}
`
export const WrapperButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	gap: ${(props) => props.theme.spacingOptions.XLG}px;
`
