import { IFiltersHierarchy } from "../typings"

export function createCdGrcMap(data: IFiltersHierarchy) {
	const cdGrcMap: { [key: string]: string } = {}
	data.salesHierarchy.forEach((item) => {
		item.govs.forEach((govInfo) => {
			govInfo.resales.forEach((resaleInfo) => {
				const cd = resaleInfo.resale
				if (!cd || !govInfo.gov) return
				cdGrcMap[cd] = govInfo.gov
			})
		})
	})

	return cdGrcMap
}
