import styled from 'styled-components'
import MorePointsFullBannerImage from '../../../assets/images/maispontos-banner-full.png'
import { colors } from '../../../shared/styles/theme'

export const Container = styled.div`
	margin-top: 48px;
`

export const ScreenPath = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	span.go-back-link {
		color: ${colors.red};
		text-decoration: underline;
		cursor: pointer;
	}
	span + span {
		margin: 0 8px;
	}
`

export const Banner = styled.div`
	width: 100%;
	min-height: 198px;
	border-radius: 4px;

	background-image: url(${MorePointsFullBannerImage});
	background-color: #800506;
	background-repeat: no-repeat;
	background-position-x: right;

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 40px;
	margin-top: 24px;

	span.banner-title {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${colors.white};
		font-weight: ${(props) => props.theme.font.weight.regular};
	}

	span.banner-subtitle {
		font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
		color: ${colors.white};
		line-height: ${(props) => props.theme.font.size.TEXT_LG.line_height}rem;
		margin-top: 16px;
		font-weight: ${(props) => props.theme.font.weight.regular};
	}
`
