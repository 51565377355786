import styled from 'styled-components'

export const ModalContent = styled.div``

export const Description = styled.p`
	color: ${(props) => props.theme.palette.gray.main};

	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;

	margin-bottom: 48px;
`

export const ButtonsWrapper = styled.div`
	width: 100%;

	gap: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

export const ButtonConfirm = styled.button`
	display: block;
	&:disabled {
		cursor: progress;
	}
`

export const ButtonToMaintain = styled.button`
	display: block;
	border: none;
`
