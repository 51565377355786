import React, { Fragment } from 'react'

import Header from '../../components/Header'
import InputPassword from '../../components/InputPassword'
import InputPasswordConfirmation from '../../components/InputPasswordConfirmation'
import Loading from '../../components/Loading'
import { IViewProps } from './types'

import ArrowLeftBlackIcon from '../../assets/images/arrow-left-black.svg'

function ChangePersonalPassword(props: IViewProps): JSX.Element {
	const {
		canProceed,
		handleSavePassword,
		newPassword,
		setPassword,
		setPasswordIsValid,
		setNewPassword,
		setNewPasswordIsValid,
		setConfirmationIsValid,
		isLoading,
		handleBack
	} = props

	return (
		<Fragment>
			<Header />
			<div className="change-personal-password-page">
				<div className="col-10">
					<div className="row offset-lg-1">
						<button
							className="back-arrow-wrapper"
							onClick={handleBack}>
							<img
								className="back-arrow"
								color={'#ffffff'}
								src={ArrowLeftBlackIcon}
								alt="Voltar"
							/>
						</button>
					</div>
					<div className="row offset-lg-1">
						<h1 className="page-title">Alterar Senha</h1>
					</div>
					<div className="centralizer">
						<div className="col-lg-5 col-sm-12">
							<h1 className="section-title">Senha Atual</h1>
							<InputPassword
								secondary
								returnValue={setPassword}
								valueIsValid={setPasswordIsValid}
							/>
						</div>
						<div className="col-lg-5 col-sm-12">
							<h1 className="section-title">Nova Senha</h1>

							<InputPassword
								secondary
								returnValue={setNewPassword}
								valueIsValid={setNewPasswordIsValid}
							/>
							<InputPasswordConfirmation
								secondary
								valueIsValid={setConfirmationIsValid}
								password={newPassword}
							/>
						</div>
					</div>

					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<div className="button-container">
							<button
								className="red-button"
								disabled={!canProceed}
								onClick={handleSavePassword}>
								SALVAR SENHA
							</button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default ChangePersonalPassword
