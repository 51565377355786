import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import { colorThemeProvider } from '../../theme/themeProvider'

export const Wrapper = styled.div`
	width: 100%;
`

export const Container = styled.div<{
	isChallengeScreen?: boolean
	disabled?: boolean
	errorMessage?: boolean
}>`
	width: 100%;
	border-radius: 4px;
	border: 1px solid
		${(props) => (!!props.errorMessage ? colors.lightRed : '#d1d1d1')};

	display: flex;
	flex-direction: row;
	align-items: center;
	height: 55px;

	> div {
		position: relative;
		width: 100%;
	}
`

export const Input = styled.input`
	width: 100%;
	border: none;
	padding: 16px;
	color: ${colorThemeProvider.grayColor};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	background: none;

	&::placeholder {
		color: ${(props) => props.theme.palette.gray.main};
	}

	&:disabled {
		color: ${(props) => props.theme.palette.gray.main};
	}
`

export const Label = styled.label<{ disabled: boolean }>`
	top: 10px;
	left: 16px;
	color: ${({ disabled }) => (disabled ? colorThemeProvider.grayColor : colorThemeProvider.secondaryColor)};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	position: relative;
	text-transform: uppercase;
`

export const ErrorMessage = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;
	color: ${colors.lightRed};
`
