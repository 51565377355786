import styled, { css } from 'styled-components'

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 0.5;
`

export const ContentWrapper = styled.div`
	position: relative;
`

export const SelectComponentWrapper = styled.div`
	width: 100%;
	z-index: 1;
	position: absolute;
`

export const SelectInput = styled.div`
	width: 100%;
	padding: 10px 16px;
	border-radius: 4px;
	background-color: #ffffff;
	border: 1px solid #d1d1d1;
`

export const SelectInfos = styled.div`
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const OptionSelected = styled.span`
	color: #5d615d;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
`

export const SelectOption = styled.label<{ optionSelected: boolean }>`
	color: #5d615d;
	display: block;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	padding: 12px 24px 12px 16px;

	cursor: pointer;
	transition: ease-in-out 300ms;

	${({ optionSelected }) =>
		optionSelected &&
		css`
			 {
				color: #CE332E;
				background-color: #fef0f0;
			}
		`}

	&:hover {
		color: #CE332E;
		background-color: #fef0f0;
	}

	> input {
		opacity: 0;
	}
`

export const SelectedOptionsAndSearchWrapper = styled.div`
	width: 100%;
	border-radius: 4px;
	background-color: #f5f5f5;
	padding: 10px 0px;
`

export const SearchInputWrapper = styled.div`
	padding: 10px;
`

export const SearchInputContainer = styled.div`
	width: 100%;
	background-color: ${(props) => props.theme.palette.white.main};
	padding: 0px 18px;
	border-radius: 4px;
`

export const SearchIcon = styled.img``

export const SearchInputElement = styled.input`
	border: none;
	outline: none;
	min-height: 55px;
	max-height: 55px;
	background-color: transparent;
	color: #979e97;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	margin-left: 8px;
`

export const Separator = styled.div`
	width: 100%;
	height: 1px;
	background-color: #e0e0e0;
`

export const SelectOptions = styled.div`
	width: 100%;
	max-height: 145px;
	overflow: auto;
	background-color: #f5f5f5;
`

export const ChevronDown = styled.img``
