import React, { Fragment } from 'react'

import {
	ChallengeCartItemBlockOfProducts,
	Loading
} from '../../shared/components'
import CartTable from '../../shared/components/CartTable'
import Header from '../../components/Header'
import TimeLine from '../../components/TimeLine'
import { formatCep } from '../../shared/utils/validators'
import OrderDataCard from '../OrderHistory/OrderDataCard'
import { IViewProps } from './types'
import infoImg from '../../assets/images/info.svg'

import ArrowLeftBlackIcon from '../../assets/images/arrow-left-black.svg'
import { ICartChallenge } from '../../shared/interfaces/pointsProgramChallenge'

import * as S from './styles'
import { ICartItem } from '../../shared/interfaces/cart'

function OrderDetail(props: IViewProps): JSX.Element {
	const {
		order,
		timeline,
		orderData,
		handleBack,
		deliveryData,
		itemsChallenge,
		myChallenges,
		removedItemsTableData,
		individualProductsData,
		isLoadingItemsChallenge
	} = props

	const renderingBlockOfIndividualProducts = (
		individualProductsData: any[]
	): JSX.Element | null => {
		if (individualProductsData.length) {
			return (
				<S.SessionWrapper>
					<S.SessionTitle>Produtos Individuais</S.SessionTitle>

					<CartTable
						tableData={individualProductsData}
						isEditable={false}
					/>
				</S.SessionWrapper>
			)
		} else return null
	}

	const renderBlockItemsChallengeComponent = (challengeID?: string) => {
		let purchasedChallengeItem: ICartChallenge | undefined = undefined

		const challengeFilteredAccordingToPurchasedProducts = myChallenges.find(
			(myChallenge) => challengeID === myChallenge.challengeId
		)

		if (challengeFilteredAccordingToPurchasedProducts) {
			purchasedChallengeItem = {
				...challengeFilteredAccordingToPurchasedProducts,
				addedProducts: itemsChallenge.filter(
					(itemChallenge) => itemChallenge.challengeID === challengeID
				)
			}
		}

		return (
			<Fragment>
				{purchasedChallengeItem && (
					<section style={{ marginBottom: '8px' }} key={challengeID}>
						<ChallengeCartItemBlockOfProducts
							isCheckoutScreen
							cartchallenge={purchasedChallengeItem}
						/>
					</section>
				)}
			</Fragment>
		)
	}

	const gettingIDsFromOrderChallenges = (
		itemsChallenge: ICartItem[]
	): string[] => {
		if (itemsChallenge.length) {
			const purchasedChallengeItems = itemsChallenge.map((item) => {
				return item.challengeID
			})

			const challengesIDS = purchasedChallengeItems.filter(
				(ID, index) => purchasedChallengeItems.indexOf(ID) === index
			)

			return challengesIDS.map((ID) => String(ID))
		} else return []
	}

	const renderingBlockListWithOrderChallenges = (
		challengeIDs: string[]
	): JSX.Element | null => {
		if (challengeIDs.length) {
			return (
				<S.SessionWrapper>
					<S.SessionTitle>Produtos do Desafio</S.SessionTitle>
					<S.ChallengeItemWrapper>
						{challengeIDs.map(renderBlockItemsChallengeComponent)}
					</S.ChallengeItemWrapper>
				</S.SessionWrapper>
			)
		} else return null
	}

	return (
		<Fragment>
			<Header />
			<div className="order-detail">
				<div className="container">
					<button className="back-arrow-wrapper" onClick={handleBack}>
						<img
							className="back-arrow"
							src={ArrowLeftBlackIcon}
							alt="Voltar"
						/>
					</button>

					{order ? (
						<>
							<div className="header-wrapper">
								{order.InvoiceNumber ? (
									<div className="last-info-wrapper">
										<h3>Nota Fiscal:</h3>
										<span>{order.InvoiceNumber}</span>
									</div>
								) : null}
								{order.AutoIncBackendID ? (
									<div className="last-info-wrapper">
										<h3>Detalhes do Pedido</h3>
									</div>
								) : null}
							</div>

							<TimeLine timeline={timeline} />

							<S.SessionWrapper className="row">
								<div className="col-12 col-md-6">
									<OrderDataCard
										data={orderData}
										isProductDetail={true}
									/>
								</div>

								<div className="col-12 col-md-6">
									<div className="page-title">
										<h2>Dados da Entrega</h2>
									</div>

									<div className="box destinary-card-box">
										<div className="info-wrapper">
											<div className="item space">
												<p className="param">
													Entrega prevista até:
												</p>
												<p className="value">
													{deliveryData.deliveryDate}
												</p>
											</div>

											<div className="item space">
												<p className="param">
													Destinatário:
												</p>
												<p className="value">
													{deliveryData.destinatary}
												</p>
											</div>

											<div className="item">
												<p className="param">
													Endereço:
												</p>
												<p className="value">
													{deliveryData.address}
												</p>
											</div>

											<div className="item">
												<p className="param">Bairro:</p>
												<p className="value">
													{deliveryData.neighborhood}
												</p>
											</div>

											<div className="item space">
												<p className="param">
													Cidade / Estado:
												</p>
												<p className="value">
													{deliveryData.cityState}
												</p>
											</div>

											<div className="item">
												<p className="param">CEP:</p>
												<p className="value">
													{formatCep(
														deliveryData.CEP
													)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</S.SessionWrapper>

							{/**
							 * @description challenges products session
							 * **/}
							{!!itemsChallenge.length &&
							!isLoadingItemsChallenge ? (
								renderingBlockListWithOrderChallenges(
									gettingIDsFromOrderChallenges(
										itemsChallenge
									)
								)
							) : isLoadingItemsChallenge ? (
								<S.LoadingWrapper>
									<Loading />
								</S.LoadingWrapper>
							) : null}

							{/**
							 * @description individual products session
							 * **/}
							{!!individualProductsData.length &&
							!isLoadingItemsChallenge ? (
								renderingBlockOfIndividualProducts(
									individualProductsData
								)
							) : isLoadingItemsChallenge ? (
								<S.LoadingWrapper>
									<Loading />
								</S.LoadingWrapper>
							) : null}

							{/**
							 * @description removed products section
							 * **/}
							{removedItemsTableData.length ? (
								<S.SessionWrapper className="row">
									<div className="col-12">
										<S.SessionTitle>
											Itens Removidos por Falta de Estoque
										</S.SessionTitle>
									</div>
									<div className="removed-items-info-message">
										<img
											src={infoImg}
											alt="info"
											height={13}
											width={13}
										/>
										<span>
											EM CASO DE DÚVIDAS, ENTRE EM CONTATO
											COM SEU VENDEDOR
										</span>
									</div>
									<div className="col-12">
										<CartTable
											tableData={removedItemsTableData}
											isEditable={false}
										/>
									</div>
								</S.SessionWrapper>
							) : null}
						</>
					) : (
						<div className="no-last-placeholder">
							Não foi possível obter todos os dados do pedido
							selecionado
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default OrderDetail
