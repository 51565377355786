import React from 'react'

import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import { IEditPushModal } from './edit-push-modal.types'
import { EditPushModalStyles } from './edit-push-modal.styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TextArea from '../../../../../components/text-area/text-area.component'
import InputField from '../../../../../components/input-field/input-field.component'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'

const EditPushModal = ({
	isOpen,
	handleToggleModal,
	pushTitle,
	messagemAplied,
	handleChangeTitle,
	handleChangeMessage,
	usersImpacted,
	handleClickEditModal,
	isLoading,
	disabledButton
}: IEditPushModal) => {
	return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			height={'659px'}
			width={'648px'}
			modalTitle={'Editar Push Agendado'}
			buttonText={'Confirmar alteração'}
			handleClick={handleClickEditModal}
			isLoading={isLoading}
			disabledButton={disabledButton}
			>
			<EditPushModalStyles>
				<InputField
					text={pushTitle}
					handleChange={handleChangeTitle}
					label="Título"
				/>
				<TextArea
					text={messagemAplied}
					handleChange={handleChangeMessage}
					label="Mensagem"
					limitCaracters={120}
				/>
				<ImpactedClients usersImpacted={usersImpacted} />
				<div className="info-modal-edit">
					<InfoOutlinedIcon />
					<p>
						É possível realizar a edição do texto na área demarcada
						e, posteriormente, confirmar as alterações realizadas.
					</p>
				</div>
			</EditPushModalStyles>
		</LayoutModal>
	)
}

export default EditPushModal
