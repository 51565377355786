export enum ItemTypeEnum {
	COMBO = 'COMBO',
	PRICE_ACTION = 'PRICE_ACTION',
	NORMAL = 'NORMAL',
	REDEMPTION = 'REDEMPTION',
}

export function getPromotionType(promotionType?: number): ItemTypeEnum {
	if (!promotionType) {
		return ItemTypeEnum.NORMAL
	} else if (promotionType === 1) {
		return ItemTypeEnum.COMBO
	} else if (promotionType === 2) {
		return ItemTypeEnum.PRICE_ACTION
	} else {
		return ItemTypeEnum.NORMAL
	}
}
