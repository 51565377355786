import { fetchAndActivate, getAll } from 'firebase/remote-config'
import React, {
	createContext,
	useContext,
	useState,
	ReactNode
} from 'react'
import firebaseObject from '../../config/firebase-confing'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'
import { getFeatureStatusByEnvironment } from './utils/get-feature-status-by-env/get-feature-status-by-env'
import { formatEnvForFirebase } from './utils/format-env-for-firebase/format-env-for-firebase'

interface FeatureStatus {
	[feature: string]: boolean
}

interface FeatureFlagContextType {
	featureFlags: FeatureStatus
}

const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(
	undefined
)

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
	const [featureFlags, setFeatureFlags] = useState<FeatureStatus>({})
	React.useEffect(() => {
		async function handleGetFeatureFlags(){
			await fetchAndActivate(firebaseObject.remoteConfig)
			.then((activated) => {
				return getAll(firebaseObject.remoteConfig)
			})
			.then((remoteFlags) => {
				const env = formatEnvForFirebase(getAmbienteName()) ?? ""
				const feature = getFeatureStatusByEnvironment({
					env,
					features: remoteFlags
				})

				setFeatureFlags(feature)
			})
			.catch((error) => console.error(error))
		} 
		handleGetFeatureFlags()
	}, [])
	return (
		<FeatureFlagContext.Provider value={{ featureFlags }}>
			{children}
		</FeatureFlagContext.Provider>
	)
}

// Custom hook para usar o contexto
export const useFeatureFlags = () => {
	const context = useContext(FeatureFlagContext)
	if (!context) {
		throw new Error(
			'useFeatureFlags must be used within a FeatureFlagProvider'
		)
	}
	return context
}
