import React from 'react'
import * as S from './searchinput.styles'
import { ITextField } from './searchinput.types'

const SearchInput = ({
	text,
	handleChange,
	label,
	searchButtonLabel,
	handleSearch,
	placeholder
}: ITextField) => {
	return (
		<S.TextFielWrapper
			label={label}
			variant="outlined"
			data-testid="search-input-component"
			value={text}
			onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
				handleChange(event)
			}
			placeholder={placeholder}
			InputProps={{
				endAdornment: (
					<S.ButtonSearch onClick={handleSearch}>
						{searchButtonLabel.toUpperCase()}
					</S.ButtonSearch>
				)
			}}
		/>
	)
}

export default SearchInput
