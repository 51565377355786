import React from 'react'
import Select from 'react-select'
import { Table } from '../../../shared/components'
import Loading from '../../../components/Loading'
import { IViewProps } from './types'
import InputFilter from '../../../components/InputFilter'
import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'

import * as S from './styles'

function ResaleList(props: IViewProps): JSX.Element {
	const {
		tableData,
		columns,
		handleSearchOnChange,
		isLoading,
		uploadPayload,
		unidades,
		unidadeEscolhida,
		setUnidadeEscolhida,
		refreshButton
	} = props

	return (
		<DefaultLayoutAdmin>
			<div className="resale-products-page">
				<div className="resale-params-table-wrapper">
					<div className="title-wrapper location">
						<div className="text-container">
							<p className="resale-title">
								Status de Processamento
							</p>
						</div>
						<div>
							<Select
								isLoading={false}
								isClearable={true}
								placeholder="Escolha uma revenda"
								options={unidades || []}
								value={unidadeEscolhida}
								onChange={(opt: any) =>
									setUnidadeEscolhida(opt)
								}
								classNamePrefix="react-select"
								className="react-select"
							/>
							<p className="description">
								*Selecione a revenda e faça upload do arquivo
								para exclusão
							</p>
						</div>
					</div>

					<S.ContentWrapper>
						<div className="title-wrapper">
							<div className="text-container">
								<div className="back-button-wrapper">
									<label
										className="back-arrow-button white-button"
										color={'#ffffff'}
										onClick={refreshButton}>
										<span className="resale-refresh">
											{' '}
											Atualizar dados &#x21BA;
										</span>
									</label>
								</div>
							</div>
						</div>
						{isLoading ? null : (
							<div className="red-button-container">
								<label
									className="white-button upload-button"
									htmlFor="upload-input">
									Subir Carga para Exclusão
									<input
										id="upload-input"
										onChange={uploadPayload}
										type="file"
										accept=".csv"
									/>
								</label>
							</div>
						)}
					</S.ContentWrapper>

					<div className="search-wrapper">
						<InputFilter
							className="search-input"
							onChange={handleSearchOnChange}
							placeholder="Filtro"
						/>
					</div>

					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<Table data={tableData} columns={columns as any} />
					)}
				</div>
			</div>
		</DefaultLayoutAdmin>
	)
}

export default ResaleList
