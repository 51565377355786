import { FilterNameEnum } from '../../components/filters-by-hieararchy/types'
import { ICartItem } from './cart'

export interface IChallenge {
	challengeId: string
	autoIncChallengeId: string
	urlFoto: string
	score: string
	startDate: string
	endDate: string
	countDayFinish: boolean
	title: string
	description: string
	Term: string
	appPopUpImageUrl: string
	typeRule: string
	participateMoreOnce: boolean
	bannerhome: boolean
	participantsQuantity: number
	filtersChallenge: IFilterChallenge[]
	products: IChallengeProduct[]
	status: ChallengeStatusEnum
	observation?: string
	challengeRegistrationDate: string
	createdAt: string
	completedAt?: string
	userIsAlreadyParticipatingInTheChallenge?: boolean
	createdBy?: string
	modifiedAt?: string
	modifiedBy?: string
	ID?: string
}

export interface IChallengeProduct {
	challengeId?: string
	productId: string
	quantity: string
	quantityReached?: number
}

export enum ChallengeStatusEnum {
	ACTIVE = 'ATIVO',
	EXPIRED = 'EXPIRADO',
	CANCELED = 'CANCELADO',
	PENDING = 'PENDENTE'
}

export interface IFilterChallenge {
	ruleID?: string
	paramName: ChallengeParamsNameEnum | FilterNameEnum
	paramValue: string
}

export enum ChallengeParamsNameEnum {
	DIRECTOR = 'DIRECTOR',
	GOV = 'GOV',
	RESALE = 'RESALE',
	STATE = 'STATE',
	CITY = 'CITY',
	CHANNEL = 'CHANNEL',
	PDV = 'PDV'
}

export interface IAcceptedTerm {
	customerID: string
	termDescription: string
	acceptTerm: string
	eventDate: string
	parentId: string
}

export enum AcceptTermEnumType {
	NO = 'N',
	YES = 'S'
}

export interface IFilterSalesHierarchyDirector {
	director: string
	govs: IFilterSalesHierarchyGov[]
}

export interface IFilterSalesHierarchyGov {
	gov: string
	resales: IFilterSalesHierarchyResale[]
}

export interface IFilterSalesHierarchyResale {
	resale: string
	states: IFilterSalesHierarchyResale[]
	cities: IFilterSalesHierarchyResale[]
	state: string
	city: string
}

export interface ISelectDefaultInterface {
	label: string
	value: string
}

export interface IPointProgramChallengeFilters {
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: ISelectDefaultInterface[]
	ufs: ISelectDefaultInterface[]
	cities: ISelectDefaultInterface[]
}

export enum ChallengeRuleTypeEnum {
	PRODUCT_MIX = 'T',
	PARTIAL = 'P'
}

export interface ICartChallenge extends IChallenge {
	addedProducts: ICartItem[]
}

export enum ChallengeFiltersEnum {
	ARE_EXPIRING = 'ARE_EXPIRING',
	OLDER = 'OLDER',
	BIGGER_BONUS = 'BIGGER_BONUS',
	EXPIRED = 'EXPIRED'
}

export interface IChallengesTheUserIsParticipatingIn {
	challengeID: string
	products: Array<Omit<IChallengeProduct, 'ID'>>
}
