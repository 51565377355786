import React from 'react'
import * as S from './radio-button.styles'
import { IRadioButton } from './radio-button.types'

import { FieldValues, useController } from 'react-hook-form'

import RadioGroup from '@mui/material/RadioGroup'
import FormControl from '@mui/material/FormControl'
import { Stack } from '@mui/material'
import ErrorText from '../error-text/error-text.component'

function RadioButtonGroup<T extends FieldValues>({
	radioLabel,
	controlLabelOptions,
	onAfterChange,
	disabled = false,
	...otherProps
}: IRadioButton<T>) {
	const {
		field: { value, onChange },
		fieldState: { error }
	} = useController(otherProps)
	const name = otherProps.name

	return (
		<Stack flexDirection="column">
			<FormControl>
				<RadioGroup
					name={name}
					value={value}
					onChange={(e) => {
						onChange(e)
						onAfterChange?.()
					}}>
					<S.RadioButtonListWrapper>
						{controlLabelOptions.map((option) => {
							return (
								<S.StyledFormControlLabel
									key={option.value}
									value={option.value}
									control={<S.StyledRadio />}
									label={option.label}
									disabled={disabled}
								/>
							)
						})}
					</S.RadioButtonListWrapper>
				</RadioGroup>
			</FormControl>
			<ErrorText error={error?.message} />
		</Stack>
	)
}

export default RadioButtonGroup
