import React from 'react'
import { IProps } from './types'
import { TitleTypeOfProductWrapper } from './styles'

function TitleTypeOfProduct({ color, title }: IProps) {
	return (
		<TitleTypeOfProductWrapper>
			<div style={{ background: color }}></div>
			<span className="combo-card-badget-title" style={{ color: color }}>
				{title}
			</span>
		</TitleTypeOfProductWrapper>
	)
}

export default TitleTypeOfProduct
