/* eslint-disable no-throw-literal */
import { Auth } from 'aws-amplify'
import { handleCognitoError } from '../../shared/utils/handleCognitoError'
import amplifyConfig from '../../amplify-config'

interface IRequestConfirmSignUp {
	username: string
	code: string
	options?: IResendSignUpOptions
}

interface IResendSignUpOptions {
	useCustomErrorHandler: boolean
}

// Código de validação de code
export async function confirmSignUp({
	username,
	code,
	options
}: IRequestConfirmSignUp) {
	try {
		const response = await Auth.confirmSignUp(username, code)
		// console.log(response)
		return response
	} catch (err) {
		console.error(err)
		if (!options || !options.useCustomErrorHandler) handleCognitoError(err)
		throw new Error()
	}
}

confirmSignUp.defaultProps = {}

interface IRequestResendSignUp {
	username: string
	clientMetadata: {
		sendemail: 'X' | ''
		sendsms: 'X' | ''
	}
}

interface IRequestValidateAttribute {
	username: string
	attr: string
	password: string
	code: string
	clientMetadata?: {
		sendemail: 'X' | ''
		sendsms: 'X' | ''
	}
}

export async function resendSignUp({
	username,
	clientMetadata
}: IRequestResendSignUp) {
	try {
		const response = await Auth.resendSignUp(username, {
			'custom:sendemail': clientMetadata.sendemail,
			'custom:sendsms': clientMetadata.sendsms
		})

		if (!response) {
			throw {
				statusCode: 466,
				message:
					'Não foi possível reenviar o código. Por favor, tente novamente mais tarde...'
			}
		}

		return response
	} catch (err) {
		console.error(err)
		handleCognitoError(err)
	}
}

export async function sendCodeForNewPassword({
	username,
	clientMetadata
}: IRequestResendSignUp) {
	try {
		Auth.configure(amplifyConfig)
		const response = await Auth.forgotPassword(username, {
			'custom:sendemail': clientMetadata.sendemail,
			'custom:sendsms': clientMetadata.sendsms
		})

		if (!response) {
			throw {
				statusCode: 466,
				message:
					'Não foi possível reenviar o código. Por favor, tente novamente mais tarde...'
			}
		}

		return response
	} catch (err) {
		handleCognitoError(err)
	}
}

export async function validateAttribute({
	username,
	attr,
	code,
	password,
	clientMetadata
}: IRequestValidateAttribute) {
	try {
		Auth.configure(amplifyConfig)
		await Auth.signIn(username, password)
		const response = await Auth.verifyCurrentUserAttributeSubmit(attr, code)
		if (!response) {
			throw {
				statusCode: 466,
				message:
					'Não foi possível reenviar o código. Por favor, tente novamente mais tarde...'
			}
		}

		return response
	} catch (err) {
		handleCognitoError(err)
	} finally {
		Auth.signOut()
	}
}
