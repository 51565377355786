import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'

export const Container = styled.div`
	width: 100%;

	.react-dual-listbox {
		.rdl-actions button {
			border-radius: 4px;
			padding: 0px;

			img {
				padding: 8px 8px;
				border-radius: 4px;
				background-color: ${colorThemeProvider.primaryColor};
			}

			img.left {
				transform: rotate(180deg);
			}

			img.top {
				transform: rotate(270deg);
			}

			img.bottom {
				transform: rotate(90deg);
			}

			img.disabled {
				background-color: ${colorThemeProvider.lightGrayColor};

				&:hover {
					cursor: not-allowed;
				}
			}

			&:disabled {
				background-color: ${colorThemeProvider.lightGrayColor};
			}
		}

		.rdl-list-box.rdl-available,
		.rdl-list-box.rdl-selected {
			padding: 24px;
			border: 1px solid ${colorThemeProvider.extrasColor};
			border-radius: 4px;
			background-color: ${colorThemeProvider.whiteColor};
		}

		.rdl-list-box {
			height: 300px;

			.rdl-filter {
				border-radius: 4px;
			}

			.rdl-control {
				border-radius: 4px;

				option {
					padding: 6px 6px;
				}

				option:hover {
					background-color: ${colorThemeProvider.lightGrayColor};
					color: ${colorThemeProvider.whiteColor};
				}

				option:checked {
					background-color: ${colorThemeProvider.primary.main};
					color: ${colorThemeProvider.whiteColor};
				}
			}
		}
	}
`
export const Descriptions = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 1.4rem;

	p:first-child {
		width: 40%;
	}
	p {
		width: 48%;

		color: ${(props) => props.theme.palette.gray.main};

		font-size: ${(props) => props.theme.font.size.TEXT_MD}red;
	}
`

export const WrapperLoading = styled.div`
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid ${colorThemeProvider.extrasColor};
`
