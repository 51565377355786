import React, { Fragment } from 'react'

import { IViewProps } from './types'
import CheckIcon from '../../assets/images/success-red.svg'

import HeaderNotLogged from '../../components/HeaderNotLogged'
import FooterNotLogged from '../../components/FooterNotLogged'
import ButtonPrimary from '../../components/ButtonPrimary'
import { primaryButtonStyle } from '../../shared/styles/theme'
import { SAC_TELEPHONE_NUMBER } from '../../assets/strings'

function AwaitingApprovalSac(props: IViewProps): JSX.Element {
	const { handleBackToLogin } = props

	return (
		<Fragment>
			<HeaderNotLogged />
			<div className="awaiting-approval-sac">
				<div className="page-wrapper">
					<img
						src={CheckIcon}
						className="check-icon"
						alt="Icone Check"
					/>

					<h1 className="title">Solicitação Concluída</h1>

					<p className="description">
						Sua solicitação de cadastro já está com nossa equipe e
						em breve, um representante comercial do Grupo Petrópolis
						entrará em contato.
					</p>

					<p className="description">
						Dúvidas? Ligue para Central de Atendimento
					</p>

					<h1 className="title">{SAC_TELEPHONE_NUMBER}</h1>

					<ButtonPrimary
						onClick={handleBackToLogin}
						{...primaryButtonStyle}>
						VOLTAR AO LOGIN
					</ButtonPrimary>
				</div>
			</div>
			<FooterNotLogged />
		</Fragment>
	)
}

export default AwaitingApprovalSac
