import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const EditModalWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	gap: 20px;

	padding: 30px 0px;

	.params-wrapper {
		font-weight: ${(props) => props.theme.font.weight.bold};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;

		color: ${(props) => props.theme.palette.black.main};
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		gap: 25px;
		padding-bottom: 20px;
		width: 100%;

		.inputs-wrapper {
			width: 100%;

			.input-collum {
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		border-bottom: 1px solid #e0e0e0;
	}

	.info-modal {
		display: flex;
		background-color: ${colors.fifthGray};
		padding: 16px;
		gap: 20px;
		width: 100%;

		svg {
			color: ${(props) => props.theme.palette.gray.main};
		}
		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.primaryWhite.contrastText};
		}
	}
`
