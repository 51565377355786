import { format } from 'date-fns'
import { IHeaderRulePoint } from '../interfaces/pointsProgramRules'
import { include } from './boolean'

import BR from 'date-fns/locale/pt-BR'

const locale = { locale: BR }

export const filteringRulesByString = (
	rules: IHeaderRulePoint[],
	value: string
): IHeaderRulePoint[] => {
	let finalArrayWithItemsFiltereds: IHeaderRulePoint[] = []
	if (rules.length) {
		rules.forEach((rule, index, array) => {
			const ruleValidityDateFormatted = `De ${format(
				new Date(String(rule.initialDate) + 'T12:00:00'),
				'dd/MM/yy',
				locale
			)} até ${format(
				new Date(String(rule.finalDate) + 'T12:00:00'),
				'dd/MM/yy',
				locale
			)}`

			const formattedRuleCreationDate = format(
				new Date(String(rule.createdAt)),
				'dd/MM/yyyy',
				locale
			)

			const columnWithTheAmountOfProducts = rule.scoreValue
				? `${rule.itemsRulePointScoreValue?.length} ${
						rule.itemsRulePointScoreValue?.length === 1
							? 'Produto'
							: 'Produtos'
				  }`
				: `${rule.itemsRulePointScoreRate?.length} ${
						rule.itemsRulePointScoreRate?.length === 1
							? 'Produto'
							: 'Produtos'
				  }`

			if (rule.scoreValue) {
				rule.itemsRulePointScoreValue?.forEach((item) => {
					if (item.productID === value.toLowerCase()) {
						finalArrayWithItemsFiltereds[index] = array[index]
					}
				})
			} else {
				rule.itemsRulePointScoreRate?.forEach((item) => {
					if (item.productID === value.toLowerCase()) {
						finalArrayWithItemsFiltereds[index] = array[index]
					}
				})
			}

			rule.filtersRulePoint?.forEach((item) => {
				if (
					include(item.paramValue.toLowerCase(), value.toLowerCase())
				) {
					finalArrayWithItemsFiltereds[index] = array[index]
				}
			})

			const checkingIfTheSearchIsBeingCarriedOutForValidityPeriod =
				value.toLowerCase().includes('até') ||
				value.toLowerCase().includes('de')

			if (
				String(rule.scoreValue?.toLowerCase()) ===
					value.toLowerCase() ||
				String(rule.autoIncRuleId) === value ||
				String(rule.status?.toLowerCase()) === value.toLowerCase() ||
				(include(
					ruleValidityDateFormatted.toLowerCase(),
					value.toLowerCase()
				) &&
					checkingIfTheSearchIsBeingCarriedOutForValidityPeriod) ||
				formattedRuleCreationDate.toLowerCase() ===
					value.toLowerCase() ||
				columnWithTheAmountOfProducts.toLowerCase() ===
					value.toLowerCase() ||
				include(
					String(rule.observation?.toLowerCase()),
					value.toLowerCase()
				)
			) {
				finalArrayWithItemsFiltereds[index] = array[index]
			}
		})
	}
	return finalArrayWithItemsFiltereds
}
