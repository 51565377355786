import styled from 'styled-components'

interface RouteTitleProps {
  active?: boolean
}

export const SpacerBottom = styled.div`
  margin-bottom: 80px;
`

export const RoutesSection = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.gray.main};
  padding-left: ${props => props.theme.spacingOptions.MD}px;

  .MuiAccordionDetails-root {
    padding: 0;
  }

  .accordion-summary{
    background-color: #5D615E;
    box-shadow: none;
    font-size: ${props => props.theme.font.size.TEXT_XS.size}rem;
    color: ${props => props.theme.palette.white.main};

    svg {
      color: white;
    }
  }

  .routes-list-item {
    width: 100%;
  }

  .route-separate {
    border-bottom: 1px solid #979e9733;
    width: 330px;
    margin-left: -24px;
    padding: 0 24px;
    padding-bottom: 10px;
    margin-bottom: 24px;
  }

  .route-link .route-link-name {
    font-style: normal;
    font-weight: ${props => props.theme.font.weight.regular};
    font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
    color: ${(props) => props.theme.palette.background.paper};
    padding: 12px;
  }

  .route-link-active-route-link {
    .route-link-name {
      color: ${(props) => props.theme.palette.white.main};
      border-radius: 4px;
      font-weight: ${props => props.theme.font.weight.bold};
    }
  }
`

export const RouteTitle = styled.p<RouteTitleProps>`
  font-weight: ${props => props.active ? props.theme.font.weight.bold : props.theme.font.weight.regular};
`
