import styled, { css } from 'styled-components'

const containerStyle = () => css`
	width: 100%;
	margin: 0 auto;
	max-width: 1088px;
`

export const PointsProgramFAQWrapper = styled.main`
	width: 100%;
	padding: 16px;
	min-height: 100vh;
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
`

export const PointsProgramFAQContainerContent = styled.main`
	${containerStyle()}
`

export const HeaderNavigationWrapper = styled.header`
	margin-top: 136px;

	.points-program-link,
	.faq-link {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${(props) => props.theme.palette.gray.main};
	}

	.my-points-link {
		color: ${(props) => props.theme.palette.primary.main};
		cursor: pointer;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		text-decoration: underline;
	}

	margin-bottom: 24px;
`

export const BannerFAQWrapper = styled.div`
	width: 100%;
	border: 1px solid ${(props) => props.theme.palette.primaryWhite.main};
	background: ${(props) => props.theme.palette.white.main} 0% 0% no-repeat padding-box;

	opacity: 1;
	border-radius: 4px;

	gap: 27px;
	display: flex;
	align-items: center;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
	padding: 32px;
`

export const FAQBannerContent = styled.div``

export const FAQBannerTitle = styled.h2`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	margin-bottom: 13px;
`

export const FAQBannerDescription = styled.p`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

	width: 100%;
	max-width: 400px;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
`

export const QuestionsFAQWrapper = styled.section`
	width: 100%;
	margin: 40px auto;
	max-width: 720px;
	padding-bottom: 57px;
`

export const LoadingWrapper = styled.div`
	width: 100%;
	margin-top: 100px;

	display: flex;
	align-items: center;
	justify-content: center;
`
