import React from 'react'
import * as S from './rating-buttons.styles'
import { IRatingButtons } from './rating-buttons.types'

function RatingButtons({ rating, setRating }: IRatingButtons) {
	return (
		<S.ButtonsWrapper>
			{Array.from({ length: 11 }, (_, index) => (
				<S.ButtonStyled
					key={index}
					isActive={index === rating}
					onClick={() =>
						setRating(index === rating ? undefined : index)
					}
					id={`button-ratting-${index}`}>
					{index}
				</S.ButtonStyled>
			))}
		</S.ButtonsWrapper>
	)
}

export default RatingButtons
