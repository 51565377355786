import styled, { css } from 'styled-components'
import { toRem, transition } from '../../shared/styles/functions'
import { OmitStyledComponentsDefaultProps } from '../../shared/styles/types'
import { colorThemeProvider } from '../../theme/themeProvider'

interface IStyleProps
	extends OmitStyledComponentsDefaultProps<'tertiaryColor'> {
	labelColor: string
}

export const FloatLabelInputWrapper = styled.div<IStyleProps | any>`
	${({ styles, isChallengeScreen }) => css`
		position: relative;
		width: 100%;

		label.fli-label {
			left: 16px;
			position: absolute;

			${isChallengeScreen
				? css`
						top: 10px;
						font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
						font-weight: ${(props) => props.theme.font.weight.bold};
						color: ${styles.labelColor};
						text-transform: uppercase;
				  `
				: css`
						top: 18px;
						color: ${styles.secondaryColor};
						font-size: ${toRem(styles.fontSize)};
				  `}

			cursor: text;
			${transition()}
		}

		textarea.fli-input {
			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};
			border: solid 1px ${styles.secondaryColor};
			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			padding: ${styles.padding};
			width: 100%;
			max-width: 100%;
			resize: none;
			height: 90px;

			${transition()}
			&:focus,
			&:valid {
				${isChallengeScreen
					? css`
							border: 1px solid ${colorThemeProvider.grayColor};
					  `
					: css`
							border: solid 1px ${styles.primaryColor};
					  `}

				+ label {
					left: 16px;
					color: ${styles.labelColor};
					${isChallengeScreen
						? css`
								font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
						  `
						: css`
								top: 12px;
								font-size: ${toRem(styles.fontSize - 4)};
						  `}
				}
			}

			&[disabled] {
				${isChallengeScreen
					? css`
							color: ${colorThemeProvider.grayColor};
							background-color: #f5f5f5;
							border: solid 1px ${styles.secondaryColor};
					  `
					: css`
							border: dashed 1px ${styles.secondaryColor};
					  `}

				+ label {
					${isChallengeScreen
						? css`
								top: 10px;
								color: #d1d1d1;
								font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
								font-weight: ${(props) => props.theme.font.weight.bold};
								text-transform: uppercase;
						  `
						: css`
								color: ${styles.secondaryColor};
								font-size: ${toRem(styles.fontSize - 4)};
								left: 16px;
								top: 12px;
						  `}
				}
			}
		}

		.fli-counter-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			position: absolute;
			top: 70px;
		}
	`}
`

export const CharacterCounter = styled.span<any>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	${(props) =>
		props.isActive
			? css`
					color: #CE332E;
			  `
			: css`
					color: ${colorThemeProvider.grayColor};
			  `}

	${(props) =>
		props.disabled
			? css`
					color: #d1d1d1;
			  `
			: ''}
	margin-right: 8px;
	font-weight: ${(props) => props.theme.font.weight.medium};
`
