import React from 'react'
import { ButtonTypes, TypeButtonEnum } from './button-default.types'

import * as S from './button-deafult.styles'

const ButtonDefault = ({
	buttonText,
	onClick,
	type = TypeButtonEnum.PRIMARY,
	idElement,
	height = '48px',
	width = '100%',
	fontSize = '16px',
	border,
	icon,
	typeButton = 'button'
}: ButtonTypes) => {
	return (
		<>
			{type === 'primary' && (
				<S.ButtonWrapper
					type={typeButton}
					onClick={onClick}
					id={idElement}
					style={{
						height: height,
						width: width,
						fontSize: fontSize
					}}>
					{icon && <S.IconContainer>{icon}</S.IconContainer>}
					{buttonText}
				</S.ButtonWrapper>
			)}
			{type === 'disabled' && (
				<S.ButtonDisabled
					onClick={onClick}
					disabled
					id={idElement}
					style={{
						height: height,
						width: width,
						fontSize: fontSize
					}}>
					{icon && <S.IconContainer>{icon}</S.IconContainer>}
					{buttonText}
				</S.ButtonDisabled>
			)}
			{type === 'outlined' && (
				<S.ButtonOutlined
					onClick={onClick}
					id={idElement}
					style={{
						height: height,
						width: width,
						fontSize: fontSize,
						border: border
					}}>
					{icon && <S.IconContainer>{icon}</S.IconContainer>}
					{buttonText}
				</S.ButtonOutlined>
			)}
			{type === 'gradient' && (
				<S.GradientButton
					type={typeButton}
					onClick={onClick}
					id={idElement}
					style={{
						height: height,
						width: width,
						fontSize: fontSize
					}}>
					{icon && <S.IconContainer>{icon}</S.IconContainer>}
					{buttonText}
				</S.GradientButton>
			)}
		</>
	)
}

export default ButtonDefault
