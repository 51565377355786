import { InputAdornment } from '@mui/material'
import { TextField } from '@mui/material'
import styled from 'styled-components'

export const InputWrapper = styled('div')`
	position: relative;
	width: 100%;
`

export const Input = styled(TextField)`
	.Mui-error {
		color: ${(props) => props.theme.palette.primary.main};
	}
	&.MuiTextField-root {
		background-color: ${(props) => props.theme.palette.background.paper};
		width: 100%;
	}

	&:hover .MuiOutlinedInput-notchedOutline,
	&.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.theme.palette.primary.main};
		border-width: 1px;
	}

	&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.theme.palette.primary.main};
		border-width: 1px;
	}

	& .MuiOutlinedInput-root {
		width: 100%;
		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}
	}
	& label.Mui-focused {
		color: ${(props) => props.theme.palette.primary.main};
	}

	& .MuiOutlinedInput-notchedOutline {
		border-width: 1px;
	}

	& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
		background-color: ${(props) => props.theme.palette.background.default};

		& ~ .MuiOutlinedInput-notchedOutline {
			border-color: ${(props) => props.theme.palette.lightGray.main};
		}
	}
`

export const CountCharacters = styled(InputAdornment)`
	font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
	color: ${(props) => props.theme.palette.primary.main};
`
