import React from 'react'

import { IProductForRedemptionProps } from './types'

import * as S from './styles'

export default function ProductForRedemption(
	props: IProductForRedemptionProps
) {
	const { product, handleClick } = props

	const filteredCharacteristics = product.Characteristics.filter(
		(item) =>
			item.Type === 'Category' ||
			item.Type === 'Brand' ||
			item.Type === 'Package'
	)

	return (
		<S.ProductForRedemptionWrapper onClick={() => handleClick(product)}>
			<S.ProductImageWrapper>
				<S.ProductImage src={product.ImageUrl} />
			</S.ProductImageWrapper>
			<S.ProductDescription>{product.Description}</S.ProductDescription>
			<S.LabelsPointsWrapper>
				<S.labelPointsForRedemption>
					Pontos para resgate
				</S.labelPointsForRedemption>
				<S.labelPoints>{product.ScoreRate}</S.labelPoints>
			</S.LabelsPointsWrapper>
			<S.CharacteristicsWrapper>
				{filteredCharacteristics.map((item) => (
					<S.Characteristic key={item.Type}>
						<S.CharacteristicLabel>
							{item.Type}
						</S.CharacteristicLabel>
						<S.CharacteristicValue>
							{item.Description}
						</S.CharacteristicValue>
					</S.Characteristic>
				))}
			</S.CharacteristicsWrapper>
		</S.ProductForRedemptionWrapper>
	)
}
