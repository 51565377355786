import { format } from 'date-fns'
import { OptionProps } from '../../../utils/options-props'

import { ITableNavProps } from '../../../components/Table/types'
import { CouponType } from '../create-coupon/services/types/coupon.types'
import { IGetCouponsService } from './services/table-coupons.services'
import { FULL_TIME_DATE_PT_BR } from '../../../utils/constants/date-format'

export interface IDiscountItems {
	ID: string
	createdAt: string
	createdBy: string
	modifiedAt: string
	modifiedBy: string
	couponID: number
	category: string
	materialID: string
	package: string
	description: string
	brand: string
}
export interface ITableDataCoupons {
	ID: string
	createdAt: string
	createdBy: string
	modifiedBy: string
	modifiedAt: string
	couponID: number
	name: string
	code: string
	couponType: CouponType
	minOrderValue: number
	discountType: string
	discountValue: number
	cumulative: boolean
	dateEnd: string
	dateStart: string
	discountCategory: string
	quantityAvailable: number
	showIn: string
	cupomUsedCount: number
	status: boolean
	tradeStatus: string
	filters: any //alterar
	amountOfCustomers: string
	isPdvRelated: boolean
	creationStatus: string
	discount_items?: IDiscountItems[]
}
export interface IColumns {
	Header: string
	acessor: string
	sortType: string
}

export interface ITableDataProps {
	tableData: ITableDataCoupons[]
	columns: any
	navigationHandles: ITableNavProps
}

export const initialFetchProps: IGetCouponsService = {
	pageNumber: '1',
	pageSize: '10',
	status: 'true',
	name: '',
	tradeStatus: '',
	finished: false
}
export const options: OptionProps[] = [
	{
		name: 'Ativos',
		value: 'Aprovado'
	},
	{
		name: 'Inativos',
		value: 'inactive'
	},
	// {
	// 	name: 'Em Análise',
	// 	value: 'Em análise'
	// },
	// {
	// 	name: 'Reprovados',
	// 	value: 'Reprovado'
	// },
	{
		name: 'Finalizados',
		value: 'finished'
	}
]
export enum TypeOptions {
	ANALISE = 'Em análise',
	REPROVADO = 'Reprovado',
	ATIVO = 'Ativo'
}

export interface IParagraphProps {
	variant: (typeof options)[number]['value']
}

export const formatDate = (date: string) => {
	const data = new Date(date)
	return format(data, FULL_TIME_DATE_PT_BR)
}
export interface ICellTableData {
	row: {
		original: ITableDataCoupons
	}
}

export interface IResponseGetCoupons {
	totalItems: number
	totalPages: number
	data: ITableDataCoupons[]
}

export interface IViewProps {
	columns: any
	tableData: ITableDataCoupons[]
	isLoading: boolean
	navProps: ITableNavProps
	fetchCoupon: ITableDataCoupons
	getCsvCupons: () => Promise<string>
	successesMessage: boolean
	setSuccessesMessage: React.Dispatch<React.SetStateAction<boolean>>
	onChangeOptions: (option: OptionProps) => void
	handleSearchCouponByIdOrName: (search: any) => Promise<void>
	isOpenModal: boolean
	setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const unlimitedQtdCoupon = 1000000
