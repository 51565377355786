import React from 'react'
import { TablePagination } from '@material-ui/core'

export const PaginationTable = ({
	data,
	rowsPerPage,
	page,
	handleChangePage,
	handleChangeRowsPerPage
}: any) => {
	return (
		<>
			<TablePagination
				rowsPerPageOptions={[10]}
				component="div"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				labelDisplayedRows={({ from, to, count }) => {
					return `${from}–${to} de ${
						count !== -1 ? count : `mais de ${to}`
					}`
				}}
			/>
		</>
	)
}
