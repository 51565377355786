import { IFiltersHierarchy } from "../typings"

export function createGrcDirectorMap(data: IFiltersHierarchy) {
	const directorGrcMap: { [key: string]: string } = {}
	data.salesHierarchy.forEach((item) => {
		item.govs.forEach((govInfo) => {
			const director = item.director
			if (!director || !govInfo.gov) return
			directorGrcMap[govInfo.gov] = director
		})
	})

	return directorGrcMap
}
