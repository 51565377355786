import React, { useState } from 'react'
import {
	Loading,
	RuleOrChallengeActionModal,
	Table
} from '../../shared/components'

import SearchInput from '../../components/SearchInput'
import { ParametersDetailsModal } from '../../shared/components'

import { IViewProps } from './types'

import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'

import * as S from './styles'

export default function PointsEarnedRatioView(props: IViewProps) {
	const {
		columns,
		tableData,
		showModal,
		modalData,
		isLoading,
		searchString,
		ruleIDToDeleteIt,
		handlerCloseModal,
		searchStringHandler,
		showDeleteConfirmationModal,
		handleShowDeleteConfirmationModal,
		handleToPointsProgramRulesPointsGainRatioCreating,
		rulesData,
		fetchPointsProgramOnPage
	} = props

	const [pageIndex, setPageIndex] = useState(0)
	return (
		<DefaultLayoutAdmin>
			<S.PageWrapper>
				<ParametersDetailsModal
					title="Parâmetros da regra"
					showModal={showModal}
					parameters={modalData}
					handlerCloseModal={handlerCloseModal}
				/>
				<RuleOrChallengeActionModal
					type="RULE"
					typeAction="EXCLUSION"
					ruleOrChallengeID={ruleIDToDeleteIt}
					showModal={showDeleteConfirmationModal}
					handleCloseModal={handleShowDeleteConfirmationModal}
				/>
				<S.PointsEarnedRatioWrapper>
					<S.PointsEarnedRatioTitle>
						Proporção Ganhos de Pontos
					</S.PointsEarnedRatioTitle>
					<S.InputAndButtonWrapper>
						<S.SearchInputWrapper>
							<SearchInput
								inputValue={searchString}
								handleChange={searchStringHandler}
							/>
						</S.SearchInputWrapper>
						<S.ButtonWrapper className="buttonContainer">
							<button
								onClick={
									handleToPointsProgramRulesPointsGainRatioCreating
								}
								className="red-button">
								NOVA REGRA
							</button>
						</S.ButtonWrapper>
					</S.InputAndButtonWrapper>
					<div>
						{isLoading ? (
							<S.LoadingWrapper className="loading-wrapper">
								<Loading />
							</S.LoadingWrapper>
						) : (
							<Table
								data={tableData}
								columns={columns}
								navProps={{
									gotoPage(selectedIndex: number) {
										fetchPointsProgramOnPage(selectedIndex)
										setPageIndex(selectedIndex)
									},
									nextPage(index = pageIndex) {
										fetchPointsProgramOnPage(index)
										setPageIndex(index)
									},
									previousPage(index = pageIndex) {
										fetchPointsProgramOnPage(index)
										setPageIndex(index)
									},
									pageCount: rulesData?.totalPages,
									pageIndex: pageIndex,
									totalDocs: rulesData!.totalItems
								}}
							/>
						)}
					</div>
				</S.PointsEarnedRatioWrapper>
			</S.PageWrapper>
		</DefaultLayoutAdmin>
	)
}
