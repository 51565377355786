import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IPointsProgramStatusActionCreators
} from '../../interfaces/action'
import { IPointsProgramStatus } from '../../interfaces/pointsProgram'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<IPointsProgramStatusActionCreators> = createActions({
	setPointsProgramStatus: ['pointsProgramStatus'],
	resetPointsProgramStatus: []
})

/**
 * Handlers
 */

export const INITIAL_STATE: IPointsProgramStatus = {
	userJoinedTheProgram: false,
	salesOrgHasProgramPoints: false
}

const set = (state = INITIAL_STATE, action: any) => action.pointsProgramStatus
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<
	IPointsProgramStatus,
	Action<IPointsProgramStatusActionCreators>
>(INITIAL_STATE, {
	[Types.SET_POINTS_PROGRAM_STATUS]: set,
	[Types.RESET_POINTS_PROGRAM_STATUS]: reset
})
