import { useCallback, useState } from 'react'
import {
	deliveryManagementAdminRulesType,
	deliveryManagementAdminSingleRuleType,
	DeliveryManagementeRulesFileHeaders,
	fileType
} from '../delivery-management-admin.types'
import { useMutation } from 'react-query'
import deliveryManagementAdmin from '../delivery-management-admin.service'

import { RequestError } from '../../../types/request-error.type'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../shared/utils/toaster'

export function useDeliveryManagement() {
	const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false)
	const [datesRules, setDatesRules] = useState<
		deliveryManagementAdminSingleRuleType[]
	>([])
	const [haveCsv, setHaveCsv] = useState<boolean>(false)

	const [fileData, setFileData] = useState<fileType>({
		name: '',
		size: 0,
		type: ''
	})
	const [fileDataArr, setFileDataArr] =
		useState<deliveryManagementAdminRulesType>([])

	const isSpreedSheetUploaded = fileDataArr.length > 0

	const handleDownloadSpreadSheet = useCallback(() => {
		const link = document.createElement('a')
		link.href = '/files/exemplo-de-regras.csv'
		link.setAttribute('download', 'exemplo-de-regras.csv')

		document.body.appendChild(link)
		link.click()

		document.body.removeChild(link)
	}, [])

	const handleOnLoadSpreadSheet = useCallback(
		(data: any[], file: fileType) => {
			setFileData({ ...file })
			setFileDataArr(data)
		},
		[]
	)
	const rulesCsvToObjMapper = useCallback((data: any) => {
		const newRule: deliveryManagementAdminSingleRuleType = {
			salesOrganizationId: data[DeliveryManagementeRulesFileHeaders.SalesOrganizationID],
			cityName: data[DeliveryManagementeRulesFileHeaders.CityName],
			state: data[DeliveryManagementeRulesFileHeaders.StateName],
			cutoffTime1: data[DeliveryManagementeRulesFileHeaders.CutOffTime1],
			cutoffTime2: data[DeliveryManagementeRulesFileHeaders.CutOffTime2],
			saturdayDelivery:
				data[DeliveryManagementeRulesFileHeaders.SaturdayDelivery],
			deliveryLeadTimeDays:
				data[DeliveryManagementeRulesFileHeaders.DeliveryLeadTimeDays]
		}
		return newRule
	}, [])

	const validateHeaders = useCallback((headers: string[]) => {
		const missingHeaders = Object.values(
			DeliveryManagementeRulesFileHeaders
		).filter((header) => !headers.includes(header))
		return missingHeaders
	}, [])

	const handleRemoveSpreadSheet = useCallback(() => {
		setFileDataArr([])
		setFileData({
			name: '',
			size: 0,
			type: ''
		})
		setHaveCsv(false)
		setDatesRules([])
	}, [])

	// Sera tratado na task de tratamento de dados
	const handleParserOptions = (text: string) => text

	const { mutate: handleUploadData, isLoading: loadingUploadData } =
		useMutation({
			mutationFn: async () => {
				const res = await deliveryManagementAdmin.deliveryDateRules(
					datesRules
				)

				return res
			},
			onError(error: RequestError) {
				cogoToast.error(error.message, cogoDefaultOptions)
			},
			onSuccess() {
				// Mensagem de sucesso
				setShowSuccessMessage(true)

				// Resetando campos
				handleRemoveSpreadSheet()
			}
		})

	return {
		showSuccessMessage,
		setShowSuccessMessage,
		fileData,
		isSpreedSheetUploaded,
		handleDownloadSpreadSheet,
		handleOnLoadSpreadSheet,
		handleRemoveSpreadSheet,
		handleParserOptions,
		handleUploadData,
		loadingUploadData,
		rulesCsvToObjMapper,
		validateHeaders,
		datesRules,
		setDatesRules,
		haveCsv,
		setHaveCsv
	}
}
