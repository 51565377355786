import { compareAsc, compareDesc, parseISO } from 'date-fns'
import { createElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ITableOrderItem } from '../../shared/components/OrderTable/types'
import { IOrderDetail } from '../../shared/interfaces/orderDetail'
import { IStore } from '../../shared/interfaces/store'
import { Creators as OrderActions } from '../../shared/store/ducks/orderHistory'
import { deepCopy } from '../../shared/utils'
import {
	formatDeliveryDate,
	parseFormat,
	parseFormatToDate
} from '../../shared/utils/date'
import {
	calcPriceTotal,
	generatePaymentTermsDescription,
	PaymentConditionsEnum,
	PaymentFormsEnum
} from '../../shared/utils/money'
import { calcPrice, returnLatestStatus } from '../../shared/utils/order'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { getOrderHistory } from './service'
import { ILastOrder, IViewProps } from './types'
import OrderHistory from './view'

function OrderHistoryContainer(): JSX.Element {
	const history = useHistory()
	const { customer, orderHistory } = useSelector((state: IStore) => state)
	const dispatch = useDispatch()
	const { updateOrderHistory } = OrderActions

	const [isLoading, setIsLoading] = useState(false)
	const [showReloadOption, setShowReloadOption] = useState(false)

	const [tableData, setTableData] = useState<Array<ITableOrderItem>>([])
	const [lastOrder, setLastOrder] = useState<ILastOrder>({
		address: '',
		CEP: '',
		destinatary: '',
		invoiceNumber: '',
		orderDate: '',
		orderID: '',
		orderNumber: '',
		orderStatus: '',
		priceTotal: '',
		paymentMethod: '',
		orderSource: 'VB2A',
		deliveryDate: ''
	})

	function loadOrderHistory() {
		;(async () => {
			dispatch(updateOrderHistory([]))

			setIsLoading(true)
			try {
				const orderHistory = await getOrderHistory({
					customerID: customer.CustomerID,
					salesOrganizationID: customer.SalesOrganizationID
				})

				if (!orderHistory || !orderHistory.length) {
					dispatch(updateOrderHistory([]))
					setTableData([])
					setIsLoading(false)
					return
				}

				const orderHistorySorted = orderHistory
					.map((order) => ({
						...order,
						OrderStatus: deepCopy(order.OrderStatus).sort(
							(a, b) => {
								return compareAsc(
									parseFormatToDate(a.EventDateTime),
									parseFormatToDate(b.EventDateTime)
								)
							}
						),
						RequestsCancelStatus: deepCopy(
							order.RequestsCancelStatus
						).sort((a, b) => {
							return compareAsc(
								parseFormatToDate(a.CreatedAt),
								parseFormatToDate(b.CreatedAt)
							)
						})
					}))
					.sort((a, b) =>
						compareDesc(
							parseISO(a.CreatedAt),
							parseISO(b.CreatedAt)
						)
					)

				dispatch(updateOrderHistory(orderHistorySorted))
			} catch (error) {
				setShowReloadOption(true)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleReload() {
		setShowReloadOption(false)
		loadOrderHistory()
	}

	function handleGoDetail(order: IOrderDetail) {
		history.replace(
			CustomerRoutesEnum.ORDER_DETAIL + '/' + order.SalesOrderID,
			{
				orderDetails: order
			}
		)
	}

	function handleGoProductList() {
		history.replace(CustomerRoutesEnum.PRODUCT_LIST)
	}

	async function handleTableData() {
		const tableData: Array<ITableOrderItem> = orderHistory.map((order) => {
			const totalItemsToCalculateTheOrderPrice = order.ItemsChallenge
				?.length
				? [...order.Items, ...order.ItemsChallenge]
				: [...order.Items]

			return {
				date: parseFormat(order.CreatedAt),
				number: String(order.AutoIncBackendID),
				status: returnLatestStatus(order),
				value: calcPrice(totalItemsToCalculateTheOrderPrice),
				InvoiceNumber: order.InvoiceNumber,
				paymentMethod: generatePaymentTermsDescription(
					order.PaymentConditionID as PaymentConditionsEnum,
					order.PaymentFormID as PaymentFormsEnum,
					calcPriceTotal(totalItemsToCalculateTheOrderPrice)
				),
				orderID: order.SalesOrderID,
				priceAction: 'R$ 136,00',
				orderSource: order.orderSource,
				orderDetails: order
			}
		})

		setTableData(tableData)
		setIsLoading(false)
	}

	function handleLastOrder() {
		if (!orderHistory.length) return

		const lastOrder = orderHistory[0]

		const orderStatus = returnLatestStatus(lastOrder)

		if (!lastOrder) return

		const totalItemsToCalculateTheOrderPrice = lastOrder.ItemsChallenge
			?.length
			? [...lastOrder.Items, ...lastOrder.ItemsChallenge]
			: [...lastOrder.Items]

		const lastOrderToSet: ILastOrder = {
			address: `${customer.Street}, ${customer.HouseNumber}`,
			CEP: customer.PostalCode,
			destinatary: customer.Name,
			invoiceNumber: lastOrder.InvoiceNumber,
			orderDate: parseFormat(lastOrder.CreatedAt),
			orderID: lastOrder.SalesOrderID,
			orderNumber: String(lastOrder.AutoIncBackendID),
			orderStatus: orderStatus,
			priceTotal: calcPrice(totalItemsToCalculateTheOrderPrice),
			paymentMethod: generatePaymentTermsDescription(
				lastOrder.PaymentConditionID as PaymentConditionsEnum,
				lastOrder.PaymentFormID as PaymentFormsEnum,
				calcPriceTotal(totalItemsToCalculateTheOrderPrice)
			),
			orderSource: lastOrder.orderSource,
			deliveryDate: formatDeliveryDate(parseISO(lastOrder.DeliveryDate)),
			orderDetails: lastOrder
		}

		setLastOrder(lastOrderToSet)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadOrderHistory, [])

	useEffect(() => {
		if (!orderHistory.length) return
		handleTableData()
		handleLastOrder()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderHistory])

	const viewProps: IViewProps = {
		handleGoDetail,
		isLoading,
		lastOrder,
		tableData,
		handleGoProductList,
		showReloadOption,
		handleReload
	}

	return createElement(OrderHistory, viewProps)
}

export default OrderHistoryContainer
