import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProductManagerView } from './product-manager.view'
import { useProducts } from './service'
import {
	IDualListProduct,
	IDualListProductOption,
	IProduct,
	IProductManager
} from './types'
import { removeDuplicates } from '../../../../utils/remove-duplicates'

export const ProductManager = ({
	edit,
	selectedProducts,
	onChange
}: IProductManager) => {
	const { getProducts, products, loading } = useProducts()
	const [productsToShowInList, setProductsToShowInList] = useState<
		IDualListProductOption[]
	>([])

	// converte padrão da api para o padrão de DuaListBoxComponent
	const allProducts: IDualListProduct[] = useMemo(
		() =>
			Object.entries(
				products.reduce(
					(acc, obj) => {
						const category = obj.Category
						if (!acc[category]) {
							acc[category] = []
						}
						let isInside = false

						selectedProducts.forEach((item) => {
							if (item.materialId === obj.MaterialID) {
								isInside = true
								obj.materialQuantity =
									item.materialQuantity || 1
							}
						})

						if (isInside) {
							acc[category].push({
								label: obj.Description,
								value: obj.MaterialID,
								materialQuantity: obj.materialQuantity || 1
							})
						} else {
							acc[category].push({
								label: obj.Description,
								value: obj.MaterialID,
								materialQuantity: obj.materialQuantity || 1
							})
						}

						return acc
					},
					{} as Record<
						string,
						{
							label: string
							value: string
							materialQuantity: number
						}[]
					>
				)
			).map(([key, value]) => ({ label: key, options: value })),
		[products, selectedProducts]
	)

	// Produtos para serem carregados inicialmente, não utilizar como estado
	const initialSelectedProducts: IDualListProduct[] = useMemo(
		() =>
			Object.entries(
				products.reduce(
					(acc, obj) => {
						selectedProducts.forEach((selectedProduct) => {
							if (obj.MaterialID === selectedProduct.materialId) {
								const category = obj.Category
								if (!acc[category]) {
									acc[category] = []
								}
								acc[category].push({
									label: obj.Description,
									value: obj.MaterialID,
									materialQuantity:
										selectedProduct.materialQuantity || 1
								})
							}
						})

						return acc
					},
					{} as Record<
						string,
						{
							label: string
							value: string
							materialQuantity: number
						}[]
					>
				)
			).map(([key, value]) => ({ label: key, options: value })),
		[products, selectedProducts]
	)

	const productsListed = useMemo(() => {
		const formattedList: IProduct[] = []
		products.filter((product) =>
			productsToShowInList.forEach((toShowInList) => {
				if (toShowInList.value === product.MaterialID) {
					formattedList.push({
						...product,
						materialQuantity: toShowInList.materialQuantity
					})
				}
			})
		)
		return formattedList
	}, [products, productsToShowInList])

	const handleSelectProducts = useCallback(
		(newProducts: IDualListProductOption[]) => {
			setProductsToShowInList(newProducts)
			const submitedData: IProduct[] = []

			newProducts.forEach((item) => {
				products.forEach((product) => {
					if (item.value === product.MaterialID) {
						submitedData.push({
							...product,
							materialQuantity: item.materialQuantity
						})
					}
				})
			})

			onChange(submitedData)
		},
		[onChange, products]
	)

	const handleChangeListedValues = useCallback(
		(newProduct: IDualListProductOption) => {
			const newState = productsToShowInList.map((item) => {
				if (item.value === newProduct.value) {
					item.materialQuantity = newProduct.materialQuantity
				}
				return item
			})
			handleSelectProducts(newState)
		},
		[handleSelectProducts, productsToShowInList]
	)

	useEffect(() => {
		getProducts()
	}, [getProducts])

	useEffect(() => {
		setProductsToShowInList(
			selectedProducts.map((item) => {
				const foundedItem = products.find(
					(product) => product.MaterialID === item.materialId
				)
				return {
					label: foundedItem?.Description || '',
					value: foundedItem?.MaterialID || '',
					materialQuantity: item?.materialQuantity || 1
				}
			})
		)
	}, [products, selectedProducts])

	return (
		<ProductManagerView
			edit={edit}
			loading={loading}
			allProducts={removeDuplicates(allProducts, 'label')}
			initialSelectedProducts={removeDuplicates(
				initialSelectedProducts,
				'label'
			)}
			key={String(initialSelectedProducts.length)}
			productsListed={productsListed}
			handleSelectProducts={handleSelectProducts}
			handleChangeListedValues={handleChangeListedValues}
		/>
	)
}
