import 'react-day-picker/lib/style.css'

import { addDays, format, isPast, isValid, parse, parseISO } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import {
	monthNamesArray,
	weekNamesLong,
	weekNamesShort
} from '../../shared/utils/date'
import { IProps } from './types'

import CalendarIcon from '../../assets/images/calendar.svg'

function DatePicker({
	dateSetter,
	value,
	disabledDaysObj = {
		before: addDays(new Date(), 1),
		after: addDays(new Date(), 20)
	}
}: IProps) {

	const FORMAT = "dd 'de' LLLL yyyy"

	const locale = { locale: BR }

	function handleDayChange(date: Date): void {
		dateSetter(date)
	}

	function handleFormatDate(date: Date): string {
		if (!date || !isValid(date)) return ''

		return format(date, FORMAT, locale)
	}

	function handleParseDate(date: string): Date | void {
		if (!date || !isValid(parseISO(date))) return

		if (isPast(parseISO(date))) return

		return parse(date, FORMAT, new Date(), locale)
	}

	// const disabledDaysT = {
	// 	before: addDays(new Date(), 1),
	// 	after: addDays(new Date(), 20)
	// }

	const dayPickerProps = {
		months: monthNamesArray,
		weekdaysLong: weekNamesLong,
		weekdaysShort: weekNamesShort,
		disabledDays: disabledDaysObj,
		renderDay: (date: Date) => <p>{date.getDate()}</p>,
		renderWeek: (weekNumber: number) => <p>{weekNamesLong[weekNumber]}</p>
	}

	return (
		<div className="date-picker-primary">
			<img className="calendar-icon" src={CalendarIcon} alt="Icone" />
			<DayPickerInput
				ref={(ref: DayPickerInput) => ref}
				onDayChange={handleDayChange}
				value={value}
				formatDate={handleFormatDate}
				parseDate={handleParseDate}
				placeholder={'Escolha uma data'}
				onDayPickerHide={() => null}
				onDayPickerShow={() => null}
				inputProps={{ readOnly: true }}
				dayPickerProps={dayPickerProps}
			/>
		</div>
	)
}

export default DatePicker
