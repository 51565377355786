import styled from 'styled-components'
import { theme } from '../../../../../theme/themeProvider'

interface ButtonStylesProps {
	isSelected: boolean | undefined
}

export const WrapperButtonSelected = styled.button<ButtonStylesProps>`
	width: 169px;
	height: 40px;

	background: #ffffff;
	border-radius: 5px;

	border: ${(props) =>
		props.isSelected ? `1px solid ${theme.palette.primary.main}` : 'none'};

	font-size: 10px;
	font-weight: 700;
	color: ${(props) =>
		props.isSelected
			? theme.palette.primary.main
			: theme.palette.lightGray.main};

	cursor: pointer;
`
