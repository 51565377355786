import { axiosGPInstance } from '../../shared/services/axiosInstace'
import { IParameterItem } from './types'

export async function fetchParams(): Promise<IParameterItem[]> {
	try {
		const response = await axiosGPInstance.get(`/parameters`)

		return response.data
	} catch (error) {
		return []
	}
}

export async function saveParam(
	param: Omit<IParameterItem, 'value'>
): Promise<any> {
	try {
		const response = await axiosGPInstance.post(`/parameters`, {
			...param
		})

		return response.data
	} catch (error) {
		throw error
	}
}

export async function deleteParam(
	param: Omit<IParameterItem, 'value'>
): Promise<any> {
	try {
		const paramToDelete = {
			...param,
			LogicalDelete: 'X'
		}

		const response = await axiosGPInstance.post(
			`/parameters`,
			paramToDelete
		)

		return response.data
	} catch (error) {
		throw error
	}
}
