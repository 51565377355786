import React from 'react'
import { IProps } from './types'
import { StepContainer, StepLabel, StepTitle, StepWrapper } from './styles'
import { colorThemeProvider } from '../../theme/themeProvider'

export default function StepCreateAccount({ stepCurrent, stepTitle }: IProps) {
	return (
		<StepContainer>
			<StepWrapper
				style={{
					borderRight: `3px solid ${
						stepCurrent >= 1
							? colorThemeProvider.successColor
							: colorThemeProvider.extrasColor
					}`,
					borderBottom: `3px solid ${
						stepCurrent >= 2
							? colorThemeProvider.successColor
							: colorThemeProvider.extrasColor
					}`,
					borderLeft: `3px solid ${
						stepCurrent >= 3
							? colorThemeProvider.successColor
							: colorThemeProvider.extrasColor
					}`,
					borderTop: `3px solid ${
						stepCurrent >= 4
							? colorThemeProvider.successColor
							: colorThemeProvider.extrasColor
					}`
				}}>
				<StepLabel>{stepCurrent} DE 4</StepLabel>
			</StepWrapper>
			<StepTitle>{stepTitle}</StepTitle>
		</StepContainer>
	)
}
