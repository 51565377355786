import React, { useCallback, useEffect, useState } from 'react'
import { ISelect } from '../../../components/SelectPrimary/types'
import { InputField, Loading, SelectPrimary } from '../../../shared/components'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { getUserDeliveryDate } from '../../../shared/services/customer.service'
import { DeliveryWrapper } from '../styles'
import { formatDeliveryDateInput } from '../util'
import { IViewProps } from './types'

function DeliveryDate(props: IViewProps): JSX.Element {
	const { value, onChange, handleContinue, handleGoBack, paymentStep } = props
	const { customer } = useTypedSelector(['customer'])
	const [options, setOptions] = useState<ISelect[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)

	function friendly(text: string) {
		return text.trim().toLowerCase()
	}

	const renderOption = useCallback(async () => {
		try {
			setIsLoading(true)
			const userDeliveryDate = await getUserDeliveryDate({
				customerID: customer.CustomerID,
				salesOrganizationID: customer.SalesOrganizationID,
				sectorID: customer.sectorID
			})
			setIsLoading(false)
			const format = formatDeliveryDateInput(
				userDeliveryDate.map(
					(date: { deliveryDate: string }) => date.deliveryDate
				)
			)
			if (!format) return []

			return [{ label: format, value: format }]
		} catch (error) {
			return []
		}
	}, [customer])

	useEffect(() => {
		const fetchOptions = async () => {
			const result = await renderOption()
			setOptions(result)
		}

		fetchOptions()
	}, [renderOption])

	return (
		<DeliveryWrapper
			className={`container ${!paymentStep?.active ? 'hide-block' : ''}`}>
			<div className="row info">
				<div className="col-sm-12 col-lg-3">
					<label className="multiselect-label">
						ENTREGA PROGRAMADA PARA ATÉ:
					</label>
					{isLoading ? (
						<Loading />
					) : (
						<SelectPrimary
							options={options}
							onChange={onChange}
							value={value}
							isLoading={false}
							isClearable={false}
							placeholder="Escolha uma data"
							classNamePrefix="react-select"
							className={`react-select select-group`}
						/>
					)}
				</div>

				<div className="col-lg-7 adress-select-disabled">
					<label className="multiselect-label">
						PARA O ENDEREÇO:
					</label>
					<InputField
						disabled
						id="address"
						secondary
						label=""
						value={`${friendly(customer.Street)}, ${friendly(
							customer.HouseNumber
						)}, ${friendly(customer.Neighborhood)} ${
							friendly(customer.NickName)
								? '- ' + friendly(customer.NickName)
								: ''
						} - ${friendly(customer.City)}/${customer.Region}`}
					/>
				</div>
			</div>

			<div className="col-8 info-text">
				<p>
					Lembrando que sua entrega pode variar de acordo com a
					aprovação do seu pedido, local de entrega e o horário de
					funcionamento do seu estabelecimento.
				</p>
			</div>
			<div className="buttons">
				<div>
					<button
						className="green-button"
						onClick={handleContinue}
						disabled={value ? false : true}>
						Continuar
					</button>
				</div>
				<button className="white-button" onClick={handleGoBack}>
					Voltar
				</button>
			</div>
		</DeliveryWrapper>
	)
}

export default DeliveryDate
