import {
	checkCNPJ,
	checkCPF,
	checkEmail,
	checkEmailMatch,
	checkRG,
	checkTel
} from '../../shared/utils/validators'
import { IInputProps, INewClient } from './types'

export const className = 'input-tertiary'

const disabled = false

const required = true

export const personalFields = (
	customer: INewClient,
	documentType: string
): Array<IInputProps> => [
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'name',
		keyName: 'Name',
		label: 'Seu Nome',
		required,
		value: customer.Name
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		errorMessage: 'INFORME UM E-MAIL VÁLIDO!',
		id: 'email',
		keyName: 'Email',
		label: 'E-mail',
		required,
		validator: checkEmail,
		value: customer.Email,
		disabledCopyPaste: true
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'ConfirmEmail',
		keyName: 'ConfirmEmail',
		errorMessage: 'O E-MAIL DEVE SER IGUAL AO INFORMADO!',
		label: 'CONFIRMAR E-MAIL',
		required,
		validator: () => checkEmailMatch(customer.Email, customer.ConfirmEmail),
		value: customer.ConfirmEmail,
		disabledCopyPaste: true
	},
	{
		className,
		containerClass: 'input-wrapper-6 margin',
		disabled,
		errorMessage: 'CPF Inválido',
		id: 'cpf',
		keyName: 'PersonalFiscalID',
		label: documentType === 'CPF' ? 'CPF' : 'CPF do Fiel Depositário',
		mask: '999.999.999-999999',
		required:
			documentType === 'CPF' || customer.PersonalFiscalID ? true : false,
		validator: checkCPF,
		value: customer.PersonalFiscalID
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		errorMessage: 'RG INVÁLIDO!',
		id: 'rg',
		keyName: 'IdentificationRegistryID',
		label: documentType === 'CPF' ? 'RG' : 'RG do Fiel Depositário',
		mask: '**.***.***-**',
		required:
			documentType === 'CPF' || customer.IdentificationRegistryID
				? true
				: false,
		validator: checkRG,
		value: customer.IdentificationRegistryID
	},
	{
		className,
		containerClass: 'input-wrapper-6 margin',
		disabled,
		errorMessage: 'Telefone Inválido',
		id: 'cel',
		keyName: 'CellphoneNumber',
		label: 'Celular',
		mask: '(99) 99999-9999',
		required,
		validator: checkTel,
		value: customer.CellphoneNumber
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		errorMessage: 'Telefone Inválido',
		id: 'phone',
		keyName: 'PhoneNumber',
		label: 'Fixo (Opcional)',
		mask: '(99) 9999-9999',
		required: false,
		validator: checkTel,
		value: customer.PhoneNumber
	}
]
export const alternativePersonalInfo = (customer: INewClient) => {
	return {
		questionOne: customer.questionOne,
		questionTwo: customer.questionTwo,
		answerOne: customer.answerOne,
		answerTwo: customer.answerTwo,
		alternativeEmail: customer.alternativeEmail,
		alternativeSms: customer.alternativeSms
	}
}

export const storeFields = (customer: INewClient): Array<IInputProps> => [
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'companyName',
		keyName: 'NickName',
		label: 'Nome Fantasia Do Estabelecimento',
		required,
		value: customer.NickName,
		errorMessage: 'Campo Obrigatório',
		validator: () => customer?.NickName?.length > 0
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		// disabled: customer.CompanyFiscalID.length ? true : false,
		disabled: false,
		errorMessage: 'CNPJ Inválido',
		id: 'cnpj',
		keyName: 'CompanyFiscalID',
		label: 'CNPJ',
		mask: '99.999.999/9999-99',
		required: false,
		validator: checkCNPJ,
		value: customer.CompanyFiscalID
	},

	{
		className,
		containerClass: 'input-wrapper-6 margin',
		disabled,
		id: 'cep',
		keyName: 'PostalCode',
		label: 'CEP',
		mask: '99999-999',
		required,
		value: customer.PostalCode,
		errorMessage: 'Campo Obrigatório',
		validator: () => customer?.PostalCode?.length > 7
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		id: 'number',
		keyName: 'HouseNumber',
		label: 'Número',
		required: false,
		value: customer.HouseNumber
	},

	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'street',
		keyName: 'Street',
		label: 'Nome da Rua',
		required,
		value: customer.Street,
		errorMessage: 'Campo Obrigatório',
		validator: () => customer?.Street?.length > 2
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'neighborhood',
		keyName: 'Neighborhood',
		label: 'Bairro',
		required,
		value: customer.Neighborhood,
		errorMessage: 'Campo Obrigatório',
		validator: () => customer?.Neighborhood?.length > 2
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'address2',
		keyName: 'AdressComplement',
		label: 'Complemento (Opcional)',
		required: false,
		value: customer.AdressComplement
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		id: 'address2',
		keyName: 'AdressReference',
		label: 'Ponto de Referência (Opcional)',
		required: false,
		value: customer.AdressReference
	},

	{
		className,
		containerClass: 'input-wrapper-6',
		disabled: true,
		id: 'state',
		keyName: 'Region',
		label: 'Estado',
		placeholder: 'ESTADO',
		required,
		value: customer.Region
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled: true,
		id: 'city',
		keyName: 'City',
		label: 'Cidade',
		placeholder: 'CIDADE',
		required,
		value: customer.City
	}
]

export const getFields = (customer: INewClient, documentType: string) => {
	return [personalFields(customer, documentType), storeFields(customer)]
}

export const DEFAULT_CLIENT: INewClient = {
	AdressComplement: '',
	CellphoneNumber: '',
	City: '',
	CompanyFiscalID: '',
	Email: '',
	HouseNumber: '',
	IdentificationRegistryID: '',
	Name: '',
	Neighborhood: '',
	NickName: '',
	PersonalFiscalID: '',
	PhoneNumber: '',
	PostalCode: '',
	Region: '',
	Street: '',
	InvoiceNumberConfirmation: '',
	AcceptLGPDTerms: '',
	AcceptNotificationTerms: '',
	AdressReference: '',
	ConfirmEmail: '',
	UserSignUpType: 'PJ',
	AcceptTermsAdhesion: ''
}

export const MOCK_CLIENT: INewClient = {
	AdressComplement: '',
	CellphoneNumber: '(54) 98891-0826',
	City: 'Caxias do Sul',
	CompanyFiscalID: '78.055.491/0001-65',
	Email: 'luizasimoneduarte-79@goldenhotel.com.br',
	HouseNumber: '407',
	IdentificationRegistryID: '27.341.773-3',
	Name: 'Luiza Simone Duarte',
	Neighborhood: 'Santa Fé',
	NickName: 'Bar Teste',
	PersonalFiscalID: '442.071.155-87',
	PhoneNumber: '(54) 2643-5103',
	PostalCode: '95047-360',
	Region: 'RS',
	Street: 'Rua dos Latoeiros',
	InvoiceNumberConfirmation: '0000000',
	AcceptLGPDTerms: 'X',

	AcceptNotificationTerms: 'X',
	AdressReference: '',
	ConfirmEmail: 'luizasimoneduarte-79@goldenhotel.com.br',
	UserSignUpType: 'PJ',
	AcceptTermsAdhesion: 'X'
}
