import React from 'react'
import { IinfoMessage } from './info-message.types'
import { StyleInfoMessage } from './info-message.styles'

function InfoMenssage({ icon, message }: IinfoMessage) {
	return (
		<StyleInfoMessage>
			{icon}
			<p>{message}</p>
		</StyleInfoMessage>
	)
}

export default InfoMenssage
