import { createElement, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import cogoToast from 'cogo-toast'

import TableButton from '../../../components/TableButton'
import { sortDate } from '../../../shared/utils/sorts'
import { getProcessStatus, deleteAllclients, fetchCompanies } from './service'
import { IViewProps, ITableData} from './types'
import ResaleList from './view'
import { include } from '../../../shared/utils/boolean'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import { IOption } from './types'

import FileIcon from '../../../assets/images/file-text.svg'
import { IResaleItem } from '../../ResaleList/types'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { parseFormat } from '../../../shared/utils/date'
import { AdminRoutesEnum } from '../../Routes/adminRoutesEnum'

const formatResaleOpt = (resale: IResaleItem) => ({
	label: `${resale.Name} - #${resale.SalesOrganizationID}`,
	value: resale.SalesOrganizationID
})

function MassProcessLog(): JSX.Element {
	const { resale } = useTypedSelector(['resale'])

	const history = useHistory()
	const [data, setData] = useState<ITableData[]>([])
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [unidades, setUnidades] = useState<IOption[]>([])
	const [unidadeEscolhida, setUnidadeEscolhida] = useState<IOption>()

	const columns = useMemo(
		() => [
			{ Header: 'ID da Solicitação', accessor: 'ID' },
			{ Header: 'Data', accessor: 'eventDateTime', sortType: sortDate('eventDateTime')},
			{ Header: 'Unidade', accessor: 'salesOrgId' },
			{ Header: 'Solicitante', accessor: 'ownerEmployeeID'},
			{ Header: 'Status', accessor: 'processStatus' },
		
			{ Header: 'Detalhes', accessor: 'button', disableSortBy: true } 
		],
		[]
	)

	const handleFetchResaleList = async () => {
		try {
			setIsLoading(true)
			
			const allResales = await fetchCompanies()
			
			setUnidades(allResales.map(formatResaleOpt))
			setUnidadeEscolhida(formatResaleOpt(resale))
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		handleFetchResaleList()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const refreshButton = async () => {
		try {
			setIsLoading(true)
			await handleFetchResaleList()
			await ProcessStatus()
			setIsLoading(false)
		}
		catch{
			cogoToast.error("Falha ao atualizar página!", cogoDefaultOptions)
			return
		}
	}

	const uploadPayload = async (e: any) => {
		try {
			setIsLoading(true)
			const [file] = e.target.files
			e.target.value = ''

			const formData = new FormData()

			if (!unidadeEscolhida){
				cogoToast.warn("Informe o código da revenda", cogoDefaultOptions)
				return
			}

			formData.append('file', file, file.name)

			cogoToast.loading("Realizando upload de arquivo...",cogoDefaultOptions)

			await deleteAllclients(formData, unidadeEscolhida.value)
			cogoToast.success(
				`${file.name} enviado com sucesso`,
				cogoDefaultOptions
			)

			await ProcessStatus()
		} catch (error) {
       	 	cogoToast.error("Erro ao fazer o upload do arquivo!", cogoDefaultOptions)
			return
		} finally {
			setIsLoading(false)
		}

	}

	function handleSearchOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.ID, value) ||
				include(item.eventDateTime, value) ||

				include(item.salesOrgId, value) ||
				include(item.ownerEmployeeID, value) ||
				include(item.processStatus, value) 
			)
		})

		setTableData(list)
	}

	function handleSetTableData() {
		if (!tableData.length) {
			setTableData(data.map((item) => item))
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleSetTableData, [])

	async function ProcessStatus() {
		try {
			setIsLoading(true)
			const response = await getProcessStatus()

			const data = response.map((resale) => ({

				eventDateTime: parseFormat(resale.eventDateTime), sortType: sortDate('eventDateTime'),
				ID: resale.ID,
				salesOrgId: resale.salesOrgId,
				ownerEmployeeID: resale.ownerEmployeeID,
				processStatus: resale.processStatus === 0 ? "Não processado"
					: resale.processStatus === 1 ? "Em processamento"
						: resale.processStatus === 2 ? "Concluído"
							: resale.processStatus === 3 ? "Erro"
								: "", // arquivo que não subiu

				// payload processStatus
				// 0 - não processado
				// 1 - em processamento
				// 2 - concluído
				// 3 - erro

				button: TableButton(
					() => handleExclusionDetailsNavigation(resale.ID),
					FileIcon
				)
			}))

			setData(data)
			setTableData(data)
		} catch (error) {
			cogoToast.error("Erro ao carregar dados!", cogoDefaultOptions)
			return
		} finally {
			setIsLoading(false)
		}
	}

	const handleExclusionDetailsNavigation = (requests: string) => {
		
		history.push({
			pathname: AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS_DETAILS,
			state: {
				idSolicitacao: requests
			}
		})
	}

	useEffect(() => {
		;(async () => {
			await ProcessStatus()
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const viewProps: IViewProps = {
		handleSearchOnChange,
		handleExclusionDetailsNavigation,
		tableData,
		columns,
		isLoading,
		uploadPayload,
		unidades,
		unidadeEscolhida,
		setUnidadeEscolhida,
		refreshButton,
	}

	return createElement(ResaleList, viewProps)
}

export default MassProcessLog
