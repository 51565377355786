import React from 'react'
import * as S from './security-information.styles'

import TextField from '../../../../components/inputs/text-field/text-field.component'
import { TypeEnum } from '../../../../components/inputs/text-field/text-field.types'
import AlertIcon from './assets/Alert Icons.svg'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { ISecurityInformationProps } from './secutiry-information.types'
import { WrapperButton } from '../../reset-password.styles'
import { useRecoverPasswordContextContext } from '../../../../context/recover-password.context'
import { validateSacurityQuestion } from '../../reset-password.service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../shared/utils/toaster'
import { TypeContact } from '../code-validation/code-validation.types'
export function SecurityInformationComponent({
	goStep,
	setIsLoading,
	setContactData,
	customerDocument
}: ISecurityInformationProps) {
	const [isError, setIsError] = React.useState<boolean>(false)

	const { userMaskedData } = useRecoverPasswordContextContext()
	const { questions } = userMaskedData

	const [questionOne, setQuestionOne] = React.useState<string>('')
	const [questionTwo, setQuestionTwo] = React.useState<string>('')

	const [answerOne, setAnswerOne] = React.useState('')
	const [answerTwo, setAnswerTwo] = React.useState('')

	React.useEffect(() => {
		// Integração com get de perguntas de segurança
		setQuestionOne(questions[0].question)
		setQuestionTwo(questions[1].question)
	}, [questions])

	async function handleVerifySecurityInfo() {
		// Integração com endpoint de perguntas de segurança
		try {
			setIsLoading(true)
			const isAnswerValid = await validateSacurityQuestion(
				customerDocument,
				[
					{
						questionId: questions[0].questionId,
						answer: answerOne
					},
					{
						questionId: questions[1].questionId,
						answer: answerTwo
					}
				]
			)
			setIsLoading(false)
			if (isAnswerValid.isCorrect) {
				setContactData({
					validationChoice: '',
					validationType: TypeContact.NEW_EMAIL
				})
				goStep(5)
				setIsError(false)
			} else {
				setIsError(true)
			}
		} catch (error) {
			cogoToast.error(error, cogoDefaultOptions)
			setIsLoading(false)
		}
	}

	return (
		<>
			<S.SecurityInformationView>
				<S.Title>Informações de segurança</S.Title>
				<S.Description>
					Para verificar a sua identidade, responda às perguntas de
					segurança
				</S.Description>

				<S.WrapperQuestions>
					<div className="wrapper-questions-content">
						<div>
							<p>{questionOne}</p>
							<TextField
								label={'Resposta (Obrigatório)'}
								placeholder={'Digite a resposta'}
								handleChangeText={setAnswerOne}
								text={answerOne}
								type={TypeEnum.TEXT}
								isError={isError}
							/>
						</div>
						<div>
							<p>{questionTwo}</p>
							<TextField
								label={'Resposta (Obrigatório)'}
								placeholder={'Digite a resposta'}
								handleChangeText={setAnswerTwo}
								text={answerTwo}
								type={TypeEnum.TEXT}
								isError={isError}
							/>
						</div>
					</div>
				</S.WrapperQuestions>

				{isError && (
					<S.Alert>
						<img src={AlertIcon} alt="AlertIcon" />
						<p>
							Respostas incorretas, tente novamente ou entre em
							contato com o nosso canal de atendimento.{' '}
						</p>
					</S.Alert>
				)}
			</S.SecurityInformationView>
			<>
				<WrapperButton>
					<ButtonDefault
						buttonText="Voltar"
						type={TypeButtonEnum.OUTLINED}
						onClick={() => goStep(3)}
						idElement="cancel"
						width="234px"
					/>
					<ButtonDefault
						buttonText="Continuar"
						type={
							answerOne.length > 0 && answerTwo.length > 0
								? TypeButtonEnum.PRIMARY
								: TypeButtonEnum.DISABLED
						}
						onClick={() => handleVerifySecurityInfo()}
						idElement="confirm"
						width="234px"
					/>
				</WrapperButton>
			</>
		</>
	)
}

export default SecurityInformationComponent
