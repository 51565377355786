import { useEffect, useMemo, useState } from 'react'
import { checkCNPJ, checkCPF } from '../shared/utils/validators'

function useDocValidation(textDoc: string): boolean {
	const [isValidDoc, setIsValidDoc] = useState(false)

	const conditionTestDoc = useMemo(() => {
		const testCpf = checkCPF(textDoc)
		const testCnpj = checkCNPJ(textDoc)
		return testCpf || testCnpj ? true : false
	}, [textDoc])

	useEffect(() => {
		setIsValidDoc(conditionTestDoc)
	}, [conditionTestDoc])

	return isValidDoc
}

export default useDocValidation
