export interface IProps {
	timeline: ITimeline[]
}

export interface ITimeline {
	date: Date
	text: string
	flowStatus: FlowStatusEnum
	orderStatus: string
	flowProgress?: string
}

enum FlowStatus {
	SUCCESS,
	NEUTRAL,
	ERROR
}

export type FlowStatusEnum = keyof typeof FlowStatus

export const FLOW_STATUS = {
	SUCCESS: 'success',
	NEUTRAL: 'neutral',
	ERROR: 'error'
}
