import React, { Fragment } from 'react'

import Header from '../../components/Header'
import InputField from '../../components/InputField'
import { IViewProps } from './types'

function MyAccount(props: IViewProps): JSX.Element {
	const { personalFields, storeFields, customer } = props

	const {
		Name = '',
		SalesOrganizationName = '',
		SalesOrganizationOpeningHours = { Start: '', End: '' },
		SalesOrganizationPhoneNumber = ''
	} = customer

	function showEasterEgg() {
		const isDev =
			'prd' === 'dev' ||
			'prd' === 'stg'
		if (isDev) {
			window.alert(
				`Código PDV: ${customer.CustomerID}\nID do canal: ${customer.ChannelGroupID}`
			)
		}
	}

	return (
		<Fragment>
			<Header />

			<div className="my-account-page">
				<div className="col-10">
					<h1 className="page-title" onClick={showEasterEgg}>
						Minha Conta
					</h1>

					<p className="welcome">
						{Name && SalesOrganizationName ? (
							<>
								{`Olá ${Name}, seu atendimento é realizado pela Unidade
							${SalesOrganizationName}.	
						`}
							</>
						) : null}

						<br />

						{SalesOrganizationOpeningHours.Start &&
						SalesOrganizationOpeningHours.End ? (
							<>
								{`
							O horário de atendimento desta
							Unidade é de ${SalesOrganizationOpeningHours.Start} até
							${SalesOrganizationOpeningHours.End} horas.
							
						`}
							</>
						) : null}

						<br />

						{SalesOrganizationPhoneNumber ? (
							<>
								{`
							Para realizar
							contato com diretamente com a Unidade, utilize o
							telefone: ${SalesOrganizationPhoneNumber}`}
							</>
						) : null}
					</p>

					<div className="centralizer">
						<div className="col-lg-6 col-sm-12">
							<h1 className="section-title">Dados pessoais</h1>
							<div className="row">
								{personalFields.map(
									(
										{
											containerClass,
											rightButton,
											...rest
										},
										i
									) => (
										<div key={i} className={containerClass}>
											<InputField {...rest} />
											{rightButton ? (
												<button
													className="change-password"
													onClick={
														rightButton.onClick
													}>
													{rightButton.text}
												</button>
											) : null}
										</div>
									)
								)}
							</div>
						</div>
						<div className="col-lg-6 col-sm-12">
							<h1 className="section-title">
								Dados do estabelecimento
							</h1>
							<div className="row">
								{storeFields.map(
									({ containerClass, ...rest }, i) => (
										<div key={i} className={containerClass}>
											<InputField {...rest} />
										</div>
									)
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default MyAccount
