import styled from 'styled-components'

export const ConfirmEditCellPhoneCodePage = styled.main``

export const ContainerPageWrapper = styled.div`
	width: 100%;
	max-width: 930px;
	margin: 0 auto;
	min-height: 100vh;
	padding: 16px;
`

export const Background = styled.div``

export const HeaderPage = styled.header`
	padding-top: 96px;

	.back-arrow-wrapper {
		border: none;
		cursor: pointer;
		background-color: transparent;
	}
`

export const TitlePage = styled.p`
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	color: ${(props) => props.theme.palette.white.main};
	font-weight: ${(props) => props.theme.font.weight.regular};
	margin-top: 32px;
	margin-bottom: 64px;
`

export const SubtitlePage = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	color: ${(props) => props.theme.palette.white.main};
	font-weight: ${(props) => props.theme.font.weight.regular};
	margin-bottom: 64px;
`

export const FormWrapper = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

export const LogoWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 300px;
`

export const LogoGP = styled.img`
	width: 95px;
	align-self: center;
	justify-self: center;
	margin-bottom: 40px;
`
