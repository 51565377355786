import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { Table } from '../../../shared/components'
import { IChallengeProduct } from '../../../shared/interfaces/pointsProgramChallenge'
import { isNegative } from '../../../shared/utils/number'
import {
	IProduct,
	PackageTypeEnum,
	UnitMeasureEnum
} from '../../RegisterProduct/types'

import * as S from './styles'

interface IProps {
	isDisabled?: boolean
	allProducts: IProduct[]
	selectedProducts: IChallengeProduct[]
	onChangeProductScoreRate: (productID: string, scoreRate: string) => void
}

interface ITableData {
	code: string
	name: string
	packageType: PackageTypeEnum
	unitMeasure: UnitMeasureEnum
	size: string
	ScoreValue?: string
	ScoreRate?: string
	button: JSX.Element
}

export function ChangeMinQtdProductsValue(props: IProps) {
	const {
		allProducts,
		selectedProducts,
		onChangeProductScoreRate,
		isDisabled
	} = props

	const [tableData, setTableData] = useState<ITableData[]>([])

	const columns = useMemo(
		() => [
			{ Header: 'Cód. do Produto', accessor: 'code' },
			{ Header: 'Nome do Produto', accessor: 'name' },
			{ Header: 'Tipo', accessor: 'packageType' },
			{ Header: 'Formato', accessor: 'unitMeasure' },
			{ Header: 'Tamanho', accessor: 'size' },
			{ Header: 'Qnt. Mínima para Compra', accessor: 'button' }
		],
		[]
	)

	const renderScoreRateInput = useCallback(
		(productID: string, productScoreRate: string) => {
			function handleChangeValue(e: ChangeEvent<HTMLInputElement>) {
				const value = e.target.value
				const numberValue = Number(value || '0')

				if (!Number.isNaN(numberValue) && !isNegative(numberValue)) {
					onChangeProductScoreRate(productID, numberValue.toString())
				}
			}
			return (
				<S.InputScoreRate
					type="number"
					disabled={isDisabled}
					value={productScoreRate}
					onChange={handleChangeValue}
				/>
			)
		},
		[isDisabled, onChangeProductScoreRate]
	)

	function loadTableProducts() {
		const tableData: ITableData[] = []

		for (const product of allProducts) {
			const findProductInSelectedItems = selectedProducts.find(
				(item) => item.productId === product.MaterialID
			)
			if (!!findProductInSelectedItems) {
				const { productId: productID, quantity: minQtd } =
					findProductInSelectedItems
				tableData.push({
					code: product.MaterialID,
					name: product.SiteDescription,
					packageType: product.PackageType,
					unitMeasure: product.UnitMeasureSale,
					size: product.Package,
					ScoreValue: product.ScoreValue,
					ScoreRate: product.ScoreRate,
					button: renderScoreRateInput(productID, minQtd)
				})
			}
		}

		setTableData(tableData)
	}

	useEffect(loadTableProducts, [
		selectedProducts,
		allProducts,
		renderScoreRateInput
	])

	return (
		<S.Container>
			{!tableData.length ? (
				<div className="empty-list-placeholder">
					<span>
						Selecione primeiro os produtos acima para cadastrar
						<br />
						as pontuações dos produtos.
					</span>
				</div>
			) : (
				<Table
					data={tableData}
					columns={columns as any}
					canPaginate={false}
				/>
			)}
		</S.Container>
	)
}
