import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import InputField from '../InputField'
import { IProps } from './types'

function InputInvoice({ returnValue, valueIsValid, className = '' }: IProps) {
	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const value = e.target.value.replace(/[^0-9]+/g, '')
		setTouched(true)
		returnValue(value)
		setInputValue(value)
	}

	function validateInput() {
		const isValid = inputValue.length !== 0

		valueIsValid(isValid)
		setHasError(touched && !isValid)
	}

	useEffect(validateInput, [value, inputValue.length, touched, valueIsValid])

	return (
		<InputField
			onChange={handleChange}
			id="invoiceNumber"
			label={'Número da Nota Fiscal'}
			maxLength={20}
			hasError={hasError}
			value={inputValue}
			className={className}
		/>
	)
}

export default InputInvoice
