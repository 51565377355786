import React from 'react'
import { useLocation } from 'react-router-dom'
import ForgotPasswordStep from './components/forgot-password-step/forgot-password-step.component'
import ChooseValidationStep from './components/choose-validation-step/choose-validation-step.component'
import { formatCnpjCpf } from '../../../../../shared/utils/form'
import DocumentStep from './components/document-step/document-step.component'
import PasswordStep from './components/password-step/password-step.component'
import useDocValidation from '../../../../../hooks/useDocValidation'
import { setUserRegistryToCognito } from '../../../services/login.services'
import { useRecoverPasswordContextContext } from '../../../../../context/recover-password.context'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../shared/utils/toaster'
import { handleSetContextUserData } from '../../../utils/handle-set-user-data'
import { LoginProps, StepLoginEnum } from './login-form.types'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { removeEspecialCharacters } from '../../../../../shared/utils'
import { userExists } from '../../../../../modules/NewAccount/service'
import { LoginCustomer } from '../../../utils/login-customer'

const LoginForm = ({
	validDocument,
	isValidDoc,
	textDoc,
	setTextDoc,
	unformatedTextDoc,
	handleChangeTypeSidebar,
	currentStep = 'document',
	handleCloseSidebar
}: LoginProps) => {
	const history = useHistory()
	const dispatch = useDispatch()

	const [stepLogin, setStepLogin] = React.useState(currentStep)
	const [password, setPassword] = React.useState('')
	const [isChecked, setIsChecked] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)

	const [showError, setShowError] = React.useState(false)
	const [documentNumber, setDocumentNumber] = React.useState('')
	const [showCreateNew, setShowCreateNew] = React.useState(false)

	const [textDocRecovery, setTextDocRecovery] = React.useState('')

	const [showErrorCnpjRecover, setShowErrorCnpjRecover] =
		React.useState(false)

	const validDocumentRecovery = formatCnpjCpf(textDocRecovery)
	const isValidDocRecovery = useDocValidation(textDocRecovery)

	const { handleSetUserMaskedData } = useRecoverPasswordContextContext()
	const location = useLocation()

	React.useEffect(() => {
		const loggedUserDataString = localStorage.getItem('@loggedUserData')
		if (
			loggedUserDataString !== null &&
			localStorage.getItem('@loggedUserData') !== null &&
			location.pathname !== '/reset-password'
		) {
			setStepLogin('password')
			setIsChecked(true)
			if (!password || !documentNumber) {
				const loggedUserData = JSON.parse(loggedUserDataString)
				setPassword(loggedUserData.password)
				setDocumentNumber(loggedUserData.docNumber)
				setTextDoc(loggedUserData.docNumber)
			}
		} else {
			setDocumentNumber(removeEspecialCharacters(unformatedTextDoc))
		}
	}, [
		unformatedTextDoc,
		documentNumber,
		password,
		setTextDoc,
		location.pathname
	])

	const handleVerifyUserExists = async () => {
		try {
			setIsLoading(true)

			const response = await userExists(
				removeEspecialCharacters(unformatedTextDoc)
			).finally(() => {
				setIsLoading(false)
			})
			if (response) {
				const { CustomerStatus } = response[0]
				if (CustomerStatus === 'NOT_FOUND') {
					setShowError(true)
					setShowCreateNew(true)
				} else {
					setStepLogin('password')
					setShowError(false)
				}
			}
		} catch (error) {
			console.error(error)
		}
	}

	const handleVerifyUserExistsRecoveryPassword = async () => {
		try {
			setIsLoading(true)
			const response = await userExists(
				removeEspecialCharacters(textDocRecovery)
			)
			const userData = await handleSetContextUserData(unformatedTextDoc)

			if (response) {
				const { CustomerStatus } = response[0]
				if (CustomerStatus === 'NOT_FOUND') {
					setShowErrorCnpjRecover(true)
					setShowCreateNew(true)
				} else {
					setShowError(false)
				}
			}
			await setUserRegistryToCognito({
				document: removeEspecialCharacters(unformatedTextDoc)
			})

			if (!userData) {
				cogoToast.error(
					'Erro ao trazer dados de recuperação de senha',
					cogoDefaultOptions
				)
			} else {
				const userMaskedData = {
					email: userData.Email,
					cellphoneNumber: userData.CellphoneNumber,
					phoneNumber: userData.CellphoneNumber,
					customerID: userData.CustomerID,
					alternativePhone: userData.AlternativePhone,
					alternativeEmail: userData.AlternativeEmail,
					questions: userData.questions
				}
				handleSetUserMaskedData(userMaskedData)
				setStepLogin('chooseValidation')
			}
		} catch (error) {
			cogoToast.error(
				'Não foi possivel recuperar os dados de recuperação de senha',
				cogoDefaultOptions
			)
		}
		setShowError(false)
		setIsLoading(false)
	}

	async function handleLogin() {
		await LoginCustomer({
			documentNumber,
			history,
			isChecked,
			password,
			setIsLoading,
			setShowError,
			dispatch
		})
	}

	const stepLoginObject: Record<StepLoginEnum, React.ReactNode> = {
		[StepLoginEnum.DOCUMENT]: (
			<DocumentStep
				handleChangeTypeSidebar={() => handleChangeTypeSidebar()}
				handleVerifyUserExists={() => handleVerifyUserExists()}
				isLoading={isLoading}
				isValidDoc={isValidDoc}
				setTextDoc={setTextDoc}
				showCreateNew={showCreateNew}
				showError={showError}
				validDocument={validDocument}
			/>
		),
		[StepLoginEnum.PASSWORD]: (
			<PasswordStep
				handleLogin={() => handleLogin()}
				isChecked={isChecked}
				isLoading={isLoading}
				password={password}
				setIsChecked={() => setIsChecked(!isChecked)}
				setPassword={setPassword}
				setStepLogin={setStepLogin}
				setTextDocRecovery={setTextDocRecovery}
				showError={showError}
				textDoc={textDoc}
			/>
		),
		[StepLoginEnum.FORGOT_PASSWORD]: (
			<ForgotPasswordStep
				isValidDoc={isValidDocRecovery}
				setStepLogin={() => handleVerifyUserExistsRecoveryPassword()}
				setTextDoc={setTextDocRecovery}
				showErrorCnpjRecover={showErrorCnpjRecover}
				textDoc={validDocumentRecovery}
				isLoading={isLoading}
			/>
		),
		[StepLoginEnum.CHOOSE_VALIDATION]: (
			<ChooseValidationStep
				unformatedTextDoc={unformatedTextDoc}
				handleCloseSidebar={handleCloseSidebar}
			/>
		)
	}

	return <>{stepLoginObject[stepLogin as StepLoginEnum]}</>
}

export default LoginForm
