import React, { ReactElement, useEffect, useRef, useState } from 'react'
import PinMarker from '../../../assets/images/pin-marker.svg'
import { IProps, Status } from './types'
import { getAddressFromCoordinates, getCoordinatesFromAddress } from './util'
import { Wrapper } from '@googlemaps/react-wrapper'
import { LoadingWrapper } from '../../MyPoints/styles'
import Loading from '../../../components/Loading'
import { colors } from '../../../shared/styles/theme'
import { GoogleMapWrapper, MarkerWrapper, ViewMap } from './styles'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import cogoToast from 'cogo-toast'
import { useDebounce } from 'use-debounce/lib'

export interface ILatLng {
	lat: number
	lng: number
}
const GOOGLE_MAPS_KEY = 'AIzaSyAFt4u9fjeHJikZ0-HaKYjq28lJyTAR-hk'

function MapComponent({
	center,
	zoom,
	onDragEnd
}: {
	center: google.maps.LatLngLiteral
	zoom: number
	updateRegion: (location: ILatLng) => void
	onDragEnd?: (map: google.maps.Map) => void
}) {
	const [map, setMap] = useState<google.maps.Map>()

	const ref = useRef<any>()

	useEffect(() => {
		if (ref?.current && !map) {
			setMap(new window.google.maps.Map(ref.current, { center, zoom }))
		}

		if (map && onDragEnd) {
			map.addListener('dragend', () => onDragEnd(map))
		}
	}, [map, onDragEnd, ref])

	return <ViewMap ref={ref} className="map" />
}

export default function Maps(props: IProps) {
	const { address, returnAddress, returnLocation, returnLoading } = props
	const [region, setRegion] = useState<ILatLng>({
		lat: -23.5657344,
		lng: -46.6534266
	})
	const [loading, setLoading] = useState(false)
	const [value] = useDebounce(region, 2000)
	const getLocationCurrent = async () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition)
		} else {
			cogoToast.error(
				'Geolocation is not supported by this browser.',
				cogoDefaultOptions
			)
		}
	}

	function showPosition(position: any) {
		setLoading(true)

		setRegion({
			lat: position.coords.latitude,
			lng: position.coords.longitude
		})
		setLoading(false)
	}

	function updateRegion(location: ILatLng) {
		setRegion(location)
	}

	const getLiveLocation = async () => {
		try {
			setLoading(true)
			if (address) {
				const response = await getCoordinatesFromAddress(address)

				if (!response || !response?.results?.length) {
					getLocationCurrent()
					return
				}

				const { lat, lng } = response.results[0].geometry.location
				setRegion({ ...region, lat, lng })
				return
			}

			getLocationCurrent()
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	const returnAddressLabel = async () => {
		try {
			returnLoading(true)
			const response = await getAddressFromCoordinates(
				region.lat,
				region.lng
			)

			if (!response || !response?.results?.length) {
				cogoToast.error('Endereço inválido.', cogoDefaultOptions)
				getLocationCurrent()
				return
			}
			const addressCurrent =
				response.results[0]?.formatted_address ||
				response.results[0]?.address_components[0].long_name

			returnAddress(addressCurrent)
		} catch (error) {
			console.error({ error })
		} finally {
			returnLoading(false)
		}
	}

	useEffect(() => {
		getLiveLocation()
	}, [])

	useEffect(() => {
		returnAddressLabel()
		returnLocation({
			lat: region.lat,
			lng: region.lng
		})
	}, [value])

	const dragEnd = (m: google.maps.Map) => {
		returnLoading(true)
		setRegion(m.getCenter()!.toJSON())
	}

	const render = (status: Status): ReactElement => {
		if (status === Status.LOADING || loading)
			return (
				<LoadingWrapper>
					<Loading color={colors.red} />
				</LoadingWrapper>
			)
		if (status === Status.FAILURE) return <h3>{status} ...</h3>
		return (
			<MapComponent
				center={region}
				zoom={18}
				updateRegion={updateRegion}
				onDragEnd={dragEnd}
			/>
		)
	}

	return (
		<GoogleMapWrapper>
			<Wrapper apiKey={GOOGLE_MAPS_KEY} render={render} />
			{loading ? null : (
				<MarkerWrapper>
					<img src={PinMarker} alt="marker" />
				</MarkerWrapper>
			)}
		</GoogleMapWrapper>
	)
}
