import React from 'react'
import { IViewProps } from './types'
import { Header, Loading } from '../../shared/components'
import welcomeIcon from '../../assets/images/welcome-placeholder.svg'
import { ButtonWrapper, LoadingWrapper, WelcomeWrapper } from './styles'
import ButtonPrimary from '../../components/ButtonPrimary'
import { primaryButtonStyle } from '../../shared/styles/theme'

function Welcome(props: IViewProps): JSX.Element {
	const { loading, refreshGetUser } = props

	return (
		<WelcomeWrapper>
			<Header />

			{loading ? (
				<LoadingWrapper>
					<Loading color="red" />
				</LoadingWrapper>
			) : (
				<main>
					<section className="container">
						<div className="welcome-box">
							<img src={welcomeIcon} alt="Icone de bem vindo" />

							<div>
								<h1 className="welcome-title">
									Seja bem vindo ao
									<br />
									Bom Parceiro!
								</h1>
								<h4 className="welcome-subtitle">
									Fique tranquilo! Em até 2h iremos liberar
									seu cadastro no
									<br />
									Bom Parceiro.
								</h4>
							</div>
						</div>
					</section>
					<div className="border" />
					<div className="container">
						<div className="banner">
							<a
								href="https://www.youtube.com/watch?v=FE_PUUnovf8"
								target="_blank"
								rel="noreferrer">
								<h1>
									Clique aqui e Conheça
									<br />o aplicativo Bom Parceiro
								</h1>
							</a>
						</div>
					</div>
					<ButtonWrapper>
						<ButtonPrimary
							onClick={refreshGetUser}
							{...primaryButtonStyle}>
							ATUALIZAR
						</ButtonPrimary>
					</ButtonWrapper>
				</main>
			)}
		</WelcomeWrapper>
	)
}

export default Welcome
