import { apiCustomer } from '../../../shared/services/axiosInstace'

interface IresponseVerifyReviewIsPending {
	isRequired: boolean
}

export interface ISaveReviewBody {
	document: string
	rate?: number
	comment?: string
	skipRate?: boolean
	rateSource: string
}

export interface ISaveReviewResponse {
	status: number
	data: any
}
export interface IVerifyIfReviewIsPending {
	customerID: string
	salesOrganizationID: string
}
export async function verifyIfReviewIsPending({
	customerID,
	salesOrganizationID
}: IVerifyIfReviewIsPending): Promise<IresponseVerifyReviewIsPending> {
	const response = await apiCustomer.get<IresponseVerifyReviewIsPending>(
		`/customers/nps/requires-rating?customerID=${customerID}&salesOrganizationID=${salesOrganizationID}`
	)

	return response.data
}

export async function saveReview({
	comment,
	document,
	rate,
	rateSource,
	skipRate
}: ISaveReviewBody): Promise<ISaveReviewResponse> {
	const response = await apiCustomer.post(`/nps/rate`, {
		document,
		rate,
		comment,
		skipRate,
		rateSource
	})
	return response
}
