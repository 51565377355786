import styled from 'styled-components'

export const ComponentCookie = styled.div`
	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size};
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height};
	color: ${(props) => props.theme.palette.primaryWhite.contrastText};

	cursor: pointer;
`
