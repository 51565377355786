import React from 'react'
import * as S from './video-player-button.styles'

import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined'
import { VideoPlayerProps } from './video-player-button.types'

import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { savingUploadImageBannerHandler } from '../../views/banners-management/service'
import Loading from '../Loading'

function VideoPlayerButton({
	hasSelectedVideo,
	canDeleteVideo = true,
	handleGetSrc,
	srcVideo
}: VideoPlayerProps) {
	const ref = React.useRef<HTMLInputElement>(null)
	const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		setSelectedFile(srcVideo)
	}, [srcVideo])

	const handleSelectImage = () => {
		if (ref.current !== null) {
			ref.current.click()
		}
	}

	const handleDataChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const file = event.target.files && event.target.files[0]

		if (file) {
			setIsLoading(true)
			const uploadVideoSelected = await savingUploadImageBannerHandler({
				file: file,
				fileName: `pn-img-${Number(new Date())}`
			}).finally(() => {
				setIsLoading(false)
			})
			setSelectedFile(uploadVideoSelected.url || null)
			handleGetSrc(uploadVideoSelected.url || null)
		}
	}

	return (
		<>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{selectedFile ? (
						<S.VideoPlayerWithData>
							<div
								className={
									canDeleteVideo
										? 'player-wrapper'
										: 'player-view-wrapper'
								}>
								<PlayCircleOutlineOutlinedIcon />
								<a
									href={selectedFile}
									target="_blank"
									rel="noreferrer"
									className='video-label'>
									video
								</a>
							</div>
							{canDeleteVideo && (
								<DeleteOutlineOutlinedIcon
									onClick={() => {
										setSelectedFile(null)
										handleGetSrc('')
									}}
								/>
							)}
						</S.VideoPlayerWithData>
					) : (
						<S.VideoPlayerButtonWrapper onClick={handleSelectImage}>
							<input
								disabled={hasSelectedVideo}
								onChange={handleDataChange}
								ref={ref}
								type="file"
								accept="video/*"
								className='video-label'
							/>
							<MovieOutlinedIcon />
							<div className="button-label">
								<p className="selected-video-label">
									Selecione o vídeo
								</p>
								<p className="sub-title-label">
									RECOMENDADO: 480PX até 720PX
								</p>
							</div>
						</S.VideoPlayerButtonWrapper>
					)}
				</>
			)}
		</>
	)
}

export default VideoPlayerButton
