import React from 'react'
 
import { Table, Loading, InputFilter } from '../../shared/components'
 
import { IViewProps } from './types'
import ModalUser from './ModalUser'
import ModalDeleteUser from './ModalDeleteUser'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
 
import * as S from './styles'
 
export default function UsersView(props: IViewProps) {
    const {
        tableProps,
        handleModalVisibility,
        modalUserIsActive,
        modalDeleteIsActive,
        selectedUser,
        handleCloseModal,
        handleFilterOnChange,
        handleDeleteUser,
        isDeleting
    } = props
 
    return (
        <>
            <DefaultLayoutAdmin>
                <div className="param-table-wrapper">
                    <S.UsersHeaderContainer>
                        <div className="">
                            <p className="param-title">Usuários</p>
                        </div>
                        <div className="red-button-container">
                            <button
                                className="red-button"
                                onClick={() =>
                                    handleModalVisibility({
                                        modal: 'CREATE/UPDATE'
                                    })
                                }>
                                CADASTRAR USUÁRIO
                            </button>
                        </div>
                    </S.UsersHeaderContainer>
 
                    <S.InputWrapper>
                        <InputFilter onChange={handleFilterOnChange} />
                    </S.InputWrapper>
 
                    <div className="custom-table-container">
                        {!tableProps.isFetching ? (
                            <Table {...tableProps} />
                        ) : (
                            <div className="loading-wrapper">
                                <Loading />
                            </div>
                        )}
                    </div>
                </div>
            </DefaultLayoutAdmin>
            <ModalUser
                isActive={modalUserIsActive}
                user={selectedUser}
                onClose={handleCloseModal}
                onDelete={() =>
                    handleModalVisibility({
                        modal: 'DELETE',
                        user: selectedUser?.user
                    })
                }
            />
            <ModalDeleteUser
                isActive={modalDeleteIsActive}
                isDeleting={isDeleting}
                onConfirm={handleDeleteUser}
                onCancel={() =>
                    handleModalVisibility({
                        modal: 'CREATE/UPDATE',
                        user: selectedUser?.user
                    })
                }
            />
        </>
    )
}