import { prop, propOr } from 'ramda'

import { IInputProps, INewClient } from '../../modules/NewClientForm/types'
import { satisfiesAll } from './function'
import { isPropEqual, pickKeys } from './object'
import { pipe } from './operators'

export function isFormValid(
	fields: Array<IInputProps>,
	input: INewClient
): boolean {
	const isRequired = pipe(propOr(false, 'required'), Boolean)
	const hasValidator = pipe(propOr(false, 'validator'), Boolean)

	const reqFields = fields.filter(isRequired)

	const reqKeys = reqFields.map(prop('keyName'))

	const getProps = pickKeys(['validator', 'keyName'])

	const validatorsMap = reqFields.filter(hasValidator).map(getProps)

	const isInReqKeys = ([key, _]: [string, string]) => reqKeys.includes(key)

	const isValueValid = ([_, value]: [string, string]) => Boolean(value)

	const isValidated = ([key, value]: [string, string]) => {
		const thisValidator = validatorsMap.find(isPropEqual('keyName')(key))

		if (!thisValidator) return true

		return Boolean(thisValidator.validator?.(value))
	}

	const inputForReqFields = Object.entries(input).filter(isInReqKeys)

	const isValidatedOrHasValue = satisfiesAll(isValueValid, isValidated)

	const canProceed = inputForReqFields.every(isValidatedOrHasValue)

	return canProceed
}

export function formatCnpjCpf(value: string) {
	const document = value.replace(/\D/g, '')

	if (document.length === 11) {
		return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
	}

	if (document.length === 14) {
		return document.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			'$1.$2.$3/$4-$5'
		)
	}

	return document
}

export function formatCellphone(value: string) {
	const cellphone = value.replace(/\D/g, '')

	// With +55 and 19
	if (cellphone.length === 14) {
		return cellphone.replace(
			/(\d{3})(\d{2})(\d{5})(\d{4})/g,
			'$1 ($2) $3-$4'
		)
	}

	// With 55 and 19
	if (cellphone.length === 13) {
		return cellphone.replace(
			/(\d{2})(\d{2})(\d{5})(\d{4})/g,
			'+$1 ($2) $3-$4'
		)
	}

	// With 19
	if (cellphone.length === 11) {
		return cellphone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3')
	}

	// With Telephone 19
	if (cellphone.length === 10) {
		return cellphone.replace(/(\d{2})(\d{4})(\d{4})/g, '($1) $2-$3')
	}

	// Only Number
	if (cellphone.length === 9) {
		return cellphone.replace(/(\d{5})(\d{4})/g, '$1-$2')
	}

	return cellphone
}

export function formatPostalCode(value: string) {
	const code = value.replace(/\D/g, '')

	if (code.length === 8) {
		return code.replace(/(\d{5})(\d{3})/g, '$1-$2')
	}

	return code
}

export function formatRG(value: string) {
	const document = value.replace(/\D/g, '')

	if (document.length === 9) {
		return document.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4')
	}

	return document
}
