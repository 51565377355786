import React from 'react'
import TextField from '../../../../../components/inputs/text-field/text-field.component'
import ButtonDefault from '../../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../../components/buttons/button/button-default.types'
import { FirstAcessProps } from './first-acess-form.types'
import { TypeEnum } from '../../../../../components/inputs/text-field/text-field.types'

import { useHistory } from 'react-router-dom'
import { UnauthenticatedRoutesEnum } from '../../../../../modules/Routes/unauthenticatedRoutesEnum'
import { useUserAuthDataContext } from '../../../../../context/user-auth-data'

const FirstAcessForm = ({
	unformatedTextDoc,
	isValidDoc,
	setTextDoc,
	validDocument
}: FirstAcessProps) => {
	const history = useHistory()

	const {  setUserDoc } = useUserAuthDataContext()

	function handleRedirectFirstAcessForm() {
		setUserDoc(unformatedTextDoc)
		history.push(UnauthenticatedRoutesEnum.NEW_ACCOUNT, {
			documentNumberHistory: unformatedTextDoc
		})
	}

	return (
		<>
			<h2>Para começar, digite o seu CPF ou CNPJ</h2>
			<TextField
				label={'CPF ou CNPJ'}
				placeholder={'Insira o seu CPF/CNPJ'}
				handleChangeText={setTextDoc}
				text={validDocument}
				type={TypeEnum.TEXT}
				idElement="documentNumber"
			/>
			<ButtonDefault
				buttonText="Continuar"
				type={
					isValidDoc
						? TypeButtonEnum.PRIMARY
						: TypeButtonEnum.DISABLED
				}
				onClick={() => handleRedirectFirstAcessForm()}
				idElement='goToFirstAcess'
			/>
		</>
	)
}

export default FirstAcessForm
