import styled from 'styled-components'
import { StatusBannerTypes } from './status-banner.types'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const WrapperStatusBanner = styled.div<StatusBannerTypes>`
	width: 79px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 24px;

	background-color: ${(props) => {
		if (props.status === 'ATIVO') return colorThemeProvider.successColor
		if (props.status === 'CANCELADO')
			return colorThemeProvider.lightGrayColor
		if (props.status === 'FINALIZADO')
			return colorThemeProvider.returnable.main
		if (props.status === 'AGENDADO') return colorThemeProvider.warningColor
	}};

	color: ${(props) =>
		props.status === 'AGENDADO'
			? colorThemeProvider.blackColor
			: colorThemeProvider.whiteColor};
	font-size: 10px;
`
