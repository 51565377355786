import React, {
	ChangeEvent,
	createElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { format } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import { useHistory } from 'react-router-dom'

import {
	TableButton,
	StatusPointsProgramChip,
	SeeRuleParametersButton,
	BedgeRulePointsProgramParameters
} from '../../shared/components'

import CancelIcon from '../../assets/images/cancel.svg'
import FileTextIcon from '../../assets/images/file-text.svg'

import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'

import {
	IChallenge,
	IFilterChallenge,
	ChallengeStatusEnum
} from '../../shared/interfaces/pointsProgramChallenge'

import ListOfChallengesView from './view'
import { getChallengesRegistereds } from './services'
import { filteringChallengesByString } from '../../shared/utils/filteringChallengesByString'

import { ITableData, IViewProps, RenderParametersType } from './types'
import * as S from './styles'

export default function PointsProgramListOfChallenges() {
	const locale = { locale: BR }
	const [isLoading, setIsLoading] = useState(false)
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [seekChallengeValue, setSeekChallengeValue] = useState('')
	const [
		showChallengeCancellationConfirmationModal,
		setShowChallengeCancellationConfirmationModal
	] = useState(false)

	const [
		showModalWithParametersOfTheChallenge,
		setShowModalWithParametersOfTheChallenge
	] = useState(false)

	const [
		modalWithParametersDetailsOfTheChallengeData,
		setModalWithParametersDetailsOfTheChallengeData
	] = useState<IFilterChallenge[]>([])

	const [challengeData, setChallengeData] = useState<IChallenge[]>([])

	const [challengeIdToCancelIt, setChallengeIdToCancelIt] = useState('')

	const hitory = useHistory()

	const columns = useMemo(
		() => [
			{
				Header: 'ID #',
				accessor: 'autoIncChallengeId',
				disableSortBy: true
			},
			{
				Header: 'status',
				accessor: 'statusBedge',
				disableSortBy: true
			},
			{
				Header: 'Título',
				accessor: 'challenge',
				disableSortBy: true
			},
			{
				Header: 'Período de Exibição',
				accessor: 'exhibitionPeriod',
				disableSortBy: true
			},
			{
				Header: 'Criado em',
				accessor: 'createdAt',
				disableSortBy: true
			},
			{
				Header: 'Pontuação',
				accessor: 'punctuation',
				disableSortBy: true
			},
			{
				Header: 'Clientes',
				accessor: 'participantsQuantity',
				disableSortBy: true
			},
			{
				Header: 'Observação',
				accessor: 'observation',
				disableSortBy: true
			},
			{ Header: '', accessor: 'detailsButton', disableSortBy: true },
			{ Header: '', accessor: 'cancelButton', disableSortBy: true }
		],
		[]
	)

	const handleShowModalWithParametersOfTheChallenge = (
		parameters: IFilterChallenge[]
	) => {
		setShowModalWithParametersOfTheChallenge(
			!showModalWithParametersOfTheChallenge
		)
		setModalWithParametersDetailsOfTheChallengeData(parameters)
	}

	const handlerCloseModalWithParametersOfTheChallenge = () =>
		setShowModalWithParametersOfTheChallenge(false)

	const handleGoToTheNewChallengeCreationScreen = () => {
		hitory.push(AdminRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAIL)
	}

	const handleGoToChallengeDetailsPage = (challenge: IChallenge) => {
		hitory.push({
			pathname: AdminRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAIL,
			state: {
				challenge
			}
		})
	}

	const handleClosingChallengeCancellationConfirmationModal = () => {
		setShowChallengeCancellationConfirmationModal(
			!showChallengeCancellationConfirmationModal
		)
	}

	const handleShowConfirmationCancelingChallengeModal = () => {
		setShowChallengeCancellationConfirmationModal(
			!showChallengeCancellationConfirmationModal
		)
	}

	const enableModalToConfirmationChallengeCanceling = (
		challengeId: string
	) => {
		handleShowConfirmationCancelingChallengeModal()
		setChallengeIdToCancelIt(challengeId)
	}

	function renderTitleAndImageBanner({
		urlFoto,
		title
	}: Partial<IChallenge>) {
		return (
			<S.BannerInfoWrapper>
				<S.ImageBanner src={urlFoto} alt={title} />
				<S.BannerChallengeTitle>{title}</S.BannerChallengeTitle>
			</S.BannerInfoWrapper>
		)
	}

	const renderParameters = ({
		parameters,
		modalData
	}: RenderParametersType) => {
		return (
			<S.FiltersContainer>
				<BedgeRulePointsProgramParameters>
					{parameters}
				</BedgeRulePointsProgramParameters>
				<SeeRuleParametersButton
					onClick={() =>
						handleShowModalWithParametersOfTheChallenge(modalData)
					}
				/>
			</S.FiltersContainer>
		)
	}

	const renderButton = (challenge: IChallenge) => {
		return (
			<button
				className="edit"
				onClick={() => handleGoToChallengeDetailsPage(challenge)}>
				<img className="edit-img" src={FileTextIcon} alt="Editar" />
			</button>
		)
	}

	const renderStatusBedge = (status: ChallengeStatusEnum) => {
		return <StatusPointsProgramChip label={status} />
	}

	const renderObservationContent = (text: string) => {
		return <S.Observation>{text}</S.Observation>
	}

	const tableElementGenerator = (challenge: IChallenge): ITableData => {
		const {
			title,
			score,
			urlFoto,
			challengeId,
			filtersChallenge,
			endDate,
			startDate
		} = challenge

		const parametersData = {
			modalData: filtersChallenge?.length ? filtersChallenge : [],
			parameters: `${filtersChallenge?.length} Parametros`
		}

		const exhibitionPeriodData = `De ${format(
			new Date(String(startDate) + 'T12:00:00'),
			'dd/MM/yy',
			locale
		)} 
		até ${format(new Date(String(endDate) + 'T12:00:00'), 'dd/MM/yy', locale)}`

		const punctuationData = score

		const challengeBannerAndTitleData = renderTitleAndImageBanner({
			title,
			urlFoto
		})

		const challengeIsPending =
			(challenge.status === ChallengeStatusEnum.ACTIVE &&
				!challenge.participantsQuantity) ||
			challenge.status === ChallengeStatusEnum.PENDING

		return {
			challengeId,
			autoIncChallengeId: `#${challenge.autoIncChallengeId}`,
			exhibitionPeriod: exhibitionPeriodData,
			filters: renderParameters(parametersData),
			punctuation: String(punctuationData),
			challenge: challengeBannerAndTitleData,
			detailsButton: renderButton(challenge),
			cancelButton: challengeIsPending
				? TableButton(
						() =>
							enableModalToConfirmationChallengeCanceling(
								String(challenge.challengeId)
							),
						CancelIcon
				  )
				: null,
			createdAt: format(
				new Date(
					challenge.challengeRegistrationDate
						? `${challenge.challengeRegistrationDate}T12:00:00`
						: (challenge.createdAt as string)
				),
				'dd/MM/yyyy',
				locale
			),
			observation: challenge.observation
				? renderObservationContent(String(challenge.observation))
				: null,
			statusBedge: renderStatusBedge(
				challenge.status || ChallengeStatusEnum.EXPIRED
			),
			participantsQuantity: challenge.participantsQuantity || 0
		}
	}

	const fetchPointsProgramChallenges = () => {
		;(async () => {
			try {
				setIsLoading(true)
				const responseChallengesData = await getChallengesRegistereds()

				setChallengeData(responseChallengesData)
				const data: ITableData[] = responseChallengesData.map(
					tableElementGenerator
				)
				setTableData(data)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	const changeHandlerSeekChallengeValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value
			setSeekChallengeValue(value)

			const challengesFiltereds = filteringChallengesByString(
				challengeData,
				value
			)
			const data: ITableData[] =
				challengesFiltereds.map(tableElementGenerator) || []

			setTableData(data)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[challengeData]
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchPointsProgramChallenges, [
		showChallengeCancellationConfirmationModal
	])

	const viewProps: IViewProps = {
		columns,
		isLoading,
		tableData,
		seekChallengeValue,
		challengeIdToCancelIt,
		changeHandlerSeekChallengeValue,
		showModalWithParametersOfTheChallenge,
		handleGoToTheNewChallengeCreationScreen,
		showChallengeCancellationConfirmationModal,
		modalWithParametersDetailsOfTheChallengeData,
		handlerCloseModalWithParametersOfTheChallenge,
		handleClosingChallengeCancellationConfirmationModal
	}

	return createElement(ListOfChallengesView, viewProps)
}
