import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { logout } from '../../shared/services/axiosInstace'
import RedirectBrowser from '../RedirectBrowser'
import { IRouteDataV2 } from '../Routes/types'
import FooterLayout from '../../layout/footer-layout/footer-layout.component'
import SidebarReview from './components/sidebar-review/sidebar-review.component'
import { verifyIfReviewIsPending } from './services/review.service'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { getRequiresRegistrationUpdate } from '../../views/registration-update/services/service'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { verifyIsMaintenance } from '../../views/maintenance-message/services/maintenance-message.services'
import { isObjectEmpty } from '../../utils/verifyEmptyObject'
import { useDispatch } from 'react-redux'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { UserTypeEnum } from '../../shared/interfaces/customer'

export default function Page({ children, ...rest }: IRouteDataV2): JSX.Element {
	const { customer, carts } = useTypedSelector(['customer', 'carts'])
	const dispatch = useDispatch()
	// Intengração para avaliar necessidade de review
	const [isReviewNeeded, setIsReviewNeeded] = React.useState(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const history = useHistory()
	const [isRegistredSafeQuestions, setIsRegistredSafeQuestions] =
		useState(false)
	const [isMaintenance, setIsMaintenance] = React.useState(false)

	const handleVerifyUserData = React.useCallback(async () => {
		let verifyUser
		setIsLoading(true)
		verifyUser = await verifyIfReviewIsPending({
			customerID: customer.CustomerID,
			salesOrganizationID: customer.SalesOrganizationID
		})
		if (verifyUser) {
			setIsReviewNeeded(verifyUser.isRequired)
		}
		setIsLoading(false)
	}, [customer])

	useEffect(() => {
		if (customer.Type !== UserTypeEnum.ADMIN) {
			if (customer.PersonalFiscalID || customer.CompanyFiscalID) {
				handleVerifyUserData()
			}
		}
	}, [customer, handleVerifyUserData])

	React.useEffect(() => {
		async function getRequiresUpdate() {
			const responseCustomer = await getRequiresRegistrationUpdate({
				personalFiscalID: customer.PersonalFiscalID,
				companyFiscalID: customer.CompanyFiscalID,
				fieldsToCheck: 'AlternativeEmail,AlternativePhone,questions'
			})
			setIsRegistredSafeQuestions(responseCustomer.requireUpdate)
		}
		if (customer.Type === 'CUSTOMER') {
			getRequiresUpdate()
		}
	}, [customer])

	React.useEffect(() => {
		if (isRegistredSafeQuestions) {
			history.replace(CustomerRoutesEnum.REGISTRATION_UPDATE)
		}
	}, [history, isRegistredSafeQuestions])

	// Verificando se o carrinho de compras é do usuário logado
	React.useEffect(() => {
		if (
			(customer.PersonalFiscalID || customer.CompanyFiscalID) &&
			carts.length > 0 &&
			carts[0].items[0].customerId !== customer.CustomerID
		) {
			dispatch(CartActions.clearCart())
		}
	}, [carts, customer.CompanyFiscalID, customer.PersonalFiscalID])

	React.useEffect(() => {
		async function handleGetMaintenanceData() {
			const data = await verifyIsMaintenance()
			setIsMaintenance(!isObjectEmpty(data))

			if (isMaintenance) {
				logout()
			}

			if (isMaintenance) {
				history.replace(
					UnauthenticatedRoutesEnum.MAINTENANCE_MESSAGE,
					data
				)
			}
		}
		handleGetMaintenanceData()
	}, [history, isMaintenance])

	return (
		<>
			<LoadingCcontainer isLoading={isLoading} />
			<SidebarReview
				isOpen={isReviewNeeded}
				handleVerifyUser={() => handleVerifyUserData()}
				documentNumber={
					customer.PersonalFiscalID
						? customer.PersonalFiscalID
						: customer.CompanyFiscalID
				}
				handleCloseSidebar={() => setIsReviewNeeded(false)}
			/>
			<RedirectBrowser />
			{/*@ts-ignore*/}
			<Route {...rest} exact render={() => children} />
			<FooterLayout />
		</>
	)
}
