import { IProduct } from '../../../../../modules/RegisterProduct/types'
import { Category, ReturnSelectOption } from '../types/categories.types'

class CategoriesMapper {
	toDomainCategories(persistence: Category): ReturnSelectOption {
		return {
			label: persistence.description,
			value: persistence.ID
		}
	}
	toDomainBrand(persistence: IProduct): ReturnSelectOption {
		return {
			label: persistence.Brand,
			value: persistence.Brand
		}
	}
	toDomainPackage(persistence: IProduct): ReturnSelectOption {
		return {
			label: persistence.Package,
			value: persistence.Package
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CategoriesMapper()
