export const cogoErrorMessages = {
	emailIsNotEqual: 'Formato de email inválido ou os emails não coincidem!',
	invalidEmail: 'Email atual invalido!'
}
export const cogoSuccessMessages = {
	updateEmailSuccess: 'Email atualizado com sucesso!',
	updateCellphoneSuccess: 'Celular atualizado com sucesso!',
	confirmAccountSuccess: 'Cadastro confirmado com sucesso!',
	updateComboImageSuccess: 'Imagem do combo atualizado com sucesso!',
}
