import React from 'react'

import * as S from './styles'
import Loading from '../../../components/Loading'

function MorePointsPageHeader({
	goBack,
	isFetchingMaterials
}: {
	goBack: () => void,
	isFetchingMaterials: boolean
}) {
	return (
		<>
			{
				isFetchingMaterials ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<S.Container>
						<S.ScreenPath>
							<span className="go-back-link" onClick={goBack}>
								Todos os Produtos
							</span>
							<span>{'>'}</span>Produtos Mais Pontos
						</S.ScreenPath>
						<S.Banner>
							<span className="banner-title">Produtos que mais pontuam</span>
							<span className="banner-subtitle">
								Uma lista repleta de produtos que te dão mais pontos!
							</span>
						</S.Banner>
					</S.Container>
				)
			}
		</>
	)
}

export default MorePointsPageHeader
