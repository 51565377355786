import styled from 'styled-components'
import { DataSelector } from '../data-selector'

export const WrapperUserHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;
	padding-top: ${(props) => props.theme.spacingOptions.SM}px;
	padding-bottom: ${(props) => props.theme.spacingOptions.SM}px;
	p {
		text-align: left;
		font-family: ${props=>props.theme.font.family};
		font-size:  ${props=>props.theme.font.size.TEXT_MD.size}rem;
		font-style: normal;
		font-weight: ${props=>props.theme.font.weight.medium};
		line-height: normal;
        color: ${props=>props.theme.palette.black.main}
	}
`

export const WrapperSelectHamburger = styled.div`
	display: flex;
	align-items: center;
	gap: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
`
export const SelectDataSelector = styled(DataSelector)`
	width: 100%;
	height: fixed;
`
