import React, { Fragment } from 'react'

import * as S from './styles'

import ArrowLeftBlackIcon from '../../assets/images/arrow-left.svg'
import GPLogo from '../../assets/images/gp.png'

import { IViewProps } from './types'
import { InputCode } from '../../shared/components'

export default function ConfirmEditCellPhoneCodeView(props: IViewProps) {
	const {
		setCode,
		handleBack,
		setCodeIsValid,
		handleSubmit,
		isDisabled,
		isLoading
	} = props

	return (
		<Fragment>
			<S.Background className="gradient-container" />
			<S.ConfirmEditCellPhoneCodePage className="">
				<S.ContainerPageWrapper>
					<S.HeaderPage>
						<button
							className="back-arrow-wrapper"
							onClick={handleBack}>
							<img
								className="back-arrow"
								src={ArrowLeftBlackIcon}
								alt="Voltar"
							/>
						</button>
					</S.HeaderPage>
					<S.TitlePage>Confirmar número de celular</S.TitlePage>
					<S.SubtitlePage>
						Para confirmar o novo número, insira o código que
						enviamos via SMS
					</S.SubtitlePage>

					<S.FormWrapper className="activation-code">
						<InputCode
							returnValue={setCode}
							valueIsValid={setCodeIsValid}
						/>

						<button
							onClick={handleSubmit}
							disabled={isLoading || isDisabled()}
							className="button"
							style={{
								marginBottom: '64px'
							}}>
							CONFIRMAR
						</button>
					</S.FormWrapper>
					<S.LogoWrapper>
						<S.LogoGP
							className="bom-parceiro-logo"
							src={GPLogo}
							alt="Logo GP"
						/>
					</S.LogoWrapper>
				</S.ContainerPageWrapper>
			</S.ConfirmEditCellPhoneCodePage>
		</Fragment>
	)
}
