import styled from 'styled-components'

export const Container = styled.div`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	color: ${(props) => props.theme.palette.white.main};

	h1 {
		margin-bottom: 10px;
	}

	.border {
		border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
	}

	.radio-group {
		display: flex;
		flex-direction: column;
		align-items: center;
		label {
			display: flex;
			align-items: center;

			padding: 19px 16px;

			cursor: pointer;

			img {
				margin-right: 8px;
			}
		}

		label + label {
			border-top: 1px solid ${(props) => props.theme.palette.primary.main};
		}
	}

	margin: 50px 0;
`
