import { Auth } from 'aws-amplify'
import { handleCognitoError } from '../../shared/utils/handleCognitoError'

import { IForgotSubmit } from './types'

export async function forgotPasswordSubmit({
	documentNumber,
	code,
	newPassword,
	options
}: IForgotSubmit): Promise<any> {
	try {
		const response = await Auth.forgotPasswordSubmit(
			documentNumber,
			code,
			newPassword
		)

		return response
	} catch (err) {
		if (!options || !options.useCustomErrorHandler) handleCognitoError(err)
		throw new Error()
	}
}
