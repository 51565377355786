import { colorThemeProvider } from './../../../theme/themeProvider'
import styled, { css } from 'styled-components'
import { colors } from '../../styles/theme'

export const Container = styled.div`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${(props) => props.theme.palette.gray.main};
	margin: 50px 0;

	h1 {
		margin-bottom: 10px;
	}

	.border {
		border-bottom: 1px solid
			${(props) => props.theme.palette.extras.stroke.main};
	}
	.radio-group {
		display: flex;
		flex-direction: column;

		label.option {
			display: flex;
			align-items: center;

			padding: 19px 16px;

			cursor: pointer;

			img {
				margin-right: 8px;
			}
		}

		.input-invoice-wrapper {
			p {
				margin-bottom: 20px;
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
		}
	}
`

export const ValidationFielContainer = styled.div`
	margin-left: 39px;
	margin-top: 13px;

	p {
		margin-bottom: 20px;
	}
	.products-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`

export const SelectProductItem = styled.div<{ selected: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: calc(50% - 4px);
	margin-top: 8px;
	padding: 8px;
	padding-right: 16px;
	cursor: pointer;

	border-radius: 4px;
	background-color: ${({ selected }) =>
		selected ? colors.lightGreen : colorThemeProvider.whiteColor};
	${({ selected }) =>
		selected &&
		css`
			border: 2px solid ${colorThemeProvider.successColor};
		`};

	img {
		height: 43px;
		width: 43px;
		margin-right: 8px;
	}
	span {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${colors.secondGrey};
	}
`
