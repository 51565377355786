const newFilters = (filtersString) => {
	const filtersStringDefault =
		'{"ufs":[],"cities":[],"channels":[],"codePdv":"","codeResales":[],"customers":[],"diretor":[],"GRC":[]}'
	const filtersParse = JSON.parse(filtersString || filtersStringDefault)

	Object.keys(filtersParse).forEach((key) => {
		if (typeof filtersParse[key] === 'object') {
			filtersParse[key] = filtersParse[key].map((item, index) => ({
				value: String(index + 1),
				name: item
			}))
		}
	})

	Object.keys(filtersParse).forEach((key) => {
		if (typeof filtersParse[key] === 'object') {
			filtersParse[key].unshift({
				value: '0',
				name: `${filtersParse[key].length} Selecionados`
			})
		}
	})

	return filtersParse
}

module.exports = { newFilters }