// TODO: trocar a tag <a/> por um <button/>
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { GPClientForm } from '../GPClientForm'
import { IViewProps } from './types'
import Close from '../../../src/assets/images/closeButtonSVG.svg'
import InputDocument from '../../components/InputDocument'
import Loading from '../../components/Loading'
import Modal from '../../components/Modal'
import NewClientForm from '../NewClientForm'

import ArrowLeftIcon from '../../assets/images/arrow-left-red.svg'
import HeaderNotLogged from '../../components/HeaderNotLogged'
import {
	GoBackButton,
	InputDocumentNumberWrapper,
	NewAccountWrapper,
	Subtitle,
	TextRed,
	Title,
	ErrorMessageText,
	FieldAlertFilledWithInvalidValue
} from './styles'
import { colors, primaryButtonStyle } from '../../shared/styles/theme'
import FooterNotLogged from '../../components/FooterNotLogged'
import ButtonPrimary from '../../components/ButtonPrimary'
import Step from './Step'
import AlertIcon from '../../assets/images/alert-circle-red.svg'
import { validatorDate } from '../../shared/utils/date'
import { InputField } from '../../shared/components'
import { checkDocumentType } from '../../utils/check-document-type'

function NewAccount(props: IViewProps): JSX.Element {
	const {
		userDoc,
		setUserDoc,
		// setDocumentNumber,
		// documentNumber,
		handleBack,
		setDocumentIsValid,
		viewState,
		handleRedirectLogin,
		handleRedirectRecoverPassword,
		loading,
		active,
		active2,
		setActive,
		setActive2,
		GPClientData,
		handleDocuments,
		LGPDCheckbox,
		SMSCheckbox,
		handleLGPDCheckbox,
		handleSMSCheckbox,
		handleChangeConfirmationMethod,
		selectedConfirmationMethod,
		sendDocumentsPF,
		sendDocumentsPJ,
		newCustomer,
		returnValues,
		goToStep,
		skipStep,
		handleCreateClient,
		birthDate,
		handleBirthDate,
		showFormForClientTypeOther,
		viewError,
		handleLatAndLong,
		handleTermsAdhesionCheckbox,
		termsAdhesionCheckbox,
		handlePassword,
		handleIsOtimizacao,
	} = props

	const canDisplayBirthDate = (checkDocumentType(userDoc) === 'CPF') && userDoc.length > 0 

	function RenderAlreadyRegistered() {
		return (
			<>
				<Title>
					Já existe uma conta cadastrada em nosso sistema com este
					documento.
					<br />
					Faça o login abaixo:
				</Title>

				<div className="login-redirect">
					<ButtonPrimary
						onClick={handleRedirectLogin}
						{...primaryButtonStyle}>
						ENTRAR
					</ButtonPrimary>

					<span className="redirect-text">
						Esqueceu a senha?
						<a
							onClick={handleRedirectRecoverPassword}
							className="link"
							href="#">
							Recuperar Senha
						</a>
					</span>
				</div>
			</>
		)
	}

	function RenderGPClient() {
		return (
			<>
				<Title>
					Vimos que{' '}
					<TextRed>você é um cliente do Grupo Petrópolis </TextRed>
					mas ainda
					<br />
					<TextRed> não possui uma conta </TextRed>
					em nosso sistema Bom Parceiro GP.
				</Title>

				<p className="subtitle-create-account">
					Complete seu Cadastro e crie seu acesso ao nosso portal:
				</p>

				<GPClientForm
					documentNumber={userDoc}
					setActive={setActive}
					setActive2={setActive2}
					GPClientData={GPClientData}
				/>
			</>
		)
	}

	function RenderOther() {
		return (
			<>
				<Title>Parece que você não é cliente Bom Parceiro.</Title>
				<Subtitle>
					Para se tornar um <TextRed>Cliente Bom Parceiro, </TextRed>
					precisamos que você
					<TextRed> realize um cadastro. </TextRed>
					Após preencher com seus dados, iremos analisar suas
					informações e retornaremos em seguida.
				</Subtitle>

				<NewClientForm
					documentNumber={userDoc}
					returnValues={returnValues}
					newCustomer={newCustomer}
				/>
			</>
		)
	}

	return (
		<NewAccountWrapper>
			<HeaderNotLogged />
			{newCustomer && goToStep ? (
				<main className="container">
					<Step
						documentNumber={userDoc}
						handleDocuments={handleDocuments}
						sendDocumentsPF={sendDocumentsPF}
						sendDocumentsPJ={sendDocumentsPJ}
						checkBoxLGPD={LGPDCheckbox}
						checkBoxSMS={SMSCheckbox}
						handleCheckBoxLGPD={handleLGPDCheckbox}
						handleCheckBoxSMS={handleSMSCheckbox}
						handleChangeConfirmationMethod={
							handleChangeConfirmationMethod
						}
						selectedConfirmationMethod={selectedConfirmationMethod}
						setActive={setActive}
						setActive2={setActive2}
						skipStep={skipStep}
						postalCode={newCustomer.PostalCode}
						address={`${newCustomer.Street}, ${newCustomer.HouseNumber}, ${newCustomer.Neighborhood} - ${newCustomer.PostalCode} - ${newCustomer.City}/${newCustomer.Region}`}
						handleCreateClient={handleCreateClient}
						name={newCustomer.Name}
						handleLatAndLong={handleLatAndLong}
						handleTermsAdhesionCheckbox={
							handleTermsAdhesionCheckbox
						}
						termsAdhesionCheckbox={termsAdhesionCheckbox}
						handlePassword={handlePassword}
						UF={
							typeof newCustomer.Region === 'string'
								? newCustomer.Region
								: newCustomer.Region.value
						}
						handleIsOtimizacao={handleIsOtimizacao}
					/>
				</main>
			) : (
				<main className="container">
					<GoBackButton
						className="back-arrow-wrapper"
						onClick={handleBack}>
						<img
							className="back-arrow"
							src={ArrowLeftIcon}
							alt="Voltar"
						/>
						<p>Voltar ao Login</p>
					</GoBackButton>
					{viewError?.type === 'birthDate' ||
					(viewError?.type === 'cpf' && viewError.message) ? (
						<FieldAlertFilledWithInvalidValue>
							<img src={AlertIcon} alt="Alert Icon" />
							<ErrorMessageText>
								{viewError.message}
							</ErrorMessageText>
						</FieldAlertFilledWithInvalidValue>
					) : null}
					<InputDocumentNumberWrapper>
						<h1 className="title-main">
							Primeiro, digite seu CPF ou CNPJ abaixo:
						</h1>

						<div className="document col-lg-4 col-md-12">
							<InputDocument
								returnValue={setUserDoc}
								valueIsValid={setDocumentIsValid}
								disabled={loading}
								className="input-tertiary"
								valueStore={userDoc}
							/>
						</div>

						<div className="document col-lg-4 col-md-12">
							{/* {console.log(userDoc, userDoc.length)} */}
							{canDisplayBirthDate &&
							viewState === 'OTHER' ? (
								<InputField
									value={birthDate}
									onChange={(e) =>
										handleBirthDate(e.target.value)
									}
									id="birthDate"
									label={'Data de Nascimento (DD/MM/YYYY)'}
									hasError={
										birthDate.length > 9 &&
										!validatorDate(birthDate)
									}
									className="input-tertiary"
									maxLength={10}
								/>
							) : null}
						</div>
					</InputDocumentNumberWrapper>

					{loading ? (
						<div className="loading-wrapper">
							<Loading color={colors.red} />
						</div>
					) : (
						<div
							className={viewState ? 'form form-border' : 'form'}>
							<div id="form-error" style={{ display: 'none' }}>
								<FieldAlertFilledWithInvalidValue>
									<img src={AlertIcon} alt="Alert Icon" />
									<ErrorMessageText>
										Agumas informações não estão corretas,
										realize a correção
									</ErrorMessageText>
								</FieldAlertFilledWithInvalidValue>
							</div>

							<div className="form-wrapper">
								{viewState === 'ALREADY_REGISTERED' ? (
									<RenderAlreadyRegistered />
								) : null}

								{viewState === 'GP_CLIENT' ? (
									<RenderGPClient />
								) : null}

								{viewState === 'OTHER' &&
								showFormForClientTypeOther ? (
									<RenderOther />
								) : null}
							</div>
						</div>
					)}
				</main>
			)}

			<Modal isActive={active}>
				<div className="modalContent">
					<button
						className="backgroundKnowMore"
						onClick={() => setActive(false)}>
						<img src={Close} alt="close" className="CloseButton" />
					</button>
					<div>
						<div className="pcontent">
							<h4 className="title">
								Aviso Legal – Privacidade e Proteção de Dados
								Pessoais
							</h4>
							<div>
								<p>
									Em respeito ao seu direito à privacidade e
									transparência no tratamento de dados
									pessoais, informamos que
								</p>
								<ul className="list">
									<li>
										Coletaremos apenas os dados pessoais
										estritamente necessários aos objetivos
										dos serviços oferecidos por este portal,
										sejam para:
										<ul>
											<li>
												Cumprimento de obrigações legais
												fisca e tributárias;
											</li>
											<li>
												Cumprimento dos contratos de
												compra e venda (comercial e
												financeiro)
											</li>
											<li>
												Comunicação sobre os serviços ou
												produtos contratados;
											</li>
											<li>
												Registros para manutenção da
												segurança e para evidenciação
												das transações realizadas.
											</li>
										</ul>
									</li>
								</ul>
								<p>
									Todo tratamento dos dados coletados será
									realizado apenas para atender às finalidades
									explicitadas acima, tendo sido tomadas as
									medidas necessárias para manter seus dados
									em segurança em nossos sistemas. Em caso de
									dúvidas, utilize o canal de comunicação do
									portal.
								</p>
								<p>
									Para registrarmos nosso compromisso, pedimos
									que confirme sua ciência sobre o tratamento
									dos seus dados pessoais, autorizando a
									coleta e tratamento para as finalidades
									acima, tendo que alguns tratamentos são
									fundamentais por obrigação legal ou
									contratual.
								</p>
								<p>
									Caso não concorde com o tratamento dos seus
									dados conforme exposto acima, não poderemos
									realizar seu cadastro e, consequentemente,
									não poderemos manter o relacionamento
									comercial pretendido.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<Modal isActive={active2}>
				<div className="modalContent">
					<button
						className="backgroundKnowMore"
						onClick={() => setActive2(false)}>
						<img src={Close} alt="close" className="CloseButton" />
					</button>
					<div>
						<div className="pcontent">
							<div>
								<p>
									Em respeito ao princípio da transparência e
									do seu direito de consentir ou não sobre o
									tratamento de seus dados para as finalidades
									apresentadas, solicitamos que leia o informe
									de tratamento de dados e confirme sua
									ciência, entendimento e consentimento.
								</p>
								<p>
									Caso não concorde com o tratamento dos seus
									dados conforme exposto acima, não iremos lhe
									enviar informações sobre lançamentos,
									promoções e outras ações de marketing ou
									comerciais.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			<FooterNotLogged />
		</NewAccountWrapper>
	)
}

export default NewAccount
