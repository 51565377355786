import React from 'react'

function TableButton(fn: (x: any) => any, src: any) {
	return (
		<button className="table__button" onClick={fn}>
			<img src={src} alt="" />
		</button>
	)
}

export default TableButton
