import { Padding, SizeEnum } from './types'
import { font, grid } from './variables'

export function getWidthString(span: string | number) {
	if (!span) return

	let width = (Number(span) / grid.cols) * 100

	return `max-width: ${width}%;`
}

export function mediaQuery(size: SizeEnum) {
	return (style: string) => {
		if (size === 'lg') {
			return `@media screen and (max-width: ${grid.lg}px) {
				${style}
			}`
		} else if (size === 'md') {
			return `@media screen and (max-width: ${grid.md}px) {
				${style}
			}`
		} else if (size === 'sm') {
			return `@media screen and (max-width: ${grid.sm}px) {
				${style}
			}`
		} else if (size === 'xs') {
			return `@media screen and (max-width: ${grid.xs}px) {
				${style}
			}`
		}
	}
}

export function transition(seconds = 0.3) {
	return `-webkit-transition: all ${seconds}s;
	-moz-transition: all ${seconds}s;
	transition: all ${seconds}s;`
}

export function fontType(fontFamily: string) {
	return `font-family: ${fontFamily};`
}

export function toRem(size: number) {
	return `${size / font.baseFontSize}rem;`
}

export function toEm(size: number) {
	return `${size / font.baseFontSize}em;`
}

export function ellipsis() {
	return `
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-wrap: normal;
		max-width: 100%;
		overflow: hidden;
		line-height: 1rem;
	`
}

export function paddingToString(padding: Padding) {
	if (typeof padding === 'string') return padding

	return `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`
}

/**
 *
 * @param color #FFFFFF
 * @param amount 20
 */

export function LightenDarkenColor(color: string, amount: number) {
	var usePound = false

	if (color[0] === '#') {
		color = color.slice(1)
		usePound = true
	}

	var num = parseInt(color, 16)

	var r = (num >> 16) + amount

	if (r > 255) r = 255
	else if (r < 0) r = 0

	var b = ((num >> 8) & 0x00ff) + amount

	if (b > 255) b = 255
	else if (b < 0) b = 0

	var g = (num & 0x0000ff) + amount

	if (g > 255) g = 255
	else if (g < 0) g = 0

	return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}
