import React from 'react'
import TextField from '../../../../../../../components/inputs/text-field/text-field.component'
import Loading from '../../../../../../../components/Loading'
import { TypeButtonEnum } from '../../../../../../../components/buttons/button/button-default.types'
import { TypeEnum } from '../../../../../../../components/inputs/text-field/text-field.types'
import ButtonDefault from '../../../../../../../components/buttons/button/button-default'
import { DocumentStepProps } from './document-step.types'
import { ForgotOrCreateNewCount } from '../../login-form.styles'

function DocumentStep({
	handleVerifyUserExists,
	isLoading,
	isValidDoc,
	setTextDoc,
	showCreateNew,
	showError,
	validDocument,
	handleChangeTypeSidebar
}: DocumentStepProps) {
	return (
		<>
			<h2>Para acessar a sua conta, digite o seu CPF ou CNPJ</h2>
			<TextField
				label={'CPF ou CNPJ'}
				placeholder={'Insira o seu CPF/CNPJ'}
				text={validDocument}
				handleChangeText={setTextDoc}
				type={TypeEnum.TEXT}
				isError={showError}
				labelIsError={'Você não possui cadastro com esses dados'}
				idElement="documentNumber"
			/>
			{showCreateNew && (
				<ForgotOrCreateNewCount
					onClick={handleChangeTypeSidebar}
					id="forgotPasswordButton">
					Criar Conta
				</ForgotOrCreateNewCount>
			)}
			{isLoading ? (
				<Loading />
			) : (
				<ButtonDefault
					buttonText="Continuar"
					type={
						isValidDoc
							? TypeButtonEnum.PRIMARY
							: TypeButtonEnum.DISABLED
					}
					onClick={handleVerifyUserExists}
					idElement="continueToPassword"
				/>
			)}
		</>
	)
}

export default DocumentStep
