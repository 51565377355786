import styled from 'styled-components'

export const WrapperOrderView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin-top: 24px;
	border-bottom: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
	padding-bottom: 42px;

	.wrapper-list-selector {
    display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
	}

  .wrapper-data-selector {
    width: 362px;
  }
`
export const EmptyContentWrapper = styled.div`
	height: 30vh;
`
