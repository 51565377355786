import React from 'react'
import { format, isPast, isValid, parse, parseISO } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import RDP, { DayPickerInputProps, DayPickerProps } from 'react-day-picker'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'

import CalendarIcon from '../../assets/images/calendar-red.svg'
import CalendarDisabled from '../../assets/images/calendar_disabled.svg'

import {
	monthNamesArray,
	weekNamesLong,
	weekNamesShort
} from '../../shared/utils/date'

import { DayPickerWrapper, SelectDateLabel } from './styles'
import { IProps } from './types'

const FORMAT = "dd 'de' LLLL yyyy"
const locale = { locale: BR }

function InputSelectPeriodPicker(props: IProps) {
	const {
		label,
		value,
		onChange,
		containerStyle,
		isDisabled,
		disableDatesFuture
	} = props
	const { from, to } = value

	function handleDayChange(date: Date): void {
		const range = RDP.DateUtils.addDayToRange(date, value)
		onChange(range)
	}

	function formatValue() {
		if (!from || !to) return

		function parseToDisplay(date: Date) {
			if (!date) return 'DD/MM/YY'

			return format(date, 'dd/MM/yy', locale)
		}

		return `De ${parseToDisplay(from)} até ${parseToDisplay(to)}`
	}

	function handleFormatDate(date: Date): string {
		if (!date || !isValid(date)) return ''

		return format(date, FORMAT, locale)
	}

	function handleParseDate(date: string): Date | void {
		if (!date || !isValid(parseISO(date))) return

		if (isPast(parseISO(date))) return

		return parse(date, FORMAT, new Date(), locale)
	}

	const dayPickerProps: DayPickerProps = {
		disabledDays: disableDatesFuture
			? { after: new Date() }
			: isDisabled
			? {
					before: new Date(),
					after: new Date()
			  }
			: { before: new Date() },
		months: monthNamesArray,
		weekdaysLong: weekNamesLong,
		weekdaysShort: weekNamesShort,
		renderDay: (date: Date) => <p>{date.getDate()}</p>,
		renderWeek: (weekNumber: number) => <p>{weekNamesLong[weekNumber]}</p>,
		modifiers: { start: from || undefined, end: to || undefined },
		selectedDays: [from || undefined, { from, to }]
	}

	const dayPickerInputProps: DayPickerInputProps = {
		dayPickerProps: dayPickerProps,
		hideOnDayClick: false,
		inputProps: { readOnly: true },
		onDayPickerHide: () => null,
		onDayPickerShow: () => null
	}

	return (
		<DayPickerWrapper isDisabled={!!isDisabled} style={containerStyle}>
			<div className="input-primary">
				<SelectDateLabel>{label}</SelectDateLabel>
				{/*@ts-ignore*/}
				<DayPickerInput
					{...props}
					{...dayPickerInputProps}
					placeholder={label}
					onDayChange={handleDayChange}
					value={formatValue()}
					parseDate={handleParseDate}
					formatDate={handleFormatDate}
				/>

				<img
					src={isDisabled ? CalendarDisabled : CalendarIcon}
					alt="Ícone de calendário"
				/>
			</div>
		</DayPickerWrapper>
	)
}

export default InputSelectPeriodPicker
