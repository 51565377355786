import * as React from 'react'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import PinDropIcon from '@mui/icons-material/PinDrop'
import { InputAdornment } from '@material-ui/core'
import * as S from './select-cd.styles'
import { ISelectCD, MenuProps } from './select-cd.types'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { handleChangeSelectedCd } from '../utils/handle-change-selected-cd'
function SelectCD({ cdList, activeCd, setActiveCd }: ISelectCD) {
	const { customer } = useTypedSelector(['customer'])
	const dispatch = useDispatch()
	return (
		<S.WrapperSelect>
			<FormControl sx={{ mt: 1 }} size="small">
				<Select
					className="select-cd"
					MenuProps={MenuProps}
					startAdornment={
						<InputAdornment position="start">
							<PinDropIcon
								sx={{
									width: '16px',
									height: '16px'
								}}
							/>
						</InputAdornment>
					}
					value={activeCd}
					onChange={(event: SelectChangeEvent) =>
						handleChangeSelectedCd({
							customer: customer,
							dispatch: dispatch,
							event: event,
							setCd: setActiveCd
						})
					}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
					defaultValue={activeCd}>
					{cdList.map((cd) => (
						<MenuItem
							key={cd.salesOrganizationID}
							value={cd.salesOrganizationID}>
							{cd.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</S.WrapperSelect>
	)
}

export default SelectCD
