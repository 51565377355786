import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const ConfirmBannerModalStyles = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	padding: 40px 0px;

	.info-modal {
		display: flex;
		align-items: center;
		background-color: ${colors.fifthGray};
		padding: 16px;
		gap: 20px;
		width: 100%;

		svg {
			color: ${(props) => props.theme.palette.gray.main};
		}
		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.primaryWhite.contrastText};
		}
	}
`
