import styled from 'styled-components'

export const SuggestionBlockWrapper = styled.section`
	padding: 40px 24px;
	border-radius: 4px;
	background-color: ${(props) => props.theme.palette.white.main};
`

export const SuggestionBlockTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	margin-bottom: 24px;
	display: inline-block;
`

export const SuggestedProductsWrapper = styled.div`
	gap: 16px;
	width: 100%;
	display: grid;
	place-items: center;
	grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));

	@media screen and (max-width: 768px) {
	}
`

export const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 250px;
`
