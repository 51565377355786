import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const NewPasswordComponentView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const Title = styled.h1`
	width: 100%;
	color: ${colorThemeProvider.primaryWhiteColorContrasct};
	font-size: 1.5rem;
	font-weight: 600;
`

export const WrapperPassword = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 2rem;
`

export const RequirementsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 1.5rem;
	gap: 2rem;
`

export const RowRequirements = styled.div<{
	$emptyPassword: boolean
	$checked: boolean
}>`
	display: flex;
	gap: 0.5rem;

	p {
		color: ${(props) =>
			props.$emptyPassword
				? colorThemeProvider.grayColor
				: props.$checked
				? colorThemeProvider.successColor
				: colorThemeProvider.errorColor};
		font-size: 1rem;
		line-height: 1.25rem;
	}
`

export const FieldsWrapper = styled.div`
	width: 50%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 1rem;

	div {
		max-width: 312px;
	}
`
