import { Tab, Tabs } from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import ArrowIcon from '../../../assets/images/arrow-left-red.svg'
import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'
import { AdminRoutesEnum } from '../../../modules/Routes/adminRoutesEnum'
import { Loading } from '../../../shared/components'
import { handleInformation } from '../consult-client.service'
import { IDataTable } from '../index.types'
import Information from './components/Information/Information.view'
import Order from './components/OrderList/order-list.view'
import {
	DividerSimple,
	PageHeader,
	StyledInputContainer
} from './consult-client-info.styles'
import { CustomerInfoContext } from '../../../context/customer-info.context'

export default function ConsultCustomerInfo() {
	const { state: dataReceived }: any = useLocation()
	const props: IDataTable = dataReceived?.dataReceived
	const [activeTab, setActiveTab] = useState('Informações')
	const { handleIsBackInThePage } = useContext(CustomerInfoContext)
	const [isLoading] = React.useState(false)
	const [propsInfo, setPropsInfo] = useState('')
	const consultVisit = async () => {
		const response = await handleInformation(
			props?.customerID,
			props?.salesOrganizationID
		)

		if (!response) return

		setPropsInfo(response)
	}

	useEffect(() => {
		consultVisit()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const tabContent: { [key: string]: React.ReactNode } = {
		Informações: (
			<Information
				propsData={propsInfo}
				others={{
					sellerName: props.employeeName,
					status: props.status
				}}
			/>
		),
		Pedidos: <Order customerAddressInfo={propsInfo} />
	}

	const handleTabChange = (event: any, newValue: any) => {
		setActiveTab(newValue)
	}

	const handleGoBack = () => {
		handleIsBackInThePage()
		return AdminRoutesEnum.CONSULT_CUSTOMER
	}

	return (
		<>
			<DefaultLayoutAdmin>
				<div className="container">
					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<StyledInputContainer>
							<PageHeader>
								<Link
									className="go-back-button"
									to={handleGoBack()}>
									<img
										className="back-arrow"
										src={ArrowIcon}
										alt="Ícone de flecha apontando para a esquerda"
									/>
									<span>Voltar</span>
								</Link>
								<h1 className="pg-title">{props.name}</h1>
								<p>
									Unidade {props.salesOrganization} -{' '}
									{props.salesOrganizationID}
								</p>
							</PageHeader>

							<Tabs
								value={activeTab}
								onChange={handleTabChange}
								variant="scrollable"
								scrollButtons="auto"
								aria-label="Tabs"
								indicatorColor="secondary"
								textColor="secondary">
								{Object.keys(tabContent).map((label) => (
									<Tab
										label={label}
										value={label}
										key={label}
									/>
								))}
							</Tabs>
							<DividerSimple />
							<div>{tabContent[activeTab]}</div>
						</StyledInputContainer>
					)}
				</div>
			</DefaultLayoutAdmin>
		</>
	)
}
