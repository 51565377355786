import React from 'react'
import PlaceHolderSearchIcon from '../../../assets/images/placeholder-search.svg'

import styles from './styles.module.scss'

interface IPlaceholderProps {
  type?: 'default' | 'not_found'
}

export default function Placeholder({ type = 'default' }: IPlaceholderProps) {
  return (
    <div className={styles['placeholder']}>
      <img src={PlaceHolderSearchIcon} alt="Ícone de pesquisa" />
      {type === 'default' ? (
        <span>Digite o CNPJ ou CPF do Cliente para exibirmos suas informações</span>
      ) : (
        <span>Cliente não encontrado! Verifique o documento informado.</span>
      )}
    </div>
  )
}
