import React, { ReactNode, useState } from 'react'
import {
  AccordionContainer,
  AccordionArrowIcon
} from './accordion.styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

export interface AccordionComponentProps {
  accordionSummary: ReactNode;
  accordionDetails: ReactNode;
	isMenuAccordion?: boolean;
	isExpanded?: boolean;
}

export function AccordionComponent({
  accordionSummary,
  accordionDetails,
	isMenuAccordion = false,
	isExpanded: defaultExpanded
}: AccordionComponentProps) {
  const [isExpanded, setIsExpanded] = useState(!!defaultExpanded);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <AccordionContainer>
      <Accordion
        elevation={0}
        disableGutters={true}
        expanded={isExpanded}
        square
        style={{ margin: '16px 0px', textAlign: 'left' }}
        className={isMenuAccordion  ? 'accordion-summary' : ''}
      >
        <AccordionSummary
          onClick={toggleExpanded}
          expandIcon={<AccordionArrowIcon />}
          style={{ height: '2px', marginLeft: '-16px' }}
        >
          {accordionSummary}
        </AccordionSummary>
        <AccordionDetails>
          {accordionDetails}
        </AccordionDetails>
      </Accordion>
    </AccordionContainer>
  )
}