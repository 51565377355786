import React from 'react'

import { IProps } from './types'
import Retornavel from '../../../assets/images/retornavel.png'
import ArrowUpRedIcon from '../../../assets/images/arrow-up-red.svg'

function ReturnableAgreement(props: IProps) {
	const { handleClientAgreement, showAlert } = props

	return (
		<div className="returnable-agreement">
			<div className="returnable-container">
				<div className="icon-wrapper">
					<img
						className="returnable-icon"
						src={Retornavel}
						alt="ícone de produto retornável"
					/>
				</div>
				<div className="text-message">
					<h3>Produtos Retornáveis</h3>
					<p>
						Identificamos que você escolheu alguns produtos com
						vasilhames retornáveis. No ato da entrega, iremos
						recolher todos os vasilhames relativos ao produto
						solicitado.
					</p>
				</div>
			</div>
			<div className="checkbox">
				<label>
					<input
						type="checkbox"
						id="returnable-product-agreement"
						name="returnable-product-agreement"
						onClick={handleClientAgreement}
					/>
					Estou ciente e entregarei os vasilhames.
				</label>
				{showAlert ? (
					<div className="error-message">
						<img src={ArrowUpRedIcon} alt="" />
						<p>
							Por favor, selecione que está ciente da devolução
							dos vasilhames.
						</p>
					</div>
				) : null}
			</div>
		</div>
	)
}

export default ReturnableAgreement
