import { is, prop } from 'ramda'
import React, { Fragment } from 'react'

import { KeyboardTimePicker } from '@material-ui/pickers'

import { TagInput } from '../../../shared/components'
import InputField from '../../../components/InputField'
import { isValidTimeToUse } from '../../../shared/utils/resale'
import { MONEY_FORMATTER, MONEY_MASK } from '../../../shared/utils/validators'
import { IProps } from './types'

import ClockIcon from '../../../assets/images/clock.svg'

function ResaleParameterInput(props: IProps): JSX.Element {
	const { Items, Type, TypeValue, index, addTag, removeTag, updateParamKey } =
		props

	const isInterval = TypeValue === 'INTERVAL'
	const isRange = TypeValue === 'RANGE'
	const isSingle = TypeValue === 'SINGLE'

	const isTime = Type === 'TIME'
	const isMoney = Type === 'MONEY'
	const isCheckbox = Type === 'BOOLEAN'

	const isValueDate = (x: any) => is(Date, Items?.[x]?.Value)

	const getValue = (x: number) => {
		const { Value } = Items?.[x] || {}

		if (!Value) return null

		if (isTime) {
			const isStr = Boolean(Value && Value?.split)

			const numsArr = isStr ? Value?.split(':').map(Number) : []

			const [hour, min] = isStr ? numsArr : [NaN, NaN]

			const validHM = isValidTimeToUse(hour) && isValidTimeToUse(min)

			const dateToUse = validHM ? new Date(2020, 0, 1, hour, min) : null

			const valueIfTime = isValueDate(x) ? Value : dateToUse

			return valueIfTime
		}

		if (isMoney) {
			const valueNum = Number(Value.replace(MONEY_MASK, '').trim()) / 100

			return MONEY_FORMATTER.format(valueNum)
		}

		return Value
	}

	const firstInputValue = getValue(0)
	const lastInputValue = getValue(1)

	const onChange = (p: 'start' | 'end') => updateParamKey(index, 'Items', p)

	const clockIcon = () => (
		<img className="clock-icon" src={ClockIcon} alt="ícone de relógio" />
	)

	const timePickerDefaultProps = {
		className: 'time-picker',
		margin: 'dense' as 'dense',

		invalidDateMessage: 'Formato de hora inválido',
		KeyboardButtonProps: { 'aria-label': 'change time' },
		keyboardIcon: clockIcon(),
		DialogProps: { className: 'time-modal' }
	}

	const tags = isSingle ? [] : Items.map(prop('Value'))

	const tagInputProps = {
		tags,
		addTag: addTag(index),
		removeTag: removeTag(index)
	}

	const isChecked = String(firstInputValue).toLowerCase() === 'true'

	return (
		<Fragment>
			<div className="param-table-field-wrapper">
				{isCheckbox ? (
					<div className="checkbox-param-input">
						<label>
							<input
								type="checkbox"
								id="LGPD"
								name="LGPD"
								checked={isChecked}
								onChange={onChange('start')}
							/>
						</label>
					</div>
				) : null}
				{isTime ? (
					<KeyboardTimePicker
						label="INÍCIO"
						onChange={onChange('start')}
						value={firstInputValue}
						{...timePickerDefaultProps}
					/>
				) : !isCheckbox && !isRange ? (
					<InputField
						className="param-input"
						id={`value-start-${index}`}
						label="valor"
						onChange={onChange('start')}
						secondary
						value={firstInputValue ? (firstInputValue as any) : ''}
					/>
				) : null}

				{isRange && !isSingle ? <TagInput {...tagInputProps} /> : null}
			</div>
			{isInterval ? (
				<div>
					{isTime ? (
						<KeyboardTimePicker
							label="FIM"
							onChange={onChange('end')}
							value={lastInputValue}
							{...timePickerDefaultProps}
						/>
					) : (
						<InputField
							id={`value-end-${index}`}
							label="fim"
							onChange={onChange('end')}
							secondary
							value={lastInputValue as any}
						/>
					)}
				</div>
			) : null}
		</Fragment>
	)
}

export default ResaleParameterInput
