import React, { Fragment } from 'react'

import QuestionIllustration from '../../assets/images/question-illustration.png'

import { AnnouncementCardTypeEnum, IAnnouncementCardProps } from './types'

import * as S from './styles'

import { Link } from 'react-router-dom'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'

export default function AnnouncementCard(props: IAnnouncementCardProps) {
	const { cardType } = props
	return (
		<Fragment>
			{cardType === AnnouncementCardTypeEnum.ANNOUNCEMENT && (
				<S.CardWrapper cardType={cardType}>
					<div className="content-announcement">
						<span className="card-announcement-title">
							Programa Mais Pontos
						</span>
						<p className="card-announcement-description">
							Uma lista repleta de produtos que te dão mais
							pontos!
						</p>
					</div>

					<Link
						to={`${CustomerRoutesEnum.PRODUCT_LIST}?productsThatScoreTheMost=true`}>
						<button className="see-products-button">
							VER PRODUTOS
						</button>
					</Link>
				</S.CardWrapper>
			)}
			{cardType === AnnouncementCardTypeEnum.FAQ && (
				<Link to={CustomerRoutesEnum.POINTS_PROGRAM__KNOW_MORE}>
					<S.CardWrapper cardType={cardType}>
						<div className="question-illustration">
							<img
								src={QuestionIllustration}
								alt="Círculo vermelho com ícone de interrogação de cor branca, ao centro."
							/>
						</div>
						<div className="content">
							<span className="title-learn-more">
								Saiba mais sobre o Programa de Pontos
							</span>
							<p className="description-learn-more">
								Acesse o FAQ e tire suas dúvidas sobre as regras
								do Programa.
							</p>
						</div>
					</S.CardWrapper>
				</Link>
			)}
		</Fragment>
	)
}
