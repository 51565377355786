import styled from 'styled-components'

export const DetailsModalWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	gap: 20px;

	padding: 30px 0px;

	.params-wrapper {
		font-weight: ${(props) => props.theme.font.weight.bold};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;

		color: ${(props) => props.theme.palette.black.main};
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		gap: 25px;
		padding-bottom: 20px;

		.inputs-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-around;
			gap: 10px;
			width: 567px;
			padding: 10px;

			.input-collum {
				width: 266px;
				display: flex;
				flex-direction: column;
				gap: 10px;
			}
		}

		border-bottom: 1px solid #e0e0e0;
	}

	.text-section {
		h3 {
			font-weight: ${(props) => props.theme.font.weight.bold};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;

			color: ${(props) => props.theme.palette.primary.main};
		}

		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;;
			color: #5d615d;
		}
	}

	.last-item {
		margin-bottom: 30px;
	}
`
