import React from 'react'
import { ImageInfo, ImageName, ImageSize, PreviewImage } from './styles'
import { IPropsPreviewImage } from './types'
import Trash from '../../assets/images/trash-secondary.svg'
import Thumbnail from '../../assets/images/pdf-thumbnail.svg'
import formatBytes from '../../shared/utils/bytes'
import { format } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'

export function ImagePreview(props: IPropsPreviewImage) {
	const { image, deleteImage, col, onClick, id } = props

	const stringLength = image.length - image.split(',')[0]?.length

	const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812
	const sizeInKb = sizeInBytes / 1000

	const today = format(new Date(), 'yyyy-MM-dd_HH:mm:ss', { locale: BR })
	const fileName = `${id}_${today}.jpeg`

	return (
		<PreviewImage className={col ? col : ''}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center'
				}}>
				<img
					src={image}
					alt="preview"
					className="img-preview"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = Thumbnail
					}}
					onClick={onClick}
				/>
				<ImageInfo style={{ width: '60%' }}>
					<ImageName>{fileName}</ImageName>
					{sizeInKb && <ImageSize>{formatBytes(sizeInKb)}</ImageSize>}
				</ImageInfo>
			</div>

			<button
				style={{
					height: '100%',
					width: 58,
					alignItems: 'center',
					justifyContent: 'center',
					background: 0,
					border: 0
				}}
				onClick={deleteImage}>
				<img src={Trash} alt="trash" />
			</button>
		</PreviewImage>
	)
}
