import styled from 'styled-components'

export const ScoreValueLabel = styled.div`
	align-self: flex-start;
	margin-bottom: 11px;

	padding: 3px 8px;
	border-radius: 10px;
	background-color: ${(props) => props.theme.palette.success.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;
	color: ${(props) => props.theme.palette.white.main};
`

export const PriceInfosWrapper = styled.div`
	width: 100%;
	padding: 16px 0px;
	border-bottom: 1px solid ${(props) => props.theme.palette.lightGray.main};
	border-bottom-width: 100%;
	margin-bottom: 16px;
`

export const PriceToPayInfo = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 8px;
`

export const UnitPriceInfosWrapper = styled(PriceToPayInfo)`
	margin-bottom: 0px;
`

export const NormalPriceLabel = styled.span`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
`

export const NormalPriceValue = styled(NormalPriceLabel)`
	&.new-price {
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const UnitPriceLabel = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	text-transform: uppercase;
`

export const UnitPriceValue = styled(UnitPriceLabel)`
	&.new-price {
		color: ${(props) => props.theme.palette.primary.main};
	}
	color: ${(props) => props.theme.palette.gray.main};
	text-transform: uppercase;
`

export const PriceActionWrapper = styled.div`
	display: flex;
	gap: 3px;
	align-items: center;
`

export const OldPriceValue = styled.span`
	color: ${(props) => props.theme.palette.white.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-decoration: line-through;
`
