import styled from 'styled-components'
import { toRem } from '../../shared/styles/functions'
import { colors } from '../../shared/styles/theme'

export const SessionTitle = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
	display: inline-block;
	margin-bottom: ${toRem(8)};
`

export const SessionWrapper = styled.section`
	margin-bottom: ${toRem(36)};
`

export const ChallengeItemWrapper = styled.div`
	margin-bottom: ${toRem(8)};
`

export const LoadingWrapper = styled.div`
	width: 100%;
	min-height: 250px;
	max-height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 24px 0px;
`
