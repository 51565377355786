import styled from 'styled-components'

export const WrapperModalConfirmOrder = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 24px;

	width: 100%;

	padding-top: 21px;

	h2 {
		color: ${(props) => props.theme.palette.black.main};
		font-size: 16px;
		font-weight: 400;
	}
`

export const WrapperOptionsOder = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 26px;
	width: 100%;

	height: 387px;
	overflow-y: scroll;

	color: ${(props) => props.theme.palette.black.main};
	font-size: 14px;
	font-weight: 400;
`
