import styled, { css } from 'styled-components'

const containerStyle = () => css`
	width: 100%;
	margin: 0 auto;
	max-width: 1088px;
`

export const PointsRedemptionWrapper = styled.div`
	width: 100%;
	padding: 16px;
	min-height: 100vh;
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
`

export const ContentWrapper = styled.div`
	${containerStyle()}
`

export const CardsWrapper = styled.div`
	display: grid;
	column-gap: 16px;
	margin: 48px 0px;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(2, 1fr);

	@media screen and (max-width: 768px) {
		row-gap: 16px;
		justify-content: center;
		grid-template-columns: 1fr;
	}
`

export const SectionBlockSuggestions = styled.section``

export const FiltersListTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	display: inline-block;
`

export const FiltersListTitleWrapper = styled.div`
	width: 100%;
	margin-top: 40px;
	padding-left: 16px;
	padding-bottom: 8px;
	padding-right: 16px;
	border-bottom: 1px solid ${(props) => props.theme.palette.lightGray.main};
`

export const FilteresWrapper = styled.div`
	${containerStyle()}

	.combo-filters {
		margin-top: 24px;
	}
`

export const RedeemableProductListWrapper = styled.section`
	${containerStyle()}
	gap: 16px;
	display: grid;
	margin-top: 40px;
	place-items: center;
	padding-bottom: 56px;
	grid-template-columns: repeat(auto-fill, minmax(248px, 1fr));
`

export const LoadingWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 250px;
`
