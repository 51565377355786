import styled from "styled-components";

export const MutedButtonWrapper = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  border-radius: 5px;
  background: #FFFFFF40;

  &:hover{
    cursor: pointer;
  }
`;

export const RounderMutedWrapper = styled.div`
    width: 25px;
    height: 25px;
    border: 2px solid ${(props) => props.theme.palette.background.paper};
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

  svg{
    color: ${(props) => props.theme.palette.background.paper}
  }
`