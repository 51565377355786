import { createElement, useEffect, useState } from 'react'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

import MyPointsView from './view'

import { IExtractPointsType, IViewProps } from './types'
import { getPointsExtracts } from '../../shared/services/customerPointsProgram.service'

export default function MyPoints() {
	const { customer, pointsProgramStatus } = useTypedSelector([
		'customer',
		'pointsProgramStatus'
	])

	const [
		showModalWithMembershipTermsState,
		setShowModalWithMembershipTermsState
	] = useState(false)

	const [extractOfPointsData, setExtractOfPointsData] =
		useState<IExtractPointsType | null>(null)

	const [isLoading, setIsLoading] = useState(false)

	const fetchExtractOfPoints = () => {
		;(async () => {
			setIsLoading(true)
			const response = await getPointsExtracts(customer.CustomerID)
			setExtractOfPointsData(response)
			setIsLoading(false)
		})()
	}

	const showMoreItemsFromTheListHandler = () => {}
	// console.log('Mostrando mais items da lista')

	const showModalWithMembershipTermsHandler = () => {
		setShowModalWithMembershipTermsState(!showModalWithMembershipTermsState)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchExtractOfPoints, [customer.CustomerID, pointsProgramStatus])

	const viewProps: IViewProps = {
		isLoading,
		extractOfPointsData,
		showMoreItemsFromTheListHandler,
		showModalWithMembershipTermsState,
		showModalWithMembershipTermsHandler,
		pointsProgramStatus: pointsProgramStatus.userJoinedTheProgram
	}
	return createElement(MyPointsView, viewProps)
}
