import * as React from 'react'
import { Header } from '../../shared/components'
import { Box, CssBaseline } from '@mui/material'
import { DefaultWrapper } from './default-admin.styles'
import { Container } from '../../components/Container/container.style'

interface IDefaultLayout {
	children: JSX.Element
}

const DefaultLayoutAdmin = ({ children }: IDefaultLayout) => (
	<DefaultWrapper>
		<CssBaseline />
		<Header />
		<Container>
			<Box pt={3}>{children}</Box>
		</Container>
	</DefaultWrapper>
)

export default DefaultLayoutAdmin
