import { ICartItem } from '../interfaces/cart'
import { store } from '../store'

export enum IAllInEvent {
	search = 'search',
	product = 'product',
	cart = 'cart',
	client = 'client',
	transaction = 'transaction'
}

interface Customer {
	Email: string
}

export function sendToAllIn(event?: IAllInEvent, items?: any, object?: any) {
	try {
		const customer: Customer = store.getState().customer

		//@ts-ignore
		const Btg360 = window['Btg360']
		// todo: arquivo btg não é um modulo e está sendo utilizado globalmente

		Btg360.add({
			account: '11302:1',
			domain: 'bomparceirogp.com.br',
			event: IAllInEvent.client,
			items: [
				{
					email: customer.Email
				}
			]
		})

		if (event && items) {
			const newObject = object ? { ...object } : undefined

			Btg360.add({
				account: '11302:1',
				domain: 'bomparceirogp.com.br',
				event,
				items,
				newObject
			})
		}
	} catch (error) {
		console.error({ error })
	}
}

export function sendOrderTransactionTag(
	id: string,
	order: ICartItem[],
	email: string
) {
	order.map((newOrder) => {
		const items = {
			transactionId: id,
			id: newOrder?.MaterialID || newOrder?.promotionID,
			name: newOrder.Description,
			price: newOrder.Price,
			email
		}
		sendToAllIn(IAllInEvent.transaction, [items], items)

		return items

		// return {
		// 	transactionId: id,
		// 	id: newOrder?.MaterialID || newOrder?.promotionID,
		// 	name: newOrder.Description,
		// 	price: newOrder.Price,
		// 	email
		// }
	})
}
