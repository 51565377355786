import React, { useEffect } from 'react'
import { ComponentCookie } from './cookie-content.styles'

const CookieContent: React.FC = () => {
	useEffect(() => {
		const script = document.createElement('script')
		script.src =
			'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk-loader.js'
		script.setAttribute(
			'data-tenant-uuid',
			'8388c660-e53f-48c5-aca9-78616a2333be'
		)
		script.setAttribute(
			'data-domain-uuid',
			'ff819387-23e1-413d-b4ef-cb1523348b42'
		)
		script.setAttribute('data-backend-url', 'https://app.securiti.ai')
		script.defer = true

		const parentElement = document.head || document.body
		parentElement.appendChild(script)

		script.addEventListener('load', () => {
			window.initCmp()
		})

		return () => {
			// Cleanup: remove the script when the component is unmounted
			parentElement.removeChild(script)
		}
	}, [])

	return (
		<>
			<ComponentCookie
				className="cmp-revoke-consent"
				style={{
					position: 'relative',
					bottom: '0px',
					left: '0px',
					padding: '0px'
				}}>
				Gerenciamento de Cookies
			</ComponentCookie>
		</>
	)
}

export default CookieContent
