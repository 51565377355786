import React from 'react'

import { Slider, withStyles } from '@material-ui/core'
import { colorThemeProvider } from '../../theme/themeProvider'

// TODO: Type
const SliderCustom = withStyles({
	root: {
		color: colorThemeProvider.primaryColor,
		height: 8
	},
	thumb: {
		height: 16,
		width: 16,
		backgroundColor: colorThemeProvider.whiteColor,
		border: '2px solid currentColor',
		marginTop: -5,
		marginLeft: -12,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit'
		},
		'&$disabled': {
			height: 16,
			width: 16
		}
	},
	active: {},
	disabled: {},
	valueLabel: {
		left: 'calc(-50% + 4px)'
	},
	track: {
		height: 6,
		borderRadius: 4
	},
	rail: {
		height: 6,
		borderRadius: 4
	}
})(Slider)

function CustomSlider(props: any) {
	return <SliderCustom {...props} />
}

export default CustomSlider
