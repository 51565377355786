import React from 'react'
import PlaceholderIcon from '../../../assets/images/no-extract-placeholder.svg'

import * as S from './styles'

export interface IProps {
	showModalWithMembershipTermsHandler: () => void
}

export default function PlaceholderNoExtract({
	showModalWithMembershipTermsHandler
}: IProps) {
	return (
		<S.PlaceholderWrapper>
			<S.PlaceholderContent>
				<S.PlaceholderImage src={PlaceholderIcon} />
				<S.PlaceholderContentTitle>
					Não há extrato para ser exibido
				</S.PlaceholderContentTitle>
				<S.PlaceholderContentDescription>
					FAÇA ADESÃO AO PROGRAMA DE PONTOS PARA EXIBIRMOS SUA
					MOVIMENTAÇÃO DE PONTOS
				</S.PlaceholderContentDescription>
				<S.PlaceholderButton
					onClick={showModalWithMembershipTermsHandler}
					className="red-button">
					PARTICIPAR DO PROGRAMA
				</S.PlaceholderButton>
			</S.PlaceholderContent>
		</S.PlaceholderWrapper>
	)
}
