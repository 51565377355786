import React from 'react'
import FacebookIcon from '../../assets/icons/facebook-icon.png'
import TwitterIcon from '../../assets/icons/twitter-icon.png'
import LinkedInIcon from '../../assets/icons/linkedin-icon.png'
import InstagramIcon from '../../assets/icons/instagram-icon.png'
import { WrapperIconsSocial } from './social-midia-icons.styles'

const SocialMidiaIcons = () => {
	const itens = [
		{
			icon: FacebookIcon,
			alt: 'facebook icon',
			href: 'https://www.facebook.com/grupo.petropolis'
		},
		{
			icon: TwitterIcon,
			alt: 'Twitter icon',
			href: 'https://twitter.com/grupopetropolis?lang=en'
		},
		{
			icon: LinkedInIcon,
			alt: 'linkedin icon',
			href: 'https://www.linkedin.com/company/grupopetropolis/'
		},
		{
			icon: InstagramIcon,
			alt: 'Instagram icon',
			href: 'https://www.instagram.com/grupo.petropolis/'
		}
	]

	return (
		<WrapperIconsSocial>
			{itens.map((item) => (
				<a key={item.href} href={item.href} target="_blank" rel="noreferrer">
					<img src={item.icon} alt={item.alt} />
				</a>
			))}
		</WrapperIconsSocial>
	)
}

export default SocialMidiaIcons
