import React from 'react'

import ArrowLeftIcon from '../../assets/images/arrow-left-red.svg'

import { InputCode } from '../../shared/components'

import { IViewProps } from './types'
import { Container } from './styles'
import HeaderNotLogged from '../../components/HeaderNotLogged'
import FooterNotLogged from '../../components/FooterNotLogged'
import ButtonPrimary from '../../components/ButtonPrimary'
import { primaryButtonStyle } from '../../shared/styles/theme'

function ConfirmActivationCodeView(props: IViewProps) {
	const {
		timer,
		enabled,
		setEnabled,
		canResendCode,
		confirmationMethod,
		setConfirmationCode,
		handleBack,
		handleSubmit,
		handleResendCode
	} = props

	return (
		<Container>
			<HeaderNotLogged />

			<div className="container wrapper">
				<div className="header">
					<button className="back-arrow-btn" onClick={handleBack}>
						<img src={ArrowLeftIcon} alt="Voltar" />
						<p>Voltar ao Login</p>
					</button>

					<h1 className="header-text">Concluir Cadastro</h1>
				</div>

				<p className="activation-code-subtitle">
					Para concluir o cadastro, insira o código que enviamos via{' '}
					{confirmationMethod === 'sendemail' ? 'E-mail' : 'SMS'}
				</p>

				<div className="form col-lg-6 col-md-12">
					<div className="activation-code">
						<InputCode
							returnValue={setConfirmationCode}
							valueIsValid={setEnabled}
							className="input-tertiary"
						/>

						<ButtonPrimary
							onClick={handleSubmit}
							disabled={!enabled}
							className='button-center'
							{...primaryButtonStyle}
							>
							CONTINUAR
						</ButtonPrimary>

						{confirmationMethod === 'sendsms' ? (
							<button
								onClick={handleResendCode}
								disabled={!canResendCode}
								className="button-primary button-center">
								{canResendCode
									? 'REENVIAR CÓDIGO'
									: `REENVIAR CÓDIGO EM 00:${timer
											.toString()
											.padStart(2, '0')}`}
							</button>
						) : null}
					</div>
				</div>
			</div>

			<FooterNotLogged />
		</Container>
	)
}

export default ConfirmActivationCodeView
