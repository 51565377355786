import React, { ChangeEvent, useEffect, useState } from 'react'
import Upload from '../../../assets/images/upload-file.svg'
import Webcam from '../../../assets/images/webcam.svg'
import ArrowLeftIcon from '../../../assets/images/arrow-left-red.svg'

import {
	SelectDocumentType,
	StepFourWrapper,
	Title,
	UploadUserPhoto,
	UploadWrapper
} from '../styles'
import SendDocument from '../../../components/SendDocument'
import { IProps as ISendDocument } from '../../../components/SendDocument/types'
import { IProps, ISelectDocumentType, DocumentType } from './types'
import { ImagePreview } from '../../../components/SendDocument/util'
import CheckBox from '../../../components/CheckBox'
import AccountActivationSessionSecondary from '../../../components/AccountActivationSessionSecondary'
import Maps, { ILatLng } from '../Maps'
import { GoBackButton } from '../../../components/SendDocument/styles'
import { colors, primaryButtonStyle } from '../../../shared/styles/theme'
import ButtonPrimary from '../../../components/ButtonPrimary'
import FloatLabelLocaleInput from '../../../components/FloatLabelLocaleInput'
import { OCRCustomerValidation, sendImagesToS3 } from '../service'
import { ISendDocumentsPF, ISendDocumentsPJ } from '../types'
import { DocumentCameraTypes, SelfieCameraTypes } from 'unico-webframe'
import { useUnicoSDKCamera } from '../../../shared/hooks/useUnicoSDKCamera'
import { InputPassword, SelectPrimary } from '../../../shared/components'
import { Maybe } from '../../../shared/interfaces/common'
import { Nullable } from 'Object/_api'
import { ActionMeta } from 'react-select'
import { ISelect } from '../../../components/SelectPrimary/types'

import { useUnicoProcess } from '../../../shared/hooks/useUnicoProcess'
import { decodeToken } from 'react-jwt'
import { IJwtJUnicoImage } from '../../../shared/interfaces/unicoSdk'
import { renameImageToAws, returnBase64AsImage } from '../util'
import { useTermsAdhesion } from '../../../shared/hooks/useTermsAdhesion'
import { LatLng } from '../Maps/types'
import { getRegistrationOptimization } from '../../../shared/services/customer.service'
import { checkPasswordIsValid } from '../../../shared/utils/validators'

export default function Step(props: IProps) {
	const {
		documentNumber,
		handleDocuments,
		checkBoxLGPD,
		checkBoxSMS,
		handleCheckBoxLGPD,
		handleCheckBoxSMS,
		handleChangeConfirmationMethod,
		selectedConfirmationMethod,
		setActive,
		setActive2,
		sendDocumentsPF,
		sendDocumentsPJ,
		skipStep,
		postalCode,
		address,
		handleCreateClient,
		name,
		handleLatAndLong,
		handleTermsAdhesionCheckbox,
		termsAdhesionCheckbox,
		UF,
		handlePassword,
		handleIsOtimizacao
	} = props

	const [stepCurrent, setStepCurrent] = useState(0)
	const [image, setImage] = useState<string | null>(null)
	const [personalFiscal, setPersonalFiscal] =
		useState<ISendDocumentsPF | null>(null)
	const [companyFiscal, setCompanyFiscal] = useState<ISendDocumentsPJ | null>(
		null
	)
	const [addressMapped, setAddressMapped] = useState('')
	const [loading, setLoading] = useState(false)
	const [region, setRegion] = useState<LatLng>()

	const [showModalAdhesion, setModalAdhesion] = useState(false)
	const [isOptimazation, setIsOptiomazation] = useState(false)
	const [password, setPassword] = useState('')

	const isCPF = documentNumber.length === 11

	const {
		stateCamera,
		errorCamera,
		loading: loadingCamera,
		prepareDocumentCamera,
		prepareSelfieCamera,
		id,
		showBoxCamera,
		cleanStateCamera
	} = useUnicoSDKCamera()
	const { modalTerms } = useTermsAdhesion({
		showModalAdhesion,
		onClose: onCloseModal
	})

	const { isLoading, processImages, unicoProcess } = useUnicoProcess()

	const [options] = useState<ISelectDocumentType[]>([
		// { value: DocumentCameraTypes.CNH, label: 'CNH' },
		{ value: DocumentType.CNH, label: DocumentType.CNH },
		{ value: DocumentType.RG, label: DocumentType.RG }
	])

	const [selectedDocumentType, setSelectedDocumentType] =
		useState<DocumentType>(DocumentType.RG)

	function handleSelectDocument(
		selectedValue: Maybe<Nullable<ISelectDocumentType>>,
		_: ActionMeta<ISelect>
	): void {
		setSelectedDocumentType(
			selectedValue?.value ? selectedValue.value : DocumentType.RG
		)
	}

	function onCloseModal() {
		setModalAdhesion(false)
	}

	function goToNextStep() {
		setStepCurrent((step) => step + 1)
		setImage(null)
		setLoading(false)
	}

	function goToPreviousStep() {
		if (stepCurrent === 4 && !isOptimazation) {
			setStepCurrent(0)
			return
		}

		setStepCurrent((step) => step - 1)
		setImage(null)
		setLoading(false)
	}

	function skipPhoto(id: string) {
		handleDocuments(isCPF, '', id)
		goToNextStep()
	}
	function getInfoRegistrationOptimization() {
		;(async () => {
			try {
				setLoading(true)

				const response = await getRegistrationOptimization({
					Lat: String(region?.lat),
					Long: String(region?.lng),
					Region: UF
				})

				handleIsOtimizacao(response.otimizacaoCadastro)

				handleLatAndLong(
					isCPF,
					String(region?.lat),
					String(region?.lng)
				)

				setIsOptiomazation(response.otimizacaoCadastro)

				if (response.otimizacaoCadastro) {
					goToNextStep()
				} else {
					setStepCurrent(4)
				}
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}
	function handleDeleteDocument(id: string) {
		setImage(null)
		deleteSpecificImage(id)
	}

	async function handleUploadDocument(
		event: ChangeEvent<HTMLInputElement>,
		id: string
	) {
		const selectedDocument = event.target?.files?.item(0)
		if (!selectedDocument) return
		const reader = new FileReader()
		reader.onloadend = () => {
			setImage(reader.result as string)

			if (isCPF) {
				const doc = {
					...personalFiscal,
					[id]: reader.result
				} as ISendDocumentsPF
				setPersonalFiscal(doc)
			} else {
				const doc = {
					...companyFiscal,
					[id]: reader.result
				} as ISendDocumentsPJ
				setCompanyFiscal(doc)
			}

			// handleDocuments(isCPF, reader.result as string, id)
		}
		reader.readAsDataURL(selectedDocument)

		event.target.value = ''
	}

	function deleteSpecificImage(id: string) {
		if (isCPF) {
			const doc = { ...personalFiscal, [id]: '' } as ISendDocumentsPF
			setPersonalFiscal(doc)
		} else {
			const doc = { ...companyFiscal, [id]: '' } as ISendDocumentsPJ
			setCompanyFiscal(doc)
		}
		handleDocuments(isCPF, '', id)
	}

	function addDocumentPersonal(id: string, image: string) {
		if (isCPF) {
			const doc = {
				...personalFiscal,
				[id]: image
			} as ISendDocumentsPF
			setPersonalFiscal(doc)
		}
	}

	function renderUpload(
		text: string,
		id: string,
		icon?: string,
		onClick?: () => void,
		col?: string
	) {
		return (
			<UploadWrapper
				className={col ? col : ''}
				htmlFor={onClick ? undefined : id}
				onClick={onClick}>
				<img src={icon ? icon : Upload} alt={text} />
				<span>{text}</span>
				{onClick ? null : (
					<input
						type={'file'}
						id={id}
						accept=".jpg, .jpge, .png, .pdf"
						onChange={(e) => handleUploadDocument(e, id)}
					/>
				)}
			</UploadWrapper>
		)
	}

	function renderPhotoPersonal(
		text: string,
		id: string,
		onClick: () => void,
		jwtEncoded?: string,
		icon?: string
	) {
		const decoded = jwtEncoded
			? decodeToken<IJwtJUnicoImage>(jwtEncoded)
			: null

		return !decoded ? (
			<UploadWrapper className="col-12" onClick={onClick}>
				<img src={icon ? icon : Upload} alt={text} />
				<span>{text}</span>
			</UploadWrapper>
		) : (
			<ImagePreview
				image={returnBase64AsImage(decoded.image)}
				deleteImage={() => deleteSpecificImage(id)}
				id={id}
			/>
		)
	}

	function renderUploadDocumentPF() {
		return (
			<>
				<SelectDocumentType className="col-lg-6 col-md-12">
					<p>Escolha tipo de documento: </p>
					<SelectPrimary
						isLoading={false}
						isClearable={true}
						placeholder="Tipo do documento"
						options={options || []}
						value={selectedDocumentType}
						onChange={handleSelectDocument}
						classNamePrefix="react-select"
						className="react-select"
						disabled={
							personalFiscal?.DocumentPersonalFront ||
							personalFiscal?.DocumentPersonalBack
								? true
								: false
						}
					/>
				</SelectDocumentType>

				<UploadUserPhoto>
					<div className="col-lg-6 col-md-12">
						<p>Sua foto</p>
						{renderPhotoPersonal(
							'Abrir Webcam',
							'DocumentPhotoFront',
							() =>
								prepareSelfieCamera(
									SelfieCameraTypes.NORMAL,
									'DocumentPhotoFront'
								),
							personalFiscal?.DocumentPhotoFront || '',
							Webcam
						)}
					</div>

					{selectedDocumentType === 'CNH' ? (
						<div className="col-lg-6 col-md-12">
							<p>Documento Frente e Verso</p>
							{renderPhotoPersonal(
								'Abrir Webcam',
								'DocumentPersonalFront',
								() =>
									prepareDocumentCamera(
										DocumentCameraTypes.CNH,
										'DocumentPersonalFront'
									),
								personalFiscal?.DocumentPersonalFront || '',
								Webcam
							)}
						</div>
					) : null}
				</UploadUserPhoto>
				{selectedDocumentType === DocumentType.RG ? (
					<UploadUserPhoto>
						<div className="col-lg-6 col-md-12">
							<p>Documento - Frente</p>
							{renderPhotoPersonal(
								'Abrir Webcam',
								'DocumentPersonalFront',
								() =>
									prepareDocumentCamera(
										DocumentCameraTypes.RG_FRENTE,
										'DocumentPersonalFront'
									),
								personalFiscal?.DocumentPersonalFront || '',
								Webcam
							)}
						</div>

						<div className="col-lg-6 col-md-12">
							<p>Documento - Verso</p>
							{renderPhotoPersonal(
								'Abrir Webcam',
								'DocumentPersonalBack',
								() =>
									prepareDocumentCamera(
										DocumentCameraTypes.RG_VERSO,
										'DocumentPersonalBack'
									),
								personalFiscal?.DocumentPersonalBack || '',
								Webcam
							)}
						</div>
					</UploadUserPhoto>
				) : null}
			</>
		)
	}

	function renderStepFour() {
		return (
			<StepFourWrapper className="col-lg-6 col-md-12">
				<AccountActivationSessionSecondary
					handleChangeConfirmationMethod={
						handleChangeConfirmationMethod
					}
					labelEmail={'Receber Código de Ativação por '}
					labelSms={'Receber Código de Ativação por '}
					selectedConfirmationMethod={selectedConfirmationMethod}
					labelEmailRed={'E-mail'}
					labelSmsRed={'SMS'}
				/>
				{isOptimazation ? (
					<div className="password col-lg-8">
						<p className="create-password">
							Agora, cadastre uma senha de acesso à sua conta
						</p>
						<div>
							<InputPassword
								returnValue={setPassword}
								valueIsValid={() => console.log}
								login
								canShowPassword
								showPasswordTextColor={colors.red}
								className="input-tertiary"
							/>
							<span className="helper-password">
								SUA SENHA DEVE CONTER NO MÍNIMO
								<span className="red"> 8 CARACTERES </span>E AO
								MENOS
								<span className="red">
									{' '}
									UM CARACTERE MINÚSCULO, MAIÚSCULO E
									NUMÉRICO.
								</span>
							</span>
						</div>
					</div>
				) : null}

				<div className="checkbox-content margin-top">
					<CheckBox
						type="checkbox"
						name="terms-adhesion"
						id="terms-adhesion"
						checked={termsAdhesionCheckbox}
						fontSize={18}
						secondaryColor="red"
						primaryColor="white"
						backgroundColor="#f4f4f4"
						onChange={handleTermsAdhesionCheckbox}
					/>
					<p className="checkbox-label">
						ACEITO OS{' '}
						<button
							className="knowMore"
							onClick={() => setModalAdhesion(true)}>
							TERMOS DE ADESÃO{' '}
						</button>{' '}
						DO PROGRAMA BOM PARCEIRO DO GRUPO PETRÓPOLIS.
					</p>
				</div>
				<div className="checkbox-content">
					<CheckBox
						type="checkbox"
						name="lgpd"
						id="lgpd"
						checked={checkBoxLGPD}
						fontSize={18}
						secondaryColor="red"
						primaryColor="white"
						backgroundColor="#f4f4f4"
						onChange={handleCheckBoxLGPD}
					/>
					<label htmlFor="lgpd" className="checkbox-label">
						CONFIRMO QUE TOMEI CIÊNCIA E AUTORIZO A COLETA,
						TRATAMENTO E O ARMAZENAMENTO DOS MEUS DADOS PESSOAIS.{' '}
						<button
							className="knowMore"
							onClick={() => setActive(true)}>
							saber mais
						</button>
					</label>
				</div>
				<div className="checkbox-content">
					<CheckBox
						type="checkbox"
						name="sms"
						checked={checkBoxSMS}
						fontSize={18}
						secondaryColor="red"
						backgroundColor="#f4f4f4"
						primaryColor="white"
						id="sms"
						onClick={handleCheckBoxSMS}
					/>
					<label htmlFor="sms" className="checkbox-label">
						AUTORIZO A COLETA, TRATAMENTO E ARMAZENAMENTO DOS MEUS
						DADOS PESSOAIS, HISTÓRICO DE COMPRAS E DE CONTATO PARA
						ENVIO DE INFORMAÇÕES SOBRE OS PRODUTOS COMERCIALIZADOS
						PELO GRUPO PETRÓPOLIS.{' '}
						<button
							className="knowMore"
							onClick={() => setActive2(true)}>
							saber mais
						</button>
					</label>
				</div>
			</StepFourWrapper>
		)
	}

	function notSendImageDuplicate(id: string) {
		if (
			isCPF &&
			sendDocumentsPF &&
			(sendDocumentsPF as any)[id] !== '' &&
			id in sendDocumentsPF
		) {
			goToNextStep()
			return true
		}
		if (
			!isCPF &&
			sendDocumentsPJ &&
			(sendDocumentsPJ as any)[id] !== '' &&
			id in sendDocumentsPJ
		) {
			goToNextStep()
			return true
		}

		return false
	}
	function OCRValidation(imageBase64: string, id: string) {
		if (notSendImageDuplicate(id)) {
			return
		}

		;(async () => {
			try {
				setLoading(true)
				await OCRCustomerValidation({
					customerName: name.toUpperCase(),
					customerZipCode: postalCode.replace('-', ''),
					urlCustomerDocument: imageBase64.replace(
						/^data:image\/[a-z]+;base64,/,
						''
					)
				})
				sendImageToAws(id)
			} catch (err) {
				console.error(err)
			} finally {
				setLoading(false)
			}
		})()
	}

	function sendImageToAws(id: string) {
		if (notSendImageDuplicate(id)) {
			return
		}
		;(async () => {
			try {
				if (!image) return
				setLoading(true)

				const upload = await sendImagesToS3(
					image,
					`${renameImageToAws(id)}_${
						isCPF ? 'PF' : 'PJ'
					}-${Date.now()}`
				)

				if (!upload?.url) return

				handleDocuments(isCPF, upload.url, id)

				goToNextStep()
			} catch (err) {
				console.error(err)
			} finally {
				setLoading(false)
			}
		})()
	}

	function translateImg(img: string) {
		switch (img) {
			case 'DocumentPhotoFront':
				return 'Sua foto'
			case 'DocumentPersonalBack':
				return 'Documento - Verso'

			case 'DocumentPersonalFront':
				return 'Documento - Frente'

			default:
				return img
		}
	}

	async function transformBase64ToFile(jwtEncoded: string, id: string) {
		try {
			const decoded = decodeToken<IJwtJUnicoImage>(jwtEncoded)
			if (!decoded) return

			const upload = await sendImagesToS3(
				decoded.image,
				`${renameImageToAws(id)}_${isCPF ? 'PF' : 'PJ'}-${Date.now()}`
			)

			if (!upload?.url) return // handleDocuments(isCPF, upload.url, id)
			;(sendDocumentsPF as any)[id] = upload.url
		} catch (error) {
			throw new Error(
				`Não foi possivel salvar a imagem: ${translateImg(id)}`
			)
		}
	}

	async function sendImages() {
		const documentsHasSendToAws =
			selectedDocumentType === DocumentType.RG
				? sendDocumentsPF?.DocumentPhotoFront &&
				  sendDocumentsPF?.DocumentPersonalBack &&
				  sendDocumentsPF?.DocumentPersonalFront
				: sendDocumentsPF?.DocumentPhotoFront &&
				  sendDocumentsPF?.DocumentPersonalFront

		if (documentsHasSendToAws) {
			goToNextStep()
			return
		}

		const hasPendingDocument =
			selectedDocumentType === DocumentType.RG
				? !personalFiscal?.DocumentPhotoFront ||
				  !personalFiscal?.DocumentPersonalBack ||
				  !personalFiscal?.DocumentPersonalFront
				: !personalFiscal?.DocumentPhotoFront ||
				  !personalFiscal?.DocumentPersonalFront

		if (hasPendingDocument || !personalFiscal) {
			return
		}

		try {
			setLoading(true)
			await processImages({
				documentNumber,
				name,
				selectedDocumentType:
					selectedDocumentType === DocumentType.RG
						? DocumentType.RG
						: DocumentType.CNH,
				imageBase64Front: personalFiscal.DocumentPersonalFront,
				imageBase64Selfie: personalFiscal.DocumentPhotoFront,
				imageBase64Back: personalFiscal.DocumentPersonalBack
			})
		} catch (error) {
			console.error({ error })
			cleanDocumentImages()
		} finally {
			setLoading(false)
		}
	}

	function cleanDocumentImages() {
		setPersonalFiscal({
			...(personalFiscal as ISendDocumentsPF),
			DocumentPersonalBack: '',
			DocumentPersonalFront: '',
			DocumentPhotoFront: ''
		})
	}

	const listenProccess = () => {
		;(async () => {
			try {
				if (
					unicoProcess?.Status !== 3 ||
					!personalFiscal ||
					stepCurrent !== 2
				)
					return

				await transformBase64ToFile(
					personalFiscal.DocumentPhotoFront,
					'DocumentPhotoFront'
				)
				await transformBase64ToFile(
					personalFiscal.DocumentPersonalBack,
					'DocumentPersonalBack'
				)
				await transformBase64ToFile(
					personalFiscal.DocumentPersonalFront,
					'DocumentPersonalFront'
				)

				goToNextStep()
			} catch (error) {
				cleanDocumentImages()
			} finally {
				setLoading(false)
			}
		})()
	}

	const disabledOtimization =
		!checkBoxLGPD ||
		!selectedConfirmationMethod ||
		!termsAdhesionCheckbox ||
		!checkPasswordIsValid(password)
			? true
			: false

	const disabledNotOtimization =
		!checkBoxLGPD || !selectedConfirmationMethod || !termsAdhesionCheckbox

	const stepsPF: ISendDocument[] = [
		{
			stepCurrent: 1,
			id: 'AddressPhoto',
			title: 'Voltar ao Endereço',
			subtitle: 'Agora, envie uma imagem de seu',
			subtitleRed: 'Comprovante de Endereço.',
			stepTitle: 'Comprovante de Endereço',
			buttonPrimary: 'ENVIAR DOCUMENTO',
			buttonSecondary: undefined,
			buttonSubmitTitle: 'ENVIAR DOCUMENTO',
			image: image || personalFiscal?.AddressPhoto || null,
			handleButtonPrimary: undefined,
			handleButtonSecondary: undefined,
			deleteImage: () => handleDeleteDocument('AddressPhoto'),
			goBack: goToPreviousStep,
			handleSubmit: () =>
				OCRValidation(
					personalFiscal?.AddressPhoto || image || '',
					'AddressPhoto'
				),
			loading: loading,
			disabledButtonPrimary: !personalFiscal?.AddressPhoto,
			children: !personalFiscal?.AddressPhoto
				? renderUpload(
						'CLIQUE PARA SELECIONAR O ARQUIVO',
						'AddressPhoto',
						undefined,
						undefined,
						'col-lg-6 col-md-12'
				  )
				: undefined
		},

		{
			stepCurrent: 2,
			id: 'DocumentPhotoFront',
			title: 'Voltar ao Endereço',
			stepTitle: 'Fotografia do Documento',
			subtitle: 'Precisamos da fotografia do seu ',
			subtitleRed: 'Documento de Identidade ',
			// subtitleSecondary: 'e também uma foto sua segurando seu',
			// subtitleRedSecondary: 'Documento ao lado de seu rosto.',
			subtitleSecondary: 'e também uma foto sua.',
			disabledButtonPrimary:
				selectedDocumentType === DocumentType.RG
					? !personalFiscal?.DocumentPhotoFront ||
					  !personalFiscal?.DocumentPersonalBack ||
					  !personalFiscal?.DocumentPersonalFront
					: !personalFiscal?.DocumentPhotoFront ||
					  !personalFiscal?.DocumentPersonalFront,
			buttonPrimary: 'ENVIAR DOCUMENTOS',
			buttonSecondary: undefined,
			loading: loading || isLoading || loadingCamera,
			buttonSubmitTitle: 'ENVIAR DOCUMENTOS',
			image: null,
			handleButtonPrimary: sendImages,
			goBack: goToPreviousStep,
			handleSubmit: sendImages,
			children: renderUploadDocumentPF()
		},
		{
			stepCurrent: 3,
			id: 'EstablishmentPhoto',
			title: 'Voltar aos Documentos',
			stepTitle: 'Fotografia da Fachada',
			subtitle: 'Agora, envie uma fotografia da',
			subtitleRed: 'Fachada de seu Estabelecimento.',
			buttonPrimary: 'ENVIAR FOTOGRAFIA',
			buttonSecondary: 'PULAR FOTOGRAFIA',
			buttonSubmitTitle: 'ENVIAR FOTOGRAFIA',
			image: image || personalFiscal?.EstablishmentPhoto || null,
			loading: loading,
			handleButtonSecondary: () => skipPhoto('EstablishmentPhoto'),
			deleteImage: () => handleDeleteDocument('EstablishmentPhoto'),
			goBack: goToPreviousStep,
			handleSubmit: () => sendImageToAws('EstablishmentPhoto'),
			disabledButtonPrimary: !personalFiscal?.EstablishmentPhoto,
			children: !personalFiscal?.EstablishmentPhoto
				? renderUpload(
						'CLIQUE PARA SELECIONAR O ARQUIVO',
						'EstablishmentPhoto',
						undefined,
						undefined,
						'col-lg-6 col-md-12'
				  )
				: undefined
		},
		{
			stepCurrent: 4,
			id: 'account-active',
			title: isOptimazation
				? 'Voltar a Foto da Fachada'
				: 'Voltar ao Endereço',
			stepTitle: 'Ativação da Conta',
			subtitle: 'Como deseja ativar sua conta?',
			subtitleRed: undefined,
			buttonPrimary: 'FINALIZAR CADASTRO',
			buttonSecondary: undefined,
			buttonSubmitTitle: undefined,
			disabledButtonPrimary: isOptimazation
				? disabledOtimization
				: disabledNotOtimization,
			image: null,
			handleButtonSecondary: undefined,
			handleButtonPrimary: handleCreateClient,
			deleteImage: undefined,
			goBack: goToPreviousStep,
			handleSubmit: undefined,
			children: renderStepFour(),
			hiddenStepWizard: isOptimazation ? undefined : true
		}
	]

	const stepsCNPJ: ISendDocument[] = [
		{
			stepCurrent: 1,
			id: 'DocumentPhoto',
			title: 'Voltar ao Endereço',
			subtitle: 'Agora, precisamos que você envie uma foto do',
			subtitleRed: 'Documento de Identificação do Fiel Depositário.',
			stepTitle: 'Comprovante de Endereço',
			buttonPrimary: 'ENVIAR DOCUMENTO',
			buttonSubmitTitle: 'ENVIAR DOCUMENTO',
			image: image || companyFiscal?.DocumentPhoto || null,
			disabledButtonPrimary: !companyFiscal?.DocumentPhoto,
			loading: loading,
			deleteImage: () => handleDeleteDocument('DocumentPhoto'),
			goBack: goToPreviousStep,
			handleSubmit: () => sendImageToAws('DocumentPhoto'),
			children: !companyFiscal?.DocumentPhoto
				? renderUpload(
						'CLIQUE PARA SELECIONAR O ARQUIVO',
						'DocumentPhoto',
						undefined,
						undefined,
						'col-lg-6 col-md-12'
				  )
				: undefined
		},
		{
			stepCurrent: 2,
			id: 'MirrorCnpj',
			title: 'Voltar ao Documento',
			stepTitle: 'Espelho do CNPJ',
			subtitle: 'Agora, envie uma imagem de seu ',
			subtitleRed: 'Espelho do CNPJ.',
			disabledButtonPrimary: !companyFiscal?.MirrorCnpj,
			buttonPrimary: 'ENVIAR DOCUMENTOS',
			buttonSubmitTitle: 'ENVIAR FOTOGRAFIA',
			image: image || companyFiscal?.MirrorCnpj || null,
			handleButtonPrimary: goToNextStep,
			deleteImage: () => handleDeleteDocument('MirrorCnpj'),
			goBack: goToPreviousStep,
			handleSubmit: () => sendImageToAws('MirrorCnpj'),
			children: !companyFiscal?.MirrorCnpj
				? renderUpload(
						'CLIQUE PARA SELECIONAR O ARQUIVO',
						'MirrorCnpj',
						undefined,
						undefined,
						'col-lg-6 col-md-12'
				  )
				: undefined,
			loading: loading
		},
		{
			stepCurrent: 3,
			id: 'EstablishmentPhoto',
			title: 'Voltar ao Espelho do CNPJ',
			stepTitle: 'Fotografia da Fachada',
			subtitle: 'Agora, envie uma fotografia da',
			subtitleRed: 'Fachada de seu Estabelecimento.',
			buttonPrimary: 'ENVIAR FOTOGRAFIA',
			buttonSecondary: 'PULAR FOTOGRAFIA',
			buttonSubmitTitle: 'ENVIAR FOTOGRAFIA',
			image: image || companyFiscal?.EstablishmentPhoto || null,
			handleButtonSecondary: () => skipPhoto('EstablishmentPhoto'),
			deleteImage: () => handleDeleteDocument('EstablishmentPhoto'),
			goBack: goToPreviousStep,
			handleSubmit: () => sendImageToAws('EstablishmentPhoto'),
			disabledButtonPrimary: !companyFiscal?.EstablishmentPhoto,
			children: !companyFiscal?.EstablishmentPhoto
				? renderUpload(
						'CLIQUE PARA SELECIONAR O ARQUIVO',
						'EstablishmentPhoto',
						undefined,
						undefined,
						'col-lg-6 col-md-12'
				  )
				: undefined,
			loading: loading
		},
		{
			stepCurrent: 4,
			id: 'account-active',
			title: isOptimazation
				? 'Voltar a Foto da Fachada'
				: 'Voltar ao Endereço',
			stepTitle: 'Ativação da Conta',
			subtitle: 'Como deseja ativar sua conta?',
			subtitleRed: undefined,
			buttonPrimary: 'FINALIZAR CADASTRO',
			buttonSecondary: undefined,
			buttonSubmitTitle: undefined,
			disabledButtonPrimary: isOptimazation
				? disabledOtimization
				: disabledNotOtimization,
			image: null,
			handleButtonSecondary: undefined,
			handleButtonPrimary: handleCreateClient,
			deleteImage: undefined,
			goBack: goToPreviousStep,
			handleSubmit: undefined,
			children: renderStepFour(),
			hiddenStepWizard: isOptimazation ? undefined : true
		}
	]

	const userType = isCPF ? stepsPF : stepsCNPJ

	const step = userType.find((x) => x.stepCurrent === stepCurrent)

	function returnAddress(addressMap: string) {
		setAddressMapped(addressMap)
	}

	function returnLocation({ lat, lng }: ILatLng) {
		setRegion({ lat, lng })
	}

	function returnLoading(isLoading: boolean) {
		setLoading(isLoading)
	}

	function renderMap() {
		return (
			<div className="map-wrapper">
				<GoBackButton className="back-arrow-wrapper" onClick={skipStep}>
					<img
						className="back-arrow"
						src={ArrowLeftIcon}
						alt="Voltar"
					/>
					<p>Voltar às Informações</p>
				</GoBackButton>

				<Title color={colors.secondGrey}>Confirme seu Endereço</Title>

				<div>
					<FloatLabelLocaleInput
						id="address"
						labelColor="red"
						label="ENDEREÇO"
						value={addressMapped}
						loading={loading}
						onChange={() => console.log}
					/>
				</div>
				<Maps
					address={address}
					returnAddress={returnAddress}
					returnLocation={returnLocation}
					returnLoading={returnLoading}
				/>
				<div style={{ textAlign: 'center' }}>
					<ButtonPrimary
						{...primaryButtonStyle}
						onClick={getInfoRegistrationOptimization}
						style={{ textAlign: 'center' }}>
						CONFIRMAR ENDEREÇO
					</ButtonPrimary>
				</div>
			</div>
		)
	}

	useEffect(() => {
		if (!stateCamera || !id) return

		addDocumentPersonal(id, stateCamera)
		cleanStateCamera()
	}, [stateCamera, errorCamera, id])

	useEffect(listenProccess, [unicoProcess])
	useEffect(() => {
		handlePassword(password)
	}, [password])

	return (
		<>
			{stepCurrent === 0 ? (
				renderMap()
			) : step ? (
				<SendDocument {...step} />
			) : null}

			{showBoxCamera ? (
				<div className="camera-wrapper">
					<div id="box-camera"></div>
				</div>
			) : null}

			{modalTerms()}
		</>
	)
}
