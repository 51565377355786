import cogoToast from 'cogo-toast'
import axiosInstance from '../../shared/services/axiosInstace'

import cogoDefaultOptions from '../../shared/utils/toaster'
import { getEnumMessage } from '../Error/handleError'

export async function getAllFaqs() {
	try {
		const response = await axiosInstance.get(`/program-points/faq`)
		return response.data
	} catch (err) {
		const error = err as any
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}
