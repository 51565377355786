import { TypeContact } from '../code-validation/code-validation.types'
import { EnumTypeFlow, ValidationProps } from '../../reset-password.types'

export interface EmailPhoneRegistrationrops {
	goStep: (step: number) => void
	typeContact: TypeContact
	setContactData: (object: ValidationProps) => void
	customerDocument: string
	newEmail: string
	setNewEmail: (newEmail: string) => void
	newPhone: string
	setNewPhone: (newPhone: string) => void
	activationType?: string
	setActivationType: (param: creationFormTypeEnum) => void
	isPasswordValidated: boolean
	choiceInitialContact: string
	setIsLoading:(param:boolean)=>void
	setTypeFlow: (parameter: EnumTypeFlow) => void
	typeFlow: EnumTypeFlow
}

export enum creationFormTypeEnum {
	email = 'email',
	cell = 'cell'
}
