import styled from 'styled-components'

export const PageWrapper = styled.main`
	.page-header-wrapper.sac {
		position: static;
	}

	.modal-wrapper {
		max-height: 100%;
		overflow-y: auto;
	}
`

export const PointsEarnedRatioWrapper = styled.section``

export const PointsEarnedRatioTitle = styled.h1`
	color: ${(props) => props.theme.palette.gray.main};
	margin-top: 64px;
	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-style: normal;
`

export const InputAndButtonWrapper = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
`

export const SearchInputWrapper = styled.div`
	margin-top: 32px;
`

export const ButtonWrapper = styled.div``

export const LoadingWrapper = styled.div``

export const FiltersContainer = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`

export const Observation = styled.p`
	overflow: hidden;
	max-width: 64px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-height: 16px;
	-webkit-box-orient: vertical;
`
