import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const Container = styled.div`
	width: 100%;
	padding: 0 10vw 48px;
	min-height: 100vh;
	background-color: ${colors.fifthGray};

	.loading-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (min-width: 1200px) {
		padding: 0 10vw 48px;
	}
`

export const FiltersGroup = styled.div`
	.input {
		margin-right: 20px;
	}
	.select {
		& > * {
			min-width: 260px;
		}
	}
`

export const ChallengesCarouselSession = styled.div`
	margin-top: 48px;

	.title {
		padding-bottom: 8px;
		border-bottom: 1px solid ${colors.lightGrey};
		margin-bottom: 24px;

		h2 {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			text-align: left;
			letter-spacing: 0.22px;
			color: ${colors.secondGrey};
		}
	}
`
