import React from 'react'

import ControlSelect from '../../../../../components/multiple-select/multiple-select'
import ControlInput from '../../../../../components/control-input/control-input.component'
import ButtonDefault from '../../../../../components/buttons/button/button-default'
import { IconButton } from '@mui/material'

import useRulesCoupon from './use-rules-coupon'

import { currencyMask } from '../../../../../utils/currency-mask'

import { RulesCouponProps } from './rules-coupon.types'

import { SessionTitle } from '../../create-coupon.styles'
import * as S from './rules-coupon.styles'

function RulesCoupon({
	errors,
	control,
	register,
	watch,
	isProductCoupon,
	reset,
	setHasFiltered,
	isEditCouponFormMode
}: RulesCouponProps) {
	const {
		isLoadingCategories,
		cumulativeOptions,
		typeOptions,
		categoriesOptions,
		buttonFilterType,
		isBrandDisabled,
		isPackageDisabled,
		startAddorment,
		brandOptions,
		isLoadingBrands,
		packageOptions,
		isLoadingPackage
	} = useRulesCoupon({
		watch,
		isEditCouponFormMode
	})

	return (
		<S.RulesCouponWrapper>
			<SessionTitle>Regras</SessionTitle>
			<S.WrapperInputs>
				<S.Row>
					<ControlInput
						{...register('minValueAmount', {
							onChange: (event) => {
								const { value } = event.target
								event.target.value = currencyMask(value)
							}
						})}
						label="Valor mínimo de compra"
						errorMessage={errors.minValueAmount?.message}
						startAdornment={startAddorment}
						id={'min-value-amount'}
					/>

					<ControlSelect
						name="cumulative"
						control={control}
						label="Cumulativo"
						options={cumulativeOptions}
						id={'cumulative'}
					/>

					<ControlSelect
						name="type"
						control={control}
						label="Tipo"
						options={typeOptions}
						disabled={isEditCouponFormMode}
						id={'field-type'}
					/>
				</S.Row>
				{isProductCoupon && (
					<>
						<h2>Seleção de produtos</h2>
						<S.SecondRow>
							<ControlSelect
								multiple
								name="category"
								control={control}
								label="Categoria"
								options={categoriesOptions}
								isLoading={isLoadingCategories}
								disabled={
									isLoadingCategories || isEditCouponFormMode
								}
								onAfterChange={(value) => {
									if (!Array.isArray(value)) return
									if (
										value.length === 0 ||
										value.includes('all')
									) {
										reset((prev) => ({
											...prev,
											brand: [],
											package: []
										}))
									}
								}}
							/>

							<ControlSelect
								multiple
								name="brand"
								control={control}
								label="Marca"
								options={brandOptions}
								disabled={
									isBrandDisabled || isEditCouponFormMode
								}
								isLoading={isLoadingBrands}
							/>

							<ControlSelect
								multiple
								name="package"
								control={control}
								label="Embalagem"
								options={packageOptions}
								isLoading={isLoadingPackage}
								disabled={
									isPackageDisabled || isEditCouponFormMode
								}
							/>
							<IconButton
								disableRipple
								disabled={isEditCouponFormMode}
								onClick={() =>
									reset((prev) => ({
										...prev,
										category: [],
										brand: [],
										package: []
									}))
								}>
								<S.StyledDeleteOutlineIcon />
							</IconButton>
							<div>
								<ButtonDefault
									type={buttonFilterType}
									buttonText="FILTRAR"
									idElement="filter-products"
									onClick={() => setHasFiltered(true)}
									fontSize="10px"
									typeButton="button"
								/>
							</div>
						</S.SecondRow>
					</>
				)}
			</S.WrapperInputs>
		</S.RulesCouponWrapper>
	)
}

export default RulesCoupon
