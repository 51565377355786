import { AxiosResponse } from 'axios'
import axiosInstance from '../../shared/services/axiosInstace'
import { getEnumMessage } from '../Error/handleError'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'

export async function editCustomerEmail(customerID: string, newEmail: string) {

	try {
		const response: AxiosResponse = await axiosInstance.put(
			'/customers/editemail',
			{ customerUpdateEmailData: { customerID, newEmail } }
		)
		return response

	} catch (error) {
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}
