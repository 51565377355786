import { createElement, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cogoToast from 'cogo-toast'
import { useDispatch } from 'react-redux'

import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'
import { ConfirmationMethods } from '../../components/AccountActivationSession/types'
import { cogoSuccessMessages } from '../../shared/utils/Messages'
import cogoDefaultOptions from '../../shared/utils/toaster'

import { IProps, IViewProps } from './types'
import { confirmSignUp, resendSignUp } from './service'
import ConfirmActivationCodeView from './view'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { signIn } from '../Auth/service'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { Creators as CustomerActions } from '../../shared/store/ducks/customer'
import { Creators as TokenActions } from '../../shared/store/ducks/token'

const INITIAL_COUNTER_VALUE = 60

function ConfirmActivationCode() {
	const { state } = useLocation<IProps>()
	const documentNumber = state.documentNumber
	const confirmationMethod = state.confirmationMethod as ConfirmationMethods

	const { customer } = useTypedSelector(['customer'])
	const history = useHistory()
	const dispatch = useDispatch()

	const [confirmationCode, setConfirmationCode] = useState('')
	const [timer, setTimer] = useState(INITIAL_COUNTER_VALUE)
	const [canResendCode, setCanResendCode] = useState(false)
	const [enabled, setEnabled] = useState(true)

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}

	const handleSubmit = useCallback(() => {
		;(async () => {
			try {
				const response = await confirmSignUp({
					username: documentNumber,
					code: confirmationCode
				})

				if (customer?.Password && customer?.Password?.length > 0) {
					const loginCognito = await signIn({
						documentNumber: documentNumber,
						password: customer.Password
					})

					if (!loginCognito) return

					if (
						!!loginCognito.hasError &&
						loginCognito.hasError.code ===
							'UserNotConfirmedException'
					) {
						history.push(
							UnauthenticatedRoutesEnum.ACTIVATE_REGISTRATION,
							{
								documentNumber
							}
						)
						return
					}

					const accessToken =
						loginCognito.signInUserSession.idToken.jwtToken
					dispatch(TokenActions.saveAccess(accessToken))
					dispatch(
						CustomerActions.updateCustomer({
							...customer,
							Password: ''
						})
					)

					history.replace(CustomerRoutesEnum.WELCOME)

					return
				}

				if (!response) return

				cogoToast.success(
					cogoSuccessMessages.confirmAccountSuccess,
					cogoDefaultOptions
				)

				history.replace(UnauthenticatedRoutesEnum.LOGIN)
			} catch {}
		})()
	}, [documentNumber, confirmationCode, history])

	const handleResendCode = useCallback(() => {
		;(async () => {
			const response = await resendSignUp({
				username: documentNumber,
				clientMetadata:
					formatConfirmationCodeMethodParams(confirmationMethod)
			})

			if (!response) return

			setTimer(INITIAL_COUNTER_VALUE)
			setCanResendCode(false)
		})()
	}, [documentNumber, confirmationMethod])

	function resendCodeTimer() {
		if (confirmationMethod === 'sendemail') return

		setInterval(() => {
			setTimer((state) => state - 1)
		}, 1000)
	}

	function checkTimer() {
		timer <= 0 && setCanResendCode(true)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(resendCodeTimer, [])
	useEffect(checkTimer, [timer])

	const viewProps: IViewProps = {
		timer,
		enabled,
		setEnabled,
		canResendCode,
		confirmationMethod,
		setConfirmationCode,
		handleBack,
		handleSubmit,
		handleResendCode
	}

	return createElement(ConfirmActivationCodeView, viewProps)
}

export default ConfirmActivationCode
