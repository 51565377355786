import React from 'react'
import { Modal, CloseButton } from '../../../shared/components'
import { IModalDeleteUserProps } from './types'
import styles from './styles.module.scss'

export default function ModalConfirmExclusion(props: IModalDeleteUserProps) {
	const {
		isActive,
		isDeleting,
		onCancel,
		onConfirm,
		excludedCustomer
	} = props

	return (
		<Modal isActive={isActive}>
			<div className={styles['moda-user']}>
				<div className={styles['modal-header']}>
					<h1 className="modal-title">Excluir Cliente</h1>
					<CloseButton onClick={onCancel} />
				</div>

				<div className={styles['message']}>
					<>
						<p>Você selecionou para Excluir este Cliente.</p>
						<p>Deseja confirmar esta exclusão?</p>
					</>
				</div>

				<div className={styles['button-wrapper']}>
					<button
						className="white-button"
						style={{ height: '43px' }}
						disabled={isDeleting}
						onClick={function() {
							onConfirm();							
							onCancel()
							}
						}
					>
						CONFIRMAR
					</button>
				</div>

				<div className={styles['button-wrapper']}>
					<button
						className={styles['cancel-button']}
						disabled={isDeleting}
						onClick={onCancel}>
						<span>{excludedCustomer ? 'FECHAR' : 'MANTER'}</span>
					</button>
				</div>
			</div>
		</Modal>
	)
}
