import { AxiosResponse } from 'axios'
import { IResponsePixAllPay } from '../../shared/interfaces/Payments'

import {
	ISaleOrder,
	ISaleOrderRes,
	ISendSaleOrderPaymentCreditCard,
	ISendSaleOrderPaymentPix
} from '../../shared/interfaces/saleOrder'
import axiosInstance from '../../shared/services/axiosInstace'

export async function sendOrder(
	order: ISaleOrder
): Promise<ISaleOrderRes | null> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			'/salesorders?isPortalClient=true',
			order
		)

		return response?.data
	} catch (error) {
		throw error
	}
}

export async function getQRCode(
	data: ISendSaleOrderPaymentPix
): Promise<IResponsePixAllPay> {
	const response: AxiosResponse = await axiosInstance.post(
		`/salesorders/sendPixToAllpays?isPortalClient=true`,
		data
	)

	return response.data
}

export async function sendOrderCreditCardRequest(
	data: ISendSaleOrderPaymentCreditCard
): Promise<IResponsePixAllPay> {
	const response: AxiosResponse = await axiosInstance.post(
		`/salesorders/sendCreditCardToAllpays?isPortalClient=true`,
		data
	)

	return response.data
}

export async function cancelTransactionId(transactionId: string): Promise<any> {
	const response: AxiosResponse = await axiosInstance.delete(
		`/salesorders/payment/cancel-pix/${transactionId}`
	)

	return response.data
}
