import React from 'react'
import { InputField } from '../../../shared/components'
import { IUser } from '../../SAC/PendingApproval/types'

import styles from './styles.module.scss'

const DEFAULT_PROPS = {
	hasError: false,
	disabled: true
}

interface ICustomerFormProps {
	customer: IUser
	onPressDelete: () => void
}

export default function CustomerForm(props: ICustomerFormProps) {
	const { customer, onPressDelete } = props

	return (
		<div className={styles['form-customer']}>
			<div className={styles.form}>
				<h2 className={styles['form-title']}>Informações do Cliente</h2>

				<InputField
					{...DEFAULT_PROPS}
					label="Nome"
					className={styles['input']}
					id={'name'}
					value={customer?.Name}
				/>

				<InputField
					{...DEFAULT_PROPS}
					label="CNPJ"
					className={styles['input']}
					id={'cnpj'}
					value={customer?.CompanyFiscalID}
				/>

				<div className="row">
					<div className={styles['double-input-col-1']}>
						<InputField
							{...DEFAULT_PROPS}
							label="Unidade"
							className={styles['input']}
							id={'sales-organization'}
							value={customer?.SalesOrganizationDescription}
						/>
					</div>
					<div className={styles['double-input-col-2']}>
						<InputField
							{...DEFAULT_PROPS}
							label="Código"
							className={styles['input']}
							id={'sales-organization-id'}
							value={customer?.SalesOrganizationID}
						/>
					</div>
				</div>

				<InputField
					{...DEFAULT_PROPS}
					label="E-mail"
					className={styles['input']}
					id={'email'}
					value={customer?.Email}
					disabledCopyPaste={true}
				/>

				<div className="row">
					<div className={styles['double-input-col-1']}>
						<InputField
							{...DEFAULT_PROPS}
							label="Telefone Fixo"
							className={styles['input']}
							id={'telefone'}
							value={customer?.PhoneNumber}
						/>
					</div>
					<div className={styles['double-input-col-2']}>
						<InputField
							{...DEFAULT_PROPS}
							label="Celular"
							className={styles['input']}
							id={'celular'}
							value={customer?.CellphoneNumber}
						/>
					</div>
				</div>
			</div>

			<div className={styles.divider} />

			<button className="red-button" onClick={onPressDelete}>
				EXCLUIR CLIENTE
			</button>
		</div>
	)
}
