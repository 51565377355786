import React, { Fragment } from 'react'

import CheckboxActive from '../../../../../assets/images/checkbox-fill-green.svg'
import CheckboxInactive from '../../../../../assets/images/checkbox-blank-green.svg'

import { ITermsProps } from '../types'
import * as S from './styles'

export function Terms({
	terms,
	onClose,
	viewMode,
	isLoading,
	onAcceptTerms,
	acceptedTerms,
	isFetchingTerms,
	headerTitle,
	termsTitle,
	onChangeAcceptTerms,
	termAcceptedLabel
}: ITermsProps) {
	return (
		<S.Container>
			<S.Header>
				<h1>{headerTitle}</h1>
				<S.ButtonClose onClick={onClose}>
					<S.XIcon />
				</S.ButtonClose>
			</S.Header>

			<S.ThermsBox>
				{!isFetchingTerms && (
					<S.ThermsBoxContent>
						<h2>{termsTitle}</h2>
						{terms.map((paragraph, index) => (
							<p key={`${index}-${paragraph}`}>{paragraph}</p>
						))}
					</S.ThermsBoxContent>
				)}
			</S.ThermsBox>
			{viewMode === 'JOIN' ? (
				<Fragment>
					<S.AcceptTermsInput onClick={onChangeAcceptTerms}>
						<img
							src={
								acceptedTerms
									? CheckboxActive
									: CheckboxInactive
							}
							alt=""
						/>
						<label>
							Aceito os termos de adesão do{' '}
							<span>{termAcceptedLabel}</span>
						</label>
					</S.AcceptTermsInput>

					<S.Button
						disabled={!acceptedTerms || isLoading}
						onClick={onAcceptTerms}
						loading={isLoading}>
						Confirmar
					</S.Button>
				</Fragment>
			) : null}
		</S.Container>
	)
}
