import styled from 'styled-components'

export const WrapperSelectedOption = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;

	gap: 16px;

	padding: 42px 0px;

	.wrapper-main-content {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 42px 60px;
	}

	.wrapper-button-confirm {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.text-oder {
		color:${(props) => props.theme.palette.gray.main};
		font-size: 16px;
		font-weight: 400;
	}
`
