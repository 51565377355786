import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import {
	CreateCouponValidationSchema,
	createCoupon
} from '../schemas/create-coupon'
import productsService from '../services/products.service'
import { useQuery } from 'react-query'
import { useState } from 'react'
import categoriesService from '../services/categories.service'
import couponService from '../services/coupon.service'
import { useLocation } from 'react-router-dom'
import { useFeatureFlags } from '../../../../context/feature-flag/feature-flag.context'

interface LocationState {
	state: {
		state: number
	}
}

export default function useCreateCoupon() {
	const [hasFiltered, setHasFiltered] = useState<boolean>(true)
	const { state }: LocationState = useLocation()

	const { featureFlags } = useFeatureFlags()

	const couponID = state?.state

	const {
		control,
		register,
		handleSubmit,
		getValues,
		watch,
		formState: { errors, dirtyFields },
		reset,
		resetField,
		setError,
		clearErrors,
		trigger,
		setValue
	} = useForm<CreateCouponValidationSchema>({
		resolver: zodResolver(createCoupon()),
		defaultValues:
			// mockDefaultValues
			{
				filters: {
					filterType: 'filter-hierarchy',
					listPdvs: [],
					filtersByHieararchy: {
						director: [],
						grc: [],
						cd: [],
						state: [],
						city: [],
						channel: []
					}
				},
				name: '',
				code: '',
				discountValue: '',
				couponInfo: {
					quantityAvailable: '',
					quantityCoupons: ''
				},
				date: {
					dateStart: undefined,
					dateEnd: undefined
				},
				discountType: 'percent',
				showInApp: '',
				minValueAmount: '',
				cumulative: '',
				type: '',
				category: [],
				brand: [],
				package: [],
				productsList: []
			}
	})
	const categories = watch('category')
	const packages = watch('package')
	const brands = watch('brand')
	const type = watch('type')

	const { data: categoriesOptions, isLoading: isLoadingCategories } =
		useQuery({
			enabled: type === 'product',
			queryKey: ['categories'],
			queryFn: async () => await categoriesService.getAll()
		})

	const canFetchCategoriesAndProducts =
		type === 'product' && !!categoriesOptions && hasFiltered
	const isEditCouponFormMode = !!couponID

	const {
		data: productsOptions = {
			data: [],
			size: 0
		},
		isLoading: isLoadingProducts
	} = useQuery({
		enabled: canFetchCategoriesAndProducts && !isEditCouponFormMode,
		onSettled: () => setHasFiltered(false),
		queryKey: ['productCoupon', categories, packages, brands],
		queryFn: async () => {
			const allCategories = (categoriesOptions ?? []).map(
				(item) => item.value
			)
			const isCategoriesFeatureFlag = featureFlags.US17241
			
			const categoriesID =
				categories.length > 0 ? categories : allCategories
			const data = await productsService.getProducts({
				categoriesID,
				packages,
				brands,
				isCategoriesFeatureFlag
			})
			return data
		}
	})

	const {
		data: couponById = {
			ID: 0,
			brand: [],
			category: [],
			code: '',
			couponInfo: {
				quantityAvailable: '',
				quantityCoupons: ''
			},
			cumulative: '',
			date: { dateStart: '', dateEnd: '' },
			discountType: '',
			discountValue: '',
			filters: {
				filtersByHieararchy: {},
				filterType: 'select-pdv',
				listPdvs: []
			},
			minValueAmount: '',
			name: '',
			package: [],
			productsList: [],
			showInApp: '',
			type: '',
			amountOfCustomers: ''
		},
		isLoading: isLoadingCoupon
	} = useQuery({
		enabled: isEditCouponFormMode,
		queryKey: ['couponById', couponID],
		queryFn: async () => {
			const coupon = await couponService.getCouponById({
				couponID
			})
			reset(coupon)
			return coupon
		},
		onError: (error) => {
			console.log('Error', error)
		}
	})

	const reactHookFormProps = {
		control,
		register,
		errors,
		dirtyFields,
		watch,
		resetField,
		reset,
		setError,
		clearErrors,
		trigger,
		getValues,
		setValue
	}

	return {
		reactHookFormProps,
		handleSubmit,
		productsOptions,
		isLoadingProducts: isLoadingCategories || isLoadingProducts,
		setHasFiltered,
		hasFiltered,
		couponById,
		isLoadingCoupon,
		isEditCouponFormMode
	}
}
