import styled from 'styled-components'

type WrapperWidthProps = {
	width: number
}

export const WrapperSuggestedOrderAdmin = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	gap: ${(props) => props.theme.spacingOptions.LG}px;

	h3 {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	}
`

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`

export const BuyTooWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	gap: 24px;
	border-top: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
	padding-top: 32px;
`

export const WrapperButtonCreateSuggestion = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
`
export const WrapperWidth = styled.div<WrapperWidthProps>`
	width: ${(props) => props.width}px;
`
