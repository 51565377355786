import { endOfMonth, startOfToday } from 'date-fns'
import { RangeModifier } from 'react-day-picker'
import {
	IChallenge,
	IChallengeProduct
} from '../../shared/interfaces/pointsProgramChallenge'

interface IChallengeModelProps {
	challenge?: Partial<IChallenge>
	settings?: ISettings
}

interface ISettings {
	isInitialization?: boolean
}

export class ChallengeModel {
	private bannerUrl: string = ''
	private appPopUpImageUrlPhoto: string = ''
	private generatedPoints: string = ''
	private initialDate: string = startOfToday().toString()
	private finalDate: string = endOfMonth(new Date()).toString()
	private showCampaignDeadline: boolean = false
	private challengeTitle: string = ''
	private challengeDescription: string = ''
	private challengeAdoptionTerms: string = ''
	private purchaseRule: string = ''
	private customerMayParticipateMoreThanOnce: boolean = false
	private showChallengeBannerOnHome: boolean = false
	private products: IChallengeProduct[] = []
	private observation: string = ''

	constructor({ challenge, settings }: IChallengeModelProps) {
		this.appPopUpImageUrlPhoto = challenge?.appPopUpImageUrl || ''
		this.bannerUrl = challenge?.urlFoto || ''
		this.generatedPoints = challenge?.score || ''
		this.showCampaignDeadline = challenge?.countDayFinish || false
		this.challengeTitle = challenge?.title || ''
		this.challengeDescription = challenge?.description || ''
		this.challengeAdoptionTerms = challenge?.Term || ''
		this.purchaseRule = challenge?.typeRule || ''
		this.customerMayParticipateMoreThanOnce =
			challenge?.participateMoreOnce || false
		this.showChallengeBannerOnHome = challenge?.bannerhome || false
		this.products = challenge?.products || []
		this.observation = challenge?.observation || ''

		if (!!settings?.isInitialization) {
			this.initialDate = challenge?.startDate
				? new Date(`${challenge.startDate}T12:00:00`).toString()
				: startOfToday().toString()
			this.finalDate = challenge?.endDate
				? new Date(`${challenge.endDate}T12:00:00`).toString()
				: endOfMonth(new Date()).toString()
		} else {
			this.initialDate = challenge?.startDate || startOfToday().toString()
			this.finalDate =
				challenge?.endDate || endOfMonth(new Date()).toString()
		}
	}

	private newInstance(customSettings?: ISettings) {
		return new ChallengeModel({
			challenge: this.challengeData,
			settings: customSettings
		})
	}

	public get challengeData(): Required<
		Omit<
			IChallenge,
			| 'challengeId'
			| 'filtersChallenge'
			| 'status'
			| 'challengeRegistrationDate'
			| 'createdAt'
			| 'autoIncChallengeId'
			| 'participantsQuantity'
			| 'completedAt'
			| 'userIsAlreadyParticipatingInTheChallenge'
		>
	> {
		return {
			appPopUpImageUrl: this.appPopUpImageUrlPhoto,
			urlFoto: this.bannerUrl,
			score: this.generatedPoints,
			startDate: this.initialDate,
			endDate: this.finalDate,
			countDayFinish: this.showCampaignDeadline,
			title: this.challengeTitle,
			description: this.challengeDescription,
			Term: this.challengeAdoptionTerms,
			typeRule: this.purchaseRule,
			participateMoreOnce: this.customerMayParticipateMoreThanOnce,
			bannerhome: this.showChallengeBannerOnHome,
			products: this.products,
			observation: this.observation
		}
	}

	public changeBannerUrl(value: string) {
		this.bannerUrl = value

		return this.newInstance()
	}

	public changeAppPopUpImageUrl(value: string) {
		this.appPopUpImageUrlPhoto = value

		return this.newInstance()
	}

	public changeShowChallengeBannerOnHome() {
		this.showChallengeBannerOnHome = !this.showChallengeBannerOnHome

		return this.newInstance()
	}

	public changeGeneratedPoints(value: string) {
		this.generatedPoints = value

		return this.newInstance()
	}

	public changePeriod({ from, to }: RangeModifier) {
		if (from) {
			this.initialDate = from.toString()
		}
		if (to) {
			this.finalDate = to.toString()
		}

		return this.newInstance()
	}

	public changeShowCampaignDeadline() {
		this.showCampaignDeadline = !this.showCampaignDeadline

		return this.newInstance()
	}

	public changeChallengeTitle(value: string) {
		this.challengeTitle = value

		return this.newInstance()
	}

	public changeChallengeDescription(value: string) {
		this.challengeDescription = value

		return this.newInstance()
	}

	public changeChallengeAdoptionTerms(value: string) {
		this.challengeAdoptionTerms = value

		return this.newInstance()
	}

	public changePurchaseRule(value: string) {
		this.purchaseRule = value

		return this.newInstance()
	}

	public changeCustomerMayParticipateMoreThanOnce() {
		this.customerMayParticipateMoreThanOnce =
			!this.customerMayParticipateMoreThanOnce

		return this.newInstance()
	}

	public changeProducts(items: string[]) {
		this.products = items.map((item) => {
			const alreadyAddedItem = this.products.find(
				(i) => i.productId === item
			)
			return {
				productId: item,
				quantity: alreadyAddedItem?.quantity || '0'
			}
		})

		return this.newInstance()
	}

	public changeProductMinQtd(productID: string, minQtd: string) {
		this.products = this.products.map((item) => {
			if (item.productId === productID) {
				return { ...item, quantity: minQtd }
			}
			return item
		})

		return this.newInstance()
	}

	public changeObservation(value: string) {
		this.observation = value

		return this.newInstance()
	}
}
