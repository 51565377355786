import React from 'react'
import { IFilterProps } from '../types'
import SearchIcon from '../../../assets/images/search.svg'

function Filters(props: IFilterProps) {
	return (
		<section className="combo-filters">
			<div className="filters-wrapper"></div>
			<div className="input">
				<input
					onChange={props.searchString.onChange}
					required
					id="search"
					value={props.searchString.value}
				/>
				<label htmlFor="search">Pesquisar combo</label>
				<img
					className="magnifier-icon"
					src={SearchIcon}
					alt="Icone de Lupa"
				/>
			</div>
		</section>
	)
}

export default Filters
