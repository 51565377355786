import axios from 'axios'
import cogoToast from 'cogo-toast'
import { store } from '../../shared/store'
import {  PaginatedBanners } from '../../shared/interfaces/banner'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'

import {
	axiosGPInstance,
	getJwtAuthGpAcessToken,
	apiCustomer,
	apiAdmin
} from '../../shared/services/axiosInstace'
import { ICategory, IGetAllBanners, IProduct } from './types'
import { IFilterListPdvs } from '../../components/filters-by-hieararchy/typings'

const apiBanner = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

export async function postListAllBanners(
	{ from, to, customerIDs }: IGetAllBanners,
	pageSize: number,
	pageNumber: number
) {
	const response = await apiBanner.post<PaginatedBanners>(
		`/banner/filtered-banners`,
		{
			customerIDs,
			from,
			to,
			pageSize,
			pageNumber
		}
	)
	return response.data
}

export async function savingUploadImageBannerHandler(payload: {
	file: File
	fileName: string
}) {
	try {
		const token = await getJwtAuthGpAcessToken()
		const customer = store.getState().customer

		const formData = new FormData()

		formData.append(
			'upload',
			payload.file,
			`${payload.fileName}.${payload.file.name.split('.')[1]}`
		)

		const response = await axios.post(
			`${'https://jyvxvsusib.execute-api.sa-east-1.amazonaws.com/prd'}/bucketbpgpbanners`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
					userID: customer.CustomerID
				}
			}
		)
		return response.data
	} catch (err) {
		const error = err as { message: string }
		cogoToast.error(error.message, cogoDefaultOptions)
	}
}

export async function getProducts() {
	try {
		const response = await axiosGPInstance.get<IProduct[]>('/materials')
		return response.data
	} catch (error) {
		return []
	}
}

export async function getCategory() {
	try {
		const response = await axiosGPInstance.get<ICategory[]>('/categories')
		return response.data
	} catch (error) {
		return []
	}
}

export async function handleFilterCustomers(filterOptions: IFilterListPdvs) {
	try {
		const response = await axiosGPInstance.post(
			'/customers/listPdvs',
			{...filterOptions,
				customers : filterOptions.codePdv
			}
		)
		return response.data.total
	} catch (error) {
		console.error({ error })
		return null
	}
}
