import { AxiosResponse } from 'axios'
import { ICustomer } from '../../shared/interfaces/customer'

import { IMaterial } from '../../shared/interfaces/material'
import axiosInstance from '../../shared/services/axiosInstace'
import { IGetMaterialsReq, IUnavailableProductReq } from './types'

export async function getMaterials({
	salesOrganizationID,
	segmentID,
	sectorID,
	customerID,
	morePoints,
	channelGroupID,
	includesDraftBeer,
	ignoreThrow = false
}: IGetMaterialsReq): Promise<Array<IMaterial> | null> {
	try {
		const response: AxiosResponse = await axiosInstance.get(
			`/materials/?salesOrganizationID=${salesOrganizationID}&segmentID=${segmentID}&sectorID=${sectorID}&customerID=${customerID}&morePoints=${morePoints}&channelGroupID=${channelGroupID}&includesDraftBeer=${!!includesDraftBeer}`
		)

		return response?.data
	} catch (error) {
		if (ignoreThrow) {
			return []
		}
		throw error
	}
}

export async function checkRulesOfTheProducts({
	customer,
	products
}: {
	products: Partial<IMaterial[]>
	customer: Partial<ICustomer>
}) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/program-points/check-registered-rules`,
			{ customer, products }
		)
		return response?.data
	} catch (error) {
		throw error
	}
}

export async function registerLogUnavailableProduct(unavailableProduct: IUnavailableProductReq) {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			`/materials/LogStockMaterial`,
			unavailableProduct
		)
		return response?.data
	} catch (error) {
		throw error
	}
}