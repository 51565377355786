import React, { Fragment } from 'react'

import PointsMovementList from './PointsMovementList'
import {
	Header,
	Loading,
	AnnouncementCard,
	PointsBalanceCard
} from '../../shared/components'
import PlaceholderNoExtract from './PlaceholderNoExtract'
import { TermsModal } from '../../shared/components/PointsProgramModalAdoption/TermsModal'

import { AnnouncementCardTypeEnum } from '../../components/AnnouncementCard/types'

import { IViewProps } from './types'

import * as S from './styles'

export default function MyPointsView(props: IViewProps) {
	const {
		isLoading,
		pointsProgramStatus,
		extractOfPointsData,
		// showMoreItemsFromTheListHandler, DESCOMENTAR QUANDO A PÁGINAÇÃO FOR INPLEMENTADA
		showModalWithMembershipTermsState,
		showModalWithMembershipTermsHandler
	} = props

	return (
		<Fragment>
			<Header />
			<S.MyPointsWrapper>
				{showModalWithMembershipTermsState && (
					<TermsModal
						goToThePoints
						active={showModalWithMembershipTermsState}
						onClose={showModalWithMembershipTermsHandler}
					/>
				)}
				{pointsProgramStatus ? (
					<S.ContentWrapper>
						{extractOfPointsData && (
							<>
								<S.CardsWrapper>
									<PointsBalanceCard
										pointsBalance={
											extractOfPointsData?.totalAvailableBalance
										}
										pointsToExpire={
											extractOfPointsData.totalToBeExpiredBalance
										}
										pointsUnderAnalysis={
											extractOfPointsData.totalAnalysisBalance
										}
									/>

									<AnnouncementCard
										cardType={AnnouncementCardTypeEnum.FAQ}
									/>
								</S.CardsWrapper>
								<S.ReadMembershipTermsWrapper>
									<S.ButtonReadMembershipTerms
										onClick={
											showModalWithMembershipTermsHandler
										}>
										LER TERMOS DE ADESÃO
									</S.ButtonReadMembershipTerms>
								</S.ReadMembershipTermsWrapper>
							</>
						)}
						{extractOfPointsData && !isLoading ? (
							<PointsMovementList
								extractOfPointsData={
									extractOfPointsData.extractTransactions
								}
							/>
						) : (
							<S.LoadingWrapper>
								<Loading />
							</S.LoadingWrapper>
						)}
						{/* DESCOMENTAR QUANDO A PÁGINAÇÃO FOR INPLEMENTADA */}
						{/* <S.ShowMoreButtonWrapper>
						<S.ShowMoreButton
							onClick={showMoreItemsFromTheListHandler}>
							Mostrar mais
						</S.ShowMoreButton>
					</S.ShowMoreButtonWrapper> */}
					</S.ContentWrapper>
				) : (
					<PlaceholderNoExtract
						showModalWithMembershipTermsHandler={
							showModalWithMembershipTermsHandler
						}
					/>
				)}
			</S.MyPointsWrapper>
		</Fragment>
	)
}
