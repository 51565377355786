import React, { KeyboardEvent, useRef } from 'react'

import { IProps } from './types'

function TagInput(props: IProps) {
	const { tags, removeTag, addTag } = props

	let inputRef = useRef()

	const inputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const { key } = e
		const { value } = e.target as any

		const wantsToAdd = key === 'Enter' && value

		if (wantsToAdd) {
			const lower = (x: string) => x.toLowerCase()

			const tagExists = (tag: string) => lower(tag) === lower(value)

			if (tags.find(tagExists)) return

			addTag(value)

			if (!inputRef?.current) return
			;(inputRef.current as any).value = null
		}

		if (key === 'Backspace' && !value) {
			removeTag(tags.length - 1)
		}
	}

	return (
		<div className="input-tag-container">
			<ul className="input-tag-list">
				{tags.map((tag, i: number) => (
					<li className="input-tag-list-item" key={i}>
						{tag}
						<button
							className="input-tag-button"
							onClick={() => removeTag(i)}>
							{'+'}
						</button>
					</li>
				))}
				<li className="input-tag-input-container">
					<input
						className="input-tag-input"
						ref={inputRef as any}
						onKeyDown={inputKeyDown}
					/>
				</li>
			</ul>
		</div>
	)
}

export default TagInput
