import cogoToast from 'cogo-toast'
import {
	allPass,
	apply,
	complement,
	gt,
	isNil,
	pipe,
	prop,
	propEq,
	props
} from 'ramda'
import { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { add } from '../../shared/utils/math'
import { parseItemPrice } from '../../shared/utils/money'
import { getMinPriceFromParams } from '../../shared/utils/param'
import { getComboPriceTotalWithoutBonus } from '../../shared/utils/price'
import { CogoPositions } from '../../shared/utils/toaster'
import { IViewProps } from './types'
import Cart from './view'

import { IExtractPoints } from '../../shared/interfaces/pointsProgram'
import { getPointsExtracts } from '../../shared/services/customerPointsProgram.service'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { IAllInEvent, sendToAllIn } from '../../shared/utils/allin'

function CartContainer(): JSX.Element {
	const {
		carts,
		customer,
		companyParams,
		pointsProgramStatus,
		cartRedemptionItems,
		cartChallengeItems
	} = useTypedSelector([
		'carts',
		'customer',
		'companyParams',
		'pointsProgramStatus',
		'cartRedemptionItems',
		'cartChallengeItems'
	])

	const [isAgreed, setAgreed] = useState(false)
	const [showAlert, setAlert] = useState(false)
	const [insufficientPoints, setInsufficientPoints] = useState(false)
	const [totalPointsRedeemedState, setTotalPointsRedeemedState] = useState(0)
	const [userPointsExtractData, setUserPointsExtractData] =
		useState<Partial<IExtractPoints> | null>(null)

	const [cartProducts, setCarProducts] = useState<ICartItem[]>([])

	const items = carts.map((item) => item.items).flat()

	const itemsChallenge = cartChallengeItems
		.map((cartChallengeItem) => cartChallengeItem.addedProducts)
		.flat()

	const numberValue = getMinPriceFromParams(companyParams)

	const isReturnable = propEq('PackType', 'Retornável')

	const isValid = pipe(prop('PackType') as any, complement(isNil))

	const returnableItemsExists =
		items.some(allPass([isValid, isReturnable])) ||
		itemsChallenge.some(allPass([isValid, isReturnable]))

	const history = useHistory()

	const fmtPrice = pipe(prop('Price') as any, Number)

	const totalPrice =
		items.map(fmtPrice).reduce(add, 0) +
		itemsChallenge.map(fmtPrice).reduce(add, 0)

	const calculateItemDiscount = (item: ICartItem): number => {
		const priceWithoutDiscount =
			item.operationTypeDescription === 'Combo' && item.products?.length
				? getComboPriceTotalWithoutBonus(item.products) *
				  item.OrderQuantity
				: item.operationTypeDescription === 'Ação' && item.olderPrice
				? item.olderPrice * item.OrderQuantity
				: item.Price

		return priceWithoutDiscount - item.Price
	}

	const calculateCartDiscount = (items: ICartItem[]): number => {
		return items.reduce(
			(accDiscount, item) => accDiscount + calculateItemDiscount(item),
			0
		)
	}

	const normalItemsDiscount = calculateCartDiscount(items)
	const challengeItemsDiscount = calculateCartDiscount(itemsChallenge)
	const cartDiscount = normalItemsDiscount + challengeItemsDiscount

	function handleBack() {
		history.replace(CustomerRoutesEnum.PRODUCT_LIST)
	}

	function handleClientAgreement() {
		setAgreed(!isAgreed)

		setAlert(false)
	}

	function handleProceed() {
		if (returnableItemsExists && !isAgreed) return setAlert(true)

		const getAmounts = props(['OrderQuantity', 'StockPosition'])

		const validAmount = pipe(getAmounts, apply(gt))

		const wrongItems = [
			...items.filter(validAmount),
			...itemsChallenge.filter(validAmount)
		]

		const options = CogoPositions['top-right']

		for (const { StockPosition, Description } of wrongItems) {
			const stock = Math.round(Number(StockPosition))

			const message = `${Description} quantidade em estoque: ${stock}`

			setTimeout(() => cogoToast.error(message, options), 0)
		}

		if (numberValue > totalPrice) {
			const minPrice = parseItemPrice(numberValue)

			const message = `Valor minimo do pedido: ${minPrice}`

			cogoToast.error(message, options)

			return
		}

		if (wrongItems.length) return

		history.replace(CustomerRoutesEnum.ORDER_SUMMARY)
	}

	const hasQuantity = pipe(prop('OrderQuantity') as any, Boolean)

	const canProccesWithThePurchaseOfProducts =
		Boolean(items.length) &&
		items.every(hasQuantity) &&
		totalPrice >= numberValue

	const canProccedWithPurchaseChallengeItems =
		Boolean(itemsChallenge.length) &&
		itemsChallenge.every(hasQuantity) &&
		totalPrice >= numberValue

	const canProceed =
		canProccesWithThePurchaseOfProducts ||
		canProccedWithPurchaseChallengeItems

	function fetchUserPointsExtractData() {
		;(async () => {
			const balance = await getPointsExtracts(customer.CustomerID)
			setUserPointsExtractData(balance)
		})()
	}

	function checkingIfThereAreEnoughPointsToRedeemMoreProducts() {
		if (
			totalPointsRedeemedState >
			Number(userPointsExtractData?.totalAvailableBalance)
		) {
			setInsufficientPoints(true)
			return
		}
		setInsufficientPoints(false)
	}

	function settingTotalPointsToBeRedeemed() {
		const totalPoints = cartProducts
			.filter(
				(item) =>
					item.typeItem === ICartItemTypeEnum.REDEMPTION &&
					!!item.rescueProduct
			)
			.reduce((acc, item) => {
				return (acc += Number(item.pointsRedeemed))
			}, 0)
		setTotalPointsRedeemedState(totalPoints)

		cartProducts.forEach((cart) => {
			sendToAllIn(IAllInEvent.cart, [
				{
					id: cart?.MaterialID || cart.promotionID,
					name: cart.Description,
					price: cart.Price,
					department: cart?.category
				}
			])
		})
	}

	useEffect(() => {
		setCarProducts([...items, ...cartRedemptionItems])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carts, cartRedemptionItems])

	useEffect(fetchUserPointsExtractData, [customer])
	useEffect(settingTotalPointsToBeRedeemed, [cartProducts])
	useEffect(checkingIfThereAreEnoughPointsToRedeemMoreProducts, [
		totalPointsRedeemedState,
		userPointsExtractData
	])

	const viewProps: IViewProps = {
		showAlert,
		handleBack,
		totalPrice,
		canProceed,
		cartDiscount,
		cartProducts,
		handleProceed,
		cartChallengeItems,
		insufficientPoints,
		handleClientAgreement,
		returnableItemsExists,
		totalPointsRedeemed: totalPointsRedeemedState,
		customerEarnsPoints: pointsProgramStatus.userJoinedTheProgram
	}

	return createElement(Cart, viewProps)
}

export default CartContainer
