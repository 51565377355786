import { format, parse } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import { add, pipe, prop } from 'ramda'
import { ISelect } from '../../components/SelectPrimary/types'
import { ICartItem } from '../../shared/interfaces/cart'
import { IFormattedPaymentCondition, IPaymentConditions } from '../../shared/interfaces/customer'
import { IFormattedParam } from '../../shared/interfaces/formattedParam'
import { IStoreCustomer } from '../../shared/interfaces/store'
import { flatReducer } from '../../shared/utils/array'
import { formatPaymentCondition } from '../../shared/utils/customer'
import { parseItemPrice } from '../../shared/utils/money'
import { isPropEqual } from '../../shared/utils/object'
import {
	getMinPriceBoletoFromParams,
	getWorkingHoursParams
} from '../../shared/utils/param'
import { getPromotionType, ItemTypeEnum } from '../../shared/utils/promotion'
import { PaymentOptions } from './Payment/types'
import { ErrorsActions, IErrorsAction, IErrorState } from './types'

export function formatDeliveryDate(dateString?: string) {
	if (!dateString) return

	const formattedDateLabel = `ATÉ ${format(
		parse(dateString, 'yyyy-MM-dd', new Date()),
		"dd 'de' MMMM yyyy",
		{
			locale: BR
		}
	).toUpperCase()}`

	return formattedDateLabel
}

export function formatDeliveryDateInput(dateString?: string) {
	if (!dateString) return

	const formattedDateLabel = `${format(
		parse(dateString, 'yyyy-MM-dd', new Date()),
		"dd 'de' MMMM, yyyy",
		{
			locale: BR
		}
	)}`

	return formattedDateLabel
}

export function handleSetWorkingHours(companyParams: IFormattedParam[]) {
	const times = getWorkingHoursParams(companyParams)

	const openTime = times[0]
	const closeTime = times[1]

	if (openTime && closeTime) {
		return {
			open: openTime,
			close: closeTime
		}
	}
}

export function isChecked(
	item: IFormattedPaymentCondition,
	condition?: IFormattedPaymentCondition
): boolean {
	if (!condition) return false

	const sameMethod = isPropEqual('PaymentMethod')(item)(condition)
	const sameTerms = isPropEqual('PaymentTerms')(item)(condition)

	return sameMethod && sameTerms
}

export class OrderDetailsModel {
	private customer: IStoreCustomer
	private companyParams: IFormattedParam[]
	private cartPurchaseItems: ICartItem[]
	private cartChallengeItemsPurchse: ICartItem[]

	public get totalPrice() {
		const fmtPrice = pipe(prop('Price'), Number)
		return (
			this.cartPurchaseItems.map(fmtPrice).reduce(add, 0) +
			this.cartChallengeItemsPurchse.map(fmtPrice).reduce(add, 0)
		)
	}

	public get conditions() {
		return (this.customer.PaymentConditions || [])
			.map(formatPaymentCondition)
			.reduce(flatReducer, [])
	}

	public get orderDateOptions() {
		const date = this.customer.DeliveryDate
		const formattedDate = formatDeliveryDate(date)

		if (!formattedDate) return []

		return [
			{
				value: formattedDate,
				label: formattedDate
			}
		]
	}

	public get workingHours() {
		return handleSetWorkingHours(this.companyParams)
	}

	public get minValueBoleto() {
		return getMinPriceBoletoFromParams(this.companyParams)
	}

	public get formattedBoletoValue() {
		return parseItemPrice(this.minValueBoleto)
	}

	public isValidBoleto(
		selectedCondition: IFormattedPaymentCondition | undefined
	) {
		const findSelectedCondition = this.conditions.find((condition) => {
			return isChecked(condition, selectedCondition)
		})
		const isBoleto = findSelectedCondition?.PaymentMethod === '5'

		return !isBoleto || this.totalPrice >= this.minValueBoleto
	}

	public canFinish(
		condition: IFormattedPaymentCondition | undefined,
		dateToDelivery: ISelect | null,
		isCheckingCombos: boolean
	) {
		return Boolean(
			condition &&
				this.isValidBoleto(condition) &&
				dateToDelivery &&
				!isCheckingCombos
		)
	}

	constructor({
		customer,
		companyParams,
		cartPurchaseItems,
		cartChallengeItemsPurchase
	}: IProps) {
		this.customer = customer
		this.companyParams = companyParams
		this.cartPurchaseItems = cartPurchaseItems
		this.cartChallengeItemsPurchse = cartChallengeItemsPurchase
	}
}

interface IProps {
	customer: IStoreCustomer
	companyParams: IFormattedParam[]
	cartPurchaseItems: ICartItem[]
	cartChallengeItemsPurchase: ICartItem[]
}

export function isCombo(promotionType?: number) {
	return getPromotionType(Number(promotionType)) === ItemTypeEnum.COMBO
}

export const translationOfPositions = [
	'PRIMEIRO',
	'SEGUNDO',
	'TERCEIRO',
	'QUARTO',
	'QUINTO',
	'SEXTO',
	'SÉTIMO',
	'OITAVO',
	'NONO',
	'DÉCIMO',
	'DÉCIMO PRIMEIRO',
	'DÉCIMO TERCEIRO',
	'DÉCIMO QUARTO',
	'DÉCIMO QUINTO',
	'DÉCIMO SEXTO',
	'DÉCIMO SÉTIMO',
	'DÉCIMO OITAVO',
	'DÉCIMO NONO',
	'VIGÉSIMO'
]

interface ICreditCardMask {
	name: string
	sizeString: number
	prefix: RegExp
	mask: RegExp
	maskReplace: string
}

const cards: ICreditCardMask[] = [
	{
		name: 'Mastercard',
		sizeString: 16,
		prefix: /^(603136|603689|608619|606200|603326|605919|608783|607998|603690|604891|603600|603134|608718|603680|608710|604998)|(5[1-5][0-9]{14}|2221[0-9]{12}|222[2-9][0-9]{12}|22[3-9][0-9]{13}|2[3-6][0-9]{14}|27[01][0-9]{13}|2720[0-9]{12})$/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'Visa',
		sizeString: 16,
		prefix: /^4[0-9]{12}(?:[0-9]{3})?$/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'American Express',
		sizeString: 15,
		prefix: /^3[47]/,
		maskReplace: '$1 $2 $3',
		mask: /(\d{4})(\d{6})(\d{5})/
	},

	{
		name: 'Diners Club',
		sizeString: 15,
		prefix: /^3(?:0[0-5]|[68][0-9])/,
		maskReplace: '$1 $2 $3',
		mask: /(\d{4})(\d{6})(\d{4})/
	},

	{
		name: 'Discover',
		sizeString: 15,
		prefix: /^6011/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'EnRoute',
		sizeString: 15,
		prefix: /^(?:2149)|(?:2014)/,
		maskReplace: '$1 $2 $3',
		mask: /(\d{4})(\d{7})(\d{4})/
	},

	{
		name: 'JCB',
		sizeString: 15,
		prefix: /^35/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'Voyager',
		sizeString: 16,
		prefix: /^869/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{5})(\d{4})(\d{5})(\d{1})/
	},

	{
		name: 'HiperCard',
		sizeString: 16,
		prefix: /^(38[0-9]{17}|60[0-9]{14})$/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'Aura',
		sizeString: 16,
		prefix: /^50[0-9]{14,17}$/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	},

	{
		name: 'Test',
		sizeString: 16,
		prefix: /^0[0]{14,17}$/,
		maskReplace: '$1 $2 $3 $4',
		mask: /(\d{4})(\d{4})(\d{4})(\d{4})/
	}
]

interface IResultValidatorCreditCard {
	isValid: boolean
	format?: string
	flag?: string
	flagId?: string
}

function verifyCart(cartNumber: string): IResultValidatorCreditCard {
	const cart = cards.find((x) => x.prefix.test(cartNumber))

	if (cart === undefined)
		return {
			isValid: false
		}

	const { mask, maskReplace } = cart

	return {
		isValid: true, // aqui pode chamar uma função que valida o numero do cartao
		flag: cart.name,
		format: cartNumber.replace(mask, maskReplace)
	}
}

export function cc_format(value: string) {
	return verifyCart(value).format
}

export function cleanCreditCard(numberPhone: string): string {
	return numberPhone.replace(/ /g, '')
}

export function validateCreditCard(value: string) {
	// Accept only digits, dashes or spaces
	if (/[^0-9-\s]+/.test(value)) return false

	// The Luhn Algorithm. It's so pretty.
	let nCheck = 0,
		bEven = false
	value = value.replace(/\D/g, '')

	for (var n = value.length - 1; n >= 0; n--) {
		var cDigit = value.charAt(n),
			nDigit = parseInt(cDigit, 10)

		if (bEven && (nDigit *= 2) > 9) nDigit -= 9

		nCheck += nDigit
		bEven = !bEven
	}

	return nCheck % 10 === 0
}

export enum cardTypes {
	//TODO: COMBINE WITH BACK-END
	VISA = 'visa',
	AMEX = 'american_express',
	MASTERCARD = 'mastercard',
	DISCOVER = 'discorver',
	DINERS = 'diners_club',
	JCB = 'jcb',
	CHINA_UNION_PAY = 'china_union_pay',
	ELO = 'elo',
	HIPERCARD = 'hipercard',
	SOROCRED = 'sorocred',
	AURA = 'aura',
	CABAL = 'cabal',
	OUTROS = 'outros'
}

export const FlagValues: { [key in string]: string } = {
	visa: '01',
	mastercard: '02',
	american_express: '03',
	sorocred: '04',
	diners_club: '05',
	elo: '06',
	hipercard: '07',
	aura: '08',
	cabal: '09',
	outros: '99'
}

export function creditCardType(cc: string) {
	cc = cleanCreditCard(cc)
	const amex = new RegExp('^3[47][0-9]{13}$')
	const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
	const cup1 = new RegExp('^62[0-9]{14}[0-9]*$')
	const cup2 = new RegExp('^81[0-9]{14}[0-9]*$')
	const sorocred = new RegExp('^627892|^636414')
	const aura = new RegExp(/^((?!504175))^((?!5067))(^50[0-9])/)

	const mastercard = new RegExp('^5[1-5][0-9]{14}$')
	const mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

	const disco1 = new RegExp('^6011[0-9]{12}[0-9]*$')
	const disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$')
	const disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$')

	const diners = new RegExp('^3[0689][0-9]{12}[0-9]*$')
	const jcb = new RegExp('^35[0-9]{14}[0-9]*$')

	const elo = new RegExp(
		/^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/
	)

	const hiper = new RegExp('/^(606282d{10}(d{3})?)|(3841d{15})$/')
	const dev = new RegExp('^(?=[0]$)*0+$')

	if (visa.test(cc)) {
		return cardTypes.VISA
	}
	if (amex.test(cc)) {
		return cardTypes.AMEX
	}
	if (mastercard.test(cc) || mastercard2.test(cc)) {
		return cardTypes.MASTERCARD
	}
	if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
		return cardTypes.DISCOVER
	}
	if (diners.test(cc)) {
		return cardTypes.DINERS
	}
	if (jcb.test(cc)) {
		return cardTypes.JCB
	}
	if (cup1.test(cc) || cup2.test(cc)) {
		return cardTypes.CHINA_UNION_PAY
	}

	if (elo.test(cc)) {
		return cardTypes.ELO
	}
	if (hiper.test(cc)) {
		return cardTypes.HIPERCARD
	}

	if (sorocred.test(cc)) {
		return cardTypes.SOROCRED
	}

	if (aura.test(cc)) {
		return cardTypes.AURA
	}

	if (
		('prd' === 'dev' ||
			'prd' === 'stg') &&
		dev.test(cc)
	) {
		return cardTypes.VISA
	}

	return ''
}

export function reducerErros(state: IErrorState, action: IErrorsAction) {
	const { type, payload } = action

	switch (type) {
		case ErrorsActions.CARD_NUMBER:
			return {
				...state,
				cardNumberValueError: payload
			}
		case ErrorsActions.VALIDITY_CREDIT_CARD:
			return {
				...state,
				validityCreditCardValueError: payload
			}
		case ErrorsActions.CARD_SECURITY_CODE:
			return {
				...state,
				cardSecurityCodeValueError: payload
			}
		case ErrorsActions.NAME_PRINTED_ON_CARD:
			return {
				...state,
				namePrintedOnCardValueError: payload
			}
		case ErrorsActions.DOCUMENT_NUMBER:
			return {
				...state,
				documentNumberError: payload
			}
		default:
			return state
	}
}

export const INITIAL_STEP = {
	step1: {
		active: true,
		options: [
			{
				index: PaymentOptions.PAY_NOW_ONLINE,
				active: false
			},
			{
				index: PaymentOptions.PAY_ON_DELIVERY,
				active: false
			},
			{
				index: PaymentOptions.PAY_WITH_CREDIT_CARD,
				active: false
			},
			{
				index: PaymentOptions.PAY_WITH_PIX,
				active: false
			}
		]
	},
	step2: {
		active: false
	},
	step3: {
		active: false
	},
	buttons: {
		confirm: false
	}
}

export const translatePayment: {
	[key in number]: string
} = {
	1: 'Na Entrega',
	2: 'Cartão de Crédito',
	3: 'Pagamento via Pix'
}

export function formatPaymentConditions(conditions: IPaymentConditions[]) {
	const formatedConditions = (conditions || [])
		.map(formatPaymentCondition)
		.reduce(flatReducer, []);

	return formatedConditions;
}