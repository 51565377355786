import { apiCustomer, apiAdmin } from '../../../shared/services/axiosInstace'
import { getAmbienteName } from '../../../utils/getApiBaseUrl.utils'
import { IHistoryPush } from '../history-push/history-push.types'
import { PushTransactionalProps } from '../transactional-push/transactional-push.types'

const apiNotification = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

interface IGetHistoryPush {
	from?: string
	to?: string
	title?: string
}

export const pushNotificationsAdmin = {
	getHitoryPushList: async function ({ from, to, title }: IGetHistoryPush) {
		const titleCondition = title ? `&title=${title}` : ''
		const response = await apiNotification.get<IHistoryPush[]>(
			`/notifications?from=${from}&to=${to}${titleCondition}`
		)
		return response.data
	},

	putCancelHitoryPush: async function (
		idNotification: string,
		justification: string
	): Promise<IHistoryPush> {
		const response = await apiNotification.put(
			`notifications/${idNotification}/cancel`,
			{
				justification
			}
		)
		return response.data
	},

	editPush: async function (
		idNotification: string,
		title: string,
		message: string
	): Promise<IHistoryPush> {
		const response = await apiNotification.put(
			`notifications/${idNotification}`,
			{
				title,
				message
			}
		)
		return response.data
	},
	getTransactionalPush: async function (): Promise<PushTransactionalProps[]> {
		const response = await apiNotification.get('/push-transactional-model')
		return response.data
	},
	editTransactionalPush: async function (
		idNotification: string,
		title: string,
		message: string,
		description: string
	): Promise<IHistoryPush> {
		const response = await apiNotification.patch(
			`push-transactional-model/${idNotification}`,
			{
				title,
				message,
				description
			}
		)
		return response.data
	},
	patchStatusTransactionalPush: async function (
		idTransactionalPush: string,
		status: string
	): Promise<PushTransactionalProps> {
		const response = await apiNotification.patch(
			`/push-transactional-model/${idTransactionalPush}/status`, {
			status
		})
		return response.data
	},
}
