import React, { Fragment } from 'react'

import {
	AnnouncementCard,
	Header,
	LightBox,
	Loading,
	PointsBalanceCard
} from '../../shared/components'
import Filters from '../ProductList/Filters'
import SuggestionBlock from './SuggestionBlock'
import ProductForRedemption from './ProductForRedemption'
import ProductModal from '../ProductList/ProductModal'

import { AnnouncementCardTypeEnum } from '../../components/AnnouncementCard/types'
import { IViewProps } from './types'

import * as S from './styles'

export default function PointsRedemptionView(props: IViewProps) {
	const {
		modalOpen,
		isLoading,
		closeModal,
		filtersProps,
		openLightbox,
		productAmount,
		lightboxPhotos,
		selectedProduct,
		handleIncrement,
		handleDecrement,
		setOpenLightbox,
		handleSelectProdut,
		handleUpdateAmount,
		handleAddItemToCard,
		productsToBeRendered,
		userPointsExtractData,
		listOfSuggestedProducts,
		insufficientAmountOfPointsForRedemption
	} = props

	const inputIncrementProps = {
		value: productAmount,
		enableButtons: true,
		handleUpdate: handleUpdateAmount,
		handleIncrementEvent: handleIncrement,
		handleDecrementEvent: handleDecrement
	}

	return (
		<Fragment>
			<Header />
			<S.PointsRedemptionWrapper>
				<ProductModal
					modalOpen={modalOpen}
					closeModal={closeModal}
					isProductForRedemption={true}
					productAmount={productAmount}
					selectedProduct={selectedProduct}
					setOpenLightbox={setOpenLightbox}
					inputIncrementProps={inputIncrementProps}
					insufficientAmountOfPointsForRedemption={
						insufficientAmountOfPointsForRedemption
					}
					handleAddProduct={() =>
						handleAddItemToCard(selectedProduct)
					}
				/>
				<S.ContentWrapper>
					<S.CardsWrapper>
						<PointsBalanceCard
							pointsBalance={
								userPointsExtractData?.totalAvailableBalance as number
							}
							pointsToExpire={
								userPointsExtractData?.totalToBeExpiredBalance as number
							}
							pointsUnderAnalysis={
								userPointsExtractData?.totalAnalysisBalance as number
							}
						/>
						<AnnouncementCard
							cardType={AnnouncementCardTypeEnum.FAQ}
						/>
					</S.CardsWrapper>
					<S.SectionBlockSuggestions>
						{!!listOfSuggestedProducts?.length && (
							<SuggestionBlock
								isLoading={isLoading}
								products={
									listOfSuggestedProducts?.length
										? listOfSuggestedProducts
										: []
								}
								handleClickItem={handleSelectProdut}
							/>
						)}
					</S.SectionBlockSuggestions>
				</S.ContentWrapper>
				<S.FilteresWrapper>
					<S.FiltersListTitleWrapper>
						<S.FiltersListTitle>
							Produtos Disponíveis
						</S.FiltersListTitle>
					</S.FiltersListTitleWrapper>
					<Filters {...filtersProps} />
				</S.FilteresWrapper>
				{!isLoading && productsToBeRendered ? (
					<S.RedeemableProductListWrapper>
						{productsToBeRendered.map((item) => (
							<ProductForRedemption
								product={item}
								key={item.MaterialID}
								handleClick={handleSelectProdut}
							/>
						))}
					</S.RedeemableProductListWrapper>
				) : (
					<S.LoadingWrapper>
						<Loading />
					</S.LoadingWrapper>
				)}
			</S.PointsRedemptionWrapper>

			<LightBox
				isOpen={openLightbox}
				setIsOpen={setOpenLightbox}
				lightboxPhotos={lightboxPhotos}
			/>
		</Fragment>
	)
}
