import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import Banner from '../../assets/images/welcome-banner_desktop.png'
import { mediaQuery } from '../../shared/styles/functions'

export const WelcomeWrapper = styled.div`
	background: ${(props) => props.theme.palette.primaryWhite.main};
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.welcome-box {
		padding: 80px;
		background-color: ${colors.white};
		border-radius: 4px;
		display: flex;
		align-items: center;
		margin-top: 130px;

		img {
			margin-right: 48px;
		}
		.welcome-title {
			color: ${colors.red};
			font-size: ${(props) => props.theme.font.size.DISPLAY_LG.size}rem;
			margin-bottom: 24px;
			font-weight: ${(props) => props.theme.font.weight.regular};
		}

		.welcome-subtitle {
			color: ${colors.secondGrey};
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		}
	}

	.border {
		background-color: ${colors.lightGrey};
		height: 1px;
		margin: 32px 0;
		width: 100%;
	}

	.banner {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		height: 200px;
		border-radius: 4px;
		background-image: url(${Banner});
		background-clip: border-box;
		background-origin: content-box; //not working-why?
		a {
			font: normal normal 300 32px/40px Roboto;
			color: ${(props) => props.theme.palette.white.main};

			h1 {
				padding: 45px;
			}
		}
	}
	${mediaQuery('sm')(`
	.welcome-box{
		display: flex;
		flex-direction: column;
		padding: 24px;

		img{
			margin: 0
		}
	}
    `)}
`

export const ButtonWrapper = styled.div`
	margin: 40px 0;
	text-align: center;
`

export const LoadingWrapper = styled.div`
	width: 100%;
	min-height: 500px;

	display: flex;
	align-items: center;
	justify-content: center;
`
