import styled from "styled-components";

export const WrapperBannerData = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    gap: 60px;

    margin: 24px 0px;

    .section-wrapper{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      gap: 16px;

      .section-title{
        color: ${(props) => props.theme.palette.black.main};
        font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
        font-weight: 500;
      }
    }
`