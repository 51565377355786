import React, { useCallback, useEffect, useState } from 'react'
import {
	CloseButton,
	InputField,
	Loading,
	Modal,
	SelectPrimary
} from '../../../shared/components'
import { ICompanie, UserGroupEnum } from '../../../shared/interfaces/sacUser'
import {
	INewUserValues,
	fetchUserGroups,
	saveUser
} from '../../../shared/services/user.service'
import { fetchCompanies } from '../../ResaleList/service'
import { fetchCompanyParam } from '../../ResaleParameters/service'

import cogoToast from 'cogo-toast'
import { ISelectOption } from '../../../shared/utils/SelectOptions'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import styles from './styles.module.scss'
import {
	IFormValues,
	IGroupSelectOption,
	IModalUserProps,
	INITIAL_FORM_VALUES
} from './types'

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { reactSelectStyles } from '../../../shared/styles/theme'

export default function ModalUser(props: IModalUserProps) {
	const { isActive, onClose, user, onDelete } = props

	const [userFields, setUserFields] =
		useState<IFormValues>(INITIAL_FORM_VALUES)
	const [groupOptions, setGroupOptions] = useState<IGroupSelectOption[]>([])
	const [companyOptions, setCompanyOptions] = useState<ISelectOption[]>([])

	const [isFetchingResales, setIsFetchingResales] = useState(false)
	const [isSubmitingUser, setIsSubmitingUser] = useState(false)
	const [formIsValid, setFormIsValid] = useState(false)
	const [userFieldError, setUserFieldError] = useState(false)

	const [selectedUnities, setSelectedUnities] = useState<ICompanie[]>([])

	const [alreadyRegisteredUnities, setAlreadyRegisteredUnities] = useState<
		ISelectOption[]
	>([])

	const handleSelectUnities = useCallback(
		(value) => {
			setSelectedUnities(value.map((e: any) => e.value))
			setAlreadyRegisteredUnities(value)
		},
		[setSelectedUnities, setAlreadyRegisteredUnities]
	)

	const handlers = {
		user: useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event?.target?.value

			if (Number.isNaN(Number(value))) return

			setUserFields((state) => ({ ...state, user: value }))
		}, []),
		group: useCallback((value: IGroupSelectOption) => {
			setUserFields((state) => {
				if (
					value.label !== UserGroupEnum.CD &&
					value.label !== UserGroupEnum.UNIDADE
				) {
					state.salesOrganizationID = undefined
				}
				return { ...state, group: value }
			})
		}, []),
		salesOrganizationID: useCallback((value: ISelectOption) => {
			setUserFields((state) => ({ ...state, salesOrganizationID: value }))
		}, [])
	}

	function handleSubmit() {
		;(async () => {
			try {
				setIsSubmitingUser(true)

				const {
					user: userEnrollment,
					group,
					salesOrganizationID
				} = userFields

				if (!group) return

				const newUserPayload: INewUserValues = {
					user: userEnrollment,
					groupId: group.value,
					group:
						group.label === UserGroupEnum.CD
							? UserGroupEnum.UNIDADE
							: group.label,
					salesOrganizationID: '',
					salesOrganizationsIDs: selectedUnities
				}

				await saveUser({
					oldValues: user,
					newValues: newUserPayload
				})

				cogoToast.success(
					'Usuário salvo com sucesso!',
					cogoDefaultOptions
				)
			} finally {
				setIsSubmitingUser(false)
				onClose({ refresh: true })
				setUserFields(INITIAL_FORM_VALUES)
			}
		})()
	}

	function handleDelete() {
		onDelete()
	}

	function handleClose() {
		onClose({ refresh: false })
		setUserFields(INITIAL_FORM_VALUES)
	}

	function validateFields() {
		if (!userFields.user) return

		let isValid = true

		isValid = isValid && !!userFields.user && userFields.user.length >= 8
		setUserFieldError(!isValid)

		isValid = isValid && !!userFields.group

		if (
			userFields.group?.label === UserGroupEnum.CD ||
			userFields.group?.label === UserGroupEnum.UNIDADE
		) {
			isValid = isValid && selectedUnities.length > 0
		}

		if (userFields.group?.label !== UserGroupEnum.UNIDADE) {
			isValid =
				isValid &&
				(userFields.user !== user?.user ||
					userFields.group?.value !== user?.groupId)

			setFormIsValid(isValid)
		} else {
			setFormIsValid(isValid)
		}
	}

	function fillFormValues() {
		if (!user) {
			setUserFields(INITIAL_FORM_VALUES)
			return
		}

		const findGroupOption = groupOptions.find(
			(option) =>
				option.label === user.group && option.value === user.groupId
		)

		if (userFields.group?.label === UserGroupEnum.UNIDADE) {
			setSelectedUnities(user?.companies ?? [])
		}

		setUserFields((state) => ({
			...state,
			user: user.user,
			group: findGroupOption
		}))
	}

	function fetchGroupOptions() {
		;(async () => {
			const userGroups = await fetchUserGroups()
			const formattedOptions = userGroups.map((group) => ({
				label: group.name,
				value: group.ID
			}))
			setGroupOptions(formattedOptions)
		})()
	}

	function fetchResaleOptions() {
		;(async () => {
			try {
				setIsFetchingResales(true)
				if (
					userFields.group?.label !== UserGroupEnum.CD &&
					userFields.group?.label !== UserGroupEnum.UNIDADE
				)
					return
				const params = await fetchCompanyParam()

				const salesOrganizationIds: string[] = params
					.filter(
						(param) =>
							param.Parameter === 'HABILITA_VENDA_BOM_PARCEIRO' &&
							param.Items.find((item) => item.Value === 'Sim')
					)
					.map((param: any) => param.SalesOrganizationID)

				const allCompanies = await fetchCompanies()
				const filteredCompaniesOptions = allCompanies
					.filter((company) =>
						salesOrganizationIds.includes(
							company.SalesOrganizationID
						)
					)
					.map((company) => ({
						label:
							company.SalesOrganizationID + ' - ' + company.Name,
						value: company.SalesOrganizationID
					}))

				const findUserCompany = filteredCompaniesOptions.find(
					(companyOption) => {
						return companyOption.value === user?.salesOrganizationID
					}
				)

				if (findUserCompany) {
					setUserFields((state) => ({
						...state,
						salesOrganizationID: findUserCompany
					}))
				}
				setCompanyOptions(filteredCompaniesOptions)
			} finally {
				setIsFetchingResales(false)
			}
		})()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchResaleOptions, [userFields.group])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(validateFields, [userFields, selectedUnities.length])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fillFormValues, [user, groupOptions])
	useEffect(fetchGroupOptions, [])

	useEffect(() => {
		if (!!user) {
			const selectCompany = companyOptions.filter((selectItem) => {
				return user?.companies?.some(
					(companyItem) =>
						companyItem.salesOrganizationID === selectItem.value
				)
			})

			setAlreadyRegisteredUnities(selectCompany)
		}else{
			setAlreadyRegisteredUnities([])
			setSelectedUnities([])
		}
	}, [user, companyOptions])

	const title = !!user ? 'Informações' : 'Cadastrar Usuário'

	return (
		<Modal isActive={isActive}>
			<div className={styles['moda-user']}>
				<div className={styles['modal-header']}>
					<h1 className="modal-title">{title}</h1>
					<CloseButton
						onClick={() => {
							setSelectedUnities([])
							setAlreadyRegisteredUnities([])
							handleClose()
						}}
					/>
				</div>

				<div className={styles['form-wrapper']}>
					<div className={styles['fields-wrapper']}>
						<InputField
							id="user"
							label="Matrícula"
							onChange={handlers.user}
							secondary
							value={userFields.user}
							hasError={userFieldError}
							errorMessage="Matrícula deve ser maior ou igual a 8 caracteres"
							disabled={!!user}
						/>
					</div>
					<div className={styles['fields-wrapper']}>
						<label
							className="multiselect-label"
							style={{ zIndex: 0 }}>
							Perfil
						</label>
						<SelectPrimary
							isLoading={false}
							isClearable={false}
							placeholder="Perfil"
							options={groupOptions.map((e) => {
								if (e.label === 'UNIDADE') {
									return {
										label: 'CD',
										value: e.value
									}
								} else {
									return {
										label: e.label,
										value: e.value
									}
								}
							})}
							value={
								userFields.group?.label ===
								UserGroupEnum.UNIDADE
									? UserGroupEnum.CD
									: userFields.group?.label || ''
							}
							onChange={handlers.group}
							classNamePrefix="react-select"
							className="react-select select-group"
						/>
					</div>
					{(userFields.group?.label === UserGroupEnum.CD ||
						userFields.group?.label === UserGroupEnum.UNIDADE) && (
						<div className={styles['fields-wrapper']}>
							{isFetchingResales ? (
								<Loading />
							) : (
								<>
									<ReactMultiSelectCheckboxes
										options={companyOptions}
										onChange={(value: ISelectOption[]) => {
											handleSelectUnities(value)
										}}
										value={alreadyRegisteredUnities}
										isMulti
										placeholderButtonLabel="CD"
										styles={reactSelectStyles}
									/>
								</>
							)}
						</div>
					)}
				</div>

				<div className={styles['button-wrapper']}>
					<button
						className="red-button"
						style={{ height: '43px' }}
						disabled={!formIsValid || isSubmitingUser}
						onClick={handleSubmit}>
						{!!user ? 'SALVAR ALTERAÇÕES' : 'CADASTRAR'}
					</button>
				</div>

				{!!user && (
					<div className={styles['button-wrapper']}>
						<button
							className={styles['delete-button']}
							onClick={handleDelete}>
							<span>EXCLUIR USUÁRIO</span>
						</button>
					</div>
				)}
			</div>
		</Modal>
	)
}
