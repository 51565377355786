import React, { ReactNode } from 'react'

import * as S from './styles'

const BedgeRulePointsProgramParameters = ({
	children
}: {
	children: ReactNode
}) => {
	return <S.BedgeParametersSpan>{children}</S.BedgeParametersSpan>
}

export default BedgeRulePointsProgramParameters
