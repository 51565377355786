import { createElement, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import TableButton from '../../components/TableButton'
import { Creators as ResaleActions } from '../../shared/store/ducks/resale'
import { Creators as ResalesActions } from '../../shared/store/ducks/resales'
import { sortName } from '../../shared/utils/sorts'
import { fetchCompanies } from './service'
import { IViewProps, ITableData, IResaleItem } from './types'
import ResaleList from './view'
import { include } from '../../shared/utils/boolean'

import FileIcon from '../../assets/images/file-text.svg'
import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'

function ResaleListContainer(): JSX.Element {
	const dispatch = useDispatch()
	const history = useHistory()

	const { setResales: setStoreResales } = ResalesActions
	const { setResale: setStoreResale } = ResaleActions
	const [resales, setResales] = useState<IResaleItem[]>([])
	const [data, setData] = useState<ITableData[]>([])
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const columns = useMemo(
		() => [
			{ Header: 'Código', accessor: 'code' },
			{ Header: 'Nome', accessor: 'name', sortType: sortName },
			{ Header: 'Código Legado', accessor: 'legacyId' },
			{ Header: '', accessor: 'button', disableSortBy: true }
		],
		[]
	)

	function handleSearchOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.name, value) ||
				include(item.legacyId, value) ||
				include(item.code, value)
			)
		})

		setTableData(list)
	}

	function handleSetTableData() {
		if (!tableData.length) {
			setTableData(data.map((item) => item))
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleSetTableData, [])

	async function getCompanies() {
		try {
			setIsLoading(true)
			const response = await fetchCompanies()

			if (!response.length) return

			setResales(response)

			const data = response.map((resale, i) => ({
				code: resale.SalesOrganizationID,
				name: resale.Name,
				legacyId: resale.LegacyID,
				button: TableButton(
					() => handleParamNavigation(i, response),
					FileIcon
				)
			}))

			setData(data)
			setTableData(data)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	const reflectLocalResales = () => {
		dispatch(setStoreResales(resales))
	}

	const handleParamNavigation = (index: number, resales: IResaleItem[]) => {
		const resale = resales[index]

		dispatch(setStoreResale(resale))

		history.push(AdminRoutesEnum.RESALE_PARAMETERS)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(reflectLocalResales, [resales])
	useEffect(() => {
		;(async () => {
			await getCompanies()
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const viewProps: IViewProps = {
		handleSearchOnChange,
		handleParamNavigation,
		tableData,
		columns,
		isLoading
	}

	return createElement(ResaleList, viewProps)
}

export default ResaleListContainer
