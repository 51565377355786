import React, { ButtonHTMLAttributes, FC } from 'react'

import DotsIcon from '../../assets/images/dots.svg'

import * as S from './styles'

interface ISeeRuleParametersButtonProps
	extends ButtonHTMLAttributes<HTMLButtonElement> {}

const SeeRuleParametersButton: FC<ISeeRuleParametersButtonProps> = ({
	...props
}) => {
	return (
		<S.ButtonDetailsRule {...props}>
			<S.Icon src={DotsIcon} />
		</S.ButtonDetailsRule>
	)
}

export default SeeRuleParametersButton
