import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { returnsRemainingDays } from '../../shared/utils/date'
import { IChallengeCartItemBlockOfProductsProps } from './types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ChipTypeEnum } from '../ChipWithInformationAboutTheChallenge/types'
import ChipWithInformationAboutTheChallenge from '../ChipWithInformationAboutTheChallenge'
import { Creators as CartChallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'

import * as S from './styles'

import {
	calcTotalQuantity,
	calcTotalAmountReachedWithItemsInCart,
	calcTotalAmountAchievedWithPurchasedItems
} from '../../shared/utils/challengesUtils'

import { ICartItem } from '../../shared/interfaces/cart'

import { ChallengeRuleTypeEnum } from '../../shared/interfaces/pointsProgramChallenge'

import ChallengeProgressBar from '../ChallengeProgressBar'

import { ReactComponent as Trash } from '../../assets/images/trash.svg'
import CartTable from '../../shared/components/CartTable'

export default function ChallengeCartItemBlockOfProducts(
	props: IChallengeCartItemBlockOfProductsProps
) {
	const { cartchallenge, isCheckoutScreen } = props

	const { cartChallengeItems } = useTypedSelector(['cartChallengeItems'])

	const [quantityReached, setQuantityReached] = useState(0)
	const [remainingProducts, setRemainingProducts] = useState(0)

	const [
		totalProductsToCompleteTheChallenge,
		setTotalProductsToCompleteTheChallenge
	] = useState(25)

	const dispatch = useDispatch()

	const checkingTheAmountOfProductsLeftToCompleteTheChallenge = () => {
		if (!cartchallenge.products?.length) return

		const challengeFoundedInTheCart = cartChallengeItems.find(
			(cartChallenge) =>
				cartChallenge.challengeId === cartchallenge.challengeId
		)

		const { products } = cartchallenge

		const totalQuantity = calcTotalQuantity(products)

		const addedProducts: ICartItem[] = challengeFoundedInTheCart
			?.addedProducts.length
			? challengeFoundedInTheCart.addedProducts
			: []

		if (cartchallenge.typeRule === ChallengeRuleTypeEnum.PRODUCT_MIX) {
			setRemainingProducts(0)
			setQuantityReached(totalQuantity)
			setTotalProductsToCompleteTheChallenge(totalQuantity)
			return
		}

		const achievedAmountOfPurchasedItems =
			calcTotalAmountAchievedWithPurchasedItems(products)

		const quantityOfProductsLeftToBuy =
			totalQuantity - achievedAmountOfPurchasedItems

		const quantityReachedWithItemsInTheCart =
			calcTotalAmountReachedWithItemsInCart(
				addedProducts,
				cartchallenge.products
			)

		const totalItemsLeft =
			quantityOfProductsLeftToBuy >= quantityReachedWithItemsInTheCart
				? quantityOfProductsLeftToBuy -
				  quantityReachedWithItemsInTheCart
				: 0

		const amountAlreadyReached =
			achievedAmountOfPurchasedItems + quantityReachedWithItemsInTheCart <
			totalQuantity
				? achievedAmountOfPurchasedItems +
				  quantityReachedWithItemsInTheCart
				: totalQuantity

		setRemainingProducts(totalItemsLeft)
		setQuantityReached(amountAlreadyReached)
		setTotalProductsToCompleteTheChallenge(totalQuantity)
	}

	const removeCartChallenge = () => {
		dispatch(
			CartChallengeItemsActions.removeChallengeAndAllItems(cartchallenge)
		)
	}

	useEffect(checkingTheAmountOfProductsLeftToCompleteTheChallenge, [
		cartchallenge,
		cartChallengeItems
	])

	return (
		<S.ChallengeCartItemBlockOfProductsWrapper>
			<S.ChallengeCartItemBlockHeader>
				<section
					style={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}>
					<S.BedgesWrapper>
						<ChipWithInformationAboutTheChallenge
							type={ChipTypeEnum.SHOW_REMAINING_DAYS}
							label={`Faltam ${returnsRemainingDays(
								String(cartchallenge.endDate)
							)} Dias`}
						/>
						<ChipWithInformationAboutTheChallenge
							beingDisplayedInSomePurchaseFlow={isCheckoutScreen}
							type={ChipTypeEnum.SHOW_POINTS_EARNED}
							label={`${Number(
								cartchallenge.score
							).toLocaleString()} Pontos`}
						/>
					</S.BedgesWrapper>
					{cartchallenge.typeRule ===
						ChallengeRuleTypeEnum.PRODUCT_MIX &&
					!isCheckoutScreen ? (
						<S.RemoveChallengeItemsButton
							onClick={removeCartChallenge}>
							<Trash />
						</S.RemoveChallengeItemsButton>
					) : null}
				</section>
				<S.ChallengeTitle>{cartchallenge.title}</S.ChallengeTitle>
				<S.ProgressBarWrapper>
					<ChallengeProgressBar
						quantityReached={quantityReached}
						totalQuantity={totalProductsToCompleteTheChallenge}
					/>
				</S.ProgressBarWrapper>
				<S.ChallengeInfoText>
					{!!remainingProducts && (
						<S.RemainingProductsText>
							{remainingProducts}{' '}
							{remainingProducts === 1 ? 'Restante' : 'Restantes'}
						</S.RemainingProductsText>
					)}{' '}
					{!!remainingProducts
						? 'Para completar o desafio'
						: 'TODOS OS PRODUTOS SELECIONADOS'}
				</S.ChallengeInfoText>
			</S.ChallengeCartItemBlockHeader>
			<S.ChallengeProductsWrapper>
				<CartTable
					isChallengeMix={
						cartchallenge.typeRule ===
						ChallengeRuleTypeEnum.PRODUCT_MIX
					}
					isChallengeItems={true}
					cartChallengeItem={cartchallenge}
					isEditable={!isCheckoutScreen ? true : false}
					tableData={cartchallenge.addedProducts}
				/>
			</S.ChallengeProductsWrapper>
		</S.ChallengeCartItemBlockOfProductsWrapper>
	)
}
