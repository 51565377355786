import styled from 'styled-components'

interface StyledNotificationTypes {
	type: 'success' | 'error' | 'info'
	isPortalAdmin?: boolean
	isComboFlex?: boolean
}

export const NotificationWrapper = styled.div<StyledNotificationTypes>`
	display: flex;
	justify-content: left;
	align-items: center;
	background: ${(props) =>
		props.type === 'error'
			? props.theme.palette.primary.light
			: props.type === 'success'
			? props.theme.palette.success.light
			: props.theme.palette.warning.light};
	box-shadow: ${(props) =>
		props.type === 'error' || props.type === 'info'
			? 'none'
			: '0px 2px 7px 0px #00000040'};
	width: 100%;
	height: ${(props) =>
		props.isPortalAdmin
			? '3rem'
			: props.type === 'error' || props.type === 'info'
			? '3rem'
			: '8rem'};
	border-radius: 0.3rem;
	padding-left: 1rem;
	gap: 1rem;
	padding: ${(props) => (props.isComboFlex ? '16px' : '0px')};
`

export const NotificationText = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
	text-align: left;
`

export const NotificationIconWrapper = styled.div<StyledNotificationTypes>`
	svg {
		width: 1.5rem;
		height: 1.5rem;
		color: ${(props) =>
			props.type === 'error'
				? props.theme.palette.primary.main
				: props.type === 'success'
				? props.theme.palette.success.main
				: props.theme.palette.warning.main};
	}
`
