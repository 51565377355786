import styled from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const ViewInput = styled.div`
	max-width: max-content;
`
export const StyledInputContainer = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
`

export const PageHeader = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px 0px; 
	gap: 10px;

	a.go-back-button {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			height: 16px;
			width: 16px;
		}

		span {
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			color: ${colors.red};
			margin-left: 8px;
		}
	}

	h1 {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${colors.secondGrey};
	}
	p {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		color: ${colors.secondGrey};
	}
`

export const DividerSimple = styled.div`
	margin-top: 8px;
	margin-bottom: 8px;
	border-bottom: 1px solid ${colors.lightGrey};
`
export const DividerContent = styled.div`
	margin-top: 30px;
	margin-bottom: 30px;
	border-bottom: 1px dotted ${colors.lightGrey};
`
