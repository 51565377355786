import { store } from '../../../shared/store'
import { getLocalizationAccess } from '../../../shared/utils/param'
import { CustomerRoutesEnum } from '../../Routes/customerRoutesEnum'

export function isCustomerPointsProgramRoutes(routeKey: string) {
    return routeKey.indexOf('points-program') > -1
}

export function enabledProgramPointRoute(
    routeKey: string,
    userJoinedTheProgram: boolean
) {
    const routes: string[] = [
        CustomerRoutesEnum.POINTS_PROGRAM__POINTS_REDEMPTION,
        CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGES,
    ]
    return routes.includes(routeKey) ? userJoinedTheProgram : true
}

export function canShowOrderHistoryAndComboPages(routeKey: string) {
    const routes: string[] = [
        CustomerRoutesEnum.ORDER_HISTORY,
        CustomerRoutesEnum.COMBO_LIST
    ]
    const companyParams = store.getState().companyParams
    const locationHasService = getLocalizationAccess(companyParams)

    return routes.includes(routeKey) ? locationHasService : true
}
