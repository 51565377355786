/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react'
import ComboCard from '../../../components/ComboCard'
import { IComboListProps } from './types'
import ComboFlex from '../../../components/combo-flex/combo-flex.component'

function ComboListSection(props: IComboListProps) {
	return (
		<Fragment>
			<div className="card-list">
				{props.data.length ? (
					props.data.map((combo, x) =>
						combo.isComboFlex ? (
							<a key={x} className="card-item-wrapper">
								<ComboFlex combo={combo} />
							</a>
						) : (
							<a
								key={x}
								onClick={(e) => props.handleSelectCombo(combo)}
								className="card-item-wrapper">
								<ComboCard data={combo} />
							</a>
						)
					)
				) : (
					<>
						<div className="no-product-placeholder-wrapper">
							<h4>Nenhum Combo Encontrado...</h4>
						</div>
					</>
				)}
			</div>
		</Fragment>
	)
}

export default ComboListSection
