import React from 'react'

import { IChipCouponProps } from './types'

import * as S from './styles'

export default function ChipCouponStatus(props: IChipCouponProps) {
	const { children, status } = props

	return (
		<S.ChipLabel status={status}>
			{children} {status}
		</S.ChipLabel>
	)
}
