interface IBlockFields {
	currentField: number
	resaleLength: number
	stateLength: number
	cityLength: number
	channelLength: number
	pdvsLength: number
	isCd: boolean
	isFilter?: boolean
}
export function blockFields({
	currentField,
	resaleLength,
	stateLength,
	cityLength,
	channelLength,
	pdvsLength,
	isCd,
	isFilter
}: IBlockFields) {
	if (isCd && isFilter) {
		return false
	} else if (isCd && !isFilter) {
		return (
			currentField === 0 &&
			(stateLength > 0 ||
				cityLength > 0 ||
				channelLength > 0 ||
				pdvsLength > 0)
		)
	} else {
		return (
			(currentField > 0 && resaleLength > 0) ||
			(currentField === 0 && stateLength > 0) ||
			cityLength > 0 ||
			channelLength > 0 ||
			pdvsLength > 0
		)
	}
}
