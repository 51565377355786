import styled from 'styled-components'
import { Typography } from '@mui/material'

export const TitlePageWrapper = styled(Typography)`
	&& {
		font-weight: ${(props) => props.theme.font.weight.medium};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${(props) => props.theme.palette.gray.dark};
		width: max-content;
	}
`
