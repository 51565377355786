import { z } from 'zod'
import { IOption } from '../../../../../components/filters-by-hieararchy/typings'

export function verifyFiltersByHieararchy(
	filtersByHieararchy: {
		director?: IOption[]
		grc?: IOption[]
		cd?: IOption[]
		state?: IOption[]
		city?: IOption[]
		channel?: IOption[]
	},
	ctx: z.RefinementCtx
) {
	const keys = Object.keys(
		filtersByHieararchy
	) as (keyof typeof filtersByHieararchy)[]
	if (keys.every((key) => filtersByHieararchy[key]?.length === 0)) {
		ctx.addIssue({
			code: 'custom',
			path: ['filtersByHieararchy'],
			message: 'Filtros por Hierarquia são obrigatórios'
		})
	}
}
