import { endOfMonth, startOfToday } from 'date-fns'
import { RangeModifier } from 'react-day-picker'
import {
    IHeaderRulePoint,
    IItemRulePointScoreRate,
    IItemRulePointScoreValue,
} from '../../shared/interfaces/pointsProgramRules'
import { defaultScoreValueOptions } from '../../shared/utils/points'

interface IRuleModelProps {
    rule?: IHeaderRulePoint
    settings?: ISettings
}

interface ISettings {
    canChangeCustomPointRatio?: boolean
    isInitialization?: boolean
}

export class RuleModel {
	private observation: string = ''
	private scoreValue: string = ''
	private initialDate: string = startOfToday().toString()
	private finalDate: string = endOfMonth(new Date()).toString()
	private itemsRulePointScoreValue: IItemRulePointScoreValue[] = []
	private itemsRulePointScoreRate: IItemRulePointScoreRate[] = []

	private canChangeCustomPointRatioValue: boolean = false

    private newInstance(customSettings?: ISettings) {
        const settings = customSettings || {
            canChangeCustomPointRatio: this.canChangeCustomPointRatioValue
        }
        return new RuleModel({ rule: this.ruleData, settings: settings })
    }

    public get ruleData(): Required<Omit<IHeaderRulePoint, 'ruleID' | 'filtersRulePoint' | 'status' | 'createdAt' | 'autoIncRuleId' | 'ruleRegistrationDate'>> {
        return {
            observation: this.observation,
            scoreValue: this.scoreValue,
            initialDate: this.initialDate,
            finalDate: this.finalDate,
            itemsRulePointScoreValue: this.itemsRulePointScoreValue,
            itemsRulePointScoreRate: this.itemsRulePointScoreRate,
        }
    }

    public get canChangeCustomPointRatio() {
        return this.canChangeCustomPointRatioValue
    }

    public get changeCheckboxCustomPointRatio() {
        const settings = {
            canChangeCustomPointRatio: !this.canChangeCustomPointRatioValue
        }
        return this.newInstance(settings)
    }

    public changeObservation(value: string) {
        this.observation = value

        return this.newInstance()
    }

    public changePointRatio(value: string) {
        if (this.canChangeCustomPointRatio) {
            this.scoreValue = `1:${value}`
        } else {
            this.scoreValue = value
        }

        return this.newInstance()
    }

    public changePeriod({ from, to }: RangeModifier) {
        if (from) {
            this.initialDate = from.toString()
        }
        if (to) {
            this.finalDate = to.toString()
        }

        return this.newInstance()
    }

    public changeItemsRulePointScoreValue(items: string[]) {
        this.itemsRulePointScoreValue = items.map(item => ({ productID: item }))

        return this.newInstance()
    }

    public changeItemsRulePointScoreRate(items: string[]) {
        this.itemsRulePointScoreRate = items.map(item => {
            const alreadyAddedItem = this.itemsRulePointScoreRate.find(i => i.productID === item)
            return {
                productID: item,
                scoreRate: alreadyAddedItem?.scoreRate || '0'
            }
        })

        return this.newInstance()
    }

    public changeProductScoreRate(productID: string, scoreRate: string) {
        this.itemsRulePointScoreRate = this.itemsRulePointScoreRate.map(item => {
            if (item.productID === productID) {
                return { ...item, scoreRate }
            }
            return item
        })

        return this.newInstance()
    }

    constructor({ rule, settings }: IRuleModelProps) {
        this.observation = rule?.observation || ''
        this.scoreValue = rule?.scoreValue || ''
        this.itemsRulePointScoreValue = rule?.itemsRulePointScoreValue || []
        this.itemsRulePointScoreRate = rule?.itemsRulePointScoreRate || []
        if (!!settings?.isInitialization) {
            this.initialDate = rule?.initialDate ? new Date(`${rule.initialDate}T12:00:00`).toString() : startOfToday().toString()
            this.finalDate = rule?.finalDate ? new Date(`${rule.finalDate}T12:00:00`).toString() : endOfMonth(new Date()).toString()
        } else {
            this.initialDate = rule?.initialDate || startOfToday().toString()
            this.finalDate = rule?.finalDate || endOfMonth(new Date()).toString()
        }

        if (
            settings?.isInitialization &&
            rule?.scoreValue &&
            !defaultScoreValueOptions.find(value => value === rule.scoreValue)
        ) {
            this.canChangeCustomPointRatioValue = true
        } else {
            this.canChangeCustomPointRatioValue = !!settings?.canChangeCustomPointRatio
        }
    }
}