type IProductList = {
	options: {
		value: string
		label: string
		package: string
		categoryID: string
		brand?: string | undefined
	}[]
	label: string
}[]

export function formatProductList(productsList: IProductList) {

	const mappedProductDetails = productsList
		.map((item) =>
			item.options
				.map((option) => ({
					brand: option.brand,
					categoryId: option.categoryID,
					package: option.package
				}))
				.filter(
					(value, index, self) =>
						index ===
						self.findIndex(
							(o) =>
								o.brand === value.brand &&
								o.categoryId === value.categoryId &&
								o.package === value.package
						)
				)
		)
		.flat()

	return mappedProductDetails
}
