import styled from 'styled-components'
import { RatingButtonStyledProps } from './rating-buttons.types'

export const ButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
`

export const ButtonStyled = styled.button<RatingButtonStyledProps>`
	border-radius: 37px;
	border: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
	background: ${(props) =>
		props.isActive
			? props.theme.palette.primary.main
			: props.theme.palette.background.paper};
	width: 32px;
	height: 32px;

	color: ${(props) =>
		props.isActive
			? props.theme.palette.background.paper
			: props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};

	&:hover {
		cursor: pointer;
	}
`

// ${(props) => props.theme.palette.primary.main}
