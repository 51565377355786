export interface ICustomer {
	BirthDate: string
	CellphoneNumber: string
	ChannelGroupDescription: string
	ChannelGroupID: string
	City: string
	CompanyFiscalID: string
	CustomerID: string
	DeliveryDate: string
	Email: string
	HouseNumber: string
	Name: string
	Neighborhood: string
	NickName: string
	PaymentConditions: Array<IPaymentConditions>
	PersonalFiscalID: string
	PhoneNumber: string
	PostalCode: string
	Region: string
	SalesOrganizationID: string
	SegmentDescription: string
	SegmentID: string
	Street: string
	DocumentNumber: string
	sectorID: string
	SalesOrganizationName: string
	SalesOrganizationOpeningHours: { Start: string; End: string }
	SalesOrganizationPhoneNumber: string
	Password?: string
	createdAt?: string
	// isNewCustomer?: boolean
	draftBeer?: boolean

	questionOne?: string
	answerOne?: string
	questionTwo?: string
	answerTwo?: string
	alternativeEmail?: string
	alternativePhone?: string
	typeOfEstablishment?: string
	tradeRepresentative?: boolean
}

export interface IUser {
	Type?: UserTypeEnum
	companies?: Array<{ salesOrganizationID: string; Name?: string }>
	activeSalesOrganizationID: string
}

export enum UserTypeEnum {
	CUSTOMER = 'CUSTOMER',
	ADMIN = 'ADMIN',
	SAC = 'SAC',
	PRICING = 'PRICING',
	UNIDADE = 'UNIDADE'
	// '' = '',
}

export interface IPaymentConditions {
	PaymentMethod: string
	PaymentMethodDescription: string
	PaymentTerms: Array<IPaymentTerms> | IPaymentTerms
}

export interface IPaymentTerms {
	PaymentTerms: string
	PaymentTermsDescription: string
	TaxRate?: number
}
export interface IFormattedPaymentCondition {
	PaymentMethod: string
	PaymentMethodDescription: string
	PaymentTerms: string
	PaymentTermsDescription: string
	TaxRate?: number
}

export interface INotificationProps {
	title: string
	body: string
}
