import React from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { makeStyles } from '@material-ui/core/styles'

import { DataSelectorListProps } from './data-selector-list.types'

export function DataSelectorList({
	title,
	list,
	handleChange,
	selectedOption,
	backgroundColor = 'transparent',
	isDisabled = false,
	dataSelectorListID = 'demo-simple-select-label',
	isNative = true
}: DataSelectorListProps) {
	const useStyles = makeStyles({
		select: {
			'&:after': {
				borderBottomColor: !isDisabled ? '#CE332E' : '#ffff'
			},
			'& .MuiSvgIcon-root': {
				color: !isDisabled ? '#CE332E' : '#ffff'
			}
		},
		disabled: {
			backgroundColor: '#B7B7B7',
			color: '#ffff',
			'&:hover': {
				cursor: 'not-allowed'
			}
		}
	})

	const classes = useStyles()
	return (
		<FormControl fullWidth disabled={isDisabled}>
			<InputLabel
				style={
					!isDisabled
						? { color: 'var(--red-10, #CE332E)' }
						: { color: '#ffff' }
				}>
				{title}
			</InputLabel>
			<Select
				data-testid="data-selector-material-ui"
				style={{ backgroundColor: backgroundColor }}
				native={isNative}
				labelId="demo-simple-select-label"
				id={dataSelectorListID}
				value={selectedOption.value}
				label={title}
				className={classes.select}
				onChange={handleChange}
				classes={{ disabled: classes.disabled }}
				sx={{
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: '#CE332E',
						borderWidth: 'thin'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: '#CE332E',
						borderWidth: 'thin'
					}
				}}>
				{list.map((item, index) => (
					<MenuItem
						key={index}
						value={item.value}
						data-testid={`data-selector-material-ui-item-${index}`}>
						{item.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
