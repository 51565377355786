import axiosInstance from './axiosInstace'

export async function fetchPointsProgramTerms() {
	const response = await axiosInstance.get(`/program-points/terms`)
	return response.data
}

export async function checkAdoptionByCustomer(customerId: string) {
	const response = await axiosInstance.get(
		`/program-points/adoption-by-customer?customerID=${customerId}`
	)
	return response.data
}

export async function adoptPointsProgram(
	customerId: string,
	salesOrganizationId: string
): Promise<{ earnedPoints: string }> {
	const response = await axiosInstance.post(
		`/program-points/adoption-by-customer?customerID=${customerId}&salesOrganizationID=${salesOrganizationId}`
	)
	return response.data
}

export async function getPointsExtracts(customerID: string) {
	const response = await axiosInstance.get(
		`/program-points/extract?customerID=${customerID}`
	)
	return response.data
}
