import { createTheme } from '@mui/material/styles'

export const colorThemeProvider = {
	primaryColor: '#CE332E',
	primary: {
		main: '#CE332E',
		contrastText: '#ffffff'
	},
	secondaryColor: '#B2231C',
	secondary: {
		main: '#ffffff',
		contrastText: '#CE332E'
	},
	errorColor: '#962BA0',
	primaryLight: '#ffcccc',
	successLight: '#effaef',
	purpleColor: '#F1E7FF',
	warningColor: '#FFC31D',
	warningLightColor: '#FEF8E4',
	successColor: '#67AF5C',
	extrasColor: '#D8D2D3',
	strokeDark: '#979e97',
	blackColor: '#333333',
	grayColor: '#6F6F6F',
	lightGrayColor: '#B7B7B7',
	primaryWhiteColor: '#F9F9F9',
	primaryWhiteColorContrasct: '#3D3D3D',
	whiteColor: '#FFFFFF',
	actionColor: '#B7B7B7',
	fourthGrey: '#D1D1D1',
	returnable: {
		main: '#0CA6BF',
		light: '#e0f6fa',
		dark: '#0892a5',
		contrastText: '#ffffff'
	},
	background: {
		default: '#F9F9F9',
		paper: '#ffffff'
	},
	alert: {
		main: '#f79239',
		contrastText: '#ffffff'
	},
	icon: {
		success: '#67af5c26'
	},
	borderContrast: '#eee',
	lightBorder: {
		main: '#eeeeee'
	},
	grayDark: '#5D615D',
	gradientPrimary: 'linear-gradient(90deg, #C3192C 9.37%, #C40F3A 26.68%, #C10D43 45.76%, #B70750 72.83%, #A70051 98.13%)'
}

export const FONT_WEIGHT_REGULAR = '400'
export const FONT_WEIGHT_MEDIUM = '500'
export const FONT_WEIGHT_SEMIBOLD = '600'
export const FONT_WEIGHT_BOLD = '700'
export const FONT_WEIGHT_EXTRA_BOLD = '800'

export const typographyTheme = {
	family: 'Inter',
	// font-size is using rem
	size: {
		DISPLAY_2XL: {
			size: 4.5, //72px
			line_height: 5.625 //90px
		},
		DISPLAY_XL: {
			size: 3.75, //60px
			line_height: 4.5 //72px
		},
		DISPLAY_LG: {
			size: 3, //48px
			line_height: 3.75 //60px
		},
		DISPLAY_MD: {
			size: 2.25, //36px
			line_height: 2.75 //44px
		},
		DISPLAY_SM: {
			size: 1.875, //30px
			line_height: 2.375 //38px
		},
		DISPLAY_XS: {
			size: 1.5, //24px
			line_height: 2 //32px
		},
		TEXT_XL: {
			size: 1.25, //20px
			line_height: 1.875 //30px
		},
		TEXT_LG: {
			size: 1.125, //18px
			line_height: 1.75 //28px
		},
		TEXT_MD: {
			size: 1, //16px
			line_height: 1.5 //24px
		},
		TEXT_SM: {
			size: 0.875, //14px
			line_height: 1.25 //20px
		},
		TEXT_XS: {
			size: 0.75, //12px
			line_height: 1.125 //18px
		},
		TEXT_XSM: {
			size: 0.625, //10px
			line_height: 1 //14px
		},
		TEXT_XXL: {
			size: 1.5, //24px
			line_height: 2.25 //36px
		}
	},
	weight: {
		regular: FONT_WEIGHT_REGULAR,
		medium: FONT_WEIGHT_MEDIUM,
		semibold: FONT_WEIGHT_SEMIBOLD,
		bold: FONT_WEIGHT_BOLD,
		extraBold: FONT_WEIGHT_EXTRA_BOLD
	}
}

export const SPACING_XXLG = 64 as const
export const SPACING_XLG = 42 as const
export const SPACING_LG = 32 as const
export const SPACING_MD = 24 as const
export const SPACING_SM = 16 as const
export const SPACING_XSM = 8 as const
export const SPACING_XXSM = 4 as const
export const SPACING_XXXSM = 2 as const

export const theme = {
	font: typographyTheme,
	palette: {
		primary: {
			main: colorThemeProvider.primaryColor,
			mainGradient: colorThemeProvider.gradientPrimary,
			light: colorThemeProvider.primaryLight
		},
		secondary: {
			main: colorThemeProvider.secondaryColor
		},
		error: {
			main: colorThemeProvider.errorColor
		},
		warning: {
			main: colorThemeProvider.warningColor,
			light: colorThemeProvider.warningLightColor
		},
		success: {
			main: colorThemeProvider.successColor,
			contrastText: colorThemeProvider.whiteColor,
			light: colorThemeProvider.successLight
		},
		extras: {
			stroke: {
				main: colorThemeProvider.extrasColor,
				dark: colorThemeProvider.strokeDark,
				contrastText: colorThemeProvider.whiteColor
			},
			returnable: {
				...colorThemeProvider.returnable
			},
			alert: {
				...colorThemeProvider.alert
			}
		},
		black: {
			main: colorThemeProvider.blackColor,
			contrastText: colorThemeProvider.whiteColor
		},
		gray: {
			main: colorThemeProvider.grayColor,
			contrastText: colorThemeProvider.whiteColor,
			light: colorThemeProvider.fourthGrey,
			dark: colorThemeProvider.grayDark
		},
		lightGray: {
			main: colorThemeProvider.lightGrayColor,
			contrastText: colorThemeProvider.whiteColor
		},
		primaryWhite: {
			main: colorThemeProvider.primaryWhiteColor,
			contrastText: colorThemeProvider.primaryWhiteColorContrasct
		},
		purple: {
			main: colorThemeProvider.purpleColor
		},

		white: {
			main: colorThemeProvider.whiteColor,
			contrastText: colorThemeProvider.primaryWhiteColorContrasct
		},
		action: {
			disabledBackground: colorThemeProvider.actionColor,
			disabled: colorThemeProvider.whiteColor
		},
		background: {
			...colorThemeProvider.background
		},
		icon: {
			sucess: colorThemeProvider.icon.success
		},
		borderContrast: {
			main: colorThemeProvider.borderContrast
		},
		lightBorder: {
			main: colorThemeProvider.lightBorder.main
		}
	},
	breakpoints: {
		values: {
			xs: 360,
			sm: 700,
			md: 992,
			lg: 1200,
			xl: 1440
		}
	},
	typography: {
		allVariants: {
			fontFamily: 'Inter'
		}
	},
	spacingOptions: {
		XXLG: SPACING_XXLG,
		XLG: SPACING_XLG,
		LG: SPACING_LG,
		MD: SPACING_MD,
		SM: SPACING_SM,
		XSM: SPACING_XSM,
		XXSM: SPACING_XXSM,
		XXXSM: SPACING_XXXSM
	}
} as const

export default createTheme(theme)
