export enum Contacts {
	EMAIL = 'email',
	CELL = 'cell'
}

export interface IGetRequiresRegistrationUpdate {
	personalFiscalID?: string
	companyFiscalID?: string
	fieldsToCheck: string
}
