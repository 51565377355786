import React from 'react'

import { FormHelperText } from '@mui/material'

export type ErrorTextProps = {
	error?: string
}

export default function ErrorText({ error }: ErrorTextProps) {
	if (!error) return null

	return (
		<FormHelperText sx={{ color: (theme) => theme.palette.primary.main }}>
			{error}
		</FormHelperText>
	)
}
