import React from 'react'
import { IPreviewPhone } from './preview-phone.types'
import * as S from './previre-phone.styles'
import Notification from './notification/notification'

const PreviewPhone = ({
	isSelectedData,
	titleNotification,
	messageNotification,
	imageNotification
}: IPreviewPhone) => {

	return (
		<S.PreviewPhoneWrapper>
			{isSelectedData ? (
				// Quando tem valores para ser exibido
				<S.PreviewPhoneInsideWrapper>
					<div className="header-phone">
						<div className="rectangle"></div>
						<div className="circle"></div>
						<div></div>
					</div>
					<Notification
						isSelectedData={isSelectedData}
						titleNotification={titleNotification}
						messageNotification={messageNotification}
						srcImg={imageNotification}
					/>
				</S.PreviewPhoneInsideWrapper>
			) : (
				// Caso não tenha valores selecionados
				<S.PreviewPhoneInsideDefault>
					<Notification isSelectedData={isSelectedData} />
				</S.PreviewPhoneInsideDefault>
			)}
		</S.PreviewPhoneWrapper>
	)
}

export default PreviewPhone
