export enum UserGroupEnum {
	ADMIN = 'ADMIN',
	CUSTOMER = 'CUSTOMER',
	SAC = 'SAC',
	PRICING = 'PRICING',
	UNIDADE = 'UNIDADE',
	CD = 'CD'
}

export interface ISacUser {
	user: string
	groupId: string
	group: UserGroupEnum
	salesOrganizationID?: string
	salesOrganizationsIDs?: string[]
	companies?: ICompanie[]
}

export interface ICompanie {
	salesOrganizationID: string
}

export interface IUserGroup {
	ID: string
	name: UserGroupEnum
	description: string
	visualizationName: string | null
}

export interface IDeleteUserPayload {
	user: string
	groupID: string
	logicalDelete: 'X' | ''
	salesOrganization?: {
		salesOrgId: string
		logicalDelete: 'X' | ''
	}
}
