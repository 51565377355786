import React from 'react'

import CartTable from '../../../../shared/components/CartTable'
import CloseButton from '../../../../components/CloseButton'
import CustomSlider from '../../../../components/CustomSlider'
import Modal from '../../../../components/Modal'
import { reverseDateHuman } from '../../../../shared/utils/date'
import { IViewProps } from './types'

function CancellationRequestModal(props: IViewProps): JSX.Element {
	const {
		isActive,
		isLoading,
		request,
		sliderState,
		calcTotalOrder,
		closeModal,
		handleOnChangeSlider,
		tableData
	} = props

	return (
		<Modal isActive={isActive}>
			<div className="cancellation-modal">
				<div className="header">
					<h2 className="modal-title">Detalhes da solicitação</h2>
					<CloseButton onClick={closeModal} />
				</div>

				<span className="order-number">
					ID do Pedido: {String(request.AutoIncBackendID)}
				</span>

				<h1 className="modal-section-title">Status de Atendimento</h1>
				<div className="slider-container">
					<div className="slider-wrapper">
						<CustomSlider
							marks
							value={sliderState}
							step={1}
							min={0}
							max={2}
							onChangeCommitted={handleOnChangeSlider}
							disabled={isLoading || sliderState === 2}
						/>
					</div>

					<div className="steps">
						<div className="step">Recebida</div>
						<div className="step">Em Atendimento</div>
						<div className="step">Atendida</div>
					</div>
				</div>

				<div className="info">
					<div className="order-details-wrapper">
						<h1 className="modal-section-title">
							Dados do Pedido e Revenda
						</h1>
						<div className="order-details">
							<div className="item big-space">
								<div className="name">Data de Entrega:</div>
								<div className="value">
									{reverseDateHuman(
										request.SalesOrderDeliveryDate
									)}
								</div>
							</div>
							<div className="item">
								<div className="name">Revenda:</div>
								<div className="value">
									{request.SalesOrganizationName}
								</div>
							</div>
							<div className="item">
								<div className="name">ID da Revenda:</div>
								<div className="value">
									{request.SalesOrganizationID}
								</div>
							</div>
							<div className="item big-space">
								<div className="name">
									ID da Revenda Legado:
								</div>
								<div className="value">
									{request.SalesOrderLegacyID}
								</div>
							</div>

							<div className="item">
								<div className="name">Total:</div>
								<div className="value">
									{calcTotalOrder(request.SalesOrderItems)}
								</div>
							</div>
						</div>
					</div>
					<div className="other-data-wrapper">
						<h1 className="modal-section-title">
							Dados do Cliente
						</h1>
						<div className="other-data">
							<div className="item big-space">
								<div className="name">Destinatário:</div>
								<div className="value">
									{request.CustomerName}
								</div>
							</div>
							<div className="item">
								<div className="name">Endereço:</div>
								<div className="value">{request.Street}</div>
							</div>
							<div className="item">
								<div className="name">Bairro:</div>
								<div className="value">
									{request.Neighborhood}
								</div>
							</div>
							<div className="item big-space">
								<div className="name">Cidade/Estado:</div>
								<div className="value">
									{`${request.City}/${request.Region}`}
								</div>
							</div>
							<div className="item">
								<div className="name">CEP:</div>
								<div className="value">
									{request.PostalCode}
								</div>
							</div>
						</div>
					</div>
				</div>

				<h1 className="modal-section-title">Informações do Pedido</h1>
				<div className="order-table-container">
					<CartTable tableData={tableData} isEditable={false} />
				</div>
			</div>
		</Modal>
	)
}

export default CancellationRequestModal
