import styled from "styled-components"

export const OrderModalTitle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	span {
		color: ${props => props.theme.palette.gray.main}
	}
`

export const OrderModalSubtitle = styled.h2`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.375rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
`

export const OrderDeliveryDetailsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	text-transform: capitalize;

	color: ${(props) => props.theme.palette.lightGray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size};
	font-weight: ${(props) => props.theme.font.weight.medium};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height};
	letter-spacing: 0em;
	text-align: left;
	margin: 1rem 0;
	width: auto;

	span {
		text-align: right;
		max-width: 300px;
		color: ${(props) => props.theme.palette.black.main};
	}
`

export const OrderIndividualProductsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: ${(props) => props.theme.palette.lightGray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height};
	letter-spacing: 0em;
	text-align: left;
	margin: 1rem 0;
	width: auto;

	span {
		color: ${(props) => props.theme.palette.black.main};
	}
`

export const OrderIndividualProductsDetails = styled(OrderIndividualProductsWrapper)`
	gap: 0.5rem;
`

export const FlagQuantityItems = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 1rem;
	height: 1rem;
	border-radius: 1.125rem;
	background-color: ${(props) => props.theme.palette.primary.main};

	span {
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		color: ${(props) => props.theme.palette.white.main};
	}

`
export const RemovedProductTitle = styled(OrderModalSubtitle)`
	color: ${(props) => props.theme.palette.error.main};
`

export const RemovedProductFlag = styled(FlagQuantityItems)`
	background-color: ${(props) => props.theme.palette.error.main};
`