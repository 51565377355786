export enum CompanyParamNames {
	VAL_MIN_PEDIDO = 'VAL_MIN_PEDIDO',
	VAL_MIN_PEDIDO_BOLETO = 'VAL_MIN_PEDIDO_BOLETO',
	FORMA_PAG_BOLETO = 'FORMA_PAG_BOLETO',
	HORARIO_ATEND_UND = 'HORARIO_ATEND_UND',
	HABILITA_VENDA_BOM_PARCEIRO = 'HABILITA_VENDA_BOM_PARCEIRO',
	QUANTIDADE_PONTO_ADESAO = 'QUANTIDADE_PONTO_ADESAO',
	LIBERA_PROGRAMA_PONTOS = 'LIBERA_PROGRAMA_PONTOS',
	DIAS_PARA_APROVAR_CLIENTE = 'DIAS_PARA_APROVAR_CLIENTE',
}

export type CompanyParamNamesEnum = keyof typeof CompanyParamNames
