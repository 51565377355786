import React, { useEffect, useMemo, useState } from 'react'

import InputQuantityLabel from '../../../../components/InputQuantityLabel'
import TitleTypeOfProduct from '../../../../components/TitleTypeOfProduct'
import {
	ICartItemTypeEnum,
	OperationTypeDescription
} from '../../../interfaces/cart'
import { TradeType } from '../../../interfaces/combo'
import { colors } from '../../../styles/theme'
import { validateImageExtension } from '../../../utils/image'
import { parseItemPrice } from '../../../utils/money'
import { getFullPrice } from '../../../utils/price'
import { IProps } from './types'

import PlaceholderProduct from '../../../../assets/images/placeholder-product.svg'
import PointsStarIcon from '../../../../assets/images/points-star.svg'
import TrashIcon from '../../../../assets/images/trash.svg'
import { calcTotalPoints } from '../../../utils/points'

import { colorThemeProvider } from '../../../../theme/themeProvider'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { IExtractPoints } from '../../../interfaces/pointsProgram'
import { getPointsExtracts } from '../../../services/customerPointsProgram.service'
import styles from './styles.module.scss'

function CartItem(props: IProps): JSX.Element {
	const {
		isEditable,
		item,
		handleRemove,
		handleIncrementQty,
		handleDecrementQty,
		totalPointsRedeemed,
		handleUpdateItemLabel,
		isChallengeMix = false,
		salesOrgHasProgramPoints,
		cartChallengeItem
	} = props
	const { customer } = useTypedSelector(['customer'])
	const [
		insufficientAmountOfPointsForRedemption,
		setInsufficientAmountOfPointsForRedemption
	] = useState(false)

	const [userPointsExtractData, setUserPointsExtractData] =
		useState<Partial<IExtractPoints> | null>(null)

	const isReturnable = item.PackType === 'Retornável'

	const isCombo =
		item.operationTypeDescription === OperationTypeDescription.Combo
	const productSrc =
		item.comboImageUrl === undefined
			? validateImageExtension(item.ImageUrl) || PlaceholderProduct
			: validateImageExtension(item.comboImageUrl) || PlaceholderProduct

	const { OrderQuantity, UnitMeasureSale, Price, Size, Description } = item

	const remove = () => handleRemove(item)

	const increment = handleIncrementQty(item)
	const decrement = handleDecrementQty(item)
	const update = handleUpdateItemLabel(item)

	const totalPointsLabel = item.ScoreValue
		? `+${calcTotalPoints(item.Price, item.ScoreValue)}`
		: ''

	const totalPriceLabel = parseItemPrice(Price)
	const isActionOrCombo =
		item.operationTypeDescription === 'Ação' ||
		item.operationTypeDescription === 'Combo'

	function fetchUserPointsExtractData() {
		;(async () => {
			const balance = await getPointsExtracts(customer.CustomerID)
			setUserPointsExtractData(balance)
		})()
	}

	function checkingIfThereAreEnoughPointsToRedeemMoreProducts() {
		if (
			(item.typeItem === ICartItemTypeEnum.REDEMPTION &&
				Number(item.pointsRedeemed) >
					Number(userPointsExtractData?.totalAvailableBalance)) ||
			Number(totalPointsRedeemed) >
				Number(userPointsExtractData?.totalAvailableBalance)
		) {
			setInsufficientAmountOfPointsForRedemption(true)
			return
		}
		setInsufficientAmountOfPointsForRedemption(false)
	}

	const discountValue = useMemo(() => {
		return item.actionPriceUndiscountedSalesValue
			? parseItemPrice(
					(
						item.actionPriceUndiscountedSalesValue *
						item.OrderQuantity
					).toString()
			  )
			: ''
	}, [item.OrderQuantity, item.actionPriceUndiscountedSalesValue])

	useEffect(fetchUserPointsExtractData, [customer])
	useEffect(checkingIfThereAreEnoughPointsToRedeemMoreProducts, [
		item.typeItem,
		item.pointsRedeemed,
		totalPointsRedeemed,
		userPointsExtractData
	])

	/**
	 * @description getting the minimum quantity of the challenge item(if it is), to see if we disable the item quantity decrease button of the InputQuantityLabel **/
	const minimumQuantityOfTheItemToCompleteTheChallenge = Number(
		cartChallengeItem?.products.find(
			(productItem) => productItem.productId === item.MaterialID
		)?.quantity
	)

	return (
		<>
			<tr>
				<td>
					<div className="table-photo-wrapper">
						<img
							className="item-photo"
							src={productSrc}
							alt="Foto do Produto"
						/>
					</div>
				</td>
				<td>
					<div className="input-quantity-wrapper">
						<InputQuantityLabel
							cartScreen={true}
							enableButtons={true}
							handleUpdate={update}
							value={OrderQuantity}
							isEditable={isEditable}
							handleDecrementEvent={decrement}
							handleIncrementEvent={increment}
							isChallengeMixItem={isChallengeMix}
							disableChallengeItemsAmountDecrement={
								isChallengeMix &&
								item.OrderQuantity ===
									minimumQuantityOfTheItemToCompleteTheChallenge
							}
							itsARescueItem={
								item.typeItem === ICartItemTypeEnum.REDEMPTION
							}
							insufficientAmountOfPointsForRedemption={
								insufficientAmountOfPointsForRedemption
							}
						/>
					</div>
				</td>

				<td>
					{isReturnable ? (
						<TitleTypeOfProduct
							color={colors.colorReturnable}
							title="RETORNÁVEL"
						/>
					) : null}
					{isCombo ? (
						<TitleTypeOfProduct
							color={
								item.promotionType !== 2
									? colorThemeProvider.successColor
									: colorThemeProvider.secondaryColor
							}
							title={
								item.promotionType !== 2
									? 'COMBO DE PRODUTOS'
									: 'AÇÃO DE PREÇO'
							}
						/>
					) : null}

					<span className="ellipsis">{Description}</span>
				</td>

				<td className="mobile-hidden">{UnitMeasureSale}</td>

				{salesOrgHasProgramPoints ? (
					<td className="mobile-hidden">
						{item.typeItem === ICartItemTypeEnum.REDEMPTION ? (
							<div className={styles['label-product-redemption']}>
								<img
									src={PointsStarIcon}
									alt="Icone de estrela"
								/>
								<span>Produto de resgate</span>
							</div>
						) : item.typeItem === ICartItemTypeEnum.NORMAL ? (
							<span className={styles.pointsColumnValue}>
								{totalPointsLabel}
							</span>
						) : null}
					</td>
				) : null}

				<td className="mobile-hidden">{Size}</td>

				<td>
					{item.promotionType !== 2 && item.products?.length ? (
						<p className="old-price">
							{parseItemPrice(
								getFullPrice(item.products) * item.OrderQuantity
							)}
						</p>
					) : (
						item.promotionType === 2 &&
						item.actionPriceUndiscountedSalesValue && (
							<p className="old-price">{discountValue}</p>
						)
					)}

					{item.operationType === TradeType.Z4BN &&
					!item.pointsRedeemed ? (
						<span className="bonus">
							<p>BONIFICAÇÃO</p>
						</span>
					) : (
						<>
							{isActionOrCombo ? (
								<p className="new-price">
									{parseItemPrice(item.Price)}
								</p>
							) : item.typeItem ===
							  ICartItemTypeEnum.REDEMPTION ? (
								<p className="price">
									{item.pointsRedeemed} Pontos
								</p>
							) : (
								<p className="price">{totalPriceLabel}</p>
							)}
						</>
					)}
				</td>

				<td>
					{item.promotionType === 2 && (
						<div className="discount">
							<p className="discount-text">OFERTA</p>
						</div>
					)}
				</td>
				<td>
					{isEditable && !isChallengeMix ? (
						// eslint-disable-next-line jsx-a11y/anchor-is-valid
						<a
							onClick={remove}
							className="trash-wrapper"
							href={'#'}>
							<img
								className="trash-icon"
								src={TrashIcon}
								alt="Icone de Deletar"
							/>
						</a>
					) : null}
				</td>
			</tr>
		</>
	)
}

export default CartItem
