import { createElement } from 'react'
import { useHistory } from 'react-router-dom'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { useCustomerFields } from './data'
import { IViewProps } from './types'
import MyAccount from './view'

function MyAccountContainer(): JSX.Element {
	const { customer } = useTypedSelector(['customer'])
	const history = useHistory()

	const [personalFields, storeFields] = useCustomerFields(customer, { history })

	const viewProps: IViewProps = {
		personalFields,
		storeFields,
		customer,
	}

	return createElement(MyAccount, viewProps)
}

export default MyAccountContainer
