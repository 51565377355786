import React from 'react'

import { InputField, InputPassword, Loading } from '../../shared/components'
import { IViewProps } from './types'

import GPLogoRed from '../../assets/images/logo-gp--red.svg'
import ArrowLeftRed from '../../assets/images/arrow-left-red.svg'
import CheckCircleRedIcon from '../../assets/images/check-circle-red.svg'

function GPLogin(props: IViewProps): JSX.Element {
	const {
		background,
		// confirmationIsDisabled,
		enrollment,
		handleBackArrow,
		// handleConfirmation,
		handleLogin,
		// handleRecover,
		// handleRecoverPassword,
		handleSuccess,
		isLoading,
		loginIsDisabled,
		// recoverIsDisabled,
		screenState,
		// setConfirmationCode,
		// setConfirmationCodeIsValid,
		// setDocumentIsValid,

		// setNewPassword,
		// setNewPasswordIsValid,
		setPassword,
		setPasswordIsValid,
		handleEnrollment
	} = props
	return (
		<div
			className="intern-container"
			style={{
				backgroundImage: `url('${background}')`
			}}>
			<div
				id="box-login"
				className={`intern-wrapper ${
					screenState === 'LOGIN' ? 'active' : ''
				}`}>
				<img
					className="gp-logo"
					src={GPLogoRed}
					alt="Grupo Petrópolis"
				/>

				<InputField
					onChange={handleEnrollment}
					id="enrollment"
					label={'Matrícula'}
					value={enrollment}
					maxLength={8}
				/>

				<InputPassword
					returnValue={setPassword}
					valueIsValid={setPasswordIsValid}
					login
					shouldValidate={false}
					canShowPassword
					showPasswordTextColor="#CE332E"
				/>

				{/* <button
					onClick={handleRecoverPassword}
					className="recover-password-link">
					Esqueci minha senha
				</button> */}

				{isLoading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<button
						disabled={loginIsDisabled()}
						onClick={handleLogin}
						className="button">
						Entrar
					</button>
				)}
			</div>

			<div
				className={`intern-wrapper ${
					screenState === 'RECOVER' ? 'active' : ''
				}`}>
				<img
					className="gp-logo"
					src={GPLogoRed}
					alt="Grupo Petrópolis"
				/>

				<div className="back-button-wrapper">
					<button
						onClick={handleBackArrow}
						className="back-arrow-button">
						<img
							className="back-arrow"
							color={'#ffffff'}
							src={ArrowLeftRed}
							alt="Voltar"
						/>

						<span className="text">Voltar</span>
					</button>
				</div>

				<h1 className="message">
					Digite seu CPF ou CNPJ abaixo e enviaremos um Código de
					Verificação para alterar sua senha.
				</h1>

				{/* <InputEnrollment
					returnValue={setEnrollment}
					valueIsValid={setDocumentIsValid}
					trueValue={documentNumber}
				/> */}

				{/* {loading ? (
					<div className="loading-wrapper">
						<Loading color="white" />
					</div>
				) : (
					<button
						disabled={recoverIsDisabled()}
						onClick={handleRecover}
						className="button">
						Enviar
					</button>
				)} */}
			</div>

			<div
				className={`intern-wrapper ${
					screenState === 'CONFIRMATION' ? 'active' : ''
				}`}>
				<img
					className="gp-logo"
					src={GPLogoRed}
					alt="Grupo Petrópolis"
				/>

				<div className="back-button-wrapper">
					<button
						onClick={handleBackArrow}
						className="back-arrow-button">
						<img
							className="back-arrow"
							color={'#ffffff'}
							src={ArrowLeftRed}
							alt="Voltar"
						/>

						<span className="text">Voltar</span>
					</button>
				</div>

				<h1 className="message">
					Digite o Código e cadastre uma nova senha.
				</h1>

				{/* <InputCode
					returnValue={setConfirmationCode}
					valueIsValid={setConfirmationCodeIsValid}
				/> */}

				{/* <InputPassword
					returnValue={setNewPassword}
					valueIsValid={setNewPasswordIsValid}
				/> */}

				{/* {loading ? (
					<div className="loading-wrapper">
						<Loading color="white" />
					</div>
				) : (
					<button
						disabled={confirmationIsDisabled()}
						onClick={handleConfirmation}
						className="button">
						Alterar Senha
					</button>
				)} */}
			</div>

			<div
				className={`intern-wrapper ${
					screenState === 'SUCCESS' ? 'active' : ''
				}`}>
				<img
					className="gp-logo"
					src={GPLogoRed}
					alt="Grupo Petrópolis"
				/>

				<div className="success-wrapper">
					<img
						className="check-circle"
						src={CheckCircleRedIcon}
						alt="Grupo Petrópolis"
					/>

					<h1 className="success-message">
						Senha alterada com sucesso
					</h1>
				</div>

				<button onClick={handleSuccess} className="button">
					Fazer Login
				</button>
			</div>
		</div>
	)
}

export default GPLogin
