import React from 'react'
import GPLogoWhite from '../../assets/images/logo-gp--white.svg'
import { SAC_TELEPHONE_NUMBER } from '../../assets/strings'

function Footer() {
	return (
		<footer className="footer">
			<img
				className="footer__logo"
				src={GPLogoWhite}
				alt="Grupo Petrópolis"
			/>
			<div className="footer__text">
				<h3>
					{`Dúvidas? Ligue para Central de Atendimento de Segunda à
					Sexta, das 8h às 17h (exceto feriados)`}
				</h3>
				<h4 className="footer__text--big-nums">
					{SAC_TELEPHONE_NUMBER}
				</h4>
			</div>
			<div className="footer__text">
				<a
					href="https://privacy-central.securiti.ai/#/dsr/64064192-5dcc-4bcf-a94e-272b9553d923"
					target="_blank"
					rel="noopener noreferrer">
					{'Canal de Atendimento aos Titulares'}
				</a>

				<a
					href="https://www.grupopetropolis.com.br/informacoes-legais/aviso-de-privacidade/"
					target="_blank"
					rel="noopener noreferrer">
					{'POLÍTICA DE PRIVACIDADE'}
				</a>
			</div>
		</footer>
	)
}

export default Footer
