import React from 'react'
import * as S from './another-form-validation.styles'

import EncryptedField from '../../../../components/EncryptedField/encrypted-field.view'
import { TypeField } from '../../../../components/EncryptedField/encrypted-field.types'
import { IAnotherFormValidation } from './another-form-validation.types'
import { WrapperButton } from '../../reset-password.styles'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { TypeContact } from '../code-validation/code-validation.types'
import { useHistory } from 'react-router-dom'
import { UnauthenticatedRoutesEnum } from '../../../../modules/Routes/unauthenticatedRoutesEnum'
import { EnumTypeFlow } from '../../reset-password.types'
import { updateRegistryAlternativeCognito } from '../../reset-password.service'

function AnotherFormValidationComponent({
	goStep,
	setContactData,
	alternativeOption,
	setTypeFlow,
	alternativeOptionString,
	customerDocument
}: IAnotherFormValidation) {
	const history = useHistory()

	async function choiceUpdateCognito() {
		await updateRegistryAlternativeCognito(
			customerDocument,
			alternativeOptionString === 'Email'
		)
	}

	return (
		<>
			<S.AnotherFormValidationView>
				<S.Title>Escolha como quer validar</S.Title>
				<S.Description>
					Receba um novo código em seu contato alternativo, ou
					responda às perguntas de segurança.
				</S.Description>

				<S.WrapperFields>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-start',
							justifyItems: 'center',
							flexDirection: 'column',
							gap: '4px'
						}}>
						<label
							style={{
								paddingLeft: '24px',
								color: '#6F6F6F',
								fontWeight: '400',
								fontSize: '14px'
							}}>
							{alternativeOptionString}
						</label>{' '}
						<EncryptedField
							typeField={TypeField.MAIL}
							text={alternativeOption}
							handleClick={() => {
								goStep(0)
								if (alternativeOptionString === 'Celular') {
									setContactData({
										validationChoice: alternativeOption,
										validationType:
											TypeContact.PHONE_ALTERNATIVE
									})
								} else {
									setContactData({
										validationChoice: alternativeOption,
										validationType:
											TypeContact.EMAIL_ALTERNATIVE
									})
								}

								setTypeFlow(EnumTypeFlow.ALTERNATIVEFLOW)
								choiceUpdateCognito()
							}}
						/>
					</div>

					<EncryptedField
						typeField={TypeField.NONE}
						text={'Pergunta de segurança'}
						handleClick={() => {
							goStep(4)
							setTypeFlow(EnumTypeFlow.SECURITY_QUESTIONS)
						}}
					/>
				</S.WrapperFields>
			</S.AnotherFormValidationView>
			<>
				<WrapperButton>
					<ButtonDefault
						buttonText="Voltar"
						type={TypeButtonEnum.OUTLINED}
						onClick={() =>
							history.replace(UnauthenticatedRoutesEnum.LOGIN)
						}
						idElement="cancel"
						width="234px"
					/>
				</WrapperButton>
			</>
		</>
	)
}

export default AnotherFormValidationComponent
