import { param } from '../../stories/components/inputParam.stories'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'
import { IBanner } from '../interfaces/banner'
import { ICustomer } from '../interfaces/customer'
import customer from '../store/ducks/customer'
import {
	apiAdmin,
	apiCustomer,
	axiosGPInstance,
	getJwtAuthGpAcessToken
} from './axiosInstace'
import axios from 'axios'

export enum PagesBannersEnum {
	HOME = 'HOME',
	LOGIN = 'LOGIN'
}

export interface CustomerBannersParameters {
	customerID: string
	platform: string
	director?: string
	grc?: string
	cd?: string
	state?: string
	city?: string
	channel?: string
}

export interface IPropsFetchGetBanners {
	customer: ICustomer
	platform: string
	targetPage: PagesBannersEnum
}

export interface CustomerGovAndDirector {
	director: string
	gov: string
}

const apiBanner = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

const testeInstance: AxiosInstance = axios.create({
	baseURL: 'http://localhost:8080/v1',
	timeout: 300000,
	headers: {
		'Content-Type': 'application/json'
	}
})

export async function fetchGetBanners(
	args: IPropsFetchGetBanners
): Promise<IBanner[]> {
	const responseData: CustomerGovAndDirector = await fetchDirectorsAndGov(
		args.customer.SalesOrganizationID
	)
	const params: CustomerBannersParameters = {
		customerID: args.customer.CustomerID,
		platform: args.platform,
		director: responseData.director,
		grc: responseData.gov,
		cd: args.customer.SalesOrganizationID,
		state: args.customer.Region,
		city: args.customer.City,
		channel: args.customer.ChannelGroupID
	}

	const response = await testeInstance.get(
		`banner/get-customer-banners-hierarchy/${args.targetPage}`,
		{
			params
		}
	)
	return response.data
}

export async function fetchGetBannersWithoutPlataform(
	targetPage?: string,
	platform?: string,
	initialDate?: string,
	finalDate?: string
): Promise<IBanner[]> {
	const response = await apiBanner.get(`/banner`, {
		params: {
			targetPage,
			platform,
			initialDate,
			finalDate
		}
	})
	return response.data
}

export async function getPriority(
	targetPage?: string,
	platform?: string,
	initialDate?: string,
	finalDate?: string,
	customerIDs?: any
): Promise<number> {
	const accessToken = await getJwtAuthGpAcessToken()
	const response = await apiBanner.post(
		`/banner/get-priority`,
		{
			targetPage: targetPage,
			platform: platform,
			initialDate: initialDate,
			finalDate: finalDate,
			customerIDs: customerIDs
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)

	return response.data
}
export async function fetchGetBannersLogin(
	targetPage?: PagesBannersEnum
): Promise<IBanner[]> {
	const accessToken = await getJwtAuthGpAcessToken()
	const response = await axiosGPInstance.get(`/banner/login`, {
		params: {
			platform: 'WEB'
		},
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	})
	return response.data
}

export async function fetchDirectorsAndGov(
	salesOrganizationID: string
): Promise<CustomerGovAndDirector> {
	const accessToken = await getJwtAuthGpAcessToken()
	const response = await axiosGPInstance.post(
		`/banner/directors`,
		{},
		{
			params: {
				salesOrganizationID: salesOrganizationID
			},
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
	return response.data
}
