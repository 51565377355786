import React from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { PlayerButtonProps } from './player-button.types'
import { PlayerButtonWrapper, RounderWrapper } from './player-button.styles'

function PlayerButton({
	isPlaying,
	onClickPlay,
	onClickPause
}: PlayerButtonProps) {
	return (
		<PlayerButtonWrapper onClick={!isPlaying ? onClickPlay : onClickPause}>
			<RounderWrapper>
				{isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
			</RounderWrapper>
		</PlayerButtonWrapper>
	)
}

export default PlayerButton
