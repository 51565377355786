import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ITransferActionCreators
} from '../../interfaces/action'
import { IStoreTransfer } from '../../interfaces/store'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<ITransferActionCreators> = createActions({
	updateTransferArea: ['value'],
	resetTransferArea: []
})

/**
 * Handlers
 */

const INITIAL_STATE: IStoreTransfer = {
	PersonalFiscalID: ''
}

const update = (state = INITIAL_STATE, action: any) => action.value
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<IStoreTransfer, Action<ITransferActionCreators>>(
	INITIAL_STATE,
	{
		[Types.UPDATE_TRANSFER_AREA]: update,
		[Types.RESET_TRANSFER_AREA]: reset
	}
)
