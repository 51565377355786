import styled from 'styled-components'

export const WrapperIconsSocial = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 24px;

	color: ${(props) => props.theme.palette.primary.main};

	img:hover {
		cursor: pointer;
	}
`
