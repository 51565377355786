import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'
import { IStyleProps } from './types'

export const Wrapper = styled.div`
	gap: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ProgressBarWrapper = styled.div`
	width: 100%;
	max-width: 324px;
	height: 7px;
	background-color: ${(props) => props.theme.palette.extras.stroke.main};
	border-radius: 4px;
	position: relative;
`

export const Bar = styled.div<IStyleProps>`
	max-width: 324px;
	width: ${({ progress }) => `${progress}%`};
	position: absolute;
	top: 0;
	height: 7px;
	transition: all ease-in-out 400ms;
	border-radius: 4px;
	background-color: ${colorThemeProvider.successColor};
	box-shadow: 0px 2px 2px $ ${colorThemeProvider.grayColor};
`

export const Label = styled.label<IStyleProps>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	min-width: 36px;
	font-weight: ${(props) => props.theme.font.weight.medium};
	display: inline-block;
	color: ${(props) =>
		props.isCompleted
			? colorThemeProvider.blackColor
			: colorThemeProvider.grayColor};
`
