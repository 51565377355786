import axios, { AxiosResponse } from 'axios'

import { Maybe } from '../../shared/interfaces/common'
import axiosInstance from '../../shared/services/axiosInstace'
import handleError from '../Error/handleError'
import { INewClient, IViaCepReturn } from './types'

export async function sendNewClient(client: INewClient): Promise<any> {
	try {
		const response: AxiosResponse = await axiosInstance.post(
			'/leads',
			client,
			{
				auth: {
					username: 'leadPortalB2B',
					password: "mSu4MX?<6=t'74P7"
				}
			}
		)

		return response.data
	} catch (error) {
		handleError(error)
		return null
	}
}

export const getAddress = async (
	cep: string
): Promise<Maybe<IViaCepReturn>> => {
	const url = `https://viacep.com.br/ws/${cep}/json`

	const options = {
		headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
	}

	const res = await axios.get(url, options)

	if (!res) return

	const address: IViaCepReturn = res.data

	return address
}
