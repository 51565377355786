import { CharacterCounter, FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React from 'react'
import { paddingToString } from '../../shared/styles/functions'
import Loading from '../Loading'

function FloatLabelLocaleInput({
	label,
	id,
	className,
	primaryColor = '#444444',
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = '#D1D1D1',
	color = '#D1D1D1',
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	value,
	labelColor,
	maxLength,
	loading,
	...rest
}: IFloatLabelInputProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		labelColor,
		color
	}

	const counterIsActive = value.length >= 1

	return (
		<FloatLabelInputWrapper styles={mergedStyles} className={className}>
			<input
				className="fli-input"
				{...rest}
				id={id}
				required
				maxLength={maxLength}
				value={value}
			/>
			<label className="fli-label" htmlFor={id}>
				{label}
			</label>
			{maxLength ? <div className="fli-counter-wrapper">
				<CharacterCounter isActive={counterIsActive}>
					{value.length}/{maxLength}
				</CharacterCounter>
			</div> : null}

			{loading ? <div className="loading-wrapper"><Loading color='red' size={14} /></div> : null}
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelLocaleInput
