import * as React from 'react'
import { Typography } from '@material-ui/core'
import { EmptyBox } from './empty-table.styles'
import StoreIcon from '../../assets/images/store.svg'
import { EmptyTableProps } from './empty-table.types'
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined'

const EmptyTable = ({
	emptyTableMessage = 'Selecione uma Revenda para exibirmos a listagem de produtos',
	icon = StoreIcon,
	isDefaultTable = true
}: EmptyTableProps) => {
	return (
		<EmptyBox>
			<div className="outside-circle">
				<div className="inside-circle">
					{isDefaultTable ? (
						<img src={icon} alt="store icon" />
					) : (
						<PanoramaOutlinedIcon className="custom-icon" />
					)}
				</div>
			</div>
			<Typography className="empty-message">
				{emptyTableMessage}
			</Typography>
		</EmptyBox>
	)
}

export default EmptyTable
