import { IChallenge } from './pointsProgramChallenge'
import { IFiltersValues } from '../../components/Filters/filters.component.types'

export enum CategoriesBannerEnum {
	BEERS = 'CERVEJAS',
	ENERGY = 'ENERGÉTICOS',
	DISTILLATES = 'DESTILADOS',
	SOFT_DRINKS = 'REFRIGERANTES',
	ISOTONICS = 'ISOTÔNICO',
	WATERS = 'ÁGUAS',
	MORE_POINTS = 'MORE_POINTS'
}

export interface IBanner {
	ID?: string
	bannerID: string
	bannerUUID: string
	title: string
	imageURL: string
	category?: CategoriesBannerEnum
	initialDate: string
	finalDate: string
	status: BannerStatus
	justification: string
	platform: string
	filters: string
	amountOfCustomers: string
	videoURL: string
	targetPage: string
	targetOrder: string
	selectedProduct: string
	selectedCategory: CategoriesBannerEnum
	actionType: string
	cd: string
	creationStatus?: string
}

export interface BannerMetadata {
	totalItems: number
	totalPages: number
}

export interface PaginatedBanners extends BannerMetadata {
	banners: IBanner[]
}

export interface INewBanner {
	title: string
	imageURL: string
	initialDate: string
	finalDate: string
	platform: string
	filters: IFiltersValues
	customers: string[]
	videoURL: string
	targetOrder: number
	targetPage: string
	selectedProduct: string
	selectedCategory: string
	actionType: string
}

export interface ICarouselBanner
	extends Omit<IBanner, 'bannerID' | 'Category'> {
	bannerID?: string
	Category?: string
	challenge?: IChallenge
}

export enum BannerStatus {
	CANCELLED = 'CANCELLED',
	ACTIVE = 'ACTIVE',
	SCHEDULED = 'SCHEDULED',
	FINISHED = 'FINISHED'
}

export enum BannerStatusPTBR {
	CANCELLED = 'CANCELADO',
	ACTIVE = 'ATIVO',
	SCHEDULED = 'AGENDADO',
	FINISHED = 'FINALIZADO'
}
