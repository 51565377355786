import { createElement } from 'react'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { logout } from '../../shared/services/axiosInstace'
import { stringToProperCase } from '../../shared/utils/string'

import { IProps, IViewProps } from './types'
import NavBar from './view'

function NavBarContainer(props: IProps): JSX.Element {
	const { isOpen, handleSetIsOpen } = props

	const { customer } = useTypedSelector(['customer'])

	if (!customer) logout()

	const { Name = '', Type, NickName } = customer

	const firstName = Name.split(' ')[0]
	const userName = stringToProperCase(firstName)

	const isCustomer = Type === 'CUSTOMER'

	const viewProps: IViewProps = {
		isOpen,
		isCustomer,
		userName,
		handleSetIsOpen,
		NickName,
		userType: Type,
	}

	return createElement(NavBar, viewProps)
}

export default NavBarContainer
