import { axiosGPInstance } from '../../shared/services/axiosInstace'
import { IResaleItem } from './types'
function sortByName(a: IResaleItem, b: IResaleItem) {
	const nomeA = a.Name.toLowerCase()
	const nomeB = b.Name.toLowerCase()

	return nomeA.localeCompare(nomeB)
}
export async function fetchCompanies(): Promise<IResaleItem[]> {
	try {
		const response = await axiosGPInstance.get(`/companies`)
		return response.data.sort(sortByName)
	} catch (error) {
		return []
	}
}
