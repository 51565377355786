import { useIsFetching, useQuery } from 'react-query'
import CategoriesService from '../../services/categories.service'
import { RulesCouponProps, TypeOption } from './rules-coupon.types'
import { TypeButtonEnum } from '../../../../../components/buttons/button/button-default.types'
import { queryClient } from '../../../../../config/query-client.config'
import { ReturnSelectOption } from '../../services/types/categories.types'

type UseRulesCouponProps = {
	watch: RulesCouponProps['watch']
	isEditCouponFormMode: boolean
}

export default function useRulesCoupon({
	watch,
	isEditCouponFormMode
}: UseRulesCouponProps) {
	const minValueAmount = watch('minValueAmount')

	const category = watch('category')
	const brand = watch('brand')

	const isBrandDisabled = category.length === 0
	const isPackageDisabled = brand.length === 0
	const buttonFilterType =
		category.length > 0
			? TypeButtonEnum.PRIMARY
			: TypeButtonEnum.DISABLED || isEditCouponFormMode

	const startAddorment = minValueAmount.length > 0 ? 'R$' : ''

	const cumulativeOptions = [
		{ label: 'Sim', value: 'true' },
		{ label: 'Não', value: 'false' }
	]
	const typeOptions = [
		{ label: 'Carrinho', value: 'cart' },
		{ label: 'Produto', value: 'product' }
	] as TypeOption[]

	const categoriesOptions =
		queryClient.getQueryData<ReturnSelectOption[] | undefined>([
			'categories'
		]) ?? []

	const isLoadingCategories = !!useIsFetching(['categories'])

	const { data: brandOptions = [], isLoading: isLoadingBrands } = useQuery({
		enabled: category.length > 0,
		queryKey: ['brands', category],
		queryFn: async () =>
			await CategoriesService.getBrandsByCategories(category)
	})

	const { data: packageOptions = [], isLoading: isLoadingPackage } = useQuery(
		{
			enabled: category.length > 0 && brand.length > 0,
			queryKey: ['package', category, brand],
			queryFn: async () =>
				await CategoriesService.getBrandsByPackage(category)
		}
	)

	return {
		isLoadingCategories,
		cumulativeOptions,
		categoriesOptions,
		typeOptions,
		isBrandDisabled,
		isPackageDisabled,
		buttonFilterType,
		startAddorment,
		brandOptions,
		isLoadingBrands,
		packageOptions,
		isLoadingPackage
	}
}
