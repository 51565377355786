import React from 'react'

import ArrowLeftIcon from '../../assets/images/arrow-left.svg'
import GPLogo from '../../assets/images/gp.png'
import { AccountActivationSession } from '../../shared/components'

import { IViewProps } from './types'

function ActivateRegistrationView(props: IViewProps) {
	const {
		handleBack,
		handleChangeConfirmationMethod,
		selectedConfirmationMethod,
		handleResendCode,
		isLoading
	} = props

	return (
		<div className="new-account-page">
			<section className="gradient-container" />

			<div className="new-account-wrapper">
				<div className="header">
					<button
						className="back-arrow-wrapper"
						onClick={handleBack}
					>
						<img
							className="back-arrow"
							src={ArrowLeftIcon}
							alt="Voltar"
						/>
					</button>

					<h1 className="header-text">
						Cadastro não Confirmado
					</h1>
				</div>

				<div className="form">
					<div className="activation-code">
						<p className="activation-code-subtitle" style={{ marginBottom: '14px' }}>
							Para ativar seu cadastro, escolha como deseja receber o Código de Ativação
						</p>

						<AccountActivationSession
							selectedConfirmationMethod={selectedConfirmationMethod}
							handleChangeConfirmationMethod={handleChangeConfirmationMethod}
						/>

						<button
							onClick={handleResendCode}
							disabled={!selectedConfirmationMethod || isLoading}
							className="button submit-activation-code"
						>SOLICITAR</button>
					</div>
				</div>
			</div>

			<img
				className="bom-parceiro-logo"
				src={GPLogo}
				alt="Grupo Petrópolis"
			/>
		</div>
	)
}

export default ActivateRegistrationView
