import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Header, Loading } from '../../shared/components'

import QuestionFAQComponent from './QuestionFAQComponent'

import ChevronIcon from '../../assets/images/chevron-right.svg'
import QuestionIllustration from '../../assets/images/question-illustration.png'

import { IViewProps } from './types'

import * as S from './styles'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

export default function PointsProgramFAQView(props: IViewProps) {
	const { questions, isLoading } = props

	return (
		<Fragment>
			<Header />
			<S.PointsProgramFAQWrapper>
				<S.PointsProgramFAQContainerContent>
					<S.HeaderNavigationWrapper>
						<span className="points-program-link">
							Programa de Pontos{' '}
							<img src={ChevronIcon} alt="Icone chevron" />
						</span>
						<Link to={CustomerRoutesEnum.POINTS_PROGRAM__MY_POINTS}>
							<span className="my-points-link">
								Meus Pontos{' '}
								<img src={ChevronIcon} alt="Icone chevron" />
							</span>
						</Link>
						<span className="faq-link">FAQ</span>
					</S.HeaderNavigationWrapper>
					<S.BannerFAQWrapper>
						<img src={QuestionIllustration} alt="Ilustração" />
						<S.FAQBannerContent>
							<S.FAQBannerTitle>
								FAQ - Programa de Pontos Bom Parceiro
							</S.FAQBannerTitle>
							<S.FAQBannerDescription>
								Veja as dúvida mais recorrentes sobre o
								funcionamento do Programa de Pontos BP
							</S.FAQBannerDescription>
						</S.FAQBannerContent>
					</S.BannerFAQWrapper>
					{isLoading ? (
						<S.LoadingWrapper>
							<Loading />
						</S.LoadingWrapper>
					) : (
						<S.QuestionsFAQWrapper>
							{questions.map((question) => (
								<QuestionFAQComponent {...question} />
							))}
						</S.QuestionsFAQWrapper>
					)}
				</S.PointsProgramFAQContainerContent>
			</S.PointsProgramFAQWrapper>
		</Fragment>
	)
}
