import { createElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConfirmationMethods } from '../../components/AccountActivationSession/types'
import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'

import { IStore } from '../../shared/interfaces/store'
import { Creators as TransferActions } from '../../shared/store/ducks/transferArea'
import handleError from '../Error/handleError'
import { userExists } from '../NewAccount/service'
import { checkUserState } from '../NewAccount/util'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { forgotPassword, setUserRegistryToCognito } from './service'
import { IViewProps } from './types'
import RecoverPassword from './view'
import { useUserAuthDataContext } from '../../context/user-auth-data'

function RecoverPasswordContainer(): JSX.Element {
	const { updateTransferArea, resetTransferArea } = TransferActions

	const history = useHistory()
	const dispatch = useDispatch()
	const transferArea = useSelector((state: IStore) => state.transferArea)

	// const [documentNumber, setDocumentNumber] = useState('')
	const { userDoc, setUserDoc } = useUserAuthDataContext()
	const [documentIsValid, setDocumentIsValid] = useState(false)
	const [selectedConfirmationMethod, setSelectedConfirmationMethod] =
		useState<ConfirmationMethods>(null)

	const [loading, setLoading] = useState(false)
	const [destination, setDestination] = useState('')

	// async function handleDocumentNumber(
	// 	e: React.ChangeEvent<HTMLInputElement>
	// ) {
	// 	setDocumentNumber(e.target.value)
	// }

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}

	async function handleAskForChange() {
		try {
			setLoading(true)

			const userExistsResponse = await userExists(userDoc)

			if (!userExistsResponse || !userExistsResponse.length) {
				// eslint-disable-next-line no-throw-literal
				throw new Error('Usuário não cadastrado ou não confirmado.')
			}

			const userStatus = checkUserState(userExistsResponse)

			if (userStatus !== 'ALREADY_REGISTERED') {
				// eslint-disable-next-line no-throw-literal
				throw new Error('Usuário não cadastrado ou não confirmado.')
			}

			//Atualizando registro da base com o cognito
			await setUserRegistryToCognito({ document: userDoc })

			const response = await forgotPassword({
				username: userDoc,
				clientMetadata: formatConfirmationCodeMethodParams(selectedConfirmationMethod)
				// clientMetadata: formatConfirmationCodeMethodParams(
				// 	'sendemail' || 'sendemail'
				// )
			})
			if (response) {
				setDestination(
					response.CodeDeliveryDetails.Destination.toString()
				)
			}
			if (!response) return

			const param = {
				PersonalFiscalID: userDoc
			}

			dispatch(updateTransferArea(param))
		} catch (err) {
			handleError(err as any)
		} finally {
			setLoading(false)
		}
	}

	function handleNavigateChangePassword() {
		if (!transferArea.PersonalFiscalID) return

		history.replace(UnauthenticatedRoutesEnum.CHANGE_PASSWORD, {
			confirmationMethod: selectedConfirmationMethod,
			destination: destination
		})
	}

	const handleChangeConfirmationMethod = useCallback(
		(method: ConfirmationMethods) => {
			setSelectedConfirmationMethod(method)
		},
		[]
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleNavigateChangePassword, [transferArea])

	useEffect(() => {
		dispatch(resetTransferArea())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// if (userDoc.length > 0) {
	// 	setDocumentIsValid(true)
	// }

	const viewProps: IViewProps = {
		handleBack,
		handleAskForChange,
		loading,
		userDoc,
		setUserDoc,
		setDocumentIsValid,
		documentIsValid,
		selectedConfirmationMethod,
		handleChangeConfirmationMethod
	}

	return createElement(RecoverPassword, viewProps)
}

export default RecoverPasswordContainer
