import styled from 'styled-components'
import { mediaQuery } from '../../shared/styles/functions'
import { colors } from '../../shared/styles/theme'

export const SuccessWrapper = styled.div`
	.successContainer {
		text-align: center;
		margin-top: 24px;
	}

	.row {
		margin: auto;
	}

	padding-bottom: 50px;

	.green-button {
		margin-top: 50px;
	}
`

export const Title = styled.p`
	color: ${colors.red};
	margin: 32px 0;
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;

	font-weight: ${(props) => props.theme.font.weight.regular};
`

export const Subtitle = styled.p`
	color: ${colors.secondGrey};
	margin: 0 0 24px 0;
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
`

export const InfoWrapper = styled.div`
	border: 1px solid ${(props) => props.theme.palette.primaryWhite.main};
	border-radius: 4px;
	background: ${(props) => props.theme.palette.white.main};
	padding: 16px;
	text-align: start;

	width: 49%;

	+ div {
		margin-left: 2%;
	}

	.paymentType {
		display: flex;
		justify-content: space-between;
	}

	${mediaQuery('sm')(`
        width: 100%;
        +div{
            margin-left: 0;
        }
    `)}
`

export const Label = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${colors.sixthGray};
	margin-bottom: 4px;
`

export const InfoValue = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	color: ${colors.secondGrey};
	text-transform: capitalize;
`

export const InfoValueTotal = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	color: ${colors.sixthGray};
`
