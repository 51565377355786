import styled, { css } from 'styled-components'
import { ChallengeStatusEnum } from '../../shared/interfaces/pointsProgramChallenge'
import { StatusRulePointsProgramEnum } from '../../shared/interfaces/pointsProgramRules'

export const StatusChipSpan = styled.span<{
	status: StatusRulePointsProgramEnum | ChallengeStatusEnum
}>`
	display: block;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	padding: 4px 8px;
	font-weight: ${(props) => props.theme.font.weight.bold};
	${({ status }) =>
		status === StatusRulePointsProgramEnum.ACTIVE ||
		status === StatusRulePointsProgramEnum.INACTIVE
			? css`
					max-width: 68px;
			  `
			: css`
					max-width: 68px;
			  `}
	border-radius: 10px;
	text-align: center;

	${({ status }) =>
		status === StatusRulePointsProgramEnum.INACTIVE ||
		status === ChallengeStatusEnum.EXPIRED
			? css`
					color: ${(props) => props.theme.palette.white.main};
					background-color: ${(props) =>
						props.theme.palette.gray.main};
			  `
			: status === StatusRulePointsProgramEnum.ACTIVE
			? css`
					color: ${(props) => props.theme.palette.success.main};
					background-color: ${(props) =>
						props.theme.palette.primaryWhite.main};
			  `
			: status === ChallengeStatusEnum.CANCELED
			? css`
					background-color: ${(props) =>
						props.theme.palette.primaryWhite.main};
					color: ${(props) => props.theme.palette.secondary.main};
			  `
			: css`
					background-color: ${(props) =>
						props.theme.palette.warning.main};
					color: ${(props) => props.theme.palette.primaryWhite.main};
			  `}
`
