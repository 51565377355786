import { FullParameter } from '../../shared/interfaces/fullParam'
import { axiosGPInstance } from '../../shared/services/axiosInstace'
import { CompanyParameter } from '../RegisterParameter/types'

export async function fetchCompanyParam(id?: string): Promise<FullParameter[]> {
	try {
		const params = await axiosGPInstance.get(
			`/parameterscompanies${id ? `?salesOrganizationID=${id}` : ''}`
		)

		return params.data
	} catch (error) {
		throw error
	}
}

export async function getCompanyParams(
	salesOrganizationID: string,
): Promise<CompanyParameter[] | undefined> {
	const params = await axiosGPInstance.get(
		`/parameterscompanies?salesOrganizationID=${salesOrganizationID}`,
	)

	return params.data
}


export async function fetchGlobalCompanyParam(): Promise<FullParameter[]> {
	try {
		const params = await axiosGPInstance.get(
			`/parameterscompanies?salesOrganizationID=0001`
		)

		return params.data
	} catch (error) {
		throw error
	}
}

export async function saveParams(id: string, params: any[]): Promise<any> {
	try {
		const response = await axiosGPInstance.post(
			`/parameterscompanies/${id}`,
			params
		)

		return response.data
	} catch (error) {
		throw error
	}
}
