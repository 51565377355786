import styled from 'styled-components'
export interface PropsParagraph {
	status: string
}
export const StyledParagraph = styled.p<PropsParagraph>`
	color: ${({ status, theme }) => {
		switch (status) {
			case 'Aprovado':
				return theme.palette.primary.main
			case 'Em análise':
				return theme.palette.warning.main
			case 'reprovado':
				return theme.palette.error.main
		}
	}};
	font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};

	&:hover {
		cursor: pointer;
	}
`
export const StyledNameParagraph = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
	font-weight: ${(props) => props.theme.font.weight.extraBold};
`
export const StyledDateParagraph = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
`
export const StyledLoagingWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`
export const StyledTitle = styled.h1`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XXL.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	padding-bottom: ${(props) => props.theme.spacingOptions.XLG}px;
`
