import React from 'react'
import * as S from './StatusIcon.styles'
import { IStatusIcon } from './StatusIcon.types'

export default function StatusIcon({ComponentIcon, text, type}: IStatusIcon) {
    return (
        <S.WrapperComponent type={type}>
            <ComponentIcon className={`wrapper-icon ${type}`} styles={{width:'42px', height:'42px'}}/>
            <h2>{text}</h2>
        </S.WrapperComponent>
    )
}
