import React from 'react'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import * as S from './delivery-management-admin.styles'
import ButtonDefault from '../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../components/buttons/button/button-default.types'
import TopModalSucess from '../../components/toast-sucess/toast-sucess.component'
import { Link } from 'react-router-dom'
import {
	deliveryManagementAdminSingleRuleType,
	DeliveryManagementeRulesFileHeaders
} from './delivery-management-admin.types'
import { useDeliveryManagement } from './hooks/useDeliveryManagement'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import { UploadSpreadsheetGeneric } from '../../components/upload-spreadsheet/hooks/upload-spreadsheet.generic'

function DeliveryManagementAdmin() {
	const {
		fileData,
		handleDownloadSpreadSheet,
		handleRemoveSpreadSheet,
		isSpreedSheetUploaded,
		setShowSuccessMessage,
		showSuccessMessage,
		handleUploadData,
		handleOnLoadSpreadSheet,
		loadingUploadData,
		rulesCsvToObjMapper,
		validateHeaders,
		datesRules,
		setDatesRules,
		haveCsv,
		setHaveCsv
	} = useDeliveryManagement()

	return (
		<>
			<LoadingCcontainer isLoading={loadingUploadData} />
			<TopModalSucess
				isOpen={showSuccessMessage}
				handleHideModal={() => setShowSuccessMessage(false)}
				sucessToastMessage="Planilha adicionada com sucesso!"
			/>
			<DefaultLayoutAdmin>
				<>
					<S.DeliveryManagementTitle>
						Gestão de Entregas
					</S.DeliveryManagementTitle>
					<S.DeliveryManagementContent>
						<S.DeliveryManagementSubTitle>
							Filtros
						</S.DeliveryManagementSubTitle>

						<S.WrapperButtonsUpload>
							{/*Propriedades mock*/}
							<S.WrapperButtonUploadCsv>
								<UploadSpreadsheetGeneric<deliveryManagementAdminSingleRuleType>
									haveCSV={haveCsv}
									setHaveCSV={setHaveCsv}
									selectedCSVData={datesRules ?? []}
									setSelectedCSVData={setDatesRules}
									clearDataFile={() =>
										handleRemoveSpreadSheet()
									}
									mapData={rulesCsvToObjMapper}
									onCsvUpload={(data, file) => {
										handleOnLoadSpreadSheet(data, file)
									}}
									validateHeaders={validateHeaders}
									headers={Object.values(
										DeliveryManagementeRulesFileHeaders
									)}
								/>
							</S.WrapperButtonUploadCsv>

							<S.WrapperButtonSendData>
								<ButtonDefault
									buttonText="SUBIR CARGA"
									onClick={() => handleUploadData()}
									typeButton={'button'}
									type={
										haveCsv
											? TypeButtonEnum.PRIMARY
											: TypeButtonEnum.DISABLED
									}
									idElement="upload-csv"
									fontSize="10px"
								/>
							</S.WrapperButtonSendData>
						</S.WrapperButtonsUpload>
						{isSpreedSheetUploaded && (
							<S.WrapperAttachFile>
								<S.AttachFileIconStyled />
								<p>{fileData.name}</p>
							</S.WrapperAttachFile>
						)}
						<S.TextHelp>
							Para carregar um arquivo, clique no botão "SUBIR
							PLANILHA EXCEL", e escolha a planilha desejada em
							seu dispositivo. Em caso de dúvidas,{' '}
							<Link to="#" onClick={handleDownloadSpreadSheet}>
								clique aqui
							</Link>{' '}
							para baixar um modelo de planilha.
						</S.TextHelp>
					</S.DeliveryManagementContent>
				</>
			</DefaultLayoutAdmin>
		</>
	)
}

export default DeliveryManagementAdmin
