import { ISelect } from '../../../components/SelectPrimary/types'
import { Maybe, Nullable } from '../../../shared/interfaces/common'
import {
	ICreditCard,
	IErrorState,
	IPaymentStep,
	IPaymentStepsButtons,
	PaymentInfo
} from '../types'
import { IFormattedPaymentCondition } from '../../../shared/interfaces/customer'
import { IPaymentMethodNameEnum } from '../../../shared/interfaces/Payments'

export interface IViewProps {
	value: PaymentInfo
	onChange(selectedValue: Maybe<Nullable<ISelect>>, _: any): void
	handleContinue: () => void
	handlerSelectPaymentMethod: (
		option: PaymentOptions[],
		buttonConfirm: boolean
	) => void
	handlerChangeStep: (step: number) => void
	totalPrice: number
	paymentStep: IPaymentStep | undefined
	condition: IFormattedPaymentCondition[]
	buttons: IPaymentStepsButtons
	paymentMethod: IPaymentMethodNameEnum[]
	handleChangeCreditCard: (id: string, value: string) => void
	creditCard: ICreditCard
	fieldsErros: IErrorState
	enableButton: boolean
	handleChangePaymentCondition: (item: IFormattedPaymentCondition) => void

}

export enum PaymentOptions {
	PAY_NOW_ONLINE = 0,
	PAY_ON_DELIVERY = 1,
	PAY_WITH_CREDIT_CARD = 2,
	PAY_WITH_PIX = 3
}
