import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import { IStyleProps } from './type'

export const ChallengeDetailsWrapper = styled.main`
	min-height: 100vh;
	padding-bottom: 48px;
	background-color: ${colors.fifthGray};
	.header {
		> div {
			position: sticky;
		}
	}
`

export const ContentWrapper = styled.section`
	margin-top: 48px;
	width: 100%;
	max-width: 1088px;

	margin: 48px auto 0 auto;

	@media screen and (max-width: 1024px) {
		padding: 24px;
	}
	@media screen and (max-width: 768px) {
		padding: 12px;
	}
`

export const ChipsWrapper = styled.section`
	display: flex;
	gap: 8px;
	align-items: center;
	margin-bottom: 8px;
`

export const GoBackButton = styled.button`
	border: none;
	gap: 8px;
	cursor: pointer;
	margin-bottom: 24px;

	display: flex;
	align-items: center;

	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
	background-color: transparent;
`

export const ChallengesInfosWrapper = styled.section`
	width: 100%;
	max-width: 720px;
	margin: 0 auto;
	margin-bottom: 32px;
`

export const ChallengeBanner = styled.div<IStyleProps>`
	width: 100%;
	height: 406px;
	max-width: 720px;
	max-height: 406px;
	border-radius: 4px;

	margin-top: 40px;
	margin-bottom: 24px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: ${({ bannerUrl }) => `url(${bannerUrl})`};
`

export const ChallengeTitle = styled.span`
	color: ${colors.blackLight};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	max-width: 536px;
	display: inline-block;
	margin-bottom: 16px;
	text-align: left;
	line-height: ${(props) => props.theme.font.size.DISPLAY_XS.line_height}rem;
`

export const ChallengeDescription = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	text-align: left;
	color: ${colors.secondGrey};
	max-width: 100%;
	line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;
	margin-bottom: 24px;
`

export const ChallengeEndDateDisplayWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 24px 16px;
	border-radius: 4px;
	background-color: ${colors.white};
`

export const FinalDateLabelText = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
`

export const BedgeWithFinalDate = styled.div`
	max-width: 75px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	padding: 3px 8px;
	text-align: center;
	border-radius: 10px;
	color: ${colors.secondGrey};
	border: 1px solid ${colors.secondGrey};
`

export const ButtonActionWrapper = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
`

export const JoinTheChallengeButton = styled.button`
	width: 100%;
	border: none;
	padding: 12px 32px;
	max-width: 190px;
	cursor: pointer;
	color: ${colors.white};
	text-transform: uppercase;
	background-color: ${colors.green};
	border-radius: 4px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	box-shadow: 0px 2px 3px #0000003d;
	transition: all ease-in-out 300ms;

	&:hover {
		background-color: ${colors.buttonGreenHover};
	}
`

export const ProductsWrapper = styled.section`
	gap: 16px;
	width: 100%;

	display: grid;
	place-items: center;

	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
`

export const LoadingWrapper = styled.section`
	width: 100%;
	min-height: 342px;

	display: flex;
	align-items: center;
	justify-content: center;
`

export const ProgressBarWrapper = styled.section`
	width: 100%;

	gap: 24px;
	display: flex;
	align-items: center;
	justify-content: center;

	max-width: 720px;
	min-height: 85px;
	padding: 29px 16px;
	border-radius: 4px;
	background-color: ${colors.white};

	@media screen and (max-width: 480px) {
		align-items: center;
		padding: 16px 8px;
		flex-direction: column;
	}
`

export const ProgressBarLabel = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
	text-transform: uppercase;
	color: ${colors.secondGrey};
	display: inline-block;
	max-width: 152px;

	@media screen and (max-width: 480px) {
		text-align: center;
	}
`

export const RemainingProductsText = styled(ProgressBarLabel)`
	color: ${colors.red};
`

export const ContinueWithPurchaseButton = styled(JoinTheChallengeButton)`
	max-width: 124px;
	transition: all ease-in-out 300ms;

	&:disabled {
		cursor: not-allowed;
		background-color: ${colors.fourthGrey};
	}
`

export const ChallengeProgressSession = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 40px;
`

export const BarWrapper = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;

	> div {
		width: 100%;
	}
`

export const CompletedChallengeLabel = styled.span`
	text-align: center;
	margin-top: 8px;
	display: inline-block;
	text-transform: uppercase;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${colors.scoreValueLabelBg};
`

export const ComplementaryElement = styled.div``
