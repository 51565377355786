import React, { useEffect, useState } from 'react'
import { Terms } from '../components/PointsProgramModalAdoption/TermsModal/Terms'
import { Modal } from '../components'
import { requestTermsAdhesion } from '../services/customer.service'

export interface IProps {
	onClose: () => void
	showModalAdhesion: boolean
}
export function useTermsAdhesion({ showModalAdhesion, onClose }: IProps) {
	const [loading, setLoading] = useState(false)
	const [term, setTerm] = useState<string[]>([])

	function getTermsAdhesion() {
		;(async () => {
			try {
				setLoading(true)
				const response = await requestTermsAdhesion()
				setTerm(response)
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	useEffect(getTermsAdhesion, [showModalAdhesion])

	function modalTerms() {
		return showModalAdhesion ? (
			<Modal isActive={showModalAdhesion} isFull>
				<Terms
					headerTitle="Termo de Adesão ao Bom Parceiro"
					termAcceptedLabel=""
					termsTitle="Termo de Adesão"
					terms={term}
					isFetchingTerms={false}
					onClose={onClose}
					onAcceptTerms={() => console.log}
					onChangeAcceptTerms={() => console.log}
					acceptedTerms={false}
					isLoading={loading}
					viewMode={'VIEW'}
				/>
			</Modal>
		) : null
	}

	return {
		modalTerms,
	}
}
