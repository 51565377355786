const originalArrayHours = [
	{
		value: '00:00:00',
		label: '00h00'
	},
	{
		value: '01:00:00',
		label: '01h00'
	},
	{
		value: '02:00:00',
		label: '02h00'
	},
	{
		value: '03:00:00',
		label: '03h00'
	},
	{
		value: '04:00:00',
		label: '04h00'
	},
	{
		value: '05:00:00',
		label: '05h00'
	},
	{
		value: '06:00:00',
		label: '06h00'
	},
	{
		value: '07:00:00',
		label: '07h00'
	},
	{
		value: '09:00:00',
		label: '09h00'
	},
	{
		value: '10:00:00',
		label: '10h00'
	},
	{
		value: '11:00:00',
		label: '11h00'
	},
	{
		value: '12:00:00',
		label: '12h00'
	},
	{
		value: '13:00:00',
		label: '13h00'
	},
	{
		value: '14:00:00',
		label: '14h00'
	},
	{
		value: '15:00:00',
		label: '15h00'
	},
	{
		value: '16:00:00',
		label: '16h00'
	},
	{
		value: '17:00:00',
		label: '17h00'
	},
	{
		value: '18:00:00',
		label: '18h00'
	},
	{
		value: '19:00:00',
		label: '19h00'
	},
	{
		value: '20:00:00',
		label: '20h00'
	},
	{
		value: '21:00:00',
		label: '21h00'
	},
	{
		value: '22:00:00',
		label: '22h00'
	},
	{
		value: '23:00:00',
		label: '23h00'
	}
]
const handleFilterHourPicker = () => {
	const date = new Date()
	const currentHour = date.getHours()
	// const currentMinuts = date.getMinutes()

	const mappedArray = originalArrayHours.filter((filterTime) => {
		const filteredHours = parseInt(filterTime.value.split(':')[0])
		if (currentHour < filteredHours) {
			return filterTime
		} else {
			return false
		}
	})
	return mappedArray
}

module.exports = {
	handleFilterHourPicker,
	originalArrayHours
}