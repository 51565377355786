import React from 'react'
import { BannerMidiaUploadProps } from '../banner-midia-upload.type'
import { useMutation } from 'react-query'
import { savingUploadImageBannerHandler } from '../../../../banners-management/service'

export const useMidiaBannerMidiaUpload = ({
	setSelectedMidia
}: BannerMidiaUploadProps) => {
	const ref = React.useRef<HTMLInputElement>(null)

	const handleSelectImage = () => {
		if (ref.current !== null) {
			ref.current.click()
		}
	}

	const { mutate: handleUploadImage, isLoading: loadingUploadImage } =
		useMutation({
			mutationFn: async (event: React.ChangeEvent<HTMLInputElement>) => {
				const imageFile = event.target.files?.[0]
				if (imageFile) {
					const res = await savingUploadImageBannerHandler({
						file: imageFile,
						fileName: `pn-img-${Number(new Date())}`
					})
					return res
				}
			},
			onSuccess(data: { url: string }) {
				setSelectedMidia(data.url)
			},
			onError(error) {
				console.error(error)
			}
		})

	return {
		ref,
		loadingUploadImage,
		handleUploadImage,
		handleSelectImage
	}
}
