import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './assets/scss/main.scss'
import Amplify from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import amplifyConfig from './amplify-config'
import App from './App'
import { unregister } from './serviceWorker'
import { captchaKeys } from './shared/enums/captchaKeys'

import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'
import theme from './theme/themeProvider'
import GlobalStyle from './theme/global-styles'

ReactGA.initialize('UA-164396029-1', {
	debug: false
})

Amplify.configure(amplifyConfig)
require('dotenv').config()

ReactDOM.render(
	<GoogleReCaptchaProvider
		language={'pt-BR'}
		reCaptchaKey={captchaKeys.USERS_CAPTCHA_KEY}>
		{/* @ts-ignore */}
		<MuiThemeProvider theme={theme}>
			{/* @ts-ignore */}
			<ThemeProvider theme={theme}>
				{/* @ts-ignore */}
				<GlobalStyle />
				<App />
			</ThemeProvider>
		</MuiThemeProvider>
	</GoogleReCaptchaProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister()
