import { Auth } from 'aws-amplify'
import { handleCognitoError } from '../../shared/utils/handleCognitoError'

import { IRequestForgotPassword, IForgotPasswordResponse, IFindCustomerParams } from './types'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'
import { apiAdmin, apiCustomer, getJwtAuthGpAcessToken } from '../../shared/services/axiosInstace'
import { AxiosResponse } from 'axios'

const api = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

export async function forgotPassword({
	username,
	clientMetadata
}: IRequestForgotPassword): Promise<IForgotPasswordResponse | undefined> {
	try {
		const response = await Auth.forgotPassword(username, {
			'custom:sendemail': clientMetadata.sendemail,
			'custom:sendsms': clientMetadata.sendsms
		})

		return response
	} catch (err) {
		handleCognitoError(err)
	}
}

export async function setUserRegistryToCognito({ document }: IFindCustomerParams) {
	const token = await getJwtAuthGpAcessToken()
	const response: AxiosResponse = await api.put(
		`/customers/setUserRegistryToCognito`,
		{
			document,
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	)
	return response?.data

}
