import React, { useCallback, useState } from 'react'
import * as S from './combo-flex.styles'
import { IComboFlex } from './combo-flex.types'
import ModalComboFlex from './components/modal-combo-flex/modal-combo-flex.component'
import { validateImageExtension } from '../../shared/utils/image'
import { ComboFlexProvider } from './context/combo-flex.context'
import { getComboMaterials } from '../../shared/services/materials.services'
import { IGroupTypesItems } from '../../shared/interfaces/combo'
import ReactGA from 'react-ga'
import Loading from '../Loading'
import { toMoney } from '../../shared/utils/money'

function ComboFlex({ combo }: IComboFlex) {
	const [isOpenModalComboFlex, setIsOpenModalComboFlex] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleSelectCombo = useCallback(async () => {
		setLoading(true)
		const productIds =
			combo.GroupsTypes?.results.flatMap((products) => {
				const productItems = products.items.map(
					(item: IGroupTypesItems) => item.productID
				)
				return productItems
			}) ?? []
		const materials = await getComboMaterials({
			productIds,
			salesOrganizationId: combo?.salesOrganizationID
		})
		combo.GroupsTypes?.results.forEach((products) => {
			products.items.forEach((item: IGroupTypesItems) => {
				item.material = materials.find(
					(material) =>
						material.materialNumber ===
						item.productID.replace(/^0+/, '')
				)
			})
		})
		setLoading(false)
	}, [combo])

	return (
		<>
			{loading ? (
				<S.StyledComboFlex>
					<Loading />
				</S.StyledComboFlex>
			) : (
				<>
					<ComboFlexProvider>
						<ModalComboFlex
							isOpenModalComboFlex={isOpenModalComboFlex}
							handleCloseModal={setIsOpenModalComboFlex}
							combo={combo}
						/>
					</ComboFlexProvider>
					<S.StyledComboFlex
						onClick={async () => {
							await handleSelectCombo()
							setIsOpenModalComboFlex(true)

							ReactGA.event({
								category: 'Combo Flex',
								action: `combo_flex - ${combo.promotionID}`,
								label: combo.description
							})
						}}>
						<S.StyledDescriptionComboFlex>
							<img
								src={validateImageExtension(
									combo.comboImageUrl
								)}
								alt="combo-flex"
							/>
							<p>{combo.description}</p>
						</S.StyledDescriptionComboFlex>
						<S.StyledFooterComboFlex>
							<p>A partir de</p>
							<p>{toMoney(combo.comboPriceTotal)}</p>
						</S.StyledFooterComboFlex>
					</S.StyledComboFlex>
				</>
			)}
		</>
	)
}

export default ComboFlex
