import cogoToast from 'cogo-toast'
import { IChallenge } from '../../shared/interfaces/pointsProgramChallenge'
import { axiosGPInstance } from '../../shared/services/axiosInstace'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { getEnumMessage } from '../Error/handleError'

export async function getChallengesRegistereds(): Promise<IChallenge[] | any> {
	try {
		const response = await axiosGPInstance.get(`/program-point-challenge`)
		return response.data
	} catch (err) {
		const error = err as any
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}

export async function cancelingChallengeService(challengeId: string) {
	try {
		const response = await axiosGPInstance.patch(
			`/program-point-challenge/cancel-challenge?challengeId=${challengeId}`
		)
		return response.data
	} catch (err) {
		const error = err as any
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}
