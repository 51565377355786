import styled from 'styled-components'

export const ContainerDetails = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: ${(props) => props.theme.spacingOptions.SM}px;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;
`
export const LabelName = styled.h3`
	font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	color: ${(props) => props.theme.palette.primary.main};
	text-transform: uppercase;
`
export const LabelValue = styled.p`
	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${(props) => props.theme.palette.black.main};
`
