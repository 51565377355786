import React, { useState } from 'react'

import { Loading, Table } from '../../shared/components'

import SearchInputBanner from './SearchInputBanner'

import { IViewProps } from './types'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import * as S from './banners-management.styles'
import ButtonExpand from './components/button-expand/button-expand.component'
import EmptyTable from '../../components/empty-table/empty-table.component'

import emptyTableIcon from './assets/empty-table.svg'
import FormCreateBanner from './components/form-create-banner/form-create-banner.component'
import { DateRangePicker } from '../../components/date-range-picker'
import TitlePage from '../../components/title-page/title-page.component'
import DetailsBannerModal from './components/banner-modals/details-banner-modal/details-banner-modal'
import CancelBannerModal from './components/banner-modals/cancel-banner-modal/cancel-banner-modal.component'
import EditBannerModal from './components/banner-modals/edit-banner-modal/edit-banner-modal'

import { colors } from '../../shared/styles/theme'
import { newFilters } from '../push-notifications-admin/history-push/utils/filters'
import FiltersByHieararchy from '../../components/filters-by-hieararchy/filters-by-hieararchy.component'
import { getChallengeFilterParameters } from '../../shared/services/pointProgramChallenges.service'
import { IFilterOptions } from '../../components/filters-by-hieararchy/typings'

export default function BannersManagementView(props: IViewProps) {
	const {
		columns,
		showEditBannerModal,
		customers,
		tableData,
		isLoading,
		bannerActiveModal,
		bannerMetadata,
		isExpandedForm,
		setIsExpandedForm,
		showBannerDetailsModal,
		setShowBannerDetailsModal,
		showBannerCancelModal,
		setShowBannerCancelModal,
		setShowEditBannerModal,
		searchString,
		searchStringHandler,
		selectedDate,
		setSelectedDate,
		fetchBanners
	} = props

	function changeSetSelectedDate(range: any) {
		if (!range.to) {
			range.to = range.from
		}
		setSelectedDate(range)
	}

	const [canFilter, setCanFilter] = useState<boolean>(false)
	const [pageIndex, setPageIndex] = useState(0)

	const [isLoadingFilterOptions, setIsLoadingFilterOptions] = useState(false)
	const [amountOfCustomers, setAmountOfCustomers] = useState<number>(0)

	const [filtersByHierarchy, setFiltersByHierarchy] =
		useState<IFilterOptions>({
			DIRECTOR: [],
			GOV: [],
			RESALE: [],
			CHANNEL: [],
			STATE: [],
			CITY: []
		})

	const [pdvs, setPdvs] = useState<string[]>([])

	const handleGetFilterValues = (hieararchyFilters: IFilterOptions) => {
		setFiltersByHierarchy(hieararchyFilters)
	}
	const handleSaveForm  = async () => {
		fetchBanners(pageIndex)
	
	}

	const showFormCreateBanner =
		Object.values(filtersByHierarchy).some((item) => item.length > 0) ||
		pdvs.length > 0

	return (
		<>
			<DefaultLayoutAdmin>
				<S.WrapperView>
					{showBannerCancelModal && (
						<CancelBannerModal
							isOpen={showBannerCancelModal}
							handleCloseModal={() => {
								setShowBannerCancelModal(false)
							}}
							banner={bannerActiveModal}
							onSaveBanner={handleSaveForm}
						/>
					)}
					{showBannerDetailsModal && (
						<DetailsBannerModal
							handleToggleModal={() =>
								setShowBannerDetailsModal(false)
							}
							actionType={bannerActiveModal.actionType}
							apliedTitle={bannerActiveModal.title}
							destinyLink={bannerActiveModal.targetPage}
							exibitionOrder={bannerActiveModal.targetOrder}
							initialDate={bannerActiveModal.initialDate}
							finalDate={bannerActiveModal.finalDate}
							selectedProduct={bannerActiveModal.selectedProduct}
							selectedCategory={
								bannerActiveModal.selectedCategory
							}
							usersImpacted={bannerActiveModal.amountOfCustomers}
							selectedImage={bannerActiveModal.imageURL}
							handleClick={() => setShowBannerDetailsModal(false)}
							selectedVideo={bannerActiveModal.videoURL}
							details={newFilters(bannerActiveModal.filters)}
						/>
					)}
					{showEditBannerModal && (
						<EditBannerModal
							handleToggleModal={() =>
								setShowEditBannerModal(false)
							}
							isOpen={showEditBannerModal}
							handleClick={() => setShowEditBannerModal(false)}
							banner={bannerActiveModal}
							customers={customers}
							onSaveBanner={handleSaveForm}
						/>
					)}
					<div className="container">
						<TitlePage titlePage="Gestão de banners" />
						{}
						<div>
							<div className="create-banner-wrapper">
								<div className="sub-title-banner">
									<h2>Cadastrar Banner</h2>
									<p>
										Selecione nos filtros ativos as unidades
										que serão impactadas pelo Banner.
									</p>
								</div>
								<ButtonExpand
									isDisabled={false}
									isEpxanded={isExpandedForm}
									handleChange={() =>
										setIsExpandedForm((prev) => !prev)
									}
								/>
							</div>
							{/* Form de criação de banner */}
							{isExpandedForm && (
								<>
									<FiltersByHieararchy
										initialFilters={undefined}
										isEnabled={true}
										onFilterChange={setPdvs}
										shouldListPdv={false}
										fetchListPDVsPush={false}
										impactedPdvsLength={amountOfCustomers}
										onPushFilterChange={setAmountOfCustomers}
										getFilterParameters={
											getChallengeFilterParameters
										}
										onLoad={() =>
											setIsLoadingFilterOptions(true)
										}
										onLoadEnd={() =>
											setIsLoadingFilterOptions(false)
										}
										canFilter={canFilter}
										setCanFilter={setCanFilter}
										handleGetFilterValues={
											handleGetFilterValues
										}
									/>
									{!isLoadingFilterOptions &&
										showFormCreateBanner && (
											<FormCreateBanner
												handleCloseFromCreate={() =>
													setIsExpandedForm(false)
												}
												amountOfCustomers={amountOfCustomers}
												filters={{
													diretor:
														filtersByHierarchy.DIRECTOR.map(
															(item) => item.value
														),
													GRC: filtersByHierarchy.GOV.map(
														(item) => item.value
													),
													codeResales:
														filtersByHierarchy.RESALE.map(
															(item) => item.value
														),
													ufs: filtersByHierarchy.STATE.map(
														(item) => item.value
													),
													cities: filtersByHierarchy.CITY.map(
														(item) => item.value
													),
													codePdv: pdvs,
													channels:
														filtersByHierarchy.CHANNEL.map(
															(item) => item.value
														)
												}}
												customers={customers}
												onSaveBanner={handleSaveForm}
											/>
										)}
								</>
							)}
							<div className="button-container">
								<div className={'search-input-wrapper-banner'}>
									<SearchInputBanner
										inputValue={searchString}
										handleChange={searchStringHandler}
										disabled={customers.length === 0}
										backgroundColor={colors.white}
									/>
								</div>
								<div className={'date-input-wrapper'}>
									<DateRangePicker
										selectedDate={selectedDate}
										setSelectedDate={changeSetSelectedDate}
										placeholder="Filtrar por data"
									/>
								</div>
							</div>
							{isLoading ? (
								<div className="loading-wrapper">
									<Loading />
								</div>
							) : customers.length === 0 ? (
								tableData ? (
									<Table
										data={tableData}
										columns={columns}
										canSortByHeader={true}
										navProps={{
											gotoPage(selectedIndex: number) {
												fetchBanners(selectedIndex)
												setPageIndex(selectedIndex)
											},
											nextPage(index = pageIndex) {
												fetchBanners(index)
												setPageIndex(index)
											},
											previousPage(index = pageIndex) {
												fetchBanners(index)
												setPageIndex(index)
											},
											pageCount: bannerMetadata?.totalPages ?? 0,
											pageIndex: pageIndex,
											totalDocs: bannerMetadata?.totalItems ?? 0
										}}
									/>
								) : (
									<EmptyTable
										emptyTableMessage="Selecione os filtros para exibirmos a listagem de Banners"
										icon={emptyTableIcon}
									/>
								)
							) : (
								<Table
									data={tableData}
									columns={columns}
									canSortByHeader={true}
									navProps={{
										gotoPage(selectedIndex: number) {
											fetchBanners(selectedIndex)
											setPageIndex(selectedIndex)
										},
										nextPage(index = pageIndex) {
											fetchBanners(index)
											setPageIndex(index)
										},
										previousPage(index = pageIndex) {
											fetchBanners(index)
											setPageIndex(index)
										},
										pageCount: bannerMetadata?.totalPages ?? 0,
										pageIndex: pageIndex,
										totalDocs: bannerMetadata?.totalItems ?? 0
									}}
								/>
							)}
						</div>
					</div>
				</S.WrapperView>
			</DefaultLayoutAdmin>
		</>
	)
}
