import React from 'react'
import Loading from '../../Loading'
import * as S from '../styles'
import ButtonDefault from '../../buttons/button/button-default'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { TypeButtonEnum } from '../../buttons/button/button-default.types'
import { colorThemeProvider, theme } from '../../../theme/themeProvider'
import { getDropdownButtonLabelOverride } from '../../../shared/utils/multiSelectCheckboxUtils'
import {
	colors,
	primaryMultiselectStyles,
	reactSelectStylesProgramPointsRulesAndChallenges
} from '../../../shared/styles/theme'
import { FilterHierarchyTypeProps } from '../typings'
import ErrorText from '../../error-text/error-text.component'

const labelStylesOfTheSelects = {
	marginLeft: '1.09rem',
	marginTop: '8px'
}

const defaultSelectProps = {
	getDropdownButtonLabel: getDropdownButtonLabelOverride,
	styles: {
		...reactSelectStylesProgramPointsRulesAndChallenges,
		...{
			control: (styles: any) => ({ ...styles }),
			dropdownButton: (styles: any, state: any) => ({
				...styles,
				width: '100%',
				height: 55,
				padding: 16,
				border: `1px solid ${colors.fourthGrey}`,
				borderRadius: ' 4px',
				boxShadow: 'none',
				background: 'white'
			})
		}
	},
	...primaryMultiselectStyles
}

export function FilterHierarchyType({
	isEnabled,
	isLoading,
	salesHierarchyFiltersProps,
	otherFiltersProps,
	canFilter,
	handleClearFields,
	handleClickFilterListPdvs,
	setIsFilter,
	impactedPdvs,
	errorMessage,
	labelTitle = 'Filtro por Hierarquia'
}: FilterHierarchyTypeProps) {
	return (
		<S.PageSession id="filter-by-hierarchy">
			{/* Inicio filtro por hierarquia */}
			<h2 className="pg-subtitle">{labelTitle}</h2>

			{isLoading ? (
				<div className="loading-wrapper">
					<Loading color="red" />
				</div>
			) : (
				<>
					<S.FiltersRow
						disabled={!isEnabled}
						className="sales-hierarchy-filters">
						{salesHierarchyFiltersProps.map((filterProps) => (
							<div
								key={filterProps.key}
								className="select-filter">
								<label
									style={{
										...labelStylesOfTheSelects,
										color:
											!isEnabled || filterProps.isDisabled
												? theme.palette.gray.light
												: colorThemeProvider.primary
														.main
									}}
									className="multiselect-label">
									{filterProps.placeholderButtonLabel}
								</label>
								<S.StyledWrapperMultSelect
									id={filterProps.key}
									disabled={filterProps.isDisabled}>
									<ReactMultiSelectCheckboxes
										{...filterProps}
										{...defaultSelectProps}
									/>
								</S.StyledWrapperMultSelect>
							</div>
						))}
					</S.FiltersRow>

					<S.FiltersRow
						disabled={!isEnabled}
						className="other-filters">
						{otherFiltersProps.map((filterProps) => (
							<div
								key={filterProps.key}
								className="select-filter">
								<label
									style={{
										...labelStylesOfTheSelects,
										color:
											!isEnabled || filterProps.isDisabled
												? theme.palette.gray.light
												: colorThemeProvider.primary
														.main
									}}
									className="multiselect-label">
									{filterProps.placeholderButtonLabel}
								</label>
								<S.StyledWrapperMultSelect
									id={filterProps.key}
									disabled={filterProps.isDisabled}>
									<ReactMultiSelectCheckboxes
										{...filterProps}
										{...defaultSelectProps}
									/>
								</S.StyledWrapperMultSelect>
							</div>
						))}

						<S.ImpactPdvWrapper>
							<label>PDV IMPACTADOS</label>
							<span>{impactedPdvs}</span>
						</S.ImpactPdvWrapper>

						{isEnabled && (
							<S.WrapperActions isAbleToCleanFielters={canFilter}>
								{/* Funcionalidade de limpeza de filtros */}
								<DeleteOutlineIcon
									style={{ color: theme.palette.gray.main }}
									onClick={handleClearFields}
								/>
								<ButtonDefault
									buttonText="Filtrar"
									idElement="filter-itens"
									onClick={() => {
										handleClickFilterListPdvs()
										setIsFilter(true)
									}}
									height="41px"
									width="72px"
									fontSize="10px"
									type={
										canFilter
											? TypeButtonEnum.PRIMARY
											: TypeButtonEnum.DISABLED
									}
								/>
							</S.WrapperActions>
						)}
					</S.FiltersRow>
				</>
			)}
			{!isLoading && <ErrorText error={errorMessage} />}
		</S.PageSession>
	)
}
