import React from 'react'
import { ISecondaryButtonProps } from './types'

function SecondaryButton(props: ISecondaryButtonProps) {
	return (
		<button className="secondary-button" {...props}>
			{props.title}
		</button>
	)
}

export default SecondaryButton
