interface IHandleFilterDeliveryDates {
	data: any[]
	input: string
	params: string[]
}
export const handleFilterDeliveryDates = ({
	data,
	input,
	params
}: IHandleFilterDeliveryDates) => {
	return data.filter((item) =>
		params.some((param) =>
			item[param].toLowerCase().startsWith(input.toLowerCase())
		)
	)
}
