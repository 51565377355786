import React, { useState } from 'react'
import { ISidebarReview } from './siderbar-review.types'

import CloseIcon from '@mui/icons-material/Close'
import * as S from './sidebar-review.styles'
import SidebarSucess from './components/sidebar-sucess/sidebar-sucess.component'
import SidebarHelp from './components/sidebar-help/sidebar-help.component'
import { fnOpenChat } from '../../../../layout/footer-layout/utils/open-chat'
import ReviewSidebar from './components/review-sidebar/review-sidebar.component'
import { ISaveReviewBody, saveReview } from '../../services/review.service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../shared/utils/toaster'
import LoadingCcontainer from '../../../../components/loading-container/loading-container.component'

function SidebarReview({
	isOpen,
	documentNumber,
	handleCloseSidebar
}: ISidebarReview) {
	const [isSucessReview, setIsSucessReview] = useState<boolean>(false)
	const [isHelpSidebarOpen, setIsHelpSidebarOpen] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleOpenChat = () => {
		fnOpenChat()
	}

	async function skipRatting({
		comment,
		document,
		rate,
		rateSource,
		skipRate
	}: ISaveReviewBody) {
		setIsLoading(true)
		try {
			const response = await saveReview({
				comment,
				document,
				rate,
				rateSource,
				skipRate
			})
			if (response.status !== 200 && response.status !== 201) {
				cogoToast.error(
					'Algo deu errado, tente novamente mais tarde',
					cogoDefaultOptions
				)
				return
			} else {
				handleCloseSidebar()
			}
		} catch (error) {
			cogoToast.error(
				'Algo deu errado, tente novamente mais tarde',
				cogoDefaultOptions
			)
		}
		setIsLoading(false)
	}

	async function handleSkipRating() {
		if (!isSucessReview && !isHelpSidebarOpen) {
			// Usuário escolher não realizar o review
			await skipRatting({
				document: documentNumber,
				skipRate: true,
				comment: '',
				rateSource: 'web'
			})
		} else if (isHelpSidebarOpen) {
			setIsHelpSidebarOpen(false)
		} else if (isSucessReview) {
			handleCloseSidebar()
		}
	}

	// Verificando se o modal esta aberto
	if (!isOpen) return null

	return (
		<>
			<LoadingCcontainer isLoading={isLoading} />
			<S.Sidebar anchor={'right'} open={isOpen} onClose={() => {}}>
				<div className="close-icon-wrapper">
					<CloseIcon
						onClick={() => {
							handleSkipRating()
						}}
					/>
				</div>
				{isSucessReview && (
					<SidebarSucess handleModal={handleCloseSidebar} />
				)}
				{isHelpSidebarOpen && (
					<SidebarHelp handleOpenChat={handleOpenChat} />
				)}
				{!isSucessReview && !isHelpSidebarOpen && (
					<ReviewSidebar
						handleOpenHelpModal={setIsHelpSidebarOpen}
						documentNumber={documentNumber}
						setIsLoading={setIsLoading}
						setIsSucessReview={setIsSucessReview}
					/>
				)}
			</S.Sidebar>
		</>
	)
}

export default SidebarReview
