import axios from 'axios'
import { Maybe } from '../../shared/interfaces/common'
import {
	axiosGPInstance,
	getJwtAuthGpAcessToken
} from '../../shared/services/axiosInstace'
import { store } from '../../shared/store'
import { ISelectOption } from '../../shared/utils/SelectOptions'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { Product } from './mapper'

export async function getProducts(
	isBonification?: boolean,
	bonificationFeatureFlag?: boolean
): Promise<Product[]> {
	try {
		const response = await axiosGPInstance.get('/materials', {
			params: { isBonification },
			headers: {
				'api-version': bonificationFeatureFlag ? '15045' : '1'
			}
		})

		return response.data
	} catch (error) {
		return []
	}
}

// aqui
export async function postProduct(
	data: Product,
	customerSegment?: string[],
	categoriesFeatureFlag = false
) {
	try {
		const response = await axiosGPInstance.post(
			'/materials',
			{
				...data,
				customerSegment
			},
			{
				headers: {
					'api-version': categoriesFeatureFlag ? '17241' : '1'
				}
			}
		)

		return response.data
	} catch (error) {
		return
	}
}

export async function uploadImage(payload: {
	file: File
	fileName: string
}): Promise<{ url: string } | boolean> {
	try {
		const token = await getJwtAuthGpAcessToken()
		const customer = store.getState().customer

		const formData = new FormData()

		formData.append(
			'upload',
			payload.file,
			`${payload.fileName}.${payload.file.name.split('.')[1]}`
		)

		const response = await axios.post(
			`${'https://jyvxvsusib.execute-api.sa-east-1.amazonaws.com/prd'}/bucketbpgpbanners`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
					userID: customer.CustomerID
				}
			}
		)
		return response.data
	} catch (err) {
		const error = err as { message: string }
		cogoToast.error(error.message, cogoDefaultOptions)
		return false
	}
}

export async function getAvailableProductMeasures(): Promise<
	Maybe<['CX', 'PAC', 'UN']>
> {
	try {
		const response = await axiosGPInstance.get(
			'/materials/salesunitsmeasure'
		)

		return response.data
	} catch (error) {
		return
	}
}

export async function deleteImage(fileName: string) {
	try {
		const response = await axiosGPInstance.delete(
			`/uploadimages?fileName=${fileName}`
		)

		return response.data
	} catch (error) {
		return
	}
}

export async function handleFilterChannels(): Promise<ISelectOption[] | null> {
	try {
		// const response = await axiosGPInstance.get('/filters/channels')
		const response = await axiosGPInstance.get('/customers/populatefilters')

		return response.data.channels
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function getMaterialChannelCustomByID(
	MaterialID: string
): Promise<string[] | void> {
	try {
		const response = await axiosGPInstance.get(`/materials/${MaterialID}`)

		return response.data
	} catch (error) {
		return
	}
}
