import { AxiosResponse } from 'axios'
import { apiAdmin } from '../../shared/services/axiosInstace'

export async function handleConsultFilterData(payload: any) {
	try {
		const response: AxiosResponse = await apiAdmin.post(
			'/customer-research/filterCustomer',
			payload
		)

		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}
export async function handleInformation(
	customerId: string,
	salesOrganizationID: string
) {
	try {
		const response: AxiosResponse = await apiAdmin.get(
			`/customer-research/basicCustomerInformation?customerId=${customerId}&salesOrganizationId=${salesOrganizationID}`
		)
		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function fetchOrders(
	salesOrganizationId: string,
	customerId: string
) {
	try {
		const response: AxiosResponse = await apiAdmin.get(
			`/customer-research/salesOrderTracking?customerId=${customerId}&salesOrganizationId=${salesOrganizationId}`
		)
		return response.data
	} catch (error) {
		throw error
	}
}
