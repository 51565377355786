import React from 'react'

import { IChallenge } from '../../../shared/interfaces/pointsProgramChallenge'

import * as S from './styles'

export type ChallengeAlertCannotBeEditedProps = {
	challenge: IChallenge
}

export default function ChallengeAlertCannotBeEdited(
	props: ChallengeAlertCannotBeEditedProps
) {
	const {
		challenge: { participantsQuantity }
	} = props

	const alertLabel = `Este Desafio está ativo${
		Number(participantsQuantity) > 0
			? 'e existem Clientes participando'
			: ''
	}.`

	return (
		<S.ChallengeAlertCannotBeEditedWrapper>
			<S.ContentWrapper>
				<S.AlertLabel>{alertLabel}</S.AlertLabel>
				<S.AlertDescription>
					Não será possível Cancelar ou Editar qualquer informação de
					um Desafio que esteja ativo.
				</S.AlertDescription>
			</S.ContentWrapper>
		</S.ChallengeAlertCannotBeEditedWrapper>
	)
}
