import * as React from 'react'
import * as S from './header-coupons.styles'

import { DataSelectorList } from '../../../../../components/data-selector-list'
import SearchInput from './components/search-input/searchinput.component'
import ButtonDefault from '../../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../../components/buttons/button/button-default.types'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'
import { IHeaderProps } from './header-coupon.types'
import { useHistory } from 'react-router-dom'
import downloadCsvFileByString from '../../../../../utils/convertToCsvFile'
import { formatStringSimple } from '../../utils/format-string-simple'

function HeaderCoupon({
	options,
	onChangeOptions,
	handleSearchCouponByIdOrName,
	handleExtractData,
	selectedOption
}: IHeaderProps): JSX.Element {
	const [typeView, setTypeView] = React.useState(selectedOption)
	const [searchString, setSearchString] = React.useState('')
	const history = useHistory()
	const searchStringHandler = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value
			setSearchString(value)
		},
		[]
	)
	const handleSelectTypeView = React.useCallback(
		(prop: React.ChangeEvent<HTMLInputElement>) => {
			onChangeOptions({
				name: options.find(
					(target) => target.value === prop.target.value
				)!.name,
				value: prop.target.value
			})
			setTypeView({
				name: options.find(
					(target) => target.value === prop.target.value
				)!.name,
				value: prop.target.value
			})
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
	React.useEffect(() => {
		setTypeView(selectedOption)
	}, [selectedOption])
	return (
		<S.ContainerWrapper>
			<S.Title>Histórico de Cupons</S.Title>
			<S.Container>
				<S.Actions>
					<S.ContainerDataSelector>
						<DataSelectorList
							title={'Visualizar'}
							list={options}
							selectedOption={typeView}
							handleChange={handleSelectTypeView}
							isNative={false}
						/>
					</S.ContainerDataSelector>
					<S.ContainerSearchInput>
						<SearchInput
							handleChange={searchStringHandler}
							searchButtonLabel="Buscar"
							text={searchString}
							handleSearch={() => {
								handleSearchCouponByIdOrName(
									formatStringSimple(searchString)
								)
							}}
							placeholder="Buscar por Nome ou ID do Cupom"
							label={''}
						/>
					</S.ContainerSearchInput>
				</S.Actions>

				<S.ButtonActions>
					<ButtonDefault
						buttonText={'NOVO CUPOM'}
						idElement="new-coupon"
						onClick={() => history.push(`/coupon/create-coupon`)}
						fontSize="10px"
						width="auto"
						height="42px"
						border="1px #D8D2D3 solid"
						type={TypeButtonEnum.PRIMARY}
					/>
					<ButtonDefault
						buttonText={'EXTRAIR HISTÓRICO'}
						idElement="extract-history"
						onClick={async () => {
							await handleExtractData().then((response) =>
								downloadCsvFileByString(response)
							)
						}}
						fontSize="10px"
						width="auto"
						height="42px"
						border="1px #D8D2D3 solid"
						type={TypeButtonEnum.OUTLINED}
						icon={<SimCardDownloadIcon />}
					/>
				</S.ButtonActions>
			</S.Container>
		</S.ContainerWrapper>
	)
}

export default HeaderCoupon
