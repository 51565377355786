import cogoToast from 'cogo-toast'
import { createElement, useEffect, useState } from 'react'

import { ICartItem } from '../../../../shared/interfaces/cart'
import cogoDefaultOptions from '../../../../shared/utils/toaster'
import { findLastRequestCancelStatusDescription } from '../data'
import { changeOrderStatus } from '../service'
import { IProps } from './types'
import CancellationRequestModal from './view'

function CancellationRequestModalContainer(props: IProps) {
	const {
		isActive,
		closeModal,
		request,
		calcTotalOrder,
		fetchCancellationRequests,
		isLoading
	} = props

	const SLIDER_STATE = {
		RECEBIDO: 0,
		'EM ATENDIMENTO': 1,
		ATENDIDA: 2,
		CONCLUIDO: 2
	}

	const SLIDER_STATE_REV: any = {
		0: 'RECEBIDO',
		1: 'EM ATENDIMENTO',
		2: 'ATENDIDA'
	}

	const REQUEST_CANCEL_STATUS_ID: any = {
		0: '001',
		1: '002',
		2: '003'
	}
	const [sliderState, setSlideState] = useState(0)

	const requestStatus = findLastRequestCancelStatusDescription(
		request?.RequestsCancelStatus
	)
	const status = SLIDER_STATE_REV[sliderState]
	const requestCancelStatusID = REQUEST_CANCEL_STATUS_ID[sliderState]
	const requestCancelID = request.RequestCancelID

	const tableData: ICartItem[] =
		request.SalesOrderItems.map((item) => ({
			MaterialID: item.ProductID,
			OrderQuantity: item.Quantity,
			UnitMeasureSale: item.UnitMeasureSale,
			Price: item.Price * item.Quantity,
			ImageUrl: item.ImageUrl,
			Size: item.Package,

			// empty
			PriceByQuantity: [],
			StockPosition: 0,
			Description: item.Description,
			PackType: ''
		})) || []

	function handleOnChangeSlider(_: any, value: number) {
		setSlideState(value)
	}

	function handleSliderChange() {
		if (!isActive || !status || !requestCancelStatusID || !requestCancelID)
			return
		;(async () => {
			if (sliderState === 0) {
				setSlideState(1)
			} else if (
				sliderState === 1 &&
				requestStatus !== SLIDER_STATE_REV[sliderState]
			) {
				await changeOrderStatus(requestCancelID, requestCancelStatusID)
				await fetchCancellationRequests()
				closeModal()

				cogoToast.success(
					`O Status foi alterado para ${SLIDER_STATE_REV[sliderState]} com sucesso!`,
					cogoDefaultOptions
				)
			} else if (
				sliderState === 2 &&
				requestStatus !== SLIDER_STATE_REV[sliderState]
			) {
				try {
					await changeOrderStatus(
						requestCancelID,
						requestCancelStatusID
					)

					cogoToast.success(
						`O Status foi alterado para ${SLIDER_STATE_REV[sliderState]} com sucesso!`,
						cogoDefaultOptions
					)
				} catch (error) {
					// setSlideState(1)
				} finally {
					await fetchCancellationRequests()
					closeModal()
				}
			}
		})()
	}

	function handleSetDefaultSliderState() {
		const requestCancelStatus = findLastRequestCancelStatusDescription(
			request?.RequestsCancelStatus
		)
		const sliderState = SLIDER_STATE[requestCancelStatus]

		setSlideState(sliderState || 0)
	}

	useEffect(handleSliderChange, [sliderState])
	useEffect(handleSetDefaultSliderState, [request])

	const viewProps = {
		isActive,
		closeModal,
		request,
		calcTotalOrder,
		tableData,
		sliderState,
		handleOnChangeSlider,
		fetchCancellationRequests,
		isLoading
	}

	return createElement(CancellationRequestModal, viewProps)
}

export default CancellationRequestModalContainer
