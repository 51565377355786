import React, { ChangeEvent, useEffect } from 'react'
import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'
import TitlePage from '../../../components/title-page/title-page.component'
import Table from '../../../components/Table'
import Loading from '../../../components/Loading'

import { EditIcon } from './transactional-push.styles'

import { pushNotificationsAdmin } from '../services/push-notifications-admin'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import Toggle from '../../../components/toggle/toggle.component'

import {
	PushTransactionalModelStatus,
	PushTransactionalProps,
	IHandleChangeToggle
} from './transactional-push.types'
import EditPushTransactionalModal from '../components/push-modals/edit-push-transactional-modal/edit-push-transactional-modal.component'

const TransactionalPush = () => {
	const [isLoading, setIsLoading] = React.useState(false)
	const [reloadTransactionalPush, setReloadTransactionalPush] =
		React.useState(false)
	const [transactionalPush, setTransactionalPush] = React.useState<any>([])
	const [isOpenEditModal, setIsOpenEditModal] = React.useState(false)
	const [selectedPush, setSelectedPush] =
		React.useState<PushTransactionalProps>({
			ID: '',
			rule: '',
			title: '',
			message: '',
			description: '',
			status: PushTransactionalModelStatus.DISABLED,
			createdAt: '',
			createdBy: '',
			modifiedAt: '',
			modifiedBy: ''
		})

	const [textAreaValue, setTextAreaValue] = React.useState('')
	const [titlePush, setTitlePush] = React.useState('')
	const [descriptionPush, setDscriptionPush] = React.useState('')

	const handleChangeToggle = async ({ id, status }: IHandleChangeToggle) => {
		setIsLoading(true)
		try {
			await pushNotificationsAdmin.patchStatusTransactionalPush(
				id,
				status
			)
			setReloadTransactionalPush(true)
		} catch (error) {
			cogoToast.error(
				'Erro ao ativar/desativar notificação de push.',
				cogoDefaultOptions
			)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		async function getPushTransactional() {
			setIsLoading(true)

			try {
				const response =
					await pushNotificationsAdmin.getTransactionalPush()
				const historyArray = response.map(
					(item: PushTransactionalProps) => ({
						...item,
						createdAt: new Date(
							item.createdAt
						).toLocaleDateString(),
						edit: (
							<EditIcon
								isDisabled={false}
								onClick={() => {
									setIsOpenEditModal(true)
									setSelectedPush({ ...item })
								}}
							/>
						),
						status: (
							<Toggle
								isChecked={
									item.status ===
									PushTransactionalModelStatus.ENABLED
										? true
										: false
								}
								handleChecked={(event) =>
									handleChangeToggle({
										id: item.ID,
										status: event.target.checked
											? PushTransactionalModelStatus.ENABLED
											: PushTransactionalModelStatus.DISABLED
									})
								}
							/>
						)
					})
				)
				setReloadTransactionalPush(false)
				setTransactionalPush(historyArray)
			} catch (error) {
				setReloadTransactionalPush(false)
				cogoToast.error(
					'Erro ao trazer histórico de push.',
					cogoDefaultOptions
				)
			} finally {
				setIsLoading(false)
			}
		}
		if (!transactionalPush.length || reloadTransactionalPush) {
			getPushTransactional()
		}
	}, [transactionalPush, reloadTransactionalPush])

	useEffect(() => {
		if (selectedPush) {
			setTextAreaValue(selectedPush.message)
			setTitlePush(selectedPush.title)
			setDscriptionPush(selectedPush.description)
		}
	}, [selectedPush])

	const handleChangeTextTitle = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setTitlePush(event.target.value)
	}

	const handleChangeTextArea = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setTextAreaValue(event.target.value)
	}

	const handleChangeTextDescription = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setDscriptionPush(event.target.value)
	}

	async function editHistoryPush() {
		if (!textAreaValue || !titlePush || !descriptionPush) return
		setIsLoading(true)
		try {
			await pushNotificationsAdmin.editTransactionalPush(
				selectedPush?.ID,
				titlePush,
				textAreaValue,
				descriptionPush
			)
			cogoToast.success(
				'A alteração foi realizada com sucesso.',
				cogoDefaultOptions
			)
			setIsOpenEditModal(false)
			setReloadTransactionalPush(true)
		} catch (error) {
			cogoToast.error(
				'Erro ao editar notificação de push.',
				cogoDefaultOptions
			)
		}
	}

	const columns = React.useMemo(
		() => [
			{ Header: 'ID', accessor: 'ID' },
			{ Header: 'Título', accessor: 'title' },
			{ Header: 'Data da criação', accessor: 'createdAt' },
			{ Header: 'Regra', accessor: 'rule' },
			{ Header: 'Editar', accessor: 'edit' },
			{ Header: 'Status', accessor: 'status' }
		],
		[]
	)

	return (
		<DefaultLayoutAdmin>
			<>
				<EditPushTransactionalModal
					handleToggleModal={() => setIsOpenEditModal(false)}
					isOpen={isOpenEditModal}
					pushTitle={titlePush}
					messagemAplied={textAreaValue}
					description={descriptionPush}
					handleChangeTitle={handleChangeTextTitle}
					handleChangeMessage={handleChangeTextArea}
					handleChangeDescription={handleChangeTextDescription}
					handleClickEditModal={() => editHistoryPush()}
					disabledButton={
						!textAreaValue || !titlePush || !descriptionPush
							? true
							: false
					}
					isLoading={isLoading}
				/>
				<TitlePage titlePage="Modelos Push Transacionais" />
				{isLoading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<Table data={transactionalPush} columns={columns} />
				)}
			</>
		</DefaultLayoutAdmin>
	)
}
export default TransactionalPush
