import React from 'react'
import { NavLink } from 'react-router-dom'
import { IRouteDataV2 } from '../../Routes/types'

interface NavLinkDetailsProps {
	routes: IRouteDataV2[]
}

export const NavLinkDetails: React.FC<NavLinkDetailsProps> = ({ routes }) => (
	<ul className="routes-section-list">
		{routes.map((route) => (
			<li key={route.name} className="routes-list-item">
				<NavLink
					className="route-link"
					activeClassName="route-link-active-route-link"
					to={route.path}>
					<div className="route-link-name" id={route.name}>
						{route.name}
					</div>
				</NavLink>
			</li>
		))}
	</ul>
)
