import React from 'react'
import * as S from '../../image-slider.styles'

import ReactPlayer from 'react-player'
import PlayerButton from '../../components/player-button/player-button.component'
import MutedButton from '../../components/muted-button/muted-button.component'
import { ImageVideoProps } from './image-video.types'

function ImageVideoComponent({
	imageUrl,
	videoUrl,
	handleOnProgress,
	isPlaying,
	setIsPlaying
}: ImageVideoProps) {
	const [muted, setMuted] = React.useState(true)

	return (
		<>
			{!isPlaying ? (
				<S.VideoPlayerWrapper>
					<S.ImageSliderWrapper
						style={{
							backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%), url(${imageUrl})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'contain',
							backgroundPosition: 'center'
						}}></S.ImageSliderWrapper>
					<PlayerButton
						isPlaying={isPlaying}
						onClickPlay={() => setIsPlaying(true)}
						onClickPause={() => setIsPlaying(false)}
					/>
				</S.VideoPlayerWrapper>
			) : (
				<S.VideoPlayerWrapper>
					{/* @ts-ignore */}
					<ReactPlayer
						url={videoUrl}
						playing={isPlaying}
						onEnded={() => {
							setIsPlaying(false)
							handleOnProgress(false)
						}}
						onProgress={() => handleOnProgress(true)}
						muted={muted}
						width={'683px'}
						height={'522px'}
						style={{
							background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%)`
						}}
					/>
					<PlayerButton
						isPlaying={isPlaying}
						onClickPlay={() => setIsPlaying(true)}
						onClickPause={() => setIsPlaying(false)}
					/>
					<MutedButton
						isMuted={muted}
						onClickMuted={() => setMuted(!muted)}
					/>
				</S.VideoPlayerWrapper>
			)}
		</>
	)
}

export default ImageVideoComponent
