import React, { ChangeEvent, useCallback, useRef } from 'react'
import { RangeModifier } from 'react-day-picker'

import {
	InputSelectPeriodPicker,
	SelectPrimary
} from '../../../shared/components'
import { isNegative } from '../../../shared/utils/number'
import { defaultScoreValueOptions } from '../../../shared/utils/points'
import { RuleModel } from '../RuleModel'

import * as S from './styles'
import { convertValueToSelectOption, getScoreValueRatio } from './util'

interface IProps {
	rule: RuleModel
	onChangePeriod: (value: RangeModifier) => void
	onChangePointRatio: (value: string) => void
	onChangeCheckboxCustomPointRatio: () => void
}

export function PointsGainRatioRuleSettings({
	rule,
	onChangePeriod,
	onChangeCheckboxCustomPointRatio,
	onChangePointRatio
}: IProps) {
	const ratioInputRef = useRef(null)
	const handlers = {
		changePeriod: useCallback(
			(value: RangeModifier): void => {
				onChangePeriod(value)
			},
			[onChangePeriod]
		),
		selectPointRatio: useCallback(
			(option: { value: string }) => {
				const value = option.value
				onChangePointRatio(value)
			},
			[onChangePointRatio]
		),
		changeCustomPointRatio: (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value
			const numberValue = Number(value)

			if (!Number.isNaN(numberValue) && !isNegative(numberValue)) {
				onChangePointRatio(value)
			}
		},
		changeCheckboxCustomPointRatio: () => {
			if (rule.canChangeCustomPointRatio && ratioInputRef.current) {
				ratioInputRef.current.value = undefined
			}
			onChangeCheckboxCustomPointRatio()
		}
	}

	return (
		<S.Container>
			<div className="s-row">
				<h2 className="pg-subtitle">Configurações</h2>
				<div
					className="checkbox-wrapper"
					onClick={handlers.changeCheckboxCustomPointRatio}>
					<input
						type="checkbox"
						checked={rule.canChangeCustomPointRatio}
					/>
					<label className="pg-subtitle">
						Cadastrar Proporção Customizada
					</label>
				</div>
			</div>
			<div className="s-row">
				<div className="setting-inputs-wrapper">
					<InputSelectPeriodPicker
						label="Início e término da regra"
						placeholder="Defina um intervalo"
						onChange={handlers.changePeriod}
						value={{
							from: new Date(rule.ruleData.initialDate),
							to: new Date(rule.ruleData.finalDate)
						}}
						style={{
							width: '100%'
						}}
					/>
					<SelectPrimary
						placeholder="PROPORÇÃO GANHOS DE PONTO"
						options={defaultScoreValueOptions.map((option) =>
							convertValueToSelectOption(option)
						)}
						value={rule.ruleData.scoreValue}
						onChange={handlers.selectPointRatio}
						disabled={rule.canChangeCustomPointRatio}
						classNamePrefix="react-select"
						className="react-select"
					/>
				</div>
				<div className="custom-ration-input-wrapper">
					<input type="number" value={1} disabled />
					<input
						ref={ratioInputRef}
						type="number"
						value={getScoreValueRatio(rule.ruleData.scoreValue)}
						onChange={handlers.changeCustomPointRatio}
						disabled={!rule.canChangeCustomPointRatio}
					/>
				</div>
			</div>
		</S.Container>
	)
}
