import React, {
	createContext,
	useContext,
	useState,
	ReactNode,
	useCallback
} from 'react'

interface ProductAdded {
	price: number
	title: number
	quantity: number
	group: number
}

type CartContextType = {
	totalPrice: number
	productAdded: ProductAdded[]
	setProductAdded: (products: ProductAdded[]) => void
	updateTotalPrice: (newPrice: number) => void
	clearContextComboFlex: () => void
}

// Criando o contexto inicial
const ComboFlexContext = createContext<CartContextType | undefined>(undefined)

// Provedor de contexto que envolve o componente pai
type ComboFlexProviderProps = {
	children: ReactNode
}

export const ComboFlexProvider: React.FC<ComboFlexProviderProps> = ({
	children
}) => {
	const [totalPrice, setTotalPrice] = useState<number>(0)
	const [productAdded, setProductAdded] = useState<ProductAdded[]>([])

	const updateTotalPrice = useCallback((newPrice: number) => {
		setTotalPrice(newPrice)
	}, [])

	const clearContextComboFlex = useCallback(() => {
		// Adicionar aqui todos os
		setTotalPrice(0)
		setProductAdded([])
	}, [])

	const contextValue: CartContextType = {
		totalPrice,
		updateTotalPrice,
		productAdded,
		setProductAdded,
		clearContextComboFlex
	}

	return (
		<ComboFlexContext.Provider value={contextValue}>
			{children}
		</ComboFlexContext.Provider>
	)
}

// Hook para consumir o contexto
export const useComboFlexContext = (): CartContextType => {
	const context = useContext(ComboFlexContext)
	if (!context) {
		throw new Error(
			'useContextComboFlex deve ser usado dentro de um CartProvider'
		)
	}
	return context
}
