import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IHistoryActionCreators
} from '../../interfaces/action'
import { IStoreHistory } from '../../interfaces/store'

export const {
	Types,
	Creators
}: IActionsFromCreators<IHistoryActionCreators> = createActions({
	EmitReplaceHistoryRoute: ['route'],
	reset: []
})

const INITIAL_STATE: IStoreHistory = ''

const replace = (state = INITIAL_STATE, action: any) => action.route
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

export default createReducer<IStoreHistory, Action<IHistoryActionCreators>>(
	INITIAL_STATE,
	{
		[Types.EMIT_REPLACE_HISTORY_ROUTE]: replace,
		[Types.RESET]: reset
	}
)
