import React from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import { ItemText } from '../item-text/item-text.component'
import * as S from './modal-review-coupon.styles'
import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import { TModalReviewCouponView } from './modal-review-coupon.types'

import useModalReviewCoupon from './use-modal-review-coupon'
import ControlSelect from '../../../../../components/multiple-select/multiple-select'
import { useForm } from 'react-hook-form'
import Loading from '../../../../../components/Loading'
import { formatProductList } from '../../utils/format-product-list'

export function ModalReviewCouponView({
	name,
	brand,
	cumulative,
	type,
	category,
	showInApp,
	minValueAmount,
	date = {
		dateStart: new Date(),
		dateEnd: new Date()
	},
	package: localPackage,
	couponInfo,
	handleClick,
	handleToggleModal,
	getValues,
	isOpen,
	usersImpacted,
	discountType,
	discountValue,
	productsList,
	filters,
	code,
	isEditCouponFormMode,
	couponId,
	status,
	tradeStatus,
	cupomUsedCount,
	isPdvRelated
}: TModalReviewCouponView) {
	const {
		conditionsModalReviewCoupon,
		categoriesMapper,
		formattedProductList,
		handleCreateCoupon,
		handleEditCoupon,
		loadingEditCoupon,
		loadingCreateCoupon,
		formatDate
	} = useModalReviewCoupon({
		name,
		brand,
		cumulative,
		type,
		category,
		showInApp,
		minValueAmount,
		date,
		package: localPackage,
		couponInfo,
		handleClick,
		handleToggleModal,
		isOpen,
		usersImpacted,
		discountType,
		discountValue,
		productsList,
		getValues,
		filters,
		code,
		isEditCouponFormMode,
		couponId,
		status,
		tradeStatus,
		cupomUsedCount,
		isPdvRelated
	})

	const { filtersByHieararchy } = filters
	const { control } = useForm()

	if (!isOpen) return null

	const mappedProductDetails = formatProductList(productsList)

	return (
		<LayoutModal
			modalTitle={
				isEditCouponFormMode ? 'Editar Cupom' : 'Cadastrar Cupom'
			}
			buttonText="Enviar"
			handleClick={
				isEditCouponFormMode ? handleEditCoupon : handleCreateCoupon
			}
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			buttonTextReturn="Voltar">
			{loadingEditCoupon || loadingCreateCoupon ? (
				<S.LoadingWrapper>
					<Loading />
				</S.LoadingWrapper>
			) : (
				<S.ModalReviewCouponContainer>
					<S.SectionReview>
						<S.SectionReviewTitle>Informações</S.SectionReviewTitle>

						<S.ModalItemLine>
							<ItemText title="NOME" description={name} />
							<ItemText title="CÓDIGO" description={code} />
						</S.ModalItemLine>

						<S.ModalItemLine>
							<ItemText
								title="DATA DE INÍCIO DE DISPONIBILIDADE"
								description={formatDate(date.dateStart)}
							/>
							<ItemText
								title="QUANTIDADE DE CUPONS"
								description={
									conditionsModalReviewCoupon.quantityCouponInfo
								}
							/>
						</S.ModalItemLine>

						<S.ModalItemLine>
							<ItemText
								title="DESCONTO"
								description={
									conditionsModalReviewCoupon.formattedDiscountValue
								}
							/>
							<ItemText
								title="DATA FINAL DE DISPONIBILIDADE"
								description={formatDate(date.dateEnd)}
							/>
						</S.ModalItemLine>

						<ItemText
							title="EXIBIR NO APP"
							description={
								conditionsModalReviewCoupon.shouldShowInApp
							}
						/>
					</S.SectionReview>

					<S.SectionReview>
						<S.SectionReviewTitle>Regras</S.SectionReviewTitle>

						<S.ModalItemLine>
							<ItemText
								title="VALOR MÍNIMO DE COMPRA"
								description={`R$ ${minValueAmount}`}
							/>
							<ItemText
								title="CUMULATIVO"
								description={
									conditionsModalReviewCoupon.isCumulative
								}
							/>
						</S.ModalItemLine>

						<S.ModalItemLine>
							<ItemText
								title="TIPO"
								description={
									conditionsModalReviewCoupon.typeCoupon
								}
							/>
						</S.ModalItemLine>

						{/* Cupom do tipo carrinho */}
						{conditionsModalReviewCoupon.showProductsSection && (
							<>
								<S.RowFilterProductsRow>
									<S.RulesSelectsWrapper>
										<ControlSelect
											disabled={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(category) => {
																return {
																	label: category.categoryId,
																	value: category.categoryId
																}
															}
													  ).length === 0
													: category.length === 0
											}
											control={control}
											name="category"
											label="Categoria"
											options={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(category) => {
																return {
																	label: category.categoryId,
																	value: category.categoryId
																}
															}
													  )
													: category?.map((item) => ({
															label: categoriesMapper[
																item
															],
															value: item
													  }))
											}
											value={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(category) =>
																category.categoryId
													  )
													: category
											}
										/>
										<ControlSelect
											disabled={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(brand) => {
																return {
																	label: brand.brand,
																	value: brand.brand
																}
															}
													  ).length === 0
													: brand.length === 0
											}
											control={control}
											name="brand"
											label="Marca"
											options={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(brand) => {
																return {
																	label:
																		brand.brand ??
																		'',
																	value:
																		brand.brand ??
																		''
																}
															}
													  )
													: brand?.map((item) => ({
															label: item,
															value: item
													  }))
											}
											value={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(brand) =>
																brand.brand
													  )
													: brand
											}
										/>
										<ControlSelect
											disabled={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(
																packageProduct
															) => {
																return {
																	label: packageProduct.package,
																	value: packageProduct.package
																}
															}
													  ).length === 0
													: localPackage.length === 0
											}
											control={control}
											name="package"
											label="Embalagem"
											options={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(
																packageProduct
															) => {
																return {
																	label:
																		packageProduct.package ??
																		'',
																	value:
																		packageProduct.package ??
																		''
																}
															}
													  )
													: localPackage?.map(
															(item) => ({
																label: item,
																value: item
															})
													  )
											}
											value={
												isEditCouponFormMode
													? mappedProductDetails.map(
															(packageProduct) =>
																packageProduct.package
													  )
													: localPackage
											}
										/>
									</S.RulesSelectsWrapper>
									<ControlSelect
										disabled={
											formattedProductList.length === 0
										}
										control={control}
										name="productList"
										label="Produtos Inseridos"
										options={formattedProductList.map(
											(item) => ({
												label: item,
												value: item
											})
										)}
										value={formattedProductList}
									/>
								</S.RowFilterProductsRow>
							</>
						)}
					</S.SectionReview>

					<S.SectionReview>
						<S.SectionReviewTitle>Parâmetros</S.SectionReviewTitle>
						<S.FiltersByHierarquySection>
							<ControlSelect
								disabled={
									filtersByHieararchy['director'].length === 0
								}
								control={control}
								name="directors"
								label="DIRETOR"
								options={filtersByHieararchy['director']?.map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['director'].map(
									(item) => item.value
								)}
							/>
							<ControlSelect
								disabled={
									filtersByHieararchy['grc'].length === 0
								}
								control={control}
								name="grc"
								label="GRC"
								options={filtersByHieararchy['grc'].map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['grc'].map(
									(item) => item.value
								)}
							/>
							<ControlSelect
								disabled={
									filtersByHieararchy['state'].length === 0
								}
								control={control}
								name="state"
								label="ESTADO"
								options={filtersByHieararchy['state'].map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['state'].map(
									(item) => item.value
								)}
							/>
							<ControlSelect
								disabled={
									filtersByHieararchy['city'].length === 0
								}
								control={control}
								name="city"
								label="CIDADE"
								options={filtersByHieararchy['city'].map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['city'].map(
									(item) => item.value
								)}
							/>

							<ControlSelect
								disabled={
									filtersByHieararchy['channel'].length === 0
								}
								control={control}
								name="channel"
								label="CANAL"
								options={filtersByHieararchy['channel'].map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['channel'].map(
									(item) => item.value
								)}
							/>
							<ControlSelect
								disabled={
									filtersByHieararchy['cd'].length === 0
								}
								control={control}
								name="cd"
								label="CD"
								options={filtersByHieararchy['cd'].map(
									(item) => ({
										label: item.label,
										value: item.value
									})
								)}
								value={filtersByHieararchy['cd'].map(
									(item) => item.value
								)}
							/>
						</S.FiltersByHierarquySection>
					</S.SectionReview>
					<ImpactedClients usersImpacted={usersImpacted} />
				</S.ModalReviewCouponContainer>
			)}
		</LayoutModal>
	)
}
