import React from 'react'
import { Loading } from '../../shared/components'

import SearchInput from './SearchInput'
import CustomerForm from './Form'
import Placeholder from './Placeholder'
import ModalConfirmExclusion from './ModalConfirmExclusion'
import { IViewProps } from './types'
import styles from './styles.module.scss'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'

export default function CustomerExclusionView(props: IViewProps) {
	const {
		handlers,
		customerDocument,
		fetchCustomerStatus,
		customer,
		toggleModalConfirm,
		showModalConfirm,
		isDeleting,
		excludedCustomer
	} = props

	return (
		<>
			<DefaultLayoutAdmin>
				<div className={styles.custumerExclusionContainer}>
					<div className={styles.header}>
						<p className={styles.title}>Exclusão de Clientes</p>

						<SearchInput
							returnValue={handlers.changeDocument}
							valueIsValid={handlers.changeDocumentValidation}
							inputValue={customerDocument}
							disabled={fetchCustomerStatus.isFetching}
						/>
					</div>

					<div className={styles.divider} />

					{fetchCustomerStatus.isFetching && <Loading />}

					{fetchCustomerStatus.notFound && (
						<Placeholder type="not_found" />
					)}

					{fetchCustomerStatus.hasFound && customer && (
						<CustomerForm
							customer={customer}
							onPressDelete={toggleModalConfirm}
						/>
					)}

					{fetchCustomerStatus.notStarted && (
						<div className={styles.wrapperPlaceholder}>
							<Placeholder />
						</div>
					)}
				</div>
			</DefaultLayoutAdmin>

			<ModalConfirmExclusion
				excludedCustomer={excludedCustomer}
				isActive={showModalConfirm}
				onCancel={toggleModalConfirm}
				onConfirm={handlers.deleteCustomer}
				isDeleting={isDeleting}
			/>
		</>
	)
}
