import { ISacUser, UserGroupEnum } from '../../../shared/interfaces/sacUser'
import { ISelectOption } from '../../../shared/utils/SelectOptions'

export interface IModalUserProps {
    isActive: boolean
    user?: ISacUser
    onClose: (props: { refresh?: boolean }) => void
    onDelete: () => void
}

export interface IGroupSelectOption extends ISelectOption {
    label: UserGroupEnum
}

export interface IFormValues extends Omit<ISacUser, 'group' | 'groupId' | 'salesOrganizationID'> {
    group: IGroupSelectOption | undefined
    salesOrganizationID?: ISelectOption | undefined
}

export const INITIAL_FORM_VALUES: IFormValues = {
    user: '',
    group: undefined,
    salesOrganizationID: undefined
}
