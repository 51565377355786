import React from 'react'
import { ContainerDetails, DetailsIcon } from './Details.styles'

interface DetailsProps {
	data: any
	viewDetails: (customerID: any) => void
}

const Details = ({ data, viewDetails }: DetailsProps) => {
	return (
		<ContainerDetails>
			<DetailsIcon onClick={() => viewDetails(data)} />
		</ContainerDetails>
	)
}

export default Details
