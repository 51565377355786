import React from 'react'
import Select from 'react-select'

import {
	CloseButton,
	InputField,
	Loading,
	Modal
} from '../../../shared/components'
import { getTypeOpt, getValTypeOpt, typeOpts, valueTypeOpts } from './data'
import { IProps } from './types'

function ParameterModal(props: IProps) {
	const {
		param,
		isActive,
		loading,
		isEdit,
		close,
		updateParamKey,
		handleSubmit
	} = props

	const { Name, Description, Type, TypeValue, Hint, LabelValues = '' } = param

	const canBeInterval = Type !== 'BOOLEAN' && Type !== 'STRING'

	const canBeRange = Type === 'STRING'

	const uniqueOpt = { value: 'SINGLE', label: 'Unico' }

	const valTypeOpts =
		canBeInterval || canBeRange ? valueTypeOpts : [uniqueOpt]

	const labelArr = LabelValues.split(';')

	const validLabels = (labelArr.length || 0) === 2 && labelArr.every(Boolean)

	const validateLabels = TypeValue === 'INTERVAL' ? validLabels : true

	const isDisabled = ![
		Name,
		Description,
		Type,
		TypeValue,
		Hint,
		validateLabels
	].every(Boolean)

	const title = `${isEdit ? 'Editar' : 'Criar'} Parâmetro`

	return (
		<Modal isActive={isActive}>
			<div className="param-modal">
				<div className="col-12 close-wrapper">
					<CloseButton onClick={close} />
				</div>
				<div className="col-12">
					<h1 className="param-modal-title">{title}</h1>
				</div>
				<div className="col-12">
					<InputField
						className="param-input"
						id="name"
						label="Parâmetro"
						onChange={updateParamKey('Name')}
						secondary
						value={Name}
					/>
					<InputField
						className="param-input"
						id="description"
						label="Descritivo"
						onChange={updateParamKey('Description')}
						secondary
						value={Description}
					/>
					<InputField
						className="param-input"
						id="hint"
						label="Dica"
						onChange={updateParamKey('Hint')}
						secondary
						value={Hint}
					/>
					{TypeValue === 'INTERVAL' ? (
						<InputField
							className="param-input"
							id="label-values"
							label="Label dos valores, separados por ;"
							onChange={updateParamKey('LabelValues')}
							secondary
							value={LabelValues}
						/>
					) : null}
				</div>
				<div className="col-12 values-subtitle">
					<h1>Valores e seus tipos</h1>
				</div>
				<div className="col-12">
					<div className="row">
						<div className="col-6 param-select-wrapper">
							<Select
								className="react-select"
								classNamePrefix="react-select"
								onChange={updateParamKey('Type') as any}
								options={typeOpts}
								placeholder={'Tipo'}
								value={getTypeOpt(Type)}
							/>
						</div>
						<div className="col-6 param-select-wrapper">
							<Select
								className="react-select"
								classNamePrefix="react-select"
								onChange={updateParamKey('TypeValue') as any}
								options={valTypeOpts}
								placeholder={'Valor Tipo'}
								value={getValTypeOpt(TypeValue)}
							/>
						</div>
					</div>
				</div>
				{loading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<div className="col-12 param-button-wrapper">
						<button
							className="red-button"
							onClick={handleSubmit}
							disabled={isDisabled}>
							{title.toUpperCase()}
						</button>
					</div>
				)}
			</div>
		</Modal>
	)
}

export default ParameterModal
