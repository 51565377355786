import React, { useCallback } from 'react'
import * as S from './form-create-banner.styles'

import FloatLabelLocaleInput from '../../../../components/FloatLabelLocaleInput'
import { primaryInputStyle } from '../../../../shared/styles/theme'
import themeProvider, {
	colorThemeProvider
} from '../../../../theme/themeProvider'
import MidiaContainer from '../midia-container/midia-container.component'

import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { FormCreateBannerPops } from './form-create-banner-types'
import { DateRangePicker } from '../../../../components/date-range-picker'
import { DateRange } from 'react-day-picker-8'
import { ActionType } from '../../banners-management.types'
import { DataSelectorList } from '../../../../components/data-selector-list'
import { SelectorOption } from '../../../../components/data-selector/data-selector.types'
import { IProductItem } from '../../../push-notifications-admin/components/behavior-filter/behavior-filter.types'
import ConfirmBannerModal from '../banner-modals/confirm-banner-modal/confirm-banner-modal.component'
import { getProducts, getCategory } from '../../service'
import { ICategory, IProduct } from '../../types'
import {
	categoryItems,
	categoryItemsV2,
	comboItems,
	productItems,
	productItemsV2
} from '../../../push-notifications-admin/components/behavior-filter/utils/arrayDynamicItems'
import { Destiny } from '../../../banners-management/banners-management.types'
import LoadingCcontainer from '../../../../components/loading-container/loading-container.component'
import { DevicesEnum } from '../../components/midia-container/midia-container.types'
import { useFeatureFlags } from '../../../../context/feature-flag/feature-flag.context'

export type SelectedOptionAction = {
	value: ActionType | ''
	name: string
}

function FormCreateBanner({
	handleCloseFromCreate,
	customers,
	filters,
	onSaveBanner,
	amountOfCustomers
}: FormCreateBannerPops) {
	const { featureFlags } = useFeatureFlags()
	const isNewHomeInApp = featureFlags.US17241
	
	const [deviceSelected, setDeviceSelected] = React.useState<SelectorOption>({
		value: '',
		name: ''
	})
	const [srcImage, setSrcImage] = React.useState<string>('')
	const [srcVideo, setSrcVideo] = React.useState<string>('')
	const [isSelectedImgAndVideo, setIsSelectedImgAndVideo] =
		React.useState(false)
	const [titleBanner, setTitleBanner] = React.useState<string>('')
	const [selectedDate, setSelectedDate] = React.useState<DateRange>({
		from: new Date(),
		to: new Date(new Date().setDate(new Date().getDate() + 3))
	})
	const [destinys, setDestinys] = React.useState<SelectorOption[]>([
		{
			value: Destiny.HOME,
			name: Destiny.HOME
		}
	])
	const [destinySelected, setDestinySelected] =
		React.useState<SelectorOption>({
			value: '',
			name: ''
		})
	const [targetOrder, setTargetOrder] = React.useState<string>('')
	const [selectedOptionAction, setSelectedOptionAction] =
		React.useState<SelectedOptionAction>({
			value: '',
			name: ''
		})
	const [showSelectProducts, setShowSelectProducts] = React.useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const [products, setProducts] = React.useState<IProduct[]>([])
	const [categories, setCategories] = React.useState<ICategory[]>([])
	const [productsForDisplay, setProductsForDisplay] = React.useState<any[]>(
		[]
	)
	const [selectedProduct, setSelectedProduct] = React.useState<IProductItem>({
		value: '',
		name: ''
	})
	const [showConfirmModal, setShowConfirmModal] = React.useState(false)
	const [formValidConfirm, setFormValidConfirm] = React.useState(false)

	const [isDisabledImage, setIsDisabledImage] = React.useState(true)

	const handleOpenModal = useCallback(async () => {
		setShowConfirmModal(true)
	}, [])

	const actionArray = React.useMemo(
		() => [
			{
				value: ActionType.OPEN_PRODUCT,
				name: 'Abrir um Produto Específico'
			},
			{
				value: ActionType.OPEN_CATEGORY,
				name: 'Abrir Categoria de Produto'
			},
			{
				value: ActionType.OPEN_COMBOS,
				name: 'Abrir o Módulo de Combos'
			},
			{
				value: ActionType.OPEN_CHALLENGES,
				name: 'Abrir o Módulo de Desafios'
			},
			{
				value: ActionType.OPEN_PROGRAM_POINTS,
				name: 'Abrir o Módulo de Pontos'
			}
		],
		[]
	)

	React.useEffect(() => {
		const isValid =
			!!deviceSelected.value &&
			(!!srcImage || !!srcVideo) &&
			!!titleBanner &&
			!!selectedDate.from &&
			!!selectedDate.to &&
			!!destinySelected.value &&
			(!!selectedOptionAction.value ||
				(!selectedOptionAction.value &&
					destinySelected.value === 'LOGIN')) &&
			(!!srcImage || !!srcVideo) &&
			(((selectedOptionAction.value === ActionType.OPEN_PRODUCT ||
				selectedOptionAction.value === ActionType.OPEN_CATEGORY) &&
				!!selectedProduct.value) ||
				((selectedOptionAction.value === ActionType.OPEN_COMBOS ||
					selectedOptionAction.value === ActionType.OPEN_CHALLENGES ||
					selectedOptionAction.value ===
						ActionType.OPEN_PROGRAM_POINTS ||
					selectedOptionAction.value === ActionType.OPEN_VIDEO) &&
					!selectedProduct.value) ||
				(!selectedOptionAction.value &&
					destinySelected.value === 'LOGIN'))

		setFormValidConfirm(isValid)
	}, [
		deviceSelected,
		srcImage,
		srcVideo,
		titleBanner,
		selectedDate,
		destinySelected,
		targetOrder,
		selectedOptionAction,
		selectedProduct
	])

	// Função que sera executada após a criação do banner com sucesso.
	const handleCloseModalClearFields = () => {
		setTitleBanner('')
		setSrcImage('')
		setSrcVideo('')
		setShowSelectProducts(false)
		setSelectedDate({
			from: new Date(),
			to: new Date(new Date().setDate(new Date().getDate() + 3))
		})
		setDestinys([
			{
				value: Destiny.HOME,
				name: Destiny.HOME
			}
		])
		setDestinySelected({ value: '', name: '' })
		setTargetOrder('')
		setSelectedOptionAction({ value: '', name: '' })
		setSelectedProduct({ value: '', name: '' })
		setFormValidConfirm(false)

		handleCloseFromCreate()
	}

	React.useEffect(() => {
		setIsLoading(true)
		const handleGetProducts = async () => {
			const data = await getProducts()

			setProducts(data)
		}
		handleGetProducts()
		setIsLoading(false)
	}, [])

	React.useEffect(() => {
		setIsLoading(true)
		const handleGetCategory = async () => {
			const data = await getCategory()

			setCategories(data)
		}
		handleGetCategory()
		setIsLoading(false)
	}, [])

	function changeSetSelectedDate(range: any) {
		if (!range.to) {
			range.to = range.from
		}
		setSelectedDate(range)
	}

	React.useEffect(() => {
		if (selectedOptionAction.value === ActionType.OPEN_PRODUCT) {
			isNewHomeInApp ? setProductsForDisplay(productItemsV2(products)) : setProductsForDisplay(productItems(products))
		} else if (selectedOptionAction.value === ActionType.OPEN_CATEGORY) {
			isNewHomeInApp ? setProductsForDisplay(categoryItemsV2(categories)) : setProductsForDisplay(categoryItems(products)) 			
		} else if (selectedOptionAction.value === ActionType.OPEN_COMBOS) {
			setProductsForDisplay(comboItems(products))
		}
		setSelectedProduct({
			value: '',
			name: ''
		})
	}, [selectedOptionAction, products, categories, isNewHomeInApp])

	function onSelectionChange(option: SelectedOptionAction) {
		setSelectedOptionAction(option)
		setShowSelectProducts(
			option.value === ActionType.OPEN_PRODUCT ||
				option.value === ActionType.OPEN_CATEGORY
		)
		setFormValidConfirm(option.value === ActionType.OPEN_VIDEO)
	}

	React.useEffect(() => {
		const destinys = Object.keys(Destiny).map((destiny) => ({
			value: destiny,
			name: destiny
		}))
		setDestinys(destinys)
	}, [])

	// Verificação de seleção de vídeo e imagem
	React.useEffect(() => {
		if (srcImage && srcVideo) {
			setShowSelectProducts(false)
			setIsSelectedImgAndVideo(true)
		} else {
			setIsSelectedImgAndVideo(false)
		}
	}, [srcImage, srcVideo, isSelectedImgAndVideo])

	React.useEffect(() => {
		if (isSelectedImgAndVideo) {
			setSelectedOptionAction({
				name: ActionType.OPEN_VIDEO,
				value: ActionType.OPEN_VIDEO
			})
		}
	}, [isSelectedImgAndVideo])

	React.useEffect(() => {
		if (deviceSelected.value === DevicesEnum.APP) {
			setIsDisabledImage(
				!(!!deviceSelected.value && !!destinySelected.value)
			)
		}
		if (deviceSelected.value === DevicesEnum.WEB) {
			setIsDisabledImage(
				!(!!deviceSelected.value && !!destinySelected.value)
			)
		}
	}, [deviceSelected.value, destinySelected.value])

	return (
		<>
			<LoadingCcontainer isLoading={isLoading} />
			<>
				<ConfirmBannerModal
					isOPen={showConfirmModal}
					handleCloseModal={() => setShowConfirmModal(false)}
					customersAmount={amountOfCustomers}
					dataBanner={{
						platform: deviceSelected.value,
						imageURL: srcImage,
						videoURL: srcVideo,
						title: titleBanner,
						initialDate: new Date(String(selectedDate.from))
							.toISOString()
							.split('T')[0],
						finalDate: new Date(String(selectedDate.to))
							.toISOString()
							.split('T')[0],
						targetPage: destinySelected.value,
						actionType: selectedOptionAction.value,
						selectedProduct:
							selectedOptionAction.value ===
							ActionType.OPEN_PRODUCT
								? selectedProduct.value
								: '',
						selectedCategory:
							selectedOptionAction.value ===
							ActionType.OPEN_CATEGORY
								? selectedProduct.value
								: '',
						targetOrder: Number(targetOrder),
						customers,
						filters
					}}
					productsForDisplay={productsForDisplay}
					handleCloseModalClearFields={handleCloseModalClearFields}
					onSaveBanner={onSaveBanner}
				/>
				<S.FormCreateBannerWrapper>
					<MidiaContainer
						deviceSelected={deviceSelected}
						handleDeviceSelected={(value) =>
							setDeviceSelected(value)
						}
						srcImage={srcImage}
						handleGetSrcImage={(url) => setSrcImage(url)}
						srcVideo={srcVideo}
						handleGetSrcVideo={(url) => setSrcVideo(url)}
						canDeleteImage={true}
						hasSelectedVideo={!!srcVideo}
						canDeleteVideo={true}
						isFormCreateBanner={srcVideo ? true : false}
						destinySelected={destinySelected}
						isDisabledImage={isDisabledImage}
					/>
					<div className="behavior-content">
						<h2 className="title-section">Opções de programação</h2>
						<FloatLabelLocaleInput
							label="Título"
							id="title-input"
							{...primaryInputStyle}
							onChange={(event) =>
								setTitleBanner(event.target.value)
							}
							style={{ backgroundColor: 'white' }}
							value={titleBanner}
							maxLength={42}
							labelColor={themeProvider.palette.primary.main}
							primaryColor={themeProvider.palette.primary.main}
							color={colorThemeProvider.blackColor}
						/>
						<DataSelectorList
							dataSelectorListID="destiny-select"
							title="Link Destino"
							backgroundColor={
								colorThemeProvider.background.paper
							}
							list={destinys}
							handleChange={(item) => {
								setDestinySelected(item.target)
								setSelectedOptionAction({ value: '', name: '' })
								setSelectedProduct({ value: '', name: '' })
							}}
							selectedOption={destinySelected}
							isNative={false}
						/>
						<div className="time-wrapper">
							<DateRangePicker
								selectedDate={selectedDate}
								setSelectedDate={changeSetSelectedDate}
								placeholder="Filtrar por data"
								disabled={[
									{
										from: new Date(1979, 0, 0),
										to: new Date(
											new Date().setDate(
												new Date().getDate() - 1
											)
										)
									}
								]}
							/>
						</div>
						<h2 className="title-section">Comportamento</h2>
						{destinySelected.value !== 'LOGIN' && (
							<DataSelectorList
								dataSelectorListID={'type-action-selector'}
								title="Tipo de ação"
								backgroundColor={
									colorThemeProvider.background.paper
								}
								list={
									isSelectedImgAndVideo
										? [
												...actionArray,
												{
													value: ActionType.OPEN_VIDEO,
													name: 'Abrir Vídeo'
												}
										  ]
										: actionArray
								}
								handleChange={(item) => {
									if (isSelectedImgAndVideo) {
										return
									} else {
										onSelectionChange(item.target)
									}
								}}
								selectedOption={
									isSelectedImgAndVideo
										? {
												value: ActionType.OPEN_VIDEO,
												name: 'Abrir Vídeo'
										  }
										: selectedOptionAction
								}
								isDisabled={isSelectedImgAndVideo}
								isNative={false}
							/>
						)}
						<>
							{showSelectProducts && (
								<DataSelectorList
									dataSelectorListID={'select-item-selector'}
									backgroundColor={
										colorThemeProvider.background.paper
									}
									title={
										selectedOptionAction.value ===
										ActionType.OPEN_CATEGORY
											? 'Selecione a Categoria'
											: 'Selecione o Produto'
									}
									list={productsForDisplay}
									handleChange={(item) =>
										setSelectedProduct(item.target)
									}
									selectedOption={selectedProduct}
									isNative={false}
								/>
							)}
						</>
						<div className="buttons-wrapper">
							<ButtonDefault
								type={TypeButtonEnum.OUTLINED}
								buttonText="Cancelar"
								onClick={handleCloseFromCreate}
								idElement="button-cancel"
							/>
							<ButtonDefault
								type={
									formValidConfirm
										? TypeButtonEnum.PRIMARY
										: TypeButtonEnum.DISABLED
								}
								buttonText="Cadastrar"
								onClick={handleOpenModal}
								idElement="button-cadastrar"
							/>
						</div>
					</div>
				</S.FormCreateBannerWrapper>
			</>
		</>
	)
}

export default FormCreateBanner
