import styled, { css } from 'styled-components'
import { PointsExpirationStatusLabelEnum } from '../../../shared/interfaces/pointsProgram'

export const ListPointsWrapper = styled.div`
	width: 100%;
	padding-top: 32px;
	padding-bottom: 56px;
`

export const ListPointsTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	margin-bottom: 16px;
`

export const ListItem = styled.div`
	padding: 16px 0;
	border-bottom: 1px solid #0000003d;
`

export const ListItemLabelStatusRequest = styled.span<{
	status: string
}>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.UNDER_ANALYSIS &&
		css`
			color: ${(props) => props.theme.palette.black.main};
		`}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.REVERSAL &&
		css`
			color: ${(props) => props.theme.palette.gray.main};
		`}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRING &&
		css`
			color: #ffa5a5;
		`}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.RELEASED &&
		css`
			color: #5ea715;
		`}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.USED &&
		css`
			color: #5ea715;
		`}

			${({ status }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRED &&
		css`
			color: ${(props) => props.theme.palette.primary.main};
		`}
`

export const LabelTotalPoints = styled.label<{
	status: string
}>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.UNDER_ANALYSIS &&
		css`
			color: color: ${(props) => props.theme.palette.black.main};
		`}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRING ||
		status === PointsExpirationStatusLabelEnum.RELEASED
			? css`
					color: #5ea715;
			  `
			: ''}

	${({ status }) =>
		status === PointsExpirationStatusLabelEnum.USED ||
		status === PointsExpirationStatusLabelEnum.REVERSAL
			? css`
					color: ${(props) => props.theme.palette.gray.main};
			  `
			: ''}


			${({ status }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRED &&
		css`
			color: ${(props) => props.theme.palette.primary.main};
		`}
`

export const ListItemHeader = styled.header`
	width: 100%;
	display: flex;
	margin-bottom: 8px;
	align-items: center;
	justify-content: space-between;

	.label-request {
		color: ${(props) => props.theme.palette.gray.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		margin-left: 12px;
	}
`

export const DateOfRequest = styled.span`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	margin-left: 5px;
	display: inline-block;
	text-transform: uppercase;
`

export const ListFooter = styled.footer`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const WrapperFilterButtons = styled.div`
	display: flex;
	margin-top: 16px;
	margin-bottom: 32px;
	align-items: center;
	gap: 10px;
`

export const FilterInputLabel = styled.label`
	display: flex;
	color: ${(props) => props.theme.palette.gray.main};
	cursor: pointer;
	max-width: 81px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	padding: 6px 10px;
	align-items: center;
	border-radius: 13px;
	background-color: ${(props) => props.theme.palette.white.main};
	position: relative;
	justify-content: center;
	text-transform: uppercase;

	transition: all 300ms ease-in-out;

	&:hover {
		background-color: ${(props) => props.theme.palette.primaryWhite.main};
	}

	&.checked {
		color: ${(props) => props.theme.palette.primary.main};
		background-color: #ffd6d6;
	}
`

export const FilterInputElement = styled.input`
	opacity: 0;
	cursor: pointer;
	position: absolute;
`
