import { prop, sortBy } from 'ramda'
import { fetchCompanyParam, fetchGlobalCompanyParam } from '../../modules/ResaleParameters/service'
import { CompanyParamNamesEnum } from '../enums/companyParams'
import { IFormattedParam } from '../interfaces/formattedParam'
import { FullParameter } from '../interfaces/fullParam'
import { IStoreCustomer } from '../interfaces/store'
import { store } from '../store'
import { Creators as CompanyParamActions } from '../store/ducks/companyParams'

const sortByPos = sortBy(prop('Position'))

export const formatParam = (param: FullParameter): IFormattedParam => ({
    name: param.Parameter as CompanyParamNamesEnum,
    value: sortByPos(param.Items).map(prop('Value'))
})

export async function loadCompanyParams(customer: IStoreCustomer) {
    if (!customer?.SalesOrganizationID) return

    const { SalesOrganizationID: id } = customer

    const companyParams = await fetchCompanyParam(id)

    const formatted = companyParams.map(formatParam)

    const globalCompanyParams = (await fetchGlobalCompanyParam()) || []
    const pointsInAdeptionParameter = globalCompanyParams.find(
        (parameter) => parameter.Parameter === 'QUANTIDADE_PONTO_ADESAO'
    )

    if (pointsInAdeptionParameter) {
        formatted.push(formatParam(pointsInAdeptionParameter))
    }

    store.dispatch(CompanyParamActions.setParams(formatted))
}