import React from 'react'

import { IProps } from './types'

function CartItem(props: IProps): JSX.Element {
	const { item, handleClick } = props

	// 'PEDIDO CANCELADO'
	// 'PEDIDO ENTREGUE'

	const includeDelivered = (status: string) =>
		status === 'PEDIDO ENTREGUE' ? 'success' : ''

	// const includeCanceled = (status: string) =>
	// 	status === 'PEDIDO CANCELADO' ? 'error' : ''

	return (
		<tr onClick={handleClick}>
			<td>
				<span className="ellipsis">{item.number}</span>
			</td>
			<td className="mobile-hidden">{item.date}</td>
			{/* error success normal */}
			<td className={`normal ${includeDelivered(item.status)}`}>
				{item.status}
			</td>
			<td>{item.paymentMethod}</td>
			<td>{item.value}</td>
		</tr>
	)
}

export default CartItem
