import React, { useContext } from 'react'
import Modal from '../../../components/ModalPrimary'

import ComboCard from '../../../components/ComboCard'
import { Notification } from '../../../components/Notification/index'
import { UnavailableProductContext } from '../../../context/UnavailableProductContext'
import { InputQuantityLabel } from '../../../shared/components'
import { IMaterial } from '../../../shared/interfaces/material'
import { colors } from '../../../shared/styles/theme'
import CardItem from '../CardItem'
import ProductInfo from '../ProductInfo'

function ProductModal(props: any) {
	const {
		closeModal,
		handleAddProduct,
		inputIncrementProps,
		modalOpen,
		productAmount,
		selectedProduct,
		setOpenLightbox,
		type = 'PRODUCT' || 'COMBO',
		isProductForRedemption = false,
		insufficientAmountOfPointsForRedemption = false,
		handleUnavailableProduct
	} = props

	const { selectedProducts } = useContext(UnavailableProductContext)

	if (!selectedProduct) return null

	const isProductAlreadySelected = selectedProducts.filter(
		(item) => item.materialNumber === selectedProduct.MaterialID
	)

	return (
		<Modal
			containerContentBg={colors.fifthGray}
			isActive={modalOpen}
			onClose={closeModal}
			title="Informações do Produto"
			size={762}
			className="product-details-modal">
			<div className="modal-product">
				<div className="modal-body">
					<div className="row">
						{!isProductForRedemption ? (
							<div className="col-12 col-md-6">
								{type === 'PRODUCT' ? (
									<CardItem
										modal
										productItem={selectedProduct}
										openLightBox={setOpenLightbox}
									/>
								) : (
									<ComboCard
										modal
										data={selectedProduct}
										openLightBox={setOpenLightbox}
									/>
								)}
							</div>
						) : (
							<div className="col-12 col-md-6">
								{selectedProduct && (
									<CardItem
										modal
										isProductForRedemption
										productItem={selectedProduct}
										openLightBox={setOpenLightbox}
									/>
								)}
							</div>
						)}

						<div className="col-12 col-md-6">
							<ProductInfo
								thisIsARescueProduct={isProductForRedemption}
								productItem={
									type === 'PRODUCT' && selectedProduct
								}
								productAmount={productAmount}
								comboItem={type === 'COMBO' && selectedProduct}
								type={
									type === 'COMBO'
										? 'COMBO'
										: (selectedProduct as IMaterial)
												.priceAction?.length
										? 'PRICE_ACTION'
										: 'PRICE'
								}
							/>
						</div>

						<div className="increment-product-sm">
							<div className="input-increment-wrapper">
								{selectedProduct.StockPosition === 0 ? (
									<Notification
										type={
											isProductAlreadySelected.length
												? 'error'
												: 'info'
										}
										message={
											isProductAlreadySelected.length
												? 'Quando o produto estiver disponível, vamos te avisar!'
												: 'Este produto não está disponível. Podemos notifica-lo quando chegar.'
										}
									/>
								) : (
									<div className="button-wrapper">
										<button
											onClick={handleAddProduct}
											className="button">
											{'Atualizar carrinho'}
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
					<div className="increment-product">
						<div className="input-increment-wrapper">
							{selectedProduct.StockPosition === 0 ? (
								<Notification
									type={
										isProductAlreadySelected.length
											? 'error'
											: 'info'
									}
									message={
										isProductAlreadySelected.length
											? 'Quando o produto estiver disponível, vamos te avisar!'
											: 'Este produto não está disponível. Podemos notifica-lo quando chegar.'
									}
								/>
							) : isProductForRedemption ? (
								<InputQuantityLabel
									itsARescueItem={isProductForRedemption}
									insufficientAmountOfPointsForRedemption={
										insufficientAmountOfPointsForRedemption
									}
									{...inputIncrementProps}
								/>
							) : (
								<InputQuantityLabel {...inputIncrementProps} />
							)}
						</div>

						<div className="button-wrapper">
							{selectedProduct.StockPosition === 0 ? (
								<button
									onClick={() => handleUnavailableProduct()}
									className={'button'}>
									{isProductAlreadySelected.length
										? 'Entendi'
										: 'Avise-me quando chegar'}
								</button>
							) : (
								<button
									disabled={
										insufficientAmountOfPointsForRedemption
									}
									onClick={handleAddProduct}
									className={'button'}>
									{isProductForRedemption
										? 'Adicionar'
										: 'Atualizar carrinho'}
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ProductModal
