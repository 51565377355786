import { Autocomplete } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export interface IOrderWrapperStatusDescription {
	types: string
}

export const OrderWrapper = styled.div`
	background-color: ${(props) => props.theme.palette.white.main};
	padding: 1.5rem 1rem;
	border-radius: 0.25rem;
	margin-bottom: 2.5rem;

	th {
		color: ${colors.black} !important;
		font-weight: ${(props) => props.theme.font.weight.medium};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	}
`

export const OrderTitle = styled.h1`
	color: ${colors.secondGrey};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	margin-top: 0.5px;
	margin-bottom: ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_XL.line_height}rem;
`

export const OrderStatusDescription = styled.span<IOrderWrapperStatusDescription>`
	color: ${(props) =>
		props.types.includes('entregue')
			? props.theme.palette.success.main
			: props.types.includes('reprovado')
			? props.theme.palette.error.main
			: props.theme.palette.gray.main};
`

export const OrderStatusAutocomplete = styled(Autocomplete)`
	width: 240px;

	font-weight: ${(props) => props.theme.font.weight.regular}rem;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	background-color: ${(props) => props.theme.palette.gray.contrastText};

	& .MuiOutlinedInput-root {
		height: 55px;

		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.black.main};
		}
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.theme.palette.primary.main};
	}

	&.MuiFormLabel-root {
		color: ${(props) => props.theme.palette.primary.main};
	}

	label {
		color: ${(props) => props.theme.palette.primary.main};
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	svg {
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const InputIconWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 360px;
	height: 55px;
	position: relative;

	svg {
		position: absolute;
		right: 10px;
		top: 16px;
		color: ${(props) => props.theme.palette.primary.main};

		&:hover {
			cursor: pointer;
		}
	}
`

export const FilterWrapper = styled.div`
	width: 250px;
	height: 55px;
`
