import cogoToast from 'cogo-toast'
import {
	ChangeEvent,
	createElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react'
import { useDispatch } from 'react-redux'
import { ActionMeta } from 'react-select'

import { ISelect } from '../../components/SelectPrimary/types'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { useNumber } from '../../shared/hooks/useNumber'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ICombo } from '../../shared/interfaces/combo'
import { Maybe, Nullable } from '../../shared/interfaces/common'
import {
	IMaterial,
	characteristicTypeEnum
} from '../../shared/interfaces/material'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { Creators as OrderActions } from '../../shared/store/ducks/orderHistory'
import { Creators as PointsProgramStatusActions } from '../../shared/store/ducks/pointsProgramStatus'
import {
	extractCartItem,
	getPrice,
	isComboAmountAboveStock
} from '../../shared/utils/cart'
import { isPropEqual } from '../../shared/utils/object'
import { CogoPositions } from '../../shared/utils/toaster'
import { getComboList } from '../Combo/service'
import {
	checkRulesOfTheProducts,
	getMaterials,
	registerLogUnavailableProduct
} from './service'
import {
	IProductListWithParams,
	ISelectFilters,
	ISelectOptions,
	IUnavailableProductReq,
	IViewProps
} from './types'
import ProductList from './view'

import { format, isToday, parseISO } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import { UnavailableProductContext } from '../../context/UnavailableProductContext'
import {
	CategoriesBannerEnum,
	ICarouselBanner
} from '../../shared/interfaces/banner'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { CogoPosition } from '../../shared/interfaces/cogoToast'
import { ChallengeFiltersEnum } from '../../shared/interfaces/pointsProgramChallenge'
import { categoriesServices } from '../../shared/services/categories.services'
import { fetchNewChallenges } from '../../shared/services/challenge.service'
import { loadCompanyParams } from '../../shared/services/companyParams.service'
import { checkAdoptionByCustomer } from '../../shared/services/customerPointsProgram.service'
import { IAllInEvent, sendToAllIn } from '../../shared/utils/allin'
import {
	settingTheProductContainerType,
	settingTheUnitPriceOfTheProducts
} from '../../shared/utils/material'
import {
	getLocalizationAccess,
	getSalesOrgHasProgramPoints
} from '../../shared/utils/param'
import { getLowerPrice } from '../../shared/utils/price'
import { categoryArraySort } from '../../utils/category-array-sort'
import { sortListByListPositionProduct } from '../../utils/sort-list-by-list-position'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { compareDates } from '../../utils/compare-dates'
import {
	compareProductsInCart,
	compareCombosInCart
} from '../../utils/compare-products-in-cart'

function ProductListContainer(): JSX.Element {
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const productParam = queryParams.get('product')
	const categoryParam = queryParams.get('category')
	const dispatch = useDispatch()
	const { customer, carts, companyParams, pointsProgramStatus } =
		useTypedSelector([
			'customer',
			'carts',
			'companyParams',
			'pointsProgramStatus'
		])
	const { updateBar, hideBar } = BottomBarActions

	const history = useHistory()

	const { addSelectedProduct, setShowNotification } = useContext(
		UnavailableProductContext
	)

	const items = carts.map((cart) => cart.items).flat()

	const DEFAULT_OPTION: ISelectOptions = {
		placeholder: '',
		options: []
	}

	const DEFAULT_FILTERS: ISelectFilters = {
		selectOne: null,
		selectTwo: null,
		selectThree: null,
		searchInput: ''
	}

	const [selectOptionsOne, setSelectOptionsOne] = useState(DEFAULT_OPTION)
	const [selectOptionsTwo, setSelectOptionsTwo] = useState(DEFAULT_OPTION)
	const [selectOptionsThree, setSelectOptionsThree] = useState(DEFAULT_OPTION)

	const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS)

	const [isFetchingMaterials, setIsFetchingMaterials] = useState(true)
	const [showReloadMaterials, setShowReloadMaterials] = useState(false)

	const [isFetchingCombos, setIsFetchingCombos] = useState(true)
	const [showReloadCombos, setShowReloadCombos] = useState(false)

	const [showProductsWithMorePoints, setShowProductsWithMorePoints] =
		useState(false)

	const [renderProductList, setRenderProductList] = useState<IMaterial[]>([])
	const [productList, setProductList] = useState<IProductListWithParams>({
		products: [],
		isMorePoints: false
	})
	const [selectedProduct, setSelectedProduct] = useState<
		IMaterial | undefined
	>(undefined)
	const [selectedCombo, setSelectedCombo] = useState<ICombo | undefined>(
		undefined
	)
	const [productAmount, increment, decrement, setAmount] = useNumber(1)
	const [modalOpen, openModal, closeModal] = useBoolean(false)
	const [lightboxPhotos, setLightboxPhotos] = useState<string[]>([])
	const [openLightbox, setOpenLightbox] = useState(false)

	const [comboData, setComboData] = useState<ICombo[]>([])
	const [locationHasService, setLocationHasService] = useState(false)
	const [challengesBanner, setChallengesBanner] = useState<ICarouselBanner[]>(
		[]
	)

	const showBannerProductsThatScoreTheMost = Boolean(
		location.search.replace('?productsThatScoreTheMost=', '')
	)

	const [showPointsProgramModal, setShowPointsProgramModal] = useState(false)

	function handleClosePointsProgramModal() {
		setShowPointsProgramModal(false)
	}

	async function fetchPageProducts(service?: 'combos' | 'materials') {
		const {
			sectorID,
			SegmentID,
			CustomerID,
			ChannelGroupID,
			SalesOrganizationID
		} = customer
		const { localizationAccess } = checkCompanyParams()

		if (
			!sectorID ||
			!SegmentID ||
			!CustomerID ||
			!ChannelGroupID ||
			!SalesOrganizationID ||
			!localizationAccess
		)
			return

		let materials: IMaterial[] = []
		let combos: ICombo[] = []

		const fetchMaterials = async () => {
			try {
				const productListResponse = await getMaterials({
					salesOrganizationID: SalesOrganizationID,
					segmentID: SegmentID,
					sectorID: sectorID,
					customerID: CustomerID,
					morePoints: showProductsWithMorePoints,
					channelGroupID: ChannelGroupID,
					includesDraftBeer: customer.draftBeer
				})

				if (!productListResponse) return
				materials = productListResponse

				const productListWithUpdatedScoreRateAndScoreValueData =
					await checkRulesOfTheProducts({
						products: productListResponse,
						customer
					})

				// UPDATING SCORE_RATE AND SCORE_VALUE OF THE PRODUCTS
				const productListWithUpdatedScoreRateAndScoreValue =
					productListResponse.map((material: IMaterial) => {
						const currentMaterial =
							productListWithUpdatedScoreRateAndScoreValueData.find(
								(product: any) =>
									product.productID === material.MaterialID
							)
						return {
							...material,
							ScoreValue: currentMaterial.ScoreValue,
							ScoreRate: currentMaterial.ScoreRate
						}
					})

				// ADDING PRICE PER UNIT
				const productListWithUnitPrices =
					settingTheUnitPriceOfTheProducts(
						productListWithUpdatedScoreRateAndScoreValue
					)

				// ADDING CONTAINER TYPE OF THE PRODUCT
				const productListWithContainerType =
					settingTheProductContainerType(productListWithUnitPrices)

				// Add Produto Com CategoryListingPosition
				const categories = await handleGetCategories()

				productListWithContainerType.map((product) => {
					let currentCategory = ''

					// identifica a categoria do produto
					product.Characteristics.map((item) => {
						if (item.Type === 'Category') {
							currentCategory = item.Description
						}

						return item
					})

					// procura o a posição na lista de categorias
					categories.map(
						(category: {
							description: string
							listingPosition: number
						}) => {
							if (
								category.description.toLowerCase() ===
								currentCategory.toLowerCase()
							) {
								product.CategoryListingPosition =
									category.listingPosition
							}

							return category
						}
					)
					return product
				})

				setProductList({
					products: productListWithContainerType,
					isMorePoints: showProductsWithMorePoints
				})
			} catch (error) {
				setShowReloadMaterials(true)
			}
		}
		const fetchCombos = async () => {
			try {
				const combosResponseData: ICombo[] = await getComboList({
					sectorID: sectorID,
					customerID: CustomerID,
					salesOrganizationID: SalesOrganizationID
				})
				combos = combosResponseData

				const filteredCombos = combosResponseData
					.sort((a, b) => compareDates(a.endDate, b.endDate))
					.slice(0, 4)

				setComboData(filteredCombos)
			} catch (error) {
				setShowReloadMaterials(true)
				setShowReloadCombos(true)
			}
		}

		async function fetchAll() {
			try {
				const shouldFetchCombos =
					service === 'combos' || !showProductsWithMorePoints

				if (shouldFetchCombos) {
					setIsFetchingCombos(true)
					await fetchCombos().finally(() => {
						setIsFetchingCombos(false)
					})
				}

				const shouldFetchMaterials = !service || service === 'materials'
				if (shouldFetchMaterials) {
					setIsFetchingMaterials(true)
					await fetchMaterials().finally(() => {
						setIsFetchingMaterials(false)
					})
				}
			} catch (error) {
				console.error(error)
			}
		}

		await fetchAll()
		if (materials.length > 0 && combos.length > 0) {
			const combosInCart = compareCombosInCart({
				cartProducts: items,
				combos: combos
			})

			const productsInCart = compareProductsInCart({
				cartProducts: items,
				products: materials
			})

			const allCombosAndProducts = [...combosInCart.items, ...productsInCart.items]

			if (combosInCart.hasChanges || productsInCart.hasChanges) {
				cogoToast.success('Produtos alterados, verifique novamente seu carrinho!')
			}

			const updatedCart = carts.map((items) => {
				return { ...items, items: allCombosAndProducts }
			})
			console.log(updatedCart)
			dispatch(
				CartActions.updateCart(
					updatedCart[0]?.items.length === 0 ? [] : updatedCart
				)
			)
		}
	}

	function handleReload(service: 'combos' | 'materials') {
		if (service === 'combos') setShowReloadCombos(false)
		if (service === 'materials') setShowReloadMaterials(false)
		fetchPageProducts(service)
	}

	async function handleGetCategories() {
		try {
			const data = await categoriesServices.getCategoriesCustomer()
			return data
		} catch (error) {
			cogoToast.error('Erro ao trazer as categorias.')
		}
	}

	function setSelectOptions() {
		if (!productList.products.length) return
		const options: {
			type: string
			values: string[]
		}[] = []
		productList.products.forEach((product) => {
			product?.Characteristics?.forEach((char) => {
				const optionIndex = options.findIndex(
					(option) => option.type === char.Type
				)
				if (optionIndex !== -1) {
					const valueIndex = options[optionIndex].values.findIndex(
						(value) => value === char.Description
					)

					if (valueIndex === -1) {
						options[optionIndex].values.push(char.Description)
					}
				} else {
					options.push({
						type: char.Type,
						values: [char.Description]
					})
				}
			})
		})

		options.forEach(async (option, i) => {
			if (i === 0) {
				const optionsOne: any[] = []
				const dataBaseCategories = await handleGetCategories()

				option.values.forEach((value) => {
					optionsOne.push({ value, label: value })
				})

				const newArrayCategorie = categoryArraySort({
					dataBaseCategories,
					categoriesByProductList: option.values
				})

				setSelectOptionsOne({
					placeholder: characteristicTypeEnum[option.type],
					options: newArrayCategorie
				})
			} else if (i === 1) {
				const optionsTwo: any[] = []
				option.values.forEach((value) => {
					optionsTwo.push({ value, label: value })
				})

				setSelectOptionsTwo({
					placeholder: characteristicTypeEnum[option.type],
					options: optionsTwo
				})
			}
			if (i === 2) {
				const optionsThree: any[] = []
				option.values.forEach((value) => {
					optionsThree.push({ value, label: value })
				})

				setSelectOptionsThree({
					placeholder: characteristicTypeEnum[option.type],
					options: optionsThree
				})
			}
		})
	}

	function handleSelectedFilters() {
		if (!productList.products.length) return

		const filteredProductList = productList.products.filter((item) => {
			if (!item) return false

			const selectOne = selectedFilters.selectOne
			const selectTwo = selectedFilters.selectTwo
			const selectThree = selectedFilters.selectThree
			const searchInput = selectedFilters.searchInput

			if (selectOne || selectTwo || selectThree || searchInput) {
				const selectedValues: Array<string> = []
				if (selectOne) selectedValues.push(selectOne)
				if (selectTwo) selectedValues.push(selectTwo)
				if (selectThree) selectedValues.push(selectThree)

				const match = selectedValues.every((v) =>
					item?.Characteristics?.some(isPropEqual('Description')(v))
				)

				const productName =
					(Boolean(searchInput) &&
						item?.Description?.toUpperCase().includes(
							searchInput ? searchInput.toUpperCase() : ''
						)) ||
					!Boolean(searchInput)

				if (match && productName) return item
			} else {
				return item
			}

			return false
		})
		if (selectedFilters.selectOne) {
			setRenderProductList(
				sortListByListPositionProduct(filteredProductList)
			)
		} else {
			setRenderProductList(filteredProductList)
		}
	}

	function handleSelectOne(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectOne: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSelectTwo(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectTwo: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSelectThree(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectThree: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>): void {
		sendToAllIn(IAllInEvent.search, [
			{
				keyword: e.target.value
			}
		])

		setSelectedFilters({
			...selectedFilters,
			searchInput: e.target.value ? e.target.value : ''
		})
	}

	function updateProductAmount(item: ICartItem): void {
		const storeItem = items.find(isPropEqual('MaterialID')(item))

		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 1

		setAmount(storeQuantity)
	}

	function handleSelectedProduct(product: IMaterial | undefined): void {
		setSelectedCombo(undefined)
		setSelectedProduct(product)
		setLightboxPhotos([
			product?.ImageUrl || '',
			product?.PackageImageUrl || ''
		])
		dispatch(hideBar())

		if (!product) return
		updateProductAmount(extractCartItem(product, productAmount))

		openModal()
		const brand = product.Characteristics.find((x) => x.Type === 'Brand')
		const category = product.Characteristics.find(
			isPropEqual('Category')(product)
		)

		sendToAllIn(IAllInEvent.product, [
			{
				id: product.MaterialID,
				name: product.Description,
				price: getPrice(product.PriceByQuantity, 1),
				department: '',
				category: category?.Description,
				subcategory: '',
				brand: brand?.Description
			}
		])
	}

	function handleSelectedCombo(combo: ICombo) {
		if (!combo) return
		setSelectedProduct(undefined)
		setSelectedCombo(combo)
		setLightboxPhotos([combo.productImageURL])
		dispatch(hideBar())
		updateProductAmount(extractCartItem(combo, productAmount))

		openModal()
		sendToAllIn(IAllInEvent.product, [
			{
				id: combo.promotionID,
				name: combo.description,
				price: combo.comboPriceTotal,
				department: '',
				category: '',
				subcategory: '',
				brand: ''
			}
		])
	}

	function handleUpdateAmount(event: ChangeEvent<HTMLInputElement>) {
		const newProductAmount = Number(event.target.value)

		if (isNaN(newProductAmount)) return

		const { amountAboveStock, stock } = selectedProduct
			? isProductAmountAboveStock(newProductAmount)
			: isComboAmountAboveStock(selectedCombo, newProductAmount)
		if (amountAboveStock) {
			cogoToast.error(
				`Quantidade em estoque: ${stock}`,
				CogoPositions['top-right']
			)
			return
		}

		setAmount(newProductAmount)
	}

	function handleIncrementAmount() {
		const newProductAmount = productAmount + 1
		if (isNaN(newProductAmount)) return

		const { amountAboveStock, stock } = selectedProduct
			? isProductAmountAboveStock(newProductAmount)
			: isComboAmountAboveStock(selectedCombo, newProductAmount)
		if (amountAboveStock) {
			cogoToast.error(
				`Quantidade em estoque: ${stock}`,
				CogoPositions['top-right']
			)
			return
		}

		increment()
	}

	function handleDecrementAmount() {
		const newProductAmount = productAmount - 1

		if (isNaN(newProductAmount)) return

		const { amountAboveStock, stock } = selectedProduct
			? isProductAmountAboveStock(newProductAmount)
			: isComboAmountAboveStock(selectedCombo, newProductAmount)
		if (amountAboveStock) {
			cogoToast.error(
				`Quantidade em estoque: ${stock}`,
				CogoPositions['top-right']
			)
			return
		}

		decrement()
	}

	function handleProductsToCart(selectedProduct: IMaterial | ICombo) {
		if (!productAmount) {
			cogoToast.error(
				'Por favor especifique a quantidade',
				CogoPositions['top-right']
			)

			return
		}

		const item = extractCartItem(selectedProduct, productAmount)

		const storeItem = items.find(isPropEqual('MaterialID')(item))
		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0

		const totalQuantity = productAmount

		return {
			item,
			storeItem,
			storeQuantity,
			totalQuantity
		}
	}

	function handleAddItemToCard(selectedType: IMaterial | ICombo | undefined) {
		if (!selectedType) return

		const options = CogoPositions['top-right']

		const product = handleProductsToCart(selectedType)

		if (!product) return

		const category = (selectedType as IMaterial)?.Characteristics?.find(
			isPropEqual('Category')(selectedType)
		)

		product.item.category = category?.Description || ''
		addItemToStore(
			product.totalQuantity,
			options,
			product.storeQuantity,
			(selectedType as IMaterial).Description ||
				(selectedType as ICombo).description,
			(selectedType as IMaterial).ImageUrl ||
				(selectedType as ICombo).productImageURL,
			(selectedType as ICombo).comboImageUrl,
			product.item
		)
	}

	function isProductAmountAboveStock(newProductAmount: number) {
		if (selectedProduct?.priceAction?.length) {
			const arrayOfNumber = selectedProduct.priceAction.map((item) =>
				Number(item.maxAmount)
			)
			const findLowerPrice = getLowerPrice(arrayOfNumber)

			const item = selectedProduct.priceAction.find(
				(item) => Number(item.maxAmount) === findLowerPrice
			)

			const stock = Number(item?.quantity)
			if (newProductAmount > stock) {
				return { amountAboveStock: true, stock }
			}
		}

		const stock = Math.round(Number(selectedProduct?.StockPosition))
		if (newProductAmount > stock) {
			return { amountAboveStock: true, stock }
		}

		return { amountAboveStock: false, stock }
	}

	function addItemToStore(
		totalQuantity: number,
		options: CogoPosition,
		storeQuantity: number,
		description: string,
		imageUrl: string,
		ComboImageUrl: string,
		item: ICartItem
	) {
		console.trace('-')
		item.rescueProduct = false
		item.typeItem = ICartItemTypeEnum.NORMAL

		if (totalQuantity > 9999) {
			cogoToast.error('O máximo de unidades por item é 9999', options)

			return
		}

		const bottomBarProps = {
			name: description,
			count: productAmount,
			imageUrl: imageUrl,
			comboImageUrl: ComboImageUrl,
			time: 20
		}

		if (storeQuantity !== productAmount) {
			const itemAddedToCart = {
				...item,
				customerId: customer.CustomerID,
				comboImageUrl: ComboImageUrl
			}
			dispatch(CartActions.addItem(itemAddedToCart, 'ADD'))
		}

		dispatch(updateBar(bottomBarProps))

		handleCloseModal()
		setTimeout(() => setAmount(1), 200)
	}

	function handleCloseModal() {
		setAmount(1)
		setLightboxPhotos([])
		closeModal()
		setSelectedProduct(undefined)
		setSelectedCombo(undefined)
	}

	function handleClear() {
		setSelectedFilters(DEFAULT_FILTERS)
	}

	const handleChangeCategory = useCallback(
		(carouselBanner: ICarouselBanner) => {
			if (carouselBanner.challenge) {
				history.push(
					CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS,
					{ challenge: carouselBanner.challenge }
				)
				return
			}

			const category = carouselBanner.Category || null
			if (category === CategoriesBannerEnum.MORE_POINTS) {
				setShowProductsWithMorePoints(true)
				return
			}

			setSelectedFilters((prev) => ({
				...prev,
				selectOne: category
			}))
		},
		[history]
	)

	function handleGoBackToAllProducts() {
		setShowProductsWithMorePoints(false)
	}

	function handleComboNavigation() {
		history.replace(CustomerRoutesEnum.COMBO_LIST)
	}

	function checkCompanyParams() {
		const localizationAccess = getLocalizationAccess(companyParams)
		const salesOrgHasProgramPoints =
			getSalesOrgHasProgramPoints(companyParams)

		return { localizationAccess, salesOrgHasProgramPoints }
	}

	function checkPointsProgramUserStatus(salesOrgHasProgramPoints: boolean) {
		;(async () => {
			const response = await checkAdoptionByCustomer(customer.CustomerID)

			if (response) {
				dispatch(
					PointsProgramStatusActions.setPointsProgramStatus({
						userJoinedTheProgram: true,
						salesOrgHasProgramPoints
					})
				)

				return
			}

			if (
				!pointsProgramStatus.pointsProgramPresentationDate ||
				!isToday(
					parseISO(
						pointsProgramStatus.pointsProgramPresentationDate.toString()
					)
				)
			) {
				if (salesOrgHasProgramPoints) {
					setShowPointsProgramModal(true)
					dispatch(
						PointsProgramStatusActions.setPointsProgramStatus({
							userJoinedTheProgram: false,
							pointsProgramPresentationDate: new Date(),
							salesOrgHasProgramPoints
						})
					)
				}
			}
		})()
	}

	const handleShowBannerProductsThatScoreTheMost = () => {
		if (showBannerProductsThatScoreTheMost) {
			setShowProductsWithMorePoints(true)
		}
	}

	function fetchChallengesBanner() {
		if (!productList.products.length) return
		;(async () => {
			try {
				const newChallenges = await fetchNewChallenges({
					products: productList.products,
					filter: ChallengeFiltersEnum.ARE_EXPIRING
				})

				const challengeBanners: ICarouselBanner[] = newChallenges
					.filter((challenge) => challenge.bannerhome)
					.map((challenge) => ({
						challenge: challenge,
						Title: challenge.title,
						imageURL: challenge.urlFoto,
						initialDate: challenge.startDate,
						finalDate: challenge.endDate
					}))

				setChallengesBanner(challengeBanners)
			} catch (err) {
				console.error('fetchChallengesBannerError', { err })
			}
		})()
	}

	function init() {
		if (!customer || !companyParams) return

		const { localizationAccess, salesOrgHasProgramPoints } =
			checkCompanyParams()
		setLocationHasService(localizationAccess)
		checkPointsProgramUserStatus(salesOrgHasProgramPoints)

		fetchPageProducts()

		dispatch(OrderActions.updateOrderHistory([]))
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(init, [])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(setSelectOptions, [productList])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleSelectedFilters, [productList, selectedFilters])
	useEffect(() => {
		if (showProductsWithMorePoints === productList.isMorePoints) return
		fetchPageProducts('materials')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showProductsWithMorePoints])
	useEffect(fetchChallengesBanner, [productList])
	useEffect(() => {
		loadCompanyParams(customer)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])
	useEffect(handleShowBannerProductsThatScoreTheMost, [
		showBannerProductsThatScoreTheMost
	])
	useEffect(() => {
		const product = productList.products.filter((product) => {
			return product.MaterialID === productParam
		})
		if (productList.products.length > 0) {
			handleSelectedProduct(product[0])
		}
	}, [productParam])
	useEffect(() => {
		handleChangeCategory({
			Title: '',
			finalDate: '',
			initialDate: '',
			Category: categoryParam
		})
	}, [categoryParam])

	async function handleRegisterUnavailableProduct() {
		const unavailableProduct: IUnavailableProductReq = {
			materialNumber: selectedProduct?.MaterialID || '',
			descriptionProduct: selectedProduct?.Description || '',
			customerID: customer.CustomerID,
			startDate: format(new Date(), 'yyyy-MM-dd'),
			salesOrganizationID: customer.SalesOrganizationID,
			status: false
		}

		try {
			const response = await registerLogUnavailableProduct(
				unavailableProduct
			)
			setShowNotification(true)
			addSelectedProduct(unavailableProduct)
			return response?.data
		} catch (error) {
			throw error
		} finally {
			closeModal()
			setTimeout(() => {
				setShowNotification(false)
			}, 3000)
		}
	}

	const viewProps: IViewProps = {
		selectOptionsOne,
		selectOptionsTwo,
		selectOptionsThree,
		renderProductList,
		setSelectedFilters,
		handleSelectOne,
		handleSelectTwo,
		handleSelectThree,
		handleSearchInput,
		selectedProduct,
		handleSelectedProduct,
		productAmount,
		handleIncrement: handleIncrementAmount,
		handleDecrement: handleDecrementAmount,
		handleAddItemToCard,
		modalOpen,
		closeModal: handleCloseModal,
		handleUpdateAmount,
		selectedFilters,
		handleClear,
		lightboxPhotos,
		openLightbox,
		setOpenLightbox,
		comboData,
		handleComboNavigation,
		handleSelectedCombo,
		selectedCombo,
		handleReload,
		locationHasService,
		showPointsProgramModal,
		handleClosePointsProgramModal,
		handleChangeCategory,
		showProductsWithMorePoints,
		handleGoBackToAllProducts,
		isFetchingCombos,
		isFetchingMaterials,
		showReloadCombos,
		showReloadMaterials,
		challengesBanner,
		handleRegisterUnavailableProduct
	}

	return createElement(ProductList, viewProps)
}

export default ProductListContainer
