import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react'

import { InputAdornment, TextFieldProps } from '@mui/material'

import Loading from '../Loading'
import ErrorText from '../error-text/error-text.component'

import * as S from './control-input.styles'

type InputProps = {
	stringValue?: string
	loading?: boolean
	label?: string
	errorMessage?: string
	startAdornment?: ReactNode | false
	maxCharacters?: number
} & TextFieldProps

const ControlInput: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
	{
		loading = false,
		disabled,
		label,
		errorMessage,
		startAdornment,
		maxCharacters,
		stringValue = '',
		onChange,
		onBlur,
		...props
	},
	ref
) => {
	return (
		<S.InputWrapper>
			<S.Input
				ref={ref}
				inputRef={ref}
				disabled={loading || disabled}
				InputLabelProps={{ shrink: true }}
				placeholder={label}
				label={label}
				onChange={onChange}
				onBlur={onBlur}
				inputProps={{
					maxLength: maxCharacters
				}}
				InputProps={{
					startAdornment: !!startAdornment ? (
						<InputAdornment position="start">
							{startAdornment}
						</InputAdornment>
					) : undefined,
					endAdornment:
						!!maxCharacters && stringValue.length > 0 ? (
							<S.CountCharacters position="end">
								{stringValue.length}/{maxCharacters}
							</S.CountCharacters>
						) : undefined
				}}
				{...props}
			/>
			{loading && <Loading />}
			<ErrorText error={errorMessage} />
		</S.InputWrapper>
	)
}

export default forwardRef(ControlInput)
