import React, { useCallback, useEffect, useState } from 'react'
import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'
import HeaderCoupon from './components/header-coupons/header-coupons.component'
import { ITableColumn, ITableNavProps } from '../../../components/Table/types'
import {
	ICellTableData,
	IResponseGetCoupons,
	ITableDataCoupons,
	formatDate,
	initialFetchProps,
	options,
	unlimitedQtdCoupon
} from './history-coupon.types'
import Toggle from '../../../components/toggle/toggle.component'

import * as S from './history-coupon.styles'
import { Table } from '../../../shared/components'
import { useHistory, useLocation } from 'react-router-dom'
import { toMoney } from '../../../shared/utils/money'
import {
	IGetCouponsService,
	enableDisableCoupon,
	getCoupons,
	getCsvCoupons
} from './services/table-coupons.services'
import { AdminRoutesEnum } from '../../../modules/Routes/adminRoutesEnum'
import TopModalSucess from '../../../components/toast-sucess/toast-sucess.component'

import DetailsCoupon from './components/details-coupons/details-coupons.component'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../shared/utils/toaster'

import { getCouponsByID } from './services/coupons-by-id.services'
import LoadingCcontainer from '../../../components/loading-container/loading-container.component'
import handleError from '../../../modules/Error/handleError'
import { IError } from '../../../modules/Error/types'
import { UNLIMITED_VALUE_COUPON } from '../create-coupon/constants/unlimited-value-coupon'
function HistoryCoupon() {
	const location = useLocation<{
		state: string
	}>()

	const showSucessMessageCoupon =
		location.state !== null &&
		location.state !== undefined &&
		location.state.state === 'couponSucessMessage'

	const [successesMessage, setSuccessesMessage] = useState<boolean>(false)
	const [tableData, setTableData] = useState<ITableDataCoupons[]>([])
	const [navigationProps, setNavigationProps] = useState(initialFetchProps)
	const [selectedCoupon, setSelectedCoupon] = useState(0)
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [paginationRef, setPaginationRef] =
		useState<Omit<IResponseGetCoupons, 'data'>>()

	const [fetchCoupon, setFetchCoupon] = useState<ITableDataCoupons>(
		{} as ITableDataCoupons
	)

	const history = useHistory()
	const fetchHistoryCoupons = useCallback(
		async ({
			pageNumber,
			pageSize,
			status,
			tradeStatus,
			name,
			couponID,
			finished
		}: IGetCouponsService = navigationProps) => {
			setIsLoading(true)
			try {
				const data = await getCoupons({
					pageNumber,
					pageSize,
					status,
					tradeStatus,
					name,
					couponID,
					finished
				})
				return data
			} catch (e) {
				handleError(e as IError)
			} finally {
				setIsLoading(false)
			}
		},
		[navigationProps]
	)
	const handleChange = async (currentCoupon: ITableDataCoupons) => {
		setIsLoading(true)
		try {
			await enableDisableCoupon({
				couponID: currentCoupon.ID,
				status: !currentCoupon.status
			})

			setTableData((prev) =>
				prev.filter((item) => item.ID !== currentCoupon.ID)
			)

			cogoToast.success(
				!currentCoupon.status
					? `O Cupom ${currentCoupon.name} foi ativado com sucesso`
					: `O Cupom ${currentCoupon.name} foi inativado com sucesso`,
				cogoDefaultOptions
			)
		} catch (e) {
			console.error(e)
		} finally {
			setIsLoading(false)
		}
	}

	const fetchCouponByID = useCallback(async () => {
		setIsLoading(true)
		const data = await getCouponsByID({ couponID: selectedCoupon })
		setFetchCoupon(data)
	}, [selectedCoupon])

	useEffect(() => {
		fetchCouponByID().then(() => {
			setIsLoading(false)
		})
	}, [fetchCouponByID])
	const columns = [
		{
			Header: 'ID',
			accessor: 'ID',
			Cell: (cell: ICellTableData) => (
				<S.StyledParagraph
					status={cell.row.original.tradeStatus}
					onClick={() => {
						setSelectedCoupon(cell.row.original.couponID)
						setIsOpenModal((prevState) => !prevState)
					}}>
					{cell.row.original.couponID}
				</S.StyledParagraph>
			),
			disableSortBy: false
		},
		{
			Header: 'Nome',
			accessor: 'name',
			Cell: (cell: ICellTableData) => (
				<S.StyledNameParagraph>
					{cell.row.original.name}
				</S.StyledNameParagraph>
			)
		},
		{
			Header: 'Tipo',
			accessor: 'couponType',
			Cell: (cell: ICellTableData) => (
				<p>
					{cell.row.original.couponType === 'item'
						? 'Produto'
						: 'Carrinho'}
				</p>
			)
		},
		{
			Header: 'Exibido no app',
			accessor: 'showIn',
			Cell: (cell: ICellTableData) => (
				<p>{cell.row.original.showIn === 'APP' ? 'Sim' : 'Não'}</p>
			)
		},
		{
			Header: 'Desconto',
			accessor: 'discountValue',
			Cell: (cell: ICellTableData) => {
				return cell.row.original.discountType === 'money' ? (
					<p>{`${toMoney(cell.row.original.discountValue)}`}</p>
				) : (
					<p>{`${cell.row.original.discountValue}%`}</p>
				)
			}
		},
		{
			Header: 'Data de Início',
			accessor: 'dateStart',
			Cell: (cell: ICellTableData) => (
				<S.StyledDateParagraph>
					{formatDate(cell.row.original.dateStart)}
				</S.StyledDateParagraph>
			)
		},
		{
			Header: 'Data de fim',
			accessor: 'dateEnd',
			Cell: (cell: ICellTableData) => (
				<S.StyledDateParagraph>
					{formatDate(cell.row.original.dateEnd)}
				</S.StyledDateParagraph>
			)
		},
		{
			Header: 'Qtd',
			accessor: 'quantityAvailable',
			Cell: (cell: ICellTableData) => {
				return (
					<p>
						{cell.row.original.quantityAvailable ===
							unlimitedQtdCoupon ||
						cell.row.original.quantityAvailable ===
							UNLIMITED_VALUE_COUPON
							? 'Ilimitado'
							: cell.row.original.quantityAvailable}
					</p>
				)
			}
		},
		{
			Header: 'Cupons Aplicados',
			accessor: 'cupomUsedCount'
		},
		{
			Header: 'Inativar',
			accessor: 'status',
			disableSortBy: true,
			Cell: (cell: ICellTableData) => {
				return (
					<Toggle
						isChecked={cell.row.original.status}
						// isChecked={false}
						handleChecked={() => handleChange(cell.row.original)}
					/>
				)
			}
		}
	]

	useEffect(() => {
		fetchHistoryCoupons(navigationProps).then((response) => {
			setTableData(response?.data || [])
			setPaginationRef({
				totalItems: response?.totalItems || 0,
				totalPages: response?.totalPages || 0
			})
			setIsLoading(false)
		})
	}, [fetchHistoryCoupons, navigationProps])

	useEffect(() => {
		if (showSucessMessageCoupon) {
			window.scrollTo(0, 0)
			setSuccessesMessage(true)
		}
	}, [location.state, showSucessMessageCoupon])

	const handleSearchCouponByIdOrName = useCallback(
		async (search) => {
			if (!search) {
				return fetchHistoryCoupons(navigationProps)
			} else if (isNaN(Number(search))) {
				return fetchHistoryCoupons({
					...navigationProps,
					pageNumber: '1',
					name: search
				})
			} else {
				return fetchHistoryCoupons({
					...navigationProps,
					pageNumber: '1',
					couponID: Number(search)
				})
			}
		},
		[fetchHistoryCoupons, navigationProps]
	)
	return (
		<>
			<TopModalSucess
				isOpen={successesMessage && !isLoading}
				handleHideModal={() => setSuccessesMessage(false)}
				sucessToastMessage={'Cupom enviado para aprovação com sucesso'}
			/>
			<DefaultLayoutAdmin>
				<div>
					<LoadingCcontainer isLoading={isLoading} />
					<S.StyledTitle>Cupom de Desconto</S.StyledTitle>
					<HeaderCoupon
						options={options}
						selectedOption={options[0]}
						onChangeOptions={(option) => {
							setNavigationProps((prevState) => {
								if (option.value === 'Aprovado') {
									return {
										...prevState,
										status: 'true',
										finished: false
									}
								} else if (option.value === 'finished') {
									return {
										...prevState,
										status: 'false',
										finished: true
									}
								} else {
									return {
										...prevState,
										status: 'false',
										finished: false
									}
								}
							})
						}}
						handleExtractData={getCsvCoupons}
						handleSearchCouponByIdOrName={(search) =>
							handleSearchCouponByIdOrName(search).then(
								(response) => {
									setTableData(response?.data || [])
									setPaginationRef({
										totalItems: response?.totalItems || 0,
										totalPages: response?.totalPages || 0
									})
									setIsLoading(false)
								}
							)
						}
					/>

					<Table
						columns={
							columns as unknown as ITableColumn<ITableDataCoupons>[]
						}
						data={tableData}
						navProps={
							{
								gotoPage(selectedIndex: number) {
									setNavigationProps((prevState) => ({
										...prevState,
										pageNumber:
											(navigationProps.pageNumber =
												String(selectedIndex + 1))
									}))
								},
								nextPage(index) {
									setNavigationProps((prevState) => ({
										...prevState,
										pageNumber:
											(navigationProps.pageNumber =
												String(index + 1))
									}))
								},
								previousPage(index) {
									setNavigationProps((prevState) => ({
										...prevState,
										pageNumber:
											(navigationProps.pageNumber =
												String(index + 1))
									}))
								},
								pageCount: paginationRef?.totalPages,
								pageIndex:
									Number(navigationProps.pageNumber) - 1,
								totalDocs: paginationRef?.totalItems
							} as ITableNavProps
						}
						canSortByHeader={true}
					/>
					{isOpenModal && !isLoading && (
						<DetailsCoupon
							coupon={fetchCoupon}
							handleClose={() => setIsOpenModal(false)}
							isOpen={isOpenModal}
							handleEditCoupon={() => {
								history.push(AdminRoutesEnum.CREATE_COUPON, {
									state: fetchCoupon.couponID
								})
							}}
						/>
					)}
				</div>
			</DefaultLayoutAdmin>
		</>
	)
}

export default HistoryCoupon
