import React, { Fragment } from 'react'

import SelectPrimary from '../../../components/SelectPrimary'
import { IProps } from './types'

import SearchIcon from '../../../assets/images/search.svg'

function Filters(props: IProps): JSX.Element {
	const {
		selectOptionsOne,
		selectOptionsTwo,
		selectOptionsThree,
		handleSelectOne,
		handleSelectTwo,
		handleSelectThree,
		handleSearchInput,
		selectedFilters,
		isLoading
	} = props

	return (
		<Fragment>
			<section className="combo-filters">
				<div className="filters-wrapper">
					<div className="miniable-input">
						<SelectPrimary
							isLoading={isLoading}
							isClearable={true}
							placeholder={selectOptionsOne.placeholder}
							options={selectOptionsOne.options}
							onChange={handleSelectOne}
							value={selectedFilters.selectOne}
						/>
					</div>
					<div className="maxalbe-input">
						<SelectPrimary
							isLoading={isLoading}
							isClearable={true}
							placeholder={selectOptionsTwo.placeholder}
							options={selectOptionsTwo.options}
							onChange={handleSelectTwo}
							value={selectedFilters.selectTwo}
						/>
					</div>
					<div className="miniable-input">
						<SelectPrimary
							isLoading={isLoading}
							isClearable={true}
							placeholder={selectOptionsThree.placeholder}
							options={selectOptionsThree.options}
							onChange={handleSelectThree}
							value={selectedFilters.selectThree}
						/>
					</div>
				</div>
				<div className="input">
					<input
						onChange={handleSearchInput}
						required
						id="search"
						value={selectedFilters.searchInput}
					/>
					<label htmlFor="search">Pesquisar produto</label>
					<img
						className="magnifier-icon"
						src={SearchIcon}
						alt="Icone de Lupa"
					/>
				</div>
			</section>
		</Fragment>
	)
}

export default Filters
