import React from 'react'

import * as S from './behavior-filter.styles'
import { DataSelector } from '../../../../components/data-selector'
import PreviewPhone from '../preview-phone/preview-phone'
import ButtonSelected from './button-selected/button-selected.component'
import FloatLabelLocaleInput from '../../../../components/FloatLabelLocaleInput'
import FloatLabelTextareaInput from '../../../../components/FloatLabelTextAreaInput'
import { colors, primaryInputStyle } from '../../../../shared/styles/theme'
import FileUploader from '../../../../components/file-uploader/file-uploader.component'
import DatePicker from '../../../../components/DatePicker'

import { getProducts } from '../../../../modules/RegisterProduct/service'
import { IProduct } from '../../../../modules/RegisterProduct/types'
import Loading from '../../../../components/Loading'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { PushActionTypesEnum } from '../../../../shared/interfaces/pushNotification'
import ConfirmPushModal from '../push-modals/confirm-push/confirm-push-modal.component'
import { SelectorOption } from '../../../../components/data-selector/data-selector.types'
import { BehaviorFilterProps, IProductItem } from './behavior-filter.types'
import { addDays } from 'date-fns'
import {
	handleFilterHourPicker,
	originalArrayHours
} from './utils/handleFilterHourPicker'
import {
	productItems,
	categoryItems,
	comboItems
} from './utils/arrayDynamicItems'
import { DataSelectorList } from '../../../../components/data-selector-list'

export function BehaviorFilter({
	userImpacted,
	handleSendNotification
}: BehaviorFilterProps) {
	const [publishNow, setPublishNow] = React.useState(true)
	const [programingPublish, setProgramingPublish] = React.useState(false)
	const [selectedDate, setSelectedDate] = React.useState<Date | ''>('')
	const [selectedOptionAction, setSelectedOptionAction] =
		React.useState<SelectorOption>({
			value: PushActionTypesEnum.OPEN_APP,
			name: 'Abrir o Aplicativo'
		})
	const [showSelectProducts, setShowSelectProducts] = React.useState(false)

	const [pushTitle, setPushTitle] = React.useState('')
	const [pushMessage, setPushMessage] = React.useState('')
	const [srcImage, setSrcImage] = React.useState('')
	const [showPreview, setShowPreview] = React.useState(false)
	const [imageNotification, setImageNotification] = React.useState('')
	const [products, setProducts] = React.useState<IProduct[]>([])
	const [productsForDisplay, setProductsForDisplay] = React.useState<any[]>(
		[]
	)
	const [selectedProduct, setSelectedProduct] = React.useState<IProductItem>({
		value: '',
		name: ''
	})
	const [isLoading, setIsLoading] = React.useState(false)
	const [openModalConfirm, setOpenModalConfirm] = React.useState(false)
	const [targetDate, setTargetDate] = React.useState('')
	const [hours, setHours] = React.useState([])
	const [selectedHour, setSelectedHour] = React.useState<SelectorOption>(null)

	React.useEffect(() => {
		if (pushTitle.length > 0 && pushMessage.length > 0) {
			setShowPreview(true)
		}
	}, [pushTitle, pushMessage])
	const [canSendPush, setCanSendPush] = React.useState(false)

	const handlePublishNow = React.useCallback(() => {
		setPublishNow(true)
		setSelectedDate('')
		setSelectedHour(null)
		setProgramingPublish(false)
	}, [])

	const handleProgramingPush = React.useCallback(() => {
		setPublishNow(false)
		setProgramingPublish(true)
	}, [])

	function onSelectionChange(option: SelectorOption) {
		setSelectedOptionAction(option as SelectorOption)
		setShowSelectProducts(
			option.value !== PushActionTypesEnum.OPEN_APP &&
				option.value !== PushActionTypesEnum.OPEN_COMBOS &&
				option.value !== PushActionTypesEnum.OPEN_PROGRAM_POINTS
		)
	}

	React.useEffect(() => {
		if (
			selectedOptionAction.value ===
			PushActionTypesEnum.OPEN_SPECIFIC_PRODUCT
		) {
			setProductsForDisplay(productItems(products))
		} else if (
			selectedOptionAction.value ===
			PushActionTypesEnum.OPEN_PRODUCT_CATEGORY
		) {
			setProductsForDisplay(categoryItems(products))
		} else if (
			selectedOptionAction.value === PushActionTypesEnum.OPEN_COMBOS
		) {
			setProductsForDisplay(comboItems(products))
		}
		setSelectedProduct({
			value: '',
			name: ''
		})
	}, [selectedOptionAction, products])

	function onSelectionChangeHour(option: SelectorOption) {
		setSelectedHour(option as SelectorOption)
	}

	React.useEffect(() => {
		const handleFormateDate = () => {
			var dateObj = new Date(selectedDate)
			const splitedHours = selectedHour?.value.split(':')
			const hoursFormatedDate = new Date(
				dateObj.setHours(splitedHours[0])
			).setMinutes(splitedHours[1])

			const result = new Date(hoursFormatedDate)
			setTargetDate(result.toISOString())
		}
		if (selectedHour && selectedDate) {
			handleFormateDate()
		} else {
			setTargetDate('')
		}
	}, [selectedHour, selectedDate])
	React.useEffect(() => {
		const handleGetProducts = async () => {
			const data = await getProducts()

			setProducts(data)
		}
		setIsLoading(true)
		handleGetProducts()
		setIsLoading(false)
	}, [])

	React.useEffect(() => {
		const conditionSendPush =
			pushTitle.length === 0 ||
			pushMessage.length === 0 ||
			(selectedOptionAction.value !== PushActionTypesEnum.OPEN_APP &&
				selectedOptionAction.value !==
					PushActionTypesEnum.OPEN_PROGRAM_POINTS &&
				selectedOptionAction.value !==
					PushActionTypesEnum.OPEN_COMBOS &&
				!selectedProduct.value)

		setCanSendPush(!conditionSendPush)
	}, [
		canSendPush,
		pushTitle.length,
		pushMessage.length,
		selectedOptionAction,
		selectedProduct.value
	])

	React.useEffect(() => {
		if (imageNotification.length > 0 && srcImage.length > 0) {
			setImageNotification(srcImage)
		} else if (srcImage.length > 0) {
			setImageNotification(srcImage)
		}
	}, [srcImage, imageNotification])

	React.useEffect(() => {
		const hour = handleFilterHourPicker()
		const today = new Date()
		const selectedDay = new Date(selectedDate)

		if (selectedDay.getDay() === today.getDay()) {
			setHours(hour)
		} else {
			setHours(originalArrayHours)
		}
	}, [selectedDate])

	return (
		<S.Wrapper>
			<ConfirmPushModal
				pushTitle={pushTitle}
				messagemAplied={pushMessage}
				usersImpacted={userImpacted}
				handleToggleModal={() => setOpenModalConfirm(false)}
				isOpen={openModalConfirm}
				handleSendPush={() => {
					handleSendNotification(
						pushTitle,
						pushMessage,
						selectedOptionAction.value,
						programingPublish ? targetDate : undefined,
						selectedOptionAction.value ===
							PushActionTypesEnum.OPEN_SPECIFIC_PRODUCT
							? selectedProduct.value
							: '',
						selectedOptionAction.value ===
							PushActionTypesEnum.OPEN_PRODUCT_CATEGORY
							? selectedProduct.value
							: '',
						imageNotification
					)
					setOpenModalConfirm(false)
				}}
			/>
			<S.Title>Comportamento</S.Title>
			<S.Container>
				<S.LeftContainer>
					<DataSelectorList
						title="Tipo de ação"
						list={[
							{
								value: PushActionTypesEnum.OPEN_APP,
								name: 'Abrir o Aplicativo'
							},
							{
								value: PushActionTypesEnum.OPEN_SPECIFIC_PRODUCT,
								name: 'Abrir um Produto Específico'
							},
							{
								value: PushActionTypesEnum.OPEN_PRODUCT_CATEGORY,
								name: 'Abrir Categoria de Produto'
							},
							{
								value: PushActionTypesEnum.OPEN_COMBOS,
								name: 'Abrir o Módulo de Combos'
							},
							{
								value: PushActionTypesEnum.OPEN_PROGRAM_POINTS,
								name: 'Abrir o Módulo Programa de Pontos'
							}
						]}
						handleChange={(item) => onSelectionChange(item.target)}
						selectedOption={selectedOptionAction}
						isNative={false}
					/>
					{!isLoading ? (
						<>
							{showSelectProducts && (
								<DataSelectorList
									title={
										selectedOptionAction.value ===
										PushActionTypesEnum.OPEN_PRODUCT_CATEGORY
											? 'Selecione a Categoria'
											: 'Selecione o Produto'
									}
									list={productsForDisplay}
									handleChange={(item) =>
										setSelectedProduct(item.target)
									}
									selectedOption={selectedProduct}
									isNative={false}
								/>
							)}
						</>
					) : (
						<Loading />
					)}
					<div className="programming-options">
						<S.SubTitle>Opções de programação</S.SubTitle>
						<div className="wrapper-buttons">
							<ButtonSelected
								buttonText="PUBLICAR AGORA"
								handleOnClick={() => handlePublishNow()}
								isSelected={publishNow}
								isDisabled={false}
							/>
							<ButtonSelected
								buttonText="PROGRAMAR"
								handleOnClick={() => handleProgramingPush()}
								isSelected={programingPublish}
								isDisabled={false}
							/>
						</div>
						{programingPublish && (
							<div className="wrapper-inputs">
								<div className="datePicker">
									<DatePicker
										value={selectedDate}
										dateSetter={setSelectedDate}
										disabledDaysObj={{
											before: addDays(new Date(), 1 - 1)
										}}
									/>
								</div>
								<DataSelector
									options={hours}
									isDisabled={!selectedDate ? true : false}
									placeholder="Horário"
									selectedOption={selectedHour}
									onSelectionChange={onSelectionChangeHour}
								/>
							</div>
						)}
					</div>
					<FileUploader
						handleGetSrc={setSrcImage}
						acceptExtensios=".png"
					/>
					<S.SubTitle>Texto</S.SubTitle>
					<FloatLabelLocaleInput
						label="Título"
						id="title-input"
						{...primaryInputStyle}
						onChange={(event) => setPushTitle(event.target.value)}
						style={{ backgroundColor: 'white', color: 'black' }}
						value={pushTitle}
						maxLength={42}
						labelColor={colors.red}
					/>

					<FloatLabelTextareaInput
						label="Mensagem"
						id="message-input"
						{...primaryInputStyle}
						style={{ backgroundColor: 'white', color: 'black' }}
						labelColor={colors.red}
						onChange={(event) => setPushMessage(event.target.value)}
						value={pushMessage}
						maxLength={220}
					/>
				</S.LeftContainer>
				<S.RightContainer>
					<h2>Prévia em tela</h2>
					<PreviewPhone
						isSelectedData={showPreview}
						titleNotification={pushTitle}
						messageNotification={pushMessage}
						imageNotification={imageNotification}
					/>
				</S.RightContainer>
			</S.Container>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
				{!canSendPush ? (
					<ButtonDefault
						buttonText="ENVIAR PUSH"
						type={TypeButtonEnum.DISABLED}
						onClick={() => {}}
						idElement="enviar-push"
						width="234px"
					/>
				) : (
					<ButtonDefault
						buttonText="ENVIAR PUSH"
						type={TypeButtonEnum.PRIMARY}
						onClick={() => setOpenModalConfirm(true)}
						idElement="enviar-push"
						width="234px"
					/>
				)}
			</div>
		</S.Wrapper>
	)
}
