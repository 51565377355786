import {
	differenceInDays,
	differenceInMonths,
	differenceInYears,
	eachDayOfInterval,
	format,
	isFuture,
	isValid,
	parse,
	parseISO
} from 'date-fns'
import { pt, ptBR } from 'date-fns/locale'
import BR from 'date-fns/locale/pt-BR'

export const weekNamesLong = [
	'Domingo',
	'Segunda',
	'Terça',
	'Quarta',
	'Quinta',
	'Sexta',
	'Sábado'
]

export const weekNamesShort = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']

export const monthNamesArray = [
	'Janeiro',
	'Fevereiro',
	'Março',
	'Abril',
	'Maio',
	'Junho',
	'Julho',
	'Agosto',
	'Setembro',
	'Outubro',
	'Novembro',
	'Dezembro'
]

/**
 *
 * @param date yyyy-mm-dd
 */
export function parseDate(date: string): Date {
	return parse(date, 'yyyy-mm-dd', new Date(), { locale: BR })
}

/**
 *
 * @param date
 * @returns dd/MM/yyyy HH:mm
 */
export function formatDate(date: Date | number): string {
	return format(date, 'dd/MM/yyyy HH:mm', { locale: BR })
}

/**
 *
 * @param date ISODate yyyy-MM-dd'T'HH:mm:ss.SSSxxx
 * @returns dd/MM/yyyy HH:mm
 */
export function parseFormat(date: string): string {
	return format(parseISO(date), 'dd/MM/yyyy HH:mm', { locale: BR })
}

/**
 *
 * @param date ISODate yyyy-MM-dd'T'HH:mm:ss.SSSxxx
 * @returns dd/MM/yyyy
 */
export function parseFormatOnlyDate(date: string): string {
	return format(parseISO(date), 'dd/MM/yyyy', { locale: BR })
}

/**
 *
 * @param date ISODate yyyy-MM-dd'T'HH:mm:ss.SSSxxx
 * @returns dd/MM/yyyy HH:mm
 */
export function parseFormatToDate(date: string): Date {
	return parseISO(date)
}

/**
 *
 * @param string dd/MM/yyyy
 * @returns yyyy-MM-dd
 */
export function reverseDate(date: string): string {
	const val = date.split('/')
	return `${val[2]}-${val[1]}-${val[0]}`
}

/**
 *
 * @param string yyyy-MM-dd
 * @returns dd/MM/yyyy
 */
export function reverseDateHuman(date: string): string {
	const val = date.split('-')
	return `${val[2]}/${val[1]}/${val[0]}`
}

export function formatDeliveryDate(date: Date) {
	return format(date, "dd 'de' LLLL',' yyyy", { locale: pt }).toUpperCase()
}

export const DATE_FORMAT_MONTH_AND_YEAR = 'MM/yy'

export function formatMonthAndYear(date: string) {
	if (date.length < 4) return date
	const parsed = parse(date, 'MMyy', new Date())

	if (!isValid(parsed)) return date
	return format(parsed, DATE_FORMAT_MONTH_AND_YEAR)
}

/**
 *
 * @param dateString string dd/MM/yyyy
 * @returns number
 */
export function returnsRemainingDays(dateString: string) {
	const year = new Date().getFullYear()
	const month = (new Date().getMonth() + 1).toString().padStart(2, '0')
	const day = new Date().getDate().toString().padStart(2, '0')
	const currentDate = new Date(year + '-' + month + '-' + day + 'T09:00:00')
	const endDate = new Date(dateString + 'T15:00:00')
	if (endDate < currentDate) return 0
	return eachDayOfInterval({ start: currentDate, end: endDate }).length
}

/**
 *
 * @param dateString string dd/MM/yyyy
 * @returns string
 */
export function remainingDaysLabel(dateString: string) {
	const remainingDays = returnsRemainingDays(dateString)

	return remainingDays === 1 ? `Falta 1 dia` : `Faltam ${remainingDays} dias`
}

export function maskDate(value: string) {
	let v = value.replace(/\D/g, '').slice(0, 10)
	if (v.length >= 5) {
		return `${v.slice(0, 2)}/${v.slice(2, 4)}/${v.slice(4)}`
	} else if (v.length >= 3) {
		return `${v.slice(0, 2)}/${v.slice(2)}`
	}
	return v
}

export function validatorDate(date: string) {
	const year = date.split('/')[2]
	if (!year || year.length < 4) return false
	const parsedDate = parse(date, 'P', new Date(), { locale: ptBR })

	const isValidDate = isValid(parsedDate)
	const dateIsFuture = isFuture(parsedDate)

	if (dateIsFuture) return false

	return isValidDate
}

export const FORMAT_BIRTH_BIRTH_DATE = 'yyyy-MM-dd'

export function formatBirthDate(date: string) {
	const dateParsed = parse(date, 'dd/MM/yyyy', new Date())
	const formatted = format(dateParsed, FORMAT_BIRTH_BIRTH_DATE, {
		locale: pt
	})
	return formatted
}

export function friendlyFormatDate(dateStr: string) {
	const date = new Date(dateStr)
	let text = ''

	const dateFormattedDays = differenceInDays(new Date(), date)
	const dateFormattedMonth = differenceInMonths(new Date(), date)
	const dateFormattedYears = differenceInYears(new Date(), date)

	if (dateFormattedDays < 30) {
		text = `Há ${dateFormattedDays} dias`
	} else if (dateFormattedMonth < 12) {
		text =
			dateFormattedMonth > 1
				? `Há ${dateFormattedMonth} meses`
				: `Há ${dateFormattedMonth} mês`
	} else if (dateFormattedDays >= 1) {
		text =
			dateFormattedYears > 1
				? `Há ${dateFormattedYears} anos`
				: `Há ${dateFormattedYears} ano`
	}

	return text
}

export function tranformDeliveryDate(dateString?: string) {
	if (!dateString) return

	const formattedDateLabel = `${format(
		parse(dateString, 'yyyy-MM-dd', new Date()),
		"dd 'de' MMMM, yyyy",
		{
			locale: BR
		}
	)}`

	return formattedDateLabel
}
