import { Row } from 'react-table'

import { reverseDate } from './date'
// import {
// 	StatusOrder,
// 	StatusOrderTypes
// } from '../../modules/SAC/PendingApproval/types'

export function sortBadgeByID(param: string) {
	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		const rowAID = rowA.values[param].props.id
		const rowBID = rowB.values[param].props.id

		if (rowAID < rowBID) return -1
		if (rowAID > rowBID) return 1
		return 0
	}
}

type StatusOrderTypes = {
	[key: string]: number
}

export function sortStatus(param: string) {
	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		const statusOrder: StatusOrderTypes = {
			Aprovado: 1,
			Informativo: 2,
			Pendente: 3,
			Recusado: 4
		}

		const statusA = (rowA.values.status?.props.id as string) || null
		const statusB = (rowB.values.status?.props.id as string) || null

		if (statusA !== null && statusB !== null) {
			return statusOrder[statusA] - statusOrder[statusB]
		} else if (statusA === null && statusB === null) {
			return 0
		} else if (statusA === null) {
			return 1
		} else {
			return -1
		}
	}
}
export function sortDate(param: string) {
	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		const a = reverseDate(rowA.values[param])
		const b = reverseDate(rowB.values[param])

		if (a < b) return -1
		if (a > b) return 1
		return 0
	}
}

export function sortMoney(param: string) {
	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		const a = parseFloat(rowA.values[param].replace(/[R$,.]+/g, ''))
		const b = parseFloat(rowB.values[param].replace(/[R$,.]+/g, ''))

		if (a < b) return -1
		if (a > b) return 1
		return 0
	}
}

export function sortName(rowA: Row, rowB: Row, _: string, __: boolean) {
	const rowAName = rowA.values.name
	const rowBName = rowB.values.name

	return rowAName.localeCompare(rowBName)
}
