import React, { Fragment } from 'react'

import ChipCouponStatus from '../ChipCouponStatus'

import { PointsExpirationStatusLabelEnum } from '../../shared/interfaces/pointsProgram'

import { IPointsBalanceCardProps } from './types'

import * as S from './styles'

export default function PointsBalanceCard(props: IPointsBalanceCardProps) {
	const { pointsBalance, pointsToExpire, pointsUnderAnalysis } = props

	return (
		<Fragment>
			<S.CardWrapper>
				<S.LabelMyPoints>Meu Saldo de Pontos</S.LabelMyPoints>
				<S.ContentInfosTopWrapper>
					<S.TotalPointsLabel>{pointsBalance}</S.TotalPointsLabel>
					<ChipCouponStatus
						status={PointsExpirationStatusLabelEnum.UNDER_ANALYSIS}>
						+{pointsUnderAnalysis}
					</ChipCouponStatus>
				</S.ContentInfosTopWrapper>
				<S.TotalPointsToExpireWrapper>
					<S.PointsToExpireLabel>
						Pontos a Expirar
					</S.PointsToExpireLabel>
					<S.TotalPointsToExpireLabel>
						{pointsToExpire}
					</S.TotalPointsToExpireLabel>
				</S.TotalPointsToExpireWrapper>
			</S.CardWrapper>
		</Fragment>
	)
}
