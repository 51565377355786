import React from 'react'

import * as S from './styles'
import { IProps } from './types'

export default function InputTitleModalBanner(props: IProps) {
	const {
		name,
		label,
		disabled,
		errorMessage,
		containerStyle,
		isChallengeScreen,
		...rest
	} = props
	return (
		<S.Wrapper>
			<S.Container
				disabled={disabled}
				errorMessage={!!errorMessage}
				isChallengeScreen={isChallengeScreen}
				style={containerStyle}>
				<div>
					<S.Label disabled={!!disabled} htmlFor={name}>
						{label}
					</S.Label>
					<S.Input disabled={disabled} name={name} {...rest} />
				</div>
			</S.Container>
			{!!errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
		</S.Wrapper>
	)
}
