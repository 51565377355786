import React from 'react'
import * as S from './password-created.styles'

import { useHistory } from 'react-router-dom'
import ButtonDefault from '../buttons/button/button-default'
import { TypeButtonEnum } from '../buttons/button/button-default.types'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'
import { logout } from '../../shared/services/axiosInstace'
import VerificatedIcon from './assets/verificated-icon.svg'

function SecurityQuestionsComponent() {
	const history = useHistory()
	return (
		<>
			<S.PasswordCreatedView>
				<img src={VerificatedIcon} alt="VerificatedIcon" />
				<S.Title>Atualização de dados cadastrais feita com sucesso!</S.Title>
			</S.PasswordCreatedView>
			<S.WrapperButton>
				<div className="wrapperButtonDefault">
					<ButtonDefault
						buttonText="Acessar conta"
						type={TypeButtonEnum.PRIMARY}
						onClick={() =>{
							logout()
							history.replace(UnauthenticatedRoutesEnum.LOGIN);
						}
						}
						idElement="access-acount"
						width="234px"
					/>
				</div>
			</S.WrapperButton>
		</>
	)
}

export default SecurityQuestionsComponent
