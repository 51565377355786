import React from 'react'
import { IViewProps } from './types'
import ArrowLeftBlackIcon from '../../../assets/images/arrow-left-black.svg'

function PaymentNavigation(props: IViewProps): JSX.Element {
	const { handleBack, stepCurrent } = props

	return (
		<div className="container payment-steps">
			<div className="row cart-back">
				<button onClick={handleBack}>
					<img src={ArrowLeftBlackIcon} alt="Voltar" />
					<p>Voltar para o Carrinho</p>
				</button>
			</div>
			<div className="row steps">
				<div className="active">
					<i>1</i>
					<span>Pagamento</span>
					<div
						className={
							stepCurrent > 1 ? 'border border-active' : 'border'
						}
					/>
				</div>
				<div className={`${stepCurrent > 1 ? 'active' : ''}`}>
					<i>2</i>
					<span>Entrega</span>
					<div
						className={
							stepCurrent > 2 ? 'border border-active' : 'border'
						}
					/>
				</div>
				<div className={`${stepCurrent > 2 ? 'active' : ''}`}>
					<i>3</i>
					<span>Confirmação</span>
				</div>
			</div>
		</div>
	)
}

export default PaymentNavigation
