import 'react-day-picker-8/dist/style.css'

import React from 'react'
import ptBR from 'date-fns/locale/pt-BR'

import * as S from './date-range-picker.styles'
import { DateRangePickerProps } from './date-range-picker.types'

export function DateRangePicker({
	selectedDate,
	setSelectedDate,
	placeholder = '',
	disabled = []
}: DateRangePickerProps) {
	const [showPicker, setShowPicker] = React.useState(false)
	const datePickerRef = React.useRef<HTMLDivElement>(null)
	const inputRef = React.useRef<HTMLInputElement>(null)

	const inputValue = React.useMemo(() => {
		let formatedFrom, formatedTo

		if (selectedDate?.from) {
			formatedFrom = Intl.DateTimeFormat('pt-BR').format(
				selectedDate?.from
			)
		}
		if (selectedDate?.to) {
			formatedTo = Intl.DateTimeFormat('pt-BR').format(selectedDate.to)
		}

		if (formatedFrom === formatedTo) {
			return formatedFrom
		} else
			return `${formatedFrom || ''}${formatedTo ? ` - ${formatedTo}` : ''
				}`
	}, [selectedDate])

	function handleShowPicker() {
		setShowPicker(true)
	}

	React.useEffect(() => {
		const handleOutsideClick = (event: any) => {
			if (
				datePickerRef.current &&
				inputRef.current &&
				!datePickerRef.current.contains(event.target) &&
				!inputRef.current.contains(event.target)
			) {
				setShowPicker(false)
			}
		}

		if (showPicker) {
			document.addEventListener('click', handleOutsideClick)
		}

		return () => {
			document.removeEventListener('click', handleOutsideClick)
		}
	}, [showPicker])

	return (
		<S.DatePickerWrapper>
			<S.ReadOnlyInput
				type="text"
				id="date-picker"
				name="fname"
				value={inputValue}
				readOnly
				placeholder={placeholder}
				onClick={handleShowPicker}
				ref={inputRef}
			/>

			{showPicker && (
				<S.DateRangePickerContainer>
					<S.DayPickerWrapper ref={datePickerRef}>
						<S.DayPicker
							disabled={disabled}
							mode="range"
							selected={selectedDate}
							onSelect={setSelectedDate}
							numberOfMonths={1}
							showOutsideDays
							fixedWeeks
							locale={ptBR}
							formatters={{
								formatWeekdayName: (day) =>
									Intl.DateTimeFormat('pt-BR', {
										weekday: 'narrow'
									}).format(day)
							}}
						/>
					</S.DayPickerWrapper>
				</S.DateRangePickerContainer>
			)}
		</S.DatePickerWrapper>
	)
}
