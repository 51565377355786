import React from 'react'

import { DataSelectorProps, SelectorOption } from './data-selector.types'

import { theme } from '../../theme/themeProvider'
import ReactSelect, { ActionMeta } from 'react-select-5'

export function DataSelector({
	options,
	selectedOption,
	onSelectionChange,
	placeholder = 'Selecione um valor',
	isDisabled = false,
}: DataSelectorProps) {
	const { palette, font } = theme

	const handleChange = (
		option: SelectorOption | null,
		actionMeta: ActionMeta<SelectorOption>
	) => {
		onSelectionChange(option)
	}

	return (
		<ReactSelect
			isDisabled={isDisabled}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: palette.primaryWhite.main,
					primary50: palette.extras.stroke.main,
					primary75: palette.lightGray.main,
					primary: palette.extras.stroke.dark
				}
			})}
			styles={{
				control: (base) => ({
					...base,
					fontFamily: font.family,
					fontSize: 14,
					height: '56px'
				}),
				menuList: (base) => ({
					...base,
					fontFamily: font.family,
					fontSize: 14
				}),
				indicatorSeparator: (base) => ({
					display: 'none'
				}),
				dropdownIndicator: (base) => ({
					...base,
					color: palette.primary.main
				})
			}}
			value={selectedOption}
			options={options}
			onChange={handleChange}
			placeholder={placeholder}
		/>
	)
}
