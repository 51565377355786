import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'

export const WrapperRegistrationUpdate = styled.div`
	/* min-height: 72vh; */
	color: ${colorThemeProvider.blackColor};
`

export const Title = styled.h1`
	padding-top: 32px;

	font-size: 24px;
	font-weight: 600;
	line-height: 30px;
	color: ${colorThemeProvider.blackColor};
`

export const Description = styled.p`
	padding: 32px 0;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const WrapperSelect = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding-bottom: 32px;
	border-bottom: 1px solid ${colorThemeProvider.extrasColor};
`

export const WrapperRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 90px;

	.css-b62m3t-container {
		width: 100%;
	}
`

export const WrapperRadio = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 90px;

	div {
		display: flex;
		align-items: center;
		width: 50%;

		input {
			margin-right: 8px;
		}
	}

	input,
	label {
		cursor: pointer;
	}
`

export const WrapperTextEmail = styled.div<{ $validEmail: boolean }>`
	display: flex;
	flex-direction: column;
	margin-top: 24px;
	padding-bottom: 32px;
	border-bottom: 1px solid ${colorThemeProvider.extrasColor};

	div {
		width: 50%;
		padding-right: 45px;

		label {
			margin: 0;
		}

		input {
			border: 1px solid
				${(props) =>
					props.$validEmail ? 'none' : colorThemeProvider.errorColor};
		}
	}

	p {
		color: ${colorThemeProvider.errorColor};
		padding-top: 4px;
		padding-left: 16px;
	}
`

export const WrapperTextCell = styled.div<{ $validPhone: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: end;
	margin-top: 24px;
	padding-bottom: 32px;
	border-bottom: 1px solid ${colorThemeProvider.extrasColor};

	div {
		width: 50%;
		padding-left: 45px;

		label {
			margin: 0;
		}

		input {
			border: 1px solid
				${(props) =>
					props.$validPhone ? 'none' : colorThemeProvider.errorColor};
		}
	}

	p {
		padding-left: 61px;
		width: 50%;
		display: ${(props) => (props.$validPhone ? 'none' : 'block')};
		color: ${colorThemeProvider.errorColor};
		padding-top: 4px;
	}
`

export const WrapperButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: end;
	padding-top: 32px;
	padding-bottom: 42px;
`

export const SecurityQuestionsComponent = styled.div`
	height: 70vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`
