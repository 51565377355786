import React from 'react'
import { Modal, CloseButton } from '../../../shared/components'
import { IModalDeleteUserProps } from './types'
import styles from './styles.module.scss'

export default function ModalDeleteUser(props: IModalDeleteUserProps) {
    const { isActive, isDeleting, onCancel, onConfirm } = props

    return (
        <Modal isActive={isActive}>
            <div className={styles['moda-user']}>
                <div className={styles['modal-header']}>
                    <h1 className="modal-title">Excluir Cadastro</h1>
                    <CloseButton onClick={onCancel} />
                </div>

                <div className={styles['message']}>
                    <p>Você selecionou para Excluir este cadastro.</p>
                    <p>Deseja confirmar esta exclusão?</p>
                </div>

                <div className={styles['button-wrapper']}>
                    <button
                        className="white-button"
                        style={{ height: '43px' }}
                        disabled={isDeleting}
                        onClick={onConfirm}
                    >
                        CONFIRMAR
                    </button>
                </div>

                <div className={styles['button-wrapper']}>
                    <button
                        className={styles['cancel-button']}
                        disabled={isDeleting}
                        onClick={onCancel}
                    >
                        <span>MANTER</span>
                    </button>
                </div>
            </div>
        </Modal>
    )
}
