import { store } from '../shared/store'

export function CheckIsLogged() {
	const jwtToken = store.getState().token.JWTToken

	// verifica se existe usuário logado, mesmo sem CustomerID, pois esta pendente de aprovacao.
	const isLogged =
		(!!store.getState().customer?.PersonalFiscalID ||
			!!store.getState().customer?.CompanyFiscalID) &&
		!!jwtToken

	return isLogged
}
