import styled from 'styled-components'

export const ChallengeAlertCannotBeEditedWrapper = styled.div`
	width: 100%;
	padding: 16px;
	border-radius: 4px;
	background-color: #f6f0d1;
	border: 1px solid #dfcf80;
`

export const ContentWrapper = styled.div``

export const AlertLabel = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: #887a39;
	margin-bottom: 3px;
	display: inline-block;
`

export const AlertDescription = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: #877a39a3;
`
