import styled, { css } from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const Container = styled.div`
	margin: 48px 0 0;
`

export const ChallengesRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
`

export const ChallengeItem = styled.div`
	padding: 0 8px;

	&:hover {
		cursor: pointer;
	}
`

export const ChallengeCardItem = styled.div`
	border-radius: 4px;
	background-color: ${colors.white};

	div.challenge-info {
		padding: 24px 16px 32px;
		min-height: 100px;

		div.chip-group {
			gap: 8px;
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			margin-bottom: 8px;
		}

		h3.challenge-title {
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			text-align: left;
			color: ${colors.secondGrey};
		}
	}
`

export const Banner = styled.div`
	position: relative;

	height: 198px;
	overflow: hidden;

	img {
		min-height: 198px;
		width: auto;
		height: auto;
		border-radius: 4px;
		object-fit: fill;
	}

	.chip-group {
		position: absolute;
		bottom: 16px;
		left: 16px;
	}
`

export const ChevronContainer = styled.div<{ direction: 'left' | 'right' }>`
	position: absolute;
	z-index: 2;
	top: calc(50% - 19px);
	${({ direction }) =>
		direction === 'left'
			? css`
					left: -15px;
			  `
			: css`
					right: -15px;
			  `}

	&:hover {
		cursor: pointer;
	}
`

export const ProgressBarWrapper = styled.div`
	border-top: 1px solid ${colors.whiteLight};
	padding: 16px 16px 24px 16px;
`

export const ProgressTextMessage = styled.p<{ isCompleted: boolean }>`
	color: ${(props) =>
		!!props.isCompleted ? colors.scoreValueLabelBg : colors.secondGrey};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	letter-spacing: 0.64px;
	text-transform: uppercase;
	text-align: center;
	margin-top: 12px;
	margin-bottom: 16px;
`
