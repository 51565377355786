import styled from 'styled-components'

export const CartQuantityBadge = styled.div`
	width: 33px;
	height: 23px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: 6px;
	border-radius: 12px;
	background-color: ${(props) => props.theme.palette.success.main};
`

export const ItemQuantityInCart = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.semibold};
	color: ${(props) => props.theme.palette.success.contrastText};
`
