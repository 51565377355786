import React, { useCallback, useEffect, useState } from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import TextArea from '../../../../../components/text-area/text-area.component'
import InputField from '../../../../../components/InputField'
import { DividerSimple } from '../../consult-client-info.styles'
import { Header, InfoModalEdit, InputStyle } from './modalStyles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { checkEmail, checkTel } from '../../../../../shared/utils/validators'
import { getAmbienteName } from '../../../../../utils/getApiBaseUrl.utils'
import {
	apiAdmin,
	apiCustomer
} from '../../../../../shared/services/axiosInstace'
import Loading from '../../../../../components/Loading'
import { deleteCustomer } from '../../../../../shared/services/customer.service'

interface IJustificativa {
	isOpen: boolean
	handleToggleModal: () => void
	customerId: string
	document?: string
	title: string
	subtitle: string
	titleConfirmation: string
	reason: string
	placeholder: string
	textInfo: string
	modalType?: 'email' | 'celular'
	oldValue?: string
	handleNotifcation: (message: string, type: string) => void
}

const ModalJustificativa = ({
	isOpen,
	handleToggleModal,
	customerId,
	document,
	title,
	subtitle,
	titleConfirmation,
	reason,
	placeholder,
	textInfo,
	modalType,
	oldValue,
	handleNotifcation
}: IJustificativa) => {
	const [inputValue, setInputValue] = useState('')
	const [confirmationValue, setConfirmationValue] = useState('')
	const [message, setMessage] = useState(false)
	const [comment, setComment] = useState(placeholder)
	const [isloading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!inputValue || !confirmationValue) return
		if (inputValue !== confirmationValue) {
			return setMessage(true)
		}
		setMessage(false)
	}, [inputValue, confirmationValue])

	const handleValidation = useCallback(
		(value: string) => {
			if (modalType === 'email') {
				return !checkEmail(value) && value.length >= 1
			}
			if (modalType === 'celular') {
				return !checkTel(value) && value.length >= 1
			}
		},
		[modalType]
	)

	const handleClick = () => {
		try {
			if (!inputValue || !confirmationValue || !comment || !customerId)
				return

			if (modalType === 'email') {
				const obj = {
					customerId: customerId,
					fields: {
						email: inputValue
					}
				}
				SendDataHana(obj)
				return handleNotifcation(
					`E-mail alterado com sucesso! Código enviado para o email ${inputValue}`,
					'success'
				)
			} else if (modalType === 'celular') {
				const obj = {
					customerId: customerId,
					fields: {
						cellPhone: `+55${inputValue.replace(/\D/g, '')}`
					}
				}
				SendDataHana(obj)
				return handleNotifcation(
					`Celular alterado com sucesso! Código enviado para o celular ${inputValue}`,
					'success'
				)
			}
			DeleteUser()
			return handleNotifcation(`Cliente excluído com sucesso!`, 'success')
		} catch (error) {
			setIsLoading(false)
			handleToggleModal()
			handleNotifcation('Erro ao alterar os dados do cliente', 'error')
		} finally {
			setIsLoading(false)
			handleToggleModal()
		}
	}

	async function DeleteUser() {
		setIsLoading(true)
		await deleteCustomer(`${customerId};${document}`)
	}

	async function SendDataHana(obj: any) {
		setIsLoading(true)
		const apiNotification =
			getAmbienteName() === 'local' ? apiCustomer : apiAdmin

		const response = await apiNotification.put(
			`/customer-research/updateContact`,
			obj
		)
		return response
	}

	return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			height={'659px'}
			width={'648px'}
			modalTitle={title}
			buttonText={titleConfirmation}
			handleClick={handleClick}
			isLoading={false}
			disabledButton={
				isloading || modalType
					? message || !inputValue || !confirmationValue
					: false
			}>
			<Header>
				<h2>{subtitle}</h2>
				<div className="input-fields">
					{modalType && (
						<>
							<InputStyle>
								<InputField
									className="param-input"
									value={oldValue}
									label={`${modalType} atual`}
									disabled
									mask={
										modalType === 'celular'
											? '(99) 99999-9999'
											: ''
									}
								/>
								<InputField
									className="param-input"
									label={`${modalType}`}
									value={inputValue}
									onChange={(e) =>
										setInputValue(e.target.value.trim())
									}
									hasError={handleValidation(inputValue)}
									mask={
										modalType === 'celular'
											? '(99) 99999-9999'
											: ''
									}
								/>
								<InputField
									className="param-input"
									label={`Confirmação de ${modalType}`}
									value={confirmationValue}
									onChange={(e) =>
										setConfirmationValue(
											e.target.value.trim()
										)
									}
									hasError={handleValidation(
										confirmationValue
									)}
									mask={
										modalType === 'celular'
											? '(99) 99999-9999'
											: ''
									}
								/>
							</InputStyle>
						</>
					)}
				</div>
				<h1>Justificativa de {reason}</h1>
				<DividerSimple />
				<h3 className="param-modal-title">
					Digite sua justificativa abaixo:
				</h3>
			</Header>
			<TextArea
				text={comment}
				handleChange={(e) => setComment(e.target.value)}
				label="Mensagem"
				limitCaracters={120}
			/>
			{textInfo && (
				<div className="info-modal-edit">
					<InfoModalEdit>
						<InfoOutlinedIcon />
						<p>{textInfo}</p>
					</InfoModalEdit>
				</div>
			)}
			{message ? (
				<div className="info-modal-edit">
					<InfoModalEdit isPurple>
						<InfoOutlinedIcon />
						<p>
							{modalType === 'email' ? `Emails ` : `Celulares `}
							digitados não correspondem
						</p>
					</InfoModalEdit>
				</div>
			) : (
				''
			)}
			{isloading ? (
				<div className="loading-wrapper">
					<Loading />
				</div>
			) : (
				''
			)}
		</LayoutModal>
	)
}

export default ModalJustificativa
