import styled from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const Container = styled.div`
	.component-subtitle {
		display: flex;
		flex-direction: row;
		align-items: center;

		margin-bottom: 20px;

		h2:nth-child(1) {
			width: calc(50% + 30px);
		}
	}

	.react-dual-listbox {
		.rdl-actions button {
			border-radius: 4px;
			background-color: ${colors.red};
			img.left {
				transform: rotate(180deg);
			}
		}

		optgroup {
			option {
				margin-bottom: 8px;
			}
		}

		.rdl-list-box.rdl-available,
		.rdl-list-box.rdl-selected {
			padding: 16px;
			border: 1px solid ${colors.grey};
			border-radius: 4px;
			background-color: ${colors.white};
		}
		.rdl-list-box {
			height: 300px;

			.rdl-filter {
				border-radius: 4px;
			}

			.rdl-control {
				border: none;
			}
		}
	}
`
