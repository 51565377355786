import {
	apiAdmin,
	getJwtAuthGpAcessToken
} from '../../../shared/services/axiosInstace'
import { IGetRequiresRegistrationUpdate } from '../../registration-update/registration-update.types'

interface IGetMaskedUserDoc {
	personalFiscalID?: string
	companyFiscalID?: string
	platform: string
}
export interface Iquestions {
	questionId: string
	question: string
}
export interface IMaskedUserInfo {
	email: string
	cellphoneNumber: string
	customerID: string
	alternativePhone: string
	alternativeEmail: string
	questions: Iquestions[]
}

export interface ResponseMaskedData {
	Email: string
	CellphoneNumber: string
	CustomerID: string
	AlternativePhone: string
	AlternativeEmail: string
	questions: Iquestions[]
}

export interface IUpdateCognito {
	document: string
}

export async function getUserDataMasked({
	personalFiscalID,
	companyFiscalID
}: IGetMaskedUserDoc): Promise<ResponseMaskedData> {
	const token = await getJwtAuthGpAcessToken()

	const response = await apiAdmin.get(
		`customers/security/masked-user-info/`,
		{
			params: {
				personalFiscalID,
				companyFiscalID
			},
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
	)
	return response.data
}

export async function getRequiresRegistrationUpdateResetPassword({
	personalFiscalID,
	companyFiscalID,
	fieldsToCheck
}: IGetRequiresRegistrationUpdate) {
	const accessToken = await getJwtAuthGpAcessToken()
	const response = await apiAdmin.get<any>(
		`customers/security/requires-registration-update`,
		{
			params: {
				personalFiscalID,
				companyFiscalID,
				fieldsToCheck
			},
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
	return response.data
}

export async function setUserRegistryToCognito({ document }: IUpdateCognito) {
	const response = await apiAdmin.put<any>(
		`customers/setUserRegistryToCognito`,
		{
			document
		}
	)
	return response.data
}
