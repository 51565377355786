import { IOption } from '../../modules/PointProgramRuleDetailsV2/types'
import {
	IHeaderRulePoint,
	IPointProgramRuleFilters
} from '../interfaces/pointsProgramRules'
import { axiosGPInstance } from './axiosInstace'
import { IResponseGetProductRedemptionRules, TypeRule } from './types'

export async function getRuleFilterParameters(): Promise<IPointProgramRuleFilters> {
	try {
		const { data: salesHierarchyData } = await axiosGPInstance.get(
			`/filters/sales-hierarchy`
		)
		const { data: channelsData } = await axiosGPInstance.get(
			`/filters/channels`
		)
		const { data: ufCitiesData } = await axiosGPInstance.get(
			`/filters/uf-cities`
		)

		return {
			salesHierarchy: salesHierarchyData,
			channels: channelsData,
			ufs: ufCitiesData.ufs,
			cities: ufCitiesData.cities
		}
	} catch (err) {
		return {
			salesHierarchy: [],
			channels: [],
			ufs: [],
			cities: []
		}
	}
}

export async function getChannelParameters(): Promise<IOption[]> {
	try {
		const { data: channelsData } = await axiosGPInstance.get(
			`/filters/channels`
		)

		return channelsData
	} catch (err) {
		return []
	}
}

export async function createRule(rule: IHeaderRulePoint) {
	const response = await axiosGPInstance.post('/program-point-rule', {
		...rule
	})
	return response.data
}

export async function updateRule(rule: IHeaderRulePoint) {
	const response = await axiosGPInstance.patch('/program-point-rule', {
		...rule
	})
	return response.data
}

export async function deleteRule(ruleID: string) {
	const response = await axiosGPInstance.delete(
		`/program-point-rule?ruleID=${ruleID}`
	)
	return response.data
}

export async function getRules(
	type: TypeRule,
	pageSize: number = 10,
	pageNumber: number = 1
): Promise<IResponseGetProductRedemptionRules> {
	const response = await axiosGPInstance.get(
		`/program-point-rule?type=${type}&pageSize=${pageSize}&pageNumber=${pageNumber}`
	)
	return response.data
}
