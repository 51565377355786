import React from 'react'
import * as S from './encrypted-field.styles'
import { IEncryptedField, TypeField } from './encrypted-field.types'

import Mail from './assets/mail.svg'
import Phone from './assets/phone_android.svg'
import ArrowDown from './assets/keyboard_arrow_down.svg'

const EncryptedField = ({ typeField, text, handleClick }: IEncryptedField) => {
	const [textField, setTextField] = React.useState('')

	React.useEffect(() => {
		if (typeField === TypeField.MAIL) {
			setTextField(text)
		} else if (typeField === TypeField.PHONE) {
			setTextField(text)
		} else if (typeField === TypeField.NONE) {
			setTextField(text)
		}
	}, [typeField, text])
	// console.log(textField)
	return (
		<S.Field onClick={handleClick}>
			<div>
				{typeField !== TypeField.NONE && (
					<img
						src={typeField === TypeField.MAIL ? Mail : Phone}
						alt="icon"
					/>
				)}
				<p>{textField}</p>
			</div>
			<img src={ArrowDown} alt="arrow icon" />
		</S.Field>
	)
}

export default EncryptedField
