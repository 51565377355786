import { axiosGPInstance } from '../../../shared/services/axiosInstace'
import { calcDate, formatData } from '../../../utils/dates'
import { ITableFilterParams, IUser } from './types'

interface ICompany {
	companies: { salesOrganizationID: string }[]
}

export async function getUsers(companies?: ICompany) {
	try {
		const response = await axiosGPInstance.post(
			'/signup-tracking/get-list',
			companies
		)
		return response.data
	} catch (error) {
		return []
	}
}

export async function updateSignUpStatus(id: string, status: string) {
	try {
		const data = {
			statusSACDescription: status
		}

		const response = await axiosGPInstance.put(
			`/signup-tracking/${id}`,
			data
		)

		return response.data
	} catch (error) {
		return
	}
}

export async function updateSentToSac(id: string, sentToSac: boolean) {
	try {
		const response = await axiosGPInstance.put(`/signup-tracking/${id}`, {
			sentToSac
		})

		return response.data
	} catch (error) {
		return undefined
	}
}

/**
 * Função responsável por retornar usuários (clientes) com alguma pendência, utilizando o filtro de status e range de data.
 * @param tableParams: ITableFilterParams
 * @param companies: ICompany
 * @returns lista de usuários
 */
export async function getUsersByFilter(
	tableParams?: ITableFilterParams,
	companies?: ICompany
): Promise<IUser[]> {
	let payload = tableParams
	try {
		if (tableParams?.clearFilter) {
			payload = {
				...payload,
				initialDate: formatData(calcDate(7)),
				endDate: formatData(new Date())
			}
		}
		delete payload?.clearFilter
		const response = await axiosGPInstance.post(
			'/signup-tracking/get-list',
			{ ...payload, ...companies }
		)
		return response.data
	} catch (error) {
		return []
	}
}
