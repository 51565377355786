import {
	apiAdmin,
	apiCustomer,
	getJwtAuthGpAcessToken
} from '../../../shared/services/axiosInstace'
import { cleanDocumentNumber } from '../../../shared/utils/string'
import { getAmbienteName } from '../../../utils/getApiBaseUrl.utils'
import {
	ICustomerSafetyInfo,
	ISecurityQuestions
} from '../interfaces/safe-questions.interface'

import { IGetRequiresRegistrationUpdate } from '../registration-update.types'

export async function getRequiresRegistrationUpdate({
	personalFiscalID,
	companyFiscalID,
	fieldsToCheck
}: IGetRequiresRegistrationUpdate) {
	const response = await apiAdmin.get<any>(
		`customers/security/requires-registration-update`,
		{
			params: {
				personalFiscalID: companyFiscalID
					? undefined
					: cleanDocumentNumber(personalFiscalID ?? ''),
				companyFiscalID: cleanDocumentNumber(companyFiscalID ?? ''),
				fieldsToCheck
			}
		}
	)
	return response.data
}

export async function getQuestions() {
	try {
		const response = await apiCustomer.get<ISecurityQuestions[]>(
			`/security-questions`
		)
		return response.data
	} catch (error) {
		return error
	}
}

export async function registerSafeQuestionsAndAlternativeData(
	customerSafetyInfo: ICustomerSafetyInfo
) {
	const api = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

	const response = await api.patch<any>(
		`customers/security/save-registration-update`,
		customerSafetyInfo
	)
	return response.data
}

export async function getQuestionsNotLogged() {
	const accessToken = await getJwtAuthGpAcessToken()
	try {
		const response = await apiAdmin.get<ISecurityQuestions[]>(
			`/security-questions`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		)
		return response.data
	} catch (error) {
		return error
	}
}
