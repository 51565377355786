import React from 'react'

import SearchIcon from '../../../assets/images/search-red.svg'

import { ISearchInputComponentProps } from './types'

import styles from './styles.module.scss'

export default function SearchInputBanner({
	label,
	id,
	...rest
}: ISearchInputComponentProps) {
	return (
		<div className={styles['input-search-customer']}>
			<div className={styles['search-input']}>
				<input required {...rest} id={id} />
				<label htmlFor={id}>{label}</label>
			</div>

			<img src={SearchIcon} alt="Ícone de lupa" />
		</div>
	)
}
