import * as React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { Button } from '@material-ui/core'
import { PaginationTable } from './components/pagination-table.component'
import { IComboData, IComboTable } from '../../combo.interface'
import { TableWrapper } from './combo-table.styles'
import { handleFormatDate } from './util/formateDateCombo'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#F9F9F9',
		color: '#EA0000'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}))

export default function ComboTable({
	data,
	onClickEditButton,
	setSelectedDataCombo
}: IComboTable) {
	const DEFAULT_ROWS_PER_PAGE = 10
	const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE)
	const [page, setPage] = React.useState(0)
	const [visibleRows, setVisibleRows] = React.useState<any>([])

	React.useEffect(() => {
		const rowsOnMount = data.slice(
			0 * DEFAULT_ROWS_PER_PAGE,
			0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
		)

		setVisibleRows(rowsOnMount)
	}, [data])

	const handleChangePage = React.useCallback(
		(event: unknown, newPage: number) => {
			setPage(newPage)

			const updatedRows = data.slice(
				newPage * rowsPerPage,
				newPage * rowsPerPage + rowsPerPage
			)
			setVisibleRows(updatedRows)
		},
		[data, rowsPerPage]
	)

	const handleChangeRowsPerPage = React.useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const updatedRowsPerPage = parseInt(event.target.value, 10)
			setRowsPerPage(updatedRowsPerPage)
			// console.log(updatedRowsPerPage)
			setPage(0)
			const updatedRows = visibleRows.slice(
				0 * updatedRowsPerPage,
				0 * updatedRowsPerPage + updatedRowsPerPage
			)
			setVisibleRows(updatedRows)
		},
		[visibleRows]
	)

	return (
		<TableWrapper>
			<Table sx={{ maxWidth: '100%' }} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell></StyledTableCell>
						<StyledTableCell align="center">Código</StyledTableCell>
						<StyledTableCell align="center">
							Nome do combo
						</StyledTableCell>
						<StyledTableCell align="center">
							Data Inicío
						</StyledTableCell>
						<StyledTableCell align="center">
							Data Fim
						</StyledTableCell>
						<StyledTableCell align="center">SAP</StyledTableCell>
						<StyledTableCell align="center"></StyledTableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{visibleRows.map((row: IComboData) => (
						<StyledTableRow key={row.applicantID}>
							<StyledTableCell scope="row">
								<img
									src={row.comboImageUrl}
									alt={row.description}
									style={{ height: '60px', width: '60px' }}
								/>
							</StyledTableCell>
							<StyledTableCell align="center">
								{row.promotionID}
							</StyledTableCell>
							<StyledTableCell align="center">
								{row.description}
							</StyledTableCell>
							<StyledTableCell align="center">
								{handleFormatDate(row.startDate)}
							</StyledTableCell>
							<StyledTableCell align="center">
								{handleFormatDate(row.endDate)}
							</StyledTableCell>
							<StyledTableCell align="center">
								{row.sectorID}
							</StyledTableCell>
							<StyledTableCell align="center">
								<Button
									variant="outlined"
									style={{ color: '#CE332E' }}
									onClick={() => {
										onClickEditButton()
										setSelectedDataCombo({ ...row })
									}}>
									Editar
								</Button>
							</StyledTableCell>
						</StyledTableRow>
					))}
				</TableBody>
			</Table>
			<PaginationTable
				data={data}
				rowsPerPage={DEFAULT_ROWS_PER_PAGE}
				page={page}
				handleChangePage={handleChangePage}
				handleChangeRowsPerPage={handleChangeRowsPerPage}
			/>
		</TableWrapper>
	)
}
