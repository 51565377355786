import { apiAdmin } from '../../../shared/services/axiosInstace'
import suggestedOrdersAdminMapper from './mappers/suggested-orders-admin.mapper'
import {
	CreateSuggestedOrderTypes,
	PatchStatusTypes,
	SuggestedOrdersAdminTypes
} from './types/suggested-orders-admin.types'

class SuggestedOrderAdminService {
	async get(): Promise<SuggestedOrdersAdminTypes> {
		const { data } = await apiAdmin.get(`/product-recommendation/last`)
		return suggestedOrdersAdminMapper.toDomainSuggestedOrders(data)
	}
	async patchStatus({
		userId,
		status,
		id
	}: PatchStatusTypes): Promise<SuggestedOrdersAdminTypes> {
		const { data } = await apiAdmin.patch(`/product-recommendation/${id}`, {
			userId: userId,
			status: status
		})
		return suggestedOrdersAdminMapper.toDomainSuggestedOrders(data)
	}
	async create({
		userId,
		title,
		banner,
		mainProducts,
		status,
		optionalProducts
	}: CreateSuggestedOrderTypes): Promise<any> {
		const form = {
			userId,
			title,
			banner,
			mainProducts,
			status,
			optionalProducts
		}
		const mapperForm = suggestedOrdersAdminMapper.toPersistence(form)
		const { data } = await apiAdmin.post(`/product-recommendation`, {
			...mapperForm
		})
		return data
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SuggestedOrderAdminService()
