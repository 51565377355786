import React from 'react'
import * as S from './table-legend.styles'
import CircleIcon from '@mui/icons-material/Circle'

function TableLegend() {
	return (
		<S.WrapperTableLegend>
			<S.StyledTableLegend type="available">
				<CircleIcon />
				<p>Disponível</p>
			</S.StyledTableLegend>
			<S.StyledTableLegend type="unavailable">
				<CircleIcon />
				<p>Indisponível</p>
			</S.StyledTableLegend>
		</S.WrapperTableLegend>
	)
}

export default TableLegend
