import { Switch } from '@mui/material'
import styled from 'styled-components'

export const StyleToggle = styled(Switch)(() => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: '#CE332E'
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: '#B2231C'
	}
}))
