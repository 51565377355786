import React from 'react'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import Modal from '../../../../components/Modal'

import LightXsvg from '../../../../assets/images/light-x.svg'

import { IModalPresentationProps } from './types'
import * as S from './styles'

export function PresentationModal({
	active,
	onClose,
	onAdvance
}: IModalPresentationProps) {
	const { companyParams } = useTypedSelector(['companyParams'])

	const pointsInAdeptionParameter = companyParams.find(
		(param) => param.name === 'QUANTIDADE_PONTO_ADESAO'
	)
	const pointsInAdeptionParameterValue =
		pointsInAdeptionParameter?.value[0] || 0

	return (
		<Modal isActive={active} isFull>
			<S.Container>
				<S.CloseIcon onClick={onClose}>
					<img
						className="close-icon"
						src={LightXsvg}
						alt="Botão Fechar"
					/>
				</S.CloseIcon>

				<S.Content>
					<S.Title>
						<strong style={{ fontWeight: 'bold' }}>
							Programa de Pontos
						</strong>
						<br />
						Bom Parceiro GP
					</S.Title>

					<S.Paragraph>
						Participe e{' '}
						<S.WhiteColor>
							ganhe {pointsInAdeptionParameterValue} pontos!
						</S.WhiteColor>
						<br />
						<br />A cada <S.WhiteColor>1 real</S.WhiteColor> em
						compras você ganha <S.WhiteColor>pontos.</S.WhiteColor>
					</S.Paragraph>

					<S.Button onClick={() => onAdvance()}>CLIQUE AQUI</S.Button>
				</S.Content>
			</S.Container>
		</Modal>
	)
}
