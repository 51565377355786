import { UserTypeEnum } from '../../../shared/interfaces/customer'
import { IRouteDataV2 } from '../../Routes/types'

import { Location } from 'history';

export function isAdminPointsProgramRoutes(routeKey: string) {
	return routeKey.indexOf('points-program') > -1
}

export function accessTypesIncludesUserType(
	accessTypes: UserTypeEnum[],
	userType: UserTypeEnum
) {
	return accessTypes.includes(userType)
}

export function activeMenu(
    routes: IRouteDataV2[],
    location: Location<unknown>
  ): boolean {
    return routes.some((route) => location.pathname.includes(route.path))
  }