import React, { useState } from 'react'
import PointsProgramModalAdoption from '../../shared/components/PointsProgramModalAdoption'

import { Container } from './styles'

interface IProps {
	visible: boolean
	totalPoints: number
}

function CardAlertBlockedPoints({ visible, totalPoints }: IProps) {
	const [show, setShow] = useState(false)
	return (
		<>
			{visible && (
				<Container>
					<p className="card-title">Pontos Bloqueados</p>

					<p className="card-text">
						Você está deixando de ganhar{' '}
						<span>{totalPoints} Pontos</span> por não ter aderido ao{' '}
						<span>Programa de Pontos.</span>
					</p>

					<button
						className="card-link-button"
						onClick={() => setShow(true)}>
						Clique aqui e saiba mais
					</button>
				</Container>
			)}
			<PointsProgramModalAdoption
				show={show}
				onClose={() => setShow(false)}
			/>
		</>
	)
}

export default CardAlertBlockedPoints
