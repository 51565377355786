import cogoToast from 'cogo-toast'
import handleError from '../../modules/Error/handleError'
import cogoDefaultOptions from './toaster'

export interface ICognitoError {
    code: string
    message: string
}

export const COMMON_ERRORS: { [key: string]: { message: string } } = {
    CodeMismatchException: {
        message: 'Código de verificação inválido. Tente novamente.',
    },
    ExpiredCodeException: {
        message: 'Código de verificação expirado. Tente novamente.',
    },
    UserNotConfirmedException: {
        message: 'Usuário não confirmado.',
    },
    InvalidPasswordException: {
        message: 'Sua senha deve conter no mínimo 8 caracteres e ao menos um caracter minúsculo, maiúsculo e numérico.',
    },
    LimitExceededException: {
        message: 'Limite de tentativas excedido, tente mais tarde.',
    },
    NotAuthorizedException: {
        message: 'Login ou senha incorretos.'
    }
}

export function handleCognitoError(error: any): {
    hasError: ICognitoError
} | undefined {
    const commonError = COMMON_ERRORS[error.code]

    if (!commonError) {
        handleError(error)
        return
    }

    cogoToast.error(commonError.message, cogoDefaultOptions)

    return {
        hasError: error
    }
}