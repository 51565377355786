import { Option } from 'react-dual-listbox'
import {
	IFilterSalesHierarchyDirector,
	ChallengeParamsNameEnum
} from '../../shared/interfaces/pointsProgramChallenge'
import { IProduct } from '../RegisterProduct/types'
import { IFilterOptions, IOption } from './types'

export function convertValueToSelectOption(value: string | number) {
	return {
		value: value?.toString(),
		label: value?.toString()
	}
}

export const options = (options: IOption[]) => [
	{
		label: 'Todos',
		options: options
	}
]

export function separateProductsByCategory(
	products: IProduct[]
): Option<string>[] {
	const productsGroupArray = products.reduce(
		(productsGroup: { [key: string]: IProduct[] }, product) => {
			const productCategory = product.Category
			if (productsGroup[productCategory]) {
				productsGroup[productCategory].push(product)
			} else {
				productsGroup[productCategory] = [product]
			}
			return productsGroup
		},
		{}
	)

	const productsGroup: Option<string>[] = Object.keys(productsGroupArray).map(
		(category) => {
			const categoryProducts = productsGroupArray[category]
			return {
				label: category,
				options: categoryProducts.map((product) => ({
					value: product.MaterialID,
					label: product.SiteDescription
				}))
			}
		}
	)

	return productsGroup
}

export function getAllDirectorOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		acc.push(convertValueToSelectOption(director.director))
		return acc
	}, [])
}

export function getAllGovOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			acc.push(convertValueToSelectOption(gov.gov))
		}
		return acc
	}, [])
}

export function getAllResaleOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			for (const resale of gov.resales) {
				acc.push(convertValueToSelectOption(resale.resale))
			}
		}
		return acc
	}, [])
}

export function getResaleOptionsBySelectedGovs(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedGovOptions: IOption[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			if (selectedGovOptions.find((option) => option.value === gov.gov)) {
				for (const { resale } of gov.resales) {
					acc.push(convertValueToSelectOption(resale))
				}
			}
		}
		return acc
	}, [])
}

export function getSalesHierarchyOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): Pick<
	IFilterOptions,
	| ChallengeParamsNameEnum.DIRECTOR
	| ChallengeParamsNameEnum.GOV
	| ChallengeParamsNameEnum.RESALE
> {
	const directorOptions = getAllDirectorOptions(salesHierarchy)
	const govOptions = getAllGovOptions(salesHierarchy)
	const resaleOptions = getAllResaleOptions(salesHierarchy)

	return {
		DIRECTOR: directorOptions,
		GOV: govOptions,
		RESALE: resaleOptions
	}
}

export function filterSelectedDirectors(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedOptions: IOption[]
) {
	return salesHierarchy.filter((director) => {
		return !!selectedOptions.find(
			(option) => option.value === director.director
		)
	})
}

export function filterSelectedGovs(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedOptions: IOption[]
) {
	return salesHierarchy.filter((director) => {
		return director.govs.some((gov) => {
			return !!selectedOptions.find((option) => option.value === gov.gov)
		})
	})
}
