import cogoToast from 'cogo-toast'
import { getEnumMessage } from '../../modules/Error/handleError'
import { IOption } from '../../modules/PointProgramChallengeDetailsV2/types'
import {
	IAcceptedTerm,
	IChallenge,
	IPointProgramChallengeFilters
} from '../interfaces/pointsProgramChallenge'
import cogoDefaultOptions from '../utils/toaster'
import axiosInstance, { axiosGPInstance } from './axiosInstace'

export async function getChallengeFilterParameters(): Promise<IPointProgramChallengeFilters> {
	try {
		const { data: salesHierarchyData } = await axiosGPInstance.get(
			`/filters/sales-hierarchy`
		)
		const { data: channelsData } = await axiosGPInstance.get(
			`/filters/channels`
		)
		const { data: ufCitiesData } = await axiosGPInstance.get(
			`/filters/uf-cities`
		)

		return {
			salesHierarchy: salesHierarchyData,
			channels: channelsData,
			ufs: ufCitiesData.ufs,
			cities: ufCitiesData.cities
		}
	} catch (err) {
		return {
			salesHierarchy: [],
			channels: [],
			ufs: [],
			cities: []
		}
	}
}

export async function getChannelParameters(): Promise<IOption[]> {
	try {
		const { data: channelsData } = await axiosGPInstance.get(
			`/filters/channels`
		)

		return channelsData
	} catch (err) {
		return []
	}
}

export async function createChallenge(
	challenge: Partial<IChallenge>
): Promise<{ challengeId: string }> {
	const response = await axiosGPInstance.post('/program-point-challenge', {
		...challenge
	})
	return response.data
}

export async function getChallengesFilter(challengeId: string) {
	const response = await axiosGPInstance.get(
		'/program-point-challenge/filters',
		{
			params: {
				challengeId
			}
		}
	)
	return response.data
}

export async function getCheckAllFilters(
	challengeId: string,
	insertedParams: number
) {
	let timeId: NodeJS.Timeout | undefined
	let intervalId: NodeJS.Timeout | undefined
	try {
		await new Promise((resolve, reject) => {
			timeId = setTimeout(() => {
				reject(
					'Tempo de espera excedido, falha ao inserir dados tente novamente'
				)
			}, 300000)
			intervalId = setInterval(async () => {
				const filtersCount = await getChallengesFilter(challengeId)
				if (filtersCount >= insertedParams) {
					resolve('sucesso')
				}
			}, 10000)
		})
	} finally {
		if (timeId !== undefined) {
			clearTimeout(timeId)
		}
		if (intervalId !== undefined) {
			clearInterval(intervalId)
		}
	}
}

export async function updateChallenge(
	challenge: Partial<IChallenge>
): Promise<{ challengeId: string }> {
	const response = await axiosGPInstance.patch('/program-point-challenge', {
		...challenge
	})
	return response.data
}

export async function deleteChallenge(challengeId: string) {
	const response = await axiosGPInstance.delete(
		`/program-point-challenge?challengeId=${challengeId}`
	)
	return response.data
}

export async function acceptTermOfTheChallenge(
	payload: IAcceptedTerm
): Promise<any> {
	try {
		const response = await axiosInstance.post(
			`/program-points-challenges/accept-challenge-term`,
			{ ...payload }
		)
		return response.data
	} catch (err) {
		const error = err as any
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}
