import React, { Fragment } from 'react'

import { IViewProps } from './types'
import CheckIcon from '../../assets/images/check-circle.svg'
import GPLogo from '../../assets/images/gp.png'

function AwaitingApproval(props: IViewProps): JSX.Element {
	const { handleBackToLogin } = props

	return (
		<Fragment>
			<section className="gradient-container" />

			<div className="awaiting-approval-page">
				<div className="page-wrapper">
					<img
						src={CheckIcon}
						className="check-icon"
						alt="Icone Check"
					/>

					<h1 className="title">Cadastro realizado!</h1>

					<p className="description">
						Você receberá um email com instruções para confirmação
						de sua conta, após isso poderá logar normalmente.
					</p>
					<span className="sub">
						O link para confirmação do cadastro irá expirar em 24
						horas.
					</span>

					<button onClick={handleBackToLogin} className="button">
						Voltar
					</button>
				</div>

				<img src={GPLogo} className="gp-logo" alt="Grupo Petrópolis" />
			</div>
		</Fragment>
	)
}

export default AwaitingApproval
