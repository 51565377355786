import { ISelectOption } from '../../shared/utils/SelectOptions'

import { IFilterOptions as FilterHierarcyFilterOptions } from '../../components/filters-by-hieararchy/types'

export interface IViewProps {
	isLoading: boolean
	handleSendNotification(
		title: string,
		body: string,
		actionType?: string,
		triggerDate?: string,
		productToOpen?: string,
		categoryToOpen?: string,
		imageURL?: string
	): void
	setPushSelectedPDVs: React.Dispatch<React.SetStateAction<string[]>>
	setCanSendPdv: React.Dispatch<React.SetStateAction<boolean>>
	handleGetFilterValues: (filters: FilterHierarcyFilterOptions) => void
	setImpactedPdvs: React.Dispatch<React.SetStateAction<number>>
	impactedPdvs: number
	pushSelectedPDVs: string[]
}

export interface ISelectedOptionsFilter {
	ufs: string[]
	cities: string[]
	channels: string[]
	codePdv: string[]
	codeResales: string[]
	diretor: string[]
	GRC: string[]
}

export interface IFilterOptions {
	ufs: string[]
	cities: string[]
	channels: string[]
	codeResales: string[]
	codePdv: string
}

export enum FiltersValuesEnum {
	UF = 'UF',
	CITY = 'CITY',
	CHANNEL = 'CHANNEL',
	RESALE_CODE = 'RESALE_CODE',
	PDV_CODE = 'PDV_CODE',
	DIRETORES = 'DIRETORES',
	GRC = 'GRC',
	CUSTOMERS = 'CUSTOMERS'
}

export interface IRequestProps {
	cities: ISelectOption[]
	ufs: ISelectOption[]
	channels: ISelectOption[]
	codResales: ISelectOption[]
}

export interface INotificationProps {
	title: string
	body: string
}

export interface IDataRequestResponse {
	city: string
	companyFiscalID: string
	customerGroup: string
	customerID: string
	customerSegment: string
	descCustomerGroup: string
	descCustomerSegment: string
	deviceID: string
	district: string
	name: string
	personalFiscalID: string
	region: string
	salesOrganizationID: string
}

export interface ISelectOptionArray {
	value: string[]
	label: string
}
