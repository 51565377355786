export interface LoginProps {
	unformatedTextDoc: string
	textDoc: string
	setTextDoc: (value: string) => void
	validDocument: string
	isValidDoc: boolean
	handleChangeTypeSidebar: () => void
	currentStep?: string,
	handleCloseSidebar: () => void
}

export enum StepLoginEnum {
	DOCUMENT = 'document',
	PASSWORD = 'password',
	FORGOT_PASSWORD = 'forgotPassword',
	CHOOSE_VALIDATION = 'chooseValidation',
}
