import React, { useEffect, useRef, useState } from 'react'
import Slider, { Settings } from 'react-slick'

import ChevronLeftSVG from '../../../../assets/images/chevron-slider-left.svg'
import ChevronRightSVG from '../../../../assets/images/chevron-slider-right.svg'

import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'
import {
	CategoriesBannerEnum,
	ICarouselBanner
} from '../../../../shared/interfaces/banner'
import {
	PagesBannersEnum,
	fetchGetBanners
} from '../../../../shared/services/banner.service'
import Loading from '../../../../components/Loading'

import * as S from './styles'
import * as SMorePoints from '../../MorePointsPageHeader/styles'
import { IProps } from './types'
import { sortBannersByFinaDate } from './util'

import ImageVideoComponent from '../../../../components/ImageVideoComponent/image-video-component/image-video-component'
import { DevicesEnum } from '../../../../views/banners-management/components/midia-container/midia-container.types'

interface IChevronProps {
	direction: 'left' | 'right'
	sliderRef: React.RefObject<Slider>
}

function Arrow({ direction, sliderRef }: IChevronProps) {
	const handleClick = () => {
		if (!sliderRef.current) return
		if (direction === 'left') sliderRef.current.slickPrev()
		if (direction === 'right') sliderRef.current.slickNext()
	}
	return (
		<S.ChevronContainer
			className="chevron"
			direction={direction}
			onClick={handleClick}>
			{direction === 'left' ? (
				<img src={ChevronLeftSVG} alt="Seta para a esquerda" />
			) : (
				<img src={ChevronRightSVG} alt="Seta para a Direita" />
			)}
		</S.ChevronContainer>
	)
}

function MorePointsBanner({ onPressItem, isFullBannerMorePoints }: IProps) {
	const morePointsBanner: ICarouselBanner = {
		Title: '',
		finalDate: '',
		initialDate: '',
		Category: CategoriesBannerEnum.MORE_POINTS
	}

	return isFullBannerMorePoints ? (
		<SMorePoints.Banner onClick={() => onPressItem(morePointsBanner)}>
			<span className="banner-title">Mais Pontos</span>
			<span className="banner-subtitle">
				Uma lista repleta de produtos que te dão mais pontos!
			</span>
		</SMorePoints.Banner>
	) : (
		<S.MorePointsBanner
			className="banner"
			key="MORE-POINTS-BANNER-ID"
			onClick={() => onPressItem(morePointsBanner)}>
			<div className="more-points-banner-image">
				<span className="banner-title">Mais Pontos</span>
				<span className="banner-subtitle">
					Uma lista repleta de produtos
					<br />
					que te dão mais pontos!
				</span>
			</div>
		</S.MorePointsBanner>
	)
}

export default function BannersCarousel({
	onPressItem,
	challenges = [],
	isFetchingMaterials = false
}: IProps) {
	const { pointsProgramStatus } = useTypedSelector(['pointsProgramStatus'])
	const [isFetchingBanners, setIsFetchingBanners] = useState(false)
	const [banners, setBanners] = useState<ICarouselBanner[]>([])
	const [carouselBanners, setCarouselBanners] = useState<ICarouselBanner[]>(
		[]
	)
	const { customer } = useTypedSelector(['customer'])

	function fetchBanners() {
		;(async () => {
			try {
				setIsFetchingBanners(true)
				const bannersResponse = await fetchGetBanners({
					customer: customer,
					targetPage: PagesBannersEnum.HOME,
					platform: DevicesEnum.WEB
				})
				setBanners(bannersResponse)
			} finally {
				setIsFetchingBanners(false)
			}
		})()
	}

	function reorderBanners() {
		const sortedBanners = [...banners, ...challenges]
		setCarouselBanners(sortedBanners)
	}

	useEffect(fetchBanners, [customer.CustomerID])
	useEffect(reorderBanners, [banners, challenges])

	const SliderRef = useRef<Slider>(null)

	const numberOfItemsToBeShownOnTheSlide =
		carouselBanners.length >= 3
			? 3
			: pointsProgramStatus.salesOrgHasProgramPoints
			? carouselBanners.length + 1
			: carouselBanners.length

	const settings: Settings = {
		infinite: true,
		slidesToShow: numberOfItemsToBeShownOnTheSlide,
		prevArrow: <Arrow direction="left" sliderRef={SliderRef} />,
		nextArrow: <Arrow direction="right" sliderRef={SliderRef} />,
		draggable: false
	}

	const mapBannersWithChallenge = carouselBanners.map((carouselBanner) => {
		if (carouselBanner.challenge) {
			return {
				ID: carouselBanner.challenge.ID,
				createdAt: carouselBanner.challenge.createdAt,
				createdBy: carouselBanner.challenge.createdBy,
				modifiedAt: carouselBanner.challenge.modifiedAt,
				modifiedBy: carouselBanner.challenge.modifiedBy,
				challengeId: carouselBanner.challenge.autoIncChallengeId,
				imageURL: carouselBanner.imageURL,
				platform: 'WEB',
				title: carouselBanner.challenge.title,
				initialDate: carouselBanner.challenge.startDate,
				finalDate: carouselBanner.challenge.endDate,
				filters: carouselBanner.challenge.filtersChallenge,
				amountOfCustomers: '0',
				videoURL: '',
				targetPage: 'HOME',
				targetOrder: 1,
				selectedProduct: '',
				selectedCategory: '',
				actionType: 'OPEN_CHALLENGES',
				justification: carouselBanner.challenge.observation,
				status: carouselBanner.challenge.status
			}
		} else {
			return carouselBanner
		}
	})

	return (
		<S.Container className="carousel-container">
			{isFetchingBanners || isFetchingMaterials ? (
				<div className="loading-wrapper">
					<Loading />
				</div>
			) : carouselBanners.length ? (
				<Slider className="slider" ref={SliderRef} {...settings}>
					{pointsProgramStatus.salesOrgHasProgramPoints ? (
						<MorePointsBanner
							onPressItem={onPressItem}
							isFullBannerMorePoints={false}
						/>
					) : null}
					{mapBannersWithChallenge.map((carouselBanner) => (
						<div
							key={carouselBanner.ID}
							data-bannerID={carouselBanner.bannerID}
							data-bannerTitle={carouselBanner.title}>
							<ImageVideoComponent
								banner={carouselBanner}
								imageUrl={carouselBanner.imageURL}
								videoUrl={carouselBanner.videoURL || ''}
							/>
						</div>
					))}
				</Slider>
			) : (
				<>
					{pointsProgramStatus.salesOrgHasProgramPoints ? (
						<MorePointsBanner
							onPressItem={onPressItem}
							isFullBannerMorePoints={true}
						/>
					) : null}
				</>
			)}
		</S.Container>
	)
}
