import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const CancelBannerModalStyles = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 16px;
	width: 100%;

	padding: 40px 0px;

	.sub-title-wrapper {
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 10px;

		h2 {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;

			color: ${(props) => props.theme.palette.black.main};
		}
	}

	.text-label {
		color: ${colors.secondGrey};
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.25rem;
		letter-spacing: -0.0125rem;
	}

	.info-modal {
		display: flex;
		align-items: center;
		background-color: ${colors.fifthGray};
		padding: 16px;
		gap: 20px;
		width: 100%;

		svg {
			color: ${(props) => props.theme.palette.gray.main};
		}
		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.primaryWhite.contrastText};
		}
	}
`
