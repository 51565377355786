import { useCallback, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'

import { IResaleItem } from '../../../modules/ResaleList/types'

import { deepCopy } from '../../../shared/utils'
import {
	getAllGovOptions,
	filterSelectedDirectors,
	filterSelectedGovs,
	getResaleOptionsBySelectedGovs,
	getAllResaleOptions,
	getSalesHierarchyOptions,
	options,
	convertValueToSelectOption,
	filterSelectedResales,
	filterCitiesSelected,
	filterGrc,
	filterCdsBySelectedGovs
} from '../util'

import { handleFilterCustomersListPdvs } from '../services/index.service'
import { formatedFilters } from '../utils/formatedFilters'
import { ChallengeParamsNameEnum } from '../../../shared/interfaces/pointsProgramChallenge'
import { blockFields } from '../utils/block-fields'
import { removeDuplicates } from '../../../utils/remove-duplicates'
import { fetchCompanies } from '../../../modules/ResaleList/service'
import { createCityStateMap } from '../utils/createCityStateMap'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import { createCdGrcMap } from '../utils/createCdGrcMap'
import { createGrcDirectorMap } from '../utils/createGrcDirectorMap'
import {
	FilterNameEnum,
	IFilterOptions,
	IFiltersHierarchy,
	IOption,
	IValuesByProps,
	OtherFilters,
	useFilterHierarchyProps
} from '../typings'

export function useFilterHierarchy({
	onLoad,
	onLoadEnd,
	onFilterChange,
	getFilterParameters,
	handleGetFilterValues,
	initialFilters,
	setCanFilter,
	onPushFilterChange,
	setCanSendPdv,
	setPushSelectedPDVs,
	shouldListPdv,
	fetchListPDVsPush,
	setListImpactedPdvs,
	listImpactedPdvs
}: useFilterHierarchyProps) {
	const [isLoading, setIsLoading] = useState(false)

	const [selectedFilterOptions, setSelectedFilterOptions] =
		useState<IFilterOptions>({
			DIRECTOR: [],
			GOV: [],
			RESALE: [],
			CHANNEL: [],
			STATE: [],
			CITY: []
		})

	const [selectedPDVs, setSelectedPDVs] = useState<IOption[]>([])

	const [groupFilters, setGroupFilters] = useState<string[]>()

	const [codPdv, setCodPdv] = useState<IOption[]>([])

	const [filtersHierarchy, setFiltersHierarchy] = useState<IFiltersHierarchy>(
		{
			salesHierarchy: [],
			channels: [],
			ufs: [],
			cities: []
		}
	)

	const initialFilterOptions = {
		DIRECTOR: [],
		GOV: [],
		RESALE: [],
		CHANNEL: [],
		STATE: [],
		CITY: []
	}
	const [filterOptions, setFilterOptions] =
		useState<IFilterOptions>(initialFilterOptions)

	const [cachedFilterOptions, setCachedFilterOptions] =
		useState<IFilterOptions>(initialFilterOptions)

	const [cityStateMap, setCityStateMap] = useState<Record<string, string>>({})
	const [cdGrcMap, setCdGrcMap] = useState<Record<string, string>>({})
	const [grcDirectorMap, setGrcDirectorMap] = useState<
		Record<string, string>
	>({})

	const [isFilter, setIsFilter] = useState<boolean>(false)

	const [allCompanies, setAllCompanies] = useState<IResaleItem[]>([])
	//Chama a api que retorna a lista (strings) de pdvs impactados
	async function handleClickFilterListPdvs() {
		try {
			startLoad()
			//Essa função formata os filtros, codePdv e customers ainda precisam ser adaptados
			const formatedResult = formatedFilters(
				selectedFilterOptions,
				shouldListPdv
			)

			//Chamando o service
			const responseFilter = await handleFilterCustomersListPdvs({
				filterOptions: formatedResult,
				fetchListPDVsPush
			})

			const dataFilter: IOption[] =
				'total' in responseFilter
					? []
					: responseFilter?.map(
							(customer: string) =>
								({
									value: customer,
									label: customer
								} as IOption)
					  )

			onPushFilterChange?.(responseFilter.total as number)

			handleGetFilterValues?.(selectedFilterOptions)

			setSelectedPDVs(
				removeDuplicates(
					dataFilter.concat(codPdv),
					'label'
				) as IOption[]
			)
		} catch (error) {
			console.error({ error })

			cogoToast.error(
				'Ocorreu um erro na filtragem de PDVs!',
				cogoDefaultOptions
			)
		} finally {
			endLoad()
		}
	}

	useEffect(() => {
		async function execute() {
			const payload = await fetchCompanies()
			setAllCompanies(payload)
		}
		execute()
	}, [])

	const changeFilterOptionsAndSelectedOptions = useCallback(
		(
			selectedOptions: IOption[],
			filter:
				| FilterNameEnum.DIRECTOR
				| FilterNameEnum.GOV
				| FilterNameEnum.STATE
				| FilterNameEnum.CITY
				| FilterNameEnum.RESALE,
			config?: IValuesByProps
		) => {
			const {
				filterSelectOptions = filterOptions,
				salesHierarchy = filtersHierarchy.salesHierarchy,
				selectedFilterSelectOptions = selectedFilterOptions
			} = config || {}

			const currentSelectedFilterOptions: IFilterOptions = deepCopy(
				selectedFilterSelectOptions
			)
			currentSelectedFilterOptions[filter] = selectedOptions

			const currentFilterOptions: IFilterOptions =
				deepCopy(filterSelectOptions)
			let selectedDirectors = salesHierarchy
			if (filter === FilterNameEnum.DIRECTOR) {
				if (!!selectedOptions?.length) {
					selectedDirectors = filterSelectedDirectors(
						salesHierarchy,
						selectedOptions
					)
				}
				currentFilterOptions.GOV = getAllGovOptions(selectedDirectors)

				currentSelectedFilterOptions.GOV = deepCopy(
					selectedFilterSelectOptions.GOV || []
				).filter((selectedGovOption) => {
					return currentFilterOptions.GOV.some(
						(govOption) =>
							govOption.value === selectedGovOption.value
					)
				})
			}
			if (
				filter === FilterNameEnum.DIRECTOR ||
				filter === FilterNameEnum.GOV
			) {
				const currentSelectedGovFilterOptions =
					filter === FilterNameEnum.DIRECTOR
						? currentSelectedFilterOptions.GOV
						: selectedOptions

				if (!!currentSelectedGovFilterOptions?.length) {
					selectedDirectors = filterSelectedGovs(
						salesHierarchy,
						currentSelectedGovFilterOptions
					)
				}

				currentFilterOptions.RESALE =
					!!currentSelectedGovFilterOptions?.length
						? getResaleOptionsBySelectedGovs(
								selectedDirectors,
								currentSelectedGovFilterOptions
						  )
						: getAllResaleOptions(selectedDirectors)

				currentSelectedFilterOptions.RESALE = deepCopy(
					selectedFilterSelectOptions.RESALE || []
				).filter((selectedResaleOption) => {
					return currentFilterOptions.RESALE.some(
						(resaleOption) =>
							resaleOption.value === selectedResaleOption.value
					)
				})
			}

			return { currentFilterOptions, currentSelectedFilterOptions }
		},
		[filterOptions, filtersHierarchy.salesHierarchy, selectedFilterOptions]
	)
	function startLoad() {
		setIsLoading(true)
		onLoad()
	}

	function endLoad() {
		setIsLoading(false)
		onLoadEnd()
	}

	function fetchFilterOptions() {
		;(async () => {
			try {
				startLoad()
				const filterParameters = await getFilterParameters()
				setFiltersHierarchy(filterParameters)

				const resCityStateMap = createCityStateMap(filterParameters)

				setCityStateMap(resCityStateMap)

				const resCdGrcMap = createCdGrcMap(filterParameters)

				setCdGrcMap(resCdGrcMap)

				const resGrcDirectorMap = createGrcDirectorMap(filterParameters)

				setGrcDirectorMap(resGrcDirectorMap)

				const {
					DIRECTOR: directorOptions,
					GOV: govOptions,
					RESALE: resaleOptions,
					STATE: statesOptions,
					CITY: citiesOptions
				} = getSalesHierarchyOptions(filterParameters.salesHierarchy)
				const { channels } = filterParameters

				const filters = {
					DIRECTOR: directorOptions,
					GOV: govOptions,
					RESALE: resaleOptions,
					CHANNEL: channels,
					STATE: statesOptions,
					CITY: citiesOptions
				}

				setFilterOptions(filters)
				setCachedFilterOptions(filters)

				populateSelectedFilterOptions({
					filterSelectOptions: filters,
					salesHierarchy: filterParameters.salesHierarchy,
					channels: filterParameters.channels
				})
			} finally {
				endLoad()
			}
		})()
	}

	function populateSelectedFilterOptions(config: IValuesByProps) {
		if (!initialFilters) return

		const groupedFiltersByParamName = initialFilters.reduce(
			(acc: any, filter) => {
				if (filter.paramName !== FilterNameEnum.PDV) {
					const selectedFilterOptions =
						config.filterSelectOptions[filter.paramName] || []
					const findItemInTheOptions = selectedFilterOptions.find(
						(option) => option.value === filter.paramValue
					)
					if (!findItemInTheOptions) return acc
				}

				const groupValue = acc[filter.paramName] || []
				if (filter.paramName === FilterNameEnum.CHANNEL) {
					const findChannelOption = config.channels.find(
						(channel) => channel.value === filter.paramValue
					)
					!!findChannelOption && groupValue.push(findChannelOption)
				} else {
					groupValue.push(
						convertValueToSelectOption(filter.paramValue)
					)
				}
				acc[filter.paramName] = groupValue
				return acc
			},
			{}
		)

		if (groupedFiltersByParamName) {
			setGroupFilters(Object.keys(groupedFiltersByParamName))
		}

		const configProps: IValuesByProps = {
			...config,
			selectedFilterSelectOptions: groupedFiltersByParamName
		}

		if (!!groupedFiltersByParamName.DIRECTOR) {
			const resDirector = changeFilterOptionsAndSelectedOptions(
				groupedFiltersByParamName.DIRECTOR,
				FilterNameEnum.DIRECTOR,
				configProps
			)
			setFilterOptions(resDirector.currentFilterOptions)
			setSelectedFilterOptions(resDirector.currentSelectedFilterOptions)
		} else if (!!groupedFiltersByParamName.GOV) {
			const resGov = changeFilterOptionsAndSelectedOptions(
				groupedFiltersByParamName.GOV,
				FilterNameEnum.GOV,
				configProps
			)
			setFilterOptions(resGov.currentFilterOptions)
			setSelectedFilterOptions(resGov.currentSelectedFilterOptions)
		} else if (!!groupedFiltersByParamName.STATE) {
			const resState = changeFilterOptionsAndSelectedOptions(
				groupedFiltersByParamName.STATE,
				FilterNameEnum.STATE,
				configProps
			)
			setFilterOptions(resState.currentFilterOptions)
			setSelectedFilterOptions(resState.currentSelectedFilterOptions)
		} else if (!!groupedFiltersByParamName.CITY) {
			const resCity = changeFilterOptionsAndSelectedOptions(
				groupedFiltersByParamName.CITY,
				FilterNameEnum.STATE,
				configProps
			)
			setFilterOptions(resCity.currentFilterOptions)
			setSelectedFilterOptions(resCity.currentSelectedFilterOptions)
		} else {
			setFilterOptions(configProps.filterSelectOptions)
			setSelectedFilterOptions(groupedFiltersByParamName)
		}
		setSelectedPDVs(
			removeDuplicates(
				groupedFiltersByParamName[FilterNameEnum.PDV] || [],
				'label'
			)
		)
	}

	const handleAddUniquePDV = useCallback((value, prevPDVS) => {
		return removeDuplicates([...prevPDVS, { value, label: value }], 'value')
	}, [])

	const createPdvOption = useCallback(
		(value) => {
			const foundSelectedPDV = selectedPDVs.find(
				(pdvOption) => pdvOption.value === value
			)

			const foundCodPDV = codPdv.find(
				(pdvOption) => pdvOption.value === value
			)

			const isFound =
				foundSelectedPDV !== undefined || foundCodPDV !== undefined

			if (isFound) {
				cogoToast.warn(
					'PDV já existe na seleção atual.',
					cogoDefaultOptions
				)
				return
			}

			setSelectedPDVs((prev) => handleAddUniquePDV(value, prev))
			setCodPdv((prev) => handleAddUniquePDV(value, prev))
			setPushSelectedPDVs?.((prev) => [...prev, value])
			setListImpactedPdvs?.([...(listImpactedPdvs ?? []), value])
			setCanSendPdv?.(true)
		},
		[
			selectedPDVs,
			codPdv,
			setPushSelectedPDVs,
			setListImpactedPdvs,
			listImpactedPdvs,
			setCanSendPdv,
			handleAddUniquePDV
		]
	)

	const populateDirector = (grcs: IOption[]) => {
		const arrDirector: IOption[] = []
		const directorHash: Record<string, boolean> = {}

		for (const grc of grcs) {
			const director = grcDirectorMap[grc.value]
			directorHash[director] = true
		}

		for (const filterDirector of filterOptions?.DIRECTOR) {
			if (filterDirector.value in directorHash) {
				arrDirector.push(filterDirector)
			}
		}

		setSelectedFilterOptions((prev) => ({
			...prev,
			DIRECTOR: arrDirector
		}))

		return arrDirector
	}

	const populateGov = (resales: IOption[]) => {
		const arrGrc: IOption[] = []
		const grcHash: Record<string, boolean> = {}

		for (const resale of resales) {
			const grc = cdGrcMap[resale.value]
			grcHash[grc] = true
		}

		for (const filterGrc of filterOptions?.GOV) {
			if (filterGrc.value in grcHash) {
				arrGrc.push(filterGrc)
			}
		}

		setSelectedFilterOptions((prev) => ({
			...prev,
			GOV: arrGrc
		}))

		return arrGrc
	}

	const populateState = (cities: IOption[]) => {
		const arrStates: IOption[] = []
		const stateHash: Record<string, boolean> = {}

		for (const city of cities) {
			const state = cityStateMap[city.value]
			stateHash[state] = true
		}

		for (const filterState of filterOptions?.STATE) {
			if (stateHash[filterState.value]) arrStates.push(filterState)
		}

		return setSelectedFilterOptions((prev) => ({
			...prev,
			STATE: arrStates
		}))
	}

	const filterCds = (govs: IOption[]) => {
		let cds: IOption[] = []

		cds = filterCdsBySelectedGovs(filtersHierarchy.salesHierarchy, govs)

		setFilterOptions((prev) => ({
			...prev,
			RESALE: cds
		}))

		return cds
	}

	const filterStates = (resales: IOption[]) => {
		let state: IOption[] = []

		state = filterSelectedResales(filtersHierarchy.salesHierarchy, resales)

		setFilterOptions((prev) => ({
			...prev,
			STATE: state
		}))

		return state
	}

	const filterCities = (states: IOption[], resales?: IOption[]) => {
		let city: IOption[] = []

		city = filterCitiesSelected(
			filtersHierarchy.salesHierarchy,
			states,
			resales
		)

		setFilterOptions((prev) => ({
			...prev,
			CITY: city
		}))
	}

	const handleClearFields = useCallback(() => {
		handleGetFilterValues?.({
			DIRECTOR: [],
			GOV: [],
			RESALE: [],
			CHANNEL: [],
			STATE: [],
			CITY: []
		})
		setSelectedFilterOptions({
			DIRECTOR: [],
			GOV: [],
			RESALE: [],
			CHANNEL: [],
			STATE: [],
			CITY: []
		})
		setIsFilter(false)
		setSelectedPDVs([])
		setCodPdv([])
		setPushSelectedPDVs?.([])
		setListImpactedPdvs?.([])
		setCanSendPdv?.(false)
		onPushFilterChange?.(0)
		setFilterOptions(cachedFilterOptions)
	}, [
		cachedFilterOptions,
		handleGetFilterValues,
		onPushFilterChange,
		setCanSendPdv,
		setListImpactedPdvs,
		setPushSelectedPDVs
	])

	const matchResaleOptions = () => {
		return allCompanies
			.filter((data) =>
				filterOptions.RESALE.some(
					(relase) => data.SalesOrganizationID === relase.value
				)
			)
			.map((option) => {
				const formattedName = option.Name.split(' - ')[0]
				return {
					label: `${option.SalesOrganizationID} - ${formattedName}`,
					value: option.SalesOrganizationID
				} as IOption
			})
	}

	const salesHierarchyFiltersProps = [
		{
			key: FilterNameEnum.DIRECTOR,
			options: options(filterOptions.DIRECTOR),
			onChange: (director: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					DIRECTOR: director
				}))

				const selectedDirectors =
					director?.length > 0
						? director
						: cachedFilterOptions.DIRECTOR

				const grc = filterGrc(
					filtersHierarchy.salesHierarchy,
					selectedDirectors
				)

				setFilterOptions((prev) => ({
					...prev,
					GOV: grc
				}))
			},
			value: selectedFilterOptions.DIRECTOR,
			placeholderButtonLabel: 'Diretor',
			placeholder: 'Buscar Diretor',
			isDisabled:
				blockFields({
					currentField: selectedFilterOptions.DIRECTOR?.length,
					resaleLength: selectedFilterOptions.RESALE?.length,
					stateLength: selectedFilterOptions.STATE?.length,
					cityLength: selectedFilterOptions.CITY?.length,
					channelLength: selectedFilterOptions.CHANNEL?.length,
					pdvsLength: selectedPDVs?.length,
					isCd: false
				}) || selectedFilterOptions.GOV?.length > 0
		},
		{
			key: FilterNameEnum.GOV,
			options: options(filterOptions.GOV),
			onChange: (govs: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					GOV: govs
				}))

				const selectedGovs =
					govs?.length > 0 ? govs : cachedFilterOptions.GOV

				filterCds(selectedGovs)
				populateDirector(govs)
			},
			value: selectedFilterOptions.GOV,
			placeholderButtonLabel: 'GRC',
			placeholder: 'Buscar GRC',
			isDisabled:
				blockFields({
					currentField: selectedFilterOptions.GOV?.length,
					resaleLength: selectedFilterOptions.RESALE?.length,
					stateLength: selectedFilterOptions.STATE?.length,
					cityLength: selectedFilterOptions.CITY?.length,
					channelLength: selectedFilterOptions.CHANNEL?.length,
					pdvsLength: selectedPDVs?.length,
					isCd: false
				}) || selectedFilterOptions.STATE?.length > 0
		},
		{
			key: FilterNameEnum.RESALE,
			options: options(matchResaleOptions()),
			onChange: (resale: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					RESALE: resale
				}))

				const selectedResales =
					resale?.length > 0 ? resale : cachedFilterOptions.RESALE

				const states = filterStates(selectedResales)

				const grc = populateGov(resale)
				populateDirector(grc)

				filterCities(states, resale)
			},
			value: selectedFilterOptions.RESALE,
			placeholderButtonLabel: 'CD',
			placeholder: 'Buscar CD',
			isDisabled:
				blockFields({
					currentField: selectedFilterOptions.RESALE?.length,
					resaleLength: selectedFilterOptions.RESALE?.length,
					stateLength: selectedFilterOptions.STATE?.length,
					cityLength: selectedFilterOptions.CITY?.length,
					channelLength: selectedFilterOptions.CHANNEL?.length,
					pdvsLength: selectedPDVs?.length,
					isCd: true,
					isFilter: isFilter
				}) ||
				selectedFilterOptions.CITY?.length > 0 ||
				selectedFilterOptions.STATE?.length > 0
		}
	]

	const otherFiltersProps: OtherFilters[] = [
		{
			key: FilterNameEnum.STATE,
			options: options(filterOptions.STATE),
			onChange: (states: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					STATE: states
				}))
				const selectedStates =
					states?.length > 0 ? states : cachedFilterOptions.STATE

				const selectedResales =
					selectedFilterOptions.RESALE?.length > 0
						? selectedFilterOptions.RESALE
						: cachedFilterOptions.RESALE

				filterCities(selectedStates, selectedResales)
			},

			value: selectedFilterOptions.STATE,
			placeholderButtonLabel: 'UF',
			placeholder: 'Buscar UF',
			isDisabled:
				selectedFilterOptions.CITY?.length > 0 ||
				selectedPDVs.length > 0
		},
		{
			key: FilterNameEnum.CITY,
			options: options(filterOptions.CITY),
			onChange: (value: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					CITY: value
				}))

				populateState(value)
			},
			value: selectedFilterOptions.CITY,
			placeholderButtonLabel: 'Cidade',
			placeholder: 'Buscar Cidade',
			isDisabled: selectedPDVs.length > 0
		},
		{
			key: FilterNameEnum.CHANNEL,
			options: options(filterOptions.CHANNEL),
			onChange: (channels: IOption[]) => {
				setSelectedFilterOptions((prev) => ({
					...prev,
					CHANNEL: channels
				}))
			},
			value: selectedFilterOptions.CHANNEL,
			placeholderButtonLabel: 'Canal',
			placeholder: 'Buscar Canal',
			isDisabled: selectedPDVs.length > 0
		}
	]

	useEffect(() => {
		onFilterChange(selectedPDVs.map((pdv) => pdv.value))
	}, [onFilterChange, selectedPDVs])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchFilterOptions, [])

	useEffect(() => {
		const selectedFilterOptionKeys = Object.keys(
			selectedFilterOptions
		) as ChallengeParamsNameEnum[]

		const someFilterWasSelected = selectedFilterOptionKeys.some((key) => {
			return key === ChallengeParamsNameEnum.PDV
				? !!selectedPDVs?.length
				: selectedFilterOptions[key]?.length
		})

		setCanFilter(someFilterWasSelected)

		onPushFilterChange?.(selectedPDVs.length)
	}, [
		onPushFilterChange,
		selectedFilterOptions,
		selectedPDVs.length,
		setCanFilter
	])

	return {
		isLoading,
		handleClickFilterListPdvs,
		otherFiltersProps,
		salesHierarchyFiltersProps,
		handleClearFields,
		createPdvOption,
		selectedPDVs,
		setSelectedPDVs,
		setIsFilter,
		groupFilters
	}
}
