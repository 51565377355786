import React from 'react'
import TextField from '../../../../../../../components/inputs/text-field/text-field.component'
import { TypeButtonEnum } from '../../../../../../../components/buttons/button/button-default.types'
import ButtonDefault from '../../../../../../../components/buttons/button/button-default'
import { ForgotPasswordStepProps } from './forgot-password-step.types'
import { TypeEnum } from '../../../../../../../components/inputs/text-field/text-field.types'
import Loading from '../../../../../../../components/Loading'
import { WrapperForgotPasswordStep } from './forgot-password-step.styles'

function ForgotPasswordStep({
	textDoc,
	setTextDoc,
	showErrorCnpjRecover,
	isValidDoc,
	setStepLogin,
	isLoading
}: ForgotPasswordStepProps) {

	return (
		<>
			<WrapperForgotPasswordStep>
				Recuperar senha
			</WrapperForgotPasswordStep>
			<p>Para redefinir a sua senha, digite o seu CPF ou CNPJ.</p>
			<TextField
				label={'CPF/CNPJ'}
				placeholder={'Insira o seu CPF/CNPJ'}
				handleChangeText={setTextDoc}
				text={textDoc}
				type={TypeEnum.TEXT}
				isError={showErrorCnpjRecover}
				labelIsError={'Você não possui cadastro com esses dados'}
				idElement="documentNumberRecovery"
			/>
			{isLoading ? (
				<Loading />
			) : (
				<ButtonDefault
					buttonText="Continuar"
					type={
						isValidDoc
							? TypeButtonEnum.PRIMARY
							: TypeButtonEnum.DISABLED
					}
					onClick={setStepLogin}
					idElement="continueToChooseValidation"
				/>
			)}
		</>
	)
}

export default ForgotPasswordStep
