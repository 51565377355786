/**
 * Função que realiza o processamento de uma string e realiza a conversão e download no formato csv
 * @param data recebe uma string, como o exemplo: "col1,col2,col3\n1,2,3\n4,5,6\n"
 */
export default function downloadCsvFileByString(data: string): void {
	const rows: string[] = data.split('\n')
	const csvRows: string[] = []

	rows.forEach((row) => {
		csvRows.push(row)
	})
	let csvContent = ''
	csvRows.forEach((row) => {
		csvContent += row.split(',') + '\n'
	})
	const url = window.URL.createObjectURL(new Blob([csvContent]))
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('download', 'cupons.csv')
	document.body.appendChild(link)
	link.click()
	link.parentNode?.removeChild(link)
}
