import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { intervalToDuration, isPast, parseISO } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import cogoToast from 'cogo-toast'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import {
	IResponsePixAllPay,
	PaymentType,
	TransactionStatus
} from '../../shared/interfaces/Payments'
import { IParams, IViewProps } from './types'
import PixQrCode from './view'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { Creators as CartRedemptionActions } from '../../shared/store/ducks/cartRedemptionItem'
import { Creators as CartCchallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { useTransactionStatus } from '../../shared/services/paymentMethod.service'
import { cancelTransactionId } from '../../shared/services/order.service'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { sendOrderTransactionTag } from '../../shared/utils/allin'

function PixQrCodeContainer(): JSX.Element {
	const { state } = useLocation<IParams>()

	const hasScreen = state?.hasScreen || false

	const { carts, customer, cartRedemptionItems } = useTypedSelector([
		'carts',
		'customer',
		'cartRedemptionItems'
	])
	const [pix, setPix] = useState<IResponsePixAllPay>({} as IResponsePixAllPay)
	const intervalId = useRef<any>(0)
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState('')
	const dispatch = useDispatch()
	const [transactionId, setTransactionId] = useState('')
	const history = useHistory()

	const { data, isError } = useTransactionStatus({
		transactionId: loading ? '' : transactionId
	})

	function init() {
		const getTransaction = carts.map((x) => x.transaction)[0]
		if (!getTransaction) {
			history.replace(CustomerRoutesEnum.PRODUCT_LIST)
			return
		}
		setPix(getTransaction)
	}

	function returnPadStart(text: number) {
		return text?.toString().padStart(2, '0')
	}

	function cleanCart() {
		const updated = carts.map((items) => {
			return { ...items, transaction: undefined }
		})

		dispatch(CartActions.updateCart(updated))
		history.replace(CustomerRoutesEnum.PRODUCT_LIST)
	}

	const startTime = useCallback(() => {
		if (!pix.transactionId) {
			// history.replace(CustomerRoutesEnum.PRODUCT_LIST)
			return
		}

		intervalId.current = setInterval(() => {
			const count = intervalToDuration({
				start: Date.now(),
				end: parseISO(pix.expireAt)
			})
			const { hours, minutes, seconds } = count
			if (isPast(parseISO(pix.expireAt))) {
				cogoToast.error('QR Code expirado!', cogoDefaultOptions)
				setTime('')
				clearInterval(intervalId.current)
				cleanCart()

				if (!hasScreen) {
					history.push(CustomerRoutesEnum.PRODUCT_LIST)
				} else {
					history.push(CustomerRoutesEnum.ORDER_SUMMARY)
				}

				return
			}

			const msg: string =
				returnPadStart(hours || 0) +
				':' +
				returnPadStart(minutes || 0) +
				':' +
				returnPadStart(seconds || 0)

			setTime(msg)
		}, 1000)
		return () => {
			clearInterval(intervalId.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carts, hasScreen, pix.expireAt])

	function goToFinishOrderSuccess() {
		if (data?.transactionStatus === TransactionStatus.Completed) {
			carts.forEach((items) => {
				sendOrderTransactionTag(
					items.salesOrderID,
					items.items,
					customer.Email
				)

				cartRedemptionItems.length > 0 &&
					sendOrderTransactionTag(
						items.salesOrderID,
						cartRedemptionItems,
						customer.Email
					)
			})

			dispatch(CartActions.clearCart())
			dispatch(CartRedemptionActions.clearRedemptionItems())
			dispatch(CartCchallengeItemsActions.clearChallengeItems())

			history.push(CustomerRoutesEnum.SUCCESS, {
				paymentType: PaymentType.ONLINE,
				paymentMethod: 'pix',
				valueTotal: data.paymentValue,
				deliveryDate: customer.DeliveryDate
			})
		}
	}

	async function handleChangePaymentMethod() {
		try {
			setLoading(true)
			await cancelTransactionId(pix.transactionId)
			// pix.transactionId = ''
			setTransactionId('')

			const updated = carts.map((items) => {
				return { ...items, transaction: undefined }
			})

			dispatch(CartActions.updateCart(updated))

			history.push(CustomerRoutesEnum.ORDER_SUMMARY)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	async function copyPaste() {
		await navigator.clipboard.writeText(pix.pixCopyPasteUrl)
		cogoToast.success('Texto copiado', cogoDefaultOptions)
	}

	function isErrorTransaction() {
		if (isError) {
			cleanCart()
		}
	}

	useEffect(startTime, [pix])
	useEffect(init, [])
	useEffect(() => setTransactionId(pix.transactionId), [pix.transactionId])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(goToFinishOrderSuccess, [data?.transactionStatus])
	useEffect(isErrorTransaction, [isError])

	const viewProps: IViewProps = {
		pix,
		time,
		handleChangePaymentMethod,
		loading,
		copyPaste
	}

	return createElement(PixQrCode, viewProps)
}

export default PixQrCodeContainer
