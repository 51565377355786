import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ITokenActionCreators
} from '../../interfaces/action'
import { IStoreToken } from '../../interfaces/store'

export const {
	Types,
	Creators
}: IActionsFromCreators<ITokenActionCreators> = createActions({
	saveAccess: ['value'],
	resetToken: []
})

const INITIAL_STATE: IStoreToken = { JWTToken: '' }

const saveAccessToken = (state = INITIAL_STATE, action: any) => ({
	JWTToken: action.value
})
const resetAll = (_ = INITIAL_STATE, __: any) => INITIAL_STATE

export default createReducer<IStoreToken, Action<ITokenActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SAVE_ACCESS]: saveAccessToken,
		[Types.RESET_TOKEN]: resetAll
	}
)
