import Mastercard from '../../assets/images/master.svg'
import Amex from '../../assets/images/amex.svg'
import Diners from '../../assets/images/diners.svg'
import Elo from '../../assets/images/elo.svg'
import Hipercard from '../../assets/images/hipercard.svg'
import Jcb from '../../assets/images/jcb.svg'
import Union from '../../assets/images/union-pay.svg'
import Visa from '../../assets/images/visa.svg'
import Aura from '../../assets/images/aura.svg'
import Sorocred from '../../assets/images/sorocred.svg'
import Cabal from '../../assets/images/cabal.svg'
import IconDefault from '../../assets/images/icon_card_default.svg'
import { cardTypes } from '../../modules/OrderSummary/util'


function selectFlag(flagName: string) {
	switch (
		flagName //TODO: check flag names from backend
	) {
		case cardTypes.MASTERCARD:
			return Mastercard
		case cardTypes.AMEX:
			return Amex
		case cardTypes.DINERS:
			return Diners
		case cardTypes.ELO:
			return Elo
		case cardTypes.HIPERCARD:
			return Hipercard
		case cardTypes.JCB:
			return Jcb
		case cardTypes.CHINA_UNION_PAY:
			return Union
		case cardTypes.VISA:
			return Visa
		case cardTypes.AURA:
			return Aura
		case cardTypes.SOROCRED:
			return Sorocred
		case cardTypes.CABAL:
			return Cabal
		default:
			return IconDefault
	}
}

export { selectFlag }
