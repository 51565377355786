import styled, { css, keyframes } from 'styled-components'

type IAccordionProps = {
	showAnswer: boolean
}

const showAnswerAnimation = keyframes`
  from {
    max-height: 60px;
  }
  to {
    max-height: 200px;
  }
`
const closeAnswerAnimation = keyframes`
  from {
    max-height: 200px;
  }
  to {
    max-height: 60px;
  }
`

export const QuestionFAQWrapper = styled.div<IAccordionProps>`
	width: 100%;
	max-width: 720px;
	overflow: hidden;

	padding: 16px 24px;
	background-color: ${(props) => props.theme.palette.white.main};
	box-shadow: 0px 2px 4px #0000000f;

	border-radius: 4px;

	margin-bottom: 10px;

	${({ showAnswer }) =>
		showAnswer
			? css`
					animation: ${showAnswerAnimation} 500ms ease-in-out forwards;
			  `
			: css`
					animation: ${closeAnswerAnimation} 500ms ease-in-out
						forwards;
			  `}

	@media screen and (max-width: 768px) {
		overflow-y: ${({ showAnswer }) => showAnswer && 'auto'};
	}
`

export const Question = styled.div<IAccordionProps>`
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;

	transition: ease-in-out 100ms;

	.button-open-question {
		border: none;
		cursor: pointer;
		background-color: transparent;
	}

	.question-title {
		color: ${(props) => props.theme.palette.gray.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		margin-top: 8px;
		margin-bottom: 24px;
		display: inline-block;
	}

	.icon-action {
		> img {
			margin-top: 8px;
		}
	}
`

export const QuestionDescription = styled.p`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
`
