import React from 'react'

import { IProps } from './types'
import InsufficientPointsAlert from '../InsufficientPointsAlert'

import {
	InputQuantityWrapper,
	InputAndButtonsWrapper,
	InsufficientPointsLabelWrapper
} from './styles'

import * as S from './styles'

function InputQuantityLabel(props: IProps) {
	const {
		enableButtons = false,
		id,
		name,
		value,
		handleUpdate,
		isEditable = true,
		cartScreen = false,
		handleDecrementEvent,
		handleIncrementEvent,
		itsARescueItem = false,
		isChallengeMixItem = false,
		insufficientAmountOfPointsForRedemption = false,
		disableChallengeItemsAmountDecrement = false,
		isComboFlex,
		comboFlexValue,
		comboFlexMaxQuantity
	} = props

	const className = `input-value ${value !== 0 ? 'active' : ''}`

	const modalMessageyouDontHaveEnoughPoints =
		insufficientAmountOfPointsForRedemption && itsARescueItem && !cartScreen

	const cartScreenMessageYouDontHaveEnoughPoints =
		insufficientAmountOfPointsForRedemption && cartScreen && itsARescueItem

	return (
		<InputQuantityWrapper>
			<InputAndButtonsWrapper>
				{enableButtons && isEditable ? (
					<S.DecrementButton
						disabled={
							isComboFlex
								? value === 0
								: value <= 1 ||
								  (isChallengeMixItem &&
										disableChallengeItemsAmountDecrement)
						}
						onClick={handleDecrementEvent}>
						<span>-</span>
					</S.DecrementButton>
				) : null}

				<S.InputWithValueWrapper
					insufficientAmountOfPointsForRedemption={
						cartScreenMessageYouDontHaveEnoughPoints ||
						modalMessageyouDontHaveEnoughPoints
					}>
					<input
						id={id}
						type="number"
						name={name}
						maxLength={4}
						value={value}
						className={className}
						disabled={!isEditable}
						onChange={handleUpdate}
					/>
				</S.InputWithValueWrapper>

				{enableButtons && isEditable ? (
					<S.IncrementButton
						onClick={handleIncrementEvent}
						disabled={
							isComboFlex
								? value === comboFlexValue ||
								  value === comboFlexMaxQuantity
								: modalMessageyouDontHaveEnoughPoints ||
								  cartScreenMessageYouDontHaveEnoughPoints
						}>
						<span>+</span>
					</S.IncrementButton>
				) : null}
			</InputAndButtonsWrapper>
			{cartScreenMessageYouDontHaveEnoughPoints ? (
				<InsufficientPointsLabelWrapper>
					<InsufficientPointsAlert cartScreen={cartScreen} />
				</InsufficientPointsLabelWrapper>
			) : modalMessageyouDontHaveEnoughPoints ? (
				<InsufficientPointsAlert />
			) : null}
		</InputQuantityWrapper>
	)
}

export default InputQuantityLabel
