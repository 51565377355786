import { createElement, useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import {
	findCustomer,
	deleteCustomer
} from '../../shared/services/customer.service'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { IUser } from '../SAC/PendingApproval/types'
import { fetchCompanyParam } from '../ResaleParameters/service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'

import { FetchStatus } from './util'
import { Status, IViewProps } from './types'
import CustomerExclusionView from './view'
import { UserTypeEnum } from '../../shared/interfaces/customer'

export default function CustomerExclusion() {
	const { customer: user } = useTypedSelector(['customer'])
	const { activeSalesOrganizationID } = user

	const [userSalesOrgId, setUserSalesOrgId] = useState<string>()
	const [customerDocument, setCustomerDocument] = useState('')
	const [documentIsValid, setDocumentIsValid] = useState(false)
	const [fetchCustomerStatus, setFetchCustomerStatus] = useState<FetchStatus>(
		new FetchStatus()
	)
	const [customer, setCustomer] = useState<IUser>()
	const [showModalConfirm, setShowModalConfirm] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [excludedCustomer, setExcludedCustomer] = useState<boolean>(false)

	const [search] = useDebounce(customerDocument, 2000)

	const toggleModalConfirm = useCallback(() => {
		setShowModalConfirm((state) => !state)
	}, [])

	const handlers = {
		changeDocument: useCallback((value: string) => {
			setCustomerDocument(value)
		}, []),
		changeDocumentValidation: useCallback((value: boolean) => {
			setDocumentIsValid(value)
		}, []),
		deleteCustomer: useCallback(() => {
			;(async () => {
				try {
					setIsDeleting(true)

					const customerId = customer?.SignupTrackingID || ''
					const customerDocument =
						customer?.CompanyFiscalID ||
						customer?.PersonalFiscalID ||
						''

					const response = await deleteCustomer(
						`${customerId};${customerDocument}`
					)

					if (!response) return

					/*Esta deleção foi migrada para o Backend
					const congnitoResponse = await deleteCognitoCustomer(
						customerDocument
					)

					if (!congnitoResponse) return
					*/

					setExcludedCustomer(true)
					setCustomerDocument('')
					setCustomer(undefined)
					changeFetchStatus('not_started')
					cogoToast.success(
						'Cliente excluído com sucesso!',
						cogoDefaultOptions
					)
				} catch {
					cogoToast.error(
						'Erro ao excluir Cliente!',
						cogoDefaultOptions
					)
				} finally {
					setIsDeleting(false)
				}
			})()
		}, [customer])
	}

	function changeFetchStatus(status: Status) {
		setFetchCustomerStatus(new FetchStatus(status))
	}

	function searchCustomer() {
		if (!customerDocument) return

		if (
			!documentIsValid &&
			(customerDocument.length === 11 || customerDocument.length === 14)
		) {
			changeFetchStatus('not_found')
			return
		}

		if (!documentIsValid) return
		;(async () => {
			try {
				changeFetchStatus('fetching')

				const customerResponse = await findCustomer({
					document: customerDocument,
					salesOrgId: activeSalesOrganizationID
				})

				setCustomer(customerResponse)
				changeFetchStatus(customerResponse ? 'has_found' : 'not_found')
			} catch {
				changeFetchStatus('not_found')
			}
		})()
	}

	function fetchResales(id?: string) {
		;(async (id?: string) => {
			if (user.Type !== UserTypeEnum.UNIDADE) return

			const params = await fetchCompanyParam(id)
			const salesOrganizationIds: string[] = params
				.filter(
					(param) =>
						param.Parameter === 'HABILITA_VENDA_BOM_PARCEIRO' &&
						param.Items.find((item) => item.Value === 'Sim') &&
						(id ? param.ParameterSalesOrganizationID === id : true)
				)
				.map((param: any) => param.SalesOrganizationID)

			const userSalesOrganizationId = user.companies.find((company) =>
				salesOrganizationIds.includes(company.salesOrganizationID)
			)

			if (userSalesOrganizationId) {
				setUserSalesOrgId(userSalesOrganizationId.salesOrganizationID)
			}
		})()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(searchCustomer, [search, user.activeSalesOrganizationID])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchResales, [])

	const viewProps: IViewProps = {
		handlers,
		customerDocument,
		fetchCustomerStatus,
		customer,
		toggleModalConfirm,
		showModalConfirm,
		isDeleting,
		excludedCustomer
	}

	return createElement(CustomerExclusionView, viewProps)
}
