import React, { useCallback, useEffect, useMemo, useState } from 'react'
import CellTableDelivey from './components/cell-table-delivey/cell-table-delivey.component'
import TableLegend from './components/table-legend.component/table-legend.component'
import SearchInput from '../../../../components/SearchInput'
import * as S from './days-delivery-table.styles'
import Table from '../../../../components/Table'
import { IGetDeliveryDatesResponse } from '../../interfaces/get-delivery-dates'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../shared/utils/toaster'
import { IDaysDeliveryTable } from './interfaces/days-delivery-table.types'
import { handleFilterDeliveryDates } from '../../utils/filter-dates-delivery'
import { getDeliveryDates } from '../../services/delivery-date.services'

function DaysDeliveryTable({ handleLogin, cds }: IDaysDeliveryTable) {
	const [searchText, setSearchText] = useState<string>('')
	const [daysDelivery, setDaysDelivery] = useState<any[]>([])
	const [filteredData, setFilteredData] = useState<any[]>([])


	const columns = useMemo(
		() => [
			{ Header: 'Código CD', accessor: 'salesOrganizationID' },
			{ Header: 'CD', accessor: 'CD' },
			{ Header: 'Cidade', accessor: 'city' },
			{ Header: 'Seg', accessor: 'monday' },
			{ Header: 'Ter', accessor: 'tuesday' },
			{ Header: 'Qua', accessor: 'wednesday' },
			{ Header: 'Qui', accessor: 'thursday' },
			{ Header: 'Sex', accessor: 'friday' },
			{ Header: 'Sab', accessor: 'saturday' }
		],
		[]
	)

	const generateTable = useCallback((tableData: IGetDeliveryDatesResponse[]) => {
		const createCell = (
			day: string,
			available: boolean,
			isSunday?: boolean
		) => (
			<CellTableDelivey
				type={available ? 'available' : 'unavailable'}
				isSsunday={isSunday}
			/>
		)

		const formatedData = tableData.map((item) => ({
			...item,
			sunday: createCell('sunday', item.sunday, true),
			monday: createCell('monday', item.monday),
			tuesday: createCell('tuesday', item.tuesday),
			wednesday: createCell('wednesday', item.wednesday),
			thursday: createCell('thursday', item.thursday),
			friday: createCell('friday', item.friday),
			saturday: createCell('saturday', item.saturday)
		}))

		setDaysDelivery(formatedData)
	}, [])

	useEffect(() => {
		const fetchData = async () => {
			try {
				handleLogin(true)
				// Chamada para api, retornando todos os dados
				const tableData = await getDeliveryDates(cds).finally(() =>
					handleLogin(false)
				)

				// Gerando a tabela de dados filtrados
				generateTable(tableData as IGetDeliveryDatesResponse[])
			} catch (error) {
				console.error('Erro ao buscar dados de entrega:', error)
				cogoToast.error(
					'Erro ao buscar dados de entrega',
					cogoDefaultOptions
				)
			}
		}

		fetchData()
	}, [cds, generateTable, handleLogin])

	useEffect(() => {
		// Filtrando os dados da api por salesOrganizationID, cd, city
		const filteredData = handleFilterDeliveryDates({
			data: daysDelivery,
			input: searchText,
			params: ['salesOrganizationID', 'CD', 'city']
		})

		setFilteredData(filteredData)
	}, [daysDelivery, searchText])

	return (
		<S.WrapperDaysDeliveryTable>
			<h2>Dias possíveis de entrega CD</h2>
			<S.FilterWrapper>
				<SearchInput
					handleChange={(event) => setSearchText(event.target.value)}
					inputValue={searchText}
					placeholder="Pesquise por Código, Nome do CD ou Cidade"
					width="370px"
				/>
				<TableLegend />
			</S.FilterWrapper>
			<Table data={filteredData} columns={columns as []} pageSize={50} />
		</S.WrapperDaysDeliveryTable>
	)
}

export default DaysDeliveryTable
