import { ICustomer } from '../../shared/interfaces/customer'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { IGetFieldsOptions, IInputProps } from './types'

export const className = 'input-secondary'

const disabled = true

const hasError = false

export const personalFields = (
	customer: ICustomer,
	options: IGetFieldsOptions,
): IInputProps[] => {
	const { history } = options
	const deliveryDate = customer.DeliveryDate
		? customer.DeliveryDate.replace(/-/g, '/')
				.split('/')
				.reverse()
				.join('/')
		: ''

	return [
		{
			className,
			containerClass: 'input-wrapper-12',
			disabled,
			hasError,
			id: 'name',
			keyName: 'Name',
			label: 'Seu Nome',
			value: customer.Name
		},
		{
			className,
			containerClass: 'input-wrapper-12',
			disabled,
			hasError,
			id: 'email',
			keyName: 'Email',
			label: 'E-mail',
			value: customer.Email,
			disabledCopyPaste: true,
			rightButton: {
				text: 'ALTERAR EMAIL',
				onClick: () => history.replace(CustomerRoutesEnum.RECOVER_EMAIL)
			},
		},
		{
			className,
			containerClass: 'input-wrapper-12',
			disabled,
			hasError,
			id: 'cpf',
			keyName: 'PersonalFiscalID',
			label: 'CPF',
			mask: '999.999.999-999999',
			value: customer.PersonalFiscalID
		},
		{
			className,
			containerClass: 'input-wrapper-6',
			disabled,
			hasError,
			id: 'phone',
			keyName: 'PhoneNumber',
			label: 'Telefone Fixo',
			mask: '(99) 99999-9999',
			value: customer.PhoneNumber
		},
		{
			className,
			containerClass: 'input-wrapper-6',
			disabled,
			hasError,
			id: 'cel',
			keyName: 'CellphoneNumber',
			label: 'Celular',
			mask: '(99) 99999-9999',
			value: customer.CellphoneNumber,
			rightButton: {
				text: 'ALTERAR CELULAR',
				onClick: () => history.replace(CustomerRoutesEnum.CHANGE_CELLPHONE)
			},
		},
		{
			className,
			containerClass: 'input-wrapper-12',
			disabled,
			hasError,
			id: 'deiveryDate',
			keyName: 'deiveryDate',
			label: 'Próxima data de entrega prevista',
			value: deliveryDate
		},
		{
			className,
			containerClass: 'input-wrapper-12',
			disabled,
			hasError,
			id: 'password',
			label: 'Senha',
			value: '● ● ● ● ● ●',
			rightButton: {
				text: 'ALTERAR SENHA',
				onClick: () => history.replace(UnauthenticatedRoutesEnum.CHANGE_PERSONAL_PASSWORD)
			},
		}
	]
}

export const storeFields = (customer: ICustomer) => [
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		hasError,
		id: 'companyName',
		keyName: 'NickName',
		label: 'Nome Fantasia Do Estabelecimento',
		value: customer.NickName
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		hasError,
		id: 'cnpj',
		keyName: 'CompanyFiscalID',
		label: 'CNPJ',
		mask: '99.999.999/9999-99',
		value: customer.CompanyFiscalID
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'cep',
		keyName: 'PostalCode',
		label: 'CEP',
		mask: '99999-99999',
		value: customer.PostalCode
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'number',
		keyName: 'HouseNumber',
		label: 'Número',
		value: customer.HouseNumber
	},
	{
		className,
		containerClass: 'input-wrapper-12',
		disabled,
		hasError,
		id: 'street',
		keyName: 'Street',
		label: 'Nome da Rua',
		value: customer.Street
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'neighborhood',
		keyName: 'Neighborhood',
		label: 'Bairro',
		value: customer.Neighborhood
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'address2',
		keyName: 'NickName',
		label: 'Complemento',
		value: customer.NickName
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'city',
		keyName: 'City',
		label: 'Cidade',
		value: customer.City
	},
	{
		className,
		containerClass: 'input-wrapper-6',
		disabled,
		hasError,
		id: 'state',
		keyName: 'Region',
		label: 'Estado',
		value: customer.Region
	}
]

export const useCustomerFields = (customer: ICustomer, options: IGetFieldsOptions) => [
	personalFields(customer, options),
	storeFields(customer)
]
