import React, { useEffect, useState } from 'react'

import * as S from './styles'
import CreatableSelect from 'react-select/creatable'

import { reactSelectCreatableStyle } from '../../shared/styles/theme'

import { FilterHierarchyType } from './components/filter-hierarchy-type'
import { FilterSelectPdvType } from './components/filter-select-pdv-type'
import { useFilterHierarchy } from './hooks/useFilterHierarchy'
import { ValueType } from 'react-select'
import { IFiltersByHieararchyProps } from './typings'

function FiltersByHieararchy({
	onLoad,
	onLoadEnd,
	onFilterChange,
	getFilterParameters,
	handleGetFilterValues,
	initialFilters,
	isEnabled,
	canFilter,
	shouldListPdv = true,
	setCanFilter,
	setPushSelectedPDVs,
	setCanSendPdv,
	onPushFilterChange,
	impactedPdvsLength,
	fetchListPDVsPush,
	errorMessage,
	setFilterType,
	setListImpactedPdvs,
	listImpactedPdvs,
	withoutPdv = false,
	labelTitle
}: IFiltersByHieararchyProps) {
	const {
		isLoading,
		setIsFilter,
		createPdvOption,
		handleClearFields,
		handleClickFilterListPdvs,
		otherFiltersProps,
		salesHierarchyFiltersProps,
		setSelectedPDVs,
		selectedPDVs,
		groupFilters
	} = useFilterHierarchy({
		onLoad,
		onLoadEnd,
		onFilterChange,
		getFilterParameters,
		handleGetFilterValues,
		initialFilters,
		setCanFilter,
		setPushSelectedPDVs,
		setCanSendPdv,
		onPushFilterChange,
		shouldListPdv,
		fetchListPDVsPush,
		setListImpactedPdvs,
		listImpactedPdvs
	})

	const optionsSelecteFilter = [
		{
			label: 'Filtro por Hierarquia',
			value: 'filter-hierarchy'
		},
		{
			label: 'Seleção de PDV',
			value: 'select-pdv'
		}
	]

	const [optionSelected, setOptionSelected] = useState<
		ValueType<
			{
				label: string
				value: string
			},
			false
		>
	>({
		label: 'Filtro por Hierarquia',
		value: 'filter-hierarchy'
	})

	function handleChangeFilter(
		option: ValueType<
			{
				label: string
				value: 'select-pdv' | 'filter-hierarchy'
			},
			false
		>
	) {
		if (!option) return
		handleClearFields()
		setOptionSelected(option)
		setFilterType?.(option.value)
	}

	useEffect(() => {
		if (groupFilters?.includes('PDV')) {
			setOptionSelected({
				label: 'Seleção de PDV',
				value: 'select-pdv'
			})

			return
		}

		setOptionSelected({
			label: 'Filtro por Hierarquia',
			value: 'filter-hierarchy'
		})
	}, [groupFilters])

	return (
		<>
			{!withoutPdv && (
				<S.StyledWrapperSelect>
					<S.LabelSelect
						isDisabled={!!groupFilters?.length}
						className="multiselect-label">
						TIPO DE FILTRO
					</S.LabelSelect>

					<CreatableSelect
						value={optionSelected}
						onChange={handleChangeFilter}
						isDisabled={!!groupFilters?.length}
						defaultValue={{
							label: 'Filtro por Hierarquia',
							value: 'filter-hierarchy'
						}}
						options={optionsSelecteFilter.map((option) => ({
							label: option.label,
							value: option.value
						}))}
						styles={reactSelectCreatableStyle}
					/>
				</S.StyledWrapperSelect>
			)}

			{optionSelected?.value === 'filter-hierarchy' && (
				<FilterHierarchyType
					canFilter={canFilter}
					handleClearFields={handleClearFields}
					isEnabled={isEnabled}
					isLoading={isLoading}
					otherFiltersProps={otherFiltersProps}
					salesHierarchyFiltersProps={salesHierarchyFiltersProps}
					handleClickFilterListPdvs={handleClickFilterListPdvs}
					setIsFilter={setIsFilter}
					impactedPdvs={
						selectedPDVs.length || impactedPdvsLength || 0
					}
					errorMessage={errorMessage}
					labelTitle={labelTitle}
				/>
			)}

			{optionSelected?.value === 'select-pdv' && (
				<FilterSelectPdvType
					canFilter={canFilter}
					createPdvOption={createPdvOption}
					isEnabled={isEnabled}
					selectedPDVs={selectedPDVs}
					setCanFilter={setCanFilter}
					setSelectedPDVs={setSelectedPDVs}
					setPushSelectedPDVs={setPushSelectedPDVs}
					setListImpactedPdvs={setListImpactedPdvs}
					listImpactedPdvs={listImpactedPdvs}
					handleClearFields={handleClearFields}
					setCanSendPdv={setCanSendPdv}
					errorMessage={errorMessage}
				/>
			)}
		</>
	)
}

export default FiltersByHieararchy
