import React from 'react'
import * as S from './footer-layout.styles'

import GPLogo from '../../assets/images/logo-gp.svg'

import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import DownloadIcon from '@mui/icons-material/Download'

import CookieContent from './components/cookie-content/cookie-content.component'
import SocialMidiaIcons from './components/social-midia-icons/social-midia-icons.component'
import { fnOpenChat } from './utils/open-chat'

import packageJson from '../../../package.json'
import { SAC_TELEPHONE_NUMBER } from '../../assets/strings'

const FooterLayout = () => {
	const handleOpenChat = () => {
		fnOpenChat()
	}
	return (
		<S.FooterWrapper>
			<div className="footer-wrapper">
				<img src={GPLogo} alt="logo-gp" />
				<p className="text-date">
					Dúvidas? Ligue para Central de Atendimento de
					<br />
					Segunda à Sexta, das 8h às 17h ( exceto feriados)
				</p>
				<div className="text-icon">
					<SupportAgentIcon />
					<p>{SAC_TELEPHONE_NUMBER}</p>
				</div>
				<p className="item-column">Versão: {packageJson.version}</p>
			</div>

			<div className="footer-wrapper">
				<strong className="title-column">Serviços</strong>
				<p className="item-column clicked" onClick={handleOpenChat}>
					Dúvidas
				</p>
				<a
					href="https://privacy-central.securiti.ai/#/dsr/64064192-5dcc-4bcf-a94e-272b9553d923"
					target="_blank"
					rel="noreferrer"
					className="item-column clicked">
					Canal de Atendimento aos Titulares
				</a>
				<a
					href="https://www.grupopetropolis.com.br/informacoes-legais/aviso-de-privacidade/"
					target="_blank"
					rel="noreferrer"
					className="item-column clicked">
					Política de Privacidade
				</a>
				<CookieContent />
			</div>
			<div className="footer-wrapper">
				<strong className="title-column">Nossos canais</strong>
				<SocialMidiaIcons />
				<p className="item-column">
					Baixe também o nosso
					<br />
					aplicativo
				</p>
				<a
					href="https://play.google.com/store/apps/details?id=com.bomparceiro"
					target="_blank"
					rel="noreferrer">
					<div className="text-icon clicked">
						<DownloadIcon />
						<p>Baixar App</p>
					</div>
				</a>
			</div>
		</S.FooterWrapper>
	)
}

export default FooterLayout
