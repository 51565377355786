import { EnumTypeFlow, ValidationProps } from '../../reset-password.types'

export interface ICodeValidationComponent {
	typeContact: TypeContact
	contact: string
	otherWay: () => void
	showAnotherFormOption: boolean
	goStep: (step: number) => void
	handleOpenSidebar: () => void
	setContactData: (object: ValidationProps) => void
	isLoading: boolean
	setIsLoading: (param: boolean) => void
	customerDocument: string
	newPassword: string
	newEmail: string
	newPhone: string
	isPasswordValidated: boolean
	setIsPasswordValidated: (param: boolean) => void
	typeFlow: EnumTypeFlow
	activationType: string
	isEmailRecover: boolean
	customerID: string
}

export enum TypeContact {
	EMAIL = 'EMAIL',
	PHONE = 'PHONE',
	EMAIL_ALTERNATIVE = 'EMAIL_ALTERNATIVE',
	PHONE_ALTERNATIVE = 'PHONE_ALTERNATIVE',
	NEW_EMAIL = 'NEW_EMAIL',
	NEW_PHONE = 'NEW_PHONE',
	EMAIL_AND_PHONE = 'EMAIL_AND_PHONE'
}
