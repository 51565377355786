import styled, { css } from 'styled-components'
import { Autocomplete } from '@mui/material'

export const Wrapper = styled.div`
	padding: 64px 72px;
	width: 100%;
`

export const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 130px;

	padding: 24px 0px;
`
export const Title = styled.h1(
	({ theme: { palette, font } }) => css`
		font-size: ${font.size.DISPLAY_XS.size}rem;
		line-height: ${font.size.DISPLAY_XS.line_height}rem;
		font-family: ${font.family};
		color: ${palette.black.main};
		margin-bottom: 6px;
	`
)

export const SubTitle = styled.p(
	({ theme: { palette, font } }) => css`
		font-size: ${font.size.TEXT_MD.size}rem;
		line-height: ${font.size.TEXT_MD.line_height}rem;
		font-family: ${font.family};
		color: ${palette.black.main};
	`
)

export const AutoCompleteStyles = styled(Autocomplete)`
	background-color: #ffff;
	&& {
		.Mui-focused {
			border-color: none;
		}
	}
`

export const LeftContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 17px;

	.programming-options {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 24px;

		p {
			font-size: 16px;
			color: #5d615d;
		}

		.wrapper-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
		}

		.wrapper-inputs {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 16px;

			.datePicker {
				display: flex;
				align-items: center;
			}
		}
	}
`

export const RightContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;

	gap: 22px;

	h2 {
		color: #5d615d;
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	}
`
