import React from 'react'
import GPLogo from '../../assets/images/logo-gp.svg'
import { FooterWrapper } from './styles'

export default function FooterNotLogged() {
	return (
		<FooterWrapper>
			<img className="gp-logo" src={GPLogo} alt="Grupo Petrópolis" />
			<button>Gerenciamento de Cookies</button>
		</FooterWrapper>
	)
}
