import styled from 'styled-components'
import { Box } from '@mui/material'

export const WrapperLayoutModal = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	overflow-y: auto;
	overflow-x: hidden;

	padding: 40px;
	gap: 32px;
	background: ${(props) => props.theme.palette.background.paper};
	box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.16);
	border-radius: 16px;

	.header-modal {
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 10px;

		h2 {
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;

			color: ${(props) => props.theme.palette.black.main};
		}
	}

	.footer-modal {
		width: 568px;
		border-top: 1px solid #e0e0e0;

		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 35px;
	}

	.footer-modal-details {
		width: 568px;
		border-top: 1px solid #e0e0e0;

		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-top: 35px;
	}
`

export const CustomCloseIcon = styled.div`
	display: flex;
	color: ${(props) => props.theme.palette.black.main};
	justify-content: flex-end;
	cursor: pointer;
`
