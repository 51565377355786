import styled from 'styled-components'

export const WrapperSelect = styled.div`
	color: ${(props) => props.theme.palette.primary.main};

	svg {
		color: ${(props) => props.theme.palette.primary.main};
	}
	.select-cd {
		width: 312px;
		&.MuiOutlinedInput-root {
			&:hover fieldset {
				border-color: ${(props) => props.theme.palette.primary.main};
			}
			&.Mui-focused fieldset {
				border-color: ${(props) => props.theme.palette.primary.main};
			}
		}
		li {
			font-size: ${props=> props.theme.font.size.TEXT_XS}rem;
			font-style: normal;
			font-weight: ${props=> props.theme.font.weight.medium};
			line-height: ${props=> props.theme.font.size.TEXT_MD}rem;
			&.Mui-focused fieldset {
				border-color: ${(props) => props.theme.palette.primary.main};
			}
			&:hover fieldset {
				background-color: ${(props) => props.theme.palette.primary.light};
			}
		}

		svg: {
			color: ${(props) => props.theme.palette.primary.main};
		}
	}
`
