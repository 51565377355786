import styled from 'styled-components'
import ButtonDefault from '../../../../../../components/buttons/button/button-default'

export const WrapperSuccessModal = styled.div`
	gap: 24px;

	.main-modal {
		width: 100%;
		display: flex;
		align-items: center;
        text-align: center;
		justify-content: center;
		gap: 24px;
		flex-direction: column;
		padding: ${(props) => props.theme.spacing};

		.wrapper-icon{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 82px;
			height: 82px;
			background-color: ${(props)=>props.theme.palette.icon.sucess};
			border-radius: 50%;

			.success-icon{
				width:  ${(props) => props.theme.font.size.DISPLAY_LG.line_height}rem;
				height:  ${(props) => props.theme.font.size.DISPLAY_LG.line_height}rem;
			}
			
		}

		h2 {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
            font-weight: ${(props) => props.theme.font.weight.bold};
	        padding: ${(props) => props.theme.spacingOptions.XLG};
            text-align: center;
		}

		p {
            width: 90%;
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			font-weight: ${(props) => props.theme.font.weight.semibold};
			line-height: ${(props) => props.theme.font.size.DISPLAY_XS.line_height};
            text-align: center;

		}

	}
`

export const ButtonStyled = styled(ButtonDefault)`
	font-size: ${(props) => props.theme.font.size.DISPLAY_MD.size}rem;

`