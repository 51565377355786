import { IChallenge } from '../interfaces/pointsProgramChallenge'

import BR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { include } from './boolean'

const locale = { locale: BR }

export const filteringChallengesByString = (
	challenges: IChallenge[],
	value: string
): IChallenge[] => {
	let finalArrayWithItemsFiltereds: IChallenge[] = []
	if (challenges.length) {
		challenges.forEach((challenge, index, array) => {
			const challengeValidityDateFormatted = `De ${format(
				new Date(String(challenge.startDate) + 'T12:00:00'),
				'dd/MM/yy',
				locale
			)} até ${format(
				new Date(String(challenge.endDate) + 'T12:00:00'),
				'dd/MM/yy',
				locale
			)}`

			const columnWithTheAmountOfProducts = `${
				challenge.products?.length
			} ${challenge.products?.length === 1 ? 'Produto' : 'Produtos'}`

			challenge.products?.forEach((item) => {
				if (item.productId === value.toLowerCase()) {
					finalArrayWithItemsFiltereds[index] = array[index]
				}
			})

			challenge.filtersChallenge?.forEach((item) => {
				if (
					include(item.paramValue.toLowerCase(), value.toLowerCase())
				) {
					finalArrayWithItemsFiltereds[index] = array[index]
				}
			})

			const checkingIfTheSearchIsBeingCarriedOutForValidityPeriod =
				value.toLowerCase().includes('até') ||
				value.toLowerCase().includes('de')

			if (
				include(String(challenge.title), value) ||
				String(challenge.status?.toLowerCase()) ===
					value.toLowerCase() ||
				(include(
					challengeValidityDateFormatted.toLowerCase(),
					value.toLowerCase()
				) &&
					checkingIfTheSearchIsBeingCarriedOutForValidityPeriod) ||
				columnWithTheAmountOfProducts.toLowerCase() ===
					value.toLowerCase() ||
				String(challenge.score) === value
			) {
				finalArrayWithItemsFiltereds[index] = array[index]
			}
		})
	}
	return finalArrayWithItemsFiltereds
}
