import React from 'react'
import { useHistory } from 'react-router-dom'

import { IModalChallengePopUpProps } from './types'
import { Modal } from '../../../shared/components'

import { CustomerRoutesEnum } from '../../Routes/customerRoutesEnum'
import { ReactComponent as ClosePopUpIcon } from '../../../assets/images/close-popup-icon.svg'

import * as S from './styles'

export default function ModalChallengePopUp(props: IModalChallengePopUpProps) {
	const { active, challenge, onClose } = props

	const history = useHistory()

	const goToChallengeDetails = () => {
		onClose()
		history.push(CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS, {
			challenge
		})
	}

	return (
		<Modal isActive={active} isFull>
			<S.ModalChallengePopUpContentWrapper>
				<S.ModalChallengePopUpContent
					appPopUpImageUrl={challenge.appPopUpImageUrl}
					onClick={goToChallengeDetails}
				/>
				<S.ButtonClosePopUp onClick={onClose}>
					<ClosePopUpIcon />
				</S.ButtonClosePopUp>
			</S.ModalChallengePopUpContentWrapper>
		</Modal>
	)
}
