import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Modal } from '../../../shared/components'

import { Terms } from '../../../shared/components/PointsProgramModalAdoption/TermsModal/Terms'
import { Creators as ChallengesTheUserIsParticipatingInActions } from '../../../shared/store/ducks/challengesTheUserIsParticipatingIn'

import { IModalTermsProps } from './types'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { acceptTermOfTheChallenge } from '../../../shared/services/challenge.service'
import { AcceptTermEnumType } from '../../../shared/interfaces/pointsProgramChallenge'
import { CustomerRoutesEnum } from '../../Routes/customerRoutesEnum'

export default function ChallengeMembershipTerms({
	active,
	onClose,
	challenge
}: IModalTermsProps) {
	const dispatch = useDispatch()
	const { customer } = useTypedSelector(['customer'])
	const hitory = useHistory()

	const [terms, setTerms] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)
	const [acceptTerms, setAcceptTerms] = useState(false)

	function handleChangeTermsAccept() {
		setAcceptTerms((prev) => !prev)
	}

	const handleAcceptChallenge = () => {
		;(async () => {
			try {
				setIsLoading(true)
				if (!challenge) return
				const termAcceptanceResponse = await acceptTermOfTheChallenge({
					customerID: customer.CustomerID,
					acceptTerm: AcceptTermEnumType.YES,
					termDescription: String(challenge.title),
					eventDate: new Date().toISOString(),
					parentId: String(challenge.challengeId)
				})

				if (!!termAcceptanceResponse.success) {
					dispatch(
						ChallengesTheUserIsParticipatingInActions.addNewChallenge(
							{
								challengeID: challenge.challengeId,
								products: challenge.products.map((product) => {
									return {
										challengeId: product.challengeId,
										productId: product.productId,
										quantity: product.quantity,
										quantityReached: product.quantityReached
									}
								})
							}
						)
					)
					hitory.push(
						CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS,
						{
							challenge: challenge
						}
					)
					onClose()
					return
				}
				onClose()
			} finally {
				setIsLoading(false)
			}
		})()
	}

	const settingTheTermsInTheStateOfTerms = () => {
		if (!challenge) return
		setTerms([String(challenge.Term)])
	}

	const handleClose = () => {
		setAcceptTerms(false)
		onClose()
	}

	useEffect(settingTheTermsInTheStateOfTerms, [active, challenge])

	return (
		<Modal isActive={active} isFull>
			<Terms
				termAcceptedLabel="Desafio Bom Parceiro"
				terms={terms}
				termsTitle="Termos de Adesão ao Desafio Bom Parceiro"
				headerTitle="Termos Desafio BP"
				isFetchingTerms={false}
				onClose={handleClose}
				onAcceptTerms={handleAcceptChallenge}
				onChangeAcceptTerms={handleChangeTermsAccept}
				acceptedTerms={acceptTerms}
				isLoading={isLoading}
				viewMode={'JOIN'}
			/>
		</Modal>
	)
}
