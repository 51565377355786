export function maskEmail(email: string) {
	if(!email){
		return ''
	}
	const atIndex = email.indexOf('@')
	const user = email.slice(0, atIndex)
	const domain = email.slice(atIndex + 1)
	const maskedUser = user.slice(0, 2) + '*'.repeat(user.length - 2)
	const maskedDomain =
		domain.slice(0, 2) + '*'.repeat(3) + '.' + domain.split('.').slice(-1)[0]
	return `${maskedUser}@${maskedDomain}`
}

export function maskCellPhoneNumber(cellNumber: string){	
    if  (/(\+55)?(\d{2})?(\d{9})/.test(cellNumber)) {
		let numberClean = cellNumber.replace(/\D/g, '')
		if (numberClean.startsWith('55')){
			numberClean = numberClean.slice(2)
		}	
		const numberFormatted = `(${numberClean.slice(0,2)})*****-${numberClean.slice(-4)}`	
		return numberFormatted

	} else {
		return ''
	}
}
