import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const Container = styled.div`
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.container {
		min-height: calc(100vh - (105px + 144px));
		padding-top: 40px;
	}

	.wrapper {
		.header {
			.back-arrow-btn {
				display: flex;
				align-items: center;
				background: 0;
				border: 0;
				margin-bottom: 20px;

				img {
					width: 16px;
					height: 16px;
				}

				p {
					font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
					letter-spacing: 0.19px;
					color: ${colors.red};
				}
			}

			.header-text {
				font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
				letter-spacing: 0.38px;
				color: ${(props) => props.theme.palette.gray.main};
				margin-bottom: 20px;
				font-weight: ${(props) => props.theme.font.weight.regular};
			}
		}

		.form{
			padding: 0;
			margin: auto;
		}
		
		.activation-code-subtitle{
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.gray.main};
			margin-bottom: 40px;
		}

		.recaptcha-wrapper {
			display: flex;
			justify-content: center;
			margin-bottom: 12px;
		}
	}

	.button-center{
		text-align: center;
		align-self: center;
		margin-bottom: 20px;
	}
`
