import React from 'react'
import * as S from '../../image-video-component/modal-video.styles'

import ReactPlayer from 'react-player'
import PlayerButton from '../../../../views/login-customer/components/image-slider/components/player-button/player-button.component'
import MutedButton from '../../../../views/login-customer/components/image-slider/components/muted-button/muted-button.component'
import Modal from '@mui/material/Modal'

function ModalVideoComponent(
    {
        videoUrl,
        handleToggleModal
    }: {
        videoUrl: string
        handleToggleModal: () => void
    }) {
    const [muted, setMuted] = React.useState(true)
    const [isPlaying, setIsPlaying] = React.useState(false)

    return (
        <Modal
            open={true}
            onClose={handleToggleModal}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <S.VideoPlayerWrapperModal>
                    {/* @ts-ignore */}
                    <ReactPlayer
                        url={videoUrl}
                        playing={isPlaying}
                        onEnded={() => {
                            setIsPlaying(false)
                        }}
                        muted={muted}
                        width={'100%'}
                        height={'100%'}
                    />
                    <PlayerButton
                        isPlaying={isPlaying}
                        onClickPlay={() => setIsPlaying(true)}
                        onClickPause={() => setIsPlaying(false)}
                    />
                    <MutedButton
                        isMuted={muted}
                        onClickMuted={() => setMuted(!muted)}
                    />
                </S.VideoPlayerWrapperModal >
            </div>

        </Modal>
    )
}

export default ModalVideoComponent
