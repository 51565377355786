import { createElement } from 'react'

import {
	AuthMobile,
	ChangePassword,
	ChangePersonalPassword,
	Confirmation,
	GPLogin,
	NewAccount,
	NewClientForm,
	ResetPassword,
	ConfirmActivationCode,
	ActivateRegistration,
	AwaitingApproval,
	AwaitingApprovalSac
} from '../index'

import { IRouteDataV2 } from './types'
import { UnauthenticatedRoutesEnum } from './unauthenticatedRoutesEnum'
import LoginCustomer from '../../views/login-customer/login-customer.view'
import MaintenanceMessage from '../../views/maintenance-message/maintenance-message.view'

export const unauthenticatedRoutesData: IRouteDataV2[] = [
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.LOGIN_MOBILE,
		path: UnauthenticatedRoutesEnum.LOGIN_MOBILE,
		children: createElement(AuthMobile)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.RESET_PASSWORD,
		path: UnauthenticatedRoutesEnum.RESET_PASSWORD,
		children: createElement(ResetPassword)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.CHANGE_PASSWORD,
		path: UnauthenticatedRoutesEnum.CHANGE_PASSWORD,
		children: createElement(ChangePassword)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.NEW_ACCOUNT,
		path: UnauthenticatedRoutesEnum.NEW_ACCOUNT,
		children: createElement(NewAccount)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.CONFIRMATION,
		path: UnauthenticatedRoutesEnum.CONFIRMATION,
		children: createElement(Confirmation)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.NEW_CLIENT,
		path: UnauthenticatedRoutesEnum.NEW_CLIENT,
		children: createElement(NewClientForm)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.CHANGE_PERSONAL_PASSWORD,
		path: UnauthenticatedRoutesEnum.CHANGE_PERSONAL_PASSWORD,
		children: createElement(ChangePersonalPassword)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.GP_LOGIN,
		path: UnauthenticatedRoutesEnum.GP_LOGIN,
		children: createElement(GPLogin)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.LOGIN,
		path: UnauthenticatedRoutesEnum.LOGIN,
		children: createElement(LoginCustomer)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.CONFIRM_ACTIVATION_CODE,
		path: UnauthenticatedRoutesEnum.CONFIRM_ACTIVATION_CODE,
		children: createElement(ConfirmActivationCode)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.ACTIVATE_REGISTRATION,
		path: UnauthenticatedRoutesEnum.ACTIVATE_REGISTRATION,
		children: createElement(ActivateRegistration)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.AWAITING_APPROVAL,
		path: UnauthenticatedRoutesEnum.AWAITING_APPROVAL,
		children: createElement(AwaitingApproval)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.AWAITING_APPROVAL_SAC,
		path: UnauthenticatedRoutesEnum.AWAITING_APPROVAL_SAC,
		children: createElement(AwaitingApprovalSac)
	},
	{
		accessTypes: [],
		displayOnNavBar: false,
		name: '',
		key: UnauthenticatedRoutesEnum.MAINTENANCE_MESSAGE,
		path: UnauthenticatedRoutesEnum.MAINTENANCE_MESSAGE,
		children: createElement(MaintenanceMessage)
	}
]
