import React, { useMemo } from 'react'
import LayoutModal from '../../../../../../layout/layout-modal/layout-modal.layout'
import { DividerSimple, PageHeader } from '../../../consult-client-info.styles'
import Table from '../../../../../../components/Table'
import Details from '../../Details/Details.view'
import { InfoNF, SubtitleInfoNF } from '../../../../consult-client.styles'

interface INF {
	openModalNF: boolean
	setOpenModalNF: React.Dispatch<React.SetStateAction<boolean>>
	openModalInfoNF: boolean
	setOpenModalInfoNF: React.Dispatch<React.SetStateAction<boolean>>
}

const NotaFiscalModal = ({
	openModalNF,
	setOpenModalNF,
	openModalInfoNF,
	setOpenModalInfoNF
}: INF) => {
	const handleViewDetailsNF = (dataReceived: any) => {
		setOpenModalInfoNF(true)
	}

	const columnsNF = useMemo(
		() => [
			{ Header: 'Número da Nota Fiscal', accessor: 'nf' },
			{ Header: 'Número do Pedido', accessor: 'order' },
			{
				Header: 'Detalhes',
				accessor: 'details',
				Cell: ({ row }) => (
					<Details
						data={row.original}
						viewDetails={handleViewDetailsNF}
					/>
				)
			}
		],
		[]
	)

	const dataTableMockNF = [
		{
			nf: '12373123',
			order: `01052`
		},
		{
			nf: '14412233',
			order: `01053`
		},
		{
			nf: '88214233',
			order: `01054`
		}
	]
	return (
		<>
			<LayoutModal
				modalTitle="Consulta de NF"
				buttonText="Voltar"
				typeLayout="details"
				isOpen={openModalNF}
				handleToggleModal={() => {
					setOpenModalNF(false)
				}}
				handleClick={() => {
					setOpenModalNF(false)
				}}
				height="678px">
				<>
					<PageHeader>
						<p>NFs referente ao período dos últimos 90 dias</p>
					</PageHeader>

					<DividerSimple />

					<Table
						data={dataTableMockNF}
						columns={columnsNF}
						canPaginate={false}
					/>
				</>
			</LayoutModal>
			<LayoutModal
				modalTitle="Consulta de NF"
				buttonText="Voltar"
				typeLayout="details"
				isOpen={openModalInfoNF}
				handleToggleModal={() => {
					setOpenModalInfoNF(false)
				}}
				handleClick={() => {
					setOpenModalInfoNF(false)
				}}
				height="88%">
				<>
					<InfoNF>
						<p>Unidade de distribuição </p>
						<span>Salto - SP </span>
					</InfoNF>
					<InfoNF>
						<p>Vendedor </p>
						<span> 00247421 - Arthur da Silva Luzia</span>
					</InfoNF>
					<InfoNF>
						<p>Cliente </p> <span> João da silva </span>
					</InfoNF>
					<InfoNF>
						<p> CPF/CNPJ do cliente</p>
						<span>111.333.598-73 </span>
					</InfoNF>
					<InfoNF>
						<p>Endereço do cliente </p>
						<span> Praca Jose Dias, 355</span>
					</InfoNF>
					<InfoNF>
						<p> Cidade do cliente</p> <span> Itu, SP </span>
					</InfoNF>
					<InfoNF>
						<p> Número do pedido</p> <span> 29589557</span>
					</InfoNF>
					<InfoNF>
						<p>Data do pedido </p> <span> 29/08/2023</span>
					</InfoNF>
					<InfoNF>
						<p>Data da entrega </p> <span> 30/08/2023</span>
					</InfoNF>
					<InfoNF>
						<p> Pagamento</p>
						<span> Pago online (crédito)</span>
					</InfoNF>
					<InfoNF>
						<p> Desconto</p> <span>R$ 30,48 </span>
					</InfoNF>
					<InfoNF>
						<p>Valor total </p> <span>R$ 455,74</span>
					</InfoNF>

					<DividerSimple />

					<SubtitleInfoNF>
						<p>Dados do Pedido</p>
					</SubtitleInfoNF>

					<InfoNF>
						<p>Número do pedido </p> <span>R$ 455,74</span>
					</InfoNF>
					<InfoNF>
						<p>Data do pedido</p> <span>R$ 455,74</span>
					</InfoNF>
					<InfoNF>
						<p>Data da entrega </p> <span>R$ 455,74</span>
					</InfoNF>
					<InfoNF>
						<p>Pagamento </p> <span>R$ 455,74</span>
					</InfoNF>
					<InfoNF>
						<p>Desconto </p> <span>R$ 455,74</span>
					</InfoNF>
					<InfoNF>
						<p>Valor total </p> <span>R$ 455,74</span>
					</InfoNF>

					<DividerSimple />

					<Table
						data={dataTableMockNF}
						columns={columnsNF}
						canPaginate={false}
					/>
				</>
			</LayoutModal>
		</>
	)
}

export default NotaFiscalModal
