export declare type LatLng = {
    lat: number;
    lng: number;
};

export interface IProps {
	address: string
	returnAddress: (address: string) => void
	returnLocation: ({ lat, lng }: LatLng) => void
	returnLoading: (isLoading: boolean) => void
}

export interface ICoords {
	latitude: number
	longitude: number
	heading: number | null
}


export interface IGeoCodeResponse {
	results: Result[]
	status: string
}

export interface Result {
	address_components: AddressComponent[]
	formatted_address: string
	geometry: Geometry
	place_id: string
	types: string[]
}

export interface AddressComponent {
	long_name: string
	short_name: string
	types: Type[]
}

export enum Type {
	AdministrativeAreaLevel1 = 'administrative_area_level_1',
	AdministrativeAreaLevel2 = 'administrative_area_level_2',
	AdministrativeAreaLevel4 = 'administrative_area_level_4',
	Country = 'country',
	Locality = 'locality',
	Political = 'political',
	PostalCode = 'postal_code',
	Route = 'route',
	StreetNumber = 'street_number',
}

export interface Geometry {
	location: Location
	location_type: string
	viewport: Bounds
	bounds?: Bounds
}

export interface Bounds {
	northeast: Location
	southwest: Location
}

export interface Location {
	lat: number
	lng: number
}

export enum Status {
	LOADING = 'LOADING',
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS'
}