import React from 'react'
import { ButtonWrapper } from './styles'
import { IButtonProps } from './types'
import { colors } from '../../shared/styles/theme'

import { paddingToString } from '../../shared/styles/functions'
import { colorThemeProvider } from '../../theme/themeProvider'

function ButtonPrimary(
	{
		children,
		className,
		primaryColor = colorThemeProvider.whiteColor,
		backgroundColor = colors.darkGrey,
		round = false,
		secondaryColor = colors.grey,
		fontSize = 16,
		fontFamily = 'sans-serif',
		padding = [16, 32, 16, 32],
		...rest
	}: IButtonProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString
	}

	return (
		<ButtonWrapper styles={mergedStyles} className={className}>
			<button {...rest}>{children}</button>
		</ButtonWrapper>
	)
}

export default ButtonPrimary
