import { getUserDetails, signIn } from '../../../modules/Auth/service'
import { Creators as TokenActions } from '../../../shared/store/ducks/token'
import { logout } from '../../../shared/services/axiosInstace'
import { UserTypeEnum } from '../../../shared/interfaces/customer'
import { sendToAllIn } from '../../../shared/utils/allin'
import { getCompanyParamsVerifyAndDispatch } from '../../../shared/services/customer.service'
import { CustomerRoutesEnum } from '../../../modules/Routes/customerRoutesEnum'
import { UnauthenticatedRoutesEnum } from '../../../modules/Routes/unauthenticatedRoutesEnum'
import { ILoginCustomer } from '../interfaces/login-customer.types'


export async function LoginCustomer({
	documentNumber,
	password,
	setIsLoading,
	history,
	setShowError,
	isChecked,
	dispatch
}: ILoginCustomer) {
	try {
		setIsLoading(true)

		const response = await signIn({
			documentNumber: documentNumber,
			password: password
		})

		if (response.hasError) {
			if (response.hasError.code === 'UserNotConfirmedException') {
				history.replace(
					UnauthenticatedRoutesEnum.ACTIVATE_REGISTRATION,
					{
						documentNumber: documentNumber
					}
				)
			} else if (response.hasError.code === 'NotAuthorizedException') {
				setShowError(true)
				return
			}
			return
		}

		const accessToken = response.signInUserSession.idToken.jwtToken
		dispatch(TokenActions.saveAccess(accessToken))

		const responseUser = await getUserDetails(documentNumber)

		if (isChecked) {
			const credentials = {
				docNumber: documentNumber,
				password: password,
				customerName: responseUser?.Name
			}

			localStorage.setItem('@loggedUserData', JSON.stringify(credentials))
		}

		if (!responseUser) {
			logout()
			return
		}

		if (responseUser.signUpStatus) {
			logout()

			history.replace(UnauthenticatedRoutesEnum.AWAITING_APPROVAL_SAC)
			return
		}

		const customerStore = {
			...responseUser,
			Type: UserTypeEnum.CUSTOMER
		}

		sendToAllIn()

		await getCompanyParamsVerifyAndDispatch({
			customer: customerStore
		})
		history.replace(CustomerRoutesEnum.PRODUCT_LIST)
	} catch (err) {
	} finally {
		setIsLoading(false)
	}
}
