import React, { useState } from 'react'

import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'

import InformationCupom from './components/information-coupon/information-coupon.component'
import RulesCoupon from './components/rules-coupon/rules-coupon.component'
import { ProductSelector } from './components/product-selector/product-selector.component'

import ButtonDefault from '../../../components/buttons/button/button-default'
import GoBack from '../../../components/go-back/go-back.component'
import { Stack } from '@mui/material'

import useCreateCoupon from './hooks/use-create-coupon'

import * as S from './create-coupon.styles'
import { ModalReviewCouponView } from './components/modal-review-coupon/modal-review-coupon.view'
import { Controller } from 'react-hook-form'
import FiltersByHieararchy from '../../../components/filters-by-hieararchy/filters-by-hieararchy.component'
import { getChallengeFilterParameters } from '../../../shared/services/pointProgramChallenges.service'
import Loading from '../../../components/Loading'
import { TProducts } from './interfaces/products.types'
import { verifyFields } from './utils/verify-fields/verify-fields'

function CreateCoupon() {
	const {
		reactHookFormProps,
		handleSubmit,
		productsOptions,
		isLoadingProducts,
		setHasFiltered,
		isLoadingCoupon,
		isEditCouponFormMode,
		couponById
	} = useCreateCoupon()

	const [isOpenModalCreateCoupon, setIsOpenModalCreateCoupon] =
		useState<boolean>(false)

	const productType = reactHookFormProps.watch('type')
	const isProductCoupon = productType === 'product'

	const [impactedPdvs, setImpactedPdvs] = useState<number>(0)
	const [canFilter, setCanFilter] = useState<boolean>(false)

	return (
		<>
			{isLoadingCoupon && couponById.ID === 0 && (
				<>
					<DefaultLayoutAdmin>
						<S.WrapperEmptyScreen>
							<Loading />
						</S.WrapperEmptyScreen>
					</DefaultLayoutAdmin>
				</>
			)}
			{!isLoadingCoupon && (
				<>
					<ModalReviewCouponView
						{...reactHookFormProps.watch()}
						handleToggleModal={() =>
							setIsOpenModalCreateCoupon(false)
						}
						isEditCouponFormMode={isEditCouponFormMode}
						isOpen={isOpenModalCreateCoupon}
						usersImpacted={
							!isEditCouponFormMode
								? impactedPdvs.toString()
								: couponById.amountOfCustomers || ''
						}
						// Chamada da requisição de criação de cupom
						handleClick={() => {}}
						getValues={reactHookFormProps.getValues}
						couponId={couponById.ID}
					/>
					<DefaultLayoutAdmin>
						<Stack direction="column" gap={4}>
							<GoBack to="/coupon/history-coupon" />
							{isEditCouponFormMode ? (
								<S.Title>Editar Cupom</S.Title>
							) : (
								<S.Title>Novo Cupom</S.Title>
							)}
							{!isEditCouponFormMode && (
								<Controller
									name="filters.filtersByHieararchy"
									render={({
										field: { onChange },
										fieldState: { error }
									}) => {
										return (
											<>
												<FiltersByHieararchy
													shouldListPdv={false}
													fetchListPDVsPush={false}
													onFilterChange={() => null}
													isEnabled={true}
													handleGetFilterValues={(
														filters
													) =>
														onChange({
															director:
																filters[
																	'DIRECTOR'
																],
															grc: filters['GOV'],
															cd: filters[
																'RESALE'
															],
															state: filters[
																'STATE'
															],
															city: filters[
																'CITY'
															],
															channel:
																filters[
																	'CHANNEL'
																]
														})
													}
													getFilterParameters={
														getChallengeFilterParameters
													}
													onLoad={() => {}}
													onLoadEnd={() => {}}
													canFilter={canFilter}
													setCanFilter={setCanFilter}
													impactedPdvsLength={
														impactedPdvs
													}
													onPushFilterChange={
														setImpactedPdvs
													}
													errorMessage={
														reactHookFormProps
															.errors.filters
															?.filtersByHieararchy
															?.message
													}
													setListImpactedPdvs={(
														value
													) =>
														reactHookFormProps.setValue(
															'filters.listPdvs',
															value
														)
													}
													listImpactedPdvs={reactHookFormProps.watch(
														'filters.listPdvs'
													)}
													setFilterType={(value) =>
														reactHookFormProps.setValue(
															'filters.filterType',
															value
														)
													}
												/>
											</>
										)
									}}
									control={reactHookFormProps.control}
								/>
							)}

							<S.CreateCouponForm>
								<InformationCupom
									{...reactHookFormProps}
									isEditCouponFormMode={isEditCouponFormMode}
								/>
								<RulesCoupon
									{...reactHookFormProps}
									isProductCoupon={isProductCoupon}
									setHasFiltered={() => setHasFiltered(true)}
									isEditCouponFormMode={isEditCouponFormMode}
								/>
								{isProductCoupon && (
									<Controller
										control={reactHookFormProps.control}
										name="productsList"
										render={({
											field: { onChange },
											fieldState: { error }
										}) => (
											<ProductSelector
												isDisabled={
													isEditCouponFormMode
												}
												initialSelectedValues={
													couponById?.productsList
												}
												selectedProducts={(
													products
												) => {
													onChange(products)
												}}
												disposabledProducts={
													isEditCouponFormMode
														? {
																data: couponById.productsList as TProducts[],
																size: couponById
																	.productsList
																	.length
														  }
														: productsOptions
												}
												isLoading={isLoadingProducts}
												key={productsOptions.data
													.map(
														(product) =>
															product.label
													)
													.join('')}
												errorMessage={error?.message}
											/>
										)}
									/>
								)}
								<S.WrapperButtonSendApproval>
									<Stack flexDirection="column">
										<div className="wrapper-button">
											<ButtonDefault
												buttonText="ENVIAR PARA APROVAÇÃO"
												idElement="createCuopon"
												onClick={handleSubmit(
													(form) => {
														verifyFields({
															form,
															reactHookFormProps,
															setIsOpenModalCreateCoupon
														})
													}
												)}
												typeButton="button"
												fontSize="10px"
											/>
										</div>
									</Stack>
								</S.WrapperButtonSendApproval>
							</S.CreateCouponForm>
						</Stack>
					</DefaultLayoutAdmin>
				</>
			)}
		</>
	)
}

export default CreateCoupon
