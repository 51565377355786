import React from 'react'
import Actionsbuttons from './components/actions-buttons.component'
import { IQuatityButtons } from './quantity-buttons.types'
import { WrapperQuantityButtons } from './quantity-buttons.styles'

function QuantityButtons({
	handleDecrement,
	handleIncrement,
	value,
	disabledIncrementButton
}: IQuatityButtons) {
	return (
		<WrapperQuantityButtons>
			<Actionsbuttons
				type="decress"
				handleClick={handleDecrement}
				isDisabled={value === 0 ? true : false}
			/>
			<p>{value}</p>
			<Actionsbuttons
				type="increment"
				handleClick={handleIncrement}
				isDisabled={disabledIncrementButton}
			/>
		</WrapperQuantityButtons>
	)
}

export default QuantityButtons
