import styled from 'styled-components'
import { getDropdownButtonLabelOverride } from '../../../../shared/utils/multiSelectCheckboxUtils'
import {
	colors,
	primaryMultiselectStyles,
	reactSelectStylesProgramPointsRulesAndChallenges
} from '../../../../shared/styles/theme'

export const WrapperContainer = styled.div`
	background-color: transparent;
	border-bottom: 1px solid
		${(props) => props.theme.palette.extras.stroke.main};
	padding-bottom: ${(props) => props.theme.spacingOptions.MD}px;
	margin-bottom: ${(props) => props.theme.spacingOptions.XSM}px;
`
export const PageTitle = styled.h1`
	color: ${(props) => props.theme.palette.primaryWhite.contrastText};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	margin-bottom: ${(props) => props.theme.spacingOptions.LG}px;
`
export const PageSubTitle = styled.h2`
	color: ${(props) => props.theme.palette.primaryWhite.contrastText};
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	margin-bottom: ${(props) => props.theme.spacingOptions.MD}px;
`
export const PageDescription = styled.div`
	margin-bottom: ${(props) => props.theme.spacingOptions.XLG}px;
`
export const FilterContainer = styled.div`
	.selector-label {
		position: relative;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: bold;
		margin-left: ${(props) => props.theme.spacingOptions.SM}px;
		margin-top: ${(props) => props.theme.spacingOptions.XSM}px;
		margin-bottom: ${(props) => props.theme.spacingOptions.XXSM}px;
		text-transform: uppercase;
		z-index: 1;

		color: ${(props) => props.theme.palette.primary.main};
	}
	width: 26%;
	svg {
		color: ${(props) => props.theme.palette.primary.main};
	}
	.multiselect-label {
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const defaultSelectProps = {
	getDropdownButtonLabel: getDropdownButtonLabelOverride,
	styles: {
		...reactSelectStylesProgramPointsRulesAndChallenges,
		...{
			control: (styles: any) => ({
				...styles,
				boxShadow: 'none',
				':hover': {
					border: `1px solid ${colors.fourthGrey}`
				},
				':focus': {
					border: `1px solid ${colors.fourthGrey}`
				}
			}),
			dropdownButton: (styles: any, state: any) => ({
				...styles,
				width: '100%',
				height: 55,
				padding: 16,
				border: `1px solid ${colors.fourthGrey}`,
				borderRadius: ' 4px',
				boxShadow: 'none',
				background: 'white'
			})
		}
	},
	...primaryMultiselectStyles
}
