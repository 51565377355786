import { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { differenceInYears, parse } from 'date-fns'

import {
	getStatusPersonalOrCompanyFiscal,
	registerUser,
	userExists
} from './service'
import {
	ISendDocumentsPF,
	ISendDocumentsPJ,
	IViewError,
	IViewProps,
	ViewStateEnum
} from './types'
import NewAccount from './view'
import { checkUserState } from './util'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { ConfirmationMethods } from '../../components/AccountActivationSessionSecondary/types'
import { INewClient } from '../NewClientForm/types'
import cogoToast from 'cogo-toast'
import { formatNewClient } from '../../shared/utils/customer'
import { checkCNPJ, checkCPF } from '../../shared/utils/validators'
import { cleanDocumentNumber } from '../../shared/utils/string'
import {
	formatBirthDate,
	maskDate,
	validatorDate
} from '../../shared/utils/date'
import { signUp } from '../GPClientForm/service'
import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'
import {
	INITIAL_STATE,
	Creators as CustomerActions
} from '../../shared/store/ducks/customer'
import { UserTypeEnum } from '../../shared/interfaces/customer'
import { useUserAuthDataContext } from '../../context/user-auth-data'
import { registerSafeQuestionsAndAlternativeData } from '../../views/registration-update/services/service'

function NewAccountContainer() {
	const history = useHistory()
	const dispatch = useDispatch()
	const [viewState, setViewState] = useState<ViewStateEnum>('')
	const { userDoc, setUserDoc } = useUserAuthDataContext()

	const [documentIsValid, setDocumentIsValid] = useState(false)
	const [loading, setLoading] = useState(false)

	const [active, setActive] = useState(false)
	const [active2, setActive2] = useState(false)
	const [LGPDCheckbox, setLGPDCheckbox] = useState(false)
	const [SMSCheckbox, setSMSCheckbox] = useState(false)
	const [termsAdhesionCheckbox, setTermsAdhesionCheckbox] = useState(false)

	const [selectedConfirmationMethod, setSelectedConfirmationMethod] =
		useState<ConfirmationMethods>(null)

	const [sendDocumentsPF, setSendDocumentsPF] =
		useState<ISendDocumentsPF | null>(null)

	const [sendDocumentsPJ, setSendDocumentsPJ] =
		useState<ISendDocumentsPJ | null>(null)

	const [GPClientData, setGPClientData] = useState({
		salesOrganizationID: '',
		customerID: ''
	})

	const [goToStep, setGoToStep] = useState(false)

	const [birthDate, setBirthDate] = useState('')

	const [showFormForClientTypeOther, setShowFormForClientTypeOther] =
		useState(false)

	const [viewError, setViewError] = useState<IViewError | null>(null)

	function handleBirthDate(value: string) {
		setBirthDate(maskDate(value))
	}

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}
	function handleRedirectLogin() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}
	function handleRedirectRecoverPassword() {
		history.replace(UnauthenticatedRoutesEnum.RESET_PASSWORD)
	}

	const [newCustomer, setNewCustomer] = useState<INewClient | undefined>()

	function returnValues(client: INewClient, workingPeriod: string) {
		setGoToStep(true)
		setNewCustomer({
			...client,
			UserSignUpType: userDoc.length === 11 ? 'PF' : 'PJ'
		})
		handleDocuments(userDoc.length === 11, workingPeriod, 'WorkingPeriod')
	}

	function handlePassword(value: string) {
		setNewCustomer({ ...newCustomer, Password: value } as INewClient)
	}

	function handleIsOtimizacao(isOtimizacao: boolean) {
		setNewCustomer({
			...newCustomer,
			IsOtimizacaoCadastro: isOtimizacao
		} as INewClient)
	}

	function getValidatorSimConsulta() {
		if (viewState !== 'OTHER' || userDoc.length < 11) return
		;(async () => {
			try {
				setLoading(true)
				const cleanedDocumentNumber = cleanDocumentNumber(userDoc)
				if (cleanedDocumentNumber.length === 11) {
					if (!validatorDate(birthDate)) {
						setShowFormForClientTypeOther(false)
						return
					}
					const difference = differenceInYears(
						new Date(),
						parse(birthDate, 'dd/MM/yyyy', new Date())
					)

					if (difference < 18) {
						setShowFormForClientTypeOther(false)
						setViewError({
							type: 'birthDate',
							message: 'A idade informada é menor de 18 anos'
						})
						return
					} else {
						setViewError(null)
					}
				}

				const validator = await getStatusPersonalOrCompanyFiscal({
					documentNumber: cleanedDocumentNumber,
					birthDate
				})

				if (!validator) return
				if (validator.errorMessage) {
					setShowFormForClientTypeOther(false)
					setViewError({
						message: validator.errorMessage,
						type: 'cpf'
					})
					return
				}
				if (
					validator.status === 'ATIVA' ||
					validator.status === 'REGULAR'
				) {
					setShowFormForClientTypeOther(true)
				} else {
					setViewError({
						message:
							'O CPF INFORMADO NÃO É UM CPF VÁLIDO. POR FAVOR INFORME OUTRO CPF OU UM CNPJ VÁLIDO.',
						type: 'cpf'
					})
				}
			} catch (error: any) {
				console.error({ error })
			} finally {
				setLoading(false)
			}
		})()
	}

	const queryParamEnum: { [id: number]: string } = {
		11: 'personalFiscalID',
		14: 'companyFiscalID'
	}
	function handleDocuments(isCpf: boolean, value: string, id: string) {
		if (isCpf) {
			const doc = { ...sendDocumentsPF, [id]: value } as ISendDocumentsPF
			setSendDocumentsPF(doc)
		} else {
			const doc = { ...sendDocumentsPJ, [id]: value } as ISendDocumentsPJ
			setSendDocumentsPJ(doc)
		}
	}

	function handleLatAndLong(isCpf: boolean, lat: string, long: string) {
		if (isCpf) {
			setSendDocumentsPF({
				...(sendDocumentsPF as ISendDocumentsPF),
				Lat: lat,
				Long: long
			})
		} else {
			setSendDocumentsPJ({
				...(sendDocumentsPJ as ISendDocumentsPJ),
				Lat: lat,
				Long: long
			})
		}
	}

	function handleCreateClient() {
		if (!newCustomer) return
		;(async () => {
			try {
				setLoading(true)

				const date = birthDate ? formatBirthDate(birthDate) : undefined
				const formattedClient = formatNewClient({
					...newCustomer,
					CompanyFiscalID: userDoc.length > 11 ? userDoc : '',
					AcceptLGPDTerms: LGPDCheckbox ? 'X' : '',
					AcceptNotificationTerms: SMSCheckbox ? 'X' : '',
					AcceptTermsAdhesion: termsAdhesionCheckbox ? 'X' : '',
					ConfirmPassword: newCustomer.Password,
					typeOfEstablishment: newCustomer.typeOfEstablishment,
					tradeRepresentative: newCustomer.tradeRepresentative
				})

				const sendDocuments = sendDocumentsPF || sendDocumentsPJ
				const registerResponse = await registerUser(
					{
						...formattedClient,
						IsOtimizacaoCadastro: Boolean(
							newCustomer.IsOtimizacaoCadastro
						),
						BirthDate: date ? date : undefined
					},
					sendDocuments ? { ...sendDocuments } : undefined
				)
				try {
					await registerSafeQuestionsAndAlternativeData({
						alternativeEmail: newCustomer.alternativeEmail,
						alternativePhoneNumber: newCustomer.alternativeSms,
						document: userDoc,
						securityQuestions: [
							{
								answer: newCustomer.answerOne,
								questionId: newCustomer.questionOne?.value ?? ''
							},
							{
								answer: newCustomer.answerTwo,
								questionId: newCustomer.questionTwo?.value ?? ''
							}
						]
					})
				} catch {}
				// Atualização cadastral

				if (!registerResponse || registerResponse.status !== 201) {
					cogoToast.error(
						'Não foi possível completar seu registro, tente novamente mais tarde...'
					)
					return
				}

				if (
					viewState === 'OTHER' &&
					!newCustomer.IsOtimizacaoCadastro
				) {
					history.replace(
						UnauthenticatedRoutesEnum.AWAITING_APPROVAL_SAC
					)
					return
				}

				const formatedConfirmationCodeParams =
					formatConfirmationCodeMethodParams(
						selectedConfirmationMethod
					)

				const responseCognito = await signUp({
					documentNumber: cleanDocumentNumber(userDoc),
					email: newCustomer.Email,
					password: newCustomer?.Password || '',
					phoneNumber:
						'+55' +
						newCustomer.CellphoneNumber.replace(/[-/ ().]/g, ''),
					phoneTwo: newCustomer?.PhoneNumber
						? '+55' +
						  newCustomer.PhoneNumber.replace(/[-/ ().]/g, '')
						: '',
					sendemail: formatedConfirmationCodeParams.sendemail,
					sendsms: formatedConfirmationCodeParams.sendsms,
					invoiceNumber: ''
				})

				if (!responseCognito) {
					return
				}

				if (viewState === 'OTHER' && newCustomer.IsOtimizacaoCadastro) {
					dispatch(
						CustomerActions.updateCustomer({
							...INITIAL_STATE,
							CompanyFiscalID: newCustomer.CompanyFiscalID,
							PersonalFiscalID: newCustomer.PersonalFiscalID,
							Password: newCustomer.Password,
							Name: newCustomer.Name || '',
							NickName: newCustomer.NickName || '',
							Type: UserTypeEnum.CUSTOMER
							// isNewCustomer: true,
						})
					)
				}

				history.replace(
					UnauthenticatedRoutesEnum.CONFIRM_ACTIVATION_CODE,
					{
						documentNumber: userDoc,
						confirmationMethod: selectedConfirmationMethod
					}
				)
			} catch (error) {
			} finally {
				setLoading(false)
			}
		})()
	}

	function handleChangeDocumentField() {
		;(async () => {
			const isDocValid = checkCNPJ(userDoc) || checkCPF(userDoc)
			if (!isDocValid) return
			if (!checkCPF(userDoc) && userDoc.length === 11) {
				setViewError({
					message: 'O CPF Informado não é um CPF válido.',
					type: 'cpf'
				})
				return
			}

			setDocumentIsValid(isDocValid)

			const userDocClean = cleanDocumentNumber(userDoc)

			if (
				isDocValid &&
				userDoc.length &&
				!!queryParamEnum[userDocClean.length]
			) {
				setLoading(true)
				setViewError(null)
				setShowFormForClientTypeOther(false)

				const response = await userExists(userDocClean)

				if (!response || !response.length) return

				const viewState = checkUserState(response)

				if (viewState === 'ALREADY_REGISTERED_UNCONFIRMED') {
					history.replace(
						UnauthenticatedRoutesEnum.ACTIVATE_REGISTRATION,
						{
							userDocClean
						}
					)
				} else if (viewState === 'GP_CLIENT') {
					const registeredCustomerData = response.find(
						(item) => item.CustomerStatus === 'REGISTERED'
					)
					if (
						!registeredCustomerData?.customerID ||
						!registeredCustomerData?.salesOrganizationID
					)
						return
					setGPClientData({
						customerID: registeredCustomerData.customerID,
						salesOrganizationID:
							registeredCustomerData.salesOrganizationID
					})
				} else {
					setGPClientData({
						salesOrganizationID: '',
						customerID: ''
					})
				}

				setViewState(viewState)
				setLoading(false)
			} else {
				setViewState('')
				setLoading(false)
				setViewError(null)
			}
		})()
	}

	function handleLGPDCheckbox() {
		setLGPDCheckbox(!LGPDCheckbox)
	}
	function handleSMSCheckbox() {
		setSMSCheckbox(!SMSCheckbox)
	}
	function handleTermsAdhesionCheckbox() {
		setTermsAdhesionCheckbox(!termsAdhesionCheckbox)
	}
	function handleChangeConfirmationMethod(method: ConfirmationMethods) {
		setSelectedConfirmationMethod(method)
	}

	function skipStep() {
		setGoToStep(false)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleChangeDocumentField, [documentIsValid, userDoc])
	useEffect(getValidatorSimConsulta, [viewState, birthDate, userDoc])

	const viewProps: IViewProps = {
		userDoc,
		setUserDoc,
		handleBack,
		setDocumentIsValid: () => true,
		viewState,
		handleRedirectLogin,
		handleRedirectRecoverPassword,
		loading,
		active,
		active2,
		setActive,
		setActive2,
		GPClientData,
		handleDocuments,
		sendDocumentsPF,
		sendDocumentsPJ,
		handleLGPDCheckbox,
		handleSMSCheckbox,
		LGPDCheckbox,
		SMSCheckbox,
		selectedConfirmationMethod,
		handleChangeConfirmationMethod,
		goToStep,
		skipStep,
		newCustomer,
		returnValues,
		handleCreateClient,
		birthDate,
		handleBirthDate,
		showFormForClientTypeOther,
		viewError,
		handleLatAndLong,
		handleTermsAdhesionCheckbox,
		termsAdhesionCheckbox,
		handlePassword,
		handleIsOtimizacao
	}
	return createElement(NewAccount, viewProps)
}

export default NewAccountContainer
