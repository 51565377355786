import React from 'react'
import LayoutModal from '../../../../layout/layout-modal/layout-modal.layout'
import { ConfirmOrderModalProps } from './confirm-order-modal.interface'
import * as S from './confirm-oder-modal.styles'

function ConfirmOrderModal({
	handleToggleModal,
	isOpen,
	isProduct,
	orderOPtions,
	handleConfirmOrder
}: ConfirmOrderModalProps) {
	return (
		<LayoutModal
			buttonText="Confirmar alteração"
			height="80%"
			handleClick={handleConfirmOrder}
			handleToggleModal={handleToggleModal}
			isOpen={isOpen}
			modalTitle="Reordenar Categorias">
			<S.WrapperModalConfirmOrder>
				<h2>Salvar nova ordem de exibição para produtos</h2>
				<S.WrapperOptionsOder>
					{orderOPtions.map((e, index) => {
						return (
							<p key={index}>
								{index + 1}º{' '}
								{isProduct ? e.Description : e.description}
							</p>
						)
					})}
				</S.WrapperOptionsOder>
			</S.WrapperModalConfirmOrder>
		</LayoutModal>
	)
}

export default ConfirmOrderModal
