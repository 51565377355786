import React from 'react'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { validateImageExtension } from '../../shared/utils/image'
import { toMoney } from '../../shared/utils/money'
import { isPropEqual } from '../../shared/utils/object'
import { colorThemeProvider } from '../../theme/themeProvider'
import CartQuantityBadge from '../CartQuantityBadge'
import TitleTypeOfProduct from '../TitleTypeOfProduct'
import { IComboCardProps } from './types'
import ReactGA from 'react-ga'

function ComboCard({ data, modal = false, openLightBox }: IComboCardProps) {
	const { carts } = useTypedSelector(['carts'])
	const items = carts.map((cart) => cart.items).flat()

	const storeItem = items.find(isPropEqual('promotionID')(data))
	const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0

	const promotionItems = data?.TradePromotionItens?.results
	const minAmount = Number(promotionItems?.map((item) => item.minAmount))
	const price = Number(promotionItems?.map((item) => item.price))
	const promotionType = data.promotionType
	const numeratorConversion = Number(
		promotionItems?.map((item) => item.numeratorConversion)
	)

	return (
		<div
			className={`combo-card-container ${
				!!storeQuantity && !modal ? 'this-combo-is-in-cart' : ''
			}`}>
			{storeQuantity && !modal ? (
				<div style={{ alignSelf: 'flex-end' }}>
					<CartQuantityBadge storeQuantity={storeQuantity} />
				</div>
			) : null}
			<div className="combo-card-wrapper">
				{promotionType === 2 && (
					<div className="offer-chip">
						<span>Oferta</span>
					</div>
				)}
				<div className="combo-card-wrapper-image">
					<img
						className="cart-icon"
						onClick={() => {
							openLightBox && openLightBox(true)
							ReactGA.event({
								category: 'combo',
								action: `${
									promotionType !== 2
										? `combo ${data.promotionID}`
										: `acao_preco ${data.promotionID}`
								}`,
								label: data.description
							})
						}}
						src={validateImageExtension(data.comboImageUrl)}
						alt="Foto do combo"
					/>
				</div>
				<TitleTypeOfProduct
					color={
						promotionType !== 2
							? colorThemeProvider.successColor
							: colorThemeProvider.secondaryColor
					}
					title={
						promotionType !== 2
							? 'COMBO DE PRODUTOS'
							: 'AÇÃO DE PREÇO'
					}
				/>
				<span className="combo-card-title">{data.description}</span>
				<div className="combo-card-price-action">
					<span className="combo-card-price-action-value-title">
						VALOR
					</span>
					<span className="combo-card-price-action-data">
						{promotionType !== 2 ? (
							toMoney(data.comboPriceTotal)
						) : (
							<>
								<div className="combo-offer-container">
									<span className="combo-price-action-subtitle">
										DE{' '}
									</span>
									<span className="combo-min-amount">
										{data.actionPriceUndiscountedSalesValue &&
											toMoney(
												data.actionPriceUndiscountedSalesValue
											)}
									</span>
								</div>
								<div
									className="combo-offer-container"
									style={{ marginTop: '5px' }}>
									<span className="combo-price-action-subtitle">
										POR ATÉ{' '}
									</span>
									<span className="combo-price-action">
										{data.actionPriceFinalSalesValue &&
											toMoney(
												data.actionPriceFinalSalesValue
											)}
									</span>
								</div>
							</>
						)}
					</span>
				</div>
				{promotionType === 2 && (
					<div className="combo-offer-container">
						<span className="combo-price-per-unit">
							PREÇO / UNIDADE
						</span>
						<div
							className="combo-offer-container"
							style={{ marginTop: '5px' }}>
							<span className="combo-price-action">
								{toMoney(price / numeratorConversion)}
							</span>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default ComboCard
