import React from 'react'

import {
	Checkbox,
	ListItemText,
	MenuItem,
	SelectChangeEvent
} from '@mui/material'
import {
	AllCheckboxItemProps,
	OptionItemProps,
	useMultipleSelectProps
} from './multiple-select.types'
import { StyledMenuItem, StyledSelectedValues } from './multiple-select.styles'
import Loading from '../Loading'

export default function useMultipleSelect({
	options,
	onChange,
	onAfterChange
}: useMultipleSelectProps) {
	const optionMap: Record<string, string> = options.reduce((acc, option) => {
		acc[option.value] = option.label
		return acc
	}, {} as Record<string, string>)

	const handleChange = (event: SelectChangeEvent<unknown>) => {
		const value = event.target.value
		if (typeof value === 'string') {
			onChange(value)
		} else if (Array.isArray(value) && value.includes('all')) {
			if (value.length - 1 === options.length) {
				onChange([])
			} else {
				onChange(options.map((item) => item.value))
			}
		} else {
			onChange(value)
		}
		onAfterChange?.(value)
	}

	const renderValue = (selected: unknown) => {
		if (Array.isArray(selected)) {
			if (selected.length === 1) {
				return (
					<StyledSelectedValues>
						{optionMap[selected[0]]}
					</StyledSelectedValues>
				)
			}

			return (
				<StyledSelectedValues>
					{selected.length} selecionados
				</StyledSelectedValues>
			)
		}
		return optionMap[selected as string]
	}

	const renderOptionItem = ({
		multiple,
		value,
		option,
		index
	}: OptionItemProps) => {
		return (
			<StyledMenuItem
				isEven={index % 2 === 0}
				key={String(option.value)}
				value={String(option.value)}>
				{multiple && (
					<Checkbox
						color="primary"
						checked={
							Array.isArray(value) &&
							value?.indexOf(option.value) > -1
						}
					/>
				)}

				<ListItemText primary={option.label} />
			</StyledMenuItem>
		)
	}

	const renderAllCheckboxItem = ({
		value,
		optionsLength
	}: AllCheckboxItemProps) => {
		return (
			<MenuItem value="all">
				<Checkbox
					color="primary"
					checked={
						optionsLength > 0 &&
						Array.isArray(value) &&
						value.length === optionsLength
					}
					indeterminate={
						Array.isArray(value) &&
						value.length > 0 &&
						value.length < optionsLength
					}
				/>
				<ListItemText primary="Todos" />
			</MenuItem>
		)
	}

	const renderStartAddorment = (
		isLoading: boolean,
		startAddorment?: React.ReactNode
	) => {
		if (isLoading) {
			return <Loading type="circular" size={18} />
		}

		return startAddorment ?? null
	}

	return {
		handleChange,
		renderValue,
		renderOptionItem,
		renderAllCheckboxItem,
		renderStartAddorment
	}
}
