import styled from 'styled-components'
import { Box } from '@mui/material'

export const HeaderLoading = styled(Box)`
	background-color: ${(props) => props.theme.palette.background.paper};
	height: 112px;
	width: 100vw;

	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 800px) {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1);

	padding: 0px 72px;

	.logo-wapper {
		display: flex;
		align-items: center;
		justify-content: center;

		gap: 50px;
	}
`

export const LoadingWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 500px;
`
