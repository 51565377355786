import React from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import Loading from '../../components/Loading'
import { isObjValidAndNotEmpty } from '../../shared/utils/object'
import { IInputProps, IOption, IViewProps } from './types'
import { InputField, SelectPrimary } from '../../shared/components'
import CheckboxActive from '../../assets/images/checkbox-fill-secondary.svg'
import AlertIcon from '../../assets/images/alert-circle-red.svg'
import CheckboxInactive from '../../assets/images/checkbox-blank-secondary.svg'
import { colors, primaryButtonStyle } from '../../shared/styles/theme'
import ButtonPrimary from '../../components/ButtonPrimary'
import { DataSelector } from '../../components/data-selector'
import { getQuestionsNotLogged } from '../../views/registration-update/services/service'
import { ISecurityQuestions } from '../../views/registration-update/interfaces/safe-questions.interface'
import { formateUtils } from '../../views/registration-update/util/format-questions'
import { estabilishmentOptions } from '../NewAccount/util'

function NewClientForm(props: IViewProps): JSX.Element {
	const [originalQuestions, setOriginalQuestions] = React.useState<
		{ label: string; value: string }[]
	>([{ label: '', value: '' }])

	const [questionsOne, setQuestionsOne] = React.useState<
		{ label: string; value: string }[]
	>([{ label: '', value: '' }])

	const [questionsTwo, setQuestionsTwo] = React.useState<
		{ label: string; value: string }[]
	>([])

	const {
		canProceed,
		cityOptions,
		documentType,
		filledAddress,
		handleRecaptcha,
		handleSelect,
		stateOptions,
		personalFields,
		storeFields,
		handleUpdateClient,
		loading,
		handleWorkingPeriod,
		workingPeriod,
		goToUploadDocuments,
		alternativePersonalInfoFields,
		showError,
		requiresGpVisit,
		selectedEstabilishmentOption,
		handleEstabilishmentOption
	} = props

	const renderInput = (
		{
			containerClass,
			value,
			keyName,
			disabled,
			label,
			...rest
		}: IInputProps,
		i: number
	) => {
		const isRegion = keyName === 'Region'
		const isCity = keyName === 'City'

		const isCityDisabled = !!(isCity && !cityOptions?.length && value)

		const isRegionDisabled = !!(isRegion && filledAddress && value)

		const isDisabled = isCityDisabled || isRegionDisabled

		const className = `${containerClass} ${isDisabled ? 'disabled' : ''}`

		const options = isRegion ? stateOptions : cityOptions

		const isObj = isObjValidAndNotEmpty(value)

		const valueToUse = isObj
			? (value as IOption).label
			: String(value || '')

		const disabledByDocumentNumber = documentType === label

		const isInputDisabled =
			!!((isRegion || isCity) && filledAddress && value) ||
			disabled ||
			disabledByDocumentNumber

		const isErrorSecondary =
			rest.id === 'email' ||
			rest.id === 'ConfirmEmail' ||
			rest.id === 'cpf' ||
			rest.id === 'rg'

		return (
			<div key={i} className={className}>
				{(isRegion || isCity) && (!filledAddress || !value) ? (
					<>
						<label
							className="multiselect-label"
							style={{ zIndex: 1 }}>
							{rest.placeholder}
						</label>
						<SelectPrimary
							options={options}
							onChange={handleSelect(keyName)}
							value={(value as IOption).label}
							disabled={isDisabled}
							placeholder={rest?.placeholder ?? ''}
							isClearable={false}
							classNamePrefix="react-select"
							className={`react-select select-group`}
						/>
					</>
				) : (
					<InputField
						onChange={handleUpdateClient(keyName)}
						value={valueToUse}
						label={label}
						showError={() =>
							value && isErrorSecondary
								? showError(!rest?.validator?.(value) || false)
								: undefined
						}
						hasError={
							value && rest?.validator
								? !rest.validator(value)
								: false
						}
						{...rest}
						disabled={isInputDisabled}
						Icon={
							value &&
							isErrorSecondary &&
							!rest?.validator?.(value)
								? AlertIcon
								: undefined
						}
					/>
				)}
			</div>
		)
	}

	function renderCheckboxIcon(
		method: 'morning' | 'afternoon' | 'nocturnal' | 'sim' | 'nao'
	) {
		return workingPeriod === method || requiresGpVisit === method ? (
			<img src={CheckboxActive} alt="checkd-item" />
		) : (
			<img src={CheckboxInactive} alt="uncheckd-item" />
		)
	}

	function renderOptions(
		method: 'morning' | 'afternoon' | 'nocturnal',
		text: string,
		border = true
	) {
		return (
			<>
				<button
					className="checkbox-button"
					onClick={() => handleWorkingPeriod(method)}>
					{renderCheckboxIcon(method)}
					<span>{text}</span>
				</button>
				{border && <div className="border" />}
			</>
		)
	}

	React.useEffect(() => {
		async function handleGetQuestions() {
			const questions = await getQuestionsNotLogged()
			const formatedArray = formateUtils(
				questions as ISecurityQuestions[]
			)
			setOriginalQuestions(formatedArray)
		}

		handleGetQuestions()
	}, [])

	React.useEffect(() => {
		if (
			!alternativePersonalInfoFields.questionTwo?.value ||
			!alternativePersonalInfoFields.questionOne?.value
		) {
			setQuestionsOne(originalQuestions)
			setQuestionsTwo(originalQuestions)
		}

		const filteredQuestionsOne = originalQuestions.filter(
			(question) =>
				question.value !==
				alternativePersonalInfoFields.questionTwo?.value
		)
		setQuestionsOne(filteredQuestionsOne)

		const filteredQuestionsTwo = originalQuestions.filter(
			(question) =>
				question.value !==
				alternativePersonalInfoFields.questionOne?.value
		)
		setQuestionsTwo(filteredQuestionsTwo)
	}, [
		originalQuestions,
		alternativePersonalInfoFields.questionOne?.value,
		alternativePersonalInfoFields.questionTwo?.value
	])

	return (
		<div className="new-client-page">
			<h1 className="title-main">Dados pessoais</h1>
			<div className="flex document">
				{personalFields.map(renderInput)}
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						gap: '20px'
					}}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							gap: '10px'
						}}>
						<DataSelector
							options={questionsOne}
							isDisabled={false}
							placeholder="PERGUNTA DE SEGURANÇA 1"
							selectedOption={
								alternativePersonalInfoFields.questionOne
							}
							onSelectionChange={(option) => {
								handleUpdateClient('questionOne')({
									target: {
										value: option
									}
								})
							}}
						/>
						<InputField
							className="param-input"
							id="description"
							label="Resposta da pergunta de segurança 1"
							onChange={handleUpdateClient('answerOne')}
							value={alternativePersonalInfoFields.answerOne}
							secondary
						/>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
							gap: '10px'
						}}>
						<DataSelector
							options={questionsTwo}
							isDisabled={false}
							placeholder="PERGUNTA DE SEGURANÇA 2"
							selectedOption={
								alternativePersonalInfoFields.questionTwo
							}
							onSelectionChange={(option) => {
								handleUpdateClient('questionTwo')({
									target: {
										value: option
									}
								})
							}}
						/>
						<InputField
							className="param-input"
							id="description"
							label="Resposta da pergunta de segurança 2"
							onChange={handleUpdateClient('answerTwo')}
							secondary
							value={alternativePersonalInfoFields.answerTwo}
						/>
					</div>
					<InputField
						className="param-input"
						id="description"
						label="Email Alternativo"
						onChange={handleUpdateClient('alternativeEmail')}
						secondary
						value={alternativePersonalInfoFields.alternativeEmail}
					/>

					<InputField
						className="param-input"
						id="description"
						label="Celular alternativo"
						onChange={handleUpdateClient('alternativeSms')}
						secondary
						value={alternativePersonalInfoFields.alternativeSms}
					/>
				</div>
			</div>
			<h1 className="title-main">
				Informações e Endereço do Estabelecimento
			</h1>
			<div className="flex document">{storeFields.map(renderInput)}</div>

			<div>
				<DataSelector
					options={estabilishmentOptions()}
					isDisabled={false}
					placeholder="SELECIONE O TIPO DE ESTABELECIMENTO"
					selectedOption={selectedEstabilishmentOption}
					onSelectionChange={(option) => {
						handleEstabilishmentOption(option)
					}}
				/>
			</div>

			<h1 className="title-main">Qual o período de funcionamento?</h1>

			{renderOptions('morning', 'Manhã / Tarde')}
			{renderOptions('afternoon', 'Tarde / Noite')}
			{renderOptions('nocturnal', 'Noturno', false)}

			<GoogleReCaptcha onVerify={handleRecaptcha} />

			<div className="loading-wrapper">
				{loading ? (
					<Loading color={colors.red} />
				) : (
					<ButtonPrimary
						{...primaryButtonStyle}
						onClick={goToUploadDocuments}
						disabled={
							!canProceed ||
							!workingPeriod ||
							!alternativePersonalInfoFields.alternativeEmail ||
							!alternativePersonalInfoFields.alternativeSms ||
							!alternativePersonalInfoFields.questionOne ||
							!alternativePersonalInfoFields.questionTwo ||
							!alternativePersonalInfoFields.answerOne ||
							!alternativePersonalInfoFields.answerTwo ||
							!selectedEstabilishmentOption
						}>
						ENVIAR INFORMAÇÕES
					</ButtonPrimary>
				)}
			</div>
		</div>
	)
}

export default NewClientForm
