import styled from 'styled-components'

export const PageWrapper = styled.main`
	.page-header-wrapper.sac {
		position: static;
	}
`

export const ListOfChallengesTitle = styled.h1`
	color: ${(props) => props.theme.palette.gray.main};
	margin-top: 64px;
	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-style: normal;
`

export const ChallengesPageWrapper = styled.section``

export const SearchInputAndButtonNewChallengeWrapper = styled.div`
	width: 100%;
	margin-top: 24px;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
`

export const LoadingWrapper = styled.section``

export const FiltersContainer = styled.div`
	display: flex;
	gap: 6px;
	align-items: center;
`

export const BannerInfoWrapper = styled.div`
	display: flex;
	align-items: center;
`

export const BannerChallengeTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	display: block;
	margin-left: 16px;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	max-width: 266px;
`

export const ImageBanner = styled.img`
	width: 62px;
	height: 35px;
	border-radius: 4px;
	margin-right: 16px;
`

export const Observation = styled.p`
	overflow: hidden;
	max-width: 64px;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-height: 16px;
	-webkit-box-orient: vertical;
`
