import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const Header = styled.div`
	padding-bottom: 10px;

	h1 {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.bold}rem;
		color: ${(props) => props.theme.palette.black.contrastText}rem;
	}
	h2 {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		margin: 20px 0px;
		color: ${colors.secondGrey};
	}
	h3 {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		margin: 10px 0px;
		color: ${colors.secondGrey};
	}
`

export const InputStyle = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	gap: 20px;
`

export const InfoModalEdit = styled.div<{
	isPurple?: boolean
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;

	padding: 30px 20px;
	margin: 20px 0px;

	width: 568px;
	height: 72px;

	background: ${(props) =>
		props.isPurple
			? props.theme.palette.purple.main
			: props.theme.palette.primaryWhite.main};

	border-radius: 5px;
	svg {
		color: ${(props) =>
			props.isPurple
				? props.theme.palette.error.main
				: props.theme.palette.gray.main};
	}
	p {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		line-height: 20px;
		color: ${(props) =>
			props.isPurple
				? props.theme.palette.error.main
				: colors.secondGrey};
	}
`
