import React, { useEffect, useState } from 'react'
import DefaultLayout from '../../layout/default/deafult-admin.layout'
import TitlePage from '../../components/title-page/title-page.component'
import AutoCompleteSelect from './components/auto-complete-select/auto-complete-select.component'
import { CombosWrapper, InputSearch } from './combos.styles'
import { Typography } from '@material-ui/core'
import { InputAdornment } from '@mui/material/'
import SearchIcon from '@mui/icons-material/Search'

import { TextField } from '@material-ui/core'

import ComboTable from './components/combo-table/combo-table.component'

import ModalTable from './components/modal-table/modal-table.component'
import { comboServices } from './services/combo.services'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ICdData } from './components/auto-complete-select/auto-complete-select.interface'
import { IComboData } from './combo.interface'
import Loading from '../../components/Loading'
import { colorThemeProvider } from '../../theme/themeProvider'
import EmptyTable from '../../components/empty-table/empty-table.component'

const Combos = () => {
	const [isLoading, setIsLoading] = useState(false)
	const { customer } = useTypedSelector(['customer'])

	const [openModal, setOpenModal] = useState(false)

	const [comboTableData, setComboTableData] = useState<IComboData[]>([])
	const [comboTableDataInput, setComboTableDataInput] = useState<any>([])
	const [cdData, setCdData] = useState<ICdData[]>([])
	const [selectedCD, setSelectCD] = useState<ICdData>({
		SalesOrganizationID: '',
		Name: '',
		LegacyID: '',
		Status: 0
	})
	const [selectedDataCombo, setSelectedDataCombo] = useState<any>({
		comboImageUrl: '',
		promotionID: '',
		description: '',
		startDate: '',
		endDate: '',
		sectorID: ''
	})

	useEffect(() => {
		async function handleGetCompanies() {
			setIsLoading(true)
			const data = await comboServices.getCompanies()
			setCdData(data)
			return data
		}

		handleGetCompanies()
	}, [customer])

	const memoizedHandleGetComboData = React.useCallback(handleGetComboData, [
		selectedCD.SalesOrganizationID
	])

	useEffect(() => {
		if (selectedCD !== null) {
			if (selectedCD.SalesOrganizationID.length > 0) {
				memoizedHandleGetComboData()
			}
		}
	}, [selectedCD, customer.CustomerID, memoizedHandleGetComboData])

	async function handleGetComboData() {
		if (selectedCD.SalesOrganizationID) {
			setIsLoading(true)
			// console.log(selectedCD)

			const data = await comboServices
				.getComboData(selectedCD.SalesOrganizationID)
				.then((resp) => resp)
				.finally(() => {
					setIsLoading(false)
				})

			// console.log(data)
			let unique = data.reduce(
				(prev: any[], cur: { ID: any }) => [
					...prev.filter((obj: { ID: any }) => obj.ID !== cur.ID),
					cur
				],
				[]
			)
			// console.log(unique)
			setComboTableData(unique)
			setComboTableDataInput(unique)
		}
	}

	useEffect(() => {
		memoizedHandleGetComboData()
	}, [memoizedHandleGetComboData])

	const handleFilterTable = (value: string) => {
		setComboTableData(
			comboTableDataInput?.filter(
				(item: IComboData) =>
					item.description === value || item.sectorID === value
			)
		)
	}

	const conditionShowTable =
		selectedCD === null ||
		selectedCD === undefined ||
		selectedCD?.Name === ''

	return (
		<DefaultLayout>
			<>
				<ModalTable
					open={openModal}
					handleClose={setOpenModal}
					modalData={selectedDataCombo}
					refreshTableComboData={handleGetComboData}
				/>
				<>
					<CombosWrapper>
						<div className="combo-header">
							<TitlePage titlePage="Combos por CD" />
							<AutoCompleteSelect
								optionsData={cdData}
								placeholder="Selecione ou digite o CD"
								setValue={setSelectCD}
								handleGetComboData={() => {}}
							/>
						</div>
						<div className="main-content">
							{conditionShowTable ? (
								<>
									<Typography variant="h2">CD</Typography>
									<Typography variant="subtitle1">
										Revenda #
									</Typography>
								</>
							) : (
								<>
									<Typography
										variant="h2"
										className="subtitle-name">
										{selectedCD?.Name}
									</Typography>
									<Typography
										variant="subtitle1"
										className="subtitle-code">
										Código {selectedCD?.LegacyID}
									</Typography>
								</>
							)}
							<InputSearch
								disableClearable
								options={comboTableDataInput?.map(
									(option: IComboData) => option.description
								)}
								// @ts-ignore
								onChange={(value: string) => {
									handleFilterTable(value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										disabled={conditionShowTable}
										placeholder="Buscar por Nome ou ID do Combo"
										InputLabelProps={{ shrink: true }}
										variant="outlined"
										InputProps={{
											...params.InputProps,
											type: 'search',
											endAdornment: (
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											)
										}}
									/>
								)}
							/>
							<div className="wrapper-table-content">
								{conditionShowTable ? (
									<EmptyTable />
								) : (
									<>
										{isLoading ? (
											<div className="loading-wrapper">
												<Loading
													color={
														colorThemeProvider.primaryColor
													}
												/>
											</div>
										) : (
											<ComboTable
												data={comboTableData}
												onClickEditButton={() =>
													setOpenModal(true)
												}
												setSelectedDataCombo={
													setSelectedDataCombo
												}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</CombosWrapper>
				</>
			</>
		</DefaultLayout>
	)
}
export default Combos
