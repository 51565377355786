import React from 'react'
import Loading from '../../components/Loading'
import { IViewProps } from './types'
import * as S from './styles'
import Payment from './Payment'
import DeliveryDate from './DeliveryDate'
import OrderSummaryDetails from './OrderSummaryDetails'
import PaymentNavigation from './PaymentNavigation'
import FinishOrderHeader from '../../components/FinishOrderHeader'

function OrderSummary(props: IViewProps): JSX.Element {
	const {
		isLoading,
		handleBack,
		handleChangePaymentCondition,
		handleFinalization,
		paymentInfo,
		dateToDelivery,
		handleSelectThree,
		totalPrice,
		paymentMethod,
		handleChangeCreditCard,
		creditCard,
		fieldsErros,
		enableButton,
		handlerChangeStep,
		handlerSelectPaymentMethod,
		paymentSteps,
		stepCurrent,
		payment,
		allCartsState,
		paymentMethodsAtDelivery
	} = props
	return (
		<div className="order-summary-page">
			<FinishOrderHeader />

			<PaymentNavigation
				handleBack={handleBack}
				paymentSteps={paymentSteps}
				stepCurrent={stepCurrent}
			/>

			{isLoading ? (
				<S.LoadingWrapper>
					<Loading />
				</S.LoadingWrapper>
			) : stepCurrent === 1 ? (
				<Payment
					onChange={handleSelectThree}
					value={paymentInfo}
					handleContinue={() => handlerChangeStep(2)}
					handleChangePaymentCondition={handleChangePaymentCondition}
					totalPrice={totalPrice}
					paymentStep={paymentSteps.step1}
					condition={paymentMethodsAtDelivery}
					handlerSelectPaymentMethod={handlerSelectPaymentMethod}
					handlerChangeStep={handlerChangeStep}
					buttons={paymentSteps.buttons}
					paymentMethod={paymentMethod}
					handleChangeCreditCard={handleChangeCreditCard}
					creditCard={creditCard}
					fieldsErros={fieldsErros}
					enableButton={enableButton}
				/>
			) : stepCurrent === 2 ? (
				<DeliveryDate
					onChange={handleSelectThree}
					value={dateToDelivery?.value || ''}
					paymentStep={paymentSteps.step2}
					handleGoBack={() => handlerChangeStep(1)}
					handleContinue={() => handlerChangeStep(3)}
				/>
			) : stepCurrent === 3 ? (
				<OrderSummaryDetails
					allCarts={allCartsState}
					paymentInfo={paymentInfo}
					confirmPayment={handleFinalization}
					goBackStepOne={() => handlerChangeStep(1)}
					paymentStep={paymentSteps.step3}
					payment={payment}
					creditCard={
						creditCard?.cardNumber ? creditCard.cardNumber : ''
					}
					dateToDelivery={dateToDelivery?.value || ''}
				/>
			) : null}
		</div>
	)
}

export default OrderSummary
