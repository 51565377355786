import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'
import Alert from '@mui/material/Alert'

export const EmailPhoneRegistrationView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`

export const Title = styled.h1`
	color: ${colorThemeProvider.blackColor};
	font-size: 1.5rem;
	font-weight: 700;
`

export const WrapperFields = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	flex-direction: column;
	padding: 24px;
	gap: 1rem;
`

export const ContactsFields = styled.div`
	display: flex;
	gap: 1rem;
`
export const Subtitle = styled.p`
	color: #3d3d3d;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
`
export const InfoFields = styled(Alert)`
	margin-top: '16px';
`
export const StyledAlert = styled(Alert)`
	/* Substitua pela cor desejada */
`

export const StyledRadioGroup = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin-top: 32px;

	gap: 48px;

	h2 {
		color: #3d3d3d;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
	}

	.wrapper-radio-recover-account {
		display: flex;
		margin-bottom: 40px;
		gap: 135px;
		width: 100%;
	}
`
