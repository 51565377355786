import { assoc, pipe, remove } from 'ramda'
import { Action } from 'redux'

import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ICartChallengeItemsActionCreators
} from '../../interfaces/action'

import { ICartItem } from '../../interfaces/cart'

import {
	ICartChallenge,
	IChallenge
} from '../../interfaces/pointsProgramChallenge'

import { getPrice } from '../../utils/cart'

export const {
	Types,
	Creators
}: IActionsFromCreators<ICartChallengeItemsActionCreators> = createActions({
	addChallengeItem: ['challenge', 'cartChallengeItem'],
	updateChallengeItem: ['cartChallenge', 'cartChallengeItem'],
	removeChallengeItem: ['cartChallenge', 'cartChallengeItem'],
	removeChallengeAndAllItems: ['cartChallenge'],
	addChallengeItemMixOfProducts: ['challenge', 'mixOfTheProducts'],
	clearChallengeItems: []
})

const INITIAL_STATE: ICartChallenge[] = []

export interface ICartChallengeItemAdd {
	challenge: IChallenge
	cartChallengeItem: ICartItem
}
export interface ICartChallengeItemMixOfProductsAdd
	extends Omit<ICartChallengeItemAdd, 'cartChallengeItem'> {
	challenge: IChallenge
	mixOfTheProducts: ICartItem[]
}

export interface ICartChallengeRemove
	extends Omit<ICartChallengeItemAdd, 'challenge'> {
	cartChallenge: ICartChallenge
}

const addChallengeItem = (
	state = INITIAL_STATE,
	action: ICartChallengeItemAdd
) => {
	const item = { ...action.cartChallengeItem }
	const challenge = { ...action.challenge }
	const cartChallengeItems = [...state]

	const cartItem: ICartChallenge = {
		...challenge,
		addedProducts: [item]
	}

	const cartItemIndex = cartChallengeItems.findIndex(
		(item) => item.challengeId === cartItem.challengeId
	)

	if (cartItemIndex === -1) {
		return [...state, cartItem]
	}

	const currentItemInCart = { ...cartChallengeItems[cartItemIndex] }

	const productAlreadyExistsIndex = currentItemInCart.addedProducts.findIndex(
		(product) => product.MaterialID === item.MaterialID
	)

	if (productAlreadyExistsIndex === -1) {
		currentItemInCart.addedProducts.push(item)
	} else {
		currentItemInCart.addedProducts[
			productAlreadyExistsIndex
		].OrderQuantity = item.OrderQuantity

		currentItemInCart.addedProducts[productAlreadyExistsIndex].Price =
			item.Price
	}

	const cartChallengeItemUpdated: ICartChallenge = {
		...currentItemInCart
	}

	cartChallengeItems.splice(cartItemIndex, 1, cartChallengeItemUpdated)

	return cartChallengeItems
}

const removeChallengeItem = (
	state = INITIAL_STATE,
	action: ICartChallengeRemove
) => {
	const cartChallengeItems = [...state]
	const item = { ...action.cartChallengeItem }
	const challenge = { ...action.cartChallenge }

	const cartItemIndex = cartChallengeItems.findIndex(
		(item) => item.challengeId === challenge.challengeId
	)

	if (cartItemIndex === -1) {
		return state
	} else {
		const currentItemInCart = { ...cartChallengeItems[cartItemIndex] }

		const productToBeRemovedIndex =
			currentItemInCart.addedProducts.findIndex(
				(product) => product.MaterialID === item.MaterialID
			)

		const updatedAddedProducts = remove(
			productToBeRemovedIndex,
			1,
			currentItemInCart.addedProducts
		)

		const updatedCartChallenge: ICartChallenge = {
			...currentItemInCart,
			addedProducts: updatedAddedProducts
		}

		if (!updatedCartChallenge.addedProducts.length) {
			const updatedCartChallengeItems = remove(
				cartItemIndex,
				1,
				cartChallengeItems
			)
			return updatedCartChallengeItems
		}

		cartChallengeItems.splice(cartItemIndex, 1, updatedCartChallenge)

		return cartChallengeItems
	}
}

const removeChallengeAndAllItems = (
	state = INITIAL_STATE,
	action: ICartChallengeRemove
) => {
	const cartChallengeItems = [...state]
	const challenge = { ...action.cartChallenge }

	const cartItemIndex = cartChallengeItems.findIndex(
		(challengeItem) => challenge.challengeId === challengeItem.challengeId
	)

	if (cartItemIndex === -1) {
		return state
	}

	const updatedCartChallengeItems = remove(
		cartItemIndex,
		1,
		cartChallengeItems
	)

	return updatedCartChallengeItems
}

export interface ICartChallengeItemUpdateAction {
	cartChallenge: ICartChallenge
	cartChallengeItem: ICartItem
}

const updateChallengeItem = (
	state = INITIAL_STATE,
	action: ICartChallengeItemUpdateAction
) => {
	const item = { ...action.cartChallengeItem }
	const challenge = { ...action.cartChallenge }
	const cartChallengeItems = [...state]

	const cartItemIndex = cartChallengeItems.findIndex(
		(item) => item.challengeId === challenge.challengeId
	)

	const currentItemInCart = { ...cartChallengeItems[cartItemIndex] }

	const indexOfTheProductToBeUpdated =
		currentItemInCart.addedProducts.findIndex(
			(product) => product.MaterialID === item.MaterialID
		)

	const newOrderQuantity = item.OrderQuantity
	const newPrice = getPrice(item.PriceByQuantity, item.OrderQuantity)
	const newOlderPrice = item.olderPrice

	const associateNewValues = pipe(
		assoc('Price', newPrice),
		assoc('OrderQuantity', newOrderQuantity),
		assoc('olderPrice', newOlderPrice)
	)

	const updatedProductInformation = associateNewValues(
		currentItemInCart.addedProducts[indexOfTheProductToBeUpdated]
	)

	currentItemInCart.addedProducts.splice(
		indexOfTheProductToBeUpdated,
		1,
		updatedProductInformation
	)

	const cartChallengeItemUpdated: ICartChallenge = {
		...currentItemInCart
	}

	cartChallengeItems.splice(cartItemIndex, 1, cartChallengeItemUpdated)

	return cartChallengeItems
}

const addChallengeItemMixOfProducts = (
	state = INITIAL_STATE,
	action: ICartChallengeItemMixOfProductsAdd
) => {
	const cartChallengeItems = [...state]
	const challenge = { ...action.challenge }
	const mixOfProducts = [...action.mixOfTheProducts]

	const newCartChallengeItem: ICartChallenge = {
		...challenge,
		addedProducts: mixOfProducts
	}
	cartChallengeItems.push(newCartChallengeItem)
	return cartChallengeItems
}

const clearChallengeItems = (_ = INITIAL_STATE, __: any): ICartChallenge[] =>
	INITIAL_STATE

export default createReducer<
	ICartChallenge[],
	Action<ICartChallengeItemsActionCreators>
>(INITIAL_STATE, {
	[Types.ADD_CHALLENGE_ITEM]: addChallengeItem,
	[Types.UPDATE_CHALLENGE_ITEM]: updateChallengeItem,
	[Types.REMOVE_CHALLENGE_ITEM]: removeChallengeItem,
	[Types.CLEAR_CHALLENGE_ITEMS]: clearChallengeItems,
	[Types.REMOVE_CHALLENGE_AND_ALL_ITEMS]: removeChallengeAndAllItems,
	[Types.ADD_CHALLENGE_ITEM_MIX_OF_PRODUCTS]: addChallengeItemMixOfProducts
})
