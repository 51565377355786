import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const Template = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 150px;
	margin-bottom: 150px;

	span {
		color: ${colors.secondGrey};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		text-align: center;
		width: 250px;
		margin-top: 16px;
	}
	.circle {
		background-color: ${colors.secondGrey};
		width: 50px;
		height: 50px;
	}
`
export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 56px;
	height: 56px;
	background-color: #ccc;
	border-radius: 50%;
`
