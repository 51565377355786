import React, {
	useContext,
	createContext,
	useState,
	Dispatch,
	SetStateAction
} from 'react'
import { IMaskedUserInfo } from '../views/login-customer/services/login.services'

interface RecoverPasswordContext {
	userMaskedData: IMaskedUserInfo
	handleSetUserMaskedData: Dispatch<SetStateAction<IMaskedUserInfo>>
}

const RecoverPasswordContextContext = createContext<RecoverPasswordContext>({
	userMaskedData: {
		email: '',
		cellphoneNumber: '',
		customerID: '',
		alternativePhone: '',
		alternativeEmail: '',
		questions: [
			{
				questionId: '',
				question: ''
			}
		]
	},
	handleSetUserMaskedData: () => {}
})

export const RecoverPasswordContextProvider: React.FC = ({ children }) => {
	const [userMaskedData, setUserMaskedData] = useState<IMaskedUserInfo>({
		email: '',
		cellphoneNumber: '',
		customerID: '',
		alternativePhone: '',
		alternativeEmail: '',
		questions: [
			{
				questionId: '',
				question: ''
			},
			{
				questionId: '',
				question: ''
			}
		]
	})

	const handleSetUserMaskedData: RecoverPasswordContext['handleSetUserMaskedData'] =
		(newData) => {
			setUserMaskedData((prevData) => ({
				...prevData,
				...newData
			}))
		}

	return (
		<RecoverPasswordContextContext.Provider
			value={{
				userMaskedData,
				handleSetUserMaskedData
			}}>
			{children}
		</RecoverPasswordContextContext.Provider>
	)
}

export const useRecoverPasswordContextContext = () => {
	const { userMaskedData, handleSetUserMaskedData } = useContext(
		RecoverPasswordContextContext
	)

	return {
		userMaskedData,
		handleSetUserMaskedData
	}
}
