import styled from 'styled-components'

export const StyledComboFlex = styled.div`
	display: flex;
	padding: 0px ${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.SM}px;
	flex-direction: column;
	height: 250px;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	border-radius: 5px;
	border: 1px solid ${(props) => props.theme.palette.borderContrast.main};
	background: ${(props) => props.theme.palette.white.main};

	&:hover {
		cursor: pointer;
	}
`
export const StyledDescriptionComboFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100%;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;
	border-bottom: 1px solid
		${(props) => props.theme.palette.borderContrast.main};
	padding-bottom: ${(props) => props.theme.spacingOptions.SM}px;

	img {
		width: 94px;
		height: 94px;
		object-fit: cover;
	}

	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		text-align: center;
	}
`

export const StyledFooterComboFlex = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	justify-content: space-between;
	width: 100%;

	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`
export const ComboFlexHeader = styled.div`
	align-self: flex-end;
`
