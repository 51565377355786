import { createElement, useState } from 'react'
import Welcome from './view'
import { IViewProps } from './types'
import { logout } from '../../shared/services/axiosInstace'
import { getCompanyParamsVerifyAndDispatch } from '../../shared/services/customer.service'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { getUserDetails } from '../Auth/service'

function WelcomeContainer(): JSX.Element {
	const { customer } = useTypedSelector(['customer'])
	const [loading, setLoading] = useState(false)

	function refreshGetUser() {
		;(async () => {
			try {
				setLoading(true)

				const responseCustomer = await getUserDetails(
					customer.PersonalFiscalID || customer.CompanyFiscalID
				)

				if (!responseCustomer) return

				if (responseCustomer.signUpStatus) {
					logout()
					return
				}

				await getCompanyParamsVerifyAndDispatch({
					customer: responseCustomer
				})
			} catch (error) {
				console.error({ error })
			} finally {
				setLoading(false)
			}
		})()
	}

	const viewProps: IViewProps = {
		loading,
		refreshGetUser
	}

	return createElement(Welcome, viewProps)
}

export default WelcomeContainer
