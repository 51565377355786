import { F } from 'ts-toolbelt'

/**
 * curry receives a function of n arguments and return 
 * functions that receive the arguments partially
 * @param func function to be curried, cant have rest arguments
	@example
	const add3 = (a, b, c) => a + b + c

	const res = curry(add3)(1)(2)(3) // 6

	const res2 = curry(add3)(2,3)()(1) // 6
 */
// The proper type of this function (F.Curry) fries CPU
export function curry<T extends F.Function>(func: T): any {
	const { length } = func

	if (length === 0) return func

	return function curried(...args: any[]) {
		return args.length >= length
			? func(...args)
			: (...args2: any[]) => curried(...args.concat(args2))
	}
}

/**
 * Pipe receives any number of functions and returns a
 * function that will receive a value and pass this value through
 * all the functions
 * @param fns functions to pipe
 * @example 
 * const add2 = (x) => x + 2

	const double = (x) => x * 2

	const res = pipe(add2, double)(2) // 8

	const res2 = pipe(double, add2)(2) // 6
 */
export const pipe = function <Fns extends Array<F.Function>>(
	...fns: Fns
): F.Piped<Fns> {
	return function (value) {
		return fns.reduce(
			(currentVal, currentFn) => currentFn(currentVal),
			value
		)
	}
}

export const pipeableArrayMethod = <R = undefined>(method: any) => <
	C extends F.Function
>(
	callback: C
) => <T>(arr: Array<T> = []): R extends undefined ? Array<T> : R => {
	return method.call(arr, callback)
}

export const pipeableMap = pipeableArrayMethod(Array.prototype.map)

export const pipeableFilter = pipeableArrayMethod(Array.prototype.filter)

export const pipeableSome = pipeableArrayMethod<boolean>(Array.prototype.some)
