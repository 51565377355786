import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { parseItemPrice } from '../../shared/utils/money'
import { calcTotalPoints } from '../../shared/utils/points'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'

import * as S from './styles'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'

const CartSummary: React.FC = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const [cartProducts, setCartProducts] = useState<ICartItem[]>([])

	const { carts, cartRedemptionItems, cartChallengeItems } = useTypedSelector(
		['carts', 'cartRedemptionItems', 'cartChallengeItems']
	)

	const [cart, setCart] = useState({
		hasProducts: false,
		totalProducts: 0,
		totalPriceLabel: 'R$ 0,00',
		earnsPoints: false,
		totalPoints: 0
	})

	const items = carts.map((item) => item.items).flat()
	const itemsChallenge = cartChallengeItems
		.map((cartChallengeItem) => cartChallengeItem.addedProducts)
		.flat()

	function ghandleGoToCart() {
		dispatch(BottomBarActions.hideBar())
		history.push(CustomerRoutesEnum.CART)
	}

	function calcCartValues() {
		const totalProducts = cartProducts.length + itemsChallenge.length

		const cartProductsTotalPrice = cartProducts
			.filter((item) => item.typeItem !== ICartItemTypeEnum.REDEMPTION)
			.reduce((acc, item) => acc + item.Price, 0)

		const cartChallengeItemsTotalPrice = itemsChallenge.reduce(
			(acc, item) => acc + item.Price,
			0
		)

		const totalPrice = cartProductsTotalPrice + cartChallengeItemsTotalPrice

		const totalPriceLabel = parseItemPrice(Number(totalPrice))

		const totalPoints = cartProducts
			.filter((item) => item.typeItem === ICartItemTypeEnum.NORMAL)
			.reduce(
				(acc, item) =>
					item.ScoreValue
						? acc + calcTotalPoints(item.Price, item.ScoreValue)
						: acc,
				0
			)

		setCart({
			hasProducts: !!totalProducts,
			totalProducts: totalProducts,
			totalPriceLabel,
			earnsPoints: !!totalPoints,
			totalPoints: totalPoints
		})
	}

	useEffect(
		() => setCartProducts([...items, ...cartRedemptionItems]),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[carts, cartRedemptionItems]
	)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(calcCartValues, [cartProducts, cartChallengeItems])

	return (
		<S.Container
			className="reset-button-style"
			disabled={!cart.hasProducts}
			onClick={ghandleGoToCart}>
			{cart.hasProducts ? (
				<>
					<S.TotalProductsBadge>
						{cart.totalProducts} PRODUTO
						{cart.totalProducts > 1 ? 'S' : ''}
					</S.TotalProductsBadge>
					<S.TotalValuesWrapper>
						<S.TotalPrice>
							TOTAL: {cart.totalPriceLabel}
						</S.TotalPrice>
						{cart.earnsPoints && (
							<S.TotalPoints>
								+{cart.totalPoints} PONTOS
							</S.TotalPoints>
						)}
					</S.TotalValuesWrapper>
				</>
			) : (
				<S.TotalProductsBadge cartIsEmpty>0</S.TotalProductsBadge>
			)}
			<S.CartIcon />
		</S.Container>
	)
}

export default CartSummary
