import React, { useMemo } from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import MidiaContainer from '../../midia-container/midia-container.component'
import { ConfirmBannerModalStyles } from './confirm-banner-modal.styles'
import { ConfirmBannerModalProps } from './confirm-banner-modal.types'

import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BannersDataContainer from '../banners-data-container/banners-data-container.component'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../shared/utils/toaster'
import {
	checkBannerRelationInsertions,
	countAllFilters,
	createBanner
} from '../service'
import { ActionType } from '../../../banners-management.types'

function ConfirmBannerModal({
	isOPen,
	handleCloseModal,
	dataBanner,
	productsForDisplay,
	handleCloseModalClearFields,
	onSaveBanner,
	customersAmount
}: ConfirmBannerModalProps) {
	const actions = useMemo(
		() =>
			({
				OPEN_PRODUCT: 'Abrir um Produto Específico',
				OPEN_CATEGORY: 'Abrir Categoria de Produto',
				OPEN_COMBOS: 'Abrir o Módulo de Combos',
				OPEN_CHALLENGES: 'Abrir o Módulo de Desafios',
				OPEN_VIDEO: 'Abrir Vídeo',
				OPEN_PROGRAM_POINTS: 'Abrir o Módulo de Pontos'
			} as { [key in ActionType]: string }),
		[]
	)
	const [productName, setProductName] = React.useState<string>('')
	const [categoryName, setCategoryName] = React.useState<string>('')
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (
			actions[dataBanner.actionType as ActionType] ===
			actions.OPEN_PRODUCT
		) {
			const product = productsForDisplay.find(
				(product) => product.value === dataBanner.selectedProduct
			)
			setProductName(product?.name || '')
			setCategoryName('')
		} else if (
			actions[dataBanner.actionType as ActionType] ===
			actions.OPEN_CATEGORY
		) {
			const category = productsForDisplay.find(
				(product) => product.value === dataBanner.selectedCategory
			)
			setCategoryName(category?.name || '')
			setProductName('')
		} else {
			setCategoryName('')
			setProductName('')
		}
	}, [productsForDisplay, dataBanner, actions])

	async function confirm() {
		setIsLoading(true)
		try {
			await createBanner(dataBanner, customersAmount)
			cogoToast.success('Banner criado com sucesso.', cogoDefaultOptions)
			onSaveBanner()
		} catch (error) {
			cogoToast.error('Erro ao criar banner.', cogoDefaultOptions)
		} finally {
			handleCloseModalClearFields()
			handleCloseModal()
			setIsLoading(false)
		}
	}

	return (
		<>
			{isOPen && (
				<LayoutModal
					handleClick={() => confirm()}
					isOpen={isOPen}
					modalTitle="Cadastrar Banner"
					buttonText="Confirmar cadastro"
					handleToggleModal={handleCloseModal}
					height="88%"
					isLoading={isLoading}>
					<ConfirmBannerModalStyles>
						<MidiaContainer
							deviceSelected={{
								value: dataBanner.platform,
								name: dataBanner.platform
							}}
							srcImage={dataBanner.imageURL}
							srcVideo={dataBanner.videoURL}
							showDeviceSelect={false}
							canDeleteVideo={false}
							hasSelectedVideo={false}
							showVideo={dataBanner.videoURL ? true : false}
							isDisabledImage={dataBanner.imageURL ? false : true}
						/>
						<BannersDataContainer
							apliedTitle={dataBanner.title}
							destinyLink={dataBanner.targetPage}
							exibitionPeriod={`De ${dataBanner.initialDate} até ${dataBanner.finalDate}`}
							exibitionOrder={String(dataBanner.targetOrder)}
							actionType={
								actions[dataBanner.actionType as ActionType]
							}
							selectedProduct={productName}
							selectedCategory={categoryName}
						/>

						<ImpactedClients
							usersImpacted={String(customersAmount)}
						/>

						<div className="info-modal">
							<InfoOutlinedIcon />
							<p>
								Alteração somente para os clientes do segmento
								selecionado
							</p>
						</div>
						<div className="info-modal">
							<InfoOutlinedIcon />
							<p>
								Nota: Por favor ao confirmar aguarde ser
								redirecionado automaticamente para a tela
								inicial, o processo de inserção pode levar algum
								tempo, se demorar mais de 5 minutos por favor
								contate o suporte.
							</p>
						</div>
					</ConfirmBannerModalStyles>
				</LayoutModal>
			)}
		</>
	)
}

export default ConfirmBannerModal
