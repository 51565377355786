import {
	axiosGPInstance,
	axiosGPBackend
} from '../../../shared/services/axiosInstace'
import { IResaleItem } from '../../ResaleList/types'
import { IProcessStatus } from './types'

export async function fetchCompanies(): Promise<IResaleItem[]> {
	try {
		const response = await axiosGPInstance.get(`/companies`)

		return response.data
	} catch (error) {
		return []
	}
}

export async function getProcessStatus(): Promise<IProcessStatus[]> {
	try {
		const response = await axiosGPInstance.get(
			`/signup-tracking/customer/MassProcessLog`
		) // criar rota no odata

		return response.data
	} catch (error) {
		return []
	}
}

export async function deleteAllclients(formData: FormData, resaleID: string) {
	try {
		await axiosGPBackend.post(
			`v1/signup-tracking/${resaleID}?salesOrganizationID=${resaleID}`,
			formData
		)
	} catch (error) {
		console.error('Erro:', error)

		throw new Error()
	}
}
