import React from 'react'

import { IChallengeProgressBarProps } from './types'

import * as S from './styles'

const calcPercentage = ({
	quantityReached,
	totalQuantity
}: IChallengeProgressBarProps) => {
	const amountReached =
		quantityReached >= totalQuantity ? totalQuantity : quantityReached
	return (amountReached * 100) / totalQuantity
}

export default function ChallengeProgressBar(
	props: IChallengeProgressBarProps
) {
	return (
		<S.Wrapper>
			<S.ProgressBarWrapper>
				<S.Bar progress={calcPercentage(props)} />
			</S.ProgressBarWrapper>
			<S.Label isCompleted={props.isCompleted}>
				{props.quantityReached} de {props.totalQuantity}
			</S.Label>
		</S.Wrapper>
	)
}
