import styled, { css } from 'styled-components'

export const WrapperSectionTitle = styled.h2`
	text-align: left;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	padding: 0 0 8px 0;
	border-bottom: 1px solid ${(props) => props.theme.palette.gray.light};
`

export const WrapperCheckbox = styled.div`
	width: 100%;
	padding: 16px;
`

export const SelectFilter = styled('div')(
	({ theme }) => css`
    width: 100%;
	  position: relative;
	  background-color: ${theme.palette.primaryWhite.main};

    div div {
      width: 100%;

      *.css-8atqhb {
        display: flex;
        flex-direction: row;
      }

      *.css-1hwfws3 {
        width: calc(90% - 24px);
        margin: 0;
        padding: 0;
      }

      *.css-151xaom-placeholder {
        font-size: 14px;
      }

      *.css-1wy0on6 {
        width: 24px;
      }
    `
)

export const MultiselectLabel = styled('label')(
	({ theme }) => css`
		font-size: 10px;
		font-weight: bold;
		position: absolute;
		margin-left: 16px;
		margin-top: 15px;
		color: ${theme.palette.primaryWhite.main};
		text-transform: uppercase;
		z-index: 1;
	`
)
