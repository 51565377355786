import { apiCustomer, apiAdmin } from '../../../../shared/services/axiosInstace'
import { getAmbienteName } from '../../../../utils/getApiBaseUrl.utils'
import { IBannerEditRequest } from '../../types'
import { INewBanner } from '../../../../shared/interfaces/banner'
import { awaitAllInsertions } from '../../../../shared/utils/asyncInsert'
import {
	getCustomersAmountFunc,
	getFilterHierachyAmountFunc
} from '../../../../shared/services/filterRelations.service'
import { IFiltersValues } from '../../../../components/Filters/filters.component.types'

const apiBanner = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

interface ICancelProps {
	ID: string
	justification: string
}
type FilterOptions = Omit<IFiltersValues, 'codePdv'>

export function countAllFilters(filterOptions: FilterOptions) {
	let filterSumCombination = 1
	for (const filterKey in filterOptions) {
		const filter = filterOptions[filterKey as keyof FilterOptions]
		filterSumCombination *= filter.length || 1
	}
	return filterSumCombination
}

export async function createBanner(
	banner: INewBanner,
	amountOfCustomers: number
) {
	try {
		const response = await apiBanner.post(`/banner/create`, {
			...banner,
			amountOfCustomers
		})
		return response.data
	} catch (error) {
		throw error
	}
}

export async function checkBannerRelationInsertions(
	originID: string,
	insertedFiltersHierarchy: number,
	insertedCustomers: number,
	isPdvRelated: boolean
) {
	const filtersToAwait = []
	const bannerOrigin = 'BANNER'
	const getFilterQuantity = getFilterHierachyAmountFunc(bannerOrigin)
	filtersToAwait.push(
		awaitAllInsertions(
			originID,
			insertedFiltersHierarchy,
			getFilterQuantity,
			'Tempo de espera excedido, falha ao inserir dados tente novamente',
			'sucesso'
		)
	)
	if (isPdvRelated) {
		const getCustommerQuantity = getCustomersAmountFunc(bannerOrigin)
		filtersToAwait.push(
			awaitAllInsertions(
				originID,
				insertedCustomers,
				getCustommerQuantity,
				'Tempo de espera excedido, falha ao inserir dados tente novamente',
				'sucesso'
			)
		)
	}
	await Promise.all(filtersToAwait)
}

export async function getBannerRelationsAmount(ID: string) {
	try {
		const response = await apiBanner.get(`/banner/${ID}/realations-amount`)
		return response
	} catch (error) {
		return error
	}
}

export async function cancelBanner({ ID, justification }: ICancelProps) {
	const response = await apiBanner.patch(`/banner/${ID}/cancel`, {
		justification
	})
	return response
}

export async function editBanner(ID: string, payload: IBannerEditRequest) {
	try {
		const response = await apiBanner.patch(`/banner/${ID}`, { ...payload })
		return response
	} catch (err) {
		const error = err as any
		return error
	}
}
