import styled from 'styled-components'

export const ChallengeCartItemBlockOfProductsWrapper = styled.section`
	width: 100%;
	padding: 24px 16px 32px 16px;
	border-radius: 4px;
	background-color: ${(props) => props.theme.palette.white.main};
`

export const ChallengeCartItemBlockHeader = styled.div`
	margin-bottom: 24px;
`

export const BedgesWrapper = styled.div`
	max-width: calc(100px * 2);
	display: flex;
	gap: 10px;
	align-items: center;
	margin-bottom: 8px;
`

export const ChallengeTitle = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	color: ${(props) => props.theme.palette.lightGray.main};
	margin-bottom: 19px;
	display: inline-block;
`

export const ProgressBarWrapper = styled.div`
	margin-bottom: 8px;

	> div {
		justify-content: flex-start;
	}
`

export const ChallengeInfoText = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;
	color: ${(props) => props.theme.palette.lightGray.main};
`

export const RemainingProductsText = styled.span`
	color: ${(props) => props.theme.palette.primary.main};
`

export const RemoveChallengeItemsButton = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
`

export const ChallengeProductsWrapper = styled.section``
