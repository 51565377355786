import React, {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RangeModifier } from 'react-day-picker'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { format, isValid } from 'date-fns'
import cogoToast from 'cogo-toast'

import ArrowLeftIcon from '../../assets/images/arrow-left-red.svg'

import { primaryTextareaStyle } from '../../shared/styles/theme'
import {
	createChallenge,
	getChallengeFilterParameters,
	getCheckAllFilters,
	updateChallenge
} from '../../shared/services/pointProgramChallenges.service'
import {
	IFilterChallenge,
	IChallenge,
	ChallengeParamsNameEnum,
	ChallengeStatusEnum
} from '../../shared/interfaces/pointsProgramChallenge'
import {
	FloatLabelTextAreaInput,
	Header,
	ImageUpload,
	InputSelectPeriodPicker,
	InputTextStyled as Input,
	Loading,
	RuleOrChallengeActionModal
} from '../../shared/components'

import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'
import { IProduct } from '../RegisterProduct/types'
import { getProducts } from '../RegisterProduct/service'

import { ChangeProductsList } from './ChangeProductsList'
import { ChangeMinQtdProductsValue } from './ChangeMinQtdProductsValue'
import { ChallengeModel } from './ChallengeModel'
import * as S from './styles'
import { IProps } from './types'
import { OrderRules } from './OrderRules'
import { randomStringGenerator } from '../../shared/utils/random-string-generator.util'
import { isNegative } from '../../shared/utils/number'
import ChallengeAlertCannotBeEdited from './ChallengeAlertCannotBeEdited'
import { savingUploadImageBannerHandler } from '../../views/banners-management/service'
import FiltersByHieararchy from '../../components/filters-by-hieararchy/filters-by-hieararchy.component'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { IFilterParams } from '../../components/filters-by-hieararchy/typings'
import { useQuery } from 'react-query'
import suggestedOrdersAdminService from '../../views/suggested-orders-admin-v2/services/suggested-orders-admin.service'
import { Product } from '../RegisterProduct/mapper'

export default function PointProgramChallengeDetailsV2() {
	const location = useLocation<IProps>()
	const { challenge: challengeFromProps } = location.state || {}

	const viewType = useMemo<'CREATE' | 'UPDATE'>(
		() => (!!challengeFromProps ? 'UPDATE' : 'CREATE'),
		[challengeFromProps]
	)

	const challengeComingByPropsIsActive = useMemo(() => {
		if (
			challengeFromProps &&
			challengeFromProps.status === ChallengeStatusEnum.ACTIVE
		) {
			return true
		}
		return false
	}, [challengeFromProps])

	const history = useHistory()

	const [isLoadingFilterOptions, setIsLoadingFilterOptions] = useState(false)
	const [selectedPDVs, setSelectedPDVs] = useState<string[]>([])

	const [challengeDetails, setChallengeDetails] = useState<ChallengeModel>(
		new ChallengeModel({
			challenge: challengeFromProps,
			settings: { isInitialization: true }
		})
	)

	const [
		showChallengeCancellationConfirmationModal,
		setShowChallengeCancellationConfirmationModal
	] = useState(false)

	const [challengeIdToCancelIt, setChallengeIdToCancelIt] = useState('')

	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [allProducts, setAllProducts] = useState<Product[]>([])
	const [canSubmit, setCanSubmit] = useState(false)
	const [isSubmiting, setIsSubmiting] = useState(false)
	const [imageFile, setImageFile] = useState<File | null>(null)
	const [appPoPupImageFile, setAppPoPupImageFile] = useState<File | null>(
		null
	)

	// State to verify if can filter
	const [canFilter, setCanFilter] = useState<boolean>(false)

	const handlers = {
		changeAppPopUpImageFile: useCallback((value: File | null) => {
			setAppPoPupImageFile(value)
		}, []),
		changeImageFile: useCallback((value: File | null) => {
			setImageFile(value)
		}, []),
		changeImageUrl: useCallback(
			(value: string) => {
				setChallengeDetails(challengeDetails.changeBannerUrl(value))
			},
			[challengeDetails]
		),
		changeAppPopUpImageUrl: useCallback(
			(value: string) => {
				setChallengeDetails(
					challengeDetails.changeAppPopUpImageUrl(value)
				)
			},
			[challengeDetails]
		),
		changeShowChallengeBannerOnHome: useCallback(() => {
			setChallengeDetails(
				challengeDetails.changeShowChallengeBannerOnHome()
			)
		}, [challengeDetails]),
		changeGeneratedPoints: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				const numberValue = Number(value || '0')

				if (!Number.isNaN(numberValue) && !isNegative(numberValue)) {
					setChallengeDetails(
						challengeDetails.changeGeneratedPoints(
							numberValue.toString()
						)
					)
				}
			},
			[challengeDetails]
		),
		changePeriod: useCallback(
			(value: RangeModifier) => {
				setChallengeDetails(challengeDetails.changePeriod(value))
			},
			[challengeDetails]
		),
		changeShowCampaignDeadline: useCallback(() => {
			setChallengeDetails(challengeDetails.changeShowCampaignDeadline())
		}, [challengeDetails]),
		changeTitle: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				setChallengeDetails(
					challengeDetails.changeChallengeTitle(value)
				)
			},
			[challengeDetails]
		),
		changeDescription: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				setChallengeDetails(
					challengeDetails.changeChallengeDescription(value)
				)
			},
			[challengeDetails]
		),
		changeTerms: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				setChallengeDetails(
					challengeDetails.changeChallengeAdoptionTerms(value)
				)
			},
			[challengeDetails]
		),
		changePurchaseRule: useCallback(
			(value: string) => {
				setChallengeDetails(challengeDetails.changePurchaseRule(value))
			},
			[challengeDetails]
		),
		changeCustomerMayParticipateMoreThanOnce: useCallback(() => {
			setChallengeDetails(
				challengeDetails.changeCustomerMayParticipateMoreThanOnce()
			)
		}, [challengeDetails]),

		changeProducts: useCallback(
			(value: string[]) => {
				setChallengeDetails(challengeDetails.changeProducts(value))
			},
			[challengeDetails]
		),
		changeProductMinQtd: useCallback(
			(productID: string, scoreRate: string) => {
				setChallengeDetails(
					challengeDetails.changeProductMinQtd(productID, scoreRate)
				)
			},
			[challengeDetails]
		),
		changeObservation: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				setChallengeDetails(challengeDetails.changeObservation(value))
			},
			[challengeDetails]
		)
	}

	function getChallengeProductIDsList() {
		return challengeDetails.challengeData.products.map(
			(product) => product.productId
		)
	}

	function joinAllFilters() {
		const joinedFilters: IFilterChallenge[] = []

		for (const pdv of selectedPDVs) {
			joinedFilters.push({
				paramName: ChallengeParamsNameEnum.PDV,
				paramValue: pdv
			})
		}
		return joinedFilters
	}

	function formatDatePayload(dateString: string) {
		if (!isValid(new Date(dateString))) return undefined
		return format(new Date(dateString), "yyyy'-'MM'-'dd")
	}

	function generateChallengeFileName(challengeID: string, file: File) {
		const currentTime = new Date().getTime()
		const [name, extension] = file.name.split('.')
		return `${challengeID}-${name}-${currentTime}.${extension}`
	}

	function handleSubmit() {
		;(async () => {
			try {
				setIsSubmiting(true)

				const challengeID =
					challengeFromProps?.challengeId || randomStringGenerator()

				let bannerUrl = challengeDetails.challengeData.urlFoto
				if (imageFile) {
					const imageFileName = generateChallengeFileName(
						challengeID,
						imageFile
					)
					const uploadImageBannerResponse =
						await savingUploadImageBannerHandler({
							file: imageFile,
							fileName: imageFileName
						})
					bannerUrl = uploadImageBannerResponse?.url || bannerUrl
				}

				let popUpImageUrl =
					challengeDetails.challengeData.appPopUpImageUrl
				if (appPoPupImageFile) {
					const appPoPupImageFileName = generateChallengeFileName(
						challengeID,
						appPoPupImageFile
					)
					const uploadImageAppPopUpImageResponse =
						await savingUploadImageBannerHandler({
							file: appPoPupImageFile,
							fileName: appPoPupImageFileName
						})
					popUpImageUrl =
						uploadImageAppPopUpImageResponse?.url || popUpImageUrl
				}

				const challengeData = challengeDetails.challengeData

				const payload: Partial<IChallenge> = {
					...challengeData,
					startDate: String(
						formatDatePayload(challengeData.startDate)
					),
					endDate: String(formatDatePayload(challengeData.endDate)),
					filtersChallenge: joinAllFilters(),
					challengeId: challengeID,
					urlFoto: bannerUrl,
					appPopUpImageUrl: popUpImageUrl
				}

				let headChallenge = { challengeId: '' }
				if (!!challengeFromProps) {
					headChallenge = await updateChallenge(payload)
				} else {
					headChallenge = await createChallenge(payload)
				}

				//Fazer uma validação se todos os filtros foram inseridos
				await getCheckAllFilters(
					headChallenge.challengeId,
					selectedPDVs?.length ?? 0
				)
				cogoToast.success(
					'Um novo Desafio foi cadastrado e estará disponível no período definido.',
					{
						position: 'bottom-center',
						heading:
							viewType === 'CREATE'
								? 'Desafio Cadastrado!'
								: 'Desafio Atualizado!'
					}
				)
				history.replace(AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES)
			} catch (error) {
				cogoToast.error(error, cogoDefaultOptions)
			} finally {
				setIsSubmiting(false)
			}
		})()
	}

	function fetchProducts() {
		;(async () => {
			try {
				setIsLoadingProducts(true)
				const products = await getProducts()

				setAllProducts(products)
			} finally {
				setIsLoadingProducts(false)
			}
		})()
	}

	const { data: domainCategory } = useQuery({
		queryKey: ['/materials-grouped-by-category'],
		queryFn: async () => {
			const res =
				await suggestedOrdersAdminService.getMaterialsGroupedByCategory()
			return res
		}
	})

	const groupedCategories = domainCategory?.groupedCategories ?? []

	function checkIfCanSubmit() {
		let canSubmit = false
		const {
			urlFoto: bannerUrl,
			score: generatedPoints,
			title: challengeTitle,
			description: challengeDescription,
			Term: challengeAdoptionTerms,
			// O purchaseRule
			typeRule: purchaseRule,
			appPopUpImageUrl: appPopUpUrl,
			products = []
		} = challengeDetails.challengeData

		const thereAreSelectedProducts = !!products.length
		const allMinQudAreValid = products.every(
			(product) => !!Number(product.quantity)
		)

		canSubmit =
			(!!bannerUrl || !!imageFile) &&
			(!!appPopUpUrl || !!appPoPupImageFile) &&
			!!generatedPoints &&
			!!challengeTitle &&
			!!challengeDescription &&
			!!challengeAdoptionTerms &&
			!!purchaseRule &&
			thereAreSelectedProducts &&
			allMinQudAreValid &&
			!(Number(generatedPoints) < 1) &&
			selectedPDVs?.length > 0

		setCanSubmit(canSubmit)
	}

	useEffect(fetchProducts, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(checkIfCanSubmit, [
		challengeDetails,
		imageFile,
		appPoPupImageFile,
		canFilter
	])

	const handleClosingChallengeCancellationConfirmationModal = () => {
		setShowChallengeCancellationConfirmationModal(
			!showChallengeCancellationConfirmationModal
		)
	}

	const handleShowConfirmationCancelingChallengeModal = () => {
		setShowChallengeCancellationConfirmationModal(
			!showChallengeCancellationConfirmationModal
		)
	}

	const enableModalToConfirmationChallengeCanceling = (
		challengeId: string
	) => {
		handleShowConfirmationCancelingChallengeModal()
		setChallengeIdToCancelIt(challengeId)
	}

	const cancelChallengeCreationOrEditing = () => {
		if (challengeFromProps) {
			enableModalToConfirmationChallengeCanceling(
				String(challengeFromProps.challengeId)
			)
		} else {
			history.replace(AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES)
		}
	}

	const isLoading = isSubmiting || isLoadingFilterOptions || isLoadingProducts

	console.log({ getChallengeProductIDsList: getChallengeProductIDsList() })

	return (
		<Fragment>
			<Header />
			<S.Container>
				<S.PageHeader>
					<Link
						className="go-back-button"
						to={AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES}>
						<img
							className="back-arrow"
							src={ArrowLeftIcon}
							alt="Ícode de flecha apontando para a esquerda"
						/>
						<span>Voltar</span>
					</Link>

					<h1 className="pg-title">
						{viewType === 'UPDATE' && challengeComingByPropsIsActive
							? 'Informações do Desafio'
							: viewType === 'UPDATE' &&
							  !challengeComingByPropsIsActive
							? 'Detalhes do Desafio'
							: 'Novo Desafio'}
					</h1>
					{challengeFromProps &&
					viewType === 'UPDATE' &&
					challengeFromProps.status === ChallengeStatusEnum.ACTIVE ? (
						<S.AlertWrapper>
							<ChallengeAlertCannotBeEdited
								challenge={challengeFromProps}
							/>
						</S.AlertWrapper>
					) : null}
				</S.PageHeader>
				<S.PageSession>
					<S.ImageUploadsWrapper>
						<S.ImageUploadItem>
							<S.ImageUploadTitle>
								Banner do Desafio
							</S.ImageUploadTitle>
							<ImageUpload
								id="challenge-banner"
								editionDisabled={challengeComingByPropsIsActive}
								imageUrl={
									imageFile
										? URL.createObjectURL(imageFile)
										: challengeDetails.challengeData.urlFoto
								}
								handlerImageURL={handlers.changeImageUrl}
								returnImage={({ file }) =>
									handlers.changeImageFile(file)
								}
								dimensions={'654x368'}
							/>
						</S.ImageUploadItem>
						<S.ImageUploadItem>
							<S.ImageUploadTitle>
								Imagem do Pop Up do Desafio
							</S.ImageUploadTitle>
							<ImageUpload
								id="challenge-banner-pop-up"
								editionDisabled={challengeComingByPropsIsActive}
								imageUrl={
									appPoPupImageFile
										? URL.createObjectURL(appPoPupImageFile)
										: challengeDetails.challengeData
												.appPopUpImageUrl
								}
								handlerImageURL={
									handlers.changeAppPopUpImageUrl
								}
								returnImage={({ file }) =>
									handlers.changeAppPopUpImageFile(file)
								}
								dimensions={'654x906'}
							/>
						</S.ImageUploadItem>
					</S.ImageUploadsWrapper>
					<S.InputCheckboxContainer
						disabled={challengeComingByPropsIsActive}
						onClick={handlers.changeShowChallengeBannerOnHome}>
						<input
							id="check-challenge-banner-on-home"
							disabled={challengeComingByPropsIsActive}
							type="checkbox"
							checked={challengeDetails.challengeData.bannerhome}
						/>
						<label>
							Exibir Banner do Desafio na Home do aplicativo
						</label>
					</S.InputCheckboxContainer>
					<S.InputsRow className="generated-points-and-period-row">
						<Input
							maxLength={7}
							isChallengeScreen={true}
							disabled={challengeComingByPropsIsActive}
							value={challengeDetails.challengeData.score}
							onChange={handlers.changeGeneratedPoints}
							name="generated-points"
							errorMessage={
								!!challengeDetails.challengeData.score &&
								Number(challengeDetails.challengeData.score) < 1
									? 'A pontuação gerada deve ser maior que 0.'
									: ''
							}
							label="Pontuação Gerada"
							containerStyle={{
								backgroundColor: challengeComingByPropsIsActive
									? '#f5f5f5'
									: 'white'
							}}
						/>
						<InputSelectPeriodPicker
							isDisabled={challengeComingByPropsIsActive}
							label="Período de Exibição"
							placeholder="Defina um período"
							onChange={handlers.changePeriod}
							value={{
								from: new Date(
									challengeDetails.challengeData.startDate
								),
								to: new Date(
									challengeDetails.challengeData.endDate
								)
							}}
							containerStyle={{
								height: 55,
								marginLeft: '16px',
								marginRight: '16px',
								backgroundColor: challengeComingByPropsIsActive
									? '#f5f5f5'
									: 'white',
								pointerEvents: challengeComingByPropsIsActive
									? 'none'
									: 'all'
							}}
							style={{
								width: '100%'
							}}
						/>
						<S.InputCheckboxContainer
							disabled={challengeComingByPropsIsActive}
							onClick={handlers.changeShowCampaignDeadline}>
							<input
								disabled={challengeComingByPropsIsActive}
								type="checkbox"
								checked={
									challengeDetails.challengeData
										.countDayFinish
								}
							/>
							<label
								aria-disabled={challengeComingByPropsIsActive}>
								Exibir prazo da campanha aos clientes
							</label>
						</S.InputCheckboxContainer>
					</S.InputsRow>
					<S.InputsRow>
						<Input
							maxLength={70}
							isChallengeScreen={true}
							disabled={challengeComingByPropsIsActive}
							value={challengeDetails.challengeData.title}
							onChange={handlers.changeTitle}
							name="challenge-title"
							label="Título do Desafio"
							containerStyle={{
								backgroundColor: challengeComingByPropsIsActive
									? '#f5f5f5'
									: 'white'
							}}
						/>
					</S.InputsRow>
					<S.InputsRow>
						<FloatLabelTextAreaInput
							isChallengeScreen={true}
							disabled={challengeComingByPropsIsActive}
							value={challengeDetails.challengeData.description}
							onChange={handlers.changeDescription}
							id="challenge-description"
							label="Descrição do Desafio"
							labelColor="#CE332E"
							maxLength={500}
							containerStyle={{
								backgroundColor: challengeComingByPropsIsActive
									? '#f5f5f5'
									: 'white'
							}}
							{...primaryTextareaStyle}
						/>
					</S.InputsRow>
					<S.InputsRow>
						<FloatLabelTextAreaInput
							isChallengeScreen={true}
							disabled={challengeComingByPropsIsActive}
							value={challengeDetails.challengeData.Term}
							onChange={handlers.changeTerms}
							id="challenge-adoption-terms"
							label="Termos de Adesão do Desafio"
							labelColor="#CE332E"
							maxLength={5000}
							containerStyle={{
								backgroundColor: challengeComingByPropsIsActive
									? '#f5f5f5'
									: 'white'
							}}
							{...primaryTextareaStyle}
						/>
					</S.InputsRow>
				</S.PageSession>
				<S.PageSession>
					<h2 className="pg-subtitle">Regras de Compra</h2>
					<OrderRules
						disabled={challengeComingByPropsIsActive}
						onChangeRule={handlers.changePurchaseRule}
						selectedRule={challengeDetails.challengeData.typeRule}
						onChangeCheckbox={
							handlers.changeCustomerMayParticipateMoreThanOnce
						}
						customerMayParticipateMoreThanOnce={
							challengeDetails.challengeData.participateMoreOnce
						}
					/>
				</S.PageSession>
				{/* Inicio filtro por hierarquia */}
				<FiltersByHieararchy
					initialFilters={
						challengeFromProps?.filtersChallenge as IFilterParams[]
					}
					isEnabled={!challengeComingByPropsIsActive}
					onFilterChange={setSelectedPDVs}
					getFilterParameters={getChallengeFilterParameters}
					onLoad={() => setIsLoadingFilterOptions(true)}
					onLoadEnd={() => setIsLoadingFilterOptions(false)}
					canFilter={canFilter}
					setCanFilter={setCanFilter}
				/>

				{/* Fim filtro por hierarquia */}
				<S.PageSession>
					{isLoadingProducts ? (
						<div className="loading-wrapper">
							<Loading color="red" />
						</div>
					) : (
						<div className="products-list-wrapper">
							<ChangeProductsList
								isDisabled={challengeComingByPropsIsActive}
								options={groupedCategories}
								selectedOptions={getChallengeProductIDsList()}
								onChangeOption={handlers.changeProducts}
							/>
							<ChangeMinQtdProductsValue
								isDisabled={challengeComingByPropsIsActive}
								allProducts={allProducts}
								selectedProducts={
									challengeDetails.challengeData.products
								}
								onChangeProductScoreRate={
									handlers.changeProductMinQtd
								}
							/>
						</div>
					)}
				</S.PageSession>
				<S.PageSession>
					<FloatLabelTextAreaInput
						isChallengeScreen
						disabled={challengeComingByPropsIsActive}
						value={challengeDetails.challengeData.observation}
						onChange={handlers.changeObservation}
						id="rule-observation"
						label="Observações (Opcional)"
						labelColor="#CE332E"
						containerStyle={{
							backgroundColor: challengeComingByPropsIsActive
								? '#f5f5f5'
								: 'white'
						}}
						maxLength={150}
						{...primaryTextareaStyle}
					/>
				</S.PageSession>
				<div>
					Por favor ao
					{viewType === 'UPDATE' ? ' atualizar ' : ' cadastrar '}
					aguarde ser redirecionado automaticamente para a tela
					inicial, o processo de inserção pode levar algum tempo, se
					demorar mais de 5 minutos por favor contate o suporte.
				</div>
				<S.ButtonActionsGroup>
					<button
						className={`${
							challengeComingByPropsIsActive
								? 'challenge-cannot-be-canceled'
								: 'transparent-button'
						}`}
						disabled={isLoading || challengeComingByPropsIsActive}
						onClick={cancelChallengeCreationOrEditing}>
						{!!challengeFromProps ? 'CANCELAR DESAFIO' : 'CANCELAR'}
					</button>
					<button
						id="submit-challenge"
						className={`${
							challengeComingByPropsIsActive ||
							isLoading ||
							!canSubmit
								? 'challenge-cannot-be-canceled'
								: 'red-button'
						}`}
						disabled={
							!canSubmit ||
							isLoading ||
							challengeComingByPropsIsActive
						}
						onClick={handleSubmit}>
						{viewType === 'UPDATE'
							? 'SALVAR ALTERAÇÕES'
							: 'CADASTRAR DESAFIO'}
					</button>
				</S.ButtonActionsGroup>
			</S.Container>
			<RuleOrChallengeActionModal
				type="CHALLENGE"
				screenAction="DETAILS"
				typeAction="CANCELLATION"
				ruleOrChallengeID={challengeIdToCancelIt}
				showModal={showChallengeCancellationConfirmationModal}
				handleCloseModal={
					handleClosingChallengeCancellationConfirmationModal
				}
			/>
		</Fragment>
	)
}
