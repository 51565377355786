import styled, { css } from 'styled-components'

export const CreateCouponForm = styled.form`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacingOptions.LG}px;
		margin-top: 10px;

		h2 {
			color: ${theme.palette.black.main};
			font-size: ${theme.font.size.TEXT_MD.size}rem;
		}
	`}
`

export const SessionTitle = styled.h2`
	${({ theme }) => css`
		margin-bottom: ${theme.spacingOptions.MD}px;
	`}
`

export const WrapperButtonSendApproval = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	.wrapper-button {
		width: 190px;
	}
`

export const Title = styled.h1`
	${({ theme }) => css`
		font-size: ${theme.font.size.DISPLAY_XS.size}rem;
		color: ${theme.palette.black.main};
		margin-bottom: 10px;
	`}
`
export const WrapperEmptyScreen = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50vh;
`
