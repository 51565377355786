import styled from "styled-components";

export const Container = styled.div`
    font-size: ${({ theme }) => theme.font.size.TEXT_MD};
    color: ${({ theme }) => theme.palette.gray.dark};
    font-weight: ${({ theme }) => theme.font.weight.regular};
    display: flex;
    gap: 16px;
    align-items: center;
`

export const CounterContainer = styled.div`
    border-radius: 24px;
    padding: 12px;
    border-width: 2px;
    width: 80px;
    height: 24px;
    border-color: ${({theme}) => theme.palette.gray.light};
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({theme}) => theme.palette.black.main};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: ${({ theme }) => theme.font.size.TEXT_MD};
`