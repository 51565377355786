import { createElement, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { ITimeline } from '../../components/TimeLine/types'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { IOrderDetail } from '../../shared/interfaces/orderDetail'
import { IStore } from '../../shared/interfaces/store'
import {
	formatDeliveryDate,
	parseFormat,
	parseFormatToDate
} from '../../shared/utils/date'
import {
	calcPrice,
	cancelStatusDesciption,
	returnLatestStatus
} from '../../shared/utils/order'
import { IDeliveryData, IOrderData, IViewProps } from './types'
import OrderDetail from './view'
import { compareAsc, parseISO } from 'date-fns'
import {
	calcPriceTotal,
	generatePaymentTermsDescription
} from '../../shared/utils/money'
import { TradeTypeEnum } from '../../shared/interfaces/combo'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { fetchMyChallenges } from '../../shared/services/challenge.service'
import { IChallenge } from '../../shared/interfaces/pointsProgramChallenge'

export interface IProps {
	orderDetails: IOrderDetail
}

function OrderDetailContainer(): JSX.Element {
	const { state } = useLocation<IProps>()
	const order = state.orderDetails

	const history = useHistory()
	const { customer } = useSelector((state: IStore) => state)
	const [myChallenges, setMyChallenges] = useState<IChallenge[]>([])
	const [timeline, setTimeline] = useState<Array<ITimeline>>([])

	const [individualProductsData, setIndividualProductsData] = useState<
		Array<Partial<ICartItem>>
	>([])

	const [
		isLoadingItemsChallenge,
		startLoadingItemsChallenge,
		stopLoadingItemsChallenge
	] = useBoolean(false)

	const [itemsChallenge, setItemsChallenge] = useState<Array<ICartItem>>([])

	const [orderData, setOrderData] = useState<IOrderData>({
		orderDate: '',
		deliveryDate: '',
		paymentMethod: '',
		priceTotal: '',
		taxRate: '',
		orderNumber: '',
		orderSource: '' as any
	})

	const [deliveryData, setDeliveryData] = useState<IDeliveryData>({
		destinatary: '',
		address: '',
		neighborhood: '',
		cityState: '',
		CEP: '',
		deliveryDate: ''
	})
	const [removedItemsTableData, setRemovedItemsTableData] = useState<
		Array<Partial<ICartItem>>
	>([])

	function handleBack() {
		history.replace(CustomerRoutesEnum.ORDER_HISTORY)
	}

	function handleSetTimeline() {
		if (!order) return

		const timelineOrderStatus: ITimeline[] = order.OrderStatus.map(
			(status) => {
				return {
					date: parseFormatToDate(status.EventDateTime),
					text: status.OrderStatusDescription,
					flowStatus: 'SUCCESS',
					orderStatus: status.OrderStatusID,
					flowProgress: status.FlowStatus
				}
			}
		)

		const timelineRequestCancel: ITimeline[] =
			order?.RequestsCancelStatus?.map((status) => ({
				date: parseFormatToDate(status.CreatedAt),
				text: cancelStatusDesciption(
					status.requestCancelStatusDescription
				),
				flowStatus: 'SUCCESS',
				orderStatus: status.requestCancelStatusID
			}))

		const sortTimelines = [
			...timelineOrderStatus,
			...timelineRequestCancel
		].sort((a, b) => {
			return compareAsc(a.date, b.date)
		})

		setTimeline(sortTimelines)
	}

	function handleSetIndividualTableData() {
		if (!order) return

		const totalItems = !!order.ItemsPoints?.length
			? [...order.Items, ...order.ItemsPoints]
			: order.Items

		const tableData: ICartItem[] = totalItems.map((item) => ({
			MaterialID: '',
			OrderQuantity: item.Quantity,
			UnitMeasureSale: item.UnitMeasureSale,
			Price: item.Price * item.Quantity,
			ImageUrl: item.ImageUrl,
			Size: item.Package,
			ScoreRate: !!item.scoreRate ? item.scoreRate : '',
			typeItem: !!Number(item.scoreRate)
				? ICartItemTypeEnum.REDEMPTION
				: ICartItemTypeEnum.NORMAL,
			pointsRedeemed: Number(item.scoreRate),
			// empty
			PriceByQuantity: [],
			StockPosition: 0,
			Description: item.Description,
			PackType: '',
			operationType: item.operationType as TradeTypeEnum
		}))

		const removedItemsTableData = tableData.filter((item) => {
			return order.removedItems?.find(
				(removedItem) =>
					removedItem.SiteDescription === item.Description
			)
		})

		setRemovedItemsTableData(removedItemsTableData)
		setIndividualProductsData(tableData)
	}

	function formattingChallengeItems(order: IOrderDetail): ICartItem[] {
		return (
			order.ItemsChallenge?.map((itemChallenge) => {
				return {
					MaterialID: '',
					OrderQuantity: itemChallenge.Quantity,
					UnitMeasureSale: itemChallenge.UnitMeasureSale,
					Price: itemChallenge.Price * itemChallenge.Quantity,
					ImageUrl: itemChallenge.ImageUrl,
					Size: itemChallenge.Package,
					ScoreRate: !!itemChallenge.scoreRate
						? itemChallenge.scoreRate
						: '',
					typeItem: !!Number(itemChallenge.scoreRate)
						? ICartItemTypeEnum.REDEMPTION
						: ICartItemTypeEnum.NORMAL,
					pointsRedeemed: Number(itemChallenge.scoreRate),
					PriceByQuantity: [],
					StockPosition: 0,
					Description: itemChallenge.Description,
					PackType: '',
					operationType: itemChallenge.operationType as TradeTypeEnum,
					challengeID: itemChallenge.challengeID
				}
			}) || []
		)
	}

	function handleSetCards() {
		if (!order) return

		const orderStatus = returnLatestStatus(order)

		const allOrderItems = order.ItemsChallenge?.length
			? [...order.Items, ...order.ItemsChallenge]
			: order.Items

		const orderData = {
			orderStatus: orderStatus,
			orderDate: parseFormat(order.CreatedAt),
			deliveryDate: parseFormat(order.DeliveryDate),
			paymentMethod: generatePaymentTermsDescription(
				//@ts-ignore
				order.PaymentConditionID,
				order.PaymentFormID,
				calcPriceTotal(allOrderItems)
			),
			priceTotal: calcPrice(allOrderItems),
			taxRate: `${
				order.TaxRate ? `${order.TaxRate * 100}%` : 'Sem juros'
			}`,
			orderNumber: String(order.AutoIncBackendID),
			orderSource: order.orderSource
		}

		setOrderData(orderData)

		const deliveryData = {
			destinatary: customer.Name,
			address: customer.Street,
			neighborhood: customer.Neighborhood,
			cityState: `${customer.City} / ${customer.Region}`,
			CEP: customer.PostalCode,
			deliveryDate: formatDeliveryDate(parseISO(order.DeliveryDate))
		}

		setDeliveryData(deliveryData)
	}

	function loadMyChallenges() {
		if (!order.ItemsChallenge?.length) return
		;(async () => {
			startLoadingItemsChallenge()
			try {
				const myChallenges = await fetchMyChallenges({
					ignoreCompletedChallenges: false
				})

				setMyChallenges(myChallenges)
			} catch (error) {
			} finally {
				stopLoadingItemsChallenge()
			}
		})()
	}

	useEffect(() => {
		if (itemsChallenge.length) {
			loadMyChallenges()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemsChallenge])

	useEffect(() => {
		if (order) setItemsChallenge(formattingChallengeItems(order))
	}, [order])

	useEffect(() => {
		handleSetIndividualTableData()
		handleSetCards()
		handleSetTimeline()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [order])

	const viewProps: IViewProps = {
		order,
		isLoadingItemsChallenge,
		timeline,
		orderData,
		handleBack,
		deliveryData,
		myChallenges,
		itemsChallenge,
		removedItemsTableData,
		individualProductsData
	}

	return createElement(OrderDetail, viewProps)
}

export default OrderDetailContainer
