import React from 'react'

import Loading from '../../components/Loading'
import Table from '../../components/Table'
import RegisterProductModal from './RegisterProductModal'
import { IViewProps } from './types'
import InputFilter from '../../components/InputFilter'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import { useFeatureFlags } from '../../context/feature-flag/feature-flag.context'

function RegisterProduct(props: IViewProps) {
	const {
		columns,
		isLoading,
		isNewProduct,
		targetProduct,
		isModalOpen,
		handleCloseModal,
		handleProductDetail,
		handleChange,
		handleSelectPackageType,
		handleSelectScoreValueType,
		handleSelectUnitMeasure,
		handleChangeCategory,
		createProduct,
		setProductImages,
		productImages,
		options,
		deleteProduct,
		isProductValid,
		tableData,
		handleFilterOnChange,
		channelOptions,
		handleChannel,
		selectedOptionChannels,
		handleCheckbox,
		customChannel,
		isBonification,
		handleChangeIsBonification,
		isFeatureFlagBonificationActive,
		handleSelectCategory,
		handleProductDetailLegacy
	} = props

	const { featureFlags } = useFeatureFlags()
	const isCategoriesFeatureFlag = featureFlags.US17241

	return (
		<>
			<DefaultLayoutAdmin>
				<div className="sac-page">
					<div className="headline">
						<div>
							<h1 className="title">Cadastro de Produtos</h1>
						</div>
						{isCategoriesFeatureFlag ? (
							<button
								onClick={() => handleProductDetail()}
								className="red-button">
								Cadastrar Produtos
							</button>
						) : (
							<button
								onClick={() => handleProductDetailLegacy()}
								className="red-button">
								Cadastrar Produtos
							</button>
						)}
					</div>

					<div className="search-input-wrapper">
						<InputFilter onChange={handleFilterOnChange} />
					</div>

					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<Table data={tableData} columns={columns} />
					)}
				</div>
			</DefaultLayoutAdmin>
			<RegisterProductModal
				isActive={isModalOpen}
				targetProduct={targetProduct}
				handleCloseModal={handleCloseModal}
				handleChange={handleChange}
				handleSelectPackageType={handleSelectPackageType}
				handleSelectScoreValueType={handleSelectScoreValueType}
				handleSelectUnitMeasure={handleSelectUnitMeasure}
				handleChangeCategory={handleChangeCategory}
				createProduct={createProduct}
				isNewProduct={isNewProduct}
				isLoading={isLoading}
				setProductImages={setProductImages}
				productImages={productImages}
				options={options}
				deleteProduct={deleteProduct}
				isProductValid={isProductValid}
				channelOptions={channelOptions}
				handleChannel={handleChannel}
				selectedOptionChannels={selectedOptionChannels}
				handleCheckbox={handleCheckbox}
				customChannel={customChannel}
				handleChangeIsBonification={handleChangeIsBonification}
				isBonification={isBonification}
				isFeatureFlagBonificationActive={
					isFeatureFlagBonificationActive
				}
				handleSelectCategory={handleSelectCategory}
			/>
		</>
	)
}

export default RegisterProduct
