import React from 'react'

import { Table } from '../../shared/components'
import Loading from '../../components/Loading'
import { IViewProps } from './types'
import InputFilter from '../../components/InputFilter'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
function ResaleList(props: IViewProps): JSX.Element {
	const { tableData, columns, handleSearchOnChange, isLoading } = props

	return (
		<DefaultLayoutAdmin>
			<div className="register-resale-page">
				<div className="resale-table-wrapper">
					<p className="resales-title">Parâmetros por Unidade</p>
					<InputFilter
						className="search-input"
						onChange={handleSearchOnChange}
						placeholder="Filtro"
					/>
					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<Table data={tableData} columns={columns as any} />
					)}
				</div>
			</div>
		</DefaultLayoutAdmin>
	)
}

export default ResaleList
