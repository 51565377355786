import styled from 'styled-components'

export const WrapperModalContent = styled.div`
	background-color: ${(props) => props.theme.palette.background.paper};
	position: absolute;

	@media only screen and (max-width: 1500px) {
		top: 5%;
		left: 20%;
	}

	top: 20%;
	left: 30%;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.MD}px;
	border-radius: ${(props) => props.theme.spacingOptions.XXSM}px;

	@media only screen and (max-width: 1500px) {
		height: 90%;
	}

	height: 70%;
`
export const WrapperHeaderModal = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: ${(props) => props.theme.spacingOptions.SM}px;

	h2 {
		flex-grow: 1;
		text-align: center;

		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.semibold};
	}

	svg {
		color: ${(props) => props.theme.palette.primary.main};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;

		:hover {
			cursor: pointer;
		}
	}
`

export const WrapperMainContent = styled.div`
	display: grid;
	grid-template-columns: 312px auto;
	padding: ${(props) => props.theme.spacingOptions.SM}px;
	height: 70%;
	overflow: hidden;
`

export const ComboDetails = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	gap: ${(props) => props.theme.spacingOptions.SM}px;

	img {
		height: 160px;
		width: 160px;
	}

	.wrapper-info-combo {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		flex-wrap: wrap;
		gap: ${(props) => props.theme.spacingOptions.SM}px;

		padding: ${(props) => props.theme.spacingOptions.SM}px;

		h3 {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			font-weight: 500;
		}

		p {
			color: ${(props) => props.theme.palette.gray.main};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			font-weight: 500;
		}
	}
`

export const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.MD}px;

	padding: ${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.MD}px;
`
export const WrapperMaxQuantityOrderAlreadyReached = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	flex-wrap: wrap;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	width: 100%;

	h3 {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: 500;
	}

	p {
		color: ${(props) => props.theme.palette.gray.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		font-weight: 500;
	}
`
