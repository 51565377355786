import * as React from 'react'
import TextField from '@mui/material/TextField'

import AutoCompleteSelectProps from './auto-complete-select.interface'
import { AutoCompleteWrapper } from './auto-complete-select.styles'

export default function AutoCompleteSelect({
	optionsData,
	placeholder,
	setValue,
	handleGetComboData
}: AutoCompleteSelectProps) {
	return (
		<AutoCompleteWrapper
			disablePortal
			clearIcon={false}
			options={optionsData}
			getOptionLabel={(option: any) =>
				`${option.SalesOrganizationID} - ${option.Name}` || ''
			}
			onChange={(event, value) => {
				setValue(value)
				handleGetComboData()
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={placeholder}
				/>
			)}
		/>
	)
}
