import { compareDesc, parseISO } from 'date-fns'
import React, { createElement, useEffect, useMemo, useState } from 'react'

import { parseFormatOnlyDate } from '../../../shared/utils/date'
import { parseItemPrice } from '../../../shared/utils/money'
import { sortBadgeByID, sortDate, sortMoney } from '../../../shared/utils/sorts'
import { DEFAULT_REQUEST, findLastRequestCancelStatusDescription } from './data'
import { getCancellationRequests } from './service'
import {
	IRequest,
	ISalesOrderItem,
	IViewProps,
	RequestCancelStatusDescriptionEnum,
	ITableData
} from './types'
import CancellationRequests from './view'
import { include } from '../../../shared/utils/boolean'

import FileTextIcon from '../../../assets/images/file-text.svg'

const statusEnum = {
	RECEBIDO: {
		class: 'pending',
		text: 'Recebido'
	},
	'EM ATENDIMENTO': {
		class: 'pending',
		text: 'Em Atendimento'
	},
	ATENDIDA: {
		class: 'approved',
		text: 'Atendida'
	},
	CONCLUIDO: {
		class: 'approved',
		text: 'Atendida'
	}
}

function CancellationRequestsContainer(): JSX.Element {
	const [isLoading, setIsLoading] = useState(false)
	// const [requestList, setRequestList] = useState<IRequest[]>([])
	const [isEditing, setIsEditing] = useState(false)
	const [targetRequest, setTargetRequest] =
		useState<IRequest>(DEFAULT_REQUEST)

	const [data, setData] = useState<ITableData[]>([])
	const [tableData, setTableData] = useState<ITableData[]>([])

	const columns = useMemo(
		() => [
			{
				Header: 'Situação',
				accessor: 'situation',
				sortType: sortBadgeByID('situation')
			},
			{
				Header: 'Solicitante',
				accessor: 'requester',
				sortType: 'basic'
			},
			{
				Header: 'Data de Criação',
				accessor: 'createdAt',
				sortType: sortDate('createdAt')
			},
			{
				Header: 'Valor total',
				accessor: 'orderValue',
				sortType: sortMoney('orderValue')
			},
			{ Header: '', accessor: 'button', disableSortBy: true }
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	function calcTotalOrder(orders: ISalesOrderItem[]) {
		let total = 0

		orders.forEach((order) => {
			const orderTotal = order.Price * order.Quantity

			total += orderTotal
		})

		return parseItemPrice(total)
	}

	function renderBadge(status: RequestCancelStatusDescriptionEnum) {
		if (!statusEnum[status]) return null

		return (
			<div className="badge-wrapper" id={statusEnum[status].text}>
				<span className={`badge-status ${statusEnum[status].class}`}>
					{statusEnum[status].text}
				</span>
			</div>
		)
	}

	function renderButton(item: IRequest) {
		return (
			<button className="edit" onClick={() => handleOpenModal(item)}>
				<img className="edit-img" src={FileTextIcon} alt="Editar" />
			</button>
		)
	}

	function sortRequest(a: IRequest, b: IRequest): number {
		const dataA = parseISO(a.RequestCancelCreatedAt)
		const dataB = parseISO(b.RequestCancelCreatedAt)
		return compareDesc(dataA, dataB)
	}

	function fetchCancellationRequests() {
		;(async () => {
			try {
				setIsLoading(true)
				const requests: IRequest[] = await getCancellationRequests()

				const requestSorted = requests
					.map((item) => ({
						...item,
						RequestsCancelStatus: item.RequestsCancelStatus.map(
							(status) => ({
								...status,
								requestCancelStatusDescription:
									status.requestCancelStatusDescription ===
									'CONCLUIDO'
										? 'ATENDIDA'
										: status.requestCancelStatusDescription
							})
						)
					}))
					.sort(sortRequest)

				const data = requestSorted?.map((item) => ({
					situation: renderBadge(
						findLastRequestCancelStatusDescription(
							item.RequestsCancelStatus
						)
					),
					requester: item.CustomerName,
					createdAt: parseFormatOnlyDate(item.RequestCancelCreatedAt),
					// orderNumber: item.SalesOrderID,
					orderValue: calcTotalOrder(item.SalesOrderItems),
					button: renderButton(item)
				}))

				setData(data)
				setTableData(data)
			} catch (error) {
				console.error({ error })
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleFilterOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.createdAt, value) ||
				include(item.situation?.props.id, value) ||
				include(item.requester, value) ||
				include(item.orderValue, value)
			)
		})

		setTableData(list)
	}

	function handleOpenModal(item: IRequest) {
		setIsEditing(true)
		setTargetRequest(item)
	}

	function handleCloseModal() {
		setIsEditing(false)
		setTargetRequest(DEFAULT_REQUEST)
	}

	useEffect(fetchCancellationRequests, [])

	const viewProps: IViewProps = {
		isLoading,
		tableData,
		columns,
		isEditing,
		handleCloseModal,
		targetRequest,
		calcTotalOrder,
		fetchCancellationRequests,
		handleFilterOnChange
	}

	return createElement(CancellationRequests, viewProps)
}

export default CancellationRequestsContainer
