import styled from 'styled-components'
import { Box } from '@mui/material'

export const FooterWrapper = styled(Box)`
	height: 275px;
	box-shadow: 0px -1px 0px ${(props) => props.theme.palette.extras.stroke.main};

	display: grid;
	grid-template-columns: auto 40% 20%;
	padding: 0px 60px;

	@media screen and (max-width: 800px) {
		grid-template-columns: 100px 1fr 1fr;
		display: flex;
		align-items: flex-start;
		padding: 42px 20px;
		gap: 10px;
	}

	.footer-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		gap: 24px;

		@media screen and (max-width: 800px) {
			align-items: center;
			gap: 15px;
			text-align: center;
		}

		.text-date {
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_MD.line_height};

			color: ${(props) => props.theme.palette.gray.main};
		}

		.title-column {
			font-weight: ${(props) => props.theme.font.weight.bold};
			font-size: ${(props) => props.theme.font.size.TEXT_LG.size};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_LG.line_height};

			color: ${(props) => props.theme.palette.black.main};
		}

		.item-column {
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_SM.line_height};
			color: ${(props) => props.theme.palette.primaryWhite.contrastText};
		}

		.clicked:hover {
			cursor: pointer;
		}

		.text-icon {
			display: flex;
			align-items: center;
			gap: 15px;

			color: ${(props) => props.theme.palette.primary.main};
			font-weight: ${(props) => props.theme.font.weight.bold};
		}
	}
`
