import styled from 'styled-components'

export const InfoParagraph = styled.h1`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	color: ${(props) => props.theme.palette.black.main};
`

export const SectionInfo = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	> :nth-child(-n + 2) {
		padding-top: 0;
	}

	> :nth-child(n + 3) {
		padding-top: ${(props) => props.theme.spacingOptions.SM}px;
	}
`
export const ContainerContentDetails = styled.div`
	padding-top: ${(props) => props.theme.spacingOptions.MD}px;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.MD}px;
	border-bottom: 1px solid #e0e0e0;
	padding-bottom: ${(props) => props.theme.spacingOptions.XSM}px;
`
export const FragDetails = styled.div`
	display: flex;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	flex-direction: column;
`

export const FragLabelsDetails = styled.div`
	display: flex;
	flex-direction: column;
`

export const FragDetailsSelect = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	width: 100%;
`

export const RulesSelectsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 178px);
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	align-items: center;
	justify-content: center;
`
export const DualSelectorContainer = styled.div`
	flex-grow: 0.48;
	flex-shrink: 1;
	flex-basis: auto;
`
export const ContainerTags = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
	gap: ${(props) => props.theme.spacingOptions.MD}px;
	padding: ${(props) => props.theme.spacingOptions.MD}px 0px;
`

export const FiltersByHierarquySection = styled.div`
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-template-columns: repeat(2, 278px);
	grid-gap: 16px 24px;

	padding: 16px 0px;
`
