import React, { useCallback, useState } from 'react'

import {
	ISelectFilterOption,
	ISelectedDate,
	ITableFilterProps,
	Status,
	StatusConfirmation
} from './TableFilter.types'
import { DataSelectorList } from '../../../../../components/data-selector-list'
import * as S from './TableFilter.styles'
import InputSelectPeriodPicker from '../../../../../components/InputSelectPeriodPicker'
import ButtonDefault from '../../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../../components/buttons/button/button-default.types'
import { RangeModifier } from 'react-day-picker/types/Modifiers'
import { calcDate } from '../../../../../utils/dates'

const optionsSAC: ISelectFilterOption[] = [
	{ name: Status.info, value: Status.info },
	{ name: Status.success, value: Status.success },
	{ name: Status.pending, value: Status.pending },
	{ name: Status.refused, value: Status.refused }
]

const optionsConfirmation: ISelectFilterOption[] = [
	{ name: StatusConfirmation.S, value: 'S' },
	{ name: StatusConfirmation.E, value: 'E' },
	{ name: StatusConfirmation.R, value: 'R' },
	{ name: StatusConfirmation.F, value: 'F' }
]

export default function TableFilter({ onFilter }: ITableFilterProps) {
	const [selectedStatusSACOption, setSelectedStatusSACOption] = useState<
		string | undefined
	>(undefined)

	const [selectedStatusOption, setSelectedStatusOption] = useState<
		string | undefined
	>(undefined)

	const [selectedDate, setSelectedDate] = useState<ISelectedDate>({
		from: calcDate(7),
		to: new Date()
	})

	const handleSelectDates = useCallback((value: RangeModifier): void => {
		if (!value.from || !value.to) return
		setSelectedDate({
			from: value.from,
			to: value.to
		})
	}, [])

	const cleanFilter = useCallback(async () => {
		setSelectedStatusSACOption(undefined)
		setSelectedStatusOption(undefined)
		setSelectedDate({
			from: calcDate(7),
			to: new Date()
		})
		onFilter(
			selectedDate,
			selectedStatusSACOption,
			selectedStatusOption,
			true
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<S.Wrapper>
			<S.Container className="table-filter">
				<S.Selectors>
					<DataSelectorList
						dataSelectorListID="selector-status-sac"
						list={optionsSAC as ISelectFilterOption[]}
						title="SAC"
						handleChange={(event) => {
							setSelectedStatusSACOption(event.target.value)
						}}
						selectedOption={{
							value: selectedStatusSACOption ?? ''
						}}
						isNative={false}
					/>
					<DataSelectorList
						dataSelectorListID="selector-status-confirmation"
						list={optionsConfirmation as ISelectFilterOption[]}
						title="Status Confirmação"
						handleChange={(event) => {
							setSelectedStatusOption(event.target.value)
						}}
						selectedOption={{
							value: selectedStatusOption ?? ''
						}}
						isNative={false}
					/>
				</S.Selectors>
				<S.InputSelectPeriodPicker id="period-filter">
					<InputSelectPeriodPicker
						label="Período"
						placeholder="Defina um intervalo"
						onChange={(value) => {
							handleSelectDates(value)
						}}
						value={selectedDate}
						disableDatesFuture={true}
						style={{
							width: '100%'
						}}
					/>
				</S.InputSelectPeriodPicker>
				<S.ButtonDefault className="button-filter">
					<ButtonDefault
						idElement="voltar-button"
						buttonText="Limpar"
						type={TypeButtonEnum.OUTLINED}
						onClick={cleanFilter}
					/>
					<ButtonDefault
						idElement="button-filter"
						buttonText="Filtrar"
						onClick={() => {
							onFilter(
								selectedDate,
								selectedStatusSACOption,
								selectedStatusOption
							)
						}}
					/>
				</S.ButtonDefault>
			</S.Container>
		</S.Wrapper>
	)
}
