import styled from 'styled-components'

export const HeaderContainer = styled.div`
	position: fixed;
	z-index: 999;

	display: flex;
	flex-direction: row;
	align-items: center;

	width: 100%;
	min-height: 80px;
	padding: 0 32px;

	background: linear-gradient(
		to right,
		${(props) => props.theme.palette.primary.main},
		${(props) => props.theme.palette.secondary.main}
	);

	+ div {
		padding-top: 80px;
	}

	.button-menu-wrapper {
		flex: 1;
		button.button-menu {
			min-width: 24px;
			cursor: pointer;
			background: none;
			border: none;
		}
	}

	.gp-logo-wrapper {
		flex: 1;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		margin: 12px;

		.gp-logo {
			width: 124px;
			height: auto;
		}
	}

	.cart-summary-wrapper {
		flex: 1;

		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}
`
