import { AdminRoutesEnum } from '../../modules/Routes/adminRoutesEnum'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'
import { UserTypeEnum } from '../interfaces/customer'

export function redirectRoute(userType: UserTypeEnum, customerId: string) {
	if (userType === UserTypeEnum.CUSTOMER) {
		const userRedirect = customerId ?  CustomerRoutesEnum.PRODUCT_LIST : CustomerRoutesEnum.WELCOME
		return userRedirect
	} else if (userType === UserTypeEnum.ADMIN) {
		return AdminRoutesEnum.RESALE_LIST
	} else if (userType === UserTypeEnum.SAC) {
		return AdminRoutesEnum.SAC_PENDING_APPROVAL
	} else if (userType === UserTypeEnum.PRICING) {
		return AdminRoutesEnum.RESALE_PRODUCTS
	} else if (userType === UserTypeEnum.UNIDADE) {
		return AdminRoutesEnum.SAC_PENDING_APPROVAL
	}

	return UnauthenticatedRoutesEnum.LOGIN
}
