import React, { ChangeEventHandler } from 'react'
import * as S from './text-field-limit-text.styles'

type TextFieldLimitTextProps = {
	id: string
	label: string
	maxCharacters: number
	text: string
	onChange: ChangeEventHandler<HTMLTextAreaElement>
}

function TextFieldLimitText({
	id,
	label,
	maxCharacters,
	text,
	onChange
}: TextFieldLimitTextProps) {
	return (
		<S.InputWrapper>
			<S.Input
				id={id}
				label={label}
				variant="outlined"
				value={text}
				onChange={onChange}
				InputProps={{
					endAdornment:
						!!maxCharacters  ? (
							<S.CountCharacters position="end">
								{text.length}/{maxCharacters}
							</S.CountCharacters>
						) : undefined
				}}
				inputProps={{
					maxLength: maxCharacters
				}}
			/>
		</S.InputWrapper>
	)
}

export default TextFieldLimitText
