import { remove } from 'ramda'
import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ICartRedemptionItemsActionCreators
} from '../../interfaces/action'

import { ICartItem } from '../../interfaces/cart'

export const {
	Types,
	Creators
}: IActionsFromCreators<ICartRedemptionItemsActionCreators> = createActions({
	addRedemptionItem: ['cartRedemptionItem', 'cartRedemptionAction'],
	removeRedemptionItem: ['cartRedemptionItem'],
	updateRedemptionItem: ['cartRedemptionItem', 'cartRedemptionAction'],
	updateRedemptionItems: ['cartRedemptionItems'],
	clearRedemptionItems: []
})

const INITIAL_STATE: ICartItem[] = []

export enum CartRedemptionItemsActionEnum {
	ADD_REDEMPTION_ITEM = 'ADD_REDEMPTION_ITEM',
	UPDATE_REDEMPTION_ITEM = 'UPDATE_REDEMPTION_ITEM'
}

interface ICartRedemptionItemAndAction {
	cartRedemptionItem: ICartItem
	cartRedemptionAction: CartRedemptionItemsActionEnum
}

const addOrUpdateRedemptionItem = (
	state = INITIAL_STATE,
	action: ICartRedemptionItemAndAction
): ICartItem[] => {
	const objJSONStrinfy = JSON.stringify(state)
	const items = JSON.parse(objJSONStrinfy)

	const cartRedemptionItemActionObjStringfy = JSON.stringify(
		action.cartRedemptionItem
	)
	const cartItem = JSON.parse(cartRedemptionItemActionObjStringfy)

	const cartItemIndex = items.findIndex(
		(item: ICartItem) => item.MaterialID === cartItem.MaterialID
	)

	const newTotalScoreRate =
		Number(cartItem.ScoreRate) * cartItem.OrderQuantity

	if (cartItemIndex === -1) {
		cartItem.pointsRedeemed = newTotalScoreRate

		return [...items, cartItem]
	} else {
		const currentCartItemObjStringfy = JSON.stringify(items[cartItemIndex])
		const currentItemInCart = JSON.parse(currentCartItemObjStringfy)

		const newOlderPrice = Number(cartItem.oldPrice) * cartItem.OrderQuantity

		const newPrice = cartItem.Price * cartItem.OrderQuantity

		const updatedItem: ICartItem = {
			...currentItemInCart,
			Price: newPrice,
			oldPrice: newOlderPrice,
			pointsRedeemed: newTotalScoreRate,
			OrderQuantity: cartItem.OrderQuantity
		}

		items.splice(cartItemIndex, 1, updatedItem)

		return items
	}
}

export const removeRedemptionItem = (
	state = INITIAL_STATE,
	action: { cartRedemptionItem: ICartItem }
): ICartItem[] => {
	const objJSONStrinfy = JSON.stringify(state)
	const items = JSON.parse(objJSONStrinfy)
	const cartItem = { ...action.cartRedemptionItem }

	const cartItemIndex = items.findIndex(
		(item: ICartItem) => item.MaterialID === cartItem.MaterialID
	)

	if (cartItemIndex === -1) {
		return state
	} else {
		const itemsUpdated = remove(cartItemIndex, 1, items)
		return itemsUpdated as ICartItem[]
	}
}

export const updateRedemptionItems = (
	_ = INITIAL_STATE,
	action: { cartRedemptionItems: ICartItem[] }
): ICartItem[] => action.cartRedemptionItems

export const clearRedemptionItems = (_ = INITIAL_STATE, __: any): ICartItem[] =>
	INITIAL_STATE

export default createReducer<
	ICartItem[],
	Action<ICartRedemptionItemsActionCreators>
>(INITIAL_STATE, {
	[Types.ADD_REDEMPTION_ITEM]: addOrUpdateRedemptionItem,
	[Types.REMOVE_REDEMPTION_ITEM]: removeRedemptionItem,
	[Types.UPDATE_REDEMPTION_ITEM]: addOrUpdateRedemptionItem,
	[Types.UPDATE_REDEMPTION_ITEMS]: updateRedemptionItems,
	[Types.CLEAR_REDEMPTION_ITEMS]: clearRedemptionItems
})
