import { apiAdmin } from '../../../../shared/services/axiosInstace'
import { IResponseGetCoupons } from '../history-coupon.types'

export interface IGetCouponsService {
	pageNumber: string
	pageSize: string
	status: string
	tradeStatus?: string
	name?: string
	couponID?: number
	director?: string
	grc?: string
	cd?: string
	state?: string
	city?: string
	channel?: string
	codePdv?: string
	finished?: boolean
}
export interface IPatchCouponService {
	couponID: string
	status: boolean
	tradeStatus?: string
}
const getCoupons = async (
	{
		pageNumber,
		pageSize,
		status,
		couponID,
		name,
		finished
	}: IGetCouponsService = {
		pageNumber: '1',
		pageSize: '10',
		status: 'true'
	}
): Promise<IResponseGetCoupons> => {
	const response = await apiAdmin.get(`/coupons`, {
		params: {
			pageNumber,
			pageSize,
			status: finished ? true : status,
			couponID,
			...(name && {name: encodeURIComponent(String(name))}),
			finished
		}
	})

	return response.data
}

const enableDisableCoupon = async ({
	couponID: cupomID,
	status,
	tradeStatus
}: IPatchCouponService): Promise<void> => {
	await apiAdmin.patch(`/coupons/status/${cupomID}`, {
		status,
		tradeStatus
	})
}
const getCsvCoupons = async (): Promise<string> => {
	const response = await apiAdmin.get('/coupons/csv', {
		headers: {
			'Content-Type': 'text/csv'
		}
	})
	return response.data
}

const getCuponsByHierarchy = async ({
	pageNumber,
	pageSize,
	status,
	tradeStatus,
	cd,
	channel,
	city,
	codePdv,
	director,
	grc,
	state
}: IGetCouponsService): Promise<IResponseGetCoupons> => {
	let queryString = `origin=COUPON&platform=WEB&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${
		status || 'true'
	}`
	console.log({ tradeStatus })
	if (tradeStatus && ['Em análise', 'Reprovado'].includes(tradeStatus))
		queryString += `&tradeStatus=${encodeURIComponent(tradeStatus)}`

	if (director) queryString += `&director=${encodeURIComponent(director)}`
	if (grc) queryString += `&grc=${encodeURIComponent(grc)}`
	if (cd) queryString += `&cd=${encodeURIComponent(cd)}`
	if (state) queryString += `&state=${encodeURIComponent(state)}`
	if (city) queryString += `&city=${encodeURIComponent(city)}`
	if (channel) queryString += `&channel=${encodeURIComponent(channel)}`
	if (codePdv) queryString += `&codePdv=${encodeURIComponent(codePdv)}`

	const response = await apiAdmin.get(`coupons/filtered?${queryString}`)

	return response.data
}
export { getCoupons, enableDisableCoupon, getCsvCoupons, getCuponsByHierarchy }
