import styled from 'styled-components'

export const WrapperLoading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100vh;
	width: 100vw;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;

	background-color: #00000066;
`
