import { Divider } from "@material-ui/core"
import React from "react"
import { useLocation } from "react-router-dom"
import { AccordionComponent } from "../../../../../../components/Accordion/accordion"
import LayoutModal from "../../../../../../layout/layout-modal/layout-modal.layout"
import { parseItemPrice } from "../../../../../../shared/utils/money"
import { IDataTable } from "../../../../index.types"
import { DividerSimple } from "../../../consult-client-info.styles"
import { Header } from "../../Modal/modalStyles"
import { changeColorByStatus } from "../util"
import {
	OrderIndividualProductsWrapper,
	OrderIndividualProductsDetails,
	OrderModalTitle,
	OrderModalSubtitle,
	OrderDeliveryDetailsContainer,
	FlagQuantityItems,
	RemovedProductTitle,
	RemovedProductFlag
} from "./order-modal.styles"
import { IOrderModal } from "./order-modal.types"

export function OrderModal({
  isOpen,
  handleToggleModal,
  modalData,
  customerAddressInfo
}: IOrderModal){
  const { state }: { state?: { dataReceived?: IDataTable } } = useLocation();
  const props: IDataTable | undefined = state?.dataReceived;
  const rejectedOrders = modalData?.status.toLowerCase().includes('reprovado');
  const statusColorStyle = { color: changeColorByStatus(modalData?.status || '')}

	const individualProducts = modalData?.individualProducts?.map(item => {
		return (
			<div key={item.ProductID}>
				<OrderIndividualProductsWrapper>
					<span>{item.Description}</span>
					<OrderIndividualProductsDetails>
						<p>{`Quantidade: ${item.Quantity} unidades`} </p>
						<span>{`Valor total ${parseItemPrice(item.Price * item.Quantity)}`}</span>
					</OrderIndividualProductsDetails>
				</OrderIndividualProductsWrapper>
				{modalData?.individualProducts?.length > 1 && <Divider />}
			</div>
		)
	})

	const itemsPoints = modalData?.itemsPoints?.map(item => {
		return (
			<div key={item.ProductID}>
				<OrderIndividualProductsWrapper>
					<span>{item.Description}</span>
					<OrderIndividualProductsDetails>
						<p>{`Quantidade: ${item.Quantity} unidades`} </p>
					</OrderIndividualProductsDetails>
				</OrderIndividualProductsWrapper>
				{modalData?.itemsPoints?.length > 1 && <Divider />}
			</div>
		)
	})

	const removedProducts = modalData?.removedProducts?.map(item => {
		return (
			<div key={item.ProductID}>
				<OrderIndividualProductsWrapper>
					<span>{item.Description}</span>
					<OrderIndividualProductsDetails>
						<p>{`Quantidade: ${item.Quantity} unidades`} </p>
						<span>{`Valor total ${parseItemPrice(item.Price * item.Quantity)}`}</span>
					</OrderIndividualProductsDetails>
				</OrderIndividualProductsWrapper>
				{modalData?.removedProducts?.length > 1 && <Divider />}
			</div>
		)
	})
	
  return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			handleClick={handleToggleModal}
			modalTitle={
				<OrderModalTitle>
					<span> Detalhes do pedido </span>
					<p>{`Número # ${modalData?.orderID}`}</p>
				</OrderModalTitle>
			}
			buttonText="Voltar"
			typeLayout="details"
			height={'659px'}
			width={'648px'}
		>
			<Header>
				<OrderModalSubtitle>Detalhes da Entrega</OrderModalSubtitle>
				<OrderDeliveryDetailsContainer>
					<p>Status</p>
					<span style={statusColorStyle}>{modalData?.status}</span>
				</OrderDeliveryDetailsContainer>
				{rejectedOrders && (
					<OrderDeliveryDetailsContainer>
						<p>Motivo</p>
						<span>{modalData?.orderStatusDetail}</span>
					</OrderDeliveryDetailsContainer>
				)}
				<OrderDeliveryDetailsContainer>
					<p>Data da entrega</p>
					<span>{modalData?.deliveryDate}</span>
				</OrderDeliveryDetailsContainer>
				<OrderDeliveryDetailsContainer>
					<p>Destinatário</p>
					<span>{props?.customerName}</span>
				</OrderDeliveryDetailsContainer>
				<OrderDeliveryDetailsContainer>
					<p>Endereço de entrega</p>
					<span>{`${customerAddressInfo?.street}, ${customerAddressInfo?.houseNumber}, ${customerAddressInfo?.district} / ${customerAddressInfo?.region}`}</span>
				</OrderDeliveryDetailsContainer>
				<OrderDeliveryDetailsContainer>
					<p>CEP</p>
					<span>{customerAddressInfo?.postCode}</span>
				</OrderDeliveryDetailsContainer>

				<OrderModalSubtitle>Detalhes da Compra</OrderModalSubtitle>
				<OrderDeliveryDetailsContainer>
					<p>Realizado em</p>
					<span>{modalData?.orderDate}</span>
				</OrderDeliveryDetailsContainer>
				<OrderDeliveryDetailsContainer>
					<p>Valor total</p>
					<span>{parseItemPrice(modalData?.orderPriceTotal || '')}</span>
				</OrderDeliveryDetailsContainer>
				<OrderDeliveryDetailsContainer>
					<p>Forma de pagamento</p>
					<span>{modalData?.paymentMethod}</span>
				</OrderDeliveryDetailsContainer>

				<AccordionComponent
					accordionSummary={
						<OrderModalSubtitle>
							Produto(s) individuais
							<FlagQuantityItems>
								<span>{modalData?.individualProducts?.length}</span>
							</FlagQuantityItems>
						</OrderModalSubtitle>
					}
					accordionDetails={individualProducts}
				/>
				<DividerSimple />

				<AccordionComponent
					accordionSummary={
						<OrderModalSubtitle>
							Produto(s) resgate
							<FlagQuantityItems>
								<span>{modalData?.itemsPoints?.length || 0}</span>
							</FlagQuantityItems>
						</OrderModalSubtitle>
					}
					accordionDetails={itemsPoints}
				/>
				<DividerSimple />

				<AccordionComponent
					accordionSummary={
						<RemovedProductTitle>
							Produto(s) removidos por falta de estoque
							<RemovedProductFlag>
								<span>{modalData?.removedProducts?.length || 0} </span>
							</RemovedProductFlag>
						</RemovedProductTitle>
					}
					accordionDetails={removedProducts}
				/>
			</Header>
		</LayoutModal>
  )
}