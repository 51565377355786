import { createElement, useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { addMinutes } from 'date-fns'
import { pipe, prop } from 'ramda'
import { ActionMeta } from 'react-select'
import { ISelect } from '../../components/SelectPrimary/types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import {
	IPaymentMethodNameEnum,
	PaymentForms
} from '../../shared/interfaces/Payments'
import { Maybe, Nullable } from '../../shared/interfaces/common'
import {
	IFormattedPaymentCondition,
	IPaymentConditions
} from '../../shared/interfaces/customer'
import { IStoreCart } from '../../shared/interfaces/store'
import { getUserDeliveryDate } from '../../shared/services/customer.service'
import {
	getPaymentMethod,
	getPaymentMethodsAtDelivery
} from '../../shared/services/paymentMethod.service'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { Creators as CartChallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'
import { Creators as CartItemsRedemption } from '../../shared/store/ducks/cartRedemptionItem'
import { sendOrderTransactionTag } from '../../shared/utils/allin'
import { checkingIfAnyChallengeItemConflictWithCombos } from '../../shared/utils/cart'
import { formatMonthAndYear } from '../../shared/utils/date'
import { add } from '../../shared/utils/math'
import { formatSaleOrder } from '../../shared/utils/order'
import { cleanDocumentNumber } from '../../shared/utils/string'
import { calcItemPriceWithTax } from '../../shared/utils/tax'
import { checkCPF, validatorMonthAndYear } from '../../shared/utils/validators'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { PaymentOptions } from './Payment/types'
import { getQRCode, sendOrder, sendOrderCreditCardRequest } from './service'
import {
	ErrorsActions,
	ICreditCard,
	IPaymentSteps,
	IViewProps,
	PaymentInfo
} from './types'
import {
	FlagValues,
	INITIAL_STEP,
	OrderDetailsModel,
	cardTypes,
	cleanCreditCard,
	creditCardType,
	formatPaymentConditions,
	reducerErros,
	validateCreditCard
} from './util'
import OrderSummary from './view'
import handleError from '../Error/handleError'
import { IError } from '../Error/types'
import { getMaterials } from '../ProductList/service'
import { getComboList } from '../Combo/service'
import { compareCombosInCart, compareProductsInCart } from '../../utils/compare-products-in-cart'
import cogoToast from 'cogo-toast'

function OrderSummaryContainer(): JSX.Element {
	const {
		customer,
		carts,
		companyParams,
		cartRedemptionItems,
		cartChallengeItems
	} = useTypedSelector([
		'customer',
		'carts',
		'token',
		'cartRedemptionItems',
		'companyParams',
		'cartChallengeItems'
	])
	const dispatch = useDispatch()

	const items = carts.map((cart) => cart.items).flat()
	const itemsChallenge = cartChallengeItems
		.map((cartChallengeItem) => cartChallengeItem.addedProducts)
		.flat()

	const [isLoading, setIsLoading] = useState(false)
	const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>()
	const [dateToDelivery, setDateToDelivery] = useState<ISelect | null>(null)

	const [allCartsState, setAllCartsState] = useState<IStoreCart[]>([])
	const [paymentMethodsDelivery, setPaymentMethodsDelivery] = useState<
		IPaymentConditions[]
	>([])
	const [paymentAtDeliveryConditions, setPaymentAtDeliveryConditions] =
		useState<IFormattedPaymentCondition[]>([])

	useEffect(() => {
		setPaymentAtDeliveryConditions(
			formatPaymentConditions(paymentMethodsDelivery)
		)
	}, [paymentMethodsDelivery])

	const [orderDetails] = useState<OrderDetailsModel>(
		new OrderDetailsModel({
			customer,
			companyParams,
			cartPurchaseItems: items,
			cartChallengeItemsPurchase: itemsChallenge
		})
	)
	const [condition, setCondition] = useState<
		IFormattedPaymentCondition | undefined
	>(!!orderDetails.conditions.length ? orderDetails.conditions[0] : undefined)

	const [paymentMethod, setPaymentMethod] = useState<
		IPaymentMethodNameEnum[]
	>([])

	const [enableButton, setEnableButton] = useState(false)

	const [creditCard, setCreditCard] = useState<ICreditCard>({
		cardNumber: '',
		cpf: '',
		cvv: '',
		dueDate: '',
		ownerName: '',
		installmentCount: ''
	} as ICreditCard)

	const [payment, setPayment] = useState(0)

	const [fieldsErros, setFieldsErros] = useReducer(reducerErros, {
		cardNumberValueError: '',
		cardSecurityCodeValueError: '',
		namePrintedOnCardValueError: '',
		validityCreditCardValueError: '',
		documentNumberError: ''
	})

	// const [splittedOrderItems, setSplittedOrderItems] = useState<ISplittedOrders[]>([])
	// const [isCheckingCombos, setIsCheckingCombos] = useState(false)

	const fmtPrice = pipe(prop('Price') as any, Number)

	const totalPriceNormalItems = items.map(fmtPrice).reduce(add, 0)
	const totalPriceChallengeItems = itemsChallenge.map(fmtPrice).reduce(add, 0)

	const totalPrice = totalPriceNormalItems + totalPriceChallengeItems

	const [paymentSteps, setPaymentSteps] =
		useState<IPaymentSteps>(INITIAL_STEP)

	const [stepCurrent, setStepCurrent] = useState(1)

	const history = useHistory()

	function handleBack() {
		history.replace(CustomerRoutesEnum.CART)
	}

	const fetchUserDeliveryDate = async () => {
		return await getUserDeliveryDate({
			customerID: customer.CustomerID,
			salesOrganizationID: customer.SalesOrganizationID,
			sectorID: customer.sectorID
		})
	}

	async function returnSalesOrder(paymentMethod: IFormattedPaymentCondition) {
		const userDeliveryDate = await fetchUserDeliveryDate()
		const selectedCondition: IFormattedPaymentCondition = {
			...paymentMethod
		}
		return allCartsState.map((cart, index) => {
			const saleOrder = formatSaleOrder({
				salesOrderID: cart.salesOrderID,
				customer,
				condition: selectedCondition,
				deliveryDate: userDeliveryDate[0].deliveryDate,
				paymentTax: 0,
				items: cart.items,
				itemsChallenge: cart.itemsChallenge || [],
				redemptionItems: index === 0 ? cartRedemptionItems : []
			})

			return saleOrder
		})
	}

	async function sendOrderPix() {
		try {
			const arrayOfSaleOrder = await returnSalesOrder({
				PaymentMethod: PaymentForms.P,
				PaymentMethodDescription: 'pix',
				PaymentTerms: 'R001',
				PaymentTermsDescription: ''
			})

			const expireAt = addMinutes(new Date(), 30).toISOString()

			const pix = await getQRCode({
				customerCpfCnpj:
					customer.PersonalFiscalID || customer.CompanyFiscalID,
				customerID: customer.CustomerID,
				customerName: customer.Name,
				items: arrayOfSaleOrder,
				expireAt
			})

			const updated = allCartsState.map((cart) => {
				return { ...cart, transaction: pix }
			})

			dispatch(CartActions.updateCart(updated))

			const userDeliveryDate = await fetchUserDeliveryDate()

			history.replace(CustomerRoutesEnum.PIX_QR_CODE, {
				deliveryDate: userDeliveryDate[0].deliveryDate,
				pix,
				valueTotal: String(paymentInfo?.total),
				hasScreen: true,
				paymentConditionID: 'R001',
				paymentFormID: 'P'
			})
		} catch (error) {
		} finally {
		}
	}

	async function sendOrderCreditCard() {
		try {
			const arrayOfSaleOrder = await returnSalesOrder({
				PaymentMethod: PaymentForms.X,
				PaymentMethodDescription: 'creditCard',
				PaymentTerms: 'R001',
				PaymentTermsDescription: ''
			})

			const { cardNumber, cpf, cvv, dueDate, ownerName } = creditCard
			const credit = creditCardType(creditCard.cardNumber ?? '')

			const creditCardInformation = {
				cpf,
				paymentOperator: 'Cielo',
				cardNumber: cardNumber ?? '',
				ownerName: ownerName ?? '',
				cvv: cvv ?? '',
				dueDate: dueDate ? cleanDocumentNumber(dueDate) : '',
				installmentCount: '1',
				flag: FlagValues[credit ? credit : cardTypes.OUTROS] || '99'

				//TODO: ONLY TESTING
				// paymentOperator: 'Cielo',
				// cardNumber: '0000000000000000',
				// ownerName: 'USUARIO',
				// dueDate: '0529',
				// cvv: '123',
				// cpf: '12312321',
				// installmentCount: '1',
				// flag: '10',
			}

			const creditCardResponse = await sendOrderCreditCardRequest({
				customerCpfCnpj:
					customer.PersonalFiscalID || customer.CompanyFiscalID,
				customerID: customer.CustomerID,
				customerName: customer.Name,
				items: arrayOfSaleOrder,
				creditCardInformation
			})

			const props = {
				deliveryDate: dateToDelivery,
				paymentConditionID: 'R001',
				paymentFormID: 'X',
				paymentType: 'ONLINE',
				valueTotal: String(paymentInfo?.total),
				paymentMethod: 'creditCard',
				transactionId: creditCardResponse.transactionId
			}

			history.replace(CustomerRoutesEnum.SUCCESS, {
				...props
			})
		} catch (error) {
			error === 'string' && cogoToast.error(error)
		} finally {
		}
	}

	async function handleFinalization() {
		try {
			const userDeliveryDate = await fetchUserDeliveryDate()

			setIsLoading(true)

			const getPayment = paymentSteps.step1.options?.find(
				(x) => x.active && x.index > 0
			)?.index

			if (getPayment === PaymentOptions.PAY_WITH_PIX) {
				await sendOrderPix()
				return
			}

			if (getPayment === PaymentOptions.PAY_WITH_CREDIT_CARD) {
				await sendOrderCreditCard()
				return
			}

			if (!condition) return

			const sendOrdersPromise = allCartsState.map((cart, index) => {
				cart.items.forEach((item) => {
					if (item.promotionType === 2) {
						delete item.operationTypeDescription
					}
				})

				const saleOrder = formatSaleOrder({
					customer,
					condition,
					items: cart.items,
					deliveryDate: userDeliveryDate[0].deliveryDate,
					salesOrderID: cart.salesOrderID,
					itemsChallenge: cart.itemsChallenge || [],
					paymentTax: paymentInfo?.tax || 0,
					redemptionItems: index === 0 ? cartRedemptionItems : []
				})

				sendOrderTransactionTag(
					cart.salesOrderID,
					cart.items,
					customer.Email
				)

				if (cartRedemptionItems.length) {
					sendOrderTransactionTag(
						cart.salesOrderID,
						cartRedemptionItems,
						customer.Email
					)
				}

				if (cart.itemsChallenge?.length) {
					sendOrderTransactionTag(
						cart.salesOrderID,
						cart.itemsChallenge,
						customer.Email
					)
				}

				return sendOrder(saleOrder)
			})

			await Promise.all(sendOrdersPromise)

			dispatch(CartActions.clearCart())
			dispatch(CartItemsRedemption.clearRedemptionItems())
			dispatch(CartChallengeItemsActions.clearChallengeItems())

			const props = {
				deliveryDate: userDeliveryDate[0].deliveryDate,
				paymentConditionID: condition.PaymentTerms,
				paymentFormID: condition.PaymentMethod,
				paymentType: 'DELIVERY',
				valueTotal: String(paymentInfo?.total),
				paymentMethod: '',
				transactionId: ''
			}

			history.replace(CustomerRoutesEnum.SUCCESS, {
				...props
			})
		} catch (error) {
			handleError(error as IError)
		} finally {
			setIsLoading(false)
		}
	}

	function listPaymentMethod() {
		setPaymentSteps({
			...paymentSteps,
			step1: {
				active: true,
				options: [
					{
						index: PaymentOptions.PAY_NOW_ONLINE,
						active: false
					},
					{
						index: PaymentOptions.PAY_ON_DELIVERY,
						active: false
					},
					{
						index: PaymentOptions.PAY_WITH_CREDIT_CARD,
						active: false
					},
					{
						index: PaymentOptions.PAY_WITH_PIX,
						active: false
					}
				]
			}
		})
		;(async () => {
			try {
				setIsLoading(true)
				const pay = await getPaymentMethod(customer.SalesOrganizationID)
				setPaymentMethod(pay.map((method) => method.name))

				const paymentMethodsAtDelivery =
					await getPaymentMethodsAtDelivery(
						customer.SalesOrganizationID,
						customer.CustomerID
					)
				setPaymentMethodsDelivery(paymentMethodsAtDelivery)
			} catch (error) {
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleChangeCreditCard(id: string, value: string) {
		let valueFormated = value

		if (id === 'dueDate') {
			valueFormated = formatMonthAndYear(value)
		}

		setCreditCard({ ...creditCard, [id]: valueFormated })
	}

	const checkingIfInputsValueAreValid = () => {
		if (creditCard.ownerName && creditCard.ownerName.length < 2) {
			setFieldsErros({
				type: ErrorsActions.NAME_PRINTED_ON_CARD,
				payload: 'Nome impresso no cartão inválido'
			})
		} else {
			setFieldsErros({
				type: ErrorsActions.NAME_PRINTED_ON_CARD,
				payload: ''
			})
		}

		if (!!creditCard.cardNumber) {
			if (
				!validateCreditCard(cleanCreditCard(creditCard.cardNumber)) ||
				creditCardType(cleanCreditCard(creditCard.cardNumber)) === ''
			) {
				setFieldsErros({
					type: ErrorsActions.CARD_NUMBER,
					payload: 'Número do cartão é inválido'
				})
			} else {
				setFieldsErros({
					type: ErrorsActions.CARD_NUMBER,
					payload: ''
				})
			}
		}

		if (
			!!creditCard.dueDate &&
			!validatorMonthAndYear(creditCard.dueDate)
		) {
			setFieldsErros({
				type: ErrorsActions.VALIDITY_CREDIT_CARD,
				payload: 'Data inválida'
			})
		} else {
			setFieldsErros({
				type: ErrorsActions.VALIDITY_CREDIT_CARD,
				payload: ''
			})
		}

		if (!!creditCard.cvv) {
			if (creditCard.cvv.length < 3 || !Number(creditCard.cvv)) {
				setFieldsErros({
					type: ErrorsActions.CARD_SECURITY_CODE,
					payload: 'CVV inválido'
				})
			} else {
				setFieldsErros({
					type: ErrorsActions.CARD_SECURITY_CODE,
					payload: ''
				})
			}
		}

		if (!!creditCard.cpf && !checkCPF(creditCard.cpf)) {
			setFieldsErros({
				type: ErrorsActions.DOCUMENT_NUMBER,
				payload: 'CPF inválido'
			})
		} else {
			setFieldsErros({
				type: ErrorsActions.DOCUMENT_NUMBER,
				payload: ''
			})
		}
	}

	function handleChangePaymentCondition(
		itemClicked: IFormattedPaymentCondition
	): void {
		setCondition(itemClicked)

		let total = 0

		items.forEach((item) => {
			total +=
				Number(
					calcItemPriceWithTax(
						Number(item.Price) / item.OrderQuantity,
						itemClicked.TaxRate || 0
					).toFixed(2)
				) * item.OrderQuantity
		})

		itemsChallenge.forEach((item) => {
			total +=
				Number(
					calcItemPriceWithTax(
						Number(item.Price) / item.OrderQuantity,
						itemClicked.TaxRate || 0
					).toFixed(2)
				) * item.OrderQuantity
		})

		setPaymentInfo({
			paymentMethod: itemClicked.PaymentTermsDescription,
			tax: itemClicked?.TaxRate || 0,
			total
		})
	}

	function handleSelectThree(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<ISelect>
	): void {
		setDateToDelivery(selectedValue as ISelect)
	}

	const settingContinueButtonToDisabled = () => {
		const {
			cardNumberValueError,
			cardSecurityCodeValueError,
			namePrintedOnCardValueError,
			validityCreditCardValueError,
			documentNumberError
		} = fieldsErros

		const fieldsWithErrorMessages =
			cardNumberValueError ||
			cardSecurityCodeValueError ||
			namePrintedOnCardValueError ||
			validityCreditCardValueError ||
			documentNumberError

		const theFieldsAreEmpty =
			!creditCard.cardNumber ||
			!creditCard.ownerName ||
			!creditCard.cpf ||
			!creditCard.cvv ||
			!creditCard.installmentCount ||
			!creditCard.dueDate

		if (fieldsWithErrorMessages || theFieldsAreEmpty) {
			setEnableButton(false)
		} else {
			setEnableButton(true)
		}
	}

	function handlerSelectPaymentMethod(
		option: PaymentOptions[],
		buttonConfirm: boolean
	) {
		const paymentStep = paymentSteps.step1
		if (paymentStep.options) {
			paymentStep.options.map((_option) => {
				_option.active = false
			})
			paymentStep.options.find((_option) => {
				option.map((o) => {
					if (_option.index == o) {
						_option.active = true
						if (
							o == PaymentOptions.PAY_WITH_PIX ||
							o == PaymentOptions.PAY_ON_DELIVERY
						) {
							setCreditCard({
								cardNumber: '',
								cpf: '',
								cvv: '',
								dueDate: '',
								ownerName: ''
							})
						}
					}
				})
			})

			const payment = paymentStep.options.find(
				(_option) => _option.active && _option.index > 0
			)
			setPayment(payment?.index || 1)
			const { step2, step3 } = paymentSteps

			setPaymentSteps({
				step1: {
					active: true,
					options: paymentStep.options
				},
				step2,
				step3,
				buttons: {
					confirm: buttonConfirm
				}
			})
		}
	}

	function handlerChangeStep(step: number) {
		paymentSteps.step1.active = false
		paymentSteps.step2.active = false
		paymentSteps.step3.active = false
		if (step === 1) {
			paymentSteps.step1.active = true
		}
		if (step === 2) {
			paymentSteps.step2.active = true
		}
		if (step === 3) {
			paymentSteps.step3.active = true
		}
		setPaymentSteps({
			step1: paymentSteps.step1,
			step2: paymentSteps.step2,
			step3: paymentSteps.step3,
			buttons: paymentSteps.buttons
		})
		setStepCurrent(step)
	}

	const reconciliateCartWithAvaiableProducts = useCallback(async () => {
		const {
			sectorID,
			SegmentID,
			CustomerID,
			ChannelGroupID,
			SalesOrganizationID
		} = customer
		
		const materials = await getMaterials({
			salesOrganizationID: SalesOrganizationID,
			segmentID: SegmentID,
			sectorID: sectorID,
			customerID: CustomerID,
			morePoints: false,
			channelGroupID: ChannelGroupID,
			includesDraftBeer: customer.draftBeer,
			ignoreThrow: true
		}) || []

		const combos = await getComboList({
			sectorID: sectorID,
			customerID: CustomerID,
			salesOrganizationID: SalesOrganizationID,
			ignoreThrow: true
		})
		

		const productsInCart = compareProductsInCart({
			cartProducts: items,
			products: materials
		})

		const combosInCart = compareCombosInCart({
			cartProducts: items,
			combos: combos
		})

		const allCombosAndProducts = [...combosInCart.items, ...productsInCart.items]

		if (combosInCart.hasChanges || productsInCart.hasChanges) {
			cogoToast.success('Produtos alterados, verifique novamente seu carrinho!')
		}

		const updatedCart = carts.map((items) => {
			return { ...items, items: allCombosAndProducts }
		})
		
		dispatch(
			CartActions.updateCart(
				updatedCart[0]?.items.length === 0 ? [] : updatedCart
			)
		)
	}, [carts, customer, dispatch, items])

	useEffect(() => {
		checkingIfAnyChallengeItemConflictWithCombos(
			setAllCartsState,
			carts,
			cartChallengeItems
		)
	}, [carts, cartChallengeItems])

	useEffect(listPaymentMethod, [])
	useEffect(checkingIfInputsValueAreValid, [creditCard])
	useEffect(settingContinueButtonToDisabled, [fieldsErros, creditCard])
	
	useEffect(() => {
		reconciliateCartWithAvaiableProducts()
	}, [])

	const viewProps: IViewProps = {
		isLoading,
		handleBack,
		handleChangePaymentCondition,
		handleFinalization,
		paymentInfo,
		dateToDelivery,
		handleSelectThree,
		orderDetails,
		condition,
		cartRedemptionItems,
		totalPrice,
		paymentMethod,
		handleChangeCreditCard,
		creditCard,
		fieldsErros,
		enableButton,
		handlerSelectPaymentMethod,
		handlerChangeStep,
		paymentSteps,
		stepCurrent,
		payment,
		allCartsState,
		paymentMethodsAtDelivery: paymentAtDeliveryConditions
	}

	return createElement(OrderSummary, viewProps)
}

export default OrderSummaryContainer
