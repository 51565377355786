import styled from "styled-components";
import { Switch } from "@material-ui/core"

import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: flex-end;
`;

export const CheckIcon = styled(Check)`
  font-size: ${props => props.theme.font.size.TEXT_MD.size};
  color: ${props => props.theme.palette.primary.main};
`;

export const CloseIcon = styled(Close)`
  font-size: ${props => props.theme.font.size.TEXT_MD.size};
  color: ${props => props.theme.palette.white.main};
`;

export const ThumbContainer = styled.div<{ selected: boolean; }>`
    background-color: ${props => props.selected ? props.theme.palette.white.main : props.theme.palette.gray.main};
    margin-top: 3px;
    margin-left: -${props => props.theme.spacingOptions.XXXSM}px;
    border-radius: ${props => props.theme.spacingOptions.XSM + props.theme.spacingOptions.XXSM}px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => props.theme.spacingOptions.MD}px;
    height: ${props => props.theme.spacingOptions.MD}px;
`;

export const Title = styled.p`
  font-family: Inter;
  font-size: ${props => props.theme.font.size.TEXT_MD.size};
  line-height: ${props => props.theme.font.size.TEXT_MD.line_height};
  font-weight: 400;
  text-align: left;
  margin-bottom: ${props => props.theme.spacingOptions.XSM}px
`;

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  maxWidth: 52,
  maxHeight: 32,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: theme.palette.white.main,
      transform: 'translateX(25px)',

      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        border: `2px solid ${theme.palette.primary.main}`
      },
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    borderRadius: 100,
    left: 0,
    top: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',

    backgroundColor: theme.palette.white.main,
    border: `2px solid ${theme.palette.gray.main}`
  },
}));