import { useEffect, useMemo, useState } from 'react'
import {
	getUnicoAccessToken,
	unicoCreateProcesses,
	unicoDocumentInsert,
	unicoExecute,
	useUnicoGetProcess
} from '../services/customer.service'
import {
	DocumentType,
	DocumentTypeNumber
} from '../../modules/NewAccount/Step/types'

export interface IUseUnicoProcessImages {
	documentNumber: string
	name: string
	selectedDocumentType: DocumentType
	imageBase64Selfie: string
	imageBase64Front: string
	imageBase64Back?: string
}

function useUnicoProcess() {
	const [processId, setProcessId] = useState('')
	const [isLoadingProcessImage, setLoadingProcessImage] = useState(false)
	const [accessTokenUnico, setAccessTokenUnico] = useState('')

	const {
		data: unicoProcess,
		isFetching: isFetchingUnicoProcess,
		isError
	} = useUnicoGetProcess({
		accessTokenUnico: isLoadingProcessImage ? '' : accessTokenUnico,
		processoId: processId
	})

	const cleanProcessIdOnError = () => {
		if (unicoProcess?.Status !== 1) {
			setProcessId('')
			return
		}
		if (!isError) return

		setProcessId('')
	}

	useEffect(cleanProcessIdOnError, [isError, unicoProcess])

	const isLoading = useMemo(() => {
		return isLoadingProcessImage || isFetchingUnicoProcess
	}, [isLoadingProcessImage, isFetchingUnicoProcess])

	const processImages = async ({
		documentNumber,
		name,
		selectedDocumentType,
		imageBase64Selfie,
		imageBase64Front,
		imageBase64Back
	}: IUseUnicoProcessImages) => {
		try {
			setLoadingProcessImage(true)

			const responseAccessToken = await getUnicoAccessToken()
			const accessToken = responseAccessToken.accessTokenUnico
			setAccessTokenUnico(accessToken)

			const responseCreateProcess = await unicoCreateProcesses({
				accessTokenUnico: accessToken,
				cpf: documentNumber,
				name,
				imagebase64Selfie: imageBase64Selfie
			})

			const processId = responseCreateProcess.Id

			await unicoDocumentInsert({
				accessTokenUnico: accessToken,
				imagebase64: imageBase64Front,
				processoId: processId,
				type:
					selectedDocumentType === DocumentType.RG
						? DocumentTypeNumber.RG_FRENTE
						: DocumentTypeNumber.CNH
			})

			if (imageBase64Back) {
				await unicoDocumentInsert({
					accessTokenUnico: accessToken,
					imagebase64: imageBase64Back,
					processoId: processId,
					type: DocumentTypeNumber.RG_VERSO
				})
			}

			await unicoExecute({
				accessTokenUnico: accessToken,
				processoId: processId
			})

			setProcessId(processId)
		} catch (error) {
			throw new Error(
				(error as any)?.message || 'Erro ao capturar imagem'
			)
		} finally {
			setLoadingProcessImage(false)
		}
	}

	return { isLoading, processImages, unicoProcess }
}

export { useUnicoProcess }
