import { ICartComboProduct } from '../interfaces/cart'
import { IMaterial } from '../interfaces/material'

export function returnHighestPrice(
	arrayOfPrice: number[],
	productItem: IMaterial
): number {
	const findPrice = productItem?.PriceByQuantity?.find(
		(price) => Number(price.Quantity) === 1
	)?.Price

	return Number(findPrice) || getHighestPrice(arrayOfPrice)
}

export function getHighestPrice(arrayOfNumber: number[]) {
	return arrayOfNumber.reduce((acc, number) => (number > acc ? number : acc))
}
export function getLowerPrice(arrayOfNumber: number[]) {
	return arrayOfNumber.reduce((acc, number) => (number < acc ? number : acc))
}

export function getComboPriceTotalWithoutBonus(
	items: ICartComboProduct[]
): number {
	return items.reduce((acc, currentValue) => {
		const maxAmount = Number(currentValue.price) || 0
		const quantity = Number(currentValue.quantity) || 0
		const productTotal = maxAmount * quantity

		return acc + productTotal
	}, 0)
}

export function getFullPrice(items: ICartComboProduct[]): number {
	return items.reduce((acc, currentValue) => {
		const isBonus = currentValue.tradeType === 'Z4BN'
		const price = isBonus
			? Number(currentValue.oldPrice || 0)
			: Number(currentValue.price || 0)
		const quantity = Number(currentValue.quantity) || 0
		const productTotal = price * quantity

		return acc + productTotal
	}, 0)
}
