import {
	ISacUser,
	IDeleteUserPayload,
	IUserGroup,
	UserGroupEnum,
	ICompanie
} from '../interfaces/sacUser'
import { axiosGPInstance } from './axiosInstace'

export async function fetchUsersPage(): Promise<ISacUser[]> {
	const response = await axiosGPInstance.get('/user/get-page')

	return response.data
}

export async function fetchUserGroups(): Promise<IUserGroup[]> {
	const response = await axiosGPInstance.get('/user/groups')

	return response.data
}
export interface INewUserValues {
	user: string
	groupId: string
	group: UserGroupEnum
	salesOrganizationID?: string
	salesOrganizationsIDs: ICompanie[]
}

interface ISaveUserProps {
	oldValues?: ISacUser
	newValues: INewUserValues
}

export async function saveUser(payload: ISaveUserProps) {
	const response = await axiosGPInstance.post('/user', payload)

	return response.data
}

export async function deleteUser(payload: IDeleteUserPayload) {
	const response = await axiosGPInstance.post('/user/delete', payload)

	return response.data
}
