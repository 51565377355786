import React from 'react'
import ShoppingCartIcon from '../../assets/images/shopping-cart24.svg'
import GPLogoRedIcon from '../../assets/images/logo-bp-red.svg'

function FinishOrderHeader(): JSX.Element {
	return (
		<>
			<div className="container header">
				<div className="row">
					<div className="col-6 logo">
						<img src={GPLogoRedIcon} alt="Grupo Petrópolis" />
					</div>
					<div className="col-6 finish-order">
						<img src={ShoppingCartIcon} alt="Carrinho de Compras" />
						<h2>Finalizar Pedido</h2>
					</div>
				</div>
			</div>
			<div className="hr-line">
				<div className="row">
					<hr />
				</div>
			</div>
		</>
	)
}

export default FinishOrderHeader
