export type FilterOptions = {
	director: string[]
	grc: string[]
	cd: string[]
	state: string[]
	city: string[]
	channel: string[]
	codePdv: string[]
}

export type CreateCoupon = {
	filters: FilterOptions
	coupon: {
		name: string
		code: string
		couponType: 'item' | 'cart'
		minOrderValue: number
		discountType: 'percent' | 'money'
		discountValue: number
		cumulative: boolean
		dateStart: string
		dateEnd: string
		discountCategory: string
		quantityAvailable: number
		showIn: 'APP' | 'NONE'
		discountItems?: {
			materialID: string
			category: string
			brand: string
			package: string
			description: string
		}[]
		cupomUsedCount: number
		status: boolean
		tradeStatus: 'Em análise' | 'Aprovado' | 'Reprovado'
		isPdvRelated: boolean
		amountOfCustomers: string
	}
}

export interface ICouponRequestProps {
	couponID: number
}

export enum ShowIn {
	NONE = 'NONE',
	BOTH = 'BOTH',
	APP = 'APP',
	WEB = 'WEB'
}

export enum CouponType {
	ITEM = 'item',
	CART = 'cart'
}

export enum DiscountType {
	MONEY = 'money',
	PERCENT = 'percent'
}

export interface IProduct {
	label: string
	options: {
		value: string
		label: string
		package: string
		categoryID: string
		brand?: string
	}[]
}

export interface IDiscountItems {
	ID: string
	materialID: string
	couponID: number
	category: string
	brand: string
	package: string
	description: string
}
export interface ICouponResponseProps {
	ID: number
	couponID: number
	name: string
	code: string
	couponType?: CouponType
	minOrderValue: number
	discountType?: DiscountType
	discountValue: number
	cumulative: boolean
	dateStart: Date
	dateEnd: Date
	discountCategory: string
	quantityAvailable: number
	showIn?: ShowIn
	discount_items?: IDiscountItems[]
	filters: FilterOptions
	amountOfCustomers: string
	isPdvRelated?: boolean
	creationStatus?: string
	cupomUsedCount: number
	status: boolean
	tradeStatus: 'Em análise' | 'Aprovado' | 'Reprovado'
}

export type EditCouponValidationSchema = {
	name: string
	minOrderValue: number
	discountValue: number
	cumulative: boolean
	dateStart: Date
	dateEnd: Date
	quantityAvailable: number
}
