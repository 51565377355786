import React, { ChangeEvent, useCallback } from 'react'
import cogoToast from 'cogo-toast'

import cogoDefaultOptions from '../../../shared/utils/toaster'

import { IActionBarForAddingChallengeItemsToCartProps } from './types'

import * as S from './styles'

export default function ActionBarForAddingChallengeItemsToCart(
	props: IActionBarForAddingChallengeItemsToCartProps
) {
	const {
		disabled = false,
		minQuantity = 0,
		maxQuantity = 999,
		actionToAddItemInTheCart,
		quantitySelectedToAddInTheCart,
		setQuantitySelectedToAddInTheCart,
		disableDecreaseTheAmountOfChallengeItem = false
	} = props

	const handleIncrement = () => {
		const incrementedValue = quantitySelectedToAddInTheCart + 1

		if (incrementedValue > maxQuantity) {
			cogoToast.error(
				`Quantidade em estoque: ${maxQuantity}`,
				cogoDefaultOptions
			)
			return
		}
		setQuantitySelectedToAddInTheCart((prevState) => {
			return prevState + 1
		})
	}

	const handleDecrement = () => {
		const decrementedValue = quantitySelectedToAddInTheCart - 1
		if (decrementedValue < minQuantity) return
		setQuantitySelectedToAddInTheCart((prevState) => {
			return prevState - 1
		})
	}

	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value
			if (value.length > 3) return
			setQuantitySelectedToAddInTheCart(Number(value))
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	const decrementButtonShouldBeDisabled =
		quantitySelectedToAddInTheCart === 0 ||
		disableDecreaseTheAmountOfChallengeItem

	const incrementButtonShouldBeDisabled =
		quantitySelectedToAddInTheCart >= maxQuantity

	return (
		<S.ActionBarWrapper>
			<S.IncrementAndDecrementButtonsWrapper>
				<S.DecrementButton
					disabled={decrementButtonShouldBeDisabled || disabled}
					onClick={handleDecrement}>
					<span>-</span>
				</S.DecrementButton>
				<S.InputWithValueWrapper>
					<input
						type="number"
						maxLength={3}
						disabled={disabled}
						max={maxQuantity}
						onChange={handleChange}
						value={quantitySelectedToAddInTheCart.toString()}
					/>
				</S.InputWithValueWrapper>
				<S.IncrementButton
					disabled={incrementButtonShouldBeDisabled || disabled}
					onClick={handleIncrement}>
					<span>+</span>
				</S.IncrementButton>
			</S.IncrementAndDecrementButtonsWrapper>
			<S.AddButton disabled={disabled} onClick={actionToAddItemInTheCart}>
				Adicionar
			</S.AddButton>
		</S.ActionBarWrapper>
	)
}
