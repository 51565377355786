export interface TradePromotionSegment {
	results: TradePromotionSegmentsResult[]
}

export interface TradePromotionSegmentsResult {
	promotionID: string
	customerSegmentID: string
	customerGroupID: string
	timestamp: string
	changeType: string
	changeTime: string
}

export interface IComboItem {
	promotionID: string
	productID: string
	tradeType: TradeType
	quantity: string
	minAmount: string
	maxAmount: string
	price: number
	utilizedQuantity: string
	customerSegmentID: string
	maxQuantityTradePromotion: number
	numeratorConversion: number
	timestamp: string
	changeType: string
	changeTime: string
	unitMeasureSale: string
	description: string
	productImageURL: string
	actionCode: string
	priceTotal: string
}

export enum TradeType {
	Z4VN = 'Z4VN', // Venda
	Z4BN = 'Z4BN' // Bonificação
}

export type TradeTypeEnum = keyof typeof TradeType

export interface ICombo {
	sectorID: string
	salesOrganizationID: string
	promotionID: string
	promotionType: number
	promotionShortName: string
	description: string
	startDate: string
	endDate: string
	forNewCustomers: string
	daysNewRegisteredCustomer: number
	maxQuantityOrder: number
	maxQuantityPeriod: number
	createdBy: string
	costCenter: string
	status: string
	departmentID: string
	applicantID: any
	investmentNumber: any
	timestamp: string
	createDate: string
	typeAction: any
	destination: string
	changeType: string
	changeTime: string
	isCustomers: number
	TradePromotionItens: {
		results: IComboItem[]
	}
	TradePromotionSegments: TradePromotionSegment
	TradePromotionPayments: any
	TradePromotionCustomers: any
	productImageURL: string
	tradePrice: number
	comboImageUrl: string
	comboPriceTotal: number
	maxQuantityTradePromotion: number
	isComboFlex?: boolean
	GroupsTypes?: IGroupTypes
	total: number
	actionPriceFinalSalesValue?: number
	actionPriceUndiscountedSalesValue?: number;
}

export interface IGroupTypes {
	results: IResultItems[]
}

export interface IResultItems {
	groupID: string
	groupName: number
	groupType: string
	quantityGroup: string
	minPriceGroup: number
	actionCodeGroup: string
	items: IGroupTypesItems[]
}

export interface IGroupTypesItems {
	promotionID: string
	productID: string
	tradeType: string
	quantity: string
	minAmount: string
	maxAmount: string
	price: string
	utilizedQuantity: string
	customerSegmentID: string
	unitMeasureSale: string
	numeratorConversion: number
	description: string
	productImageURL?: string
	packageSize: string
	quantitySales?: string
	groupID: string
	groupType: string
	groupName: number
	timestamp: string
	actionCode: string
	changeType: string
	priceIsMaxAmount: boolean
	priceTotal: string
	material: any
	unitPriceInPackage: string
}
