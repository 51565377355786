import React, { Fragment, useCallback } from 'react'

import GPLogo from '../../assets/images/logo-bp.svg'
import MenuWhiteIcon from '../../assets/images/menu-white.svg'
import NavBar from '../../modules/NavBar'
import { useBoolean } from '../../shared/hooks/useBoolean'
import CartSummary from '../CartSummary'

import * as S from './styles'
import { IProps } from '../Header/types'
import { useHistory } from 'react-router-dom'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'
import { Skeleton } from '@mui/material'

function Header({ disabledMenuCart, isLoading }: IProps): JSX.Element {
	const [isMenuOpen, , , toggle] = useBoolean(false)
	const history = useHistory()

	const handleGoBackHome = useCallback(() => {
		history.push(CustomerRoutesEnum.PRODUCT_LIST)
	}, [history])

	return (
		<Fragment>
			<NavBar isOpen={isMenuOpen} handleSetIsOpen={toggle} />
			<S.HeaderContainer>
				{!disabledMenuCart && (
					<div className="button-menu-wrapper">
						<button className="button-menu" onClick={toggle}>
							<img src={MenuWhiteIcon} alt="menu" />
						</button>
					</div>
				)}

				<div
					className="gp-logo-wrapper"
					onClick={() => handleGoBackHome()}
					style={{ cursor: 'pointer' }}>
					<picture>
						<source
							className="gp-logo"
							media="(max-width: 767px)"
							srcSet={GPLogo}
						/>
						<img
							className="gp-logo"
							src={GPLogo}
							alt="Grupo Petrópolis"
						/>
					</picture>
				</div>

				{!disabledMenuCart && (
					<div className="cart-summary-wrapper">
						{isLoading ? (
							<Skeleton
								variant="rectangular"
								width={260}
								height={26}
							/>
						) : (
							<CartSummary />
						)}
					</div>
				)}
			</S.HeaderContainer>
		</Fragment>
	)
}

export default Header
