import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const NoLocationServiceContainer = styled.div`
    width: 100vw;
    height: 100vh;

    background-color: ${colors.fifthGray};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span:nth-child(2) {
        color: ${colors.secondGrey};
        font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
        margin-top: 24px;
    }
    
    span + span {
        color: ${colors.sixthGray};
        font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
        font-weight: ${(props) => props.theme.font.weight.medium};
        text-align: center;
        max-width: 236px;
        margin-top: 8px;
        line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
    }
`;
