import styled from 'styled-components'
import { CellTableDelivery } from './cell-table-delivery.types'

export const WrapperCellTalbe = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;
	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS}rem;
	}
`

export const StyledCellTableDelivery = styled.div<CellTableDelivery>`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 41px;
	padding: 8px;

	border-radius: 5px;
	border: 1px solid ${(props) => props.theme.palette.extras.stroke};

	background: ${(props) =>
		props.type === 'available'
			? props.theme.palette.success.main
			: props.theme.palette.lightGray.main};

	p {
		color: ${(props) => props.theme.palette.black.main};
		text-align: center;
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`
