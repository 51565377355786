export enum TransactionType {
	C = 'Crédito',
	D = 'Débito'
}

export type TransactionTypeEnum = keyof typeof TransactionType

export enum SalesOrderStatus {
	A = 'Em Análise',
	E = 'Entregue',
	D = 'Devolvido',
	C = 'Cancelado'
}

export enum DecisionEnum {
	S = 'Sim',
	N = 'Não'
}

export enum PointsExpirationStatusLabelEnum {
	EXPIRING = 'A EXPIRAR',
	RELEASED = 'LIBERADO',
	UNDER_ANALYSIS = 'EM ANÁLISE',
	EXPIRED = 'EXPIRADO',
	USED = 'UTILIZADO',
	REVERSAL = 'ESTORNO'
}

export type SalesOrderStatusEnum = keyof typeof SalesOrderStatus

export interface IPointsProgramStatus {
	userJoinedTheProgram: boolean
	pointsProgramPresentationDate?: Date
	salesOrgHasProgramPoints: boolean
}

export interface IExtractProgramPoints {
	transactionsID: string[]
	customerID: string
	salesOrgId: string
	OrderNumber?: string
	productsID?: string[]
	score: string
	type: TransactionTypeEnum
	customerSalesOrderDate: Date
	expirationDate?: Date
	liberationDate?: Date
	salesOrderPaidOut: DecisionEnum
	salesOrderStatus: SalesOrderStatusEnum
	processed: DecisionEnum
	label?: string
	AutoIncBackendID?: number
}

export interface IExtractPoints {
	totalAnalysisBalance: number
	totalAvailableBalance: number
	totalToBeExpiredBalance: number
	extractTransactions: Array<IExtractProgramPoints>
}
