export enum TypeEnum {
	TEXT = 'text',
	PASSWORD = 'password'
}
export interface TextFieldProps {
	label: string
	placeholder: string
	text: string
	handleChangeText: (text: string) => void
	type?: TypeEnum
	isError?: boolean
	labelIsError?: string
	idElement?: string
}
