import { IProduct } from '../../../../modules/RegisterProduct/types'
import { apiAdmin } from '../../../../shared/services/axiosInstace'
import { removeDuplicates } from '../../../../utils/remove-duplicates'
import CategoriesMapper from './mappers/categories-mapper'
import { Category, ReturnSelectOption } from './types/categories.types'

class Categories {
	async getAll(): Promise<ReturnSelectOption[]> {
		const { data: categories } = await apiAdmin.get<Category[]>(
			'/categories'
		)
		return categories.map((category) =>
			CategoriesMapper.toDomainCategories(category)
		)
	}
	async getBrandsByCategories(
		categories: string[],
		isCategoriesFeatureFlag = false
	): Promise<ReturnSelectOption[]> {
		const { data: brands } = await apiAdmin.get<IProduct[]>(
			`/categories/${categories.join(',')}/products`,
			{
				headers: {
					'api-version': isCategoriesFeatureFlag ? '17241' : '1'
				}
			}
		)
		return removeDuplicates(
			brands
				.map((brand) => CategoriesMapper.toDomainBrand(brand))
				.filter((item) => !!item.value),
			'value'
		)
	}
	async getBrandsByPackage(
		categories: string[],
		isCategoriesFeatureFlag = false
	): Promise<ReturnSelectOption[]> {
		const { data: packages } = await apiAdmin.get<IProduct[]>(
			`/categories/${categories.join(',')}/products`,
			{
				headers: {
					'api-version': isCategoriesFeatureFlag ? '17241' : '1'
				}
			}
		)
		const packs = removeDuplicates(
			packages
				.map((item) => CategoriesMapper.toDomainPackage(item))
				.filter((item) => !!item.value),
			'value'
		)
		return packs
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Categories()
