import React, { ChangeEvent } from 'react'
import * as S from './text-field.styles'
import { TextFieldProps, TypeEnum } from './text-field.types'

import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const TextField = ({
	label,
	placeholder,
	text,
	handleChangeText,
	type = TypeEnum.TEXT,
	isError = false,
	labelIsError,
	idElement
}: TextFieldProps) => {
	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		handleChangeText(event.target.value)
	}

	const [showPassword, setShowPassword] = React.useState(false)

	return (
		<>
			<S.TextFieldStyles>
				<S.LabelStyles isError={isError}>{label}</S.LabelStyles>
				<S.FieldStyles
					id={idElement}
					isError={isError}
					// type={type === 'password' ? 'password' : 'text'}
					type={
						type === 'password'
							? showPassword
								? 'text'
								: 'password'
							: 'text'
					}
					placeholder={placeholder}
					onChange={handleInputChange}
					value={text}
				/>
				{type === 'password' &&
					(showPassword ? (
						<VisibilityIcon
							onClick={() => setShowPassword(false)}
						/>
					) : (
						<VisibilityOffIcon
							onClick={() => setShowPassword(true)}
						/>
					))}
				{isError && <S.LabelError>{labelIsError}</S.LabelError>}
			</S.TextFieldStyles>
		</>
	)
}

export default TextField
