import { Auth } from 'aws-amplify'
import { AxiosResponse } from 'axios'

import { IStoreCustomer } from '../../shared/interfaces/store'
import axiosInstance from '../../shared/services/axiosInstace'
import { handleCognitoError } from '../../shared/utils/handleCognitoError'
import { ISignIn } from './types'

export async function signIn({ documentNumber, password }: ISignIn) {
	try {
		const response = await Auth.signIn(documentNumber, password)

		return response
	} catch (err) {
		return handleCognitoError(err)
	}
}

const queryParamEnum: { [id: number]: string } = {
	11: 'personalFiscalID',
	14: 'companyFiscalID'
}

export async function getUserDetails(
	documentNumber: string
): Promise<IStoreCustomer | null> {
	try {
		const queryParamName = queryParamEnum[documentNumber.length]
		const response: AxiosResponse = await axiosInstance.get(
			`/customers/?${queryParamName}=${documentNumber}&platform=web`
		)

		return response.data
	} catch (error) {
		return null
	}
}
