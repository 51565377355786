import cogoToast from 'cogo-toast'
import { createElement, useEffect, useState } from 'react'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { defaultSort } from '../../shared/utils/array'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { IOption } from '../NewClientForm/types'
import { fetchCompanies } from '../ResaleList/service'
import { IResaleItem } from '../ResaleList/types'
import { fetchCompanyProducts, uploadCSV } from './service'
import { IViewProps } from './types'
import View from './view'

import { include } from '../../shared/utils/boolean'
import { IFetchCompanyProductsResponse } from '../../shared/interfaces/response'
import { parseItemPrice } from '../../shared/utils/money'
import { reverseDateHuman } from '../../shared/utils/date'

const formatResaleOpt = (resale: IResaleItem) => ({
	label: `${resale.Name} - #${resale.SalesOrganizationID}`,
	value: resale.SalesOrganizationID
})

function ResaleProductsContainer(): JSX.Element {
	const { resale } = useTypedSelector(['resale'])

	const [selectedResale, setSelectedResale] = useState<IOption>()
	const [resaleList, setCompaniesList] = useState<IOption[]>([])

	const [data, setData] = useState<IFetchCompanyProductsResponse[]>([])
	const [tableData, setTableData] = useState<IFetchCompanyProductsResponse[]>(
		[]
	)

	const [isLoading, setLoading] = useState(false)

	const handleFetchResaleList = async () => {
		try {
			setLoading(true)
			setSelectedResale(formatResaleOpt(resale))

			const allResales = await fetchCompanies()

			setCompaniesList(allResales.map(formatResaleOpt))
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleFetchProductList = async () => {
		try {
			setLoading(true)

			if (!selectedResale?.value) return

			const { value: code } = selectedResale

			const allProducts = await fetchCompanyProducts(code)

			const sorted = [...allProducts].sort(defaultSort('ChannelGroup'))

			setData(sorted)
			setTableData(sorted)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		handleFetchResaleList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		handleFetchProductList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedResale])

	const uploadPayload = async (e: any) => {
		try {
			setLoading(true)
			const [file] = e.target.files
			e.target.value = ''

			const formData = new FormData()

			if (!selectedResale) return
			formData.append('file', file, file.name)

			await uploadCSV(formData, selectedResale.value)
			cogoToast.success(
				`${file.name} enviado com sucesso`,
				cogoDefaultOptions
			)
		} catch (error) {
		} finally {
			setLoading(false)
		}

		await handleFetchProductList()
	}

	// Organização de Vendas	Tabela	Canal	Código do Material	Tipo	Quantidade Mínima	Valor Mínimo	Valor Máximo	Termo Inicial
	function handleFilterOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.SalesOrganizationID, value) ||
				include(item.PriceTable, value) ||
				include(item.ChannelGroup, value) ||
				include(item.MaterialNumber, value) ||
				include(item.Type, value) ||
				include(item.MinQuantity, value) ||
				include(item.MaterialNumber, value) ||
				include(parseItemPrice(item.MaxValue), value) ||
				include(parseItemPrice(item.MinValue), value) ||
				include(reverseDateHuman(item.InitialTerm), value)
			)
		})

		setTableData(list)
	}

	const viewProps: IViewProps = {
		tableData,
		isLoading,
		resaleList,
		selectedResale,
		uploadPayload,
		setSelectedResale,
		handleFilterOnChange
	}

	return createElement(View, viewProps)
}

export default ResaleProductsContainer
