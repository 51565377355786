import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ICompanyParamsActionCreators
} from '../../interfaces/action'
import { IFormattedParam } from '../../interfaces/formattedParam'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<ICompanyParamsActionCreators> = createActions({
	setParams: ['params'],
	resetParams: []
})

/**
 * Handlers
 */

export const INITIAL_STATE: IFormattedParam[] = []

const update = (state = INITIAL_STATE, action: any) => action.params
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<
	IFormattedParam[],
	Action<ICompanyParamsActionCreators>
>(INITIAL_STATE, {
	[Types.SET_PARAMS]: update,
	[Types.RESET_PARAMS]: reset
})
