import React from 'react'

function Button(props: any) {
	const { label } = props

	return (
		<>
			<button onClick={() => null} className="m-button">
				<span className="m-button__label">{label}</span>
			</button>
		</>
	)
}

export default Button
