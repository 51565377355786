import { AxiosResponse } from 'axios'
import { ICombo } from '../../shared/interfaces/combo'
import apiCustomer from '../../shared/services/axiosInstace'

export async function getComboList({
	sectorID,
	customerID,
	salesOrganizationID,
	ignoreThrow = false
}: {
	sectorID: string
	customerID: string
	salesOrganizationID: string
	ignoreThrow?: boolean;
}): Promise<ICombo[]> {
	try {

		const combosResponseDataFiltered: ICombo[] = []

		const responseComboFlex: AxiosResponse<ICombo[]> = await apiCustomer.get(
			`/combo?salesOrganizationID=${salesOrganizationID}&sectorID=${sectorID}&customerID=${customerID}&comboFlex=true`
		)

		responseComboFlex.data.map((comboFlexData) => {
			const resultFlex = comboFlexData.TradePromotionItens.results.find(
				(item) => item.tradeType === `Z4VN`
			)

			if (resultFlex) {
				combosResponseDataFiltered.push({
					...comboFlexData,
					productImageURL: resultFlex.productImageURL
				})
			}

			return comboFlexData
		})
		
		return combosResponseDataFiltered
	} catch (error) {
		if (ignoreThrow) {
			return []
		}
		throw error
	}
}
