import React, { useCallback, useMemo, useState } from 'react'
import { StatusPageProps } from './status-page.types'
import * as S from './status-page.styles'
import HelpModal from '../../components/help-modal/help-modal.component'

import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import EngineeringIcon from '@mui/icons-material/Engineering'
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { fnOpenChat } from '../../layout/footer-layout/utils/open-chat'
import ButtonDefault from '../buttons/button/button-default'
import { TypeButtonEnum } from '../buttons/button/button-default.types'
import { useHistory } from 'react-router-dom'
import { verifyIsMaintenance } from '../../views/maintenance-message/services/maintenance-message.services'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import LoadingCcontainer from '../loading-container/loading-container.component'
import { isObjectEmpty } from '../../utils/verifyEmptyObject'

const StatusPage = ({ type, message, title }: StatusPageProps): JSX.Element => {
	const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const history = useHistory()

	const mapIcons = useMemo(
		() => ({
			system: <MiscellaneousServicesIcon />,
			construction: <EngineeringIcon />,
			error: <DangerousOutlinedIcon />,
			warning: <ErrorOutlineOutlinedIcon />,
			alert: <WarningAmberIcon />,
			info: <InfoOutlinedIcon />
		}),
		[type]
	)

	const handleGetMaintenanceData = useCallback(async () => {
		try {
			setIsLoading(true)
			const data = await verifyIsMaintenance().finally(() =>
				setTimeout(() => {
					setIsLoading(false)
				}, 2000)
			)

			if (isObjectEmpty(data)) {
				history.replace(UnauthenticatedRoutesEnum.LOGIN)
			}
		} catch (error) {
			cogoToast.error('Algo deu errado', cogoDefaultOptions)
		}
	}, [history])

	React.useEffect(() => {
		handleGetMaintenanceData()
	}, [handleGetMaintenanceData])

	return (
		<>
			<LoadingCcontainer isLoading={isLoading} />
			<HelpModal
				isOpen={isOpenHelpModal}
				handleClose={() => setIsOpenHelpModal(false)}
				handleOpenChat={() => fnOpenChat()}
			/>

			<S.MessageContainer>
				<S.CircleIcon type={type}>{mapIcons[type]}</S.CircleIcon>

				<S.MessageTitle type={type}>{title}</S.MessageTitle>
				<S.MessageContent>{message}</S.MessageContent>

				<ButtonDefault
					type={TypeButtonEnum.OUTLINED}
					buttonText="Tentar novamente"
					onClick={() => handleGetMaintenanceData()}
					idElement="try-again"
					height="50px"
					width="200px"
					fontSize="16px"
				/>
				<S.HelpInfo>
					{mapIcons.info}
					<div className="wrapper-help">
						<h2>
							Precisa de ajuda? Entre em contato com o nosso{' '}
							<p onClick={() => setIsOpenHelpModal(true)}>
								canal de atendimento
							</p>
						</h2>
					</div>
				</S.HelpInfo>
			</S.MessageContainer>
		</>
	)
}
export default StatusPage
