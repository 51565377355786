import React, { useEffect } from 'react'

import cogoToast from 'cogo-toast'
import {
	fileInfoType,
	UploadSpreadSheetProps
} from '../upload-spreadsheet.types'
import { verifyUploadError } from '../utils/verify-upload-error'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import { UploadSpreadsheetView } from '../upload-spreadsheet.view'

export const UploadSpreadsheetGeneric = <T,>({
	clearDataFile,
	haveCSV,
	selectedCSVData,
	setHaveCSV,
	setSelectedCSVData,
	onCsvUpload,
	validateHeaders,
	mapData,
	headers
}: UploadSpreadSheetProps<T>) => {
	async function loadedCsvReader(data: any[], fileInfo: fileInfoType) {
		const error = verifyUploadError({
			data,
			fileInfo,
			validateHeaders,
			errorOnCsvReader,
			headers
		})

		if (error) return

		const createPDVCSVOption = (value: T[]) => {
			setSelectedCSVData((prevState) => [...prevState, ...value])
			onCsvUpload(value, fileInfo)
		}
		try {
			const normalizedData = data.map(mapData)
			createPDVCSVOption([...new Set(normalizedData)])
		} finally {
			cogoToast.success(
				'Planilha reconhecida com sucesso!',
				cogoDefaultOptions
			)
			setHaveCSV(true)
		}
		return
	}

	function errorOnCsvReader(error: Error) {
		if (error) {
			cogoToast.error(error.message, cogoDefaultOptions)
		}
		setSelectedCSVData([])
		setHaveCSV(false)
	}

	const parseOptions = {
		header: true,
		dynamicTyping: false,
		skipEmptyLines: true,
		transformHeader: (header: any) => {
			return String(header)
		}
	}

	useEffect(() => {
		if (selectedCSVData.length === 0) setHaveCSV(false)
		else {
			setHaveCSV(true)
		}
	}, [haveCSV, selectedCSVData.length])

	return (
		<UploadSpreadsheetView
			parserOptions={parseOptions}
			errorOnCsvReader={errorOnCsvReader}
			onClick={clearDataFile}
			haveCSV={haveCSV}
			loadedCsvReader={loadedCsvReader}
		/>
	)
}
