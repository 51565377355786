import React, { useState } from 'react'
import { WrapperAction } from './actions-combo.styles'
import GroupItems from './components/group-items/group-items.component'
import { IActionsCombo } from './actions-combo.types'
import ModalProductDetails from '../../../../../modal-product-details/modal-product-details.component'
import { IItem } from '../../../../../modal-product-details/modal-product-detail.types'

function ActionsCombo({
	groupTypes,
	setSelectedProducts,
	selectedProductsToCart,
	setSelectedProductsToCart
}: IActionsCombo) {
	const [isOpenModalProductDetails, setIsOpenModalProductDetails] =
		useState(false)

	const [productDetail, setProductDetail] = useState<IItem>({
		description: '',
		price: '',
		productImageURL: '',
		material: {
			packing: '',
			packingEAN: '',
			packingType: '',
			scoreRate: '',
			unitEAN: '',
			brand: '',
			category: ''
		},
		unitPriceInPackage: ''
	})

	const isGroudDisabled = (index: number) => {
		if (index === 0) return false
		const prevGroup = (groupTypes?.results || [])?.[index - 1]
		const productsQuantityByGroup = Object.values(
			selectedProductsToCart?.[prevGroup.groupID] ?? []
		).reduce((acc, curr) => (acc += curr.quantity), 0)

		if (productsQuantityByGroup >= Number(prevGroup.quantityGroup))
			return false
		return true
	}

	return (
		<>
			<ModalProductDetails
				item={productDetail}
				isOpen={isOpenModalProductDetails}
				handleClose={() => setIsOpenModalProductDetails(false)}
			/>
			<WrapperAction>
				{(groupTypes?.results || []).map((group, index) => {
					return (
						<GroupItems
							setProductDetail={setProductDetail}
							key={group.groupID}
							groupItemsData={group}
							isDisabled={isGroudDisabled(index)}
							handleOpenModal={() => {
								setIsOpenModalProductDetails(true)
							}}
							selectedProductsToCart={selectedProductsToCart}
							setSelectedProductsToCart={
								setSelectedProductsToCart
							}
						/>
					)
				})}
			</WrapperAction>
		</>
	)
}

export default ActionsCombo
