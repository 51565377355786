import cogoToast from 'cogo-toast'

import { logout } from '../../shared/services/axiosInstace'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { AMPLIFY_ERROR_ENUM, GPLOGIN_MESSAGES } from './errorEnum'
// import { preSignUpError } from './preSignUpError'
import { IError } from './types'

function handleError(err: IError): void {
	if (process.env.NODE_ENV === 'development') {
		console.error('HANDLE_ERROR', { err })
	}

	const errorData = {
		message: err.message,
		status: err.statusCode,
		code: err.code
	}
	if (!err) {
		return
	}

	// else if (err.message.includes('Não existem parâmetros cadastrados')) {
	// 	// TODO: Check This
	// 	// message = 'Ocorreu um erro, tente novamente mais tarde...'
	// 	console.log({ checkThisMsg: err.message })
	// }

	// Not sure if we need this anymore
	// else if (preSignUpError(err)) {
	// 	console.log('PRE SIGN UP ERROR')
	// }
	else if (
		err?.fault?.detail?.errorcode ||
		err?.message === 'refreshToken.getToken is not a function'
	) {
		console.error('TOKEN EXPIRED')
		// Quando o Token Expirar Sempre Deslogar 24/07/2020.
		logout()
	} else if (err?.statusCode === 500) {
		const message =
			err?.message ||
			'Servidor indisponível, tente novamente mais tarde...'
		cogoToast.error(message, cogoDefaultOptions)
		errorData.message = message
	} else if (err?.statusCode === 404) {
		let message = getEnumMessage(err.message)

		if (err.message.includes('Cannot GET')) {
			errorData.message =
				'Servidor indisponível, tente novamente mais tarde...'
		}

		if (typeof message !== 'string') return
		cogoToast.error(String(message), cogoDefaultOptions)
		err.message = String(message)
	} else {
		let message = getEnumMessage(err.message)

		if (typeof message !== 'string') return
		cogoToast.error(String(message), cogoDefaultOptions)
	}

	throw errorData
}

export function getEnumMessage(msg: string): string {
	const message = AMPLIFY_ERROR_ENUM[msg] || GPLOGIN_MESSAGES[msg]

	return message || msg
}

export default handleError

// **
// Backup in case we need to rollback to refresh the token / DO NOT DELETE
// **

// Auth.currentAuthenticatedUser({
// 	bypassCache: true
// })
// 	.then((res) => {
// 		const accessToken = res.signInUserSession.accessToken.jwtToken
// 		store.dispatch(TokenActions.saveAccess(accessToken))
// 		console.log('TOKEN REFRESHED')
// 		// Hack to refresh the page (need improvement)
// 		store.dispatch(
// 			HistoryActions.EmitReplaceHistoryRoute(routesEnum.LOGIN)
// 		)
// 	})
// 	.catch((err) => {
// 		message =
// 			'Código de Acesso inválido, por favor faça login novamente.'
// 		logout()
// 	})
