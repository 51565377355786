import { ICategoriesPosition } from '../interfaces/categories'
import { apiAdmin, apiCustomer } from './axiosInstace'
import {
	Product,
	PersistenceProduct
} from '../../modules/RegisterProduct/mapper'

import RegisterProductMapper from '../../modules/RegisterProduct/mapper'

export const categoriesServices = {
	getCategoriesAdmin: async function (): Promise<any> {
		const url = `/categories`
		const { data } = await apiAdmin.get(url)
		return data
	},
	getCategoriesCustomer: async function (): Promise<any> {
		const url = `/categories`
		const { data } = await apiCustomer.get(url)
		return data
	},

	getProductById: async function (id: string): Promise<Product> {
		const url = `/categories/products?uuid=${id}`

		const { data } = await apiAdmin.get<PersistenceProduct>(url, {
			headers: {
				'api-version': '17241'
			}
		})

		return RegisterProductMapper.toDomain(data)
	},

	getProductsByCategorie: async function ({
		ID,
		isCategoriesFeatureFlag = false
	}: {
		ID: string
		isCategoriesFeatureFlag?: boolean
	}): Promise<any> {
		const url = `/categories/${ID}/products`

		const { data } = await apiAdmin.get(url, {
			headers: {
				'api-version': isCategoriesFeatureFlag ? '17241' : '1'
			}
		})
		return data
	},

	updateCategoriesList: async function (
		categoriesList: ICategoriesPosition[]
	): Promise<any> {
		// console.log(categoriesList)
		const { data } = await apiAdmin.patch(`/categories`, categoriesList)
		return data
	}
}
