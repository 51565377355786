import React from 'react'
import { IBanner } from '../../../../shared/interfaces/banner'
import * as S from './image-slider.styles'

import ReactPlayer from 'react-player'
import { IconButton } from '@mui/material'
import PlayerButton from './components/player-button/player-button.component'
import MutedButton from './components/muted-button/muted-button.component'
import ImageVideoComponent from './components/image-video/image-video.component'

interface ImageSliderProps {
	sliderItens: IBanner[]
}

function ImageSlider({ sliderItens }: ImageSliderProps) {
	const [currentIndex, setCurrentIndex] = React.useState(0)
	const [isDisabledNext, setIsDisabledNext] = React.useState(false)
	const [muted, setMuted] = React.useState(true)
	const [intervalId, setIntervalId] = React.useState<any>(null)
	const lastIndex = sliderItens.length - 1

	const [isPlaying, setIsPlaying] = React.useState(false)
	const [videoInProgress, setVideoInProgress] = React.useState(false)

	React.useEffect(() => {
		setIsDisabledNext(currentIndex === lastIndex)
	}, [currentIndex, lastIndex])

	// Função que cria o intervalo
	const handleCreateInterval = () => {
		const intervalId = setInterval(() => {
			if (!videoInProgress) {
				setCurrentIndex((prevIndex) =>
					prevIndex === lastIndex ? 0 : prevIndex + 1
				)
			}
		}, 5000)
		setIntervalId(intervalId)
	}

	// Função que reseta e chama o intervalo novamente.
	const handleResetInverval = () => {
		if (intervalId !== null) {
			clearInterval(intervalId)
			handleCreateInterval()
		}
	}

	const handlePrevBanner = () => {
		setCurrentIndex(currentIndex - 1)
		setIsPlaying(false)
		handleResetInverval()
	}

	const handleNextBanner = () => {
		setCurrentIndex(currentIndex + 1)
		setIsPlaying(false)
		handleResetInverval()
	}

	const handleMoveBannerIndex = (index: number) => {
		setCurrentIndex(index)
		setIsPlaying(false)
		handleResetInverval()
	}

	React.useEffect(() => {
		let intervalId: NodeJS.Timeout | null = null
		if (!isPlaying) {
			intervalId = setInterval(() => {
				setCurrentIndex((prevIndex) =>
					prevIndex === lastIndex ? 0 : prevIndex + 1
				)
			}, 5000)
		}
		setIntervalId(intervalId)

		return () => {
			if (intervalId !== null) {
				clearInterval(intervalId)
			}
		}
	}, [isPlaying, lastIndex])

	return (
		<S.SliderWrapper>
			{sliderItens.length === 0 ? (
				<div></div>
			) : (
				<>
					<div>
						{sliderItens[currentIndex].imageURL &&
							sliderItens[currentIndex].videoURL ? (
							<ImageVideoComponent
								imageUrl={sliderItens[currentIndex].imageURL}
								videoUrl={sliderItens[currentIndex].videoURL}
								isPlaying={isPlaying}
								setIsPlaying={(show) => setIsPlaying(show)}
								handleOnProgress={(show) =>
									setVideoInProgress(show)
								}
							/>
						) : sliderItens[currentIndex].imageURL ? (
							<S.ImageSliderWrapper
								style={{
									backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%), url(${sliderItens[currentIndex].imageURL})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									backgroundPosition: 'center'
								}}></S.ImageSliderWrapper>
						) : (
							<S.VideoPlayerWrapper>
								{/* @ts-ignore */}
								<ReactPlayer
									url={sliderItens[currentIndex].videoURL}
									playing={isPlaying ? true : false}
									onProgress={() => setVideoInProgress(true)}
									onEnded={() => {
										setIsPlaying(false)
										setVideoInProgress(false)
									}}
									muted={muted}
									width={'683px'}
									height={'522px'}
									style={{
										background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%)`
									}}
								/>
								<PlayerButton
									isPlaying={isPlaying}
									onClickPlay={() => setIsPlaying(true)}
									onClickPause={() => setIsPlaying(false)}
								/>
								<MutedButton
									isMuted={muted}
									onClickMuted={() => setMuted(!muted)}
								/>
							</S.VideoPlayerWrapper>
						)}
					</div>
					<S.ControlsSliderWrapper>
						<IconButton
							onClick={() => handlePrevBanner()}
							disabled={currentIndex === 0 ? true : false}>
							<S.PrevButton isDisabled={currentIndex > 0} />
						</IconButton>
						<div className="dots-slider">
							{sliderItens.map((item, index) => {
								return (
									<div
										onClick={() =>
											handleMoveBannerIndex(index)
										}
										key={item.ID}
										className={
											currentIndex === index
												? 'active-item'
												: 'disabled'
										}></div>
								)
							})}
						</div>
						<IconButton
							disabled={isDisabledNext}
							onClick={() => handleNextBanner()}>
							<S.NextButton isDisabled={isDisabledNext} />
						</IconButton>
					</S.ControlsSliderWrapper>
				</>
			)
			}
		</S.SliderWrapper >
	)
}

export default ImageSlider
