import styled from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const Container = styled.div`
	margin-top: 40px;

	.empty-list-placeholder {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		width: 100%;
		background-color: ${colors.white};
		padding: 64px;
		border-radius: 4px;

		span {
			text-align: center;
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${colors.sixthGray};
		}
	}
`

export const InputScoreRate = styled.input`
	height: 55px;
	width: 138px;
	border: 1px solid ${colors.grey};
	border-radius: 4px;
	padding: 16px;
	color: ${({ value }) =>
		value === '0' ? colors.sixthGray : colors.secondGrey};

	&:disabled {
		color: ${(props) => props.theme.palette.black.main};
		background-color: ${(props) => props.theme.palette.primaryWhite.main};
		border: 1px solid ${(props) => props.theme.palette.lightGray.main};
	}
`
