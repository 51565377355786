import styled from 'styled-components'

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`

export const Container = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;
`

export const InputSelectPeriodPicker = styled.div`
	width: 100%;
`

export const ButtonDefault = styled.div`
	height: 100%;
	display: flex;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;
	justify-content: center;
	align-items: center;
`
export const Selectors = styled.div`
	display: flex;
	width: 100%;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;
`
