import styled, { css } from 'styled-components'

export const ItemTextContainer = styled.div`
    flex: 1;
	
`
export const ItemText = styled.p`
	${({ theme }) => css`
        font-weight: ${theme.font.weight.semibold};
		font-size: ${theme.font.size.TEXT_XSM.size}rem;
        color: ${theme.palette.primary.main};
	`}
`

export const ItemTextDescription = styled.p`
	${({ theme }) => css`
        font-weight: ${theme.font.weight.regular};
		font-size: ${theme.font.size.TEXT_SM.size}rem;
        color: ${theme.palette.white.contrastText};
	`}
`