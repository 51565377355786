import { IModalProps } from './types'
import { ModalPrimaryWrapper } from './styles'
import { X } from 'react-feather'

import React, { useEffect } from 'react'
import { colorThemeProvider } from '../../theme/themeProvider'

function ModalPrimary({
	isActive,
	onClose,
	scrollRef,
	title,
	children,
	size = 500,
	className,
	containerContentBg = colorThemeProvider.whiteColor
}: IModalProps) {
	useEffect(() => {
		const refElement = document.getElementById(scrollRef || '')

		if (isActive && refElement) {
			refElement.style.overflow = 'hidden'
		} else if (refElement) {
			refElement.removeAttribute('style')
		}
	}, [isActive, scrollRef])

	return (
		<ModalPrimaryWrapper
			size={size}
			containerContentBg={containerContentBg}
			className={`${className} ${isActive ? 'active' : ''}`}>
			<div className="modal-wrapper">
				<div className="modal-header">
					<h1 className="modal-title">{title}</h1>
					<X className="modal-close" onClick={onClose} />
				</div>
				<div className="modal-content">{children}</div>
			</div>
		</ModalPrimaryWrapper>
	)
}

export default ModalPrimary
