// globalStyles.js
import { createGlobalStyle } from 'styled-components'
import { colorThemeProvider, typographyTheme } from './themeProvider'

const GlobalStyle = createGlobalStyle`
  :root{
    --gp-palette-primary-main: ${colorThemeProvider.primary.main};
    --gp-palette-primary-contrastText: ${colorThemeProvider.primary.contrastText};
    --gp-palette-secondary-main: ${colorThemeProvider.secondary.main};
    --gp-palette-secondary-contrastText: ${colorThemeProvider.secondary.contrastText};
    --gp-palette-secondary: ${colorThemeProvider.secondaryColor};
    --gp-palette-error: ${colorThemeProvider.errorColor};
    --gp-palette-warning: ${colorThemeProvider.warningColor};
    --gp-palette-success: ${colorThemeProvider.successColor};
    --gp-palette-extras: ${colorThemeProvider.extrasColor};
    --gp-palette-black: ${colorThemeProvider.blackColor};
    --gp-palette-gray: ${colorThemeProvider.grayColor};
    --gp-palette-light-gray: ${colorThemeProvider.lightGrayColor};
    --gp-palette-primary-white: ${colorThemeProvider.primaryWhiteColor};
    --gp-palette-primary-white-contrast: ${colorThemeProvider.primaryWhiteColorContrasct};
    --gp-palette-white: ${colorThemeProvider.whiteColor};
    --gp-palette-action: ${colorThemeProvider.actionColor};
    --gp-palette-returnable-main: ${colorThemeProvider.returnable.main};
    --gp-palette-returnable-light: ${colorThemeProvider.returnable.light};
    --gp-palette-returnable-dark: ${colorThemeProvider.returnable.dark};
    --gp-palette-returnable-contrastText: ${colorThemeProvider.returnable.contrastText};
    --gp-palette-background-default: ${colorThemeProvider.background.default};
    --gp-palette-background-paper: ${colorThemeProvider.background.paper};
    --gp-typography-size-textxs-size: ${typographyTheme.size.TEXT_XS.size}
  }

  body {
    font-family: 'Inter';
  }
`

export default GlobalStyle
