import React from 'react'
import * as S from './new-password.styles'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../shared/utils/toaster'

import CheckedIconGray from './assets/checked-icon-gray.svg'
import CheckedIconGreen from './assets/checked-icon-green.svg'
import UncheckedIcon from './assets/unchecked-icon.svg'

import TextField from '../../../../components/inputs/text-field/text-field.component'
import { TypeEnum } from '../../../../components/inputs/text-field/text-field.types'
import { WrapperButton } from '../../reset-password.styles'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { NewPasswordProps } from './new-password.types'

import { sendCodeForNewPassword } from '../../../../modules/ConfirmActivationCode/service'
import { formatConfirmationCodeMethodParams } from '../../../../components/AccountActivationSession/util'
import { TypeContact } from '../code-validation/code-validation.types'
import { EnumTypeFlow } from '../../reset-password.types'

function NewPasswordComponent({
	goStep,
	setNewPassword,
	newPassword,
	typeContact,
	customerDocument,
	typeFlow,
	setIsLoading,
	setIsOpen
}: NewPasswordProps) {
	const [minimumCharacters, setMinimumCharacters] =
		React.useState<boolean>(false)
	const [capitalCharacter, setCapitalCharacter] =
		React.useState<boolean>(false)
	const [lowercaseCharacter, setLowercaseCharacter] =
		React.useState<boolean>(false)
	const [hasNumber, setHasNumber] = React.useState<boolean>(false)

	const [confirmNewPassword, setConfirmNewPassword] =
		React.useState<string>('')

	const [isEqualPasswords, setIsEqualPasswords] =
		React.useState<boolean>(false)
	const [isValidPassword, setIsValidPassword] = React.useState(false)

	// const history = useHistory()

	async function confirm() {
		setIsLoading(true)
		try {
			// enviar codigo de confirmação
			await sendCodeForNewPassword({
				username: customerDocument,
				clientMetadata: formatConfirmationCodeMethodParams(
					typeContact === TypeContact.EMAIL ||
						typeContact === TypeContact.EMAIL_ALTERNATIVE ||
						typeContact === TypeContact.NEW_EMAIL
						? 'sendemail'
						: 'sendsms'
				)
			})
			goStep(1)
		} catch (error) {
			console.error(error)
			cogoToast.error('Erro ao alterar senha', cogoDefaultOptions)
		} finally {
			setIsLoading(false)
		}
	}

	React.useEffect(() => {
		setMinimumCharacters(newPassword.length >= 8)
		setCapitalCharacter(/[A-Z]/.test(newPassword))
		setLowercaseCharacter(/[a-z]/.test(newPassword))
		setHasNumber(/[0-9]/.test(newPassword))
	}, [newPassword])

	React.useEffect(() => {
		if (
			confirmNewPassword.length > 0 &&
			newPassword !== confirmNewPassword
		) {
			setIsEqualPasswords(false)
		} else {
			setIsEqualPasswords(true)
		}

		if (
			newPassword === confirmNewPassword &&
			minimumCharacters &&
			capitalCharacter &&
			lowercaseCharacter &&
			hasNumber
		) {
			setIsValidPassword(true)
		} else {
			setIsValidPassword(false)
		}
	}, [
		newPassword,
		confirmNewPassword,
		capitalCharacter,
		hasNumber,
		isEqualPasswords,
		lowercaseCharacter,
		minimumCharacters
	])

	return (
		<>
			<S.NewPasswordComponentView>
				<S.Title>Crie uma senha de acesso</S.Title>

				<S.WrapperPassword>
					<S.RequirementsWrapper>
						<S.RowRequirements
							$emptyPassword={!newPassword}
							$checked={minimumCharacters}>
							{!newPassword ? (
								<img src={CheckedIconGray} alt="CheckIcon" />
							) : (
								<img
									src={
										minimumCharacters
											? CheckedIconGreen
											: UncheckedIcon
									}
									alt="CheckIcon"
								/>
							)}
							<p>Ter no mínimo 8 caracteres</p>
						</S.RowRequirements>
						<S.RowRequirements
							$emptyPassword={!newPassword}
							$checked={capitalCharacter}>
							{!newPassword ? (
								<img src={CheckedIconGray} alt="CheckIcon" />
							) : (
								<img
									src={
										capitalCharacter
											? CheckedIconGreen
											: UncheckedIcon
									}
									alt="CheckIcon"
								/>
							)}
							<p>Ter ao menos um caractere maiúsculo</p>
						</S.RowRequirements>
						<S.RowRequirements
							$emptyPassword={!newPassword}
							$checked={lowercaseCharacter}>
							{!newPassword ? (
								<img src={CheckedIconGray} alt="CheckIcon" />
							) : (
								<img
									src={
										lowercaseCharacter
											? CheckedIconGreen
											: UncheckedIcon
									}
									alt="CheckIcon"
								/>
							)}
							<p>Ter ao menos um caractere minúsculo</p>
						</S.RowRequirements>
						<S.RowRequirements
							$emptyPassword={!newPassword}
							$checked={hasNumber}>
							{!newPassword ? (
								<img src={CheckedIconGray} alt="CheckIcon" />
							) : (
								<img
									src={
										hasNumber
											? CheckedIconGreen
											: UncheckedIcon
									}
									alt="CheckIcon"
								/>
							)}
							<p>Ter ao menos um número</p>
						</S.RowRequirements>
					</S.RequirementsWrapper>

					<S.FieldsWrapper>
						<TextField
							label={'Senha'}
							placeholder={'Digite a sua senha'}
							handleChangeText={setNewPassword}
							text={newPassword}
							type={TypeEnum.PASSWORD}
							isError={false}
							labelIsError={'Senha incorreta, tente novamente'}
							idElement={'password'}
						/>
						<TextField
							label={'Senha'}
							placeholder={'Digite a sua senha'}
							handleChangeText={setConfirmNewPassword}
							text={confirmNewPassword}
							type={TypeEnum.PASSWORD}
							isError={!isEqualPasswords}
							labelIsError={'Senhas digitadas não correspondem'}
							idElement={'password'}
						/>
					</S.FieldsWrapper>
				</S.WrapperPassword>
			</S.NewPasswordComponentView>
			<WrapperButton>
				<>
					<ButtonDefault
						buttonText="Voltar"
						type={TypeButtonEnum.OUTLINED}
						onClick={() =>
							typeFlow === EnumTypeFlow.ALTERNATIVEFLOW
								? goStep(3)
								: typeFlow === EnumTypeFlow.SECURITY_QUESTIONS
								? goStep(5)
								: typeFlow === EnumTypeFlow.NORMALFLOW &&
								  // history.replace(
								  // 	UnauthenticatedRoutesEnum.LOGIN,
								  // 	{
								  // 		contentType: 'chooseValidation'
								  // 	}
								  // )
								  setIsOpen(true)
						}
						idElement="cancel"
						width="234px"
					/>
					<ButtonDefault
						buttonText="Criar senha"
						type={
							!isValidPassword
								? TypeButtonEnum.DISABLED
								: TypeButtonEnum.PRIMARY
						}
						onClick={() => confirm()}
						idElement="confirm"
						width="234px"
					/>
				</>
			</WrapperButton>
		</>
	)
}

export default NewPasswordComponent
