import React from 'react'

import ProductImage from '../ProductImage'
import { IProps } from './types'

import CameraIcon from '../../../../assets/images/camera.svg'

function ImageUploader(props: IProps) {
	const { setProductImages, productImages } = props

	function handleUnitImage(event: { target: { files: any } }) {
		setProductImages((prev) => ({
			...prev,
			unitImage: {
				file: event.target.files[0],
				name: event.target.files[0].name,
				preview: URL.createObjectURL(event.target.files[0])
			}
		}))
	}

	function handlePackImage(event: { target: { files: any } }) {
		setProductImages((prev) => ({
			...prev,
			packImage: {
				file: event.target.files[0],
				name: event.target.files[0].name,
				preview: URL.createObjectURL(event.target.files[0])
			}
		}))
	}

	function deleteUnitImage() {
		setProductImages({
			...productImages,
			unitImage: {
				file: '',
				name: '',
				preview: ''
			}
		})
	}

	function deletePackImage() {
		setProductImages({
			...productImages,
			packImage: {
				file: '',
				name: '',
				preview: ''
			}
		})
	}

	return (
		<>
			<div className="image-uploader">
				{productImages?.unitImage?.preview === '' ? (
					<>
						<div className="placeholder-wrapper">
							<input
								id="unit-image"
								type="file"
								onChange={(e) => handleUnitImage(e)}
								accept="image/*"
							/>
							<label
								className="placeholder"
								htmlFor={'unit-image'}>
								<div className="placeholder-wrapper">
									<img
										className="placeholder__icon"
										src={CameraIcon}
										alt=""
									/>
									<p className="placeholder__text">
										Foto do Produto
									</p>
								</div>
							</label>
						</div>
					</>
				) : (
					<>
						<ProductImage
							src={productImages?.unitImage?.preview}
							onClick={deleteUnitImage}
						/>
					</>
				)}
				{productImages?.packImage?.preview === '' ? (
					<>
						<div className="placeholder-wrapper">
							<input
								id="package-image"
								type="file"
								onChange={(e) => handlePackImage(e)}
								accept="image/*"
							/>
							<label
								className="placeholder"
								htmlFor={'package-image'}>
								<div className="placeholder-wrapper">
									<img
										className="placeholder__icon"
										src={CameraIcon}
										alt=""
									/>
									<p className="placeholder__text">
										Foto do Pacote
									</p>
								</div>
							</label>
						</div>
					</>
				) : (
					<>
						<ProductImage
							src={productImages?.packImage?.preview}
							onClick={deletePackImage}
						/>
					</>
				)}
			</div>
		</>
	)
}

export default ImageUploader
