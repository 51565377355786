import { AxiosResponse } from 'axios'
import { apiAdmin, apiCustomer } from '../../../shared/services/axiosInstace'
import { getAmbienteName } from '../../../utils/getApiBaseUrl.utils'
import { EnumEnvironment } from '../../../shared/enums/environment-enum'
import { IFilterListPdvs } from '../typings'

const apiFilter =
	getAmbienteName() === EnumEnvironment.LOCAL ? apiCustomer : apiAdmin

interface HandleFilterCustomersListPdvsProps {
	filterOptions: IFilterListPdvs
	fetchListPDVsPush?: boolean
}

export async function handleFilterCustomersListPdvs({
	fetchListPDVsPush = false,
	filterOptions
}: HandleFilterCustomersListPdvsProps) {
	try {
		const urlFetch = fetchListPDVsPush ? '/customers/listPDVsFilterPush' : '/customers/listPdvs'

		let response: AxiosResponse = await apiFilter.post(
			urlFetch,
			filterOptions
		)

		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}
