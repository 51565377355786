import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProductManagerView } from './product-manager.view'
import { useProducts } from './service'
import { IDualListProductOption, IProduct, IProductManager } from './types'
import { removeDuplicates } from '../../../../utils/remove-duplicates'
import { useQuery } from 'react-query'
import suggestedOrdersAdminService from '../../services/suggested-orders-admin.service'

export const ProductManager = ({
	edit,
	selectedProducts,
	onChange
}: IProductManager) => {
	const { getProducts, products, loading } = useProducts()
	const [productsToShowInList, setProductsToShowInList] = useState<
		IDualListProductOption[]
	>([])

	const { data: domainCategory } = useQuery({
		queryKey: ['/materials-grouped-by-category'],
		queryFn: async () => {
			const res =
				await suggestedOrdersAdminService.getMaterialsGroupedByCategory(
					selectedProducts
				)
			return res
		}
	})

	const groupedCategories = domainCategory?.groupedCategories ?? []
	const initialSelectedProducts =
		domainCategory?.initialSelectedProducts ?? []

	const productsListed = useMemo(() => {
		const formattedList: IProduct[] = []
		products.filter((product) =>
			productsToShowInList.forEach((toShowInList) => {
				if (toShowInList.value === product.MaterialID) {
					formattedList.push({
						...product,
						materialQuantity: toShowInList.materialQuantity
					})
				}
			})
		)
		return formattedList
	}, [products, productsToShowInList])

	const handleSelectProducts = useCallback(
		(newProducts: IDualListProductOption[]) => {
			setProductsToShowInList(newProducts)
			const submitedData: IProduct[] = []

			newProducts.forEach((item) => {
				products.forEach((product) => {
					if (item.value === product.MaterialID) {
						submitedData.push({
							...product,
							materialQuantity: item.materialQuantity
						})
					}
				})
			})

			onChange(submitedData)
		},
		[onChange, products]
	)

	const handleChangeListedValues = useCallback(
		(newProduct: IDualListProductOption) => {
			const newState = productsToShowInList.map((item) => {
				if (item.value === newProduct.value) {
					item.materialQuantity = newProduct.materialQuantity
				}
				return item
			})
			handleSelectProducts(newState)
		},
		[handleSelectProducts, productsToShowInList]
	)

	useEffect(() => {
		getProducts()
	}, [getProducts])

	useEffect(() => {
		setProductsToShowInList(
			selectedProducts.map((item) => {
				const foundedItem = products.find(
					(product) => product.MaterialID === item.materialId
				)
				return {
					label: foundedItem?.Description || '',
					value: foundedItem?.MaterialID || '',
					materialQuantity: item?.materialQuantity || 1
				}
			})
		)
	}, [products, selectedProducts])

	return (
		<ProductManagerView
			edit={edit}
			loading={loading}
			allProducts={removeDuplicates(groupedCategories ?? [], 'label')}
			initialSelectedProducts={removeDuplicates(
				initialSelectedProducts,
				'label'
			)}
			key={String(initialSelectedProducts.length)}
			productsListed={productsListed}
			handleSelectProducts={handleSelectProducts}
			handleChangeListedValues={handleChangeListedValues}
		/>
	)
}
