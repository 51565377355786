import React from 'react'
import { DateRange } from 'react-day-picker-8'
import { DateRangePicker } from '../../../../../components/date-range-picker'

interface IData {
    sendData: (dataDados: any) => void
}

function DateCalendar(props: IData) {

    const [selectedDate, setSelectedDate] = React.useState<DateRange>({
        from: new Date(new Date().setDate(1)),
        to: new Date()
    })
    function changeSetSelectedDate(range: any) {
        if (!range.to) {
            range.to = range.from
        }
        props.sendData({ start: range?.from, end: range?.to })
        setSelectedDate(range)
    }
    return (
        <DateRangePicker
            selectedDate={selectedDate}
            setSelectedDate={changeSetSelectedDate}
            placeholder="Filtrar por data"
        />
    )
}

export default DateCalendar;