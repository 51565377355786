import { apiMaintenance } from '../../../shared/services/axiosInstace'

export async function verifyIsMaintenance(): Promise<any> {
	try {
		const response = await apiMaintenance.get('/message')
		return response.data
	} catch (error) {
		return error
	}
}
