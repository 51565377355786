import * as React from 'react'

import Modal from '@mui/material/Modal'
import { HelpModalProps } from './help-modal.types'
import { WrapperHelpModal } from './help-modal.styles'

import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import CloseIcon from '@mui/icons-material/Close'
import ButtonDefault from '../buttons/button/button-default'
import { SAC_TELEPHONE_NUMBER } from '../../assets/strings'

export default function BasicModal({
	isOpen,
	handleClose,
	handleOpenChat
}: HelpModalProps) {
	return (
		<>
			<Modal
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description">
				<WrapperHelpModal>
					<div className="header-modal">
						<CloseIcon
							className="close-button"
							onClick={handleClose}
						/>
						<SupportAgentIcon className="support-icon" />
					</div>
					<div className="main-modal">
						<h2>Fale com a gente</h2>
						<p>
							Ligue para Central de Atendimento ou acesse o nosso
							chat, de segunda a sexta, das 8h às 17h.
						</p>
						<p>
							Para mensagens fora do nosso horário de atendimento,
							envie um e-mail para:{' '}
							<strong>sac.corp@grupopetropolis.com.br</strong>
						</p>
						<ButtonDefault
							buttonText="Chat"
							idElement="open-chat"
							onClick={() => handleOpenChat()}
						/>
						<div className="wrapper-cell">
							<p>{SAC_TELEPHONE_NUMBER}</p>
						</div>
					</div>
				</WrapperHelpModal>
			</Modal>
		</>
	)
}
