import React, { Fragment } from 'react'

import ShoppingCartGreenIcon from '../../assets/images/shopping-cart-green.svg'

import * as S from './styles'

type CartQuantityBadgeProps = {
	storeQuantity: number
}

export default function CartQuantityBadge(props: CartQuantityBadgeProps) {
	const { storeQuantity } = props
	return (
		<Fragment>
			<S.CartQuantityBadge>
				<img src={ShoppingCartGreenIcon} alt=""/>
				<S.ItemQuantityInCart>{storeQuantity}</S.ItemQuantityInCart>
			</S.CartQuantityBadge>
		</Fragment>
	)
}
