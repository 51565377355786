import styled from 'styled-components'

export const WrapperBannerMidiaUpload = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 24px;

	p {
		color: var(--black-10, #3d3d3d);
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`

interface WrapperSelectedMidiaProps {
	mediaUrl: string
}

export const WrapperUpload = styled.div`
	width: 330px;
	height: 88px;
	border-radius: 5px;
	border: 1px dashed ${(props) => props.theme.palette.extras.stroke.main};
	background: ${(props) => props.theme.palette.primary.contrastText};

	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;

	svg {
		width: 32px;
		height: 32px;
		color: ${(props) => props.theme.palette.lightGray.main};
	}

	.main-text {
		color: ${(props) => props.theme.palette.lightGray.main};
		text-align: center;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	.sub-text {
		color: ${(props) => props.theme.palette.lightGray.main};
		text-align: center;
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`
export const WrapperSelectedMidia = styled.div<WrapperSelectedMidiaProps>`
	position: relative;
	width: 330px;
	height: 88px;

	border: 1px solid black;
	background-image: ${({ mediaUrl }) => `url(${mediaUrl})`};
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: center;
	align-items: center;

	.wrapper-midia {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		height: 100%;
		padding: 10px 10px;

		svg {
			width: 22px;
			height: 22px;
			color: ${(props) => props.theme.palette.black.main};

			cursor: pointer;
		}

		.wrapper-midia-info {
			.midia-name {
				color: ${(props) => props.theme.palette.black.main};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}

			.midia-size {
				color: ${(props) => props.theme.palette.black.main};
				font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
				font-weight: ${(props) => props.theme.font.weight.medium};
			}
		}
	}
`
