import styled, { css } from 'styled-components'
import MorePointsBannerImage from '../../../../assets/images/maispontos-banner.png'

export const Container = styled.div`
	margin: 48px 0 0;
`

export const Banner = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	height: 198px;
	padding: 0 8px;
	overflow: hidden;

	&:hover {
		cursor: pointer;
	}

	img {
		width: 100%;
		height: 100%;
		border-radius: 4px;
		object-fit: fill;
	}
`

export const MorePointsBanner = styled(Banner)`
	> div {
		width: 100%;
		height: 100%;
		border-radius: 4px;

		background-image: url(${MorePointsBannerImage});
		background-color: #800506;
		background-repeat: no-repeat;
		background-position-x: right;

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 32px;

		span.banner-title {
			font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
			color: ${(props) => props.theme.palette.white.main};
		}

		span.banner-subtitle {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.white.main};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_SM.line_height}rem;
			margin-top: 13px;
		}
	}
`

export const ChevronContainer = styled.div<{ direction: 'left' | 'right' }>`
	position: absolute;
	z-index: 2;
	top: calc(50% - 19px);
	${({ direction }) =>
		direction === 'left'
			? css`
					left: -15px;
			  `
			: css`
					right: -15px;
			  `}

	&:hover {
		cursor: pointer;
	}
`
