import { AxiosResponse } from 'axios'
import { ISelectOptionArray, ISelectedOptionsFilter } from './types'
import { apiAdmin, apiCustomer } from '../../shared/services/axiosInstace'
import { INotificationProps } from '../../shared/interfaces/pushNotification'
import { IFilterSalesHierarchyDirector } from '../../shared/interfaces/pointsProgramChallenge'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'

const apiNotification = getAmbienteName() === 'local' ? apiCustomer : apiAdmin

export const INITIAL_NOTIFICATION_FIELDS: INotificationProps = {
	title: '',
	body: '',
	data: {
		actionType: '',
		productToOpen: '',
		categoryToOpen: ''
	},
	trigger_date: '',
	imageUrl: ''
}

export async function handleFilterData() {
	try {
		const response: AxiosResponse = await apiAdmin.get(
			'/customers/populatefilters'
		)

		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function getSalesHierarchy(): Promise<
	IFilterSalesHierarchyDirector[]
> {
	const { data } = await apiAdmin.get(`/filters/sales-hierarchy`)
	return data
}

export async function filterDiretor(
	salesHierarchyData: IFilterSalesHierarchyDirector[]
) {
	let response: ISelectOptionArray[] = []

	await Promise.all(
		salesHierarchyData.map(async (diretor) => {
			const labelDiretor = diretor.director
			let valueResaleCodes: string[] = []

			await Promise.all(
				diretor.govs.map(async (grc) => {
					await Promise.all(
						grc.resales.map((resale) =>
							valueResaleCodes.push(String(resale.resale))
						)
					)
				})
			)

			response.push({
				value: valueResaleCodes,
				label: labelDiretor
			})
		})
	)

	return response
}

export async function filterGRC(
	salesHierarchyData: IFilterSalesHierarchyDirector[]
) {
	let response: ISelectOptionArray[] = []

	await Promise.all(
		salesHierarchyData.map(async (diretor) => {
			await Promise.all(
				diretor.govs.map(async (grc) => {
					let labelGrc = grc.gov
					let valueResaleCodes: string[] = []

					await Promise.all(
						grc.resales.map((resale) =>
							valueResaleCodes.push(String(resale.resale))
						)
					)

					response.push({
						value: valueResaleCodes,
						label: labelGrc
					})
				})
			)
		})
	)

	return response
}

export async function handleFilterCustomers(filterOptions: {
	codePdv: string[]
}) {
	try {
		let response: AxiosResponse = await apiAdmin.post(
			'/customers/listfilteredcustomers',
			filterOptions
		)

		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function sendSingleNotification(
	notification: INotificationProps,
	filters: ISelectedOptionsFilter
) {
	try {
		let params = {
			filters: filters,
			notification: notification,
			trigger_date: notification.trigger_date
		}

		if (!notification.trigger_date || notification.trigger_date === '') {
			delete params.trigger_date
		}
		const response: AxiosResponse = await apiNotification.post(
			'/push',
			params
		)

		return response.data
	} catch (error) {
		console.error({ error })
		throw error
	}
}
