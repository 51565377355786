import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { checkEmail } from '../../../shared/utils/validators'
import InputField from '../../../components/InputField'
import { IProps } from './types'

function InputEmail({ returnValue, valueIsValid, className = '' }: IProps) {
	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setTouched(true)
		returnValue(e.target.value)
		setInputValue(e.target.value)
	}

	function validateInput() {
		const isValid = checkEmail(inputValue)
		valueIsValid(isValid)
		setHasError(touched && !isValid)
	}

	useEffect(validateInput, [value, inputValue, touched, valueIsValid])

	return (
		<InputField
			onChange={handleChange}
			id="email"
			label="E-mail"
			hasError={hasError}
			disabledCopyPaste={true}
			className={className}
		/>
	)
}

export default InputEmail
