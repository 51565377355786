import React, { Fragment } from 'react'

import CardItem from '../CardItem'
import { IProps } from './types'
import { IMaterial } from '../../../shared/interfaces/material'
import ReactGA from 'react-ga'

function List(props: IProps): JSX.Element {
	const { renderProductList, handleSelectedProduct } = props

	const sortListByListingPosition = (
		renderProductList: Array<IMaterial>
	): Array<IMaterial> => {
		renderProductList.sort((a: IMaterial, b: IMaterial) => {
			if (
				a.ListingPosition === undefined ||
				b.ListingPosition === undefined
			) {
				return 0
			}

			return a.ListingPosition - b.ListingPosition
		})

		renderProductList.sort((a: IMaterial, b: IMaterial) => {
			if (
				a.CategoryListingPosition === undefined ||
				b.CategoryListingPosition === undefined
			) {
				return 0
			}

			return a.CategoryListingPosition - b.CategoryListingPosition
		})

		return renderProductList
	}

	return (
		<Fragment>
			<div className="card-list">
				{renderProductList.length ? (
					sortListByListingPosition(renderProductList).map(
						(product, x) => {
							return (
								<a
									data-CategoryListingPosition={
										product.CategoryListingPosition
									}
									data-ListingPosition={
										product.ListingPosition
									}
									key={x}
									onClick={(e) => {
										handleSelectedProduct(product)
										ReactGA.event({
											category: 'product',
											action: `product ${product.MaterialID}`,
											label: product.description
										})
									}}
									className="card-item-wrapper">
									<CardItem
										productItem={product}
										showPrice={true}
									/>
								</a>
							)
						}
					)
				) : (
					<>
						<div className="no-product-placeholder-wrapper">
							<h4>Nenhum Produto Encontrado...</h4>
						</div>
					</>
				)}
			</div>
		</Fragment>
	)
}

export default List
