import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ICustomerActionCreators
} from '../../interfaces/action'
import { IStoreCustomer } from '../../interfaces/store'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<ICustomerActionCreators> = createActions({
	updateCustomer: ['customer'],
	resetCustomer: []
})

/**
 * Handlers
 */

export const INITIAL_STATE: IStoreCustomer = {
	BirthDate: '',
	CellphoneNumber: '',
	ChannelGroupDescription: '',
	ChannelGroupID: '',
	City: '',
	CompanyFiscalID: '',
	CustomerID: '',
	DeliveryDate: '',
	DocumentNumber: '',
	Email: '',
	HouseNumber: '',
	Name: '',
	Neighborhood: '',
	NickName: '',
	PaymentConditions: [],
	PersonalFiscalID: '',
	PhoneNumber: '',
	PostalCode: '',
	Region: '',
	SalesOrganizationID: '',
	SegmentDescription: '',
	SegmentID: '',
	Street: '',
	SalesOrganizationName: '',
	SalesOrganizationOpeningHours: { Start: '', End: '' },
	SalesOrganizationPhoneNumber: '',
	sectorID: '',
	Password: '',
	activeSalesOrganizationID: ''
}

const update = (state = INITIAL_STATE, action: any) => action.customer
const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<IStoreCustomer, Action<ICustomerActionCreators>>(
	INITIAL_STATE,
	{
		[Types.UPDATE_CUSTOMER]: update,
		[Types.RESET_CUSTOMER]: reset
	}
)
