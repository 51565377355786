export function sortListByListPosition(list: any[]) {
  list.sort(function (a, b) {
    if (a.listingPosition > b.listingPosition) {
      return 1
    }
    if (a.listingPosition < b.listingPosition) {
      return -1
    }
    return 0
  })

  return list;
}

export function sortListByListPositionProduct(list: any[]) {
  list.sort(function (a, b) {
    if (a.ListingPosition > b.ListingPosition) {
      return 1
    }
    if (a.ListingPosition < b.ListingPosition) {
      return -1
    }
    return 0
  })

  return list;
}