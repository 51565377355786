import { ICategories } from "../shared/interfaces/categories";
import { sortListByListPosition } from "./sort-list-by-list-position";

export function categoryArraySort({
	dataBaseCategories,
	categoriesByProductList
}: {
	dataBaseCategories: ICategories[]
	categoriesByProductList: string[]
}) {
	const sortedCategories = sortListByListPosition(dataBaseCategories);

	const newArray = sortedCategories.flatMap((categorie) => {
		return categoriesByProductList
			.filter(
				(item) =>
					item.includes(categorie.description) ||
					categorie.description.includes(item)
			)
			.map((item) => ({
				value: item,
				label: item
			}))
	})

	return newArray
}
