import styled, { css } from 'styled-components'

export const PageHeaderContainer = styled.div`
	${({ theme }) => css`
		padding-top: 64px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		gap: ${theme.spacingOptions.SM}px;

		@media screen and (max-width: ${theme.breakpoints.values.xs}px) {
			flex-direction: column;
		}
	`}
`

export const RegisterParametersContent = styled.div`
	width: 100%;
`
