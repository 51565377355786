import React, { useCallback, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useHistory } from 'react-router-dom'

import {
	InputCellPhone,
	InputEmail,
	InputPassword,
	InputPhone,
	Loading
} from '../../shared/components'
import { ConfirmationMethods } from '../../components/AccountActivationSession/types'
import { registerUser } from '../NewAccount/service'

import { signUp } from './service'
import { IViewProps } from './types'
import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'
import {
	getCustomerRegisterValidationAttempts,
	getProductsForValidationByLastPurchase
} from '../../shared/services/customer.service'
import RegisterValidationSession from '../../shared/components/RegisterValidationSession'
import {
	IProductDescriptionAndImage,
	RegisterValidationMethods
} from '../../shared/components/RegisterValidationSession/types'
import { IUserSignUpTrackingGPClient } from '../NewAccount/types'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import AccountActivationSessionSecondary from '../../components/AccountActivationSessionSecondary'
import CheckBox from '../../components/CheckBox'
import ButtonPrimary from '../../components/ButtonPrimary'
import { colors, primaryButtonStyle } from '../../shared/styles/theme'
import { estabilishmentOptions } from '../NewAccount/util'
import { DataSelector } from '../../components/data-selector'
import { SelectorOption } from '../../components/data-selector/data-selector.types'
import { TypeOfEstabilishmentContainer } from './styles'

const queryParamEnum: { [id: number]: string } = {
	11: 'personalFiscalID',
	14: 'companyFiscalID'
}

export function GPClientForm(props: IViewProps) {
	const history = useHistory()
	const { documentNumber, setActive, setActive2, GPClientData } = props

	const [cellPhone, setCellPhone] = useState('')
	const [cellPhoneIsValid, setCellPhoneIsValid] = useState(false)
	const [password, setPassword] = useState('')
	const [passwordIsValid, setPasswordIsValid] = useState(false)
	const [email, setEmail] = useState('')
	const [emailIsValid, setEmailIsValid] = useState(false)
	const [phone, setPhone] = useState('')
	const [phoneIsValid, setPhoneIsValid] = useState(false)
	const [captcha, setCaptcha] = useState(false)
	const [loading, setLoading] = useState(false)
	const [LGPDCheckbox, setLGPDCheckbox] = useState(false)
	const [SMSCheckbox, setSMSCheckbox] = useState(false)

	const [selectedConfirmationMethod, setSelectedConfirmationMethod] =
		useState<ConfirmationMethods>(null)
	const [registerValidarionValue, setRegisterValidarionValue] = useState<
		IProductDescriptionAndImage | string
	>()
	const [newValidationAttempt, setNewValidationAttempt] = useState(0)
	const [
		isFetchingProductsForValidationByLastPurchase,
		setIsFetchingProductsForValidationByLastPurchase
	] = useState(false)

	const [
		productsForValidationByLastPurchase,
		setProductsForValidationByLastPurchase
	] = useState([])

	const [
		selectedRegisterValidationMethod,
		setSelectedRegisterValidationMethod
	] = useState<RegisterValidationMethods>('invoice')

	const [selectedEstabilishmentOption, setSelectedEstabilishmentOption] =
		useState<SelectorOption | null>(null)

	function handleRecaptcha(value: string | null): void {
		setCaptcha(value !== null)
	}

	function handleLGPDCheckbox() {
		setLGPDCheckbox(!LGPDCheckbox)
	}
	function handleSMSCheckbox() {
		setSMSCheckbox(!SMSCheckbox)
	}

	function handleEstabilishmentOption(selectedValue: SelectorOption | null) {
		setSelectedEstabilishmentOption({
			label: selectedValue?.label ?? '',
			value: selectedValue?.value ?? ''
		})
	}

	async function handleCreateNewAccount() {
		try {
			setLoading(true)

			const documentType = queryParamEnum[documentNumber.length]
			const CompanyFiscalID =
				documentType === 'companyFiscalID' ? documentNumber : ''
			const PersonalFiscalID =
				documentType === 'personalFiscalID' ? documentNumber : ''

			const user = {
				CompanyFiscalID: CompanyFiscalID,
				PersonalFiscalID: PersonalFiscalID,
				CellphoneNumber: '+55' + cellPhone,
				PhoneNumber: phone ? '+55' + phone : '',
				Email: email,
				AcceptLGPDTerms: LGPDCheckbox ? 'X' : '',
				AcceptNotificationTerms: SMSCheckbox ? 'X' : '',
				Name: '',
				NickName: '',
				IdentificationRegistryID: '',
				PostalCode: '',
				HouseNumber: '',
				Street: '',
				Neighborhood: '',
				AdressComplement: '',
				City: '',
				Region: '',
				typeOfEstablishment: selectedEstabilishmentOption?.value,
				tradeRepresentative: false
			}

			let customerNotPurchase = true

			if (GPClientData.customerID) {
				const response = await getCustomerRegisterValidationAttempts(
					GPClientData
				)
				customerNotPurchase = response.customerNotPurchase
			}

			const createGPClientData: IUserSignUpTrackingGPClient = {
				...user,
				...GPClientData,
				CustomerNotPurchase: customerNotPurchase ? 'X' : ''
			}

			if (typeof registerValidarionValue === 'object') {
				createGPClientData.SelectedProductToValidation = (
					registerValidarionValue as IProductDescriptionAndImage
				).productID
				createGPClientData.ValidatedProduct = 'X'
			} else {
				createGPClientData.InvoiceNumberConfirmation =
					registerValidarionValue as string
				createGPClientData.ValidatedProduct = ''
			}

			const registerResponseStatus = await registerUser(
				createGPClientData
			)

			if (
				!registerResponseStatus ||
				registerResponseStatus.status !== 201
			) {
				cogoToast.error(
					'Não foi possível completar seu registro, tente novamente mais tarde...'
				)
				return
			}

			if (
				typeof registerResponseStatus?.data.success === 'boolean' &&
				!registerResponseStatus.data.success
			) {
				const { attempts } =
					await getCustomerRegisterValidationAttempts(GPClientData)

				if (attempts.length === 2) {
					cogoToast.error(
						'Produto selecionado incorreto. Informe o número de nota fiscal.',
						cogoDefaultOptions
					)
				} else {
					cogoToast.error(
						'Produto selecionado incorreto. Tente novamente.',
						cogoDefaultOptions
					)
				}

				setNewValidationAttempt(attempts.length)
				return
			}

			const formatedConfirmationCodeParams =
				formatConfirmationCodeMethodParams(selectedConfirmationMethod)

			const reponseCognito = await signUp({
				documentNumber: documentNumber,
				email,
				invoiceNumber:
					typeof registerValidarionValue === 'object'
						? ''
						: registerValidarionValue,
				password,
				phoneNumber: '+55' + cellPhone,
				phoneTwo: phone ? '+55' + phone : '',
				sendemail: formatedConfirmationCodeParams.sendemail,
				sendsms: formatedConfirmationCodeParams.sendsms
			})

			if (!reponseCognito) {
				cogoToast.error(
					'Não foi possível completar seu registro, tente novamente mais tarde...'
				)
				return
			}

			history.replace(UnauthenticatedRoutesEnum.CONFIRM_ACTIVATION_CODE, {
				documentNumber: documentNumber,
				confirmationMethod: selectedConfirmationMethod
			})
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function isDisabled() {
		const toValidate = [
			cellPhoneIsValid,
			emailIsValid,
			phoneIsValid,
			passwordIsValid,
			// passwordConfirmationIsValid,
			captcha,
			LGPDCheckbox,
			!!selectedConfirmationMethod,
			!selectedEstabilishmentOption,
		]

		return !toValidate.every(Boolean)
	}

	const handleChangeConfirmationMethod = useCallback(
		(method: ConfirmationMethods) => {
			setSelectedConfirmationMethod(method)
		},
		[]
	)

	function validateRegisterValidationMethod(): boolean {
		if (selectedRegisterValidationMethod === 'invoice') {
			return (
				typeof registerValidarionValue === 'string' &&
				!!registerValidarionValue.length
			)
		}
		return (
			typeof registerValidarionValue === 'object' &&
			!!registerValidarionValue.productID
		)
	}

	function fetchProductOptionsToRegisterValidate() {
		;(async () => {
			const { attempts } = await getCustomerRegisterValidationAttempts({
				customerID: GPClientData.customerID,
				salesOrganizationID: GPClientData.salesOrganizationID
			})
			setNewValidationAttempt(attempts.length)
			if (
				GPClientData.customerID &&
				GPClientData.salesOrganizationID &&
				attempts.length < 2
			) {
				try {
					setIsFetchingProductsForValidationByLastPurchase(true)
					const response =
						await getProductsForValidationByLastPurchase(
							GPClientData
						)
					setProductsForValidationByLastPurchase(response)
					if (response.length) {
						setSelectedRegisterValidationMethod(
							'lastPurchaseProduct'
						)
					}
				} finally {
					setIsFetchingProductsForValidationByLastPurchase(false)
				}
			} else {
				setProductsForValidationByLastPurchase([])
			}
		})()
	}

	useEffect(fetchProductOptionsToRegisterValidate, [GPClientData])
	useEffect(() => {
		if (newValidationAttempt === 1) {
			fetchProductOptionsToRegisterValidate()
		} else if (newValidationAttempt === 2) {
			setSelectedRegisterValidationMethod('invoice')
			setRegisterValidarionValue(undefined)
			setProductsForValidationByLastPurchase([])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newValidationAttempt])

	return (
		<div className="gp-client-form">
			<div className="input-form">
				<div className="col-12 col-md-6 document">
					<InputCellPhone
						returnValue={setCellPhone}
						valueIsValid={setCellPhoneIsValid}
						className="input-tertiary"
					/>
					<InputPhone
						returnValue={setPhone}
						valueIsValid={setPhoneIsValid}
						className="input-tertiary"
					/>
					<InputEmail
						returnValue={setEmail}
						valueIsValid={setEmailIsValid}
						className="input-tertiary"
					/>
					<TypeOfEstabilishmentContainer>
						<DataSelector
							options={estabilishmentOptions()}
							isDisabled={false}
							placeholder="SELECIONE O TIPO DE ESTABELECIMENTO"
							selectedOption={selectedEstabilishmentOption}
							onSelectionChange={(option) => {
								handleEstabilishmentOption(option)
							}}
						/>
					</TypeOfEstabilishmentContainer>
					<InputPassword
						returnValue={setPassword}
						valueIsValid={setPasswordIsValid}
						login
						canShowPassword
						showPasswordTextColor={colors.red}
						className="input-tertiary"
					/>

					<span className="info-msg">
						SUA SENHA DEVE CONTER NO MÍNIMO
						<span className="red"> 8 CARACTERES </span>E AO MENOS
						<span className="red">
							{' '}
							UM CARACTERE MINÚSCULO, MAIÚSCULO E NUMÉRICO.
						</span>
					</span>
				</div>
			</div>

			<RegisterValidationSession
				loading={isFetchingProductsForValidationByLastPurchase}
				productsForValidationByLastPurchase={
					productsForValidationByLastPurchase
				}
				selectedRegisterValidationMethod={
					selectedRegisterValidationMethod
				}
				setSelectedRegisterValidationMethod={(value) => {
					setSelectedRegisterValidationMethod(value)
					setRegisterValidarionValue(undefined)
				}}
				returnValidationValue={setRegisterValidarionValue}
				registerValidarionValue={registerValidarionValue}
			/>

			<div className="activation">
				<AccountActivationSessionSecondary
					title="Como você deseja ativar sua conta?"
					selectedConfirmationMethod={selectedConfirmationMethod}
					handleChangeConfirmationMethod={
						handleChangeConfirmationMethod
					}
					labelEmail={'Receber Código de Ativação por '}
					labelSms={'Receber Código de Ativação por '}
					labelEmailRed={'E-mail'}
					labelSmsRed={'SMS'}
				/>
			</div>

			<div className="checkbox-group col-lg-6 col-md-12 center">
				<div className="checkbox">
					<label>
						<CheckBox
							type="checkbox"
							name="lgpd"
							id="lgpd"
							checked={LGPDCheckbox}
							fontSize={18}
							secondaryColor="red"
							primaryColor="white"
							backgroundColor="#f4f4f4"
							onChange={handleLGPDCheckbox}
						/>
						<p className="pTerm1">
							{`Confirmo que tomei ciência e autorizo a coleta,
							tratamento e o armazenamento dos meus dados pessoais.`}
							<button
								className="backgroundKnowMore"
								onClick={() => setActive(true)}>
								Saber mais
							</button>
						</p>
					</label>
				</div>

				<GoogleReCaptcha onVerify={handleRecaptcha} />

				<div className="checkbox">
					<label>
						<CheckBox
							type="checkbox"
							name="sms"
							checked={SMSCheckbox}
							fontSize={18}
							secondaryColor="red"
							backgroundColor="#f4f4f4"
							primaryColor="white"
							id="sms"
							onClick={handleSMSCheckbox}
						/>
						<p className="pTerm2">
							{`Autorizo a coleta, tratamento e o armazenamento dos meus dados pessoais, 
								histórico de compras e de contato para envio de informações sobre os produtos comercializados pelo 
								Grupo Petrópolis.`}
							<button
								className="backgroundKnowMore"
								onClick={() => setActive2(true)}>
								Saber mais
							</button>
						</p>
					</label>
				</div>
			</div>

			{loading ? (
				<div className="loading-wrapper">
					<Loading color={'red'} />
				</div>
			) : (
				<div className="button-wrapper">
					<ButtonPrimary
						onClick={handleCreateNewAccount}
						{...primaryButtonStyle}
						disabled={
							isDisabled() && !validateRegisterValidationMethod()
						}>
						ENVIAR CADASTRO
					</ButtonPrimary>
				</div>
			)}
		</div>
	)
}
