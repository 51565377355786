export interface IEncryptedField {
	typeField: TypeField
	text: string
	handleClick?: () => void
}

export enum TypeField {
	MAIL = 'mail',
	PHONE = 'phone',
	NONE = 'none'
}
