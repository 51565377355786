import React from 'react'
import { IImpactedClientCounterProps } from './types'
import { Container, CounterContainer } from './styles'

export function ImpactedClientCounter({ impactedClients }: IImpactedClientCounterProps) {
	return (
		<Container>
				<span>
				Clientes Impactados
				</span>

				<CounterContainer>
					{impactedClients}
				</CounterContainer>
		</Container>
	)
}
