import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IChallengeAlreadyShownCreators
} from '../../interfaces/action'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<IChallengeAlreadyShownCreators> = createActions({
	setChallengeShown: ['challengesIDs'],
	addChallengeShown: ['challengeID'],
	resetChallengesShown: []
})

/**
 * Handlers
 */

export const INITIAL_STATE: string[] = []

const setChallengeShown = (state = INITIAL_STATE, action: any) =>
	action.challengesIDs

const addChallengeShown = (state = INITIAL_STATE, action: any): string[] => {
	const { challengeID } = action
	const challengesIDs = [...state]

	challengesIDs.push(challengeID)

	return challengesIDs
}

const resetChallengesShown = (state = INITIAL_STATE, action: any) =>
	INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<string[], Action<IChallengeAlreadyShownCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_CHALLENGE_SHOWN]: setChallengeShown,
		[Types.ADD_CHALLENGE_SHOWN]: addChallengeShown,
		[Types.RESET_CHALLENGES_SHOWN]: resetChallengesShown
	}
)
