import { Action, combineReducers, Reducer, ReducersMapObject } from 'redux'

import { IActionsCreators } from '../../interfaces/action'
import { IStore } from '../../interfaces/store'
import bottomBar from './bottomBar'
import browserHistory from './browserHistory'
import carts from './cart'
import companyParams from './companyParams'
import customer from './customer'
import orderHistory from './orderHistory'
import resale from './resale'
import resales from './resales'
import token from './token'
import transferArea from './transferArea'
import pointsProgramStatus from './pointsProgramStatus'
import cartRedemptionItems from './cartRedemptionItem'
import cartChallengeItems from './cartChallengeItems'
import challengesAlreadyShown from './challengesAlreadyShown'
import challengesTheUserIsParticipatingIn from './challengesTheUserIsParticipatingIn'

const reducers: ReducersMapObject<IStore, Action<IActionsCreators>> = {
	bottomBar,
	browserHistory,
	carts,
	companyParams,
	customer,
	orderHistory,
	resale,
	resales,
	token,
	transferArea,
	pointsProgramStatus,
	cartRedemptionItems,
	cartChallengeItems,
	challengesAlreadyShown,
	challengesTheUserIsParticipatingIn
}

export const Reducers: Reducer = combineReducers(reducers)
