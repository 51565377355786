import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { IS_VALID_PASSWORD } from '../../shared/utils/validators'
import InputField from '../InputField'
import { IProps } from './types'
import styles from './styles.module.scss'

function InputPassword(props: IProps) {
	const {
		returnValue,
		valueIsValid,
		secondary = false,
		login,
		shouldValidate = true,
		canShowPassword = false,
		showPasswordTextColor = '#fff',
		className = ''
	} = props

	// const passwordMask = '********************'

	const [isPasswordVisible, setIsPasswordVisible] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setTouched(true)
		returnValue(e.target.value)
		setInputValue(e.target.value)
	}

	function validateInput() {
		// Password must be between 4 and 8 digits long
		// and include at least one numeric digit.
		const isValid = shouldValidate
			? IS_VALID_PASSWORD.test(inputValue)
			: true

		valueIsValid(isValid)

		setHasError(touched && !isValid)
	}

	function handleToggletShowPassword() {
		setIsPasswordVisible((state) => !state)
	}

	useEffect(validateInput, [
		value,
		inputValue,
		shouldValidate,
		touched,
		valueIsValid
	])

	return (
		<div style={{ position: 'relative' }}>
			<InputField
				className={className}
				secondary={secondary}
				maxLength={20}
				type={isPasswordVisible ? 'text' : 'password'}
				onChange={handleChange}
				id="password"
				label={'Senha'}
				hasError={hasError}
				errorMessage={'Senha inválida'}
				infoMsg={
					login
						? ''
						: 'Sua senha deve conter no mínimo 8 caracteres e ao menos um caracter minúsculo, maiúsculo e numérico.'
				}
			/>
			{canShowPassword && (
				<button
					type="button"
					onClick={handleToggletShowPassword}
					className={`reset-button-style ${styles['show-password-button']}`}
					style={{ color: showPasswordTextColor }}>
					{isPasswordVisible ? 'Ocultar' : 'Mostrar'}
				</button>
			)}
		</div>
	)
}

export default InputPassword
