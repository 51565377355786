import styled from 'styled-components'

export const WrapperDaysDeliveryTable = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.LG}px;
	padding-top: 26px;

	width: 100%;
	background-color: ${(props) => props.theme.palette.white.main};

	h2 {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	.table-wrapper .table {
		width: 1280px;

		thead {
			background: ${(props) => props.theme.palette.white.main};
			height: 77px;
			border-top: 1px solid
				${(props) => props.theme.palette.lightBorder.main};
			box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
			th {
				color: ${(props) => props.theme.palette.black.main};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
				font-weight: ${(props) => props.theme.font.weight.semibold};
			}
		}

		tbody tr td {
			width: 90px;
		}

		tbody tr td:nth-child(3) {
			padding: 16px 8px;
		}
		tbody tr td:nth-child(1) {
			padding: 16px 8px;
			vertical-align: middle;
			color: ${(props) => props.theme.palette.primary.main};
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
		}
		tbody tr td:nth-child(2) {
			padding: 16px 8px;
			vertical-align: middle;
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
		}

		tbody tr td:nth-child(3) {
			padding: 16px 8px;
			vertical-align: middle;
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
		}

		tbody tr td {
			padding: 16px 8px;
			vertical-align: bottom;
		}
	}
`

export const FilterWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`
