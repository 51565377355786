import styled from 'styled-components'

export const MyPointsWrapper = styled.div`
	width: 100%;
	padding: 16px;
	min-height: 100vh;
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
`

export const ContentWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	max-width: 1088px;
`

export const CardsWrapper = styled.div`
	display: grid;
	column-gap: 16px;
	margin-top: 48px;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(2, 1fr);

	@media screen and (max-width: 768px) {
		row-gap: 16px;
		justify-content: center;
		grid-template-columns: 1fr;
	}
`

export const ReadMembershipTermsWrapper = styled.div`
	width: 100%;
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ButtonReadMembershipTerms = styled.button`
	border: none;
	color: ${(props) => props.theme.palette.primary.main};
	cursor: pointer;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	background-color: transparent;
	border-bottom: 1px solid ${(props) => props.theme.palette.black.main};
	border-color: ${(props) => props.theme.palette.primary.main};
`

export const ShowMoreButtonWrapper = styled.div`
	width: 100%;
	padding: 12px;
	display: flex;
	margin-top: 24px;
	align-items: center;
	justify-content: center;
`
export const ShowMoreButton = styled.button`
	border: none;
	color: ${(props) => props.theme.palette.primary.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	cursor: pointer;
	text-transform: uppercase;
	background-color: transparent;
	border-bottom: 1px solid ${(props) => props.theme.palette.primary.main};
`

export const LoadingWrapper = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	align-items: center;
	justify-content: center;
`
