import styled from 'styled-components'
import Drawer from '@mui/material/Drawer'

export const Sidebar = styled(Drawer)`
	&& {
		.MuiPaper-root {
			width: 464px;
			height: 1008px;
			position: absolute;
			bottom: 0;
			top: 112px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			padding: 32px 24px;
			gap: 34px;
		}
	}

	.close-icon-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
		width: 100%;
		height: 72px;

		svg {
			color: ${(props) => props.theme.palette.primary.main};

			&:hover {
				cursor: pointer;
			}
		}
	}

	h2 {
		width: 100%;
		text-align: left;
		font-weight: ${(props) => props.theme.font.weight.semibold};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${(props) => props.theme.palette.black.main};
	}
`
