import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

import { colorThemeProvider } from '../../theme/themeProvider'
import { ChipTypeEnum, IStylesProps } from './types'

export const ChipWithInformationAboutTheChallengeWrapper = styled.div<IStylesProps>`
	min-width: 90px;
	max-height: 19px;
	padding: 4px 8px;
	border-radius: 10px;

	text-align: center;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${({ type, beingDisplayedInSomePurchaseFlow }) =>
		type === ChipTypeEnum.NEW_CHALLENGE
			? colorThemeProvider.whiteColor
			: type === ChipTypeEnum.SHOW_POINTS_EARNED &&
			  !beingDisplayedInSomePurchaseFlow
			? colorThemeProvider.whiteColor
			: type === ChipTypeEnum.SHOW_POINTS_EARNED &&
			  beingDisplayedInSomePurchaseFlow
			? colorThemeProvider.primaryWhiteColor
			: type === ChipTypeEnum.IS_PARTICIPATING ||
			  type === ChipTypeEnum.COMPLETED
			? colorThemeProvider.successColor
			: colorThemeProvider.primaryColor};
`

export const ChipWithInformationAboutTheChallengeText = styled.span<IStylesProps>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;
	font-weight: ${(props) => props.theme.font.weight.medium};

	/* color: ${({ type }) =>
		type === ChipTypeEnum.NEW_CHALLENGE ||
		type === ChipTypeEnum.SHOW_REMAINING_DAYS
			? colors.red
			: colors.green}; */

	color: ${({ type }) =>
		type === ChipTypeEnum.IS_PARTICIPATING ||
		type === ChipTypeEnum.COMPLETED
			? colorThemeProvider.whiteColor
			: colorThemeProvider.blackColor};
`
