import React, { ChangeEvent, useEffect } from 'react'
import DefaultLayoutAdmin from '../../../layout/default/deafult-admin.layout'
import TitlePage from '../../../components/title-page/title-page.component'
import Table from '../../../components/Table'
import Loading from '../../../components/Loading'

import { DateRange } from 'react-day-picker-8'
import SearchInput from '../../../components/SearchInput'
import {
	DeleteIcon,
	EditIcon,
	WrapperInputs,
	DetailsNotification
} from './history-push.styles'
import CancelPushModal from '../components/push-modals/cancel-push-modal/cancel-push-modal.component'
import DetailsPushModal from '../components/push-modals/details-push-modal/details-push-modal'

import { pushNotificationsAdmin } from '../services/push-notifications-admin'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import {
	ActionType,
	IHistoryPush,
	NotificationType,
	StatusType,
	NotificationTypePTBR
} from './history-push.types'
import EditPushModal from '../components/push-modals/edit-push-modal/edit-push-modal.component'
import { newFilters } from './utils/filters'
import { DateRangePicker } from '../../../components/date-range-picker'

const HistoryPush = () => {
	const [selectedDate, setSelectedDate] = React.useState<DateRange>({
		from: new Date(new Date().setDate(1)),
		to: new Date()
	})
	const [searchPush, setSearchPush] = React.useState('')

	const [isOpenDetailsModal, setIsOpenDetailsModal] = React.useState(false)
	const [detailsNotification, setDetailsNotification] =
		React.useState<IHistoryPush>()
	const [isOpenCancelModal, setIsOpenCancelModal] = React.useState(false)
	const [isOpenEditModal, setIsOpenEditModal] = React.useState(false)

	const [idHistoryPushCancel, setIdHistoryPushCancel] = React.useState('')
	const [justification, setJustification] = React.useState<string>('')
	const [isLoading, setIsLoading] = React.useState(false)
	const [historyPushTable, setHistoryPushTable] = React.useState<
		IHistoryPush[]
	>([])

	// Edit push states
	const [selectedPush, setSelectedPush] = React.useState<IHistoryPush>({
		ID: '',
		createdAt: '',
		createdBy: '',
		filters: {
			ufs: [],
			cities: [],
			channels: [],
			codePdv: '',
			codeResales: [],
			customers: [],
			diretor: [],
			GRC: []
		},
		modifiedAt: '',
		modifiedBy: '',
		title: '',
		message: '',
		dtScheduling: '',
		qtdCustomer: '',
		type: NotificationType.IMMEDIATE,
		status: StatusType.CANCELLED,
		dtSent: '',
		actionType: ActionType.OPEN_APP,
		productToOpen: '',
		categoryToOpen: '',
		notificationId: 0
	})
	const [textAreaValue, setTextAreaValue] = React.useState('')
	const [titlePush, setTitlePush] = React.useState('')

	const handleChangeTextTitle = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setTitlePush(event.target.value)
	}

	const handleChangeTextArea = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setTextAreaValue(event.target.value)
	}

	const getHistoryPushTableCallback = React.useCallback(
		getHistoryPushTable,
		[]
	)

	useEffect(() => {
		selectedDate.from?.setHours(0, 0, 1)
		selectedDate.to?.setHours(23, 59, 59)
		if (searchPush.length === 0) {
			getHistoryPushTableCallback(selectedDate.from, selectedDate.to)
		}
	}, [
		searchPush,
		selectedDate.from,
		selectedDate.to,
		getHistoryPushTableCallback
	])

	useEffect(() => {
		if (selectedPush) {
			setTextAreaValue(selectedPush.message)
			setTitlePush(selectedPush.title)
		}
	}, [selectedPush])

	async function handleGetPushByTitle() {
		if (searchPush.length === 0) {
			return
		}

		getHistoryPushTable(selectedDate.from, selectedDate.to, searchPush)
	}

	function changeSetSelectedDate(range: any) {
		if (!range.to) {
			range.to = range.from
		}
		setSelectedDate(range)
		if (range.from && range.to) getHistoryPushTable(range.from, range.to)
	}

	async function getHitoryPushList(from?: Date, to?: Date, title?: string) {
		setIsLoading(true)
		try {
			const response = await pushNotificationsAdmin.getHitoryPushList({
				from: from?.toISOString(),
				to: to?.toISOString(),
				title: title
			})
			return response
		} catch (error) {
			cogoToast.error(
				'Erro ao trazer histórico de push.',
				cogoDefaultOptions
			)
			return []
		} finally {
			setIsLoading(false)
		}
	}

	async function getHistoryPushTable(from?: Date, to?: Date, title?: string) {
		const historyPush = await getHitoryPushList(from, to, title)
		const historyArray = historyPush.map((item: IHistoryPush) => ({
			...item,
			createdAt: new Date(item.createdAt).toLocaleDateString(),
			dtScheduling: new Date(item.dtScheduling).toLocaleDateString(),
			typePTBR: NotificationTypePTBR[item.type],
			id: (
				<DetailsNotification
					onClick={() => {
						setDetailsNotification({
							...item,
							filters: newFilters(item.filters)
						})
						setIsOpenDetailsModal(true)
					}}>
					{item.notificationId}
				</DetailsNotification>
			),
			edit: (
				<EditIcon
					data-disabled-edit={verifyIfIsSchedule(item)}
					isDisabled={verifyIfIsSchedule(item)}
					onClick={() => {
						setIsOpenEditModal(!verifyIfIsSchedule(item))
						setSelectedPush({ ...item })
					}}
				/>
			),
			cancel: (
				<DeleteIcon
					data-disabled-delete={verifyIfIsSchedule(item)}
					isDisabled={verifyIfIsSchedule(item)}
					onClick={() => {
						setIsOpenCancelModal(!verifyIfIsSchedule(item))
						setIdHistoryPushCancel(item.ID)
					}}
				/>
			)
		}))
		setHistoryPushTable(historyArray)
	}

	function verifyIfIsSchedule(item: IHistoryPush): boolean {
		if (
			item.type === NotificationType.IMMEDIATE ||
			item.status === StatusType.SENT ||
			item.status === StatusType.CANCELLED
		) {
			return true
		}
		return false
	}

	async function cancelHitoryPush() {
		if (!justification) return
		try {
			await pushNotificationsAdmin
				.putCancelHitoryPush(idHistoryPushCancel, justification)
				.finally(() => {
					cogoToast.success(
						'Push cancelado com sucesso.',
						cogoDefaultOptions
					)
					getHistoryPushTable(selectedDate.from, selectedDate.to)
					setIsLoading(false)
					setIsOpenCancelModal(false)
				})
		} catch (error) {
			cogoToast.error(
				'Erro ao cancelar notificação de push.',
				cogoDefaultOptions
			)
		} finally {
			setJustification('')
		}
	}

	async function editHistoryPush() {
		if (!textAreaValue || !titlePush) return
		setIsLoading(true)
		try {
			pushNotificationsAdmin
				.editPush(selectedPush?.ID, titlePush, textAreaValue)
				.finally(() => {
					cogoToast.success(
						'A alteração foi realizada com sucesso.',
						cogoDefaultOptions
					)
					getHistoryPushTable(selectedDate.from, selectedDate.to)
					setIsLoading(false)
					setIsOpenEditModal(false)
				})
		} catch (error) {
			cogoToast.error(
				'Erro ao editar notificação de push.',
				cogoDefaultOptions
			)
		}
	}

	const columns = React.useMemo(
		() => [
			{ Header: 'id', accessor: 'id' },
			{ Header: 'Tipo', accessor: 'typePTBR' },
			{ Header: 'Título', accessor: 'title' },
			{ Header: 'Data da criação', accessor: 'createdAt' },
			{ Header: 'Data de agendamento', accessor: 'dtScheduling' },
			{ Header: 'Clientes', accessor: 'qtdCustomer' },
			{ Header: 'Editar', accessor: 'edit' },
			{ Header: 'Cancelar', accessor: 'cancel' }
		],
		[]
	)

	return (
		<DefaultLayoutAdmin>
			<>
				{isOpenDetailsModal && (
					<DetailsPushModal
						isOpen={isOpenDetailsModal}
						handleToggleModal={() => setIsOpenDetailsModal(false)}
						handleClick={() => setIsOpenDetailsModal(false)}
						details={detailsNotification}
					/>
				)}

				{isOpenEditModal && (
					<EditPushModal
						handleToggleModal={() => setIsOpenEditModal(false)}
						isOpen={isOpenEditModal}
						pushTitle={titlePush}
						messagemAplied={textAreaValue}
						usersImpacted={selectedPush?.qtdCustomer}
						handleChangeTitle={handleChangeTextTitle}
						handleChangeMessage={handleChangeTextArea}
						handleClickEditModal={() => editHistoryPush()}
						disabledButton={
							!textAreaValue || !titlePush ? true : false
						}
						isLoading={isLoading}
					/>
				)}

				{isOpenCancelModal && (
					<CancelPushModal
						isOpen={isOpenCancelModal}
						handleToggleModal={() => setIsOpenCancelModal(false)}
						handleClick={cancelHitoryPush}
						handleTextArea={setJustification}
						disabledButton={justification.length < 3 ? true : false}
						isLoading={isLoading}
					/>
				)}
				<TitlePage titlePage="Histórico de Push" />
				<WrapperInputs>
					<SearchInput
						inputValue={searchPush}
						width="359px"
						handleChange={(event) =>
							setSearchPush(event.target.value)
						}
						handleClickSearch={handleGetPushByTitle}
					/>
					<DateRangePicker
						selectedDate={selectedDate}
						setSelectedDate={changeSetSelectedDate}
						placeholder="Filtrar por data"
					/>
				</WrapperInputs>
				{isLoading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<Table data={historyPushTable} columns={columns} />
				)}
			</>
		</DefaultLayoutAdmin>
	)
}
export default HistoryPush
