import React, { useEffect } from 'react'
import { IOption } from '../../types'
import * as S from './select-impacted-pdv.styles'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined'

export interface SelectImpactedPdvProps {
	options: IOption[]
	label: string
	setOptions: (str: Array<string>) => void
	isEnabled: boolean
	handleFilter: () => void
}

const SelectImpactedPdv: React.FC<SelectImpactedPdvProps> = ({
	options,
	setOptions,
	isEnabled,
	handleFilter
}) => {
	useEffect(() => {
		if (!!options.length) handleFilter()
	}, [handleFilter, options])

	const filterOptionsToState = (optionSelected: IOption) =>
		options
			.filter((option) => option.label !== optionSelected!.label)
			.map((option) => option.label)
	return (
		<S.AutocompleteStyled
			clearIcon={
				<SearchOutlinedIcon sx={{ color: '#CE332E', size: 16 }} />
			}
			onChange={(_, optionSelected) => {
				if (optionSelected && isEnabled)
					setOptions(filterOptionsToState(optionSelected as IOption))
				
			}}
			options={options}
			value={{
				label: `${options.length}`,
				value: `${options.length}`
			}}
			renderInput={(params) => {
				return (
					<S.TextFieldStyled
						{...params}
						InputLabelProps={{
							shrink: false,
							sx: {
								'&.Mui-focused': {
									color: '#CE332E',
									shrink: false
								}
							}
						}}
						placeholder="Buscar código"
						label="PDVS IMPACTADOS"
					/>
				)
			}}
			renderOption={(props, option) => {
				const optionCast: IOption = option as IOption
				return (
					<S.BoxStyled {...props}>
						{isEnabled && <IndeterminateCheckBoxOutlinedIcon />}
						<S.LabelText>{optionCast.label}</S.LabelText>
					</S.BoxStyled>
				)
			}}
		/>
	)
}

export default SelectImpactedPdv
