import { useEffect, useState } from 'react'

/**
 * @description Takes a string specifying the break point, example: (max-width: 40rem)
 * @returns returns true if the screen size is equal or less and false otherwise
 * **/
const useMedia = (media: string) => {
	const [match, setMatch] = useState<boolean>(false)

	useEffect(() => {
		const changeMatch = () => {
			const { matches } = window.matchMedia(media)
			setMatch(matches)
		}
		changeMatch()
		window.addEventListener('resize', changeMatch)

		return () => {
			window.removeEventListener('resize', changeMatch)
		}
	}, [media])

	return match
}

export default useMedia
