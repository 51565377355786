import React, { useCallback, useEffect, useState } from 'react'

import { PresentationModal } from './PresentationModal'
import { TermsModal } from './TermsModal'
import { IProps, ModalActiveType } from './types'

export default function PointsProgramModalAdoption({
	show,
	onClose,
	goToThePoints = false
}: IProps) {
	const [modalActive, setModalActive] = useState<ModalActiveType>('')

	const handleClose = useCallback(() => {
		onClose()
	}, [onClose])

	const nextModal = useCallback(() => {
		setModalActive('TERMS')
	}, [setModalActive])

	useEffect(() => {
		if (!show) setModalActive('')
		else if (show && modalActive === '') setModalActive('PRESENTATION')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show])

	return (
		<>
			<PresentationModal
				active={modalActive === 'PRESENTATION'}
				onClose={handleClose}
				onAdvance={nextModal}
			/>
			<TermsModal
				active={modalActive === 'TERMS'}
				onClose={handleClose}
				goToThePoints={goToThePoints}
			/>
		</>
	)
}
