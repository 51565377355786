import styled, { css } from 'styled-components'

export const ModalReviewCouponContainer = styled.div`
	${({ theme }) => css`
		margin-bottom: ${theme.spacingOptions.MD}px;
	`}
`

export const SectionReviewTitle = styled.div`
	${({ theme }) => css`
		font-weight: ${theme.font.weight.semibold};
		font-size: ${theme.font.size.TEXT_SM.size}rem;
		color: ${theme.palette.white.contrastText};
	`}
`
export const SectionReview = styled.div`
	${({ theme }) => css`
		margin-top: ${theme.spacingOptions.MD}px;
	`}
`
export const ModalItemLine = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	${({ theme }) => css`
		margin: ${theme.spacingOptions.SM}px 0px;
	`}
`

export const RowFilterProductsRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	width: 100%;
`

export const RulesSelectsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 178px);
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	align-items: center;
	justify-content: center;
`
export const FiltersByHierarquySection = styled.div`
	display: grid;
	grid-template-rows: repeat(3, 1fr);
	grid-template-columns: repeat(2, 278px);
	grid-gap: 16px 24px;

	padding: 16px 0px;
`
export const LoadingWrapper = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
`
