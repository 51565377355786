import React from 'react'

import { Link } from 'react-router-dom'

import { Stack } from '@mui/material'

import * as S from './go-back.styles'

type GoBackProps = {
	to: string
	title?: string
}

export default function GoBack({ to, title = 'Voltar' }: GoBackProps) {
	return (
		<Link to={to}>
			<Stack direction="row" gap={1}>
				<S.GoBackIcon sx={{ color: '#3D3D3D' }} />
				<S.Title>{title}</S.Title>
			</Stack>
		</Link>
	)
}
