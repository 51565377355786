export function convertValueToSelectOption(value: string | number) {
	return {
		value: value.toString(),
		label: value.toString()
	}
}

export function getScoreValueRatio(scoreValue: string) {
	const [, ratio] = scoreValue.split(':')
	return ratio
}
