import { useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { useDispatch, useSelector } from 'react-redux'
// import { IProps } from './types'
import { useHistory } from 'react-router-dom'

import { IStore } from '../../shared/interfaces/store'
import { Creators as HistoryActions } from '../../shared/store/ducks/browserHistory'

function RedirectBrowser() {
	const history = useHistory()
	const dispatch = useDispatch()
	const browserHistory = useSelector((state: IStore) => state.browserHistory)
	const [lastState, setLastState] = useState('')
	const { reset } = HistoryActions
	let prevPathName = ''

	function handleRedirect() {
		if (browserHistory.length && lastState !== browserHistory) {
			history.push(browserHistory)
			setLastState(browserHistory)
			dispatch(reset())
		}
	}

	history.listen((location) => {
		if (prevPathName !== location.pathname) {
			prevPathName = location.pathname
			ReactGA.set({ page: location.pathname })
			ReactGA.pageview(location.pathname)
		}
	})

	useMemo(handleRedirect, [handleRedirect])

	return null
}

export default RedirectBrowser
