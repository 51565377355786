import { IParameterItem } from './types'

export const MOCKED_PARAMS: IParameterItem[] = [
	{
		DeletionFlag: '',
		Description: '',
		Hint: '',
		LabelValues: '',
		Name: '',
		ParameterID: '',
		Type: 'TIME',
		TypeValue: 'INTERVAL'
	},
	{
		DeletionFlag: '',
		Description: '',
		Hint: '',
		LabelValues: '',
		Name: '',
		ParameterID: '',
		Type: 'TIME',
		TypeValue: 'INTERVAL'
	}
]

export const DEFAULT_PARAM: IParameterItem = {
	DeletionFlag: '',
	Description: '',
	Hint: '',
	LabelValues: '',
	Name: '',
	ParameterID: '',
	Type: 'TIME',
	TypeValue: 'INTERVAL'
}
