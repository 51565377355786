import { useMutation } from 'react-query'
import couponService from '../../services/coupon.service'
import {IError} from '../../../../../modules/Error/types'

import {
	InformationCouponProps,
	OnDateStartChangeType
} from './information-coupon.types'

export default function useInformationCoupon({
	setError,
	clearErrors,
	watch
}: InformationCouponProps) {
	const showInAppOptions = [
		{ label: 'Sim', value: 'app' },
		{ label: 'Não', value: 'none' }
	]

	const unlimitedOptions = [
		{
			label: 'Ilimitado',
			value: 'Ilimitado'
		}
	]

	const discountTypeOptions = [
		{
			label: 'Porcentagem',
			value: 'percent'
		},
		{
			label: 'Valor',
			value: 'money'
		}
	]

	const onDateStartChange = ({
		date: dateStart,
		getValues,
		onChange
	}: OnDateStartChangeType) => {
		const dateEnd = getValues('date.dateEnd')

		if (!dateStart) return

		if (dateStart < new Date()) {
			onChange?.(new Date())
		}

		if (!dateEnd) return
	}

	const onDateEndChange = ({
		date: dateEnd,
		onChange
	}: OnDateStartChangeType) => {
		if (!dateEnd) return

		if (dateEnd < new Date()) {
			onChange?.(new Date())
		}
	}

	const { mutate: handleVerifyCodeCoupon, isLoading: loadingVerifyCoupon } =
		useMutation({
			mutationKey: ['couponCheckCode'],
			mutationFn: async (code: string) => {
				if (code.length === 0) return
				const data = await couponService.checkCode(code)
				return data
			},
			onError(error: IError) {
				if (!error) return

				setError('code', {
					message: error?.message
				})
			},
			onSuccess(data) {
				if (data) {
					setError('code', {
						message: 'Código em uso'
					})
				} else {
					clearErrors('code')
				}
			}
		})

	return {
		showInAppOptions,
		unlimitedOptions,
		discountTypeOptions,
		onDateStartChange,
		onDateEndChange,
		handleVerifyCodeCoupon,
		loadingVerifyCoupon
	}
}
