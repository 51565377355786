export const formatStringSimple = (text: string) => {
	const regex = /[^a-z0-9\s]/g

	return text
		.toLowerCase()
		.normalize('NFD')
		.replace(regex, '')
		.replace(/\s+/g, ' ')
		.trim()
}
