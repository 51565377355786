// import { configure } from 'axios-hooks'
// import LRU from 'lru-cache'
import React, { StrictMode } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { QueryClientProvider } from 'react-query'
import { UnavailableProductProvider } from './context/UnavailableProductContext'
import { UserAuthDataProvider } from './context/user-auth-data'
import Routes from './modules/Routes'
import { persistor, store } from './shared/store'
import { RecoverPasswordContextProvider } from './context/recover-password.context'
import { CustomerInfoProvider } from './context/customer-info.context'

import { queryClient } from './config/query-client.config'
import { FeatureFlagProvider } from './context/feature-flag/feature-flag.context'

function App(): JSX.Element {
	// const cache = new LRU({ max: 10 })

	// configure({ axios: axiosInstance, cache })

	return (
		<StrictMode>
			{/*@ts-ignore */}
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<QueryClientProvider client={queryClient}>
							<FeatureFlagProvider>
								<RecoverPasswordContextProvider>
									<UserAuthDataProvider>
										<UnavailableProductProvider>
											<CustomerInfoProvider>
												<Routes />
											</CustomerInfoProvider>
										</UnavailableProductProvider>
									</UserAuthDataProvider>
								</RecoverPasswordContextProvider>
							</FeatureFlagProvider>
						</QueryClientProvider>
					</MuiPickersUtilsProvider>
				</PersistGate>
			</Provider>
		</StrictMode>
	)
}

export default App
