import { apiAdmin } from '../../shared/services/axiosInstace'
import { deliveryManagementAdminRulesType } from './delivery-management-admin.types'

class DeliveryManagementAdminService {
	async deliveryDateRules(
		rules: deliveryManagementAdminRulesType
	): Promise<boolean> {
		const { data } = await apiAdmin.post(
			`/delivery-date-distribution/rules`,

			rules
		)
		return data
	}
}
const deliveryManagementAdmin = new DeliveryManagementAdminService()

export default deliveryManagementAdmin
