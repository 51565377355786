import React, { useState, useEffect, useCallback } from 'react'
import { Row } from '../Information/Information.styles'
import InputDocument from '../../../../../components/InputDocument'
import {
	primaryMultiselectStyles,
	reactSelectStyles
} from '../../../../../shared/styles/theme'
import { getDropdownButtonLabelOverride } from '../../../../../shared/utils/multiSelectCheckboxUtils'
import {
	FiltersValuesEnum,
	IRequestProps,
	ISelectedOptionsFilter
} from '../../../../../modules/PushNotification/types'
import InputField from '../../../../../components/InputField'
import {
	filterDiretor,
	filterGRC,
	getSalesHierarchy,
	handleFilterData
} from '../../../../../modules/PushNotification/service'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import {
	ISelectOption,
	options
} from '../../../../../shared/utils/SelectOptions'
import DateCalendar from '../Date/DateRange.view'

interface IFilter {
	handleCpf: (document: any) => void
	sendData: (dadosCliente: any) => void
}

interface IData {
	documentNumber: string
	nomeCliente: string
	codCliente: string
	cd: ISelectOption[]
	canal: ISelectOption[]
	data: string
}

function FilterConsult({ handleCpf, sendData }: IFilter) {
	const [documentNumber, setDocumentNumber] = useState('')
	const [documentIsValid, setDocumentIsValid] = useState(false)

	const [data, setData] = useState<IData>({
		documentNumber: '',
		nomeCliente: '',
		codCliente: '',
		cd: [],
		canal: [],
		data: ''
	})

	const [filterOptions, setFilterOptions] = useState<ISelectedOptionsFilter>({
		ufs: [],
		cities: [],
		channels: [],
		resaleCodes: [],
		diretores: [],
		grc: [],
		customers: []
	})
	const [totalData, setTotalData] = useState('')

	const [selectedFilterOptions] = useState<ISelectedOptionsFilter>({
		ufs: [],
		cities: [],
		channels: [],
		resaleCodes: [],
		diretores: [],
		grc: [],
		customers: []
	})

	function fetchData() {
		;(async () => {
			try {
				const result: IRequestProps = await handleFilterData()
				const salesHierarchyData = await getSalesHierarchy()
				const diretores = await filterDiretor(salesHierarchyData)
				const grc = await filterGRC(salesHierarchyData)

				if (!result) return
				let filterOptionsList = {
					ufs: result.ufs,
					cities: result.cities,
					channels: result.channels,
					resaleCodes: result.codResales,
					diretores,
					grc,
					customers: []
				}

				filterOptionsList.channels.push({
					label: ' - BRQ - Devs',
					value: 'brq'
				})

				filterOptionsList.channels.push({
					label: ' - [GP] Bom Parceiro',
					value: 'gpbp'
				})

				setFilterOptions(filterOptionsList)
			} finally {
				// setIsLoading(false)
			}
		})()
	}

	useEffect(fetchData, [])

	const handleChangeFilter = useCallback(
		async (filter: FiltersValuesEnum, value: ISelectOption[]) => {
			if (filter === FiltersValuesEnum.CHANNEL) {
				setData((prev) => ({
					...prev,
					canal: value
				}))
			}
			if (filter === FiltersValuesEnum.RESALE_CODE) {
				setData((prev) => ({
					...prev,
					cd: value
				}))
			}
		},
		[selectedFilterOptions, FiltersValuesEnum]
	)

	useEffect(() => {
		const obj = {
			...data,
			documentNumber: documentNumber
		}
		setData(obj)
	}, [documentNumber])

	const handleData = useCallback(
		(property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
			const obj = {
				...data,
				[property]: (event.target as HTMLInputElement).value
			}
			setData(obj)
		},
		[data]
	)

	useEffect(() => {
		sendData(data)
	}, [data])

	useEffect(() => {
		if (data.nomeCliente || data.codCliente || documentIsValid) {
			return handleCpf(true)
		}
		if (!documentIsValid || !data.nomeCliente || !data.codCliente) {
			return handleCpf(false)
		}
	}, [data.nomeCliente, data.codCliente, documentIsValid])

	const handleResultData = useCallback(
		async (document: any) => {
			setTotalData(document)
		},
		[totalData]
	)

	return (
		<>
			<Row>
				<InputDocument
					returnValue={setDocumentNumber}
					valueIsValid={setDocumentIsValid}
					className="input-tertiary"
				/>
				<InputField
					className="param-input"
					id="nomeCliente"
					label="Nome Cliente"
					value={data.nomeCliente}
					onChange={handleData('nomeCliente')}
				/>
				<InputField
					className="param-input"
					id="codigoCliente"
					label="Código Cliente"
					value={data.codCliente}
					onChange={handleData('codCliente')}
				/>
			</Row>
			<Row>
				<div className="select-filter">
					<ReactMultiSelectCheckboxes
						options={options(filterOptions.resaleCodes)}
						onChange={(value: ISelectOption[]) => {
							handleChangeFilter(
								FiltersValuesEnum.RESALE_CODE,
								value
							)
						}}
						value={data.cd}
						getDropdownButtonLabel={getDropdownButtonLabelOverride}
						isMulti
						placeholderButtonLabel="CD"
						{...primaryMultiselectStyles}
						styles={reactSelectStyles}
					/>
				</div>
				<div className="select-filter">
					<ReactMultiSelectCheckboxes
						options={options(filterOptions.channels)}
						onChange={(value: ISelectOption[]) => {
							handleChangeFilter(FiltersValuesEnum.CHANNEL, value)
						}}
						value={data.canal}
						getDropdownButtonLabel={getDropdownButtonLabelOverride}
						isMulti
						placeholderButtonLabel="Canal"
						{...primaryMultiselectStyles}
						styles={reactSelectStyles}
					/>
				</div>
				<DateCalendar sendData={handleResultData} />
			</Row>
		</>
	)
}

export default FilterConsult
