import * as React from 'react'
import { SidebarProps } from './sidebar.types'
import * as S from './sidebar.styles'

import CloseIcon from '@mui/icons-material/Close'
import { formatCnpjCpf } from '../../../../shared/utils/form'
import useDocValidation from '../../../../hooks/useDocValidation'
import FirstAcessForm from './first-acess-form/first-acess-form'
import LoginForm from './login-form/login-form'

export default function Sidebar({
	isOpen,
	toggleSidebar,
	sideBarType,
	handleChangeTypeSidebar,
	loginStep
}: SidebarProps) {
	const [textDoc, setTextDoc] = React.useState('')

	const handleCloseModal = React.useCallback(() => {
		toggleSidebar()
		setTextDoc('')
	}, [toggleSidebar])

	const validDocument = formatCnpjCpf(textDoc)
	const isValidDoc = useDocValidation(textDoc)

	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent): void => {
			if (event.key === 'Escape') {
				handleCloseModal()
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [handleCloseModal])

	return (
		<S.Sidebar
			className="test-component"
			anchor={'right'}
			open={isOpen}
			onClose={handleCloseModal}
			style={{ zIndex: '99' }}>
			<div className="close-icon-wrapper">
				<CloseIcon onClick={handleCloseModal} />
			</div>
			{sideBarType === 'firstAcess' && (
				<FirstAcessForm
					unformatedTextDoc={textDoc}
					isValidDoc={isValidDoc}
					setTextDoc={setTextDoc}
					validDocument={validDocument}
				/>
			)}
			{sideBarType === 'login' && (
				<LoginForm
					unformatedTextDoc={textDoc}
					isValidDoc={isValidDoc}
					textDoc={textDoc}
					setTextDoc={setTextDoc}
					validDocument={validDocument}
					handleChangeTypeSidebar={handleChangeTypeSidebar}
					currentStep={loginStep}
					handleCloseSidebar={() => toggleSidebar()}
				/>
			)}
		</S.Sidebar>
	)
}
