import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import InputField from '../InputField'
import { IProps } from './types'

function InputCode({ returnValue, valueIsValid, className }: IProps) {
	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const value = e.target.value.replace(/[^0-9]+/g, '')

		setTouched(true)
		returnValue(value)
		setInputValue(value)
	}

	function validateInput() {
		const isValid = inputValue.length === 6
		valueIsValid(isValid)
		setHasError(touched && !isValid)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(validateInput, [value, inputValue, touched])

	return (
		<InputField
			onChange={handleChange}
			id="codeNumber"
			label={'Código'}
			maxLength={6}
			hasError={hasError}
			className={className}
			// Used here to input reflect exact the value we are changing
			value={inputValue}
			errorMessage={'O código deve conter 6 números'}
		/>
	)
}

export default InputCode
