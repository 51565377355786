import React, { ChangeEvent } from 'react'

import { ICancelPushModal } from './cancel-push-modal.types'
import { CancelPushModalStyles } from './cancel-push-modal.styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TextArea from '../../../../../components/text-area/text-area.component'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'

const CancelPushModal = ({
	isOpen,
	handleToggleModal,
	handleClick,
	handleTextArea,
	disabledButton,
	messagemAplied,
	usersImpacted,
	isLoading
}: ICancelPushModal) => {
	const [textAreaValue, setTextAreaValue] = React.useState('')

	const handleChangeTextArea = (
		event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		handleTextArea(event.target.value)
		setTextAreaValue(event.target.value)
	}

	return (
		<LayoutModal
			isOpen={isOpen}
			disabledButton={disabledButton}
			handleToggleModal={handleToggleModal}
			handleClick={handleClick}
			height={'568px'}
			width={'648px'}
			modalTitle={'Justificativa de Cancelamento de Push'}
			buttonText={'Confirmar alteração'}
			isLoading={isLoading}
			>
			<CancelPushModalStyles>
				<h2 className="subtitle-modal">
					Digite sua justificativa abaixo:
				</h2>
				<TextArea
					text={textAreaValue}
					handleChange={handleChangeTextArea}
					label="Mensagem"
					limitCaracters={120}
				/>
				<div className="info-modal-cancel">
					<InfoOutlinedIcon />
					<p>
						É possível realizar a edição do texto na área demarcada
						e, posteriormente, confirmar as alterações realizadas.
					</p>
				</div>
			</CancelPushModalStyles>
		</LayoutModal>
	)
}

export default CancelPushModal
