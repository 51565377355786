import React from 'react'
import * as S from './password-created.styles'

import VerificatedIcon from './assets/verificated-icon.svg'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import { useHistory } from 'react-router-dom'
import { UnauthenticatedRoutesEnum } from '../../../../modules/Routes/unauthenticatedRoutesEnum'
import { WrapperButton } from '../../reset-password.styles'

function PasswordCreatedComponent() {
	const history = useHistory()
	return (
		<>
			<S.PasswordCreatedView>
				<img src={VerificatedIcon} alt="VerificatedIcon" />

				<S.Title>Senha alterada com sucesso</S.Title>

				<S.Description>
					Sua senha é pessoal e intransferível. Não compartilhe com
					outras pessoas.
				</S.Description>
			</S.PasswordCreatedView>
			<WrapperButton>
				<div className="wrapperButtonDefault">
					<ButtonDefault
						buttonText="Acessar conta"
						type={TypeButtonEnum.PRIMARY}
						onClick={() =>
							history.replace(UnauthenticatedRoutesEnum.LOGIN)
						}
						idElement="access-acount"
						width="234px"
					/>
				</div>
			</WrapperButton>
		</>
	)
}

export default PasswordCreatedComponent
