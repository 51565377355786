export enum TypeButtonEnum {
	PRIMARY = 'primary',
	DISABLED = 'disabled',
	OUTLINED = 'outlined',
	GRADIENT = 'gradient'
}

export interface ButtonTypes {
	buttonText: string
	onClick: () => void
	type?: TypeButtonEnum
	idElement: string
	height?: string
	width?: string
	fontSize?: string
	border?: string
	icon?: React.JSX.Element
	typeButton?: 'button' | 'reset' | 'submit'
}
