import styled from 'styled-components'

export const ContainerWrapper = styled.div`
	text-align: left;
`
export const Container = styled.div`
	display: flex;
	justify-content: center;
`
export const Actions = styled.div`
	display: flex;
	flex: 1;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
`
export const Title = styled.h1`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_LG}px;
	font-weight: ${(props) => props.theme.font.weight.medium};
	width: 100%;
	padding-bottom: ${(props) => props.theme.spacingOptions.LG}px;
`
export const ContainerDataSelector = styled.div`
	width: 35%;
`
export const ContainerSearchInput = styled.div`
	width: 55%;
`
export const ButtonActions = styled.div`
	display: flex;
	flex: 1;
	direction: rtl;
	align-items: center;
	padding-left: ${(props) => props.theme.spacingOptions.XSM}px;
	gap: ${(props) => props.theme.spacingOptions.MD}px;
`
