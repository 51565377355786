import React, {
	ChangeEvent,
	Fragment,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'
import { v4 as uuID } from 'uuid'

import { ChipCouponStatus } from '../../../shared/components'

import {
	IExtractProgramPoints,
	PointsExpirationStatusLabelEnum
} from '../../../shared/interfaces/pointsProgram'

import { IPointsMovementListProps } from './types'

import * as S from './styles'

export default function PointsMovementList(props: IPointsMovementListProps) {
	const { extractOfPointsData } = props
	const [checkedFilterOption, setCheckedFilterOption] = useState('')
	const [filteredPointsExtracts, setfilteredPointsExtracts] = useState<
		IExtractProgramPoints[]
	>([])

	const inputFilterOptionReleasedRef = useRef<HTMLInputElement>(null)
	const inputFilterOptionToExpireRef = useRef<HTMLInputElement>(null)
	const inputFilterOption30DaysRef = useRef<HTMLInputElement>(null)

	const filterOptions = useMemo(
		() => [
			{
				id: 'Liberados',
				name: 'Liberados',
				value: 'Liberado',
				inputRef: inputFilterOptionReleasedRef
			},
			{
				id: 'A expirar',
				name: 'A expirar',
				value: 'A expirar',
				inputRef: inputFilterOptionToExpireRef
			},
			{
				id: '30 dias',
				name: '30 dias',
				value: '30 dias',
				inputRef: inputFilterOption30DaysRef
			}
		],
		[]
	)

	function formartDate(date: Date) {
		return String(
			format(new Date(date), "iiii',' dd/MM/yyyy '-' p", { locale: ptBR })
		)
	}

	function checkedFilterOPtionChange({
		target
	}: ChangeEvent<HTMLInputElement>) {
		if (checkedFilterOption) {
			filterOptions.forEach((item) => {
				if (item.inputRef?.current?.checked && item.id !== target.id) {
					item.inputRef.current.checked = false
				}
			})
			setCheckedFilterOption('')
		}
		if (target.checked) {
			setCheckedFilterOption(target.value)
		}
	}

	function filteringPointsExtract() {
		if (extractOfPointsData && checkedFilterOption) {
			const filteredData = extractOfPointsData.filter((item) => {
				if (checkedFilterOption === '30 dias') {
					const thirtyDaysAgo = new Date(
						Date.now() - 30 * 24 * 60 * 60 * 1000
					).getTime()

					return (
						new Date(item.customerSalesOrderDate).getTime() >=
						thirtyDaysAgo
					)
				}
				return (
					item.label?.toLowerCase() ===
					checkedFilterOption.toLowerCase()
				)
			})
			setfilteredPointsExtracts(filteredData)
			return
		}
		setfilteredPointsExtracts(extractOfPointsData)
	}

	useEffect(filteringPointsExtract, [
		filterOptions,
		extractOfPointsData,
		checkedFilterOption
	])

	return (
		<Fragment>
			<S.ListPointsWrapper>
				<S.ListPointsTitle>Movimentação de Pontos</S.ListPointsTitle>
				<S.WrapperFilterButtons>
					{filterOptions.map((item) => (
						<S.FilterInputLabel
							key={item.id}
							htmlFor={item.id}
							className={`filterLabel ${
								checkedFilterOption === item.value
									? 'checked'
									: ''
							}`}>
							<S.FilterInputElement
								id={item.id}
								type="checkbox"
								name={item.name}
								value={item.value}
								ref={item.inputRef}
								onChange={checkedFilterOPtionChange}
							/>
							{item.name}
						</S.FilterInputLabel>
					))}
				</S.WrapperFilterButtons>
				{filteredPointsExtracts.map((item, index) => (
					<S.ListItem key={uuID()}>
						<S.ListItemHeader>
							<div className="content-left">
								<ChipCouponStatus
									status={item.label as string}
								/>
								<label className="label-request">
									{item.AutoIncBackendID
										? `Pedido ${item.AutoIncBackendID}`
										: `Bonificação de Adesão ao Programa de Pontos`}
								</label>
							</div>
							<S.LabelTotalPoints status={item.label as string}>
								{item.label ===
									PointsExpirationStatusLabelEnum.EXPIRED ||
								item.label ===
									PointsExpirationStatusLabelEnum.REVERSAL ||
								item.label ===
									PointsExpirationStatusLabelEnum.USED
									? '-'
									: '+'}
								{item.score} pontos
							</S.LabelTotalPoints>
						</S.ListItemHeader>
						<S.ListFooter>
							<S.DateOfRequest>
								{formartDate(item.customerSalesOrderDate)}
							</S.DateOfRequest>
							{item.label !==
								PointsExpirationStatusLabelEnum.EXPIRED && (
								<S.ListItemLabelStatusRequest
									status={item.label as string}>
									Válido até{' '}
									{formartDate(item.expirationDate as Date)}
								</S.ListItemLabelStatusRequest>
							)}
						</S.ListFooter>
					</S.ListItem>
				))}
			</S.ListPointsWrapper>
		</Fragment>
	)
}
