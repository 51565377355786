import styled from 'styled-components'

export const ProductForRedemptionWrapper = styled.div`
	width: 100%;
	cursor: pointer;
	max-width: 248px;
	background-color: ${(props) => props.theme.palette.white.main};
	border: 1px solid ${(props) => props.theme.palette.lightGray.main};
	padding: 16px 16px 24px 16px;
`

export const ProductImageWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ProductImage = styled.img`
	max-width: 80px;
	margin-bottom: 12px;
`

export const ProductDescription = styled.p`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	text-transform: uppercase;
	margin-bottom: 14px;
`

export const LabelsPointsWrapper = styled.div``

export const labelPointsForRedemption = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${(props) => props.theme.palette.black.main};
	margin-right: 46px;
	text-transform: uppercase;
`

export const labelPoints = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${(props) => props.theme.palette.gray.main};
	margin-bottom: 16px;
	display: inline-block;
	text-transform: uppercase;
`

export const CharacteristicsWrapper = styled.div`
	padding-top: 16px;
	border-top: 1px solid ${(props) => props.theme.palette.lightGray.main};
`

export const Characteristic = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	justify-content: space-between;
`

export const CharacteristicLabel = styled.label`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
`

export const CharacteristicValue = styled.label`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
`
