import cogoToast from 'cogo-toast'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { deletePointsProgramRule } from '../../modules/PointsEarnedRatio/service'
import { cancelingChallengeService } from '../../modules/PointsProgramListOfChallenges/services'
import { AdminRoutesEnum } from '../../modules/Routes/adminRoutesEnum'

import Modal from '../ModalPrimary'

import * as S from './styles'

interface IRuleOrChallengeActionModalProps {
	type: 'RULE' | 'CHALLENGE'
	typeAction: 'EXCLUSION' | 'CANCELLATION'
	screenAction?: 'DETAILS' | 'LISTING'
	ruleOrChallengeID: string
	showModal: boolean
	handleCloseModal: () => void
}

export default function RuleOrChallengeActionModal({
	type,
	showModal,
	typeAction,
	screenAction,
	ruleOrChallengeID,
	handleCloseModal
}: IRuleOrChallengeActionModalProps) {
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()

	const cancelingChallenge = async () => {
		if (screenAction && screenAction === 'DETAILS') {
			await cancelingChallengeService(ruleOrChallengeID)
			history.replace(AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES)
			return
		}
		await cancelingChallengeService(ruleOrChallengeID)
	}

	const confirmAction = async () => {
		try {
			setIsLoading(true)

			type === 'CHALLENGE' && typeAction === 'CANCELLATION'
				? cancelingChallenge()
				: await deletePointsProgramRule(ruleOrChallengeID)

			cogoToast.success(
				type === 'CHALLENGE' && typeAction === 'CANCELLATION'
					? 'Desafio cancelado com sucesso!'
					: 'Regra deletada com sucesso!',
				{
					position: 'bottom-center'
				}
			)
		} finally {
			setIsLoading(false)
			handleCloseModal()
		}
	}

	const modalTitle =
		typeAction === 'CANCELLATION' ? 'Cancelar desafio' : 'Excluir Regra'

	const modalDescription = `Você selecionou por ${
		type === 'CHALLENGE' && typeAction === 'CANCELLATION'
			? 'Cancelar'
			: 'Excluir'
	} ${
		type === 'CHALLENGE' ? 'este Desafio' : 'esta Regra'
	}. Deseja confirmar esta ${
		type === 'CHALLENGE' && typeAction === 'CANCELLATION'
			? 'ação'
			: 'exclusão'
	}?`

	return (
		<Modal
			isActive={showModal}
			title={modalTitle}
			onClose={handleCloseModal}
			size={424}>
			<S.ModalContent>
				<S.Description>{modalDescription}</S.Description>
				<S.ButtonsWrapper>
					<S.ButtonConfirm
						disabled={isLoading}
						onClick={confirmAction}
						className="white-button">
						Confirmar
					</S.ButtonConfirm>
					<S.ButtonToMaintain
						onClick={handleCloseModal}
						className="transparent-button">
						Manter
					</S.ButtonToMaintain>
				</S.ButtonsWrapper>
			</S.ModalContent>
		</Modal>
	)
}
