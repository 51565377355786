import { colorThemeProvider } from './../../theme/themeProvider'
import styled, { css, keyframes } from 'styled-components'

export const animationEntry = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export const InsufficientPointsWarning = styled.div<{
	cartScreen?: boolean
	background?: string
}>`
	width: 100%;

	${({ cartScreen }) =>
		!cartScreen &&
		css`
			max-width: 352px;
		`}

	margin-top: 16px;
	border-radius: 4px;
	border: 1px solid ${(props) => props.theme.palette.primaryWhite.main};

	background-color: ${({ background }) =>
		background ? background : colorThemeProvider.grayColor};

	padding: ${({ cartScreen }) =>
		cartScreen ? '10px' : '10px 108px 10px 10px'};

	display: flex;
	align-items: center;

	animation: ${animationEntry} forwards ease-in-out 0.5s;

	> span {
		color: ${colorThemeProvider.blackColor};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		margin-left: 8px;
		font-weight: ${(props) => props.theme.font.weight.semibold};
		display: inline-block;
		text-transform: uppercase;
	}
`
