import { useQuery, UseQueryResult } from 'react-query'
import {
	IPaymentMethod,
	IResponseGetPixTransactionAllPays
} from '../interfaces/Payments'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance from './axiosInstace'
import { IPaymentConditions } from '../interfaces/customer'

export async function getPaymentMethod(
	salesOrganizationID: string
): Promise<IPaymentMethod[]> {
	const response = await axiosInstance.get(
		`/payment-methods/?salesOrganizationID=${salesOrganizationID}`
	)
	return response.data
}

export type ICheckPixStatusRequest = { transactionId: string }

async function getTransactionStatus({
	transactionId
}: ICheckPixStatusRequest): Promise<IResponseGetPixTransactionAllPays> {
	const response = await axiosInstance.get(
		`/payment-methods/${transactionId}`
	)

	return response.data
}

export function useTransactionStatus({
	transactionId
}: ICheckPixStatusRequest): UseQueryResult<IResponseGetPixTransactionAllPays> {
	const enabled = !!transactionId

	return useQuery(
		[QUERY_KEYS.GET_STATUS_PIX, { transactionId }],
		async ({ queryKey }) => {
			const [, params] = queryKey
			const { transactionId } = params as ICheckPixStatusRequest

			return getTransactionStatus({ transactionId })
		},
		{
			enabled,
			// staleTime: 1,
			refetchInterval: 5000
		}
	)
}

export async function getPaymentMethodsAtDelivery(
	salesOrganizationID: string,
	customerID: string
): Promise<Array<IPaymentConditions>> {
	const response = await axiosInstance.get(
		`/payment-methods/formofpayment/?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`,
	)
	return response.data
}
