import React from 'react'

import XIcon from '../../assets/images/x.svg'

function CloseButton(props: any) {
	const { onClick, testID } = props
	return (
		<button onClick={onClick} className="close-button" data-testid={testID}>
			<img className="close-icon" src={XIcon} alt="Botão Fechar" />
		</button>
	)
}

export default CloseButton
