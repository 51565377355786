export type IHistoryPush = {
	ID: string
	createdAt: string
	createdBy: string
	filters: IFilters
	modifiedAt: string
	modifiedBy: string
	title: string
	message: string
	dtScheduling: string
	qtdCustomer: string
	type: NotificationType
	status: StatusType
	dtSent: string
	actionType: ActionType
	productToOpen: string
	categoryToOpen: string
	typePTBR?: NotificationTypePTBR
	edit?: any
	cancel?: any
	notificationId: number
}

export type IFilters = {
	ufs: Array<any>
	cities: Array<any>
	channels: Array<any>
	codePdv: string
	codeResales: Array<any>
	customers: Array<any>
	diretor: Array<any>
	GRC: Array<any>
}

export type Column = {
	Header: string
	accessor: string
}

export enum ActionType {
	OPEN_APP = 'OPEN_APP',
	OPEN_SPECIFIC_PRODUCT = 'OPEN_SPECIFIC_PRODUCT',
	OPEN_PRODUCT_CATEGORY = 'OPEN_PRODUCT_CATEGORY',
	OPEN_COMBOS = 'OPEN_COMBOS',
	OPEN_CHALLENGES = 'OPEN_CHALLENGES'
}

export enum NotificationType {
	IMMEDIATE = 'IMMEDIATE',
	SCHEDULED = 'SCHEDULED',
	TRANSACTIONAL = 'TRANSACTIONAL'
}

export enum NotificationTypePTBR {
	IMMEDIATE = 'Imediato',
	SCHEDULED = 'Agendado',
	TRANSACTIONAL = 'Transacional'
}

export enum StatusType {
	CREATED = 'CREATED',
	SENT = 'SENT',
	SCHEDULED = 'SCHEDULED',
	CANCELLED = 'CANCELLED'
}
