import styled from 'styled-components'

export const StepContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 16px 0;
`

export const StepWrapper = styled.div`
	border-radius: 25px;
	border-width: 3px;
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(223 224 228 / 20%);
`

export const StepTitle = styled.h1`
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	color: ${(props) => props.theme.palette.gray.main};
	line-height: ${(props) => props.theme.font.size.TEXT_LG.line_height}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	margin-left: 8px;
`
export const StepLabel = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};

	color: ${(props) => props.theme.palette.gray.main};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
`
