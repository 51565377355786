import styled from 'styled-components'

export const PlaceholderWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	margin-top: 48px;
	max-width: 1088px;
	border-radius: 4px;
	padding: 160px 0;
	background-color: ${(props) => props.theme.palette.white.main};
`

export const PlaceholderContent = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

export const PlaceholderImage = styled.img``

export const PlaceholderContentTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	margin-top: 24px;
	margin-bottom: 8px;
`

export const PlaceholderContentDescription = styled.p`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	max-width: 308px;
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
	text-align: center;
	margin-bottom: 40px;
`

export const PlaceholderButton = styled.button`
	cursor: pointer;
`
