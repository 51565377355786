import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IChallengesTheUserIsParticipatingInActionCreators
} from '../../interfaces/action'
import { IChallengesTheUserIsParticipatingIn } from '../../interfaces/pointsProgramChallenge'

/**
 * Action types & creators
 */
export const {
	Types,
	Creators
}: IActionsFromCreators<IChallengesTheUserIsParticipatingInActionCreators> =
	createActions({
		setChallenges: ['challengesTheUserIsParticipatingIn'],
		addNewChallenge: ['challenge'],
		clearChallenges: []
	})

/**
 * Handlers
 */
export const INITIAL_STATE: IChallengesTheUserIsParticipatingIn[] = []

const setChallenges = (state = INITIAL_STATE, action: any) =>
	action.challengesTheUserIsParticipatingIn

const addNewChallenge = (
	state = INITIAL_STATE,
	action: any
): IChallengesTheUserIsParticipatingIn[] => {
	const { challenge } = action
	const currentChallenges = [...state]

	currentChallenges.push(challenge)

	return currentChallenges
}

const clearChallenges = (state = INITIAL_STATE, action: any) => INITIAL_STATE

/**
 * Reducer
 */
export default createReducer<
	IChallengesTheUserIsParticipatingIn[],
	Action<IChallengesTheUserIsParticipatingInActionCreators>
>(INITIAL_STATE, {
	[Types.SET_CHALLENGES]: setChallenges,
	[Types.ADD_NEW_CHALLENGE]: addNewChallenge,
	[Types.CLEAR_CHALLENGES]: clearChallenges
})
