import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import { DEFAULT_RESALE } from '../../../modules/ResaleList/data'
import { IResaleItem } from '../../../modules/ResaleList/types'
import {
	IActionsFromCreators,
	IResaleActionCreators
} from '../../interfaces/action'

export const {
	Types,
	Creators
}: IActionsFromCreators<IResaleActionCreators> = createActions({
	setResale: ['resale'],
	resetResale: []
})

const INITIAL_STATE: IResaleItem = DEFAULT_RESALE

const set = (state = INITIAL_STATE, action: any) => action.resale

const reset = (state = INITIAL_STATE, action: any) => INITIAL_STATE

export default createReducer<IResaleItem, Action<IResaleActionCreators>>(
	INITIAL_STATE,
	{
		[Types.SET_RESALE]: set,
		[Types.RESET_RESALE]: reset
	}
)
