import React from 'react'
import * as S from './details-coupons.styles'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import { IDetailsCouponProps } from './details-coupons.types'
import LabelDetails from './components/label-details/label-details.component'
import { formatDate } from '../../history-coupon.types'
import { useForm } from 'react-hook-form'
import ControlSelect from '../../../../../components/multiple-select/multiple-select'
import TagInfo from '../../../../../components/tag-info/tag-info.component'
import { toMoney } from '../../../../../shared/utils/money'
import { CouponType } from '../../../create-coupon/services/types/coupon.types'
import { UNLIMITED_VALUE_COUPON } from '../../../create-coupon/constants/unlimited-value-coupon'
function DetailsCoupon({
	coupon,
	handleClose,
	isOpen,
	handleEditCoupon
}: IDetailsCouponProps): JSX.Element {
	const { control } = useForm()

	const { discount_items: itens } = coupon

	const categories = itens && itens.map((item) => item.category)
	const brands = itens && itens.map((item) => item.brand)
	const packages = itens && itens.map((item) => item.package)
	const products = itens && itens.map((item) => item.description)
	const prepareOptions = (array: Array<string>) => {
		return array.map((item) => ({
			label: item,
			value: item
		}))
	}

	const parsedFilter = (s: string) => {
		if (!coupon.filters) return []
		return JSON.parse(coupon.filters)[s] as string[]
	}

	return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleClose}
			modalTitle={coupon.name}
			buttonText="Editar"
			handleClick={handleEditCoupon}
			typeLayout="details">
			<S.ContainerContentDetails>
				<S.FragDetails>
					<S.InfoParagraph>Informações</S.InfoParagraph>
					<S.FragLabelsDetails>
						{coupon && (
							<S.SectionInfo>
								<LabelDetails
									name="STATUS"
									value={String(
										coupon.status ? 'Ativo' : 'Inativo'
									)}
								/>
								<LabelDetails
									name="CÓDIGO"
									value={coupon.code}
								/>
								<LabelDetails name="NOME" value={coupon.name} />
								<LabelDetails
									name="EXIBIR NO APP"
									value={coupon.showIn === 'APP' ? 'Sim' : 'Não'}
								/>
								<LabelDetails
									name="DATA DE INÍCIO"
									value={
										coupon.dateStart
											? formatDate(coupon.dateStart)
											: ''
									}
								/>
									<LabelDetails
									name="QUANTIDADE DE CUPONS"
									value={
										coupon.quantityAvailable ===
										UNLIMITED_VALUE_COUPON
											? 'Ilimitado'
											: String(coupon.quantityAvailable)
									}
								/>
									<LabelDetails
									name="DESCONTO"
									value={
										coupon.discountType === 'money'
											? `${toMoney(coupon.discountValue)}`
											: `${coupon.discountValue}%`
									}
								/>
								<LabelDetails
									name="DATA DE FIM"
									value={
										coupon.dateEnd
											? formatDate(coupon.dateEnd)
											: ''
									}
								/>
							</S.SectionInfo>
						)}
					</S.FragLabelsDetails>
				</S.FragDetails>
				<S.FragDetails>
					<S.InfoParagraph>Regras</S.InfoParagraph>
					<S.FragLabelsDetails>
						{coupon && (
							<S.SectionInfo>
								<LabelDetails
									name="VALOR MÍNIMO DE COMPRA"
									value={toMoney(coupon.minOrderValue)}
								/>
								<LabelDetails
									name="CUMULATIVO"
									value={coupon.cumulative ? 'Sim' : 'Não'}
								/>
								<LabelDetails
									name="TIPO"
									value={
										coupon.couponType === CouponType.CART
											? 'Carrinho'
											: 'Produto'
									}
								/>
							</S.SectionInfo>
						)}
					</S.FragLabelsDetails>
				</S.FragDetails>
				{coupon.couponType !== CouponType.CART && (
					<S.FragDetails>
						<S.FragDetailsSelect>
							<S.RulesSelectsWrapper>
								{categories && (
									<ControlSelect
										control={control}
										name="category"
										label="Categoria"
										options={prepareOptions([
											...new Set(categories)
										])}
										value={[...new Set(categories)]}
									/>
								)}

								{brands && (
									<ControlSelect
										control={control}
										name="brand"
										label="Marca"
										options={prepareOptions([
											...new Set(brands)
										])}
										value={[...new Set(brands)]}
									/>
								)}
								{packages && (
									<ControlSelect
										control={control}
										name="package"
										label="Embalagem"
										options={prepareOptions([
											...new Set(packages)
										])}
										value={[...new Set(packages)]}
									/>
								)}
							</S.RulesSelectsWrapper>
							{products && (
								<ControlSelect
									control={control}
									name="productList"
									label="Produtos Inseridos"
									options={prepareOptions(products)}
									value={products}
								/>
							)}
						</S.FragDetailsSelect>
					</S.FragDetails>
				)}
				<S.FragDetails>
					<S.InfoParagraph>Parâmetros</S.InfoParagraph>
					<S.FiltersByHierarquySection>
						<ControlSelect
							control={control}
							name="director"
							label="Diretor"
							options={prepareOptions([
								...new Set(parsedFilter('director'))
							])}
							value={parsedFilter('director')}
						/>
						<ControlSelect
							control={control}
							name="grc"
							label="GRC"
							options={prepareOptions([
								...new Set(parsedFilter('grc'))
							])}
							value={parsedFilter('grc')}
						/>
						<ControlSelect
							control={control}
							name="state"
							label="UF"
							options={prepareOptions([
								...new Set(parsedFilter('state'))
							])}
							value={parsedFilter('state')}
						/>
						<ControlSelect
							control={control}
							name="city"
							label="Cidade"
							options={prepareOptions([
								...new Set(parsedFilter('city'))
							])}
							value={parsedFilter('city')}
						/>
						<ControlSelect
							control={control}
							name="channel"
							label="Canal"
							options={prepareOptions([
								...new Set(parsedFilter('channel'))
							])}
							value={parsedFilter('channel')}
						/>
						<ControlSelect
							control={control}
							name="cd"
							label="CD"
							options={prepareOptions([
								...new Set(parsedFilter('cd'))
							])}
							value={parsedFilter('cd')}
						/>
					</S.FiltersByHierarquySection>
				</S.FragDetails>
			</S.ContainerContentDetails>
			<S.ContainerTags>
				<TagInfo
					title="Clientes Impactados"
					value={coupon.amountOfCustomers}
				/>

				<TagInfo
					title="Cupons Utilizados"
					value={String(coupon.cupomUsedCount)}
				/>
			</S.ContainerTags>
		</LayoutModal>
	)
}

export default DetailsCoupon
