import styled from 'styled-components'
import { Box } from '@material-ui/core'

export const PreviewPhoneWrapper = styled(Box)`
	width: 400px;
	max-height: 598px;
	background: ${(props) => props.theme.palette.extras.stroke.main};
	border-radius: 50px 50px 0px 0px;

	display: flex;
	align-items: flex-end;
	justify-content: center;
`

export const PreviewPhoneInsideWrapper = styled(Box)`
	width: 380px;
	height: 585px;

	background: ${(props) => props.theme.palette.black.main};
	border-radius: 36px 36px 0px 0px;
	display: flex;
	align-items: center;
	flex-direction: column;

	.header-phone {
		width: 148px;
		height: 21px;
		background: ${(props) => props.theme.palette.extras.stroke.main};;
		border-radius: 0px 0px 14.4026px 14.4026px;

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		margin-bottom: 80px;

		.rectangle {
			background: ${(props) => props.theme.palette.black.main};
			border-radius: 100px;
			width: 44px;
			height: 7px;
		}

		.circle {
			width: 7px;
			height: 7px;

			background: ${(props) => props.theme.palette.black.main};
			border-radius: 100%;
		}
	}
`

export const PreviewPhoneInsideDefault = styled(Box)`
	width: 380px;
	height: 585px;

	background: ${(props) => props.theme.palette.lightGray.main};
	border-radius: 36px 36px 0px 0px;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-top: 98px;
`
