import styled from 'styled-components'
import Box from '@mui/material/Box'

export const WrapperModal = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 788px;
	height: 650px;

	@media only screen and (max-width: 1366px) {
		height: 80%;
	}

	overflow-y: scroll;

	background-color: ${(props) => props.theme.palette.background.default};
	padding: ${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.XLG}px;
	border-radius: 7px;

	.modal-title {
		font-weight: ${(props) => props.theme.font.weight.regular};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		line-height: ${(props) =>
			props.theme.font.size.DISPLAY_XS.line_height}rem;

		color: ${(props) => props.theme.palette.black.main};

		margin-bottom: ${(props) => props.theme.spacingOptions.XLG}px;
	}

	.close-button {
		position: absolute;
		top: 20px;
		right: 20px;
	}

	.image-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 194px;
		width: 100%;

		.upload-file {
			height: 100%;
			width: 343px;
			background-color: ${(props) =>
				props.theme.palette.background.default};
			border: 1px dashed ${(props) => props.theme.palette.primary.light};
			border-radius: 7px;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			gap: ${(props) => props.theme.spacingOptions.XSM}px;

			svg {
				color: ${(props) => props.theme.palette.primary.main};
				margin-bottom: ${(props) => props.theme.spacingOptions.SM}px;
				font-size: ${(props) =>
					props.theme.font.size.DISPLAY_LG.size}rem;
			}

			.title-upload-image {
				color: ${(props) => props.theme.palette.primary.main};
				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}

			.sub-title-upload-image {
				font-weight: ${(props) => props.theme.font.weight.medium};
				font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
				color: ${(props) => props.theme.palette.primary.main};
			}
		}

		.gradient {
			border-radius: 7px;

			position: absolute;
			height: 194px;
			width: 344px;
			background-color: transparent;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				#000000 100%
			);

			z-index: 1;
		}
		img {
			border-radius: 7px;

			z-index: 0;
			height: 194px;
			width: 344px;
		}

		.image-info-wrapper {
			width: 344px;
			padding: 0px ${(props) => props.theme.spacingOptions.MD}px;

			position: absolute;
			top: 230px;
			z-index: 3;

			display: flex;
			align-items: center;
			justify-content: space-between;

			.image-info {
				display: flex;
				align-items: flex-start;
				justify-content: space-around;
				flex-direction: column;
				gap: ${(props) => props.theme.spacingOptions.XSM}px;

				.image-detail-name {
					font-weight: ${(props) => props.theme.font.weight.regular};
					font-size: ${(props) =>
						props.theme.font.size.TEXT_SM.size}rem;
					color: ${(props) => props.theme.palette.white.main};
				}

				.image-detail-size {
					font-weight: ${(props) => props.theme.font.weight.regular};
					font-size: ${(props) =>
						props.theme.font.size.TEXT_XSM.size}rem;
					color: ${(props) => props.theme.palette.white.main};
				}
			}

			.delete-image {
				font-size: ${(props) =>
					props.theme.font.size.DISPLAY_XS.size}rem;
			}
			.delete-image:hover {
				cursor: pointer;
			}
		}
	}

	.modal-product-info {
		width: 100%;
		margin-top: ${(props) => props.theme.spacingOptions.LG}px;

		.modal-product-info-title {
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.gray.main};
			padding-bottom: ${(props) => props.theme.spacingOptions.SM}px;
			border-bottom: 0.5px solid
				${(props) => props.theme.palette.primaryWhite.main};
		}

		.label-wrapper {
			@media only screen and (max-width: 700px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			}

			display: grid;
			justify-content: center;
			grid-template-areas:
				'comboName comboName comboName comboName comboName comboName'
				'codeCombo codeCombo codeCombo sap sap sap'
				'dateBegin dateEnd dateEnd cd cd cd';
			/* grid-template-rows: 54px 3fr; */
			grid-template-columns: fit-content(repeat(2, 1fr));
			grid-auto-rows: fit-content(54px);

			gap: ${(props) => props.theme.spacingOptions.SM}px;
			margin: ${(props) => props.theme.spacingOptions.SM}px 0px;

			.defaultStyleInput {
				.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
					font-weight: ${(props) => props.theme.font.weight.regular};
					font-size: ${(props) =>
						props.theme.font.size.TEXT_SM.size}rem;
					-webkit-text-fill-color: ${(props) =>
						props.theme.palette.lightGray.main};
				}

				.css-kyi295-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
					font-weight: ${(props) => props.theme.font.weight.bold};
					color: ${(props) => props.theme.palette.black.main};
				}
			}

			.comboName {
				grid-area: comboName;
				height: 54px;
				width: 100%;
			}
			.codeCombo {
				grid-area: codeCombo;
				width: 340px;
				@media only screen and (max-width: 700px) {
					width: 100%;
				}
				height: 54px;

				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
			.sap {
				grid-area: sap;
				width: 340px;
				@media only screen and (max-width: 700px) {
					width: 100%;
				}
				height: 54px;

				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
			.dateBegin {
				grid-area: dateBegin;
				width: 165px;
				@media only screen and (max-width: 700px) {
					width: 100%;
				}
				height: 54px;

				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
			.dateEnd {
				grid-area: dateEnd;
				width: 162px;
				@media only screen and (max-width: 700px) {
					width: 100%;
				}
				height: 54px;

				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
			.cd {
				grid-area: cd;
				width: 339px;
				@media only screen and (max-width: 700px) {
					width: 100%;
				}
				height: 54px;

				font-weight: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			}
		}
	}

	.button-wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`
