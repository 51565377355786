import styled from 'styled-components'
import Logo from '../../../../../assets/images/logo-bp-placeholder.svg'
import Xsvg from '../../../../../assets/images/x.svg'
import { colorThemeProvider } from '../../../../../theme/themeProvider'
import { transition } from '../../../../styles/functions'
import { colors } from '../../../../styles/theme'

export const Container = styled.div`
	position: relative;
	padding: 72px 32px 48px;
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const ButtonClose = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	padding: 24px;
	cursor: pointer;
	background: none;
	border: none;
`

export const XIcon = styled.img.attrs(() => ({
	src: Xsvg,
	alt: 'Botão Fechar'
}))``

export const LogoBP = styled.img.attrs(() => ({
	src: Logo,
	alt: 'Logo vermelha do Bom Parceiro',
	width: 140,
	height: 140
}))``

export const Title = styled.h1`
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	color: ${colorThemeProvider.grayColor};
	margin-top: 32px;
	text-align: center;
`

export const Message = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	color: ${colors.sixthGray};
	margin: 16px 32px 40px;
	text-align: center;
	line-height: ${(props) => props.theme.font.size.TEXT_LG.line_height}rem;
`

export const Button = styled.button<{ loading?: boolean }>`
	align-self: center;
	border: none;
	background: ${(props) => props.theme.palette.success.main};
	color: ${(props) => props.theme.palette.white.main};
	padding: 12px 32px;
	border-radius: 4px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
	cursor: pointer;
	${transition()}

	&:hover {
		opacity: 0.7;
	}
`
