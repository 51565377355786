import { pick } from 'ramda'
import React from 'react'
import { CSVLink } from 'react-csv'
import Select from 'react-select'

import { Table } from '../../shared/components'
import Loading from '../../components/Loading'
import { formatCSVData } from '../../shared/utils/string'
import { columns, headersLabels } from './data'
import { IViewProps } from './types'
import InputFilter from '../../components/InputFilter'

import ResalePlaceholderIcon from '../../assets/images/placeholder-resale.svg'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'

import * as S from './styles'

function ResaleProducts(props: IViewProps): JSX.Element {
	const {
		setSelectedResale,
		uploadPayload,
		isLoading,
		tableData,
		resaleList,
		selectedResale,
		handleFilterOnChange
	} = props

	const orderProps = pick([
		'SalesOrganizationID',
		'PriceTable',
		'ChannelGroup',
		'MaterialNumber',
		'Type',
		'MinQuantity',
		'MinValue',
		'MaxValue',
		'InitialTerm'
	])

	const sortedData = tableData.map(orderProps)
	const selectedName = selectedResale?.label?.split('-')[0] || ''

	return (
		<div className="resale-products-page">
			<DefaultLayoutAdmin>
				<div className="resale-params-table-wrapper ">
					<div className="  title-wrapper location">
						<div className=" text-container">
							<p className="resale-title">Preços por Unidade</p>
						</div>
						<S.SelectResaleContainer>
							<Select
								isLoading={false}
								isClearable={true}
								placeholder="Formato"
								options={resaleList || []}
								value={selectedResale}
								onChange={(opt: any) => setSelectedResale(opt)}
								classNamePrefix="react-select"
								className="react-select"
								id="unit-price-select"
							/>
						</S.SelectResaleContainer>
					</div>

					<div className="">
						<div className=" title-wrapper">
							<div className="text-container">
								<p className="resale-title">{`Revenda ${selectedName}`}</p>
								<p className="resale-code">{`Código #${selectedResale?.value}`}</p>
							</div>
						</div>
						{isLoading ? null : (
							<div className="red-button-container">
								<label
									className="white-button upload-button"
									htmlFor="upload-input">
									<input
										id="upload-input"
										onChange={uploadPayload}
										type="file"
										accept=".csv"
									/>
									SUBIR CARGA
								</label>
								<CSVLink
									className="red-button csv-link"
									data={formatCSVData(
										sortedData,
										headersLabels
									)}
									filename={`PRECOS_${selectedResale?.value}.csv`}>
									BAIXAR LISTA
								</CSVLink>
							</div>
						)}
					</div>

					<div className="">
						<div className="search-wrapper">
							<InputFilter onChange={handleFilterOnChange} />
						</div>
					</div>

					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<div className="">
							{tableData.length ? (
								<Table
									columns={columns as any}
									data={tableData}
								/>
							) : (
								<div className="placeholder-resale-container">
									<>
										<img
											className="placeholder-resale"
											src={ResalePlaceholderIcon}
											alt="Placeholder Revenda"
										/>
										<p className="placeholder-resale-text">
											Não foram encontrados produtos para
											essa revenda
										</p>
									</>
								</div>
							)}
						</div>
					)}
				</div>
			</DefaultLayoutAdmin>
		</div>
	)
}

export default ResaleProducts
