type formatDiscountCategoriesTypes = {
	categoires: string[]
	couponType: 'item' | 'cart' | string
}
export function formatDiscountCategories({
	categoires,
	couponType
}: formatDiscountCategoriesTypes): string {
	if (couponType === 'cart') return ''

	const formatedCategories =
		categoires.length > 1 ? 'Múltiplas' : String(categoires[0])

	return formatedCategories
}
