import { z } from 'zod'

export function verifyBateBeginEnd(
	dateStart: Date | undefined,
	dateEnd: Date | undefined,
	ctx: z.RefinementCtx
) {
	if (
		dateStart?.toISOString().slice(0, 17) ===
		dateEnd?.toISOString().slice(0, 17)
	) {
		ctx.addIssue({
			code: 'custom',
			path: ['dateStart'],
			message: 'As datas de inicio e fim devem ser diferentes'
		})
		ctx.addIssue({
			code: 'custom',
			path: ['dateEnd'],
			message: 'As datas de inicio e fim devem ser diferentes'
		})
	} else if (
		dateStart &&
		dateEnd &&
		dateStart.getTime() > dateEnd.getTime()
	) {
		// Hora de início posterior à hora de término
		ctx.addIssue({
			code: 'custom',
			path: ['dateStart'],
			message: 'A data de início deve ser anterior à data de término'
		})
		ctx.addIssue({
			code: 'custom',
			path: ['dateEnd'],
			message: 'A data de término deve ser posterior à data de início'
		})
	}
}
