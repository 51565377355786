import styled from 'styled-components'

export const ForgotOrCreateNewCount = styled.button`

	font-weight: ${(props) => props.theme.font.weight.bold};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	line-height:  ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;

	text-align: center;

	color: ${(props) => props.theme.palette.primary.main};
	background-color:${(props) => props.theme.palette.background.paper};
	border: none;
	&:hover {
		cursor: pointer;
	}
`
