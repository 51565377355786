import { INewClient, IOption } from '../../modules/NewClientForm/types'
import {
	IFormattedPaymentCondition,
	IPaymentConditions,
	IPaymentTerms
} from '../interfaces/customer'
import { isObjValidAndNotEmpty, pickKeys } from './object'
import { NON_DIGIT } from './validators'

export const formatPaymentCondition = (
	condition: IPaymentConditions
): Array<IFormattedPaymentCondition> => {
	const { PaymentMethod, PaymentMethodDescription, PaymentTerms } = condition

	const terms = validatePaymentTerms(PaymentTerms)

	const conditions = terms.map((term) => ({
		...term,
		PaymentMethod,
		PaymentMethodDescription,
		TaxRate: term?.TaxRate
	}))

	return conditions
}

export const validatePaymentTerms = (
	terms: IPaymentTerms | IPaymentTerms[]
): Array<IPaymentTerms> => {
	const isObj = isObjValidAndNotEmpty(terms)

	return (isObj ? [terms] : (terms as unknown)) as Array<IPaymentTerms>
}

export function formatNewClient(client: INewClient) {
	const keysToFormat = [
		'CompanyFiscalID',
		'PersonalFiscalID',
		'IdentificationRegistryID',
		'PostalCode',
		'PhoneNumber',
		'CellphoneNumber'
	]

	const { City: c, Region: r } = client

	const isObj = isObjValidAndNotEmpty

	const City = isObj(c) ? (c as IOption).value : c

	const Region = isObj(r) ? (r as IOption).value : r

	const objToFormat = pickKeys(keysToFormat)(client)

	const fmt = ([key, val]: [string, any]) => [key, val.replace(NON_DIGIT, '')]

	const replaceFalsies = ([key, val]: [string, any]) => [key, val || '']

	const formattedMap = Object.entries(objToFormat).map(fmt)

	const formattedClientMap = Object.entries(client).map(replaceFalsies)

	const formattedObject = Object.fromEntries(formattedMap)

	const formattedClient = Object.fromEntries(formattedClientMap)

	const created = { ...formattedClient, ...formattedObject, Region, City }

	return created
}
