import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import suggestedOrdersAdminService from '../services/suggested-orders-admin.service'
import {
	FillFieldsSuggestionTypes,
	SuggestedOrdersAdminTypes,
	SuggestedProducts
} from '../services/types/suggested-orders-admin.types'
import { ISelectedProducts } from '../components/product-manager/types'
import { ScrollToTop } from '../../../utils/scroll-to-top'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

type suggestionIdsType = {
	userId: string
	suggestionId: string
	bannerMidiaId: string
}

export default function useSuggestedOrdersAdmin() {
	const [titleSuggestion, setTitleSuggestion] = useState<string>('')
	const [isSuggestedAvailable, setIsScreenAvailable] =
		useState<boolean>(false)
	const [selectedMidia, setSelectedMidia] = useState<string>('')
	const [mainProducts, setMainProducts] = useState<ISelectedProducts[]>([])
	const [optionalProducts, setOptionalProducts] = useState<
		ISelectedProducts[]
	>([])
	const [suggestionIds, setSuggestionIds] = useState<suggestionIdsType>({
		userId: '',
		suggestionId: '',
		bannerMidiaId: ''
	})
	const [isOpenSucessMessage, setIsOpenSucessMessage] =
		useState<boolean>(false)
	const { customer } = useTypedSelector(['customer'])
	
	const isDisabledSaveSuggestion =
		isSuggestedAvailable &&
		(titleSuggestion.length === 0 ||
			selectedMidia.length === 0 ||
			mainProducts.length === 0)

	// Função para prencher campos da view de sugestões
	const handleFillFieldsSuggestion = useCallback(
		({
			title,
			status,
			bannerUrl,
			mainProducts,
			optionalProducts
		}: FillFieldsSuggestionTypes) => {
			setTitleSuggestion(title)
			setIsScreenAvailable(status)
			setSelectedMidia(bannerUrl)
			setMainProducts(mainProducts)
			setOptionalProducts(optionalProducts)
		},
		[]
	)

	const fetchSuggestedOrderData = async () => {
		const data = await suggestedOrdersAdminService.get()
		return data
	}
	const {
		isLoading: isLoadingSuggestion,
		refetch: refecthLast,
		isRefetching: isRefetchingLast
	} = useQuery({
		queryKey: ['suggestedOrder'],
		queryFn: fetchSuggestedOrderData,
		refetchOnWindowFocus: false,
		onSuccess: (data: SuggestedOrdersAdminTypes) => {
			handleFillFieldsSuggestion({
				title: data.title,
				status: data.status,
				bannerUrl: data.banner.url,
				mainProducts: data.mainProducts,
				optionalProducts: data.optionalProducts
			})
			setSuggestionIds({
				userId: data.userId,
				suggestionId: data.ID,
				bannerMidiaId: data.banner.id
			})
		}
	})

	const { mutate: handleUpdateStatus, isLoading: loadingUpdateStatus } =
		useMutation({
			mutationFn: async () => {
				const res = await suggestedOrdersAdminService.patchStatus({
					status: isSuggestedAvailable,
					userId: customer.CustomerID,
					id: suggestionIds.suggestionId
				})
				refecthLast()
				return res
			},
			onSuccess() {
				setIsOpenSucessMessage(true)
			}
		})

	const { mutate: handleSaveSuggestion, isLoading: loadingSaveSuggestion } =
		useMutation({
			mutationFn: async () => {
				const res = await suggestedOrdersAdminService.create({
					title: titleSuggestion,
					userId: customer.CustomerID,
					status: isSuggestedAvailable,
					banner: {
						id: suggestionIds.bannerMidiaId,
						url: selectedMidia
					},
					mainProducts: mainProducts as SuggestedProducts[],
					optionalProducts: optionalProducts as SuggestedProducts[]
				})

				refecthLast()
				return res
			},
			onSuccess() {
				ScrollToTop()
				setIsOpenSucessMessage(true)
			}
		})

	// Adicionar as condicionais de carregamento nessa constante
	const isLoadingSuggestedOrderData =
		isLoadingSuggestion ||
		loadingUpdateStatus ||
		loadingSaveSuggestion ||
		isRefetchingLast

	return {
		titleSuggestion,
		setTitleSuggestion,
		isSuggestedAvailable,
		setIsScreenAvailable,
		selectedMidia,
		setSelectedMidia,
		isLoadingSuggestedOrderData,
		mainProducts,
		setMainProducts,
		optionalProducts,
		setOptionalProducts,
		handleUpdateStatus,
		isOpenSucessMessage,
		setIsOpenSucessMessage,
		isDisabledSaveSuggestion,
		handleSaveSuggestion
	}
}
