import React from 'react'
import { Typography } from '@material-ui/core'
import { IConfirmPushModal } from './confirm-push-modal.types'
import { ConfirmPushModalStyles } from './confirm-push-modal.styles'
import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'

const ConfirmPushModal = ({
	isOpen,
	handleToggleModal,
	pushTitle,
	messagemAplied,
	usersImpacted,
	handleSendPush
}: IConfirmPushModal) => {
	return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			height={'536px'}
			width={'648px'}
			modalTitle={'Confirmar Disparo Push'}
			buttonText={'Confirmar envio'}
			handleClick={handleSendPush}
		>
			<ConfirmPushModalStyles>
				<div className="text-section">
					<Typography variant="h3">TÍTULO APLICADO</Typography>
					<Typography variant="body1" component="p">
						{pushTitle}
					</Typography>
				</div>

				<div className="text-section last-item">
					<Typography variant="h3">MENSAGEM APLICADA</Typography>
					<Typography variant="body1" component="p">
						{messagemAplied}
					</Typography>
				</div>

				<ImpactedClients usersImpacted={usersImpacted} />
			</ConfirmPushModalStyles>
		</LayoutModal>
	)
}

export default ConfirmPushModal
