import React from 'react'
import VideoPlayerButton from '../../../../components/video-player-button/video-player-button.component'

import { MidiaContainerWrapper } from './midia-container.styles'
import FileUploader from '../../../../components/file-uploader/file-uploader.component'
import { DataSelectorList } from '../../../../components/data-selector-list'
import { MidiaContainerProps, DevicesEnum } from './midia-container.types'
import { SelectorOption } from '../../../../components/data-selector/data-selector.types'
import { colorThemeProvider } from '../../../../theme/themeProvider'

function MidiaContainer({
	showDeviceSelect = true,
	deviceSelected,
	destinySelected,
	handleDeviceSelected = (value: SelectorOption) => { },
	showImage = true,
	showVideo = true,
	srcImage,
	handleGetSrcImage = (value: string) => { },
	canDeleteImage = false,
	srcVideo,
	canDeleteVideo = true,
	hasSelectedVideo = false,
	handleGetSrcVideo = (value: string) => { },
	isFormCreateBanner = false,
	isDisabledImage = false
}: MidiaContainerProps) {
	return (
		<MidiaContainerWrapper>
			{showDeviceSelect && (
				<div className="device-wrapper">
					<DataSelectorList
						list={[
							{
								name: 'WEB',
								value: DevicesEnum.WEB
							},
							{
								name: 'APP',
								value: DevicesEnum.APP
							}
						]}
						title="Dispositivo"
						selectedOption={deviceSelected}
						backgroundColor={colorThemeProvider.background.paper}
						handleChange={(item) =>
							handleDeviceSelected(item.target)
						}
						isNative={false}
						dataSelectorListID={'data-device-select'}
					/>
				</div>
			)}
			{showImage && (
				<FileUploader
					srcImage={srcImage}
					handleGetSrc={(url) => handleGetSrcImage(url)}
					canDelete={canDeleteImage}
					acceptExtensios=".png"
					isDisabled={isDisabledImage}
					deviceSelected={deviceSelected}
					destinySelected={destinySelected}
				/>
			)}
			{showVideo && (
				<VideoPlayerButton
					srcVideo={srcVideo}
					handleGetSrc={(url) => handleGetSrcVideo(url)}
					canDeleteVideo={canDeleteVideo}
					hasSelectedVideo={hasSelectedVideo}
				/>
			)}
			{isFormCreateBanner && (
				<p className="warning-video-text">
					* O banner terá ação de abrir o vídeo carregado.
				</p>
			)}
		</MidiaContainerWrapper>
	)
}

export default MidiaContainer
