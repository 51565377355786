import styled from 'styled-components'

export const MidiaContainerWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap:24px;

	.device-wrapper {
		width: 151px;
	}

	.warning-video-text{
		color: #CE332E;
		font-size: 16px;
	}
`
