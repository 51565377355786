import styled from 'styled-components'
import { Box } from '@mui/material'

export const MainWrapper = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px 72px 100px 72px;
	gap: 72px;
	@media screen and (max-width: 800px) {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 20px;
	}

	img {
		max-width: 1240px;
		max-height: 522px;
	}

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	.animated-background {
		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: darkgray;
		background: linear-gradient(
			to right,
			#eeeeee 10%,
			#dddddd 18%,
			#eeeeee 33%
		);
		background-size: 800px 104px;
		height: 100px;
		position: relative;

		width: 683px;
		height: 522px;
		border-radius: 5px;
	}
`

export const RightContainer = styled(Box)`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 24px;

	@media screen and (max-width: 800px) {
		width: 100%;
	}

	.title {
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
		color: ${(props) => props.theme.palette.black.main};
	}

	.benefits-container {
		border: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
		border-radius: 5px;

		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 24px;
		gap: 16px;

		@media screen and (max-width: 800px) {
			width: 100%;
			align-items: flex-start;
		}

		width: 350px;
		height: 288px;

		.benefit-item {
			display: flex;
			align-items: center;
			justify-content: center;

			gap: 20px;

			svg {
				color: ${(props) => props.theme.palette.primary.main};
			}

			p {
				font-weight: ${(props) => props.theme.font.weight.medium};
				font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
				line-height: ${(props) =>
					props.theme.font.size.TEXT_MD.line_height};
				color: ${(props) => props.theme.palette.black.main};

				strong {
					font-weight: ${(props) => props.theme.font.weight.bold};
				}
			}
		}
	}
`
export const UserLogged = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding: 24px;

	width: 381px;
	height: 368px;
	background: ${(props) => props.theme.palette.background.paper};
	border: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
	border-radius: 5px;

	.wrapper-data-user {
		padding: 16px;
		gap: 16px;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 333px;
		height: 76px;

		border: 2px solid ${(props) => props.theme.palette.extras.stroke.main};
		box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.16);
		border-radius: 5px;

		.cicrleIcon {
			width: 44px;
			height: 44px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;

			border: 3px solid
				${(props) => props.theme.palette.extras.stroke.main};

			svg {
				font-size: ${(props) =>
					props.theme.font.size.DISPLAY_XS.size}rem;

				font-size: ${(props) => props.theme.font.weight.bold};

				color: ${(props) => props.theme.palette.extras.stroke.main};
			}
		}

		.user-data {
			h1 {
				font-weight: 400;
				font-size: ${(props) => props.theme.font.weight.regular};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
				line-height: 20px;
				color: ${(props) => props.theme.palette.gray.main};
			}

			p {
				font-size: ${(props) => props.theme.font.weight.bold};
				font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;

				color: ${(props) => props.theme.palette.black.main};
			}
		}
	}
`