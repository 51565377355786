import styled from "styled-components";
import { StatusStyledIcon, TypeIconEnum } from "./StatusIcon.types";

export const WrapperComponent = styled.div<StatusStyledIcon>`

        width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: ${(props) => props.theme.spacingOptions.MD}px;
		flex-direction: column;

        svg{
            font-size: 48px;
           
            color: ${(props) => props.type === TypeIconEnum.SUCCESS && props.theme.palette.success.main };
            color: ${(props) => props.type === TypeIconEnum.WARNING && props.theme.palette.warning.main };
        }

        .wrapper-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(props) => props.theme.palette.icon.sucess};
            border-radius: 50%;
            width: 82px;
            height:  82px;
            padding: ${(props) => props.theme.spacingOptions.SM}px;
            
                background-color: ${(props) =>props.type === TypeIconEnum.SUCCESS && props.theme.palette.icon.sucess};
                background-color: ${(props) =>props.type === TypeIconEnum.WARNING && props.theme.palette.warning.light};
            
        }

        h2 {
            color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
            font-weight: ${(props) => props.theme.font.weight.bold};
	        padding: ${(props) => props.theme.spacingOptions.XLG};
            text-align: center;
		}
    `