import TextField from '@mui/material/TextField'
import styled from 'styled-components'

export const TextFielWrapper = styled(TextField)`
	width: 100%;
	height: 100%;

	& .MuiOutlinedInput-root {
		border-radius: 4px;
		fieldset {
			border: 1px solid
				${(props) => props.theme.palette.extras.stroke.main};
			border-radius: 4px;
		}

		& .MuiInputBase-input {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.regular};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_XSM.line_height}rem;
		}

		&:hover fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}

		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}
	}

	& .MuiInputLabel-root {
		&.Mui-focused {
			color: ${(props) => props.theme.palette.primary.main};
		}
	}
`
export const ButtonSearch = styled.div`
	font-weight: ${(props) => props.theme.font.weight.bold};
	color: ${(props) => props.theme.palette.primary.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;

	&:hover {
		cursor: pointer;
	}
`
