import React, { useEffect, useState } from 'react'
import ReactInputMask from 'react-input-mask'
import { useDebounce } from 'use-debounce/lib'
import SearchIcon from '../../../assets/images/search-red.svg'
import { removeEspecialCharacters } from '../../../shared/utils'
import { checkCNPJ, checkCPF } from '../../../shared/utils/validators'

import styles from './styles.module.scss'
import { IProps } from './types'

export default function SearchInput({
    returnValue,
    valueIsValid,
    inputValue,
    disabled = false,
}: IProps) {
    const cpfMask = '999.999.999-999999'
    const cnpjMask = '99.999.999/9999-99'

    const [mask, setMask] = useState(cpfMask)
    const [value] = useDebounce(inputValue, 1000)

    async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        const valueLength = e.target.value.replace(/\.|-|\//g, '').length

        setMask(valueLength >= 12 ? cnpjMask : cpfMask)
        returnValue(removeEspecialCharacters(e.target.value))
    }

    function validateInput() {
        const validate = mask === cnpjMask ? checkCNPJ : checkCPF

        const isValid = validate(inputValue)
        valueIsValid(isValid)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(validateInput, [value])

    return (
        <div className={styles['input-search-customer']}>
            <div className={styles['search-input']}>
                <ReactInputMask
                    id="search-input-id"
                    required
                    mask={mask}
                    maskChar={''}
                    disabled={disabled}
                    onChange={handleChange}
                    value={inputValue}
                />
                <label htmlFor="search-input-id">Buscar CNPJ ou CPF</label>
            </div>

            <img src={SearchIcon} alt="Ícone de lupa" />
        </div>
    )
}
