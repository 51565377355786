import React from 'react'
import { IActionsButtons } from './actions-buttons.types'
import * as S from './actions-buttons.styles'

function ActionsButtons({ type, handleClick, isDisabled }: IActionsButtons) {
	return (
		<>
			{type === 'increment' ? (
				<S.StyledIncrementButton
					type={'increment'}
					onClick={isDisabled ? () => {} : handleClick}
					isDisabled={isDisabled}
					style={{
						fontSize: '28px'
					}}
				/>
			) : (
				<S.StyledDecrementButton
					type={'decress'}
					onClick={isDisabled ? () => {} : handleClick}
					isDisabled={isDisabled}
					style={{
						fontSize: '28px'
					}}
				/>
			)}
		</>
	)
}

export default ActionsButtons
