import { apiAdmin } from '../../../shared/services/axiosInstace'
import suggestedOrdersAdminMapper from './mappers/suggested-orders-admin.mapper'
import {
	CreateSuggestedOrderTypes,
	PatchStatusTypes,
	SuggestedOrdersAdminTypes,
	GroupedCategories,
	DomainGroupedCategory
} from './types/suggested-orders-admin.types'

import { ISelectedProducts } from '../components/product-manager/types'

class SuggestedOrderAdminService {
	async get(): Promise<SuggestedOrdersAdminTypes> {
		const { data } = await apiAdmin.get(`/product-recommendation/last`)
		return suggestedOrdersAdminMapper.toDomainSuggestedOrders(data)
	}
	async patchStatus({
		userId,
		status,
		id
	}: PatchStatusTypes): Promise<SuggestedOrdersAdminTypes> {
		const { data } = await apiAdmin.patch(`/product-recommendation/${id}`, {
			userId: userId,
			status: status
		})
		return suggestedOrdersAdminMapper.toDomainSuggestedOrders(data)
	}
	async create({
		userId,
		title,
		banner,
		mainProducts,
		status,
		optionalProducts
	}: CreateSuggestedOrderTypes): Promise<any> {
		const form = {
			userId,
			title,
			banner,
			mainProducts,
			status,
			optionalProducts
		}
		const mapperForm = suggestedOrdersAdminMapper.toPersistence(form)
		const { data } = await apiAdmin.post(`/product-recommendation`, {
			...mapperForm
		})
		return data
	}

	async getMaterialsGroupedByCategory(
		selectedProducts: ISelectedProducts[] = []
	): Promise<DomainGroupedCategory> {
		const { data } = await apiAdmin.get<GroupedCategories>(
			`/materials/grouped-by-category`,
			{
				headers: {
					'api-version': '17241'
				}
			}
		)

		console.log({ categoriesResponse: data.categories })

		const groupedCategories = data.categories.map((category) =>
			suggestedOrdersAdminMapper.toDomainGroupedCategories(category)
		)

		const initialSelectedProducts =
			suggestedOrdersAdminMapper.toDomainInitialSelectedProducts(
				data.categories,
				selectedProducts
			)

		return {
			groupedCategories,
			initialSelectedProducts
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SuggestedOrderAdminService()
