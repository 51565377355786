import styled from 'styled-components'
import { mediaQuery } from '../../shared/styles/functions'

export const FooterWrapper = styled.footer`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 32px 80px;
	height: 144px;
	border-top: 1px solid ${(props) => props.theme.palette.primaryWhite.main};

	button {
		background: ${(props) => props.theme.palette.white.main};
		border: none;
		color: ${(props) => props.theme.palette.lightGray.main};
		padding: 10px 20px;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		border-radius: 4px;
	}

	${mediaQuery('sm')(`
        padding: 32px 16px;

    `)}
`
