import React, {
	ChangeEvent,
	createElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import BR from 'date-fns/locale/pt-BR'
import {
	CreationStatus,
	IFilterRulePoint,
	IHeaderRulePoint,
	StatusRulePointsProgramEnum
} from '../../shared/interfaces/pointsProgramRules'
import { ITableData, IViewProps } from './types'

import {
	TableButton,
	StatusPointsProgramChip,
	BedgeRulePointsProgramParameters,
	SeeRuleParametersButton
} from '../../shared/components'

import PointsEarnedRatioView from './view'

import FileTextIcon from '../../assets/images/file-text.svg'
import TrashIcon from '../../assets/images/trash.svg'

import * as S from './styles'
import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'
import { filteringRulesByString } from '../../shared/utils/filteringRulesByString'
import { IResponseGetProductRedemptionRules } from '../../shared/services/types'
import { getRules } from '../../shared/services/pointProgramRules.service'
import { creationStatusMapper } from '../../shared/utils/creationStatusMapper'

export default function PointsEarnedRatio() {
	const [rulesData, setRulesData] =
		useState<IResponseGetProductRedemptionRules>({
			data: [],
			totalItems: 0,
			totalPages: 0
		})
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [searchString, setSearchString] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
		useState(false)
	const [ruleIDToDeleteIt, setRuleIDToDeleteIt] = useState('')

	const [ruleParametersModalData, setRuleParametersModalData] = useState<
		IFilterRulePoint[]
	>([])

	const history = useHistory()

	const columns = useMemo(
		() => [
			{
				Header: 'ID #',
				accessor: 'autoIncRuleId',
				disableSortBy: true
			},
			{
				Header: 'Status',
				accessor: 'status',
				disableSortBy: true
			},
			{
				Header: 'Filtros',
				accessor: 'filters',
				disableSortBy: true
			},
			{
				Header: 'Data de Início e Término',
				accessor: 'startAndEndDate',
				disableSortBy: true
			},
			{
				Header: 'Produtos',
				accessor: 'products',
				disableSortBy: true
			},
			{
				Header: 'Prop. Ganhos de Pontos',
				accessor: 'pointsEarnings',
				disableSortBy: true
			},
			{
				Header: 'Data de criação',
				accessor: 'createdAt',
				disableSortBy: true
			},
			{
				Header: 'Observação',
				accessor: 'observation',
				disableSortBy: true
			},
			{
				Header: 'Status Criação',
				accessor: 'creationStatus',
				disableSortBy: true
			},
			{ Header: '', accessor: 'button', disableSortBy: true },
			{ Header: '', accessor: 'deleteButton', disableSortBy: true }
		],
		[]
	)

	const handlerSeeDetailsOfRule = (modalData: IFilterRulePoint[]) => {
		setShowModal(!showModal)
		setRuleParametersModalData(modalData)
	}

	const enableModalToConfirmRuleDeletion = (ruleID: string) => {
		handleShowDeleteConfirmationModal()
		setRuleIDToDeleteIt(ruleID)
	}

	const handleShowDeleteConfirmationModal = () =>
		setShowDeleteConfirmationModal(!showDeleteConfirmationModal)

	const handleEditRule = (rule: IHeaderRulePoint) =>
		history.push({
			pathname:
				AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO__DETAIL,
			state: {
				rule
			}
		})

	const handleToPointsProgramRulesPointsGainRatioCreating = () => {
		history.push(
			AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO__DETAIL
		)
	}

	const renderStatus = (status: StatusRulePointsProgramEnum) => {
		return <StatusPointsProgramChip label={status} />
	}

	const renderParameters = ({
		parameters,
		modalData
	}: {
		parameters: string
		modalData: IFilterRulePoint[]
	}) => {
		return (
			<S.FiltersContainer>
				<BedgeRulePointsProgramParameters>
					{parameters}
				</BedgeRulePointsProgramParameters>
				<SeeRuleParametersButton
					onClick={() => handlerSeeDetailsOfRule(modalData)}
				/>
			</S.FiltersContainer>
		)
	}

	const renderButton = (rule: IHeaderRulePoint) => {
		return (
			<button className="edit" onClick={() => handleEditRule(rule)}>
				<img className="edit-img" src={FileTextIcon} alt="Editar" />
			</button>
		)
	}

	const renderObservationContent = (text: string) => {
		return <S.Observation>{text}</S.Observation>
	}

	const locale = { locale: BR }

	const countFilterParams = (filters = ''): number => {
		if (!filters) return 0

		const filterObject: Record<string, string[]> = JSON.parse(filters)

		const count = Object.values(filterObject).reduce(
			(acc, curr) => (acc += curr.length),
			0
		)
		return count
	}

	const tableElementGenerator = (rule: IHeaderRulePoint): ITableData => {
		return {
			ID: rule.ruleID,
			autoIncRuleId: `#${rule.autoIncRuleId}`,
			filters: renderParameters({
				parameters: `${countFilterParams(rule.filters)} PARÂMETROS`,
				modalData: rule.filtersRulePoint ? rule.filtersRulePoint : []
			}),
			startAndEndDate: `De ${format(
				new Date(String(rule.initialDate) + 'T12:00:00'),
				'dd/MM/yy',
				locale
			)} 
				até ${format(
					new Date(String(rule.finalDate) + 'T12:00:00'),
					'dd/MM/yy',
					locale
				)}`,

			status: renderStatus(
				rule.status || StatusRulePointsProgramEnum.INACTIVE
			),
			button: renderButton(rule),
			deleteButton: TableButton(
				() => enableModalToConfirmRuleDeletion(String(rule.ruleID)),
				TrashIcon
			),
			pointsEarnings: String(rule.scoreValue),
			products: `${rule.itemsRulePointScoreValue?.length} ${
				rule.itemsRulePointScoreValue?.length === 1
					? 'Produto'
					: 'Produtos'
			}`,
			createdAt: format(
				new Date(
					rule.ruleRegistrationDate
						? `${rule.ruleRegistrationDate}T12:00:00`
						: (rule.createdAt as string)
				),
				'dd/MM/yyyy',
				locale
			),
			observation: rule.observation
				? renderObservationContent(rule.observation)
				: null,
			creationStatus:
				creationStatusMapper[rule?.creationStatus ?? 'SUCCESS']
		}
	}

	const fetchPointsProgramRules = (pageNumber?: number) => {
		;(async () => {
			const pageSize = 10
			setIsLoading(true)
			// const response = await getPointsProgramRules()
			const response = pageNumber
				? await getRules('acumulo')
				: await getRules('acumulo', pageSize, pageNumber)
			setIsLoading(false)
			setRulesData(response)
			const data: ITableData[] = response.data.map(tableElementGenerator)
			setTableData(data)
		})()
	}

	const searchStringHandler = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value
			setSearchString(value)

			if (rulesData?.data.length) {
				const rulesFiltered = filteringRulesByString(
					rulesData.data,
					value
				)

				const data: ITableData[] =
					rulesFiltered.map(tableElementGenerator) || []

				setTableData(data)
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rulesData]
	)

	const handlerCloseModal = () => setShowModal(false)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchPointsProgramRules, [showDeleteConfirmationModal])

	const fetchPointsProgramOnPage = useCallback(async (index: number = 0) => {
		const pageSize = 10
		setIsLoading(true)
		const response = await getRules('acumulo', pageSize, index + 1)
		setIsLoading(false)
		setRulesData(response)
		const data: ITableData[] = response.data.map(tableElementGenerator)
		setTableData(data)
	}, [])

	const viewProps: IViewProps = {
		columns,
		tableData,
		showModal,
		isLoading,
		searchString,
		ruleIDToDeleteIt,
		handlerCloseModal,
		searchStringHandler,
		showDeleteConfirmationModal,
		handleShowDeleteConfirmationModal,
		modalData: ruleParametersModalData,
		handleToPointsProgramRulesPointsGainRatioCreating,
		rulesData,
		fetchPointsProgramOnPage
	}

	return createElement(PointsEarnedRatioView, viewProps)
}
