import cogoToast from 'cogo-toast'
import { createElement, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ConfirmationMethods } from '../../components/AccountActivationSession/types'
import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'

import { IStore } from '../../shared/interfaces/store'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { resendSignUp } from '../ConfirmActivationCode/service'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { forgotPasswordSubmit } from './service'
import { IProps, IViewProps } from './types'
import ChangePassword from './view'

const INITIAL_COUNTER_VALUE = 60

function ChangePasswordContainer(): JSX.Element {
	const { state } = useLocation<IProps>()
	const confirmationMethod = state.confirmationMethod as ConfirmationMethods
	const destination = state.destination

	const history = useHistory()
	const transferArea = useSelector((state: IStore) => state.transferArea)

	const [code, setCode] = useState('')
	const [codeIsValid, setCodeIsValid] = useState(false)

	const [password, setPassword] = useState('')
	const [passwordIsValid, setPasswordIsValid] = useState(false)

	const [loading, setLoading] = useState(false)

	const [timer, setTimer] = useState(INITIAL_COUNTER_VALUE)
	const [canResendCode, setCanResendCode] = useState(false)

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}

	async function handleChangePassword() {
		try {
			setLoading(true)

			const response = await forgotPasswordSubmit({
				documentNumber: transferArea.PersonalFiscalID,
				code,
				newPassword: password
			})

			if (!!response?.hasError) return

			cogoToast.success('Senha Alterada com sucesso!', cogoDefaultOptions)
			history.replace(UnauthenticatedRoutesEnum.LOGIN)
		} finally {
			setLoading(false)
		}
	}

	function isDisabled() {
		if (!passwordIsValid || !codeIsValid) return true

		return false
	}

	const handleResendCode = useCallback(() => {
		;(async () => {
			const response = await resendSignUp({
				username: transferArea.PersonalFiscalID,
				clientMetadata:
					formatConfirmationCodeMethodParams(confirmationMethod)
			})

			if (!response) return

			setTimer(INITIAL_COUNTER_VALUE)
			setCanResendCode(false)
		})()
	}, [transferArea.PersonalFiscalID, confirmationMethod])

	function resendCodeTimer() {
		if (confirmationMethod === 'sendemail') return

		setInterval(() => {
			setTimer((state) => state - 1)
		}, 1000)
	}

	function checkTimer() {
		timer <= 0 && setCanResendCode(true)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(resendCodeTimer, [])
	useEffect(checkTimer, [timer])
	useEffect(() => {
		if (!transferArea.PersonalFiscalID)
			history.replace(UnauthenticatedRoutesEnum.RESET_PASSWORD)
	})

	const viewProps: IViewProps = {
		handleChangePassword,
		loading,
		setPassword,
		setPasswordIsValid,
		setCode,
		setCodeIsValid,
		isDisabled,
		handleBack,
		timer,
		canResendCode,
		confirmationMethod,
		handleResendCode,
		password,
		destination
	}

	return createElement(ChangePassword, viewProps)
}

export default ChangePasswordContainer
