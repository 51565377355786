import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import SearchIcon from '../../assets/images/search.svg'

import { Header, Loading, SelectPrimary } from '../../shared/components'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { IMaterial } from '../../shared/interfaces/material'
import {
	ChallengeFiltersEnum,
	ChallengeStatusEnum,
	IChallenge
} from '../../shared/interfaces/pointsProgramChallenge'
import {
	fetchMyChallenges,
	fetchNewChallenges
} from '../../shared/services/challenge.service'
import { Creators as ChallengesAlreadyShownActions } from '../../shared/store/ducks/challengesAlreadyShown'
import { Creators as ChallengesThatTheUserIsParticipatingInActions } from '../../shared/store/ducks/challengesTheUserIsParticipatingIn'
import { ISelectOption } from '../../shared/utils/SelectOptions'
import { getMaterials } from '../ProductList/service'
import ChallengesCarousel from './ChallengesCarousel'
import ModalChallengePopUp from './ModalChallengePopUp'

import * as S from './styles'
import {
	challengeFilters,
	filterChallengesCompletedMoreThan30DaysAgo
} from './util'

function Challenges() {
	const { challengesAlreadyShown } = useTypedSelector([
		'challengesAlreadyShown'
	])

	const dispatch = useDispatch()

	const { customer } = useTypedSelector(['customer'])

	const [isFetchingNewChallenges, setIsFetchingNewChallenges] = useState(true)
	const [isFetchingMyChallenges, setIsFetchingMyChallenges] = useState(false)
	const [selectedFilterOption, setSelectedFilterOption] = useState(
		ChallengeFiltersEnum.ARE_EXPIRING
	)
	const [newChallenges, setNewChallenges] = useState<IChallenge[]>([])
	const [filteredNewChallenges, setFilteredNewChallenges] = useState<
		IChallenge[]
	>([])

	const [products, setProducts] = useState<IMaterial[]>([])
	const [myChallenges, setMyChallenges] = useState<IChallenge[]>([])

	const [challengeToBePopped, setChallengeToBePopped] = useState<IChallenge>()

	const [searchString, setSearchString] = useState('')

	const handlers = {
		changeSearchString: (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value || ''
			filterChallengesByString({ searchStringProp: value })
			setSearchString(value)
		},
		changeFilterOption: useCallback((option: ISelectOption) => {
			setSelectedFilterOption(option.value as ChallengeFiltersEnum)
		}, [])
	}

	function initialRequest() {
		fetchProducts()
		fetchMyChallengesData()
	}

	function fetchNewChallengesData(productsArray = products) {
		if (!productsArray.length) return
		;(async () => {
			try {
				setIsFetchingNewChallenges(true)

				const newChallenges = await fetchNewChallenges({
					products: productsArray,
					filter: selectedFilterOption
				})

				const newChallengesWithIndicationThatTheUserIsNotParticipating =
					newChallenges.map((newChallenge) => ({
						...newChallenge,
						userIsAlreadyParticipatingInTheChallenge: false
					}))

				filterChallengesByString({
					newChallengesProp:
						newChallengesWithIndicationThatTheUserIsNotParticipating
				})
				setNewChallenges(
					newChallengesWithIndicationThatTheUserIsNotParticipating
				)
			} finally {
				setIsFetchingNewChallenges(false)
			}
		})()
	}

	function fetchProducts() {
		;(async () => {
			try {
				const products = await getMaterials({
					salesOrganizationID: customer.SalesOrganizationID,
					segmentID: customer.SegmentID,
					sectorID: customer.sectorID,
					customerID: customer.CustomerID,
					morePoints: false,
					channelGroupID: customer.ChannelGroupID,
					includesDraftBeer: customer.draftBeer
				})

				if (!products?.length) return

				fetchNewChallengesData(products)
				setProducts(products)
			} catch (err) {
				// console.log('fetchProductsError', { err })
				if (!products.length) {
					setIsFetchingNewChallenges(false)
					return
				}
				fetchNewChallengesData()
			}
		})()
	}

	function fetchMyChallengesData() {
		if (isFetchingMyChallenges) return
		;(async () => {
			try {
				setIsFetchingMyChallenges(true)

				const myChallengesData = await fetchMyChallenges({
					ignoreCompletedChallenges: false
				})

				if (!myChallengesData?.length) return

				let myChallenges: IChallenge[] = myChallengesData
					.map((myChallenge) => ({
						...myChallenge,
						userIsAlreadyParticipatingInTheChallenge: true
					}))
					.sort((challenge) => (!challenge.completedAt ? -1 : 1))

				myChallenges =
					filterChallengesCompletedMoreThan30DaysAgo(myChallenges)

				setMyChallenges(myChallenges)

				dispatch(
					ChallengesThatTheUserIsParticipatingInActions.setChallenges(
						myChallenges
							.filter((challenge) => !challenge.completedAt)
							.map((challenge) => ({
								challengeID: challenge.challengeId,
								products: challenge.products
							}))
					)
				)
			} finally {
				setIsFetchingMyChallenges(false)
			}
		})()
	}

	function handleShowChallengePopup(challenge: IChallenge) {
		dispatch(
			ChallengesAlreadyShownActions.addChallengeShown(
				challenge.challengeId
			)
		)
		setChallengeToBePopped(challenge)
	}

	const showPopUpOfChallengeHandler = (
		newChallengesState: IChallenge[],
		challengesAlreadyShownState: string[]
	) => {
		if (!newChallengesState.length) return

		const newChallengeSortedByTheMostRecent = [...newChallengesState]
			.sort(
				(a, b) =>
					new Date(String(b.createdAt)).getTime() -
					new Date(String(a.createdAt)).getTime()
			)
			.filter(
				(challenge) => challenge.status !== ChallengeStatusEnum.EXPIRED
			)

		if (!newChallengeSortedByTheMostRecent.length) return

		if (!challengesAlreadyShownState.length) {
			handleShowChallengePopup(newChallengeSortedByTheMostRecent[0])
			return
		}

		for (let i = 0; i < newChallengeSortedByTheMostRecent.length; i++) {
			const newChallenge = newChallengeSortedByTheMostRecent[i]
			const challengeAlreadyShown = challengesAlreadyShownState.find(
				(challengeId) => newChallenge.challengeId === challengeId
			)
			if (!challengeAlreadyShown) {
				handleShowChallengePopup(newChallenge)
				break
			}
		}
	}

	function filterChallengesByString({
		searchStringProp = searchString,
		newChallengesProp = newChallenges
	}: {
		searchStringProp?: string
		newChallengesProp?: IChallenge[]
	}) {
		const upperCasedString = searchStringProp.toUpperCase()
		const filteredNewChallengesByString = newChallengesProp.filter(
			(challenge) =>
				challenge.score.indexOf(upperCasedString) > -1 ||
				challenge.title.toUpperCase().indexOf(upperCasedString) > -1 ||
				challenge.description.toUpperCase().indexOf(upperCasedString) >
					-1 ||
				(challenge.observation
					?.toUpperCase()
					.indexOf(upperCasedString) || -1) > -1
		)
		setFilteredNewChallenges(filteredNewChallengesByString)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initialRequest, [])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchNewChallengesData, [selectedFilterOption])

	useEffect(() => {
		showPopUpOfChallengeHandler(newChallenges, challengesAlreadyShown)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newChallenges])

	return (
		<>
			<Header />
			<S.Container>
				<S.FiltersGroup>
					<div
						className="combo-filters"
						style={{ padding: 0, justifyContent: 'flex-start' }}>
						<div className="input">
							<input
								onChange={handlers.changeSearchString}
								required
								id="search"
								value={searchString}
								style={{ height: 58 }}
							/>
							<label htmlFor="search">Pesquisar Desafio</label>
							<img
								className="magnifier-icon"
								src={SearchIcon}
								alt="Icone de Lupa"
							/>
						</div>
						<div className="select">
							<SelectPrimary
								placeholder={'FILTRAR DESAFIOS'}
								options={challengeFilters}
								onChange={handlers.changeFilterOption}
								value={
									challengeFilters.find(
										(option) =>
											option.value ===
											selectedFilterOption
									)?.label || ''
								}
							/>
						</div>
					</div>
				</S.FiltersGroup>
				<S.ChallengesCarouselSession>
					<div className="title">
						<h2>Novos Desafios</h2>
					</div>
					{!isFetchingNewChallenges ? (
						!!filteredNewChallenges.length ? (
							<ChallengesCarousel
								isParticipating={false}
								challenges={filteredNewChallenges}
							/>
						) : (
							<div className="no-product-placeholder-wrapper">
								<h4>
									Não há novos desafios para serem exibidos
								</h4>
							</div>
						)
					) : (
						<div className="loading-wrapper">
							<Loading />
						</div>
					)}
				</S.ChallengesCarouselSession>
				<S.ChallengesCarouselSession>
					<div className="title">
						<h2>Meus Desafios</h2>
					</div>
					{!isFetchingMyChallenges ? (
						!!myChallenges.length ? (
							<ChallengesCarousel
								isParticipating={true}
								challenges={myChallenges}
							/>
						) : (
							<div className="no-product-placeholder-wrapper">
								<h4>
									Você ainda não está participando de nenhum
									desafio no momento
								</h4>
							</div>
						)
					) : (
						<div className="loading-wrapper">
							<Loading />
						</div>
					)}
				</S.ChallengesCarouselSession>
			</S.Container>
			{challengeToBePopped ? (
				<ModalChallengePopUp
					challenge={challengeToBePopped}
					active={!!challengeToBePopped}
					onClose={() => setChallengeToBePopped(undefined)}
				/>
			) : null}
		</>
	)
}

export default Challenges
