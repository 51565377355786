import React, { Fragment, useState } from 'react'
import { Header } from '../../shared/components'

import { ImpactedClientCounter } from '../../components/ImpactedClientCounter'
import { IViewProps } from './types'

import { BehaviorFilter } from '../../views/push-notifications-admin/components/behavior-filter'
import FiltersByHieararchy from '../../components/filters-by-hieararchy/filters-by-hieararchy.component'
import { getChallengeFilterParameters } from '../../shared/services/pointProgramChallenges.service'
import { useLocation } from 'react-router-dom'

import { IProps } from '../PointProgramChallengeDetailsV2/types'
import { Container } from '../../components/Container/container.style'

import styles from './styles.module.scss'
import { IFilterParams } from '../../components/filters-by-hieararchy/typings'

function PushNotification(props: IViewProps): JSX.Element {
	const {
		isLoading: pushLoading,
		impactedPdvs,
		pushSelectedPDVs,
		handleSendNotification,
		handleGetFilterValues,
		setCanSendPdv,
		setImpactedPdvs,
		setPushSelectedPDVs
	} = props

	const location = useLocation<IProps>()
	const [canFilter, setCanFilter] = useState<boolean>(false)

	const [isLoadingFilterOptions, setIsLoadingFilterOptions] = useState(false)

	const { challenge: challengeFromProps } = location.state || {}

	const isLoading = pushLoading || isLoadingFilterOptions

	return (
		<Fragment>
			<Header />
			<Container>
				<div className="push-notification-page">
					<div className="container-full">
						<div className="header push-notification-header">
							<h1 className="title">Push Notifications</h1>
						</div>
					</div>

					<div className={styles.filterHierarchyContainer}>
						<FiltersByHieararchy
							initialFilters={
								challengeFromProps?.filtersChallenge as IFilterParams[]
							}
							shouldListPdv={false}
							fetchListPDVsPush={true}
							onFilterChange={() => null}
							onPushFilterChange={setImpactedPdvs}
							isEnabled={true}
							setCanSendPdv={setCanSendPdv}
							setPushSelectedPDVs={setPushSelectedPDVs}
							handleGetFilterValues={handleGetFilterValues}
							getFilterParameters={getChallengeFilterParameters}
							onLoad={() => setIsLoadingFilterOptions(true)}
							onLoadEnd={() => setIsLoadingFilterOptions(false)}
							canFilter={canFilter}
							setCanFilter={setCanFilter}
							impactedPdvsLength={impactedPdvs}
						/>

						<ImpactedClientCounter
							impactedClients={
								impactedPdvs || pushSelectedPDVs.length
							}
						/>

						<div className="container-full">
							{!isLoading &&
							(impactedPdvs > 0 ||
								pushSelectedPDVs.length > 0) ? (
								<BehaviorFilter
									userImpacted={(
										impactedPdvs || pushSelectedPDVs.length
									).toString()}
									handleSendNotification={
										handleSendNotification
									}
								/>
							) : (
								<Fragment />
							)}
						</div>
					</div>
				</div>
			</Container>
		</Fragment>
	)
}

export default PushNotification
