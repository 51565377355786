import React, { FormEvent } from 'react'
import InputMask from 'react-input-mask'

import { IProps } from './types'

function InputField(props: IProps) {
	const {
		type,
		disabled = false,
		onChange,
		id,
		name,
		maxLength,
		value,
		mask,
		label,
		errorMessage,
		infoMsg,
		hasError = false,
		secondary = false,
		className = '',
		disabledCopyPaste = false,
		labelColor,
		showError,
		Icon
	} = props

	const inputClass = secondary ? 'input-secondary' : 'input'

	const styles = `${className || inputClass} ${hasError ? 'error' : ''}`

	function handleChangeCopyAndPaste(e: FormEvent) {
		if (disabledCopyPaste) e.preventDefault()
	}

	React.useEffect(() => showError?.(hasError), [hasError, showError])

	return (
		<div className={styles}>
			{mask ? (
				<InputMask
					{...props}
					disabled={disabled}
					id={id}
					mask={mask}
					maskChar={''}
					maxLength={maxLength}
					name={name}
					onChange={onChange}
					required
					type={type}
					value={value}
				/>
			) : (
				<input
					{...props}
					disabled={disabled}
					id={id}
					maxLength={maxLength}
					name={name}
					onChange={onChange}
					required
					type={type}
					value={value}
					onCopy={handleChangeCopyAndPaste}
					onCut={handleChangeCopyAndPaste}
					onPasteCapture={handleChangeCopyAndPaste}
					onPaste={handleChangeCopyAndPaste}
				/>
			)}

			<label htmlFor={id} className={labelColor}>
				{label}
			</label>

			{hasError ? (
				<span className="error-msg">
					{errorMessage || 'Campo inválido'}
				</span>
			) : null}

			{infoMsg ? <span className="info-msg">{infoMsg}</span> : null}

			{Icon ? (
				<div className="icon">
					<img src={Icon} alt="input icone" />
				</div>
			) : null}
		</div>
	)
}

export default InputField
