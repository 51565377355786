import GPLogo from '../../assets/images/logo-bp.svg'
import ShoppingCartWhiteIcon from '../../assets/images/shopping-cart-white.svg'
import MenuWhiteIcon from '../../assets/images/menu-white.svg'
import GPLogoRed from '../../assets/images/logo-gp--red.svg'
import ShoppingCartIcon from '../../assets/images/shopping-cart.svg'
import MenuIcon from '../../assets/images/menu.svg'
import ShoppingCartRed from '../../assets/images/shopping-cart-red.svg'

export const headerAssetPaths = {
	default: {
		logo: GPLogo,
		cartIcon: ShoppingCartWhiteIcon,
		menuIcon: MenuWhiteIcon
	},
	sac: {
		logo: GPLogoRed,
		cartIcon: ShoppingCartIcon,
		menuIcon: MenuIcon
	},
	reverse: {
		cartIcon: ShoppingCartRed
	}
}
