import { isAfter, sub } from 'date-fns'
import { ChallengeFiltersEnum, IChallenge } from '../../shared/interfaces/pointsProgramChallenge'
import { ISelectOption } from '../../shared/utils/SelectOptions'

export const challengeFilters: ISelectOption[] = [
    {
        value: ChallengeFiltersEnum.ARE_EXPIRING,
        label: 'A EXPIRAR',
    },
    {
        value: ChallengeFiltersEnum.OLDER,
        label: 'MAIS ANTIGOS',
    },
    {
        value: ChallengeFiltersEnum.BIGGER_BONUS,
        label: 'MAIOR BONIFICAÇÃO',
    },
    {
        value: ChallengeFiltersEnum.EXPIRED,
        label: 'EXPIRADOS',
    },
]

export function filterChallengesCompletedMoreThan30DaysAgo(
    myChallenges: IChallenge[]
) {
    const filteredChallenges = myChallenges.filter(
        (challenge) => {
            if (!challenge.completedAt) return true

            const challengeCompletedDate = new Date(challenge.completedAt)
            const periodLimit = { days: 30 }

            return isAfter(challengeCompletedDate, sub(new Date(), periodLimit))
        }
    )

    return filteredChallenges
}
