import { last } from 'ramda'

import { ICartComboProduct, ICartItem } from '../interfaces/cart'
import { ICustomer, IFormattedPaymentCondition } from '../interfaces/customer'
import { IOrderDetail, IOrderItem } from '../interfaces/orderDetail'
import { IComboItem, IItem, ISaleOrder } from '../interfaces/saleOrder'
import { parseItemPrice } from './money'

export function formatSaleOrder({
	salesOrderID,
	customer,
	condition,
	items,
	itemsChallenge,
	paymentTax,
	deliveryDate,
	redemptionItems
}: {
	salesOrderID: string
	customer: ICustomer
	condition: IFormattedPaymentCondition
	items: Array<ICartItem>
	paymentTax: number
	deliveryDate?: string
	redemptionItems: Array<ICartItem>
	itemsChallenge: Array<ICartItem>
}): ISaleOrder {
	const {
		SalesOrganizationID: salesOrganizationID,
		CustomerID: customerID,
		sectorID
	} = customer

	const { PaymentTerms: paymentConditionID, PaymentMethod: paymentFormID } =
		condition

	const formattedItems = items
		.map((item, i) => formatSaleOrderItem({ item, i, paymentTax }))
		.flat()

	const formattedRedemptionItems = redemptionItems.map((item, i) =>
		formatSaleOrderItem({ item, i, paymentTax, isRedemption: true })
	)

	const formattedItemsChallenge = itemsChallenge.map((item, i) =>
		formatSaleOrderItem({ item, i, paymentTax })
	)

	const taxRate = paymentTax

	const formatted: ISaleOrder = {
		salesOrderID,
		salesOrganizationID,
		customerID,
		deliveryDate: deliveryDate ? deliveryDate : '',
		paymentConditionID,
		paymentFormID,
		items: formattedItems as IItem[],
		taxRate,
		sectorID,
		itemsPoints: formattedRedemptionItems as IItem[],
		itemsChallenge: formattedItemsChallenge as IItem[]
	}

	return formatted
}

interface FormatSaleOrderItemProps {
	item: ICartItem
	i: number
	paymentTax: number
	isRedemption?: boolean
}

function formatSaleOrderItem({
	item,
	i,
	paymentTax,
	isRedemption = false
}: FormatSaleOrderItemProps): IItem | undefined | IItem[] {
	const {
		MaterialID: id,
		Price: pr,
		OrderQuantity: oq,
		UnitMeasureSale,
		ScoreValue,
		ScoreRate
	} = item

	if (item.operationTypeDescription && item.promotionType?.toString() === '1')
		return formatSaleOrderComboItem(item, i, paymentTax)

	const salesOrderItem = String(i + 1).padStart(6, '0')

	const quantity = Number(oq)

	const price = Number((Number(pr) / quantity).toFixed(2))

	const taxValue = Number((price * paymentTax).toFixed(2))
	const taxValueAllQuantity = Number((taxValue * quantity).toFixed(2))

	const priceUnitWithTax = Number((price + taxValue).toFixed(2))
	const productID =
		item.promotionType === 2
			? Number(item?.products?.map((item) => item.productID)).toString()
			: Number(id).toString()

	const scoreRate = isRedemption
		? String(Number(ScoreRate) * quantity)
		: undefined

	const formatted: IItem = {
		salesOrderItem,
		productID,
		quantity,
		unitMeasureSale: UnitMeasureSale || '',
		price: priceUnitWithTax,
		taxValue: taxValueAllQuantity,
		scoreRate: scoreRate,
		scoreValue: !isRedemption ? ScoreValue : undefined,
		challengeID: item.challengeID
	}

	if (item.promotionType?.toString() === '2') {
		const salesOrderItem = String(i + 1).padStart(6, '0')

		const productItens = item?.products?.flatMap<IItem>(
			(product, index) => ({
				productID: product.productID,
				unitMeasureSale: product.unitMeasureSale,
				quantity: product.quantity * oq,
				price: product.price,
				numeratorConversion: String(product.numeratorConversion),
				promotionID: product.productID,
				promotionShortName: item?.promotionShortName ?? '',
				promotionType: item.promotionType ?? 0,
				salesOrderItem: String(index + salesOrderItem + 1).padStart(
					6,
					'0'
				),
				taxValue: item.priceByUnit
					? Number((item.priceByUnit * paymentTax).toFixed(2))
					: 0
			})
		)
		return productItens
	}
	return formatted
}

function formatSaleOrderComboItem(
	item: ICartItem,
	i: number,
	paymentTax: number
): IItem {
	const products = item.products || []

	const formattedProducts = products.map((product) => {
		const {
			productID: id,
			price: pr,
			quantity: oq,
			unitMeasureSale,
			tradeType,
			numeratorConversion
		} = product

		const productID = Number(id).toString()

		const quantity = Number(oq)

		const price = Number(Number(pr).toFixed(2))
		//const price = Number((Number(pr) / quantity).toFixed(2))
		const taxValue = Number((price * paymentTax).toFixed(2))
		const taxValueAllQuantity = Number((taxValue * quantity).toFixed(2))
		const priceUnitWithTax = Number((price + taxValue).toFixed(2))

		const formattedProduct: ICartComboProduct = {
			productID,
			quantity,
			price: priceUnitWithTax,
			unitMeasureSale,
			tradeType,
			taxValue: taxValueAllQuantity,
			numeratorConversion: String(numeratorConversion),
		}

		return formattedProduct
	})

	const { Price: pr, OrderQuantity: oq } = item

	const salesOrderItem = String(i + 1).padStart(6, '0')

	const orderQuantity = Number(oq)
	const comboPrice = Number((Number(pr) / orderQuantity).toFixed(2))
	const taxValue = Number((comboPrice * paymentTax).toFixed(2))
	const taxValueAllQuantity = Number((taxValue * orderQuantity).toFixed(2))
	const priceUnitWithTax = Number((comboPrice + taxValue).toFixed(2))

	const formattedCombo: IComboItem = {
		salesOrderItem: salesOrderItem,
		quantity: orderQuantity,
		price: priceUnitWithTax,
		taxValue: taxValueAllQuantity,
		promotionID: item.promotionID,
		promotionShortName: item.promotionShortName,
		promotionType: item.promotionType,
		operationTypeDescription: item.operationTypeDescription,
		products: formattedProducts,
		tradePrice: item.tradePrice
	}

	return formattedCombo
}

export function calcPrice(items: Array<IOrderItem>): string {
	let price = 0
	items.forEach((item) => {
		if (item.operationType === 'Z4BN') return
		price += item.Price * item.Quantity
	})

	return parseItemPrice(price)
}

export const cancelStatusDesciption = (status: string) => {
	if (status === 'RECEBIDO') {
		return 'CANCELAMENTO RECEBIDO'
	} else if (status === 'EM ATENDIMENTO') {
		return 'CANCELAMENTO EM ATENDIMENTO'
	} else if (status === 'ATENDIDA') {
		return 'CANCELAMENTO ATENDIDO'
	} else if (status === 'CONCLUIDO') {
		return 'CANCELAMENTO CONCLUIDO'
	} else {
		return ''
	}
}

export const returnLatestStatus = (order: IOrderDetail): string =>
	order.RequestsCancelStatus.length > 0
		? cancelStatusDesciption(
				last(order.RequestsCancelStatus)
					?.requestCancelStatusDescription || ''
		  )
		: last(order.OrderStatus)?.OrderStatusDescription || ''
