import React, { FC } from 'react'
import { StatusRulePointsProgramEnum } from '../../shared/interfaces/pointsProgramRules'
import { ChallengeStatusEnum } from '../../shared/interfaces/pointsProgramChallenge'

import * as S from './styles'

interface IStatusPointsProgramChip {
	label: ChallengeStatusEnum | StatusRulePointsProgramEnum
}

const StatusPointsProgramChip: FC<IStatusPointsProgramChip> = ({ label }) => {
	return <S.StatusChipSpan status={label}>{label}</S.StatusChipSpan>
}

export default StatusPointsProgramChip
