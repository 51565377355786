import React from 'react'

import { IViewProps } from './types'

import CheckCircleIcon from '../../assets/images/conclusion-placeholder.svg'
import FinishOrderHeader from '../../components/FinishOrderHeader'
import {
	InfoValue,
	InfoValueTotal,
	InfoWrapper,
	Label,
	Subtitle,
	SuccessWrapper,
	Title
} from './styles'
import { toMoney } from '../../shared/utils/money'
import { translatePaymentOnlineDone } from '../../shared/utils/translate'
import { tranformDeliveryDate } from '../../shared/utils/date'

function Success(props: IViewProps): JSX.Element {
	const {
		handleBackToProductList,
		paymentType,
		valueTotal,
		paymentMethod,
		paymentDetail,
		deliveryDate
	} = props

	return (
		<SuccessWrapper className="order-summary-page">
			<FinishOrderHeader />

			<div className="container successContainer ">
				<img
					src={CheckCircleIcon}
					className="check-icon"
					alt="Icone Check"
				/>

				<Title>Pedido confirmado</Title>
				<Subtitle>O pedido já está sendo preparado.</Subtitle>
				<div className="row">
					<InfoWrapper>
						<Label>ENTREGA EM ATÉ</Label>
						<InfoValue>
							{tranformDeliveryDate(deliveryDate)}
						</InfoValue>
					</InfoWrapper>

					<InfoWrapper>
						<Label>PAGAMENTO</Label>
						<span className="paymentType">
							<InfoValue>
								{paymentType === 'DELIVERY'
									? `Na entrega, ${paymentDetail.toLowerCase()}`
									: paymentMethod &&
									  translatePaymentOnlineDone[paymentMethod]}
							</InfoValue>

							<InfoValueTotal>
								{paymentMethod === 'creditCard'
									? `1x de ${toMoney(valueTotal)}`
									: toMoney(valueTotal)}
							</InfoValueTotal>
						</span>
					</InfoWrapper>
				</div>

				<button
					className="green-button"
					onClick={handleBackToProductList}>
					Concluir
				</button>
			</div>
		</SuccessWrapper>
	)
}

export default Success
