import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IOrderHistoryActionCreators
} from '../../interfaces/action'
import { IStoreOrderHistory } from '../../interfaces/store'

export const {
	Types,
	Creators
}: IActionsFromCreators<IOrderHistoryActionCreators> = createActions({
	updateOrderHistory: ['orderHistory']
})

const INITIAL_STATE: IStoreOrderHistory = []

const update = (state = INITIAL_STATE, action: any) => action.orderHistory

export default createReducer<
	IStoreOrderHistory,
	Action<IOrderHistoryActionCreators>
>(INITIAL_STATE, {
	[Types.UPDATE_ORDER_HISTORY]: update
})
