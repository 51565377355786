import styled from 'styled-components'
import { toRem } from '../../shared/styles/functions'
import { colors } from '../../shared/styles/theme'

export const SplittedOrderAlert = styled.div`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${colors.secondGrey};
	width: 350px;
	letter-spacing: ${toRem(0.64)};
	text-transform: uppercase;
	align-self: flex-start;
	margin-bottom: 12px;
`

export const OrderTitlteWrapper = styled.div`
	margin-top: 32px;
`
export const DeliveryWrapper = styled.div`
	.info {
		justify-content: center;
	}

	.react-select {
		margin-left: 3px;
		background: white;
	}

	.input-secondary {
		background: white;
	}

	input {
		text-transform: capitalize;
	}

	.info-text {
		text-align: center;
		margin: auto;
	}

	p {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		color: ${colors.secondGrey};
		margin-bottom: ${toRem(64)};
	}

	.buttons {
		text-align: center;
	}
	button {
		margin-bottom: 8px;
	}
`
export const OrderSummaryDetails = styled.section`
	margin-top: 60px;

	.cart-table thead {
		display: none;
	}

	h2 {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colors.secondGrey};
		margin-bottom: 24px;
	}

	.summary-title {
		font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
		color: ${colors.secondGrey};
		margin-right: 8px;
	}

	.cart-summary {
		background-color: ${(props) => props.theme.palette.primaryWhite.main};
		border: 1px solid ${(props) => props.theme.palette.lightGray.main};
		border-radius: 4px;
		padding: 24px;
		> div {
			margin-bottom: 20px;
			padding: 0 0 20px;
		}

		> div:nth-child(1),
		> div:nth-child(3) {
			border-bottom: 1px solid
				${(props) => props.theme.palette.lightGray.main};
		}

		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${colors.secondGrey};
			margin: 8px 0;
		}
		.address {
			text-transform: capitalize;
		}
	}

	.button {
		text-align: center;
	}

	.flex {
		display: flex;
		align-items: center;
	}

	.delivery {
		text-transform: capitalize;
	}

	.cart-payment {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`

export const LoadingWrapper = styled.div`
	margin-top: 20px;
`

export const ChallengesProductsTitle = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
	margin-bottom: 8px;
`
