import { IChallenge } from '../../../shared/interfaces/pointsProgramChallenge'

type SalesHierarchyFilters = {
	key: FilterNameEnum
	options: {
		label: string
		options: IOption[]
	}[]
	onChange: (director: IOption[]) => void
	value: IOption[]
	placeholderButtonLabel: string
	placeholder: string
	isDisabled: boolean
}

export type OtherFilters = {
	key: FilterNameEnum
	options: {
		label: string
		options: IOption[]
	}[]
	onChange: (states: IOption[]) => void
	value: IOption[]
	placeholderButtonLabel: string
	placeholder: string
	isDisabled?: boolean
}

export interface FilterHierarchyTypeProps {
	isEnabled: boolean
	isLoading: boolean
	salesHierarchyFiltersProps: SalesHierarchyFilters[]
	otherFiltersProps: OtherFilters[]
	canFilter: boolean
	handleClearFields: () => void
	handleClickFilterListPdvs: () => Promise<void>
	setIsFilter: React.Dispatch<React.SetStateAction<boolean>>
	impactedPdvs?: number
	errorMessage?: string
	labelTitle?: string
}

export interface FilterSelectPdvTypeProps {
	isEnabled: boolean
	setSelectedPDVs: React.Dispatch<React.SetStateAction<IOption[]>>
	setCanFilter: (param: boolean) => void
	createPdvOption: (value: any) => void
	canFilter: boolean
	selectedPDVs: IOption[]
	handleClearFields: () => void
	setPushSelectedPDVs?: React.Dispatch<React.SetStateAction<string[]>>
	setCanSendPdv?: React.Dispatch<React.SetStateAction<boolean>>
	errorMessage?: string
	setFilterType?: (param: 'select-pdv' | 'filter-hierarchy') => void
	listImpactedPdvs?: string[]
	setListImpactedPdvs?: (param: string[]) => void
}

export interface useFilterHierarchyProps {
	onLoad: () => void
	onLoadEnd: () => void
	onFilterChange: (selectedPDVs: string[]) => void
	getFilterParameters: () => Promise<IFiltersHierarchy>
	handleGetFilterValues?: (filters: IFilterOptions) => void
	initialFilters?: IFilterParams[]
	setCanFilter: (param: boolean) => void
	onPushFilterChange?: (total: number) => void
	setCanSendPdv?: React.Dispatch<React.SetStateAction<boolean>>
	setPushSelectedPDVs?: React.Dispatch<React.SetStateAction<string[]>>
	shouldListPdv: boolean
	fetchListPDVsPush?: boolean
	listImpactedPdvs?: string[]
	setListImpactedPdvs?: (param: string[]) => void
}

export enum FilterNameEnum {
	DIRECTOR = 'DIRECTOR',
	GOV = 'GOV',
	RESALE = 'RESALE',
	STATE = 'STATE',
	CITY = 'CITY',
	CHANNEL = 'CHANNEL',
	PDV = 'PDV'
}

export interface IFilterListPdvs {
	ufs: string[]
	cities: string[]
	channels: string[]
	codeResales: string[]
	diretor: string[]
	GRC: string[]
	codePdv: string[]
	listPdvs: boolean
}

export interface IFilterParams {
	ruleID?: string
	paramName: FilterNameEnum
	paramValue: string
}

export interface IFilterSalesHierarchyDirector {
	director: string
	govs: IFilterSalesHierarchyGov[]
}

export interface IFilterSalesHierarchyGov {
	gov: string
	resales: IFilterSalesHierarchyResale[]
}

export interface IFilterSalesHierarchyResale {
	resale: string
	states: {
		cities: { city: string | null }[]
		state: string | null
	}[]
}

export interface IFiltersHierarchy {
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: ISelectDefaultInterface[]
	ufs: ISelectDefaultInterface[]
	cities: ISelectDefaultInterface[]
}

export interface ISelectDefaultInterface {
	label: string
	value: string
}

export interface IProps {
	challenge?: IChallenge
}

export interface IOption {
	value: string
	label: string
}

export type IFilterOptions = {
	[FilterNameEnum.DIRECTOR]: IOption[]
	[FilterNameEnum.GOV]: IOption[]
	[FilterNameEnum.RESALE]: IOption[]
	[FilterNameEnum.CHANNEL]: IOption[]
	[FilterNameEnum.STATE]: IOption[]
	[FilterNameEnum.CITY]: IOption[]
}

export interface IValuesByProps {
	filterSelectOptions: IFilterOptions
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: IOption[]
	selectedFilterSelectOptions?: IFilterOptions
}

export enum TypeDoc {
	TEXTCSV = 'text/csv'
}
export enum DocHeader {
	PDV = 'pdv'
}

export enum MaxSizeFile {
	CSV = 1024 * 1024 * 70
}

export interface IFiltersByHieararchyProps {
	onLoad: () => void
	onLoadEnd: () => void
	onFilterChange: (selectedPDVs: string[]) => void
	onPushFilterChange?: (total: number) => void
	getFilterParameters: () => Promise<IFiltersHierarchy>
	handleGetFilterValues?: (filters: IFilterOptions) => void
	setCanSendPdv?: React.Dispatch<React.SetStateAction<boolean>>
	setPushSelectedPDVs?: React.Dispatch<React.SetStateAction<string[]>>
	initialFilters?: IFilterParams[]
	isEnabled: boolean
	canFilter: boolean
	shouldListPdv?: boolean
	setCanFilter: (param: boolean) => void
	impactedPdvsLength?: number
	fetchListPDVsPush?: boolean
	errorMessage?: string
	setFilterType?: (param: 'select-pdv' | 'filter-hierarchy') => void
	listImpactedPdvs?: string[]
	setListImpactedPdvs?: (param: string[]) => void
	withoutPdv?: boolean
	labelTitle?: string
}
