import React, { useCallback, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'
import { useDispatch } from 'react-redux'

import { IChallengeItemProps } from './type'

import { parseItemPrice } from '../../../shared/utils/money'
import { ItemTypeEnum } from '../../../shared/utils/promotion'
import { getCharacteristicDescription } from '../../../shared/utils/material'
import { getLowerPrice, returnHighestPrice } from '../../../shared/utils/price'
import { Creators as CarChallengeItemsActions } from '../../../shared/store/ducks/cartChallengeItems'

import { extractCartItem } from '../../../shared/utils/cart'
import { CogoPositions } from '../../../shared/utils/toaster'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'

import RetornavelIcon from '../../../assets/images/retornavel.svg'
import ShoppingIconWhite from '../../../assets/images/shopping-cart-small-white.svg'
import ShoppingIconGreen from '../../../assets/images/shopping-cart-green.svg'

import ActionBarForAddingChallengeItemsToCart from '../ActionBarForAddingChallengeItemsToCart'

import * as S from './styles'
import { ICartItemTypeEnum } from '../../../shared/interfaces/cart'

export default function ChallengeItem(props: IChallengeItemProps) {
	const {
		challenge,
		productItem,
		isChallengeMix,
		addingItemToTheProductMix,
		allProductsThatArePartOfTheMix,
		userIsNotParticipatingInTheChallenge
	} = props

	const dispatch = useDispatch()

	const { cartChallengeItems } = useTypedSelector(['cartChallengeItems'])

	const minimumItemQuantity = challenge?.products?.find(
		(product) => product.productId === productItem.MaterialID
	)?.quantity

	const [quantitySelectedToAddInTheCart, setQuantitySelectedToAddInTheCart] =
		useState(Number(minimumItemQuantity))

	const quantityAlreadyPurchased =
		challenge?.products?.find(
			(product) => product.productId === productItem.MaterialID
		)?.quantityReached || 0

	const items = cartChallengeItems.find(
		(cartChallenge) => cartChallenge.challengeId === challenge.challengeId
	)?.addedProducts

	const productFoundInCart = items?.find(
		(cartItem) => cartItem.MaterialID === productItem.MaterialID
	)

	const productAddingInTheMix = allProductsThatArePartOfTheMix.find(
		(product) => product.productId === productItem.MaterialID
	)

	const itemType: ItemTypeEnum = !!productItem.priceAction?.length
		? ItemTypeEnum.PRICE_ACTION
		: ItemTypeEnum.NORMAL

	const minimumAmountToCompleteAChallenge = String(
		productItem.minimumAmountToCompleteAChallenge
	)

	const pricePerUnit = Number(productItem.PricePerUnit)
	const itemContainerType = productItem.typeOfContainer

	const packageType = getCharacteristicDescription(
		productItem.Characteristics,
		'PackageType'
	)

	const isReturnable = packageType === 'Retornável'

	const imageUrl =
		String(productItem.ImageUrl) || String(productItem.PackageImageUrl)
	const description = productItem.Description

	const arrayOfPrice = productItem.PriceByQuantity.map((item) =>
		Number(item.Price)
	) as number[]

	const hasActionPrice = itemType === ItemTypeEnum.PRICE_ACTION

	const minimumAmountReached =
		Number(productFoundInCart?.OrderQuantity) >=
		Number(minimumItemQuantity) - Number(quantityAlreadyPurchased)

	const minimumQuantityOfMixItemReached =
		Number(productAddingInTheMix?.quantity) >=
		Number(minimumAmountToCompleteAChallenge)

	const productAlreadyPurchased =
		Number(quantityAlreadyPurchased) >= Number(minimumItemQuantity)

	const handleAddItemOfTheChallengeMix = () => {
		addingItemToTheProductMix(productItem, quantitySelectedToAddInTheCart)
	}

	const addItemInTheCart = useCallback(() => {
		const itemWithQuantity = extractCartItem(
			productItem,
			quantitySelectedToAddInTheCart
		)

		if (itemWithQuantity.OrderQuantity > itemWithQuantity.StockPosition) {
			cogoToast.error(
				`Quantidade acima do estoque de ${itemWithQuantity.StockPosition}`,
				CogoPositions['top-right']
			)
			return
		}

		if (!quantitySelectedToAddInTheCart) {
			cogoToast.error(
				'Por favor especifique a quantidade',
				CogoPositions['top-right']
			)
			return
		}

		if (quantitySelectedToAddInTheCart && itemWithQuantity && challenge) {
			const itemToAdd = {
				...itemWithQuantity,
				typeItem: ICartItemTypeEnum.CHALLENGE_ITEM
			}
			dispatch(
				CarChallengeItemsActions.addChallengeItem(challenge, itemToAdd)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		productItem,
		quantitySelectedToAddInTheCart,
		minimumItemQuantity,
		challenge
	])

	const renderActionButtonsWrapper = () => {
		const disabledDecreaseButton =
			isChallengeMix &&
			!productFoundInCart &&
			Number(quantitySelectedToAddInTheCart) ===
				Number(minimumItemQuantity)
				? true
				: false

		const challengeFoundedInTheCart = cartChallengeItems.find(
			(cartChallenge) =>
				cartChallenge.challengeId === challenge.challengeId
		)

		const handleBarFn =
			isChallengeMix && !challengeFoundedInTheCart
				? handleAddItemOfTheChallengeMix
				: isChallengeMix && !!challengeFoundedInTheCart
				? addItemInTheCart
				: addItemInTheCart

		return (
			<S.ActionsButtonWrapper>
				<ActionBarForAddingChallengeItemsToCart
					disableDecreaseTheAmountOfChallengeItem={
						disabledDecreaseButton
					}
					disabled={!!userIsNotParticipatingInTheChallenge}
					actionToAddItemInTheCart={handleBarFn}
					setQuantitySelectedToAddInTheCart={
						setQuantitySelectedToAddInTheCart
					}
					quantitySelectedToAddInTheCart={
						quantitySelectedToAddInTheCart
					}
				/>
			</S.ActionsButtonWrapper>
		)
	}

	function handleQuantity() {
		if (productFoundInCart?.OrderQuantity) {
			setQuantitySelectedToAddInTheCart(
				Number(productFoundInCart?.OrderQuantity)
			)
			return
		}
		setQuantitySelectedToAddInTheCart(Number(minimumItemQuantity))
	}

	useEffect(handleQuantity, [
		productFoundInCart,
		minimumItemQuantity,
		cartChallengeItems
	])

	return (
		<S.ChallengeItemWrapper
			itsInTheCart={!!productFoundInCart || !!productAddingInTheMix}
			theMinimumAmountHasYetToBeReached={
				!!minimumAmountReached || !!minimumQuantityOfMixItemReached
			}>
			<S.AlertsAboutItem>
				<div className="returnable-badge">
					{isReturnable ? <img src={RetornavelIcon} alt="" /> : null}
				</div>
				{!!productFoundInCart || !!productAddingInTheMix ? (
					<S.BedgeCart
						itsInTheCart={
							!!productFoundInCart || !!productAddingInTheMix
						}
						theMinimumAmountHasYetToBeReached={
							!!minimumAmountReached ||
							!!minimumQuantityOfMixItemReached
						}>
						<img
							src={
								minimumAmountReached ||
								minimumQuantityOfMixItemReached
									? ShoppingIconGreen
									: ShoppingIconWhite
							}
							alt="ìcone de carrinho"
						/>
						<S.BedgeCartText
							itsInTheCart={
								!!productFoundInCart || !!productAddingInTheMix
							}
							theMinimumAmountHasYetToBeReached={
								!!minimumAmountReached ||
								!!minimumQuantityOfMixItemReached
							}>
							{productFoundInCart?.OrderQuantity ||
								productAddingInTheMix?.quantity}
						</S.BedgeCartText>
					</S.BedgeCart>
				) : null}
			</S.AlertsAboutItem>
			<S.ProductImageWrapper>
				<S.ProductImage src={imageUrl} alt={description} />
			</S.ProductImageWrapper>
			<S.ProductDescriptionWrapper>
				<S.ProductDescription>
					{productItem.Description}
				</S.ProductDescription>
			</S.ProductDescriptionWrapper>
			<S.ProductPriceWrapper>
				<S.LabelPrice>Por até</S.LabelPrice>
				<S.ProductPriceActionWrapper>
					{hasActionPrice && (
						<S.ProductOlderPrice>
							{parseItemPrice(
								returnHighestPrice(arrayOfPrice, productItem)
							)}
						</S.ProductOlderPrice>
					)}
					<S.PriceValue isActionPrice={hasActionPrice}>
						{parseItemPrice(
							hasActionPrice && productItem.priceAction
								? getLowerPrice(
										productItem.priceAction?.map(
											(priceAction) =>
												Number(priceAction.maxAmount)
										)
								  )
								: getLowerPrice(arrayOfPrice)
						)}
					</S.PriceValue>
				</S.ProductPriceActionWrapper>
			</S.ProductPriceWrapper>
			<S.UnitPriceWrapper>
				<S.UnitPriceLabel>Preço / {itemContainerType}</S.UnitPriceLabel>
				<S.unitPriceValue isActionPrice={hasActionPrice}>
					{parseItemPrice(pricePerUnit.toFixed(2))}
				</S.unitPriceValue>
			</S.UnitPriceWrapper>
			<S.BedgeMinimumQuantityWrapper
				itsInTheCart={!!productFoundInCart || !!productAddingInTheMix}
				theMinimumAmountHasYetToBeReached={
					!!minimumAmountReached || !!minimumQuantityOfMixItemReached
				}>
				<S.BedgeMinimumQuantityText
					itsInTheCart={
						!!productFoundInCart || !!productAddingInTheMix
					}
					theMinimumAmountHasYetToBeReached={
						!!minimumAmountReached ||
						!!minimumQuantityOfMixItemReached
					}>
					{productAlreadyPurchased
						? 'Quantidade minima já comprada'
						: 'Quantidade minima'}
				</S.BedgeMinimumQuantityText>
				<S.BedgeMinimumQuantityText
					itsInTheCart={
						!!productFoundInCart || !!productAddingInTheMix
					}
					theMinimumAmountHasYetToBeReached={
						!!minimumAmountReached ||
						!!minimumQuantityOfMixItemReached
					}>
					{minimumItemQuantity}
				</S.BedgeMinimumQuantityText>
			</S.BedgeMinimumQuantityWrapper>
			{!productAlreadyPurchased && renderActionButtonsWrapper()}
		</S.ChallengeItemWrapper>
	)
}
