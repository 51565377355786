import {
	styled,
	css,
	Select as MuiSelect,
	MenuItem,
	InputLabel
} from '@mui/material'

type SelectWrapperProps = {
	isLoading: boolean
}

type IStyledMenuItem = {
	isEven: boolean
}

export const InputLabelStyled = styled(InputLabel)(
	({ theme }) => css`
		color: ${theme.palette.primary.main};
	`
)

export const SelectWrapper = styled('div')(
	({ isLoading }: SelectWrapperProps) => css`
		.MuiSelect-select {
			visibility: ${isLoading ? 'hidden' : 'visible'};
		}
	`
)

export const Select = styled(MuiSelect)(
	({ theme }) => css`
		width: 100%;
		background-color: ${theme.palette.background.paper};

		&:hover .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.primary.main};
			border-width: 1px;
		}

		&.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.primary.main};
			border-width: 1px;
		}

		& .MuiOutlinedInput-input.Mui-disabled {
			cursor: not-allowed;
		}

		&.Mui-disabled {
			background-color: transparent;
			.MuiOutlinedInput-notchedOutline {
				border-color: ${theme.palette.lightGray.main};
				border-width: 1px;
			}
		}
	`
)

export const StyledMenuItem = styled(MenuItem)<IStyledMenuItem>`
	background: ${(props) =>
		props.isEven
			? props.theme.palette.primaryWhite.main
			: props.theme.palette.background.paper};

	&.Mui-selected {
		background-color: ${(props) =>
			props.isEven
				? props.theme.palette.primaryWhite.main
				: props.theme.palette.background.paper};
	}
`

export const StyledSelectedValues = styled('p')`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XSM}rem;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`
