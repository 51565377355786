import styled, { css } from 'styled-components'

export const Container = styled.div`
	${({ theme }) => css`
		max-width: ${theme.breakpoints.values.xl}px;
		margin: 0 auto;
		padding: ${theme.spacingOptions.SM}px ${theme.spacingOptions.LG}px;
		padding-top: 64px;
		display: flex;
		flex-direction: column;
		gap: ${theme.spacingOptions.LG}px;
	`}
`
