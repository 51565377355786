import React from 'react'
import { RouteTitle, RoutesSection } from '../admin-navbar.styles'
import { AccordionComponent } from '../../../../components/Accordion/accordion'
import { NavLinkDetails } from '../admin-navlink-details'
import { activeMenu } from '../util'
import { IRouteDataV2 } from '../../../Routes/types'
import { useLocation } from 'react-router-dom'

function RenderAccordion(title: string, routes: IRouteDataV2[]) {
	const location = useLocation()
	return (
		<RoutesSection>
			<AccordionComponent
				accordionSummary={
					<RouteTitle active={activeMenu(routes, location)}>
						{title}
					</RouteTitle>
				}
				accordionDetails={<NavLinkDetails routes={routes} />}
				isMenuAccordion={true}
				isExpanded={activeMenu(routes, location)}
			/>
		</RoutesSection>
	)
}

export default RenderAccordion
