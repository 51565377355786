import { Autocomplete, TextField } from '@mui/material'
import styled from 'styled-components'

export const AutocompleteStyled = styled(Autocomplete)`
	width: 185px;
	border-color: ${(props) => props.theme.palette.primary.main};
	input {
		color: #5d615d;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border: 1px solid ${(props) => props.theme.palette.primary.main};
	}
	.MuiAutocomplete-popupIndicator {
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const TextFieldStyled = styled(TextField)`
	margin: ${(props) => props.theme.spacingOptions.XSM}px;

	label {
		position: absolute;
		top: -10px;
		color: ${(props) => props.theme.palette.primary.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.bold};
	}
`
export const LabelText = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	margin-left: 8px;
`

export const BoxStyled = styled.li`

	svg{
		color: ${(props) => props.theme.palette.primary.main};
		height: 20px;
		width: 20px;
	}
`
