import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { IconButton, Typography } from '@material-ui/core'
import { ModalLayoutProps } from './layout-modal.types'
import * as S from './layout-modal.styles'
import ButtonDefault from '../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../components/buttons/button/button-default.types'
import Loading from '../../components/Loading'
import Close from '@mui/icons-material/Close'

const LayoutModal = ({
	isOpen,
	handleToggleModal,
	modalTitle,
	children,
	buttonText,
	buttonTextReturn = 'Voltar',
	height = '773px',
	width = '648px',
	typeLayout = 'default',
	handleClick,
	disabledButton = false,
	isLoading = false
}: ModalLayoutProps) => {
	return (
		<Modal open={isOpen} onClose={isLoading ? () => {} : handleToggleModal}>
			<>
				<S.WrapperLayoutModal
					style={{
						height: height,
						width: width,
						overflowY: 'auto'
					}}>
					<S.CustomCloseIcon onClick={() => handleToggleModal()}>
						<Close />
					</S.CustomCloseIcon>
					<Box className="header-modal">
						<Typography variant="h2">{modalTitle}</Typography>
					</Box>
					{isLoading ? (
						<div
							style={{
								height: '100%',
								width: '100%',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<Loading color="red" />
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								minHeight: '90%'
							}}>
							{children}
							<>
								{typeLayout === 'default' ? (
									<Box className="footer-modal">
										<ButtonDefault
											idElement="voltar-button"
											buttonText={buttonTextReturn}
											type={TypeButtonEnum.OUTLINED}
											onClick={handleToggleModal}
											width="46px"
										/>
										<ButtonDefault
											idElement="confirm-button"
											buttonText={buttonText}
											type={
												disabledButton
													? TypeButtonEnum.DISABLED
													: TypeButtonEnum.PRIMARY
											}
											onClick={handleClick}
											width={'200px'}
											height={'48px'}
										/>
									</Box>
								) : (
									<Box className="footer-modal-details">
										<ButtonDefault
											idElement="confirm-button"
											buttonText={buttonText}
											type={TypeButtonEnum.PRIMARY}
											onClick={handleClick}
											width={'200px'}
											height={'48px'}
										/>
									</Box>
								)}
							</>
						</div>
					)}
				</S.WrapperLayoutModal>
			</>
		</Modal>
	)
}

export default LayoutModal
