export interface IStatusIcon {
	ComponentIcon: React.FC<any>
	text: string
	type: TypeIconEnum
}

export enum TypeIconEnum {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error'
}

export interface StatusStyledIcon {
	type: TypeIconEnum
}
