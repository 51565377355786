import { Dispatch, SetStateAction } from "react"

export interface ICd {
	LegacyID?: string
	Name?: string
	salesOrganizationID: string
	UF?: string
}

export interface IMappedCdList extends ICd {
	label: string
}

export interface ISelectCD {
	cdList: IMappedCdList[]
	activeCd: string
	setActiveCd: Dispatch<SetStateAction<string>>
}

const configMenuProps={
	ITEM_HEIGHT: 48,
	ITEM_PADDING_TOP: 8
}
export const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: configMenuProps.ITEM_HEIGHT * 3 + configMenuProps.ITEM_PADDING_TOP,
			width: 250,
		},
	},
};