import { theme } from "../../../../../theme/themeProvider";

export function changeColorByStatus(status: string): string {
  const lowerCasedStatus = status?.toLowerCase();

  const statusColorMap: Record<string, string> = {
    'reprovado': theme.palette.error.main,
    'entregue': theme.palette.success.main,
    'devolvido': theme.palette.gray.main,
  };

  const matchingStatus = Object.keys(statusColorMap).find(key =>
    lowerCasedStatus?.includes(key?.toLowerCase())
  );

  return matchingStatus ? statusColorMap[matchingStatus] : theme.palette.black.main;
}

export const setOrderSource = (orderSource: string): string => {
  const sourceMap: { [key: string]: string } = {
      'VB2A': 'APP',
      'VAPP': 'VENDEDOR',
      'VB2B': 'PORTAL',
  };
  return sourceMap[orderSource] || '';
}