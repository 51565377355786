import styled from "styled-components";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AccordionContainer = styled.div`
	.MuiAccordionDetails-root {
		padding: 0;
	}
`

export const AccordionArrowIcon = styled(ExpandMoreIcon)`
  color: ${(props) => props.theme.palette.primary.main};
`