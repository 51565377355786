import * as React from 'react'
import { ToggleProps } from './toggle.types'
import { StyleToggle } from './toggle.styles'

export default function Toggle({ isChecked, handleChecked }: ToggleProps) {
	return (
		<div>
			<StyleToggle
				checked={isChecked}
				onChange={handleChecked}
				inputProps={{ 'aria-label': 'controlled' }}
			/>
		</div>
	)
}
