import React from 'react'
import { IViewProps, PaymentOptions } from './types'
import CreditCardIcon from '../../../assets/images/credit.svg'
import ArrowRight from '../../../assets/images/arrow-right-red.svg'
import TruckDeliveryIcon from '../../../assets/images/truck-delivery.svg'
import PixIcon from '../../../assets/images/pix.svg'
import PixImage from '../../../assets/images/pix-logo.svg'
import { InputField, SelectPrimary } from '../../../shared/components'
import { OrderSummaryPaymentInfo } from '../../../components/OrderSummaryPaymentInfo'
import { toMoney } from '../../../shared/utils/money'
import { getConditionLabel } from '../../../shared/utils/payment'
import RadioOff from '../../../assets/images/radio-fill-disabled.svg'
import RadioOn from '../../../assets/images/radio-fill-green.svg'
import { formatCnpjCpf } from '../../../shared/utils/form'

import { cc_format, creditCardType } from '../util'
import { IFormattedPaymentCondition } from '../../../shared/interfaces/customer'
import { PaymentForms } from '../../../shared/interfaces/Payments'
import { selectFlag } from '../../../shared/utils/selectBankFlag'

function Payment(props: IViewProps): JSX.Element {
	const {
		handleChangePaymentCondition,
		handleContinue,
		handlerSelectPaymentMethod,
		totalPrice,
		paymentStep,
		condition,
		buttons,
		handlerChangeStep,
		paymentMethod,
		handleChangeCreditCard,
		creditCard,
		fieldsErros,
		enableButton
	} = props

	const {
		cardNumberValueError,
		cardSecurityCodeValueError,
		namePrintedOnCardValueError,
		validityCreditCardValueError,
		documentNumberError
	} = fieldsErros

	function renderOption() {
		return [
			{
				value: `1x de ${toMoney(totalPrice)}`,
				label: `1x de ${toMoney(totalPrice)}`
			}
		]
	}

	function renderPaymentMethod(
		onClick: () => void,
		title: string,
		img: string
	) {
		return (
			<div className="row header-payment-option">
				<div className="col-12" onClick={onClick}>
					<h4>{title}</h4>
					<img src={img} alt={title} />
				</div>
			</div>
		)
	}

	function onDelivery(cond: IFormattedPaymentCondition) {
		handleChangePaymentCondition(cond)
		handlerChangeStep(2)
	}

	function onPix() {
		console.trace()
		const cond = {
			PaymentMethod: PaymentForms.P,
			PaymentMethodDescription: 'pix',
			PaymentTerms: 'R001',
			PaymentTermsDescription: ''
		}

		handleChangePaymentCondition(cond)
		handlerSelectPaymentMethod(
			[PaymentOptions.PAY_NOW_ONLINE, PaymentOptions.PAY_WITH_PIX],
			true
		)
	}

	function onCreditCard() {
		const cond = {
			PaymentMethod: PaymentForms.X,
			PaymentMethodDescription: 'creditCard',
			PaymentTerms: 'R001',
			PaymentTermsDescription: ''
		}

		handleChangePaymentCondition(cond)
		handlerSelectPaymentMethod(
			[
				PaymentOptions.PAY_NOW_ONLINE,
				PaymentOptions.PAY_WITH_CREDIT_CARD
			],
			false
		)
	}

	function renderConditionsPayment() {
		return condition.map((cond, index) => (
			<div className="container payment-credit-card" key={index}>
				<div
					className="row header-payment-option"
					onClick={() => onDelivery(cond)}>
					<div className="col-12">
						<h4>
							{getConditionLabel(
								cond.PaymentMethodDescription,
								cond.PaymentTermsDescription
							).toLowerCase()}
						</h4>
						<img src={ArrowRight} alt="continuar" />
					</div>
				</div>
			</div>
		))
	}

	return (
		<div
			className={`container payment-step-1 ${
				!paymentStep?.active ? 'hide-block' : ''
			}`}>
			<div className="row">
				<div className="col-lg-8">
					<h3>Selecione a Forma de Pagamento</h3>
					<div
						style={!paymentMethod.length ? { display: 'none' } : {}}
						className={`pay-option ${
							paymentStep?.options?.find((x) => {
								return x.index === PaymentOptions.PAY_NOW_ONLINE
							})?.active
								? 'active'
								: ''
						}`}>
						<div
							className="payment-credit-card-active"
							onClick={() =>
								handlerSelectPaymentMethod(
									[PaymentOptions.PAY_NOW_ONLINE],
									false
								)
							}>
							<div>
								{paymentStep?.options?.find(
									(x) =>
										x.index ===
										PaymentOptions.PAY_NOW_ONLINE
								)?.active ? (
									<img
										src={RadioOn}
										alt="Input radio off"
										className="radio-on"
									/>
								) : (
									<img src={RadioOff} alt="Input radio off" />
								)}
								<label
									htmlFor="pay-option-online"
									className="pay-option-online">
									Pagar agora (Online)
								</label>
							</div>
						</div>
						<hr className="payment-separator" />
						<div className={`container payment-credit-card`}>
							{renderPaymentMethod(
								() => onCreditCard(),
								'Cartão de Crédito',
								CreditCardIcon
							)}

							<div
								className={`flex gap ${
									!paymentStep?.options?.find(
										(x) =>
											x.index ===
											PaymentOptions.PAY_WITH_CREDIT_CARD
									)?.active
										? 'hide-block'
										: ''
								}`}>
								<div style={{ width: 'calc(50% - 16px)' }}>
									<InputField
										disabled={false}
										id="credit-card-name"
										secondary
										label="Nome impresso no cartão"
										value={creditCard.ownerName}
										onChange={(e) =>
											handleChangeCreditCard(
												'ownerName',
												e.target.value
											)
										}
										hasError={!!namePrintedOnCardValueError}
										errorMessage={
											namePrintedOnCardValueError
										}
										labelColor={
											creditCard.ownerName ? 'label' : ''
										}
									/>
								</div>
								<div
									style={{ width: '50%' }}
									className="creditCard">
									<InputField
										maxLength={20}
										disabled={false}
										id="credit-card-number"
										secondary
										label="Número do cartão"
										value={cc_format(creditCard.cardNumber)}
										onChange={(e) =>
											handleChangeCreditCard(
												'cardNumber',
												e.target.value
											)
										}
										labelColor={
											creditCard.cardNumber ? 'label' : ''
										}
										hasError={!!cardNumberValueError}
										errorMessage={cardNumberValueError}
									/>
									<img
										src={selectFlag(
											creditCardType(
												creditCard.cardNumber
											)
										)}
										alt=""
										className="icon"
									/>
								</div>

								<div style={{ width: 'calc(50% - 16px)' }}>
									<InputField
										disabled={false}
										id="cpf"
										secondary
										label="Número do CPF"
										value={
											creditCard?.cpf
												? formatCnpjCpf(creditCard.cpf)
												: ''
										}
										maxLength={14}
										errorMessage={documentNumberError}
										hasError={!!documentNumberError}
										onChange={(e) =>
											handleChangeCreditCard(
												'cpf',
												e.target.value
											)
										}
										labelColor={
											creditCard.cpf ? 'label' : ''
										}
									/>
								</div>
								<div style={{ width: 'calc(50% - 16px)' }} />
								<div style={{ width: 'calc(40% - 16px)' }}>
									<InputField
										maxLength={5}
										disabled={false}
										id="credit-card-expiration"
										secondary
										label="Validade (MM/AA)"
										value={creditCard.dueDate}
										onChange={(e) =>
											handleChangeCreditCard(
												'dueDate',
												e.target.value
											)
										}
										errorMessage={
											validityCreditCardValueError
										}
										hasError={
											!!validityCreditCardValueError
										}
										labelColor={
											creditCard.dueDate ? 'label' : ''
										}
									/>
								</div>
								<div style={{ width: '20%' }}>
									<InputField
										maxLength={4}
										disabled={false}
										id="credit-card-cvv"
										secondary
										label="CVV"
										value={creditCard.cvv}
										onChange={(e) =>
											handleChangeCreditCard(
												'cvv',
												e.target.value
											)
										}
										errorMessage={
											cardSecurityCodeValueError
										}
										hasError={!!cardSecurityCodeValueError}
										labelColor={
											creditCard.cvv ? 'label' : ''
										}
									/>
								</div>
								<div style={{ width: 'calc(40% - 16px)' }}>
									<SelectPrimary
										options={renderOption()}
										onChange={() =>
											handleChangeCreditCard(
												'installmentCount',
												'1'
											)
										}
										value={
											creditCard?.installmentCount
												? `${
														creditCard?.installmentCount
												  }x de ${toMoney(totalPrice)}`
												: ''
										}
										isLoading={false}
										isClearable={false}
										placeholder="Selecione as Parcelas"
										classNamePrefix="react-select"
										className={`react-select select-group`}
									/>
								</div>
							</div>
						</div>

						<div className="container payment-pix">
							{renderPaymentMethod(
								() => onPix(),
								'Pagar pelo Pix',
								PixIcon
							)}

							<div className="row content-payment-option">
								<div
									className={`col-lg-6 ${
										!paymentStep?.options?.find(
											(x) =>
												x.index ===
												PaymentOptions.PAY_WITH_PIX
										)?.active
											? 'hide-block'
											: ''
									}`}>
									<img src={PixImage} alt="Logo Pix" />
								</div>
								<div
									className={`col-lg-6 ${
										!paymentStep?.options?.find(
											(x) =>
												x.index ===
												PaymentOptions.PAY_WITH_PIX
										)?.active
											? 'hide-block'
											: ''
									}`}>
									<ul>
										<li>
											<i>1</i>
											<p>
												Clique em Finalizar Compra para
												gerar o Código de Pagamento
											</p>
										</li>
										<li>
											<i>2</i>
											<p>
												Abra o aplicativo do seu banco e
												selecione a opção Pix
											</p>
										</li>
										<li>
											<i>3</i>
											<p>
												Aponte a câmera para o QR Code
												ou copie e cole o Còdigo
											</p>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div
						className={`pay-option ${
							paymentStep?.options?.find(
								(x) =>
									x.index === PaymentOptions.PAY_ON_DELIVERY
							)?.active
								? 'active'
								: ''
						}`}>
						<div
							className="payment-on-delivery-active"
							onClick={() =>
								handlerSelectPaymentMethod(
									[PaymentOptions.PAY_ON_DELIVERY],
									false
								)
							}>
							{/* <input
								type="radio"
								id="pay-option-on-delivery"
								name="pay-option"
								checked={
									paymentStep?.options?.find(
										(x) =>
											x.index ==
											PaymentOptions.PAY_ON_DELIVERY
									)?.active
								}
							/> */}

							<div>
								{paymentStep?.options?.find(
									(x) =>
										x.index ===
										PaymentOptions.PAY_ON_DELIVERY
								)?.active ? (
									<img
										src={RadioOn}
										alt="Input radio on"
										className="radio-on"
									/>
								) : (
									<img src={RadioOff} alt="Input radio off" />
								)}
								<label
									htmlFor="pay-option-on-delivery"
									className="pay-option-online">
									Pagar na Entrega
								</label>
							</div>
							<img
								src={TruckDeliveryIcon}
								alt="Pagar na Entrega"
							/>
						</div>
						<hr className="payment-separator" />

						{renderConditionsPayment()}
					</div>
				</div>
				<div className="col-lg-4">
					<h3>Resumo do Pedido</h3>
					<OrderSummaryPaymentInfo
						canProceed={buttons.confirm || enableButton}
						handleProceed={handleContinue}
						insufficientPoints={false}
						showAlert={false}
						totalPrice={totalPrice}
						totalPriceLabel={toMoney(totalPrice)}
					/>
				</div>
			</div>
		</div>
	)
}

export default Payment
