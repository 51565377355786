import React, { useEffect, useState } from 'react'
import AlertIcon from '../../assets/images/alert.svg'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { IStoreCart } from '../../shared/interfaces/store'
import { parseItemPrice } from '../../shared/utils/money'
import { getMinPriceFromParams } from '../../shared/utils/param'
import { calcTotalPoints } from '../../shared/utils/points'
import { getFullPrice } from '../../shared/utils/price'
import CardAlertBlockedPoints from '../CardAlertBlockedPoints'
import InsufficientPointsAlert from '../InsufficientPointsAlert'

import { useTheme } from 'styled-components'

export interface IProps {
	insufficientPoints: boolean
	totalPriceLabel: string
	showAlert: boolean
	canProceed: boolean
	handleProceed: () => void
	totalPrice: number
	title?: string
	col?: string
}

export function OrderSummaryPaymentInfo(props: IProps): JSX.Element {
	const {
		insufficientPoints,
		totalPriceLabel,
		showAlert,
		canProceed,
		handleProceed,
		totalPrice,
		title,
		col
	} = props
	const {
		carts,
		pointsProgramStatus,
		cartRedemptionItems,
		companyParams,
		cartChallengeItems
	} = useTypedSelector([
		'carts',
		'pointsProgramStatus',
		'cartRedemptionItems',
		'companyParams',
		'cartChallengeItems'
	])
	const theme = useTheme()
	const items = carts.map((cart) => cart.items).flat()
	const itemsChallenge = cartChallengeItems
		.map((item) => item.addedProducts)
		.flat()

	const [totalPointsRedeemedState, setTotalPointsRedeemedState] = useState(0)
	const [cartProducts, setCarProducts] = useState<ICartItem[]>([])

	const totalPointsTheProductGives: number = totalPoints(carts)

	const calculateCartDiscount = (items: ICartItem[]): number => {
		return items.reduce(
			(accDiscount, item) => accDiscount + calculateItemDiscount(item),
			0
		)
	}

	const discountOfTheNormalItems = calculateCartDiscount(items)
	const discountItemsChallenge = calculateCartDiscount(itemsChallenge)

	const cartDiscount = discountOfTheNormalItems + discountItemsChallenge
	const numberValue = getMinPriceFromParams(companyParams)

	const minValueLabel = parseItemPrice(numberValue)

	function settingTotalPointsToBeRedeemed() {
		const totalPoints = cartProducts
			.filter(
				(item) =>
					item.typeItem === ICartItemTypeEnum.REDEMPTION &&
					!!item.rescueProduct
			)
			.reduce((acc, item) => {
				return (acc += Number(item.pointsRedeemed))
			}, 0)
		setTotalPointsRedeemedState(totalPoints)
	}

	function totalPoints(carts: IStoreCart[]) {
		let total = 0
		if (carts && carts.length) {
			carts.forEach((cart) => {
				total += cart.items.reduce(
					(acc, item) =>
						item.ScoreValue
							? acc + calcTotalPoints(item.Price, item.ScoreValue)
							: acc,
					0
				)
			})
		}

		return total
	}
	function calculateItemDiscount(item: ICartItem): number {
		const priceWithoutDiscount =
			item.promotionType !== 2 && item.products?.length
				? getFullPrice(item.products) * item.OrderQuantity
				: item.promotionType === 2 && item.PromotionOlderPrice
				? item.PromotionOlderPrice * item.OrderQuantity
				: item.Price

		return priceWithoutDiscount - item.Price
	}

	useEffect(() => {
		setCarProducts([...items, ...cartRedemptionItems])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [carts, cartRedemptionItems])
	useEffect(settingTotalPointsToBeRedeemed, [cartProducts])

	const priceWithoutDiscount =
		Number(
			totalPriceLabel
				.replace('R$', '')
				.replace(/\./g, '')
				.replace(',', '.')
				.trim()
		) + Math.abs(cartDiscount)

	return (
		<div className={col}>
			<div className="totals">
				{title && <h4 className="totals-title">{title}</h4>}

				<div className="totals-item">
					<p className="totals-description">{'Produtos'}</p>
					<p className="totals-value">
						{items.length +
							itemsChallenge.length +
							cartRedemptionItems.length}
					</p>
				</div>
				<div className="totals-item">
					<p className="totals-description">Total em produtos</p>
					<p className="total-without-discount">
						{parseItemPrice(priceWithoutDiscount)}
					</p>
				</div>
				<div className="totals-item">
					<p className="totals-description">{'Desconto'}</p>
					<p className="totals-discount">
						-{parseItemPrice(cartDiscount).replace('-', '')}
					</p>
				</div>
				{pointsProgramStatus.userJoinedTheProgram &&
				totalPointsTheProductGives ? (
					<div className="totals-item">
						<p className="totals-description">
							{'Total de Pontos Ganhos nesta Compra'}
						</p>
						<p className="totals-value">
							+{totalPointsTheProductGives} Pontos
						</p>
					</div>
				) : null}
				{totalPointsRedeemedState > 0 && (
					<div className="totals-item">
						<p className="totals-description">
							{'Pontos Resgatados'}
						</p>
						{/* @ts-ignore */}
						<p
							style={{ color: theme.palette.lightGray.main }}
							className="totals-value">
							-{totalPointsRedeemedState}{' '}
							{insufficientPoints && (
								<img src={AlertIcon} alt="Icone de alerta" />
							)}
						</p>
					</div>
				)}
				<div className="totals-item">
					<p className="total-emphazis">{'Valor Total'}</p>
					<p className="total-emphazis">{totalPriceLabel}</p>
				</div>

				{insufficientPoints ? (
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}>
						{/* @ts-ignore */}
						<InsufficientPointsAlert
							cartScreen
							background={theme.palette.white.main}
						/>
					</div>
				) : null}
				<button
					disabled={!canProceed || insufficientPoints}
					onClick={handleProceed}
					className="button">
					{'Finalizar Compra'}
				</button>

				{showAlert ? (
					<span className="error-message">
						Por favor, selecione que está ciente da devolução dos
						vasilhames.
					</span>
				) : null}
			</div>

			{!!totalPointsTheProductGives && (
				<CardAlertBlockedPoints
					visible={!pointsProgramStatus.userJoinedTheProgram}
					totalPoints={totalPointsTheProductGives}
				/>
			)}
			{numberValue > totalPrice ? (
				<div className="min-order">
					<p className="min-order-subtitle">
						{'Valor mínimo não foi atingido'}
					</p>

					<p className="min-order-text">
						{`Sua compra precisa atingir o valor mínimo de `}
					</p>

					<p className="min-order-text">{`${minValueLabel}`}</p>
				</div>
			) : null}
		</div>
	)
}
