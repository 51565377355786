import { IProductResponseProps } from '../types/products.types'

class ProductsMapper {
	toDomainProducts(data: IProductResponseProps[], categoriesID: string[]) {
		const categorizedProducts: { [key: string]: IProductResponseProps[] } =
			{}

		data.forEach((product) => {
			if (!categorizedProducts[product.CategoryID]) {
				categorizedProducts[product.CategoryID] = []
			}
			categorizedProducts[product.CategoryID].push(product)
		})

		const formattedProducts = categoriesID.map((categoryID) => {
			const products = categorizedProducts[categoryID] || []
			const categoryName =
				categorizedProducts[categoryID]?.[0]?.Category || 'NO CATEGORY'

			const options = products.map((product) => ({
				categoryID: product.Category,
				label: product.Description,
				value: product.MaterialID,
				package: product.Package,
				brand: product.Brand
			}))

			return {
				label: categoryName,
				options
			}
		})

		return {
			data: formattedProducts,
			size: data.length
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ProductsMapper()
