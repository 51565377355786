import axios from 'axios'
import cogoToast from 'cogo-toast'
import { pick, prop } from 'ramda'
import { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBoolean } from '../../shared/hooks/useBoolean'
import { createGuard } from '../../shared/utils/error'
import { isFormValid } from '../../shared/utils/form'
import { isPropEqual } from '../../shared/utils/object'
import { pipe } from '../../shared/utils/operators'
import cogoDefaultOptions from '../../shared/utils/toaster'
import {
	SPECIAL_CHAR_AND_WHITESPACE,
	checkCPF,
	checkEmail,
	checkEmailMatch,
	checkRG
} from '../../shared/utils/validators'
import handleError from '../Error/handleError'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { DEFAULT_CLIENT, alternativePersonalInfo, getFields } from './data'
import { getAddress } from './service'
import {
	INewClient,
	IOption,
	IProps,
	IRegion,
	IViaCepReturn,
	IViewProps
} from './types'
import NewClientForm from './view'
import { SelectorOption } from '../../components/data-selector/data-selector.types'
import { checkDocumentType } from '../../utils/check-document-type'

function NewClientFormContainer({
	documentNumber,
	returnValues,
	newCustomer,
}: IProps): JSX.Element {
	const history = useHistory()

	const fieldEnum: { [id: string]: string } = {
		CPF: 'PersonalFiscalID',
		CNPJ: 'CompanyFiscalID'
	}

	const documentType = checkDocumentType(documentNumber)
	const field = fieldEnum[documentType]

	const [loading, stopLoading] = useBoolean(false)

	const [client, setClient] = useState(
		newCustomer
			? newCustomer
			: {
					...DEFAULT_CLIENT,
					[field]: documentNumber
			  }
	)

	const [regionOptions, setRegionOptions] = useState<Array<IOption>>([])
	const [cityOptions, setCityOptions] = useState<Array<IOption>>([])
	const [filledAddress, setFilled, removeFilled] = useBoolean(false)

	const [personalFields, storeFields] = getFields(client, documentType)
	const alternativePersonalInfoFields = alternativePersonalInfo(client)
	const [workingPeriod, setWorkingPeriod] = useState('')
	const [gpVisit, setGpVisit] = useState('')

	const allFields = personalFields.concat(storeFields)

	const canProceed = isFormValid(allFields, client)

	const errorHandler = pipe(handleError, stopLoading)

	const guarded = createGuard(errorHandler)

	const [captchaIsValid, setCaptcha] = useState(false)

	const [selectedEstabilishmentOption, setSelectedEstabilishmentOption] =
		useState<SelectorOption | null>(
			null
		)

	function handleRecaptcha(value: string | null): void {
		setCaptcha(value !== null)
	}

	function handleWorkingPeriod(period: string) {
		setWorkingPeriod(period)
	}

	function handleGpVisit(method: string) {
		const isRequired = method === 'sim' ? true : false
		const updateTradeRepresentative = { 'tradeRepresentative': isRequired }
		setGpVisit(method)
		setClient((c) => Object.assign({}, c, updateTradeRepresentative))
	}

	function handleEstabilishmentOption(selectedValue: SelectorOption | null) {
		const updateEstabilishment = { 'typeOfEstabilishment': selectedValue?.value }
		setSelectedEstabilishmentOption({
			label: selectedValue?.label ?? '',
			value: selectedValue?.value ?? ''
		})
		setClient((c) => Object.assign({}, c, updateEstabilishment))
	}
	
	function initStates() {
		let url = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/'

		const init = guarded(async () => {
			try {
				const res = await axios.get(url)

				const getRegion = pick(['id', 'sigla'])

				const states: Array<IRegion> = res.data.map(getRegion)

				const fmt = ({ sigla, ...rest }: IRegion) => ({
					...rest,
					label: sigla,
					value: sigla
				})

				setRegionOptions(states.map(fmt))
			} catch (err) {
				cogoToast.error(
					'Erro ao listar os estados do Brasil.',
					cogoDefaultOptions
				)
			}
		})
		init()
	}

	function initCities() {
		const stateId = (client?.Region as IOption)?.id

		if (!stateId) return

		let url = `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateId}/municipios`

		const init = guarded(async () => {
			try {
				const res = await axios.get(url)

				const cities: Array<string> = res.data.map(prop('nome'))

				const fmt = (value: string) => ({ label: value, value })

				setCityOptions(cities.map(fmt))
			} catch (err) {
				cogoToast.error(
					'Erro ao listar as cidades deste estado.',
					cogoDefaultOptions
				)
			}
		})

		init()
	}

	function fetchAddressFromCEP() {
		if (!client?.PostalCode) return

		const { PostalCode } = client

		const cep = PostalCode.replace(SPECIAL_CHAR_AND_WHITESPACE, '')

		if (cep.length !== 8) return

		const callService = async () => {
			const address = await getAddress(cep)

			if (!address) return

			autoFillAddress(address)
		}

		const safe = guarded(callService)

		safe()
	}

	function removeFilledAddress() {
		if (!client.PostalCode) {
			removeFilled()
			setCityOptions([])
		}
	}

	function autoFillAddress(address: IViaCepReturn) {
		const { bairro, complemento, localidade, logradouro, uf } = address

		const regionOption = regionOptions.find(isPropEqual('label')(uf))

		const updateClient = (client: INewClient): INewClient => ({
			...client,
			Neighborhood: bairro || client.Neighborhood,
			City: localidade || client.City,
			Street: logradouro || client.Street,
			AdressComplement: complemento || client.AdressComplement,
			Region: regionOption || client.Region,
		})

		setClient(updateClient)
		if (localidade && uf) {
			setFilled()
			return
		}

		removeFilled()
	}

	function handleUpdateClient(keyName: string) {
		return function (e: { target: { value: any } }) {
			const update = { [keyName]: e.target.value }

			setClient((c) => Object.assign({}, c, update))
		}
	}

	function handleSelect(keyName: string) {
		return function (value: string) {
			const update = { [keyName]: value }

			if (keyName === 'Region') {
				setClient((c) => Object.assign({}, c, update, { City: '' }))
				return
			}

			setClient((c) => Object.assign({}, c, update))
		}
	}

	function goToUploadDocuments() {
		returnValues(client, workingPeriod)
		// setGoToStepSendDocument(true)
	}

	function handlePasswordNav() {
		history.replace(UnauthenticatedRoutesEnum.CHANGE_PERSONAL_PASSWORD)
	}

	function updateClientDocumentNumber() {
		const documentType = checkDocumentType(documentNumber)
		const field = fieldEnum[documentType]

		setClient((client) => ({
			...client,
			[fieldEnum.CPF]: '',
			[fieldEnum.CNPJ]: '',
			[field]: documentNumber
		}))
	}

	function showError(hasError?: boolean) {
		const doc = document.getElementById('form-error')
		if (!doc) return

		if (hasError) {
			doc.style.display = 'block'
		} else {
			if (
				(client.Email && !checkEmail(client.Email)) ||
				(client.ConfirmEmail &&
					!checkEmailMatch(client.ConfirmEmail, client.Email)) ||
				(client.IdentificationRegistryID &&
					!checkRG(client.IdentificationRegistryID)) ||
				(client.PersonalFiscalID && !checkCPF(client.PersonalFiscalID))
			) {
				doc.style.display = 'block'
				return
			}

			doc.style.display = 'none'
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initStates, [])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchAddressFromCEP, [client.PostalCode])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(removeFilledAddress, [client.PostalCode])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(initCities, [(client?.Region as IOption)?.id])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(updateClientDocumentNumber, [documentNumber])

	const viewProps: IViewProps = {
		personalFields,
		storeFields,
		handlePasswordNav,
		handleUpdateClient,
		loading,
		canProceed,
		stateOptions: regionOptions,
		cityOptions,
		handleSelect,
		filledAddress,
		handleRecaptcha,
		captchaIsValid,
		documentType,
		workingPeriod,
		handleWorkingPeriod,
		goToUploadDocuments,
		showError,
		alternativePersonalInfoFields,
		handleRequiresGpVisit: handleGpVisit,
		requiresGpVisit: gpVisit,
		selectedEstabilishmentOption,
		handleEstabilishmentOption
	}

	return createElement(NewClientForm, viewProps)
}

export default NewClientFormContainer
