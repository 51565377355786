import styled from 'styled-components'
import { transition } from '../../../styles/functions'
import { colors } from '../../../styles/theme'
import PointsHeaderImage from '../../../../assets/images/banner_modal-portalx2.jpg'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const Container = styled.div`
	/* background-color: ${colors.pointsProgramBgColor}; */
	background-image: url(${PointsHeaderImage});
	background-repeat: no-repeat;
	width: 536px;
	position: relative;
	height: 465px;
	background-size: cover;
	border-radius: 4px;
	max-width: 536px;
	position: relative;
`

export const CloseIcon = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	padding: 24px;

	cursor: pointer;
	background: none;
	border: none;
`

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 56px 40px 48px;
`

export const Title = styled.h1`
	color: ${colorThemeProvider.whiteColor};
	text-align: center;
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	line-height: ${(props) => props.theme.font.size.DISPLAY_XS.line_height}rem;
`

export const TitleStrong = styled(Title)`
	font-weight: ${(props) => props.theme.font.weight.medium};
`

export const Paragraph = styled.p`
	margin-top: 24px;
	color: ${(props) => props.theme.palette.extras.stroke.main};
	text-align: center;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
`

export const WhiteColor = styled.span`
	font-weight: ${(props) => props.theme.font.weight.bold};
	color: ${(props) => props.theme.palette.white.main};
`

export const Button = styled.button`
	margin-top: 40px;
	border: none;
	background: ${(props) => props.theme.palette.white.main};
	color: ${(props) => props.theme.palette.primary.main};
	padding: 12px 32px;
	border-radius: 4px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
	cursor: pointer;
	${transition()}

	&:hover {
		opacity: 0.7;
	}
`
