import { IFiltersHierarchy } from "../typings"

export function createCityStateMap(data: IFiltersHierarchy) {
	const cityStateMap: { [key: string]: string } = {}
	data.salesHierarchy.forEach((item) => {
		item.govs.forEach((govInfo) => {
			govInfo.resales.forEach((resaleInfo) => {
				resaleInfo.states.forEach((stateInfo) => {
					stateInfo.cities.forEach(({ city }) => {
						const state = stateInfo.state
						if (!city || !state) return
						cityStateMap[city] = state
					})
				})
			})
		})
	})

	return cityStateMap
}
