import React from 'react'
// import SearchIcon from '../../assets/images/search-red.svg'

import styles from './styles.module.scss'
import { IProps } from './types'
import SearchIcon from '@mui/icons-material/Search'
import { colorThemeProvider } from '../../theme/themeProvider'

export default function SearchInput({
	inputValue,
	disabled = false,
	width,
	handleChange,
	handleClickSearch,
	placeholder = 'Buscar'
}: IProps) {
	return (
		<div
			className={styles['input-search-customer']}
			style={{
				width: width
			}}>
			<div className={styles['search-input']}>
				<input
					required
					value={inputValue}
					id="search-input-id"
					disabled={disabled}
					onChange={handleChange}
				/>
				<label htmlFor="search-input-id">{placeholder}</label>
			</div>

			{/* <img src={SearchIcon} alt="Ícone de lupa" /> */}
			<div
				style={{
					backgroundColor: colorThemeProvider.whiteColor,
					padding: '16px',
					display: 'flex'
				}}>
				<SearchIcon
					style={{ color: colorThemeProvider.primary.main }}
					onClick={handleClickSearch}
				/>
			</div>
		</div>
	)
}
