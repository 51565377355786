import styled, { css } from 'styled-components'
import Xsvg from '../../../../../assets/images/x.svg'
import { colorThemeProvider } from '../../../../../theme/themeProvider'
import { transition } from '../../../../styles/functions'
import { colors } from '../../../../styles/theme'

export const Container = styled.div`
	padding: 40px 32px;
	display: flex;
	flex-direction: column;

	&:-webkit-scrollbar-track {
		background: ${(props) => props.theme.palette.primary.main};
	}
`

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;

	h1 {
		font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
		color: ${colorThemeProvider.grayColor};
	}
`

export const ButtonClose = styled.button`
	cursor: pointer;
	background: none;
	border: none;
`

export const XIcon = styled.img.attrs(() => ({
	src: Xsvg,
	alt: 'Botão Fechar',
	className: 'close-icon'
}))``

export const ThermsBox = styled.div`
	background-color: ${colorThemeProvider.whiteColor};
	border-radius: 4px;
	padding: 32px 10px 10px;
	margin-bottom: 42px;
`

export const ThermsBoxContent = styled.div`
	overflow-y: scroll;
	max-width: 698px;
	max-height: 328px;
	padding: 0 16px;

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: red;
	}

	h2 {
		font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
		color: ${colorThemeProvider.grayColor};
		font-weight: ${(props) => props.theme.font.weight.medium};
	}

	p {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colorThemeProvider.grayColor};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		margin-top: 32px;
	}
`

export const AcceptTermsInput = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;

	border: none;
	background: none;

	&:hover {
		cursor: pointer;
	}

	label {
		margin-left: 8px;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colorThemeProvider.grayColor};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;

		span {
			font-weight: ${(props) => props.theme.font.weight.medium};
		}

		&:hover {
			cursor: pointer;
		}
	}
`

export const Button = styled.button<{ loading?: boolean }>`
	align-self: center;
	margin-top: 40px;
	border: none;
	background: ${(props) => props.theme.palette.success.main};
	color: ${(props) => props.theme.palette.white.main};
	padding: 12px 32px;
	border-radius: 4px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
	cursor: pointer;
	${transition()}

	&:hover {
		opacity: 0.7;
	}

	&:disabled {
		background-color: ${colors.grey};
		color: ${colorThemeProvider.grayColor};
		opacity: 1;

		${({ loading, disabled }) =>
			loading
				? css`
						cursor: wait;
				  `
				: css`
						cursor: not-allowed;
				  `}
	}
`
