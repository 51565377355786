import { orderOptionsData } from './order-options.data'
import { IOrderOptionsGenerate } from '../order-admin.types'

type OrderOption = {
	value: 'Categorias' | 'Produtos'
}

export const orderOptionsGenerate = ({
	selectedOrderOption,
}: IOrderOptionsGenerate) => {
	const selectedOptionData =
		orderOptionsData[selectedOrderOption.value as OrderOption['value']] ||
		{}
	return selectedOptionData
}
