import React from 'react'

import * as S from './banner-midia-upload.styles'

import ImageIcon from '@mui/icons-material/Image'
import { BannerMidiaUploadProps } from './banner-midia-upload.type'
import Loading from '../../../../components/Loading'
import { useMidiaBannerMidiaUpload } from './hooks/use-banner-midia-upload.hook'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

function BannerMidiaUpload({
	setSelectedMidia,
	isSelectedMidia,
	selectedMidia
}: BannerMidiaUploadProps) {
	const { handleUploadImage, handleSelectImage, loadingUploadImage, ref } =
		useMidiaBannerMidiaUpload({
			isSelectedMidia,
			setSelectedMidia,
			selectedMidia
		})
	return (
		<S.WrapperBannerMidiaUpload>
			<p>Banner</p>
			{isSelectedMidia ? (
				<S.WrapperSelectedMidia mediaUrl={selectedMidia}>
					<div className="wrapper-midia">
						<DeleteOutlineIcon
							onClick={() => setSelectedMidia('')}
						/>
						<div className="wrapper-midia-info">
							<p className="midia-name">pedidoSugerido.png</p>
							<p className="midia-size">150KB</p>
						</div>
					</div>
				</S.WrapperSelectedMidia>
			) : (
				<S.WrapperUpload onClick={handleSelectImage}>
					{loadingUploadImage ? (
						<Loading />
					) : (
						<>
							<input
								onChange={handleUploadImage}
								ref={ref}
								type="file"
								accept={'jpg, jpeg, png, gif, mp4'}
							/>
							<ImageIcon />
							<p className='main-text'>Arraste ou selecione o banner</p>
							<p className='sub-text'>RESOLUÇÃO MÁXIMA: 654x176</p>
						</>
					)}
				</S.WrapperUpload>
			)}
		</S.WrapperBannerMidiaUpload>
	)
}

export default BannerMidiaUpload
