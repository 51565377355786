import React from 'react'
import { IViewProps } from './types'
import PixLogo from '../../assets/images/pix-placeholder.svg'
import CopyPaste from '../../assets/images/copy.svg'
import {
	PixQrCodeTitle,
	PixQrCodeDescription,
	PixQrCodeExpiration,
	QRCodeContainer,
	CopyPasteWrapper,
	CopyPasteTitle,
	PixQrCodeWrapper
} from './styles'
import { Loading } from '../../shared/components'
import FinishOrderHeader from '../../components/FinishOrderHeader'

function PixQrCode(props: IViewProps): JSX.Element {
	const { pix, time, handleChangePaymentMethod, loading, copyPaste } = props

	function renderPixQRCode() {
		return (
			<QRCodeContainer>
				<img className="qr-code" src={pix.pixUrl} alt="Pix Icon" />
				<div className="border" />

				<PixQrCodeDescription>
					Ou copie este código abaixo para realizar o pagamento:
				</PixQrCodeDescription>
				<CopyPasteWrapper>
					<CopyPasteTitle style={{ marginRight: 5 }}>
						{pix.pixCopyPasteUrl}
					</CopyPasteTitle>
					<div className="copyPaste">
						<button onClick={copyPaste}>
							<img
								className="copy"
								src={CopyPaste}
								alt="Pix Icon"
							/>
						</button>
					</div>
				</CopyPasteWrapper>
			</QRCodeContainer>
		)
	}
	return (
		<PixQrCodeWrapper className="order-summary-page">
			<FinishOrderHeader />

			<section className="container pixWrapper">
				<img className="cart-icon" src={PixLogo} alt="Pix Icon" />

				<PixQrCodeTitle>Pagamento Pix</PixQrCodeTitle>

				<PixQrCodeDescription>
					Escaneie o QR Code para realizar o pagamento de seu pedido
					ou copie e cole o código:
				</PixQrCodeDescription>
				<PixQrCodeExpiration>
					QR Code expira em {time}
				</PixQrCodeExpiration>

				{loading ? (
					<Loading />
				) : (
					<>
						{renderPixQRCode()}
						<button
							className="red-button"
							style={{ marginTop: 36 }}
							onClick={handleChangePaymentMethod}>
							ALTERAR FORMA DE PAGAMENTO
						</button>
					</>
				)}
			</section>
		</PixQrCodeWrapper>
	)
}

export default PixQrCode
