import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Table } from '../../../shared/components'
import { IItemRulePointScoreRate } from '../../../shared/interfaces/pointsProgramRules'
import { isNegative } from '../../../shared/utils/number'
import { PackageTypeEnum, UnitMeasureEnum } from '../../RegisterProduct/types'

import * as S from './styles'
import { Product } from '../../RegisterProduct/mapper'

interface IProps {
	allProducts: Product[]
	selectedProducts: IItemRulePointScoreRate[]
	isPrimaryID?: boolean
	onChangeProductScoreRate: (productID: string, scoreRate: string) => void
}

interface ITableData {
	code: string
	name: string
	packageType: PackageTypeEnum
	unitMeasure: UnitMeasureEnum
	size: string
	ScoreValue?: string
	ScoreRate?: string
	button: JSX.Element
}

export function ChangeRedemptionProductsValue(props: IProps) {
	const { allProducts, selectedProducts, onChangeProductScoreRate } = props

	const [tableData, setTableData] = useState<ITableData[]>([])

	const columns = useMemo(
		() => [
			{ Header: 'Cód. do Produto', accessor: 'code' },
			{ Header: 'Nome do Produto', accessor: 'name' },
			{ Header: 'Tipo', accessor: 'packageType' },
			{ Header: 'Formato', accessor: 'unitMeasure' },
			{ Header: 'Tamanho', accessor: 'size' },
			{
				Header: 'Pontos para Regate',
				accessor: 'button',
				disableSortBy: true
			}
		],
		[]
	)

	function renderScoreRateInput(productID: string, productScoreRate: string) {
		function handleChangeValue(e: ChangeEvent<HTMLInputElement>) {
			const value = e.target.value
			const numberValue = Number(value || '0')

			if (!Number.isNaN(numberValue) && !isNegative(numberValue)) {
				onChangeProductScoreRate(productID, numberValue.toString())
			}
		}
		return (
			<S.InputScoreRate
				type="number"
				value={productScoreRate}
				onChange={handleChangeValue}
			/>
		)
	}

	function loadTableProducts() {
		const tableData: ITableData[] = []

		for (const product of allProducts) {
			const findProductInSelectedItems = selectedProducts.find(
				(item) =>
					item.productID ===
					(props.isPrimaryID
						? product.ID ?? product.MaterialID
						: product.MaterialID)
			)
			if (!!findProductInSelectedItems) {
				const { productID, scoreRate } = findProductInSelectedItems
				tableData.push({
					code: product.MaterialID,
					name: product.SiteDescription,
					packageType: product.PackageType,
					unitMeasure: product.UnitMeasureSale,
					size: product.Package,
					ScoreValue: product.ScoreValue,
					ScoreRate: product.ScoreRate,
					button: renderScoreRateInput(productID, scoreRate)
				})
			}
		}

		setTableData(tableData)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(loadTableProducts, [selectedProducts, allProducts])

	return (
		<S.Container>
			{!tableData.length ? (
				<div className="empty-list-placeholder">
					<span>
						Selecione primeiro os produtos acima para cadastrar
						<br />
						as pontuações dos produtos.
					</span>
				</div>
			) : (
				<Table
					data={tableData}
					columns={columns as any}
					canPaginate={false}
				/>
			)}
		</S.Container>
	)
}
