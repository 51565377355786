import React from 'react'

import Modal from '@mui/material/Modal'

import CloseIcon from '@mui/icons-material/Close'

import * as S from './modal-product-detail.styles'
import { validateImageExtension } from '../../../../shared/utils/image'
import { IModalProductDetail } from './modal-product-detail.types'

function ModalProductDetails({
	isOpen,
	handleClose,
	item
}: IModalProductDetail) {
	return (
		<Modal open={isOpen} onClose={() => handleClose()}>
			<S.WrapperModalContent>
				<S.WrapperHeaderModal>
					<CloseIcon onClick={handleClose} />
				</S.WrapperHeaderModal>
				<S.WrapperMainContext>
					<S.TitleModal>
						<h2>Informações do Produto</h2>
					</S.TitleModal>
					<S.WrapperMainDetailsContent>
						<S.InfoProduct>
							<img
								src={validateImageExtension(
									item.productImageURL || ''
								)}
								alt="combo-flex"
							/>
							<div className="wrapper-info-product">
								{/* <h3>{combo.description}</h3> */}
								<h3>{item.description}</h3>

								<ul>
									<li>
										<p className="li-category">CATEGORIA</p>
										<p className="li-value">
											{item.material.category}
										</p>
									</li>
									<li>
										<p className="li-category">MARCA</p>
										<p className="li-value">
											{item.material.brand}
										</p>
									</li>
									<li>
										<p className="li-category">TAMANHO</p>
										<p className="li-value">
											{item.material.packing}
										</p>
									</li>
									<li>
										<p className="li-category">TIPO</p>
										<p className="li-value">
											{item.material.packingType}
										</p>
									</li>
									<li>
										<p className="li-category">
											CÓDIGO DE BARRAS UNIDADE
										</p>
										<p className="li-value">
											{item.material.unitEAN}
										</p>
									</li>
									<li>
										<p className="li-category">
											CÓDIGO DE BARRAS PACOTE
										</p>
										<p className="li-value">
											{item.material.packingEAN}
										</p>
									</li>
								</ul>
							</div>
						</S.InfoProduct>
						<S.WrapperDetailsContent>
							<h2>Selecione a quantidade</h2>
							<S.StyledCard>
								<div>
									<h2 className="qtd-order-points">1 PAC</h2>
									<p className="price-order-points">
										R$ {item.price}
									</p>
								</div>
								<div className="earning-points">
									GANHE {item.material.scoreRate} PONTOS
								</div>
							</S.StyledCard>
							<S.StyledSmallCard>
								<p className="item">Preço / Garrafa</p>
								<p className="value">R$ {item.unitPriceInPackage}</p>
							</S.StyledSmallCard>
						</S.WrapperDetailsContent>
					</S.WrapperMainDetailsContent>
				</S.WrapperMainContext>
			</S.WrapperModalContent>
		</Modal>
	)
}

export default ModalProductDetails
