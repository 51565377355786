import { __, cond, has, identity, isNil } from 'ramda'
import React from 'react'

import {
	translatedTypes,
	translatedValueTypes
} from '../../../shared/utils/param'
import ResaleParameterInput from '../ResaleParameterInput'
import IProps from './types'

function ResaleParameterItem(props: IProps): JSX.Element {
	const { item, index, updateParamKey, addTag, removeTag } = props

	const { Name, Description, Type, TypeValue } = item

	const textFields = [Name, Description, Type, TypeValue]

	function getFrom<T>(from: T) {
		return <K extends keyof T>(key: K) => from[key]
	}

	const isType = has(__, translatedTypes)

	const isValueType = has(__, translatedValueTypes)

	const getVal = cond([
		[isType, getFrom(translatedTypes)],
		[isValueType, getFrom(translatedValueTypes)],
		[Boolean, identity],
		[isNil, (_) => '-']
	])

	return (
		<tr>
			{textFields.map((field, i) => (
				<td key={i} className="param-table-field">
					<span>{getVal(field)}</span>
				</td>
			))}
			<td className="param-table-field-value">
				<ResaleParameterInput
					addTag={addTag}
					index={index}
					removeTag={removeTag}
					updateParamKey={updateParamKey}
					{...item}
				/>
			</td>
		</tr>
	)
}

export default ResaleParameterItem
