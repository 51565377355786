import React from 'react'

import RadioButtonGroup from '../../../../../components/radio-button-group/radio-button'
import DatePickerWithTime from '../../../../../components/date-picker-hours/date-picker-with-time'
import ControlInput from '../../../../../components/control-input/control-input.component'
import ControlSelect from '../../../../../components/multiple-select/multiple-select'

import { CreateCouponProps } from '../../create-coupon.types'

import useInformationCoupon from './use-information-coupon'
import { currencyMask } from '../../../../../utils/currency-mask'

import { SessionTitle } from '../../create-coupon.styles'

import * as S from './information-coupon.styles'
import Loading from '../../../../../components/Loading'

export type InformationCouponProps = {
	isEditCouponFormMode: boolean
} & CreateCouponProps

function InformationCoupom({
	control,
	watch,
	register,
	errors,
	resetField,
	setError,
	clearErrors,
	getValues,
	isEditCouponFormMode,
	dirtyFields,
	reset,
	setValue,
	trigger
}: InformationCouponProps) {
	const {
		showInAppOptions,
		unlimitedOptions,
		discountTypeOptions,
		onDateStartChange,
		onDateEndChange,
		handleVerifyCodeCoupon,
		loadingVerifyCoupon
	} = useInformationCoupon({
		control,
		watch,
		register,
		errors,
		resetField,
		setError,
		clearErrors,
		getValues,
		dirtyFields,
		reset,
		setValue,
		trigger
	})

	const name = watch('name')
	const code = watch('code')
	const discountType = watch('discountType')

	return (
		<S.InformationCupomWrapper>
			<SessionTitle>Informações do cupom</SessionTitle>
			<S.WrapperInputs>
				<S.FirstRow>
					<ControlInput
						{...register('name')}
						label="Nome"
						errorMessage={errors.name?.message}
						maxCharacters={16}
						stringValue={name}
						id="coupon-name"
					/>

					<ControlInput
						{...register('code')}
						stringValue={code}
						label="Código"
						errorMessage={errors.code?.message}
						maxCharacters={14}
						onBlur={(event) => {
							handleVerifyCodeCoupon(event.target.value)
						}}
						startAdornment={
							loadingVerifyCoupon && (
								<Loading type="circular" size={16} />
							)
						}
						id="coupon-code"
					/>

					<ControlSelect
						label="Exibido no app"
						options={showInAppOptions}
						name="showInApp"
						control={control}
						disabled={isEditCouponFormMode}
						id="show-in-app"
					/>
				</S.FirstRow>

				<S.SecondRow>
					<DatePickerWithTime
						label="Data de início de disponibilidade"
						control={control}
						name="date.dateStart"
						disablePast
						onAfterChange={(date, onChange) =>
							onDateStartChange({
								date,
								getValues,
								onChange,
								setError,
								clearErrors
							})
						}
						id={'date-begin'}
					/>

					<DatePickerWithTime
						label="Data de fim de disponibilidade"
						control={control}
						name="date.dateEnd"
						disablePast
						onAfterChange={(date, onChange) =>
							onDateEndChange({
								date,
								getValues,
								setError,
								onChange,
								clearErrors
							})
						}
						id={'date-end'}
					/>

					<ControlInput
						{...register('couponInfo.quantityCoupons')}
						onBlur={(e) => {
							!!e.target.value &&
								setValue(
									'couponInfo.quantityAvailable',
									''
								)
						}}
						label="Quantidade de cupons"
						errorMessage={
							errors.couponInfo?.quantityCoupons?.message
						}
						id={'quantity-coupons'}
					/>

					<RadioButtonGroup
						onAfterChange={() =>
							setValue('couponInfo.quantityCoupons', '')
						}
						radioLabel="Ilimitado"
						control={control}
						name="couponInfo.quantityAvailable"
						controlLabelOptions={unlimitedOptions}
					/>
				</S.SecondRow>

				<div className="inputs-row">
					<RadioButtonGroup
						radioLabel="Valor"
						name="discountType"
						control={control}
						controlLabelOptions={discountTypeOptions}
						onAfterChange={() => resetField('discountValue')}
						disabled={isEditCouponFormMode}
					/>
				</div>

				<div className="inputs-row">
					{discountType && (
						<S.WrapperInput width="152px">
							<ControlInput
								{...register('discountValue', {
									onChange: (event) => {
										if (discountType === 'percent') {
											const { value } = event.target
											const formattedValue =
												value.replace(/\D/g, '')

											if (Number(value) > 100) {
												setError('discountValue', {
													message:
														'Valor de porcentagem deve ser menor ou igual a 100'
												})
											} else {
												clearErrors('discountValue')
											}
											return (event.target.value =
												formattedValue)
										}

										const { value } = event.target
										event.target.value = currencyMask(value)
									}
								})}
								label=""
								errorMessage={errors.discountValue?.message}
								startAdornment={
									discountType === 'money' ? 'R$' : '%'
								}
								id={'discount-value'}
							/>
						</S.WrapperInput>
					)}
				</div>
			</S.WrapperInputs>
		</S.InformationCupomWrapper>
	)
}

export default InformationCoupom
