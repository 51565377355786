import React, {
	ChangeEvent,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { RangeModifier } from 'react-day-picker'
import { Link, useParams, useLocation, useHistory } from 'react-router-dom'
import cogoToast from 'cogo-toast'

import ArrowLeftIcon from '../../assets/images/arrow-left-red.svg'
import {
	createRule,
	getRuleFilterParameters,
	updateRule
} from '../../shared/services/pointProgramRules.service'
import { IHeaderRulePoint } from '../../shared/interfaces/pointsProgramRules'
import {
	InputSelectPeriodPicker,
	Loading,
	InputTextStyled as Input
} from '../../shared/components'
import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'
import { getProducts } from '../RegisterProduct/service'

import { PointsGainRatioRuleSettings } from './PointsGainRatioRuleSettings'
import { ChangeProductsList } from './ChangeProductsList'
import { ChangeRedemptionProductsValue } from './ChangeRedemptionProductsValue'
import { RuleModel } from './RuleModel'
import * as S from './styles'
import { IOption, IParams, IProps, ViewTypeEnum } from './types'
import FiltersByHieararchy from '../../components/filters-by-hieararchy/filters-by-hieararchy.component'
import { formatDatePayload } from './utils/formatDatePayload'
import { formatPdvsToPayload } from './utils/format-pdvs-to-payload'
import cogoDefaultOptions from '../../shared/utils/toaster'
// import { getCheckAllFilters } from '../../shared/services/pointProgramChallenges.service'
import {
	IFilterOptions,
	FilterNameEnum,
	IFilterParams
} from '../../components/filters-by-hieararchy/typings'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import { useFeatureFlags } from '../../context/feature-flag/feature-flag.context'
import { useQuery } from 'react-query'
import suggestedOrdersAdminService from '../../views/suggested-orders-admin-v2/services/suggested-orders-admin.service'
import { Product } from '../RegisterProduct/mapper'

export default function PointProgramRuleDetailsV2() {
	const location = useLocation<IProps>()
	const { rule: ruleFromProps } = location.state || {}

	const paramNameMapper = {
		diretor: FilterNameEnum.DIRECTOR,
		GRC: FilterNameEnum.GOV,
		codeResales: FilterNameEnum.RESALE,
		ufs: FilterNameEnum.STATE,
		cities: FilterNameEnum.CITY,
		channels: FilterNameEnum.CHANNEL,
		codePdvs: FilterNameEnum.PDV
	} as const

	const filters: Record<keyof typeof paramNameMapper, string[]> = JSON.parse(
		ruleFromProps?.filters ?? '[]'
	)
	const { featureFlags } = useFeatureFlags()
	const isFeatureFlagBonificationActive = featureFlags.PRODUTO_BONIFICADO

	const getInitialFilters = (): IFilterParams[] => {
		const returnFilters: IFilterParams[] = []

		for (const filter of Object.entries(filters)) {
			const key = filter[0] as keyof typeof paramNameMapper
			const value = filter[1]
			value.forEach((item) => {
				returnFilters.push({
					paramName: paramNameMapper[key],
					paramValue: item,
					ruleID: ruleFromProps?.ruleID
				})
			})
		}
		return returnFilters
	}

	const initialFilters = getInitialFilters()

	const params = useParams<IParams>()

	const { rule_type: ruleType } = params

	const viewType = useMemo<ViewTypeEnum.CREATE | ViewTypeEnum.UPDATE>(
		() => (!!ruleFromProps ? ViewTypeEnum.UPDATE : ViewTypeEnum.CREATE),
		[ruleFromProps]
	)

	const history = useHistory()

	const [ruleDetails, setRuleDetails] = useState<RuleModel>(
		new RuleModel({
			rule: ruleFromProps,
			settings: { isInitialization: true }
		})
	)

	const [filterHierarchy, setFilterHierarchy] = useState<IFilterOptions>({})
	const [amountOfCustomers, setAmountOfCustomers] = useState<number>(0)

	const [isLoadingProducts, setIsLoadingProducts] = useState(false)
	const [allProducts, setAllProducts] = useState<Product[]>([])
	const [isLoadingFilterOptions, setIsLoadingFilterOptions] = useState(false)

	const [selectedPDVs, setSelectedPDVs] = useState<IOption[] | string[]>([])
	const [canSubmit, setCanSubmit] = useState(false)
	const [isSubmiting, setIsSubmiting] = useState(false)

	const [canFilter, setCanFilter] = useState<boolean>(false)

	// Inicio dos handlers
	const handlers = {
		changeObservation: useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const value = e.target.value
				setRuleDetails(ruleDetails.changeObservation(value))
			},
			[ruleDetails]
		),
		changePDVOption: useCallback((value) => {
			setSelectedPDVs(value || [])
		}, []),
		changePeriod: useCallback(
			(value: RangeModifier) => {
				setRuleDetails(ruleDetails.changePeriod(value))
			},
			[ruleDetails]
		),
		changeCheckboxCustomPointRatio: useCallback(() => {
			if (ruleDetails.canChangeCustomPointRatio) {
				setRuleDetails(
					ruleDetails.changeCheckboxCustomPointRatio.changePointRatio(
						''
					)
				)
			} else {
				setRuleDetails(ruleDetails.changeCheckboxCustomPointRatio)
			}
		}, [ruleDetails]),
		changePointRatio: useCallback(
			(value: string) => {
				setRuleDetails(ruleDetails.changePointRatio(value))
			},
			[ruleDetails]
		),
		changeProducts: useCallback(
			(value: string[]) => {
				const changedItems =
					ruleType === 'point-gain-ratio'
						? ruleDetails.changeItemsRulePointScoreValue(value)
						: ruleDetails.changeItemsRulePointScoreRate(value)

				setRuleDetails(changedItems)
			},
			[ruleType, ruleDetails]
		),
		changeProductScoreRate: useCallback(
			(productID: string, scoreRate: string) => {
				setRuleDetails(
					ruleDetails.changeProductScoreRate(productID, scoreRate)
				)
			},
			[ruleDetails]
		)
	}

	function goBack() {
		return ruleType === 'point-gain-ratio'
			? AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO
			: AdminRoutesEnum.POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION
	}

	function getItemsByRuleType() {
		const items =
			ruleType === 'point-gain-ratio'
				? ruleDetails.ruleData.itemsRulePointScoreValue
				: ruleDetails.ruleData.itemsRulePointScoreRate

		return items.map((item) => item.productID)
	}

	function handleSubmit() {
		;(async () => {
			try {
				setIsSubmiting(true)

				const filterOptions = formatPdvsToPayload(
					selectedPDVs as string[]
				)

				const {
					observation,
					initialDate,
					finalDate,
					itemsRulePointScoreRate,
					itemsRulePointScoreValue,
					scoreValue
				} = ruleDetails.ruleData
				const payload: IHeaderRulePoint = {
					observation,
					initialDate: formatDatePayload(initialDate),
					finalDate: formatDatePayload(finalDate),
					filtersRulePoint: filterOptions,
					amountOfCustomers: amountOfCustomers,
					filtersRulePointNormalized: {
						diretor: filterHierarchy.DIRECTOR?.map(
							(relation) => relation.value
						),
						GRC: filterHierarchy.GOV?.map(
							(relation) => relation.value
						),
						codeResales: filterHierarchy.RESALE?.map(
							(relation) => relation.value
						),
						ufs: filterHierarchy.STATE?.map(
							(relation) => relation.value
						),
						cities: filterHierarchy.CITY?.map(
							(relation) => relation.value
						),
						channels: filterHierarchy.CHANNEL?.map(
							(relation) => relation.value
						),
						codePdv: selectedPDVs as string[]
					},
					...(ruleType === 'product-redemption'
						? { itemsRulePointScoreRate: itemsRulePointScoreRate }
						: {
								itemsRulePointScoreValue:
									itemsRulePointScoreValue,
								scoreValue: scoreValue
						  })
				}

				// Cadastro de regra
				let headRule = { ruleID: '' }
				if (!!ruleFromProps) {
					payload.ruleID = ruleFromProps.ruleID
					headRule = await updateRule(payload)
				} else {
					headRule = await createRule(payload)
				}

				cogoToast.success(
					'Os produtos selecionados terão uma nova regra para o Programa de Pontos',
					{
						position: 'bottom-center',
						heading:
							viewType === ViewTypeEnum.CREATE
								? 'Regra Cadastrada!'
								: 'Regra Atualizada!'
					}
				)
				history.replace(
					ruleType === 'product-redemption'
						? AdminRoutesEnum.POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION
						: AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO
				)
			} catch (error) {
				cogoToast.error(error, cogoDefaultOptions)
			} finally {
				setIsSubmiting(false)
			}
		})()
	}
	const isBonificatiion = useMemo(
		() => ruleType === 'product-redemption',
		[ruleType]
	)

	function fetchProducts() {
		;(async () => {
			try {
				setIsLoadingProducts(true)
				const products = await getProducts(
					isBonificatiion,
					isFeatureFlagBonificationActive
				)

				console.log({ products })
				setAllProducts(products)
			} finally {
				setIsLoadingProducts(false)
			}
		})()
	}

	const { data: domainCategory } = useQuery({
		queryKey: ['/materials-grouped-by-category'],
		queryFn: async () => {
			const res =
				await suggestedOrdersAdminService.getMaterialsGroupedByCategory()
			return res
		}
	})

	const groupedCategories = domainCategory?.groupedCategories ?? []

	function checkIfCanSubmit() {
		let canSubmit = false

		const {
			itemsRulePointScoreRate = [],
			itemsRulePointScoreValue = [],
			scoreValue
		} = ruleDetails.ruleData

		if (ruleType === 'product-redemption') {
			const thereAreSelectedProducts = !!itemsRulePointScoreRate.length
			const allScoreRateAreValid = itemsRulePointScoreRate.every(
				(product) => !!Number(product.scoreRate)
			)

			canSubmit = thereAreSelectedProducts && allScoreRateAreValid
		} else {
			const thereAreSelectedProducts = !!itemsRulePointScoreValue.length
			const scoreValueIsValid = !!scoreValue && !!scoreValue.split(':')[1]

			canSubmit = thereAreSelectedProducts && scoreValueIsValid
		}
		setCanSubmit(canSubmit && amountOfCustomers > 0)
	}

	useEffect(fetchProducts, [])

	useEffect(checkIfCanSubmit, [amountOfCustomers, ruleDetails, ruleType])

	const isLoading = isSubmiting || isLoadingFilterOptions || isLoadingProducts

	return (
		<DefaultLayoutAdmin>
			<S.Container>
				<S.PageHeader>
					<Link className="go-back-button" to={goBack()}>
						<img
							className="back-arrow"
							src={ArrowLeftIcon}
							alt="Ícode de flecha apontando para a esquerda"
						/>
						<span>Voltar</span>
					</Link>

					<h1 className="pg-title">
						{viewType === ViewTypeEnum.UPDATE
							? 'Detalhes da Regra'
							: 'Nova regra'}
					</h1>
				</S.PageHeader>
				<S.PageSession>
					<h2 className="pg-subtitle">Observações sobre a Regra</h2>
					<Input
						value={ruleDetails.ruleData.observation}
						onChange={handlers.changeObservation}
						name="rule-observation"
						label="Observações"
						disabled={viewType === ViewTypeEnum.UPDATE}
						containerStyle={{
							marginTop: '16px'
						}}
						maxLength={150}
					/>
				</S.PageSession>
				<>
					<FiltersByHieararchy
						initialFilters={initialFilters}
						isEnabled={viewType === ViewTypeEnum.CREATE}
						impactedPdvsLength={amountOfCustomers}
						fetchListPDVsPush={false}
						onPushFilterChange={setAmountOfCustomers}
						onFilterChange={setSelectedPDVs}
						getFilterParameters={getRuleFilterParameters}
						onLoad={() => setIsLoadingFilterOptions(true)}
						onLoadEnd={() => setIsLoadingFilterOptions(false)}
						canFilter={canFilter}
						setCanFilter={setCanFilter}
						shouldListPdv={false}
						handleGetFilterValues={setFilterHierarchy}
					/>
				</>
				{ruleType === 'point-gain-ratio' && (
					<S.PageSession>
						<PointsGainRatioRuleSettings
							rule={ruleDetails}
							onChangePeriod={handlers.changePeriod}
							onChangeCheckboxCustomPointRatio={
								handlers.changeCheckboxCustomPointRatio
							}
							onChangePointRatio={handlers.changePointRatio}
						/>
					</S.PageSession>
				)}
				{ruleType === 'product-redemption' && (
					<S.PageSession>
						<h2 className="pg-subtitle">
							Informe o Início e o Término desta regra
						</h2>
						<InputSelectPeriodPicker
							label="Início e término da regra"
							placeholder="Defina um intervalo"
							onChange={handlers.changePeriod}
							value={{
								from: new Date(
									ruleDetails.ruleData.initialDate
								),
								to: new Date(ruleDetails.ruleData.finalDate)
							}}
							containerStyle={{
								maxWidth: '28%',
								marginTop: '23px'
							}}
							style={{
								width: '100%'
							}}
						/>
					</S.PageSession>
				)}
				<S.PageSession>
					{isLoadingProducts ? (
						<div className="loading-wrapper">
							<Loading color="red" />
						</div>
					) : (
						<>
							<ChangeProductsList
								disabled={viewType === ViewTypeEnum.UPDATE}
								options={groupedCategories}
								selectedOptions={getItemsByRuleType()}
								onChangeOption={handlers.changeProducts}
							/>
							{ruleType === 'product-redemption' && (
								<ChangeRedemptionProductsValue
									allProducts={allProducts}
									selectedProducts={
										ruleDetails.ruleData
											.itemsRulePointScoreRate
									}
									isPrimaryID={
										isBonificatiion &&
										isFeatureFlagBonificationActive
									}
									onChangeProductScoreRate={
										handlers.changeProductScoreRate
									}
								/>
							)}
						</>
					)}
				</S.PageSession>
				<div>
					Por favor ao
					{viewType === ViewTypeEnum.UPDATE
						? ' atualizar '
						: ' cadastrar '}
					aguarde ser redirecionado automaticamente para a tela
					inicial, o processo de inserção pode levar algum tempo, se
					demorar mais de 5 minutos por favor contate o suporte.
				</div>
				<S.ButtonActionsGroup>
					<button
						className="red-button"
						disabled={!canSubmit || isLoading}
						onClick={handleSubmit}>
						{viewType === ViewTypeEnum.UPDATE
							? 'SALVAR ALTERAÇÕES'
							: 'CADASTRAR REGRA'}
					</button>
				</S.ButtonActionsGroup>
			</S.Container>
		</DefaultLayoutAdmin>
	)
}
