import { ICharacteristics, IMaterial } from '../interfaces/material'
import { getLowerPrice, returnHighestPrice } from './price'
import { ItemTypeEnum } from './promotion'

export function settingTheUnitPriceOfTheProducts(products: IMaterial[]) {
	return products.map((product) => {
		const itemType: ItemTypeEnum = product.priceAction?.length
			? ItemTypeEnum.PRICE_ACTION
			: ItemTypeEnum.NORMAL

		const arrayPrices = product.PriceByQuantity.map((item) =>
			Number(item.Price)
		)

		const unitPriceValue =
			itemType === ItemTypeEnum.PRICE_ACTION
				? returnHighestPrice(arrayPrices, product) /
				  Number(product.itemUnitiesQuantity)
				: getLowerPrice(arrayPrices) /
				  Number(product.itemUnitiesQuantity)

		return {
			...product,
			PricePerUnit: unitPriceValue
		}
	})
}

export function checkingTheContainerTypeOfAProduct(
	productCharacteristics: ICharacteristics[]
) {
	return productCharacteristics.reduce((acc, item) => {
		if (item.Type === 'Package') {
			acc =
				item.Description.toLowerCase().includes('vidro') ||
				item.Description.toLowerCase().includes('pet')
					? 'GARRAFA'
					: 'LATA'
		}
		return acc
	}, '')
}

export function settingTheProductContainerType(products: IMaterial[]) {
	return products.map((product) => {
		const typeOfContainer = checkingTheContainerTypeOfAProduct(
			product.Characteristics
		)
		return {
			...product,
			typeOfContainer: typeOfContainer
		}
	})
}

export function getCharacteristicDescription(
	characteristics: ICharacteristics[],
	characteristicType: string
): string {
	return (
		characteristics.find(
			(characteristic) => characteristic.Type === characteristicType
		)?.Description || ''
	)
}
