import React, { Fragment } from 'react'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import {
	creditCardType,
	formatDeliveryDateInput,
	translatePayment,
	translationOfPositions
} from '../util'
import { IViewProps } from './types'
import * as S from '../styles'
import {
	CartTable,
	ChallengeCartItemBlockOfProducts
} from '../../../shared/components'
import { toMoney } from '../../../shared/utils/money'
import { selectFlag } from '../../../shared/utils/selectBankFlag'
import { PaymentOptions } from '../Payment/types'

function OrderSummaryDetails(props: IViewProps): JSX.Element {
	const { cartRedemptionItems, customer, cartChallengeItems } =
		useTypedSelector([
			'carts',
			'cartRedemptionItems',
			'customer',
			'cartChallengeItems'
		])

	const {
		allCarts,
		paymentStep,
		confirmPayment,
		goBackStepOne,
		paymentInfo,
		payment,
		creditCard,
		dateToDelivery
	} = props

	function friendly(text: string) {
		return text?.trim()?.toLowerCase()
	}

	const address = `${friendly(customer.Street)}, ${friendly(
		customer.HouseNumber
	)}, ${friendly(customer.Neighborhood)} ${
		friendly(customer.NickName) ? '- ' + friendly(customer.NickName) : ''
	} - ${friendly(customer.City)}/${customer.Region}`

	const renderChallengeItem = (challengeID?: string) => {
		const currentChallenge = cartChallengeItems.find(
			(challenge) => challenge.challengeId === String(challengeID)
		)
		return (
			<Fragment>
				{!!currentChallenge && (
					<div style={{ marginBottom: 16 }}>
						<ChallengeCartItemBlockOfProducts
							key={challengeID}
							isCheckoutScreen={true}
							cartchallenge={currentChallenge}
						/>
					</div>
				)}
			</Fragment>
		)
	}

	return (
		<S.OrderSummaryDetails
			className={`container row ${
				!paymentStep?.active ? 'hide-block' : ''
			}`}>
			<div className="col-lg-8">
				<h2>
					{allCarts.length <= 1
						? 'Produtos do Pedido'
						: 'Detalhes do Pedido'}
				</h2>

				{allCarts.length > 1 && (
					<S.SplittedOrderAlert>
						SEU PEDIDO POSSUI PRODUTOS QUE SE REPETEM, E POR ISSO
						SEPARAMOS EM MAIS DE UM PEDIDO EM UMA SÓ SOLICITAÇÃO.
					</S.SplittedOrderAlert>
				)}

				{allCarts.map((order, i, arr) => {
					const challengesAddedInTheCart = order.itemsChallenge?.map(
						(item) => {
							return item.challengeID
						}
					)
					const challengesIDS = challengesAddedInTheCart?.filter(
						(ID, index) =>
							challengesAddedInTheCart.indexOf(ID) === index
					)
					const thereIsSeparationOfOrders = allCarts.length > 1

					return (
						<Fragment key={order.salesOrderID}>
							{arr.length > 1 && (
								<S.OrderTitlteWrapper>
									<div className="page-title">
										<h2>
											Produtos do{' '}
											<span
												style={{
													textTransform: 'capitalize'
												}}>
												{translationOfPositions[
													i
												]?.toLowerCase()}
											</span>{' '}
											Pedido
										</h2>
									</div>
								</S.OrderTitlteWrapper>
							)}

							{!!challengesIDS?.length ? (
								<Fragment>
									{thereIsSeparationOfOrders ? null : (
										<section
											style={{
												width: '100%',
												marginBottom: 16,
												justifyContent: 'flex-start'
											}}>
											<S.ChallengesProductsTitle>
												Produtos do desafio
											</S.ChallengesProductsTitle>
										</section>
									)}

									<section
										style={{
											marginBottom: 16
										}}>
										{challengesIDS.map(renderChallengeItem)}
									</section>
								</Fragment>
							) : null}
							{(!!order.items.length ||
								!!cartRedemptionItems.length) && (
								<Fragment>
									{thereIsSeparationOfOrders ? null : (
										<section
											style={{
												width: '100%',
												marginBottom: 16,
												justifyContent: 'flex-start'
											}}>
											<S.ChallengesProductsTitle>
												Produtos Individuais
											</S.ChallengesProductsTitle>
										</section>
									)}
									<CartTable
										isEditable={false}
										tableData={[
											...order.items,
											...cartRedemptionItems
										]}
									/>
								</Fragment>
							)}
						</Fragment>
					)
				})}
			</div>

			<div className="col-lg-4">
				<h2>Detalhes do Pagamento</h2>

				{!!paymentInfo ? (
					<div className="cart-summary">
						<div className="separate">
							<span className="flex">
								<h3 className="summary-title">Método</h3>
								<button
									className="link-button-green"
									onClick={goBackStepOne}>
									ALTERAR
								</button>
							</span>

							<div className="cart-payment">
								<p>{translatePayment[payment]}</p>

								{creditCard ? (
									<div className="cart-payment">
										<p style={{ marginRight: 4 }}>
											{'...' +
												creditCard.substr(
													creditCard.length - 4
												)}
										</p>
										<img
											src={selectFlag(
												creditCardType(creditCard)
											)}
											alt=""
											className="icon"
										/>
									</div>
								) : null}

								{payment === PaymentOptions.PAY_ON_DELIVERY && (
									<p className="delivery">
										{paymentInfo?.paymentMethod?.toLowerCase()}
									</p>
								)}
							</div>
							<div className="cart-payment">
								<p>Valor Total</p>
								<p>{toMoney(paymentInfo.total)}</p>
							</div>
						</div>

						<div className="separate">
							<h3 className="summary-title">
								Endereço de entrega
							</h3>

							<p className="address">{address}</p>
						</div>

						<div className="separate">
							<h3 className="summary-title">
								Entrega prevista em até
							</h3>

							<p>{dateToDelivery}</p>
						</div>

						<div className="button">
							<button
								className="green-button"
								onClick={confirmPayment}>
								Confirmar
							</button>
						</div>
					</div>
				) : null}
			</div>
		</S.OrderSummaryDetails>
	)
}

export default OrderSummaryDetails
