import React, { useEffect } from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import MidiaContainer from '../../midia-container/midia-container.component'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BannersDataContainer from '../banners-data-container/banners-data-container.component'
import { CancelBannerModalProps } from './cancel-banner-modal.types'
import { CancelBannerModalStyles } from './cancel-banner-modal.styles'
import FloatLabelTextareaInput from '../../../../../components/FloatLabelTextAreaInput'
import { primaryInputStyle } from '../../../../../shared/styles/theme'

import { cancelBanner } from '../service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../shared/utils/toaster'

function CancelBannerModal({
	isOpen,
	handleCloseModal,
	banner,
	onSaveBanner
}: CancelBannerModalProps) {
	const [justification, setJustification] = React.useState('')
	const [date, setDate] = React.useState('')
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	useEffect(() => {
		const initialDate = new Date(banner.initialDate)
		const finalDate = new Date(banner.finalDate)
		initialDate.setDate(initialDate.getDate() + 1)
		finalDate.setDate(finalDate.getDate() + 1)
		const date = `De ${initialDate.toLocaleDateString()} até ${finalDate.toLocaleDateString()}`
		setDate(date)
	}, [banner])

	async function confirm() {
		setIsLoading(true)

		try {
			if (banner.ID) {
				await cancelBanner({ ID: banner.ID, justification })
				cogoToast.success(
					'Banner cancelado com sucesso.',
					cogoDefaultOptions
				)
				onSaveBanner()
				handleCloseModal()
			}
		} catch (error) {
			cogoToast.error('Erro ao cancelar banner.', cogoDefaultOptions)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<LayoutModal
			handleClick={() => confirm()}
			isOpen={isOpen}
			modalTitle="Cancelamento Banner"
			buttonText="Confirmar"
			buttonTextReturn="Manter"
			handleToggleModal={handleCloseModal}
			height="88%"
			disabledButton={justification.length <= 9}
			isLoading={isLoading}>
			<CancelBannerModalStyles>
				<MidiaContainer
					deviceSelected={{ value: '', name: '' }}
					showDeviceSelect={false}
					showImage={!!banner.imageURL}
					showVideo={!!banner.videoURL}
					srcImage={banner.imageURL}
					canDeleteImage={false}
					handleGetSrcImage={() => {}}
					srcVideo={banner.videoURL}
					canDeleteVideo={false}
					hasSelectedVideo={true}
					handleGetSrcVideo={() => {}}
				/>
				<BannersDataContainer
					apliedTitle={banner.title}
					destinyLink={banner.targetPage}
					exibitionPeriod={date}
					actionType={banner.actionType}
					selectedProduct={banner.selectedProduct}
					selectedCategory={''}
				/>
				<div className="sub-title-wrapper">
					<h2>Justificativa de Cancelamento do Banner</h2>
				</div>
				<p className="text-label">Digite sua justificativa abaixo (com no mínimo 10 caracteres):</p>
				<FloatLabelTextareaInput
					label="Justificativa"
					id="message-input"
					{...primaryInputStyle}
					style={{ backgroundColor: 'white', color: 'black' }}
					labelColor={'#CE332E'}
					onChange={(event) => setJustification(event.target.value)}
					value={justification}
					maxLength={120}
				/>
				<div className="info-modal">
					<InfoOutlinedIcon />
					<p>
						Essa justificativa será anexada ao registro de Banner
						cancelado. Confirme para finalizar o processo.
					</p>
				</div>
			</CancelBannerModalStyles>
		</LayoutModal>
	)
}

export default CancelBannerModal
