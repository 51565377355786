export enum CustomerRoutesEnum {
	PRODUCT_LIST = '/product-list',
	COMBO_LIST = '/combo-list',
	CART = '/cart',
	ORDER_SUMMARY = '/order-summary',
	MY_ACCOUNT = '/my-account',
	ORDER_HISTORY = '/order-history',
	ORDER_DETAIL = '/order-detail',
	SUCCESS = '/success',
	RECOVER_EMAIL = '/recover-email',
	CHANGE_CELLPHONE = '/change-cellphone',
	CONFIRM_EDIT_CELLPHONE_CODE = '/confirm-edit-cellphone-code',
	PIX_QR_CODE = '/pix',
	WELCOME = '/welcome',
	REGISTRATION_UPDATE = '/registration-update',

	// POINTS PROGRAM ROUTES
	POINTS_PROGRAM__MY_POINTS = '/points-program/my-points',
	POINTS_PROGRAM__POINTS_REDEMPTION = '/points-program/redemption',
	POINTS_PROGRAM__KNOW_MORE = '/points-program/know-more',
	POINTS_PROGRAM__CHALLENGES = '/points-program/challenges',
	POINTS_PROGRAM__CHALLENGE_DETAILS = '/points-program/challenge-details'
}
