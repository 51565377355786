import styled from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const Container = styled.div`
  .s-row + .s-row {
    margin-top: 20px;
  }

  .s-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    & > *:nth-child(1) {
        width: 70%;
    }
    & > *:nth-child(2) {
        width: 30%;
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
            margin-left: 8px;
        }
    }

    .setting-inputs-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        & > *:nth-child(1) {
            width: 40%;
        }
        & > *:nth-child(2) {
            margin-left: 20px;
            width: 30%;
        }
    }

    .custom-ration-input-wrapper {
        &, input {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        input {
            justify-content: center;

            height: 58px;
            width: 58px;
            text-align: center;
            color: ${colors.secondGrey};
            border: 1px solid ${colors.secondGrey};
            border-radius: 4px;
            font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

            & + input {
                margin-left: 20px;
            }

            &:disabled {
                border-color:  ${colors.grey};
                background-color: ${colors.lightGrey};
                color: ${colors.grey};
            }
        }
    }
  }
`
