import React from 'react'

import { ICongratsProps } from '../types'
import * as S from './styles'

export function Congrats({
	onClose,
	onNavigate,
	goToThePoints,
	totalEarnedPoints
}: ICongratsProps) {
	return (
		<S.Container>
			<S.ButtonClose onClick={onClose}>
				<S.XIcon />
			</S.ButtonClose>

			<S.LogoBP />

			<S.Title>Parabéns!</S.Title>
			<S.Message>
				Agora você faz parte do Programa de Pontos BP!
				<br />E para comemorar, você já tem disponível o saldo de
				<br />
				{totalEarnedPoints} pontos para uso em nosso site!
			</S.Message>

			{goToThePoints && (
				<S.Button onClick={onNavigate}>Acessar Pontos</S.Button>
			)}
		</S.Container>
	)
}
