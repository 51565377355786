import styled, { css } from 'styled-components'

export const UsersHeaderContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const InputWrapper = styled.div`
	${({ theme }) => css`
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin-top: ${theme.spacingOptions.SM}px;
	`}
`
