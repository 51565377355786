import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { removeEspecialCharacters } from '../../shared/utils'
import { checkTel } from '../../shared/utils/validators'
import InputField from '../InputField'
import { IProps } from './types'

function InputCellPhone({ returnValue, valueIsValid, className = '', disabled = false, label = 'celular', valueDefault = '' }: IProps) {

	const cellphoneMask = '(99) 99999-9999'

	const [inputValue, setInputValue] = useState(valueDefault)
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setTouched(true)
		returnValue(removeEspecialCharacters(e.target.value))
		setInputValue(e.target.value)
	}

	function validateInput() {
		// Por causa da mácara, esse campo começa com '(' )
		if (inputValue !== '(') {
			const isValid = checkTel(inputValue)
			valueIsValid(isValid)
			setHasError(touched && !isValid)
		}

		valueIsValid(true)
	}

	useEffect(validateInput, [value, inputValue, touched, valueIsValid])

	return (
		<InputField
			mask={cellphoneMask}
			onChange={handleChange}
			id="cellphone"
			label={label}
			hasError={hasError}
			className={className}
			disabled={disabled}
		/>
	)
}

export default InputCellPhone
