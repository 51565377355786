import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const PasswordCreatedView = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 2rem;
`

export const Title = styled.h1`
	color: ${colorThemeProvider.blackColor};
	font-size: 1.5rem;
	font-weight: 700;
`

export const Description = styled.p`
	width: 19.5rem;
	text-align: center;
	color: ${colorThemeProvider.grayColor};
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5rem;
	letter-spacing: -0.0125rem;
`
