import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	height: 100%;
	flex-direction: row;
	align-items: flex-end;
	justify-content: space-between;
	gap: ${(props) => props.theme.spacingOptions.XXSM}px;

	.search-input-wrapper-pending-approval {
		width: 18%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 32px;
	}
`

export const LoadingWrapper = styled.div`
	width: 170px;
	height: 40px;
`
