import { TypeDoc } from '../../filters-by-hieararchy/typings'
import { fileInfoType } from '../upload-spreadsheet.types'

type verifyUploadErrorType = {
	data: Array<Record<string, unknown>>
	fileInfo: fileInfoType
	validateHeaders: (para: string[]) => string[]
	errorOnCsvReader: (error: Error) => void
	headers: string[]
}

export function verifyUploadError({
	data,
	fileInfo,
	validateHeaders,
	errorOnCsvReader,
	headers
}: verifyUploadErrorType): boolean {
	// Validação csv com o tipo correto
	if (fileInfo.type !== TypeDoc.TEXTCSV) {
		errorOnCsvReader({
			message: 'O tipo de arquivo deve ser .csv!',
			name: 'Error'
		})
		return true
	}

	if (data.length === 0) {
		const blankHeaders = headers.join(',')

		errorOnCsvReader({
			message: `Planilha não tem a coluna ${blankHeaders}!`,
			name: 'Error'
		})
		return true
	}

	// Validação csv com cabeçalho invalido
	const missingHeaders = validateHeaders(Object.keys(data[0]))
	if (missingHeaders.length > 0) {
		errorOnCsvReader({
			message: `Planilha não tem a coluna ${missingHeaders.join(',')}!`,
			name: 'Error'
		})
		return true
	}

	// Validação csv com mais de 3000 linhas
	if (data.length > 3000) {
		errorOnCsvReader({
			message: `Limite de 3000 linhas excedido!`,
			name: 'Error'
		})
		return true
	}

	// Validação csv com mais de 70MB
	if (fileInfo.size > 1024 * 1024 * 70) {
		errorOnCsvReader({
			message:
				'Seu arquivo é maior que 70MB, tente com um arquivo de tamanho até 70MB!',
			name: 'Error'
		})
		return true
	}

	return false
}
