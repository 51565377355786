import { useState } from 'react'

type UseBooleanReturn = [boolean, () => void, () => void, () => void]

export const useBoolean = (initialState: boolean): UseBooleanReturn => {
	const [state, setState] = useState(initialState)

	const setTrue = () => setState(true)

	const setFalse = () => setState(false)

	const toggle = () => setState((x) => !x)

	return [state, setTrue, setFalse, toggle]
}
