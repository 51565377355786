import { createElement, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { IViewProps } from './types'
import Confirmation from './view'

function ConfirmationContainer(): JSX.Element {
	const history = useHistory()

	const [code, setCode] = useState('')
	const [loading] = useState(false)

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}

	// async function handleConfirmation() {
	// 	try {
	// 		setLoading(true)

	// 		const response = await confirmSignUp({
	// 			documentNumber: transferArea.PersonalFiscalID,
	// 			code
	// 		})

	// 		if (!response) return

	// 		cogoToast.success('Usuário confirmado com sucesso!', {
	// 			position: 'top-right'
	// 		})

	// 		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	// 	} catch (err) {
	// 	} finally {
	// 		setLoading(false)
	// 	}
	// }

	async function handleSetCode(e: React.ChangeEvent<HTMLInputElement>) {
		setCode(e.target.value)
	}

	function isDisabled() {
		const tokenRegex = /\d{6}/

		if (tokenRegex.test(code)) return false

		return true
	}

	const viewProps: IViewProps = {
		code,
		handleSetCode,
		// handleConfirmation,
		loading,
		handleBack,
		isDisabled
	}

	return createElement(Confirmation, viewProps)
}

export default ConfirmationContainer
