import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const Container = styled.section`
	:hover {
		cursor: pointer;
	}
	.pg-subtitle {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		color: ${colors.secondGrey};
	}
	.pg-subtitle-text {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height};
		color: ${colors.secondGrey};
		padding-bottom: ${(props) => props.theme.spacingOptions.SM}px;
	}
	.pg-description {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height};
		color: ${(props) => props.theme.palette.gray.main};
		padding-bottom: ${(props) => props.theme.spacingOptions.XLG}px;
	}

	.pg-button-title-wrapper {
		margin-bottom: ${(props) => props.theme.spacingOptions.XXSM}px;
		height: ${(props) => props.theme.spacingOptions.XLG}px;
		display: flex;
		justify-content: space-between;
	}
	.pg-click-here-button {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height};
		color: ${(props) => props.theme.palette.primary.main};
		background-color: transparent;
		border: none;
	}
	.upload-csv {
		width: auto;
		height: 52px;
		border: 1px #d8d2d3 solid;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px ${(props) => props.theme.spacingOptions.SM}px;
		background-color: ${(props) => props.theme.palette.white.main};

		:hover {
			cursor: pointer;
		}
	}
	.upload-csv-label {
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		color: ${(props) => props.theme.palette.primary.main};
		font-weight: ${(props) => props.theme.font.weight.bold};

		:hover {
			cursor: pointer;
		}
	}
	.upload-csv-label-disabled {
		font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
		color: #d1d1d1;
		background-color: ${(props) => props.theme.palette.background.default};

		font-weight: ${(props) => props.theme.font.weight.bold};
	}
`
