import {
	CreateSuggestedOrderTypes,
	ResponseSuggestedOrdersAdmin,
	SuggestedOrdersAdminTypes,
	GroupedCategory
} from '../types/suggested-orders-admin.types'
import {
	IDualListProduct,
	ISelectedProducts,
	IDualListProductOption
} from '../../components/product-manager/types'

class SuggestedOrdersAdminMapper {
	toDomainSuggestedOrders(
		persistence: ResponseSuggestedOrdersAdmin
	): SuggestedOrdersAdminTypes {
		return {
			ID: String(persistence.ID),
			title: persistence.title,
			banner: persistence.banner,
			mainProducts: persistence.mainProducts,
			optionalProducts: persistence.optionalProducts,
			status: persistence.status,
			userId: persistence.userId
		}
	}
	toPersistence(
		domain: CreateSuggestedOrderTypes
	): CreateSuggestedOrderTypes {
		return {
			userId: domain.userId,
			title: domain.title,
			banner: {
				id:
					domain.banner.id === ''
						? String(new Date().getTime())
						: domain.banner.id,
				url: domain.banner.url
			},
			mainProducts: domain.mainProducts,
			optionalProducts:
				domain.optionalProducts?.map((optionProduct) => {
					return {
						materialId: optionProduct.materialId,
						materialQuantity: 0
					}
				}) ?? [],
			status: domain.status
		}
	}

	toDomainGroupedCategories(persistence: GroupedCategory): IDualListProduct {
		return {
			label: persistence.description,
			options: persistence.products.map((product) => {
				return {
					label: product.Description,
					value: product.MaterialID,
					materialQuantity: 1
				}
			})
		}
	}

	toDomainInitialSelectedProducts(
		categories: GroupedCategory[],
		selectedProducts: ISelectedProducts[]
	): IDualListProduct[] {
		const selectedProductsMap = new Map(
			selectedProducts.map((product) => [
				product.materialId,
				product.materialQuantity || 1
			])
		)

		const groupedProductsByCategory: Record<
			string,
			IDualListProductOption[]
		> = {}

		for (const category of categories) {
			for (const product of category.products) {
				const materialQuantity = selectedProductsMap.get(
					product.MaterialID
				)

				if (materialQuantity !== undefined) {
					if (!groupedProductsByCategory[category.description]) {
						groupedProductsByCategory[category.description] = []
					}

					groupedProductsByCategory[category.description].push({
						label: product.Description,
						value: product.MaterialID,
						materialQuantity
					})
				}
			}
		}

		return Object.entries(groupedProductsByCategory).map(
			([category, options]) => ({
				label: category,
				options
			})
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SuggestedOrdersAdminMapper()
