export enum PushTransactionalModelStatus {
	ENABLED = 'ENABLED',
	DISABLED = 'DISABLED'
}
export interface PushTransactionalProps {
	ID: string
	rule: string
	title: string
	message: string
	description: string
	status: PushTransactionalModelStatus
	createdAt: string
	createdBy: string
	modifiedAt: string
	modifiedBy: string
}

export interface IHandleChangeToggle {
	id: string
	status: string
}
