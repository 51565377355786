import styled from 'styled-components'

export const ModalContent = styled.div``

export const ParameterWrapper = styled.div`
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	padding: 8px;
	border-bottom: 1px solid ${(props) => props.theme.palette.gray.main};

	&:last-child {
		border-bottom: none;
	}
`

export const ParameterName = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-transform: uppercase;
`

export const ParameterValue = styled.span`
	display: block;
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	margin-bottom: 5px;
	text-transform: uppercase;
	text-align: right;
`
