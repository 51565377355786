import React, { useState } from 'react'
import SubTitlePage from '../../../../components/sub-title-page/sub-title-page.component'
import { SelectedOrderOptionProps } from './selected-order-option.types'
import { WrapperSelectedOption } from './selected-order-option.styles'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import DualListBoxComponent from '../../../../components/DualListBoxComponent/DualListBoxComponent.view'

function SelectedOrderOption({
	options,
	labelKey,
	valueKey,
	subtitlePage,
	textOrderData,
	searchTitle,
	firstDescription,
	secondDescription,
	handleOpenConfirmModal,
	sortedOptions,
	handleGetSortedOptions
}: SelectedOrderOptionProps) {
	const buttonType =
		sortedOptions?.length >= options?.length
			? TypeButtonEnum.PRIMARY
			: TypeButtonEnum.DISABLED

	return (
		<WrapperSelectedOption>
			<SubTitlePage subtitlePage={subtitlePage} />
			<p className="text-oder">{textOrderData}</p>
			<div className="wrapper-main-content">
				<DualListBoxComponent
					labelKey={labelKey}
					valueKey={valueKey}
					options={options}
					sortedOptions={(array) => handleGetSortedOptions(array)}
					searchTitle={searchTitle}
					firstDescription={firstDescription}
					secondDescription={secondDescription}
				/>
			</div>
			{/* Adicionar condicionais ao componente */}
			<div className="wrapper-button-confirm">
				<ButtonDefault
					buttonText="SALVAR ORDENAÇÃO"
					onClick={handleOpenConfirmModal}
					width="240px"
					height="51px"
					type={buttonType}
					idElement="confirm-order-button"
				/>
			</div>
		</WrapperSelectedOption>
	)
}

export default SelectedOrderOption
