import React from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import {
	CloseButton,
	InputField,
	Loading,
	Modal
} from '../../../shared/components'
import CheckBox from '../../../components/CheckBox'
import SelectPrimary from '../../../components/SelectPrimary'
import {
	primaryMultiselectStyles,
	reactSelectStyles
} from '../../../shared/styles/theme'
import { getDropdownButtonLabelOverride } from '../../../shared/utils/multiSelectCheckboxUtils'
import ImageUploader from './ImageUploader'
import { IModalProps } from './types'

import * as S from './RegisterProductModal.styles'
import NormalSelect from './normal-select/normal-select'
import { useFeatureFlags } from '../../../context/feature-flag/feature-flag.context'

function RegisterProductModal(props: IModalProps) {
	const {
		handleCloseModal,
		isActive,
		targetProduct,
		handleChange,
		handleChangeCategory,
		handleSelectPackageType,
		handleSelectScoreValueType,
		handleSelectUnitMeasure,
		createProduct,
		isNewProduct,
		isLoading,
		setProductImages,
		productImages,
		options,
		deleteProduct,
		isProductValid,
		channelOptions,
		handleChannel,
		handleSelectCategory,
		selectedOptionChannels,
		handleCheckbox,
		customChannel,
		handleChangeIsBonification,
		isBonification,
		isFeatureFlagBonificationActive
	} = props

	const { featureFlags } = useFeatureFlags()
	const isCategoriesFeatureFlag = featureFlags.US17241

	const DEFAULT_PROPS = {
		className: 'input',
		hasError: false,
		disabled: false
	}

	const categoriesOptions = options?.categories.map((category) => ({
		value: category.ID || '',
		label: category.description || ''
	}))

	const packageTypeOptions = options?.PackageType.map((x) => ({
		value: x,
		label: x
	}))

	const unitMeasureOptions = options?.UnitMeasure.map((x) => ({
		value: x,
		label: x
	}))

	const scoreValueOptions = options?.ScoreValueType.map((x) => ({
		value: x,
		label: x
	}))

	const keyword = isNewProduct ? 'Cadastrar' : 'Editar'

	return (
		<Modal isActive={isActive}>
			<div className="register-product-modal">
				<div className="row">
					<div className="col-12">
						<CloseButton
							onClick={handleCloseModal}
							testID={'button-close-modal'}
						/>
						<h1 className="title">{keyword} Produto</h1>
					</div>

					<div className="col-12">
						<S.WrapperSectionTitle>
							Fotos do Produto
						</S.WrapperSectionTitle>
					</div>
					<div className="col-12">
						<ImageUploader
							setProductImages={setProductImages}
							productImages={productImages}
						/>
					</div>

					<div className="col-12">
						<S.WrapperSectionTitle>
							Informações do Produto
						</S.WrapperSectionTitle>
					</div>
					<div className="col-12 col-lg-6">
						<div className="input-row">
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Código do Produto"
								id={'MaterialID'}
								value={targetProduct.MaterialID}
								type="number"
								disabled={!isNewProduct}
								data-testid="material-id-input"
							/>
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Marca"
								id={'Brand'}
								value={targetProduct.Brand}
							/>
						</div>
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Descrição"
							id={'Description'}
							value={targetProduct.Description}
							data-testid="description-input"
						/>
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Descrição Completa - Site"
							id={'SiteDescription'}
							value={targetProduct.SiteDescription}
							data-testid="site-description-input"
						/>

						<div className="input-row">
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Tamanho"
								id={'Package'}
								value={targetProduct.Package}
							/>
						</div>
						<SelectPrimary
							isLoading={false}
							isClearable={true}
							placeholder="Formato"
							options={unitMeasureOptions || []}
							value={targetProduct.UnitMeasureSale}
							onChange={handleSelectUnitMeasure}
							classNamePrefix="react-select"
							className="react-select"
							id="format"
						/>
						<SelectPrimary
							isLoading={false}
							isClearable={true}
							placeholder="TIPO"
							options={packageTypeOptions || []}
							value={targetProduct.PackageType}
							onChange={handleSelectPackageType}
							classNamePrefix="react-select"
							className="react-select"
							id="type"
						/>
						<SelectPrimary
							isLoading={false}
							isClearable={true}
							placeholder="PROPORÇÃO GANHOS DE PONTO"
							options={scoreValueOptions || []}
							value={targetProduct.ScoreValue}
							onChange={handleSelectScoreValueType}
							classNamePrefix="react-select"
							className="react-select"
							id="pointsGain"
						/>
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Pontos para o Resgate"
							id={'ScoreRate'}
							value={targetProduct.ScoreRate}
							type="number"
						/>
					</div>
					<div className="col-12 col-lg-6">
						{isCategoriesFeatureFlag ? (
							<NormalSelect
								multiple
								name="category"
								label="Categoria"
								options={categoriesOptions ?? []}
								value={targetProduct.categoryId}
								onCustomChange={(value) => {
									if (Array.isArray(value)) {
										handleChangeCategory(value)
									}
								}}
							/>
						) : (
							<SelectPrimary
								isLoading={false}
								isClearable={true}
								placeholder="Categoria"
								options={categoriesOptions || []}
								value={targetProduct.Category}
								onChange={handleSelectCategory}
								classNamePrefix="react-select"
								className="react-select"
								id="category"
							/>
						)}
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Cód. de Barras"
							id={'UnitBarcode'}
							value={targetProduct.UnitBarcode}
							type="number"
						/>
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Cód. de Barras do Pacote"
							id={'PackageBarcode'}
							value={targetProduct.PackageBarcode}
							type="number"
						/>
						<div className="input-row">
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Família"
								id={'Family'}
								value={targetProduct.Family}
							/>
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Coloração"
								id={'Coloration'}
								value={targetProduct.Coloration}
							/>
						</div>
						<InputField
							{...DEFAULT_PROPS}
							onChange={handleChange}
							label="Estilo"
							id={'Style'}
							value={targetProduct.Style}
						/>
						<div className="input-row">
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="IBU"
								id={'IBU'}
								value={targetProduct.IBU}
							/>
							<InputField
								{...DEFAULT_PROPS}
								onChange={handleChange}
								label="Teor Alcoólico"
								id={'AlcoholContent'}
								value={targetProduct.AlcoholContent}
								maxLength={6}
							/>
						</div>
					</div>
					<S.WrapperCheckbox>
						<div className="checkbox-content">
							<CheckBox
								className="materialCustom"
								type="checkbox"
								name="materialCustom"
								id="materialCustom"
								checked={customChannel}
								onChange={handleCheckbox}
								fontSize={18}
								secondaryColor="red"
								primaryColor="white"
								data-testid="custom-channel-checkbox"
							/>
							<label
								htmlFor="materialCustom"
								className="labelMaterial">
								Este produto é customizado por Canal?
							</label>
						</div>
						{customChannel && (
							<div className="select-filter">
								<label
									className="multiselect-label"
									style={{ marginTop: 8 }}>
									Selecione o Canal
								</label>
								<ReactMultiSelectCheckboxes
									options={channelOptions}
									onChange={handleChannel}
									value={selectedOptionChannels}
									getDropdownButtonLabel={
										getDropdownButtonLabelOverride
									}
									isMulti
									placeholderButtonLabel="Selecione o Canal"
									{...primaryMultiselectStyles}
									styles={reactSelectStyles}
									id={'selectedChannel'}
								/>
							</div>
						)}
					</S.WrapperCheckbox>
					{isFeatureFlagBonificationActive ? (
						<S.WrapperCheckbox>
							<div className="checkbox-content">
								<CheckBox
									className="materialCustom"
									type="checkbox"
									name="materialBonification"
									id="materialBonification"
									checked={isBonification}
									onChange={handleChangeIsBonification}
									fontSize={18}
									secondaryColor="red"
									primaryColor="white"
								/>
								<label
									htmlFor="materialBonification"
									className="labelMaterial">
									Produto exclusivo para bonificação
								</label>
							</div>
						</S.WrapperCheckbox>
					) : null}
					<div className="button-wrapper">
						{isLoading ? (
							<Loading />
						) : (
							<>
								<button
									className="red-button"
									onClick={createProduct}
									disabled={!isProductValid}>
									{keyword}
								</button>
								{!isNewProduct ? (
									<button
										className="transparent-button"
										data-testid="delete-product-button"
										onClick={deleteProduct}>
										Excluir
									</button>
								) : null}
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	)
}
export default RegisterProductModal
