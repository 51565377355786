import { Auth } from '@aws-amplify/auth'
import { store } from '../store'
import { Creators as TokenActions } from '../store/ducks/token'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { handleCognitoError } from '../utils/handleCognitoError'

export async function getCognitoSession(): Promise<CognitoUserSession> {
	const session = await Auth.currentSession()

	return session
}

export async function refreshUserSession() {
	try {
		const userCognito = await Auth.currentAuthenticatedUser()
		let session = await getCognitoSession()

		await userCognito.refreshSession(session.getRefreshToken(), () => {})

		const PromiseGetCognitoSession = new Promise<CognitoUserSession>((resolve) => {
		    setTimeout(() => {
                resolve(getCognitoSession())
            }, 1000)
        })
		
		const sessionResponse = await PromiseGetCognitoSession
		const refreshedAccessToken = sessionResponse.getIdToken().getJwtToken()
		store.dispatch(
			TokenActions.saveAccess(refreshedAccessToken),
		)
		return refreshedAccessToken
	} catch (err) {
		handleCognitoError(err)
		return ''
	}
}