import { axiosGPInstance } from '../../../shared/services/axiosInstace'

export async function getCancellationRequests() {
	try {
		const response = await axiosGPInstance.get('/salesorders/requestcancel')

		return response.data
	} catch (error) {
		throw error
	}
}

export async function changeOrderStatus(orderID: string, statusID: string) {
	try {
		const payload = {
			RequestCancelID: statusID
		}

		const response = await axiosGPInstance.post(
			`/salesorders/requestcancel/${orderID}`,
			payload
		)

		return response
	} catch (error) {
		throw error
	}
}
