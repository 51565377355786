export interface IChipWithInformationAboutTheChallengeProps {
	type: ChipTypeEnum
	label: string
	beingDisplayedInSomePurchaseFlow?: boolean
}

export interface IStylesProps {
	type?: ChipTypeEnum
	beingDisplayedInSomePurchaseFlow?: boolean
}

export enum ChipTypeEnum {
	SHOW_POINTS_EARNED = 'SHOW_POINTS_EARNED',
	SHOW_REMAINING_DAYS = 'SHOW_REMAINING_DAYS',
	IS_PARTICIPATING = 'IS_PARTICIPATING',
	NEW_CHALLENGE = 'NEW_CHALLENGE',
	COMPLETED = 'COMPLETED'
}
