import styled, { css } from 'styled-components'
import { ellipsis, toRem, transition } from '../../shared/styles/functions'
import { OmitStyledComponentsDefaultProps } from '../../shared/styles/types'
import { colorThemeProvider } from '../../theme/themeProvider'

interface IStyleProps
	extends OmitStyledComponentsDefaultProps<'tertiaryColor'> {
	labelColor: string
}

export const FloatLabelInputWrapper = styled.div<IStyleProps | any>`
	${({ styles }) => css`
		position: relative;

		label.fli-label {
			color: ${styles.secondaryColor};
			font-size: ${toRem(styles.fontSize)};
			left: 16px;
			position: absolute;
			top: 18px;

			cursor: text;

			${transition()}
		}

		input.fli-input {
			background: ${styles.backgroundColor};
			border-radius: ${styles.round ? '30px' : '4px'};
			border: solid 1px ${styles.secondaryColor};
			color: ${styles.color};
			font-size: ${toRem(styles.fontSize)};
			padding: ${styles.padding};
			width: 100%;

			${transition()}
			${ellipsis()}
			
			&:focus,
			&:valid {
				border: solid 1px ${styles.primaryColor};

				+ label {
					color: ${styles.labelColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
				}
			}

			&[disabled] {
				border: dashed 1px ${styles.secondaryColor};

				+ label {
					color: ${styles.secondaryColor};
					font-size: ${toRem(styles.fontSize - 4)};
					left: 16px;
					top: 12px;
				}
			}
		}

		.fli-counter-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			position: absolute;
			top: 33px;
		}

		.loading-wrapper {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			width: 100%;
			position: absolute;
			top: 50%;
			padding-right: 10px;
			height: 16px;
		}
	`}
`

export const CharacterCounter = styled.span<any>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	${(props) =>
		props.isActive
			? css`
					color: ${colorThemeProvider.primaryColor};
			  `
			: css`
					color: ${colorThemeProvider.grayColor};
			  `}
	margin-right: 8px;
	font-weight: ${(props) => props.theme.font.weight.medium};
`
