import React from 'react'

import { FormControl, MenuItem } from '@mui/material'

import ErrorText from '../../../../components/error-text/error-text.component'

import { NormalSelectProps } from './normal-select.types'

import useNormalSelect from './use-normal-select'

import * as S from './normal-select.styles'

export default function NormalSelect<T>({
	label,
	options,
	disabled = false,
	isLoading = false,
	multiple = false,
	onAfterChange,
	onCustomChange,
	value,
	errorMessage,
	...rest
}: NormalSelectProps<T>) {
	const {
		handleChange,
		renderAllCheckboxItem,
		renderOptionItem,
		renderValue,
		renderStartAddorment
	} = useNormalSelect({ onChange: onCustomChange, options, onAfterChange })

	const name = rest.name

	const optionsLength = options.length

	const showAllCheckBoxItem = multiple && options.length > 0

	return (
		<FormControl fullWidth sx={{ pb: 4 }}>
			<S.InputLabelStyled id="control-select-simple-id">
				{label}
			</S.InputLabelStyled>
			<S.Select
				{...rest}
				name={name}
				label={label}
				labelId="control-select-simple-id"
				renderValue={renderValue}
				onChange={handleChange}
				value={value ?? []}
				multiple={multiple}
				startAdornment={renderStartAddorment(
					isLoading,
					rest?.startAdornment
				)}
				disabled={disabled || isLoading}>
				{optionsLength === 0 && (
					<MenuItem disabled>
						<em>Sem itens disponíveis</em>
					</MenuItem>
				)}

				{showAllCheckBoxItem &&
					renderAllCheckboxItem({ optionsLength, value })}

				{options.map((option, index) =>
					renderOptionItem({
						option,
						multiple,
						value,
						index
					})
				)}
			</S.Select>
			<ErrorText error={errorMessage} />
		</FormControl>
	)
}
