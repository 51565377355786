import styled from 'styled-components'

export const TitleTypeOfProductWrapper = styled.div`
	display: flex;
	align-items: center;

	div {
		width: 7px;
		height: 7px;
		border-radius: 50%;
		margin-right: 3px;
	}
	.combo-card-badget-title {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		text-transform: uppercase;
	}
`
