import styled from 'styled-components'

export const FormCreateBannerWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	gap: 32px;

	padding: 72px 0px;

	.device-wrapper {
		width: 151px;
	}

	.behavior-content {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 24px;
	}

	.time-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 32px;
		width: 100%;
	}

	.title-section {
		color: #3d3d3d;
		font-family: Inter;
		font-size: 16px;
		font-weight: 400;
	}

	.buttons-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 32px;
		width: 338px;
	}
`