import { createElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTransactionStatus } from '../../shared/services/paymentMethod.service'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

import { IViewProps, IFinishOrderSuccess } from './types'
import Success from './view'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { Creators as CartRedemptionActions } from '../../shared/store/ducks/cartRedemptionItem'
import { Creators as CartChallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'
import { generatePaymentTermsDescription } from '../../shared/utils/money'
import { sendOrderTransactionTag } from '../../shared/utils/allin'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'

function SuccessContainer(): JSX.Element {
	const { state } = useLocation<IFinishOrderSuccess>()
	const {
		deliveryDate,
		paymentConditionID,
		paymentFormID,
		paymentType,
		valueTotal,
		paymentMethod,
		transactionId
	} = state

	const { cartRedemptionItems, carts, customer } = useTypedSelector([
		'carts',
		'cartRedemptionItems',
		'customer'
	])

	const history = useHistory()

	const [isLoading, setLoading] = useState<boolean>(
		transactionId ? true : false
	)
	const dispatch = useDispatch()

	const { data } = useTransactionStatus({
		transactionId: isLoading && transactionId ? transactionId : ''
	})

	function handleStatusPayment() {
		if (data?.transactionStatus === 'Completed') {
			let items: any[] = []
			carts.forEach((order) => {
				items.push(
					sendOrderTransactionTag(
						order.salesOrderID,
						order.items,
						customer.Email
					)
				)

				cartRedemptionItems.length > 0 &&
					items.push(
						sendOrderTransactionTag(
							order.salesOrderID,
							cartRedemptionItems,
							customer.Email
						)
					)
			})

			dispatch(CartActions.clearCart())
			dispatch(CartRedemptionActions.clearRedemptionItems())
			dispatch(CartChallengeItemsActions.clearChallengeItems())
			setLoading(false)
		}
	}

	function handleBackToProductList(): void {
		dispatch(CartActions.clearCart())
		dispatch(CartRedemptionActions.clearRedemptionItems())
		dispatch(CartChallengeItemsActions.clearChallengeItems())
		setLoading(false)
		history.replace(CustomerRoutesEnum.PRODUCT_LIST)
	}

	const paymentDetail = generatePaymentTermsDescription(
		paymentConditionID as any,
		paymentFormID as any,
		Number(valueTotal)
	)

	useEffect(handleStatusPayment, [
		data?.transactionStatus,
		cartRedemptionItems,
		carts,
		customer.Email,
		dispatch
	])

	const viewProps: IViewProps = {
		handleBackToProductList,
		deliveryDate: deliveryDate || '',
		paymentConditionID,
		paymentFormID,
		paymentType,
		valueTotal: Number(valueTotal),
		paymentMethod,
		isLoading,
		paymentDetail,
		customer
	}

	return createElement(Success, viewProps)
}

export default SuccessContainer
