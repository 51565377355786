import React from 'react'

import { CloseButton, Loading, Modal } from '../../../../shared/components'
import { IViewProps } from './types'

function ConfirmationModal(props: IViewProps) {
	const { isActive, closeModal, isApprove, updateStatus, isLoading } = props

	const keyword = isApprove ? 'aprovar' : 'recusar'
	const capsKeyword = isApprove ? 'Aprovar' : 'Recusar'

	return (
		<Modal isActive={isActive}>
			<div className="m-confirmation-modal">
				<div className="row">
					<div className="col-12">
						{isLoading ? (
							<div className="loading-wrapper">
								<Loading />
							</div>
						) : (
							<>
								<CloseButton onClick={closeModal} />
								<h2>{capsKeyword} Cadastro</h2>
								<h3>
									Você tem certeza que deseja {keyword} este
									cadastro?
								</h3>
								<div className="row">
									<div className="buttons-wrapper">
										<button
											className="white-button"
											onClick={closeModal}>
											Cancelar
										</button>
										<button
											className={
												isApprove
													? 'red-button'
													: 'dark-button'
											}
											onClick={updateStatus}>
											Sim, {capsKeyword}
										</button>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmationModal
