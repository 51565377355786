import 'react-image-lightbox/style.css'

import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'

import { IProps } from './types'

function LightBoxComponent(props: IProps) {
	const [photoIndex, setPhotoIndex] = useState(0)

	const { lightboxPhotos, isOpen, setIsOpen } = props

	return (
		<div>
			{isOpen && (
				<Lightbox
					mainSrc={lightboxPhotos[photoIndex]}
					nextSrc={
						lightboxPhotos[(photoIndex + 1) % lightboxPhotos.length]
					}
					prevSrc={
						lightboxPhotos[
							(photoIndex + lightboxPhotos.length - 1) %
								lightboxPhotos.length
						]
					}
					onCloseRequest={() => setIsOpen(false)}
					onMovePrevRequest={() =>
						setPhotoIndex(
							(photoIndex + lightboxPhotos.length - 1) %
								lightboxPhotos.length
						)
					}
					onMoveNextRequest={() =>
						setPhotoIndex((photoIndex + 1) % lightboxPhotos.length)
					}
				/>
			)}
		</div>
	)
}

export default LightBoxComponent
