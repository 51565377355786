import { Auth } from 'aws-amplify'

import handleError from '../Error/handleError'
import { ISignUp } from './types'

export async function signUp({
	documentNumber,
	password,
	email,
	phoneNumber,
	invoiceNumber,
	phoneTwo,
	sendemail,
	sendsms
}: ISignUp) {
	try {
		const response = await Auth.signUp({
			username: documentNumber,
			password,
			attributes: {
				email,
				phone_number: phoneNumber,
				'custom:invoiceNumber': invoiceNumber,
				'custom:phoneTwo': phoneTwo,
				'custom:sendemail': sendemail,
				'custom:sendsms': sendsms
			}
		})
		return response
	} catch (err) {
		handleError(err)
	}
}
