import styled from 'styled-components'

export const FileUploaderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 280px;
	width: 100%;
	position: relative;

	&:hover {
		cursor: pointer;
	}

	.upload-file {
		height: 100%;
		width: 100%;
		border: 1px dashed #fdb0b0;
		border-radius: 7px;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 7px;

		svg {
			margin-bottom: 15px;
			font-size: 3rem;
		}

		.title-upload-image {
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;
		}

		.sub-title-upload-image {
			font-weight: 500;
			font-size: 10px;
			line-height: 12px;
		}
	}

	.gradient {
		border-radius: 4px;

		position: absolute;
		height: 280px;
		width: 100%;
		background-color: transparent;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%);

		z-index: 1;
	}

	.img {
		border-radius: 4px;
		height: 100%;
		width: 100%;
	}

	.image-info-wrapper {
		width: 100%;
		padding: 0px 20px;

		position: absolute;
		bottom: 20px;
		z-index: 3;

		display: flex;
		align-items: center;
		justify-content: space-between;

		.image-info {
			display: flex;
			align-items: flex-start;
			justify-content: space-around;
			flex-direction: column;
			gap: 6px;

			.image-detail-name {
				font-weight: 400;
				font-size: 0.875rem;
				line-height: 1.063rem;
				color: ${(props) => props.theme.palette.white.main};
			}

			.image-detail-size {
				font-weight: 400;
				font-size: 0.625rem;
				line-height: 1.063rem;
				color: ${(props) => props.theme.palette.white.main};
			}
		}

		.delete-image {
			font-size: 1.375rem;
			color: '#ffff';
		}
		.delete-image:hover {
			cursor: pointer;
		}
	}
`
