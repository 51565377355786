export function formatEnvForFirebase(env: string): string | undefined {
  const envs: { [key: string]: string } = {
    dev: "dev",
    hml: "homolog",
    prd: "prod",
    local: "local"
  };

  if (envs.hasOwnProperty(env)) {
    return envs[env];
  } else {
    return undefined;
  }
}