import { Styles, StylesConfig } from 'react-select'
import { colorThemeProvider } from '../../theme/themeProvider'
import { DefaultStyles } from './types'

export const colors = {
	red: '#CE332E',
	darkRed: '#991717',
	lightRed: '#FFA5A5',
	redExtraLight: '#FFD6D6',
	grey: '#D1D1D1',
	darkGrey: '#444444',
	white: '#fff',
	whiteLight: '#FAFAFA',
	black: '#000',
	blackLight: '#272927',
	lightGrey: '#E6E6E6',
	secondGrey: '#5D615D',
	thirdGrey: '#858585',
	fourthGrey: '#D1D1D1',
	fifthGray: '#F9F9F9',
	sixthGray: '#979E97',
	greyExtraLight: '#EEEEEE',
	green: '#5EA715',
	buttonGreenHover: '#8dc05a',
	lightGreen: '#ECFAC8',
	colorReturnable: '#0CA6BF',
	scoreValueLabelBg: '#808080',
	scoreValueLabelText: '#528C18',
	pointsProgramBgColor: '#801318',
	progressBarBg: '#F0F0F0'
}

export const primaryButtonStyle: DefaultStyles = {
	primaryColor: colors.white,
	backgroundColor: colorThemeProvider.primaryColor,
	padding: [15, 40, 15, 40],
	fontSize: 11,
	fontFamily: 'Roboto'
}

export const secondaryButtonStyle: DefaultStyles = {
	primaryColor: colorThemeProvider.primaryColor,
	backgroundColor: colors.white,
	padding: [15, 40, 15, 40],
	fontSize: 11,
	fontFamily: 'Roboto'
}

export const primaryInputStyle: DefaultStyles = {
	primaryColor: colors.black,
	backgroundColor: 'transparent',
	fontSize: 15,
	fontFamily: 'Roboto'
}

export const primaryPushNotificationInputStyle: DefaultStyles = {
	primaryColor: colorThemeProvider.primaryColor,
	tertiaryColor: 'yellow',
	backgroundColor: 'transparent',
	fontSize: 15,
	fontFamily: 'Roboto'
}

export const primaryMultiselectStyles = {
	alignContent: 'flex-end',
	backgroundColor: 'transparent',
	fontFamily: 'Montserrat Regular',
	fontSize: 16,
	padding: [26, 16, 10, 16],
	primaryColor: colorThemeProvider.primaryColor,
	secondaryColor: colorThemeProvider.primaryColor
}

export const primaryTextareaStyle: DefaultStyles = {
	secondaryColor: colorThemeProvider.grayColor,
	backgroundColor: 'transparent',
	fontSize: 15,
	fontFamily: 'Roboto'
}

export const reactSelectStyles: StylesConfig & {
	dropdownButton?: any
} = {
	container: (styles, state) => ({
		...styles,
		background: colors.white,
		boxShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.25)'
	}),
	option: (styles) => ({
		...styles,
		background: colors.white,
		color: colorThemeProvider.blackColor,
		cursor: 'pointer',
		transition: 'all 0.2s',
		fontWeight: 500,
		fontSize: 12,
		':hover': {
			background: colors.white,
			color: colors.thirdGrey
		}
	}),
	groupHeading: (styles: any, state: any) => ({
		...styles,
		textTransform: 'capitalize',
		color: colorThemeProvider.blackColor,
		fontSize: 14
	}),
	dropdownButton: (styles: any) => ({
		...styles,
		width: '100%',
		height: 55,
		padding: 16,
		border: `1px solid ${colors.fourthGrey}`,
		borderRadius: ' 4px',
		boxShadow: 'none',
		background: '#F9F9F900'
	}),
	control: () => ({
		display: 'none'
	}),
	menu: (styles, state) => ({
		...styles
	}),
	input: (styles) => ({
		...styles,
		width: '100%'
	})
}

export const reactSelectStylesProgramPointsRulesAndChallenges: StylesConfig & {
	dropdownButton?: any
} = {
	...reactSelectStyles,
	dropdownButton: (styles: any) => ({
		...styles,
		width: '100%',
		height: 55,
		paddingTop: 16,
		paddingBottom: 10,
		paddingRight: 16,
		paddingLeft: 16,
		border: `1px solid ${colors.fourthGrey}`,
		borderRadius: ' 4px',
		boxShadow: 'none',
		background: '#F9F9F900'
	})
}

export const reactSelectCreatableStyle: Partial<Styles> = {
	container: (styleProps) => ({
		...styleProps,
		minHeight: 55
	}),
	control: (styleProps) => ({
		...styleProps,
		minHeight: 55,
		backgroundColor: colors.white,
		cursor: 'pointer',
		border: `1px solid ${colors.fourthGrey}`
	}),
	placeholder: (styleProps) => ({
		...styleProps,
		fontSize: 14,
		color: '#515151'
	}),
	indicatorSeparator: (styleProps) => ({
		...styleProps,
		display: 'none'
	}),
	menu: (styles, state) => ({
		...styles,
		zIndex: 10
	}),
}

export const reactSelectCreatableStyleProgramPointsRulesAndChallenges: Partial<Styles> =
	{
		...reactSelectCreatableStyle,
		control: (styleProps) => ({
			...styleProps,
			minHeight: 55,
			paddingTop: 8,
			backgroundColor: colors.fifthGray,
			cursor: 'pointer',
			border: `1px solid ${colors.fourthGrey}`
		}),
	}