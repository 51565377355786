import React from 'react'
import { ButtonExpandProps } from './button-expand.types'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import * as S from './button-expand.styles'

function ButtonExpand({
	isEpxanded,
	isDisabled,
	handleChange
}: ButtonExpandProps) {
	return (
		<>
			{isEpxanded ? (
				<S.ButtonDecressWrapper onClick={handleChange}>
					CADASTRAR BANNER <RemoveIcon />
				</S.ButtonDecressWrapper>
			) : (
				<S.ButtonExpandedWrapper
					onClick={handleChange}
					disabled={isDisabled}>
					CADASTRAR BANNER <AddIcon />
				</S.ButtonExpandedWrapper>
			)}
		</>
	)
}

export default ButtonExpand
