import { axiosGPInstance } from '../../shared/services/axiosInstace'
import { IFetchCompanyProductsResponse } from '../../shared/interfaces/response'

export async function fetchCompanyProducts(
	id: string
): Promise<IFetchCompanyProductsResponse[]> {
	try {
		const route = `/prices?salesOrganizationID=${id}`

		const products = await axiosGPInstance.get(route)

		return products?.data
	} catch (error) {
		throw error
	}
}

export async function saveParams(id: string, params: any[]): Promise<any> {
	try {
		const response = await axiosGPInstance.post(
			`/parameterscompanies/${id}`,
			params
		)

		return response.data
	} catch (error) {
		throw error
	}
}

export async function uploadCSV(
	formData: FormData,
	resaleID: string
): Promise<any> {
	try {
		const response = await axiosGPInstance.post(
			`/prices/upload/${resaleID}`,
			formData
		)

		return response.data
	} catch (error) {
		throw error
	}
}
