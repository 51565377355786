import styled from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const ActionBarWrapper = styled.section`
	width: 100%;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: space-between;
`

export const DecrementButton = styled.button`
	width: 38px;
	border: none;
	padding: 10px;
	cursor: pointer;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: ${colors.secondGrey};
	transition: all ease-in-out 300ms;

	&:hover {
		background-color: ${colors.darkGrey};
	}

	> span {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		color: ${colors.white};
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${colors.fourthGrey};
	}

	display: flex;
	align-items: center;
	justify-content: center;
`

export const InputWithValueWrapper = styled.div`
	background-color: ${colors.fifthGray};
	padding: 10px;

	> input[type='number'] {
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		border: none;
		max-width: 28px;
		max-height: 37px;
		text-align: center;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${colors.blackLight};
		background-color: transparent;

		-moz-appearance: textfield;
		appearance: textfield;
	}
`

export const IncrementButton = styled(DecrementButton)`
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`

export const IncrementAndDecrementButtonsWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`

export const AddButton = styled.button`
	width: 100%;
	border: none;
	padding: 12px 19px;
	max-width: 96px;
	cursor: pointer;
	color: ${colors.white};
	text-transform: uppercase;
	background-color: ${colors.green};
	border-radius: 4px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	box-shadow: 0px 2px 3px #0000003d;
	transition: all ease-in-out 300ms;

	&:hover {
		background-color: ${colors.buttonGreenHover};
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${colors.fourthGrey};
	}
`
