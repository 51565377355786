import { useMutation } from 'react-query'
import { queryClient } from '../../../../../config/query-client.config'
import { ReturnSelectOption } from '../../services/types/categories.types'
import { TModalReviewCouponView } from './modal-review-coupon.types'
import CouponService from '../../services/coupon.service'
import { useHistory } from 'react-router-dom'
import { AdminRoutesEnum } from '../../../../../modules/Routes/adminRoutesEnum'

import { format } from 'date-fns'
import { FULL_TIME_DATE_PT_BR } from '../../../../../utils/constants/date-format'
import { toMoney } from '../../../../../shared/utils/money'
import { formatCurrencyToNumber } from '../../utils/format-currency-to-number'

export default function useModalReviewCoupon({
	couponInfo,
	cumulative,
	discountType,
	discountValue,
	showInApp,
	type,
	productsList,
	getValues,
	couponId,
	usersImpacted
}: TModalReviewCouponView) {
	const history = useHistory()
	const quantityCouponInfo =
		(couponInfo.quantityAvailable === 'Ilimitado'
			? 'Ilimitado'
			: couponInfo.quantityCoupons) ?? '--'

	const shouldShowInApp = showInApp === 'app' ? 'Sim' : 'Não'

	const formattedDiscountValue =
		discountType === 'percent' ? `${discountValue}%` : toMoney(formatCurrencyToNumber(discountValue))

	const typeCoupon = type === 'cart' ? 'Carrinho' : 'Produto'

	const showProductsSection = type === 'product'

	const isCumulative = cumulative === 'true' ? 'Sim' : 'Não'

	const categoriesOptions =
		queryClient.getQueryData<ReturnSelectOption[] | undefined>([
			'categories'
		]) ?? []

	const categoriesMapper: Record<string, string> = categoriesOptions.reduce(
		(acc, option) => {
			acc[option.value] = option.label
			return acc
		},
		{} as Record<string, string>
	)

	const formattedProductList = productsList
		.flatMap((item) => item.options)
		.map((product) => product.label)

	const { mutate: handleCreateCoupon, isLoading: loadingCreateCoupon } =
		useMutation({
			mutationFn: async () => {
				const form = getValues()
				const res = await CouponService.create(form,usersImpacted)

				return res
			},
			onError(error) {
				console.error(error)
			},
			onSuccess() {
				history.push(AdminRoutesEnum.HISTORY_COUPON, {
					state: 'couponSucessMessage'
				})
			}
		})

	const { mutate: handleEditCoupon, isLoading: loadingEditCoupon } =
		useMutation({
			mutationFn: async () => {
				const form = getValues()
				const res = await CouponService.editCoupon(form, couponId)

				return res
			},
			onError(error) {
				console.error(error)
			},
			onSuccess() {
				history.push(AdminRoutesEnum.HISTORY_COUPON, {
					state: 'couponSucessMessage'
				})
			}
		})

	const formatDate = (date?: string | Date | null) => {
		if (!date) return ''

		if (date.toString() === 'Invalid Date') return ''

		if (typeof date === 'object') {
			return format(date, FULL_TIME_DATE_PT_BR)
		}
		return date
	}

	const conditionsModalReviewCoupon = {
		quantityCouponInfo,
		shouldShowInApp,
		formattedDiscountValue,
		typeCoupon,
		showProductsSection,
		isCumulative
	}
	return {
		conditionsModalReviewCoupon,
		categoriesMapper,
		formattedProductList,
		handleCreateCoupon,
		handleEditCoupon,
		loadingEditCoupon,
		loadingCreateCoupon,
		formatDate
	}
}
