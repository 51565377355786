const amplifyConfig = {
	aws_cognito_region: 'us-east-1',
	aws_user_pools_id: 'us-east-1_3npjzFJn0',
	aws_user_pools_web_client_id: '6g1nqr3e4ar82oddgqq3jsn1mn'

	// Auth: {
	// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
	// identityPoolId: 'us-east-1:4d064d1f-6cce-4a97-a1d5-631c51c07df9',
	// REQUIRED - Amazon Cognito Region
	// region: 'us-east-1'

	// OPTIONAL - Amazon Cognito Federated Identity Pool Region
	// Required only if it's different from Amazon Cognito Region
	//identityPoolRegion: 'XX-XXXX-X',
	// OPTIONAL - Amazon Cognito User Pool ID
	// userPoolId: 'XX-XXXX-X_abcd1234',
	// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
	// userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',
	// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
	// mandatorySignIn: false,
	// OPTIONAL - Configuration for cookie storage
	// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
	// cookieStorage: {
	// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
	// 	domain: '.yourdomain.com',
	// 	// OPTIONAL - Cookie path
	// 	path: '/',
	// 	// OPTIONAL - Cookie expiration in days
	// 	expires: 365,
	// 	// OPTIONAL - Cookie secure flag
	// 	// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
	// 	secure: true
	// },
	// OPTIONAL - customized storage object
	// storage: new MyStorage(),
	// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
	// authenticationFlowType: 'USER_PASSWORD_AUTH',
	// OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
	// clientMetadata: { myCustomKey: 'myCustomValue' },
	// OPTIONAL - Hosted UI configuration
	// oauth: {
	// 	domain: 'your_cognito_domain',
	// 	scope: [
	// 		'phone',
	// 		'email',
	// 		'profile',
	// 		'openid',
	// 		'aws.cognito.signin.user.admin'
	// 	],
	// 	redirectSignIn: 'http://localhost:3000/',
	// 	redirectSignOut: 'http://localhost:3000/',
	// 	responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
	// }
	// }
}

module.exports = amplifyConfig
