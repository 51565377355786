import axios from 'axios'
import {
	ICheckCustomerResponse,
	IRequestCustomerOCRValidation,
	ISendDocumentsPF,
	ISendDocumentsPJ,
	IUserSignUpTrackingGPClient,
	IUserSignUpTrackingNewClient
} from './types'
import {
	apiAdmin,
	apiCustomer,
	getJwtAuthGpAcessToken
} from '../../shared/services/axiosInstace'
import { getAmbienteName } from '../../utils/getApiBaseUrl.utils'

const queryParamEnum: { [id: number]: string } = {
	11: 'personalFiscalID',
	14: 'companyFiscalID'
}
export async function userExists(
	documentNumber: string
): Promise<ICheckCustomerResponse[] | null> {
	try {
		const queryParamName = queryParamEnum[documentNumber.length]
		const api = getAmbienteName() === 'local' ? apiCustomer : apiAdmin
		const response = await api.get(
			`/customers/checkcustomerregister?${queryParamName}=${documentNumber}`
		)
		return response.data
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function registerUser(
	user: IUserSignUpTrackingGPClient | IUserSignUpTrackingNewClient,
	documents?: ISendDocumentsPF | ISendDocumentsPJ
) {
	try {
		// TODO LOCAL
		// const response = await axiosInstance.post(
		// 	`/signup-tracking`,
		// 	user
		// )
		// TODO DEV/PROD
		const response = await apiAdmin.post(`/signup-tracking/register-user`, {
			...user,
			...documents
		})

		return response
	} catch (error) {
		console.error({ error })
		return null
	}
}

export async function OCRCustomerValidation(
	payload: IRequestCustomerOCRValidation
): Promise<void> {
	const accessToken = await getJwtAuthGpAcessToken()
	const customerOCRValidation = { ...payload }

	await apiAdmin.post(
		`/customers/ocr-customer-documents`,
		{
			customerOCRValidation
		},
		{
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		}
	)
}

export interface IRequestValidatorDocumentNumber {
	documentNumber: string
	birthDate?: string
}

export interface IResponseValidatorDocumentNumber {
	status: string
	errorMessage: string
}

export async function getStatusPersonalOrCompanyFiscal(
	props: IRequestValidatorDocumentNumber
): Promise<IResponseValidatorDocumentNumber | undefined> {
	try {
		const accessToken = await getJwtAuthGpAcessToken()

		const response = await apiAdmin.get(
			`/customers/validationsim?documentNumber=${props.documentNumber}&birthDate=${props.birthDate}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		)

		return response.data
	} catch (err) {
		console.error({ err })
	}
}

export interface IResponseUploadSignUp {
	url: string
}

export async function sendImagesToS3(
	base64: string,
	fileName: string
): Promise<IResponseUploadSignUp | undefined> {
	const response = await axios.post(
		`${'https://icysbtqjj1.execute-api.sa-east-1.amazonaws.com/prod/bucketbpgpSignup'}`,
		{
			fileName,
			file: base64.replace(/data:[^;]+;base64,/, '')
		},
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}
	)

	return response.data
}
