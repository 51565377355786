import styled from 'styled-components'

export const VideoPlayerButtonWrapper = styled.div`
	width: 100%;
	height: 55px;
	padding: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;

	border-radius: 5px;
	border: 1px dashed #d8d2d3;
	background: #fff;

	&:hover {
		cursor: pointer;
	}

	svg {
		color: #b7b7b7;
	}

	.selected-video-label {
		color: #CE332E;
		font-size: 14px;
		font-weight: 500;
	}

	.sub-title-label {
		color: #b7b7b7;
		font-size: 10px;
		font-weight: 500;
	}
`

export const VideoPlayerWithData = styled.div`
	width: 100%;
	height: 55px;

	border-radius: 5px;
	border: 1px solid #d8d2d3;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;

	.player-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;

		p {
			color: #ce332e;
			font-size: 14px;
			font-weight: 500;
		}
	}
	svg {
		color: #ce332e;

		&:hover {
			cursor: pointer;
		}
	}

	.player-view-wrapper {
		display: flex;
		align-items: center;
		gap: 8px;

	}
	.video-label {
		color: #ce332e;
		font-size: 14px;
		font-weight: 500;
	}
`
