import styled, { keyframes } from 'styled-components';

import DoneIcon from '@mui/icons-material/Done'

interface WrapperTostSucessProps {
  fade: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
`;

export const WrapperTostSucessModal = styled.div<WrapperTostSucessProps>`
  width: 100vw;
  background: #effaef;
  position: absolute;
  top: 80px;
  display: flex;
  align-items: center;
  padding: 16px 72px;
  gap: 16px;
  p {
    color: ${(props) => props.theme.palette.black.main};
    font-size: 14px;
    font-weight: 500;
  }
  opacity: ${({ fade }) => (fade ? 1 : 0)};
  animation: ${({ fade }) => (fade ? fadeIn : fadeOut)} 0.2s ease-in-out;
`;

export const DoneIconStyles = styled(DoneIcon)`
	color: ${(props) => props.theme.palette.success.main};
`
