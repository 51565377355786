export interface IOrderDetail {
	AutoIncBackendID: number
	CreatedAt: string
	CustomerID: string
	DeliveryDate: string
	InvoiceNumber: string
	Items: Array<IOrderItem>
	ItemsPoints: Array<IOrderItem>
	ItemsChallenge?: Array<IOrderItem>
	OrderStatus: Array<IOrderStatus>
	PaymentConditionID: string
	PaymentFormID: string
	RequestsCancelStatus: IRequestsCancelStatus[]
	SalesOrderID: string
	SalesOrderLegacyID: string
	SalesOrganizationID: string
	TaxRate: number
	orderSource: OrderSourceEnum
	removedItems?: IRemovedItem[]
}

export enum OrderSource {
	VB2B = 'VB2B', // Portal
	VB2A = 'VB2A' // APP
}
export type OrderSourceEnum = keyof typeof OrderSource

export interface IOrderStatus {
	AlertStatus: string
	EventDateTime: string
	FlowStatus: string
	OrderStatusDescription: string
	OrderStatusDetail: string
	OrderStatusID: string
}

export interface IRequestsCancelStatus {
	CreatedAt: string
	ModifiedAt: string
	requestCancelStatusDescription: string
	requestCancelStatusID: string
}

// ** Same as IItem from ISaleOrder but not capitalized
// ** (We receive different than we send - dictated by server)
export interface IOrderItem {
	Description: string
	ImageUrl: string
	Package: string
	Price: number
	ProductID: string
	Quantity: number
	SalesOrderID: string
	SalesOrderItem: string
	UnitMeasureSale: string
	TaxValue: number
	operationType: string
	scoreRate?: string
	challengeID?: string
}

export interface IRemovedItem {
	SalesOrganizationID: string
	CustomerID: string
	OrderNumberPortal: string
	OrderNumberLegacy: string
	productID: string
	SiteDescription: string
	EventDateTime: string
}
