type TAmbientes = {
	[key: string]: string
}

// Admin
export const apiAdminBaseUrl: TAmbientes = {
	local: 'http://localhost:8081/v1',
	dev: 'https://ix3o67i359.execute-api.sa-east-1.amazonaws.com/dev/api/v1/bomparceirogpadmin',
	hml: 'https://ix3o67i359.execute-api.sa-east-1.amazonaws.com/stg/api/v1/bomparceirogpadmin',
	prd: 'https://cv5zzq7x01.execute-api.sa-east-1.amazonaws.com/prd/api/v1/bomparceirogpadmin'
}

// Customer
export const apiCustomerBaseUrl: TAmbientes = {
	local: 'http://localhost:8080/v1',
	dev: 'https://8el3vrz76d.execute-api.sa-east-1.amazonaws.com/dev/api/v1/bomparceirogp',
	hml: 'https://8el3vrz76d.execute-api.sa-east-1.amazonaws.com/stg/api/v1/bomparceirogp',
	prd: 'https://i9pgm6h6u9.execute-api.sa-east-1.amazonaws.com/prd/api/v1/bomparceirogp'
}

// Lambda de manutenção
export const apiLambdaUrl: TAmbientes = {
	local: 'https://mat64n2e80.execute-api.sa-east-1.amazonaws.com/dev',
	dev: 'https://mat64n2e80.execute-api.sa-east-1.amazonaws.com/dev',
	hml: 'https://om22g431sj.execute-api.sa-east-1.amazonaws.com/stg',
	stg: 'https://om22g431sj.execute-api.sa-east-1.amazonaws.com/stg',
	prd: 'https://vgbkagq1gh.execute-api.sa-east-1.amazonaws.com/prd'
}
