import React from 'react'
import { FileUploader } from 'react-drag-drop-files'

import UploadIcon from '../../assets/images/upload.svg'
import TrashIcon from '../../assets/images/delete.svg'

import { IImageUploadProps } from './types'
import { ImageUploadWrapper } from './styles'

export default function ImageUploadComponent(props: IImageUploadProps) {
	const {
		width,
		returnImage,
		imageUrl,
		id,
		handlerImageURL,
		editionDisabled,
		dimensions
	} = props
	const fileTypes = ['JPG', 'PNG', 'GIF', 'JPEG']

	function handleInputChange(file: any) {
		returnImage({
			file: file,
			url: URL.createObjectURL(file)
		})
	}

	function handleRemoveImage() {
		handlerImageURL('')
		returnImage({ file: null, url: '' })
	}

	return (
		<ImageUploadWrapper containerWidth={width}>
			<label className="label" htmlFor={id}>
				{imageUrl ? (
					<div className="preview-image">
						<img src={imageUrl} alt="Imagem do Banner" />
						<div />
					</div>
				) : (
					<FileUploader
						name="file"
						types={fileTypes}
						handleChange={handleInputChange}
						children={
							<div className="placeholder">
								<img src={UploadIcon} alt="upload-icon" />
								<span>Arraste ou selecione a imagem</span>
								<span style={{ color: '#F59393' }}>
									RESOLUÇÃO MÁXIMA: {dimensions}
								</span>
							</div>
						}
					/>
				)}
			</label>

			{imageUrl && !editionDisabled ? (
				<button className="trash-icon" onClick={handleRemoveImage}>
					<img src={TrashIcon} alt="trash-icon" />
				</button>
			) : null}
		</ImageUploadWrapper>
	)
}
