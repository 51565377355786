import React from 'react'

import { CloseButton, Modal } from '../../../shared/components'

function ConfirmDeleteParamModal(props: any) {
	const { closeModal, confirmAction, isActive, paramName } = props

	return (
		<Modal isActive={isActive}>
			<div className="delete-param-confirmation-modal col-12">
				<CloseButton onClick={closeModal} />

				<p className="modal-title">{'Excluir Parâmetro'}</p>
				<p className="param-name">{paramName}</p>
				<p className="confirm-text">
					{'Você tem certeza que deseja cancelar este parâmetro?'}
				</p>

				<div className="buttons-wrapper">
					<button className="transparent-button" onClick={closeModal}>
						{'CANCELAR'}
					</button>

					<button className="dark-button" onClick={confirmAction}>
						{'EXCLUIR'}
					</button>
				</div>
			</div>
		</Modal>
	)
}

export default ConfirmDeleteParamModal
