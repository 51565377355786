import React, { Fragment } from 'react'
import { IViewProps } from './types'
import Loading from '../../components/Loading'
import { Header, InputField } from '../../shared/components'

import ArrowLeftBlackIcon from '../../assets/images/arrow-left-black.svg'
import { checkEmail, checkEmailMatch } from '../../shared/utils/validators'

export default function changeEmail(props: IViewProps): JSX.Element {
	const {
		handleBack,
		setEmail,
		setEmailConfirmation,
		email,
		emailConfirmation,
		handleSubmit,
		buttonActive,
		loading
	} = props

	return (
		<Fragment>
			<Header />
			<div className="change-personal-email-page">
				<div className="col-10">
					<div className="row offset-lg-1">
						<button
							className="back-arrow-wrapper"
							onClick={handleBack}>
							<img
								className="back-arrow"
								color={'#ffffff'}
								src={ArrowLeftBlackIcon}
								alt="Voltar"
							/>
						</button>
					</div>
					<div className="row offset-lg-1">
						<h1 className="page-title">Alterar Email</h1>
					</div>
					<div className="centralizer">
						<div className="col-lg-5 col-sm-12">
							<InputField
								className="param-input"
								id="name"
								label="novo E-mail"
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								hasError={
									!checkEmail(email) &&
									email.length >= 1
								}
								errorMessage="E-mail inválido"
								disabledCopyPaste={true}
							/>
						</div>
						<div className="col-lg-5 col-sm-12">
							<InputField
								className="param-input"
								id="name"
								label="Confirmação de E-mail"
								onChange={(e) =>
									setEmailConfirmation(e.target.value)
								}
								value={emailConfirmation}
								hasError={
									!checkEmailMatch(
										emailConfirmation,
										email
									) && emailConfirmation.length >= 1
								}
								errorMessage="E-mails não estão iguais"
								disabledCopyPaste={true}
							/>
						</div>
					</div>

					{loading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<div className="button-container">
							<button
								className="red-button"
								disabled={!buttonActive}
								onClick={handleSubmit}>
								{'SALVAR EMAIL'}
							</button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}
