import React from 'react'
import { LoadingContaierProps } from './loading-container.types'
import Loading from '../Loading'
import * as S from './loading-container.styles'

function LoadingCcontainer({ isLoading }: LoadingContaierProps) {
	if (!isLoading) {
		return null
	}
	return (
		<>
			<S.WrapperLoading>
				<Loading color="#CE332E" />
			</S.WrapperLoading>
		</>
	)
}

export default LoadingCcontainer
