import styled from 'styled-components'
import { theme } from '../../theme/themeProvider'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditNoteIcon from '@mui/icons-material/EditNote';

interface IconProps {
	isDisabled: boolean | undefined
}

export const WrapperView = styled.div`
	.title {
		color: #5d615d;
		font-weight: 400;
		font-size: 1.5rem;
		font-style: normal;
	}

	.inputAndButtonWrapper {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
	}

	.banner {
		display: flex;
		align-items: center;
	}

	.imageBanner {
		width: 62px;
		height: 35px;
		border-radius: 4px;
		margin-right: 16px;
	}

	.create-banner-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;

		margin-top: 32px;
		.sub-title-banner {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			gap: 24px;

			h2 {
				color: #3d3d3d;
				font-size: 18px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			p {
				color: #3d3d3d;
				font-family: Inter;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
	}

	.button-container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 19px 0px;

		.search-input-wrapper-banner {
			width: 361px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.date-input-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
		}

		.data-selector-wrapper{
			width: 100px;
		}
	}
`

export const DetailsBanner = styled.p`
	color: ${theme.palette.primary.main};
	cursor: pointer;
	font-weight: 700;
`

export const DeleteIcon = styled(DeleteOutlineIcon) <IconProps>`
	color: ${(props) =>
		!props.isDisabled
			? theme.palette.primary.main
			: theme.palette.lightGray.main};
	&:hover {
		cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'not-allowed')};
	}
`

export const EditIcon = styled(EditNoteIcon) <IconProps>`
	color: ${(props) =>
		!props.isDisabled
			? theme.palette.primary.main
			: theme.palette.lightGray.main};
	&:hover {
		cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'not-allowed')};
	}
`
