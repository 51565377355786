import styled from 'styled-components'

export const WrapperHelpModal = styled.div`
	gap: 24px;
	.header-modal {
		width: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 70px;

		.close-button {
			position: absolute;
			right: 24px;
			top: 10px;
			font-size: 32px;
			color: ${(props) => props.theme.palette.black.main};
		}

	}

	.main-modal {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 24px;
		flex-direction: column;
		padding: 0px 24px;

		h2 {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
      font-weight: ${(props) => props.theme.font.weight.bold};
	  padding: 24px 0px;
		}

		p {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			font-weight: ${(props) => props.theme.font.weight.semibold};
			line-height: 24px;

			strong {
				color: ${(props) => props.theme.palette.primary.main};
				font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			}
		}

		.wrapper-cell {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: ${(props) => props.theme.palette.primary.main};
				text-align: center;
				font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
				font-weight: ${(props) => props.theme.font.weight.bold};
			}
		}
	}
`