import styled from 'styled-components'

export const BedgeParametersSpan = styled.span`
	display: block;
	background-color: transparent;
	border: 1px solid ${(props) => props.theme.palette.gray.main};
	padding: 4px 8px;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	text-align: center;
	max-width: 102px;
	color: ${(props) => props.theme.palette.gray.main};
	border-radius: 10px;
`