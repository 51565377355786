import styled from 'styled-components'
import { mediaQuery } from '../../shared/styles/functions'
import { colorThemeProvider } from '../../theme/themeProvider'

export const HeaderWrapper = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 32px 80px;
	height: 105px;
	border-bottom: 1px solid ${(props) => props.theme.palette.primaryWhite.main};
	h2 {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-type: (1);
		color: ${colorThemeProvider.grayColor};
	}

	${mediaQuery('sm')(`
        padding: 32px 16px;

    `)}
`
