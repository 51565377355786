import React, { createElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { useBoolean } from '../../shared/hooks/useBoolean'
import { logout } from '../../shared/services/axiosInstace'
import {
	Creators as CustomerActions,
	INITIAL_STATE as DEFAULT_CUSTOMER
} from '../../shared/store/ducks/customer'
import { redirectRoute } from '../../shared/utils/redirect'

import { authUser, userComplementData } from './service'
import { IViewProps, ScreenStateEnum } from './types'
import GPLogin from './view'

import sacBackground from '../../assets/images/background-sac.png'

// import { Auth } from 'aws-amplify'
// import handleError from '../Error/handleError'

function GPLoginContainer(): JSX.Element {
	const history = useHistory()
	const dispatch = useDispatch()

	const background = sacBackground

	const [enrollment, setEnrollment] = useState('')
	const [password, setPassword] = useState('')
	const [, setPasswordIsValid] = useState(false)
	const [screenState, setScreenState] = useState<ScreenStateEnum>('LOGIN')
	const [isLoading, startLoading, stopLoading] = useBoolean(false)

	// const [, setConfirmationCode] = useState('')
	// const [confirmationCodeIsValid, setConfirmationCodeIsValid] = useState(
	// 	false
	// )
	// const [documentIsValid, setDocumentIsValid] = useState(false)
	// const [, setNewPassword] = useState('')
	// const [newPasswordIsValid, setNewPasswordIsValid] = useState(false)

	function handleEnrollment(e: React.ChangeEvent<HTMLInputElement>) {
		setEnrollment(e.target.value)
	}

	async function handleLogin() {
		startLoading()
		try {
			const userResponse = await authUser({
				user: enrollment,
				password: password
				//applicationName: 'PORTALB2BADMIN'
			})

			if (!userResponse) return
			
			const { group, companies } =  await userComplementData(enrollment)

			const [defaultCompany] = companies ?? []
			dispatch(
				CustomerActions.updateCustomer({
					...DEFAULT_CUSTOMER,
					CustomerID: enrollment,
					Type: group,
					companies: companies,
					activeSalesOrganizationID: defaultCompany?.salesOrganizationID
				})
			)
			history.replace(redirectRoute(group))
		} finally {
			stopLoading()
		}
	}

	// async function handleRecover() {
	// 	// TODO: Integrate with Cognito
	// 	setScreenState('CONFIRMATION')
	// }

	// async function handleConfirmation() {
	// 	// TODO: Integrate with Cognito
	// 	setScreenState('SUCCESS')
	// }

	async function handleSuccess() {
		setScreenState('LOGIN')
		resetForm()
	}

	function loginIsDisabled(): boolean {
		// if (!documentIsValid || !passwordIsValid) return true
		const isEnrollmentValid = enrollment.length > 0

		const isPasswordValid = password.length > 0

		return !(isEnrollmentValid && isPasswordValid)
	}

	// function recoverIsDisabled(): boolean {
	// 	if (!documentIsValid) return true

	// 	return false
	// }

	// function confirmationIsDisabled(): boolean {
	// 	if (!confirmationCodeIsValid || !newPasswordIsValid) return true

	// 	return false
	// }

	// function handleRecoverPassword() {
	// 	setScreenState('RECOVER')
	// 	resetForm()
	// }

	function handleBackArrow() {
		setScreenState('LOGIN')
		resetForm()
	}

	function resetForm() {
		setEnrollment('')
		setPassword('')
		// setNewPassword('')
		// setConfirmationCode('')
	}

	useEffect(() => {
		logout()
	}, [])

	const viewProps: IViewProps = {
		background,
		// confirmationIsDisabled,
		enrollment,
		handleBackArrow,
		// handleConfirmation,
		handleLogin,
		// handleRecover,
		// handleRecoverPassword,
		handleSuccess,
		loginIsDisabled,
		// recoverIsDisabled,
		screenState,
		// setConfirmationCode,
		// setConfirmationCodeIsValid,
		// setDocumentIsValid,
		// setNewPassword,
		// setNewPasswordIsValid,
		setPassword,
		setPasswordIsValid,
		handleEnrollment,
		isLoading
	}

	return createElement(GPLogin, viewProps)
}

export default GPLoginContainer
