import React, { ReactNode, createContext, useCallback, useState } from 'react';

type CustomerInfoContextProps = {
  storedData: any;
  handleStoredData: (data: any) => void;
  clear: () => void;
  isBackInThePage: boolean;
  handleIsBackInThePage: () => void;
};

type CustomerInfoProviderProps = {
	children: ReactNode
}

export const CustomerInfoContext = createContext(
	{} as CustomerInfoContextProps
)

export function CustomerInfoProvider({
	children
}: CustomerInfoProviderProps) {
  const [storedData, setStoredData] = useState([])
  const [isBackInThePage, setIsBackInThePage]= useState(false)

  const handleStoredData = useCallback((data: any) => {
    setStoredData(data)
  }, [])

  const clear = () => {
    setStoredData([])
  }

  const handleIsBackInThePage = useCallback(() => {
    setIsBackInThePage(!isBackInThePage);
  }, [])


  return (
    <CustomerInfoContext.Provider value={{
      handleStoredData,
      storedData,
      isBackInThePage,
      handleIsBackInThePage,
      clear
    }}>
      {children}
    </CustomerInfoContext.Provider>
  );
}
