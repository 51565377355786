import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import { IModalComboFlex } from './modal-combo-flex.types'
import * as S from './modal-combo-flex.styles'

import CloseIcon from '@mui/icons-material/Close'
import ActionsCombo from './components/actions-combo/components/actions-combo/actions-combo.component'
import InputQuantityLabel from '../../../InputQuantityLabel'
import ButtonDefault from '../../../buttons/button/button-default'
import { TypeButtonEnum } from '../../../buttons/button/button-default.types'
import { validateImageExtension } from '../../../../shared/utils/image'
import { useComboFlexContext } from '../../context/combo-flex.context'
import { Notification } from '../../../Notification'
import { addComboFlexToCart } from '../../utils/add-combo-flex-cart'
import { CogoPositions } from '../../../../shared/utils/toaster'

import { Creators as BottomBarActions } from '../../../../shared/store/ducks/bottomBar'
import { setTimeout } from 'timers'
import { toMoney } from '../../../../shared/utils/money'
import { useTypedSelector } from '../../../../shared/hooks/useTypedSelector'

export type SelectedProduct = {
	productID: string
	quantity: number
	dataProduct: any
}

export type SelectedProductsToCart = Record<
	string,
	Record<string, SelectedProduct>
>

function ModalComboFlex({
	isOpenModalComboFlex,
	handleCloseModal,
	combo
}: IModalComboFlex) {
	const { totalPrice, clearContextComboFlex } = useComboFlexContext()

	const dispatch = useDispatch()
	const { customer } = useTypedSelector(['customer'])

	const [selectedProductsToCart, setSelectedProductsToCart] =
		useState<SelectedProductsToCart>({})

	const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true)

	const [qtdComboFlex, setQtdComboFlex] = useState(1)
	const [maxQuantityOrderAlreadyReached, setMaxQuantityOrderAlreadyReached] =
		useState(false)

	const comboFlexValue = useMemo(
		() => totalPrice * qtdComboFlex,
		[qtdComboFlex, totalPrice]
	)

	const openBottomBar = () => {
		const bottomBarProps = {
			name: combo.description,
			count: qtdComboFlex,
			imageUrl: combo.comboImageUrl,
			comboImageUrl: combo.comboImageUrl,
			time: 20,
			isActive: true
		}

		dispatch(BottomBarActions.updateBar(bottomBarProps))

		setTimeout(() => {
			BottomBarActions.hideBar()
		}, 200)
	}

	const isAddButtonToCartDisabled = useCallback(() => {
		const groupTypesMax: Record<string, number> = (
			combo.GroupsTypes?.results || []
		).reduce(
			(acc, curr) => ({
				...acc,
				[curr.groupID]: Number(curr.quantityGroup)
			}),
			{}
		)

		const totalProductByGroup: Record<string, number> = {}

		for (const [key, products] of Object.entries(selectedProductsToCart)) {
			totalProductByGroup[key] = Object.values(products).reduce(
				(acc, curr) => (acc += curr.quantity),
				0
			)
		}

		if (
			Object.keys(groupTypesMax).length !==
			Object.keys(selectedProductsToCart).length
		)
			return true

		if (
			Object.entries(totalProductByGroup).every(([key, value]) => {
				if (!(key in groupTypesMax)) {
					return false
				} else {
					if (value >= groupTypesMax[key]) return true
					return false
				}
			})
		) {
			return false
		}

		return true
	}, [combo.GroupsTypes?.results, selectedProductsToCart])

	useEffect(() => {
		const isDisabled = isAddButtonToCartDisabled()

		setIsAddButtonDisabled(isDisabled)
	}, [isAddButtonToCartDisabled])

	return (
		<Modal
			open={isOpenModalComboFlex}
			onClose={() => {
				handleCloseModal(false)
				clearContextComboFlex()
			}}>
			<S.WrapperModalContent>
				<S.WrapperHeaderModal>
					<h2>Detalhes do Combo Flex</h2>
					<CloseIcon
						onClick={() => {
							handleCloseModal(false)
							clearContextComboFlex()
						}}
					/>
				</S.WrapperHeaderModal>
				<S.WrapperMainContent>
					<S.ComboDetails>
						<img
							src={validateImageExtension(combo.comboImageUrl)}
							alt="combo-flex"
						/>
						<div className="wrapper-info-combo">
							<h3>{combo.description}</h3>
							{combo.maxQuantityOrder ? (
								<p>
									{' '}
									*Limite de {combo.maxQuantityOrder} combos
									por PDV.
								</p>
							) : (
								''
							)}
							<p>*Oferta válida enquanto durar o estoque.</p>
						</div>
					</S.ComboDetails>
					<ActionsCombo
						groupTypes={combo.GroupsTypes}
						selectedProductsToCart={selectedProductsToCart}
						setSelectedProductsToCart={setSelectedProductsToCart}
					/>
				</S.WrapperMainContent>
				{maxQuantityOrderAlreadyReached ? (
					<S.WrapperMaxQuantityOrderAlreadyReached>
						<Notification
							type="info"
							message="Você alcançou o seu limite de combos por PDV."
							isComboFlex={true}
						/>
					</S.WrapperMaxQuantityOrderAlreadyReached>
				) : (
					''
				)}
				{isAddButtonDisabled && (
					<S.WrapperMaxQuantityOrderAlreadyReached>
						<Notification
							type="info"
							message="Finalize primeiro a escolha de produtos."
							isComboFlex={true}
						/>
					</S.WrapperMaxQuantityOrderAlreadyReached>
				)}
				<S.ButtonsWrapper>
					{totalPrice !== 0 && (
						<InputQuantityLabel
							handleIncrementEvent={
								/**
								 * Verifica se maxQuantityOrder (máximo de compras por PDV/dia) e maxQuantityTradePromotion (Quantidade de estoque) são maiores que a quantidade que o usuário quer comprar.
								 */
								() =>
									combo.maxQuantityOrder > qtdComboFlex &&
									combo.maxQuantityTradePromotion >
										qtdComboFlex
										? setQtdComboFlex(qtdComboFlex + 1)
										: setMaxQuantityOrderAlreadyReached(
												true
										  )
							}
							handleDecrementEvent={() => {
								setQtdComboFlex(qtdComboFlex - 1)
								setMaxQuantityOrderAlreadyReached(false)
							}}
							handleUpdate={() => {}}
							value={qtdComboFlex}
							comboFlexMaxQuantity={
								combo.maxQuantityTradePromotion
							}
							enableButtons={true}
							isComboFlex={true}
							isEditable={!isAddButtonDisabled}
							comboFlexValue={combo.maxQuantityOrder}
						/>
					)}
					<ButtonDefault
						buttonText={`Adicionar ${
							totalPrice !== 0 ? `${toMoney(comboFlexValue)}` : ''
						}`}
						idElement="add-to-cart"
						onClick={() => {
							handleCloseModal(false)
							addComboFlexToCart({
								comboId: combo.promotionID,
								ComboImageUrl: combo.comboImageUrl,
								description: combo.description,
								dispatch,
								handleCloseModal,
								limitPdv: combo.maxQuantityOrder,
								openBottomBar,
								quantityComboFlex: qtdComboFlex,
								storeQuantity: comboFlexValue,
								totalPriceCombo: comboFlexValue,
								totalQuantity: comboFlexValue,
								options: CogoPositions['top-right'],
								products: Object.values(
									selectedProductsToCart
								).flatMap((product) => Object.values(product)),
								maxQuantityTradePromotion:
									combo.maxQuantityTradePromotion,
								promotionShortName: combo.promotionShortName,
								customerId: customer.CustomerID
							})
						}}
						type={
							comboFlexValue === 0 || isAddButtonDisabled
								? TypeButtonEnum.DISABLED
								: TypeButtonEnum.PRIMARY
						}
					/>
				</S.ButtonsWrapper>
			</S.WrapperModalContent>
		</Modal>
	)
}

export default ModalComboFlex
