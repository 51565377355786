import handleError from '../../modules/Error/handleError'
import { IError } from '../../modules/Error/types'

export const createGuard = <H extends (error: IError) => any>(handler: H) => <
	F extends (...params: any[]) => any
>(
	callback: F
) => <P extends Parameters<F>>(
	...props: P
): ReturnType<F> | ReturnType<H> | void => {
	try {
		return callback(...props)
	} catch (error) {
		return handler(error)
	}
}

export const guarded = createGuard(handleError)
