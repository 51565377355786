import React from 'react'
import IPrimaryButton from './primary-button.interface'
import { WrapperPrimaryButton } from './primary-button.styles'

export const PrimaryButton = ({
	buttonText,
	handleClickButton
}: IPrimaryButton) => {
	return (
		<WrapperPrimaryButton variant="contained" onClick={handleClickButton}>
			{buttonText}
		</WrapperPrimaryButton>
	)
}
