import styled, { css } from 'styled-components'

export const OrderRulesWrapper = styled.div`
	padding-top: 24px;
`

export const InputRadioContainer = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;

	label {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		color: ${(props) => props.theme.palette.gray.main};

		${({ disabled }) =>
			disabled &&
			css`
				color: ${(props) => props.theme.palette.black.main};
			`}
	}

	${({ disabled }) =>
		disabled &&
		css`
			pointer-events: none;
		`}

	&.type-radio {
		& + &.type-radio {
			margin: 20px 0;
		}

		${({ disabled }) =>
			disabled &&
			css`
				pointer-events: none;
			`}

		img {
			border-radius: 50px;
			height: 19px;
			width: 19px;

			${({ disabled }) =>
				disabled &&
				css`
					pointer-events: none;
					border: 1px solid ${(props) => props.theme.palette.lightGray.main};
				`}

			&.inactive {
				border: 1px solid ${(props) => props.theme.palette.lightGray.main};
			}
		}
	}

	input {
		height: 19px;
		width: 19px;
		margin: 0;

		&:disabled {
			background-color: ${(props) => props.theme.palette.lightGray.main};
		}
	}

	label {
		margin-left: 8px;
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		color: ${(props) => props.theme.palette.gray.main};

		${({ disabled }) =>
			disabled &&
			css`
				color: ${(props) => props.theme.palette.black.main};
			`}
	}
`
