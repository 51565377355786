import React from 'react'

import AlertIcon from '../../assets/images/alert.svg'

import * as S from './styles'

export default function InsufficientPointsAlert({
	cartScreen,
	background = '#f5f5f5'
}: {
	cartScreen?: boolean
	background?: string
}) {
	return (
		<S.InsufficientPointsWarning
			className="insufficient-points-label"
			background={background}
			cartScreen={cartScreen}>
			<img src={AlertIcon} alt="" />
			<span>Você não possui pontos suficientes</span>
		</S.InsufficientPointsWarning>
	)
}
