import { createElement } from 'react'
import { useHistory } from 'react-router-dom'

import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import IProps, { IViewProps } from './types'
import AwaitingApproval from './view'

function AwaitingApprovalContainer(props: IProps): JSX.Element {
	const history = useHistory()

	function handleBackToLogin(): void {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}
	const viewProps: IViewProps = { handleBackToLogin }

	return createElement(AwaitingApproval, viewProps)
}

export default AwaitingApprovalContainer
