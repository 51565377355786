import styled, { css } from 'styled-components'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

export const RulesCouponWrapper = styled.section`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
	`}
`

export const WrapperInputs = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacingOptions.SM}px;

		& .MuiTextField-root {
			width: 100%;
		}
	`}
`

export const Row = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: ${theme.spacingOptions.SM}px;

		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	`}
`

export const SecondRow = styled.div`
	${({ theme }) => css`
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 24px 72px;
		align-items: center;
		gap: ${theme.spacingOptions.SM}px;

		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	`}
`

export const StyledDeleteOutlineIcon = styled(DeleteForeverOutlinedIcon)`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
`
