import React from 'react'
import SearchIcon from '../../../assets/images/search-red.svg'

import styles from './styles.module.scss'
import { IProps } from './types'

export default function SearchInputBanner({
	inputValue,
	disabled = false,
	handleChange,
	backgroundColor = 'transparent'
}: IProps) {
	return (
		<div className={styles['input-search-customer']}>
			<div className={styles['search-input']}>
				<input
					required
					value={inputValue}
					id="search-input-id"
					disabled={disabled}
					onChange={handleChange}
					style={{ backgroundColor: backgroundColor }}
				/>
				<label htmlFor="search-input-id">Buscar</label>
			</div>

			<img src={SearchIcon} alt="Ícone de lupa" />
		</div>
	)
}
