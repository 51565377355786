import { parseISO } from 'date-fns'
import { format } from 'date-fns/fp'
import { pipe, propOr } from 'ramda'

import { parseItemPrice } from '../../shared/utils/money'
import { sortDate } from '../../shared/utils/sorts'

const FORMAT = 'dd/MM/yyyy'

export const getFmtVal = (p: string) => pipe(propOr(0, p), parseItemPrice)

export const getFmtDate = (p: string) =>
	pipe(propOr('', p), parseISO, format(FORMAT))

export const headers = [
	{ label: 'ORG_VENDAS', key: 'SalesOrganizationID' },
	{ label: 'TABELA', key: 'PriceTable' },
	{ label: 'GRUPO_CANAL', key: 'ChannelGroup' },
	{ label: 'CODIGO_MATERIAL', key: 'MaterialNumber' },
	{ label: 'TIPO', key: 'Type' },
	{ label: 'QTD_MINIMA', key: 'MinQuantity' },
	{ label: 'VALOR_MINIMO', key: 'MinValue' },
	{ label: 'VALOR_MAXIMO', key: 'MaxValue' },
	{ label: 'VIGENCIA_INICIAL', key: 'InitialTerm' }
]

export const headersLabels = [
	'ORG_VENDAS',
	'TABELA',
	'GRUPO_CANAL',
	'CODIGO_MATERIAL',
	'TIPO',
	'QTD_MINIMA',
	'VALOR_MINIMO',
	'VALOR_MAXIMO',
	'VIGENCIA_INICIAL'
]

export const columns = [
	{ Header: 'Organização de Vendas', accessor: 'SalesOrganizationID' },
	{ Header: 'Tabela', accessor: 'PriceTable' },
	{ Header: 'Canal', accessor: 'ChannelGroup' },
	{ Header: 'Código do Material', accessor: 'MaterialNumber' },
	{ Header: 'Tipo', accessor: 'Type' },
	{ Header: 'Quantidade Mínima', accessor: 'MinQuantity' },
	{ Header: 'Valor Mínimo', accessor: getFmtVal('MinValue') },
	{ Header: 'Valor Máximo', accessor: getFmtVal('MaxValue') },
	{
		Header: 'Termo Inicial',
		accessor: getFmtDate('InitialTerm'),
		sortType: sortDate('Termo Inicial')
	}
]
