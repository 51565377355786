import { createStore, Reducer } from 'redux'
import { Persistor, persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { Reducers } from './ducks'

const persistConfig = {
	key: 'E-Commerce GP',
	storage,
	whitelist: [
		'carts',
		'companyParams',
		'customer',
		'orderHistory',
		'resale',
		'token',
		'pointsProgramStatus',
		'cartRedemptionItems',
		'cartChallengeItems',
		'challengesAlreadyShown',
		'challengesTheUserIsParticipatingIn'
	]
}

const persistedReducer: Reducer = persistReducer(persistConfig, Reducers)

//TODO: Type properly : Store<IStore>
export const store: any = createStore(persistedReducer)
export const persistor: Persistor = persistStore(store)
