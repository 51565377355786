export interface ITableFilterProps {
	onFilter: (
		data: ISelectedDate | undefined,
		statusSAC: string | undefined,
		statusConfirmation: string | undefined,
		clearFilter?: boolean
	) => Promise<void>
}

export enum Status {
	info = 'INFORMATIVO',
	success = 'APROVADO',
	pending = 'PENDENTE',
	refused = 'RECUSADO'
}

export interface ISelectFilterOption {
	name: string
	value: string
}

export interface ISelectedDate {
	from: Date | undefined
	to: Date | undefined
}
export enum StatusConfirmation {
	S = 'Sucesso',
	E = 'Pendente',
	R = 'Recusado',
	F = 'Aprovado com Pendência'
}
