import React from 'react'

import CheckboxBlankIcon from '../../assets/images/checkbox-blank.svg'
import CheckboxFillIcon from '../../assets/images/checkbox-fill.svg'

import { ConfirmationMethods, IProps } from './types'
import { Container } from './styles'

function AccountActivationSession({
	title = 'Como deseja ativar sua conta?',
	selectedConfirmationMethod,
	handleChangeConfirmationMethod
}: IProps) {

	function renderCheckboxIcon(method: ConfirmationMethods) {
		return selectedConfirmationMethod === method
			? <img src={CheckboxFillIcon} alt="checkd-item" />
			: <img src={CheckboxBlankIcon} alt="uncheckd-item" />
	}

	return (
		<Container>
			<h1>{title}</h1>

			<div className="radio-group">
				<label onClick={() => handleChangeConfirmationMethod('sendsms')}>
					{renderCheckboxIcon('sendsms')}
					Receber por SMS
				</label>
				<label onClick={() => handleChangeConfirmationMethod('sendemail')}>
					{renderCheckboxIcon('sendemail')}
					Receber por E-mail
				</label>
			</div>
		</Container>
	)
}

export default AccountActivationSession
