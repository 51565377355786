export function sortBool() {
	const sentToSac = {
		'sent-to-sac-true': true,
		'sent-to-sac-false': false,
		'sent-to-sac-null': null
	}
	type Sent = keyof typeof sentToSac

	return (rowA: Row, rowB: Row, _: string, __: boolean) => {
		const statusA = (rowA.values.renderSentToSac?.props.id as Sent) || null
		const statusB = (rowB.values.renderSentToSac?.props.id as Sent) || null

		if (statusA !== null && statusB !== null) {
			return Number(sentToSac[statusA]) - Number(sentToSac[statusB])
		} else if (statusA === null && statusB === null) {
			return 0
		} else if (statusA === null) {
			return 1
		} else {
			return -1
		}
	}
}
