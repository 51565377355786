import { ChangeEvent, createElement, useEffect, useState } from 'react'
import { IViewProps } from './types'

import ComboList from './view'

import { useDispatch } from 'react-redux'
import cogoToast from 'cogo-toast'

import { ICombo } from '../../shared/interfaces/combo'
import { getComboList } from './service'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { CogoPositions } from '../../shared/utils/toaster'
import {
	extractCartItem,
	isComboAmountAboveStock
} from '../../shared/utils/cart'
import { useNumber } from '../../shared/hooks/useNumber'
import { isPropEqual } from '../../shared/utils/object'
import { CogoPosition } from '../../shared/interfaces/cogoToast'
import { ICartItem } from '../../shared/interfaces/cart'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { IAllInEvent, sendToAllIn } from '../../shared/utils/allin'
import { compareDates } from '../../utils/compare-dates'

function ComboListContainer(): JSX.Element {
	const { customer, carts } = useTypedSelector(['customer', 'carts'])

	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(true)
	const [showReloadOption, setShowReloadOption] = useState(false)

	const [comboItensData, setCombosItensData] = useState<ICombo[]>([])
	const [inputSearch, setInputSearch] = useState('')
	const [renderComboList, setRenderComboList] = useState<ICombo[]>([])
	const [currentCombo, setCurrentCombo] = useState<ICombo>({} as ICombo)
	const items = carts.map((cart) => cart.items).flat()
	const [productAmount, increment, decrement, setAmount] = useNumber(1)
	const { updateBar, hideBar } = BottomBarActions
	const [lightboxPhotos, setLightboxPhotos] = useState<string[]>([])
	const [openLightbox, setOpenLightbox] = useState(false)
	const [modalOpen, openModal, closeModal] = useBoolean(false)

	const [filterValues] = useState({
		family: '',
		sizes: '',
		brand: ''
	})

	function handleSelectCombo(product: ICombo) {
		setCurrentCombo(product)
		dispatch(hideBar())
		setLightboxPhotos([product.productImageURL])
		updateProductAmount(extractCartItem(product, productAmount))

		openModal()
		sendToAllIn(IAllInEvent.product, [
			{
				id: product.promotionID,
				name: product.description,
				price: product.comboPriceTotal,
				department: '',
				category: '',
				subcategory: '',
				brand: ''
			}
		])
	}

	function updateProductAmount(item: ICartItem): void {
		const storeItem = items.find(isPropEqual('MaterialID')(item))
		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0
		const totalQuantity = item.OrderQuantity + storeQuantity
		setAmount(totalQuantity)
	}

	function handleFetch() {
		;(async () => {
			setIsLoading(true)
			try {
				const combosResponseData = await getComboList({
					sectorID: customer.sectorID,
					customerID: customer.CustomerID,
					salesOrganizationID: customer.SalesOrganizationID
				})

				setCombosItensData(
					combosResponseData.sort((a: ICombo, b: ICombo) =>
						compareDates(a.endDate, b.endDate)
					)
				)
			} catch (error) {
				setShowReloadOption(true)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleReload() {
		setShowReloadOption(false)
		handleFetch()
	}

	function handleSearchStringChange(
		inputEvent: ChangeEvent<HTMLInputElement>
	) {
		setInputSearch(inputEvent.target.value)

		sendToAllIn(IAllInEvent.search, [
			{
				keyword: inputEvent.target.value
			}
		])
	}

	function handleSelectedFilters() {
		if (!comboItensData.length) return

		const filteredProductList = comboItensData.filter((item) => {
			if (!item) return false

			const familySelect = filterValues.family
			const brandSelect = filterValues.brand
			const sizeSelect = filterValues.sizes

			if (familySelect || brandSelect || sizeSelect || inputSearch) {
				const selectedValues: Array<string> = []
				if (familySelect) selectedValues.push(familySelect)
				if (brandSelect) selectedValues.push(brandSelect)
				if (sizeSelect) selectedValues.push(sizeSelect)

				// const match = selectedValues.every((v) =>
				// 	item.Characteristics.some(isPropEqual('Description')(v))
				// )

				const productName =
					(Boolean(inputSearch) &&
						item.description
							.toUpperCase()
							.includes(
								inputSearch ? inputSearch.toUpperCase() : ''
							)) ||
					!Boolean(inputSearch)

				if (productName) return item
			} else {
				return item
			}

			return false
		})

		setRenderComboList(filteredProductList)
	}

	const filterProps = {
		loading: isLoading,
		searchString: {
			onChange: handleSearchStringChange,
			value: inputSearch
		}
	}

	function handleProductsToCart(selectedProduct: ICombo) {
		const options = CogoPositions['top-right']
		if (!productAmount) {
			cogoToast.error('Por favor especifique a quantidade', options)

			return
		}

		const item = extractCartItem(selectedProduct, productAmount)

		const storeItem = items.find(isPropEqual('MaterialID')(item))
		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0

		const totalQuantity = productAmount

		return {
			item,
			storeItem,
			storeQuantity,
			totalQuantity
		}
	}

	function handleAddItemToCard(selectedType: ICombo) {
		if (!selectedType) return

		const options = CogoPositions['top-right']

		const product = handleProductsToCart(selectedType)

		if (!product) return

		const { amountAboveStock, stock } = isComboAmountAboveStock(
			selectedType as ICombo,
			productAmount
		)
		if (amountAboveStock) {
			cogoToast.error(`Quantidade em estoque: ${stock}`, options)
			return
		}

		addItemToStore(
			product.totalQuantity,
			options,
			product.storeQuantity,
			selectedType.description,
			selectedType.productImageURL,
			selectedType.comboImageUrl,
			product.item
		)
	}

	function addItemToStore(
		totalQuantity: number,
		options: CogoPosition,
		storeQuantity: number,
		description: string,
		imageUrl: string,
		comboImageUrl: string,
		item: ICartItem
	) {
		if (totalQuantity > 9999) {
			cogoToast.error('O máximo de unidades por item é 9999', options)

			return
		}
		const bottomBarProps = {
			name: description,
			count: productAmount,
			imageUrl: imageUrl,
			comboImageUrl: comboImageUrl,
			time: 20
		}

		if (storeQuantity !== productAmount) {
			dispatch(
				CartActions.addItem(
					{ ...item, customerId: customer.CustomerID },
					'ADD'
				)
			)
		}

		dispatch(updateBar(bottomBarProps))

		handleCloseModal()
		setTimeout(() => setAmount(1), 200)
	}

	function handleCloseModal() {
		setAmount(1)
		setLightboxPhotos([])
		closeModal()
		setCurrentCombo(null as any)
	}

	function handleUpdateAmount(event: ChangeEvent<HTMLInputElement>) {
		const OrderQuantity = Number(event.target.value)

		if (isNaN(OrderQuantity)) return

		setAmount(OrderQuantity)
	}

	useEffect(handleFetch, [])
	useEffect(handleSelectedFilters, [
		comboItensData,
		filterValues,
		inputSearch
	])

	const viewProps: IViewProps = {
		isLoading,
		filterProps,
		comboItensData: renderComboList,
		handleSelectCombo,
		productAmount,
		handleIncrement: increment,
		handleDecrement: decrement,
		handleAddItemToCard,
		modalOpen,
		closeModal: handleCloseModal,
		lightboxPhotos,
		openLightbox,
		setOpenLightbox,
		currentCombo,
		handleUpdateAmount,
		showReloadOption,
		handleReload
	}

	return createElement(ComboList, viewProps)
}

export default ComboListContainer
