import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { Creators as PointsProgramStatusActions } from '../../../store/ducks/pointsProgramStatus'
import {
	adoptPointsProgram,
	fetchPointsProgramTerms
} from '../../../services/customerPointsProgram.service'
import Modal from '../../../../components/Modal'

import { Terms } from './Terms'
import { Congrats } from './Congrats'
import { IModalTermsProps } from './types'
import { CustomerRoutesEnum } from '../../../../modules/Routes/customerRoutesEnum'

export function TermsModal({
	active,
	onClose,
	goToThePoints
}: IModalTermsProps) {
	const { customer, pointsProgramStatus } = useTypedSelector([
		'customer',
		'pointsProgramStatus'
	])

	const history = useHistory()
	const dispatch = useDispatch()

	const [terms, setTerms] = useState<string[]>([])
	const [isFetchingTerms, setIsFetchingTerms] = useState(false)

	const [acceptTerms, setAcceptTerms] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [showCongrats, setShowCongrats] = useState(false)

	const [totalEarnedPoints, setTotalEarnedPoints] = useState<number>(0)

	function handleChangeTermsAccept() {
		setAcceptTerms((prev) => !prev)
	}

	function handleAccept() {
		;(async () => {
			try {
				setIsLoading(true)

				const { earnedPoints } = await adoptPointsProgram(
					customer.CustomerID,
					customer.SalesOrganizationID
				)

				setTotalEarnedPoints(Number(earnedPoints) || 0)
				setShowCongrats(true)
				dispatch(
					PointsProgramStatusActions.setPointsProgramStatus({
						...pointsProgramStatus,
						userJoinedTheProgram: true
					})
				)
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function handleClose() {
		setTotalEarnedPoints(0)
		setShowCongrats(false)
		setAcceptTerms(false)
		onClose()
	}

	function handleNavigate() {
		handleClose()
		history.push(CustomerRoutesEnum.POINTS_PROGRAM__MY_POINTS)
	}

	function fetchTerms() {
		if (!active || isFetchingTerms) return
		;(async () => {
			try {
				setIsFetchingTerms(true)
				setTerms([])

				const termsResponse = await fetchPointsProgramTerms()
				setTerms(termsResponse)
			} finally {
				setIsFetchingTerms(false)
			}
		})()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(fetchTerms, [active])

	return (
		<Modal isActive={active} isFull>
			{!showCongrats ? (
				<Terms
					termAcceptedLabel="Programa de pontos Bom Parceiro"
					termsTitle="Termos de Adesão ao Programa de Pontos Bom Parceiro"
					headerTitle="Programa de Pontos BP"
					terms={terms}
					isFetchingTerms={isFetchingTerms}
					onClose={handleClose}
					onAcceptTerms={handleAccept}
					onChangeAcceptTerms={handleChangeTermsAccept}
					acceptedTerms={acceptTerms}
					isLoading={isLoading}
					viewMode={
						pointsProgramStatus.userJoinedTheProgram
							? 'VIEW'
							: 'JOIN'
					}
				/>
			) : (
				<Congrats
					onClose={handleClose}
					onNavigate={handleNavigate}
					goToThePoints={goToThePoints}
					totalEarnedPoints={totalEarnedPoints}
				/>
			)}
		</Modal>
	)
}
