import { IGeoCodeResponse } from './types'

const GOOGLE_MAPS_KEY = 'AIzaSyAFt4u9fjeHJikZ0-HaKYjq28lJyTAR-hk'

export function getCoordinatesFromAddress(
	address: string
): Promise<IGeoCodeResponse> {
	return new Promise((resolve, reject) => {
		fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_KEY}&address=${address}`
		)
			.then((response) => response.json())
			.then((responseJson) => {
				resolve(responseJson)
			})
			.catch((error) => {
				return reject(error)
			})
	})
}

export function getAddressFromCoordinates(
	lat: number,
	lgn: number
): Promise<IGeoCodeResponse> {
	return new Promise((resolve, reject) => {
		fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?key=${GOOGLE_MAPS_KEY}&address=${lat},${lgn}`
		)
			.then((response) => response.json())
			.then((responseJson) => {
				resolve(responseJson)
			})
			.catch((error) => {
				console.error(error)
				return reject(error)
			})
	})
}
