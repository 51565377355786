import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { removeEspecialCharacters } from '../../../shared/utils'
import { checkTel } from '../../../shared/utils/validators'
import InputField from '../../../components/InputField'
import { IProps } from './types'

function InputPhone({ returnValue, valueIsValid, className = '' }: IProps) {
	const cellphoneMask = '(99) 9999-9999'

	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setTouched(true)
		returnValue(removeEspecialCharacters(e.target.value))
		setInputValue(e.target.value)
	}

	function validateInput() {
		// Por causa da mácara, esse campo começa com '(' )

		if (inputValue === '') {
			valueIsValid(true)
			setHasError(false)
		} else if (inputValue !== '(') {
			const isValid = checkTel(inputValue)

			valueIsValid(isValid)
			setHasError(touched && !isValid)
		}
	}

	useEffect(validateInput, [value, inputValue, touched, valueIsValid])

	return (
		<InputField
			mask={cellphoneMask}
			onChange={handleChange}
			id="phone"
			label="Telefone fixo (opcional)"
			hasError={hasError}
			className={className}
		/>
	)
}

export default InputPhone
