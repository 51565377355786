import { UseQueryResult, useQuery } from 'react-query'
import { IResaleItem } from '../../modules/ResaleList/types'
import { axiosGPInstance } from './axiosInstace'
import { QUERY_KEYS } from '../utils/reactQuery'

export async function getResalesFilter(): Promise<IResaleItem[]> {
	try {
		const response = await axiosGPInstance.get(`/companies`)

		return response.data
	} catch (error) {
		return []
	}
}

export interface PaginatedRequest {
	maxItemsPerPage: number
	pageIndex: number
	initialDate: string
	finalDate: string
	resalesList?: string[]
	sort?: string
	order?: number
}

export interface IResponsePaginated {
	docs: IReport[]
}

export interface IReport {
	customerId: string
	salesOrgId: string
	customerName: string
	quantitySalesOrder: number
	registerDate: string
	virtualCustomerDays: number
}
export async function fetchCustomersPage(
	payload: PaginatedRequest
): Promise<IResponsePaginated> {
	const response = await axiosGPInstance.post('/customers/getReport', {
		...payload
	})

	return response.data
}

export function useCustomersPage({
	maxItemsPerPage,
	pageIndex,
	initialDate,
	resalesList,
	finalDate,
	order,
	sort
}: PaginatedRequest): UseQueryResult<IResponsePaginated> {
	const enabled = !!(maxItemsPerPage && pageIndex && initialDate && finalDate)

	return useQuery(
		[
			QUERY_KEYS.GET_USERS_LIST,
			{
				maxItemsPerPage,
				pageIndex,
				initialDate,
				resalesList,
				finalDate,
				order,
				sort
			}
		],
		async ({ queryKey }) => {
			const [, params] = queryKey
			const {
				maxItemsPerPage,
				pageIndex,
				initialDate,
				resalesList,
				finalDate,
				order,
				sort
			} = params as PaginatedRequest

			return fetchCustomersPage({
				maxItemsPerPage,
				pageIndex,
				initialDate,
				resalesList,
				finalDate,
				order,
				sort
			})
		},
		{
			enabled,
			retry: 0,
			cacheTime: 0
		}
	)
}

export interface IResponseGetTotal {
	totalCustomers: number
	totalCustomersWithSalesOrder: number
}

export async function fetchTotalReport(
	payload: PaginatedRequest
): Promise<IResponseGetTotal> {
	const response = await axiosGPInstance.post('/customers/getClientsTotal', {
		...payload
	})

	return response.data
}
