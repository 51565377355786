import React from 'react'
import EncryptedField from '../../../../../../../components/EncryptedField/encrypted-field.view'
import { TypeField } from '../../../../../../../components/EncryptedField/encrypted-field.types'
import { UnauthenticatedRoutesEnum } from '../../../../../../../modules/Routes/unauthenticatedRoutesEnum'
import { useHistory, useLocation } from 'react-router-dom'
import { ForgotOrCreateNewCount } from '../../login-form.styles'
import { ChoseValidationTypes } from './chose-validation-step.types'
import HelpModal from '../../../../../../../components/help-modal/help-modal.component'
import { TypeContact } from '../../../../../../../views/reset-password/components/code-validation/code-validation.types'
import { fnOpenChat } from '../../../../../../../layout/footer-layout/utils/open-chat'
import { useRecoverPasswordContextContext } from '../../../../../../../context/recover-password.context'
import { ConfirmationMethods } from '../../../../../../../components/AccountActivationSessionSecondary/types'
import Loading from '../../../../../../../components/Loading'
import { getRequiresRegistrationUpdateResetPassword } from '../../../../../services/login.services'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../../../shared/utils/toaster'
import { removeEspecialCharacters } from '../../../../../../../shared/utils'

// import { getRequiresRegistrationUpdate } from '../../../../../../registration-update/services/service'
// import { getRequiresRegistrationUpdateResetPassword } from '../../../../../services/login.services'
// import cogoToast from 'cogo-toast'
// import cogoDefaultOptions from '../../../../../../../shared/utils/toaster'

function ChooseValidationStep({
	unformatedTextDoc,
	handleCloseSidebar
}: ChoseValidationTypes) {
	const history = useHistory()
	const [requireUpdate, setRequireUpdate] = React.useState(false)
	const [openDialogHelp, setOpenDialogHelp] = React.useState(false)
	const location = useLocation()
	const [isLoading, setIsLoading] = React.useState<boolean>(false)

	const { userMaskedData } = useRecoverPasswordContextContext()

	const handleSendCode = async (typeValidation: ConfirmationMethods) => {
		try {
			setIsLoading(true)
			// const response = await sendAttributeCode({
			// 	username: removeEspecialCharacters(unformatedTextDoc ?? ''),
			// 	clientMetadata:
			// 		formatConfirmationCodeMethodParams(typeValidation)
			// })
			// console.log('passou')
			// console.log(response)
			//			if (!response) return
		} finally {
			setIsLoading(false)
		}
	}

	function handleSelectAlternative({
		validationType,
		validationChoice,
		currentStep,
		unformatedTextDoc
	}: {
		validationType: string
		validationChoice: string
		currentStep: number
		unformatedTextDoc: string
	}) {
		history.replace(UnauthenticatedRoutesEnum.RESET_PASSWORD, {
			validationType: validationType,
			validationChoice: validationChoice,
			currentStep: currentStep,
			unformatedTextDoc
		})
		if (location.pathname === '/reset-password') {
			window.location.reload()
		}
	}

	function handleOpenChat() {
		setOpenDialogHelp(false)
		handleCloseSidebar()
		fnOpenChat()
	}

	async function getRequiresUpdate(): Promise<any> {
		let responseCustomer

		try {
			responseCustomer = await getRequiresRegistrationUpdateResetPassword(
				{
					personalFiscalID:
						removeEspecialCharacters(unformatedTextDoc),
					fieldsToCheck: 'AlternativeEmail,AlternativePhone,questions'
				}
			)

			if (responseCustomer) {
				setRequireUpdate(true)
			} else {
				setRequireUpdate(false)
			}
			return responseCustomer
		} catch (error) {
			cogoToast.error(error, cogoDefaultOptions)
		}
		return responseCustomer
	}

	async function handleNavigate() {
		setIsLoading(true)
		const responseCustomer = await getRequiresUpdate().finally(() => {
			setIsLoading(false)
		})

		if (responseCustomer.requireUpdate) {
			setOpenDialogHelp(true)
		}

		if (!responseCustomer.requireUpdate) {
			handleSelectAlternative({
				validationType: TypeContact.EMAIL,
				validationChoice: userMaskedData.email,
				currentStep: 3,
				unformatedTextDoc: unformatedTextDoc
			})
			handleCloseSidebar()
		}
		return responseCustomer
	}
	return (
		<>
			{isLoading && <Loading />}
			{!isLoading && (
				<>
					<HelpModal
						isOpen={openDialogHelp}
						handleClose={() => setOpenDialogHelp(false)}
						handleOpenChat={() => handleOpenChat()}
					/>
					<h2
						style={{
							paddingLeft: '6px'
						}}>
						Escolha como quer validar
					</h2>
					<p>
						Para sua segurança, escolha como prefere receber o
						código.
					</p>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-start',
							justifyItems: 'center',
							flexDirection: 'column',
							gap: '4px'
						}}>
						<label
							style={{
								paddingLeft: '24px',
								color: '#6F6F6F',
								fontWeight: '400',
								fontSize: '14px'
							}}>
							E-mail
						</label>
						<EncryptedField
							typeField={TypeField.MAIL}
							text={userMaskedData.email}
							handleClick={() => {
								handleSelectAlternative({
									validationType: TypeContact.EMAIL,
									validationChoice: userMaskedData.email,
									currentStep: 0,
									unformatedTextDoc
								})
								handleSendCode('sendemail')
							}}
						/>
					</div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-start',
							justifyItems: 'center',
							flexDirection: 'column',
							gap: '4px'
						}}>
						<label
							style={{
								paddingLeft: '24px',
								color: '#6F6F6F',
								fontWeight: '400',
								fontSize: '14px'
							}}>
							SMS
						</label>
						<EncryptedField
							typeField={TypeField.PHONE}
							text={userMaskedData.cellphoneNumber}
							handleClick={() => {
								handleSendCode('sendsms')
								handleSelectAlternative({
									validationType: TypeContact.PHONE,
									validationChoice:
										userMaskedData.cellphoneNumber,
									currentStep: 0,
									unformatedTextDoc
								})
							}}
						/>
					</div>
					<ForgotOrCreateNewCount
						id="other-options"
						onClick={() => handleNavigate()}>
						Outras opções de validação
					</ForgotOrCreateNewCount>
				</>
			)}
		</>
	)
}

export default ChooseValidationStep
