import { CreateCouponValidationSchema } from '../../schemas/create-coupon'

type VerifyFieldsParams = {
  form: CreateCouponValidationSchema
  // Tipo vem do hook da view
  reactHookFormProps: any
  setIsOpenModalCreateCoupon: (param: boolean) => void
}
export function verifyFields({
  form,
  reactHookFormProps,
  setIsOpenModalCreateCoupon
}: VerifyFieldsParams) {
  if (Number(form.discountValue) > 100 && form.discountType === 'percent') {
    reactHookFormProps.setError('discountValue', {
      message: 'Valor de porcentagem deve ser menor ou igual a 100'
    })
  } else {
    setIsOpenModalCreateCoupon(true)
  }
}
