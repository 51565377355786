import React from 'react'
import { INotification } from './notification.types'
import * as S from './notification.styles'

import SmallIconApp from '../../../../../assets/images/small-icon-app.svg'
import AppIconRounded from '../../../../../assets/images/app-icon-rounded.svg'

const Notification = ({
	isSelectedData,
	titleNotification,
	messageNotification,
	srcImg
}: INotification) => {
	const [isSelectedImage, setIsSelectedImage] = React.useState(false)

	React.useEffect(() => {
		if (srcImg && srcImg.length > 0) {
			setIsSelectedImage(true)
		} else {
			setIsSelectedImage(false)
		}
	}, [srcImg, isSelectedImage])

	return (
		<>
			{isSelectedData ? (
				<S.WrapperNotification isSelectedImage={isSelectedImage}>
					<div className="header-notification">
						<div className="left-content">
							<img src={AppIconRounded} alt={'header logo'} />
							<p>Bom Parceiro GP</p>
						</div>
						<div className="right-content">
							<p>15:30</p>
						</div>
					</div>
					<div className="main-notification">
						{isSelectedImage && (
							<img src={srcImg} alt="notification" />
						)}

						<h2>{titleNotification}</h2>
						<p>{messageNotification}</p>
					</div>
				</S.WrapperNotification>
			) : (
				<S.DefaultWrapper>
					<div className="header-notification">
						<img src={SmallIconApp} alt={'header logo'} />
						<div className="empty-app-name"></div>
					</div>
					<div className="notification-body">
						<div className="empty-title"></div>
						<div className="empty-message"></div>
					</div>
				</S.DefaultWrapper>
			)}
		</>
	)
}

export default Notification
