import React from 'react'
import { OptionProps } from '../../../../../../../utils/options-props'
import * as S from './label-details.styles'
function LabelDetails({ name, value }: OptionProps): JSX.Element {
	return (
		<S.ContainerDetails>
			<S.LabelName>{name}</S.LabelName>
			<S.LabelValue>{value}</S.LabelValue>
		</S.ContainerDetails>
	)
}

export default LabelDetails
