import React from 'react'

import LogoutIcon from '../../assets/images/logout.svg'

import { AdminNavBar } from './AdminNavBar/admin-navbar'
import { CustomerNavBar } from './CustomerNavBar'
import { IViewProps } from './types'
import styles from './styles.module.scss'
import { logout } from '../../shared/services/axiosInstace'

function NavBar(props: IViewProps): JSX.Element {
	const {
		isCustomer,
		isOpen,
		handleSetIsOpen,
		userName,
		NickName,
		userType
	} = props

	const navClassName = `${isCustomer ? '' : 'sac'} ${isOpen ? 'active' : ''}`

	return (
		<section className="inside-panel">
			<div
				className={isOpen ? 'layer-active' : ''}
				onClick={handleSetIsOpen}
			/>

			<nav className={navClassName} style={{ overflowY: 'scroll' }}>
				{isCustomer ? (
					<CustomerNavBar userName={userName} nickName={NickName} />
				) : (
					<AdminNavBar userType={userType} />
				)}

				{isCustomer ? (
					<section className={styles.logoutSection}>
						<button
							className={`reset-button-style ${styles.logoutButton}`}
							style={{ fontSize: '14px' }}
							onClick={() => logout()}>
							<span className={styles.routeLink}>
								{'Sair da Conta'}
							</span>
							<img src={LogoutIcon} alt="Ícone de sair" />
						</button>
					</section>
				) : null}
			</nav>
		</section>
	)
}

export default NavBar
