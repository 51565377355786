import React from 'react'
import { Typography } from '@material-ui/core'
import { IUsersImpacted } from './impacted-clients.types'
import { ImpactedClientsStyles } from './impacted-clients.styles'

const ImpactedClients = ({ usersImpacted }: IUsersImpacted) => {
	return (
		<ImpactedClientsStyles>
			<Typography
				variant="body1"
				component="p"
				className="impacted-clients-title">
				Clientes Impactados
			</Typography>
			<div className="impacted-clients-value">
				<Typography variant="body1" component="p">
					{usersImpacted}
				</Typography>
			</div>
		</ImpactedClientsStyles>
	)
}

export default ImpactedClients
