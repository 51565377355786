import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'

export const CardWrapper = styled.div`
	width: 100%;
	max-width: 536px;
	max-height: 180px;
	padding-top: 22px;
	border-radius: 4px;
	background-color: ${colorThemeProvider.whiteColor};
`

export const LabelMyPoints = styled.label`
	color: ${colorThemeProvider.whiteColor};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	margin-left: 16px;
	margin-bottom: 16px;
	display: inline-block;
`

export const TotalPointsLabel = styled.label`
	color:${colorThemeProvider.successColor};
	font-size: ${(props) => props.theme.font.size.DISPLAY_MD.size}rem;
`

export const PointsToExpireLabel = styled.label`
	color: ${colorThemeProvider.blackColor};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
`

export const TotalPointsToExpireLabel = styled.label`
	color:  ${colorThemeProvider.secondaryColor};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
`
export const TotalPointsToExpireWrapper = styled.div`
	width: 100%;
	padding: 16px;
	display: flex;
	align-items: center;
	border-top: 1px solid ${colorThemeProvider.primaryWhiteColor};
	justify-content: space-between;
`

export const ContentInfosTopWrapper = styled.header`
	width: 100%;
	display: flex;
	padding: 0 16px;
	margin-bottom: 42px;
	align-items: center;
	justify-content: space-between;
`
