import { useState } from 'react'
import {
	UnicoCheckBuilder,
	UnicoThemeBuilder,
	CallbackCamera,
	ErrorPictureResponse,
	SuccessPictureResponse,
	SupportPictureResponse,
	DocumentCameraType,
	SelfieCameraType
} from 'unico-webframe'
import { IUnicoSdk } from '../interfaces/unicoSdk'
import { colorThemeProvider } from '../../theme/themeProvider'

function useUnicoSDKCamera(): IUnicoSdk {
	const [stateCamera, setStateCamera] = useState('')
	const [errorCamera, setErrorCamera] = useState('')
	const [id, setId] = useState('')
	const [showBoxCamera, setShowBoxCamera] = useState(false)
	const [loading, setLoading] = useState(false)

	function resetComponentStates() {
		setShowBoxCamera(false)
		setLoading(false)
	}

	const urlPathModels = `${window.location.protocol}//${window.location.host}/models`

	const unicoTheme = new UnicoThemeBuilder()
		.setColorSilhouetteSuccess(`${colorThemeProvider.successColor}`)
		.setColorSilhouetteError(`${colorThemeProvider.primaryColor}`)
		.setColorSilhouetteNeutral(`${colorThemeProvider.grayColor}`)
		.setBackgroundColor(colorThemeProvider.grayColor)
		.setColorText(`${colorThemeProvider.whiteColor}`)
		.setBackgroundColorComponents(`${colorThemeProvider.primaryColor}`)
		.setColorTextComponents(`${colorThemeProvider.whiteColor}`)
		.setBackgroundColorButtons(`${colorThemeProvider.primaryColor}`)
		.setColorTextButtons(`${colorThemeProvider.whiteColor}`)
		.setBackgroundColorBoxMessage(`${colorThemeProvider.whiteColor}`)
		.setColorTextBoxMessage(`${colorThemeProvider.grayColor}`)
		.setHtmlPopupLoading(
			`<div style="position: absolute; top: 45%; right: 50%; transform:
    translate(50%, -50%); z-index: 10; text-align: center;">Carregando...</div>`
		)
		.build()

	const unicoCamera = new UnicoCheckBuilder()
		.setTheme(unicoTheme)
		.setModelsPath(urlPathModels)
		.setResourceDirectory('/resources')
		.build()

	const sdkCallbacks: CallbackCamera = {
		on: {
			success: function (obj: SuccessPictureResponse) {
				setStateCamera?.(obj.encrypted)
				resetComponentStates()
			},
			error: function (error: ErrorPictureResponse) {
				setErrorCamera(error.message)
				resetComponentStates()
			},
			support: function (error: SupportPictureResponse) {
				setErrorCamera(error.message)
				resetComponentStates()
			}
		}
	}

	const prepareSelfieCamera = async (
		cameraType: SelfieCameraType,
		idCamera?: string
	) => {
		setLoading(true)
		setShowBoxCamera(true)
		// console.log({ reactEnv: 'prd' })
		const jsonPath =
			'prd' === 'prod'
				? '/services-prod.json'
				: '/services.json'
		// const jsonPath = '/services.json'
		const { open } = await unicoCamera.prepareSelfieCamera(
			jsonPath,
			cameraType
		)

		setId(idCamera || '')
		open(sdkCallbacks)
		setLoading(false)
	}

	const prepareDocumentCamera = async (
		cameraType: DocumentCameraType,
		idCamera: string
	) => {
		setLoading(true)
		setShowBoxCamera(true)

		// console.log({ reactEnv: 'prd' })
		const jsonPath =
			'prd' === 'prod'
				? '/services-sem-facetec-prod.json'
				: '/services-sem-facetec.json'
		// const jsonPath = '/services-sem-facetec.json'
		const { open } = await unicoCamera.prepareDocumentCamera(
			jsonPath,
			cameraType
		)

		setId(idCamera || '')
		open(sdkCallbacks)
		setLoading(false)
	}

	function cleanStateCamera() {
		setStateCamera('')
	}

	return {
		prepareDocumentCamera,
		prepareSelfieCamera,
		stateCamera,
		errorCamera,
		showBoxCamera,
		loading,
		id,
		resetComponentStates,
		cleanStateCamera
	}
}

export { useUnicoSDKCamera }
