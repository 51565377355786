import { IUser } from './types'

export const DEFAULT_USER: IUser = {
	SignupTrackingID: '',
	SalesOrganizationID: '',
	SalesOrganizationDescription: '',
	CustomerID: '',
	PersonalFiscalID: '',
	CompanyFiscalID: '',
	Name: '',
	NickName: '',
	SignUpStatusConfirmation: '',
	DetailSignUpStatusConfirmation: '',
	InvoiceNumberConfirmation: '',
	Email: '',
	CellphoneNumber: '',
	PhoneNumber: '',
	IdentificationRegistryID: '',
	Street: '',
	HouseNumber: '',
	AdressComplement: '',
	Neighborhood: '',
	City: '',
	Region: '',
	PostalCode: '',
	SignupDate: '',
	UpdatedAt: '',
	statusSACDescription: 'PENDENTE'
}
