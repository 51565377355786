import styled from 'styled-components'

export const ImageUploadWrapper = styled.div<{
	containerWidth: string | undefined
}>`
	position: relative;
	max-width: 344px;
	flex: 1 0 100%;

	.preview-image {
		position: relative;

		img {
			width: 100%;
			object-fit: cover;
			max-height: 211px;
			border-radius: 4px;
			object-position: center;
		}

		div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: black;
			-webkit-mask-image: linear-gradient(
				to bottom,
				transparent 15%,
				black 100%
			);
		}
	}

	.label {
		&:hover {
			cursor: pointer;
		}

		.placeholder {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			padding: 4px;

			width: ${(props) =>
				props.containerWidth ? `${props.containerWidth}px` : '100%'};
			height: 211px;
			border: 2px dashed ${(props) => props.theme.palette.primary.main};
			border-radius: 8px;
			background: ${(props) => props.theme.palette.primaryWhite.main} 0%
				0% no-repeat padding-box;

			img {
				width: 23px;
				height: 23px;
			}

			span {
				color: ${(props) => props.theme.palette.primary.main};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
				text-align: center;
				margin-top: 14px;
			}
			span + span {
				font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
				margin-top: 24px;
			}

			input {
				position: absolute;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}

	.trash-icon {
		width: 32px;
		height: 32px;
		position: absolute;
		bottom: 16px;
		right: 16px;

		border: none;
		background: none;

		&:hover {
			cursor: pointer;
		}
	}
`
