import { SelectorOption } from '../../../../components/data-selector/data-selector.types'

export interface MidiaContainerProps {
	showDeviceSelect?: boolean
	deviceSelected: SelectorOption
	handleDeviceSelected?: (value: SelectorOption) => void
	showImage?: boolean
	showVideo?: boolean
	canDeleteImage?: boolean
	srcImage: string
	handleGetSrcImage?: (value: string) => void
	srcVideo: string
	canDeleteVideo: boolean
	hasSelectedVideo?: boolean
	handleGetSrcVideo?: (value: string) => void
	isFormCreateBanner?: boolean
	isDisabledImage?: boolean
	destinySelected?: SelectorOption
}

export enum DevicesEnum {
	APP = 'MOBILE',
	WEB = 'WEB'
}

export enum RecommendedSize {
	APPLOGIN = '1080X1080',
	APPHOME = '1560x340',
	WEBLOGIN = '683X522',
	WEBHOME = '374X198'
}
