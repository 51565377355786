import styled from 'styled-components'

export interface StyledWrapperChoiceQuantity {
	isDisabled: boolean
}

export const WrapperStyledChoiceQuantity = styled.div<StyledWrapperChoiceQuantity>`
	display: flex;
	width: 100%;
	padding: ${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.MD}px;
	justify-content: space-between;
	align-items: center;

	background: ${(props) => props.theme.palette.primaryWhite.main};
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

	.left-content {
		color: ${(props) =>
			props.isDisabled
				? props.theme.palette.lightGray.main
				: props.theme.palette.black.main};
		font-size: ${(props) => props.theme.spacingOptions.SM}px;
		font-weight: ${(props) => props.theme.font.weight.semibold};
	}

	.right-content {
		color: ${(props) =>
			props.isDisabled
				? props.theme.palette.lightGray.main
				: props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
		font-weight: ${(props) => props.theme.font.weight.semibold};
	}
`
