export function createArrayWithObjects(numPositions: any) {
  const resultArray = []
  let positions = numPositions
  if (numPositions === 1) {
    positions = positions + 1
  } else if (numPositions === 0) {
    numPositions = 1
  }
  for (let i = 1; i <= positions; i++) {
    const newObj = {
      value: i.toString(),
      name: i.toString()
    }
    // @ts-ignore: Unreachable code error
    resultArray.push(newObj)
  }
  if (resultArray.length === 0) {
    resultArray.push({
      value: '1',
      name: '1',
    })
  }
  return resultArray
}