import React from 'react'
import Loading from '../../components/Loading'
import { IViewProps } from './types'
import GPLogo from '../../assets/images/gp.png'

function AuthMobileView(props: IViewProps): JSX.Element {
	const { loading } = props
	return (
		<div className="auth-container">
			<div className="auth-wrapper">
				<div className="message-container">
					<h2>Agora você pode pedir online nossos produtos!</h2>
				</div>

				<div className="login-container">
					<img
						className="gp-logo"
						src={GPLogo}
						alt="Grupo Petrópolis"
					/>

					{loading && (
						<div className="loading-wrapper">
							<Loading color="white" />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default AuthMobileView
