export const options = (option: ISelectOption[]) => [
	{
		label: 'Todos',
		options: option
	}
]

export interface ISelectOption {
    value: any;
    label: string;
}