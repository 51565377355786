import React, { useState } from 'react'

import PlusIcon from '../../../assets/images/plus.svg'
import MinusIcon from '../../../assets/images/minus.svg'

import { IQuestionFAQComponentProps } from './types'

import * as S from './styles'

export default function QuestionFAQComponent(
	props: IQuestionFAQComponentProps
) {
	const { answer, question } = props

	const [showAnswer, setShowAnswer] = useState(false)

	return (
		<S.QuestionFAQWrapper showAnswer={showAnswer}>
			<S.Question showAnswer={showAnswer}>
				<span className="question-title">{question}</span>
				<button
					onClick={() => setShowAnswer(!showAnswer)}
					className="button-open-question">
					<img src={showAnswer ? MinusIcon : PlusIcon} alt="icone" />
				</button>
			</S.Question>
			<S.QuestionDescription>{answer}</S.QuestionDescription>
		</S.QuestionFAQWrapper>
	)
}
