import TextField from '@mui/material/TextField'
import styled from 'styled-components'

interface StyledInputProps {
	width: number
}

export const CountCharacters = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_XSM}rem;
	color: ${(props) => props.theme.palette.primary.main};
`

export const TextFielWrapper = styled(TextField)<StyledInputProps>`
	width: ${(props) => props.width}px;
	height: 100%;

	& .MuiOutlinedInput-root {
		border-radius: 5px;
		fieldset {
			border: 1px solid
				${(props) => props.theme.palette.extras.stroke.main};
			border-radius: 5px;
		}

		& .MuiInputBase-input {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size};
			color: ${(props) => props.theme.palette.black.main};
		}

		&:hover fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}

		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}
	}

	& .MuiInputLabel-root {
		color: ${(props) => props.theme.palette.black.main};

		&.Mui-focused {
			color: ${(props) => props.theme.palette.primary.main};
		}
	}
`
