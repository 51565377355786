import { createElement, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import cogoToast from 'cogo-toast'
import { confirmEditCellPhoneCode } from './services'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { cogoSuccessMessages } from '../../shared/utils/Messages'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { editCustomerData } from '../../shared/services/customer.service'

import { Creators as CustomerActions } from '../../shared/store/ducks/customer'

import ConfirmEditCellPhoneCodeView from './view'

import { IViewProps } from './types'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

export default function ConfirmEditCellPhoneCode() {
	const dispatch = useDispatch()
	const history = useHistory()

	const { state } = useLocation<{
		cellphone: string
	}>()

	const { customer } = useTypedSelector(['customer'])
	const [code, setCode] = useState('')
	const [, setCodeIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handleSubmit = useCallback(() => {
		;(async () => {
			try {
				setIsLoading(true)
				const response = await confirmEditCellPhoneCode(code)

				if (response !== 'SUCCESS') return

				const customerID =
					customer.PersonalFiscalID || customer.CompanyFiscalID

				const res = await editCustomerData(customerID, {
					cellPhone: state.cellphone
				})

				if (!res || !res.length) return

				dispatch(
					CustomerActions.updateCustomer({
						...customer,
						CellphoneNumber: res[0].cellPhone
					})
				)

				cogoToast.success(
					cogoSuccessMessages.updateCellphoneSuccess,
					cogoDefaultOptions
				)
				history.replace(CustomerRoutesEnum.MY_ACCOUNT)
			} finally {
				setIsLoading(false)
			}
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, customer, state])

	const handleBack = () =>
		history.replace(CustomerRoutesEnum.CHANGE_CELLPHONE)

	const isDisabled = () => code.length < 6 && !isNaN(Number(code))

	const viewProps: IViewProps = {
		setCode,
		handleBack,
		isLoading,
		setCodeIsValid,
		handleSubmit,
		isDisabled
	}

	return createElement(ConfirmEditCellPhoneCodeView, viewProps)
}
