import { TypeContact } from './components/code-validation/code-validation.types'

export enum Steps {
	VALID_CODE = 0,
	NEW_PASSWORD = 1,
	SUCCESS = 2,
	ANOTHER_FORM = 3,
	SECURITY = 4,
	NEW_EMAIL_PHONE = 5
}

export interface ValidationProps {
	validationType: TypeContact
	validationChoice: string
	currentStep?: number
	unformatedTextDoc?: string
}

export enum EnumTypeFlow {
	NORMALFLOW = 'normalFlow',
	ALTERNATIVEFLOW = 'alternativeFlow',
    SECURITY_QUESTIONS = 'securityQuestions',
	ALTERNATIVEFLOW_CONCLUDED = 'alternativeFlowConcluded'

}