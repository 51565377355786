import axios, { AxiosResponse } from 'axios'
import { apiAdmin, getJwtAuthGpAcessToken } from '../../shared/services/axiosInstace'
import { getEnumMessage } from '../Error/handleError'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { UserGroupEnum } from '../../shared/interfaces/sacUser'

interface IUserAuthData {
	user: string
	password: string
	applicationName?: string
}

export interface IUserAuthResponse {
	user: string
	group: UserGroupEnum
	companies: Array<{
		salesOrganizationID: string,
		Name?: string,
	}>
}

export async function authUser(
	data: IUserAuthData
): Promise<IUserAuthResponse | null> {
	const accessToken = await getJwtAuthGpAcessToken()

	try {
		const REACT_APP_API_KEY_GP = '3YjhU64Yx48PgiXVPv0Yo4vDFFTLbSfS7A2tiEPf'

		const response: AxiosResponse = await axios.post(
			`${'https://5m7vvpiuh4.execute-api.sa-east-1.amazonaws.com/prd/v1/proxyauth'}`,
			{
				...data,
				applicationName: 'PORTALB2BADMIN'
			},
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'x-api-key': REACT_APP_API_KEY_GP
				}
			}
		)

		return response.data
	} catch (error: any) {
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
		return null
	}
}


export async function userComplementData(user:string): Promise<IUserAuthResponse> {
	return (await apiAdmin.get(`/user/get-single/${user}`)).data
}
