import React from 'react'
import * as S from './modal-video.styles'

import PlayerButton from '../../../views/login-customer/components/image-slider/components/player-button/player-button.component'
import ModalVideoComponent from '../components/modal-video/modal-video'
import ReactPlayer from 'react-player'
import { NavLink } from 'react-router-dom'
import { ActionType, ActionTypeLink } from '../../../views/banners-management/banners-management.types'
import { IBanner } from '../../../shared/interfaces/banner'

function ImageVideoComponent({
	imageUrl,
	videoUrl,
	banner
}: {
	imageUrl: string
	videoUrl?: string
	banner: IBanner
}) {
	const [showModalVideo, setShowModalVideo] = React.useState(false)

	function redirectRoute() {
		if (ActionType[banner.actionType] === ActionType.OPEN_PRODUCT) {
			return `${ActionTypeLink[banner.actionType]}/?product=${banner.selectedProduct}`
		}

		if (ActionType[banner.actionType] === ActionType.OPEN_CATEGORY) {
			return `${ActionTypeLink[banner.actionType]}/?category=${banner.selectedCategory}`
		}
		return ActionTypeLink[banner.actionType]
	}

	return (
		<>
			{
				(imageUrl && !videoUrl) ? (
					<NavLink to={() => redirectRoute()}>
						<S.VideoPlayerWrapper>
							<S.ImageSliderWrapper
								style={{
									backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%), url(${imageUrl})`,
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'contain',
									backgroundPosition: 'center'
								}}></S.ImageSliderWrapper>
						</S.VideoPlayerWrapper>
					</NavLink>
				) : (
					<>
						{imageUrl ? (
							<S.VideoPlayerWrapper>
								<S.ImageSliderWrapper
									style={{
										backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 150%), url(${imageUrl})`,
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'contain',
										backgroundPosition: 'center'
									}}></S.ImageSliderWrapper>
								{videoUrl && (
									<PlayerButton
										isPlaying={showModalVideo}
										onClickPlay={() => setShowModalVideo(true)}
										onClickPause={() => setShowModalVideo(false)}
									/>
								)}
							</S.VideoPlayerWrapper>
						) : (
							<S.VideoPlayerWrapper>
								{/* @ts-ignore */}
								<ReactPlayer
									url={videoUrl}
									playing={false}
									onEnded={() => { }}
									muted={false}
									width={'100%'}
									height={'100%'}
								/>
								<PlayerButton
									isPlaying={showModalVideo}
									onClickPlay={() => setShowModalVideo(true)}
									onClickPause={() => setShowModalVideo(false)}
								/>
							</S.VideoPlayerWrapper>
						)}
					</>
				)
			}
			{showModalVideo && videoUrl && (
				<div
					style={{
						width: '70%',
						height: '70%'
					}}>
					<ModalVideoComponent
						videoUrl={videoUrl}
						handleToggleModal={() => {
							setShowModalVideo(false)
						}}
					/>
				</div >
			)}
		</>
	)
}

export default ImageVideoComponent
