import styled from 'styled-components'
import { CellTableDelivery } from '../cell-table-delivey/cell-table-delivery.types'

export const WrapperTableLegend = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
`

export const StyledTableLegend = styled.div<CellTableDelivery>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;

	svg {
		width: 14px;
		height: 14px;

		color: ${(props) =>
			props.type === 'available'
				? props.theme.palette.success.main
				: props.theme.palette.lightGray.main};
	}

	p {
		color: ${(props) => props.theme.palette.gray.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`
