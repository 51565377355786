import styled from 'styled-components'

export const StyleInfoMessage = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	gap: 16px;
	border-radius: 5px;
	background: #f9f9f9;
	padding: 16px;

	svg {
		font-size: 24px;
		color: #6f6f6f;
	}

	p {
		color: #3d3d3d;
		font-size: 14px;
		font-weight: 400;
	}
`
