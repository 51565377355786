export interface IDualListBoxComponent {
	options: any[]
	labelKey: string
	valueKey: string
	sortedOptions: (value: any[]) => void
	searchTitle: string
	firstDescription: string
	secondDescription: string
	preserveSelectOrder?: boolean
	totalOptions?: number
	simplifiedSelected?: boolean
	isLoading?: boolean
	isDisabled?: boolean
	initialSelectedValues?: any
}

export interface IRenderIcon {
	type: string
	direction: string
	isDisabled: boolean
}

export enum Direction {
	TOP = 'top',
	BOTTOM = 'bottom',
	LEFT = 'left',
	RIGHT = 'right'
}
