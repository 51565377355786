import styled from 'styled-components'

export const GoogleMapWrapper = styled.div`
	position: relative;
	margin-bottom: 40px;
`

export const ViewMap = styled.div`
	> div {
		position: relative !important;
		height: 424px !important;
	}
`

export const MarkerWrapper = styled.div`
	position: absolute;
	left: calc(50% - 22px);
	top: calc(50% - 40px);
	width: 22px;
	height: 40px;
`
