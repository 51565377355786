import React from 'react'

import { formatDate } from '../../shared/utils/date'
import { FLOW_STATUS, IProps } from './types'

function TimeLine(props: IProps): JSX.Element {
	const { timeline } = props

	return (
		<>
			<div className="timeline-wrapper">
				<div className="timeline">
					{timeline.length
						? timeline.map((item, index) => (
								<div
									key={index}
									className={`item ${
										FLOW_STATUS[item.flowStatus]
									}`}>
									<span className={'circle'} />
									<span className="text">{item.text}</span>

									<p className={'date'}>
										{formatDate(item.date)}
									</p>
								</div>
						  ))
						: null}
				</div>
			</div>
		</>
	)
}

export default TimeLine
