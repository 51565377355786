import cogoToast from 'cogo-toast'
import { axiosGPInstance } from '../../shared/services/axiosInstace'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { getEnumMessage } from '../Error/handleError'

export async function deletePointsProgramRule(ruleID: string) {
	try {
		const response = await axiosGPInstance.delete(
			`/program-point-rule?ruleID=${ruleID}`
		)
		return response.data
	} catch (err) {
		const error = err as any
		const message = getEnumMessage(error.response.data.message)
		cogoToast.error(message, cogoDefaultOptions)
	}
}
