import React, {
	ChangeEvent,
	createElement,
	useEffect,
	useMemo,
	useState
} from 'react'

import BannersManagementView from './banners-management.view'

import { ITableData, IViewProps } from './types'
import {
	IBanner,
	BannerStatus,
	BannerStatusPTBR,
	CategoriesBannerEnum,
	BannerMetadata
} from '../../shared/interfaces/banner'
import { IFiltersValues } from '../../components/Filters/filters.component.types'

import { postListAllBanners } from './service'
import { include } from '../../shared/utils/boolean'
import { format } from 'date-fns'
import BR from 'date-fns/locale/pt-BR'
import * as S from './banners-management.styles'
import StatusBanner from './components/status-banner/status-banner.component'
import { DateRange } from 'react-day-picker-8'
import { colorThemeProvider } from '../../theme/themeProvider'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { creationStatusMapper } from './banners-management.types'

export default function BannersManagement() {
	const [lastGetBanners] = useState(Number(new Date()))
	const [customers, setCustomers] = useState([])
	const [bannerMetadata, setBannerMetadata] = useState<BannerMetadata>()
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [bannersData, setBannersData] = useState<IBanner[]>([])
	const [bannerActiveModal, setBannerActiveModal] = useState<IBanner>({
		bannerUUID: '',
		bannerID: '',
		title: '',
		imageURL: '',
		initialDate: '',
		finalDate: '',
		status: BannerStatus.CANCELLED,
		justification: '',
		platform: '',
		filters: '',
		amountOfCustomers: '',
		videoURL: '',
		targetPage: '',
		targetOrder: '',
		selectedProduct: '',
		selectedCategory: CategoriesBannerEnum.BEERS,
		actionType: '',
		cd: ''
	})
	const [filters, setFilters] = React.useState<IFiltersValues>({
		GRC: [],
		ufs: [],
		cities: [],
		diretor: [],
		channels: [],
		codeResales: [],
		customers: [],
		codePdv: ''
	})
	const [isExpandedForm, setIsExpandedForm] = React.useState(false)
	const [showBannerDetailsModal, setShowBannerDetailsModal] = useState(false)
	const [showBannerCancelModal, setShowBannerCancelModal] = useState(false)
	const [searchString, setSearchString] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [showEditBannerModal, setShowEditBannerModal] = useState(false)
	const locale = { locale: BR }

	// Gerando as datas para filtro de banner
	//Primeiro dia do mês a dois meses atrás
	//Ultimo dia do mês atual
	var today = new Date()
	var threeMonthsAgo = new Date(today)
	threeMonthsAgo.setMonth(today.getMonth() - 3)
	threeMonthsAgo.setDate(1)
	const [selectedDate, setSelectedDate] = React.useState<DateRange>({
		from: threeMonthsAgo,
		to: new Date(today.getFullYear(), today.getMonth() + 1, 0)
	})

	const columns = useMemo(
		() => [
			{ Header: 'Status', accessor: 'status', sortType: 'basic' },
			{ Header: 'ID', accessor: 'id', sortType: 'basic' },
			{ Header: 'Título', accessor: 'banner', sortType: 'basic' },
			{ Header: 'Plataforma', accessor: 'platform' },
			{ Header: 'Destino', accessor: 'destiny', sortType: 'basic' },
			{
				Header: 'Período de Exibição',
				accessor: 'exhibitionPeriod',
				sortType: 'basic'
			},
			{ Header: 'Editar', accessor: 'button', disableSortBy: true },
			{
				Header: 'Cancelar',
				accessor: 'deleteButton',
				disableSortBy: true
			}
		],
		[]
	)

	function renderTitleAndImageBanner({
		imageURL,
		title,
		status
	}: {
		imageURL: string
		title: string
		status: BannerStatus
	}) {
		return (
			<div key={title} className={'banner'}>
				{imageURL && (
					<img
						src={imageURL}
						alt={title}
						className={'imageBanner'}
						style={{
							color: isCancelledStatus(status)
								? colorThemeProvider.lightGrayColor
								: '',
							filter: isCancelledStatus(status)
								? 'grayscale(1)'
								: 'grayscale(0)'
						}}
					/>
				)}
				{tableParagraph(title || '', status)}
			</div>
		)
	}

	function renderButton(banner: IBanner) {
		return (
			<button
				className="edit"
				onClick={() => {
					setShowEditBannerModal(true)
					setBannerActiveModal(banner)
				}}
				disabled={isCancelledStatus(banner.status) ? true : false}
				style={{
					cursor: isCancelledStatus(banner.status)
						? 'not-allowed'
						: 'pointer'
				}}>
				<S.EditIcon isDisabled={isCancelledStatus(banner.status)} />
			</button>
		)
	}

	function tableParagraph(text: string, status: BannerStatus) {
		return (
			<p
				style={{
					color: isCancelledStatus(status)
						? colorThemeProvider.lightGrayColor
						: ''
				}}>
				{text}
			</p>
		)
	}

	function computeStatusBanner(banner: IBanner): BannerStatusPTBR {
		const today = new Date()
		if (isCancelledStatus(banner.status)) return BannerStatusPTBR.CANCELLED
		if (today < new Date(banner.initialDate))
			return BannerStatusPTBR.SCHEDULED

		if (
			today >= new Date(banner.initialDate) &&
			today <= new Date(banner.finalDate)
		) {
			return BannerStatusPTBR.ACTIVE
		}

		return BannerStatusPTBR.FINISHED
	}

	function isCancelledStatus(status: BannerStatus): boolean {
		return BannerStatus[status] === BannerStatus.CANCELLED
	}

	function tableElementGenerator(banner: IBanner) {
		return {
			status: <StatusBanner status={computeStatusBanner(banner)} />,
			id: (
				<S.DetailsBanner
					onClick={() => {
						setShowBannerDetailsModal(true)
						setBannerActiveModal(banner)
					}}>
					{banner.bannerID}
				</S.DetailsBanner>
			),
			banner: renderTitleAndImageBanner({ ...banner }),
			platform: tableParagraph(banner.platform, banner.status),
			destiny: tableParagraph(banner.targetPage, banner.status),
			exhibitionPeriod: tableParagraph(
				`${format(
					new Date(String(banner.initialDate) + 'T12:00:00'),
					'dd/MM/yy',
					locale
				)}
					até ${format(
						new Date(String(banner.finalDate) + 'T12:00:00'),
						'dd/MM/yy',
						locale
					)}`,
				banner.status
			),
			button: renderButton(banner),
			deleteButton: (
				<>
					{!isCancelledStatus(banner.status) && (
						<S.DeleteIcon
							isDisabled={isCancelledStatus(banner.status)}
							onClick={() => {
								setShowBannerCancelModal(
									banner.status === BannerStatus.CANCELLED
										? false
										: true
								)
								setBannerActiveModal(banner)
							}}
						/>
					)}
				</>
			)
		}
	}

	async function fetchBanners(pageIndex: number = 0) {
		const pageSize = 10
		const formatedIsoFromDate = selectedDate.from
			?.toISOString()
			.split('T')[0]

		const formatedIsoToDate = selectedDate.to?.toISOString().split('T')[0]

		setIsLoading(true)
		try {
			const bannerData = await postListAllBanners(
				{
					from: formatedIsoFromDate,
					to: formatedIsoToDate,
					customerIDs: customers
				},
				pageSize,
				pageIndex + 1
			)
			setBannersData(bannerData.banners)
			const data: ITableData[] = bannerData.banners.map(
				tableElementGenerator
			)
			setBannerMetadata({
				totalItems: bannerData.totalItems,
				totalPages: bannerData.totalPages
			})
			setTableData(data)

			setIsLoading(false)
		} catch (error) {
			cogoToast.error('Erro ao listar banners.', cogoDefaultOptions)
		} finally {
			setIsLoading(false)
		}
	}

	const searchStringHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value
		setSearchString(value)
		//buscar por nome??
		const filteredBannersData = bannersData.filter((banner: IBanner) => {
			const filters = JSON.parse(banner.filters)
			return (
				include(BannerStatus[banner.status], value) ||
				include(banner.bannerID, value) ||
				include(banner.title, value) ||
				include(banner.targetPage, value) ||
				filters.codeResales.findIndex((el: string) =>
					include(el, value)
				) !== -1
			)
		})
		const banners: ITableData[] = filteredBannersData.map(
			tableElementGenerator
		)
		setTableData(banners)
	}

	useEffect(() => {
		if (customers.length === 0) return
		fetchBanners()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customers])

	useEffect(() => {
		fetchBanners()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastGetBanners])

	const viewProps: IViewProps = {
		columns,
		showEditBannerModal,
		filters,
		customers,
		tableData,
		isLoading,
		bannerActiveModal,
		bannerMetadata,
		isExpandedForm,
		setIsExpandedForm,
		showBannerDetailsModal,
		setShowBannerDetailsModal,
		setShowEditBannerModal,
		searchString,
		searchStringHandler,
		setFilters,
		showBannerCancelModal,
		setShowBannerCancelModal,
		selectedDate,
		setSelectedDate,
		fetchBanners
	}
	return createElement(BannersManagementView, viewProps)
}
