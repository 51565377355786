export const AMPLIFY_ERROR_ENUM: any = {
	UsernameExistsException: 'Usuário já cadastrado.',
	UserNotConfirmedException: 'Usuário não confirmou registro.',
	NotAuthorizedException: 'Usuário e/ou Senha incorretos',
	AuthError: 'Número de Documento não pode ser vazio.',
	InvalidParameterException: 'Verifique sua senha e tente novamente.',
	LimitExceededException:
		'Limite Excedido, por favor tente novamente mais tarde',
	CodeMismatchException: 'Código de verificação inválido',
	InvalidPasswordException:
		'Sua senha deve conter no mínimo 8 caracteres e ao menos um caracter minúsculo, maiúsculo e numérico.'
	// UserLambdaValidationException: 'Invoice incorreto'
}

export const GPLOGIN_MESSAGES: any = {
	'user must be longer than or equal to 8 characters':
		'Matrícula de ser maior ou igual a 8 caracteres.',
	Unauthorized: 'Senha e/ou Matrícula errada'
}
