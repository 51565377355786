import styled from 'styled-components'

export interface StyledComboItem {
	isDisabled: boolean
}

export const WrapperComboFlexItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;
	flex-direction: column;
	width: 100%;

	padding: ${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.SM}px
		${(props) => props.theme.spacingOptions.MD}px
		${(props) => props.theme.spacingOptions.SM}px;
	border-bottom: 1px solid
		${(props) => props.theme.palette.borderContrast.main};
`

export const WrapperHeaderComboFlex = styled.div<StyledComboItem>`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.XSM}px;
	width: 100%;

	:hover {
		cursor: pointer;
	}

	img {
		width: 62px;
		height: 62px;
		opacity: ${(props) => (props.isDisabled ? '0.4' : '1')};
	}
	.wrapper-combo-details {
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		gap: ${(props) => props.theme.spacingOptions.XSM}px;
		p {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			flex-wrap: wrap;
			opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
		}
	}

	.is-returnable {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: ${(props) => props.theme.spacingOptions.XSM}px;

		svg {
			font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
			color: ${(props) => props.theme.palette.extras.returnable.main};
			opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
		}

		p {
			color: ${(props) => props.theme.palette.extras.returnable.main};
			font-size: ${(props) => props.theme.font.size.TEXT_XSM.size}rem;
			opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
		}
	}
`

export const WrapperFooterComboItem = styled.div<StyledComboItem>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	.wrapper-item-data {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
		flex-grow: 1;
		gap: ${(props) => props.theme.spacingOptions.XSM}px;
		opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
		:hover {
			cursor: pointer;
		}

		.unit-value {
			display: flex;
			align-items: center;
			justify-content: center;
			:hover {
				cursor: pointer;
			}
			gap: ${(props) => props.theme.spacingOptions.XXSM}px;
			p {
				color: ${(props) => props.theme.palette.gray.main};
				font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			}

			.isBonusText {
				text-decoration: line-through;
			}
		}

		.isBonusComboPrice {
			color: ${(props) => props.theme.palette.success.main};
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
		}

		p {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			font-weight: ${(props) => props.theme.font.weight.medium};
		}
	}
`
