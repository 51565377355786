export interface FeatureStatus {
  [feature: string]: boolean
}

interface getFeatureStatusByEnvironmentTypes {
  env: string,
  features: any,
}

export function getFeatureStatusByEnvironment(
  {
    env,
    features
  }: getFeatureStatusByEnvironmentTypes
): FeatureStatus {
  const featureStatus: FeatureStatus = {}
  for (const feature in features) {
    const featureData = features[feature]
    if (featureData._source === 'remote') {
      const parsedValue = JSON.parse(featureData._value)
      featureStatus[feature] = parsedValue[env] ?? false
    }
  }

  return featureStatus
}