import { IFilterOptions } from '../../components/filters-by-hieararchy/types'

class PushNotificationMapper {
	toPersitenceFilter({
		filters,
		selectedPDVs,
		canSendPdv
	}: {
		filters: IFilterOptions
		selectedPDVs: string[]
		canSendPdv: boolean
	}) {
		return {
			ufs: filters.STATE.map((item) => item.value),
			cities: filters.CITY.map((item) => item.value),
			channels: filters.CHANNEL.map((item) => item.value),
			codePdv: canSendPdv ? selectedPDVs : [],
			codeResales: filters.RESALE.map((item) => item.value),
			diretor: filters.DIRECTOR.map((item) => item.label),
			GRC: filters.GOV.map((item) => item.value)
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new PushNotificationMapper()
