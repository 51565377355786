import { getHours, getMinutes, isAfter } from 'date-fns'
import { anyPass, equals, identity, is, map, pipe } from 'ramda'

import { IParamValueObj } from '../../modules/RegisterParameter/types'
import { Maybe } from '../interfaces/common'
import { displayError } from './cogoToast'
import { MONEY_MASK } from './validators'

export const isValidTimeToUse = anyPass([equals(0), Boolean])

export const getHoursString = (date: Date): string => {
	if (!date) return 'Invalid Date'
	const times = [getHours(date), getMinutes(date)]

	const [hours, minutes] = times
	const fmtPad = (x: number) => String(x).padStart(2, '0')

	return `${fmtPad(hours)}:${fmtPad(minutes)}`
}

export const formatMoneyValue = (item: IParamValueObj) => {
	const Value = String(Number(item.Value.replace(MONEY_MASK, '')) / 100)
	return { ...item, Value }
}

export function formatReceived(value: string) {
	return `${value}00`
}

export const formatBooleanValue = (item: IParamValueObj) => {
	return { ...item, Value: String(item.Value) }
}

export const updateParamValueDate = (item: IParamValueObj) => {
	const { Value: val } = item

	const typedAsDate = (val as unknown) as Date

	const Value = is(String, val) ? val : getHoursString(typedAsDate)

	const updated = { ...item, Value }

	return updated
}

export const formatDateToHoursString = (date: Date): string => {
	const fn = is(String, date) ? identity : getHoursString

	const result = (fn(date) as unknown) as string

	return result
}

export const formatStringDateValue = (value: string) => {
	const isStr = is(String, value)

	const [hour, min] = isStr ? value?.split(':').map(Number) : [NaN, NaN]

	const validHM = isValidTimeToUse(hour) && isValidTimeToUse(min)

	const dateToUse = validHM ? new Date(2020, 0, 1, hour, min) : null

	return dateToUse
}

export const formatItemsEnum = {
	TIME: map(updateParamValueDate),
	MONEY: map(formatMoneyValue),
	BOOLEAN: map(formatBooleanValue),
	STRING: map(formatBooleanValue)
}

const fmtDateToStr = pipe(formatDateToHoursString, formatStringDateValue)

const checkIfIsDateAndFormat = (value: string | Date): Date => {
	if (typeof value === 'string') {
		const result = formatStringDateValue(value)

		return result as Date
	}

	const result = fmtDateToStr(value)

	return result as Date
}

export const validateTimeOrder = (
	pos: Maybe<'start' | 'end'>,
	items: (IParamValueObj | string)[]
) => {
	if (!pos) return true

	const idx1 = pos === 'end' ? 0 : 1
	const idx2 = Number(!idx1)

	const mess1 = 'O inicio do atendimento precisa ser antes do fim'

	const mess2 = 'O fim do atendimento precisa ser após o inicio'

	const toCompare = (items?.[idx1] as any)?.Value
	const toCompare2 = (items?.[idx2] as any)?.Value || items?.[idx2]

	const formatted1 = checkIfIsDateAndFormat(toCompare)
	const formatted2 = checkIfIsDateAndFormat(toCompare2)

	const order = idx1 ? [formatted1, formatted2] : [formatted2, formatted1]

	const isValid = !formatted1 ? true : isAfter(order[0], order[1])

	const message = idx1 ? mess1 : mess2

	if (!isValid) displayError(message)

	return isValid
}
