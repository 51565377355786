import React from 'react'
import { IButtonSelected } from './button-selected.types'
import { WrapperButtonSelected } from './button-selected.styles'

const ButtonSelected = ({
	buttonText,
	handleOnClick,
	isSelected,
	isDisabled
}: IButtonSelected) => {
	return (
		<WrapperButtonSelected
			onClick={handleOnClick}
			isSelected={isSelected}
			disabled={isDisabled}>
			{buttonText}
		</WrapperButtonSelected>
	)
}

export default ButtonSelected
