import React from 'react'
import TextField from '../../../../../../../components/inputs/text-field/text-field.component'
import { TypeEnum } from '../../../../../../../components/inputs/text-field/text-field.types'
import { ForgotOrCreateNewCount } from '../../login-form.styles'
import { FormControlLabel } from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import { TypeButtonEnum } from '../../../../../../../components/buttons/button/button-default.types'
import Loading from '../../../../../../../components/Loading'
import ButtonDefault from '../../../../../../../components/buttons/button/button-default'
import { PasswordStepProps } from './password-step.types'

function PasswordStep({
	handleLogin,
	isChecked,
	isLoading,
	password,
	setIsChecked,
	setPassword,
	setStepLogin,
	setTextDocRecovery,
	showError,
	textDoc
}: PasswordStepProps) {
	return (
		<>
			<h2>Digite a sua senha cadastrada</h2>
			<TextField
				label={'Senha'}
				placeholder={'Digite a sua senha'}
				handleChangeText={setPassword}
				text={password}
				type={TypeEnum.PASSWORD}
				isError={showError}
				labelIsError={'Senha incorreta, tente novamente'}
				idElement={'password'}
			/>
			<ForgotOrCreateNewCount
				onClick={() => {
					setStepLogin('forgotPassword')
					setTextDocRecovery(textDoc)
				}}
				id="forgotPasswordButton">
				Esqueceu a senha
			</ForgotOrCreateNewCount>

			{localStorage.getItem('@loggedUserData') === null && (
				<FormControlLabel
					control={
						<Checkbox
							onChange={setIsChecked}
							checked={isChecked}
							className="radio-checked"
							color="error"
						/>
					}
					label="Mantenha-me conectado"
					id="keepLogged"
				/>
			)}
			{isLoading && <Loading />}
			{!isLoading && (
				<ButtonDefault
					buttonText="Continuar"
					type={TypeButtonEnum.PRIMARY}
					onClick={() => handleLogin()}
					idElement="continueToHome"
				/>
			)}
		</>
	)
}

export default PasswordStep
