import React, { useEffect } from 'react'
import DualListBox from 'react-dual-listbox'
import * as S from './DualListBoxComponent.styles'

import ChevronIcon from '../../assets/images/chevron.svg'
import DoubleChevronIcon from '../../assets/images/double-chevron.svg'
import { addLabelValue, removeLabelValue } from './utils/AddRemoveLabelValue'
import {
	IDualListBoxComponent,
	IRenderIcon,
	Direction
} from './DualListBoxComponent.types'
import Loading from '../Loading'

const DualListBoxComponent = ({
	options,
	labelKey,
	valueKey,
	sortedOptions,
	searchTitle,
	firstDescription,
	secondDescription,
	preserveSelectOrder = true,
	totalOptions,
	simplifiedSelected = true,
	isLoading,
	isDisabled = false,
	initialSelectedValues
}: IDualListBoxComponent) => {
	const [treatedOptions, setTreatedOptions] = React.useState<any[]>([])
	const [state, setState] = React.useState({
		selected: initialSelectedValues ?? []
	})

	function onChange(selected: any) {
		setState({ selected })

		if (simplifiedSelected) {
			sortedOptions(removeLabelValue({ options: selected }))
		} else {
			const selectedByCategory = options.map((option) => {
				const formatted = {
					label: option.label,
					options: (selected as any[])
						.filter((item) => item.label === option.label)
						.map((item) => ({
							...item.options[0]
						}))
				}
				return formatted
			})

			sortedOptions(selectedByCategory)
		}
	}

	function renderIcon({ type, direction, isDisabled }: IRenderIcon) {
		return (
			<img
				className={`button-action-icon ${direction} ${
					isDisabled ? 'disabled' : ''
				}`}
				alt="double-chevron-icon"
				src={type === 'chevron' ? ChevronIcon : DoubleChevronIcon}
			/>
		)
	}

	useEffect(() => {
		const newOptions = addLabelValue({ options, labelKey, valueKey })
		setTreatedOptions(newOptions)
	}, [options, labelKey, valueKey])
	return (
		<S.Container>
			{firstDescription && secondDescription && (
				<S.Descriptions>
					<p>{firstDescription}</p>
					<p>{secondDescription}</p>
				</S.Descriptions>
			)}
			{!isLoading ? (
				// @ts-ignore
				<DualListBox
					options={treatedOptions}
					simpleValue={false}
					selected={state.selected}
					onChange={(value) => onChange(value)}
					preserveSelectOrder={preserveSelectOrder}
					showOrderButtons
					canFilter
					filterPlaceholder={searchTitle}
					disabled={isDisabled}
					icons={{
						moveAllLeft: renderIcon({
							type: 'double-chevron',
							direction: Direction.LEFT,
							isDisabled: !state.selected.length
						}),
						moveLeft: renderIcon({
							type: 'chevron',
							direction: Direction.LEFT,
							isDisabled: !state.selected.length
						}),
						moveAllRight: renderIcon({
							type: 'double-chevron',
							direction: Direction.RIGHT,
							isDisabled:
								(totalOptions || treatedOptions.length) <=
								state.selected.length
						}),
						moveRight: renderIcon({
							type: 'chevron',
							direction: Direction.RIGHT,
							isDisabled:
								(totalOptions || treatedOptions.length) <=
								state.selected.length
						}),
						moveTop: renderIcon({
							type: 'double-chevron',
							direction: Direction.TOP,
							isDisabled: !state.selected.length
						}),
						moveUp: renderIcon({
							type: 'chevron',
							direction: Direction.TOP,
							isDisabled: !state.selected.length
						}),
						moveDown: renderIcon({
							type: 'chevron',
							direction: Direction.BOTTOM,
							isDisabled: !state.selected.length
						}),
						moveBottom: renderIcon({
							type: 'double-chevron',
							direction: Direction.BOTTOM,
							isDisabled: !state.selected.length
						})
					}}
				/>
			) : (
				<S.WrapperLoading>
					<Loading type="circular" />
				</S.WrapperLoading>
			)}
		</S.Container>
	)
}

export default DualListBoxComponent
