import { InputAdornment, css, styled } from '@mui/material';
import { TextField } from '@mui/material';

export const InputWrapper = styled('div')(
    () => css`
        position: relative;
        width: 100%;
    `
);

export const Input = styled(TextField)(
    ({ theme }) => css`
        .Mui-error {
            color: ${theme.palette.primary.main};
        }
        &.MuiTextField-root {
            background-color: ${theme.palette.background.paper};
            width: 100%;
        }

        &:hover .MuiOutlinedInput-notchedOutline,
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.palette.primary.main};
            border-width: 1px;
        }

        &:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
            border-color: ${theme.palette.primary.main};
            border-width: 1px;
        }

        & .MuiOutlinedInput-root {
            width: 100%;
            &.Mui-focused fieldset {
                border-color: ${theme.palette.primary.main};
            }
        }
        & label.Mui-focused {
            color: ${theme.palette.primary.main};
        }

        & .MuiOutlinedInput-notchedOutline {
            border-width: 1px;
        }

        & .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled {
            background-color: ${theme.palette.background.default};

            & ~ .MuiOutlinedInput-notchedOutline {
                border-color: ${theme.palette.lightGray.main};
            }
        }
    `
);

export const CountCharacters = styled(InputAdornment)(
    ({ theme }) => css`
        font-size: ${theme.font.size.TEXT_XSM.size}rem;
        color: ${theme.palette.primary.main};
    `
);
