import styled from 'styled-components'

export const StyledInputContainer = styled.div`
	flex: 1;
	display: flex;
	justify-content: space-between;
	gap: 30px;
`

export const ButtonClick = styled.button`
	display: flex;
	background-color: none;
	cursor: pointer;
	width: auto;
	height: auto;
`
export const InfoNF = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	color: ${(props) => props.theme.palette.lightGray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size};
	font-weight: ${(props) => props.theme.font.weight.medium};
	line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height};
	letter-spacing: 0em;
	text-align: left;
	margin: 10px 0px;
	width: auto;

	span {
		color: ${(props) => props.theme.palette.black.main};
	}
`

export const SubtitleInfoNF = styled.div`
	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size};
		font-weight: ${(props) => props.theme.font.weight.bold};
		line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height};
		letter-spacing: 0em;
		text-align: left;
		margin: 5px 0px;
	}
`
export const ContentNF = styled.div`
	overflow: auto;

	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
`
export const ButtonStyleBorder = styled.div`
	border: solid 1px ${(props) => props.theme.palette.secondary.contrastText};
	border-color: #ce332e;
	border-radius: 5px;
`
export const ButtonStyle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	p.nf {
		font-size: 10px;
		font-weight: 700;
		line-height: 24px;
		text-align: left;
		margin-bottom: 2px;
		color: #ce332e;
	}
`

export const InputStyleSvg = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	svg {
		position: absolute;
		right: 40px;
		margin-top: 23px;

		color: ${(props) => props.theme.palette.primary.main};

		&:hover {
			cursor: pointer;
		}
	}
`
export const MessageStyle = styled.div`
	display: flex;
	position: relative;
	top: -75px;
	height: 10px;
`
interface StyledCellProps {
	isActive: string
}
export const StyleStatus = styled.div<StyledCellProps>`
	background-color: ${(props) =>
		props.isActive === 'Ativo'
			? props.theme.palette.success.main
			: props.theme.palette.lightGray.main};
	color: ${(props) =>
		props.isActive === 'Ativo'
			? props.theme.palette.white.main
			: props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size};
	width: max-content;
	height: 20px;
	padding: 0px 15px;
	border-radius: 24px;
	text-transform: uppercase;
`
