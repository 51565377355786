import { colorThemeProvider } from './../../theme/themeProvider'
import { StylesConfig } from 'react-select'

export const customStyles: StylesConfig = {
	menu: (styles, state) => ({
		...styles
	}),
	option: (styles, state) => ({
		color: colorThemeProvider.blackColor,
		background: state.isSelected
			? colorThemeProvider.lightGrayColor
			: colorThemeProvider.whiteColor,
		padding: 16,
		cursor: 'pointer',

		':hover': {
			background: colorThemeProvider.grayColor,
			color: colorThemeProvider.blackColor
		}
	}),
	control: (styles, state) => ({
		width: '100%',
		height: 58,
		background: 'transparent',
		border: 'solid 1px #d1d1d1',
		padding: 12,
		borderRadius: 4,
		cursor: 'pointer',
		zIndex: 99,
		display: 'flex'
	}),
	singleValue: (styles, state) => ({
		// opacity: state.isDisabled ? 0.2 : 0.8,
		color: colorThemeProvider.blackColor,
		fontSize: 16
	}),
	placeholder: (styles, state) => ({
		// opacity: state.isDisabled ? 0.2 : 0.4,
		fontSize: 14,
		color: colorThemeProvider.strokeDark,
		fontWeight: 'normal',
		textTransform: 'uppercase'
	}),
	noOptionsMessage: (styles, state) => ({
		padding: 15,
		opacity: state.isDisabled ? 0.2 : 0.4,
		color: colorThemeProvider.blackColor
	}),
	multiValue: (styles, state) => ({
		...styles,
		background: colorThemeProvider.blackColor,
		borderRadius: 12,
		padding: '6px 8px'
	}),
	multiValueLabel: (styles, state) => ({
		...styles,
		color: colorThemeProvider.blackColor,
		padding: 0,
		paddingRight: 8,
		fontSize: 12
	}),
	multiValueRemove: (styles, state) => ({
		...styles,
		padding: 0,
		color: colorThemeProvider.blackColor
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: () => ({
		color: colorThemeProvider.blackColor
	}),
	valueContainer: (styles, state) => ({
		...styles,
		padding: 0
	})
}
