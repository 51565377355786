import { subDays } from 'date-fns'
/**
 * Função que formata a data, recebendo um objeto Date()
 * @param data: Date()
 * @returns string (yyyy-mm-dd)
 */
const formatData = (data: Date | undefined): string => {
	if (!data) return ''
	const year = data.getFullYear()
	const month = data.getMonth() + 1
	const day = data.getDate()
	const parsedValue = (value: number) => {
		return value < 10 ? '0' + value : value
	}

	const formatedDate = `${year}-${parsedValue(month)}-${parsedValue(day)}`
	return formatedDate
}

/**
 * Função que formata a data e a data de 30 dias anteriores (D-30)
 * @param
 * @returns Date
 */
const calcDateD30 = () => {
	const dateRef: Date = new Date()
	const newDate: Date = subDays(dateRef, 30)
	return newDate
}

/**
 * Função que formata a data e coloca a quantidade de dias passadas
 * @param day: number
 * @returns Date
 */
const calcDate = (day: number) => {

	if(typeof day !== 'number') {
		throw new Error('Parâmetro tem que ser um inteiro')
	}

	const dateRef: Date = new Date()
	const newDate: Date = subDays(dateRef, day)
	return newDate
}

export { formatData, calcDateD30, calcDate }
