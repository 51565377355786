import React, { useCallback } from 'react'
import * as S from './filter-by-cd.styles'

import { IFilterByCd, ISelectOption } from './filter-by-cd.types'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { fetchCompanies } from './filter-by-id.services'
import cogoToast from 'cogo-toast'
import { options } from '../../../../shared/utils/SelectOptions'
import { sortByCdName } from './utils/sort-by-cd-name'

export default function FilterByCd({
	handleLoading,
	setSelectCds
}: IFilterByCd) {
	const [allCds, setAllCds] = React.useState<ISelectOption[]>([
		{ label: '', value: '' }
	])

	const resultCdsCallBack = useCallback(fetchCompanies, [])

	const filterProps = {
		options: options(allCds),
		onChange: (value: ISelectOption[]) => {
			setSelectCds(value.map((option) => option.value))
		},
		placeholderButtonLabel: 'CD',
		placeholder: 'Buscar CD'
	}
	React.useEffect(() => {
		handleLoading(true)
		resultCdsCallBack()
			.then((fetchedCds) => {
				const sortedCds = fetchedCds.sort(sortByCdName)
				const filledData = sortedCds.map(
					(data): ISelectOption => ({
						label: `${data.SalesOrganizationID} - ${data.Name}`,
						value: data.SalesOrganizationID
					})
				)
				setAllCds(filledData)
			})
			.catch((error) => {
				return cogoToast.error(`Erro - ${error}`, {
					position: 'top-left'
				})
			})
			.finally(() => {
				handleLoading(false)
			})
	}, [handleLoading, resultCdsCallBack])

	return (
		<S.WrapperContainer>
			<S.PageTitle>Consulta de Entrega</S.PageTitle>
			<S.PageDescription>
				<S.PageSubTitle>Filtros</S.PageSubTitle>
				<S.PageDescription>
					Selecione no filtro ativo o CD para consultar as datas de
					entrega.
				</S.PageDescription>
			</S.PageDescription>
			<S.FilterContainer id={'select-cd'}>
				<ReactMultiSelectCheckboxes
					{...filterProps}
					{...S.defaultSelectProps}
				/>
			</S.FilterContainer>
		</S.WrapperContainer>
	)
}
