import React, {
	ChangeEvent,
	useEffect,
	useState,
	useMemo,
	useCallback
} from 'react'
import { Divider, TextField } from '@material-ui/core'
import { InputField, Loading, Table } from '../../../../../shared/components'
import {
	formatDate,
	parseFormatOnlyDate
} from '../../../../../shared/utils/date'
import { calcPriceTotal, paymentMethods } from '../../../../../shared/utils/money'
import SearchIcon from '@mui/icons-material/Search'
import Details from '../Details/Details.view'
import { Row } from '../Information/Information.styles'
import {
	FilterWrapper,
	OrderTitle,
	OrderWrapper,
	InputIconWrapper,
	OrderStatusAutocomplete
} from './order-list.styles'
import { fetchOrders } from '../../../consult-client.service'
import { IOrderTableData, OrderListProps } from './order-list.types'
import { include } from '../../../../../shared/utils/boolean'
import { changeColorByStatus, setOrderSource } from './util'
import { useLocation } from 'react-router-dom'
import { OrderModal } from './modal/order-modal'
import { IOrderModalTableData } from './modal/order-modal.types'

export default function OrderList({ customerAddressInfo }: OrderListProps) {
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState<IOrderTableData[]>([])
	const [originalData, setOriginalData] = useState<IOrderTableData[]>([])
  const [selectedOptions, setSelectedOptions] = useState([{ label: '', value: '' }]);
	const [modalData, setModalData] = useState<IOrderModalTableData>()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const { state }: any = useLocation()
	const userData = state?.dataReceived

	const columns = useMemo(() => {
		const handleViewDetails = (dataReceived: any) => {
			const prepareModalData: IOrderModalTableData = {
				deliveryDate: dataReceived?.orderDate,
				status: dataReceived?.OrderStatusDescription[0],
				orderPriceTotal: dataReceived?.orderTotalPrice,
				paymentMethod: dataReceived?.PaymentFormID,
				quantity: dataReceived?.quantity,
				orderDate: dataReceived?.orderCreatedAt,
				individualProducts: dataReceived?.individualProducts,
				itemsPoints: dataReceived?.itemsPoints,
				removedProducts: dataReceived?.removedProducts,
				orderID: dataReceived?.AutoIncBackendID,
				orderStatusDetail: dataReceived?.OrderStatusDetail
			}
			setModalData(prepareModalData)
			setIsModalOpen(true)
		}

		return [
			{ Header: 'Número', accessor: 'AutoIncBackendID' },
			{ Header: 'CD', accessor: 'SalesOrganizationID' },
			{ Header: 'Origem', accessor: 'orderSource' },
			{ Header: 'Forma de pagamento', accessor: 'PaymentFormID' },
			{ Header: 'Status', accessor: 'OrderStatusDescription',
				Cell: ({ value }: any) => (
					<span style={{ color: changeColorByStatus(value[0]), textTransform: 'uppercase' }}>
						{value[0]?.toLowerCase()?.includes('aguardando') ? 'Aguardando Aprovação do CD ' : value}
					</span>
				)
			},
			{ Header: 'Data do pedido', accessor: 'CreatedAt' },
			{ Header: 'Data da entrega', accessor: 'orderDate' },
			{ Header: 'Detalhes', accessor: 'details',
				Cell: ({ row }: any) => (
					<Details
						data={row.original}
						viewDetails={handleViewDetails}
					/>
				)
			}
		]
	}, [])

	const handleFetchOrders = useCallback(async () => {
		setIsLoading(true)
		try {
			const response = await fetchOrders(userData?.salesOrganizationID, userData?.customerID)
			if (!response) return
			setData(response)
			setOriginalData(response)
		} catch (error) {
			throw error
		} finally {
			setIsLoading(false)
		}
	},[userData?.salesOrganizationID, userData?.customerID, setData, setIsLoading])

	useEffect(() => {
		handleFetchOrders()
	},[handleFetchOrders])

	const handleSearchChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const { value } = event.currentTarget

			if (!value.trim()) {
				setData(originalData)
				return
			}

			const filteredData = data.filter(
				(item) =>
					include(item.AutoIncBackendID, value) ||
					include(item.CustomerID, value) ||
					include(item.orderSource, value) ||
					include(paymentMethods[item.PaymentFormID], value) ||
					include(parseFormatOnlyDate(item.CreatedAt), value) ||
					include(parseFormatOnlyDate(item.DeliveryDate), value)
			)

			setData(filteredData)
		},
		[data, originalData, setData]
	)

  const handleStatusChange = useCallback(
    (value: string | any) => {
      if (!value) {
        setSelectedOptions([]);
        setData(originalData);
        return;
      }

      const selectedOption = value.label.toUpperCase().trim();

      const optionIsAlreadySelected = selectedOptions.includes(selectedOption);
      const newSelectedOptions = optionIsAlreadySelected
        ? selectedOptions.filter((option) => option !== selectedOption)
        : [...selectedOptions, selectedOption];

      setSelectedOptions(newSelectedOptions);

      const filteredData = originalData.filter((item) =>
        newSelectedOptions.some((selected) =>
          include(
            item.OrderStatus.map((order) => order.OrderStatusDescription).toString(),
            selected
          )
        )
      );

      setData(filteredData);
    },
    [originalData, selectedOptions]
  );

	const tableData = data?.map((items) => {
		const orderStatus = items.OrderStatus.map((order) => order.OrderStatusDescription)
		const orderStatusDetail = items.OrderStatus.map((order) => order.OrderStatusDetail)
		return {
			AutoIncBackendID: items.AutoIncBackendID,
			SalesOrganizationID: items.SalesOrganizationID,
			orderSource: setOrderSource(items.orderSource),
			PaymentFormID: paymentMethods[items.PaymentFormID],
			OrderStatusDescription: orderStatus,
			OrderStatusDetail: orderStatusDetail,
			CreatedAt: parseFormatOnlyDate(items.CreatedAt),
			orderDate: parseFormatOnlyDate(items.DeliveryDate),
			orderTotalPrice: calcPriceTotal(items.Items),
			quantity: items.Items.map((item) => item.Quantity),
			orderCreatedAt: formatDate(new Date(items.CreatedAt)),
			individualProducts: items.Items,
			itemsPoints: items.ItemsPoints,
			removedProducts: items.removedItems
		}
	})

	const optionSet = new Set();
	const availableOptions = data.flatMap(items =>
		items.OrderStatus.map(status => {
			const option = {
				label: status?.OrderStatusDescription?.toUpperCase().trim(),
				value: status?.OrderStatusDescription?.toUpperCase().trim(),
			};
	
			if (option.label !== undefined && option.value !== undefined && !optionSet.has(JSON.stringify(option))) {
				optionSet.add(JSON.stringify(option));
				return option;
			}
			return null;
		})
	).filter(Boolean);

	return (
		<OrderWrapper>
			<OrderTitle>Pedidos</OrderTitle>
			<Row>
				<InputIconWrapper>
					<InputField
						onChange={handleSearchChange}
						className="param-input"
						width={'363px'}
						disabled={isLoading}
						placeholder="Buscar"
						label="Buscar"
					/>
					<SearchIcon />
				</InputIconWrapper>
				<FilterWrapper>
					<OrderStatusAutocomplete
						disablePortal
						loading={isLoading}
						options={availableOptions}
						isOptionEqualToValue={(option: any, value: any) => option.label === value.label && option.value === value.value}
						onChange={(event, value) => {
							handleStatusChange(value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Filtrar por"
								placeholder='Todos'
								InputLabelProps={{ shrink: true }}
								variant="outlined"
							/>
						)}
					/>
				</FilterWrapper>
			</Row>
			<Divider />
			{isLoading ? (
				<div className="loading-wrapper">
					<Loading />
				</div>
			) : (
				<Table data={tableData} columns={columns as any[]} />
			)}

			<OrderModal
				handleToggleModal={() => {
					setIsModalOpen(!isModalOpen)
				}}
				isOpen={isModalOpen}
				modalData={modalData}
				customerAddressInfo={customerAddressInfo}
			/>
		</OrderWrapper>
	)
}
