import React, { useState } from 'react'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import TitlePage from '../../components/title-page/title-page.component'
import { DataSelectorList } from '../push-notifications-admin/components/data-selector-list'

import * as S from './order-admin.styles'
import SubTitlePage from '../../components/sub-title-page/sub-title-page.component'
import SelectedOrderOption from './components/selected-order-option.component/selected-order-option.component'
import { ISelectedOption, OrderDataProps } from './order-admin.types'
import { orderOptionsGenerate } from './utils/order-options-generate'
import { addNameValueToCategory } from '../push-notifications-admin/components/behavior-filter/utils/arrayDynamicItems'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import {
	finalOrderedListCategory,
	finalOrderedListMaterial
} from './utils/order-options-sorted-array'
import ConfirmOrderModal from './components/confirm-order-modal/confirm-order-modal.component'

import { categoriesServices } from '../../shared/services/categories.services'
import { materialsServices } from '../../shared/services/materials.services'
import { sortListByListPosition } from '../../utils/sort-list-by-list-position'
import { ICategories } from '../../shared/interfaces/categories'
import TopModalSucess from '../../components/toast-sucess/toast-sucess.component'
import { useFeatureFlags } from '../../context/feature-flag/feature-flag.context'

function OrderAdmin() {
	const [selectedOrderOption, setSelectedOrderOption] =
		useState<ISelectedOption>({
			value: '',
			name: ''
		})
	const [orderData, setOrderData] = useState<OrderDataProps>({
		options: [],
		labelKey: '',
		valueKey: '',
		subtitlePage: '',
		textOrderData: '',
		searchTitle: '',
		firstDescription: '',
		secondDescription: ''
	})
	const [selectedCategory, setSelectedCategory] =
		React.useState<ISelectedOption>({
			value: '',
			name: ''
		})
	const [canShowOrderComponent, setCanShowOrderComponent] =
		React.useState<boolean>(false)
	const [isLoading, setIsLoading] = React.useState(false)

	const { featureFlags } = useFeatureFlags()
	const isCategoriesFeatureFlag = featureFlags.US17241

	const [categorys, setCategorys] = React.useState<any[]>([])
	const [canShowConfirmOrderModal, setCanShowConfirmOrderModal] =
		useState<boolean>(false)
	const [sortedOptions, setSortedOptions] = useState<any[]>([])

	console.log({ sortedOptions })

	const [isProductsOrder, setIsProductsOrder] = useState(false)
	const [isCategoryOrder, setIsCategoryOrder] = useState(false)

	const [showOrderSucessMessage, setShowOrderSucessMessage] =
		useState<boolean>(false)

	const [orderSucessMessage, setOrderSucessMessage] = useState<string>('')

	React.useMemo(() => {
		setIsProductsOrder(selectedOrderOption.value === 'Produtos')
		setIsCategoryOrder(selectedOrderOption.value === 'Categorias')
	}, [selectedOrderOption])

	const listOptions = React.useMemo(
		() => [
			{
				value: 'Categorias',
				name: 'Categorias'
			},
			{
				value: 'Produtos',
				name: 'Produtos'
			}
		],
		[]
	)

	// Get de Produtos e categorias
	React.useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true)

				const responseCategories =
					await categoriesServices.getCategoriesAdmin()
				const sortListCategories =
					sortListByListPosition(responseCategories)
				setCategorys(addNameValueToCategory(sortListCategories))

				let productsItems = []
				if (!!selectedCategory.value) {
					const responseProducts =
						await categoriesServices.getProductsByCategorie({
							ID: selectedCategory.value,
							isCategoriesFeatureFlag
						})
					productsItems = responseProducts
				}

				const selectedOptionsData = orderOptionsGenerate({
					selectedOrderOption
				})

				const optionsToSet = isProductsOrder
					? sortListByListPosition(productsItems)
					: sortListCategories

				if (
					Object.values(selectedOptionsData).length &&
					optionsToSet.length >= 0
				) {
					// Passando dados de categorias ou produtos para o componente orderData
					setOrderData({
						...selectedOptionsData,
						options: optionsToSet
					})
				}
			} catch (error) {
				cogoToast.error(
					isProductsOrder
						? 'Erro ao trazer dados de produto'
						: 'Erro ao trazer dados de categoria',
					cogoDefaultOptions
				)
			} finally {
				setIsLoading(false)
			}
		}
		fetchData()
	}, [selectedOrderOption, isProductsOrder, selectedCategory])

	React.useEffect(() => {
		const shouldShowOrderComponent =
			(isProductsOrder && selectedCategory.value) || isCategoryOrder

		if (!isLoading) {
			setCanShowOrderComponent(Boolean(shouldShowOrderComponent))
		}
	}, [
		selectedOrderOption,
		selectedCategory,
		isLoading,
		isProductsOrder,
		isCategoryOrder
	])

	const handleConfirmNewOrder = async () => {
		try {
			setIsLoading(true)
			setCanShowConfirmOrderModal(false)

			let newSortedOptions

			if (isProductsOrder) {
				newSortedOptions = finalOrderedListMaterial({
					array: sortedOptions,
					selectedCategory
				})

				await materialsServices
					.updateMaterialsList(
						newSortedOptions,
						isCategoriesFeatureFlag
					)
					.then((success) => {
						setSortedOptions([])
						handleResetOrderComponent({
							responseCategoriesOrder: success
						})
						// cogoToast.success(
						// 	'Ordem de exibição de produtos alterada com sucesso!',
						// 	cogoDefaultOptions
						// )
						setShowOrderSucessMessage(true)
						setOrderSucessMessage(
							'Ordem de exibição de produtos alterada com sucesso!'
						)
					})
			} else {
				newSortedOptions = finalOrderedListCategory({
					array: sortedOptions
				})

				await categoriesServices
					.updateCategoriesList(newSortedOptions)
					.then((success) => {
						setSortedOptions([])
						handleResetOrderComponent({
							responseCategoriesOrder: success
						})
						// cogoToast.success(
						// 	'Ordem de exibição de categorias alterada com sucesso!',
						// 	cogoDefaultOptions
						// )
						setShowOrderSucessMessage(true)
						setOrderSucessMessage(
							'Ordem de exibição de categorias alterada com sucesso!'
						)
					})
			}
		} catch (error) {
			cogoToast.error(
				'Erro inesperado, tente novamente mais tarde',
				cogoDefaultOptions
			)
		} finally {
			setIsLoading(false)
		}
	}

	async function handleResetOrderComponent({
		responseCategoriesOrder
	}: {
		responseCategoriesOrder: ICategories[]
	}) {
		const selectedOptionsData = orderOptionsGenerate({
			selectedOrderOption
		})

		await setCanShowOrderComponent(false)

		await setOrderData({
			...selectedOptionsData,
			options: responseCategoriesOrder
		})

		await setCanShowOrderComponent(true)
	}

	return (
		<>
			<TopModalSucess
				isOpen={showOrderSucessMessage}
				handleHideModal={() => setShowOrderSucessMessage(false)}
				sucessToastMessage={orderSucessMessage}
			/>
			<ConfirmOrderModal
				isOpen={canShowConfirmOrderModal}
				handleToggleModal={() => setCanShowConfirmOrderModal(false)}
				isProduct={isProductsOrder}
				orderOPtions={sortedOptions}
				handleConfirmOrder={() => handleConfirmNewOrder()}
			/>
			<DefaultLayoutAdmin>
				<>
					<LoadingCcontainer isLoading={isLoading} />
					<div>
						<TitlePage titlePage="Ordenação" />
						<S.WrapperOrderView>
							<SubTitlePage subtitlePage="Filtros" />
							<div className="wrapper-list-selector">
								<div className="wrapper-data-selector">
									<DataSelectorList
										dataSelectorListID="selector-order"
										list={listOptions}
										title="Selecione o que deseja ordenar"
										handleChange={(event) => {
											setSelectedOrderOption(event.target)
											setSelectedCategory({
												name: '',
												value: ''
											})
										}}
										selectedOption={selectedOrderOption}
									/>
								</div>
								{isProductsOrder && (
									<div className="wrapper-data-selector">
										<DataSelectorList
											dataSelectorListID="selector-category"
											list={categorys}
											title="Selecione uma categoria"
											handleChange={(event) =>
												// TODO: Após a implementação da rota de produtos filtrados por categoria
												// adicionar ao onChange a função para atualizar os produtos.
												setSelectedCategory(
													event.target
												)
											}
											selectedOption={
												'selectedOrderOption'
											}
										/>
									</div>
								)}
							</div>
						</S.WrapperOrderView>
						{canShowOrderComponent ? (
							// Exibe a div com os dados de produtos ou categorias
							<SelectedOrderOption
								{...orderData}
								handleOpenConfirmModal={() =>
									setCanShowConfirmOrderModal(true)
								}
								sortedOptions={sortedOptions}
								handleGetSortedOptions={setSortedOptions}
							/>
						) : (
							// Div vázia para exibição da tela sem opção selecionada
							<S.EmptyContentWrapper></S.EmptyContentWrapper>
						)}
					</div>
				</>
			</DefaultLayoutAdmin>
		</>
	)
}

export default OrderAdmin
