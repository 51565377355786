import styled from 'styled-components'

export const Container = styled.div`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	span {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		color: ${(props) => props.theme.palette.gray.main};
		margin-left: 8px;
	}

	h1 {
		margin-bottom: 10px;
	}

	.radio-group {
		display: flex;
		flex-direction: column;

		label {
			display: flex;
			align-items: center;
			border-bottom: 1px solid
				${(props) => props.theme.palette.lightGray.main};
			padding: 19px 0;
			cursor: pointer;
		}

		.label-sms {
			color: ${(props) => props.theme.palette.primary.main};
			margin: 0;
		}
	}
	margin: 50px 0;
`
