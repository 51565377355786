import styled from 'styled-components'
import { mediaQuery } from '../../shared/styles/functions'
import HeroImage1366 from '../../assets/images/heroimage-1366.png'
import HeroImage1920 from '../../assets/images/heroimage-1920.png'

export const AuthContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100vw;
	height: 100vh;
	align-items: center;
	align-content: center;

	${(props) => props.theme.breakpoints.down('sm')} {
		display: block;
	}
`

export const AuthBackground = styled.div`
	background-image: url(${HeroImage1366});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-color: ${(props) => props.theme.palette.primary.main};
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	justify-content: space-between;
	padding-top: 32px;

	${(props) => props.theme.breakpoints.up('xl')} {
		background-image: url(${HeroImage1920});
	}

	.logo-wrapper {
		text-align: center;
	}
	.img-logo {
		width: 130px;
		height: 56px;
		margin-bottom: 20px;
	}

	h1 {
		font-size: ${(props) => props.theme.font.size.DISPLAY_SM.size}rem;
		color: ${(props) => props.theme.palette.primaryWhite.main};
		line-height: ${(props) =>
			props.theme.font.size.DISPLAY_SM.line_height}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
		width: 360px;
		text-align: center;
	}

	.footer-login {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 32px;

		h3 {
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			color: ${(props) => props.theme.palette.gray.main};
			line-height: ${(props) =>
				props.theme.font.size.TEXT_MD.line_height}rem;
		}

		figure {
			text-align: center;
			margin-right: 40px;
		}
	}

	.contact {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${(props) => props.theme.palette.gray.main};
		line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
	}

	.red {
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const LoginContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${(props) => props.theme.palette.primaryWhite.main};
	height: 100vh;
	justify-content: space-between;

	.first-access {
		width: 100%;
		background: ${(props) => props.theme.palette.white.main};
		padding: 40px;
		border: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${(props) => props.theme.palette.primary.main};
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		border-radius: 4px;
		margin-bottom: 10px;
		cursor: pointer;
	}

	.login-form {
		margin: auto;
		padding: 0;
	}

	${mediaQuery('sm')(`
        .login-form {
            padding: 16px;
        }
    `)}

	${mediaQuery('md')(`
        .login-form {
            padding: 24px;
        }
    `)}
	.login {
		background: ${(props) => props.theme.palette.white.main};
		padding: 40px;
		border-radius: 4px;
		margin-bottom: 18px;
		.btn-login {
			margin-top: 20px;
			text-align: center;
		}
	}

	h1 {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${(props) => props.theme.palette.lightGray.main};
		line-height: ${(props) =>
			props.theme.font.size.DISPLAY_XS.line_height}rem;
		letter-spacing: 0.38px;
		font-weight: ${(props) => props.theme.font.weight.regular};
		margin-bottom: 20px;
		text-align: start;
	}

	.recover-password {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
		color: ${(props) => props.theme.palette.lightGray.main};
		text-align: center;

		.recover-password-link {
			color: ${(props) => props.theme.palette.primary.main};
			background: none;
			border: none;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	footer {
		border-top: 1px solid ${(props) => props.theme.palette.lightGray.main};
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		display: flex;
		height: 144px;
		.btn-privacy {
			background: none;
			cursor: pointer;
			border-radius: 4px;
			border: none;
			text-transform: uppercase;
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			color: ${(props) => props.theme.palette.lightGray.main};
			padding: 12px 20px;
			line-height: ${(props) =>
				props.theme.font.size.TEXT_XS.line_height}rem;
			letter-spacing: 0.64px;

			&:hover {
				background-color: ${(props) => props.theme.palette.white.main};
			}
		}
	}
`
