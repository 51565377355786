import * as React from 'react'

import { IProps } from './types'

// Functional Component
const Modal = ({ isActive, children, isFull }: IProps): JSX.Element => (
	<section className={`modal-primary ${isActive ? 'active' : ''}`} style={{zIndex:99}}>
		<div className="modal-bg">
			<div className={`modal-container ${isFull && 'full'}`}>
				{children ? children : null}
			</div>
		</div>
	</section>
)

export default Modal
