// import { handleCognitoError } from "../../shared/utils/handleCognitoError"
import { AxiosResponse } from 'axios'
import cogoToast from 'cogo-toast'
import { add, isAfter, parseISO } from 'date-fns'
import { UseQueryResult, useQuery } from 'react-query'
import { IGPClientData } from '../../modules/NewAccount/types'
import { ICompanyItem } from '../../modules/RegisterParameter/types'
import { getCompanyParams } from '../../modules/ResaleParameters/service'
import { IUser } from '../../modules/SAC/PendingApproval/types'
import { CompanyParamNames } from '../enums/companyParams'
import { UserTypeEnum } from '../interfaces/customer'
import { IStoreCustomer } from '../interfaces/store'
import { store } from '../store'
import { Creators as CustomerActions } from '../store/ducks/customer'
import { QUERY_KEYS } from '../utils/reactQuery'
import axiosInstance, { axiosGPInstance, logout } from './axiosInstace'
import { loadCompanyParams } from './companyParams.service'
//import AWS from './awsInstance'
// import amplifyConfig from '../../amplify-config'

interface IFindCustomerProps {
	document: string
	salesOrgId?: string
}

export async function findCustomer({
	document,
	salesOrgId
}: IFindCustomerProps): Promise<IUser> {
	const response = await axiosGPInstance.get(
		`/customers/${document}?salesOrgId=${salesOrgId}`
	)

	return response.data
}

export async function deleteCustomer(customerIds: string) {
	try {
		const response = await axiosGPInstance.delete(
			`/signup-tracking/${customerIds}`
		)

		return response.data
	} catch (err) {
		throw err
	}
}
/*
export async function deleteCognitoCustomer(userDocument: string) {
    try {
        const cognito = new AWS.CognitoIdentityServiceProvider();

        const response = await cognito.adminDeleteUser({
            UserPoolId: amplifyConfig.aws_user_pools_id,
            Username: userDocument,
        }).promise();

        return response
    } catch (err) {
        handleCognitoError(err)
    }
}*/

export async function getCustomerRegisterValidationAttempts({
	customerID,
	salesOrganizationID
}: IGPClientData): Promise<{ customerNotPurchase: boolean; attempts: any[] }> {
	// TODO LOCAL
	// const response = await axiosInstance.get(
	// 	`/signup-tracking/register-validation-attempts?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`,
	// )
	// TODO DEV/PROD
	const response = await axiosGPInstance.get(
		`/customers/register-validation-attempts?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`
	)

	return response.data
}

export async function getProductsForValidationByLastPurchase({
	customerID,
	salesOrganizationID
}: IGPClientData) {
	// TODO LOCAL
	// const response = await axiosInstance.get(
	//     `/customers/getProductsForValidationByLastPurchase?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`,
	// )
	// TODO DEV/PROD
	const response = await axiosGPInstance.get(
		`/customers/getProductsForValidationByLastPurchase?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`
	)

	return response.data
}

export async function editCustomerData(customerID: string, customerData: any) {
	const response: AxiosResponse = await axiosInstance.put(`/customers/`, {
		customerID,
		fields: customerData
	})

	return response.data
}

export interface IResponseUnicoAccessToken {
	accessTokenUnico: string
}
export async function getUnicoAccessToken(): Promise<IResponseUnicoAccessToken> {
	const response: AxiosResponse = await axiosGPInstance.get(
		`/customers/unico/get-access-token`
	)

	return response.data
}
export interface IRequestCreateProcesses {
	cpf: string
	name: string
	imagebase64Selfie: string
	accessTokenUnico: string
}

export interface IResponseCreateProcesses {
	Id: string
}

export async function unicoCreateProcesses(
	payload: IRequestCreateProcesses
): Promise<IResponseCreateProcesses> {
	const response: AxiosResponse = await axiosGPInstance.post(
		`/customers/unico/create-process`,
		payload
	)

	return response.data
}

export interface IRequestDocumentInsert {
	type: string
	processoId: string
	imagebase64: string
	accessTokenUnico: string
}

export interface IResponseDocumentInsert {
	Id: string
	Typed: string
	Document?: {
		BirthDate: string
		PlaceOfBirth: string
		Category: string
		ExpeditionCity: string
		ExpeditionState: string
		ExpeditionDate: string
		ExpirationDate: string
		FatherName: string
		MotherName: string
		FirstLicenseDate: string
		Name: string
		RG: string
		Code: string
		RegistrationNumber: string
		Renach: string
		SecurityCode: string
		MirrorNumber: string
		CRM: string
		Observation: string
	}
}
export async function unicoDocumentInsert(
	payload: IRequestDocumentInsert
): Promise<IResponseDocumentInsert> {
	const response: AxiosResponse = await axiosGPInstance.post(
		`/customers/unico/document-insert`,
		payload
	)

	return response.data
}

export interface IRequestExecuteAndGetProcess {
	processoId: string
	accessTokenUnico: string
}

export async function unicoExecute(payload: IRequestExecuteAndGetProcess) {
	const response: AxiosResponse = await axiosGPInstance.post(
		`/customers/unico/execute`,
		payload
	)

	return response.data
}

export async function unicoGetProcess(payload: IRequestExecuteAndGetProcess) {
	const response: AxiosResponse = await axiosGPInstance.post(
		`/customers/unico/get-process`,
		payload
	)

	return response.data
}

export interface IResponseGetConsultProcess {
	FaceMatch: number
	HasBiometry: boolean
	Id: string
	Liveness: number
	OCRCode: number
	Status: number
	Score: number
}

export function useUnicoGetProcess({
	accessTokenUnico,
	processoId
}: IRequestExecuteAndGetProcess): UseQueryResult<IResponseGetConsultProcess> {
	const enabled = !!accessTokenUnico && !!processoId

	return useQuery(
		[QUERY_KEYS.UNICO_GET_STATUS_PROCESS, { accessTokenUnico, processoId }],
		async ({ queryKey }) => {
			const [, params] = queryKey
			const { accessTokenUnico, processoId } =
				params as IRequestExecuteAndGetProcess

			return unicoGetProcess({ accessTokenUnico, processoId })
		},
		{
			enabled,
			staleTime: 1,
			refetchInterval: 7000,
			retry: 0
		}
	)
}

export async function requestTermsAdhesion() {
	const response: AxiosResponse = await axiosGPInstance.get(
		`/customers/term-of-adhesion`
	)

	return response.data
}

export interface IRequestRegisterOptimization {
	Lat: string
	Long: string
	Region: string
}

export async function getRegistrationOptimization(
	payload: IRequestRegisterOptimization
) {
	const response: AxiosResponse = await axiosGPInstance.post(
		`/customers/get-otimizacao-cadastro-from-geocode`,
		{
			customerGeocode: { ...payload }
		}
	)

	return response.data
}

async function getCompanyParamsVerifyAndDispatch({
	customer
}: {
	customer: IStoreCustomer
}) {
	const companyParams = await getCompanyParams(customer.SalesOrganizationID)

	if (customer.createdAt) {
		await verifyDaysToAproval({
			createdAt: customer.createdAt,
			companyParams
		})
	}

	if (!companyParams) return

	await loadCompanyParams(customer)

	store.dispatch(
		CustomerActions.updateCustomer({
			...customer,
			Type: UserTypeEnum.CUSTOMER
		})
	)
}

function findDaysToAproval({
	companyParams
}: {
	companyParams?: ICompanyItem[]
}) {
	if (!companyParams) return 0

	const DIAS_PARA_APROVAR_CLIENTE = companyParams.find(
		(param) =>
			param.Parameter === CompanyParamNames.DIAS_PARA_APROVAR_CLIENTE
	)

	const paramValue = DIAS_PARA_APROVAR_CLIENTE?.Items[0]?.Value
		? Number(DIAS_PARA_APROVAR_CLIENTE?.Items[0]?.Value)
		: 0

	return paramValue
}

function isTodayAfterDaysToAprovalDeadline({
	daysToAproval,
	createdAt
}: {
	daysToAproval: number
	createdAt: string
}) {
	if (daysToAproval === 0) return true

	const today = new Date()
	const createdDateParsed = parseISO(createdAt)
	const deadLine = add(createdDateParsed, { days: daysToAproval })
	const canLogin = isAfter(today, deadLine)

	return canLogin
}

async function verifyDaysToAproval({
	createdAt,
	companyParams
}: {
	createdAt: string
	companyParams?: ICompanyItem[]
}) {
	const daysToAproval = findDaysToAproval({ companyParams })

	const canLogin = await isTodayAfterDaysToAprovalDeadline({
		daysToAproval,
		createdAt
	})

	if (!canLogin) {
		cogoToast.error(
			'Login ainda não liberado, por favor entre em contato com o SAC.'
		)
		logout()
		throw new Error('')
	}
}


export async function getUserDeliveryDate({
	customerID,
	salesOrganizationID,
	sectorID
}: IGPClientData) {
	try {
		const response = await axiosInstance.get(
			`/customers/deliverydate?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}&sectorID=${sectorID}`,
		)

		return response.data
	} catch (err) {
		throw err
	}
}


export {
	findDaysToAproval,
	getCompanyParamsVerifyAndDispatch, isTodayAfterDaysToAprovalDeadline, verifyDaysToAproval
}

