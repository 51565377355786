import { __, cond, has, identity, is, isNil } from 'ramda'

import { CompanyParamNames } from '../enums/companyParams'
import { IFormattedParam } from '../interfaces/formattedParam'
import { getHoursString } from './resale'

export const translatedTypes = {
	BOOLEAN: 'Boolean',
	STRING: 'Texto',
	TIME: 'Tempo',
	MONEY: 'Monetário'
}

export const translatedValueTypes = {
	SINGLE: 'Único',
	INTERVAL: 'Intervalo',
	RANGE: 'Espectro'
}

export const getMinPriceFromParams = (companyParams: IFormattedParam[]) => {
	const MIN_VALUE_PARAM = companyParams.find(
		(param) => param.name === String(CompanyParamNames.VAL_MIN_PEDIDO)
	) || { value: ['0'] }

	const numberValue = Number(MIN_VALUE_PARAM.value[0])

	return numberValue
}

export const getMinPriceBoletoFromParams = (
	companyParams: IFormattedParam[]
) => {
	const MIN_VALUE_PARAM = companyParams.find(
		(param) =>
			param.name === String(CompanyParamNames.VAL_MIN_PEDIDO_BOLETO)
	) || { value: ['0'] }

	const numberValue = Number(MIN_VALUE_PARAM.value[0])

	return numberValue
}

export const getWorkingHoursParams = (companyParams: IFormattedParam[]) => {
	const HORARIO_ATEND_UND = companyParams.find(
		(param) => param.name === String(CompanyParamNames.HORARIO_ATEND_UND)
	) || { value: ['', ''] }

	const values = HORARIO_ATEND_UND.value

	return values
}

// TODO: need to type the param for this fn
export const returnParamField = (x: any): string => {
	function getFrom<T>(from: T) {
		return <K extends keyof T>(key: K) => from[key]
	}

	const isType = has(__, translatedTypes)

	const isValueType = has(__, translatedValueTypes)

	const getVal = cond<any, any>([
		[is(Date), getHoursString],
		[isType, getFrom(translatedTypes)],
		[isValueType, getFrom(translatedValueTypes)],
		[Boolean, identity],
		[isNil, (_) => '-']
	])

	return getVal(x)
}

export function getLocalizationAccess(companyParams: IFormattedParam[]) {
	const HABILITA_VENDA_BOM_PARCEIRO = companyParams.find(
		(param) =>
			param.name === String(CompanyParamNames.HABILITA_VENDA_BOM_PARCEIRO)
	) || { value: ['0'] }

	return HABILITA_VENDA_BOM_PARCEIRO.value[0] === 'Sim' ? true : false
}

export function getSalesOrgHasProgramPoints(companyParams: IFormattedParam[]) {
	const LIBERA_PROGRAMA_PONTOS = companyParams.find(
		(param) =>
			param.name === String(CompanyParamNames.LIBERA_PROGRAMA_PONTOS)
	) || { value: ['0'] }

	return LIBERA_PROGRAMA_PONTOS.value[0] === 'true' ? true : false
}
