/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect, useCallback } from 'react'

import NavBar from '../../modules/NavBar'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { IStore } from '../../shared/interfaces/store'
import CustomerPortalHeader from '../CustomerPortalHeader'
import CartSummary from '../CartSummary'
import { headerAssetPaths } from './data'
import LogoutIcon from '../../assets/images/logout-grey.svg'
import { logout } from '../../shared/services/axiosInstace'
import { IProps } from './types'
import * as S from './header.styles'
import SelectCD from './components/select-cd.component'
import { formattedList } from './utils/formatted-list'
import { useHistory } from 'react-router-dom'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'
import { UserTypeEnum } from '../../shared/interfaces/customer'
import { AdminRoutesEnum } from '../../modules/Routes/adminRoutesEnum'

function Header({ disabledMenuCart, isLoading }: IProps): JSX.Element {
	const storeKeys: (keyof IStore)[] = ['customer']
	const [cds, setCds] = useState<any[]>([])
	const [activeCd, setActiveCd] = useState('')
	const history = useHistory()

	const { customer } = useTypedSelector(storeKeys)

	const [isMenuOpen, , , toggle] = useBoolean(false)

	const { Type } = customer || {}

	const isCustomer = Type === 'CUSTOMER'

	const assetKey = !isCustomer ? 'sac' : 'default'

	const wrapperClass = `page-header-wrapper ${assetKey}`

	const { logo, menuIcon } = headerAssetPaths[assetKey]

	function handleLogoutAdmin() {
		logout()
		history.push(UnauthenticatedRoutesEnum.GP_LOGIN)
	}

	const handleGoBackHome = useCallback(() => {
		if (Type === UserTypeEnum.ADMIN) {
			history.push(AdminRoutesEnum.RESALE_LIST)
		} else if (Type === UserTypeEnum.SAC) {
			history.push(AdminRoutesEnum.SAC_PENDING_APPROVAL)
		} else if (Type === UserTypeEnum.PRICING) {
			history.push(AdminRoutesEnum.RESALE_PRODUCTS)
		} else if (Type === UserTypeEnum.UNIDADE) {
			history.push(AdminRoutesEnum.SAC_PENDING_APPROVAL)
		}
	}, [Type, history])

	useEffect(() => {
		// Formatando dados para a listagem no select
		if (customer.companies) {
			const formattedCd = formattedList(customer.companies)
			setActiveCd(formattedCd[0].salesOrganizationID)
			setCds(formattedCd)
		}
	}, [customer.companies])

	if (isCustomer)
		return (
			<CustomerPortalHeader
				disabledMenuCart={disabledMenuCart === true}
				isLoading={isLoading}
			/>
		)

	return (
		<Fragment>
			<NavBar isOpen={isMenuOpen} handleSetIsOpen={toggle} />
			<header className={wrapperClass}>
				<div className="page-header">
					<S.WrapperUserHeader>
						<S.WrapperSelectHamburger>
							<div
								className="menu"
								id={'menu-hamburguer'}
								onClick={toggle}>
								<img src={menuIcon} alt="menu" />
							</div>
							<div>
								{customer.Type === 'UNIDADE' && (
									<>
										{customer.Name ? (
											<p>Bem vindo, {customer.Name}</p>
										) : null}
										<SelectCD
											cdList={cds}
											activeCd={activeCd}
											setActiveCd={setActiveCd}
										/>
									</>
								)}
							</div>
						</S.WrapperSelectHamburger>
					</S.WrapperUserHeader>

					<div
						className="gp-logo-wrapper"
						style={{ cursor: 'pointer' }}>
						<picture>
							<source
								className="gp-logo"
								media="(max-width: 767px)"
								srcSet={logo}
							/>
							<img
								onClick={() => handleGoBackHome()}
								className="gp-logo"
								src={logo}
								alt="Grupo Petrópolis"
							/>
						</picture>
					</div>

					<div className="right-corner-wrapper">
						<ul className="navbar">
							{isCustomer ? (
								<li>
									<CartSummary />
								</li>
							) : (
								<li className="logout-button-wrapper">
									<button
										onClick={() => handleLogoutAdmin()}
										className="logout-button">
										<span>Sair</span>
										<img
											src={LogoutIcon}
											alt="Logout icon"
										/>
									</button>
								</li>
							)}
						</ul>
					</div>
				</div>
			</header>
		</Fragment>
	)
}

export default Header
