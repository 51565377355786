import {
	CreateSuggestedOrderTypes,
	ResponseSuggestedOrdersAdmin,
	SuggestedOrdersAdminTypes
} from '../types/suggested-orders-admin.types'

class SuggestedOrdersAdminMapper {
	toDomainSuggestedOrders(
		persistence: ResponseSuggestedOrdersAdmin
	): SuggestedOrdersAdminTypes {
		return {
			ID: String(persistence.ID),
			title: persistence.title,
			banner: persistence.banner,
			mainProducts: persistence.mainProducts,
			optionalProducts: persistence.optionalProducts,
			status: persistence.status,
			userId: persistence.userId
		}
	}
	toPersistence(
		domain: CreateSuggestedOrderTypes
	): CreateSuggestedOrderTypes {
		return {
			userId: domain.userId,
			title: domain.title,
			banner: {
				id: domain.banner.id === '' ? String(new Date().getTime()) : domain.banner.id,
                url: domain.banner.url
			},
			mainProducts: domain.mainProducts,
			optionalProducts:
				domain.optionalProducts?.map((optionProduct) => {
					return {
						materialId: optionProduct.materialId,
						materialQuantity: 0
					}
				}) ?? [],
			status: domain.status
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SuggestedOrdersAdminMapper()
