import { createElement, useEffect, useMemo, useState } from 'react'
import { sortName } from '../../../shared/utils/sorts'
import { getProcessStatusDetails } from './service'
import { IViewProps, ITableData } from './types'
import ResaleList from './view'
import { include } from '../../../shared/utils/boolean'
import { useHistory, useLocation } from 'react-router-dom';
import { parseFormat } from '../../../shared/utils/date'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../shared/utils/toaster'
import { AdminRoutesEnum } from '../../Routes/adminRoutesEnum'

function MassProcessLogDetail(): JSX.Element {

	const history = useHistory()

	const location = useLocation<any>()

	const [data, setData] = useState<ITableData[]>([])
	const [tableData, setTableData] = useState<ITableData[]>([])
	const [isLoading, setIsLoading] = useState(false)

	const columns = useMemo(
		() => [
			{ Header: 'ID da Solicitação', accessor: 'parent_ID' },
			{ Header: 'ID único', accessor: 'ID' },
			{ Header: 'CPF/CNPJ', accessor: 'fiscalID', sortType: sortName },
			{ Header: 'Status', accessor: 'finished' },
			{ Header: 'Data e Hora de processamento', accessor: 'timeStamp' }
		],
		[]
	)

	function handleSearchOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.ID, value) ||
				include(item.parent_ID, value) ||
				include(item.fiscalID, value) ||
				include(item.finished, value)
			)
		})

		setTableData(list)
	}

	function handleSetTableData() {
		if (!tableData.length) {
			setTableData(data.map((item) => item))
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleSetTableData, [])

	const refreshButton = async () => {
		try {
			setIsLoading(true)
			await ProcessStatusDetails()
			setIsLoading(false)
		}
		catch{
			cogoToast.error("Falha ao atualizar página!", cogoDefaultOptions)
			return
		}
	}

	async function ProcessStatusDetails() {
		try {
			setIsLoading(true)

			const specificDetailsExclusion = await getProcessStatusDetails(location.state.idSolicitacao)
		
			if (!specificDetailsExclusion.length) return


			const data = specificDetailsExclusion.map((resale) => ({

				ID: resale.ID,
				parent_ID: resale.parent_ID,
				fiscalID: resale.fiscalID,
				finished: 
				resale.finished === 0 ? "Não processado"
					: resale.finished === 1 ? "Usuário excluído com sucesso"
						: resale.finished === 2 ? "Erro interno no cognito."
							: resale.finished === 3 ? "Erro de parâmetro."
								: resale.finished === 4 ? "Não autorizado!"
									: resale.finished === 5 ? "Rota não existente!"
										: resale.finished === 6 ? "Falha, muitas requisições realizadas."
											: resale.finished === 7 ? "Este usuário não existe!"
												: "", // arquivo que não subiu

				//payload de finished 2
				// 0- não processado
				// 1- concluído
				// 2- erro interno no cognito (InternalErrorException)
				// 3- parâmetro inválido (InvalidParameterException)
				// 4- usuário não autorizado (NotAuthorizedException)
				// 5- não encontra a rota (ResourceNotFoundException)
				// 6- muitas requisições (TooManyRequestsException)
				// 7- usuário não existe (UserNotFoundException)
				timeStamp: resale.timeStamp === null ? "Em processamento" : parseFormat(resale.timeStamp),

			}))

			setData(data)
			setTableData(data)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		;(async () => {
			await ProcessStatusDetails()
		})()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleGoBack = () => history.replace(AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS)

	const viewProps: IViewProps = {
		handleSearchOnChange,
		tableData,
		handleGoBack,
		columns,
		isLoading,
		refreshButton
	}

	return createElement(ResaleList, viewProps)
}

export default MassProcessLogDetail
