import styled from 'styled-components'
import ShoppingCartWhiteIcon from '../../assets/images/shopping-cart-white.svg'
import { colorThemeProvider } from '../../theme/themeProvider'

export const Container = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
`

export const TotalProductsBadge = styled.div<{
	cartIsEmpty?: boolean
}>`
	padding: 4px 10px;
	border-radius: 11px;
	background-color: ${({ cartIsEmpty }) =>
		cartIsEmpty
			? colorThemeProvider.secondaryColor
			: colorThemeProvider.whiteColor};
	margin-right: 8px;

	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	color: #CE332E;
`

export const TotalValuesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin-right: 32px;
`

export const TotalPrice = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.bold};
	color: ${(props) => props.theme.palette.white.main};
`

export const TotalPoints = styled.span`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${(props) => props.theme.palette.white.main};
	margin-top: 4px;
`

export const CartIcon = styled.img.attrs(() => ({
	src: ShoppingCartWhiteIcon,
	alt: 'Icone do Carrinho',
	height: 24,
	width: 24
}))``
