import styled from 'styled-components'

export const ImpactedClientsStyles = styled.div`
	display: flex;
	align-items: center;
	gap: 20px;

	.impacted-clients-title {
		font-weight: ${(props) => props.theme.font.weight.bold};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height};
		color: ${(props) => props.theme.palette.black.main};
	}

	.impacted-clients-value {
		display: flex;
		align-items: center;
		justify-content: center;

		border: 2px solid #d1d1d1;
		border-radius: 24px;
		height: 24px;
		width: 80px;

		p {
			font-weight: ${(props) => props.theme.font.weight.bold};;
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height};
			color: ${(props) => props.theme.palette.black.main};
		}
	}
`
