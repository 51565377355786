import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const WrapperContent = styled.div`
	height: 162px;
	position: relative;
	p {
		position: absolute;
		bottom: 0;
		right: 20px;
		padding-bottom: 10px;

		font-weight: 400;
		font-size: 9px;
		color: ${(props) => props.theme.palette.primary.main};
	}
`

export const TextWrapper = styled(TextField)`
	width: 568px;
	height: 162px;

	& .MuiOutlinedInput-root {
		border-radius: 5px;
		height: 162px;
		fieldset {
			border: 1px solid
				${(props) => props.theme.palette.extras.stroke.main};
			border-radius: 5px;
		}

		& .MuiInputBase-input {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size};
            font-weight: 400;
			color: #5d615d;

		}

		&:hover fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}

		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.primary.main};
		}
	}

	& .MuiInputLabel-root {
		color: #5d615d;

		&.Mui-focused {
			color: ${(props) => props.theme.palette.primary.main};
		}
	}
`
