import React, { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Slider, { Settings } from 'react-slick'

import ChevronLeftSVG from '../../../assets/images/chevron-slider-left.svg'
import ChevronRightSVG from '../../../assets/images/chevron-slider-right.svg'
import {
	ChallengeProgressBar,
	ChipWithInformationAboutTheChallenge
} from '../../../shared/components'
import { ChipTypeEnum } from '../../../components/ChipWithInformationAboutTheChallenge/types'
import useMedia from '../../../shared/hooks/useMedia'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import {
	ChallengeStatusEnum,
	IChallenge
} from '../../../shared/interfaces/pointsProgramChallenge'
import {
	calcTotalAmountAchievedWithPurchasedItems,
	calcTotalAmountReachedWithItemsInCart,
	calcTotalQuantity
} from '../../../shared/utils/challengesUtils'
import { remainingDaysLabel } from '../../../shared/utils/date'
import { CustomerRoutesEnum } from '../../Routes/customerRoutesEnum'

import * as S from './styles'
import { IProps } from './types'

interface IChevronProps {
	direction: 'left' | 'right'
	sliderRef: React.RefObject<Slider>
}

function Arrow({ direction, sliderRef }: IChevronProps) {
	const handleClick = () => {
		if (!sliderRef.current) return
		if (direction === 'left') sliderRef.current.slickPrev()
		if (direction === 'right') sliderRef.current.slickNext()
	}
	return (
		<S.ChevronContainer
			className="chevron"
			direction={direction}
			onClick={handleClick}>
			{direction === 'left' ? (
				<img src={ChevronLeftSVG} alt="Seta para a esquerda" />
			) : (
				<img src={ChevronRightSVG} alt="Seta para a Direita" />
			)}
		</S.ChevronContainer>
	)
}

export default function ChallengesCarousel({
	challenges,
	isParticipating
}: IProps) {
	const { pointsProgramStatus, cartChallengeItems } = useTypedSelector([
		'pointsProgramStatus',
		'cartChallengeItems'
	])

	const history = useHistory()

	const SliderRef = useRef<Slider>(null)
	const smallScreen = useMedia('(max-width: 820px)')

	const numberOfItemsToBeShownOnTheSlide =
		(challenges.length <= 1 &&
			!pointsProgramStatus.salesOrgHasProgramPoints) ||
		smallScreen
			? 1
			: challenges.length > 2 &&
			  pointsProgramStatus.salesOrgHasProgramPoints
			? 3
			: 2

	const settings: Settings = {
		infinite: true,
		slidesToShow: numberOfItemsToBeShownOnTheSlide,
		prevArrow: <Arrow direction="left" sliderRef={SliderRef} />,
		nextArrow: <Arrow direction="right" sliderRef={SliderRef} />,
		draggable: false
	}

	function onPressItem(challenge: IChallenge) {
		if (challenge.status !== ChallengeStatusEnum.ACTIVE) return
		history.push(CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS, {
			challenge
		})
	}

	function renderChallengeItem(
		challenge: IChallenge,
		challengeIndex: number
	) {
		const challengeFoundedInTheCart = cartChallengeItems.find(
			(cartChallengeItem) =>
				cartChallengeItem.challengeId === challenge.challengeId
		)

		const totalQuantity = calcTotalQuantity(challenge.products)

		const achievedAmountOfPurchasedItems =
			calcTotalAmountAchievedWithPurchasedItems(challenge.products)

		const quantityOfProductsLeftToBuy =
			totalQuantity - achievedAmountOfPurchasedItems

		const quantityReachedWithItemsInTheCart = challengeFoundedInTheCart
			? calcTotalAmountReachedWithItemsInCart(
					challengeFoundedInTheCart.addedProducts,
					challenge.products
			  )
			: 0

		const quantityReached =
			achievedAmountOfPurchasedItems + quantityReachedWithItemsInTheCart

		const productsRemaining =
			quantityOfProductsLeftToBuy - quantityReachedWithItemsInTheCart

		const indicativeMessageOfHowManyProductsRemainToComplete =
			productsRemaining > 0
				? `${productsRemaining} ${
						productsRemaining === 1 ? 'Produto' : 'Produtos'
				  } ${
						productsRemaining === 1 ? 'restante' : 'restantes'
				  } para completar`
				: 'Todos produtos adicionados'

		return (
			<S.ChallengeItem
				key={`${challengeIndex}-${challenge.challengeId}`}
				onClick={() => onPressItem(challenge)}>
				<S.ChallengeCardItem>
					<S.Banner className="banner">
						<img src={challenge.urlFoto} alt={challenge.title} />
						<div className="chip-group">
							<ChipWithInformationAboutTheChallenge
								label={`${Number(
									challenge.score
								).toLocaleString()} Pontos`}
								type={ChipTypeEnum.SHOW_POINTS_EARNED}
							/>
						</div>
					</S.Banner>
					<div className="challenge-info">
						<div className="chip-group">
							{challenge.countDayFinish &&
							!challenge.completedAt &&
							challenge.status !== ChallengeStatusEnum.EXPIRED ? (
								<ChipWithInformationAboutTheChallenge
									label={remainingDaysLabel(
										String(challenge.endDate)
									)}
									type={ChipTypeEnum.SHOW_REMAINING_DAYS}
								/>
							) : null}

							{isParticipating && !challenge.completedAt ? (
								<ChipWithInformationAboutTheChallenge
									label="Participando"
									type={ChipTypeEnum.IS_PARTICIPATING}
								/>
							) : null}

							{!!challenge.completedAt ? (
								<ChipWithInformationAboutTheChallenge
									label="Concluído"
									type={ChipTypeEnum.COMPLETED}
								/>
							) : null}
						</div>
						<h3 className="challenge-title">{challenge.title}</h3>
					</div>
					{isParticipating && (
						<S.ProgressBarWrapper>
							<ChallengeProgressBar
								isCompleted={!!challenge.completedAt}
								quantityReached={quantityReached}
								totalQuantity={totalQuantity}
							/>
							<S.ProgressTextMessage
								isCompleted={!!challenge.completedAt}>
								{!!challenge.completedAt
									? 'DESAFIO CONCLUÍDO'
									: indicativeMessageOfHowManyProductsRemainToComplete}
							</S.ProgressTextMessage>
						</S.ProgressBarWrapper>
					)}
				</S.ChallengeCardItem>
			</S.ChallengeItem>
		)
	}

	return (
		<S.Container className="carousel-container">
			{challenges.length < 3 ? (
				<S.ChallengesRow>
					{challenges.map((challenge, index) =>
						renderChallengeItem(challenge, index)
					)}
				</S.ChallengesRow>
			) : (
				<Slider className="slider" ref={SliderRef} {...settings}>
					{challenges.map((challenge, index) =>
						renderChallengeItem(challenge, index)
					)}
				</Slider>
			)}
		</S.Container>
	)
}
