import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { removeEspecialCharacters } from '../../shared/utils'
import { checkCNPJ, checkCPF } from '../../shared/utils/validators'
import InputField from '../InputField'
import { IProps } from './types'
import { formatCnpjCpf } from '../../shared/utils/form'

function InputDocument({
	returnValue,
	valueIsValid,
	disabled = false,
	className = '',
	valueStore,
	isMaskedRequired = true
}: IProps) {
	const cpfMask = '999.999.999-999999'
	const cnpjMask = '99.999.999/9999-99'

	const [mask, setMask] = useState(cpfMask)
	const [inputValue, setInputValue] = useState(valueStore || '')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const valueLength = e.target.value.replace(/\.|-|\//g, '').length
		if (valueLength >= 12) {
			setMask(cnpjMask)
		} else {
			setMask(cpfMask)
		}

		setTouched(true)
		returnValue(removeEspecialCharacters(e.target.value))
		setInputValue(e.target.value)
	}

	function validateInput(): void {
		if (mask === cnpjMask) {
			const isValid = checkCNPJ(inputValue)
			valueIsValid(isValid)
			setHasError(touched && !isValid)

			return
		}
		const isValid = checkCPF(inputValue)
		valueIsValid(isValid)
		setHasError(touched && !isValid)
	}

	useEffect(validateInput, [value, touched, inputValue, mask, valueIsValid])

	return (
		<InputField
			mask={isMaskedRequired ? mask : undefined}
			value={
				valueStore && valueStore.length
					? formatCnpjCpf(valueStore)
					: undefined
			}
			onChange={handleChange}
			id="documentNumber"
			label={'CPF OU CNPJ'}
			hasError={hasError}
			disabled={disabled}
			className={className}
		/>
	)
}

export default InputDocument
