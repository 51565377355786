import React from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FieldValues, UseControllerProps, useController } from 'react-hook-form'
import { DateTimePickerProps } from '@material-ui/pickers'
import ErrorText from '../error-text/error-text.component'
import { Stack } from '@mui/material'
import { StyledDateTimePicker } from './date-picker-with-time.styles'

import ptBR from 'date-fns/locale/pt-BR'

export type OnChangeType = (event: Date) => void

export type IDatePickerWithTime<T extends FieldValues> =
	UseControllerProps<T> & {
		label: string
		onAfterChange?: (date: Date | null, onChange?: OnChangeType) => void
	} & Partial<DateTimePickerProps>

function DatePickerWithTime<T extends FieldValues>({
	label,
	disablePast = false,
	onAfterChange,
	...rest
}: IDatePickerWithTime<T>) {
	const {
		field: { value, onChange, ref },
		fieldState: { error }
	} = useController(rest)

	return (
		<Stack flexDirection="column">
			<LocalizationProvider
				dateAdapter={AdapterDateFns}
				adapterLocale={ptBR}>
				<StyledDateTimePicker
					ref={ref}
					inputRef={ref}
					label={label}
					onChange={(date) => {
						onChange(date)
						onAfterChange?.(date, onChange)
					}}
					value={value ? new Date(value) : value ?? null}
					disablePast={disablePast}
					disableIgnoringDatePartForTimeValidation
				/>
			</LocalizationProvider>
			<ErrorText error={error?.message} />
		</Stack>
	)
}

export default DatePickerWithTime
