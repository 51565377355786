import { compareDesc, parseISO } from 'date-fns'

import { IRequestCancelStatus } from './types'

export const requests: Array<any> = [
	{
		situation: 'SOLICITADO',
		timeline: [
			{
				date: '12/12/12',
				text: 'pedido feito',
				flowStatus: 'IN_PROGRESS'
			}
		],
		requester: 'Bar dos Amigos',
		requestDate: 1594907761,
		orderNumber: 78564,
		orderValue: 1233
	},
	{
		situation: 'CANCELADO',
		timeline: [
			{
				date: '12/12/12',
				text: 'pedido feito',
				flowStatus: 'IN_PROGRESS'
			}
		],
		requester: 'Bar dos Amigos',
		requestDate: 1594907761,
		orderNumber: 78564,
		orderValue: 3211
	},
	{
		situation: 'RECUSADO',
		timeline: [
			{
				date: '12/12/12',
				text: 'pedido feito',
				flowStatus: 'IN_PROGRESS'
			}
		],
		requester: 'Bar dos Amigos',
		requestDate: 1594907761,
		orderNumber: 78564,
		orderValue: 3211.15
	}
]

export const DEFAULT_REQUEST = {
	AutoIncBackendID: 0,
	City: '',
	CompanyFiscalID: null,
	CustomerID: '',
	CustomerName: '',
	Neighborhood: '',
	PersonalFiscalID: '',
	PostalCode: '',
	Region: '',
	RequestCancelCreatedAt: '',
	RequestCancelID: '',
	RequestsCancelStatus: [],
	SalesOrderDeliveryDate: '', // yyyy-mm-dd
	SalesOrderID: '',
	SalesOrderItems: [],
	SalesOrderLegacyID: 0,
	SalesOrganizationID: '',
	SalesOrganizationName: '',
	Street: ''
}

function sortDate(a: IRequestCancelStatus, b: IRequestCancelStatus): number {
	const dateA = parseISO(a.CreatedAt)
	const dateB = parseISO(b.CreatedAt)
	return compareDesc(dateA, dateB)
}

export function findLastRequestCancelStatusDescription(
	items: IRequestCancelStatus[]
) {
	return items.sort(sortDate)[0]?.requestCancelStatusDescription || ''
}
