const TranslatedTypeOpts = {
	STRING: 'Texto',
	BOOLEAN: 'Booleano',
	TIME: 'Tempo',
	MONEY: 'Monetário'
}

const TranslatedValueTypeOpts = {
	SINGLE: 'Unico',
	INTERVAL: 'Intervalo',
	RANGE: 'Espectro'
}

const createOptionForLabel = (translated: Record<string, any>) => (
	value: string
) => ({ value, label: translated[value] })

export const getTypeOpt = createOptionForLabel(TranslatedTypeOpts)

export const getValTypeOpt = createOptionForLabel(TranslatedValueTypeOpts)

export const typeOpts = ['STRING', 'BOOLEAN', 'TIME', 'MONEY'].map(getTypeOpt)

export const valueTypeOpts = ['INTERVAL', 'SINGLE', 'RANGE'].map(getValTypeOpt)
