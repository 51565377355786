import { apiAdmin } from '../../shared/services/axiosInstace'

export async function validateSacurityQuestion(
	document: string,
	questions: { questionId: string; answer: string }[]
): Promise<{ isCorrect: boolean }> {
	return (
		await apiAdmin.post('/security-questions/validate', {
			document,
			questionAnswers: questions
		})
	).data
}

export async function updateNewEmail(
	document: string,
	newEmail: string
): Promise<boolean> {
	return (
		await apiAdmin.put('customers/security/save-email-update', {
			document,
			newEmail
		})
	).data
}

export async function updateNewPhone(
	document: string,
	newPhone: string
): Promise<boolean> {
	return (
		await apiAdmin.put('customers/security/save-phone-update', {
			document,
			newPhone
		})
	).data
}

export async function updateEmailCognito(
	document: string,
	newEmail: string
): Promise<boolean> {
	return (
		await apiAdmin.put('/customers/setEmailToCognito', {
			document,
			newEmail
		})
	).data
}

export async function updatePhoneCognito(
	document: string,
	newPhone: string
): Promise<boolean> {
	// console.log('phone')
	return (
		await apiAdmin.put('/customers/setPhoneToCognito', {
			document,
			newPhone
		})
	).data
}

export async function updateEmailCognitoWithValidation(
	document: string,
	newEmail: string
): Promise<boolean> {
	return (
		await apiAdmin.put('/customers/setEmailToCognitoValidation', {
			document,
			newEmail
		})
	).data
}

export async function updateRegistryAlternativeCognito(
	document: string,
	choiceEmail: boolean
): Promise<boolean> {
	return (
		await apiAdmin.put('/customers/setUserRegistryAlternativeToCognito', {
			document,
			choiceEmail
		})
	).data
}

export async function updatePhoneCognitoWithValidation(
	document: string,
	newPhone: string
): Promise<boolean> {
	return (
		await apiAdmin.put('/customers/setPhoneToCognitoValidation', {
			document,
			newPhone
		})
	).data
}
