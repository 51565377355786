import React from 'react'
import TitlePage from '../../components/title-page/title-page.component'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import ButtonDefault from '../../components/buttons/button/button-default'

import * as S from './suggested-orders-admin.styles'
import TextFieldLimitText from '../../components/inputs/text-field-limit-text/text-field-limit-text.component'
import BannerMidiaUpload from './components/banner-midia-upload/banner-midia-upload.component'
import { SuggestedToggle } from '../../components/Toggles/SuggestedToggle'
import useSuggestedOrdersAdmin from './hooks/use-suggested-orders-admin'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import { ProductManager } from './components/product-manager'
import TopModalSucess from '../../components/toast-sucess/toast-sucess.component'
import { TypeButtonEnum } from '../../components/buttons/button/button-default.types'

function SuggestedOrdersAdmin() {
	const {
		isSuggestedAvailable,
		selectedMidia,
		setIsScreenAvailable,
		setSelectedMidia,
		setTitleSuggestion,
		titleSuggestion,
		isLoadingSuggestedOrderData,
		mainProducts,
		setMainProducts,
		handleUpdateStatus,
		isOpenSucessMessage,
		setIsOpenSucessMessage,
		isDisabledSaveSuggestion,
		handleSaveSuggestion,
		optionalProducts,
		setOptionalProducts
	} = useSuggestedOrdersAdmin()
	return (
		<>
			<TopModalSucess
				isOpen={isOpenSucessMessage}
				sucessToastMessage="Pedido sugerido atualizado com sucesso"
				handleHideModal={() => setIsOpenSucessMessage(false)}
			/>
			<LoadingCcontainer isLoading={isLoadingSuggestedOrderData} />
			<DefaultLayoutAdmin>
				<S.WrapperSuggestedOrderAdmin>
					<S.HeaderWrapper>
						<TitlePage titlePage="Pedido Sugerido" />
						<SuggestedToggle
							handleChange={() =>
								setIsScreenAvailable(!isSuggestedAvailable)
							}
							selected={isSuggestedAvailable}
						/>
					</S.HeaderWrapper>
					{isSuggestedAvailable && (
						<>
							<h3>Informações</h3>
							<S.WrapperWidth width={480}>
								<TextFieldLimitText
									id="suggestion-title"
									label="Título de exibição de Pedido Sugerido"
									maxCharacters={24}
									onChange={(event) =>
										setTitleSuggestion(event.target.value)
									}
									text={titleSuggestion}
								/>
							</S.WrapperWidth>

							<BannerMidiaUpload
								selectedMidia={selectedMidia}
								setSelectedMidia={setSelectedMidia}
								isSelectedMidia={!!selectedMidia}
							/>

							<ProductManager
								onChange={(data) => {
									setMainProducts(
										data.map((item) => {
											return {
												materialId: item.MaterialID,
												materialQuantity:
													item.materialQuantity
											}
										})
									)
								}}
								edit={true}
								selectedProducts={mainProducts}
							/>
							<S.BuyTooWrapper>
								<TitlePage titlePage="Compre Também" />
								<ProductManager
									onChange={(data) =>
										setOptionalProducts(
											data.map((item) => {
												return {
													materialId: item.MaterialID,
													materialQuantity:
														item.materialQuantity
												}
											})
										)
									}
									edit={false}
									selectedProducts={optionalProducts}
								/>
							</S.BuyTooWrapper>
						</>
					)}
					<S.WrapperButtonCreateSuggestion>
						<ButtonDefault
							buttonText="SALVAR"
							onClick={() => {
								isSuggestedAvailable
									? handleSaveSuggestion()
									: handleUpdateStatus()
							}}
							idElement="create-suggested-order"
							width="200px"
							fontSize="10px"
							height="700"
							type={
								isDisabledSaveSuggestion
									? TypeButtonEnum.DISABLED
									: TypeButtonEnum.PRIMARY
							}
						/>
					</S.WrapperButtonCreateSuggestion>
				</S.WrapperSuggestedOrderAdmin>
			</DefaultLayoutAdmin>
		</>
	)
}

export default SuggestedOrdersAdmin
