import {
	IFilterSalesHierarchyDirector,
	IHeaderRulePoint,
	RuleParamsNameEnum
} from '../../shared/interfaces/pointsProgramRules'
import { PackageTypeEnum, UnitMeasureEnum } from '../RegisterProduct/types'

export interface IProps {
	rule?: IHeaderRulePoint
}

export interface IParams {
	rule_type: 'point-gain-ratio' | 'product-redemption'
}

export interface ITableData {
	code: string
	name: string
	packageType: PackageTypeEnum
	unitMeasure: UnitMeasureEnum
	size: string
	ScoreValue?: string
	ScoreRate?: string
	button: JSX.Element
}

export interface IOption {
	value: string
	label: string
}

export interface IFilterOptions {
	[RuleParamsNameEnum.DIRECTOR]: IOption[]
	[RuleParamsNameEnum.GOV]: IOption[]
	[RuleParamsNameEnum.RESALE]: IOption[]
	[RuleParamsNameEnum.CHANNEL]: IOption[]
	[RuleParamsNameEnum.STATE]: IOption[]
	[RuleParamsNameEnum.CITY]: IOption[]
}

export interface IValuesByProps {
	filterSelectOptions: IFilterOptions
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: IOption[]
	selectedFilterSelectOptions?: IFilterOptions
}

export enum ViewTypeEnum {
	CREATE = 'CREATE',
	UPDATE = 'UPDATE'
}
