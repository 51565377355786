import styled, { css } from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const Container = styled.div`
	background-color: ${colors.fifthGray};
	padding: 0 10vw 120px;
`

export const AlertWrapper = styled.div`
	margin-top: 32px;
	margin-bottom: 24px;
`

export const PageHeader = styled.div`
	margin: 32px 0;

	a.go-back-button {
		display: flex;
		flex-direction: row;
		align-items: center;

		img {
			height: 16px;
			width: 16px;
		}

		span {
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			color: ${colors.red};
			margin-left: 8px;
		}
	}

	h1.pg-title {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		color: ${colors.secondGrey};
		margin-top: 24px;
	}
`

export const PageSession = styled.section`
	padding-bottom: 40px;
	margin-bottom: 40px;
	border-bottom: 1px solid ${colors.lightGrey};

	.pg-subtitle {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		color: ${colors.secondGrey};
	}

	.products-list-wrapper {
		.react-dual-listbox {
			position: sticky;
		}
	}
`

export const ButtonActionsGroup = styled.div`
	display: flex;
	gap: 20px;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;

	width: 100%;

	.button-delete {
		border: none;
		background: none;

		margin-right: 32px;

		span {
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			font-weight: ${(props) => props.theme.font.weight.bold};
			color: ${colors.secondGrey};
		}

		&:hover {
			cursor: pointer;
		}
	}

	.challenge-cannot-be-canceled,
	.transparent-button:disabled {
		color: ${(props) => props.theme.palette.white.main};
		font-weight: ${(props) => props.theme.font.weight.medium};
		cursor: not-allowed;
		padding: 14px 40px;
		text-transform: uppercase;
		background-color: ${(props) => props.theme.palette.lightGray.main};
		border-radius: 4px;
		min-width: 184px;
		border: none;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	}

	.transparent-button {
		border: none;
		transition: all ease-in-out 300ms;

		&:hover {
			background-color: ${(props) =>
				props.theme.palette.primaryWhite.main};
		}
	}
`

export const FiltersRow = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 16px;

	&.sales-hierarchy-filters {
		& > div:nth-child(1) {
			width: 30%;
		}
		& > div:nth-child(2) {
			width: 30%;
			padding: 0 16px;
		}
		& > div:nth-child(3) {
			width: 20%;
			padding-right: 16px;
		}
		& > div:nth-child(4) {
			width: 20%;
		}
	}

	&.other-filters {
		& > div:nth-child(1) {
			width: 25%;
			padding-right: 16px;
		}
		& > div:nth-child(2) {
			width: 25%;
			padding: 0 16px;
		}
		& > div:nth-child(3) {
			width: 25%;
			padding: 0 16px;
		}
		& > div:nth-child(4) {
			width: 25%;
			padding: 0 16px;
		}
	}

	svg {
		color: ${(props) => props.theme.palette.black.main};
	}

	${({ disabled }) =>
		disabled &&
		css`
			.select-filter {
				> div > button {
					pointer-events: none;
					background-color: ${(props) => props.theme.palette.primaryWhite.main};
				}
				> div > button span {
					color: ${(props) => props.theme.palette.black.main};
				}
				}
					
			}
			.creatableSelect {
				> div > div {
					background-color: ${(props) => props.theme.palette.primaryWhite.main};
				}
			}
		`}
`
interface ICleanFiltersFieldIcon {
	isAbleToCleanFielters: boolean
}
export const WrapperActions = styled.div<ICleanFiltersFieldIcon>`
	margin-left: 16px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 16px;

	svg {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS}px;
		color: ${(props) =>
			props.isAbleToCleanFielters
				? props.theme.palette.gray.main
				: props.theme.palette.lightGray.main};

		&:hover {
			cursor: pointer;
		}
	}
`
export const ImageUploadsWrapper = styled.div`
	gap: 20px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 21px;

	> div:nth-child(2) {
		> div {
			> label:nth-child(1) {
				max-width: 200px;
				display: block;
			}
		}
	}

	> *:first-child {
		min-width: 350px;
	}
`

export const ImageUploadItem = styled.div``

export const ImageUploadTitle = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	display: block;
	margin-bottom: 24px;
`

export const InputsRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 20px;
	width: 100%;

	&.generated-points-and-period-row {
		align-items: baseline;

		& > *:nth-child(1) {
			width: 22%;
			align-self: center;
		}
		& > *:nth-child(2) {
			width: 38%;
		}
		& > *:nth-child(3) {
			width: 45%;
		}
	}
`

export const InputCheckboxContainer = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;

	input {
		height: 19px;
		width: 19px;
		margin: 0;
	}

	label {
		color: ${(props) => props.theme.palette.gray.main};
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		margin-left: 8px;

		${({ disabled }) =>
			disabled &&
			css`
				color: ${(props) => props.theme.palette.black.main};
			`}
	}
`
