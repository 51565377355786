import React from 'react'

import CheckboxActive from '../../assets/images/checkbox-fill-secondary.svg'
import CheckboxInactive from '../../assets/images/checkbox-blank-secondary.svg'

import { ConfirmationMethods, IProps } from './types'
import { Container } from './styles'

function AccountActivationSessionSecondary({
	title,
	selectedConfirmationMethod,
	handleChangeConfirmationMethod,
	labelSms,
	labelEmail,
	labelSmsRed,
	labelEmailRed
}: IProps) {
	function renderCheckboxIcon(method: ConfirmationMethods) {
		return selectedConfirmationMethod === method ? (
			<img src={CheckboxActive} alt="checkd-item" />
		) : (
			<img src={CheckboxInactive} alt="uncheckd-item" />
		)
	}

	return (
		<Container>
			<h1 className='title-main'>{title}</h1>

			<div className="radio-group">
				<label
					onClick={() => handleChangeConfirmationMethod('sendsms')}>
					{renderCheckboxIcon('sendsms')}
					<span>
						{labelSms}
						{labelSmsRed && (
							<span className='label-sms'>
								{labelSmsRed}
							</span>
						)}
					</span>
				</label>
				<label
					onClick={() => handleChangeConfirmationMethod('sendemail')}>
					{renderCheckboxIcon('sendemail')}
					<span>
						{labelEmail}
						{labelEmailRed && (
							<span className='label-sms'>
								{labelEmailRed}
							</span>
						)}
					</span>
				</label>
			</div>
		</Container>
	)
}

export default AccountActivationSessionSecondary
