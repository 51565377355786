import styled from 'styled-components'
import { IStyleProps } from './types'

export const ModalChallengePopUpContentWrapper = styled.section<IStyleProps>`
	position: relative;
`

export const ModalChallengePopUpContent = styled.section<IStyleProps>`
	width: 100%;
	min-height: 453px;
	max-height: 453px;
	max-width: 327px;
	min-width: 327px;

	cursor: pointer;

	border-radius: 4px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: ${({ appPopUpImageUrl }) => `url(${appPopUpImageUrl})`};
`

export const ButtonClosePopUp = styled.button`
	width: 32px;
	height: 32px;
	border: none;
	cursor: pointer;
	border-radius: 50%;
	background-color: transparent;
	position: absolute;
	top: 17px;
	right: 17px;
	z-index: 999;
`
