import { ScoreValueEnum } from '../../modules/RegisterProduct/types'
import { ICartItem } from '../interfaces/cart'

export function calcTotalPoints(price: number, scoreValue: string) {
	const [, proportion] = scoreValue.split(':')
	return Math.floor(price * +proportion)
}

export function totalPointsOnPurchase(items: ICartItem[]) {
	return items.reduce((totalPoints, item) => {
		if (!item.ScoreValue) return totalPoints
		totalPoints+= calcTotalPoints(item.Price, item.ScoreValue)
		return totalPoints
	}, 0)
}

export function formatScoreValueLabel(scoreValeu?: string) {
	if (!scoreValeu) return ''
	const [moneyValue, pointValue] = scoreValeu.split(':')

	return `R$ ${moneyValue} vale ${pointValue} ponto${+pointValue > 1 ? 's' : ''}`
}

export function formatEarnPointsLabel(quantity: number, price: number, scoreValeu?: string) {
	if (!scoreValeu) return ''
	const pointValue = scoreValeu.split(':')[1] 
	const totalPoints = Math.floor(quantity * price * +pointValue)

	return `Ganhe ${totalPoints} pontos`
}

export const defaultScoreValueOptions: ScoreValueEnum[] = ['1:1', '1:2', '1:3']
