import { SelectChangeEvent } from '@mui/material'

import { Creators as CustomerActions } from '../../../shared/store/ducks/customer'

export interface IHandleChangeSelectedCd {
	event: SelectChangeEvent
	setCd: (param: string) => void
	dispatch: (param: any) => void
	customer: any
}

export interface IHandleSetDefaultActiveCd {
	activeCd: string
	setActiveCd: (param: string) => void
	dispatch: (param: any) => void
	customer: any
}

export const handleChangeSelectedCd = ({
	dispatch,
	event,
	setCd,
	customer
}: IHandleChangeSelectedCd) => {
	setCd(event.target.value)
	dispatch(
		CustomerActions.updateCustomer({
			...customer,
			activeSalesOrganizationID: event.target.value
		})
	)
}

export const handleSetDefaultActiveCd = ({
	dispatch,
	activeCd,
	setActiveCd,
	customer
}: IHandleSetDefaultActiveCd) => {
	setActiveCd(activeCd)
	dispatch(
		CustomerActions.updateCustomer({
			...customer,
			activeSalesOrganizationID: activeCd
		})
	)
}
