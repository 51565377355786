import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const SecurityInformationView = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`

export const Title = styled.h1`
	color: ${colorThemeProvider.blackColor};
	font-size: 1.5rem;
	font-weight: 700;
`

export const Description = styled.p`
	color: ${colorThemeProvider.blackColor};
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const WrapperQuestions = styled.div`
	display: flex;
	width: 100%;
	gap: 1.5rem;
	margin-bottom: 2.62rem;

	.wrapper-questions-content {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;

		p {
			padding: 1.5rem 0;
		}
	}
`

export const Alert = styled.div`
	display: flex;
	padding: 1rem;
	align-items: center;
	gap: 1rem;
	border-radius: 0.3125rem;
	background: #f5edff;

	p {
		color: ${colorThemeProvider.errorColor};
		font-size: 0.875rem;
		font-weight: 400;
		line-height: 1.25rem;
		letter-spacing: -0.0125rem;
	}
`
