import React from 'react'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import OrderTable from '../../shared/components/OrderTable'
import { formatCep } from '../../shared/utils/validators'
import OrderDataCard from './OrderDataCard'
import { IViewProps } from './types'

import connectErrorPlaceholderIcon from '../../assets/images/connecterror-placeholder.svg'

function OrderHistory(props: IViewProps): JSX.Element {
	const {
		handleGoDetail,
		isLoading,
		tableData,
		lastOrder,
		handleGoProductList,
		showReloadOption,
		handleReload
	} = props

	function renderOrderHistory() {
		return (
			<>
				{showReloadOption ? (
					<div className="placeholder-wrapper">
						<div className="placeholder-container">
							<div className="gp-logo-wrapper">
								<img
									className="gp-logo"
									src={connectErrorPlaceholderIcon}
									alt="Grupo Petrópolis"
								/>
							</div>
							<h1>Servidor indisponível, no momento</h1>
							<h2>
								PARECE QUE NÃO CONSEGUIMOS ESTABELECER UMA
								CONEXÃO
							</h2>
							<button onClick={handleReload} className="button">
								ATUALIZAR
							</button>
						</div>
					</div>
				) : (
					renderHistoryTable()
				)}
			</>
		)
	}
	function renderHistoryTable() {
		return (
			<>
				{tableData.length ? (
					<div className="container">
						<h2 className="page-big-title ">
							Histórico de Pedidos
						</h2>

						<div className="row">
							<div className="col-12">
								<div className="header-wrapper">
									{lastOrder.invoiceNumber ? (
										<div className="last-info-wrapper">
											<h3>Última Nota Fiscal:</h3>
											<span>
												{lastOrder.invoiceNumber}
											</span>
										</div>
									) : null}
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12 col-md-6">
								<OrderDataCard
									data={lastOrder}
									goDetail={() =>
										lastOrder.orderDetails &&
										handleGoDetail(lastOrder.orderDetails)
									}
									isProductDetail={false}
								/>
							</div>

							<div className="col-12 col-md-6">
								<div className="page-title">
									<h2>Dados da Entrega</h2>
								</div>

								<div className="box destinary-card-box">
									<div className="info-wrapper">
										<div className="item space">
											<p className="param">
												Entrega prevista em:
											</p>
											<p className="value">
												{lastOrder.deliveryDate}
											</p>
										</div>

										<div className="item space">
											<p className="param">
												Destinatário:
											</p>
											<p className="value">
												{lastOrder.destinatary}
											</p>
										</div>

										<div className="item">
											<p className="param">Endereço:</p>
											<p className="value">
												{lastOrder.address}
											</p>
										</div>
										<div className="destinatary-spacer">
											<div className="item" id="cep">
												<p className="param">CEP:</p>
												<p className="value">
													{formatCep(lastOrder.CEP)}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-12">
								<div className="page-title">
									<h2>Pedidos Anteriores</h2>
								</div>

								<OrderTable tableData={tableData} />
							</div>
						</div>
					</div>
				) : (
					<>
						<div className="placeholder-wrapper">
							<div className="placeholder-container">
								<h1>Você ainda não fez nenhuma compra!</h1>

								<button
									onClick={handleGoProductList}
									className="button">
									Lista de Produtos
								</button>
							</div>
						</div>
					</>
				)}
			</>
		)
	}

	return (
		<>
			<Header />
			<div className="order-history">
				{isLoading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					renderOrderHistory()
				)}
			</div>
		</>
	)
}

export default OrderHistory
