import React, { useState } from 'react'
import { TypeButtonEnum } from '../../../../../../components/buttons/button/button-default.types'
import ButtonDefault from '../../../../../../components/buttons/button/button-default'
import RatingButtons from './components/ratting-buttons/rating-buttons.component'
import { HelpModalProps } from './review-sidebar.types'

import * as S from './review-sidebar.styles'
import {
	ISaveReviewBody,
	saveReview
} from '../../../../services/review.service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../../shared/utils/toaster'

function ReviewSidebar({
	handleOpenHelpModal,
	documentNumber,
	setIsLoading,
	setIsSucessReview
}: HelpModalProps) {
	const [rating, setRating] = useState<number | undefined>(undefined)
	const [commentReview, setCommentReview] = useState<string>('')

	async function handleSaveReview({
		comment,
		document,
		rate,
		rateSource,
		skipRate
	}: ISaveReviewBody) {
		setIsLoading(true)
		try {
			const response = await saveReview({
				comment,
				document,
				rate,
				rateSource,
				skipRate
			})
			if (response.status !== 200 && response.status !== 201) {
				cogoToast.error(
					'Algo deu errado, tente novamente mais tarde',
					cogoDefaultOptions
				)
				return
			} else {
				setIsSucessReview(true)
			}
		} catch (error) {
			cogoToast.error(
				'Algo deu errado...Tente novamente mais tarde',
				cogoDefaultOptions
			)
		}
		setIsLoading(false)
	}

	return (
		<>
			<S.WrapperReviewSidebar>
				<h2>Avalie sua experiencia</h2>
				<S.WrapperSubtitle>
					<S.SubtitleSidebar>
						Como você avalia sua experiência realizando negócios com
						o <strong>Grupo Petrópolis?</strong>
					</S.SubtitleSidebar>
					<S.ReviewText>Escolha de 0 a 10 para avaliar</S.ReviewText>
				</S.WrapperSubtitle>
				<RatingButtons rating={rating} setRating={setRating} />
				<S.WrapperCommentReview>
					<p>Deixe o seu comentário (opcional)</p>
					<textarea
						placeholder="Digite um comentário sobre a sua experiência"
						onChange={(text) => setCommentReview(text.target.value)}
						value={commentReview}
						id="comment-field"
					/>
				</S.WrapperCommentReview>
				<S.WrapperButtons>
					<ButtonDefault
						type={TypeButtonEnum.OUTLINED}
						buttonText="Saiba mais sobre o Grupo Petrópolis"
						onClick={() => {
							handleOpenHelpModal(true)
						}}
						idElement="button-help"
						fontSize="14px"
						width="312px"
						height="48px"
					/>
					<ButtonDefault
						type={
							rating === undefined
								? TypeButtonEnum.DISABLED
								: TypeButtonEnum.PRIMARY
						}
						buttonText="Continuar"
						onClick={() => {
							rating !== undefined &&
								handleSaveReview({
									document: documentNumber,
									rate: rating,
									rateSource: 'web',
									skipRate: false,
									comment: commentReview
								})
						}}
						idElement="button-send-review"
						fontSize="16px"
						width="327px"
						height="48px"
					/>
				</S.WrapperButtons>
			</S.WrapperReviewSidebar>
		</>
	)
}

export default ReviewSidebar
