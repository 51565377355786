import React from 'react'

import { IEditPushModal } from './edit-push-transactional-modal.types'
import { EditPushTransactionalModalStyles } from './edit-push-transactional-modal.styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import TextArea from '../../../../../components/text-area/text-area.component'
import InputField from '../../../../../components/input-field/input-field.component'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'

const EditPushTransactionalModal = ({
	isOpen,
	handleToggleModal,
	pushTitle,
	messagemAplied,
	handleChangeTitle,
	handleChangeMessage,
	handleClickEditModal,
	isLoading,
	disabledButton,
	description,
	handleChangeDescription
}: IEditPushModal) => {
	return (
		<LayoutModal
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			height={'759px'}
			width={'648px'}
			modalTitle={'Editar Push Transacional'}
			buttonText={'Confirmar alteração'}
			handleClick={handleClickEditModal}
			isLoading={isLoading}
			disabledButton={disabledButton}>
			<EditPushTransactionalModalStyles>
				<InputField
					text={pushTitle}
					handleChange={handleChangeTitle}
					label="Título"
				/>
				<TextArea
					text={messagemAplied}
					handleChange={handleChangeMessage}
					label="Mensagem"
					limitCaracters={120}
				/>
				<TextArea
					text={description}
					handleChange={handleChangeDescription}
					label="Descrição"
					limitCaracters={120}
				/>
				<div className="info-modal-edit">
					<InfoOutlinedIcon />
					<p>
						É possível realizar a edição do texto na área demarcada
						e, posteriormente, confirmar as alterações realizadas.
					</p>
				</div>
			</EditPushTransactionalModalStyles>
		</LayoutModal>
	)
}

export default EditPushTransactionalModal
