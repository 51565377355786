import cogoToast from 'cogo-toast'

import { CogoPosition } from '../../../shared/interfaces/cogoToast'

import { Creators as CartActions } from '../../../shared/store/ducks/cart'
import { SelectedProduct } from '../components/modal-combo-flex/modal-combo-flex.component'

export interface IAddComboFlexToCart {
	comboId: string
	totalQuantity: number
	options: CogoPosition
	storeQuantity: number
	description: string
	ComboImageUrl: string
	quantityComboFlex: number
	dispatch: (param: any) => any
	handleCloseModal: (param: boolean) => void
	totalPriceCombo: number
	limitPdv: number
	openBottomBar: () => void
	products: SelectedProduct[]
	maxQuantityTradePromotion: any
	promotionShortName: string
	customerId: string
}

enum COMBO_TYPE {
	COMBO_FLEX = 1,
	ACTION_PRICE = 2,
  }

export function addComboFlexToCart({
	totalQuantity,
	options,
	description,
	ComboImageUrl,
	quantityComboFlex,
	dispatch,
	handleCloseModal,
	totalPriceCombo,
	limitPdv,
	openBottomBar,
	comboId,
	products,
	maxQuantityTradePromotion,
	promotionShortName,
	customerId
}: IAddComboFlexToCart) {
	const formatedProducts = products.filter(
		(currentProduct) =>
			currentProduct.quantity !== 0 && currentProduct.dataProduct
	)

	const filteredPrinceQuantity = formatedProducts.map((e: any) => {
		if (e.dataProduct.groupType === 'BONUS_GROUP') {
			return {
				Price: 0,
				Quantity: e.quantity
			}
		} else {
			return {
				Price: Number(e.dataProduct.price),
				Quantity: e.quantity
			}
		}
	})

	const filteredProducts = formatedProducts.map((e: any) => {
		if (e.dataProduct.groupType === 'BONUS_GROUP') {
			return {
				oldPrice: Number(e.dataProduct.price),
				price: 0,
				productID: e.productID,
				quantity: e.quantity,
				tradeType: e.dataProduct.tradeType,
				unitMeasureSale: e.dataProduct.unitMeasureSale,
				promotionType: COMBO_TYPE.COMBO_FLEX,
				numeratorConversion: e.dataProduct.numeratorConversion
			}
		} else {
			return {
				oldPrice: Number(e.dataProduct.price),
				price: Number(e.dataProduct.price),
				productID: e.productID,
				quantity: e.quantity,
				tradeType: e.dataProduct.tradeType,
				unitMeasureSale: e.dataProduct.unitMeasureSale,
				promotionType: COMBO_TYPE.COMBO_FLEX,
				numeratorConversion: e.dataProduct.numeratorConversion
			}
		}
	})

	if (totalQuantity > 9999) {
		cogoToast.error('O máximo de unidades por item é 9999', options)

		return
	}

	dispatch(
		CartActions.addItem(
			{
				MaterialID: comboId,
				UnitMeasureSale: 'Combo',
				Price: totalPriceCombo,
				OrderQuantity: quantityComboFlex,
				Size: 'Combo',
				PriceByQuantity: filteredPrinceQuantity,
				ImageUrl: ComboImageUrl,
				StockPosition: limitPdv,
				Description: description,
				PackType: '',
				MaxQuantityTradePromotion: maxQuantityTradePromotion,
				promotionID: comboId,
				promotionType: 1,
				promotionShortName: promotionShortName,
				operationTypeDescription: 'Combo',
				products: filteredProducts,
				customerId: customerId
			},
			'ADD'
		)
	)
	openBottomBar()
	handleCloseModal(false)
}
