import React from 'react'

import Header from '../../../components/Header'
import InputField from '../../../components/InputField'
import Loading from '../../../components/Loading'
import ConfirmationModal from './ConfirmationModal'
import { IViewProps } from './types'
import InputFilter from '../../../components/InputFilter'
import ArrowLeftRedIcon from '../../../assets/images/arrow-left-red.svg'
import Table from '../../../components/Table'
import * as S from './styles'
import TableFilter from './components/TableFilter/index.component'
import { formatData } from '../../../utils/dates'

function PendingApproval(props: IViewProps): JSX.Element {
	const {
		targetUser,
		viewState,
		handleBack,
		isModalOpen,
		closeModal,
		handleModal,
		isApprove,
		updateStatus,
		isLoading,
		columns,
		tableData,
		handleFilterOnChange,
		onTableFilterChange,
		handleChangeSentSacState,
		isUpdatingTargetUser
	} = props

	const DEFAULT_PROPS = {
		className: 'input',
		hasError: false,
		disabled: true
	}

	const { DetailSignUpStatusConfirmation, statusSACDescription, sentToSac } =
		targetUser

	const APPROVED = statusSACDescription === 'APROVADO'
	const REFUSED = statusSACDescription === 'RECUSADO'
	const INFORMATIVE = statusSACDescription === 'INFORMATIVO'

	const HIDE_BUTTONS = DetailSignUpStatusConfirmation === 'NAO CADASTRADO'

	const reason = DetailSignUpStatusConfirmation

	return (
		<>
			<Header />
			<div className="pending-approval">
				{viewState === 'LIST' ? (
					<div className="container">
						<h1 className="title">Clientes em Análise</h1>

						<S.Container>
							<div className="search-input-wrapper-pending-approval">
								<InputFilter onChange={handleFilterOnChange} />
							</div>
							<TableFilter
								onFilter={async (
									selectedData,
									statusSACDescription,
									SignUpStatusConfirmation,
									clearFilter = false
								) => {
									onTableFilterChange({
										initialDate: formatData(
											selectedData?.from
										),
										endDate: formatData(selectedData?.to),
										statusSACDescription,
										SignUpStatusConfirmation,
										clearFilter
									})
								}}
							/>
						</S.Container>

						<div>
							{isLoading ? (
								<div className="loading-wrapper">
									<Loading />
								</div>
							) : (
								<Table data={tableData} columns={columns} />
							)}
						</div>
					</div>
				) : (
					<div className="container">
						<div className="back-button-wrapper">
							<button
								onClick={handleBack}
								className="back-arrow-button">
								<img
									className="back-arrow"
									color={'#ffffff'}
									src={ArrowLeftRedIcon}
									alt="Voltar"
								/>

								<span className="text">Voltar</span>
							</button>
						</div>

						<div className="pending-user-header">
							<h1 className="title-user">
								Informações do Solicitante
							</h1>

							{APPROVED ? (
								<div className="badge-wrapper">
									<span
										className={`badge-status big approved`}>
										cadastro aprovado
									</span>
								</div>
							) : REFUSED ? (
								<div className="badge-wrapper">
									<span
										className={`badge-status big refused`}>
										cadastro recusado
									</span>
								</div>
							) : INFORMATIVE ? (
								<div className="buttons">
									{isUpdatingTargetUser ? (
										<S.LoadingWrapper>
											<Loading />
										</S.LoadingWrapper>
									) : (
										<button
											className="button-red"
											onClick={() =>
												handleChangeSentSacState()
											}>
											{sentToSac
												? 'Marcar como não enviado'
												: 'Marcar como enviado'}
										</button>
									)}
								</div>
							) : HIDE_BUTTONS ? null : (
								<div className="buttons">
									<button
										disabled={false}
										onClick={() => handleModal(false)}
										className="button-white">
										Recusar
									</button>
									<button
										disabled={false}
										onClick={() => handleModal(true)}
										className="button-red">
										Aprovar Cadastro
									</button>
								</div>
							)}
						</div>

						<div className="form">
							<div className="row">
								<div className="col-12">
									<div
										className={`reason ${
											reason ? '' : 'hide'
										}`}>
										Motivo
										<div className="sac-status">
											<p className="text">{reason}</p>
											<div className="badge-wrapper">
												{sentToSac ? (
													<span
														className={`badge-status big sent-to-sac`}>
														ENVIADO PARA O SAC
													</span>
												) : (
													<span
														className={`badge-status big not-sent-to-sac`}>
														NÃO ENVIADO PARA O SAC
													</span>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6">
									<h2 className="form-title">
										Dados Pessoais
									</h2>
									<InputField
										{...DEFAULT_PROPS}
										label="Nome"
										id={'name'}
										value={targetUser.Name}
									/>

									<InputField
										{...DEFAULT_PROPS}
										label="E-mail"
										id={'email'}
										value={targetUser.Email}
									/>

									<InputField
										{...DEFAULT_PROPS}
										label="CPF"
										id={'cpf'}
										value={targetUser.PersonalFiscalID}
									/>

									<InputField
										{...DEFAULT_PROPS}
										label="RG"
										id={'rg'}
										value={
											targetUser.IdentificationRegistryID
												? targetUser.IdentificationRegistryID
												: ''
										}
									/>

									<div className="row">
										<div className="double-input-col-1">
											<InputField
												{...DEFAULT_PROPS}
												label="Telefone Fixo"
												id={'telefone'}
												value={targetUser.PhoneNumber}
											/>
										</div>
										<div className="double-input-col-2">
											<InputField
												{...DEFAULT_PROPS}
												label="Celular"
												id={'celular'}
												value={
													targetUser.CellphoneNumber
												}
											/>
										</div>
									</div>
								</div>

								<div className="col-12 col-md-6">
									<h2 className="form-title">
										Dados do estabelecimento
									</h2>

									<InputField
										{...DEFAULT_PROPS}
										label="Nome Fantasia do Estabelecimento"
										id={'bussiness_name'}
										value={targetUser.NickName || ''}
									/>

									<InputField
										{...DEFAULT_PROPS}
										label="CNPJ"
										id={'cnpj'}
										value={targetUser.CompanyFiscalID}
									/>

									<div className="row">
										<div className="double-input-col-1">
											<InputField
												{...DEFAULT_PROPS}
												label="CEP"
												id={'cep'}
												value={
													targetUser.PostalCode || ''
												}
											/>
										</div>
										<div className="double-input-col-2">
											<InputField
												{...DEFAULT_PROPS}
												label="Número"
												id={'number'}
												value={
													targetUser.HouseNumber || ''
												}
											/>
										</div>
									</div>

									<InputField
										{...DEFAULT_PROPS}
										label="Rua"
										id={'street'}
										value={targetUser.Street || ''}
									/>

									<div className="row">
										<div className="double-input-col-1">
											<InputField
												{...DEFAULT_PROPS}
												label="Bairro"
												id={'neighbor'}
												value={
													targetUser.Neighborhood ||
													''
												}
											/>
										</div>
										<div className="double-input-col-2">
											<InputField
												{...DEFAULT_PROPS}
												label="Complemento"
												id={'more'}
												value={
													targetUser.AdressComplement ||
													''
												}
											/>
										</div>
									</div>

									<div className="row">
										<div className="double-input-col-1">
											<InputField
												{...DEFAULT_PROPS}
												label="Cidade"
												id={'city'}
												value={targetUser.City || ''}
											/>
										</div>
										<div className="double-input-col-2">
											<InputField
												{...DEFAULT_PROPS}
												label="Estado"
												id={'state'}
												value={targetUser.Region || ''}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<ConfirmationModal
				isActive={isModalOpen}
				closeModal={closeModal}
				isApprove={isApprove}
				updateStatus={updateStatus}
				isLoading={isLoading}
			/>
		</>
	)
}

export default PendingApproval
