import styled from 'styled-components'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { theme } from '../../../theme/themeProvider'

interface IconProps {
	isDisabled: boolean | undefined
}

export const WrapperInputs = styled.div`
	display: flex;
	gap: 30px;

	margin-top: 45px;
	margin-bottom: 65px;
`

export const EditIcon = styled(EditNoteOutlinedIcon)<IconProps>`
	color: ${(props) =>
		!props.isDisabled ? theme.palette.primary.main : theme.palette.lightGray.main};
	&:hover {
		cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'not-allowed')};
	}
`
export const DeleteIcon = styled(DeleteOutlineIcon)<IconProps>`
	color: ${(props) =>
		!props.isDisabled ? theme.palette.primary.main : theme.palette.lightGray.main};
	&:hover {
		cursor: ${(props) => (!props.isDisabled ? 'pointer' : 'not-allowed')};
	}
`
