import React from 'react'

import RadioFill from '../../../assets/images/radio-fill-red.svg'
import RadioBlank from '../../../assets/images/radio-blank-red.svg'
import RadioDisabled from '../../../assets/images/radio-fill-disabled.svg'

import * as S from './styles'

interface IProps {
	disabled?: boolean
	onChangeRule: (value: string) => void
	selectedRule: string
	onChangeCheckbox: () => void
	customerMayParticipateMoreThanOnce: boolean
}

interface IRule {
	value: string
	label: string
}

export function OrderRules({
	disabled,
	onChangeRule,
	selectedRule,
	onChangeCheckbox,
	customerMayParticipateMoreThanOnce
}: IProps) {
	function renderRadioIconProps(isSelected: boolean) {
		return {
			src:
				isSelected && !disabled
					? RadioFill
					: isSelected && disabled
					? RadioDisabled
					: RadioBlank,
			className: isSelected ? 'active' : 'inactive'
		}
	}
	const rules: IRule[] = [
		{
			label: 'O cliente deve comprar o Mix de Produtos em sua totalidade. (Todos os produtos de uma vez)',
			value: 'T'
		},
		{
			label: 'O cliente pode realizar compras progressivas durante a vigência.',
			value: 'P'
		}
	]
	return (
		<S.OrderRulesWrapper>
			{rules.map((rule, index) => (
				<S.InputRadioContainer
					key={`${rule.value}-${index}`}
					id={`${rule.value}-${index}`}
					disabled={disabled}
					className="type-radio"
					onClick={() => onChangeRule(rule.value)}>
					<img
						{...renderRadioIconProps(rule.value === selectedRule)}
						alt=""
					/>
					<label>{rule.label}</label>
				</S.InputRadioContainer>
			))}
			<S.InputRadioContainer
				disabled={disabled}
				onClick={onChangeCheckbox}>
				<input
					disabled={disabled}
					type="checkbox"
					checked={customerMayParticipateMoreThanOnce}
				/>
				<label>Cliente poderá participar mais de uma vez.</label>
			</S.InputRadioContainer>
		</S.OrderRulesWrapper>
	)
}
