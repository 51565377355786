import styled from 'styled-components'

export const WrapperQuantityButtons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	z-index: 999;

	p {
		color: ${(props) => props.theme.palette.black.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
	}
`
