import React from 'react'

import ArrowLeftIcon from '../../assets/images/arrow-left.svg'
import GPLogo from '../../assets/images/gp.png'

import {
	InputCode,
	InputPassword,
	InputPasswordConfirmation,
	Loading
} from '../../shared/components'

import { IViewProps } from './types'
import styles from './styles.module.scss'

function ChangePassword(props: IViewProps): JSX.Element {
	const {
		handleChangePassword,
		loading,
		setPassword,
		setPasswordIsValid,
		setCode,
		setCodeIsValid,
		isDisabled,
		handleBack,
		timer,
		canResendCode,
		confirmationMethod,
		handleResendCode,
		password,
		destination
	} = props

	return (
		<div className="new-account-page">
			<section className="gradient-container" />

			<div className="new-account-wrapper">
				<div className="header">
					<button className="back-arrow-wrapper" onClick={handleBack}>
						<img
							className="back-arrow"
							src={ArrowLeftIcon}
							alt="Voltar"
						/>
					</button>

					<h1 className="header-text">Cadastrar Nova Senha</h1>
				</div>

				<div className="form">
					<div className="activation-code">
						<p className="activation-code-subtitle">
							Por favor, insira o código que enviamos para{' '}
							{destination}
						</p>

						<InputCode
							returnValue={setCode}
							valueIsValid={setCodeIsValid}
						/>

						{confirmationMethod === 'sendsms' ? (
							<button
								onClick={handleResendCode}
								disabled={!canResendCode}
								className="button"
								style={{ marginBottom: '64px' }}>
								{canResendCode
									? 'REENVIAR CÓDIGO'
									: `REENVIAR CÓDIGO EM 00:${timer
											.toString()
											.padStart(2, '0')}`}
							</button>
						) : null}

						<p className="activation-code-subtitle">
							Agora, cadastre uma nova senha:
						</p>

						<div className={styles.inputsRowWrapper}>
							<InputPassword
								returnValue={setPassword}
								valueIsValid={setPasswordIsValid}
							/>
							<InputPasswordConfirmation
								password={password}
								valueIsValid={setPasswordIsValid}
							/>
						</div>

						{loading ? (
							<div className="loading-wrapper">
								<Loading color="white" />
							</div>
						) : (
							<button
								disabled={isDisabled()}
								onClick={handleChangePassword}
								className="button"
								style={{ marginTop: '48px' }}>
								Mudar Senha
							</button>
						)}
					</div>
				</div>
			</div>

			<img
				className="bom-parceiro-logo"
				src={GPLogo}
				alt="Grupo Petrópolis"
			/>
		</div>
	)
}

export default ChangePassword
