import React, { Fragment } from 'react'
import SecondaryButton from '../../../components/SecondaryButton'
import { OrderSource } from '../../../shared/interfaces/orderDetail'
import { IOrderDataCardProps } from './types'

export default function OrderDataCard(props: IOrderDataCardProps) {
	const { data, isProductDetail, goDetail } = props

	const {
		orderStatus,
		orderDate,
		priceTotal,
		paymentMethod,
		orderID,
		orderNumber,
		orderSource
	} = data

	return (
		<Fragment>
			<div className="page-title">
				<h2>Dados do Pedido</h2>
			</div>

			<div className="order-number-badget">
				<span>{orderNumber}</span>
			</div>
			<div className="box">
				<div className="info-wrapper">
					<div className="item space">
						<p className="param">Situação</p>
						<p className="value">{orderStatus}</p>
					</div>

					<div className="item space">
						<p className="param">Data</p>
						<p className="value">{orderDate}</p>
					</div>

					<div className="item space">
						<p className="param">Valor Total</p>
						<p className="value">{priceTotal}</p>
					</div>

					<div className="item space">
						<p className="param">Pagamento</p>
						<p className="value">{paymentMethod}</p>
					</div>

					<div className="item space">
						<p className="param">Canal de Compras</p>
						<p className="value">
							{orderSource === OrderSource.VB2A
								? 'APP'
								: 'PORTAL'}
						</p>
					</div>
				</div>

				{!isProductDetail && (
					<SecondaryButton
						onClick={() =>
							goDetail ? goDetail(orderID) : () => {}
						}
						title="Ver Detalhe"
					/>
				)}
			</div>
		</Fragment>
	)
}
