import { find, propEq } from 'ramda'
import React from 'react'

import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import {
	characteristicTypeEnum,
	ICharacteristics
} from '../../../shared/interfaces/material'
import { validateImageExtension } from '../../../shared/utils/image'
import { parseItemPrice } from '../../../shared/utils/money'
import { isPropEqual } from '../../../shared/utils/object'
import { formatScoreValueLabel } from '../../../shared/utils/points'
import { getLowerPrice, returnHighestPrice } from '../../../shared/utils/price'
import ProductInfo from '../ProductInfo'

import RetornavelIcon from '../../../assets/images/retornavel.svg'

import { CartQuantityBadge } from '../../../shared/components'
import * as S from './styles'
import { IProps } from './types'

export function parseCharType(type: string): string {
	return characteristicTypeEnum[type]
}

function CardItem({
	productItem,
	modal = false,
	openLightBox,
	showPrice = false,
	isProductForRedemption = false
}: IProps) {
	const { Characteristics } = productItem

	const { carts } = useTypedSelector(['carts'])

	const items = carts.map(cart => cart.items).flat()

	const storeItem = items.find(isPropEqual('MaterialID')(productItem))

	const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0

	const packageType = Characteristics.find((x) => x.Type === 'PackageType')
	const category = Characteristics.find(propEq('Type', 'Category'))
	const brand = Characteristics.find(propEq('Type', 'Brand'))

	const size = find(propEq('Type', 'Package'))(
		Characteristics
	) as ICharacteristics

	const isReturnable = packageType?.Description === 'Retornável'

	const arrayOfPrice = productItem.PriceByQuantity.map((item) =>
		Number(item.Price)
	) as number[]

	const hasPriceAction =
		productItem.priceAction && productItem.priceAction.length > 0

	const productEarnsPoints = !!productItem.ScoreValue

	return (
		<div
			className={`card-item ${
				!!storeQuantity && !modal ? 'this-product-is-in-cart' : ''
			}`}>
			{isReturnable && !modal ? (
				<div className="returnable-badge">
					<img src={RetornavelIcon} alt="" />
				</div>
			) : null}
			{storeQuantity && !modal ? (
				<div style={{ alignSelf: 'flex-end' }}>
					<CartQuantityBadge storeQuantity={storeQuantity} />
				</div>
			) : null}

			{modal ? (
				<div className="collage">
					<img
						onClick={() => openLightBox && openLightBox(true)}
						src={validateImageExtension(productItem.ImageUrl)}
						alt=""
					/>
					{!isProductForRedemption && (
						<img
							onClick={() =>
								openLightBox &&
								productItem.PackageImageUrl &&
								openLightBox(true)
							}
							src={
								productItem.PackageImageUrl
									? validateImageExtension(
											productItem.PackageImageUrl
									  )
									: validateImageExtension('')
							}
							alt=""
						/>
					)}
				</div>
			) : (
				<>
					<img
						className="product-image"
						src={validateImageExtension(productItem.ImageUrl)}
						alt="Foto do Produto"
					/>
				</>
			)}

			{!modal && productEarnsPoints && Number(productItem.StockPosition) > 0 && (
				<S.ScoreValueLabel>
					{formatScoreValueLabel(productItem.ScoreValue)}
				</S.ScoreValueLabel>
			)}
			<h3 className="product-name">{productItem.Description}</h3>
			{showPrice ? (
				<S.PriceInfosWrapper>
					<S.PriceToPayInfo>
						<S.NormalPriceLabel>Por até</S.NormalPriceLabel>
						<S.PriceActionWrapper>
							{hasPriceAction && (
								<S.OldPriceValue>
									{parseItemPrice(
										returnHighestPrice(
											arrayOfPrice,
											productItem
										)
									)}
								</S.OldPriceValue>
							)}
							<S.NormalPriceValue
								className={hasPriceAction ? 'new-price' : ''}>
								{parseItemPrice(
									hasPriceAction && productItem.priceAction
										? getLowerPrice(
												productItem.priceAction?.map(
													(priceAction) =>
														Number(
															priceAction.maxAmount
														)
												)
										  )
										: getLowerPrice(arrayOfPrice)
								)}
							</S.NormalPriceValue>
						</S.PriceActionWrapper>
					</S.PriceToPayInfo>

					<S.UnitPriceInfosWrapper>
						<S.UnitPriceLabel>
							{'PREÇO / ' + productItem.typeOfContainer}
						</S.UnitPriceLabel>
						<S.UnitPriceValue
							className={hasPriceAction ? 'new-price' : ''}>
							{parseItemPrice(
								Number(productItem.PricePerUnit).toFixed(2)
							)}
						</S.UnitPriceValue>
					</S.UnitPriceInfosWrapper>
				</S.PriceInfosWrapper>
			) : null}

			{!modal ? (
				<div className="card-info">
					<div className="type">
						<p className="title">Categoria</p>
						<p className="description">{category?.Description}</p>
					</div>

					<div className="type">
						<p className="title">Marca</p>
						<p className="description">{brand?.Description}</p>
					</div>

					<div className="type">
						<p className="title">Tamanho</p>
						<p className="description">{size?.Description}</p>
					</div>
				</div>
			) : (
				<ProductInfo
					productItem={productItem}
					productAmount={50}
					type={'DESCRIPTION'}
				/>
			)}
		</div>
	)
}

export default CardItem
