import React from 'react'
import Select from 'react-select'

import { customStyles } from './styles'
import { ISelectPrimaryProps, ReactSelectOnChangeType } from './types'

function SelectPrimary({
	isLoading = false,
	isClearable = false,
	placeholder,
	options,
	onChange,
	value,
	className,
	classNamePrefix,
	disabled = false,
	id
}: ISelectPrimaryProps) {
	const selectValue = value ? { value, label: value } : null

	return (
		<Select
			isLoading={Boolean(isLoading)}
			isClearable={Boolean(isClearable)}
			placeholder={String(placeholder)}
			options={options}
			styles={customStyles}
			onChange={onChange as ReactSelectOnChangeType}
			value={selectValue}
			className={className}
			classNamePrefix={classNamePrefix}
			isDisabled={disabled}
			id={id}
		/>
	)
}

export default SelectPrimary
