import styled from 'styled-components'

interface ButtonProps {
	isError?: boolean
}

export const TextFieldStyles = styled.div`
	width: 100%;

	svg {
		position: absolute;
		right: 40px;
		margin-top: 23px;

		color: ${(props) => props.theme.palette.primary.main};

		&:hover {
			cursor: pointer;
		}
	}
`

export const FieldStyles = styled.input<ButtonProps>`
	width: 100%;
	height: 60px;

	border-radius: 5px;
	margin-top: 5px;
	padding: 18px 16px;

	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;

	color: ${(props) => props.theme.palette.black.main};

	border: 1px solid
		${(props) =>
			props.isError
				? props.theme.palette.error.main
				: props.theme.palette.lightGray.main};

	&::placeholder {
		font-weight: ${(props) => props.theme.font.weight.regular};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		color: ${(props) => props.theme.palette.lightGray.main};
	}
`

export const LabelStyles = styled.label<ButtonProps>`
	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;

	color: ${(props) =>
		props.isError
			? props.theme.palette.error.main
			: props.theme.palette.gray.main};
	margin-left: 20px;
`

export const LabelError = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	margin-left: 20px;
	color: ${(props) => props.theme.palette.error.main};
`
