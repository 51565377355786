import React from 'react'

import { IProps } from './types'
import InputField from '../InputField'

const InputFilter: React.FC<IProps> = ({ className, ...rest }) => {
	return (
		<div className={`input-filter  ${className}`}>
			<InputField
				secondary={true}
				id="search"
				label="Busca na tabela"
				{...rest}
			/>
		</div>
	)
}

export default InputFilter
