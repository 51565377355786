import { prop } from 'ramda'
import React, { Fragment, useEffect, useState } from 'react'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { IStore } from '../../shared/interfaces/store'
import { add } from '../../shared/utils/math'
import { parseItemPrice } from '../../shared/utils/money'
import { pipe } from '../../shared/utils/operators'
import { calcTotalPoints } from '../../shared/utils/points'
import { headerAssetPaths } from '../../components/Header/data'

import { useTheme } from 'styled-components'

function CartSummaryBadge(props: any) {
	const theme = useTheme()

	const { reverse = false, onPress } = props
	const [cartProducts, setCartProducts] = useState<ICartItem[]>([])

	const storeKeys: (keyof IStore)[] = [
		'carts',
		'customer',
		'cartRedemptionItems',
		'cartChallengeItems'
	]

	const [totalPointsRedeemed, setTotalPointsRedeemed] = useState(0)

	const { carts, customer, cartRedemptionItems, cartChallengeItems } =
		useTypedSelector(storeKeys)

	const items = carts.map((cart) => cart.items).flat()
	const itemsChallenge = cartChallengeItems
		.map((cartChallengeItem) => cartChallengeItem.addedProducts)
		.flat()

	const [{ Type }] = [customer]

	const { length } = cartProducts

	const fmtPrice = pipe(prop('Price'), Number)

	const itemsTotalPrice = items.map(fmtPrice).reduce(add, 0)
	const itemsChallengeTotalPrice = itemsChallenge.map(fmtPrice).reduce(add, 0)

	const totalPrice = itemsTotalPrice + itemsChallengeTotalPrice

	const totalPriceLabel = parseItemPrice(totalPrice)

	const assetKey = reverse
		? 'reverse'
		: Type !== 'CUSTOMER'
		? 'sac'
		: 'default'

	const labelClass = `label ${!!length ? 'active' : ''} ${assetKey}`

	const totalClass = `total-amount ${assetKey}`

	const wrapperClass = `cart-wrapper ${onPress ? 'pointer' : ''}`

	const { cartIcon } = headerAssetPaths[assetKey]

	const totalPoints = items.reduce(
		(acc, item) =>
			item.ScoreValue
				? acc + calcTotalPoints(item.Price, item.ScoreValue)
				: acc,
		0
	)

	useEffect(
		() =>
			setCartProducts([
				...items,
				...cartRedemptionItems,
				...itemsChallenge
			]),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[carts, cartRedemptionItems]
	)

	useEffect(() => {
		setTotalPointsRedeemed(
			cartRedemptionItems.reduce(
				(acc, item) => (acc += Number(item.pointsRedeemed)),
				0
			)
		)
	}, [cartRedemptionItems])

	return (
		<button onClick={onPress} className={wrapperClass}>
			<div className="top-row">
				<div className={labelClass}>
					<p>{`${length} Produto${length !== 1 ? 's' : ''}`}</p>
				</div>

				<img
					className="cart-icon"
					src={cartIcon}
					alt="Icone do Carrinho"
				/>
			</div>
			{props.itemType === ICartItemTypeEnum.REDEMPTION ? (
				<Fragment>
					<p className={totalClass}>
						TOTAL DE PONTOS: {totalPointsRedeemed}
					</p>
					{/* @ts-ignore */}
					<p
						style={{ color: theme.palette.primary.main }}
						className="total-points">
						-{totalPointsRedeemed}
					</p>
				</Fragment>
			) : (
				<Fragment>
					<p className={totalClass}>TOTAL: {totalPriceLabel}</p>
					<p className="total-points">+{totalPoints} PONTOS</p>
				</Fragment>
			)}
		</button>
	)
}

export default CartSummaryBadge
