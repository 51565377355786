import styled, { css } from 'styled-components'
import { colors } from '../../../shared/styles/theme'

export const PriceInfoContainer = styled.div`
	width: 100%;

	.point-value {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.unit-measure-sale {
			color: ${(props) => props.theme.palette.black.main};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			text-transform: uppercase;
		}
		.points {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.gray.main};
		}
	}
`

export const WrapperQuantity = styled.div`
	margin-top: 12px;
`

export const PriceInfoItem = styled.div<{
	highlight?: boolean
}>`
	width: 100%;
	padding: 23px 20px;
	border-radius: 4px;
	background: ${colors.fifthGray};
	/* background: ${colors.white}; // TODO MUDAR QUANDO AJUSTAR A LIB */
	box-shadow: 2px 2px 2px #00000014;
	margin-bottom: 8px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	${({ highlight }) =>
		highlight &&
		css`
			border: 1px solid ${(props) => props.theme.palette.primary.main};
		`};

	.price-title {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${colors.sixthGray};
		text-transform: uppercase;
		font-weight: ${(props) => props.theme.font.weight.bold};

		margin-bottom: 8px;
	}

	.price-value {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colors.secondGrey};
	}

	.price-desc {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${colors.secondGrey};
		margin-top: 8px;
	}
`

export const EarnsPointsLabel = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${colors.green};
	text-transform: uppercase;
`

export const OfferButton = styled.button`
	width: 100%;
	background: ${colors.red};
	padding: 12px 0;
	border-radius: 4px;
	border: none;
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	color: ${colors.white};
	text-transform: uppercase;
	margin: 7px 0;
`

export const PriceActionInfoContainer = styled.div<{
	thisIsARescueProduct?: boolean
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px;
	background: ${colors.fifthGray};
	/* background: ${colors.white}; // TODO MUDAR QUANDO AJUSTAR A LIB */
	border-radius: 4px;
	justify-content: space-between;
	box-shadow: 0px 2px 4px #00000014;

	${({ thisIsARescueProduct }) =>
		thisIsARescueProduct &&
		css`
			background-color: ${(props) => props.theme.palette.white.main};
			padding: 34px 24px 34px 14px;
		`}

	.old-price {
		color: ${colors.lightRed};
		font-size: 0.75rem;
		margin: 5px 0;
		text-decoration: line-through;
	}

	.unit-type {
		color: ${colors.sixthGray};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	}

	.price {
		font-size: ${(props) => props.theme.font.size.TEXT_XL.size}rem;
		color: $mediumGrey;
	}
`

export const UnitPriceInfoWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background-color: ${(props) => props.theme.palette.white.main};
	border-radius: 4px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const UnitPriceLabel = styled.label`
	color: ${(props) => props.theme.palette.black.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	text-transform: uppercase;
`

export const UnitPriceValue = styled(UnitPriceLabel)`
	color: ${(props) => props.theme.palette.gray.main};
	text-transform: none;
`
