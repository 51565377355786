import styled, { css } from 'styled-components'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export const DeliveryManagementTitle = styled.h1(
	({ theme: { palette, font, spacingOptions } }) => css`
		font-size: ${font.size.DISPLAY_XS.size}rem;
		line-height: ${font.size.DISPLAY_XS.line_height}rem;
		color: ${palette.primaryWhite.contrastText};
		font-weight: ${font.weight.medium};

		margin-bottom: ${spacingOptions.XLG}px;
	`
)

export const DeliveryManagementContent = styled.div(
	({ theme: { spacingOptions } }) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;

		gap: ${spacingOptions.MD}px;
		margin-bottom: ${spacingOptions.LG}px;
	`
)

export const DeliveryManagementSubTitle = styled.h2(
	({ theme: { palette, font } }) => css`
		font-size: ${font.size.TEXT_MD.size}rem;
		line-height: ${font.size.TEXT_MD.line_height}rem;
		color: ${palette.primaryWhite.contrastText};
		font-weight: ${font.weight.medium};
	`
)

export const WrapperButtonsUpload = styled.div(
	({ theme: { spacingOptions } }) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 100%;
		gap: ${spacingOptions.LG}px;
	`
)

export const WrapperButtonUploadCsv = styled.div(
	({}) => css`
		width: 174px;
	`
)

export const WrapperAttachFile = styled.div(
	({ theme: { palette, font } }) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;

		p {
			color: ${palette.gray.main};
			font-size: ${font.size.TEXT_MD.size}rem;
			line-height: ${font.size.TEXT_MD.line_height}rem;
		}
	`
)

export const AttachFileIconStyled = styled(AttachFileIcon)(
	({ theme: { palette, font } }) => css`
		font-size: ${font.size.TEXT_MD}rem;
		color: ${palette.gray.main};
	`
)

export const WrapperButtonSendData = styled.div(
	({}) => css`
		width: 120px;
	`
)

export const TextHelp = styled.p(
	({ theme: { palette, font } }) => css`
		color: ${palette.gray.main};
		font-size: ${font.size.TEXT_SM.size}rem;
		width: 100%;

		a {
			color: ${palette.primary.main};
			font-size: ${font.size.TEXT_SM.size}rem;
			line-height: ${font.size.TEXT_SM.line_height}rem;
			font-weight: ${font.weight.medium};

			:hover {
				cursor: pointer;
			}
		}
	`
)
