import styled from 'styled-components'
import { colorThemeProvider } from '../../../../theme/themeProvider'

export const AnotherFormValidationView = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const Title = styled.h1`
	width: 100%;
	color: ${colorThemeProvider.primaryWhiteColorContrasct};
	font-size: 1.5rem;
	font-weight: 600;
	padding-bottom: 2rem;
`

export const Description = styled.p`
	color: ${colorThemeProvider.blackColor};
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
	letter-spacing: -0.0125rem;
`

export const WrapperFields = styled.div`
	width: 26rem;
	padding-top: 2.62rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
`
