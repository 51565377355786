import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { customerRoutesData } from '../../Routes/customerRoutesData'
import { CustomerRoutesEnum } from '../../Routes/customerRoutesEnum'

import { ICustomerNavBarProps } from './types'
import styles from './styles.module.scss'
import {
	canShowOrderHistoryAndComboPages,
	enabledProgramPointRoute,
	isCustomerPointsProgramRoutes
} from './util'
import { useTermsAdhesion } from '../../../shared/hooks/useTermsAdhesion'

export function CustomerNavBar(props: ICustomerNavBarProps) {
	const { userName, nickName } = props

	const { pointsProgramStatus, customer } = useTypedSelector([
		'pointsProgramStatus',
		'customer'
	])
	const [showModalAdhesion, setModalAdhesion] = useState(false)

	const { modalTerms } = useTermsAdhesion({
		showModalAdhesion,
		onClose: onCloseModal
	})

	const routesShownInNavBar = customerRoutesData.filter(
		(route) => route.displayOnNavBar
	)

	const pointsProgramRoutes = routesShownInNavBar
		.filter((route) => isCustomerPointsProgramRoutes(route.key))
		.filter((route) =>
			enabledProgramPointRoute(
				route.key,
				pointsProgramStatus.userJoinedTheProgram
			)
		)
	const navBarRoutes = routesShownInNavBar
		.filter((route) => canShowOrderHistoryAndComboPages(route.key))
		.filter((route) => !isCustomerPointsProgramRoutes(route.key))

	function onCloseModal() {
		setModalAdhesion(false)
	}

	function openModal() {
		setModalAdhesion((show) => !show)
	}
	return (
		<>
			<div className={styles.spacer80px} />

			<div className={styles.customerProfile}>
				<span className={styles.customerName}>Olá, {userName}</span>
				{nickName ? (
					<span className={styles.customerNickname}>{nickName}</span>
				) : null}
				{customer.CustomerID && (
					<Link
						className={styles.customerProfileLink}
						to={CustomerRoutesEnum.MY_ACCOUNT}>
						VER PERFIL
					</Link>
				)}
			</div>

			<section className={styles.routesSection}>
				<ul className={styles.routesSectionList}>
					{customer.CustomerID &&
						navBarRoutes.map((route) => (
							<li
								key={route.name}
								className={styles.routesListItem}>
								<NavLink
									className={styles.routeLink}
									activeClassName={styles.activeRouteLink}
									to={route.path}>
									<div>{route.name}</div>
								</NavLink>
							</li>
						))}
					<li className={styles.routesListItem} onClick={openModal}>
						<div className={styles.termsAdhesionLink}>
							Termo de adesão
						</div>
					</li>
				</ul>
			</section>

			{pointsProgramStatus.salesOrgHasProgramPoints &&
			customer.CustomerID ? (
				<section
					className={styles.routesSection}
					style={{ height: '100%' }}>
					<span className={styles.PointsProgramRoutesSection}>
						Programa de Pontos
					</span>
					<ul className={styles.routesSectionList}>
						{pointsProgramRoutes.map((route) => {
							return (
								<li
									key={route.name}
									className={styles.routesListItem}>
									<NavLink
										className={styles.routeLink}
										activeClassName={styles.activeRouteLink}
										to={route.path}>
										<div>{route.name}</div>
									</NavLink>
								</li>
							)
						})}
					</ul>
				</section>
			) : null}
			{modalTerms()}
		</>
	)
}
