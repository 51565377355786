import styled, { keyframes } from 'styled-components'

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

interface PrevButtonProps {
	isDisabled: boolean
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`

export const SliderWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	gap: 16px;
`

export const ControlsSliderWrapper = styled.div`
	display: flex;
	gap: 20px;

	.dots-slider {
		display: flex;
		align-items: center;
		gap: 15px;
	}

	.active-item {
		background-color: ${(props) => props.theme.palette.primary.main};
		height: 8px;
		width: 16px;
		border-radius: 30%;

		transition: 0.3s ease-in-out, color 0.3s ease-in-out;
		animation: ${fadeIn} 0.3s ease-in-out;

		:hover {
			cursor: pointer;
		}
	}
	.disabled {
		background-color: #eaeaea;
		height: 8px;
		width: 8px;
		border-radius: 100%;
		transition: 0.3s ease-in-out, color 0.3s ease-in-out;
		animation: ${fadeIn} 0.3s ease-in-out;

		:hover {
			cursor: pointer;
		}
	}
`

export const ImageSliderWrapper = styled.div`
	width: 683px;
	height: 522px;
	border-radius: 2%;
	transition: background-image 0.3s ease-in-out;
`

export const VideoPlayerWrapper = styled.div`
	width: 683px;
	height: 522px;
	position: relative;
	transition: background-image 0.3s ease-in-out;
`

export const PrevButton = styled(ArrowBackIosIcon)<PrevButtonProps>`
	color: ${(props) =>
		props.isDisabled
			? props.theme.palette.primary.main
			: props.theme.palette.extras.stroke};
	transition: color 0.3s ease-in-out;
`

export const NextButton = styled(ArrowForwardIosIcon)<PrevButtonProps>`
	color: ${(props) =>
		!props.isDisabled
			? props.theme.palette.primary.main
			: props.theme.palette.extras.stroke};
	transition: color 0.3s ease-in-out;
`
