export const TypesValidation = {
	EMAIL: {
		title: 'Validação',
		description: 'Digite o código enviado para o e-mail'
	},
	PHONE: {
		title: 'Validação',
		description: 'Digite o código enviado para o celular'
	},
	EMAIL_ALTERNATIVE: {
		title: 'Validação com e-mail alternativo',
		description: 'Digite o código enviado para o e-mail'
	},
	PHONE_ALTERNATIVE: {
		title: 'Validação com celular alternativo',
		description: 'Digite o código enviado para o celular'
	},
	NEW_EMAIL: {
		title: 'Validação de novo e-mail',
		description: 'Digite o código enviado para o e-mail'
	},
	NEW_PHONE: {
		title: 'Validação de novo número de celular',
		description: 'Digite o código enviado para o número'
	},
	EMAIL_AND_PHONE: {
		title: 'Validação',
		description: 'Digite o código enviado para o e-mail'
	}
}
