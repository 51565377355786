import React from 'react'
import ChoiceQuantity from '../choice-quantity/choice-quantity.component'
import ComboFlexItem from '../combo-flex-item/combo-flex-item.component'
import { IGroupItems } from './group-items.types'
import { Notification } from '../../../../../../../../../Notification'
import { validateImageExtension } from '../../../../../../../../../../shared/utils/image'

function GroupItems({
	groupItemsData,
	isDisabled,
	handleOpenModal,
	setProductDetail,
	selectedProductsToCart,
	setSelectedProductsToCart
}: IGroupItems) {
	const totalItemsGroup = Object.values(
		selectedProductsToCart?.[groupItemsData.groupID] ?? []
	).reduce((acc, product) => {
		return (acc += product.quantity)
	}, 0)

	const updateComboGroupItem = (item: any, quantity: number) => {
		setSelectedProductsToCart((prev) => {
			return {
				...prev,
				[item.groupID]: {
					...prev[item.groupID],
					[item.productID]: {
						productID: item.productID,
						dataProduct: item,
						quantity
					}
				}
			}
		})
	}

	return (
		<>
			<ChoiceQuantity
				isDisabled={isDisabled}
				label={
					groupItemsData.groupType === 'SALES_GROUP'
						? 'Escolha os produtos'
						: 'Escolha a bonificação'
				}
				min={totalItemsGroup.toString()}
				max={groupItemsData.quantityGroup}
			/>
			{isDisabled && (
				<Notification
					type="info"
					message="Finalize as escolhas acima para liberar esta opção"
					isComboFlex={true}
				/>
			)}
			{groupItemsData.items.map((item) => (
				<ComboFlexItem
					onClick={() => {
						handleOpenModal()
						setProductDetail({ ...item })
					}}
					comboItem={item}
					key={item.productID}
					updateComboGroupItem={updateComboGroupItem}
					productImage={validateImageExtension(
						item.productImageURL || ''
					)}
					isReturnable={item.material?.packingType !== 'Retornável'}
					priceComboFlex={item.price}
					priceUnity={item.unitPriceInPackage}
					productName={item.description}
					isDisabled={isDisabled}
					// Força diff no  git
					disabledIncrementButton={
						totalItemsGroup >= Number(groupItemsData.quantityGroup)
					}
					isBonus={item.groupType === 'BONUS_GROUP'}
					selectedProductsToCart={selectedProductsToCart}
					setSelectedProductsToCart={setSelectedProductsToCart}
				/>
			))}
		</>
	)
}

export default GroupItems
