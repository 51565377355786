import React from 'react'

import CheckboxActive from '../../../assets/images/checkbox-fill-secondary.svg'
import CheckboxInactive from '../../../assets/images/checkbox-blank-secondary.svg'

import Loading from '../../../components/Loading'
import InputInvoice from '../../../components/InputInvoice'

import { IProps, RegisterValidationMethods } from './types'
import { Container, ValidationFielContainer, SelectProductItem } from './styles'

function RegisterValidationSession({
	loading,
	productsForValidationByLastPurchase,
	selectedRegisterValidationMethod,
	setSelectedRegisterValidationMethod,
	returnValidationValue,
	registerValidarionValue
}: IProps) {
	function renderCheckboxIcon(method: RegisterValidationMethods) {
		return selectedRegisterValidationMethod === method ? (
			<img src={CheckboxActive} alt="checkd-item" />
		) : (
			<img src={CheckboxInactive} alt="uncheckd-item" />
		)
	}

	function isSelected(productId: string) {
		if (
			!registerValidarionValue ||
			typeof registerValidarionValue !== 'object'
		)
			return false
		return registerValidarionValue?.productID === productId
	}

	return (
		<Container>
			<h1 className="title-main">
				Para validar seu cadastro, selecione um método para confirmar
				sua autenticidade:
			</h1>

			{loading ? (
				<div className="loading-wrapper">
					<Loading color="red" />
				</div>
			) : (
				<div className="radio-group col-lg-6 col-md-12 center">
					{!!productsForValidationByLastPurchase.length && (
						<>
							<label
								className={
									selectedRegisterValidationMethod ===
									'invoice'
										? 'option border'
										: 'option'
								}
								onClick={() =>
									setSelectedRegisterValidationMethod(
										'lastPurchaseProduct'
									)
								}>
								{renderCheckboxIcon('lastPurchaseProduct')}
								Via Último Pedido realizado
							</label>

							{selectedRegisterValidationMethod ===
								'lastPurchaseProduct' &&
								typeof registerValidarionValue !== 'string' && (
									<ValidationFielContainer>
										<p>
											Qual foi o produto que você
											solicitou em sua última compra?
										</p>
										<div className="products-row">
											<SelectProductItem
												selected={isSelected(
													productsForValidationByLastPurchase[0]
														.productID
												)}
												onClick={() =>
													returnValidationValue(
														productsForValidationByLastPurchase[0]
													)
												}>
												<img
													src={
														productsForValidationByLastPurchase[0]
															.imageUrl
													}
													alt="Imagem do Produto"
												/>
												<span>
													{
														productsForValidationByLastPurchase[0]
															.description
													}
												</span>
											</SelectProductItem>
											<SelectProductItem
												selected={isSelected(
													productsForValidationByLastPurchase[1]
														.productID
												)}
												onClick={() =>
													returnValidationValue(
														productsForValidationByLastPurchase[1]
													)
												}>
												<img
													src={
														productsForValidationByLastPurchase[1]
															.imageUrl
													}
													alt="Imagem do Produto"
												/>
												<span>
													{
														productsForValidationByLastPurchase[1]
															.description
													}
												</span>
											</SelectProductItem>
										</div>
										<div className="products-row">
											<SelectProductItem
												selected={isSelected(
													productsForValidationByLastPurchase[2]
														.productID
												)}
												onClick={() =>
													returnValidationValue(
														productsForValidationByLastPurchase[2]
													)
												}>
												<img
													src={
														productsForValidationByLastPurchase[2]
															.imageUrl
													}
													alt="Imagem do Produto"
												/>
												<span>
													{
														productsForValidationByLastPurchase[2]
															.description
													}
												</span>
											</SelectProductItem>
											<SelectProductItem
												selected={isSelected(
													productsForValidationByLastPurchase[3]
														.productID
												)}
												onClick={() =>
													returnValidationValue(
														productsForValidationByLastPurchase[3]
													)
												}>
												<img
													src={
														productsForValidationByLastPurchase[3]
															.imageUrl
													}
													alt="Imagem do Produto"
												/>
												<span>
													{
														productsForValidationByLastPurchase[3]
															.description
													}
												</span>
											</SelectProductItem>
										</div>
									</ValidationFielContainer>
								)}
						</>
					)}
					<label
						className={
							selectedRegisterValidationMethod ===
							'lastPurchaseProduct'
								? 'option border'
								: 'option'
						}
						onClick={() =>
							setSelectedRegisterValidationMethod('invoice')
						}>
						{renderCheckboxIcon('invoice')}
						Via Número da Nota Fiscal
					</label>

					{selectedRegisterValidationMethod === 'invoice' && (
						<div className="input-invoice-wrapper col-10 center document">
							<p>Informe o Número da Nota Fiscal</p>
							<InputInvoice
								returnValue={(value) =>
									returnValidationValue(String(value))
								}
								valueIsValid={() => {}}
								className='input-tertiary'
							/>

							<span className="info-msg">
								INSIRA O
								<span className="red">
									{' '}
									NÚMERO DE UMA NOTA FISCAL{' '}
								</span>{' '}
								QUE O GRUPO PETRÓPOLIS EMITIU PARA VOCÊ. SOMENTE
								SERÃO VÁLIDAS AS{' '}
								<span className="red">
									ÚLTIMAS TRÊS NOTAS FISCAIS RECEBIDAS.
								</span>
							</span>
						</div>
					)}
				</div>
			)}
		</Container>
	)
}

export default RegisterValidationSession
