import React from 'react'
import ConfirmModalItem from './confirm-modal-item/confirm-modal-item'
import { WrapperBannerData } from './banner-data-container.styles'
import { BannersDataContainerProps } from './banner-data-container.types'

function BannersDataContainer({
	apliedTitle,
	destinyLink,
	exibitionPeriod,
	actionType,
	selectedProduct,
	selectedCategory
}: BannersDataContainerProps) {
	return (
		<WrapperBannerData>
			<div className="section-wrapper">
				<h2 className="section-title">Programação</h2>
				<ConfirmModalItem
					itemTitle="TÍTULO APLICADO"
					itemText={apliedTitle}
				/>
				<ConfirmModalItem
					itemTitle="LINK DESTINO"
					itemText={destinyLink}
				/>
				<ConfirmModalItem
					itemTitle="PERÍODO DE EXIBIÇÃO"
					itemText={exibitionPeriod}
				/>
			</div>
			<div className="section-wrapper">
				<h2 className="section-title">Comportamento</h2>
				<ConfirmModalItem
					itemTitle="TIPO DE AÇÃO"
					itemText={actionType}
				/>
				{(selectedProduct || selectedCategory) && (
					<ConfirmModalItem
						itemTitle="Destino"
						itemText={selectedProduct || selectedCategory}
					/>
				)}
			</div>
		</WrapperBannerData>
	)
}

export default BannersDataContainer
