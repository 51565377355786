import { ConfirmationMethods } from '../../../components/AccountActivationSession/types'
import { ISendDocumentsPF, ISendDocumentsPJ } from '../types'

export interface IProps {
	documentNumber: string
	handleDocuments: (isCpf: boolean, value: string, id: string) => void
	handleCheckBoxLGPD(): void
	handleCheckBoxSMS(): void
	checkBoxLGPD: boolean
	checkBoxSMS: boolean
	selectedConfirmationMethod: ConfirmationMethods
	handleChangeConfirmationMethod: (method: ConfirmationMethods) => void
	// handleSubmitDocuments: () => void
	skipStep: () => void
	sendDocumentsPF: ISendDocumentsPF | null
	sendDocumentsPJ: ISendDocumentsPJ | null
	setActive: React.Dispatch<React.SetStateAction<boolean>>
	setActive2: React.Dispatch<React.SetStateAction<boolean>>
	postalCode: string
	address: string
	name: string
	handleCreateClient: () => void
	handleLatAndLong: (isCpf: boolean, lat: string, long: string) => void
	termsAdhesionCheckbox: boolean
	handleTermsAdhesionCheckbox: () => void
	UF: string
	handlePassword: (value: string) => void
	handleIsOtimizacao: (isOtimizacao: boolean) => void
}

export enum DocumentType {
	RG = 'RG',
	CNH = 'CNH'
}

export interface ISelectDocumentType {
	value: DocumentType
	label: DocumentType
}

export enum DocumentTypeNumber {
	SELFIE = '1',
	RG = '2',
	RG_FRENTE = '501',
	RG_VERSO = '502',
	CNH = '4'
}

export type DocumentTypeNumberEnum = keyof typeof DocumentTypeNumber
