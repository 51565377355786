import { apiAdmin } from '../../../../shared/services/axiosInstace'
import { ITableDataCoupons } from '../history-coupon.types'
export interface IGetCouponsByIDService {
	couponID: number
}
const getCouponsByID = async ({
	couponID
}: IGetCouponsByIDService): Promise<ITableDataCoupons> => {
	const response = await apiAdmin.get(`/coupons/${couponID}/coupon-by-id`, {})
	return response.data
}
export { getCouponsByID }
