import styled from 'styled-components'
import { Box } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

export const CombosWrapper = styled(Box)`
	.combo-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main-content {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: ${(props) => props.theme.spacingOptions.SM}px;
		margin-top: ${(props) => props.theme.spacingOptions.XLG}px;

		h2 {
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
			line-height: ${(props) =>
				props.theme.font.size.DISPLAY_XS.line_height}rem;
			color: ${(props) => props.theme.palette.lightGray.main};
		}

		h6 {
			font-weight: ${(props) => props.theme.font.weight.regular};
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.black.main};
		}
		blackColor .subtitle-name {
			color: ${(props) => props.theme.palette.black.main};
		}

		.subtitle-code {
			color: ${(props) => props.theme.palette.primary.main};
		}

		.main-content-header {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		.wrapper-table-content {
			width: 100%;
			height: 570px;
			margin-top: ${(props) => props.theme.spacingOptions.XSM}px;

			.loading-wrapper {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
`

export const InputSearch = styled(Autocomplete)`
	width: 390px;
	background: ${(props) => props.theme.palette.primaryWhite.main};

	font-weight: ${(props) => props.theme.font.weight.regular};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

	& .MuiOutlinedInput-root {
		&.Mui-focused fieldset {
			border-color: ${(props) => props.theme.palette.black.main};
		}
	}

	svg {
		color: ${(props) => props.theme.palette.lightGray.main};
	}
`
