import { createElement, useEffect, useState } from 'react'
import { ActionMeta } from 'react-select'
import { useDispatch } from 'react-redux'
import cogoToast from 'cogo-toast'

import { checkRulesOfTheProducts, getMaterials } from '../ProductList/service'

import {
	characteristicTypeEnum,
	IMaterial
} from '../../shared/interfaces/material'
import { Maybe, Nullable } from '../../shared/interfaces/common'
import { ISelectFilters, ISelectOptions } from '../ProductList/types'
import { ISelect } from '../../components/SelectPrimary/types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'
import {
	CartRedemptionItemsActionEnum,
	Creators as CartRedemptionActions
} from '../../shared/store/ducks/cartRedemptionItem'

import { getPointsExtracts } from '../../shared/services/customerPointsProgram.service'
import { categoriesServices } from '../../shared/services/categories.services'

import { isPropEqual } from '../../shared/utils/object'
import { useNumber } from '../../shared/hooks/useNumber'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'
import { extractCartItem } from '../../shared/utils/cart'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { CogoPositions } from '../../shared/utils/toaster'
import { CogoPosition } from '../../shared/interfaces/cogoToast'
import { IExtractPoints } from '../../shared/interfaces/pointsProgram'
import { categoryArraySort } from '../../utils/category-array-sort'

import PointsRedemptionView from './view'
import { IViewProps } from './types'
import { sortListByListPositionProduct } from '../../utils/sort-list-by-list-position'

const DEFAULT_OPTION: ISelectOptions = {
	placeholder: '',
	options: []
}

const DEFAULT_FILTERS: ISelectFilters = {
	selectOne: null,
	selectTwo: null,
	selectThree: null,
	searchInput: ''
}

const DEFAULT_LIST: Array<IMaterial> = []

export default function PointsRedemption() {
	const dispatch = useDispatch()
	const { customer, cartRedemptionItems } = useTypedSelector([
		'customer',
		'cartRedemptionItems'
	])

	const [listOfSuggestedProducts, setListOfSuggestedProducts] = useState<
		Array<IMaterial>
	>([])

	const [redeemableProductList, setRedeemableProductList] = useState<
		Array<IMaterial>
	>([])

	const [userPointsExtractData, setUserPointsExtractData] =
		useState<Partial<IExtractPoints> | null>(null)

	const [isLoading, setIsLoading] = useState(false)
	const [selectedBrand, setSelectedBrand] = useState(DEFAULT_OPTION)
	const [selectedPackage, setSelectedPackage] = useState(DEFAULT_OPTION)
	const [selectedCategory, setSelectedCategory] = useState(DEFAULT_OPTION)
	const [selectedFilters, setSelectedFilters] = useState(DEFAULT_FILTERS)
	const [productsToBeRendered, setProductsToBeRendered] =
		useState(DEFAULT_LIST)
	const [
		insufficientAmountOfPointsForRedemption,
		setInsufficientAmountOfPointsForRedemption
	] = useState(false)

	const [selectedProduct, setSelectedProduct] = useState<
		IMaterial | undefined
	>(undefined)

	const [openLightbox, setOpenLightbox] = useState(false)
	const [modalOpen, openModal, closeModal] = useBoolean(false)
	const [lightboxPhotos, setLightboxPhotos] = useState<string[]>([])
	const [productAmount, increment, decrement, setAmount] = useNumber(1)

	const { updateBar, hideBar } = BottomBarActions

	function handleSelectCategory(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectOne: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSelectBrand(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectTwo: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSelectPackage(
		selectedValue: Maybe<Nullable<ISelect>>,
		_: ActionMeta<any>
	): void {
		setSelectedFilters({
			...selectedFilters,
			selectThree: selectedValue ? selectedValue.value : ''
		})
	}

	function handleSearchInput(e: React.ChangeEvent<HTMLInputElement>): void {
		setSelectedFilters({
			...selectedFilters,
			searchInput: e.target.value ? e.target.value : ''
		})
	}

	function handleClear() {
		setSelectedFilters(DEFAULT_FILTERS)
	}

	function setSelectOptions() {
		if (!redeemableProductList.length) return

		const options: {
			type: string
			values: string[]
		}[] = []
		redeemableProductList.forEach((product) => {
			product?.Characteristics?.forEach((char) => {
				const optionIndex = options.findIndex(
					(option) => option.type === char.Type
				)
				if (optionIndex !== -1) {
					const valueIndex = options[optionIndex].values.findIndex(
						(value) => value === char.Description
					)

					if (valueIndex === -1) {
						options[optionIndex].values.push(char.Description)
					}
				} else {
					options.push({
						type: char.Type,
						values: [char.Description]
					})
				}
			})
		})

		options.forEach(async (option, i) => {
			if (i === 0) {
				const optionsOne: any[] = []
				const dataBaseCategories = await handleGetCategories()

				option.values.forEach((value) => {
					optionsOne.push({ value, label: value })
				})

				const newArrayCategorie = categoryArraySort({
					dataBaseCategories,
					categoriesByProductList: option.values
				})

				setSelectedCategory({
					placeholder: characteristicTypeEnum[option.type],
					options: newArrayCategorie
				})
			} else if (i === 1) {
				const optionsTwo: any[] = []
				option.values.forEach((value) => {
					optionsTwo.push({ value, label: value })
				})

				setSelectedBrand({
					placeholder: characteristicTypeEnum[option.type],
					options: optionsTwo
				})
			}
			if (i === 2) {
				const optionsThree: any[] = []
				option.values.forEach((value) => {
					optionsThree.push({ value, label: value })
				})

				setSelectedPackage({
					placeholder: characteristicTypeEnum[option.type],
					options: optionsThree
				})
			}
		})
	}

	async function handleGetCategories() {
		try {
			const data = await categoriesServices.getCategoriesCustomer()
			return data
		} catch (error) {
			cogoToast.error('Erro ao trazer as categorias.')
		}
	}

	function handleSelectedFilters() {
		if (!redeemableProductList.length) return

		const filteredProductList = redeemableProductList.filter((item) => {
			if (!item) return false

			const selectOne = selectedFilters.selectOne
			const selectTwo = selectedFilters.selectTwo
			const selectThree = selectedFilters.selectThree
			const searchInput = selectedFilters.searchInput

			if (selectOne || selectTwo || selectThree || searchInput) {
				const selectedValues: Array<string> = []
				if (selectOne) selectedValues.push(selectOne)
				if (selectTwo) selectedValues.push(selectTwo)
				if (selectThree) selectedValues.push(selectThree)

				const match = selectedValues.every((v) =>
					item?.Characteristics?.some(isPropEqual('Description')(v))
				)

				const productName =
					(Boolean(searchInput) &&
						item?.Description?.toUpperCase().includes(
							searchInput ? searchInput.toUpperCase() : ''
						)) ||
					!Boolean(searchInput)

				if (match && productName) return item
			} else {
				return item
			}

			return false
		})

		if (selectedFilters.selectOne) {
			setProductsToBeRendered(sortListByListPositionProduct(filteredProductList))
		} else {
			setProductsToBeRendered(filteredProductList)
		}
	}

	const filtersProps = {
		isLoading,
		handleClear,
		selectedFilters,
		handleSearchInput,
		selectOptionsTwo: selectedBrand,
		handleSelectTwo: handleSelectBrand,
		selectOptionsOne: selectedCategory,
		selectOptionsThree: selectedPackage,
		handleSelectOne: handleSelectCategory,
		handleSelectThree: handleSelectPackage
	}

	function fetchProductsList() {
		; (async () => {
			try {
				setIsLoading(true)
				const productsResponseData = await getMaterials({
					morePoints: false,
					sectorID: customer.sectorID,
					segmentID: customer.SegmentID,
					customerID: customer.CustomerID,
					salesOrganizationID: customer.SalesOrganizationID,
					includesDraftBeer: customer.draftBeer
				})

				if (!productsResponseData) return

				const productListWithUpdatedScoreRateAndScoreValueData =
					await checkRulesOfTheProducts({
						products: productsResponseData,
						customer
					})

				const productListWithUpdatedScoreRateAndScoreValue =
					productsResponseData.map((material: IMaterial) => {
						const currentMaterial =
							productListWithUpdatedScoreRateAndScoreValueData.find(
								(product: any) =>
									product.productID === material.MaterialID
							)
						return {
							...material,
							ScoreValue: currentMaterial.ScoreValue,
							ScoreRate: currentMaterial.ScoreRate
						}
					})

				const filteredSuggestedProducts =
					productListWithUpdatedScoreRateAndScoreValue.filter(
						(item) => {
							return (
								item.ScoreRate &&
								Number(item.ScoreRate) <=
								Number(
									userPointsExtractData?.totalAvailableBalance
								)
							)
						}
					)

				const filteredRedeemableProducts =
					productListWithUpdatedScoreRateAndScoreValue?.filter(
						(item) => {
							return !!item.ScoreRate
						}
					)

				setListOfSuggestedProducts(filteredSuggestedProducts)
				setRedeemableProductList(filteredRedeemableProducts)
			} catch (err) {
			} finally {
				setIsLoading(false)
			}
		})()
	}

	function fecthExtractPointsBalance() {
		; (async () => {
			const balance = await getPointsExtracts(customer.CustomerID)
			setUserPointsExtractData(balance)
		})()
	}

	function updateProductAmount(item: ICartItem): void {
		const storeItem = cartRedemptionItems.find(
			isPropEqual('MaterialID')(item)
		)
		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0
		const totalQuantity = item.OrderQuantity + storeQuantity
		if (
			totalQuantity * Number(item.ScoreRate) >
			Number(userPointsExtractData?.totalAvailableBalance)
		) {
			setInsufficientAmountOfPointsForRedemption(true)
		}
		setAmount(totalQuantity)
	}

	function redemptionLimitForProductsWithPointsExceeded(
		totalAmountOfPointsForThisRedemption: number
	) {
		if (
			totalAmountOfPointsForThisRedemption >
			Number(userPointsExtractData?.totalAvailableBalance)
		) {
			setInsufficientAmountOfPointsForRedemption(true)
			return
		}
		setInsufficientAmountOfPointsForRedemption(false)
	}

	function handleDecrement() {
		const newProductAmount = productAmount - 1
		if (isNaN(newProductAmount)) return

		const totalQuantityOfProductsInPoints =
			newProductAmount * Number(selectedProduct?.ScoreRate)

		redemptionLimitForProductsWithPointsExceeded(
			totalQuantityOfProductsInPoints
		)
		decrement()
	}

	function handleIncrement() {
		const newProductAmount = productAmount + 1
		if (isNaN(newProductAmount)) return

		const totalQuantityOfProductsInPoints =
			newProductAmount * Number(selectedProduct?.ScoreRate)

		redemptionLimitForProductsWithPointsExceeded(
			totalQuantityOfProductsInPoints
		)
		increment()
	}

	function handleSelectProdut(product: Maybe<IMaterial>) {
		setSelectedProduct(product)
		if (
			userPointsExtractData &&
			Number(userPointsExtractData.totalAvailableBalance) <
			Number(product?.ScoreRate)
		) {
			setInsufficientAmountOfPointsForRedemption(true)
		}
		setLightboxPhotos([
			product?.ImageUrl || '',
			product?.PackageImageUrl || ''
		])
		dispatch(hideBar())
		if (!product) return
		updateProductAmount(extractCartItem(product, productAmount))
		openModal()
	}

	function handleUpdateAmount(event: React.ChangeEvent<HTMLInputElement>) {
		const newProductAmount = Number(event.target.value)
		if (isNaN(newProductAmount)) return

		const totalQuantityOfProductsInPoints =
			newProductAmount * Number(selectedProduct?.ScoreRate)

		redemptionLimitForProductsWithPointsExceeded(
			totalQuantityOfProductsInPoints
		)
		setAmount(newProductAmount)
	}

	function handleProductsToCart(selectedItem: IMaterial | undefined) {
		if (!productAmount) {
			cogoToast.error(
				'Por favor especifique a quantidade',
				CogoPositions['top-right']
			)
			return
		}
		const item = extractCartItem(selectedItem as IMaterial, productAmount)
		const storeItem = cartRedemptionItems.find(
			isPropEqual('MaterialID')(item)
		)
		const storeQuantity = storeItem ? storeItem?.OrderQuantity : 0
		const totalQuantity = productAmount

		return {
			item,
			storeItem,
			storeQuantity,
			totalQuantity
		}
	}

	function handleCloseModal() {
		setAmount(1)
		setInsufficientAmountOfPointsForRedemption(false)
		setLightboxPhotos([])
		setSelectedProduct(undefined)
		closeModal()
	}

	function addItemToStore(
		totalQuantity: number,
		options: CogoPosition,
		storeQuantity: number,
		item: ICartItem
	) {
		item.rescueProduct = true
		item.typeItem = ICartItemTypeEnum.REDEMPTION

		if (totalQuantity > 9999) {
			cogoToast.error('O máximo de unidades por item é 9999', options)
			return
		}

		if (storeQuantity !== productAmount) {
			dispatch(
				CartRedemptionActions.addRedemptionItem(
					item,
					CartRedemptionItemsActionEnum.ADD_REDEMPTION_ITEM
				)
			)
		}

		const bottomBarProps = {
			name: item?.Description,
			count: productAmount,
			imageUrl: item?.ImageUrl,
			time: 20,
			itemType: ICartItemTypeEnum.REDEMPTION
		}

		dispatch(updateBar(bottomBarProps))

		handleCloseModal()
		setTimeout(() => setAmount(1), 200)
	}

	function handleAddItemToCard(selectedType: IMaterial | undefined) {
		if (!selectedType) return

		const options = CogoPositions['top-right']

		const product = handleProductsToCart(selectedType)

		if (!product) return

		addItemToStore(
			product.totalQuantity,
			options,
			product.storeQuantity,
			product.item
		)
	}

	useEffect(fecthExtractPointsBalance, [customer])
	useEffect(fetchProductsList, [customer, userPointsExtractData])
	useEffect(setSelectOptions, [redeemableProductList])
	useEffect(handleSelectedFilters, [redeemableProductList, selectedFilters])

	const viewProps: IViewProps = {
		isLoading,
		modalOpen,
		openLightbox,
		filtersProps,
		productAmount,
		lightboxPhotos,
		setOpenLightbox,
		handleDecrement,
		selectedProduct,
		handleIncrement,
		handleUpdateAmount,
		handleSelectProdut,
		handleAddItemToCard,
		productsToBeRendered,
		listOfSuggestedProducts,
		closeModal: handleCloseModal,
		userPointsExtractData,
		insufficientAmountOfPointsForRedemption
	}
	return createElement(PointsRedemptionView, viewProps)
}
