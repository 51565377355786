import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'

export const MainResetPasswordView = styled.div`
	padding-top: 2rem;
	padding-bottom: 2.65rem;
`

export const WrapperButton = styled.div`
	display: flex;
	justify-content: space-between;
	border-top: 1px solid ${colorThemeProvider.extrasColor};
	padding-top: 32px;
	margin-top: 32px;

	.wrapperButtonDefault {
		display: flex;
		justify-content: center;
		width: 100%;
	}
`
