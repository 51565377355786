import { isPast, isValid, parse } from 'date-fns'

export const IS_VALID_PASSWORD =
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z$*&@#!]{8,}$/

export const ALL_SAME_NUMBER = /^(\d)\1+$/

export const SPECIAL_CHAR_AND_WHITESPACE = /[^\w\s]/gi

export const IS_VALID_EMAIL =
	/^(([^<>()[\]\\.,:\s@"]+(\.[^<>()[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const NON_DIGIT = /\D/g

export const MONEY_MASK = /[R$,.]+/g

export const validDDDs = [
	11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
	37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
	65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
	89, 91, 92, 93, 94, 95, 96, 97, 98, 99
]

export function checkRG(s: string): boolean {
	let rg = s.replace(SPECIAL_CHAR_AND_WHITESPACE, '')

	return rg.length > 6
}

export function checkCNPJ(s: string): boolean {
	let cnpj = s.replace(/[^\d]+/g, '')

	// Valida a quantidade de caracteres
	if (cnpj.length !== 14) return false

	// Elimina inválidos com todos os caracteres iguais
	if (ALL_SAME_NUMBER.test(cnpj)) return false

	// Cáculo de validação
	let t = cnpj.length - 2,
		d = cnpj.substring(t),
		d1 = parseInt(d.charAt(0)),
		d2 = parseInt(d.charAt(1)),
		calc = (x: number) => {
			let n = cnpj.substring(0, x),
				y = x - 7,
				s = 0,
				r = 0

			for (let i = x; i >= 1; i--) {
				s += Number(n.charAt(x - i)) * y--
				if (y < 2) y = 9
			}

			r = 11 - (s % 11)
			return r > 9 ? 0 : r
		}

	return Boolean(calc(t) === d1 && calc(t + 1) === d2)
}

export function checkCPF(cpf: string): boolean {
	cpf = cpf.replace(SPECIAL_CHAR_AND_WHITESPACE, '').trim()

	if (cpf.length !== 11 || ALL_SAME_NUMBER.test(cpf)) {
		return false
	}

	// Valida 1o digito
	let add: number = 0
	for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 0) * (10 - i)
	let rev: number = 11 - (add % 11)
	if (rev === 10 || rev === 11) rev = 0
	if (rev !== parseInt(cpf.charAt(9), 0)) return false

	// Valida 2o digito
	add = 0
	for (let i: number = 0; i < 10; i++) {
		add += parseInt(cpf.charAt(i), 0) * (11 - i)
	}
	rev = 11 - (add % 11)
	if (rev === 10 || rev === 11) rev = 0
	if (rev !== parseInt(cpf.charAt(10), 0)) return false

	return true
}

export function checkEmail(email: string): boolean {
	return Boolean(email && email.length && IS_VALID_EMAIL.test(email))
}

export function checkEmailMatch(email: string, emailCheck: string) {
	var tEmail = email.trim()
	var tEmailCheck = emailCheck.trim()
	var areEqualAndNotEmpty =
		tEmail === tEmailCheck &&
		tEmail.length !== 0 &&
		tEmailCheck.length !== 0
	return areEqualAndNotEmpty
}

export function checkPassword(
	password: string,
	password_check: string
): boolean {
	password = password.trim()
	password_check = password_check.trim()

	const areEqualAndNotEmpty =
		password === password_check &&
		password.length !== 0 &&
		password_check.length !== 0

	return areEqualAndNotEmpty
}

export function checkPasswordIsValid(password: string) {
	password = password.trim()
	const IS_VALID_PASSWORD =
		/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
	const isValidPassword = IS_VALID_PASSWORD.test(password)
	const isTrue = password.length !== 0 && isValidPassword

	return isTrue
}

export function checkTel(telephone: string): boolean {
	const tel = telephone.replace(NON_DIGIT, '').trim()

	const telArr = [...tel]

	const ddd = telArr.slice(0, 2).join('')

	// Verifica se tem a quantidade de numero correto
	if (tel.length > 11 || tel.length < 10) return false

	if (tel.length === 11 && Number(telArr[2]) !== 9) {
		// Se celular verifica se começa com 9
		return false
	}

	if (ALL_SAME_NUMBER.test(tel)) return false

	return validDDDs.includes(Number(ddd))
}

export const MONEY_FORMATTER = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL'
})

export function formatCep(cep: string): string {
	return cep.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')
}

export const DATE_FORMAT_MONTH_AND_YEAR = 'MM/yy'

export function validatorMonthAndYear(date: string): boolean {
	if (date.length < 5) return false

	const newDate = parse(date, DATE_FORMAT_MONTH_AND_YEAR, new Date())

	if (!isValid(newDate)) return false

	if (
		newDate.getFullYear() === new Date().getFullYear() &&
		newDate.getMonth() === new Date().getMonth()
	) {
		return true
	}

	if (isPast(newDate)) return false
	return true
}
