import { equals, flip } from 'ramda'
import React from 'react'

import { Table } from '../../shared/components'
import Header from '../../components/Header'
import Loading from '../../components/Loading'
import ConfirmDeleteParamModal from './ConfirmDeleteParamModal'
import ParameterModal from './ParameterModal'
import { IViewProps } from './types'
import { Container } from '../../components/Container/container.style'

import * as S from '../RegisterParameter/styles'

function RegisterParameter(props: IViewProps): JSX.Element {
	const {
		isConfirmOpen,
		isEditOpen,
		loading,
		param,
		paramList = [],
		promptedIndex,
		isEdit,
		closeConfirmModal,
		confirmParamDeletion,
		handleCloseModal,
		handleOpenModal,
		handleSubmit,
		updateParamKey,
		data,
		columns
	} = props

	const wantedIndex = flip(equals(promptedIndex))

	const paramName = paramList?.find(wantedIndex)?.Name || ''

	return (
		<div className="register-param-page">
			<Header />
			<Container>
				<div className="param-table-wrapper">
					<S.PageHeaderContainer>
						<p className="param-title">Parâmetros Gerais</p>
						<div className="red-button-container">
							<button
								className="red-button"
								onClick={() => handleOpenModal()}>
								CRIAR PARÂMETRO
							</button>
						</div>
					</S.PageHeaderContainer>
					<S.RegisterParametersContent>
						{(paramList || []).length ? (
							<Table data={data} columns={columns as any} />
						) : (
							<div className="loading-wrapper">
								<Loading />
							</div>
						)}
					</S.RegisterParametersContent>
				</div>
			</Container>

			<ParameterModal
				loading={loading}
				isEdit={isEdit}
				close={handleCloseModal}
				handleSubmit={handleSubmit}
				isActive={isEditOpen}
				param={param}
				updateParamKey={updateParamKey}
			/>
			<ConfirmDeleteParamModal
				closeModal={closeConfirmModal}
				confirmAction={confirmParamDeletion}
				isActive={isConfirmOpen}
				paramName={paramName}
			/>
		</div>
	)
}

export default RegisterParameter
