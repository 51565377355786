import { Box } from '@material-ui/core'
import styled from 'styled-components'

export const EmptyBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 10px;

	width: 100%;
	height: 354px;
	background: ${(props) => props.theme.palette.white.main};
	border-radius: 20px;

	.outside-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 140px;
		height: 140px;
		background: rgba(234, 0, 0, 0.06);
		border-radius: 50%;
		margin-bottom: 15px;

		.inside-circle {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(234, 0, 0, 0.06);
			border-radius: 50%;

			width: 96px;
			height: 96px;

			svg {
				color: ${(props) => props.theme.palette.primary.main};
				font-size: 60px;
			}

			.custom-icon {
				color: ${(props) => props.theme.palette.gray.main};
			}
		}
	}

	.empty-message {
		color: ${(props) => props.theme.palette.gray.main};
		font-weight: ${(props) => props.theme.font.weight.regular};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

		margin-top: 5px;
	}
`
