import React from 'react'
import { IconWrapper, Template } from './GeneralTemplate.styles'
import GalleryIcon from '@mui/icons-material/Collections'

interface IPlaceholderProps {
	text: string
}

function GeneralTemplate({ text }: IPlaceholderProps) {
	return (
		<Template>
			<IconWrapper>
				<GalleryIcon fontSize="large" />
			</IconWrapper>
			<span>{text}</span>
		</Template>
	)
}
export default GeneralTemplate
