import React from 'react'
import { useHistory } from 'react-router-dom'
import { CustomerRoutesEnum } from '../../../modules/Routes/customerRoutesEnum'
import { IOrderDetail } from '../../interfaces/orderDetail'

import CartItem from './CartItem'
import { IProps } from './types'

function OrderTable(props: IProps) {
	const { tableData } = props
	const history = useHistory()

	function handleClick(order: IOrderDetail) {
		history.replace(
			CustomerRoutesEnum.ORDER_DETAIL + '/' + order.SalesOrderID,
			{ orderDetails: order }
		)
	}

	return (
		<table className="order-table">
			<thead>
				<tr>
					<th>Número</th>
					<th>Data</th>
					<th>Situação</th>
					<th>Pagamento</th>
					<th>Valor Total</th>
				</tr>
			</thead>
			<tbody>
				<>
					{tableData.map((item, i) => (
						<CartItem
							handleClick={() => handleClick(item.orderDetails)}
							item={item}
							key={i}
						/>
					))}
				</>
			</tbody>
		</table>
	)
}

export default OrderTable
