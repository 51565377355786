import { createElement, useCallback, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { ConfirmationMethods } from '../../components/AccountActivationSession/types'
import { formatConfirmationCodeMethodParams } from '../../components/AccountActivationSession/util'
import { resendSignUp } from '../ConfirmActivationCode/service'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'

import { IProps, IViewProps } from './types'
import ActivateRegistrationView from './view'

function ActivateRegistration() {
	const { state } = useLocation<IProps>()
	const documentNumber = state.documentNumber

	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)
	const [
		selectedConfirmationMethod,
		setSelectedConfirmationMethod
	] = useState<ConfirmationMethods>(null)

	function handleBack() {
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}

	const handleChangeConfirmationMethod = useCallback((
		method: ConfirmationMethods
	) => {
		setSelectedConfirmationMethod(method)
	}, [])

	const handleResendCode = useCallback(() => {
		(async () => {
			try {
				setIsLoading(true)

				const response = await resendSignUp({
					username: documentNumber,
					clientMetadata: formatConfirmationCodeMethodParams(selectedConfirmationMethod)
				})

				if (!response) return

				history.replace(UnauthenticatedRoutesEnum.CONFIRM_ACTIVATION_CODE, {
					documentNumber: documentNumber,
					confirmationMethod: selectedConfirmationMethod
				})
			} finally {
				setIsLoading(false)
			}
		})()
	}, [documentNumber, selectedConfirmationMethod, history])

	const viewProps: IViewProps = {
		handleBack,
		handleChangeConfirmationMethod,
		selectedConfirmationMethod,
		handleResendCode,
		isLoading
	}
	return createElement(ActivateRegistrationView, viewProps)
}

export default ActivateRegistration
