import styled from 'styled-components'

export const ImageSliderWrapper = styled.div`
	width: 100%;
	height: 198px;
	padding: 0 8px;
	border-radius: 4px;
`

export const VideoPlayerWrapper = styled.div`
	height: 198px;
	width: 100%;
	border-radius: 4px;
	padding: 0 8px;
	position: relative;
`

export const VideoPlayerWrapperModal = styled.div`
	height: 100%;
	width: 100%;
	border-radius: 4px;
	padding: 0 8px;
	position: relative;
`
