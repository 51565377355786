import { prop } from 'ramda'
import { createElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'

import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { IStore } from '../../shared/interfaces/store'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'
import { validateImageExtension } from '../../shared/utils/image'
import { add } from '../../shared/utils/math'
import { parseItemPrice } from '../../shared/utils/money'
import { pipe } from '../../shared/utils/operators'
import { getMinPriceFromParams } from '../../shared/utils/param'
import { IViewProps } from './types'
import BottomBarToaster from './view'

import ProductPlaceholder from '../../assets/images/placeholder-product.svg'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'

function BottomBarToasterContainer() {
	const dispatch = useDispatch()
	const history = useHistory()
	const storeKeys: (keyof IStore)[] = [
		'bottomBar',
		'carts',
		'companyParams',
		'cartChallengeItems'
	]

	const { bottomBar, carts, companyParams, cartChallengeItems } =
		useTypedSelector(storeKeys)

	const itemsChallenge = cartChallengeItems
		.map((cartChallengeItem) => cartChallengeItem.addedProducts)
		.flat()
	const items = carts.map((cart) => cart.items).flat()

	const fmtPrice = pipe(prop('Price'), Number)

	const itemsTotalPrice = items.map(fmtPrice).reduce(add, 0)
	const itemsChallengeTotalPrice = itemsChallenge.map(fmtPrice).reduce(add, 0)

	const totalPrice = itemsTotalPrice + itemsChallengeTotalPrice

	const numberValue = getMinPriceFromParams(companyParams)

	const minValueLabel = parseItemPrice(numberValue)

	const reachedMinValue = totalPrice >= numberValue

	const { hideBar } = BottomBarActions
	const {
		name,
		count,
		isActive,
		buttonGoTo,
		time,
		imageUrl,
		comboImageUrl,
		itemType
	} = bottomBar

	const [barTime, setBarTime] = useState(0)
	const [stopTimer, setStop] = useState(false)

	function handleBarTime() {
		if (!barTime) return

		const time = barTime - 1

		const timeHandler = () => {
			setBarTime(stopTimer ? 0 : time)

			// if (time === 0) dispatch(hideBar())
		}

		setTimeout(timeHandler, 1000)
	}

	const productSrc =
		comboImageUrl === undefined
			? validateImageExtension(imageUrl) || ProductPlaceholder
			: validateImageExtension(comboImageUrl) || ProductPlaceholder

	const handleStatusChange = () => {
		if (isActive === true) setBarTime(time)
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleStatusChange, [isActive])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleBarTime, [barTime])

	function hide() {
		dispatch(hideBar())
		setStop(true)
	}

	function handleGoToCart() {
		if (buttonGoTo() !== null) return buttonGoTo()

		history.replace(CustomerRoutesEnum.CART)

		hide()
	}

	const viewProps: IViewProps = {
		count,
		isActive,
		minValueLabel,
		name,
		productSrc,
		reachedMinValue,
		handleGoToCart,
		hide,
		itemType
	}

	return createElement(BottomBarToaster, viewProps)
}

export default withRouter(BottomBarToasterContainer)
