import React, { Fragment } from 'react'

import { Header, Loading } from '../../shared/components'
import ComboListSection from './ComboList'
import Filters from './Filters'
import ProductModal from '../ProductList/ProductModal'
import LightBox from './LightBox'
import { IViewProps } from './types'

import connectErrorPlaceholderIcon from '../../assets/images/connecterror-placeholder.svg'

function ComboList(props: IViewProps) {
	const {
		isLoading,
		filterProps,
		comboItensData,
		handleSelectCombo,
		closeModal,
		handleAddItemToCard,
		handleDecrement,
		handleIncrement,
		lightboxPhotos,
		modalOpen,
		openLightbox,
		productAmount,
		setOpenLightbox,
		currentCombo,
		handleUpdateAmount,
		showReloadOption,
		handleReload
	} = props

	const comboListProps = {
		handleSelectCombo,
		data: comboItensData
	}

	const inputIncrementProps = {
		value: productAmount,
		enableButtons: true,
		handleIncrementEvent: handleIncrement,
		handleDecrementEvent: handleDecrement,
		handleUpdate: handleUpdateAmount
	}

	function renderReloadOption() {
		return (
			<div className="placeholder-wrapper">
				<div className="placeholder-container">
					<div className="gp-logo-wrapper">
						<img
							className="gp-logo"
							src={connectErrorPlaceholderIcon}
							alt="Grupo Petrópolis"
						/>
					</div>
					<h1>Servidor indisponível, no momento</h1>
					<h2>PARECE QUE NÃO CONSEGUIMOS ESTABELECER UMA CONEXÃO</h2>
					<button onClick={handleReload} className="button">
						ATUALIZAR
					</button>
				</div>
			</div>
		)
	}

	function renderCombosPanel() {
		return (
			<div className="row">
				<Filters {...filterProps} />

				<div className="col-12">
					<div className="combo-page-title">
						<h2>Resultado</h2>
					</div>
					<div className="combo-list-content-wrapper">
						{isLoading ? (
							<div className="loading-wrapper">
								<Loading />
							</div>
						) : (
							<ComboListSection {...comboListProps} />
						)}
					</div>
				</div>
			</div>
		)
	}

	return (
		<Fragment>
			<Header />

			<div className="combo-list-page">
				<div className="container">
					{showReloadOption
						? renderReloadOption()
						: renderCombosPanel()}
				</div>
			</div>

			{currentCombo?.isComboFlex ? '':<ProductModal
				closeModal={closeModal}
				handleAddProduct={() => handleAddItemToCard(currentCombo)}
				inputIncrementProps={inputIncrementProps}
				modalOpen={modalOpen}
				productAmount={productAmount}
				selectedProduct={currentCombo}
				setOpenLightbox={setOpenLightbox}
				type={'COMBO'}
			/>}

			<LightBox
				isOpen={openLightbox}
				setIsOpen={setOpenLightbox}
				lightboxPhotos={lightboxPhotos}
			/>
		</Fragment>
	)
}

export default ComboList
