import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	IBottomBarActionCreators
} from '../../interfaces/action'
import { ICartItemTypeEnum } from '../../interfaces/cart'
import { IStoreBottomBar } from '../../interfaces/store'

export const {
	Types,
	Creators
}: IActionsFromCreators<IBottomBarActionCreators> = createActions({
	updateBar: ['bottomBar'],
	hideBar: []
})

export const INITIAL_STATE: IStoreBottomBar = {
	name: '',
	count: 0,
	imageUrl: '',
	isActive: false,
	buttonGoTo: () => null,
	time: 0,
	itemType: ICartItemTypeEnum.NORMAL,
	comboImageUrl: ''
}

const updateBar = (state = INITIAL_STATE, action: any) => {
	const { bottomBar } = action

	return {
		isActive: true,
		buttonGoTo: () => null,
		time: 5,
		...bottomBar
	}
}

const hideBar = (state = INITIAL_STATE) => INITIAL_STATE

export default createReducer<IStoreBottomBar, Action<IBottomBarActionCreators>>(
	INITIAL_STATE,
	{
		[Types.UPDATE_BAR]: updateBar,
		[Types.HIDE_BAR]: hideBar
	}
)
