import React from 'react'
import * as S from './code-validation.styles'
import { ICodeValidationComponent, TypeContact } from './code-validation.types'
import { TypesValidation } from './code-validation.data'

import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import AlertIconGray from './assets/alert-icon-gray.svg'
import AlertIconPurple from './assets/alert-icon-purple.svg'
import { WrapperButton } from '../../reset-password.styles'
import { formatConfirmationCodeMethodParams } from '../../../../components/AccountActivationSession/util'
import {
	validateAttribute,
	sendCodeForNewPassword
} from '../../../../modules/ConfirmActivationCode/service'
import { ConfirmationMethods } from '../../../../components/AccountActivationSessionSecondary/types'
import {
	updateEmailCognitoWithValidation,
	updateNewEmail,
	updateNewPhone,
	updatePhoneCognitoWithValidation
} from '../../reset-password.service'
import { forgotPasswordSubmit } from '../../../../modules/ChangePassword/service'
import { EnumTypeFlow } from '../../reset-password.types'
import { maskCellPhoneNumber, maskEmail } from '../../../../utils/mask-data'

function CodeValidationComponent({
	typeContact,
	contact,
	showAnotherFormOption,
	goStep,
	handleOpenSidebar,
	isLoading,
	setIsLoading,
	customerDocument,
	newPassword,
	newEmail,
	newPhone,
	typeFlow,
	isPasswordValidated,
	setIsPasswordValidated,
	activationType,
	isEmailRecover,
	customerID
}: ICodeValidationComponent) {
	const [codeArray, setCodeArray] = React.useState<string[]>([
		'',
		'',
		'',
		'',
		'',
		''
	])
	const [isDisabledButton, setIsDisabledButton] = React.useState(false)
	const [timer, setTimer] = React.useState<number>(59)
	const [hasValidationCodeResponse, setHasValidationCodeResponse] =
		React.useState<boolean>(false)
	const [isInvalidCode, setIsInvalidCode] = React.useState<boolean>(false)
	const [contactMask, setContactMask] = React.useState<string>('')
	// const { userMaskedData } = useRecoverPasswordContextContext()
	const handleSendCode = async (typeValidation: ConfirmationMethods) => {
		try {
			setIsLoading(true)
			if (isPasswordValidated) {
				if (isEmailRecover) {
					await updateEmailCognitoWithValidation(
						customerDocument,
						newEmail
					)
				} else {
					await updatePhoneCognitoWithValidation(
						customerDocument,
						newPhone
					)
				}
			} else {
				await sendCodeForNewPassword({
					username: customerDocument,
					clientMetadata: formatConfirmationCodeMethodParams(
						typeContact === TypeContact.EMAIL ||
							typeContact === TypeContact.EMAIL_ALTERNATIVE ||
							typeContact === TypeContact.NEW_EMAIL ||
							isEmailRecover
							? 'sendemail'
							: 'sendsms'
					)
				})
			}
		} finally {
			setIsLoading(false)
		}
		setTimer(5)
	}

	function changeValueCode({
		index,
		value
	}: {
		index: number
		value: string
	}): void {
		setHasValidationCodeResponse(false)
		if (value.length > 1) return

		const newCodeArray = codeArray
		newCodeArray[index] = value

		setCodeArray([...newCodeArray])
		if (index < 5) {
			const inputElement = document.getElementById(
				`input-code-${index + 1}`
			)
			inputElement?.focus()
		}
	}

	async function confirm() {
		const code = codeArray.join('')
		confirmValidationCode(code)
	}

	async function confirmValidationCode(code: string) {
		setHasValidationCodeResponse(true)

		try {
			if (typeFlow === EnumTypeFlow.NORMALFLOW) {
				await forgotPasswordSubmit({
					documentNumber: customerDocument,
					code: code,
					newPassword: newPassword,
					options: { useCustomErrorHandler: true }
				})
				setIsPasswordValidated(true)
				goStep(2)
			} else if (typeFlow === EnumTypeFlow.ALTERNATIVEFLOW) {
				await forgotPasswordSubmit({
					documentNumber: customerDocument,
					code: code,
					newPassword: newPassword,
					options: { useCustomErrorHandler: true }
				})
				setIsPasswordValidated(true)

				goStep(5)
			} else if (typeFlow === EnumTypeFlow.SECURITY_QUESTIONS) {
				await forgotPasswordSubmit({
					documentNumber: customerDocument,
					code: code,
					newPassword: newPassword,
					options: { useCustomErrorHandler: true }
				})
				setIsPasswordValidated(true)
				goStep(2)
			} else if (typeFlow === EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED) {
				await validateAttribute({
					username: customerDocument,
					code: code,
					attr: isEmailRecover ? 'email' : 'phone_number',
					password: newPassword
				})
				goStep(2)
			}

			if (
				typeFlow === EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED ||
				typeFlow === EnumTypeFlow.SECURITY_QUESTIONS
			) {
				updateNewEmail(customerDocument, newEmail)
				updateNewPhone(customerDocument, newPhone)
			}
			setIsInvalidCode(false)
		} catch (error) {
			setIsInvalidCode(true)
		}
	}

	function handleClickNewFlow() {
		if (typeFlow === EnumTypeFlow.ALTERNATIVEFLOW) {
			goStep(4)
		}

		if (
			typeFlow === EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED ||
			typeFlow === EnumTypeFlow.SECURITY_QUESTIONS
		) {
			goStep(5)
		} else if (typeFlow === EnumTypeFlow.NORMALFLOW) {
			handleOpenSidebar()
		}
	}

	React.useEffect(() => {
		if (timer > 0 && !isLoading) {
			setTimeout(() => {
				setTimer(Number(timer - 1))
			}, 1000)
		}
	}, [timer, isLoading])

	React.useEffect(() => {
		const code = codeArray.join('')
		if (code.length < 6) {
			setIsDisabledButton(true)
		} else {
			setIsDisabledButton(false)
		}
	}, [codeArray, isDisabledButton])

	React.useEffect(() => {
		if (typeContact === TypeContact.NEW_PHONE) {
			setContactMask(maskCellPhoneNumber(contact))
		} else if (typeContact === TypeContact.NEW_EMAIL) {
			setContactMask(maskEmail(contact))
		} else {
			setContactMask(contact)
		}
	}, [typeContact, contact])

	return (
		<>
			<S.CodeValidationView>
				<S.Title>Validação de dados</S.Title>
				<S.ContactText>{contactMask}</S.ContactText>
				<S.Description>
					{TypesValidation[typeContact].description}
				</S.Description>
				<S.Code>
					{codeArray.map((item, index) => {
						return (
							<input
								id={`input-code-${index}`}
								type="text"
								value={item}
								onChange={(event) =>
									changeValueCode({
										index,
										value: event.target.value
									})
								}
							/>
						)
					})}
				</S.Code>

				<S.TimerWrapper>
					{timer !== 0 && !isLoading ? (
						<div>
							<p>Solicitar um novo código em:</p>
							<S.Timer>
								00:{timer < 10 ? `0${timer}` : timer}
							</S.Timer>
						</div>
					) : (
						<ButtonDefault
							buttonText="Solicitar novo código"
							type={TypeButtonEnum.OUTLINED}
							onClick={
								typeContact === TypeContact.EMAIL ||
								typeContact === TypeContact.EMAIL_ALTERNATIVE ||
								typeContact === TypeContact.NEW_EMAIL
									? () => handleSendCode('sendemail')
									: () => handleSendCode('sendsms')
							}
							idElement="button-new-code"
							width="234px"
						/>
					)}
				</S.TimerWrapper>
				{isInvalidCode && hasValidationCodeResponse && (
					<S.ErrorCard>
						<img src={AlertIconPurple} alt="AlertIconPurple" />
						<p>O código digitado está incorreto</p>
					</S.ErrorCard>
				)}

				{timer === 0 && showAnotherFormOption && (
					<S.OtherWayCard>
						<img src={AlertIconGray} alt="AlertIconGray" />
						<p>
							Não recebeu o seu código?
							<p onClick={() => handleClickNewFlow()}>
								{' '}
								{typeFlow === EnumTypeFlow.ALTERNATIVEFLOW
									? 'tente perguntas de segurança'
									: typeFlow ===
											EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED ||
									  typeFlow ===
											EnumTypeFlow.SECURITY_QUESTIONS
									? 'Altere o email'
									: 'tente de outra forma'}{' '}
							</p>
						</p>
					</S.OtherWayCard>
				)}
			</S.CodeValidationView>

			<WrapperButton>
				<>
					<ButtonDefault
						buttonText="Voltar"
						type={TypeButtonEnum.OUTLINED}
						onClick={() =>
							typeFlow === EnumTypeFlow.NORMALFLOW ||
							typeFlow === EnumTypeFlow.ALTERNATIVEFLOW
								? goStep(0)
								: typeFlow ===
										EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED &&
								  goStep(5)
						}
						idElement="cancel"
						width="234px"
					/>
					<ButtonDefault
						buttonText="Validar dados"
						type={
							isDisabledButton
								? TypeButtonEnum.DISABLED
								: TypeButtonEnum.PRIMARY
						}
						onClick={() => confirm()}
						idElement="confirm"
						width="234px"
					/>
				</>
			</WrapperButton>
		</>
	)
}

export default CodeValidationComponent
