import React, { useEffect } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'

import {
	IProps,
	ITableColumn,
	ITableInstance,
	ITableOptions,
	ITableState
} from './types'
import Loading from '../Loading'
import { colorThemeProvider } from '../../theme/themeProvider'

type TableProps<T extends Record<string, any>> = Omit<
	Partial<IProps<T>>,
	'columns'
> &
	Pick<IProps<T>, 'columns'>

function Table<T extends Record<string, any>>(props: TableProps<T>) {
	const {
		data = [],
		columns,
		canPaginate = true,
		isLoading,
		navProps,
		pageSize,
		sortBy,
		getSelectedColumnAcessorAndOrder,
		canSortByHeader = true
	} = props

	const tableOptions: ITableOptions<T> = {
		columns,
		data,
		manualSortBy: !!getSelectedColumnAcessorAndOrder
	}

	const tableInstance: ITableInstance<T> = useTable<T>(
		tableOptions,
		useSortBy,
		usePagination
	) as ITableInstance<T>

	let {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		page,
		state,
		gotoPage,
		previousPage,
		nextPage,
		canPreviousPage,
		canNextPage,
		pageCount,
		setPageSize
	} = tableInstance
	let { pageIndex } = state as ITableState<T>
	let totalDocs = rows.length
	if (navProps) {
		pageCount = navProps.pageCount
		pageIndex = navProps.pageIndex
		totalDocs = navProps.totalDocs
		canNextPage = !!navProps ? pageIndex + 1 < pageCount : canNextPage
		canPreviousPage = !!navProps ? pageIndex > 0 : canPreviousPage
	}

	const callPreviousPage = !!navProps ? navProps.previousPage : previousPage
	const callNextPage = !!navProps ? navProps.nextPage : nextPage
	const callGoToPage = !!navProps ? navProps.gotoPage : gotoPage
	const filterPages = (visiblePages: any[], totalPages: number) => {
		return visiblePages.filter((page: number) => page <= totalPages)
	}

	const getVisiblePages = (page: number, total: number) => {
		if (total < 7) {
			return filterPages([1, 2, 3, 4, 5, 6], total)
		} else {
			if (page % 5 >= 0 && page > 4 && page + 2 < total) {
				return [1, page - 1, page, page + 1, total]
			} else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
				return [1, total - 3, total - 2, total - 1, total]
			} else {
				return [1, 2, 3, 4, 5, total]
			}
		}
	}

	const formatPagination = (x: number, idx: number, arr: number[]) => {
		const { length } = arr

		const total = arr[length - 1]

		if (total <= 6) {
			return x
		} else if (length > 5) {
			return idx === 5 ? '...' + x : x
		} else {
			if (idx === 0) return x + '...'
			if (idx === 4 && arr[2] < total - 2) return '...' + x
			return x
		}
	}

	function getOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined ? -1 : isSortedDesc ? 1 : 0
	}

	function getNextOrder(isSortedDesc: boolean | undefined) {
		return isSortedDesc === undefined
			? true
			: isSortedDesc
			? false
			: undefined
	}

	function getOrderAndAcessor(id: string, isSortedDesc: any) {
		const value =
			getSelectedColumnAcessorAndOrder &&
			getSelectedColumnAcessorAndOrder(id, isSortedDesc)

		return value
	}

	useEffect(() => {
		if (!pageSize) return

		setPageSize(pageSize)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageSize])

	return (
		<>
			{isLoading && (
				<div data-testid="loading" className="loading-wrapper">
					<Loading color={colorThemeProvider.primaryColor} />
				</div>
			)}

			{data.length && !isLoading ? (
				<div className="table-wrapper">
					<div className="custom-table-container">
						<table {...getTableProps()} className="table ">
							<thead>
								{/* TODO: FINISH TYPES */}
								{headerGroups.map((headerGroup: any, i) => (
									<tr
										key={i}
										{...headerGroup.getHeaderGroupProps()}>
										{headerGroup.headers.map(
											(column: ITableColumn<T>) => {
												const getColumnHeaderProps = {
													onClick: () => {
														if (
															isLoading ||
															!column.canSort
														)
															return
														getSelectedColumnAcessorAndOrder &&
															getOrderAndAcessor(
																column.id,
																getOrder(
																	column.isSortedDesc
																)
															)

														const isDecending =
															getNextOrder(
																column.isSortedDesc
															)

														isDecending ===
															undefined &&
															column.clearSortBy()

														column.toggleSortBy &&
															column.toggleSortBy(
																isDecending,
																false
															)
														isDecending ===
															undefined &&
															column.clearSortBy()
													}
												}
												// Condicional para criar tabelas sem ordenação por colunar.
												if (canSortByHeader) {
													return (
														<th
															{...column.getHeaderProps(
																column.getSortByToggleProps()
															)}
															{...(getSelectedColumnAcessorAndOrder
																? getColumnHeaderProps
																: undefined)}>
															{column.render(
																'Header'
															)}
															<span>
																{!getSelectedColumnAcessorAndOrder &&
																column.isSorted
																	? column.isSortedDesc
																		? '  ⇓'
																		: '  ⇑'
																	: ''}
																{sortBy &&
																column.id ===
																	sortBy?.acessor &&
																sortBy?.order !==
																	0
																	? sortBy?.order ===
																	  -1
																		? '  ⇓'
																		: '  ⇑'
																	: ''}
															</span>
														</th>
													)
												} else {
													return (
														<th>
															{column.render(
																'Header'
															)}
														</th>
													)
												}
											}
										)}
									</tr>
								))}
							</thead>

							<tbody {...getTableBodyProps()}>
								{(canPaginate ? page : rows).map((row) => {
									prepareRow(row)
									return (
										<tr {...row.getRowProps()}>
											{row.cells.map((cell) => {
												return (
													<td
														{...cell.getCellProps()}>
														{cell.render('Cell')}
													</td>
												)
											})}
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>

					{canPaginate && (
						<div className="table-footer">
							<span className="result-span">
								Resultados, {page.length} de {totalDocs}
							</span>
							<div className="pagination">
								<button
									className="button"
									onClick={() =>
										callPreviousPage(pageIndex - 1)
									}
									disabled={!canPreviousPage}>
									{'Anterior'}
								</button>

								<div className="page-numbers">
									{getVisiblePages(
										pageIndex + 1,
										pageCount
									).map((x, index, arr) => {
										return (
											<span
												key={index}
												className={`page-number ${
													x - 1 === pageIndex
														? 'active'
														: ''
												}`}
												onClick={() => {
													callGoToPage(x - 1)
												}}>
												{formatPagination(
													x,
													index,
													arr
												)}
											</span>
										)
									})}
								</div>
								<button
									className="button"
									onClick={() => callNextPage(pageIndex + 1)}
									disabled={!canNextPage}>
									{'Próxima'}
								</button>
							</div>
						</div>
					)}
				</div>
			) : (
				!isLoading && (
					<div className="empty-table">
						<span className="text">Nenhum dado para mostrar.</span>
					</div>
				)
			)}
		</>
	)
}

export default Table
