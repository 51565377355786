import { Auth } from 'aws-amplify'
import { handleCognitoError } from '../../shared/utils/handleCognitoError'

export async function confirmEditCellPhoneCode(confirmationCode: string) {
	try {
		const userCognito = await Auth.currentAuthenticatedUser()

		const response = await Auth.verifyUserAttributeSubmit(
			userCognito,
			'phone_number',
			confirmationCode
		)

		return response
	} catch (err) {
		console.error('ERROR', { err })
		handleCognitoError(err)
	}
}
