import { createElement } from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { UserTypeEnum } from '../../shared/interfaces/customer'
import { logout } from '../../shared/services/axiosInstace'

import { Creators as CustomerActions } from '../../shared/store/ducks/customer'
import { Creators as TokenActions } from '../../shared/store/ducks/token'
import { getUserDetails } from '../Auth/service'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { UnauthenticatedRoutesEnum } from '../Routes/unauthenticatedRoutesEnum'
import { IViewProps } from './types'
import AuthMobileView from './view'

function AuthMobile() {
	const history = useHistory()
	const dispatch = useDispatch()

	const { documentNumber, token } = useParams<{
		documentNumber: string
		token: string
	}>()

	//	const [documentNumber, setDocumentNumber] = useState('')
	//	const [documentIsValid, setDocumentIsValid] = useState(false)

	const [loading, setLoading] = useState(false)

	function handleMobileLogin() {
		;(async () => {
			try {
				setLoading(true)
				dispatch(TokenActions.saveAccess(token))

				const responseUser = await getUserDetails(documentNumber)

				if (!responseUser) {
					logout()
					return
				}

				if (responseUser.signUpStatus) {
					logout()

					history.replace(UnauthenticatedRoutesEnum.AWAITING_APPROVAL_SAC)
					return
				}

				dispatch(
					CustomerActions.updateCustomer({
						// ...DEFAULT_CUSTOMER,
						...responseUser,
						Type: UserTypeEnum.CUSTOMER
					})
				)

				history.replace(CustomerRoutesEnum.PRODUCT_LIST)
			} catch (err) {
			} finally {
				setLoading(false)
			}
		})()
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleMobileLogin, [])

	const viewProps: IViewProps = {
		loading
	}

	return createElement(AuthMobileView, viewProps)
}

export default AuthMobile
