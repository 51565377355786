import { CharacterCounter, FloatLabelInputWrapper } from './styles'
import { IFloatLabelInputProps } from './types'

import React from 'react'
import { paddingToString } from '../../shared/styles/functions'

function FloatLabelTextareaInput({
	label,
	id,
	className,
	primaryColor = '#444444',
	labelColor = primaryColor,
	backgroundColor = 'transparent',
	round = false,
	secondaryColor = '#D1D1D1',
	fontSize = 16,
	fontFamily = 'sans-serif',
	padding = [26, 16, 10, 16],
	value,
	disabled,
	maxLength,
	containerStyle,
	isChallengeScreen,
	...rest
}: IFloatLabelInputProps) {
	const paddingString = paddingToString(padding)

	const mergedStyles = {
		primaryColor,
		backgroundColor,
		round,
		secondaryColor,
		fontSize,
		fontFamily,
		padding: paddingString,
		labelColor,
		...containerStyle
	}

	const counterIsActive = value.length >= 1

	return (
		<FloatLabelInputWrapper
			isChallengeScreen={isChallengeScreen}
			styles={mergedStyles}
			className={className}>
			<textarea
				className="fli-input"
				{...rest}
				id={id}
				disabled={disabled}
				maxLength={maxLength}
				required
				value={value}
			/>
			<label className="fli-label" htmlFor={id}>
				{`${label} `}
			</label>
			<div className="fli-counter-wrapper">
				<CharacterCounter
					disabled={disabled}
					isActive={counterIsActive}>
					{value.length}/{maxLength}
				</CharacterCounter>
			</div>
		</FloatLabelInputWrapper>
	)
}

export default FloatLabelTextareaInput
