import styled from 'styled-components'

export const ConfirmPushModalStyles = styled.div`
	height: 70%;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;

	gap: 20px;

	padding: 30px 0px;

	.text-section {
		width: 100%;
		h3 {
			font-weight: ${(props) => props.theme.font.weight.bold};
			font-size: 10px;
			word-wrap: break-word;

			color: ${(props) => props.theme.palette.primary.main};
		}

		p {
			font-size: ${(props) =>
				props.theme.font.size.TEXT_SM.line_height}rem;
			color: #5d615d;
			word-wrap: break-word;
		}
	}

	.last-item {
		margin-bottom: 30px;
		height: 120px;
	}
`
