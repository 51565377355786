import React, { useContext, createContext, useState } from 'react'

interface UserAuthData {
	userDoc: string
	setUserDoc: React.Dispatch<React.SetStateAction<string>>
}

const UserAuthDataContext = createContext<UserAuthData>({
	userDoc: '',
	setUserDoc: () => {}
})

export const UserAuthDataProvider: React.FC = ({ children }) => {
	const [userDoc, setUserDoc] = useState('')

	return (
		<UserAuthDataContext.Provider
			value={{
				userDoc,
				setUserDoc
			}}>
			{children}
		</UserAuthDataContext.Provider>
	)
}

export const useUserAuthDataContext = () => {
	const { userDoc, setUserDoc } = useContext(UserAuthDataContext)

	return {
		userDoc,
		setUserDoc
	}
}
