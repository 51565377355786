import React from 'react'
import { WrapperHelpModal } from './sidebar-help.styles'
import ButtonDefault from '../../../../../../components/buttons/button/button-default'
import { HelpModalProps } from './siderbar-help.types'
import { SAC_TELEPHONE_NUMBER } from '../../../../../../assets/strings'

function SidebarHelp({ handleOpenChat }: HelpModalProps) {
	return (
		<WrapperHelpModal>
			<div className="main-modal">
				<h2>Fale com a gente</h2>
				<p>
					Ligue para Central de Atendimento ou acesse o nosso chat, de
					segunda a sexta, das 8h às 17h.
				</p>
				<p>
					Para mensagens fora do nosso horário de atendimento, envie
					um e-mail para:{' '}
					<a href="mailto:sac.corp@grupopetropolis.com.br">
						<strong>sac.corp@grupopetropolis.com.br</strong>
					</a>
				</p>
				<ButtonDefault
					buttonText="Chat"
					idElement="open-chat"
					onClick={() => handleOpenChat()}
				/>
				<div className="wrapper-cell">
					<a href={`tel:${SAC_TELEPHONE_NUMBER.replace(/\s/g, '')}`}>
						<p>{SAC_TELEPHONE_NUMBER}</p>
					</a>
				</div>
			</div>
		</WrapperHelpModal>
	)
}

export default SidebarHelp
