export interface IPaymentMethod {
	name: IPaymentMethodName
	operator: string
	isCheckoutTransparent?: boolean
	maxInstallments?: number
	noFeeInstallments?: number
	installmentsFee?: number
}

export interface ICreditCardInformation {
	paymentOperator: string
	cardNumber: string
	dueDate: string
	ownerName: string
	cvv: string
	cpf: string
	installmentCount: string
}

export interface IResponsePixAllPay {
	transactionId: string
	createdAt: string
	pixUrl: string
	pixCopyPasteUrl: string
	itens: IPaymentItems[]
	expireAt: string
}

export interface IPaymentItems {
	itemERPId: string
	itemName: string
	value: number
}
// export interface IResponsePixAllPay {
// 	transactionId: string
// 	createdAt: string
// 	pixUrl: string
// 	pixCopyPasteUrl: string
// 	itens: IPaymentItems[]
// 	expireAt: string
// }
export enum TransactionStatus {
	'Pending' = 'Pending',
	'WaitingForPaymentConfirmation' = 'WaitingForPaymentConfirmation',
	'Completed' = 'Completed',
	'WaitingForAccess' = 'WaitingForAccess',
	'Cancelled' = 'Cancelled',
}
export interface IResponseGetPixTransactionAllPays
	extends Omit<IResponsePixAllPay, 'expireAt'> {
	// transactionId: string
	// createdAt: Date
	// pixUrl: string
	// pixCopyPasteUrl: string
	// itens: IPaymentMethodsCreditCardItems[]
	paymentValue: number
	paymentMethodDetail: string
	operatorTransactionId: string
	numberOfInstallments: number
	paymentDate: Date
	customerCpfCnpj: string
	customerName: string
	companyCnpj: string
	transactionStatus: TransactionStatus
}

export enum IPaymentMethodName {
	'creditCard' = 'creditCard',
	'wallets' = 'wallets',
	'pix' = 'pix',
}

export type PaymentConditionsEnum = keyof typeof PaymentConditions

enum PaymentConditions {
	R001,
	R003,
	R007,
	R009,
	R010,
	R011,
	R013,
	R014,
	R018,
	R020,
	R021,
	R025,
	R026,
	R028,
	R029,
	R030,
	R032,
	R036,
	R037,
	R038,
	R040,
	R041,
	R043,
	R048,
	R050,
	R052,
	R053,
	R054,
	R055,
	R057,
	R058,
	R063,
	R068,
	R069,
	R070,
	R071,
	R072,
	R073,
	R074,
	R075,
	R077,
	R078,
	R080,
	R081,
	R082,
	R083,
	R085,
	R086,
	R087,
	R090,
	R096,
	R097,
	R215,
	R216,
	R217,
	R218,
	R219,
	R220,
	R221,
	R222,
	R226,
	R257,
	R319,
	R324,
	R325,
}

export type PaymentFormsEnum = keyof typeof PaymentForms

export enum PaymentForms {
	'BOLETO' = '5',
	A = 'A',
	G = 'G',
	H = 'H',
	L = 'L',
	S = 'S',
	U = 'U',
	X = 'X',
	P = 'P',
}

export enum PaymentType {
	ONLINE = 'ONLINE',
	DELIVERY = 'DELIVERY',
}

export type PaymentTypeEnum = keyof typeof PaymentType
export type IPaymentMethodNameEnum = keyof typeof IPaymentMethodName
