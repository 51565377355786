import { mergeAll } from 'ramda'
import React from 'react'

import Header from '../../components/Header'
import Loading from '../../components/Loading'
import ResaleParametersTable from './ResaleParametersTable'
import { IViewProps } from './types'

import ArrowIcon from '../../assets/images/arrow-left-red.svg'
import ChevronIcon from '../../assets/images/chevron-right.svg'

function ResaleParameters(props: IViewProps): JSX.Element {
	const { handleSubmit, handleGoBack, loading, resale, data, ...rest } = props

	const params = mergeAll([{ data }, rest]) as any

	return (
		<div className="resale-params-page">
			<Header />
			<div className="custom-container resale-params-table-wrapper">
				<div className="arrow-wrapper">
					<button
						className="back-arrow-wrapper row"
						onClick={handleGoBack}>
						<img
							className="back-arrow"
							src={ArrowIcon}
							alt="Voltar"
						/>
						<p>{'Voltar'}</p>
					</button>
				</div>

				<div className="location">
					<p className="location-text">{'Revendas'}</p>
					<img
						className="chevron-right-icon"
						src={ChevronIcon}
						alt="Chevron"
					/>
					<p className="location-text">{'Detalhes da Revenda'}</p>
				</div>

				<div className="detail-resource__header">
					<div className="title-wrapper">
						<div className="text-container">
							<p className="resale-title">{`Revenda ${resale.Name}`}</p>
							<p className="resale-code">{`Código #${resale.SalesOrganizationID}`}</p>
						</div>
					</div>

					<div className="red-button-container">
						{loading ? (
							<div className="loading-wrapper">
								<Loading />
							</div>
						) : (
							<button
								className="red-button"
								onClick={handleSubmit}>
								SALVAR PARÂMETROS
							</button>
						)}
					</div>
				</div>

				{loading ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : (
					<ResaleParametersTable {...params} />
				)}
			</div>
		</div>
	)
}

export default ResaleParameters
