import { assoc, clone, eqProps, findIndex, remove, update } from 'ramda'
import { Action } from 'redux'
import { createActions, createReducer } from 'reduxsauce'

import {
	IActionsFromCreators,
	ICartActionCreators
} from '../../interfaces/action'
import { ICartItem, OperationTypeDescription } from '../../interfaces/cart'
import { IStoreCart } from '../../interfaces/store'

import {
	checkConflictProductAndComboFromCartWithNewItem,
	getPrice,
	getPriceCombo
} from '../../utils/cart'

import { pipe } from '../../utils/operators'
import { getPromotionType, ItemTypeEnum } from '../../utils/promotion'
import { randomStringGenerator } from '../../utils/random-string-generator.util'

export const { Types, Creators }: IActionsFromCreators<ICartActionCreators> =
	createActions({
		addItem: ['cartItem', 'cartAction'],
		removeItem: ['cartItem'],
		updateItem: ['id', 'payload'],
		updateCart: ['carts'],
		clearCart: []
	})

const INITIAL_STATE: IStoreCart[] = []

export type CartActionEnum = keyof typeof CartAction
export enum CartAction {
	ADD = 'ADD',
	UPDATE = 'UPDATE'
}

const addItem = (
	state = INITIAL_STATE,
	action: { cartItem: ICartItem; cartAction: CartActionEnum }
): IStoreCart[] => {
	const carts = clone(state)
	const cartItem = clone(action.cartItem)
	const itemType: ItemTypeEnum = getPromotionType(cartItem?.promotionType)
	const cartAction = action.cartAction

	// Caso não exista nenhum carrinho na lista de carrinhos
	if (!carts || !carts.length) {
		const salesOrderID = randomStringGenerator() as string

		const cart: IStoreCart = {
			salesOrderID: salesOrderID,
			items: [cartItem]
		}

		return [cart]
	}

	// Caso exista algum carrinho na lista de carrinhos
	let hasAllCartsWithConflicts = true
	for (let index = 0; index < carts.length; index++) {
		const cart = carts[index]

		const updateItem = cart.items.find(
			(x) => x.MaterialID === cartItem.MaterialID
		)

		if (updateItem) {
			addOrUpdateItemInCart(itemType, cartItem, cart, 'UPDATE')
			hasAllCartsWithConflicts = false
			break
		}
		// Sem conflito
		if (!checkConflictProductAndComboFromCartWithNewItem(cart, cartItem)) {
			addOrUpdateItemInCart(itemType, cartItem, cart, cartAction)

			hasAllCartsWithConflicts = false
			break
		}
	}

	// Se todos os os carrinhos estiverem com conflito, cria um novo
	if (hasAllCartsWithConflicts) {
		// Conflito entre:
		// 1 -Produtos x Combo Adicionado
		// 2 - Combos x Combo Adicionado
		// 3 - Combo x Produto Adicionado
		// desta forma um novo carrinho é criado com o item a ser adicionado
		const salesOrderID = randomStringGenerator() as string
		const newCart: IStoreCart = {
			salesOrderID: salesOrderID,
			items: [cartItem]
		}
		carts.push(newCart)
	}

	return carts
}

const removeItem = (
	state = INITIAL_STATE,
	action: { cartItem: ICartItem }
): IStoreCart[] => {
	const cartItem = clone(action.cartItem)
	const carts = clone(state)

	const itemType: ItemTypeEnum = getPromotionType(cartItem.promotionType)
	let selectedCartIndex = -1
	let cartItemIndex = -1

	for (let index = 0; index < carts.length; index++) {
		const cart = carts[index]
		cartItemIndex = findItemIndexInCart(itemType, cartItem, cart.items)
		if (cartItemIndex >= 0) {
			selectedCartIndex = index
			break
		}
	}

	if (selectedCartIndex === -1) return state
	if (cartItemIndex === -1) return state

	const arrayOfItem = carts[selectedCartIndex].items

	remove(cartItemIndex, 1, arrayOfItem)

	if (arrayOfItem.length <= 1) {
		carts.splice(selectedCartIndex, 1)
	}

	if (arrayOfItem.length > 1) {
		arrayOfItem.splice(cartItemIndex, 1)
	}

	return carts
}

export const returnTotalValueOfItem = (item: ICartItem) => {
	const isCombo =
		item.operationTypeDescription === OperationTypeDescription.Combo

	return isCombo
		? getPriceCombo(item.PriceByQuantity, item.OrderQuantity)
		: getPrice(item.PriceByQuantity, item.OrderQuantity)
}

export const updateCarts = (state = INITIAL_STATE, action: any): IStoreCart[] =>
	action.carts

const clear = (_ = INITIAL_STATE, __: any): IStoreCart[] => INITIAL_STATE

export default createReducer<IStoreCart[], Action<ICartActionCreators>>(
	INITIAL_STATE,
	{
		[Types.ADD_ITEM]: addItem,
		[Types.REMOVE_ITEM]: removeItem,
		[Types.UPDATE_ITEM]: addItem,
		[Types.UPDATE_CART]: updateCarts,
		[Types.CLEAR_CART]: clear
	}
)

function findItemIndexInCart(
	itemType: ItemTypeEnum,
	cartItem: ICartItem,
	items: ICartItem[]
): number {
	const propToCompare = itemType === 'COMBO' ? 'promotionID' : 'MaterialID'
	const compareWithCartItem = eqProps(propToCompare, cartItem)

	return findIndex(compareWithCartItem)(items)
}

function addOrUpdateItemInCart(
	itemType: ItemTypeEnum,
	cartItem: ICartItem,
	cart: IStoreCart,
	cartAction: string
) {
	const cartItemIndex = findItemIndexInCart(itemType, cartItem, cart.items)
	// Produto não existe no carrinho atual, logo é um novo produto no carrinho
	if (cartItemIndex === -1) {
		cart.items.push(cartItem)
	} else {
		// Produto existe no carrinho atual, logo seus valoes são atualizados
		const cartItemByindex = clone(cart.items[cartItemIndex])
		const newOrderQuantity =
			cartAction === 'ADD'
				? cartItemByindex.OrderQuantity + cartItem.OrderQuantity
				: cartItem.OrderQuantity

		const newPrice =
			cartAction === 'ADD'
				? cartItemByindex.Price + cartItem.Price
				: returnTotalValueOfItem(cartItem)

		const newOlderPrice =
			cartAction === 'ADD'
				? Number(cartItemByindex?.olderPrice) +
				Number(cartItem?.olderPrice)
				: cartItem.olderPrice
		const associateNewValues = pipe(
			assoc('Price', newPrice),
			assoc('OrderQuantity', newOrderQuantity),
			assoc('olderPrice', newOlderPrice)
		)
		const cartItemUpdated = associateNewValues(cartItemByindex) as unknown as ICartItem

		cart.items = update<ICartItem>(cartItemIndex, cartItemUpdated, cart.items)
	}
}
