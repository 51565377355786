import styled from 'styled-components'

export const WrapperAction = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: ${(props) => props.theme.spacingOptions.LG}px;
	flex-direction: column;
	overflow-y: scroll;

	height: 80%;

	img {
		width: 52px;
		height: 52px;
	}
`
