import React from 'react'
import { TextWrapper, WrapperContent } from './text-area.styles'
import { ITextArea } from './text-area.types'

const TextArea = ({ text, handleChange, label, limitCaracters }: ITextArea) => {
	return (
		<WrapperContent>
			<TextWrapper
				id="filled-multiline-static"
				label={label}
				multiline
				minRows={4}
				value={text}
				onChange={(event) => handleChange(event)}
				variant="outlined"
				inputProps={{
					maxLength: limitCaracters
				}}
			/>
			<p>
				{text.length}/{limitCaracters}
			</p>
		</WrapperContent>
	)
}

export default TextArea
