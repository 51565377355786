import React from 'react'
import * as S from './cell-table-delivery.styles'
import { CellTableDelivery } from './cell-table-delivery.types'

function CellTableDelivey({ type, isSsunday = false }: CellTableDelivery) {
	return (
		<S.WrapperCellTalbe>
			{isSsunday && <p>Período Comum</p>}
			<S.StyledCellTableDelivery type={type}>
				<p>{type === 'available' ? 'Disponível' : 'Indisponível'}</p>
			</S.StyledCellTableDelivery>
		</S.WrapperCellTalbe>
	)
}

export default CellTableDelivey
