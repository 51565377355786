import styled from 'styled-components'

export const Container = styled.div`
	align-items: center;
	background: ${(props) => props.theme.palette.primaryWhite.main};
	border-radius: 4px;
	border: 1px solid ${(props) => props.theme.palette.lightGray.main};
	display: flex;
	flex-direction: column;
	margin-top: 8px;
	padding: 32px 16px;
	text-align: center;

	p.card-title {
		color: ${(props) => props.theme.palette.secondary.main};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		letter-spacing: 0.2px;
		margin-bottom: 8px;
	}

	p.card-text {
		color: ${(props) => props.theme.palette.primary.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		letter-spacing: 0.2px;
		margin-bottom: 24px;

		span {
			color: ${(props) => props.theme.palette.success.main};
		}
	}

	.card-link-button {
		border: none;
		background: none;
		text-decoration: underline;
		color: ${(props) => props.theme.palette.success.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		letter-spacing: 0.2px;
		cursor: pointer;

		&:hover {
			cursor: pointer;
		}
	}
`
