import { axiosGPInstance } from '../../../shared/services/axiosInstace'
import { IProcessStatusDetails } from './types'

export async function getProcessStatusDetails(id?: number): Promise<IProcessStatusDetails[]> {
	try {
		const response = await axiosGPInstance.get(
			`/signup-tracking/customer/MassProcessLogDetail/details?id=${id}`)
		return response.data
	} catch (error) {
		return []
	}
}
