import { QueryClient } from 'react-query'
import handleError from '../modules/Error/handleError'
import { IError } from '../modules/Error/types'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: true,
			refetchOnMount: true,
			refetchOnReconnect: true,
			refetchIntervalInBackground: false,
			onError: (error) => {
				handleError(error as IError)
			}
		}
	}
})
