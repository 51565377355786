import { createElement, useCallback, useEffect, useState } from 'react'
import { IViewProps } from './types'
import ChangeCellphoneView from './view'
import { useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import handleError from '../Error/handleError'
import { checkTel } from '../../shared/utils/validators'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

export default function ChangeCellphoneContainer(): JSX.Element {
	const history = useHistory()

	const [cellphone, setCellphone] = useState('')
	const [cellphoneConfirmation, setCellphoneConfirmation] = useState('')
	const [buttonActive, setButtonActive] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	function handleBack() {
		history.replace(CustomerRoutesEnum.MY_ACCOUNT)
	}

	function clearPhoneNumber(value: string) {
		return value.replace(/[-/./(/)/ ]/g, '')
	}

	const handleChangeCellphone = useCallback((value: string) => {
		if (value.length > 15 || value[0] === '0') return
		const cleanedPhoneNumber = clearPhoneNumber(value)
		setCellphone(cleanedPhoneNumber)
	}, [])

	const handleChangeCellphoneConfirmation = useCallback((value: string) => {
		if (value.length > 15 || value[0] === '0') return
		const cleanedPhoneNumber = clearPhoneNumber(value)
		setCellphoneConfirmation(cleanedPhoneNumber)
	}, [])

	async function handleSubmit() {
		try {
			setIsLoading(true)
			const userCognito = await Auth.currentAuthenticatedUser()

			await Auth.updateUserAttributes(userCognito, {
				phone_number: '+55' + cellphone
			})

			history.replace(CustomerRoutesEnum.CONFIRM_EDIT_CELLPHONE_CODE, {
				cellphone
			})
		} catch (err) {
			handleError(err as any)
		} finally {
			setIsLoading(false)
		}
	}

	function validateFields() {
		setButtonActive(
			!!checkTel(cellphone) &&
				!!checkTel(cellphoneConfirmation) &&
				cellphoneConfirmation === cellphone
		)
	}

	useEffect(validateFields, [cellphone, cellphoneConfirmation])

	const viewProps: IViewProps = {
		handleBack,
		handleChangeCellphone,
		handleChangeCellphoneConfirmation,
		cellphone,
		cellphoneConfirmation,
		handleSubmit,
		buttonActive,
		isLoading
	}

	return createElement(ChangeCellphoneView, viewProps)
}
