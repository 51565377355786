import { AxiosResponse } from 'axios'

import { IStoreOrderHistory } from '../../shared/interfaces/store'
import axiosInstance from '../../shared/services/axiosInstace'
import { IGetOrderHistory } from './types'

export async function getOrderHistory({
	salesOrganizationID,
	customerID
}: IGetOrderHistory): Promise<IStoreOrderHistory | null> {
	try {
		const response: AxiosResponse = await axiosInstance.get(
			`/salesorder-tracking/?salesOrganizationID=${salesOrganizationID}&customerID=${customerID}`
		)

		return response.data
	} catch (error) {
		return null
	}
}
