import { IFilterSalesHierarchyDirector } from '../../shared/interfaces/pointsProgramChallenge'
import { removeDuplicates } from '../../utils/remove-duplicates'
import { IFilterOptions, IOption } from './typings'

export function convertValueToSelectOption(value: string | number) {
	return {
		value: value?.toString(),
		label: value?.toString()
	}
}

export const options = (options: IOption[]) => {
	return [
		{
			label: 'Todos',
			options: options
		}
	]
}
export function getAllDirectorOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		acc.push(convertValueToSelectOption(director.director))
		return acc
	}, [])
}

export function getAllGovOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	const govs = salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			if (!gov.gov) continue
			acc.push(convertValueToSelectOption(gov.gov))
		}
		return acc
	}, [])

	return removeDuplicates(govs, 'label')
}

export function getAllResaleOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			for (const resale of gov.resales) {
				acc.push(convertValueToSelectOption(resale.resale))
			}
		}
		return acc
	}, [])
}

export function getAllStateOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	const states = salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			for (const resales of gov.resales) {
				for (const states of resales.states) {
					if (states.state)
						acc.push(convertValueToSelectOption(states.state))
				}
			}
		}
		return acc
	}, [])
	const statesFormatted = removeDuplicates(states, 'label')
	return statesFormatted
}

export function getAllCitiesOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): IOption[] {
	const cities = salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			for (const resales of gov.resales) {
				for (const states of resales.states) {
					for (const state of states.cities) {
						if (state.city)
							acc.push(convertValueToSelectOption(state.city))
					}
				}
			}
		}
		return acc
	}, [])
	const citiesFormatted = removeDuplicates(cities, 'label')
	return citiesFormatted
}

export function getResaleOptionsBySelectedGovs(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedGovOptions: IOption[]
): IOption[] {
	return salesHierarchy.reduce((acc: IOption[], director) => {
		for (const gov of director.govs) {
			if (selectedGovOptions.find((option) => option.value === gov.gov)) {
				for (const { resale } of gov.resales) {
					acc.push(convertValueToSelectOption(resale))
				}
			}
		}
		return acc
	}, [])
}

export function getSalesHierarchyOptions(
	salesHierarchy: IFilterSalesHierarchyDirector[]
): Omit<Pick<IFilterOptions, keyof IFilterOptions>, 'CHANNEL'> {
	const directorOptions = getAllDirectorOptions(salesHierarchy)
	const govOptions = getAllGovOptions(salesHierarchy)
	const resaleOptions = getAllResaleOptions(salesHierarchy)
	const statesOptions = getAllStateOptions(salesHierarchy)
	const citiesOptions = getAllCitiesOptions(salesHierarchy)
	return {
		DIRECTOR: directorOptions,
		GOV: govOptions,
		RESALE: resaleOptions,
		STATE: statesOptions,
		CITY: citiesOptions
	}
}

export function filterGrc(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedDirector: IOption[]
) {
	const directorHash: Record<string, boolean> = {}

	const grc: IOption[] = []

	for (const option of selectedDirector) {
		directorHash[option.value] = true
	}

	for (const sales of salesHierarchy) {
		for (const gov of sales.govs) {
			if (directorHash[sales.director]) {
				grc.push({ label: gov.gov, value: gov.gov })
			}
		}
	}

	const grcFiltered = grc.filter((state) => !!state.value)
	const grcFormatted = removeDuplicates(grcFiltered, 'label')
	return grcFormatted
}

export function filterSelectedDirectors(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedOptions: IOption[]
) {
	return salesHierarchy.filter((director) => {
		return !!selectedOptions.find(
			(option) => option.value === director.director
		)
	})
}

export function filterSelectedGovs(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedOptions: IOption[]
) {
	const govs = salesHierarchy.filter((director) => {
		return director.govs.some((gov) => {
			return !!selectedOptions.find((option) => option.value === gov.gov)
		})
	})
	return govs
}

export function filterCdsBySelectedGovs(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedGovs: IOption[]
): IOption[] {
	const govHash: Record<string, boolean> = {}

	const cds: IOption[] = []

	for (const option of selectedGovs) {
		govHash[option.value] = true
	}

	for (const sales of salesHierarchy) {
		for (const gov of sales.govs) {
			for (const { resale } of gov.resales) {
				if (govHash[gov.gov]) {
					cds.push({ label: resale, value: resale })
				}
			}
		}
	}
	const cdsFiltered = cds.filter((cd) => !!cd.value)
	const cdsFormatted = removeDuplicates(cdsFiltered, 'label')
	return cdsFormatted
}

export function filterSelectedResales(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedOptions: IOption[]
): IOption[] {
	const hash: Record<string, boolean> = {}

	const res: IOption[] = []

	for (const option of selectedOptions) {
		hash[option.value] = true
	}

	for (const sales of salesHierarchy) {
		for (const gov of sales.govs) {
			for (const { resale, states } of gov.resales) {
				for (const { state } of states) {
					if (hash[resale]) {
						res.push({ label: state, value: state })
					}
				}
			}
		}
	}
	const statesFiltered = res.filter((state) => !!state.value)
	const statesFormatted = removeDuplicates(statesFiltered, 'label')
	return statesFormatted
}

export function filterCitiesSelected(
	salesHierarchy: IFilterSalesHierarchyDirector[],
	selectedStates: IOption[],
	selectedResales: IOption[] = []
): IOption[] {
	const resalesHash: Record<string, boolean> = {}
	const statesHash: Record<string, boolean> = {}

	const resCities: IOption[] = []

	for (const resale of selectedResales) {
		resalesHash[resale.value] = true
	}

	for (const state of selectedStates) {
		statesHash[state.value] = true
	}

	const filterByResalesAndState = selectedResales.length > 0

	for (const sales of salesHierarchy) {
		for (const gov of sales.govs) {
			for (const { states, resale } of gov.resales) {
				for (const { state, cities } of states) {
					for (const { city } of cities) {
						const shouldAddCity =
							(!filterByResalesAndState && statesHash[state]) ||
							(statesHash[state] && resalesHash[resale])

						if (shouldAddCity) {
							resCities.push({ label: city, value: city })
						}
					}
				}
			}
		}
	}

	const citiesFiltered = resCities.filter((city) => !!city.value)
	const citiesFormatted = removeDuplicates(citiesFiltered, 'label')

	return citiesFormatted
}
