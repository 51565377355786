import { createElement, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { IMaterial } from '../../shared/interfaces/material'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import {
	ChallengeRuleTypeEnum,
	ICartChallenge,
	IChallenge,
	IChallengeProduct
} from '../../shared/interfaces/pointsProgramChallenge'

import { Creators as CartChallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'

import {
	settingTheProductContainerType,
	settingTheUnitPriceOfTheProducts
} from '../../shared/utils/material'

import { IViewProps } from './type'
import ChallengeDetailsView from './view'
import { getMaterials } from '../ProductList/service'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

import { extractCartItem } from '../../shared/utils/cart'
import {
	calcTotalAmountAchievedWithPurchasedItems,
	calcTotalAmountReachedWithItemsInCart,
	calcTotalAmountReachedWithItemsInCartForMixingChallenges,
	calcTotalQuantity
} from '../../shared/utils/challengesUtils'
import cogoToast from 'cogo-toast'
import { CogoPositions } from '../../shared/utils/toaster'
import { ICartItem, ICartItemTypeEnum } from '../../shared/interfaces/cart'

export default function ChallengeDetails() {
	const dispatch = useDispatch()
	const history = useHistory()

	const { customer, cartChallengeItems, challengesTheUserIsParticipatingIn } =
		useTypedSelector([
			'customer',
			'cartChallengeItems',
			'challengesTheUserIsParticipatingIn'
		])

	const { state } = useLocation<{ challenge: IChallenge }>()
	const challengeByProps = state.challenge

	const [quantityReached, setQuantityReached] = useState(0)
	const [remainingProducts, setRemainingProducts] = useState(0)

	const [
		totalProductsToCompleteTheChallenge,
		setTotalProductsToCompleteTheChallenge
	] = useState(0)

	const [
		isFetchingProductsOfTheChallenge,
		setIsFetchingProductsOfTheChallenge
	] = useState(false)

	const [
		productsAvailableToTheUserWhoAreParticipatingInTheChallenge,
		setProductsAvailableToTheUserWhoAreParticipatingInTheChallenge
	] = useState<IMaterial[]>([])

	const [
		showModalWithChallengeMembershipTerms,
		setShowModalWithChallengeMembershipTerms
	] = useState(false)

	const [theUserCantContinueThePurchase, setTheUserCantContinueThePurchase] =
		useState(false)

	const [allProductsThatArePartOfTheMix, setAllProductsThatArePartOfTheMix] =
		useState<Array<{ productId: string; quantity: number }>>([])

	const [challengeFoundedInTheCart, setChallengeFoundedInTheCart] =
		useState<ICartChallenge>()

	const [currentChallenge, setCurrentChallenge] =
		useState<IChallenge>(challengeByProps)

	const updatingCurrentChallengeInfo = () => {
		const currentChallengeWasFoundInAcceptedChallenges =
			challengesTheUserIsParticipatingIn.find(
				(item) => challengeByProps.challengeId === item.challengeID
			)

		if (currentChallengeWasFoundInAcceptedChallenges) {
			setCurrentChallenge((prevState) => ({
				...prevState,
				userIsAlreadyParticipatingInTheChallenge: true
			}))
		}
	}

	const handleGoBack = () => {
		history.replace(CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGES)
	}

	const settingChallengeFoundInCart = () => {
		const challengeFoundedInTheCart = cartChallengeItems.find(
			(cartChallenge) =>
				cartChallenge.challengeId === currentChallenge.challengeId
		)
		setChallengeFoundedInTheCart(challengeFoundedInTheCart)
	}

	const handleShowModalWithChallengeMembershipTerms = () => {
		setShowModalWithChallengeMembershipTerms(
			!showModalWithChallengeMembershipTerms
		)
	}

	const lookingForProductsParticipatingInTheChallenge = () => {
		;(async () => {
			try {
				setIsFetchingProductsOfTheChallenge(true)
				const productList = await getMaterials({
					customerID: customer.CustomerID,
					salesOrganizationID: customer.SalesOrganizationID,
					segmentID: customer.SegmentID,
					channelGroupID: customer.ChannelGroupID,
					sectorID: customer.sectorID,
					morePoints: false,
					includesDraftBeer: customer.draftBeer
				})

				if (productList && !productList.length) return

				const productsOfTheChallenge = productList?.filter(
					(product) => {
						return !!currentChallenge.products?.find(
							(item) => item.productId === product.MaterialID
						)
					}
				)

				const productsWithTheMinimumQuantityToCompleteTheChallenge =
					productsOfTheChallenge?.map((item) => {
						const productInTheChallenge =
							currentChallenge.products?.find(
								(product) =>
									product.productId === item.MaterialID
							)
						return {
							...item,
							minimumAmountToCompleteAChallenge: Number(
								productInTheChallenge?.quantity
							)
						}
					})

				if (
					productsWithTheMinimumQuantityToCompleteTheChallenge?.length
				) {
					const productListWithUnitPrices =
						settingTheUnitPriceOfTheProducts(
							productsWithTheMinimumQuantityToCompleteTheChallenge
						)

					const productListWithContainerType =
						settingTheProductContainerType(
							productListWithUnitPrices
						)

					setProductsAvailableToTheUserWhoAreParticipatingInTheChallenge(
						productListWithContainerType
					)
				}
			} finally {
				setIsFetchingProductsOfTheChallenge(false)
			}
		})()
	}

	const addingItemToTheProductMix = (item: IMaterial, quantity: number) => {
		if (item && quantity) {
			const itemWithQuantity = extractCartItem(item, quantity)

			if (
				itemWithQuantity.OrderQuantity > itemWithQuantity.StockPosition
			) {
				cogoToast.error(
					`Quantidade acima do estoque de ${itemWithQuantity.StockPosition}`,
					CogoPositions['top-right']
				)
				return
			}

			setAllProductsThatArePartOfTheMix((prevState) => {
				let productsAdded: Array<{
					productId: string
					quantity: number
				}> = [...prevState]
				const productAlreadyAddedIndex = prevState.findIndex(
					(product) =>
						product.productId === itemWithQuantity.MaterialID
				)

				if (productAlreadyAddedIndex !== -1) {
					const product = productsAdded[productAlreadyAddedIndex]
					const newQuantity = product.quantity + quantity
					const productUpdated = { ...product, quantity: newQuantity }
					productsAdded.splice(
						productAlreadyAddedIndex,
						1,
						productUpdated
					)
				} else {
					productsAdded.push({
						productId: String(itemWithQuantity.MaterialID),
						quantity
					})
				}
				return productsAdded
			})
		}
	}

	const checkingRemainingAmountToReachChallengeTotal = (
		totalQuantity: number,
		products: IChallengeProduct[]
	) => {
		const achievedAmountOfPurchasedItems =
			calcTotalAmountAchievedWithPurchasedItems(products)

		const quantityOfProductsLeftToBuy =
			totalQuantity - achievedAmountOfPurchasedItems

		if (!challengeFoundedInTheCart) {
			setQuantityReached(achievedAmountOfPurchasedItems)
			setRemainingProducts(quantityOfProductsLeftToBuy)
			setTotalProductsToCompleteTheChallenge(totalQuantity)
			return
		}

		const { addedProducts } = challengeFoundedInTheCart

		const quantityReachedWithItemsInTheCart =
			calcTotalAmountReachedWithItemsInCart(addedProducts, products)

		const totalItemsLeft =
			quantityOfProductsLeftToBuy >= quantityReachedWithItemsInTheCart
				? quantityOfProductsLeftToBuy -
				  quantityReachedWithItemsInTheCart
				: 0

		const amountAlreadyReached =
			achievedAmountOfPurchasedItems + quantityReachedWithItemsInTheCart <
			totalQuantity
				? achievedAmountOfPurchasedItems +
				  quantityReachedWithItemsInTheCart
				: totalQuantity

		setRemainingProducts(totalItemsLeft)
		setQuantityReached(amountAlreadyReached)
		setTotalProductsToCompleteTheChallenge(totalQuantity)
	}

	const checkingRemainingQuantityToAchieveTotalProductMix = (
		totalQuantity: number,
		products: IChallengeProduct[]
	) => {
		if (challengeFoundedInTheCart) {
			checkingRemainingAmountToReachChallengeTotal(
				totalQuantity,
				products
			)
		} else {
			const amountOfMixAdded =
				calcTotalAmountReachedWithItemsInCartForMixingChallenges(
					products,
					allProductsThatArePartOfTheMix
				)

			const quantityProductsToAdd = totalQuantity - amountOfMixAdded

			setRemainingProducts(quantityProductsToAdd)
			setQuantityReached(amountOfMixAdded)
			setTotalProductsToCompleteTheChallenge(totalQuantity)
		}
	}

	const checkingTheAmountOfProductsLeftToCompleteTheChallenge = () => {
		if (!currentChallenge.products?.length) return
		const { products } = currentChallenge
		const totalQuantity = calcTotalQuantity(products)

		if (currentChallenge.typeRule === ChallengeRuleTypeEnum.PARTIAL) {
			checkingRemainingAmountToReachChallengeTotal(
				totalQuantity,
				products
			)
		} else if (
			currentChallenge.typeRule === ChallengeRuleTypeEnum.PRODUCT_MIX
		) {
			checkingRemainingQuantityToAchieveTotalProductMix(
				totalQuantity,
				products
			)
		}
	}

	const checkingIfTheUserCanProceedWithThePurchaseOfTheChallengeItems =
		() => {
			if (
				currentChallenge.typeRule ===
					ChallengeRuleTypeEnum.PRODUCT_MIX &&
				quantityReached < totalProductsToCompleteTheChallenge
			) {
				setTheUserCantContinueThePurchase(true)
			} else if (
				currentChallenge.typeRule === ChallengeRuleTypeEnum.PARTIAL &&
				!challengeFoundedInTheCart
			) {
				setTheUserCantContinueThePurchase(true)
			} else {
				setTheUserCantContinueThePurchase(false)
			}
		}

	const continueBuyingTheProductMixInItsEntirety = () => {
		if (
			allProductsThatArePartOfTheMix.length &&
			!theUserCantContinueThePurchase &&
			productsAvailableToTheUserWhoAreParticipatingInTheChallenge.length &&
			!challengeFoundedInTheCart
		) {
			const materialsOfTheChallenge: IMaterial[] = []

			allProductsThatArePartOfTheMix.forEach((product) => {
				productsAvailableToTheUserWhoAreParticipatingInTheChallenge.forEach(
					(material) => {
						if (product.productId === material.MaterialID) {
							materialsOfTheChallenge.push(material)
							return
						}
					}
				)
			})

			const addedProductsFormatted: ICartItem[] =
				materialsOfTheChallenge.map((item) => {
					const productQuantity = allProductsThatArePartOfTheMix.find(
						(product) => item.MaterialID === product.productId
					)?.quantity
					return {
						...extractCartItem(item, Number(productQuantity)),
						typeItem: ICartItemTypeEnum.CHALLENGE_ITEM
					}
				})

			if (addedProductsFormatted.length) {
				dispatch(
					CartChallengeItemsActions.addChallengeItemMixOfProducts(
						currentChallenge,
						addedProductsFormatted
					)
				)
				history.push(CustomerRoutesEnum.CART)
			}
		} else {
			history.push(CustomerRoutesEnum.CART)
		}
	}

	const goToCartScreen = () => history.push(CustomerRoutesEnum.CART)

	useEffect(updatingCurrentChallengeInfo, [
		challengeByProps,
		challengesTheUserIsParticipatingIn,
		showModalWithChallengeMembershipTerms
	])

	useEffect(settingChallengeFoundInCart, [
		cartChallengeItems,
		currentChallenge
	])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(checkingTheAmountOfProductsLeftToCompleteTheChallenge, [
		currentChallenge,
		cartChallengeItems,
		challengeFoundedInTheCart,
		allProductsThatArePartOfTheMix
	])

	useEffect(lookingForProductsParticipatingInTheChallenge, [
		customer,
		currentChallenge
	])

	useEffect(checkingIfTheUserCanProceedWithThePurchaseOfTheChallengeItems, [
		currentChallenge,
		quantityReached,
		challengeFoundedInTheCart,
		allProductsThatArePartOfTheMix,
		totalProductsToCompleteTheChallenge
	])

	const viewProps: IViewProps = {
		handleGoBack,
		goToCartScreen,
		quantityReached,
		remainingProducts,
		addingItemToTheProductMix,
		challenge: currentChallenge,
		theUserCantContinueThePurchase,
		allProductsThatArePartOfTheMix,
		isFetchingProductsOfTheChallenge,
		totalProductsToCompleteTheChallenge,
		showModalWithChallengeMembershipTerms,
		continueBuyingTheProductMixInItsEntirety,
		handleShowModalWithChallengeMembershipTerms,
		productsOfTheChallenge:
			productsAvailableToTheUserWhoAreParticipatingInTheChallenge
	}

	return createElement(ChallengeDetailsView, viewProps)
}
