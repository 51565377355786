import React, {
	createElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'

import { useBoolean } from '../../../shared/hooks/useBoolean'
import { parseFormatOnlyDate } from '../../../shared/utils/date'
import {
	formatCellphone,
	formatCnpjCpf,
	formatPostalCode,
	formatRG
} from '../../../shared/utils/form'
import { sortDate, sortStatus } from '../../../shared/utils/sorts'
import { DEFAULT_USER } from './data'
import {
	getUsersByFilter,
	updateSentToSac,
	updateSignUpStatus
} from './service'
import {
	DetailSignUpStatusConfirmationEnum,
	IUser,
	IViewProps,
	statusSACDescriptionEnum,
	ViewStateEnum,
	ITableData,
	ITableFilterParams,
	ClientStatus
} from './types'
import PendingApproval from './view'
import { include } from '../../../shared/utils/boolean'
import FileTextIcon from '../../../assets/images/file-text.svg'
import { useTypedSelector } from '../../../shared/hooks/useTypedSelector'
import { UserTypeEnum } from '../../../shared/interfaces/customer'
import { sortBool } from './util'

// Caso um novo indice seja adicionado, deve se atualizar a função sortStatus

const clientStatus: ClientStatus = {
	PENDENTE: {
		class: 'pending',
		text: 'Pendente'
	},
	RECUSADO: {
		class: 'refused',
		text: 'Recusado'
	},
	APROVADO: {
		class: 'approved',
		text: 'Aprovado'
	},
	INFORMATIVO: {
		class: 'informative',
		text: 'Informativo'
	}
}

function PendingApprovalContainer(): JSX.Element {
	const { customer } = useTypedSelector(['customer'])

	const [viewState, setViewState] = useState<ViewStateEnum>('LIST')
	const [targetUser, setTargetUser] = useState(DEFAULT_USER)
	const [isUpdatingTargetUser, setIsUpdatingTargetUser] = useState(false)

	const [isModalOpen, openModal, closeModal] = useBoolean(false)
	const [isApprove, approveSignUp, refuseSignUp] = useBoolean(false)
	const [isLoading, setIsLoading] = useState(false)

	const [tableData, setTableData] = useState<ITableData[]>([])
	const [filteredTableData, setFilteredTableData] = useState<ITableData[]>([])

	const [tableFilter, setTableFilter] = useState<ITableFilterParams>({
		endDate: undefined,
		initialDate: undefined,
		statusSACDescription: undefined,
		SignUpStatusConfirmation: undefined
	})

	function renderReason(status: DetailSignUpStatusConfirmationEnum) {
		return status
	}

	const columns = useMemo(
		() => [
			{
				Header: `ENVIADO AO SAC`,
				accessor: 'renderSentToSac',
				sortType: sortBool()
			},
			{
				Header: 'Aprovação',
				accessor: 'status',
				sortType: sortStatus('status')
			},
			{
				Header: 'Motivo do bloqueio',
				accessor: 'blockReason',
				sortType: 'basic'
			},
			{
				Header: 'Data do Registro',
				accessor: 'createdAt',
				sortType: sortDate('createdAt')
			},
			{
				Header: 'Revenda',
				accessor: 'salesOrganization',
				sortType: 'basic'
			},
			{
				Header: 'Nome do Cliente',
				accessor: 'name',
				sortType: 'basic'
			},
			{
				Header: 'Telefone Celular',
				accessor: 'cellphone',
				sortType: 'alphanumeric'
			},
			{ Header: '', accessor: 'button', disableSortBy: true }
		],

		[]
	)

	function handleBack() {
		setViewState('LIST')
		setTargetUser(DEFAULT_USER)
	}

	function renderBadge(
		status: statusSACDescriptionEnum,
		updatedAt: string | null
	) {
		if (!clientStatus[status]) return null

		const date = updatedAt || ''

		const [dayMonthYear, hoursMinutes] = date.split(' ')

		const formatedDay = dayMonthYear?.replace(/-/g, '/')

		const formatedHour = hoursMinutes?.slice(0, 5)

		const formatedDate = `${formatedDay} - ${formatedHour}`

		return (
			<div className="badge-wrapper" id={clientStatus[status].text}>
				<span className={`badge-status ${clientStatus[status].class}`}>
					{clientStatus[status].text}
				</span>
				{date !== '' ? (
					<span className={`date ${clientStatus[status].class}`}>
						{formatedDate}
					</span>
				) : null}
			</div>
		)
	}

	function handleUserDetail(user: IUser) {
		setViewState('USER')
		setTargetUser(user)
	}

	function renderButton(user: IUser) {
		return (
			<button className="edit" onClick={() => handleUserDetail(user)}>
				<img className="edit-img" src={FileTextIcon} alt="Editar" />
			</button>
		)
	}

	const formatDataTable = (users: IUser[]) => {
		const usersParsed: IUser[] = users.map((user) => ({
			...user,
			PersonalFiscalID: user.PersonalFiscalID
				? formatCnpjCpf(user.PersonalFiscalID)
				: '',
			CompanyFiscalID: user.CompanyFiscalID
				? formatCnpjCpf(user.CompanyFiscalID)
				: '',
			PostalCode: formatPostalCode(
				user.PostalCode ? user.PostalCode : ''
			),
			IdentificationRegistryID: formatRG(
				user.IdentificationRegistryID
					? user.IdentificationRegistryID
					: ''
			),
			SignupDate: user.SignupDate
				? parseFormatOnlyDate(user.SignupDate)
				: '',
			CellphoneNumber: user.CellphoneNumber
				? formatCellphone(user.CellphoneNumber)
				: user.CellphoneNumber
		}))

		const tableData: ITableData[] = usersParsed?.map((user) => ({
			renderSentToSac: renderSendToSacButton(user),
			status: renderBadge(user.statusSACDescription, user.UpdatedAt),
			blockReason: renderReason(user.DetailSignUpStatusConfirmation),
			createdAt: user.SignupDate,
			salesOrganization: user.SalesOrganizationDescription,
			name: user.Name,
			cellphone: user.CellphoneNumber,
			button: renderButton(user),
			ID: user.SignupTrackingID
		}))
		return tableData
	}

	async function handleChangeSentSacState(user: IUser) {
		if (!user) {
			const newSentToSac = !targetUser.sentToSac

			setIsUpdatingTargetUser(true)
			try {
				const updated = await updateSentToSac(
					targetUser.SignupTrackingID,
					newSentToSac
				)

				if (updated === undefined) return

				setTargetUser((prevTargetUser) => ({
					...prevTargetUser,
					sentToSac: newSentToSac
				}))

				fetchUsersList(tableFilter)
			} catch (error) {
				console.error(error)
			} finally {
				setIsUpdatingTargetUser(false)
			}
		}
	}

	function renderSendToSacButton(user: IUser) {
		const { statusSACDescription, sentToSac } = user
		if (statusSACDescription === 'INFORMATIVO') {
			return (
				<div
					id={`sent-to-sac-${sentToSac}`}
					onClick={() => handleChangeSentSacState(user)}
					className="badge-wrapper">
					<span
						className={`badge-status ${
							sentToSac ? 'sent-to-sac' : 'not-sent-to-sac'
						}`}>
						{sentToSac ? 'ENVIADO' : 'NÃO ENVIADO'}
					</span>
				</div>
			)
		}
		return null
	}

	async function fetchUsersList(payloadFilter?: ITableFilterParams) {
		setIsLoading(true)
		try {
			let users: IUser[] = []
			if (customer.Type === UserTypeEnum.UNIDADE) {
				const companies = {
					companies: [
						{
							salesOrganizationID:
								customer.activeSalesOrganizationID
						}
					]
				}
				users = await getUsersByFilter(payloadFilter, companies)
			} else {
				users = await getUsersByFilter(payloadFilter)
			}
			const data = formatDataTable(users)

			setTableData(data)
			setFilteredTableData(data)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	function handleModal(approve: boolean) {
		approve ? approveSignUp() : refuseSignUp()
		openModal()
	}

	async function updateStatus() {
		setIsLoading(true)
		const id = targetUser.SignupTrackingID
		const status = isApprove ? 'APROVADO' : 'RECUSADO'
		try {
			await updateSignUpStatus(id, status)
			setIsLoading(false)
			closeModal()
			fetchUsersList({ clearFilter: true })
			setTargetUser({
				...targetUser,
				statusSACDescription: status
			})
		} catch (error) {
			console.error({ error })
			setIsLoading(false)
		}
	}

	function handleFilterOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = tableData.filter((item) => {
			return (
				include(item.blockReason, value) ||
				include(item.cellphone, value) ||
				include(item.createdAt, value) ||
				include(item.name, value) ||
				include(item.salesOrganization, value) ||
				include(item.status?.props.id, value)
			)
		})

		setFilteredTableData(list)
	}

	useEffect(() => {
		function someKeyFilled(tableFilterParams: ITableFilterParams) {
			const keyFilterParams = Object.keys(tableFilterParams)

			for (const keyObj of keyFilterParams) {
				const valueObj =
					tableFilterParams[keyObj as keyof ITableFilterParams]
				if (
					valueObj !== undefined &&
					valueObj !== null &&
					(typeof valueObj !== 'string' || valueObj.trim() !== '')
				) {
					return true
				}
			}
			return false
		}

		if (someKeyFilled(tableFilter)) {
			fetchUsersList(tableFilter)
		} else {
			fetchUsersList({ clearFilter: true })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customer.activeSalesOrganizationID, tableFilter])

	const onTableFilterChange = useCallback((payload: ITableFilterParams) => {
		setTableFilter(payload)
	}, [])

	const viewProps: IViewProps = {
		targetUser,
		viewState,
		handleBack,
		isModalOpen,
		closeModal,
		handleModal,
		isApprove,
		updateStatus,
		isLoading,
		columns,
		tableData: filteredTableData,
		handleFilterOnChange,
		onTableFilterChange,
		handleChangeSentSacState,
		isUpdatingTargetUser
	}

	return createElement(PendingApproval, viewProps)
}

export default PendingApprovalContainer
