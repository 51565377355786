import styled from 'styled-components'
import { Box } from '@mui/material'

export const HeaderWrapper = styled(Box)`
	background-color: ${(props) => props.theme.palette.background.paper};
	height: 112px;

	display: flex;
	align-items: center;
	justify-content: space-between;

	box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.1);

	padding: 0px 72px;

	position: relative;
	z-index: 100;

	.logo-wapper {
		display: flex;
		align-items: center;
		justify-content: center;

		gap: 50px;

		.help-text {
			font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
			line-height: ${(props) =>
				props.theme.font.size.TEXT_MD.line_height}rem;
			font-weight: ${(props) => props.theme.font.weight.bold};

			color: ${(props) => props.theme.palette.black.main};
			background-color: ${(props) => props.theme.palette.background.paper};;
			border: none;

			&:hover{
				cursor: pointer;
			}
		}
	}

	.button-login {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_MD.line_height}rem;
		font-weight: ${(props) => props.theme.font.weight.bold};

		color: ${(props) => props.theme.palette.black.main};

		background-color: ${(props) => props.theme.palette.background.paper};
		border: none;

		&:hover {
			cursor: pointer;
		}
	}
`
