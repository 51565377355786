import React from 'react'

import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import Table from '../../../components/Table'
import CancellationRequestModal from './CancellationRequestModal'
import { IViewProps } from './types'
import InputFilter from '../../../components/InputFilter'

function CancellationRequests(props: IViewProps): JSX.Element {
	const {
		isLoading,
		tableData,
		columns,
		isEditing,
		handleCloseModal,
		targetRequest,
		calcTotalOrder,
		fetchCancellationRequests,
		handleFilterOnChange
	} = props

	return (
		<>
			<Header />
			<div className="cancellation-requests">
				<div className="container">
					<h1 className="title">
						Solicitações de Cancelamento de Pedido
					</h1>

					<div className="search-input-wrapper">
						<InputFilter onChange={handleFilterOnChange} />
					</div>

					<div>
						{isLoading ? (
							<div className="loading-wrapper">
								<Loading />
							</div>
						) : (
							<Table data={tableData} columns={columns} />
						)}
					</div>
				</div>
			</div>

			<CancellationRequestModal
				isActive={isEditing}
				closeModal={handleCloseModal}
				request={targetRequest}
				calcTotalOrder={calcTotalOrder}
				fetchCancellationRequests={fetchCancellationRequests}
				isLoading={isLoading}
			/>
		</>
	)
}

export default CancellationRequests
