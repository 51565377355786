import { IFormattedPaymentCondition } from '../interfaces/customer'

export function getConditionLabel(
	methodDesc: IFormattedPaymentCondition['PaymentMethodDescription'],
	termsDesc: IFormattedPaymentCondition['PaymentTermsDescription']
): string {
	if (methodDesc && termsDesc) {
		const words = termsDesc.split(' ')

		const term = words.slice(words.length - 2).join(' ')

		return `${methodDesc} - ${term}`
	}
	if (termsDesc) {
		return termsDesc
	}
	if (methodDesc) {
		return methodDesc
	}

	return 'Método de pagamento inválido'
}
