import {
	apiAdminBaseUrl,
	apiCustomerBaseUrl,
	apiLambdaUrl
} from '../config/api-base-url.config'

export const getAmbienteName = (): string => {
	let ambiente: string = ''

	switch (window.location.hostname) {
		case 'devb2bcustomerportalgp.cfapps.br10.hana.ondemand.com':
			ambiente = 'dev'
			break

		case 'homb2bcustomerportalgp.cfapps.br10.hana.ondemand.com':
			ambiente = 'hml'
			break

		case 'www.bomparceirogp.com.br':
			ambiente = 'prd'
			break

		default:
			if (process.env.REACT_APP_AMBIENTE) {
				ambiente = process.env.REACT_APP_AMBIENTE
			} else if (process.env.NODE_ENV === 'development') {
				ambiente = 'dev'
			} else {
				ambiente = 'prd'
			}
			break
	}

	return ambiente
}

export const getApiBaseUrl = (apiName: string): string => {
	const ambiente: string = process.env.REACT_APP_AMBIENTE || getAmbienteName()

	const apiBaseUrl: string | undefined = {
		admin: apiAdminBaseUrl[ambiente],
		customer: apiCustomerBaseUrl[ambiente]
	}[apiName]

	if (!apiBaseUrl) {
		throw new Error(`ApiName: ${apiName} is unknown`)
	}

	return apiBaseUrl
}

export const getLambdaApiBaseUrl = (): string => {
	const ambiente: string = process.env.REACT_APP_AMBIENTE || getAmbienteName()
	const lambda = apiLambdaUrl[ambiente]
	return lambda
}
