import React from 'react'
import {
	ButtonWrapper,
	Container,
	GoBackButton,
	SendDocumentContainer,
	Title
} from './styles'
import { IProps } from './types'
import StepCreateAccount from '../StepCreateAccount'
import ArrowLeftIcon from '../../assets/images/arrow-left-red.svg'
import {
	primaryButtonStyle,
	secondaryButtonStyle
} from '../../shared/styles/theme'
import ButtonPrimary from '../ButtonPrimary'
import { ImagePreview } from './util'
import Loading from '../Loading'
import { colorThemeProvider } from '../../theme/themeProvider'

function SendDocument({
	title,
	subtitle,
	stepCurrent,
	stepTitle,
	subtitleRed,
	buttonPrimary,
	buttonSecondary,
	disabledButtonPrimary = false,
	image,
	buttonSubmitTitle,
	children,
	subtitleRedSecondary,
	subtitleSecondary,
	handleButtonPrimary,
	goBack,
	handleButtonSecondary,
	deleteImage,
	handleSubmit,
	loading,
	id,
	hiddenStepWizard
}: IProps) {
	return (
		<Container>
			<GoBackButton className="back-arrow-wrapper" onClick={goBack}>
				<img className="back-arrow" src={ArrowLeftIcon} alt="Voltar" />
				<p>{title}</p>
			</GoBackButton>

			{hiddenStepWizard ? (
				<div className="mt20" />
			) : (
				<StepCreateAccount
					stepCurrent={stepCurrent}
					stepTitle={stepTitle}
				/>
			)}

			<Title color={colorThemeProvider.whiteColor}>
				{subtitle}
				{subtitleRed && (
					<Title color={colorThemeProvider.primaryColor}>
						{' '}
						{subtitleRed}
					</Title>
				)}
			</Title>
			<br />

			{subtitleSecondary && (
				<Title color={colorThemeProvider.whiteColor}>
					{subtitleSecondary}
					{subtitleRedSecondary && (
						<Title color={colorThemeProvider.primaryColor}>
							{' '}
							{subtitleRedSecondary}
						</Title>
					)}
				</Title>
			)}
			<SendDocumentContainer>
				{children && children}

				{!image ? (
					<ButtonWrapper>
						{loading ? (
							<Loading color={colorThemeProvider.primaryColor} />
						) : (
							<ButtonPrimary
								{...primaryButtonStyle}
								onClick={handleButtonPrimary}
								disabled={disabledButtonPrimary}>
								{buttonPrimary}
							</ButtonPrimary>
						)}

						{buttonSecondary && (
							<ButtonPrimary
								onClick={handleButtonSecondary}
								style={{ marginLeft: 8 }}
								{...secondaryButtonStyle}>
								{buttonSecondary}
							</ButtonPrimary>
						)}
					</ButtonWrapper>
				) : (
					<>
						{image && deleteImage ? (
							<ImagePreview
								image={image}
								deleteImage={deleteImage}
								col="col-lg-6 col-md-12"
								id={id}
							/>
						) : null}

						{buttonSubmitTitle && handleSubmit && !loading ? (
							<div
								style={{
									alignItems: 'center'
								}}>
								<ButtonPrimary
									onClick={handleSubmit}
									{...primaryButtonStyle}>
									{buttonSubmitTitle}
								</ButtonPrimary>
							</div>
						) : (
							<Loading color={colorThemeProvider.primaryColor} />
						)}
					</>
				)}
			</SendDocumentContainer>
		</Container>
	)
}

export default SendDocument
