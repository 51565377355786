import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import { mediaQuery } from '../../shared/styles/functions'

export const NewAccountWrapper = styled.section`
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.form {
		margin-top: 40px;
	}

	.form-border {
		border-top: 1px solid ${(props) => props.theme.palette.lightGray.main};
	}
	.container {
		min-height: calc(100vh - (105px + 144px));
		padding-top: 40px;
	}
	.title-main {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		letter-spacing: 0.38px;
		color: ${colors.secondGrey};
		margin-top: 32px;
		margin-bottom: 48px;
		font-weight: ${(props) => props.theme.font.weight.regular};
	}

	.map-wrapper {
		margin-bottom: 40px;

		input {
			color: ${colors.secondGrey} !important;
			background: white !important;
			margin-bottom: 16px;
		}
	}
	.subtitle-create-account {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colors.sixthGray};
		margin-bottom: 40px;
	}
	.gp-client-form {
		margin-bottom: 40px;
		.input-form {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.center {
			margin: auto;
		}
		.activation .radio-group {
			margin: auto;
			max-width: calc(50%);

			${mediaQuery('sm')(`
       			max-width: 100%;
			`)}
		}
	}

	.helper-password {
		display: block;
		color: ${(props) => props.theme.palette.black.main};
		margin-top: 8px;
		text-transform: uppercase;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	}
	.red {
		color: ${colors.red};
	}
`
export const GoBackButton = styled.button`
	display: flex;
	align-items: center;
	background: none;
	border: none;

	p {
		color: ${colors.red};
		margin-left: 8px;
	}

	&:hover{
		cursor: pointer;
	}
`
export const InputDocumentNumberWrapper = styled.div`
	.col-4 {
		padding: 0;
	}
`

export const Title = styled.h1`
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
	color: ${colors.secondGrey};
	margin: 20px 0;
	line-height: ${(props) => props.theme.font.size.TEXT_LG.line_height}rem;
`

export const Subtitle = styled.p`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.sixthGray};
	margin-bottom: 40px;
`
export const TextRed = styled.span`
	color: ${colors.red};
`

export const UploadWrapper = styled.label`
	background-color: #ffebeb;
	border: 1px solid #fdb0b0;
	height: 83px;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	display: flex;
	margin-top: 30px;
	cursor: pointer;
	span {
		text-transform: uppercase;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
		letter-spacing: 0.64px;
		color: ${colors.red};
		margin-left: 8px;
	}
`

export const UploadUserPhoto = styled.div`
	width: 100%;
	display: flex;
	margin-top: 24px;
	.col-lg-6 {
		padding: 0;
		+ .col-lg-6 {
			padding-left: 16px;
		}
	}

	p {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colors.secondGrey};

		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	}

	${mediaQuery('sm')(`
       	display: block;
		   .col-lg-6 {
			+ .col-lg-6 {
				padding-left: 0px;
			}
		}
	`)}
`
export const StepFourWrapper = styled.div`
	.checkbox-content {
		margin-bottom: 16px;
		padding: 8px 0;
		display: flex;
		align-items: center;
	}

	.margin-top {
		margin-top: 40px;
	}

	.checkbox-button {
		border-bottom: 1px solid
			${(props) => props.theme.palette.lightGray.main};
	}

	.checkbox-label {
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${colors.secondGrey};
		margin-left: 8px;

		line-height: ${(props) => props.theme.font.size.TEXT_XS.line_height}rem;
		width: 100%;
	}

	.knowMore {
		background: 0;
		border: 0;
		color: #CE332E;
		text-transform: uppercase;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		padding: 0;
	}

	.create-password {
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		line-height: ${(props) => props.theme.font.size.DISPLAY_XS.line_height}rem;
		letter-spacing: 0.38px;
		color: ${colors.secondGrey};
		font-weight: ${(props) => props.theme.font.weight.regular};
		margin-bottom: 14px;
	}

	.password {
		margin: auto;
	}
`

export const FieldAlertFilledWithInvalidValue = styled.div`
	background-color: #fae5e5;
	border: 1px solid #f0c9c9;
	border-radius: 4px;
	width: 100%;
	padding: 24px 16px;
	margin: 32px 0;
	display: flex;
	align-items: center;
`

export const ErrorMessageText = styled.div`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;

	color: #e67575;
	margin: 0 16px;
	letter-spacing: 0.64px;
`

export const SelectDocumentType = styled.div`
	padding: 0;
	align-self: self-start;
	margin: 40px 0;
	p {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		color: ${colors.secondGrey};

		line-height: ${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
	}
`
