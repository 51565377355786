export enum UnauthenticatedRoutesEnum {
	LOGIN = '/login',
	LOGIN_MOBILE = '/login-mobile/:documentNumber/:token',
	RESET_PASSWORD = '/reset-password',
	CHANGE_PASSWORD = '/change-password',
	NEW_ACCOUNT = '/new-account',
	CONFIRMATION = '/confirmation',
	NEW_CLIENT = '/new-client',
	CHANGE_PERSONAL_PASSWORD = '/change-personal-password',
	GP_LOGIN = '/GPlogin',
	CONFIRM_ACTIVATION_CODE = '/confirm-activation-code',
	ACTIVATE_REGISTRATION = '/activate-Registration',
	AWAITING_APPROVAL = '/awaiting-approval',
	AWAITING_APPROVAL_SAC = '/awaiting-approval-sac',
	MAINTENANCE_MESSAGE = '/maintenance-message'
}
