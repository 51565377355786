import { colorThemeProvider } from './../../theme/themeProvider'
import styled from 'styled-components'

export const DayPickerWrapper = styled.div<{ isDisabled: boolean }>`
	display: flex;
	align-items: center;
	width: 100%;

	border: 1px solid ${(props) => props.theme.palette.extras.stroke.main};
	border-radius: 4px;

	.input-primary {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;

		label {
			z-index: 1;
			top: -4.5px;
			color: ${({ isDisabled }) => (isDisabled ? '#D1D1D1' : '#CE332E')};
			font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
			margin-top: 15px;
			font-weight: ${(props) => props.theme.font.weight.bold};
			position: absolute;
			text-transform: uppercase;
		}

		img {
			padding: 18px;
		}
	}

	.date-picker-primary {
		width: 100%;
		position: relative;
	}

	.DayPickerInput {
		input {
			padding: 26px 16px 10px;
			color: ${({ isDisabled }) =>
				isDisabled
					? colorThemeProvider.grayColor
					: colorThemeProvider.blackColor};
		}

		+ label {
			color: ${colorThemeProvider.blackColor};
			text-transform: uppercase;
		}
	}
`

export const SelectDateLabel = styled.label``
