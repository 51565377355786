import React from 'react'
import DualListBoxComponent from '../../../../components/DualListBoxComponent/DualListBoxComponent.view'
import {
	Table,
	TableContainer,
	TableDescription,
	TableHead,
	TableInput,
	TableRow,
	TableTitle,
	TableVoidContainer,
	TableVoidTitle,
	WrapperListBox
} from './product-manager.styles'
import { IProductManagerView } from './types'

export const ProductManagerView = ({
	initialSelectedProducts,
	edit,
	allProducts,
	loading,
	handleSelectProducts,
	productsListed,
	handleChangeListedValues
}: IProductManagerView) => {
	return (
		<>
			<WrapperListBox>
				<DualListBoxComponent
					firstDescription="Listagem de Produtos"
					labelKey=""
					options={allProducts}
					searchTitle="Buscar produtos"
					secondDescription={`Produtos Inseridos`}
					preserveSelectOrder={false}
					sortedOptions={(handledProducts) => {
						handleSelectProducts(
							handledProducts.map((item) => {
								return { ...item.options[0] }
							})
						)
					}}
					initialSelectedValues={initialSelectedProducts}
					valueKey=""
					isLoading={loading}
				/>
			</WrapperListBox>
			{productsListed.length > 0 ? (
				<TableContainer>
					<Table data-testid="table-container">
						<thead>
							<TableHead>
								<TableTitle>Cód. Produto</TableTitle>
								<TableTitle>Nome do Produto</TableTitle>
								<TableTitle>Tipo</TableTitle>
								<TableTitle>Formato</TableTitle>
								<TableTitle>Tamanho</TableTitle>
								{edit && <TableTitle>Qnt. Exibida</TableTitle>}
							</TableHead>
						</thead>
						<tbody>
							{productsListed.map((item) => (
								<TableRow key={item.ID}>
									<TableDescription>
										{item.MaterialID}
									</TableDescription>
									<TableDescription>
										{item.Description}
									</TableDescription>
									<TableDescription>
										{item.PackageType}
									</TableDescription>
									<TableDescription>
										{item.UnitMeasureSale}
									</TableDescription>
									<TableDescription>
										{item.Package}
									</TableDescription>
									{edit && (
										<TableDescription>
											<TableInput
												inputProps={{
													'data-testid': `input-component-${item.MaterialID}`
												}}
												type="number"
												value={item.materialQuantity}
												onChange={(event) => {
													handleChangeListedValues({
														label: item.Description,
														value: item.MaterialID,
														materialQuantity:
															Number(
																event.target
																	.value
															)
													})
												}}
											/>
										</TableDescription>
									)}
								</TableRow>
							))}
						</tbody>
					</Table>
				</TableContainer>
			) : (
				<TableVoidContainer data-testid="void-container">
					<TableVoidTitle>
						Selecione primeiro os produtos acima para cadastrar as
						pontuações dos produtos.
					</TableVoidTitle>
				</TableVoidContainer>
			)}
		</>
	)
}
