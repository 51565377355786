import styled from "styled-components";

export const WrapperConfirmModalStyles = styled.div`

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  width: 254px;

  .item-title{
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
    font-weight: ${(props) => props.theme.font.weight.bold};

    padding-bottom: 5px;
  }

  p{
    color: ${(props) => props.theme.palette.gray.main};
    font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
  }
`;