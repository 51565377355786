import React, { useCallback } from 'react'

import * as S from './registration-update.styles'
import Header from '../../components/Header'
import { DataSelector } from '../../components/data-selector'
import { SelectorOption } from '../../components/data-selector/data-selector.types'
import TextField from '../../components/inputs/text-field/text-field.component'
import { TypeEnum } from '../../components/inputs/text-field/text-field.types'
import { Contacts } from './registration-update.types'
import ButtonDefault from '../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../components/buttons/button/button-default.types'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import SecurityQuestionsComponent from '../../components/password-created/password-created.component'
import {
	getQuestions,
	registerSafeQuestionsAndAlternativeData
} from './services/service'
import { formateUtils } from './util/format-questions'
import { checkProperties } from '../../utils/check-object-properties'
import { validateEmail } from '../../utils/valid-email'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import {
	ICustomerSafetyInfo,
	ISecurityQuestions
} from './interfaces/safe-questions.interface'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { logout } from '../../shared/services/axiosInstace'
import { removeEspecialCharacters } from '../../shared/utils'

function RegistrationUpdate() {
	const { customer } = useTypedSelector(['customer'])

	const [questionOne, setQuestionOne] = React.useState<SelectorOption | null>(
		null
	)
	const [questionTwo, setQuestionTwo] = React.useState<SelectorOption | null>(
		null
	)

	const [answerOne, setAnswerOne] = React.useState<string>('')
	const [answerTwo, setAnswerTwo] = React.useState<string>('')

	const [typeContact, setTypeContact] = React.useState<string>('')

	const [email, setEmail] = React.useState<string>('')
	const [confirmEmail, setConfirmEmail] = React.useState<string>('')
	const [validEmail, setValidEmail] = React.useState<boolean>(false)

	const [originalQuestions, setOriginalQuestions] = React.useState<
		{ label: string; value: string }[]
	>([{ label: '', value: '' }])

	const [questionsOne, setQuestionsOne] = React.useState<
		{ label: string; value: string }[]
	>([{ label: '', value: '' }])

	const [questionsTwo, setQuestionsTwo] = React.useState<
		{ label: string; value: string }[]
	>([])

	const [phone, setPhone] = React.useState<string>('')
	const [confirmPhone, setConfirmPhone] = React.useState<string>('')
	const [validPhone, setValidPhone] = React.useState<boolean>(false)
	const [sucessPage, setSucessPage] = React.useState<boolean>(false)

	const [validEmailFormat, setValidEmailFormat] =
		React.useState<boolean>(false)

	const [canSendData, setCanSendData] = React.useState<boolean>(false)

	const [loading, setLoading] = React.useState<boolean>(false)

	const [registrationPayload, setRegistrationPayload] =
		React.useState<ICustomerSafetyInfo>({
			document: '',
			alternativeEmail: '',
			alternativePhoneNumber: 'string',
			securityQuestions: []
		})

	function handleRadio(type: Contacts) {
		setTypeContact(type)
		setEmail('')
		setConfirmEmail('')
		setPhone('')
		setConfirmPhone('')
	}

	const objectData = useCallback(() => {
		if (typeContact === Contacts.CELL) {
			return {
				questionOne,
				questionTwo,
				answerOne,
				answerTwo,
				alternativePhone: phone
			}
		} else {
			return {
				questionOne,
				questionTwo,
				answerOne,
				answerTwo,
				alternativeEmail: email
			}
		}
	}, [
		typeContact,
		answerOne,
		answerTwo,
		email,
		phone,
		questionOne,
		questionTwo
	])

	async function handleGetQuestions() {
		setLoading(true)
		const questions = await getQuestions()
		const formatedArray = formateUtils(questions as ISecurityQuestions[])
		setLoading(false)
		setOriginalQuestions(formatedArray)
	}

	React.useEffect(() => {
		handleGetQuestions()
	}, [])

	React.useEffect(() => {
		if (!questionTwo?.value || !questionOne?.value) {
			setQuestionsOne(originalQuestions)
			setQuestionsTwo(originalQuestions)
		}

		const filteredQuestionsOne = originalQuestions.filter(
			(question) => question.value !== questionTwo?.value
		)
		setQuestionsOne(filteredQuestionsOne)

		const filteredQuestionsTwo = originalQuestions.filter(
			(question) => question.value !== questionOne?.value
		)
		setQuestionsTwo(filteredQuestionsTwo)
	}, [originalQuestions, questionOne?.value, questionTwo?.value])

	function checkPhoneMask(telefone: string): boolean {
		const numeroLimpo = telefone.replace(/\D/g, '')

		if (numeroLimpo.length !== 11 || numeroLimpo.length < 10) {
			setValidPhone(false)
			return false
		}

		return true
	}

	async function confirmButton() {
		setLoading(true)
		try {
			await registerSafeQuestionsAndAlternativeData(registrationPayload)
			setSucessPage(true)
		} catch (error) {
			cogoToast.error(error, cogoDefaultOptions)
		} finally {
			setLoading(false)
		}
	}

	function cancelButton() {
		logout()
	}

	React.useEffect(() => {
		if (
			confirmEmail.length > 0 &&
			email.length > 0 &&
			email === confirmEmail
		) {
			setValidEmail(true)
		} else {
			setValidEmail(false)
		}
	}, [email, confirmEmail])

	React.useEffect(() => {
		if (
			phone.length > 0 &&
			confirmPhone.length > 0 &&
			phone === confirmPhone
		) {
			setValidPhone(true)
		} else {
			setValidPhone(false)
		}
	}, [phone, confirmPhone])

	React.useEffect(() => {
		const currentFormData = objectData()
		const isFiledForm = checkProperties(currentFormData)

		if (
			(typeContact === Contacts.EMAIL &&
				validEmail &&
				isFiledForm &&
				validEmailFormat) ||
			(typeContact === Contacts.CELL &&
				checkPhoneMask(phone) &&
				checkPhoneMask(confirmPhone) &&
				validPhone &&
				isFiledForm)
		) {
			setCanSendData(true)
			setRegistrationPayload({
				document: removeEspecialCharacters(
					customer.PersonalFiscalID
						? customer.PersonalFiscalID
						: customer.CompanyFiscalID
				),
				alternativeEmail: currentFormData.alternativeEmail,
				alternativePhoneNumber: currentFormData.alternativePhone,
				securityQuestions: [
					{
						questionId: currentFormData.questionOne?.value ?? '',
						answer: currentFormData.answerOne
					},
					{
						questionId: currentFormData.questionTwo?.value ?? '',
						answer: currentFormData.answerTwo
					}
				]
			})
		} else {
			setCanSendData(false)
		}
	}, [
		objectData,
		typeContact,
		validEmail,
		validPhone,
		validEmailFormat,
		phone,
		confirmPhone,
		customer.PersonalFiscalID,
		customer.CompanyFiscalID
	])

	React.useEffect(() => {
		setValidEmailFormat(validateEmail(email) && validateEmail(confirmEmail))
	}, [email, confirmEmail])

	return (
		<>
			<LoadingCcontainer isLoading={loading} />
			<Header disabledMenuCart={true} />
			{sucessPage && (
				<S.SecurityQuestionsComponent>
					<SecurityQuestionsComponent />
				</S.SecurityQuestionsComponent>
			)}
			{!sucessPage && (
				<>
					<S.WrapperRegistrationUpdate className="container">
						<S.Title>
							Atualização de dados cadastrais necessária
						</S.Title>

						<S.Description>
							Escolha e responda duas perguntas de segurança. Elas
							serão essenciais caso precise recuperar o seu acesso
							a conta.
						</S.Description>

						<S.WrapperSelect>
							<S.WrapperRow>
								<DataSelector
									options={questionsOne}
									placeholder="Pergunta de segurança 1"
									selectedOption={questionOne}
									onSelectionChange={setQuestionOne}
								/>

								<DataSelector
									options={questionsTwo}
									placeholder="Pergunta de segurança 2"
									selectedOption={questionTwo}
									onSelectionChange={setQuestionTwo}
								/>
							</S.WrapperRow>

							<S.WrapperRow>
								<TextField
									label={'Resposta (Obrigatório)'}
									placeholder={'Digite a resposta'}
									handleChangeText={setAnswerOne}
									text={answerOne}
									type={TypeEnum.TEXT}
								/>

								<TextField
									label={'Resposta (Obrigatório)'}
									placeholder={'Digite a resposta'}
									handleChangeText={setAnswerTwo}
									text={answerTwo}
									type={TypeEnum.TEXT}
								/>
							</S.WrapperRow>
						</S.WrapperSelect>

						<S.Description>
							Adicione um e-mail ou celular alternativo de
							recuperação de conta.
						</S.Description>

						<S.WrapperRadio>
							<div onClick={() => handleRadio(Contacts.EMAIL)}>
								<input
									type="radio"
									checked={typeContact === Contacts.EMAIL}
								/>
								<label>E-mail alternativo</label>
							</div>

							<div onClick={() => handleRadio(Contacts.CELL)}>
								<input
									type="radio"
									checked={typeContact === Contacts.CELL}
								/>
								<label>Celular alternativo</label>
							</div>
						</S.WrapperRadio>
						{typeContact === Contacts.EMAIL && (
							<S.WrapperTextEmail
								$validEmail={
									(confirmEmail.length > 0 && !validEmail) ||
									(validEmail && !validEmailFormat)
										? false
										: true
								}>
								<TextField
									label={''}
									placeholder={'Digite o seu e-mail'}
									handleChangeText={setEmail}
									text={email}
									type={TypeEnum.TEXT}
								/>
								<TextField
									label={''}
									placeholder={'Confirme o seu e-mail'}
									handleChangeText={setConfirmEmail}
									text={confirmEmail}
									type={TypeEnum.TEXT}
								/>
								{confirmEmail.length > 0 &&
									validEmail &&
									!validEmailFormat && (
										<p>
											E-mails digitados com formato
											incorreto
										</p>
									)}
								{confirmEmail.length > 0 && !validEmail && (
									<p>E-mails digitados não correspondem</p>
								)}
							</S.WrapperTextEmail>
						)}
						{typeContact === Contacts.CELL && (
							<S.WrapperTextCell
								$validPhone={
									confirmPhone.length > 0 && !validPhone
										? false
										: true
								}>
								<TextField
									label={''}
									placeholder={
										'Digite um celular alternativo'
									}
									handleChangeText={setPhone}
									text={phone}
									type={TypeEnum.TEXT}
								/>
								<TextField
									label={''}
									placeholder={
										'Confirme o celular alternativo'
									}
									handleChangeText={setConfirmPhone}
									text={confirmPhone}
									type={TypeEnum.TEXT}
								/>
								{confirmPhone !== phone && (
									<p>Celulares digitados não correspondem</p>
								)}
								{validPhone || (
									<p>Celulares digitados são inválidos</p>
								)}
							</S.WrapperTextCell>
						)}

						<S.WrapperButton>
							<ButtonDefault
								buttonText="Voltar"
								type={TypeButtonEnum.OUTLINED}
								onClick={() => cancelButton()}
								idElement="cancel"
								width="234px"
							/>
							<ButtonDefault
								buttonText="Continuar"
								type={
									canSendData
										? TypeButtonEnum.PRIMARY
										: TypeButtonEnum.DISABLED
								}
								onClick={() => confirmButton()}
								idElement="confirm"
								width="234px"
							/>
						</S.WrapperButton>
					</S.WrapperRegistrationUpdate>
				</>
			)}
		</>
	)
}

export default RegistrationUpdate
