import cogoToast from 'cogo-toast'
import { mergeDeepLeft, omit } from 'ramda'
import { createElement, useEffect, useMemo, useState } from 'react'

import TableButton from '../../components/TableButton'
import { useBoolean } from '../../shared/hooks/useBoolean'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { Maybe } from '../../shared/interfaces/common'
import { FullParameter } from '../../shared/interfaces/fullParam'
import { Creators as ResaleActions } from '../../shared/store/ducks/resale'
import { returnParamField } from '../../shared/utils/param'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { SPECIAL_CHAR_AND_WHITESPACE } from '../../shared/utils/validators'
import { DEFAULT_PARAM } from './data'
import { deleteParam, fetchParams, saveParam } from './service'
import { IParameterItem, IViewProps, PossibleInputValues } from './types'
import RegisterParameter from './view'

import FileIcon from '../../assets/images/file-text.svg'
import TrashIcon from '../../assets/images/trash.svg'

function RegisterParameterContainer(): JSX.Element {
	const { resale } = useTypedSelector(['resale'])

	const { setResale: setStoreResale } = ResaleActions

	const [paramList, setParamList] = useState<IParameterItem[]>([])
	const [param, setParam] = useState(DEFAULT_PARAM)
	const [isEditOpen, openEditModal, closeEditModal] = useBoolean(false)
	const [isConfirmOpen, openConfirm, closeConfirmModal] = useBoolean(false)
	const [promptedIndex, setPromptedIndex] = useState<Maybe<number>>(undefined)
	const [loading, startLoading, stopLoading] = useBoolean(false)
	const [isEdit, setEdit, setNew] = useBoolean(false)

	const data = useMemo(
		() =>
			paramList.map((x, idx) => ({
				name: returnParamField(x.Name),
				description: returnParamField(x.Description),
				type: returnParamField(x.Type),
				typeValue: returnParamField(x.TypeValue),
				editButton: TableButton(() => handleOpenModal(idx), FileIcon),
				deleteButton: TableButton(() => removeParam(idx), TrashIcon)
			})),
		// eslint-disable-next-line
		[paramList, FileIcon, TrashIcon]
	)

	const columns = useMemo(
		() => [
			{ Header: 'Parâmetro', accessor: 'name' },
			{ Header: 'Descritivo', accessor: 'description' },
			{ Header: 'Tipo', accessor: 'type' },
			{ Header: 'Valor do Tipo', accessor: 'typeValue' },
			{
				Header: '',
				accessor: 'editButton',
				disableSortBy: true
			},
			{
				Header: '',
				accessor: 'deleteButton',
				disableSortBy: true
			}
		],
		[]
	)

	const handleFetchParamList = () => {
		fetchParams().then(setParamList)
	}

	useEffect(handleFetchParamList, [])

	const handleSubmit = async () => {
		startLoading()

		const toSave = omit(['value'], { ...param, LogicalDelete: '' })

		try {
			await saveParam(toSave)

			const payload = { ...resale, params: paramList }

			setStoreResale(payload)

			cogoToast.success(
				`Parâmetro ${isEdit ? 'editado' : 'criado'} com sucesso`,
				cogoDefaultOptions
			)

			await handleFetchParamList()
		} catch (error) {
		} finally {
			setParam(DEFAULT_PARAM)
			closeEditModal()
			stopLoading()
		}
	}

	const fmtName = (x: string) =>
		x.replace(SPECIAL_CHAR_AND_WHITESPACE, '').toUpperCase().trim()

	const updateParamKey = <K extends keyof FullParameter>(
		key: K,
		pos?: 'start' | 'end'
	) => (input: Maybe<PossibleInputValues>) => {
		if (!input) return

		const isEvent = Boolean((input as any)?.target)
		const isOption = Boolean((input as any)?.value)

		const obj = isEvent ? (input as any).target : isOption ? input : {}

		const { value: val = input } = obj

		const useObjValue = param.TypeValue === 'INTERVAL'

		const valueToUse = pos && useObjValue ? { [pos]: val } : val

		const isUniq = key === 'Type' && (val === 'BOOLEAN' || val === 'STRING')

		const unique = isUniq ? { TypeValue: 'SINGLE' as 'SINGLE' } : {}

		const payload = {
			[key]: key === 'Name' ? fmtName(valueToUse) : valueToUse,
			...unique
		}

		setParam(mergeDeepLeft(payload))
	}

	const handleOpenModal = (i?: number) => {
		const toEdit = i !== undefined ? paramList[i] : DEFAULT_PARAM

		const operation = i !== undefined ? setEdit : setNew

		setParam(toEdit)

		operation()
		openEditModal()
	}

	const handleCloseModal = () => {
		setParam(DEFAULT_PARAM)

		closeEditModal()
	}

	const removeParam = (i: number) => {
		openConfirm()
		setPromptedIndex(i)
	}

	const confirmParamDeletion = async () => {
		if (promptedIndex === undefined) return

		const i = promptedIndex

		const wantedParam = paramList[i]

		// setParamList(remove(i, i))

		try {
			await deleteParam(wantedParam)
			cogoToast.success(
				'Parâmetro excluido com sucesso',
				cogoDefaultOptions
			)

			await handleFetchParamList()
		} catch (error) {
		} finally {
			closeConfirmModal()
		}
	}

	const viewProps: IViewProps = {
		isConfirmOpen,
		isEditOpen,
		param,
		paramList,
		promptedIndex,
		loading,
		isEdit,
		closeConfirmModal,
		confirmParamDeletion,
		handleCloseModal,
		handleOpenModal,
		handleSubmit: () => {
			handleSubmit()
		},
		openEditModal,
		removeParam,
		updateParamKey,
		data,
		columns
	}

	return createElement(RegisterParameter, viewProps)
}

export default RegisterParameterContainer
