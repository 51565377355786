import React from 'react'

import ProductForRedemption from '../ProductForRedemption'

import { ISuggestionBlockProps } from './types'
import { Loading } from '../../../shared/components'

import * as S from './styles'

export default function SuggestionBlock(props: ISuggestionBlockProps) {
	const { products, isLoading, handleClickItem } = props

	return (
		<S.SuggestionBlockWrapper>
			<S.SuggestionBlockTitle>Sugestões para você</S.SuggestionBlockTitle>
			{isLoading ? (
				<S.LoadingWrapper>
					<Loading />
				</S.LoadingWrapper>
			) : (
				<S.SuggestedProductsWrapper>
					{products.map((item) => (
						<ProductForRedemption
							product={item}
							handleClick={() => handleClickItem(item)}
							key={item.MaterialID}
						/>
					))}
				</S.SuggestedProductsWrapper>
			)}
		</S.SuggestionBlockWrapper>
	)
}
