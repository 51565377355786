import styled from 'styled-components'
import { Box } from '@material-ui/core'

interface NotificationStylesProps {
	isSelectedImage: boolean | undefined
}

export const WrapperNotification = styled(Box)<NotificationStylesProps>`
	width: 342px;

	max-height: 359px;
	background: ${(props) =>
		props.isSelectedImage ? '#FFFFFF' : ' rgba(245, 245, 245, 0.6)'};

	backdrop-filter: blur(16.601px);
	border-radius: 6px;
	padding: 8px 10px;

	overflow: auto;

	.header-notification {
		width: 100%;
		display: flex;
		justify-content: space-between;

		margin-bottom: 10px;

		.left-content {
			display: flex;
			align-items: center;
			gap: 10px;
			p {
				font-size: 15px;
				font-weight: 400;
				line-height: 15px;

				color: #3d3d3d;
			}
		}

		.right-content {
			display: flex;
			align-items: center;

			p {
				font-weight: 400;
				font-size: 13px;
				line-height: 15px;
				color: #3d3d3d;
			}
		}
	}

	.main-notification {
		h2 {
			font-weight: 700;
			font-size: 15px;
			line-height: 20px;
			color: #3d3d3d;
			word-wrap: break-word;
		}

		p {
			font-weight: 400;
			font-size: 15px;
			line-height: 18px;
			word-wrap: break-word;

			color: #3d3d3d;
		}

		img {
			width: 359px;
			height: 200px;
		}
	}
`

export const DefaultWrapper = styled(Box)`
	width: 342px;
	height: 119px;

	background: #f9f9f9;
	border-radius: 6px;

	.header-notification {
		display: flex;
		align-items: center;
		gap: 10px;
		padding: 4px 8px;

		.empty-app-name {
			width: 143px;
			height: 10px;
			background: #d8d2d3;
		}
	}

	.notification-body {
		height: 99px;
		width: 100%;
		background: rgba(183, 183, 183, 0.3);
		border-radius: 0px 0px 6px 6px;

		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 10px;
		padding: 15px 0px 0px 20px;

		.empty-title {
			width: 312px;
			height: 16px;
			background: #d8d2d3;
		}

		.empty-message {
			width: 143px;
			height: 16px;

			background: #d8d2d3;
		}
	}
`
