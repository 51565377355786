import { colorThemeProvider } from './../../theme/themeProvider';
import styled, { css } from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const InputQuantityWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`

export const InputAndButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;

	.decrement {
		display: flex;
		width: 40px;
		height: 40px;
		border: none;
		//		padding: 4px;
		background-color: transparent;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.increment {
		display: flex;
		width: 40px;
		height: 40px;
		border: none;
		//		padding: 4px;
		background-color: transparent;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:disabled {
			cursor: not-allowed;
		}
	}
`

export const InsufficientPointsLabelWrapper = styled.div`
	width: 96%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const InputWithValueWrapper = styled.div<{
	insufficientAmountOfPointsForRedemption?: boolean
}>`
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
	padding: 10px;

	${({ insufficientAmountOfPointsForRedemption }) =>
		insufficientAmountOfPointsForRedemption &&
		css`
			border: 1px solid ${colors.lightRed};
		`}

	> input[type='number'] {
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		border: none;
		max-width: 28px;
		max-height: 37px;
		text-align: center;
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		color: ${({ insufficientAmountOfPointsForRedemption }) =>
			insufficientAmountOfPointsForRedemption
				? colorThemeProvider.primaryColor
				: colorThemeProvider.blackColor};
		background-color: transparent;

		-moz-appearance: textfield;
		appearance: textfield;
	}
`

export const DecrementButton = styled.button`
	width: 38px;
	border: none;
	padding: 10px;
	cursor: pointer;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	background-color: ${colorThemeProvider.primaryColor};
	transition: all ease-in-out 300ms;

	&:hover {
		background-color: ${colorThemeProvider.secondaryColor};
	}

	> span {
		font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.medium};
		color: ${(props) => props.theme.palette.white.main};
	}

	&:disabled {
		cursor: not-allowed;
		background-color: ${colorThemeProvider.grayColor};
	}

	display: flex;
	align-items: center;
	justify-content: center;
`

export const IncrementButton = styled(DecrementButton)`
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`
