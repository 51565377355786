import React from 'react'

import CircleIcon from '@mui/icons-material/Circle'
import * as S from './combo-flex-item.styles'
import QuantityButtons from '../../../../../../../../../quantity-buttons/quantity-buttons.component'
import { IComboFlexItem } from './combo-flex-item.types'
import { useComboFlexContext } from '../../../../../../../../context/combo-flex.context'

function ComboFlexItem({
	productImage,
	productName,
	isReturnable,
	priceUnity,
	priceComboFlex,
	isDisabled,
	onClick,
	updateComboGroupItem,
	comboItem,
	// TODO: Força diff
	disabledIncrementButton,
	isBonus,
	selectedProductsToCart
}: IComboFlexItem) {
	const { totalPrice, updateTotalPrice } = useComboFlexContext()

	return (
		<S.WrapperComboFlexItem>
			<S.WrapperHeaderComboFlex isDisabled={isDisabled} onClick={onClick}>
				<img src={productImage} alt="combo-flex-item" />
				<div className="wrapper-combo-details">
					<p>{productName}</p>
					{isReturnable && (
						<div className="is-returnable">
							<CircleIcon />
							<p>RETORNÁVEL</p>
						</div>
					)}
				</div>
			</S.WrapperHeaderComboFlex>
			<S.WrapperFooterComboItem isDisabled={isDisabled}>
				<div className="wrapper-item-data" onClick={onClick}>
					<div className="unit-value" onClick={onClick}>
						{isBonus ? (
							<p className="isBonusText">R${priceComboFlex}</p>
						) : (
							<>
								<p>Unidade</p>
								<p>R$ {priceUnity}</p>
							</>
						)}
					</div>
					{
						<>
							{isBonus ? (
								<p className="isBonusComboPrice">R$ 0,00</p>
							) : (
								<p>R$ {priceComboFlex}</p>
							)}
						</>
					}
				</div>
				{!isDisabled && (
					<QuantityButtons
						handleDecrement={() => {
							const quantity =
								selectedProductsToCart?.[comboItem.groupID]?.[
									comboItem.productID
								]?.quantity || 0

							updateTotalPrice(
								isBonus
									? totalPrice + 0
									: totalPrice - parseFloat(priceComboFlex)
							)
							updateComboGroupItem(
								comboItem,
								quantity === 0 ? 0 : quantity - 1
							)
						}}
						handleIncrement={() => {
							const quantity =
								selectedProductsToCart?.[comboItem.groupID]?.[
									comboItem.productID
								]?.quantity || 0

							updateTotalPrice(
								isBonus
									? totalPrice + 0
									: totalPrice + parseFloat(priceComboFlex)
							)
							updateComboGroupItem(comboItem, quantity + 1)
						}}
						// TODO: Força diff
						disabledIncrementButton={disabledIncrementButton}
						value={
							selectedProductsToCart?.[comboItem.groupID]?.[
								comboItem.productID
							]?.quantity || 0
						}
					/>
				)}
			</S.WrapperFooterComboItem>
		</S.WrapperComboFlexItem>
	)
}

export default ComboFlexItem
