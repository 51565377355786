import React from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import { DetailsModalWrapper } from './details-banner-modal.styles'
// import { DataSelectorList } from '../../../../../components/data-selector-list'
import { IDetailsModalBanner } from './details-banner-modal.types'
import MidiaContainer from '../../midia-container/midia-container.component'
import BannersDataContainer from '../banners-data-container/banners-data-container.component'
import { DataSelectorList } from '../../../../../components/data-selector-list'

const DetailsModalBanner = ({
	isOpen = true,
	handleToggleModal,
	handleClick,
	actionType,
	apliedTitle,
	destinyLink,
	initialDate,
	finalDate,
	exibitionOrder,
	selectedProduct,
	usersImpacted,
	selectedImage,
	selectedVideo,
	details,
	selectedCategory
}: IDetailsModalBanner) => {
	return (
		<LayoutModal
			modalTitle="Detalhes do Banner"
			buttonText="Retornar"
			typeLayout="details"
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			handleClick={handleClick}
			height="88%">
			<DetailsModalWrapper>
				<MidiaContainer
					deviceSelected={{ value: '', name: '' }}
					showDeviceSelect={false}
					canDeleteVideo={false}
					srcImage={selectedImage}
					srcVideo={selectedVideo}
				/>

				<BannersDataContainer
					apliedTitle={apliedTitle}
					destinyLink={destinyLink}
					exibitionPeriod={`De ${initialDate} até ${finalDate}`}
					exibitionOrder={exibitionOrder}
					actionType={actionType}
					selectedProduct={selectedProduct}
					selectedCategory={selectedCategory}
				/>

				<div className="params-wrapper">
					<h2>Parâmetros</h2>
					<div className="inputs-wrapper">
						<div className="input-collum">
							<DataSelectorList
								selectedOption={details?.diretor[0]}
								title="Diretor"
								list={details?.diretor || []}
								isNative={false}
							/>
							<DataSelectorList
								selectedOption={details?.ufs[0]}
								title="UF"
								list={details?.ufs || []}
								isNative={false}
							/>
							<DataSelectorList
								selectedOption={details?.channels[0]}
								title="Canal"
								list={details?.channels || []}
								isNative={false}
							/>
						</div>

						<div className="input-collum">
							<DataSelectorList
								selectedOption={details?.GRC[0]}
								title="GRC"
								list={details?.GRC || []}
								isNative={false}
							/>
							<DataSelectorList
								selectedOption={details?.cities[0]}
								title="Cidade"
								list={details?.cities || []}
								isNative={false}
							/>
							<DataSelectorList
								selectedOption={details?.codeResales[0]}
								title="CD"
								list={details?.codeResales || []}
								isNative={false}
							/>
						</div>
					</div>
				</div>

				<ImpactedClients usersImpacted={usersImpacted} />
			</DetailsModalWrapper>
		</LayoutModal>
	)
}

export default DetailsModalBanner
