/* eslint-disable */
import { createElement, useEffect, useState } from 'react'
import cogoToast from 'cogo-toast'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Auth } from 'aws-amplify'

import { IViewProps } from './types'
import changeEmail from './view'
import cogoDefaultOptions from '../../shared/utils/toaster'
import handleError from '../Error/handleError'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { cogoSuccessMessages } from '../../shared/utils/Messages'
import { editCustomerEmail } from './service'
import { Creators as CustomerActions } from '../../shared/store/ducks/customer'
import { checkEmail, checkEmailMatch } from '../../shared/utils/validators'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'

export default function ChangeEmail(): JSX.Element {
	const { customer } = useTypedSelector(['customer'])
	const history = useHistory()
	const [email, setEmail] = useState('')
	const [emailConfirmation, setEmailConfirmation] = useState('')
	const [buttonActive, setButtonActive] = useState(false)
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	async function handleBack() {
		history.replace(CustomerRoutesEnum.MY_ACCOUNT)
	}

	async function handleSubmit() {
		const user = await Auth.currentAuthenticatedUser()

		try {
			setLoading(true)

			await Auth.updateUserAttributes(user, {
				email: email
			})

			const response: any = await editCustomerEmail(
				customer.PersonalFiscalID || customer.CompanyFiscalID,
				emailConfirmation
			)

			if (!response || !response.data.length) return

			dispatch(
				CustomerActions.updateCustomer({
					...customer,
					Email: response.data[0].email
				})
			)

			await handleBack()

			cogoToast.success(
				cogoSuccessMessages.updateEmailSuccess,
				cogoDefaultOptions
			)
		} catch (error) {
			//@ts-ignore
			handleError(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		setButtonActive(
			!!checkEmail(email) &&
				!!checkEmail(emailConfirmation) &&
				!!checkEmailMatch(emailConfirmation, email)
		)
	}, [email, emailConfirmation])

	const viewProps: IViewProps = {
		handleBack,
		setEmail,
		setEmailConfirmation,
		email,
		emailConfirmation,
		handleSubmit,
		buttonActive,
		loading
	}

	return createElement(changeEmail, viewProps)
}
