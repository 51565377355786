import { createElement, useState } from 'react'
import { INotificationProps } from '../../shared/interfaces/pushNotification'
import { sendSingleNotification } from './service'

import PushNotification from './view'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import handleError from '../Error/handleError'

import { IError } from '../Error/types'

import PushNotificationMapper from './mapper'
import { IFilterOptions } from '../../components/filters-by-hieararchy/typings'

function PushNotificationContainer(): JSX.Element {
	const [isLoading, setIsLoading] = useState(false)

	const [impactedPdvs, setImpactedPdvs] = useState<number>(0)
	const [pushSelectedPDVs, setPushSelectedPDVs] = useState<string[]>([])

	const [filters, setFilters] = useState<IFilterOptions>({
		DIRECTOR: [],
		GOV: [],
		RESALE: [],
		CHANNEL: [],
		STATE: [],
		CITY: []
	})

	const [canSendPdv, setCanSendPdv] = useState(false)

	async function sendNotification(
		title: string,
		body: string,
		actionType: string,
		triggerDate: string,
		productToOpen: string,
		categoryToOpen: string,
		imageUrl: string
	) {
		try {
			setIsLoading(true)
			let notification: INotificationProps = {
				title: title,
				body: body,
				data: {
					actionType: actionType,
					productToOpen: productToOpen,
					categoryToOpen: categoryToOpen
				},
				trigger_date: triggerDate,
				imageUrl: imageUrl
			}

			const filterParams = PushNotificationMapper.toPersitenceFilter({
				filters,
				selectedPDVs: pushSelectedPDVs,
				canSendPdv
			})

			await sendSingleNotification(notification, filterParams)

			cogoToast.success(
				`Noficação processada com sucesso!`,
				cogoDefaultOptions
			)
		} catch (error) {
			handleError(error as IError)
		} finally {
			setIsLoading(false)
		}
	}

	function handleSendNotification(
		title: string,
		body: string,
		actionType: string,
		triggerDate: string,
		productToOpen: string,
		categoryToOpen: string,
		imageUrl: string
	) {
		sendNotification(
			title,
			body,
			actionType,
			triggerDate,
			productToOpen,
			categoryToOpen,
			imageUrl
		)
	}

	const handleGetFilterValues = (hieararchyFilters: IFilterOptions) => {
		setFilters(hieararchyFilters)
	}

	const viewProps = {
		isLoading,
		impactedPdvs,
		pushSelectedPDVs,
		setImpactedPdvs,
		handleSendNotification,
		handleGetFilterValues,
		setCanSendPdv,
		setPushSelectedPDVs,
		
	}

	return createElement(PushNotification, viewProps)
}

export default PushNotificationContainer
