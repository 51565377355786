import React, { Fragment } from 'react'
import { format } from 'date-fns'

import { IViewProps } from './type'
import {
	Header,
	ChipWithInformationAboutTheChallenge,
	Loading,
	ChallengeProgressBar
} from '../../shared/components'

import ChallengeItem from './ChallengeItem'
import { remainingDaysLabel } from '../../shared/utils/date'
import ChallengeMembershipTerms from './ChallengeMembershipTerms'
import { ChipTypeEnum } from '../../components/ChipWithInformationAboutTheChallenge/types'
import { ChallengeRuleTypeEnum } from '../../shared/interfaces/pointsProgramChallenge'

import { ReactComponent as ArrowLeftBlackIcon } from '../../assets/images/arrow-left-black.svg'

import * as S from './styles'

export default function ChallengeDetailsView(props: IViewProps) {
	const {
		challenge,
		handleGoBack,
		goToCartScreen,
		productsOfTheChallenge,
		addingItemToTheProductMix,
		allProductsThatArePartOfTheMix,
		isFetchingProductsOfTheChallenge,
		showModalWithChallengeMembershipTerms,
		handleShowModalWithChallengeMembershipTerms,
		continueBuyingTheProductMixInItsEntirety,
		quantityReached,
		remainingProducts,
		theUserCantContinueThePurchase,
		totalProductsToCompleteTheChallenge
	} = props

	const {
		title,
		score,
		urlFoto,
		endDate,
		typeRule,
		description,
		completedAt,
		countDayFinish,
		userIsAlreadyParticipatingInTheChallenge
	} = challenge

	const challengeTitle = title
	const challengeDescription = description
	const challengeIsCompleted = !!completedAt
	const challengeEndDate = String(endDate)
	const challengeBanner = urlFoto
	const challengeScore = Number(score)
	const shownDaysLeftUntilChallengeEnds = !!countDayFinish
	const thisChallengeIsMixType =
		typeRule === ChallengeRuleTypeEnum.PRODUCT_MIX

	return (
		<Fragment>
			<S.ChallengeDetailsWrapper>
				<header className="header">
					<Header />
				</header>

				<S.ContentWrapper>
					<S.GoBackButton onClick={handleGoBack}>
						<ArrowLeftBlackIcon /> Voltar
					</S.GoBackButton>
					<div className="page-title">
						<h2>Informações do Desafio</h2>
					</div>
					<S.ChallengesInfosWrapper>
						<S.ChallengeBanner bannerUrl={challengeBanner} />
						<S.ChipsWrapper>
							{challengeIsCompleted ? (
								<ChipWithInformationAboutTheChallenge
									label="Concluído"
									type={ChipTypeEnum.COMPLETED}
								/>
							) : null}
							<ChipWithInformationAboutTheChallenge
								label={`${challengeScore.toLocaleString()} Pontos`}
								type={ChipTypeEnum.SHOW_POINTS_EARNED}
							/>
							{shownDaysLeftUntilChallengeEnds &&
							!challengeIsCompleted ? (
								<ChipWithInformationAboutTheChallenge
									label={remainingDaysLabel(challengeEndDate)}
									type={ChipTypeEnum.SHOW_REMAINING_DAYS}
								/>
							) : null}
						</S.ChipsWrapper>
						<S.ChallengeTitle>{challengeTitle}</S.ChallengeTitle>
						<S.ChallengeDescription>
							{challengeDescription}
						</S.ChallengeDescription>
						{shownDaysLeftUntilChallengeEnds ? (
							<S.ChallengeEndDateDisplayWrapper>
								<S.FinalDateLabelText>
									Data Final do Desafio
								</S.FinalDateLabelText>
								<S.BedgeWithFinalDate>
									{format(
										new Date(
											challenge.endDate + 'T12:00:00'
										),
										'dd/MM/yyyy'
									)}
								</S.BedgeWithFinalDate>
							</S.ChallengeEndDateDisplayWrapper>
						) : null}
					</S.ChallengesInfosWrapper>
					{userIsAlreadyParticipatingInTheChallenge ? (
						<S.ChallengeProgressSession>
							<S.ProgressBarWrapper>
								{!challengeIsCompleted ? (
									<S.ProgressBarLabel>
										{!!remainingProducts && (
											<S.RemainingProductsText>
												{remainingProducts}{' '}
												{remainingProducts === 1
													? 'Restante'
													: 'Restantes'}
											</S.RemainingProductsText>
										)}{' '}
										{!!remainingProducts
											? 'Para completar o desafio'
											: 'TODOS OS PRODUTOS SELECIONADOS'}
									</S.ProgressBarLabel>
								) : (
									<S.ComplementaryElement
										style={{ width: '152px' }}
									/>
								)}

								<S.BarWrapper>
									<ChallengeProgressBar
										isCompleted={!!challengeIsCompleted}
										quantityReached={quantityReached}
										totalQuantity={
											totalProductsToCompleteTheChallenge
										}
									/>
									{challengeIsCompleted ? (
										<S.CompletedChallengeLabel>
											Desafio concluído
										</S.CompletedChallengeLabel>
									) : null}
								</S.BarWrapper>

								{!challengeIsCompleted ? (
									<S.ContinueWithPurchaseButton
										onClick={
											thisChallengeIsMixType
												? continueBuyingTheProductMixInItsEntirety
												: goToCartScreen
										}
										disabled={
											theUserCantContinueThePurchase
										}>
										Continuar
									</S.ContinueWithPurchaseButton>
								) : (
									<S.ComplementaryElement
										style={{ width: '152px' }}
									/>
								)}
							</S.ProgressBarWrapper>
						</S.ChallengeProgressSession>
					) : isFetchingProductsOfTheChallenge ? (
						<S.LoadingWrapper style={{ maxHeight: '20px' }}>
							<Loading />
						</S.LoadingWrapper>
					) : (
						<S.ButtonActionWrapper>
							<S.JoinTheChallengeButton
								onClick={
									handleShowModalWithChallengeMembershipTerms
								}>
								participar do desafio
							</S.JoinTheChallengeButton>
						</S.ButtonActionWrapper>
					)}

					{productsOfTheChallenge.length &&
					!isFetchingProductsOfTheChallenge ? (
						<S.ProductsWrapper>
							{productsOfTheChallenge.map((product) => (
								<ChallengeItem
									isChallengeMix={!!thisChallengeIsMixType}
									userIsNotParticipatingInTheChallenge={
										!userIsAlreadyParticipatingInTheChallenge
									}
									key={product.MaterialID}
									allProductsThatArePartOfTheMix={
										allProductsThatArePartOfTheMix
									}
									addingItemToTheProductMix={
										addingItemToTheProductMix
									}
									challenge={challenge}
									productItem={product}
								/>
							))}
						</S.ProductsWrapper>
					) : !productsOfTheChallenge.length &&
					  isFetchingProductsOfTheChallenge ? (
						<S.LoadingWrapper>
							<Loading />
						</S.LoadingWrapper>
					) : null}
				</S.ContentWrapper>
			</S.ChallengeDetailsWrapper>

			<Fragment>
				<ChallengeMembershipTerms
					challenge={challenge}
					onClose={handleShowModalWithChallengeMembershipTerms}
					active={showModalWithChallengeMembershipTerms}
				/>
			</Fragment>
		</Fragment>
	)
}
