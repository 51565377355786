import React, { useEffect, useState } from 'react'
import Modal from '../ModalPrimary'

import { IFilterRulePoint } from '../../shared/interfaces/pointsProgramRules'
import { IFilterChallenge } from '../../shared/interfaces/pointsProgramChallenge'

import * as S from './styles'
import { getChannelParameters } from '../../shared/services/pointProgramRules.service'

interface IParametersDetailsModalProps {
	showModal: boolean
	title: string
	parameters: IFilterRulePoint[] | IFilterChallenge[]
	handlerCloseModal: () => void
}

const ParametersDetailsModal = ({
	title,
	showModal,
	parameters,
	handlerCloseModal
}: IParametersDetailsModalProps) => {
	const [parameterDirector, setParameterDirector] = useState<string[]>([])
	const [parameterGov, setParameterGov] = useState<string[]>([])
	const [parameterState, setParameterState] = useState<string[]>([])
	const [parameterCity, setParameterCity] = useState<string[]>([])
	const [parameterResale, setParameterResale] = useState<string[]>([])
	const [parameterChannel, setParameterChannel] = useState<string[]>([])
	const [parameterPDV, setParameterPDV] = useState<string[]>([])

	const settingAllParameters = () => {
		;(async () => {
			// @ts-ignore
			const parametersReducer = parameters.reduce(
				(
					acc: { [key: string]: string[] },
					value: { paramName: string; paramValue: string }
				) => {
					acc[value.paramName]?.push(value.paramValue)
					return acc
				},
				{
					DIRECTOR: [],
					CITY: [],
					GOV: [],
					STATE: [],
					RESALE: [],
					CHANNEL: [],
					PDV: []
				}
			)
			if (parametersReducer.CHANNEL) {
				const channels = await getChannelParameters()
				setParameterChannel(
					parametersReducer.CHANNEL.map((channel: string) => {
						const findChannelOption = channels.find((opt) => {
							return opt.value === channel
						})
						return findChannelOption?.label || channel
					})
				)
			}
			setParameterDirector(parametersReducer.DIRECTOR)
			setParameterGov(parametersReducer.GOV)
			setParameterState(parametersReducer.STATE)
			setParameterCity(parametersReducer.CITY)
			setParameterResale(parametersReducer.RESALE)
			setParameterPDV(parametersReducer.PDV)
		})()
	}

	useEffect(settingAllParameters, [parameters])

	return (
		<Modal
			isActive={showModal}
			title={title}
			onClose={handlerCloseModal}
			size={424}>
			<S.ModalContent>
				{parameterDirector.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>Diretor</S.ParameterName>
						<div>
							{parameterDirector.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterGov.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>Gov</S.ParameterName>
						<div>
							{parameterGov.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterState.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>UF</S.ParameterName>
						<div>
							{parameterState.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterCity.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>Cidade</S.ParameterName>
						<div>
							{parameterCity.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterChannel.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>Canal</S.ParameterName>
						<div>
							{parameterChannel.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterResale.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>Revenda</S.ParameterName>
						<div>
							{parameterResale.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
				{parameterPDV.length ? (
					<S.ParameterWrapper>
						<S.ParameterName>CÓDIGO PDV</S.ParameterName>
						<div>
							{parameterPDV.map((item, index) => (
								<S.ParameterValue key={`${index}-${item}`}>
									{item}
								</S.ParameterValue>
							))}
						</div>
					</S.ParameterWrapper>
				) : null}
			</S.ModalContent>
		</Modal>
	)
}

export default ParametersDetailsModal
