import { IOrderItem } from '../interfaces/orderDetail'

export const parseItemPrice = (price: string | number): string => {
	const opts = { minimumFractionDigits: 2 }

	const formattedPrice = Number(price).toLocaleString('pt-BR', opts)

	return `R$ ${formattedPrice}`
}

export function toMoney(value: number): string {
	if (isNaN(value)) return ''

	// According to MDN and Node documentation itself, Node.js support only en-US locale
	// https://stackoverflow.com/questions/60011347/intl-numberformat-doesnt-convert-to-pt-br-locale
	return MONEY_FORMATTER.format(value)
}

export function toNumber(value: string): number {
	return Number(value.replace(MONEY_REGEX, '')) / 100
}

export const MONEY_REGEX = /[R$,.]+/g

export const MONEY_FORMATTER = new Intl.NumberFormat('pt-BR', {
	style: 'currency',
	currency: 'BRL'
})

export const paymentMethods: any = {
	5: 'BOLETO',
	X: 'CARTAO DE CREDITO',
	A: 'CARTAO DE DEBITO',
	G: 'REC. ANTECIPADO',
	H: 'CHEQUE',
	L: 'DINHEIRO',
	S: 'NOTA ASSINADA',
	U: 'TRANS. BANCARIA',
	3: 'CARTAO DE CREDITO',
	P: 'PIX'
}

const paymentConditions = {
	R001: { daysToPay: 0, numberOfParcels: 1 },
	R003: { daysToPay: 1, numberOfParcels: 1 },
	R007: { daysToPay: 2, numberOfParcels: 1 },
	R009: { daysToPay: 3, numberOfParcels: 1 },
	R010: { daysToPay: 4, numberOfParcels: 1 },
	R011: { daysToPay: 5, numberOfParcels: 1 },
	R013: { daysToPay: 6, numberOfParcels: 1 },
	R014: { daysToPay: 7, numberOfParcels: 1 },
	R018: { daysToPay: 8, numberOfParcels: 1 },
	R020: { daysToPay: 9, numberOfParcels: 1 },
	R021: { daysToPay: 10, numberOfParcels: 1 },
	R025: { daysToPay: 11, numberOfParcels: 1 },
	R026: { daysToPay: 12, numberOfParcels: 1 },
	R028: { daysToPay: 13, numberOfParcels: 1 },
	R029: { daysToPay: 14, numberOfParcels: 1 },
	R030: { daysToPay: 14, numberOfParcels: 2 },
	R032: { daysToPay: 15, numberOfParcels: 1 },
	R036: { daysToPay: 16, numberOfParcels: 1 },
	R037: { daysToPay: 17, numberOfParcels: 1 },
	R038: { daysToPay: 18, numberOfParcels: 1 },
	R040: { daysToPay: 19, numberOfParcels: 1 },
	R041: { daysToPay: 20, numberOfParcels: 1 },
	R043: { daysToPay: 21, numberOfParcels: 1 },
	R048: { daysToPay: 22, numberOfParcels: 1 },
	R050: { daysToPay: 23, numberOfParcels: 1 },
	R052: { daysToPay: 24, numberOfParcels: 1 },
	R053: { daysToPay: 29, numberOfParcels: 1 },
	R054: { daysToPay: 25, numberOfParcels: 1 },
	R055: { daysToPay: 26, numberOfParcels: 1 },
	R057: { daysToPay: 27, numberOfParcels: 1 },
	R058: { daysToPay: 28, numberOfParcels: 1 },
	R063: { daysToPay: 30, numberOfParcels: 1 },
	R068: { daysToPay: 31, numberOfParcels: 1 },
	R069: { daysToPay: 32, numberOfParcels: 1 },
	R070: { daysToPay: 34, numberOfParcels: 1 },
	R071: { daysToPay: 35, numberOfParcels: 1 },
	R072: { daysToPay: 36, numberOfParcels: 1 },
	R073: { daysToPay: 38, numberOfParcels: 1 },
	R074: { daysToPay: 40, numberOfParcels: 1 },
	R075: { daysToPay: 42, numberOfParcels: 1 },
	R077: { daysToPay: 43, numberOfParcels: 1 },
	R078: { daysToPay: 45, numberOfParcels: 1 },
	R080: { daysToPay: 47, numberOfParcels: 1 },
	R081: { daysToPay: 49, numberOfParcels: 1 },
	R082: { daysToPay: 50, numberOfParcels: 1 },
	R083: { daysToPay: 53, numberOfParcels: 1 },
	R085: { daysToPay: 55, numberOfParcels: 1 },
	R086: { daysToPay: 56, numberOfParcels: 1 },
	R087: { daysToPay: 57, numberOfParcels: 1 },
	R090: { daysToPay: 60, numberOfParcels: 1 },
	R096: { daysToPay: 90, numberOfParcels: 1 },
	R097: { daysToPay: 120, numberOfParcels: 1 },
	R215: { daysToPay: 33, numberOfParcels: 1 },
	R216: { daysToPay: 37, numberOfParcels: 1 },
	R217: { daysToPay: 39, numberOfParcels: 1 },
	R218: { daysToPay: 41, numberOfParcels: 1 },
	R219: { daysToPay: 44, numberOfParcels: 1 },
	R220: { daysToPay: 46, numberOfParcels: 1 },
	R221: { daysToPay: 48, numberOfParcels: 1 },
	R222: { daysToPay: 51, numberOfParcels: 1 },
	R226: { daysToPay: 63, numberOfParcels: 1 },
	R257: { daysToPay: 99, numberOfParcels: 1 },
	R319: { daysToPay: 365, numberOfParcels: 1 },
	R324: { daysToPay: 10, numberOfParcels: 2 },
	R325: { daysToPay: 10, numberOfParcels: 3 },
}

export function generatePaymentTermsDescription(
	paymentConditionID: PaymentConditionsEnum,
	paymentMethodID: PaymentFormsEnum,
	totalValueWithInterest: number,
) {
	const paymentCondition = paymentConditions[paymentConditionID]

	const paymentMethod = paymentMethods[paymentMethodID]

	if (!paymentCondition || !paymentMethod) return ''

	if (paymentMethod === 'BOLETO') {
		const daysToPay = `EM ${paymentCondition.daysToPay} DIA${
			paymentCondition.daysToPay === 1 ? '' : 'S'
		}`
		return `${paymentMethod} ${daysToPay}`
	} else if (paymentMethod === 'CARTAO DE CREDITO') {
		return `${paymentMethod} ${paymentCondition.numberOfParcels}X de  ${toMoney(
			calculateParcelValue(
				totalValueWithInterest,
				paymentCondition.numberOfParcels,
			),
		)}`
	} else {
		return `${paymentMethod}`
	}
}

export function calculateParcelValue(
	totalValue: number,
	numberOfParcels: number,
): number {
	return numberOfParcels > 1 ? totalValue / numberOfParcels : totalValue
}

export type PaymentConditionsEnum = keyof typeof PaymentConditions

enum PaymentConditions {
	R001,
	R003,
	R007,
	R009,
	R010,
	R011,
	R013,
	R014,
	R018,
	R020,
	R021,
	R025,
	R026,
	R028,
	R029,
	R030,
	R032,
	R036,
	R037,
	R038,
	R040,
	R041,
	R043,
	R048,
	R050,
	R052,
	R053,
	R054,
	R055,
	R057,
	R058,
	R063,
	R068,
	R069,
	R070,
	R071,
	R072,
	R073,
	R074,
	R075,
	R077,
	R078,
	R080,
	R081,
	R082,
	R083,
	R085,
	R086,
	R087,
	R090,
	R096,
	R097,
	R215,
	R216,
	R217,
	R218,
	R219,
	R220,
	R221,
	R222,
	R226,
	R257,
	R319,
	R324,
	R325,
}

export type PaymentFormsEnum = keyof typeof PaymentForms

export enum PaymentForms {
	'BOLETO' = '5',
	A = 'A',
	G = 'G',
	H = 'H',
	L = 'L',
	S = 'S',
	U = 'U',
	X = 'X',
}

export function calcPriceTotal(items: Array<IOrderItem>): number {
	let price = 0
	items.forEach((item) => {
		if (item.operationType === 'Z4BN') return
		price += item.Price * item.Quantity
	})

	return price
}