import React from 'react'

import { IChipWithInformationAboutTheChallengeProps } from './types'

import * as S from './styles'

export default function ChipWithInformationAboutTheChallenge(
	props: IChipWithInformationAboutTheChallengeProps
) {
	const { label, type, beingDisplayedInSomePurchaseFlow } = props
	return (
		<S.ChipWithInformationAboutTheChallengeWrapper
			beingDisplayedInSomePurchaseFlow={beingDisplayedInSomePurchaseFlow}
			type={type}>
			<S.ChipWithInformationAboutTheChallengeText type={type}>
				{label}
			</S.ChipWithInformationAboutTheChallengeText>
		</S.ChipWithInformationAboutTheChallengeWrapper>
	)
}
