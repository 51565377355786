import styled from 'styled-components'
import { Card } from '@material-ui/core'

export const WrapperModalContent = styled.div`
	background-color: ${(props) => props.theme.palette.background.paper};
	position: absolute;

	@media only screen and (max-width: 1500px) {
		top: 5%;
		left: 20%;
	}

	top: 20%;
	left: 30%;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.MD}px;
	border-radius: 5px;

	@media only screen and (max-width: 1500px) {
		height: 90%;
	}

	height: 70%;
	width: 762px;
	padding: ${(props) => props.theme.spacingOptions.SM}px;
`

export const WrapperHeaderModal = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;

	svg {
		color: #5d615d;
		:hover {
			cursor: pointer;
		}
	}
`

export const WrapperMainDetailsContent = styled.div`
	display: grid;
	grid-template-columns: 333px 381px;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	height: 100%;
`
export const WrapperDetailsContent = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.LG}px;
	height: 100%;

	h2 {
		color: #5d615d;
		font-size: ${props=> props.theme.font.size.TEXT_XSM.line_height}rem;
	}
`
export const StyledCard = styled(Card)`
	display: flex;
	height: 25%;
	width: 333px;
	align-items: center;
	justify-content: space-between;
	padding: ${(props) => props.theme.spacingOptions.SM}px;

	.qtd-order-points {
		color: #979e97;
		font-family: Inter;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-style: normal;
		font-weight: ${props=> props.theme.typography.fontWeightBold};
		line-height: normal;
	}
	div {
		padding: ${(props) => props.theme.spacingOptions.SM}px;
	}
	.price-order-points {
		color: #5d615d;
		font-family: Inter;
		font-size: ${props=> props.theme.font.size.TEXT_XSM.line_height}rem;
		font-style: normal;
		font-weight: ${props=>props.theme.typography.fontWeightRegular};
		line-height: normal;
	}
	.earning-points {
		color: #5ea715;
		font-family: Inter;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-style: normal;
		font-weight: ${props=>props.theme.typography.fontWeightRegular};
		line-height: normal;
	}
`

export const StyledSmallCard = styled(Card)`
	display: flex;
	height: 53px;
	width: 333px;
	align-items: center;
	justify-content: space-between;
	padding: ${(props) => props.theme.spacingOptions.SM}px;

	.qtd-order-points {
		color: #979e97;
		font-family: Inter;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-style: normal;
		font-weight: ${props=> props.theme.typography.fontWeightBold};
		line-height: normal;
	}
	div {
		padding: ${(props) => props.theme.spacingOptions.SM}px;
	}
	.price-order-points {
		color: #5d615d;
		font-family: Inter;
		font-size: ${props=> props.theme.font.size.TEXT_XSM.line_height}rem;
		font-style: normal;
		font-weight: ${props=>props.theme.typography.fontWeightRegular};
		line-height: normal;
	}
	.earning-points {
		color: #5ea715;
		font-family: Inter;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-style: normal;
		font-weight: ${props=>props.theme.typography.fontWeightRegular};
		line-height: normal;
	}

	.item {
		color: #979e97;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-weight: ${props=> props.theme.typography.fontWeightBold};
	}

	.value {
		color: #5d615d;
		font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
		font-weight: ${props=> props.theme.typography.fontWeightBold};
	}
`

export const InfoProduct = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 333px;
	height: 100%;
	padding: 12px;
	gap: ${(props) => props.theme.spacingOptions.SM}px;

	border-radius: 5px;
	border: 1px solid #d8d2d3;

	img {
		width: 30px;
		height: 80px;
	}

	.wrapper-info-product {
		width: 100%;
		h3 {
			color: var(--black-10, #3d3d3d);
			font-family: Inter;
			font-size: ${props=> props.theme.font.size.TEXT_XSM.line_height}rem;
			font-style: normal;
			font-weight: ${props=>props.theme.typography.fontWeightRegular};
			line-height: normal;
			text-transform: uppercase;

			margin-bottom: 32px${props=>props.theme.spacingOptions};
		}

		ul {
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;

				padding: 9px 0px;
				border-bottom: 1px solid #d1d1d1;
				&:last-child {
					border-bottom: none;
				}

				.li-category {
					color: #979e97;
					font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
					font-weight: ${props=> props.theme.typography.fontWeightBold};
				}

				.li-value {
					color: #5d615d;
					font-size: ${props=>props.theme.font.size.TEXT_XSM.size}rem;
					font-weight: ${props=> props.theme.typography.fontWeightBold};
				}
			}
		}
	}
`
export const TitleModal = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;

	h3 {
		color: #5d615d;
		font-size: ${props=>props.theme.font.size.TEXT_MD.size}rem;
		font-weight: ${props=> props.theme.typography.fontWeightMedium};
	}
`

export const WrapperMainContext = styled.div`
	height: 100%;
	padding-bottom: ${(props) => props.theme.spacingOptions.SM}px;
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
`
