import { Status } from './types'

export class FetchStatus {
    private status: Status

    get notStarted() {
        return this.status === 'not_started'
    }

    get isFetching() {
        return this.status === 'fetching'
    }

    get notFound() {
        return this.status === 'not_found'
    }

    get hasFound() {
        return this.status === 'has_found'
    }

    constructor(status?: Status) {
        this.status = status || 'not_started'
    }
}