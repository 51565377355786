import React from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { makeStyles } from '@material-ui/core/styles'

import { DataSelectorListProps } from './data-selector-list.types'

export function DataSelectorList({
	title,
	list,
	handleChange,
	selectedOption,
	dataSelectorListID='demo-simple-select'
}: DataSelectorListProps) {
	const useStyles = makeStyles({
		select: {
			'&:after': {
				borderBottomColor: '#CE332E'
			},
			'& .MuiSvgIcon-root': {
				color: '#CE332E'
			}
		}
	})
	const classes = useStyles()

	return (
		<FormControl fullWidth>
			<InputLabel
				id="demo-simple-select-label"
				style={{ color: 'var(--red-10, #CE332E)' }}>
				{title}
			</InputLabel>
			<Select
				labelId="demo-simple-select-label"
				// id="demo-simple-select"
				id={dataSelectorListID}
				value={selectedOption.value}
				label={title}
				className={classes.select}
				onChange={handleChange}
				sx={{
					'&:hover .MuiOutlinedInput-notchedOutline': {
						borderColor: '#CE332E',
						borderWidth: 'thin'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderColor: '#CE332E',
						borderWidth: 'thin'
					}
				}}>
				{list.map((item, index) => (
					<MenuItem key={index} value={item.value}>
						{item.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
