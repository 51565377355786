import React, { Fragment } from 'react'

import {
	Header,
	Loading,
	SearchInputComponent,
	ParametersDetailsModal,
	RuleOrChallengeActionModal,
	Table
} from '../../shared/components'

import { IViewProps } from './types'

import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'

import * as S from './styles'

export default function PointsProgramListOfChallengesView(props: IViewProps) {
	const {
		columns,
		isLoading,
		tableData,
		seekChallengeValue,
		challengeIdToCancelIt,
		changeHandlerSeekChallengeValue,
		showModalWithParametersOfTheChallenge,
		handleGoToTheNewChallengeCreationScreen,
		showChallengeCancellationConfirmationModal,
		modalWithParametersDetailsOfTheChallengeData,
		handlerCloseModalWithParametersOfTheChallenge,
		handleClosingChallengeCancellationConfirmationModal
	} = props
	return (
		<DefaultLayoutAdmin>
			<S.PageWrapper>
				<ParametersDetailsModal
					title="Parâmetros do Desafio"
					showModal={showModalWithParametersOfTheChallenge}
					parameters={modalWithParametersDetailsOfTheChallengeData}
					handlerCloseModal={
						handlerCloseModalWithParametersOfTheChallenge
					}
				/>

				<RuleOrChallengeActionModal
					type="CHALLENGE"
					screenAction="LISTING"
					typeAction="CANCELLATION"
					ruleOrChallengeID={challengeIdToCancelIt}
					showModal={showChallengeCancellationConfirmationModal}
					handleCloseModal={
						handleClosingChallengeCancellationConfirmationModal
					}
				/>
				<S.ChallengesPageWrapper>
					<S.ListOfChallengesTitle>Desafios</S.ListOfChallengesTitle>
					<S.SearchInputAndButtonNewChallengeWrapper>
						<SearchInputComponent
							id="searchChallenge"
							label="Buscar desafio"
							value={seekChallengeValue}
							style={{ minWidth: '360px' }}
							onChange={changeHandlerSeekChallengeValue}
						/>
						<div className="buttonContainer">
							<button
								onClick={
									handleGoToTheNewChallengeCreationScreen
								}
								className="red-button">
								NOVO DESAFIO
							</button>
						</div>
					</S.SearchInputAndButtonNewChallengeWrapper>
					<div>
						{isLoading ? (
							<S.LoadingWrapper className="loading-wrapper">
								<Loading />
							</S.LoadingWrapper>
						) : (
							<Table data={tableData} columns={columns} />
						)}
					</div>
				</S.ChallengesPageWrapper>
			</S.PageWrapper>
		</DefaultLayoutAdmin>
	)
}
