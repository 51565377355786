import styled from 'styled-components'
import { colorThemeProvider } from '../../theme/themeProvider'

export const Field = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 18px 16px;
	border-radius: 0.3125rem;
	border: 1px solid var(--stroke-10, ${colorThemeProvider.extrasColor});
	user-select: none;
	cursor: pointer;

	&:hover {
		border: 1px solid var(--stroke-10, ${colorThemeProvider.lightGrayColor});
	}

	div {
		display: flex;
		align-items: center;

		img {
			padding-right: 16px;
		}
	}
`
