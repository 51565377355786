import { Auth } from 'aws-amplify'
import cogoToast from 'cogo-toast'
import { createElement, useState } from 'react'
import { useHistory } from 'react-router-dom'

import cogoDefaultOptions from '../../shared/utils/toaster'
import handleError from '../Error/handleError'
import { CustomerRoutesEnum } from '../Routes/customerRoutesEnum'
import { IViewProps } from './types'
import ChangePersonalPassword from './view'

function MyAccountContainer(): JSX.Element {
	const [password, setPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [passwordIsValid, setPasswordIsValid] = useState(false)
	const [newPasswordIsValid, setNewPasswordIsValid] = useState(false)
	const [confirmationIsValid, setConfirmationIsValid] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const history = useHistory()

	const canProceed =
		passwordIsValid && newPasswordIsValid && confirmationIsValid

	async function handleSavePassword() {
		try {
			setIsLoading(true)

			const user = await Auth.currentAuthenticatedUser()
			if (!user) return

			const res = await Auth.changePassword(user, password, newPassword)
			if (!res) return

			cogoToast.success('Senha Alterada com sucesso!', cogoDefaultOptions)
		} catch (error) {
			handleError(error as any)
		} finally {
			setIsLoading(false)
		}
	}

	function handleBack() {
		history.replace(CustomerRoutesEnum.MY_ACCOUNT)
	}

	const viewProps: IViewProps = {
		canProceed,
		handleSavePassword,
		newPassword,
		setPassword,
		setPasswordIsValid,
		setNewPassword,
		setNewPasswordIsValid,
		setConfirmationIsValid,
		isLoading,
		handleBack
	}

	return createElement(ChangePersonalPassword, viewProps)
}

export default MyAccountContainer
