import { ConfirmationMethods } from "./types"

export function formatConfirmationCodeMethodParams(
    selectedConfirmationMethod: ConfirmationMethods
): {
    sendemail: 'X' | '',
    sendsms: 'X' | '',
} {
    const confirmationMethodIsSMS = selectedConfirmationMethod === 'sendsms'
    const confirmationMethodIsEmail = !confirmationMethodIsSMS

    return {
        sendemail: confirmationMethodIsEmail ? 'X' : '',
        sendsms: confirmationMethodIsSMS ? 'X' : '',
    }
}