import React from 'react'
import * as S from './email-phone-registration.styles'

import TextField from '../../../../components/inputs/text-field/text-field.component'
import { TypeEnum } from '../../../../components/inputs/text-field/text-field.types'
import { WrapperButton } from '../../reset-password.styles'
import ButtonDefault from '../../../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../../../components/buttons/button/button-default.types'
import {
	EmailPhoneRegistrationrops,
	creationFormTypeEnum
} from './email-phone-registration.types'
import { TypeContact } from '../code-validation/code-validation.types'
import { verifyIsEmailValid } from '../../../../utils/verify-is-valid-email'
import {
	updateEmailCognito,
	updateEmailCognitoWithValidation,
	updatePhoneCognito,
	updatePhoneCognitoWithValidation
} from '../../reset-password.service'
import { maskEmail, maskCellPhoneNumber } from '../../../../utils/mask-data'
import { FormControlLabel, Radio } from '@material-ui/core'
import InfoMenssage from '../../../../components/info-menssage/info-menssage.componente'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { EnumTypeFlow } from '../../reset-password.types'

export function EmailPhoneRegistrationComponent({
	goStep,
	typeContact,
	setContactData,
	customerDocument,
	newEmail,
	setNewEmail,
	newPhone,
	setNewPhone,
	setActivationType,
	activationType,
	isPasswordValidated,
	choiceInitialContact,
	setIsLoading,
	setTypeFlow,
	typeFlow
}: EmailPhoneRegistrationrops) {
	const [isErrorEmail, setIsErrorEmail] = React.useState<boolean>(false)
	const [isErrorContact, setIsErrorContact] = React.useState<boolean>(false)

	const [email, setEmail] = React.useState<string>('')

	const [contact, setContact] = React.useState<string>('')

	const [isValidData, setIsValidData] = React.useState(false)

	React.useEffect(() => {
		// Validação de contact
		const contactError = newPhone.length > 0 && contact !== newPhone
		setIsErrorContact(contactError)

		// Validação de email
		const emailError = newEmail.length > 0 && email !== newEmail
		setIsErrorEmail(emailError)

		// Verifica se os dados de email são válidos
		const isEmailValid = Boolean(
			email.length > 0 &&
				newEmail.length > 0 &&
				verifyIsEmailValid(email) &&
				verifyIsEmailValid(newEmail)
		)

		setIsValidData(isEmailValid)
		//Verifica se os dados de telefone estão validos
		const isContactValid = contact.length > 0 && newPhone.length > 0

		setIsValidData(isContactValid)
	}, [
		contact,
		newPhone,
		email,
		newEmail,
		isValidData,
		typeContact,
		activationType
	])

	async function confirm() {
		try {
			setIsLoading(true)
			const isEmailRecover = activationType === creationFormTypeEnum.email
			if (!isPasswordValidated) {
				await updateEmailCognito(customerDocument, newEmail)
				await updatePhoneCognito(customerDocument, newPhone)
				setContactData({
					validationChoice: isEmailRecover
						? maskEmail(newEmail)
						: maskCellPhoneNumber(newPhone),
					validationType: isEmailRecover
						? TypeContact.NEW_EMAIL
						: TypeContact.NEW_PHONE
				})
			} else {
				//Adicionar outrois meios de conforção
				setContactData({
					validationChoice: isEmailRecover
						? maskEmail(newEmail)
						: maskCellPhoneNumber(newPhone),
					validationType: isEmailRecover
						? TypeContact.NEW_EMAIL
						: TypeContact.NEW_PHONE
				})
				if (isEmailRecover) {
					await updateEmailCognitoWithValidation(
						customerDocument,
						newEmail
					)
					await updatePhoneCognito(customerDocument, newPhone)
				} else {
					await updatePhoneCognitoWithValidation(
						customerDocument,
						newPhone
					)
					await updateEmailCognito(customerDocument, newEmail)
				}
			}

			if (typeFlow !== EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED) {
				setTypeFlow(EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED)
				goStep(1)
			}

			if (typeFlow === EnumTypeFlow.ALTERNATIVEFLOW_CONCLUDED) {
				goStep(1)
			}

			if (typeFlow === EnumTypeFlow.SECURITY_QUESTIONS) {
				goStep(0)
			}
		} catch (error) {
			console.error(error)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			<S.EmailPhoneRegistrationView>
				<S.Title>Insira novos dados de acesso</S.Title>

				<S.WrapperFields>
					<>
						<S.ContactsFields>
							<TextField
								label={'E-mail'}
								placeholder={'Digite o seu e-mail'}
								handleChangeText={setEmail}
								text={email}
								type={TypeEnum.TEXT}
								isError={false}
							/>
							<TextField
								label={'Confirmar e-mail'}
								placeholder={'Confirme o seu e-mail'}
								handleChangeText={setNewEmail}
								text={newEmail}
								type={TypeEnum.TEXT}
								isError={isErrorEmail}
							/>
						</S.ContactsFields>

						<S.ContactsFields>
							<TextField
								label={'Celular'}
								placeholder={'(00) 0 0000-0000'}
								handleChangeText={setContact}
								text={contact}
								type={TypeEnum.TEXT}
								isError={false}
							/>
							<TextField
								label={'Confirmar Celular'}
								placeholder={'(00) 0 0000-0000'}
								handleChangeText={setNewPhone}
								text={newPhone}
								type={TypeEnum.TEXT}
								isError={isErrorContact}
							/>
						</S.ContactsFields>
						<S.StyledRadioGroup>
							<h2>Como deseja ativar a sua conta?</h2>
							<div className="wrapper-radio-recover-account">
								<FormControlLabel
									value="recover-by-sms"
									control={
										<Radio
											checked={
												activationType ===
												creationFormTypeEnum.cell
											}
											onChange={() => {
												setActivationType(
													creationFormTypeEnum.cell
												)
											}}
										/>
									}
									label="Receber por SMS"
								/>
								<FormControlLabel
									value="recover-by-email"
									control={
										<Radio
											checked={
												activationType ===
												creationFormTypeEnum.email
													? true
													: false
											}
											onChange={() => {
												setActivationType(
													creationFormTypeEnum.email
												)
											}}
										/>
									}
									label="Receber por e-mail"
								/>
							</div>
						</S.StyledRadioGroup>
						<InfoMenssage
							icon={<InfoOutlinedIcon />}
							message="Verifique se todas as informações digitadas estão
						corretas"
						/>
					</>
				</S.WrapperFields>
			</S.EmailPhoneRegistrationView>
			<WrapperButton>
				<ButtonDefault
					buttonText="Voltar"
					type={TypeButtonEnum.OUTLINED}
					onClick={() =>
						// Não alterar a linha abaixo
						typeFlow === EnumTypeFlow.ALTERNATIVEFLOW
							? goStep(1)
							: typeFlow === EnumTypeFlow.SECURITY_QUESTIONS &&
							  goStep(4)
					}
					idElement="cancel"
					width="234px"
				/>
				<ButtonDefault
					buttonText="Continuar"
					type={
						isValidData && activationType
							? TypeButtonEnum.PRIMARY
							: TypeButtonEnum.DISABLED
					}
					onClick={() => confirm()}
					idElement="confirm"
					width="234px"
				/>
			</WrapperButton>
		</>
	)
}

export default EmailPhoneRegistrationComponent
