import React from 'react'
import { IProductSelectorProps } from './product-selector.component.types'
import DualListBoxComponent from '../../../../../components/DualListBoxComponent/DualListBoxComponent.view'
import ErrorText from '../../../../../components/error-text/error-text.component'
import { Stack } from '@mui/material'

export function ProductSelector({
	selectedProducts,
	disposabledProducts,
	isLoading,
	errorMessage,
	isDisabled,
	initialSelectedValues
}: IProductSelectorProps) {
	return (
		<Stack alignItems={'center'} justifyContent={'center'} display={'flex'}>
			<DualListBoxComponent
				labelKey="enabledValues"
				valueKey="SelectedValues"
				options={disposabledProducts.data}
				sortedOptions={selectedProducts}
				searchTitle="Buscar produtos"
				firstDescription="Listagem de Produtos"
				secondDescription="Produtos Inseridos"
				preserveSelectOrder={false}
				totalOptions={disposabledProducts.size}
				simplifiedSelected={false}
				isLoading={isLoading}
				isDisabled={isDisabled}
				initialSelectedValues={initialSelectedValues}
			/>
			<ErrorText error={errorMessage} />
		</Stack>
	)
}
