import Radio from '@mui/material/Radio'
import styled, { css } from 'styled-components'
import { FormControlLabel } from '@material-ui/core'

export const StyledFormControlLabel = styled(FormControlLabel)`
	${({ theme }) => css`
		span {
			&.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
				color: ${theme.palette.black.main};
				font-size: ${theme.font.size.TEXT_SM.size}rem;
			}
			&.Mui-disabled {
				color: ${theme.palette.gray.light};

				:hover {
					cursor: not-allowed;
				}
			}
		}
	`}
`

export const StyledRadio = styled(Radio)`
	${({ theme }) => css`
		&.MuiRadio-root {
			color: ${theme.palette.extras.stroke.main};
			&.Mui-checked {
				color: ${theme.palette.primary.main};
			}
		}
	`}
`

export const RadioButtonListWrapper = styled('a')`
	${({ theme }) => css`
		display: flex;
		gap: ${theme.spacingOptions.XLG}px;
	`}
`
