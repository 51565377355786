import styled from 'styled-components'

import { colors } from '../../../shared/styles/theme'
import { colorThemeProvider } from '../../../theme/themeProvider'
import { IStyleProps } from './type'

export const ChallengeItemWrapper = styled.div<IStyleProps>`
	width: 100%;
	max-width: 260px;
	min-height: 342px;
	border-radius: 4px;
	border: 1px solid
		${({ itsInTheCart, theMinimumAmountHasYetToBeReached }) => {
			return itsInTheCart && theMinimumAmountHasYetToBeReached
				? colors.green
				: itsInTheCart && !theMinimumAmountHasYetToBeReached
				? colors.red
				: colors.greyExtraLight
		}};
	background-color: ${colors.white};
	padding: 16px 16px 24px 16px;
`

export const ProductImageWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
`

export const ProductImage = styled.img`
	width: 80px;
	height: 80px;
	object-fit: cover;
`

export const ProductDescriptionWrapper = styled.div`
	margin-bottom: 29px;
`

export const ProductDescription = styled.span`
	max-width: 228px;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
`

export const ProductPriceWrapper = styled.div`
	width: 100%;
	margin-bottom: 8px;

	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const LabelPrice = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	color: ${colors.blackLight};
`

export const PriceValue = styled(LabelPrice)<IStyleProps>`
	color: ${({ isActionPrice }) =>
		!isActionPrice ? colors.blackLight : colors.red};
`

export const UnitPriceWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`

export const UnitPriceLabel = styled.label`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	text-transform: uppercase;
	color: ${colorThemeProvider.grayColor};
`

export const unitPriceValue = styled(UnitPriceLabel)<IStyleProps>`
	text-transform: none;
	color: ${({ isActionPrice }) =>
		!isActionPrice ? colorThemeProvider.grayColor : colorThemeProvider.primaryColor};
`

export const ProductOlderPrice = styled.span`
	color: ${(props) => props.theme.palette.gray.main};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
	text-decoration: line-through;
`

export const ProductPriceActionWrapper = styled.div`
	display: flex;
	gap: 3px;
	align-items: center;
`

export const BedgeMinimumQuantityWrapper = styled.div<IStyleProps>`
	width: 100%;
	padding: 16px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	margin-bottom: 8px;
	justify-content: space-between;
	background-color: ${({
		itsInTheCart,
		theMinimumAmountHasYetToBeReached
	}) => {
		return itsInTheCart && theMinimumAmountHasYetToBeReached
			? colors.scoreValueLabelBg
			: itsInTheCart && !theMinimumAmountHasYetToBeReached
			? colors.redExtraLight
			: colors.fifthGray
	}};
`

export const BedgeMinimumQuantityText = styled.span<IStyleProps>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	text-transform: uppercase;
	color: ${({ itsInTheCart, theMinimumAmountHasYetToBeReached }) => {
		return itsInTheCart && theMinimumAmountHasYetToBeReached
			? colors.green
			: itsInTheCart && !theMinimumAmountHasYetToBeReached
			? colors.red
			: colors.secondGrey
	}};
`

export const AlertsAboutItem = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`

export const BedgeCart = styled.div<IStyleProps>`
	width: 33px;
	height: 23px;
	padding: 6px;
	gap: 2px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 12px;
	background-color: ${({
		itsInTheCart,
		theMinimumAmountHasYetToBeReached
	}) => {
		return itsInTheCart && theMinimumAmountHasYetToBeReached
			? colors.scoreValueLabelBg
			: colors.red
	}};
`

export const BedgeCartText = styled.span<IStyleProps>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.medium};
	color: ${({ itsInTheCart, theMinimumAmountHasYetToBeReached }) => {
		return itsInTheCart && theMinimumAmountHasYetToBeReached
			? colors.green
			: colors.white
	}};
`

export const ActionsButtonWrapper = styled.section``
