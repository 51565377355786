export const orderOptionsData = {
	Categorias: {
		subtitlePage: 'Categoria',
		labelKey: 'description',
		valueKey: 'ID',
		textOrderData:
			'Utilize a lista suspensa abaixo para adicionar as categorias em uma nova ordem de exibição.',
		searchTitle: 'Buscar Categorias',
		firstDescription:
			'Lista de categorias exibido conforme ordem atual de visualização',
		secondDescription: 'Nova ordem de exibição de categorias'
	},
	Produtos: {
		subtitlePage: 'Produtos por categoria',
		labelKey: 'Description',
		valueKey: 'MaterialID',
		textOrderData:
			'Utilize a lista suspensa abaixo para adicionar os produtos em uma nova ordem de exibição.',
		searchTitle: 'Buscar Produtos',
		firstDescription:
			'Lista de produtos exibido conforme ordem atual de visualização',
		secondDescription: 'Nova ordem de exibição de produtos'
	}
}
