import React from 'react'

function ProductImage(props: any) {
	const { src, onClick } = props

	return (
		<>
			<div className="image-wrapper">
				<img className="image" src={src} alt="" />
				<button className="red-button" onClick={onClick}>
					x
				</button>
			</div>
		</>
	)
}

export default ProductImage
