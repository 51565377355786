import React, { Fragment } from 'react'

import CartTable from '../../shared/components/CartTable'
import Header from '../../components/Header'
import { parseItemPrice } from '../../shared/utils/money'
import ReturnableAgreement from './ReturnableAgreement'
import { IViewProps } from './types'
import { ReactComponent as ArrowLeftBlackIcon } from '../../assets/images/arrow-left-black.svg'
import { ChallengeCartItemBlockOfProducts } from '../../shared/components'
import { OrderSummaryPaymentInfo } from '../../components/OrderSummaryPaymentInfo'

import * as S from './styles'

function Cart(props: IViewProps): JSX.Element {
	const {
		showAlert,
		totalPrice,
		handleBack,
		canProceed,
		cartProducts,
		handleProceed,
		cartChallengeItems,
		insufficientPoints,
		totalPointsRedeemed,
		returnableItemsExists,
		handleClientAgreement
	} = props
	const totalPriceLabel = parseItemPrice(totalPrice)

	function renderingLisOfIndividualProducts(): JSX.Element {
		return (
			<div
				className="table-wrapper col-12"
				style={{ paddingBottom: '16px' }}>
				<CartTable
					isEditable
					tableData={cartProducts}
					totalPointsRedeemed={totalPointsRedeemed}
				/>
			</div>
		)
	}

	const renderChallengeItems = () => {
		return (
			<Fragment>
				{cartChallengeItems.map((cartChallengeItem) => (
					<section
						style={{ marginBottom: '8px' }}
						key={cartChallengeItem.challengeId}>
						<ChallengeCartItemBlockOfProducts
							cartchallenge={cartChallengeItem}
						/>
					</section>
				))}
			</Fragment>
		)
	}

	// function renderOrderSummary(): JSX.Element {
	// 	return (
	// 		<div className="col-12 col-lg-4">
	// 			<div className="totals">
	// 				<h4 className="totals-title">{'Resumo do Pedido'}</h4>

	// 				<div className="totals-item">
	// 					<p className="totals-description">{'Produtos'}</p>
	// 					<p className="totals-value">{tableData.length}</p>
	// 				</div>
	// 				<div className="totals-item">
	// 					<p className="totals-description">{'Desconto'}</p>
	// 					<p className="totals-discount">
	// 						{parseItemPrice(cartDiscount)}
	// 					</p>
	// 				</div>
	// 				{customerEarnsPoints && totalPointsTheProductGives ? (
	// 					<div className="totals-item">
	// 						<p className="totals-description">
	// 							{'Total de Pontos Ganhos nesta Compra'}
	// 						</p>
	// 						<p className="totals-value">
	// 							+{totalPointsTheProductGives} Pontos
	// 						</p>
	// 					</div>
	// 				) : null}
	// 				{totalPointsRedeemed > 0 && (
	// 					<div className="totals-item">
	// 						<p className="totals-description">
	// 							{'Pontos Resgatados'}
	// 						</p>
	// 						<p
	// 							style={{ color: '#5D615D' }}
	// 							className="totals-value">
	// 							-{totalPointsRedeemed}{' '}
	// 							{insufficientPoints && (
	// 								<img
	// 									src={AlertIcon}
	// 									alt="Icone de alerta"
	// 								/>
	// 							)}
	// 						</p>
	// 					</div>
	// 				)}
	// 				<div className="totals-item">
	// 					<p className="totals-description">{'Valor Total'}</p>
	// 					<p className="totals-value">{totalPriceLabel}</p>
	// 				</div>

	// 				{insufficientPoints ? (
	// 					<div
	// 						style={{
	// 							width: '100%',
	// 							display: 'flex',
	// 							alignItems: 'center',
	// 							justifyContent: 'center'
	// 						}}>
	// 						<InsufficientPointsAlert
	// 							cartScreen
	// 							background="#fff"
	// 						/>
	// 					</div>
	// 				) : null}
	// 				<button
	// 					disabled={!canProceed || insufficientPoints}
	// 					onClick={handleProceed}
	// 					className="button">
	// 					{'Confirmar Pedido'}
	// 				</button>

	// 				{showAlert ? (
	// 					<span className="error-message">
	// 						Por favor, selecione que está ciente da devolução
	// 						dos vasilhames.
	// 					</span>
	// 				) : null}
	// 			</div>

	// 			{!!totalPointsTheProductGives && (
	// 				<CardAlertBlockedPoints
	// 					visible={!customerEarnsPoints}
	// 					totalPoints={totalPointsTheProductGives}
	// 				/>
	// 			)}
	// 			{numberValue > totalPrice ? (
	// 				<div className="min-order">
	// 					<p className="min-order-subtitle">
	// 						{'Valor mínimo não foi atingido'}
	// 					</p>

	// 					<p className="min-order-text">
	// 						{`Sua compra precisa atingir o valor mínimo de `}
	// 					</p>

	// 					<p className="min-order-text">{`${minValueLabel}`}</p>
	// 				</div>
	// 			) : null}
	// 		</div>
	// 	)
	// }

	return (
		<Fragment>
			<Header />
			<S.CartScreenWrapper>
				<S.CartScreenContainer>
					<S.GoBackButtonWrapper>
						<S.ButtonGoBack onClick={handleBack}>
							<ArrowLeftBlackIcon />
							Voltar ao Produtos
						</S.ButtonGoBack>
					</S.GoBackButtonWrapper>
				</S.CartScreenContainer>

				{cartChallengeItems.length ? (
					<S.CartScreenContainer>
						<section>
							<S.CartScreenPageTitle>
								Produtos do Desafio
							</S.CartScreenPageTitle>
							{renderChallengeItems()}
						</section>
					</S.CartScreenContainer>
				) : null}

				{!!cartChallengeItems.length && <S.Divider />}

				{!!cartProducts.length && (
					<S.CartScreenContainer>
						<S.CartScreenPageTitle>
							Produtos Individuais
						</S.CartScreenPageTitle>
						<Fragment>
							<div className="row">
								{renderingLisOfIndividualProducts()}
							</div>
						</Fragment>
					</S.CartScreenContainer>
				)}

				{!!cartProducts.length || !!cartChallengeItems.length ? (
					<S.CartScreenContainer>
						<section>
							<Fragment>
								<div className="row">
									<div className="col-12 col-lg-8">
										{returnableItemsExists ? (
											<ReturnableAgreement
												handleClientAgreement={
													handleClientAgreement
												}
												showAlert={showAlert}
											/>
										) : null}
										<div className="button-wrapper">
											<button
												className="gp-button secondary"
												onClick={handleBack}>
												Continuar comprando
											</button>
										</div>
									</div>
									{/* {renderOrderSummary()} */}
									<OrderSummaryPaymentInfo
										canProceed={canProceed}
										handleProceed={handleProceed}
										insufficientPoints={insufficientPoints}
										showAlert={showAlert}
										totalPrice={totalPrice}
										totalPriceLabel={totalPriceLabel}
										title="Resumo do Pedido"
										col="col-12 col-lg-4"
									/>
								</div>
							</Fragment>
						</section>
					</S.CartScreenContainer>
				) : (
					<div className="no-product-placeholder-wrapper">
						<h2>
							{'Adicione itens no seu carrinho para prosseguir'}
						</h2>
					</div>
				)}
			</S.CartScreenWrapper>
		</Fragment>
	)
}

export default Cart
