import styled from 'styled-components'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import { theme } from '../../../../../theme/themeProvider'

export const ContainerDetails = styled.div`
    margin: 1px;
`

export const DetailsIcon = styled(FindInPageOutlinedIcon)`
	color: ${(props) => theme.palette.primary.main};
	&:hover {
		cursor: ${(props) => 'pointer'};
	}
`
