import {
	ChallengeFiltersEnum,
	IAcceptedTerm,
	IChallenge,
} from '../interfaces/pointsProgramChallenge'
import { store } from '../store'
import axiosInstance from './axiosInstace'

interface IFetchNewChallengesProps {
	filter: ChallengeFiltersEnum
	products: Array<{ MaterialID: string }>
}

export async function fetchNewChallenges(
	props: IFetchNewChallengesProps,
): Promise<IChallenge[]> {
	const customer = store.getState().customer

	const response = await axiosInstance.post(
		'program-points-challenges/challenges-by-filter',
		{
			filter: props.filter,
			productsAndUserData: {
				products: props.products,
				customer,
			},
		},
	)

	return response?.data || []
}

export async function fetchMyChallenges({
	ignoreCompletedChallenges
}: { ignoreCompletedChallenges: boolean }): Promise<IChallenge[]> {
	const customer = store.getState().customer
	const response = await axiosInstance.get(
		`program-points-challenges/my-challenges?customerID=${customer.CustomerID}&ignoreCompletedChallenges=${ignoreCompletedChallenges}`,
	)

	return response?.data || []
}

export async function acceptTermOfTheChallenge(
	payload: IAcceptedTerm,
): Promise<any> {
	const response = await axiosInstance.post(
		`/program-points-challenges/accept-challenge-term`,
		{ ...payload },
	)

	return response.data
}
