import React from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

import { BoxInfo, LoadingWrapper, Subtitle, Title, Wrapper } from './styles'
import {
	InputSelectPeriodPicker,
	Table,
	Loading
} from '../../shared/components'
import ButtonPrimary from '../../components/ButtonPrimary'
import {
	primaryButtonStyle,
	primaryMultiselectStyles,
	reactSelectStyles
} from '../../shared/styles/theme'
import { IViewProps } from './types'
import { getDropdownButtonLabelOverride } from '../../shared/utils/multiSelectCheckboxUtils'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'

function ClientsReport(props: IViewProps) {
	const {
		columns,
		loading,
		tableData,
		resaleList,
		handleChangeFilter,
		selectedResales,
		handleSelectDates,
		selectedDates,
		navProps,
		itemsPerPage,
		handleClickFilter,
		status,
		totalSalesOrder
	} = props

	return (
		<DefaultLayoutAdmin>
			<Wrapper>
				<div>
					<Title>Relatório de Cliente</Title>
					<Subtitle>Filtrar por</Subtitle>
				</div>

				<div className="input-wrapper">
					<InputSelectPeriodPicker
						label="Período"
						placeholder="Período"
						onChange={handleSelectDates}
						value={selectedDates}
						disableDatesFuture={true}
					/>

					<div className="select-filter">
						<label className="title">Revenda</label>
						<ReactMultiSelectCheckboxes
							options={resaleList}
							onChange={handleChangeFilter}
							value={selectedResales}
							getDropdownButtonLabel={
								getDropdownButtonLabelOverride
							}
							isMulti
							placeholderButtonLabel="Revenda"
							{...primaryMultiselectStyles}
							styles={reactSelectStyles}
						/>
					</div>

					<ButtonPrimary
						{...primaryButtonStyle}
						onClick={handleClickFilter}>
						FILTRAR
					</ButtonPrimary>
				</div>

				{loading ? (
					<LoadingWrapper>
						<Loading color="red" />
					</LoadingWrapper>
				) : (
					<>
						<div className="card-wrapper">
							<BoxInfo>
								<p>Clientes Cadastrados</p>
								<h1>{navProps.totalDocs}</h1>
							</BoxInfo>

							<BoxInfo>
								<p>Clientes que já realizaram pedidos</p>
								<h1>{totalSalesOrder}</h1>
							</BoxInfo>
						</div>

						<Table
							data={tableData}
							columns={columns}
							navProps={navProps}
							pageSize={itemsPerPage}
							isLoading={status === 'loading'}
						/>
					</>
				)}
			</Wrapper>
		</DefaultLayoutAdmin>
	)
}

export default ClientsReport
