import { TextField } from '@mui/material'
import styled from 'styled-components'

export const TableContainer = styled.div`
    width: 100%;
    max-height: 280px;
    overflow-x: auto;
`;

export const Table = styled.table`
    border-collapse: separate;
    width: calc(100%);
    text-align: left;

    &::-webkit-slider-thumb {
     -webkit-appearance: none;
     width: 15px;
     height: 15px;
     border:1px solid black;
  }
`;

export const TableHead = styled.tr`
    position: sticky;
    top: 0px;
    background-color: #f9f9f9;
    z-index: 1;
`;

export const TableTitle = styled.th`
	font-family: Inter;
	font-size: ${({ theme }) => theme.font.size.TEXT_XS.size}rem;
	font-weight: 400;
	line-height: ${({ theme }) => theme.font.size.TEXT_XS.line_height}rem;
	color: ${({ theme }) => theme.palette.primary.main};
`

export const TableDescription = styled.td`
	font-family: Inter;
	font-size: ${({ theme }) => theme.font.size.TEXT_SM.size}rem;
	font-weight: 400;
	line-height: ${({ theme }) => theme.font.size.TEXT_SM.line_height}rem;
	color: ${({ theme }) => theme.palette.gray.dark};

	vertical-align: middle;
`

export const TableRow = styled.tr`
	height: 89px;
`

export const TableInput = styled(TextField).attrs({
	variant: 'outlined'
})`
	width: 138px;
`

export const TableVoidContainer = styled.div`
	margin-top: 42px;
	padding: 72px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.palette.white.main};
	width: 100%;
`

export const TableVoidTitle = styled.p`
	font-family: Inter;
	font-size: ${({ theme }) => theme.font.size.TEXT_SM.size}rem;
	font-weight: 400;
	line-height: ${({ theme }) => theme.font.size.TEXT_SM.line_height}rem;
	color: ${({ theme }) => theme.palette.gray.main};
`

export const WrapperListBox = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
`
