import styled from 'styled-components'
import { colors } from '../../../../../shared/styles/theme'

export const BoxInfo = styled.div`
	background-color: ${(props) => props.theme.palette.white.main};
	padding: 24px 16px;
	border-radius: 4px;
	margin-bottom: 40px;

	p {
		color: ${colors.secondGrey};
		font-size: ${(props) => props.theme.font.size.TEXT_XL.size}rem;
		margin-top: 8px;
		margin-bottom: 25px;
	}

	h1 {
		color: ${colors.secondGrey};
		font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
		margin-top: 8px;
		margin-bottom: 25px;
	}
`
export const Row = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 25px;
	gap: 20px;

	input {
		background-color: ${(props) => props.theme.palette.white.main};
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: left;

		&[disabled] {
			border: solid 1px #d1d1d1;
			color: ${(props) => props.theme.palette.white.contrastText};

			+ label {
				top: 8px;
				left: 16px;
				color: ${(props) => props.theme.palette.gray.main};
				text-transform: capitalize;
				padding-bottom: 3px;
			}
		}
	}
`
export const Visit = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	gap: 20px;
	width: '50%';
`

export const DaysContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	width: '50%';
	padding: 10px;
	border-radius: 5px;
	border: 1px solid ${colors.lightGrey};
	p {
		color: ${colors.darkGrey};
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	}
`
export const DayVertical = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

export const DayLabel = styled.label`
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 10px;
	gap: 10px;

	input[type='checkbox'] {
		margin-right: 5px;
		width: 20px;
		height: 20px;
		background-color: ${colors.red};
	}
`
