import styled, { css } from 'styled-components'
import { AnnouncementCardTypeEnum } from './types'

import Banner from '../../assets/images/maispontos-banner-large.png'

export const CardWrapper = styled.div<{ cardType: AnnouncementCardTypeEnum }>`
	width: 100%;
	height: 180px;
	max-width: 536px;
	border-radius: 4px;

	${({ cardType }) =>
		cardType === AnnouncementCardTypeEnum.ANNOUNCEMENT &&
		css`
			padding: 48px 36px;

			gap: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			${(props) => props.theme.breakpoints.down('sm')} {
				flex-direction: column;
			}

			background-size: cover;
			background-position: center;
			background-image: url(${Banner});

			.card-announcement-title {
				color: ${(props) => props.theme.palette.white.main};
				font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
				margin-bottom: 13px;
				display: inline-block;

				${(props) => props.theme.breakpoints.down('sm')} {
					font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
					text-align: center;
				}
			}

			.card-announcement-description {
				width: 100%;
				color: ${(props) => props.theme.palette.white.main};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
				max-width: 235px;

				${(props) => props.theme.breakpoints.down('sm')} {
					font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
					text-align: center;
				}
			}

			.see-products-button {
				border: none;
				color: ${(props) => props.theme.palette.primary.main};
				cursor: pointer;
				font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
				font-weight: ${(props) => props.theme.font.weight.bold}
				border-radius: 4px;
				padding: 12px 32px;
				background-color: ${(props) => props.theme.palette.white.main};
			}
		`}

	${({ cardType }) =>
		cardType === AnnouncementCardTypeEnum.FAQ &&
		css`
			display: grid;
			cursor: pointer;
			align-items: center;
			grid-template-columns: 145px 1fr;

			background: ${(props) => props.theme.palette.primaryWhite.main} 0%
				0% no-repeat padding-box;

			${(props) => props.theme.breakpoints.down('sm')} {
				padding-right: 12px;
			}

			.question-illustration {
				> img {
					${(props) => props.theme.breakpoints.down('sm')} {
						width: 130px;
					}
				}
			}

			.title-learn-more {
				color: ${(props) => props.theme.palette.gray.main};
				max-width: 309px;
				font-size: ${(props) => props.theme.font.size.TEXT_XL.size}rem;
				display: inline-block;
				margin-bottom: 13px;

				@media screen and (max-width: 768px) {
					font-size: ${(props) =>
						props.theme.font.size.TEXT_LG.size}rem;
				}
			}

			.description-learn-more {
				max-width: 309px;
				color: ${(props) => props.theme.palette.gray.main};
				font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

				${(props) => props.theme.breakpoints.down('sm')} {
					max-width: 260px;
					font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
				}
			}
		`}
`
