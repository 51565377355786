export const addLabelValue = ({
	options,
	labelKey,
	valueKey
}: IAddLabelValue) => {
	return options.map((option) => ({
		label: option[labelKey],
		value: option[valueKey],
		...option
	}))
}

export const removeLabelValue = ({ options }: IRemoveLabelValue) => {
	return options.map((option) => {
		const { label, value, ...newOption } = option
		return newOption
	})
}

interface IAddLabelValue {
	options: any[]
	labelKey: string
	valueKey: string
}

interface IRemoveLabelValue {
	options: any[]
}
