import { Maybe, ValidPropType } from '../interfaces/common'
import { satisfiesAll } from './function'

export const updateDeep = function <T>(path: string, payload: any, obj: T): T {
	const created = JSON.parse(JSON.stringify(obj))
	const pathArr = path.split('.')
	const len = pathArr.length - 1
	let nested = created
	for (let i = 0; i <= len; i++) {
		const key = pathArr[i]
		if (i === len) {
			if (payload instanceof Function) {
				nested[key] = payload(nested[key])
			} else {
				nested[key] = payload
			}
		}
		nested = nested[key]
	}
	return created
}

export const pickProp = <K extends ValidPropType>(prop: K) => <
	O extends { [key in K]?: any }
>(
	obj: O
): O[K] => {
	if (!obj) {
		throw TypeError(`Cannot convert ${obj} to object`)
	}

	return obj[prop]
}

export const pickKeys = <K extends ValidPropType>(keys: Array<K>) => <
	O extends { [key in K]?: any }
>(
	obj: O
): Pick<O, K> => {
	const newObj = {} as O

	for (const key of keys) {
		newObj[key] = obj[key]
	}

	return newObj
}

export const isValidObj = (obj: any): boolean =>
	obj === Object(obj) && !Array.isArray(obj)

export const objHasProps = (obj: any): boolean =>
	Boolean(obj && Object.keys(obj).length)

/**
 * - isObjValidAndNotEmpty checks if object is valid and not empty
 * @param x object to verify
 * @returns boolean
 */
export const isObjValidAndNotEmpty = satisfiesAll(isValidObj, objHasProps)

/**
 * -compareProp
 * @param checkEqual whether to check if prop is equal or different
 * @param prop property to compare
 * @param item1 item to pick prop from and compare
 * @param item2 item to pick prop and compare with item1
 */
const compareProp = function (
	checkEqual: boolean
): <K extends ValidPropType, T extends { [key in K]: any }>(
	prop: K
) => (item1: Maybe<T | T[K]>) => (item2: Maybe<T | T[K]>) => boolean {
	return (prop) => (item1) => (item2) => {
		if (!item1 || !item2) return false

		const toCompare1 = isObjValidAndNotEmpty(item1) ? item1[prop] : item1
		const toCompare2 = isObjValidAndNotEmpty(item2) ? item2[prop] : item2

		const matches = checkEqual
			? toCompare1 === toCompare2
			: toCompare1 !== toCompare2

		return Boolean(item1 && item2 && matches)
	}
}

export const isPropEqual = compareProp(true)

export const isPropDifferent = compareProp(false)

export function copyObjRemoveNull(obj: any) {
	const element = { ...obj }
	for (const key in element) {
		if (element[key] === null) element[key] = ''
	}
	return element
}

export const createKeyExtractor = (key: string) => (obj: any) => {
	const accessors = key.split('.')

	const isComposed = !!accessors[1]

	if (!isComposed) return obj[key]

	const getProp = (col: any, acc: any) => col[acc]

	const extracted = accessors.reduce(getProp, obj)

	return extracted
}
