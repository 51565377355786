import React from 'react'
import DualListBox, { Option } from 'react-dual-listbox'

import 'react-dual-listbox/lib/react-dual-listbox.css'
import ChevronIcon from '../../../assets/images/chevron.svg'
import DoubleChevronIcon from '../../../assets/images/double-chevron.svg'

import * as S from './styles'

interface IProps {
	options: Option<string>[]
	selectedOptions: string[]
	onChangeOption: (options: string[]) => void
	disabled?: boolean
}

export function ChangeProductsList(props: IProps) {
	const { options, selectedOptions, onChangeOption, disabled = false } = props

	function renderIcon(
		type: 'chevron' | 'double-chevron',
		direction: 'right' | 'left'
	) {
		return (
			<img
				className={`button-action-icon ${direction}`}
				alt="double-chevron-icon"
				src={type === 'chevron' ? ChevronIcon : DoubleChevronIcon}
			/>
		)
	}
	
	return (
		<S.Container>
			<div className="component-subtitle">
				<h2 className="pg-subtitle">Listagem de Produtos</h2>
				<h2 className="pg-subtitle">Produtos Inseridos</h2>
			</div>
			{/*@ts-ignore*/}
			<DualListBox
				disabled={disabled}
				options={options}
				selected={selectedOptions}
				onChange={onChangeOption}
				canFilter
				filterPlaceholder="Buscar produto"
				icons={{
					moveAllLeft: renderIcon('double-chevron', 'left'),
					moveLeft: renderIcon('chevron', 'left'),
					moveAllRight: renderIcon('double-chevron', 'right'),
					moveRight: renderIcon('chevron', 'right')
				}}
			/>
		</S.Container>
	)
}
