import { Dispatch, SetStateAction, useState } from 'react'

type UseNumberReturn = [
	number,
	() => void,
	() => void,
	Dispatch<SetStateAction<number>>
]

export const useNumber = (
	initialState: number,
	min = 0,
	max = 9999
): UseNumberReturn => {
	const [state, setState] = useState(initialState)

	const increment = () => setState((x) => (x < max ? x + 1 : x))

	const decrement = () => setState((x) => (x > min ? x - 1 : x))

	return [state, increment, decrement, setState]
}
