import { is, subtract } from 'ramda'

import { functionMap } from './function'
import { createKeyExtractor } from './object'
import { stringComp } from './string'

/**
 * -arrayReplace - creates a new array replacing the index item for the payload
 * @param arr array to replace an item from
 * @param index index of the item to replace
 * @param payload value to put in the given index
 */
export function arrayReplace<T>(
	index: number,
	payload: T,
	arr: Array<T>
): Array<T> {
	const created: Array<T> = []

	const len = arr.length

	for (let i = 0; i < len; i++) {
		if (i !== index) {
			created.push(arr[i])
		} else {
			created.push(payload)
		}
	}

	return created
}

/**
 * -removeIndex returns a new array without the element in the index position
 * @param arr any array
 * @param index index to be shifted
 */
export function removeIndex<T>(index = -1, arr: Array<T>): Array<T> {
	if (!Array.isArray(arr)) return arr

	return arr.filter((_, i) => index !== i)
}

export function flatReducer<T>(a: Array<T>, b: Array<T>): Array<T> {
	return a.concat(b)
}

const defaultCompare = functionMap([
	[Number.isInteger, subtract],
	[is(String), stringComp]
])

export const createSort = (comparation = defaultCompare) => (path: string) => (
	a: any,
	b: any
) => {
	const aProp = path ? createKeyExtractor(path)(a) : a
	const bProp = path ? createKeyExtractor(path)(b) : b

	const fn = comparation(aProp)

	return fn?.(aProp, bProp)
}

export const defaultSort = createSort()
