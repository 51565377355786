import { DayPicker as ReactDayPicker } from 'react-day-picker-8'
import styled, { css } from 'styled-components'

export const DatePickerWrapper = styled.div(
	({ theme: { palette } }) => css`
		background-color: ${palette.primaryWhite.main};
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	`
)

export const DateRangePickerContainer = styled.div`
	display: flex;
	font-family: 'Inter';
	position: relative;
	background-color: #ffff;
	z-index: 99;
	`

export const DayPickerWrapper = styled.div`
	position: absolute;
	right: -198px;
	z-index: 99;
	background-color: white;
`

export const DayPicker = styled(ReactDayPicker)(
	({ theme: { palette, font } }) => css`
		border: 1px solid ${palette.extras.stroke.dark};
		border-radius: 5px;
		padding: 24px 22px;
		font-family: ${font.family};
		transition: all 0.3s;

		color: ${palette.black.main};
		margin: 3px 0 0;

		.rdp-day_selected {
			background-color: ${palette.primaryWhite.main};
			color: ${palette.primaryWhite.contrastText};
		}

		.rdp-day_selected:hover {
			background-color: ${palette.primary.main};
			color: ${palette.primary.contrastText};
		}

		.rdp-day_selected:focus-visible {
			outline: 0;
		}

		.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
			background-color: ${palette.extras.stroke.main};
		}

		.rdp-button:focus-visible:not([disabled]) {
			background-color: ${palette.primary.main}20;
			border: 1px solid ${palette.primary.main};
		}

		.rdp-day_range_middle:hover {
			background-color: ${palette.extras.stroke.main};
		}

		.rdp-day_range_start {
			background-color: ${palette.primary.main};
			color: ${palette.white.main};
		}

		.rdp-day_range_end {
			background-color: ${palette.primary.main};
			color: ${palette.white.main};
		}
	`
)

export const ReadOnlyInput = styled.input(
	({ theme: { palette, font } }) => css`
		min-width: 215px;
		padding: 18px;
		font-family: ${font.family};
		font-size: ${font.size.TEXT_SM.size}rem;
		border: 1px solid #d1d1d1;
		border-radius: 5px;
		color: ${palette.black.main};

		:focus {
			border: 1px solid ${palette.extras.stroke.dark};
		}
	`
)
