import { apiAdmin } from '../../../../shared/services/axiosInstace'
import { CreateCouponValidationSchema } from '../schemas/create-coupon'
import couponMapper from './mappers/coupon-mapper'

import CouponMapper from './mappers/coupon-mapper'
import { ICouponRequestProps, ICouponResponseProps } from './types/coupon.types'

export type ExtendedCreateCouponValidationSchema =
	CreateCouponValidationSchema & {
		ID: number
		amountOfCustomers?: string
	}

class CouponService {
	async create(
		form: CreateCouponValidationSchema,
		userImpacted: string
	): Promise<boolean> {
		const body = CouponMapper.toPersistence(form, userImpacted)
		const { data } = await apiAdmin.post(`coupons`, {
			...body
		})
		return data
	}
	async editCoupon(
		form: CreateCouponValidationSchema,
		couponID: number
	): Promise<boolean> {
		const body = CouponMapper.toPersistenceEdit(form)

		const { data } = await apiAdmin.patch(`coupons/${couponID}`, {
			...body
		})

		return data
	}
	async checkCode(code: string): Promise<boolean> {
		const { data } = await apiAdmin.get<{ codeAlreadyExists: boolean }>(
			`coupons/validate-code/${code}`
		)

		return data.codeAlreadyExists
	}
	async getCouponById({
		couponID
	}: ICouponRequestProps): Promise<ExtendedCreateCouponValidationSchema> {
		const { data } = await apiAdmin.get<ICouponResponseProps>(
			`coupons/${couponID}/coupon-by-id`
		)
		return couponMapper.toDomain(data)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CouponService()
