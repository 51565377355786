import { ICartItem } from '../shared/interfaces/cart'
import { ICombo } from '../shared/interfaces/combo'
import { IMaterial } from '../shared/interfaces/material'
import { calculateActionPrice } from './calculateActionPrice'

interface ICompareProductsInCart {
	products: IMaterial[]
	cartProducts: ICartItem[]
}

interface ICompareCombosInCart {
	combos: ICombo[]
	cartProducts: ICartItem[]
}

interface ICompareResult {
	items: ICartItem[];
	hasChanges: boolean;
}

enum PromotionTypes {
	COMBO = 1,
	PRICE_ACTION = 2,
}

export function compareProductsInCart({
	products,
	cartProducts
}: ICompareProductsInCart): ICompareResult {
	const productsWithoutPromotion = cartProducts.filter(
		(item) =>
			item.operationTypeDescription !== 'Ação' &&
			item.operationTypeDescription !== 'Combo'
	)

	let hasChanges = false;

	const updatedCartProducts: ICartItem[] = productsWithoutPromotion.filter(
		(cartItem) => {
			const foundProduct = products.find(
				(item) => item.MaterialID === cartItem.MaterialID
			)
			const stockPosition = Number(foundProduct?.StockPosition)

			if (!foundProduct) return true

			const cartPrice = cartItem.PriceByQuantity[0].Price
			const dbPrice = foundProduct.PriceByQuantity[0].Price

			if (cartPrice !== dbPrice || stockPosition === 0) {
				hasChanges = true
			}

			return cartPrice === dbPrice && stockPosition > 0
		}
	)
	return {
		hasChanges,
		items: updatedCartProducts
	}
}

export function compareCombosInCart({
	combos,
	cartProducts
}: ICompareCombosInCart): ICompareResult {
	const promotionProducts = cartProducts.filter(
		(item) =>
			item.operationTypeDescription === 'Ação' ||
			item.operationTypeDescription === 'Combo'
	)

	let hasChanges = false;

	const updatedComboProducts: ICartItem[] = promotionProducts.filter(
		(cartItem) => {
			const foundCombo = combos.find(
				(item) => item.promotionID === cartItem.promotionID
			)

			if (!foundCombo) return true

			if (foundCombo.promotionType === PromotionTypes.PRICE_ACTION) {
				const calculatedActionPrice = calculateActionPrice(cartItem)

				if (foundCombo.actionPriceFinalSalesValue !== calculatedActionPrice || foundCombo.maxQuantityTradePromotion === 0) {
					hasChanges = true
				}

				return (
					foundCombo.actionPriceFinalSalesValue === calculatedActionPrice &&
					foundCombo.maxQuantityTradePromotion > 0
				)
			}
		}
	)
	return {
		hasChanges,
		items: updatedComboProducts
	}
}
