import { apiAdmin } from '../../../shared/services/axiosInstace'
import { IGetDeliveryDatesResponse } from '../interfaces/get-delivery-dates'

export async function getDeliveryDates(
	salesOrganizationIDs: string[]
): Promise<IGetDeliveryDatesResponse[]> {
	const response = await apiAdmin.get(`delivery-date-distribution/weekly`, {
		params: {
			salesOrganizationID: salesOrganizationIDs.join(',')
		}
	})
	return response.data
}
