import { Button } from '@material-ui/core'
import styled from 'styled-components'

export const WrapperPrimaryButton = styled(Button)`
	background-color: #ea0000 !important;
	color: #ffffff !important;

	width: 142px;
	height: 41px;

	font-family: 'Inter' !important;
	font-weight: 700 !important;
	font-size: 0.625rem !important;
	line-height: 0.75rem !important;
`
