import {
	IFilterRulePoint,
	RuleParamsNameEnum
} from '../../../shared/interfaces/pointsProgramRules'

export function formatPdvsToPayload(pdvs: string[]): IFilterRulePoint[] {
	//Mapeando todos os pdvs
	const mappedPdvs = pdvs.map((pdv) => {
		return {
			paramName: RuleParamsNameEnum.PDV,
			paramValue: pdv
		}
	})

	return mappedPdvs
}
