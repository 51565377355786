import { IMaterial } from '../../../shared/interfaces/material'
import { ICategories } from '../../../shared/interfaces/categories'
import { ISelectedOption } from '../order-admin.types'

export function finalOrderedListCategory({ array }: { array: ICategories[] }) {
	return array.map((item, index) => ({
		listingPosition: index,
		ID: item.ID
	}))
}

export function finalOrderedListMaterial({
	array,
	selectedCategory
}: {
	array: IMaterial[]
	selectedCategory: ISelectedOption
}) {
	return array.map((item, index) => ({
		listingPosition: index,
		ID: item.ID,
		categoryId: selectedCategory.value
	}))
}
