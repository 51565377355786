import React from 'react'
import * as S from './input-field.styles'
import { ITextField } from './input-field.types'

const InputField = ({
	text,
	handleChange,
	label,
	maxCharacters,
	width = 568
}: ITextField) => {
	return (
		<S.TextFielWrapper
			width={width}
			label={label}
			variant="outlined"
			value={text}
			onChange={(event) => handleChange(event)}
			inputProps={{
				maxLength: maxCharacters
			}}
			InputProps={
				!!maxCharacters
					? {
							endAdornment: (
								<S.CountCharacters>
									{text.length}/{maxCharacters}
								</S.CountCharacters>
							)
					  }
					: {}
			}
		/>
	)
}

export default InputField
