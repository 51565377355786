import styled from 'styled-components'
import { mediaQuery, toRem } from '../../shared/styles/functions'
import { colors } from '../../shared/styles/theme'

export const PixQrCodeWrapper = styled.div`
	.pixWrapper {
		text-align: center;
		padding-top: 50px;
	}
	padding-bottom: 50px;
`

export const PixQrCodeTitle = styled.p`
	color: ${colors.red};
	margin: 24px 0;
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	font-weight: ${(props) => props.theme.font.weight.regular};
`

export const PixQrCodeDescription = styled.p`
	color: ${colors.secondGrey};
	margin: 0 0 24px 0;
	font-size: ${(props) => props.theme.font.size.TEXT_LG.size}rem;
`

export const PixQrCodeExpiration = styled.p`
	color: ${colors.red};
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	margin-bottom: 16px;
`

export const QRCodeContainer = styled.div`
	background-color: ${colors.white};
	padding: 24px;
	border: 1px solid ${(props) => props.theme.palette.primaryWhite.main};
	border-radius: 4px;
	text-align: center;
	width: ${toRem(550)};
	margin: auto;
	.qr-code {
		width: ${toRem(246)};
		height: ${toRem(246)};
		margin-bottom: 16px;
	}

	.border {
		border-bottom: 1px solid
			${(props) => props.theme.palette.primaryWhite.main};
		margin-bottom: 16px;
	}

	${mediaQuery('sm')(`
        width: 100%;
    `)}
`
export const CopyPasteWrapper = styled.div`
	border: 1px solid ${colors.sixthGray};
	border-radius: 4px;
	height: 55px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 16px;
	margin-bottom: 32px;

	.copyPaste {
		/* width: 10%; */
		cursor: pointer;
	}

	button {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		height: 100%;
	}
`

export const CopyPasteTitle = styled.p`
	color: ${colors.secondGrey};
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	align-items: center;
	max-width: 500px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`
