import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'

export const CartScreenWrapper = styled.main`
	width: 100%;
	min-height: 100vh;
	padding-top: 108px;
	background-color: ${colors.fifthGray};

	@media screen and (max-width: 768px) {
		padding-top: 54px;
	}
`

export const CartScreenPageTitle = styled.h2`
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
	margin-bottom: 8px;
`

export const CartScreenContainer = styled.section`
	width: 100%;
	max-width: 1088px;
	margin: 0 auto;

	@media screen and (max-width: 820px) {
		padding: 16px;
	}
`

export const GoBackButtonWrapper = styled.section`
	width: 100%;
	margin-bottom: 40px;
`

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	margin: 32px 0px;
	background-color: ${colors.lightGrey};
`

export const ButtonGoBack = styled.button`
	gap: 8px;
	display: flex;
	cursor: pointer;
	align-items: center;
	padding: 0;
	font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
	color: ${colors.secondGrey};
	border: none;
	background-color: transparent;
`
