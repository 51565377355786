import styled, { css, DefaultTheme } from 'styled-components'
import { PointsExpirationStatusLabelEnum } from '../../shared/interfaces/pointsProgram'

export const ChipLabel = styled.label<{ status: string; theme: DefaultTheme }>`
	font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
	max-height: 19px;
	padding: 5px 8px;
	text-align: center;
	border-radius: 10px;
	display: inline-block;
	text-transform: uppercase;

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.RELEASED &&
		css`
			color: ${palette.white.main};
			background-color: ${palette.success.main};
		`}

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRING &&
		css`
			color: theme: { palette }.palette.primary.main;
			border: 1px solidtheme: { palette }.palette.primary.main;
			background-color: ${palette.white.main};
		`}

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.USED &&
		css`
			color: ${palette.success.main};
			border: 1px solid ${palette.success.main};
			background-color: ${palette.white.main};
		`}

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.REVERSAL &&
		css`
			color: ${palette.white.main};
			background-color: ${palette.lightGray.main};
		`}

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.UNDER_ANALYSIS &&
		css`
			color: ${palette.black.main};
			background-color: ${palette.extras.alert.main};
		`}

	${({ status, theme: { palette } }) =>
		status === PointsExpirationStatusLabelEnum.EXPIRED &&
		css`
			color: ${palette.lightGray.main};
			background-color: ${palette.extras.stroke.main};
		`}
`
