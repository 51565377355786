/* eslint-disable no-loop-func */
import { ICartItem } from '../interfaces/cart'
import { IChallengeProduct } from '../interfaces/pointsProgramChallenge'

export function calcTotalQuantity(products: IChallengeProduct[]) {
	return products.reduce((acc, product) => {
		return (acc += Number(product.quantity))
	}, 0)
}

export const calcTotalAmountReachedWithItemsInCart = (
	addedProducts: ICartItem[],
	products: IChallengeProduct[]
) => {
	let amountReached = 0

	const challengeItemsFilteredAccordingToCartItems = products.filter(
		(product) =>
			!!addedProducts.find(
				(addedProduct) => addedProduct.MaterialID === product.productId
			)
	)

	addedProducts.forEach((cartItem) => {
		challengeItemsFilteredAccordingToCartItems.forEach(
			(challengeProduct) => {
				if (cartItem.MaterialID === challengeProduct.productId) {
					const theQuantityInTheCartIsGreaterThanTheMinimumQuantityOfTheItem =
						cartItem.OrderQuantity >=
						Number(challengeProduct.quantity)

					const theAmountAddedHasAlreadyExceededTheMinimumQuantity =
						cartItem.OrderQuantity +
							Number(challengeProduct.quantityReached) >=
						Number(challengeProduct.quantity)

					const result =
						theQuantityInTheCartIsGreaterThanTheMinimumQuantityOfTheItem &&
						!theAmountAddedHasAlreadyExceededTheMinimumQuantity
							? amountReached + Number(challengeProduct.quantity)
							: theAmountAddedHasAlreadyExceededTheMinimumQuantity
							? amountReached +
							  (Number(challengeProduct.quantity) -
									Number(challengeProduct.quantityReached))
							: amountReached + cartItem.OrderQuantity

					amountReached = result
				}
			}
		)
	})

	return amountReached
}

export const calcTotalAmountReachedWithItemsInCartForMixingChallenges = (
	products: IChallengeProduct[],
	addedProducts: { productId: string; quantity: number }[]
) => {
	let amountReached = 0

	addedProducts.forEach((cartItem) => {
		const productFounded = products.find(
			(product) => product.productId === cartItem.productId
		)
		if (cartItem.quantity >= Number(productFounded?.quantity)) {
			amountReached += Number(productFounded?.quantity)
		}
	})

	return amountReached
}

export function calcTotalAmountAchievedWithPurchasedItems(
	products: IChallengeProduct[]
) {
	return products.reduce((acc, product) => {
		return (acc += product.quantityReached || 0)
	}, 0)
}
