import React from "react"
import { ISuggestedToggle } from "./types"
import { CheckIcon, CloseIcon, Container, CustomSwitch, ThumbContainer, Title } from "./styles"

export const SuggestedToggle = ({ selected, handleChange }: ISuggestedToggle) => {

    return (
        <Container data-testid="container-SuggestedToggle">
            <Title data-testid="title">{selected ? 'Desabilitar' : 'Habilitar'}</Title>
            <CustomSwitch
                data-testid='toggle'
                checked={selected}
                onClick={handleChange}
                checkedIcon={
                    <ThumbContainer selected={selected}>
                        <CheckIcon />
                    </ThumbContainer>
                }
                icon={
                    <ThumbContainer selected={selected}>
                        <CloseIcon />
                    </ThumbContainer>
                }
            />
        </Container>
    )
}