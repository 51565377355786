import React from 'react'
import { Typography } from '@material-ui/core'
import { ITagInfo } from './tag-info.types'
import { TagInfoStyles } from './tag-info.styles'

const TagInfo = ({ title, value }: ITagInfo) => {
	return (
		<TagInfoStyles>
			<Typography
				variant="body1"
				component="p"
				className="tag-info-title">
				{title}
			</Typography>
			<div className="tag-info-value">
				<Typography variant="body1" component="p">
					{value}
				</Typography>
			</div>
		</TagInfoStyles>
	)
}

export default TagInfo
