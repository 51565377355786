import 'react-activity/dist/react-activity.css'

import React from 'react'
import { Dots } from 'react-activity'
import { colorThemeProvider } from '../../theme/themeProvider'
import { CircularProgress } from '@mui/material'

type LoadingProps = {
	color?: string
	size?: number
	type?: 'dots' | 'circular'
}

function Loading({
	color = colorThemeProvider.grayColor,
	size = 24,
	type = 'dots'
}: LoadingProps) {
	return type === 'circular' ? (
		<CircularProgress size={size} />
	) : (
		<Dots size={size} color={color} speed={1} animation={true} />
	)
}

export default Loading
