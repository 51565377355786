import React from 'react'
import { MutedButtonProps } from './muted-button.types'

import VolumeUpIcon from '@mui/icons-material/VolumeUp'
import VolumeOffIcon from '@mui/icons-material/VolumeOff'
import { MutedButtonWrapper, RounderMutedWrapper } from './muted-button.styles'

function MutedButton({ isMuted, onClickMuted }: MutedButtonProps) {
	return (
		<MutedButtonWrapper>
			<RounderMutedWrapper>
				{isMuted ? (
					<VolumeOffIcon onClick={onClickMuted} />
				) : (
					<VolumeUpIcon onClick={onClickMuted} />
				)}
			</RounderMutedWrapper>
		</MutedButtonWrapper>
	)
}

export default MutedButton
