import React from 'react'
import * as S from './login-loading.styles'
import { Box } from '@mui/material'
import Loading from '../../../../components/Loading'

import GPLogo from '../../../../assets/images/logo-bp--redV2.svg'

function LoginLoading() {
	return (
		<>
			<S.HeaderLoading>
				<Box className="logo-wapper">
					<img src={GPLogo} alt="logo-gp" />
				</Box>
			</S.HeaderLoading>
			<S.LoadingWrapper>
				<div>
					<Loading />
				</div>
			</S.LoadingWrapper>
		</>
	)
}

export default LoginLoading
