import React from 'react'
import { ItemText, ItemTextContainer, ItemTextDescription } from './item-text.styles'
import { IItemText } from './item-text.types'

export function ItemTextView({title, description}: IItemText) {
	return (
		<ItemTextContainer>
			<ItemText>{title}</ItemText>
			<ItemTextDescription>{description || '--'}</ItemTextDescription>
		</ItemTextContainer>
	)
}
