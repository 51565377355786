import React from 'react'
import * as S from './reset-password.styles'
import { EnumTypeFlow, Steps, ValidationProps } from './reset-password.types'
import { useHistory, useLocation } from 'react-router-dom'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'

import HeaderLogin from '../login-customer/components/header-login/header-login.component'
import { sidebarTypes } from '../login-customer/components/sidebar/sidebar.types'
import Sidebar from '../login-customer/components/sidebar/sidebar.component'

import CodeValidationComponent from './components/code-validation/code-validation.component'
import { TypeContact } from './components/code-validation/code-validation.types'
import NewPasswordComponent from './components/new-password/new-password.component'
import PasswordCreatedComponent from './components/password-created/password-created.component'
import AnotherFormValidationComponent from './components/another-form-validation/another-form-validation.component'
import SecurityInformationComponent from './components/security-information/security-information.component'
import EmailPhoneRegistrationComponent from './components/email-phone-registration/email-phone-registration.component'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import { useRecoverPasswordContextContext } from '../../context/recover-password.context'
import { handleSetContextUserData } from '../login-customer/utils/handle-set-user-data'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { removeEspecialCharacters } from '../../shared/utils'
import { creationFormTypeEnum } from './components/email-phone-registration/email-phone-registration.types'

function ResetPassword() {
	const history = useHistory()
	const { state } = useLocation<ValidationProps>()

	const [step, setStep] = React.useState<Steps>(0)
	const [sideBarContent, setSidebarContent] = React.useState<sidebarTypes>('')
	const [isOpen, setIsOpen] = React.useState(false)
	const [showAnotherFormOption, setShowAnotherFormOption] =
		React.useState(true)
	const [contactData, setContactData] = React.useState<ValidationProps>({
		validationChoice: '',
		validationType: TypeContact.EMAIL
	})
	const [contactAlternative, setContactAlternative] = React.useState('')
	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [newPassword, setNewPassword] = React.useState<string>('')

	const [newEmail, setNewEmail] = React.useState<string>('')
	const [newPhone, setNewPhone] = React.useState<string>('')
	const [isPasswordValidated, setIsPasswordValidated] = React.useState(false)

	// Escolha do usuário ativação por email ou sms
	const [activationType, setActivationType] = React.useState('')
	const [choiceInitialContact] = React.useState<string>('Email')

	const [typeFlow, setTypeFlow] = React.useState<EnumTypeFlow>(
		EnumTypeFlow.NORMALFLOW
	)

	const { userMaskedData, handleSetUserMaskedData } =
		useRecoverPasswordContextContext()

	React.useEffect(() => {
		if (!state || !state.validationType) {
			history.push(UnauthenticatedRoutesEnum.LOGIN)
		} else {
			setContactData({ ...state })
			if (state.currentStep) {
				setContactData({ ...state })
				setStep(state.currentStep)
			}
		}
	}, [history, state, userMaskedData])

	// React.useEffect(() => {
	// 	console.log(activationType)
	// }, [activationType])

	React.useEffect(() => {
		async function handleGetUserData() {
			try {
				setIsLoading(true)
				if (state.unformatedTextDoc) {
					const userData = await handleSetContextUserData(
						state.unformatedTextDoc
					)

					const newUserMaskedData = {
						email: userData.Email,
						cellphoneNumber: userData.CellphoneNumber,
						phoneNumber: userData.CellphoneNumber,
						customerID: userData.CustomerID,
						alternativePhone: userData.AlternativePhone,
						alternativeEmail: userData.AlternativeEmail,
						questions: userData.questions
					}
					handleSetUserMaskedData(newUserMaskedData)
				}
			} catch (error) {
				cogoToast.error(error, cogoDefaultOptions)
			} finally {
				setIsLoading(false)
			}
		}

		if (
			!userMaskedData.customerID &&
			(!userMaskedData.alternativeEmail ||
				!userMaskedData.alternativePhone)
		) {
			// caso ele so tenha uma informação alternativa essa parte pode entrar em loop
			handleGetUserData()
		}
	}, [handleSetUserMaskedData, state.unformatedTextDoc, userMaskedData])

	const stepsProps = {
		goStep: function (step: number) {
			setStep(step)
		},
		prevStep: () => {
			if (step === 0) {
				history.replace(UnauthenticatedRoutesEnum.LOGIN)
			} else {
				setStep((prevState) => prevState - 1)
			}
		},
		navigateToSpecifique: (step: number) => {
			setStep(step)
		},
		setContactData: (object: ValidationProps) => setContactData(object)
	}

	// Objeto com todos os steps
	const recoveryStepperObject = {
		0: (
			<NewPasswordComponent
				typeContact={contactData.validationType}
				setNewPassword={setNewPassword}
				newPassword={newPassword}
				customerDocument={removeEspecialCharacters(
					state.unformatedTextDoc ?? ''
				)}
				{...stepsProps}
				typeFlow={typeFlow}
				setIsLoading={setIsLoading}
				setIsOpen={setIsOpen}
			/>
		),
		1: (
			<CodeValidationComponent
				typeContact={contactData.validationType}
				activationType={activationType}
				isEmailRecover={activationType === creationFormTypeEnum.email}
				typeFlow={typeFlow}
				contact={
					contactData.validationType === TypeContact.EMAIL
						? userMaskedData.email
						: contactData.validationType ===
						  TypeContact.PHONE_ALTERNATIVE
						? userMaskedData.alternativePhone
						: contactData.validationType ===
						  TypeContact.EMAIL_ALTERNATIVE
						? userMaskedData.alternativeEmail
						: contactData.validationType === TypeContact.PHONE
						? userMaskedData.cellphoneNumber
						: contactData.validationType === TypeContact.NEW_EMAIL
						? newEmail
						: contactData.validationType === TypeContact.NEW_PHONE
						? newPhone
						: ''
				}
				customerDocument={removeEspecialCharacters(
					state.unformatedTextDoc ?? ''
				)}
				otherWay={() => setStep(Steps.ANOTHER_FORM)}
				showAnotherFormOption={showAnotherFormOption}
				{...stepsProps}
				handleOpenSidebar={() => setIsOpen(true)}
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				newPassword={newPassword}
				newEmail={newEmail}
				newPhone={newPhone}
				isPasswordValidated={isPasswordValidated}
				setIsPasswordValidated={setIsPasswordValidated}
				customerID={userMaskedData.customerID}
			/>
		),
		2: <PasswordCreatedComponent />,
		3: (
			<AnotherFormValidationComponent
				handleStep={(value) => {
					if (!value) {
						setStep(Steps.SECURITY)
					} else {
						setStep(Steps.VALID_CODE)
						setShowAnotherFormOption(false)
						setContactAlternative(value)
					}
				}}
				{...stepsProps}
				alternativeOption={
					userMaskedData.alternativeEmail.length > 0
						? userMaskedData.alternativeEmail
						: userMaskedData.alternativePhone
				}
				alternativeOptionString={
					userMaskedData.alternativeEmail.length > 0
						? choiceInitialContact
						: 'Celular'
				}
				customerDocument={removeEspecialCharacters(
					state.unformatedTextDoc ?? ''
				)}
				setTypeFlow={setTypeFlow}
			/>
		),
		4: (
			<SecurityInformationComponent
				{...stepsProps}
				setIsLoading={setIsLoading}
				customerDocument={removeEspecialCharacters(
					state.unformatedTextDoc ?? ''
				)}
			/>
		),
		5: (
			<EmailPhoneRegistrationComponent
				{...stepsProps}
				choiceInitialContact={choiceInitialContact}
				typeContact={contactData.validationType}
				newEmail={newEmail}
				setNewEmail={setNewEmail}
				newPhone={newPhone}
				setNewPhone={setNewPhone}
				customerDocument={removeEspecialCharacters(
					state.unformatedTextDoc ?? ''
				)}
				activationType={activationType}
				setActivationType={setActivationType}
				isPasswordValidated={isPasswordValidated}
				setIsLoading={setIsLoading}
				setTypeFlow={setTypeFlow}
				typeFlow={typeFlow}
			/>
		)
	}

	return (
		<>
			<LoadingCcontainer isLoading={isLoading} />
			<HeaderLogin
				openAccountSidebar={() => {
					setSidebarContent('login')
					history.replace(UnauthenticatedRoutesEnum.LOGIN)
				}}
			/>
			<Sidebar
				isOpen={isOpen}
				toggleSidebar={() => setIsOpen(false)}
				sideBarType={'login'}
				handleChangeTypeSidebar={() => setSidebarContent('login')}
				loginStep={'chooseValidation'}
			/>
			<S.MainResetPasswordView className="container">
				{recoveryStepperObject[step]}
			</S.MainResetPasswordView>
		</>
	)
}

export default ResetPassword
