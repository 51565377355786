import { createElement, useEffect, useState } from 'react'

import { getAllFaqs } from './service'

import { IViewProps } from './types'

import PointsProgramFAQView from './view'

export default function PointsProgramFAQ() {
	const [isLoading, setIsLoading] = useState(false)
	const [questions, setQuestions] = useState<
		Array<{
			question: string
			answer: string
		}>
	>([])

	function fetchFaqData() {
		;(async () => {
			setIsLoading(true)
			const faqs = await getAllFaqs()
			setIsLoading(false)
			setQuestions(faqs)
		})()
	}

	useEffect(fetchFaqData, [])

	const viewProps: IViewProps = {
		questions,
		isLoading
	}

	return createElement(PointsProgramFAQView, viewProps)
}
