import React from 'react'

import { FieldValues, useController } from 'react-hook-form'

import { FormControl, InputLabel, MenuItem } from '@mui/material'

import ErrorText from '../error-text/error-text.component'

import { ControlSelectProps } from './multiple-select.types'

import useMultipleSelect from './use-multiple-select'

import * as S from './multiple-select.styles'

export default function ControlSelect<T extends FieldValues>({
	label,
	options,
	disabled = false,
	isLoading = false,
	multiple = false,
	onAfterChange,
	...otherProps
}: ControlSelectProps<T>) {
	const {
		field: { value, onChange },
		fieldState: { error }
	} = useController(otherProps)

	const {
		handleChange,
		renderAllCheckboxItem,
		renderOptionItem,
		renderValue,
		renderStartAddorment
	} = useMultipleSelect({ onChange, options, onAfterChange })

	const name = otherProps.name

	const optionsLength = options.length

	const showAllCheckBoxItem = multiple && options.length > 0

	return (
		<FormControl fullWidth>
			<S.InputLabelStyled id="control-select-simple-id">
				{label}
			</S.InputLabelStyled>
			<S.Select
				{...otherProps}
				name={name}
				label={label}
				labelId="control-select-simple-id"
				renderValue={renderValue}
				onChange={handleChange}
				value={otherProps?.value ?? value}
				multiple={multiple}
				startAdornment={renderStartAddorment(
					isLoading,
					otherProps?.startAdornment
				)}
				disabled={disabled || isLoading}>
				{optionsLength === 0 && (
					<MenuItem disabled>
						<em>Sem itens disponíveis</em>
					</MenuItem>
				)}

				{showAllCheckBoxItem &&
					renderAllCheckboxItem({ optionsLength, value })}

				{options.map((option, index) =>
					renderOptionItem({
						option,
						multiple,
						value,
						index
					})
				)}
			</S.Select>
			<ErrorText error={error?.message} />
		</FormControl>
	)
}
