import { Maybe } from '../../shared/interfaces/common'
import { UserTypeEnum } from '../../shared/interfaces/customer'
import { AdminRoutesEnum } from './adminRoutesEnum'
import { CustomerRoutesEnum } from './customerRoutesEnum'
import { UnauthenticatedRoutesEnum } from './unauthenticatedRoutesEnum'

export interface IRouteData {
	children?: JSX.Element
	accessTypes?: AccessTypeEnum[]
	displayOnNavBar?: boolean
	isPrivate?: Maybe<boolean>
	name?: Maybe<string>
	exact: boolean
	key: string
	path: string
}

enum AccessType {
	'ADMIN',
	'CUSTOMER',
	'SAC',
	'PRICING',
	'UNIDADE'
}

export type AccessTypeEnum = keyof typeof AccessType

export enum RouteType {
	CD = 'CD',
	PARAMETERS = 'PARAMETERS',
	CUSTOMERS = 'CUSTOMERS',
	COMUNICATION = 'COMUNICATION',
	POINTS = 'POINTS',
	SORT = 'SORT',
	LOGISTIC = 'LOGISTIC',
	SUGGESTED_ORDER = 'SUGGESTED_ORDER',
	COUPON = 'COUPON'
}

export type RouteTypeEnum = keyof typeof RouteType

export interface IRouteDataV2 {
	accessTypes: UserTypeEnum[]
	displayOnNavBar: boolean
	name: string
	key: AdminRoutesEnum | CustomerRoutesEnum | UnauthenticatedRoutesEnum
	path:
		| AdminRoutesEnum
		| CustomerRoutesEnum
		| UnauthenticatedRoutesEnum
		| string
	children: JSX.Element
	customerPending?: boolean
	routeType: RouteTypeEnum
}
