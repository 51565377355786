import React, { useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { checkPassword } from '../../shared/utils/validators'
import InputField from '../InputField'
import { IProps } from './types'

function InputPasswordConfirmation(props: IProps) {
	const { password, valueIsValid, secondary } = props

	// const passwordMask = '********************'

	const [inputValue, setInputValue] = useState('')
	const [hasError, setHasError] = useState(false)
	const [touched, setTouched] = useState(false)
	const [value] = useDebounce(inputValue, 1000)

	async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setTouched(true)
		setInputValue(e.target.value)
	}

	function validateInput() {
		const isValid = checkPassword(password, inputValue)

		valueIsValid(isValid)

		setHasError(touched && !isValid)
	}

	useEffect(validateInput, [value, inputValue, password, touched, valueIsValid])

	return (
		<InputField
			secondary={secondary}
			// mask={passwordMask}
			maxLength={20}
			type="password"
			onChange={handleChange}
			id="passwordConfirmation"
			label={'Confirmar senha'}
			hasError={hasError}
			errorMessage={'Senha não confere'}
		/>
	)
}

export default InputPasswordConfirmation
