import * as React from 'react'
import Modal from '@mui/material/Modal'
import { IModalTable } from './modal-table.interface'
import { WrapperModal } from './modal-table.styles'
import { TextField, Typography } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { PrimaryButton } from '../../../../components/primary-button/primary-button.component'

import FileUploadIcon from '@mui/icons-material/FileUpload'
import { comboServices } from '../../services/combo.services'
import { handleFormatDate } from '../combo-table/util/formateDateCombo'
import Loading from '../../../../components/Loading'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../shared/utils/toaster'
import { cogoSuccessMessages } from '../../../../shared/utils/Messages'
import { CloseButton } from '../../../../shared/components'

const ModalTable = ({
	open,
	handleClose,
	modalData,
	refreshTableComboData
}: IModalTable) => {
	const [selectedImage, setSelectedImage] = React.useState(
		modalData.comboImageUrl
	)
	const [selectedImageBinary, setSelectedImageBinary] = React.useState(null)
	const [isDeletedImage, setIsDeletedImage] = React.useState<boolean>(false)
	const ref = React.useRef<HTMLInputElement>(null)

	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		setSelectedImage(modalData.comboImageUrl)
	}, [modalData.comboImageUrl])

	const handleCloseBackDrop = (event: any, reason: string) => {
		if (event && reason === 'backdropClick') {
			handleClose(false)
		}
	}

	const handleSelectImage = () => {
		if (ref.current !== null) {
			ref.current.click()
		}
	}

	const handleDataChange = (event: any) => {
		const input = event.target.files[0]

		var reader = new FileReader()
		reader.onload = function () {
			const dataURL = reader.result
			setSelectedImage(dataURL)
		}
		reader.readAsDataURL(input)
		setSelectedImageBinary(input)
		setIsDeletedImage(false)
	}

	async function submitFormUploadComboImage() {
		let url = ''
		let comboId = `${modalData.promotionID}`
		setIsLoading(true)
		if (selectedImageBinary) {
			const resUpload = await comboServices.uploadComboImage(
				selectedImageBinary
			)
			url = resUpload.url
		}
		await comboServices.updateComboSaveUrlImage(comboId, url)
		setIsLoading(false)
		cogoToast.success(
			cogoSuccessMessages.updateComboImageSuccess,
			cogoDefaultOptions
		)
		handleClose(false)
		refreshTableComboData()
		return
	}

	return (
		<Modal
			open={open}
			onClose={(event, reason) => {
				handleClose(open)
				handleCloseBackDrop(event, reason)
				setIsDeletedImage(true)
			}}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description">
			<>
				<WrapperModal>
					{isLoading ? (
						<Loading />
					) : (
						<>
							<CloseButton onClick={() => handleClose(false)} />
							<Typography className="modal-title">
								Alterar Imagem
							</Typography>

							<div className="image-wrapper">
								{isDeletedImage ? (
									<div
										className="upload-file"
										onClick={handleSelectImage}
										onChange={(event) =>
											handleDataChange(event)
										}>
										<input
											onChange={(event) =>
												handleDataChange(event)
											}
											ref={ref}
											type="file"
										/>
										<FileUploadIcon />
										<p className="title-upload-image">
											Arraste ou selecione a imagem
										</p>
										<p className="sub-title-upload-image">
											RESOLUÇÃO MÁXIMA: 654X368
										</p>
									</div>
								) : (
									<>
										<div className="gradient"></div>
										<img
											src={selectedImage}
											alt={'product-item'}
										/>
										<div className="image-info-wrapper">
											<div className="image-info">
												<p className="image-detail-name">
													imagem_combo.jpg
												</p>
												<p className="image-detail-size">
													150KB
												</p>
											</div>
											<DeleteOutlinedIcon
												className="delete-image"
												style={{ color: 'white' }}
												onClick={() =>
													setIsDeletedImage(true)
												}
											/>
										</div>
									</>
								)}
							</div>
							<div className="modal-product-info">
								<Typography className="modal-product-info-title">
									Informações do Combo
								</Typography>
								<div className="label-wrapper">
									<TextField
										disabled
										label="NOME DO COMBO"
										defaultValue={modalData.description}
										className="defaultStyleInput comboName"
									/>
									<TextField
										disabled
										label="CÓDIGO DO COMBO"
										defaultValue={modalData.promotionID}
										className="defaultStyleInput codeCombo"
									/>
									<TextField
										disabled
										label="SAP"
										defaultValue={modalData.sectorID}
										className="defaultStyleInput sap"
									/>
									<TextField
										disabled
										label="Data de início"
										defaultValue={handleFormatDate(
											modalData.startDate
										)}
										className="defaultStyleInput dateBegin"
									/>
									<TextField
										disabled
										label="Data de fim"
										defaultValue={handleFormatDate(
											modalData.endDate
										)}
										className="defaultStyleInput dateEnd"
									/>
									<TextField
										disabled
										label="CD"
										defaultValue={modalData.createdBy}
										className="defaultStyleInput cd"
									/>
								</div>
							</div>
							<div className="button-wrapper">
								<PrimaryButton
									buttonText="CONFIRMAR"
									handleClickButton={() =>
										submitFormUploadComboImage()
									}
								/>
							</div>
						</>
					)}
				</WrapperModal>
			</>
		</Modal>
	)
}

export default ModalTable
