import { css, styled } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

export const StyledDateTimePicker = styled(DateTimePicker)(
	({ theme }) => css`
		&.MuiTextField-root {
			background-color: ${theme.palette.background.paper};
		}

		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.primary.main};
			border-width: 1px;
		}

		& .MuiOutlinedInput-root {
			&.Mui-focused fieldset {
				border-color: ${theme.palette.primary.main};
			}
		}

		& .MuiFormLabel-root.MuiInputLabel-root.Mui-error {
			border-color: ${theme.palette.primary.main};
			border-width: 1px;
			color: ${theme.palette.primary.main};
		}

		&
			.MuiInputBase-root.MuiOutlinedInput-root.Mui-error
			.MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.primary.main};
			border-width: 1px;
		}
	`
)
