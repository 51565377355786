import React, { useState, useCallback, useContext } from 'react'
import { InputField, Loading, Table } from '../../shared/components'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import TitlePage from '../../components/title-page/title-page.component'
import ButtonPrimary from '../../components/ButtonPrimary'
import { primaryButtonStyle } from '../../shared/styles/theme'
import { useHistory } from 'react-router-dom'
import {
	DividerSimple,
	PageHeader
} from './consul-client-info/consult-client-info.styles'
import FilterConsult from './consul-client-info/components/Filter/FilterConsult.view'
import GeneralTemplate from './consul-client-info/components/template/GeneralTemplate.view'
import {
	BoxInfo,
	Row
} from './consul-client-info/components/Information/Information.styles'
import SearchInput from '../../components/SearchInput'
import { handleConsultFilterData } from './consult-client.service'
import Details from './consul-client-info/components/Details/Details.view'
import { AdminRoutesEnum } from '../../modules/Routes/adminRoutesEnum'
import { IDataTable, IUser } from './index.types'
import ModalJustification from './consul-client-info/components/Modal/ModalJustification.view'
import NotaFiscalModal from './consul-client-info/components/Modal/NotaFiscal/NotaFiscal.view'
import {
	ButtonStyleBorder,
	InputStyleSvg,
	MessageStyle,
	StyleStatus
} from './consult-client.styles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Notification } from '../../components/Notification'
import { CustomerInfoContext } from '../../context/customer-info.context'

export default function ConsultCustomer() {
	const history = useHistory()
	const { storedData, handleStoredData, isBackInThePage } =
		useContext(CustomerInfoContext)
	const [documentNumber] = useState('')
	const [documentIsValid] = useState(false)
	const [isLoading, setIsLoading] = React.useState(false)
	const [dadosFilter, setDadosFilter] = React.useState<IUser>()
	const [searchPush, setSearchPush] = React.useState('')
	const [isDisabled, setIsDisabled] = useState(false)
	const [defaultMessage, setDefaultMessage] = React.useState(true)
	const [defaultText, setDefaultText] = React.useState(
		'Selecione os filtros para exibirmos a Consulta do Cliente'
	)
	const [dataTable, setDataTable] = useState<IDataTable[]>([])
	const [openModalDeleteUser, setOpenModalDeleteUser] = React.useState(false)
	const [openModalCel, setOpenModalCel] = React.useState(false)
	const [openModalEmail, setOpenModalEmail] = React.useState(false)
	const [openModalNF, setOpenModalNF] = useState(false)
	const [openModalInfoNF, setOpenModalInfoNF] = useState(false)
	const [isVisible, setIsVisible] = useState(false)
	const [type, setType] = useState<any>('')
	const [message, setMessage] = useState('')

	const handleFilter = useCallback(async () => {
		try {
			setDefaultMessage(false)
			setIsLoading(true)

			const payload = {
				CGC: dadosFilter?.documentNumber,
				name: dadosFilter?.nomeCliente,
				codePdv: dadosFilter?.codCliente,
				codeResales:
					dadosFilter?.cd?.map((option: any) => option.value) || [],
				channels:
					dadosFilter?.canal?.map((option: any) => option.value) ||
					[],
				researchDate: {
					start: '',
					end: ''
				}
			}

			const salesHierarchyData = await handleConsultFilterData(payload)

			if (
				!salesHierarchyData ||
				salesHierarchyData?.customers?.length === 0
			) {
				setDefaultMessage(true)
				setDefaultText(
					'Não foram encontrados dados para esses filtros.'
				)
				setDataTable([])
				return
			}

			const newData = salesHierarchyData?.customers?.map(
				(customer: any) => ({
					...customer,
					status:
						customer.acceptLGPDTerms === 'X' ? 'Ativo' : 'Inativo',
					revenda: customer.descSelesOrg,
					params:
						customer.acceptNotificationTerms === 'X'
							? 'Sim'
							: 'Não',
					salesOrganizationID: customer.salesOrganizationID,
					channel: customer.distributionChannelID,
					sales: customer.employeeName,
					details: '',
					invoiceConfirm: customer.invoiceConfirm
				})
			)
			handleStoredData(newData)
			setDataTable(newData)
			setDefaultMessage(false)
		} catch (error) {
			setDefaultMessage(true)
		} finally {
			setIsLoading(false)
		}
	}, [documentIsValid, documentNumber, dadosFilter, defaultMessage])

	const handleChangeDisabled = useCallback((verify: boolean) => {
		if (!verify) {
			return setIsDisabled(true)
		} else {
			return setIsDisabled(false)
		}
	}, [])

	const handleResultData = useCallback((document: any) => {
		setDadosFilter(document)
	}, [])

	const handleViewDetails = (dataReceived: string) => {
		history.replace(AdminRoutesEnum.CONSULT_CUSTOMER_INFO, { dataReceived })
	}

	const columns = React.useMemo(
		() => [
			{
				Header: 'Status',
				accessor: 'status',
				Cell: ({ value }: any) => (
					<StyleStatus isActive={value}> {value}</StyleStatus>
				)
			},
			{ Header: 'Unidade', accessor: 'salesOrganization' },
			{ Header: 'Parâmetro D+2', accessor: 'params' },
			{ Header: 'CD', accessor: 'salesOrganizationID' },
			{ Header: 'Canal', accessor: 'channel' },
			{ Header: 'Vendedor', accessor: 'sales' },
			{
				Header: 'Detalhes',
				accessor: 'details',
				Cell: ({ row }) => (
					<Details
						data={row.original}
						viewDetails={handleViewDetails}
					/>
				)
			}
		],
		[]
	)

	const visibleNotification = () => {
		setIsVisible(true)
		const timeoutId = setTimeout(() => {
			setIsVisible(false)
			handleFilter()
		}, 3000)
		return () => clearTimeout(timeoutId)
	}

	const handleMessageNotification = (message: string, type: string) => {
		setType(type)
		setMessage(message)
		visibleNotification()
	}

	return (
		<>
			<DefaultLayoutAdmin>
				<div>
					{isVisible && (
						<MessageStyle>
							<Notification
								message={message}
								type={type}
								isPortalAdmin
							/>
						</MessageStyle>
					)}
					<TitlePage titlePage="Consulta de Cliente" />

					<PageHeader>
						<h1 className="pg-title">Filtros</h1>
						<p className="pg-subtitle">
							*Obrigatório o preenchimento do CPF/CNPJ, Nome
							Cliente ou Código Cliente para habilitar a pesquisa.
						</p>
					</PageHeader>

					<FilterConsult
						handleCpf={handleChangeDisabled}
						sendData={handleResultData}
					/>

					<ButtonPrimary
						disabled={isDisabled}
						onClick={handleFilter}
						{...primaryButtonStyle}>
						PESQUISAR CLIENTE
					</ButtonPrimary>

					<DividerSimple />

					{!defaultMessage || isBackInThePage ? (
						isLoading ? (
							<div className="loading-wrapper">
								<Loading />
							</div>
						) : (
							<>
								<PageHeader
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
										alignItems: 'start'
									}}>
									<h1 className="pg-title">
										Informações do cliente
									</h1>

									<ButtonPrimary
										onClick={() =>
											setOpenModalDeleteUser(true)
										}
										{...primaryButtonStyle}>
										EXCLUIR CLIENTE
									</ButtonPrimary>
								</PageHeader>
								{storedData && storedData.length > 0 && (
									<>
										<Row>
											<InputField
												className="param-input"
												id="nome"
												label="nome cliente"
												value={
													storedData
														? storedData[0]
																?.name ||
														  '-'
														: dataTable[0]
																?.name ||
														  '-'
												}
												disabled
											/>
											<InputField
												className="param-input"
												id="cpfcnpj"
												label="cpf/cnpj"
												value={
													storedData
														? storedData[0]
																?.companyFiscalID ?? storedData[0]
																?.personalFiscalID : "-"
												}
												disabled

											/>
											<InputField
												className="param-input"
												id="unidade"
												label="unidade"
												value={
													dataTable[0]?.descSelesOrg
														? dataTable[0]
																?.descSelesOrg +
														  ' - ' +
														  dataTable[0]
																?.salesOrganizationID
														: dataTable[0]
																?.salesOrganizationID ||
														  '-'
												}
												disabled
											/>
											<InputField
												className="param-input"
												id="codigocliente"
												label="código cliente"
												value={
													storedData
														? storedData[0]
																?.customerId ||
														  '-'
														: dataTable[0]
																?.customerId ||
														  '-'
												}
												disabled
											/>
										</Row>

										<Row>
											<InputStyleSvg>
												<InputField
													className="param-input"
													id="email"
													label="e-mail"
													value={
														storedData
															? storedData[0]
																	?.email ||
															  '-'
															: dataTable[0]
																	?.email ||
															  '-'
													}
													disabled
												/>
												<EditOutlinedIcon
													onClick={() =>
														setOpenModalEmail(true)
													}
												/>
											</InputStyleSvg>
											<InputField
												className="param-input"
												id="telefonefixo"
												label="telefone Fixo"
												value={
													storedData
														? storedData[0]
																?.phone || '-'
														: dataTable[0]?.phone ||
														  '-'
												}
												disabled
											/>
											<InputStyleSvg>
												<InputField
													className="param-input"
													id="celular"
													label="celular"
													value={
														storedData
															? storedData[0]
																	?.cellPhone ||
															  '-'
															: dataTable[0]
																	?.cellPhone ||
															  '-'
													}
													disabled
												/>
												<EditOutlinedIcon
													onClick={() =>
														setOpenModalCel(true)
													}
												/>
											</InputStyleSvg>
										</Row>
										<BoxInfo>
											<PageHeader
												style={{
													display: 'flex',
													flexDirection: 'row',
													justifyContent:
														'space-between',
													alignItems: 'start'
												}}>
												<SearchInput
													width="359px"
													handleClickSearch={() => {}}
													inputValue={searchPush}
													handleChange={(event) =>
														setSearchPush(
															event.target.value
														)
													}
												/>
												<ButtonStyleBorder
													style={{
														...(!dataTable[0]
															?.invoiceConfirm && {
															border: 'none',
															opacity: '0.5'
														})
													}}></ButtonStyleBorder>
											</PageHeader>

											<Table
												data={
													storedData.length
														? storedData
														: dataTable
												}
												columns={columns}
											/>
										</BoxInfo>
									</>
								)}{' '}
							</>
						)
					) : (
						<GeneralTemplate text={defaultText} />
					)}
				</div>
			</DefaultLayoutAdmin>

			<ModalJustification
				handleNotifcation={handleMessageNotification}
				isOpen={openModalDeleteUser}
				handleToggleModal={() => setOpenModalDeleteUser(false)}
				document={storedData[0]?.companyFiscalID ?? '-'}
				customerId={storedData[0]?.customerId ?? '-'}
				title={'Excluir Cliente'}
				subtitle={'Você selecionou para excluir este cliente'}
				titleConfirmation={'Confirmar exclusão'}
				reason={'exclusão'}
				placeholder={'O cliente precisa refazer o seu cadastro'}
				textInfo={'Excluir o cliente não apaga o seu histórico'}
			/>

			{/* email */}
			<ModalJustification
				handleNotifcation={handleMessageNotification}
				isOpen={openModalEmail}
				handleToggleModal={() => setOpenModalEmail(false)}
				customerId={storedData[0]?.customerId ?? '-'}
				title={'Alterar e-mail'}
				titleConfirmation={'Confirmar alteração'}
				subtitle={`Alterar e-mail do cliente ${storedData[0]?.customerId}`}
				reason={'alteração'}
				placeholder={'O cliente não consegue acessar a sua conta'}
				modalType={'email'}
				oldValue={storedData[0]?.email ?? '-'}
				textInfo={`Após realizar a alteração do e-mail no cadastro, lembre o cliente de acessar o nosso portal WEB/APP e escolher a opção "Esqueci Minha Senha" para cria uma nova senha.`}
			/>
			{/* celular */}
			<ModalJustification
				handleNotifcation={handleMessageNotification}
				isOpen={openModalCel}
				handleToggleModal={() => setOpenModalCel(false)}
				customerId={dataTable[0]?.customerId ?? '-'}
				title={'Alterar celular'}
				titleConfirmation={'Confirmar alteração'}
				subtitle={`Alterar celular do cliente ${dataTable[0]?.customerId}`}
				reason={'alteração'}
				placeholder={'O cliente não consegue acessar a sua conta'}
				modalType={'celular'}
				oldValue={dataTable[0]?.cellPhone ?? '-'}
				textInfo={`Após realizar a alteração do celular no cadastro, lembre o cliente de acessar o nosso portal WEB/APP e escolher a opção "Esqueci Minha Senha" para cria uma nova senha.`}
			/>

			<NotaFiscalModal
				openModalNF={openModalNF}
				setOpenModalNF={setOpenModalNF}
				openModalInfoNF={openModalInfoNF}
				setOpenModalInfoNF={setOpenModalInfoNF}
			/>
		</>
	)
}
