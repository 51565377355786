import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { styled, css } from '@mui/material'

export const GoBackIcon = styled(ArrowBackIcon)(
	({ theme }) => css`
		color: ${theme.palette.black.main};
	`
)

export const Title = styled('p')(
	({ theme }) => css`
		color: ${theme.palette.black.main};
	`
)
