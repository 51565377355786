import React, { Fragment } from 'react'
import { IViewProps } from './types'
import Loading from '../../components/Loading'
import { Header, InputField } from '../../shared/components'
import ArrowLeftBlackIcon from '../../assets/images/arrow-left-black.svg'
import { formatCellphone } from '../../shared/utils/form'
import { checkTel } from '../../shared/utils/validators'

export default function ChangeCellphoneView(props: IViewProps): JSX.Element {
	const {
		handleBack,
		handleChangeCellphone,
		handleChangeCellphoneConfirmation,
		cellphone,
		cellphoneConfirmation,
		handleSubmit,
		buttonActive,
		isLoading
	} = props

	return (
		<Fragment>
			<Header />
			<div className="change-personal-email-page">
				<div className="col-10">
					<div className="row offset-lg-1">
						<button
							className="back-arrow-wrapper"
							onClick={handleBack}>
							<img
								className="back-arrow"
								color={'#ffffff'}
								src={ArrowLeftBlackIcon}
								alt="Voltar"
							/>
						</button>
					</div>
					<div className="row offset-lg-1">
						<h1 className="page-title">
							Alterar número de celular
						</h1>
					</div>
					<div className="centralizer">
						<div className="col-lg-5 col-sm-12">
							<InputField
								className="param-input"
								id="name"
								label="novo número"
								onChange={(e) =>
									handleChangeCellphone(e.target.value)
								}
								value={formatCellphone(cellphone)}
								hasError={
									cellphone.length >= 11 &&
									!checkTel(cellphone)
								}
								errorMessage="Número de celular inválido"
								disabledCopyPaste={true}
							/>
						</div>
						<div className="col-lg-5 col-sm-12">
							<InputField
								className="param-input"
								id="name"
								label="Confirmação do número"
								onChange={(e) =>
									handleChangeCellphoneConfirmation(
										e.target.value
									)
								}
								value={formatCellphone(cellphoneConfirmation)}
								hasError={
									cellphoneConfirmation.length >= 11 &&
									cellphoneConfirmation !== cellphone
								}
								errorMessage="Os números não estão iguais"
								disabledCopyPaste={true}
							/>
						</div>
					</div>

					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<div className="button-container">
							<button
								className="red-button"
								disabled={!buttonActive}
								onClick={handleSubmit}>
								{'SALVAR CELULAR'}
							</button>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}
