import { ISecurityQuestions } from "../interfaces/safe-questions.interface";

export function formateUtils(array: ISecurityQuestions[]) {
  const arrayData = array.map(e => {
    const result = {
      label: e.question,
      value: e.ID
    }
    return result;
  })

  return arrayData;
}