import React from 'react'
import { ConfirmModalItemProps } from './confirm-modal-item.types'
import { WrapperConfirmModalStyles } from './confirm-modal-item.styles'

function ConfirmModalItem({ itemTitle, itemText }: ConfirmModalItemProps) {
	return (
		<WrapperConfirmModalStyles>
			<h3 className="item-title">{itemTitle}</h3>
			<p className="item-text">{itemText}</p>
		</WrapperConfirmModalStyles>
	)
}

export default ConfirmModalItem
