import styled from 'styled-components'

export const ButtonDetailsRule = styled.button`
	width: 29px;
	height: 29px;
	border-radius: 50%;
	background-color: ${(props) => props.theme.palette.primaryWhite.main};
	border: none;
	cursor: pointer;
`

export const Icon = styled.img`
	width: 19px;
	height: 19px;
`
