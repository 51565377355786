import React, { useState } from 'react'
import * as S from '../styles'
import { UploadSpreadsheet } from '../../upload-spreadsheet/upload-spreadsheet'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CreatableSelect from 'react-select/creatable'
import { reactSelectCreatableStyleProgramPointsRulesAndChallenges } from '../../../shared/styles/theme'
import SelectImpactedPdv from '../../../modules/PointProgramChallengeDetailsV2/components/select-impacted-pdv/select-impacted-pdv.component'
import { PayloadExample } from '../../../modules/PointProgramChallengeDetailsV2/componentes/download-template-csv/download-template-types'
import { colorThemeProvider, theme } from '../../../theme/themeProvider'
import { FilterSelectPdvTypeProps } from '../typings'
import ErrorText from '../../error-text/error-text.component'

const labelStylesOfTheSelects = {
	marginLeft: '1.09rem',
	marginTop: '8px'
}

export function FilterSelectPdvType({
	isEnabled,
	setCanFilter,
	setSelectedPDVs,
	createPdvOption,
	canFilter,
	selectedPDVs,
	handleClearFields,
	setPushSelectedPDVs,
	setCanSendPdv,
	errorMessage,
	setListImpactedPdvs,
	listImpactedPdvs
}: FilterSelectPdvTypeProps) {
	const [selectedPDVCSV, setSelectedPDVCSV] = useState<string[]>([])
	const [haveCSV, setHaveCSV] = useState(false)

	const downloadTemplate = () => {
		const csvData = PayloadExample

		const blob = new Blob([csvData], { type: 'text/csv' })

		const url = window.URL.createObjectURL(blob)

		const a = document.createElement('a')
		a.href = url
		a.download = 'dados.csv'

		document.body.appendChild(a)
		a.click()

		document.body.removeChild(a)

		window.URL.revokeObjectURL(url)
	}

	const clearPdvsFile = () => {
		handleClearFields()
		setSelectedPDVCSV([])
		setHaveCSV(false)
		setSelectedPDVs([])
	}

	return (
		<S.PageSession>
			{/* Inicio filtro de seleção de PDVs */}
			<h2 className="pg-subtitle">Filtros de Seleção de PDV</h2>

			<S.ButtonsWrapper>
				{isEnabled && (
					<UploadSpreadsheet
						onCsvUpload={(pdvs) => {
							setSelectedPDVs(
								pdvs.map((value) => ({ value, label: value }))
							)

							setPushSelectedPDVs?.(pdvs)
							setListImpactedPdvs?.(pdvs)
						}}
						setSelectedPDVCSV={setSelectedPDVCSV}
						clearPdvsFile={clearPdvsFile}
						setHaveCSV={setHaveCSV}
						selectedPDVCSV={selectedPDVCSV}
						haveCSV={haveCSV}
						handleFilter={() => {
							setCanFilter(false)
							setCanSendPdv?.(true)
						}}
					/>
				)}

				<div style={{ position: 'relative', width: '165px' }}>
					<label
						style={{
							...labelStylesOfTheSelects,
							marginLeft: '0.7rem',
							color: !isEnabled
								? theme.palette.gray.light
								: colorThemeProvider.primary.main
						}}
						className="multiselect-label">
						Código do PDV
					</label>
					<CreatableSelect
						id="code-pdv"
						isDisabled={!isEnabled}
						isClearable={false}
						placeholder="Código do PDV"
						noOptionsMessage={() => 'Digite um código'}
						onCreateOption={createPdvOption}
						styles={
							reactSelectCreatableStyleProgramPointsRulesAndChallenges
						}
					/>
				</div>

				<SelectImpactedPdv
					isEnabled={isEnabled}
					label={String(selectedPDVs?.length)}
					options={selectedPDVs}
					setOptions={(newPdvArray) => {
						const labeledPdv = newPdvArray.map((pdv) => ({
							value: pdv,
							label: pdv
						}))
						setSelectedPDVs(labeledPdv)
					}}
					handleFilter={() => {
						setCanFilter(false)
					}}
				/>

				{isEnabled && (
					<S.WrapperActions isAbleToCleanFielters={canFilter}>
						{/* Funcionalidade de limpeza de filtros */}
						<DeleteOutlineIcon
							style={{ color: theme.palette.gray.main }}
							onClick={clearPdvsFile}
						/>
					</S.WrapperActions>
				)}
				<ErrorText error={errorMessage} />
			</S.ButtonsWrapper>

			<S.ContainerTextUpload className="pg-button-title-wrapper">
				<h2 className="pg-subtitle">Subir Carga</h2>

				<h2 className="pg-subtitle-text">
					Selecione os filtros ativos ou suba uma planilha com a base
					de dados para habilitar mais opções.
				</h2>
				<p className="pg-description">
					Para carregar um arquivo, clique no botão "SUBIR PLANILHA
					EXCEL", e escolha a planilha desejada em seu dispositivo. Em
					caso de dúvidas,
					<button
						className="pg-click-here-button"
						onClick={downloadTemplate}>
						clique aqui
					</button>
					para baixar um modelo de planilha.
				</p>
			</S.ContainerTextUpload>
		</S.PageSession>
	)
}
