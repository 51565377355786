import React from 'react'
import { IDetailsPushModal } from './details-push-modal.types'
import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import { DetailsModalWrapper } from './details-push-modal.styles'
import { Typography } from '@material-ui/core'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import { DataSelectorList } from '../../../../../components/data-selector-list'

const DetailsPushModal = ({
	isOpen,
	handleToggleModal,
	handleClick,
	details
}: IDetailsPushModal) => {
	return (
		<LayoutModal
			modalTitle="Detalhes do Push"
			buttonText="Retornar"
			typeLayout="details"
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			handleClick={handleClick}
			height="773px">
			<DetailsModalWrapper>
				<div className="params-wrapper">
					<h2>Parâmetros</h2>
					<div className="inputs-wrapper">
						<div className="input-collum">
							<DataSelectorList selectedOption={details?.filters.diretor[0]} title="Diretor" list={details?.filters.diretor || []} isNative={false}/>
							<DataSelectorList selectedOption={details?.filters.ufs[0]} title="UF" list={details?.filters.ufs || []} isNative={false} />
							<DataSelectorList selectedOption={details?.filters.channels[0]} title="Canal" list={details?.filters.channels || []} isNative={false}/>
						</div>

						<div className="input-collum">
							<DataSelectorList selectedOption={details?.filters.GRC[0]} title="GRC" list={details?.filters.GRC || []} isNative={false}/>
							<DataSelectorList selectedOption={details?.filters.cities[0]} title="Cidade" list={details?.filters.cities || []} isNative={false}/>
							<DataSelectorList selectedOption={details?.filters.codeResales[0]} title="CD" list={details?.filters.codeResales || []} isNative={false}/>
						</div>
					</div>
				</div>

				<div className="text-section">
					<Typography variant="h3">TÍTULO APLICADO</Typography>
					<Typography variant="body1" component="p">
						{details?.title}
					</Typography>
				</div>

				<div className="text-section last-item">
					<Typography variant="h3">MENSAGEM APLICADA</Typography>
					<Typography variant="body1" component="p">
						{details?.message}
					</Typography>
				</div>

				<ImpactedClients usersImpacted={details?.qtdCustomer || ''} />
			</DetailsModalWrapper>
		</LayoutModal>
	)
}

export default DetailsPushModal
