import { useCallback, useState } from "react"
import { axiosGPInstance } from "../../../../shared/services/axiosInstace"
import { IProduct } from "./types"
import { useDebounce } from "use-debounce";
import { formatProductsToView } from "./util/format-products-to-view";

export function useProducts() {
    const [products, setProducts] = useState<IProduct[]>([]);
    const [loading, setLoading] = useState(false);
    const [debouncedProducts] = useDebounce(products, 200);

    const getProducts = useCallback(async () => {
        try {
            setLoading(true);
            const { data } = await axiosGPInstance.get<IProduct[]>('/materials')
            const products = formatProductsToView(data)
            setProducts(products);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, []);

    return {
        products: debouncedProducts,
        loading,
        getProducts
    }
}