import styled from 'styled-components'
import { colors } from '../../shared/styles/theme'
import { toRem } from '../../shared/styles/functions'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${(props) => props.theme.spacingOptions.SM}px;

	background-color: ${(props) => props.theme.palette.primaryWhite.main};
	min-height: 100vh;

	.input-wrapper,
	.card-wrapper {
		display: flex;
		align-items: center;
		gap: ${(props) => props.theme.spacingOptions.SM}px;

		& > div {
			width: 368px;
		}
	}

	.DayPickerInput {
		width: 100%;
	}

	.select-filter {
		button {
			padding: 26px 16px 16px 16px;
		}
	}

	.title {
		margin-left: ${toRem(16)};
		z-index: 1;
		top: -4.5px;
		color: ${(props) => props.theme.palette.primary.main};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		margin-top: 15px;
		font-weight: ${(props) => props.theme.font.weight.bold};
		position: absolute;
		text-transform: uppercase;
	}
`
export const Title = styled.h1`
	color: ${colors.secondGrey};
	font-size: ${(props) => props.theme.font.size.DISPLAY_XS.size}rem;
	margin-top: 60px;
	margin-bottom: 16px;
`
export const Subtitle = styled.h4`
	color: ${colors.secondGrey};
	font-size: ${(props) => props.theme.font.size.TEXT_MD.size}rem;
	margin-bottom: 16px;
`

export const BoxInfo = styled.div`
	background-color: ${(props) => props.theme.palette.white.main};
	padding: 24px 16px;
	border-radius: 4px;

	p {
		color: ${colors.sixthGray};
		font-size: ${(props) => props.theme.font.size.TEXT_XS.size}rem;
		margin-bottom: 8px;
	}

	h1 {
		color: ${colors.secondGrey};
		font-size: ${(props) => props.theme.font.size.DISPLAY_MD.size}rem;
		font-weight: ${(props) => props.theme.font.weight.regular};
	}
`
export const LoadingWrapper = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
`
