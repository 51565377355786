import React, { Fragment } from 'react'
import ReactInputMask from 'react-input-mask'

import Loading from '../../components/Loading'
import { IViewProps } from './types'

function Confirmation(props: IViewProps): JSX.Element {
	const {
		// code,
		handleSetCode,
		// handleConfirmation,
		loading,
		handleBack
		// isDisabled
	} = props

	return (
		<Fragment>
			<section className="gradient-container" />
			<ReactInputMask
				id="code"
				mask={'999999'}
				maskChar={'_'}
				onChange={handleSetCode}
			/>

			{/* <button
				className="back-arrow-wrapper"
				onClick={handleBack}>
				<img
					className="back-arrow"
					src={ArrowLeftIcon}
					alt="Voltar"
				/>
			</button> */}

			<div className="container">
				<div className="row">
					<div className="confirmation-wrapper col-new-account">
						<h1 className="confirmation-title">
							Quase pronto!
							<br />
							<br /> Você receberá um email com instruções para
							confirmação de sua conta, após isso poderá logar
							normalmente.
						</h1>

						{/* <div className="code-input">
							<label
								htmlFor="code"
								className={`code-label ${
									code[0] ? 'active' : ''
									}`}>
								{code[0]}
							</label>
							<label
								htmlFor="code"
								className={`code-label ${
									code[1] ? 'active' : ''
									}`}>
								{code[1]}
							</label>
							<label
								htmlFor="code"
								className={`code-label ${
									code[2] ? 'active' : ''
									}`}>
								{code[2]}
							</label>
							<label
								htmlFor="code"
								className={`code-label ${
									code[3] ? 'active' : ''
									}`}>
								{code[3]}
							</label>
							<label
								htmlFor="code"
								className={`code-label ${
									code[4] ? 'active' : ''
									}`}>
								{code[4]}
							</label>
							<label
								htmlFor="code"
								className={`code-label ${
									code[5] ? 'active' : ''
									}`}>
								{code[5]}
							</label>
						</div> */}

						{loading ? (
							<div className="loading-wrapper">
								<Loading color="white" />
							</div>
						) : (
							<button onClick={handleBack} className="button">
								Voltar Login
							</button>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Confirmation
