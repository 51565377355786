import React from 'react'
import { Table } from '../../../shared/components'
import Header from '../../../components/Header'
import Loading from '../../../components/Loading'
import { IViewProps } from './types'
import InputFilter from '../../../components/InputFilter'
import ArrowIcon from '../../../assets/images/arrow-left-red.svg'

function ResaleList(props: IViewProps): JSX.Element {
	const { 
		tableData, 
		columns, 
		handleGoBack, 
		handleSearchOnChange, 
		isLoading, 
		refreshButton 
	} = props

	return (
		<div className="resale-products-page">
			<Header />
			<div className="resale-params-table-wrapper col-12">
				<div className="col-12 col-lg-10 arrow-wrapper row">
					<button
						className="back-arrow-wrapper row"
						onClick={handleGoBack}>
						<img
							className="back-arrow"
							src={ArrowIcon}
							alt="Voltar"
						/>
						<p>{'Voltar'}</p>
					</button>
				</div>
				<div className="col-12 col-lg-10 title-wrapper location">
					<div className="col-8 text-container">
						<p className="resale-title">Detalhes do Status de Processamento</p>
					</div>

					<div className="col-4 back-button-wrapper">
						<label
							className="back-arrow-button white-button"
							color={'#ffffff'}
							onClick={refreshButton}>
							
							<span className="resale-refresh"> Atualizar dados  &#x21BA;</span>
						</label>
					</div>
				</div>

				<div className="col-12 col-lg-10">
					<div className="search-wrapper">
						<InputFilter
							className="search-input"
							onChange={handleSearchOnChange}
							placeholder="Filtro"
						/>
					</div>
				</div>

				<div className="col-10">
					{isLoading ? (
						<div className="loading-wrapper">
							<Loading />
						</div>
					) : (
						<Table
							data={tableData}
							columns={columns as any}
						/>
					)}

				</div>
			</div>
		</div>
	)
}

export default ResaleList
