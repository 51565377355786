import { createElement } from 'react'

import Cart from '../Cart'
import MyAccount from '../MyAccount'
import OrderDetail from '../OrderDetail'
import OrderHistory from '../OrderHistory'
import OrderSummary from '../OrderSummary'
import ProductList from '../ProductList'
import Success from '../Success'
import ChangeEmail from '../changeEmail'
import ChangeCellphone from '../ChangeCellphone'
import Combo from '../Combo'
import MyPoints from '../MyPoints'
import PointsRedemption from '../PointsRedemption'
import PointsProgramFAQ from '../PointsProgramFAQ'
import PixQrCode from '../PixQrCode'
import ConfirmEditCellPhoneCode from '../ConfirmEditCellPhoneCode'
import RegistrationUpdate from '../../views/registration-update/registration-update.view'

import { IRouteDataV2 } from './types'
import { CustomerRoutesEnum } from './customerRoutesEnum'
import { UserTypeEnum } from '../../shared/interfaces/customer'
import ChallengeDetails from '../ChallengeDetails'
import Challenges from '../Challenges'
import Welcome from '../Welcome'

export const customerRoutesData: IRouteDataV2[] = [
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Produtos',
		key: CustomerRoutesEnum.PRODUCT_LIST,
		path: CustomerRoutesEnum.PRODUCT_LIST,
		children: createElement(ProductList)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Combos',
		key: CustomerRoutesEnum.COMBO_LIST,
		path: CustomerRoutesEnum.COMBO_LIST,
		children: createElement(Combo)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Meu carrinho',
		key: CustomerRoutesEnum.CART,
		path: CustomerRoutesEnum.CART,
		children: createElement(Cart)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.ORDER_SUMMARY,
		path: CustomerRoutesEnum.ORDER_SUMMARY,
		children: createElement(OrderSummary)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.MY_ACCOUNT,
		path: CustomerRoutesEnum.MY_ACCOUNT,
		children: createElement(MyAccount)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Historico de Pedidos',
		key: CustomerRoutesEnum.ORDER_HISTORY,
		path: CustomerRoutesEnum.ORDER_HISTORY,
		children: createElement(OrderHistory)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.ORDER_DETAIL,
		path: CustomerRoutesEnum.ORDER_DETAIL + '/:orderID',
		children: createElement(OrderDetail)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.SUCCESS,
		path: CustomerRoutesEnum.SUCCESS,
		children: createElement(Success)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.RECOVER_EMAIL,
		path: CustomerRoutesEnum.RECOVER_EMAIL,
		children: createElement(ChangeEmail)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.CHANGE_CELLPHONE,
		path: CustomerRoutesEnum.CHANGE_CELLPHONE,
		children: createElement(ChangeCellphone)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.CONFIRM_EDIT_CELLPHONE_CODE,
		path: CustomerRoutesEnum.CONFIRM_EDIT_CELLPHONE_CODE,
		children: createElement(ConfirmEditCellPhoneCode)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Meus Pontos',
		key: CustomerRoutesEnum.POINTS_PROGRAM__MY_POINTS,
		path: CustomerRoutesEnum.POINTS_PROGRAM__MY_POINTS,
		children: createElement(MyPoints)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Resgate',
		key: CustomerRoutesEnum.POINTS_PROGRAM__POINTS_REDEMPTION,
		path: CustomerRoutesEnum.POINTS_PROGRAM__POINTS_REDEMPTION,
		children: createElement(PointsRedemption)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: 'Informações do desafio',
		key: CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS,
		path: CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAILS,
		children: createElement(ChallengeDetails)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.POINTS_PROGRAM__KNOW_MORE,
		path: CustomerRoutesEnum.POINTS_PROGRAM__KNOW_MORE,
		children: createElement(PointsProgramFAQ)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: '',
		key: CustomerRoutesEnum.PIX_QR_CODE,
		path: CustomerRoutesEnum.PIX_QR_CODE,
		children: createElement(PixQrCode)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: true,
		name: 'Desafios',
		key: CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGES,
		path: CustomerRoutesEnum.POINTS_PROGRAM__CHALLENGES,
		children: createElement(Challenges)
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: 'Bem vindo',
		key: CustomerRoutesEnum.WELCOME,
		path: CustomerRoutesEnum.WELCOME,
		children: createElement(Welcome),
		customerPending: true
	},
	{
		accessTypes: [UserTypeEnum.CUSTOMER],
		displayOnNavBar: false,
		name: 'Atualização cadastral',
		key: CustomerRoutesEnum.REGISTRATION_UPDATE,
		path: CustomerRoutesEnum.REGISTRATION_UPDATE,
		children: createElement(RegistrationUpdate)
	}
]
