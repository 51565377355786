import { Auth } from 'aws-amplify'
import axios, { AxiosInstance, AxiosResponse } from 'axios'

import handleError from '../../modules/Error/handleError'
import { Creators as BottomBarActions } from '../../shared/store/ducks/bottomBar'
import { Creators as CartActions } from '../../shared/store/ducks/cart'
import { Creators as CustomerActions } from '../../shared/store/ducks/customer'
import { Creators as TokenActions } from '../../shared/store/ducks/token'
import { Creators as CompanyParamsActions } from '../../shared/store/ducks/companyParams'
import { Creators as PointsProgramStatusActions } from '../../shared/store/ducks/pointsProgramStatus'
import { Creators as CartRedemptionsItems } from '../../shared/store/ducks/cartRedemptionItem'
import { Creators as CartChallengeItemsActions } from '../../shared/store/ducks/cartChallengeItems'
import { Creators as ChallengesAlreadyShownActions } from '../../shared/store/ducks/challengesAlreadyShown'
import { Creators as ChallengesTheUserIsParticipatingInActions } from '../../shared/store/ducks/challengesTheUserIsParticipatingIn'
import { store } from '../store'
import { IOAuthResponse } from './types'
import { ICustomer } from '../interfaces/customer'
import { refreshUserSession } from './auth.service'
import { checkIfTokenIsExpired } from '../utils/session'
import {
	getApiBaseUrl,
	getLambdaApiBaseUrl
} from '../../utils/getApiBaseUrl.utils'

// console.log(process.env)

export const API_URL: string = getApiBaseUrl('customer')
const BACKEND_URL: string = getApiBaseUrl('customer')
const GP_URL: string = getApiBaseUrl('admin')
const API_MAINTENANCE = getLambdaApiBaseUrl()

const axiosInstance: AxiosInstance = axios.create({
	baseURL: API_URL,
	timeout: 300000,
	headers: {
		'Content-Type': 'application/json'
	}
})

const axiosInstanceMaintenance: AxiosInstance = axios.create({
	baseURL: API_MAINTENANCE,
	timeout: 300000,
	headers: {
		'Content-Type': 'application/json'
	}
})

export const logout = (): void => {
	store.dispatch(BottomBarActions.hideBar())
	store.dispatch(CustomerActions.resetCustomer())
	store.dispatch(TokenActions.resetToken())
	store.dispatch(CompanyParamsActions.resetParams())
	store.dispatch(PointsProgramStatusActions.resetPointsProgramStatus())
	store.dispatch(CartRedemptionsItems.clearRedemptionItems())
	store.dispatch(CartChallengeItemsActions.clearChallengeItems())
	store.dispatch(ChallengesAlreadyShownActions.resetChallengesShown())
	store.dispatch(ChallengesTheUserIsParticipatingInActions.clearChallenges())
	Auth.signOut()
}

axiosInstance.interceptors.request.use(
	async (request) => {
		const jwtToken = store.getState().token.JWTToken
		// verifica se existe usuário logado, mesmo sem CustomerID, pois esta pendente de aprovacao.
		const isLogged =
			(!!store.getState().customer?.PersonalFiscalID ||
				!!store.getState().customer?.CompanyFiscalID) &&
			!!jwtToken

		const tokenIsExpired = isLogged && !!checkIfTokenIsExpired(jwtToken)

		let refreshedAccessToken = ''

		if (tokenIsExpired) {
			refreshedAccessToken = await refreshUserSession()
			if (!refreshedAccessToken) {
				logout()
				return
			}
		}

		const accessToken: string = refreshedAccessToken || jwtToken
		if (accessToken) {
			request.headers.Authorization = `Bearer ${accessToken}`
		}
		return request
	},
	(err) => {
		console.error('REQUEST', { err })
		handleError(err?.request?.data)
		throw err
	}
)

axiosInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		console.error('RESPONSE', { err })
		handleError(err?.response?.data)
		throw err
	}
)

export const axiosGPInstance: AxiosInstance = axios.create({
	baseURL: GP_URL,
	timeout: 300000,
	headers: {
		'Content-Type': 'application/json'
	}
})

export async function getJwtAuthGpAcessToken(): Promise<string | null> {
	try {
		const API_TOKEN_PROVIDER_ENDPOINT =
			'https://api-token-provider.cfapps.br10.hana.ondemand.com'
		const API_TOKEN_PROVIDER_AUTHENTICATION =
			'Basic YXBpLXRva2VuLXByb3ZpZGVyLXVzZXI6RmQ0d0NVbWg2cE5FIXhedw=='

		const response: AxiosResponse<IOAuthResponse> = await axios.post(
			`${API_TOKEN_PROVIDER_ENDPOINT}/v1/api/token/cognito/signin`,
			{
				user: 'bpgpadmin',
				password: 'BE#pK!DvcgfuH96P'
			},
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: API_TOKEN_PROVIDER_AUTHENTICATION
				}
			}
		)

		return response?.data.accessToken
	} catch (error) {
		throw error
	}
}

axiosGPInstance.interceptors.request.use(
	async (request) => {
		const accessToken = await getJwtAuthGpAcessToken()
		const customer: ICustomer = store.getState().customer
		request.headers.Authorization = `Bearer ${accessToken}`
		request.headers.userID = customer.CustomerID

		return request
	},
	(err) => {
		console.error('GP REQUEST', { err })
		handleError(err?.request?.data)
		throw err
	}
)

axiosGPInstance.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		console.error('GP RESPONSE', { err })
		handleError(err?.response?.data)
		throw err
	}
)

//  backend
export const axiosGPBackend: AxiosInstance = axios.create({
	baseURL: BACKEND_URL,
	timeout: 300000,
	headers: {
		'Content-Type': 'application/json'
	}
})
axiosGPBackend.interceptors.request.use(
	async (request) => {
		const accessToken = 'Basic YXBpLWIyYi11c2VyOjlaVmpOTTY4TSN0Vi1qZ20='
		const customer: ICustomer = store.getState().customer
		request.headers.Authorization = accessToken
		request.headers.userID = customer.CustomerID

		return request
	},
	(err) => {
		console.error('GP REQUEST BACKEND', { err })
		handleError(err?.request?.data)
		throw err
	}
)

axiosGPBackend.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		console.error('GP RESPONSE BACKEND', { err })
		handleError(err?.response?.data)
		throw err
	}
)

export const apiCustomer = axiosInstance
export const apiAdmin = axiosGPInstance
export const apiMaintenance = axiosInstanceMaintenance

export default axiosInstance
