import { IFilterListPdvs, IFilterOptions } from "../typings"

export function formatedFilters(
  inputDataFilter: IFilterOptions & {
    codePdv?: string[]
  },
  shouldListPdv: boolean
): IFilterListPdvs {
   
  let ufs = inputDataFilter.STATE?.map((value) => value.value) || []
	let cities = inputDataFilter.CITY?.map((value) => value.value) || []
	let channels = inputDataFilter.CHANNEL?.map((value) => value.value) || []
	let codeResales = inputDataFilter.RESALE?.map((value) => value.value) || []
	let diretor = inputDataFilter.DIRECTOR?.map((value) => value.value) || []
	let GRC = inputDataFilter.GOV?.map((value) => value.value) || []
	let codePdv = inputDataFilter.codePdv || [] 
  
  
    const outputDataFilter = {
      ufs,
      cities,
      channels,
      codeResales,
      diretor,
      GRC,
      codePdv,
      customers: [],
		  listPdvs: shouldListPdv
	  }
  
    return outputDataFilter
  }
