import React from 'react'
import { IResponseMaintenance } from './maintenance-message.types'
import { useHistory, useLocation } from 'react-router-dom'
import StatusPage from '../../components/StatusPage/status-page.component'
import { WrapperMaintenance } from './maintenance-message.styles'
import { UnauthenticatedRoutesEnum } from '../../modules/Routes/unauthenticatedRoutesEnum'

function MaintenanceMessage() {
	const { state } = useLocation<IResponseMaintenance>()
	const history = useHistory();
	if(!state){
		history.replace(UnauthenticatedRoutesEnum.LOGIN)
	}
	
	return (
		<WrapperMaintenance>
			<StatusPage
				type={state.type}
				title={state.title}
				message={state.message}

			/>
		</WrapperMaintenance>
	)
}

export default MaintenanceMessage
