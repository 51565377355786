import styled, { css } from 'styled-components'

interface IWrapperInput {
	width: string
}

export const InformationCupomWrapper = styled.div`
	${() => css`
		display: flex;
		align-items: flex-start;
		justify-content: center;
		flex-direction: column;
	`}
`

export const WrapperInputs = styled.div`
	${({ theme }) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacingOptions.MD}px;
		width: 100%;
	`}
`

export const WrapperInput = styled.div<IWrapperInput>`
	${({ width }) => css`
		width: ${width};
	`}
`

export const FirstRow = styled.div`
	display: grid;
	grid-template-columns: 3fr 3fr 1fr;
	gap: ${(props) => props.theme.spacingOptions.SM}px;
	align-items: flex-start;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`

export const SecondRow = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: 2fr 2fr 1fr auto;
	gap: ${(props) => props.theme.spacingOptions.SM}px;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`
