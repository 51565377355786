import { TradeTypeEnum } from './combo'
import { IPriceByQuantity } from './material'

export interface ICartItem {
	MaterialID?: string
	UnitMeasureSale?: string

	priceByUnit?: number
	olderPrice?: number

	OrderQuantity: number
	Price: number
	Size: string
	PriceByQuantity: Array<IPriceByQuantity>
	ImageUrl: string
	comboImageUrl?: string
	StockPosition: number
	Description: string
	PackType?: string
	PromotionOlderPrice?: number
	MaxQuantityTradePromotion?: number

	promotionID?: string
	promotionType?: number
	promotionShortName?: string
	operationTypeDescription?: OperationTypeDescriptionEnum
	products?: ICartComboProduct[]

	// Used only in order history, this will not exist otherwise (Workaround don't do this at home)
	operationType?: TradeTypeEnum

	ScoreValue?: string
	ScoreRate?: string

	// Identifying if the material is rescue
	pointsRedeemed?: number
	rescueProduct?: boolean
	typeItem?: ICartItemTypeEnum
	category?: string
	challengeID?: string
	tradePrice?: number
	customerId?: string
	itemUnitiesQuantity?: string
	total?: number
	//Used only in price action
	actionPriceUndiscountedSalesValue?: number
	actionPriceFinalSalesValue?: number
}

export enum ICartItemTypeEnum {
	NORMAL = 'NORMAL',
	REDEMPTION = 'REDEMPTION',
	CHALLENGE_ITEM = 'CHALLENGE_ITEM'
}

export interface ICartComboProduct {
	tradeType: TradeTypeEnum
	quantity: number
	productID: string
	unitMeasureSale: string
	price: number
	oldPrice: number
	taxValue?: number
	numeratorConversion?: string
}

export enum OperationTypeDescription {
	Combo = 'Combo',
	Ação = 'Ação'
}

export type OperationTypeDescriptionEnum = keyof typeof OperationTypeDescription
