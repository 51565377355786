import {
	IFilterSalesHierarchyDirector,
	IChallenge,
	ChallengeParamsNameEnum
} from '../../shared/interfaces/pointsProgramChallenge'
import { PackageTypeEnum, UnitMeasureEnum } from '../RegisterProduct/types'

export interface IProps {
	challenge?: IChallenge
}

export interface ITableData {
	code: string
	name: string
	packageType: PackageTypeEnum
	unitMeasure: UnitMeasureEnum
	size: string
	ScoreValue?: string
	ScoreRate?: string
	button: JSX.Element
}

export interface IOption {
	value: string
	label: string
}

export interface IFilterOptions {
	[ChallengeParamsNameEnum.DIRECTOR]: IOption[]
	[ChallengeParamsNameEnum.GOV]: IOption[]
	[ChallengeParamsNameEnum.RESALE]: IOption[]
	[ChallengeParamsNameEnum.CHANNEL]: IOption[]
	[ChallengeParamsNameEnum.STATE]: IOption[]
	[ChallengeParamsNameEnum.CITY]: IOption[]
}

export interface IFilterListPdvs {
	ufs: string[]
	cities: string[]
	channels: string[]
	codeResales: string[]
	diretor: string[]
	GRC: string[]
	codePdv: string[]
	customers:string[]
	listPdvs: boolean
}

export interface IValuesByProps {
	filterSelectOptions: IFilterOptions
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: IOption[]
	selectedFilterSelectOptions?: IFilterOptions
}

export enum TypeDoc {
	TEXTCSV = 'text/csv'
}
export enum DocHeader {
	PDV = 'pdv'
}

export enum MaxSizeFile {
	CSV = 1024 * 1024 * 70
}
