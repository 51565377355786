import React from 'react'
import * as S from './choice-quantity.styles'
import { IChoiceQuantity } from './choice-quantity.types'

function ChoiceQuantity({ label, min, max, isDisabled }: IChoiceQuantity) {
	return (
		<S.WrapperStyledChoiceQuantity isDisabled={isDisabled}>
			<div className="left-content">{label}</div>
			<div className="right-content">
				{min} de {max}
			</div>
		</S.WrapperStyledChoiceQuantity>
	)
}

export default ChoiceQuantity
