import {
	IProduct,
	UnitMeasureEnum,
	PackageTypeEnum,
	ScoreValueEnum
} from './types'

export type Product = Omit<IProduct, 'categoryId' | 'Category'> & {
	categoryId: string[]
	Category: string | string[]
}

export type PersistenceProduct = {
	ID: string
	MaterialID: string
	Description: string
	UnitMeasureSale: UnitMeasureEnum
	SiteDescription: string
	ImageUrl: string
	PackageImageUrl: string
	Category: string[]
	Brand: string
	Package: string
	PackageType: PackageTypeEnum
	Family: string
	Style: string
	Coloration: string
	AlcoholContent: string
	UnitBarcode: string
	PackageBarcode: string
	IBU: string
	LogicalDelete: string
	ScoreValue: ScoreValueEnum
	ScoreRate: string
	HasChannelCustom: boolean
	CategoryID: string[]
	listingPosition: number
	showInBonification: boolean
}

class RegisterProductMapper {
	toPersistence(domain: Product): Product {
		return {
			MaterialID: domain.MaterialID ?? '',
			Description: domain.Description ?? '',
			UnitMeasureSale: domain.UnitMeasureSale ?? '',
			SiteDescription: domain.SiteDescription ?? '',
			ImageUrl: domain.ImageUrl ?? '',
			PackageImageUrl: domain.PackageImageUrl ?? '',
			Brand: domain.Brand ?? '',
			Package: domain.Package ?? '',
			PackageType: domain.PackageType ?? '',
			Family: domain.Family ?? '',
			Style: domain.Style ?? '',
			Coloration: domain.Coloration ?? '',
			AlcoholContent: domain.AlcoholContent ?? '',
			UnitBarcode: domain.UnitBarcode ?? '',
			PackageBarcode: domain.PackageBarcode ?? '',
			IBU: domain.IBU ?? '',
			LogicalDelete: domain.LogicalDelete ?? '',
			ScoreValue: domain.ScoreValue ?? '',
			ScoreRate: domain.ScoreRate ?? '',
			HasChannelCustom: domain.HasChannelCustom ?? false,
			categoryId: domain.categoryId ?? '',
			listingPosition: domain.listingPosition,
			showInBonification: domain.showInBonification,
			Category: domain.Category ?? ''
		}
	}
	toPersistenceEdit(domain: Product): Product {
		return {
			ID: domain.ID ?? '',
			MaterialID: domain.MaterialID ?? '',
			Description: domain.Description ?? '',
			UnitMeasureSale: domain.UnitMeasureSale ?? '',
			SiteDescription: domain.SiteDescription ?? '',
			ImageUrl: domain.ImageUrl ?? '',
			PackageImageUrl: domain.PackageImageUrl ?? '',
			Brand: domain.Brand ?? '',
			Package: domain.Package ?? '',
			PackageType: domain.PackageType ?? '',
			Family: domain.Family ?? '',
			Style: domain.Style ?? '',
			Coloration: domain.Coloration ?? '',
			AlcoholContent: domain.AlcoholContent ?? '',
			UnitBarcode: domain.UnitBarcode ?? '',
			PackageBarcode: domain.PackageBarcode ?? '',
			IBU: domain.IBU ?? '',
			LogicalDelete: domain.LogicalDelete ?? '',
			ScoreValue: domain.ScoreValue ?? '',
			ScoreRate: domain.ScoreRate ?? '',
			HasChannelCustom: domain.HasChannelCustom ?? false,
			categoryId: domain.categoryId ?? '',
			listingPosition: domain.listingPosition,
			showInBonification: domain.showInBonification,
			Category: domain.Category ?? ''
		}
	}

	toDomain(persistence: PersistenceProduct): Product {
		return {
			ID: persistence.ID ?? '',
			MaterialID: persistence.MaterialID ?? '',
			Description: persistence.Description ?? '',
			UnitMeasureSale: persistence.UnitMeasureSale ?? '',
			SiteDescription: persistence.SiteDescription ?? '',
			ImageUrl: persistence.ImageUrl ?? '',
			PackageImageUrl: persistence.PackageImageUrl ?? '',
			Brand: persistence.Brand ?? '',
			Package: persistence.Package ?? '',
			PackageType: persistence.PackageType ?? '',
			Family: persistence.Family ?? '',
			Style: persistence.Style ?? '',
			Coloration: persistence.Coloration ?? '',
			AlcoholContent: persistence.AlcoholContent ?? '',
			UnitBarcode: persistence.UnitBarcode ?? '',
			PackageBarcode: persistence.PackageBarcode ?? '',
			IBU: persistence.IBU ?? '',
			LogicalDelete: persistence.LogicalDelete ?? '',
			ScoreValue: persistence.ScoreValue ?? '',
			ScoreRate: persistence.ScoreRate ?? '',
			HasChannelCustom: persistence.HasChannelCustom ?? false,
			categoryId: persistence.CategoryID ?? '',
			listingPosition: persistence.listingPosition,
			showInBonification: persistence.showInBonification,
			Category: persistence.Category ?? ''
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RegisterProductMapper()
