import React from 'react'
import GPLogo from '../../assets/images/logo-bp-red.svg'
import { HeaderWrapper } from './styles'

export default function HeaderNotLogged() {
	return (
		<HeaderWrapper>
			<h2>Primeiro Acesso</h2>
			<img className="gp-logo" src={GPLogo} alt="Grupo Petrópolis" />
		</HeaderWrapper>
	)
}
