import React, { Fragment, useContext, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import {
	BannersCarousel,
	ComboCard,
	Header,
	LightBox,
	Loading,
	PointsProgramModalAdoption,
	SecondaryButton
} from '../../shared/components'
import Filters from './Filters'
import List from './List'
import ProductModal from './ProductModal'
import { NoLocationServiceContainer } from './styles'
import { IViewProps } from './types'

import connectErrorPlaceholderIcon from '../../assets/images/connecterror-placeholder.svg'
import rangeOffPlaceholderIcon from '../../assets/images/rangeoff-placeholder.svg'
import { Notification } from '../../components/Notification/index'
import { UnavailableProductContext } from '../../context/UnavailableProductContext'
import MorePointsPageHeader from './MorePointsPageHeader'
import ComboFlex from '../../components/combo-flex/combo-flex.component'

function ProductList(props: IViewProps): JSX.Element {
	const history = useHistory()

	const {
		selectOptionsOne,
		selectOptionsTwo,
		selectOptionsThree,
		renderProductList,
		setSelectedFilters,
		handleSelectOne,
		handleSelectTwo,
		handleSelectThree,
		handleSearchInput,
		selectedProduct,
		handleSelectedProduct,
		productAmount,
		handleIncrement,
		handleDecrement,
		modalOpen,
		closeModal,
		handleUpdateAmount,
		selectedFilters,
		handleClear,
		lightboxPhotos,
		openLightbox,
		setOpenLightbox,
		comboData,
		handleComboNavigation,
		handleSelectedCombo,
		selectedCombo,
		handleAddItemToCard,
		handleReload,
		locationHasService,
		showPointsProgramModal,
		handleClosePointsProgramModal,
		handleChangeCategory,
		showProductsWithMorePoints,
		handleGoBackToAllProducts,
		isFetchingCombos,
		isFetchingMaterials,
		showReloadCombos,
		showReloadMaterials,
		challengesBanner,
		handleRegisterUnavailableProduct
	} = props

	const filtersProps = {
		selectOptionsOne,
		selectOptionsTwo,
		selectOptionsThree,
		setSelectedFilters,
		handleSelectOne,
		handleSelectTwo,
		handleSelectThree,
		handleSearchInput,
		selectedFilters,
		handleClear,
		isLoading: isFetchingMaterials
	}

	const ProductListProps = {
		handleSelectedProduct,
		renderProductList
	}

	const inputIncrementProps = {
		value: productAmount,
		enableButtons: true,
		handleIncrementEvent: handleIncrement,
		handleDecrementEvent: handleDecrement,
		handleUpdate: handleUpdateAmount
	}

	const { selectedProducts, showNotification } = useContext(
		UnavailableProductContext
	)
	const isProductAlreadySelected = selectedProducts.filter(
		(item) => item.materialNumber === selectedProduct?.MaterialID
	)

	const topReference = useRef<HTMLDivElement | null>(null)
	const scrollToTop = () => {
		topReference.current?.scrollIntoView({ behavior: 'smooth' })
	}

	useEffect(() => {
		if (showNotification) {
			scrollToTop()
		}
	}, [isProductAlreadySelected, showNotification])

	function renderReloadOption(service: 'combos' | 'materials') {
		return (
			<div
				className="placeholder-wrapper"
				style={{
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					padding: 0
				}}>
				<div className="placeholder-container">
					<div className="gp-logo-wrapper">
						<img
							className="gp-logo"
							src={connectErrorPlaceholderIcon}
							alt="Grupo Petrópolis"
							style={{
								height: 64,
								width: 64
							}}
						/>
					</div>
					<h1
						style={{
							width: '100%',
							marginBottom: 32
						}}>
						{`Houve algum erro ao buscar os ${
							service === 'combos' ? 'combos' : 'produtos'
						}`}
					</h1>
					<button
						onClick={() => handleReload(service)}
						className="button">
						Tentar novamente
					</button>
				</div>
			</div>
		)
	}

	function renderCombosAndProductsPanel() {
		return (
			<div className="row">
				<Filters {...filtersProps} />

				{!showProductsWithMorePoints ? (
					<div className="col-12">
						<div className="page-title">
							<h2>Combos de Produtos</h2>
						</div>

						<div className="card-list" id="card-list-combo">
							{isFetchingCombos ? (
								<div className="loading-wrapper">
									<Loading />
								</div>
							) : showReloadCombos ? (
								renderReloadOption('combos')
							) : (
								renderCombos()
							)}
						</div>
					</div>
				) : null}

				<div className="col-12">
					<div className="page-title">
						<h2>Produtos Individuais</h2>
					</div>
				</div>
				{isFetchingMaterials ? (
					<div className="loading-wrapper">
						<Loading />
					</div>
				) : showReloadMaterials ? (
					renderReloadOption('materials')
				) : (
					renderProducts()
				)}
				<div style={{ height: 80, width: '100%' }} />
			</div>
		)
	}

	function renderCombos() {
		return comboData.length ? (
			<Fragment>
				{comboData.map((combo, index) => (
					<div
						onClick={() => handleSelectedCombo(combo)}
						key={index}
						className="card-item-wrapper">
						{combo.isComboFlex ? (
							<ComboFlex handleClick={() => {}} combo={combo} />
						) : (
							<ComboCard data={combo} />
						)}
					</div>
				))}
				<div className="combo-section">
					<SecondaryButton
						onClick={handleComboNavigation}
						title="MAIS COMBOS"
					/>
				</div>
			</Fragment>
		) : (
			<div className="no-product-placeholder-wrapper">
				<h4>Nenhum Combo Encontrado...</h4>
			</div>
		)
	}

	function renderProducts() {
		return (
			<div className="col-12">
				<List {...ProductListProps} />
			</div>
		)
	}

	function goProductList() {
		history.push('/product-list')
	}

	if (!locationHasService)
		return (
			<Fragment>
				<Header />

				<NoLocationServiceContainer>
					<img src={rangeOffPlaceholderIcon} alt="Range off icon" />
					<span>
						Oops! Infelizmente ainda não chegamos na sua região
					</span>
					<span>
						ESTAMOS EXPANDINDO NOSSA COBERTURA E VOCÊ SERÁ
						NOTIFICADO EM BREVE!
					</span>
				</NoLocationServiceContainer>
			</Fragment>
		)

	return (
		<>
			<div ref={topReference}>
				<Header isLoading={isFetchingMaterials || isFetchingCombos} />

				{isProductAlreadySelected && showNotification && (
					<Notification
						type="success"
						message="Quando o produto estiver disponível, vamos te avisar!"
					/>
				)}

				<div className="product-list-page">
					<div className="container">
						{!showProductsWithMorePoints ? (
							<BannersCarousel
								onPressItem={handleChangeCategory}
								challenges={challengesBanner}
								isFetchingMaterials={isFetchingMaterials}
							/>
						) : (
							<MorePointsPageHeader
								goBack={() => {
									handleGoBackToAllProducts()
									goProductList()
								}}
								isFetchingMaterials={isFetchingMaterials}
							/>
						)}
						{renderCombosAndProductsPanel()}
					</div>
				</div>

				{selectedCombo?.isComboFlex ? (
					''
				) : (
					<ProductModal
						closeModal={closeModal}
						handleAddProduct={
							selectedProduct
								? () => handleAddItemToCard(selectedProduct)
								: () => handleAddItemToCard(selectedCombo)
						}
						inputIncrementProps={inputIncrementProps}
						modalOpen={modalOpen}
						productAmount={productAmount}
						selectedProduct={selectedProduct || selectedCombo}
						setOpenLightbox={setOpenLightbox}
						type={selectedProduct ? 'PRODUCT' : 'COMBO'}
						handleUnavailableProduct={
							handleRegisterUnavailableProduct
						}
					/>
				)}

				<LightBox
					isOpen={openLightbox}
					setIsOpen={setOpenLightbox}
					lightboxPhotos={lightboxPhotos}
				/>

				<PointsProgramModalAdoption
					show={showPointsProgramModal}
					onClose={handleClosePointsProgramModal}
				/>
			</div>
		</>
	)
}

export default ProductList
