export const stringToProperCase = (str: string): string => {
	if (!str) return str

	const [first, ...rest] = str

	return first.toUpperCase() + rest.join('').toLowerCase()
}

/**
 * @param str String from which the expression will be picked
 */
export const pickFileNameFromUrl = (str: string): string => {
	// picks everything after the last '/'
	const idx = str.search(/[^/]*$/g)

	return str.slice(idx)
}

export const formatCSVData = <T>(data: T[], headers?: string[]): string => {
	const initial = headers?.length ? headers.join(',') : ''

	const csv = data.reduce((str: string, item: T) => {
		const line = Object.values(item).join(',')

		return str + '\n' + line
	}, initial)

	return csv
}

export const stringComp = (a: string, b: string): number => a.localeCompare(b)

export function capitalizeFirstLetter(value: string) {
	return value.charAt(0).toUpperCase() + value.slice(1)
}

export function cleanDocumentNumber(documentNumber: string): string {
	return documentNumber.replace(/[-/.]/g, '')
}