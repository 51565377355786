import styled from 'styled-components'

export const CancelPushModalStyles = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	padding: 30px 0px;

	.subtitle-modal {
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;

		color: ${(props) => props.theme.palette.black.main};;
	}

	.info-modal-cancel {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 20px;

		width: 568px;
		height: 72px;

		background: ${(props) => props.theme.palette.primaryWhite.main};;
		border-radius: 5px;
		svg {
			color: ${(props) => props.theme.palette.gray.main};;
		}
		p {
			font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
			color: ${(props) => props.theme.palette.primaryWhite.contrastText};
		}
	}
`
