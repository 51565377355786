import { ICd } from '../components/select-cd.types'

export function formattedList(data: ICd[]): ICd[] {
	// Utiliza a função map para adicionar a propriedade 'label'
	// e combina 'SalesOrganizationID' e 'Name' para criar o valor 'label'
	const transformedData: ICd[] = data.map((e) => ({
		...e,
		label: `${e.salesOrganizationID} - CD ${e.Name?.charAt(0).toUpperCase()}${e.Name?.slice(1).toLowerCase()}`
	}))

	

	return transformedData
}
